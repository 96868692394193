import Repository from "./Repository";
const EndPoint = "/Finance/mycj";

export default {
    // Function for getting  data
    mycj(userId, token) {
        return Repository.get(`${EndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
};
