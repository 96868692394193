import CommissionRebate from "./CommissionRebate";
import SharedCommission from "./SharedCommission";

const Referral = ({ subItem }) => {
    const renderReferralContent = () => {
        switch (subItem) {
            case 'Commission Rebate':
                return <CommissionRebate />;
            case 'Shared Commission':
                return <SharedCommission />;
            default:
                return <CommissionRebate />;
        }
    };

    return (
        <div>
            {renderReferralContent()}
        </div>
    );
};

export default Referral;
