import React from 'react';
import { TbCaretUpDownFilled } from "react-icons/tb";
import NoData from "assets/img/user/No-data.svg";

function CopyTradingValues() {

    return (
        <div className='w-full'>
            {/* Header row */}
            <div className="w-full overflow-x-auto">
            <div className='flex bg-gray-100 min-w-[600px] py-4 px-2 mb-8'>
                <div className='flex items-center gap-1 flex-1'>
                    <p className='text-xs text-[#929AA5] text-ellipsis whitespace-nowrap'>Strategy Name</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 flex-1'>
                    <p className='text-xs text-[#929AA5]'>Equity</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 flex-1'>
                    <p className='text-xs text-[#929AA5]'>ROI</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 flex-1'>
                    <p className='text-xs text-[#929AA5]'>PnL</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 flex-[2]'>
                    <p className='text-xs text-[#929AA5]'>Realized Profit Share</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-end gap-1 flex-[2]'>
                    <p className='text-xs text-[#929AA5]'>Action</p>
                </div>
            </div>

                <div className='text-center text-md'>
                    <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                        <img src={NoData} alt="No Data" />
                        <p className='text-[#707a8a] text-sm'>No records</p>
                    </div>
                </div>
                </div>
        </div>
    );
}

export default CopyTradingValues;
