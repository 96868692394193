import React from "react";
import Image from "../../../assets/img/earn/usdt.png";
import { FaCaretDown } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { AiFillLike } from "react-icons/ai";
import { AiFillDislike } from "react-icons/ai";

const SummaryCard = ({ negative, threestar, twostar }) => {
    return (
        <div className="flex-1 border border-solid border-[#EFB81C] rounded-xl  p-4">
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                    <div className="w-12  rounded-full">
                        <img
                            src={Image}
                            alt=""
                            className="w-full  rounded-full"
                        />
                    </div>
                    <div>
                        <div className="flex items-center gap-2">
                            <h2 className="font-semibold text-lg leading-tight">
                                User Name
                            </h2>
                            <div className="flex items-center gap-1 text-sm text-yellow-500">
                                {threestar ? (
                                    <>
                                        <FaStar />
                                        <FaStar />
                                        <FaStar />
                                    </>
                                ) : twostar ? (
                                    <>
                                        <FaStar />
                                        <FaStar />
                                    </>
                                ) : (
                                    <>
                                        <FaStar />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="leading-tight text-sm flex items-center gap-2 font-medium">
                            <p className="text-[#a0a0a0]">8 Followers</p>
                            {negative ? (
                                <p className="text-red-600 flex items-center ">
                                    <span className="text-xl rotate-180">
                                        <FaCaretDown />
                                    </span>
                                    40.00%
                                </p>
                            ) : (
                                <p className="text-green-600 flex items-center ">
                                    <span className="text-xl ">
                                        <FaCaretDown />
                                    </span>
                                    700.00%
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <span className="text-lg cursor-pointer">
                        <AiFillLike />
                    </span>
                    <span className="text-lg cursor-pointer">
                        <AiFillDislike />
                    </span>
                </div>
            </div>

            <div className="mt-4">
                {negative ? (
                    <h2 className="text-red-600 font-semibold text-2xl">
                        -39.34%
                    </h2>
                ) : (
                    <h2 className="text-green-600 font-semibold text-2xl">
                        +69.34%
                    </h2>
                )}
            </div>
            <div className="mt-4 flex items-center justify-between !text-sm">
                <h2 className="text-[#a0a0a0] font-semibold">Total Token</h2>
                <h2 className=" font-semibold">$1365,780</h2>
            </div>
            <div className="mt-2 flex items-center justify-between !text-sm">
                <h2 className="text-[#a0a0a0] font-semibold">All Token</h2>
                <h2 className=" font-semibold">$1365,780</h2>
            </div>
            <div className="mt-2 flex items-center justify-between !text-sm">
                <h2 className="text-[#a0a0a0] font-semibold">
                    Total Earning Amount
                </h2>
                <h2 className=" font-semibold">$1365,780</h2>
            </div>

            <div className="mt-2 ">
                <button className="text-sm  text-white font-bold w-full py-2 bg-yellow-500 rounded-md">
                    FOLLOW
                </button>
            </div>
        </div>
    );
};

export default SummaryCard;
