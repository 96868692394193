import React, { useState, useRef, useCallback, useEffect } from "react";
import Dropdown from "./Dropdown";
import { IoSearchOutline } from "react-icons/io5";
import { Input } from "antd";
import NoData from "assets/img/user/No-data.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderView } from "../../../store/actions/OrderUserAction";
import { RiDownload2Line, RiFileHistoryFill } from "react-icons/ri";
import { DatePicker, Space } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import { Tooltip } from "react-tooltip";
import { GetOrder, trade } from "store/actions/p2pActions";

const { RangePicker } = DatePicker;

function AllOrders() {
    const dispatch = useDispatch();

    const { loading, orderViews, error } = useSelector(
        (state) => state.orderViewing
    );
    const userId = useSelector((state) => state.authUser?.user?.ID);
    const token = useSelector((state) => state.authUser?.user?.TOKEN);

    const [openDropdown, setOpenDropdown] = useState(null);
    const [selectedCoin, setSelectedCoin] = useState("All");
    const [selectedType, setSelectedType] = useState("All trade Types");
    const [selectedStatus, setSelectedStatus] = useState("All");
    const [selectedFiat, setSelectedFiat] = useState("All");
    const [selectedDate, setSelectedDate] = useState("Start Date");
    const [selectedStartTime, setSelectedStartTime] = useState("");
    const [selectedEndTime, setSelectedEndTime] = useState("");
    const [activeTab, setActiveTab] = useState("All");
    const [orders, setOrders] = useState([]);
    const [type, settype] = useState("");

    const handleReset = () => {
        setSelectedCoin("All");
        setSelectedType("All trade Types");
        setSelectedFiat("All");
        setSelectedStartTime("");
        setSelectedEndTime("");
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const dropdownRefs = useRef({});

    // Handles the toggle of dropdowns
    const handleDropdownToggle = useCallback(
        (dropdown) => {
            setOpenDropdown(openDropdown === dropdown ? null : dropdown);
        },
        [openDropdown]
    );

    // Closes all dropdowns
    const closeAllDropdowns = () => {
        setOpenDropdown(null);
    };

    // Handles clicks outside the dropdown
    const handleClickOutside = (event) => {
        const dropdownElement = dropdownRefs.current[openDropdown];
        if (dropdownElement && !dropdownElement.contains(event.target)) {
            closeAllDropdowns();
        }
    };

    useEffect(() => {
        dispatch(trade(userId, token)).then((result) => {
            if (result) {
                const responseData = result.data.p2pOrders;
                const type = result.data.p2pOrders.ad_type;
                setOrders(responseData);
                settype(type);
                console.log(responseData);
                
            }
        });
    }, [dispatch, userId, token]);
    
    const { dataa } = useSelector((state) => state.p2p);
    const [allCurrencies, setAllCurrencies] = useState(dataa?.fiat_list || []);
    const [searchCurrencyQuery, setSearchCurrencyQuery] = useState("");
    const [filteredCurrencies, setFilteredCurrencies] = useState(allCurrencies);
    
    const handleSearchCurrency = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchCurrencyQuery(query);
        if (query === "") {
            setFilteredCurrencies(allCurrencies);
        } else {
            const filtered = allCurrencies.filter((item) =>
                item.title.toLowerCase().includes(query)
            );
            setFilteredCurrencies(filtered);
        }
    };

    useEffect(() => {
        if (dataa?.fiat_list) {
            setAllCurrencies(dataa.fiat_list);
            setFilteredCurrencies(dataa.fiat_list);
        }}, [dataa]);


    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [openDropdown]);

    // Configures dropdowns with options, their state, and width
    const dropdowns = [
        {
            key: "coin",
            label: "Coins",
            options: ["All" ,"BTC", "USDT", "CVM", "BNB", "ETH"],
            selected: selectedCoin,
            setSelected: setSelectedCoin,
            width: "100%", // Custom width for this dropdown
        },
        {
            key: "fiat",
            label: "Currency",
            options: ["All", "UYU", "OMR", "MYR", "USD"],
            selected: selectedFiat,
            setSelected: setSelectedFiat,
            width: "100%",
            isSearchable: true,
        },
        {
            key: "type",
            label: "Order Type",
            options: ["All trade Types", "Buy", "Sell"],
            selected: selectedType,
            setSelected: setSelectedType,
            width: "100%", // Custom width for this dropdown
        },
    ];

    useEffect(() => {
        console.log("Orders", orderViews);
    }, [orderViews]);

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="text-lg">Loading...</div>
            </div>
        );
    }

    const [searchQuery, setSearchQuery] = useState("");

    const filteredOrders = orders.filter((order) => {
        // Filter by order id (search) and tab (status)
        const matchesSearch = order.orderid.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesTab = activeTab === "All" || 
            (activeTab === "Completed" && order.status === "2") ||
            (activeTab === "Canceled" && order.status === "3");

                const matchesType = selectedType === "All trade Types" || 
        (selectedType === "Buy" && order.ad_type == 1) || // Assuming ad_type 1 is for Buy
        (selectedType === "Sell" && order.ad_type == 2);

        const matchesCoin = selectedCoin === "All" || order.coin.toUpperCase() === selectedCoin.toUpperCase();
        const matchesFiat = selectedFiat === "All" || order.fiat.toUpperCase() === selectedFiat.toUpperCase();
        const matchesStartTime = selectedStartTime === "" || order.addtime * 1000 >= new Date(selectedStartTime).getTime();;
        const matchesEndTime = selectedEndTime === "" || order.endtime * 1000 < new Date(selectedEndTime).getTime() + 86400000;

        return matchesSearch && matchesTab && matchesType && matchesCoin && matchesFiat && matchesStartTime && matchesEndTime;
    });

    const handleDateChange = (startDate, endDate) => {
    setSelectedStartTime(startDate);
    setSelectedEndTime(endDate);
};

    return (
        <>
            <div className="shadow-md pb-4">
                {/* Tabs */}
                <div className="container">
                <div className="flex container gap-4 pt-4 pb-4 rounded-md">
                <button
                    className={`px-4 py-2 text-sm rounded-md ${
                        activeTab === "All"
                            ? "bg-[#EFB81C33] text-[#EFB81C]"
                            : "text-gray-500 bg-[#F5F5F5]"
                    }`}
                    onClick={() => handleTabClick("All")}
                >
                    <div className="flex items-center gap-1">
                        <p className="text-[14px]">All</p>
                    </div>
                </button>
                <button
                    className={`px-4 py-2 text-sm rounded-md ${
                        activeTab === "Completed"
                            ? "bg-[#EFB81C33] text-[#EFB81C]"
                            : "text-gray-500 bg-[#F5F5F5]"
                    }`}
                    onClick={() => handleTabClick("Completed")}
                >
                    <div className="flex items-center gap-1">
                        <p className="text-[14px]">Completed</p>
                    </div>
                </button>
                <button
                    className={`px-4 py-2 text-sm rounded-md ${
                        activeTab === "Canceled"
                            ? "bg-[#EFB81C33] text-[#EFB81C]"
                            : "text-gray-500 bg-[#F5F5F5]"
                    }`}
                    onClick={() => handleTabClick("Canceled")}
                >
                    <div className="flex items-center gap-1">
                        <p className="text-[14px]">Canceled</p>
                    </div>
                </button>
            </div>
                    <div className="flex container items-center justify-between mb-4">
                        <div className="flex w-full md:w-full flex-wrap lg:w-[80%] gap-3">
                            {dropdowns.map(
                                ({
                                    key,
                                    label,
                                    options,
                                    selected,
                                    setSelected,
                                    width,
                                    isSearchable,
                                }) => (
                                    <div
                                        key={key}
                                        className="flex-1 w-full"
                                        ref={(el) =>
                                            (dropdownRefs.current[key] = el)
                                        }
                                    >
                                        <Dropdown
                                            label={label}
                                            options={isSearchable ? filteredCurrencies.map(item => item.title) : options} // Extract titles for options
                                            initialSelected={selected}
                                            onSelect={(value) => {
                                                console.log(`Selected ${key.charAt(0).toUpperCase() + key.slice(1)}:`, value);

                                                setSelected(value);
                                                closeAllDropdowns();
                                            }}
                                            isOpen={openDropdown === key}
                                            onToggle={(isOpen) =>
                                                isOpen
                                                    ? handleDropdownToggle(key)
                                                    : closeAllDropdowns()
                                            }
                                            width={width}
                                            isSearchable={isSearchable}
                                        />
                                    </div>
                                )
                            )}
                            <div className="flex-1">
                                <GlobalStyles />
                                <StyledRangePicker
                                    className="px-2 py-[0.55rem] border-[2px] border-[#DBDBDB]"
                                    style={{ borderRadius: "0.5rem" }}
                                    placeholder={["Start Date", "End Date"]}
                                    value={[selectedStartTime, selectedEndTime]}
                                    onChange={(dates) => handleDateChange(dates ? dates[0] : null, dates ? dates[1] : null)}
                                    />
                            </div>
                        </div>
                        <div className="md:hidden justify-between gap-6 hidden lg:flex">
                            <label
                                htmlFor="coin"
                                className="block text-sm font-medium bg-gray-200 rounded-md px-3 py-3"
                            >
                                <RiDownload2Line
                                    data-tooltip-id="coin-tooltip"
                                    data-tooltip-content="Export order list"
                                    className="inline cursor-pointer"
                                />
                                <Tooltip
                                    id="coin-tooltip"
                                    place="top"
                                    effect="solid"
                                />
                            </label>
                            <label
                                htmlFor="coin"
                                className="block text-sm font-medium bg-gray-200 rounded-md px-3 py-3"
                            >
                                <RiFileHistoryFill
                                    data-tooltip-id="coin-tooltip"
                                    data-tooltip-content="View the export task process"
                                    className="inline cursor-pointer"
                                />
                                <Tooltip
                                    id="coin-tooltip"
                                    place="top"
                                    effect="solid"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="container">
                        <div className="flex items-center w-72">
                            <StyledInput
                                placeholder="Search order no."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                prefix={<IoSearchOutline />}
                            />

                            <div className="ml-3 text-[#EFB81C] text-sm cursor-pointer" onClick={handleReset}>
                                Reset
                            </div>
                            <div className="md:flex ml-5 justify-between gap-6 flex lg:hidden">
                                <label
                                    htmlFor="coin"
                                    className="block text-sm font-medium bg-gray-200 rounded-md px-3 py-3"
                                >
                                    <RiDownload2Line
                                        data-tooltip-id="coin-tooltip"
                                        data-tooltip-content="Export order list"
                                        className="inline cursor-pointer"
                                    />
                                    <Tooltip
                                        id="coin-tooltip"
                                        place="top"
                                        effect="solid"
                                    />
                                </label>
                                <label
                                    htmlFor="coin"
                                    className="block text-sm font-medium bg-gray-200 rounded-md px-3 py-3"
                                >
                                    <RiFileHistoryFill
                                        data-tooltip-id="coin-tooltip"
                                        data-tooltip-content="View the export task process"
                                        className="inline cursor-pointer"
                                    />
                                    <Tooltip
                                        id="coin-tooltip"
                                        place="top"
                                        effect="solid"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
    <table className="w-full text-sm font-light border-none table-hover">
        <thead className="border-b text-neutral-400">
            <tr>
                <th scope="col" className="py-2 font-light text-[12px] cursor-pointer">
                    Order ID/Time Created
                </th>
                <th scope="col" className="py-2 font-light text-[12px] cursor-pointer">
                    Price
                </th>
                <th scope="col" className="py-2 font-light text-[12px] cursor-pointer">
                    Total Value
                </th>
                <th scope="col" className="py-2 font-light text-[12px] cursor-pointer">
                    Amount
                </th>
                <th scope="col" className="py-2 font-light text-[12px] cursor-pointer">
                    Merchant Name
                </th>
                <th scope="col" className="py-2 font-light text-[12px] cursor-pointer">
                    Status
                </th>
                <th scope="col" className="py-2 font-light text-[12px] cursor-pointer">
                    Operation
                </th>
            </tr>
        </thead>
        <tbody className="text-center">
    {filteredOrders.length > 0 ? (
        filteredOrders.map((order) => {
            const formattedCoin = `${parseFloat(order.coin_qty).toFixed(2)} ${order.coin.toUpperCase()}`;
            const formattedFiat = order.fiat_qty
                ? `${parseFloat(order.fiat_qty).toFixed(2)} ${order.fiat.toUpperCase()}`
                : '09.00 USDT';
            const formattedPrice = `${parseFloat(order.fixed_price).toFixed(2)} ${order.fiat.toUpperCase()}`;

            return (
                <tr key={order.id} className="border-b text-neutral-700 text-center">
                    <td className="py-2 text-[12px]">
                        {order.orderid} / {new Date(order.addtime * 1000).toLocaleString()}
                    </td>
                    <td className="py-2 text-[12px]">
                        {formattedPrice}
                    </td>
                    <td className="py-2 text-[12px]">
                        {formattedFiat}
                    </td>
                    <td className="py-2 text-[12px]">
                        {formattedCoin}
                    </td>
                    <td className="py-2 text-[12px]">{order.name ? order.name : "Default Username"}</td>
                    <td className="py-2 text-[12px]">
                        {order.status === "0" ? "Pending" : "Completed"}
                    </td>
                    <td className="py-2 text-[12px]">
                        <button className="text-blue-500">Details</button>
                    </td>
                </tr>
            );
        })
    ) : (
        <tr>
            <td colSpan="7" className="text-center">
                No orders found
            </td>
        </tr>
    )}
</tbody>
    </table>
</div>

        </>
    );
}
const GlobalStyles = createGlobalStyle`
  // Override hover state for picker
  .ant-picker:hover {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on hover */
  }

  // Override focus state for picker
  .ant-picker-focused {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on focus */
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important; /* Add shadow with color #EFB81C */
  }

  // Style for the active state bar in RangePicker
  .ant-picker-active-bar {
    background: #EFB81C !important; /* Change the active border bar color to #EFB81C */
  }

  // Style calendar cells with selected date
  .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EFB81C !important; /* Change the background color of selected date */
    color: white !important; /* Keep the text color white for contrast */
  }

  // Style for "Today" cell in the calendar dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    border: 1px solid #EFB81C !important; /* Change border color to #EFB81C for the "Today" cell */
    border-radius: 4px;
    content: "";
  }
    // Change color of calendar header buttons on hover
  .ant-picker-dropdown .ant-picker-header-view > button:hover {
    color: #EFB81C !important; /* Change hover color to #EFB81C */
  }
`;

const StyledRangePicker = styled(RangePicker)`
    // Custom styling for RangePicker input border when focused
    .ant-picker-input > input:focus {
        box-shadow: none !important; /* Remove any focus box-shadow */
        border-color: #efb81c !important; /* Ensure border stays #EFB81C */
    }
`;

const StyledInput = styled(Input)`
    padding: 0.55rem;
    font-weight: bold;
    color: #707a8a;
    border: 2px solid #dbdbdb;
    transition: border-color 0.3s ease; /* Smooth transition for border color */

    &:hover {
        border-color: #efb81c; /* Border color on hover */
    }

    &:focus,
    &:focus-within {
        border-color: #efb81c; /* Border color on focus */
        box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2); /* Shadow color on focus */
        outline: none; /* Removes default outline */
    }
`;

export default AllOrders;
