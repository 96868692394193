

import React from 'react';
import { useSelector } from 'react-redux';
import { ImCross } from "react-icons/im";
import G2faVerification from "../.././SecuritSettingsComponents/helpers/G2faVerify";
import { Link } from "react-router-dom";

const ShowTwoFA = ({nextStep,setShow2FA}) => {
  const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;
    const { authStatus } = useSelector(
        (state) => state.authCheck
    );
    const email = authStatus?.email;
    // hide the twofa 
    const hideshow2FA=()=>{
        setShow2FA(false);
    }
  return (
      <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-neutral-200 bg-opacity-70">
          <div className="w-2/6 p-4 bg-white rounded-xl">
          
          <div className="">
          <button className="float-right">
<ImCross onClick={hideshow2FA} className="text-xl text-[#D7D7D7] " />
</button>
          <h1  className="text-xl font-bold">2FA</h1>

          <G2faVerification onSuccess={nextStep}
                        emailasprop={email} userId={userId}
                            token={token}
                            type="update" />
                            <div className="text-center">
                            <Link to="/user/account/security" className="mt-3 text-yellow-500 ">Security Authentication unavailable?</Link>
                            </div>
          
        </div>


          </div>
        
      </div>
  )
}

export default ShowTwoFA
