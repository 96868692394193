// src/repository/WalletRepository.js
import axios from 'axios';

// API endpoint for fetching wallet data
const API_URL = 'https://api.buycex.com/Transfer/index';

// Helper function to generate headers
const getHeaders = (token, id) => ({
  'TOKEN': token,
  'ID': id,
  'Content-Type': 'application/x-www-form-urlencoded',
});

const WalletRepository = {
  // Function to get wallet data based on from and to wallets

  async getWalletData(from, to, token, id) {
    const data = new URLSearchParams({
      from, // From wallet (e.g., spot)
      to,   // To wallet (e.g., staking)
    });
    console.log("data",from, to, token, id)
    try {
      const response = await axios.post(API_URL, data.toString(), {
        headers: getHeaders(token, id),
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default WalletRepository;
