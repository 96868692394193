// userUpdateRepository.js
import axiosObj from "./Repository";
import qs from "qs";

const userUpdateRepository = {
    async updateUsername(username, token, id) {
        const data = qs.stringify({
            username: username,
        });

        const config = {
            method: "post",
            url: "/User/updateUsername",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                TOKEN: token,
                ID: id,
            },
            data: data,
        };

        try {
            const response = await axiosObj.request(config);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async updateUserNickName(username, token, id) {
        const data = qs.stringify({
            nickname: username,
            nickname_confirm: username,
        });

        const config = {
            method: "post",
            url: "/User/updateNickname",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                TOKEN: token,
                ID: id,
            },
            data: data,
        };

        try {
            const response = await axiosObj.request(config);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};

export default userUpdateRepository;
