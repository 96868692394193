import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    MdAccountCircle,
    MdNavigateNext,
    MdCheckBoxOutlineBlank,
} from "react-icons/md";
import { IoCheckbox } from "react-icons/io5";
import DisableConfirmation from "./disableConfirmation";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { disableAccount, fetchAuthStatus } from "store/actions/userSecuritySettingsAction";
import toast from "react-hot-toast";

function DisableAccount() {
    const [selectedReason, setSelectedReason] = useState(null);
    const [isAccepted, setIsAccepted] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authUser?.user || {});
    const state = useSelector((state) => state);
    const userId = user?.ID;
    const token = user?.TOKEN;

    console.log("sdsds",state);
    

    const handleReasonClick = (reason) => {
        setSelectedReason((prevReason) =>
            prevReason === reason ? null : reason
        );
    };

    const handleAcceptanceClick = () => {
        setIsAccepted((prevState) => !prevState);
    };

    const openPopup = () => {
        if (!selectedReason) {
            toast.error("Please select at least one reason for disabling the account.");
            return;
        }

        if (!isAccepted) {
            toast.error("Please accept the terms and conditions.");
            return;
        }

        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const refresh = () => { 
        dispatch(fetchAuthStatus(userId, token));
    }

    const handleConfirm = () => {
        // Here you would handle the actual account disabling logic
        const payload = {
            pass: 'freeze',
            reason: selectedReason
        }
        dispatch(disableAccount(payload, userId, token)).then(() => {
            setIsDisabled(true);
            setIsPopupOpen(false);
        });
    };

    if (isDisabled) {
        return (
            <>
                <div className="flex items-center">
                    <div className="flex items-center gap-2">
                        <MdAccountCircle className="text-[24px]" />
                        <span className="text-[24px] font-bold">
                            Account Settings
                        </span>
                    </div>
                    <div>
                        <MdNavigateNext className="text-[24px] text-[#DADADA]" />
                    </div>
                    <span className="text-[24px] font-bold">
                        Disable Account
                    </span>
                </div>
                <div className="flex mt-2">
                    <div className="bg-white h-[471px] p-16 w-full rounded-xl shadow-lg">
                        <div className="flex gap-3">
                            <RiCheckboxCircleFill className="text-[46px] text-[#EFB81C]" />
                            <div className="flex flex-col">
                                <p className="text-xl font-semibold">
                                    Account Disabled successfully
                                </p>
                                <p className="mt-2 text-sm text-[#9C9C9C] w-[65%]">
                                    Your account has been Disabled. All active
                                    login sessions have been terminated. To
                                    regain access, please contact our Customer
                                    Support team.
                                </p>
                                <Link to="/user/account/security">
                                <button
                                    type="button"
                                    className="text-black mt-3 flex justify-start w-52 bg-[#EFB81C] hover:bg-[#EFB81C] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5"
                                    onClick={refresh}
                                >
                                    Back to Settings
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="flex items-center">
                <div className="flex items-center gap-2">
                    <MdAccountCircle className="text-[24px]" />
                    <span className="text-[24px] font-bold">
                        Account Settings
                    </span>
                </div>
                <div>
                    <MdNavigateNext className="text-[24px] text-[#DADADA]" />
                </div>
                <span className="text-[24px] font-bold">Disable Account</span>
            </div>

            <div className="bg-[#fff] mt-2 shadow rounded-xl p-20 text-justify">
                <span className="text-xl font-semibold">
                    After Disabling your account:
                </span>
                <p className="text-[16px] w-[65%] text-[#9C9C9C] pt-2">
                    Disabling your account will restrict all account functions.
                    You will be unable to trade, withdraw funds, or access your
                    account. Additionally, all API private keys will be deleted,
                    pending withdrawals canceled, and open orders terminated.
                </p>

                <div className="bg-[#F3F3F3] rounded-xl p-3 !pl-10 !pr-10 mt-6 mb-6">
                    <span className="font-bold">Note:</span>
                    <p>
                        To re-activate a disabled account, please contact BUYCEX
                        support. The account re-activation process requires
                        verification and may take anywhere from 3 to 30 days to
                        complete.
                    </p>
                </div>

                <div>
                    <span className="font-semibold">
                        Please select a reason to disable your account:
                    </span>
                    <div className="flex gap-8 mt-3">
                        {[
                            "Remote login/Account stolen",
                            "Disable Account",
                            "Others",
                        ].map((reason) => (
                            <div
                                key={reason}
                                className="flex items-center gap-1 border pl-2 pr-2 rounded-full cursor-pointer"
                                onClick={() => handleReasonClick(reason)}
                            >
                                {selectedReason === reason ? (
                                    <IoCheckbox className="text-[#EFB81C]" />
                                ) : (
                                    <MdCheckBoxOutlineBlank className="text-[#EFB81C]" />
                                )}
                                <span>{reason}</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div
                    className="flex items-center gap-1 mt-10 rounded-full cursor-pointer"
                    onClick={handleAcceptanceClick}
                >
                    {isAccepted ? (
                        <IoCheckbox className="text-[#EFB81C]" />
                    ) : (
                        <MdCheckBoxOutlineBlank className="text-[#EFB81C]" />
                    )}
                    <span>
                        I fully comprehend and accept the consequences of
                        account deactivation.
                    </span>
                </div>

                <button
                    type="button"
                    className="text-black mt-5 bg-[#E6E6E6] hover:bg-[#E6E6E6] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5"
                    onClick={openPopup}
                >
                    Disable Account
                </button>
            </div>

            <DisableConfirmation
                isOpen={isPopupOpen}
                onClose={closePopup}
                onConfirm={handleConfirm}
            />
        </>
    );
}

export default DisableAccount;