import React, { useEffect, useState } from "react";
import "./premarket.css";
// import Banner from "../../../assets/img/home-page/hero-section/hero-img-1.jpg";
import { useSelector } from "react-redux";
import PaginatedItems from "./PaginatedItems";
import { FaArrowRight } from "react-icons/fa";

import Guidesection from "./Guidesection";
import FaqsSection from "./FaqsSection";
import CardSlider from "./CardSlider";

import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import IssueRepository from "repository/IssueRepository"; 

const PreMarketTrading = () => {
    const [currentCoins, setCurrentCoins] = useState("available");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    useEffect(() => {
        const fetchData = async () => {
            try {
                const issuesData = await IssueRepository.fetchIssues(TOKEN, ID);
                setData(issuesData.data.list_jinxing); // Adjust based on your API response structure
                console.log("check data ", issuesData.data.list_jinxing);
            } catch (err) {
                setError("Error fetching data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [TOKEN, ID]);
    //  the top section four link buutons e.g trading rules etc
    const topbuttons = [
        {
            id: 1,
            title: "Trade Now",
            link: "/trade/spot",
            icon: "//s1.bycsi.com/bybit/deadpool/image-f921f812edc64b5c8a1809617f250a8e.svg",
        },
        {
            id: 2,
            title: "My Assets",
            link: "/user/assets/spot",
            icon: "//s1.bycsi.com/bybit/deadpool/image-96389ab17bda4838b8479dc0b1871e2c.svg",
        },
    ];

    return (
        <div className=" pt-10 ">
            <div className="  premarketcontainer-header premarketcontainer">
                <div className=" container flex flex-col lg:flex-row items-center justify-between gap-4  ">
                    {/* <div className="absolute top-0 left-0 w-full h-full">
           <img src={Banner} alt="" className="w-full h-full rounded-xl z-10 " />
         </div> */}

                    <div className="w-full lg:w-[60%] ">
                        <h1 className="lg:text-[48px] text-[36px] font-bold text-black mt-2">
                             <span className="text-[48px] font-[900] text-[#EFB81C]"> Pre-Listed Spot</span>
                        </h1>
                        <p className="mt-[16px] lg:text-[18px] text-[16px] font-base  text-[#81858c]">
                            Pre-Market Trading is an over-the-counter(OTC)
                            Trading Service. For trade new tokens before
                            official listing in buycex.
                        </p>
                        {/* the link buttons  */}
                        <div className="flex items-center gap-4 mt-4 premarket-navbutton">
                            {topbuttons.map((button) => (
                                <NavLink to={button.link} key={button.id}>
                                    <button
                                        className="flex bg-yellow-600  
               p-[8px] h-[24px] rounded-[4px] gap-1 font-medium items-center text-white text-[12px] "
                                    >
                                        {" "}
                                        <img
                                            src={button.icon}
                                            alt=""
                                            className="w-[14px] h-[14px]"
                                        />{" "}
                                        {button.title}
                                    </button>
                                </NavLink>
                            ))}
                        </div>
                        {/* the total vlaues section  */}
                        <div className="flex items-center text-[#EFB81C] gap-[16px] md:gap-[24px] mt-[24px] pb-2">
                            <div className="flex gap-4 md:gap-6 items-center">
                                <div>
                                    <h1 className="md:text-[24px] text-[18px] font-semibold default-font ">
                                        21
                                    </h1>
                                    <p className="text-[#81858c] font-medium text-[12px]">
                                        Projects
                                    </p>
                                </div>
                                <div className="text-3xl">|</div>
                            </div>
                            <div className="flex gap-4 md:gap-6 items-center">
                                <div>
                                    <h1 className="md:text-[24px] text-[18px] font-semibold default-font ">
                                        94,650
                                    </h1>
                                    <p className="text-[#81858c] font-medium text-[12px]">
                                        Participants
                                    </p>
                                </div>
                                <div className="text-3xl ">|</div>
                            </div>
                            <div className="">
                                <div>
                                    <h1 className="md:text-[24px] text-[18px] font-semibold default-font ">
                                        $20,030,495
                                    </h1>
                                    <p className="text-[#81858c] font-medium text-[12px]">
                                        Total Trading Volume
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="lg:w-[40%] w-full  ">
                        {/* <img src={Banner} alt="" className="w-full h-full obejct-cover" /> */}
                        <CardSlider data={data} />
                    </div>
                </div>
            </div>
            <div className="premarketcontainer">
                {/* the first bg section having background  */}

                {/* second section  */}
                <div className="container mt-8 ">
                    <div className="flex items-center py-2 md:py-0 justify-between border-b border-[#adb1b8]">
                        <div className="flex items-center gap-4 md:gap-6">
                            <button
                                onClick={() => setCurrentCoins("available")}
                                className={` py-2 border-b-2  hover:border-yellow-500 z-10 font-semibold text-[14px] md:text-[20px]  ${
                                    currentCoins == "available"
                                        ? "border-yellow-500"
                                        : "border-transparent text-[#adb1b8]"
                                }`}
                            >
                                Avaiable Coins
                            </button>
                            <button
                                onClick={() => setCurrentCoins("past")}
                                className={` py-2 border-b-2 hover:border-yellow-500 font-semibold z-10 text-[14px] md:text-[20px]  ${
                                    currentCoins == "past"
                                        ? "border-yellow-500 "
                                        : "border-transparent text-[rgb(173,177,184)]"
                                }`}
                            >
                                Past Coins
                            </button>
                        </div>
                        <NavLink to="/user/spot-order/order-history">
                            {" "}
                            <button className="border-[2px]  border-yellow-500 px-[14px] md:px-[18px] py-[4px] md:py-[8px] text-yellow-500 text-xs font-semibold rounded-md flex items-center gap-1">
                                My Orders <FaArrowRight size={12} />
                            </button>
                        </NavLink>
                    </div>
                    {loading && (
                        <div className="text-center text-green-600 p-20 text-2xl">
                            Loading...
                        </div>
                    )}
                    {error && (
                        <div className="text-center text-red-600 p-20 text-2xl">
                            {error}
                        </div>
                    )}
                    <PaginatedItems
                        itemsPerPage={8}
                        currentCoins={currentCoins}
                        data={data}
                    />
                    <Guidesection />
                    <FaqsSection />
                </div>
            </div>
            <div className="bg-gray-200  text-black py-3 px-3 lg:px-8 flex items-center justify-between">
                <div className="flex md:items-center flex-col md:flex-row  gap-1 md:gap-4 lg:gap-10">
                    <div className="flex  gap-2 lg:gap-4 items-center">
                        <button className="text-xs  font-semibold pr-1 md:pr-2 lg:pr-4  border-r border-black">
                            Market Overview
                        </button>
                        <button className="text-xs  font-semibold pr-1 md:pr-2 lg:pr-4  border-r border-black">
                            Trading Free
                        </button>
                        <button className="text-xs  font-semibold pr-1 md:pr-2 lg:pr-4 border-r border-black">
                            API
                        </button>
                        <button className="text-xs  font-semibold pr-1 md:pr-2 lg:pr-4 ">
                            Help Center
                        </button>
                    </div>
                    <p className="text-xs  text-[#82868a] font-semibold">
                        © 2018-2024 Bybit.com. All rights reserved.
                    </p>
                </div>
                <button className="text-[#82868a] font-semibold text-[9px] md:text-xs">
                    Routing Auto
                </button>
            </div>
        </div>
    );
};

export default PreMarketTrading;
