import React from "react";
import CoinCard from "./CoinCard";
import DPSTable from "./DPSTable";

const CryptoDPS = () => {
    return (
        <div className="bg-[#f5f7fa] pb-10">
            <div className="bg-[#f5f7fa] ">
                <div classname="earn-overview">
                    <div className="earn-overview-header h-96">
                    <div className="container h-full flex items-stretch justify-between !px-10 gap-10 pt-10 pb-6">
    {/* Left Section */}
    <div className="flex-1 text-black p-6 pt-12 rounded-[16px]">
        <span className="text-[52px] font-[900] text-[#EFB81C]">CRYPTO DPS</span>
        <p className="text-[24px] font-medium">
            Deposit Monthly Get Back Double Amount
        </p>
        <div className="flex gap-6 text-[#EFB81C] items-center mt-4 flex-wrap">
            <div>
                <p className="text-[#adb1b8] text-[14px] font-semibold">
                    Total Committed Amount (USD)
                </p>
                <p className="text-[18px] font-semibold">
                    $4,446,678,055
                </p>
            </div>
            <div className="flex items-center gap-6">
                <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                <div>
                    <p className="text-[#adb1b8] text-[14px] font-semibold">AVG. Growth</p>
                    <p className="text-[18px] font-semibold">$645%</p>
                </div>
            </div>
            <div className="flex items-center gap-6">
                <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                <div>
                    <p className="text-[#adb1b8] text-[14px] font-semibold">Participants</p>
                    <p className="text-[18px] font-semibold">2,151,102</p>
                </div>
            </div>
            <div className="flex items-center gap-6">
                <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                <div>
                    <p className="text-[#adb1b8] text-[14px] font-semibold">Projects</p>
                    <p className="text-[18px] font-semibold">31</p>
                </div>
            </div>
        </div>

        <div className="hero-btn-wrap flex items-center gap-4 mt-2 mb-12"></div>
        
        <div className="mt-4 flex items-center gap-4">
            <div className="flex items-center gap-1">
                <button className="bg-[#EFB81C] text-white py-1 px-4 rounded-md">
                    Token Sale Records
                </button>
            </div>
            <div className="flex items-center gap-1">
                <button className="bg-[#EFB81C10] hover:bg-[#EFB81C] text-black py-1 px-4 rounded-md">
                    Vote History 
                </button>
            </div>
        </div>
    </div>

    {/* Separator Line */}
    <div className="w-[2px] bg-[#adb1b8] h-full mx-4"></div>

    {/* Right Section */}
    <div className="w-[45%] text-black p-6 pt-12 rounded-[16px]">
        <span className="text-[52px] font-[900] text-[#EFB81C]">Account Summary</span>
        <div className="flex gap-6 text-[#EFB81C] items-center mt-4 flex-wrap">
            <div>
                <p className="text-[#adb1b8] text-[14px] font-semibold">Total Count</p>
                <p className="text-[18px] font-semibold">60 Month</p>
            </div>
            <div className="flex items-center gap-6">
                <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                <div>
                    <p className="text-[#adb1b8] text-[14px] font-semibold">Auto Completed</p>
                    <p className="text-[18px] font-semibold">On</p>
                </div>
            </div>
            <div className="flex items-center gap-6">
                <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                <div>
                    <p className="text-[#adb1b8] text-[14px] font-semibold">Completed</p>
                    <p className="text-[18px] font-semibold">26</p>
                </div>
            </div>
            <div className="flex items-center gap-6">
                <div className="w-[2px] bg-[#adb1b8] h-8"></div>
                <div>
                    <p className="text-[#adb1b8] text-[14px] font-semibold">Missed</p>
                    <p className="text-[18px] font-semibold">4</p>
                </div>
            </div>
        </div>
        <div className="border-2 border-[#EFB81C] text-[#fff] mt-4 rounded-[14px] shadow-lg p-4 flex items-center justify-between gap-8">
            <div className="flex-1 flex flex-col gap-2">
                <p className="text-[#adb1b8] text-[14px] font-semibold">Total Deposit</p>
                <div className="flex items-center gap-1">
                    <p className="text-[18px] font-semibold text-[#20b26c]">2600 USDT</p>
                </div>
                <button className="w-full bg-[#ffb11a] text-[12px] h-[32px] rounded-[8px] font-semibold">
                    History
                </button>
            </div>
            <div className="flex-1 flex flex-col gap-2 items-end">
                <p className="text-[#adb1b8] text-[14px] font-semibold">Total Due</p>
                <div className="flex items-center gap-1">
                    <p className="text-[18px] font-semibold text-red-500">400 USDT</p>
                </div>
                <button className="w-full h-[32px] text-[12px] text-black font-semibold border-solid border-[1px] border-[#595d61] rounded-[8px]">
                    Deposit
                </button>
            </div>
        </div>
    </div>
</div>


                    </div>
                    {/* cards  */}
                    {/* <div className="container !px-10 ">
                        <h2 className="text-[#121214] text-[24px] mt-8 font-semibold">
                            Expiring Soon
                        </h2>
                        <p className="mt-1 text-[#81858c] text-[16px]">
                            T+0 or T+1-day expiry options. Affordable and highly
                            liquid options for traders.
                        </p>
                        <div className="mt-4 flex flex-wrap gap-6">
                            <CoinCard />
                            <CoinCard />
                            <CoinCard />
                        </div>
                    </div> */}
                    {/* otrher cards  */}
                    {/* <div className="container !px-10">
                        <h2 className="text-[#121214] text-[24px] mt-8 font-semibold">
                            Top Volume in Last 24H
                        </h2>
                        <p className="mt-1 text-[#81858c] text-[16px]">
                            Recently executed individual trades with the highest
                            trading volume.
                        </p>
                        <div className="mt-4 flex flex-wrap gap-6">
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                            <CoinCard btn={"true"} />
                        </div>
                    </div> */}

                    <div className="mt-6 flex justify-between gap-4 container !px-10">
                        <div className="flex-1">
                            <DPSTable />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CryptoDPS;
