import React from "react";
import "./marketplace.css";
import ShopCenter from "./ShopCenter";
const MarketPlace = () => {
    return (
        <div>
            <div className="earn-overview-header h-80">
                <div className="px-10">
                    <div className=" container flex flex-col lg:flex-row items-center justify-between gap-4  ">
                        <div className="w-full lg:w-[50%] ">
                            <h1 className="lg:text-[48px] text-[36px] font-bold  text-[#EFB81C] mt-10">
                                BUYCEX STORE
                            </h1>
                            <p className="mt-[16px] lg:text-[18px] text-[16px] font-base   text-[#81858c]">
                                Welcome to BUYCEX STORE your one stop shop where
                                shopping meets smart investment! With every
                                purchase, you’re not just buying products,
                                you’re gaining access to an innovative world of
                                rewards. Hold BCX tokens to unlock exclusive
                                perks: monthly earnings from exchange profits,
                                exclusive discounts, and rewards on every
                                purchase. Enjoy the power of shopping with
                                benefits that keep giving back. BUYCEX where
                                every buy earns you more.
                            </p>
                            {/* the link buttons  */}

                            {/* the total vlaues section  */}
                        </div>

                        <div className="lg:w-[40%] w-full mt-10 border border-[#EFB81C] rounded-[14px]">
    <div className="text-[#fff] rounded-[14px] shadow-lg p-8 flex items-center justify-between gap-8">
        <div className="flex-1 flex flex-col gap-4">
            <p className="text-[#adb1b8] text-[14px] font-semibold">
                BCX Token Holdings
            </p>
            <div className="flex items-center gap-1">
                <p className="text-[18px] font-semibold text-[#fff]">
                    100{" "}
                    <span className="text-[18px] font-semibold text-yellow-500">
                        BCX
                    </span>
                </p>
                <button></button>
            </div>
            <button className="w-full bg-[#ffb11a] h-[36px] rounded-[8px] font-semibold">
                My Earnings
            </button>
        </div>
        <div className="flex-1 flex flex-col gap-4 items-end">
            <p className="text-[#adb1b8] text-[14px] font-semibold">
                Market Price
            </p>
            <div className="flex items-center gap-1">
                <p className="text-[18px] font-semibold text-[#20b26c]">
                    1000 USDT
                </p>
                <button></button>
            </div>
            <button className="w-full bg-[#EFB81C10] h-[36px] font-semibold border-solid text-black hover:bg-[#EFB81C] border-[1px] border-[#595d61] rounded-[8px]">
                Order History
            </button>
        </div>
    </div>
</div>

                    </div>
                </div>
            </div>
            <div className="px-10">
                <div className="container">
                    <div className="">
                        <ShopCenter />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketPlace;
