import Repository from "./Repository";
const EndPoint = "/Staking/index";
const createStaking = "/Staking/createStaking"
const Staking = "/Staking/logs"

export default {
    // Function for getting Assets data
    earnStaking(userId, token) {
        return Repository.get(`${EndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    Staking(userId, token) {
        return Repository.get(`${Staking}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    createStaking(userid, token,payload) {
        return Repository.post(`${createStaking}`, payload,{
            headers: {
                "Content-Type": "multipart/form-data",
                token: token,
                id: userid,
            },
        });
    },
};
