import React from 'react';
import { MdSwapVerticalCircle } from "react-icons/md";


export default function SwapButton({handleSwap}) {
    return(
        <div className="flex items-center justify-center" style={{ marginTop: '-20px', marginBottom: '-68px' }}>
            <div className="cursor-pointer bg-neutral-50 rounded-full" onClick={handleSwap}>
                <MdSwapVerticalCircle className="w-12 h-12" fill="#EFB81C" />
            </div>
        </div>
    );
}