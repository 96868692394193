import React, { useState } from "react";
import { PiNoteFill } from "react-icons/pi";


const MarginHistory = () => {
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <>
      <div className="relative flex items-center gap-x-3 bottom-3">
        <PiNoteFill size={26} />
        <div className="text-black text-[24px] font-semibold">Margin History</div>
      </div>
      <div className="flex flex-col w-full">
        {/* First card */}
        <div className="flex-col justify-center w-full p-4 space-x-4 bg-white rounded-3xl">
          <div className="flex items-center justify-between w-full ">
          <div className="flex items-center space-x-4">
              <label htmlFor="account" className="text-gray-700">
                Account
              </label>
              <select id="account" className="px-3  border rounded-md w-40">
                <option>BTC/USDT </option>
                
              </select>
            </div>
            <div className="flex items-center space-x-4">
              <label htmlFor="asset" className="text-gray-700">
                Asset
              </label>
              <select id="asset" className="px-3  border rounded-md w-40">
                <option>All</option>
              </select>
            </div>
            <div className="flex items-center space-x-4">
              <label htmlFor="operation" className="text-gray-700">
                Operation
              </label>
              <select id="operation" className="px-3  border rounded-md w-40">
                <option>All</option>
              </select>
            </div>
          </div>
          <div className="flex items-center w-full mt-3 gap-x-3">
            <div className="flex items-center gap-x-2">
              <label className="text-gray-700 py-1">Time</label>
              <button className="px-4 py-1 bg-yellow-200 rounded-md">
                Last 7 days
              </button>
              <button className="px-4 py-1 bg-gray-200 rounded-md">
                Last 30 days
              </button>
              <button className="px-4 py-1 bg-gray-200 rounded-md">All</button>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="date"
                name="startDate"
                value={dateRange.startDate}
                onChange={handleDateChange}
                className="px-4 py-1 border rounded-md"
              />
              <span className="text-gray-700">-</span>
              <input
                type="date"
                name="endDate"
                value={dateRange.endDate}
                onChange={handleDateChange}
                className="px-4 py-1 border rounded-md"
              />
            </div>
            <button className="px-4 py-1 bg-yellow-200 rounded-md">Export</button>
          </div>
        </div>

        {/* Second Card */}
        <div className="bg-white rounded-2xl w-full min-h-[500px] mt-3 p-4">
          {/* Content for the second card */}
        <table className="w-full">
          <thead className="w-full">
            <tr className="w-full flex items-center justify-between gap-1 py-2 border-b">
              <th className="text-[#AAAAAA]  text-sm flex-1">Time </th>
              <th className="text-[#AAAAAA]  text-sm flex-1">Account  </th>
              <th className="text-[#AAAAAA]  text-sm flex-1">Assets </th>
              <th className="text-[#AAAAAA]  text-sm flex-1">Operation </th>
              <th className="text-[#AAAAAA]  text-sm flex-1">Assets Change </th>
              <th className="text-[#AAAAAA]  text-sm flex-1">Balance  </th>
              
            </tr>
          </thead>
          <tbody className="w-full">
          <tr className="w-full flex items-center justify-between gap-1 py-2 border-b hover:shadow-lg">
              <td className="  text-sm flex-1">2024-07-05 14:13 </td>
              <td className="  text-sm flex-1"> CET/USDT   </td>
              <td className="  text-sm flex-1">USDT </td>
              <td className="  text-sm flex-1">Margin Transfer </td>
              <td className="  text-sm flex-1 text-green-500"> +935764</td>
              <td className="  text-sm flex-1"> 0,000</td>
              
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </>
  );
};

export default MarginHistory;
