import React from 'react';

const ShareCodeSVG = () => {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6">
                <circle cx="50" cy="50" r="50" fill="url(#paint0_linear_1095_25961)" fill-opacity="0.3"></circle>
                <circle cx="50" cy="50" r="49.5" stroke="#F7A600" stroke-opacity="0.15"></circle>
            </g>
            <path d="M72.917 68.7513V64.5846C72.917 51.9281 62.6568 41.668 50.0003 41.668V41.668C37.3438 41.668 27.0836 51.9281 27.0836 64.5846V68.7513" stroke="white" stroke-width="4.16667" stroke-linecap="round"></path>
            <circle cx="50.0003" cy="33.3348" r="8.33333" stroke="white" stroke-width="4.16667" stroke-linecap="round"></circle>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.0771 45.8986C53.1925 46.1811 53.8721 47.3154 53.5951 48.4321L53.2411 49.8594L55.5746 50.4503C56.69 50.7328 57.3696 51.8671 57.0926 52.9838C56.8156 54.1006 55.6868 54.7769 54.5714 54.4944L48.3052 52.9076C47.9235 52.811 47.5157 52.8704 47.1711 53.079C46.8257 53.2881 46.5725 53.6303 46.4726 54.0328C46.3728 54.4353 46.4364 54.8577 46.6433 55.2067C46.8498 55.5549 47.1817 55.8015 47.5633 55.8982L51.7408 56.9561C53.2057 57.327 54.4609 58.27 55.2329 59.5717C56.0044 60.8726 56.2305 62.4252 55.8676 63.8884C55.5046 65.3517 54.5805 66.613 53.2929 67.3925C52.0665 68.1349 50.6058 68.3821 49.2047 68.0842L48.8827 69.3824C48.6056 70.4992 47.4769 71.1755 46.3615 70.8931C45.2461 70.6106 44.5665 69.4763 44.8435 68.3596L45.1645 67.0654L42.7264 66.448C41.611 66.1656 40.9314 65.0313 41.2084 63.9146C41.4854 62.7978 42.6142 62.1215 43.7296 62.4039L49.9958 63.9907C50.3775 64.0874 50.7853 64.028 51.1299 63.8194C51.4753 63.6103 51.7285 63.2681 51.8284 62.8656C51.9282 62.4631 51.8646 62.0407 51.6577 61.6917C51.4512 61.3435 51.1193 61.0968 50.7377 61.0002L46.5602 59.9423C45.0953 59.5714 43.8401 58.6284 43.0681 57.3267C42.2966 56.0258 42.0705 54.4732 42.4334 53.0099C42.7964 51.5467 43.7206 50.2854 45.0081 49.5059C46.2652 48.7448 47.7686 48.5042 49.2016 48.8376L49.556 47.4093C49.833 46.2925 50.9617 45.6162 52.0771 45.8986Z" fill="#F7A600"></path>
            <line x1="66.667" y1="62.5011" x2="79.167" y2="62.5011" stroke="#F7A600" stroke-width="4.16667" stroke-linecap="round"></line>
            <line x1="72.917" y1="56.2513" x2="72.917" y2="68.7513" stroke="#F7A600" stroke-width="4.16667" stroke-linecap="round"></line>
            <defs>
                <linearGradient id="paint0_linear_1095_25961" x1="11.2501" y1="17.0831" x2="79.167" y2="77.9163" gradientUnits="userSpaceOnUse">
                    <stop offset="0.11722" stop-color="#F7A600"></stop>
                    <stop offset="1" stop-color="#F7A600" stop-opacity="0"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

const ConnectFriendsSvg = () => {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6">
                <circle cx="50" cy="50" r="50" fill="url(#paint0_linear_1095_25970)" fill-opacity="0.3"></circle>
                <circle cx="50" cy="50" r="49.5" stroke="#F7A600" stroke-opacity="0.15"></circle>
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M58.982 27.5005H39.1664C32.7231 27.5005 27.4998 32.7238 27.4998 39.1672V59.8159C28.9377 58.8784 30.6551 58.3335 32.4997 58.3335C32.7807 58.3335 33.0586 58.3461 33.3331 58.3709V39.1672C33.3331 35.9455 35.9448 33.3338 39.1664 33.3338H57.5372C57.5125 33.0592 57.4998 32.7812 57.4998 32.5002C57.4998 30.6557 58.0446 28.9384 58.982 27.5005ZM66.6665 41.6668C68.8825 41.6668 70.9149 40.8805 72.4998 39.5716V60.8338C72.4998 67.2771 67.2764 72.5005 60.8331 72.5005H40.1837C41.1215 71.0625 41.6664 69.345 41.6664 67.5002C41.6664 67.2194 41.6538 66.9415 41.6291 66.6672H60.8331C64.0547 66.6672 66.6664 64.0555 66.6664 60.8338V41.6668C66.6664 41.6668 66.6665 41.6668 66.6665 41.6668Z" fill="white"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M32.5 76.6678C37.5626 76.6678 41.6666 72.5638 41.6666 67.5011C41.6666 62.4385 37.5626 58.3345 32.5 58.3345C27.4374 58.3345 23.3333 62.4385 23.3333 67.5011C23.3333 72.5638 27.4374 76.6678 32.5 76.6678ZM32.5 71.6678C34.8012 71.6678 36.6666 69.8023 36.6666 67.5011C36.6666 65.2 34.8012 63.3345 32.5 63.3345C30.1988 63.3345 28.3333 65.2 28.3333 67.5011C28.3333 69.8023 30.1988 71.6678 32.5 71.6678Z" fill="#F7A600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M66.6668 41.6698C71.7294 41.6698 75.8335 37.5657 75.8335 32.5031C75.8335 27.4405 71.7294 23.3364 66.6668 23.3364C61.6042 23.3364 57.5002 27.4405 57.5002 32.5031C57.5002 37.5657 61.6042 41.6698 66.6668 41.6698ZM66.6668 36.6698C68.968 36.6698 70.8335 34.8043 70.8335 32.5031C70.8335 30.2019 68.968 28.3364 66.6668 28.3364C64.3656 28.3364 62.5002 30.2019 62.5002 32.5031C62.5002 34.8043 64.3656 36.6698 66.6668 36.6698Z" fill="#F7A600"></path>
            <defs>
                <linearGradient id="paint0_linear_1095_25970" x1="11.2501" y1="17.0831" x2="79.167" y2="77.9163" gradientUnits="userSpaceOnUse">
                    <stop offset="0.11722" stop-color="#F7A600"></stop>
                    <stop offset="1" stop-color="#F7A600" stop-opacity="0"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

const GetMultipleRewardsSvg = () => {
    return (
        <svg width="260" height="100" viewBox="0 0 260 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6">
                <circle cx="50" cy="50" r="50" fill="url(#paint0_linear_1095_25992)" fill-opacity="0.3"></circle>
                <circle cx="50" cy="50" r="49.5" stroke="#F7A600" stroke-opacity="0.15"></circle>
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M50.0003 31.2511C38.4944 31.2511 29.167 40.5785 29.167 52.0845C29.167 63.5904 38.4944 72.9178 50.0003 72.9178C61.5062 72.9178 70.8336 63.5904 70.8336 52.0845C70.8336 40.5785 61.5062 31.2511 50.0003 31.2511ZM25.0003 52.0845C25.0003 38.2774 36.1932 27.0845 50.0003 27.0845C63.8074 27.0845 75.0003 38.2774 75.0003 52.0845C75.0003 65.8916 63.8074 77.0845 50.0003 77.0845C36.1932 77.0845 25.0003 65.8916 25.0003 52.0845Z" fill="white"></path>
            <path d="M48.6018 39.9438C49.0268 38.5683 50.9738 38.5683 51.3989 39.9438L53.443 46.5586H60.2562C61.6564 46.5586 62.2575 48.3357 61.1448 49.1857L55.5707 53.444L57.6612 60.2091C58.082 61.5707 56.5066 62.6696 55.374 61.8045L50.0003 57.6993L44.6266 61.8045C43.4941 62.6696 41.9187 61.5707 42.3395 60.2091L44.43 53.444L38.8558 49.1857C37.7431 48.3357 38.3442 46.5586 39.7444 46.5586H46.5577L48.6018 39.9438Z" fill="#F7A600"></path>
            <path d="M72.917 18.7515L73.0855 19.7623C73.6111 22.9159 76.0319 25.4161 79.167 26.0431C76.0319 26.6701 73.6111 29.1703 73.0855 32.324L72.917 33.3348L72.7549 32.3682C72.2247 29.2058 69.8068 26.6938 66.667 26.0431C69.8021 25.4161 72.2229 22.9159 72.7485 19.7623L72.917 18.7515Z" fill="#F7A600"></path>
            <path d="M77.0836 33.3342L77.1052 33.5002C77.2423 34.5518 78.1082 35.357 79.1669 35.4175L79.0875 35.4322C78.0473 35.6248 77.243 36.455 77.0836 37.5008C76.9804 36.4717 76.2076 35.6355 75.1897 35.4517L75.0003 35.4175C76.0909 35.3624 76.9751 34.514 77.0751 33.4265L77.0836 33.3342Z" fill="white"></path>
            <g opacity="0.6">
                <circle cx="130" cy="50" r="50" fill="url(#paint1_linear_1095_25992)" fill-opacity="0.3"></circle>
                <circle cx="130" cy="50" r="49.5" stroke="#F7A600" stroke-opacity="0.15"></circle>
            </g>
            <path d="M111.25 64.194V68.0569C111.25 69.591 112.494 70.8346 114.028 70.8346H149.445C152.513 70.8346 155 68.3473 155 65.2791V44.4457C155 42.9116 153.757 41.668 152.223 41.668H147.952" stroke="white" stroke-width="4.16667"></path>
            <rect x="105" y="33.335" width="41.6667" height="29.1667" rx="2.77778" stroke="white" stroke-width="4.16667" stroke-linecap="round"></rect>
            <line x1="107.084" y1="43.7516" x2="144.584" y2="43.7516" stroke="#F7A600" stroke-width="4.16667"></line>
            <line x1="132.084" y1="54.1681" x2="138.334" y2="54.1681" stroke="#F7A600" stroke-width="4.16667" stroke-linecap="round"></line>
            <path d="M152.917 18.7515L153.086 19.7623C153.611 22.9159 156.032 25.4161 159.167 26.0431C156.032 26.6701 153.611 29.1703 153.086 32.324L152.917 33.3348L152.755 32.3682C152.225 29.2058 149.807 26.6938 146.667 26.0431C149.802 25.4161 152.223 22.9159 152.749 19.7623L152.917 18.7515Z" fill="#F7A600"></path>
            <path d="M157.084 33.3342L157.106 33.5002C157.243 34.5518 158.109 35.357 159.167 35.4175L159.088 35.4322C158.048 35.6248 157.243 36.455 157.084 37.5008C156.981 36.4717 156.208 35.6355 155.19 35.4517L155.001 35.4175C156.091 35.3624 156.975 34.514 157.075 33.4265L157.084 33.3342Z" fill="white"></path>
            <g opacity="0.6">
                <circle cx="210" cy="50" r="50" fill="url(#paint2_linear_1095_25992)" fill-opacity="0.3"></circle>
                <circle cx="210" cy="50" r="49.5" stroke="#F7A600" stroke-opacity="0.15"></circle>
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M192.88 34.765C196.634 30.5456 201.94 27.7 208.006 27.1703C220.615 26.0695 231.729 35.3983 232.83 48.0069C232.93 49.1531 233.94 50.0012 235.086 49.9011C236.232 49.801 237.08 48.7907 236.98 47.6444C235.679 32.7434 222.545 21.7184 207.644 23.0194C200.394 23.6524 194.061 27.0885 189.621 32.1616L189.328 28.8015C189.228 27.6552 188.217 26.8072 187.071 26.9072C185.925 27.0073 185.077 28.0176 185.177 29.1639L185.902 37.4656C186.002 38.6119 187.012 39.4599 188.158 39.3599L196.46 38.635C197.606 38.535 198.454 37.5246 198.354 36.3784C198.254 35.2322 197.244 34.3841 196.098 34.4842L192.88 34.765Z" fill="#F7A600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M187.17 51.9933C187.07 50.8471 186.06 49.999 184.914 50.0991C183.767 50.1991 182.919 51.2095 183.019 52.3557C184.32 67.2567 197.455 78.2817 212.356 76.9807C219.606 76.3477 225.939 72.9118 230.379 67.8389L230.672 71.1991C230.772 72.3453 231.782 73.1934 232.929 73.0933C234.075 72.9932 234.923 71.9829 234.823 70.8367L234.098 62.5349C233.998 61.3887 232.988 60.5406 231.841 60.6407L223.54 61.3655C222.393 61.4656 221.545 62.4759 221.645 63.6222C221.745 64.7684 222.756 65.6165 223.902 65.5164L227.119 65.2355C223.366 69.4547 218.06 72.3002 211.993 72.8298C199.385 73.9307 188.271 64.6019 187.17 51.9933Z" fill="white"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M202.709 43.7515C204.435 43.7515 205.834 42.3524 205.834 40.6265C205.834 38.9006 204.435 37.5015 202.709 37.5015C200.983 37.5015 199.584 38.9006 199.584 40.6265C199.584 42.3524 200.983 43.7515 202.709 43.7515ZM215.209 47.9181C210.031 47.9181 205.834 52.1155 205.834 57.2931V58.3348C205.834 59.4854 206.766 60.4181 207.917 60.4181H222.5C223.651 60.4181 224.584 59.4854 224.584 58.3348V57.2931C224.584 52.1155 220.386 47.9181 215.209 47.9181ZM215.209 52.0848C217.728 52.0848 219.83 53.8741 220.313 56.2515H210.105C210.587 53.8741 212.689 52.0848 215.209 52.0848ZM218.334 42.7098C218.334 44.4357 216.935 45.8348 215.209 45.8348C213.483 45.8348 212.084 44.4357 212.084 42.7098C212.084 40.9839 213.483 39.5848 215.209 39.5848C216.935 39.5848 218.334 40.9839 218.334 42.7098ZM193.334 56.2517C193.334 50.4987 197.997 45.835 203.75 45.835C204.901 45.835 205.834 46.7678 205.834 47.9184C205.834 49.0689 204.901 50.0017 203.75 50.0017C201.029 50.0017 198.714 51.7409 197.856 54.1684H201.666H201.667C202.818 54.1684 203.75 55.1011 203.75 56.2517C203.75 57.4023 202.818 58.335 201.667 58.335H195.417C194.266 58.335 193.334 57.4023 193.334 56.2517Z" fill="white"></path>
            <defs>
                <linearGradient id="paint0_linear_1095_25992" x1="11.2501" y1="17.0831" x2="79.167" y2="77.9163" gradientUnits="userSpaceOnUse">
                    <stop offset="0.11722" stop-color="#F7A600"></stop>
                    <stop offset="1" stop-color="#F7A600" stop-opacity="0"></stop>
                </linearGradient>
                <linearGradient id="paint1_linear_1095_25992" x1="91.2501" y1="17.0831" x2="159.167" y2="77.9163" gradientUnits="userSpaceOnUse">
                    <stop offset="0.11722" stop-color="#F7A600"></stop>
                    <stop offset="1" stop-color="#F7A600" stop-opacity="0"></stop>
                </linearGradient>
                <linearGradient id="paint2_linear_1095_25992" x1="171.25" y1="17.0831" x2="239.167" y2="77.9163" gradientUnits="userSpaceOnUse">
                    <stop offset="0.11722" stop-color="#F7A600"></stop>
                    <stop offset="1" stop-color="#F7A600" stop-opacity="0"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

const ForwardIconSvg = () => {
    return (
        <div className='hidden md:block'>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M20.6911 39.7695L40 20L20.6911 0.230469V7.69708H0V32.3371H20.6911V39.7695Z" fill="url(#paint0_linear_1527_17833)"></path>
                <defs>
                <linearGradient id="paint0_linear_1527_17833" x1="45.4623" y1="16.4439" x2="2.3297" y2="16.5241" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F7A600"></stop>
                    <stop offset="1" stop-color="#F7A600" stop-opacity="0"></stop>
                </linearGradient>
                </defs>
            </svg>
          </div>
    );
}

const DownwardIconSvg = () => {
    return (
        <div className='block md:hidden'>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.230469 20.6911L20 40L39.7695 20.6911H32.3029V0H7.66288V20.6911H0.230469Z" fill="url(#paint0_linear_1527_17833)"></path>
                <defs>
                    <linearGradient id="paint0_linear_1527_17833" x1="2.3297" y1="45.4623" x2="16.5241" y2="2.3297" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#F7A600"></stop>
                        <stop offset="1" stop-color="#F7A600" stop-opacity="0"></stop>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}

export { ForwardIconSvg, ConnectFriendsSvg, GetMultipleRewardsSvg, ShareCodeSVG, DownwardIconSvg };