import React from "react";
import { Modal } from "antd";
import chathelp from "assets/img/chat-help.svg";

const P2pNeedHelp = ({ visible, onClose }) => {
    const problemList = [
        "Order Cancel but Paid",
        "Report Counterparty",
        "Revert Completion Rate",
        "Others",
    ];

    return (
        <Modal
            title="Need Help"
            visible={visible}
            onCancel={onClose}
            footer={null} // Removes default footer with OK/Cancel buttons
        >
            {/* Chat Section */}
            <div className="bg-gray-100 rounded-xl p-4 mb-4">
                <div className="flex">
                    <img src={chathelp} className="w-20 h-20" alt="Chat" />
                    <div className="ml-4">
                        <p className="text-[12px] font-semibold">
                            Negotiate with the counterparty
                        </p>
                        <p className="text-[12px] text-[#707A8A]">
                            You can upload proof of payment and account info in
                            the chatbox to help both sides verify the payment.
                        </p>
                        <button className="text-[12px] bg-[#EFB81C] rounded-lg px-4 py-1 mt-2">
                            Chat Now
                        </button>
                    </div>
                </div>
            </div>

            {/* Problem List Section */}
            <div>
                <p className="font-semibold py-2 text-sm">Problem List</p>
                {problemList.map((problem, index) => (
                    <div className="flex items-center mb-4" key={index}>
                        <input
                            id={`radio-${index}`}
                            type="radio"
                            name="problem-radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                        />
                        <label
                            htmlFor={`radio-${index}`}
                            className="ml-2 text-sm font-medium text-gray-900"
                        >
                            {problem}
                        </label>
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export default P2pNeedHelp;
