import React from "react";

import { BsDatabaseExclamation } from "react-icons/bs";

const CopyTradesTable = () => {
    return (
        <table className="w-full order-list-body__table">
            <thead className="w-full">
                <tr className="flex justify-between w-full">
                    <th className="text-left text-[12px] font-normal text-[#15141580] dark:text-[#71777A]  px-1 py-2.5">
                        N/A
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580] dark:text-[#71777A]  px-1 py-2.5">
                        N/A
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580] dark:text-[#71777A]  px-1 py-2.5">
                        N/A
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580] dark:text-[#71777A]  px-1 py-2.5">
                        N/A
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580] dark:text-[#71777A]  px-1 py-2.5">
                        N/A
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580]  dark:text-[#71777A] px-1 py-2.5">
                        N/A
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580]  dark:text-[#71777A] px-1 py-2.5">
                        N/A
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580] dark:text-[#71777A]  px-1 py-2.5">
                        N/A
                    </th>
                </tr>
            </thead>

            <tbody className="flex flex-col w-full gap-2 h-[40lvh] overflow-y-auto">
                <div className="flex flex-col items-center justify-center w-full h-full gap-2">
                    <BsDatabaseExclamation size={28} fill="#EFB81C50" />
                    <p className="text-[12px] text-[#15141580] dark:text-[#71777A]  font-[500] text-center w-max">
                        No copy trades found.
                    </p>
                </div>
            </tbody>
        </table>
    );
};

export default CopyTradesTable;
