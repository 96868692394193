// src/redux/reducers/transferReducer.js
import {
    DO_TRANSFER_REQUEST,
    DO_TRANSFER_SUCCESS,
    DO_TRANSFER_FAILURE,
  } from '../types';
  
  const initialState = {
    loading: false,
    successMessage: '', // Store success message from the transfer
    error: null,        // Store error message if any
  };
  
  const transferReducer = (state = initialState, action) => {
    switch (action.type) {
      case DO_TRANSFER_REQUEST:
        return { ...state, loading: true, error: null, successMessage: '' };
  
      case DO_TRANSFER_SUCCESS:
        return {
          ...state,
          loading: false,
          successMessage: action.payload, // Store the success message
          error: null,
        };
  
      case DO_TRANSFER_FAILURE:
        return { ...state, loading: false, error: action.payload, successMessage: '' };
  
      default:
        return state;
    }
  };
  
  export default transferReducer;
  