import FiatListRepository from "../../repository/FiatListRepository";
import {
    FETCH_FIAT_CURRENCY_LIST_SUCCESS,
    FETCH_FIAT_CURRENCY_LIST_FAILURE,
} from "../types";

export const fetchFiatDropdownList = (userId, token) => async (dispatch) => {
    try {
        const response = await FiatListRepository.getCurrencyFiatList(
            userId,
            token
        );

        const dropdownValue = response.data.data.fiatcoins;
        if (response.data.status === 1) {
            dispatch({
                type: FETCH_FIAT_CURRENCY_LIST_SUCCESS,
                payload: dropdownValue,
            });
        } else {
            dispatch({ type: FETCH_FIAT_CURRENCY_LIST_FAILURE, payload: [] });
        }
    } catch (error) {
        dispatch({ type: FETCH_FIAT_CURRENCY_LIST_FAILURE, payload: [] });
    }
};
