import React from 'react'
import  imag1 from 'assets/img/user-protection/kyc.png';
import  imag2 from 'assets/img/user-protection/aml.png';
import  imag3 from 'assets/img/user-protection/law.png';
import  imag4 from 'assets/img/user-protection/reponsible-trading.png';

function ComplianceInitiatives() {


    const securityData = [
        {
            id: 1 ,
            title : "Safe Sign In", 
            img :  imag1,
            
        },
        {
            id: 2 ,
            title : "Access Control", 
            img :  imag2,
        },
        {
            id: 3 ,
            title : "Security Notifications", 
            img :  imag3,
        },
        {
            id: 4,
            title : "Security Notifications", 
            img :  imag4,
        },
        
    ]


  return (
    <section className='mt-5'>
    <div className="container">
      <div>
        <h2 className="text-2xl font-bold my-4">Compliance Initiatives</h2>
        <div className="">

        <ul className="grid md:grid-cols-3 grid-cols-2 gap-5">

        {
  securityData.map((data) => (   
<li className="flex items-center gap-3" key={data.id}>
<img
  className="w-10 h-10 object-contain "
  src= {data.img}
  alt="img"
/>
<span className='font-semibold text2xl'> {data.title}</span>
</li> 
  ))
} 



         
          
         
          </ul>
        </div>
      </div>
    </div>
  </section>
  )
}

export default ComplianceInitiatives
