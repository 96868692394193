import React from "react";
import {
    BsHeadset,
    BsPeopleFill,
    BsQuestionCircleFill,
} from "react-icons/bs";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "./CustomerSupport.scss";

const CustomerSupport = () => {
    return (
        <section className="customer-support-section py-10">
            <div className="container">
                <div className="inner-wrapper flex flex-col gap-8">
                    <div className="header">
                        <h4 className="text-[34px] font-bold">
                            We're Here for You!
                        </h4>
                        <p className="max-w-[50%] text-[#15141590]">
                            Need a hand? Our friendly customer care team is
                            ready to answer your questions and help you get the
                            most out of BUYCEX Service/Product. Browse our
                            comprehensive FAQs for quick answers, or join our
                            active community forum to connect with other users.
                        </p>
                    </div>

                    <div className="body flex justify-between">
                        <div className="body_card-wrapper flex flex-col gap-4 max-w-[32%] border rounded-md p-4">
                            <div className="flex flex-col gap-4">
                                <BsHeadset size={30} />
                                <span className="text-[24px] font-bold">
                                    Customer Support
                                </span>
                            </div>
                            <p className="text-[16px] text-[#15141590]">
                                Our dedicated customer care team is available
                                24/7 to answer your questions and ensure you
                                have a smooth experience with BUYCEX. Whether
                                you need quick troubleshooting or in-depth
                                guidance, we're here to help you get the most
                                out of our service/product.
                            </p>
                            <Link
                                to="#!"
                                className="mt-auto w-max text-[#EFB81C]"
                            >
                                <span className="text-[16px] hover:underline font-[500]">
                                    Chat with us
                                </span>
                            </Link>
                        </div>
                        <div className="body_card-wrapper flex flex-col gap-4 max-w-[32%] border rounded-md p-4">
                            <div className="flex flex-col gap-4">
                                <BsQuestionCircleFill size={30} />
                                <span className="text-[24px] font-bold">
                                    FAQs
                                </span>
                            </div>
                            <p className="text-[16px] text-[#15141590]">
                                Dive deep! Find detailed instructions and
                                solutions to common questions about using
                                BUYCEX.
                            </p>
                            <Link
                                to="#!"
                                className="mt-auto w-max text-[#EFB81C]"
                            >
                                <span className="text-[16px] hover:underline font-[500]">
                                    Browse FAQs
                                </span>
                            </Link>
                        </div>
                        <div className="body_card-wrapper flex flex-col gap-4 max-w-[32%] border rounded-md p-4">
                            <div className="flex flex-col gap-4">
                                <BsPeopleFill size={30} />
                                <span className="text-[24px] font-bold">
                                    Join our community
                                </span>
                            </div>
                            <p className="text-[16px] text-[#15141590]">
                                Empower your BUYCEX experience by joining our
                                thriving community! Share insights, ask
                                questions, and connect with fellow users from
                                around the world.
                            </p>
                            <Link
                                to="#!"
                                className="mt-auto w-max text-[#EFB81C]"
                            >
                                <span className="text-[16px] hover:underline font-[500]">
                                    Join our community
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CustomerSupport;
