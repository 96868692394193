import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaChevronRight } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa";

const HelpCenter = () => {
    const [openMenu, setOpenMenu] = useState({});
    const [openRightMenu, setOpenRightMenu] = useState({});
    // toggleMenu1
    const toggleMenu = (index) => {
        setOpenMenu((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };
    // toggleMenu2
    const toggleRightMenu = (index) => {
        setOpenRightMenu((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };
    return (
        <>
            <main className="w-full h-full my-20 px-1">
                {/* top search section */}
                <div className="w-full h-full flex flex-col lg:flex-row-reverse  justify-between items-start">
                    {/* search bar*/}
                    <div className="flex items-center h-full w-full rounded-md group justify-between ">
                        <div className="flex items-center gap-3 w-full  px-4 py-3 rounded-md group-hover:border-[#EFB81C] group-hover:border justify-between bg-[#f5f7fa] dark:bg-white">
                            <FaSearch className="text-[#81858c]   dark:text-white text-xl font-extrabold " />
                            <input
                                type="text"
                                placeholder="Ask Us Anything"
                                className="text-[16px]  outline-none border-none w-full bg-transparent font-medium leading-6 appearance-none placeholder:text-[#ADB1B8] text-black dark:text-white"
                            />
                        </div>
                    </div>
                    <div className="flex justify-start items-center mt-3 gap-3 w-full">
                        <button className="hover:text-[#EFB81C] text-[12px] md:text-[14px]  text-[#81858c] dark:text-white font-semibold  rounded-md  transition-colors duration-500 ease-in-out">
                            Help Center
                        </button>
                        <FaChevronRight className="text-[#ADB1B8] text-[12px] " />

                        <button className="text-[14px]  text-black dark:text-white font-semibold  rounded-md text-center transition-colors duration-500 ease-in-out">
                            Topics
                        </button>
                    </div>
                </div>
                {/* lists */}
                <section className="w-full h-full flex mt-4 justify-between items-start">
                    {/* left list */}
                    <ul className="rounded-2xl border flex-col w-[25%] p-4 lg:flex hidden justify-between items-center">
                        <h3 className="text-black dark:text-white text-2xl w-full text-start font-bold">
                            Topics
                        </h3>
                        {/* account management */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                text-black dark:text-white
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Account Management
                                {openMenu[1] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(1)} // Passing index 1
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(1)} // Passing index 1
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[1] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Registration & Login
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Account Settings
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Submit a Request
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Subaccount
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Account Deactivation
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Data Export
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* KYC and Security Matters */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                KYC and Security Matters
                                {openMenu[2] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(2)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(2)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[2] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Identity Verification
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Google Authenticator
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Email & SMS
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Proof of Reserves
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Additional Safety Features
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Platform Walkthrough */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Platform Walkthrough
                                {openMenu[3] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(3)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(3)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[3] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Getting Started
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Order Types Overview
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Asset Page navigation
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        App Version
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Optimization and User Experience
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Crypto Deposits/Withdrawals */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Crypto Deposits/Withdrawals
                                {openMenu[4] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(4)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(4)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[4] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        On-chain Crypto Deposits
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        On-chain Crypto Withdrawals
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Crypto Withdrawals via Internal Transfer
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Crypto Deposits and Withdrawals Useful
                                        Tips
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Buy Crypto */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Buy Crypto
                                {openMenu[5] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(5)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(5)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[5] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Overview
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Auto-Invest
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Bank Card Payment
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Third Party Payment Channel
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* P2P Trading */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                P2P Trading
                                {openMenu[6] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(6)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(6)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[6] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Get Started With P2P Trading
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        P2P Trading Tips
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        P2P Order Appeals
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        P2P Advertisers Guides
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        P2P HotSwap
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        P2P Safety Information
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Fiat Deposits/Withdrawals */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Fiat Deposits/Withdrawals
                                {openMenu[7] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(7)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(7)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[7] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Fiat Deposits
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Fiat Withdrawals
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Spot X */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Spot X
                                {openMenu[8] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(8)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(8)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[8] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Launchpad
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Launchpool
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        ByStarter
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        ByVotes
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Token Splash
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Airdrop Hunt
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Standard Account */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Standard Account
                                {openMenu[9] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(9)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(9)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[9] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Standard Account Overview
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Spot Trading
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Margin Trading
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Leveraged Token
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Trading Tools
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Derivatives Trading Mechanism
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Derivatives Risk Management
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        USDT Perpetual Contract
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Inverse Perpetual Contract
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Inverse Futures Contract
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Other Common Derivatives FAQ
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Unified Trading Account */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Unified Trading Account
                                {openMenu[10] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(10)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(10)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[10] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Unified Trading Account Overview
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Unified Trading Risk Management
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Spot Trading
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Leveraged Token
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Spot Margin Trading
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Derivatives Trading Mechanism
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        USDT Perpetual
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        USDC Perpetual and Futures
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        USDC Options
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Derivatives Trading Tools
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Pre-Market Trading
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Copy Trading */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Copy Trading
                                {openMenu[11] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(11)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(11)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[11] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Overview
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Master Traders
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Followers
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Copy Trading Pro*/}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Copy Trading Pro
                                {openMenu[12] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(12)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(12)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[12] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Overview
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Trading Bot */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Trading Bot
                                {openMenu[13] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(13)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(13)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[13] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Overview
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Spot Grid Bot
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Futures Grid Bot
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        DCA Bot
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Futures Martingale Bot
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Futures Combo Bot
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Bybit Earn */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Bybit Earn
                                {openMenu[14] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(14)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(14)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[14] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Overview
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Smart Leverage
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Bybit Savings
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Dual Asset
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Liquidity Mining
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        ETH 2.0 Staking
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Wealth Management
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Double Win
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Discount Buy
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Bybit Card */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Bybit Card
                                {openMenu[15] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(15)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(15)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[15] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Get Started with Bybit Card (EEA & CH
                                        Users)
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Get Started with Bybit Card (Australian
                                        Users)
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Bybit Card Management
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Bybit Card Transactions
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Bybit Card Rewards
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Bybit Card Additional Features
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Finance */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Finance
                                {openMenu[16] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(16)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(16)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[16] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Crypto Loan
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Institutional Loan
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Promotions */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Promotions
                                {openMenu[17] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(17)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(17)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[17] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Rewards Hub
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        VIP Program
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Referral Program
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Affiliate Program
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Market Maker Incentive Program
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Giveaway
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Leaderboard
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* API */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                API
                                {openMenu[18] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(18)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(18)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[18] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        API Guide
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        API Broker Program
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Terms of Service */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Terms of Service
                                {openMenu[19] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(19)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(19)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[19] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Terms of Service
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* MT4 */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                MT4
                                {openMenu[20] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(20)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(20)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[20] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        MT4 Agreement
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Opening a Bybit MT4 Account
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Common MT4 Platform Queries
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Login and Account Management
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        MT4 Trading Related FAQs
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        MT4 Terminal Guide
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Expert Advisors (EAs)
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        MT4 Bonus Program
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        MT4 Historical Data
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        MT4 Contract Specifications
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Buycex Web3 */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-between items-center"
                            >
                                Buycex Web3
                                {openMenu[21] ? (
                                    <FaChevronUp
                                        onClick={() => toggleMenu(21)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleMenu(21)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openMenu[21] && (
                                <div className="links px-4 flex flex-col justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Web3 Overview
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Web3 Wallet
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Web3 Marketplace
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Web3 Earn
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Web3 Trade
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Web3 Buy Crypto
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        NFT
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        ApeX Pro
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        TradeGPT Bot
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm dark:text-[#F4F4F4] text-[#151415] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Web3 Terms and Conditions
                                    </Link>
                                </div>
                            )}
                        </li>
                    </ul>
                    {/* right list */}
                    <ul className=" w-[75%] px-4 flex flex-col justify-between items-start">
                        <h3 className="text-black dark:text-white text-2xl w-full text-start font-semibold">
                            Account Management
                        </h3>
                        {/* Registration & Login */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-start gap-2 items-center"
                            >
                                Registration & Login
                                {openRightMenu[1] ? (
                                    <FaChevronUp
                                        onClick={() => toggleRightMenu(1)} // Passing index 1
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleRightMenu(1)} // Passing index 1
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openRightMenu[1] && (
                                <div className="links px-4 flex flex-col lg:grid lg:grid-cols-2 justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm lg:text-base  text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        How to Register an Account
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm lg:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        How to Log In Using QR Code on Bybit
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm lg:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        How to Reset Your Password
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Account Settings */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-start gap-2 items-center"
                            >
                                Account Settings
                                {openRightMenu[2] ? (
                                    <FaChevronUp
                                        onClick={() => toggleRightMenu(2)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleRightMenu(2)} // Passing index 2
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openRightMenu[2] && (
                                <div className="links px-4 flex flex-col lg:grid lg:grid-cols-2 justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        How to Check Your Account UID
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        How to Update Your Nickname, Profile
                                        Picture and Platform Display
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Self-Service Features: Updating your
                                        Email, Mobile Number, and Google
                                        Authenticator on Bycex
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        FAQ — Account Settings
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        How to Manage Your Trade Settings
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Subaccount */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-start gap-2 items-center"
                            >
                                Subaccount
                                {openRightMenu[3] ? (
                                    <FaChevronUp
                                        onClick={() => toggleRightMenu(3)} // Passing index 3
                                        className="text-sm md:text-base transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleRightMenu(3)} // Passing index 3
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openRightMenu[3] && (
                                <div className="links px-4 flex flex-col lg:grid lg:grid-cols-2 justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        How to Get Started With a Standard
                                        Subaccount
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        FAQ — Standard Subaccount
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Introduction to Custodial Trading
                                        Subaccount
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        FAQ — Custodial Trading Subaccount
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Account Deactivation */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-start gap-2 items-center"
                            >
                                Account Deactivation
                                {openRightMenu[4] ? (
                                    <FaChevronUp
                                        onClick={() => toggleRightMenu(4)} // Passing index 4
                                        className="text-sm md:text-base transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleRightMenu(4)} // Passing index 4
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openRightMenu[4] && (
                                <div className="links px-4 flex flex-col lg:grid lg:grid-cols-2 justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        How to Deactivate Your Account
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        FAQ — Account Deactivation
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        How to Reactivate Your Account
                                    </Link>
                                </div>
                            )}
                        </li>
                        {/* Data Export */}
                        <li className="item-one flex flex-col justify-between items-start w-full">
                            <div
                                className="text-base font-semibold 
                                dark:text-[#F4F4F4] text-[#151415]
                                hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer w-full mb-2 mt-4 flex justify-start gap-2 items-center"
                            >
                                Data Export
                                {openRightMenu[5] ? (
                                    <FaChevronUp
                                        onClick={() => toggleRightMenu(5)} // Passing index 5
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                ) : (
                                    <FaChevronDown
                                        onClick={() => toggleRightMenu(5)} // Passing index 5
                                        className="text-sm transition-all ease-in-out duration-300"
                                    />
                                )}
                            </div>
                            {/* links */}
                            {openRightMenu[5] && (
                                <div className="links px-4 flex flex-col lg:grid lg:grid-cols-2 justify-between items-start w-full">
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        How to Self-Export My Account Data
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        How to Extract The Content From Your
                                        Data Export File
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        Differences between Trade History and
                                        Order History
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        How to Use Tax API service on Bybit
                                    </Link>
                                    <Link
                                        to="/"
                                        className="mt-3 hover:no-underline text-sm md:text-base text-[#d6850d] hover:text-amber-400 hover:opacity-60 ease-in-out transition-opacity duration-300 cursor-pointer"
                                        rel="noopener noreferrer"
                                        title="Submit a Request"
                                    >
                                        FAQ — Tax API
                                    </Link>
                                </div>
                            )}
                        </li>
                    </ul>
                </section>
            </main>
        </>
    );
};

export default HelpCenter;
