import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { verifyPhone, resendPhoneOtp } from "store/actions/authAction";
import toast from "react-hot-toast";
import ModalVerification from "./phoneVerificationHelp"; // ensure correct import name

function PhoneVerification({ onSuccess = null }) {
    const { loading } = useSelector((state) => state.auth);
    const { sid } = useSelector((state) => state.authUser);
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState(Array(6).fill(""));
    const [showModal, setShowModal] = useState(false);
    const [timer, setTimer] = useState({ minutes: 0, seconds: 59 });
    const [isCountdownComplete, setIsCountdownComplete] = useState(false); // Define this
    const history = useHistory();
    const dispatch = useDispatch();
    const inputRefs = useRef([]);

    useEffect(() => {
        const storedPhone = localStorage.getItem("phone");
        const storedPassword = localStorage.getItem("password");
        if (storedPhone) setPhone(storedPhone);
        if (storedPassword) setPassword(storedPassword);

        // console.log("Stored:", storedPhone , storedPassword);
    }, []);

    const resendCode = async () => {
        const payload = { sid };

        if (!sid) {
            toast.error("Phone is required to resend OTP.");
            return;
        }

        try {
            await dispatch(resendPhoneOtp(payload));
            setTimer({ minutes: 0, seconds: 59 });
            setIsCountdownComplete(false);
        } catch (error) {
            toast.error("Failed to resend OTP");
            console.error("Error resending phone OTP:", error);
        }
    };

    useEffect(() => {
        const countdown = setInterval(() => {
            if (timer.seconds === 0) {
                if (timer.minutes === 0) {
                    setIsCountdownComplete(true);
                    clearInterval(countdown);
                } else {
                    setTimer({ minutes: timer.minutes - 1, seconds: 59 });
                }
            } else {
                setTimer({
                    minutes: timer.minutes,
                    seconds: timer.seconds - 1,
                });
            }
        }, 1000);

        return () => clearInterval(countdown);
    }, [timer]);

    const handleResendCode = () => {
        if (isCountdownComplete) {
            resendCode();
        } else {
            toast.error(
                "Please wait until the timer finishes before resending the code."
            );
        }
    };

    const handleOtpChange = (e, index) => {
        const { value } = e.target;
        const newOtp = [...otp];
        newOtp[index] = value.slice(-1);
        setOtp(newOtp);

        if (e.key === "Backspace" && index > 0) {
            inputRefs.current[index - 1].focus();
        } else if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && index > 0 && otp[index] === "") {
            inputRefs.current[index - 1].focus();
            const newOtp = [...otp];
            newOtp[index - 1] = "";
            setOtp(newOtp);
        }
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData("text");
        if (paste.length === 6 && /^\d+$/.test(paste)) {
            const newOtp = paste.split("");
            setOtp(newOtp);
            inputRefs.current.forEach((input, i) => {
                input.value = newOtp[i];
            });
        }
    };

    const verifyOtp = async (e) => {
        e.preventDefault();
        const otpString = otp.join("");
        const payload = {
            sid,
            code: parseInt(otpString),
        };

        console.log(
            "payload in register page when verifying phone with otp: ",
            payload
        );

        try {
            await dispatch(
                verifyPhone(payload, () => {
                    if (onSuccess) {
                        onSuccess();
                    } else {
                        history.push("/login");
                    }
                    console.log("Phone verified successfully, Please login");
                })
            );
        } catch (error) {
            toast.error("Failed to verify phone");
            console.error("Error verifying phone:", error);
        }
    };

    return (
        <div className="flex flex-col gap-4 my-auto">
            <form onSubmit={(e) => verifyOtp(e)}>
                <div className="flex flex-col items-center justify-center gap-4">
                    <div>
                        <h4>Verify Your Phone</h4>
                        <p>
                            Please enter 6 digits verification code that was
                            sent to {phone}. The code is valid for 30 minutes.
                        </p>
                    </div>
                    <div className="flex gap-2" onPaste={handlePaste}>
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                ref={(el) => (inputRefs.current[index] = el)}
                                className="border border-slate-400 w-[50px] h-[50px] text-center rounded-md bg-transparent"
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleOtpChange(e, index)}
                                onKeyDown={(e) => handleBackspace(e, index)}
                                required
                            />
                        ))}
                    </div>
                    <button type="submit" className="border btn">
                        Verify
                    </button>
                    <div
                        className="flex gap-2 text-[#F4F4F4]"
                        onClick={handleResendCode}
                    >
                        <span className="text-[#F1BA19] cursor-pointer">
                            Send code again
                        </span>
                        <span className="text-[#918f8f]">
                            {isCountdownComplete
                                ? ""
                                : ` ${timer.minutes
                                      .toString()
                                      .padStart(2, "0")}:${timer.seconds
                                      .toString()
                                      .padStart(2, "0")}`}
                        </span>
                    </div>
                </div>
            </form>
            {/* <div>
                <div onClick={() => setShowModal(true)} className="text-[#F1BA19] hover:underline" style={{ cursor: "pointer" }}>
                    Didn't receive code?
                </div>
            </div> */}
            <ModalVerification
                visible={showModal}
                onClose={() => setShowModal(false)}
            />
        </div>
    );
}

export default PhoneVerification;
