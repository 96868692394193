import Repository from "./Repository";

const WITHDRAW = "/Crypto/doWithdraw";

export default {
    async doWithdraw(payload, token,userId) {
        console.log("Withdraw Payload:", payload);

        // Create FormData object
        const formData = new FormData();
        formData.append("coin", payload.coin);
        formData.append("num", payload.num);
        formData.append("addr", payload.addr);
        formData.append("ga", payload.ga);
       // formData.append("verify", payload.verify);

        try {
            const response = await Repository.post(WITHDRAW, formData, {
                headers: {
                    "TOKEN": token,
                    "ID": userId,
                    "Content-Type": "multipart/form-data",
                },
            });

            console.log("Withdraw Response:", response);
            return response;
        } catch (error) {
            console.error("Error during withdrawal:", error.message);
            throw error;
        }
    },
};
