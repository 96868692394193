import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from 'react-router-dom';

const SingleTable = ({ currentItems }) => {
    const [activeRowIndex, setActiveRowIndex] = useState(null);
    const history = useHistory();

    const handleBuyNowBtn = (coin, id, value, imgPath) => {
        history.push({
            pathname: `/earn/home/${coin}`,
            state: {
                coin: coin,
                id: id,
                value: value,
                imgPath: imgPath,
            }
        });
    };

    const toggleDetail = (index) => {
        if (activeRowIndex === index) {
            setActiveRowIndex(null);
        } else {
            setActiveRowIndex(index);
        }
    };

    // Check if currentItems is empty or contains null values
    const isEmpty = !currentItems || currentItems.length === 0 || currentItems.every(item => 
        !item.docid && !item.amount && !item.percentage && !item.period && 
        !item.minvest && !item.begintime && !item.endtime && !item.credited
    );

    return (
        <>
            {isEmpty ? (
                <div className="text-center p-4 text-gray-500">
                    No data available.
                </div>
            ) : (
                currentItems.map((item, index) => {
                    const values = Object.values(item.percentage).map(Number);
                    const minValue = Math.min(...values);
                    const maxValue = Math.max(...values);
                    return (
                        <div key={index}>
                            <div
                                className={`p-3 px-4 min-h-[56px] py-4 border-b-[1px] border-solid border-[#f3f5f7] ${
                                    activeRowIndex === index ? "bg-[#F5F7FA66]" : ""
                                }`}
                            >
                                <div
                                    onClick={() => toggleDetail(index)}
                                    className="h-[56px] flex items-center justify-between gap-4 cursor-pointer"
                                >
                                    <div className="flex-1 flex items-center gap-[10px]">
                                        <p className="text-[16px] text-[#121214] font-medium">
                                            {item.docid}
                                        </p>
                                    </div>
                                    <div className="flex-1 flex items-center text-[16px] text-[#20b26c] font-medium">
                                        {item.amount} SAR
                                    </div>
                                    <div className="flex-1 flex items-center text-[16px] text-[#121214] font-medium">
                                        {item.percentage} %
                                    </div>
                                    <div className="flex-1 flex items-center text-[16px] text-[#121214] font-medium">
                                        {item.period} Days
                                    </div>
                                    <div className="flex-1 flex items-center text-[16px] text-[#121214] font-medium">
                                        {item.minvest}
                                    </div>
                                    <div className="flex-1 flex items-center text-[16px] text-[#121214] font-medium">
                                        {item.begintime} - {item.endtime}
                                    </div>
                                    <div className="flex-1 flex items-center text-[16px] text-[#121214] font-medium">
                                        {item.credited}
                                    </div>
                                    <div className="flex-1 items-center flex text-center justify-end">
                                        <div className={`${activeRowIndex === index ? "rotate-180" : ""}`}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="8"
                                                viewBox="0 0 14 8"
                                            >
                                                <path
                                                    fill="#121214"
                                                    d="M.852.602a.917.917 0 0 1 1.296 0L7 5.454 11.852.602a.917.917 0 0 1 1.296 1.296l-5.5 5.5a.917.917 0 0 1-1.296 0l-5.5-5.5a.917.917 0 0 1 0-1.296Z"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                {/* {activeRowIndex === index && (
                                    <div>
                                        {values.map((value, valueIndex) => (
                                            <div key={valueIndex} className="border-t-[1px] border-solid border-[#f3f5f7] h-[64px] flex items-center justify-between gap-4 cursor-pointer">
                                                <div className="flex-1 flex items-center gap-[10px]">
                                                    <div className="w-[28px] h-[28px]"></div>
                                                    <p className="text-[16px] text-[#121214] font-medium">
                                                        {item.coinname.toUpperCase()}
                                                    </p>
                                                </div>
                                                <div className="flex-1 flex items-center text-[16px] text-[#20b26c] font-medium">
                                                    {value}%  {/* Display the value here */}
                                                {/* </div>
                                                <div className="flex-1 flex items-center text-[16px] text-[#121214] font-medium">
                                                    {valueIndex % 2 === 0 ? "Flexible" : "Fixed"}
                                                </div>
                                                <div className="flex-1 flex items-center">
                                                    <button className="text-[#20b26c] font-medium text-[12px] rounded-[4px] bg-green-100 px-2 py-[2px]">
                                                        Steady
                                                    </button>
                                                </div>
                                                <div className="flex-1 items-center flex text-center justify-end">
                                                    <button type="button" onClick={() => handleBuyNowBtn(item.coinname, item.id, value, `https://api.buycex.com/Upload/coin/${item.img}`)} className="bg-[#ffb11a] rounded-[8px] text-[12px] w-[100px] h-[32px] text-center text-[#000] font-medium flex items-center justify-center ">
                                                        Buy Now
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )} */} 
                            </div>
                        </div>
                    );
                })
            )}
        </>
    );
};

export default SingleTable;
