import React from "react";

const HowToJoin = () => {
  return (
    <div className="container mx-auto py-10 px-5">
      <h2 className="text-3xl font-semibold text-center mb-10">How to join?</h2>
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-8 md:space-y-0 md:space-x-8">
        
        {/* Step 1 */}
        <div className="flex flex-col items-center text-center max-w-xs">
          <div className="mb-4">
            {/* Icon for step 1 */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-12 h-12 text-black"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 7.5l-.496 4.469a2.25 2.25 0 01-1.856 1.981l-3.37.579a2.25 2.25 0 01-2.673-2.03l-.282-3.538a2.25 2.25 0 012.03-2.673l3.37-.579a2.25 2.25 0 012.673 2.03l.554 4.434"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.24 4.902A9.753 9.753 0 014.5 12.75c0 5.386 4.364 9.75 9.75 9.75s9.75-4.364 9.75-9.75a9.753 9.753 0 00-5.74-8.848"
              />
            </svg>
          </div>
          <h3 className="text-xl font-medium mb-2">1. Select Project</h3>
          <p className="text-gray-500">
            Select the project you are interested in and complete the interactive tasks to earn entries.
          </p>
        </div>

        {/* Step 2 */}
        <div className="flex flex-col items-center text-center max-w-xs">
          <div className="mb-4">
            {/* Icon for step 2 */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-12 h-12 text-black"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m3 0v6.75a2.25 2.25 0 002.25 2.25h6a2.25 2.25 0 002.25-2.25V12m-3-5.25v-1.5A1.5 1.5 0 0012 3.75h-.008a1.5 1.5 0 00-1.492 1.5v1.5m3 0H9m3 0v3"
              />
            </svg>
          </div>
          <h3 className="text-xl font-medium mb-2">2. Complete Interactive Tasks</h3>
          <p className="text-gray-500">
            The more tasks you complete, the more entries you can earn, and the better your chances of getting airdrop rewards will be.
          </p>
        </div>

        {/* Step 3 */}
        <div className="flex flex-col items-center text-center max-w-xs">
          <div className="mb-4">
            {/* Icon for step 3 */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-12 h-12 text-black"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25V9m7.5 0h-9m9 0a2.25 2.25 0 012.25 2.25v7.5A2.25 2.25 0 0115.75 21h-7.5A2.25 2.25 0 016 18.75v-7.5A2.25 2.25 0 018.25 9m9 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25V9"
              />
            </svg>
          </div>
          <h3 className="text-xl font-medium mb-2">3. Wait for Airdrop</h3>
          <p className="text-gray-500">
            Check the reward distribution status and wait for the airdrop.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowToJoin;
