import React from 'react';
import { TbCaretUpDownFilled } from "react-icons/tb";
import { FaRegUserCircle } from "react-icons/fa";

function CopyTrading() {
    const popularCoins = [
        { 
            name: 'Linnen_bitman', 
            icon: <FaRegUserCircle className='text-2xl'/>, 
            color: '#F7931A', 
            pnl: '511,005.77', 
            win: '14.29%',
            change: '+1.23', 
            AUM: '2,921,082.58', 
        },
        { 
            name: 'Coin rare', 
            icon: <FaRegUserCircle className='text-2xl'/>, 
            color: '#3C3C3D', 
            pnl: '10.2', 
            win: '28.19%',
            change: '+1.56', 
            AUM: '2024-09-26', 
        },
    ];

    return (
        <div className='w-full'>
            <p>No need to understand complex trading knowledge,follow the expert's operations automatically and achieve passive income !</p>
            <button className='text-sm text-[#EFB81C] underline mb-5'>View Tutorial</button>
            {/* Header row */}
            <div className='flex w-full mb-8'>
                <div className='flex items-center gap-1 w-2/5'>
                    <p className='text-xs text-[#929AA5]'>Top Traders</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>30D ROI</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>30D PNL(USDT)</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>AUM(USDT)</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Win Rate</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Trade</p>
                </div>
            </div>

            {/* Map through popular coins array */}
            {popularCoins.map((coin, index) => (
                <div key={index} className='flex w-full items-center mb-4'>
                    <div className='flex gap-2 w-2/5'>
                        <div className='flex items-center gap-2'>
                            <div className='text-lg' style={{ color: coin.color }}>
                                {coin.icon} {/* Coin icon with color */}
                            </div>
                        </div>
                        {/* Description for each coin */}
                        <div className='flex flex-col'>
                        <p className='text-md font-normal'>{coin.name}</p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-1 w-1/5'>
                        <p className='text-md text-end font-normal text-[#2EBD85]'>{coin.change}</p>
                        {/* Description for change */}
                    </div>
                    <div className='flex flex-col gap-1 w-1/5'>
                        <p className='text-md text-end font-normal'>{coin.pnl}</p>
                        {/* Description for pnl */}
                    </div>
                    
                    <div className='w-1/5'>
                        <div className='flex gap-2 justify-end items-center'><p className='text-md'>{coin.AUM}</p></div>
                    </div>

                    <div className='flex flex-col gap-1 w-1/5'>
                        <p className='text-md text-end font-normal'>{coin.win}</p>
                        {/* Description for pnl */}
                    </div>

                    <div className='flex justify-end gap-3 w-1/5'>
                        <button className='text-sm text-[#EFB81C] underline'>Mock</button>
                        <button className='text-sm text-[#EFB81C] underline'>Copy</button>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CopyTrading;
