import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

const InactivityContext = createContext();

export const useInactivity = () => useContext(InactivityContext);

export const InactivityProvider = ({ children }) => {
    const history = useHistory();
    const inactivityTimeout = useRef(null);
    const secureLogging = useSelector(state => state.secureLogin.secureLogging); // Use Redux state

    const resetInactivityTimeout = () => {
        if (inactivityTimeout.current) {
            clearTimeout(inactivityTimeout.current);
        }
        if (secureLogging) { // Check if secure logging is enabled
            inactivityTimeout.current = setTimeout(handleLogout, 30000000); // 5 minutes
        }
    };

    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/login");
        toast.success("Logged out successfully because of 5minutes Inactivity.");
    };

    useEffect(() => {
        const handleUserActivity = () => {
            resetInactivityTimeout();
        };

        window.addEventListener("mousemove", handleUserActivity);
        window.addEventListener("keydown", handleUserActivity);

        resetInactivityTimeout();

        return () => {
            window.removeEventListener("mousemove", handleUserActivity);
            window.removeEventListener("keydown", handleUserActivity);
        };
    }, [secureLogging]); // Re-run effect if secureLogging state changes

    return (
        <InactivityContext.Provider value={{ resetInactivityTimeout }}>
            {children}
        </InactivityContext.Provider>
    );
};
