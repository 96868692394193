import React, { useEffect, useRef, useState } from "react";

import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { fetchFiatOrder } from "store/actions/OrderUserAction";
import FiatOrderTable from '../../tables/FiatOrderTable'
const FiatOrder = () => {

  const dispatch = useDispatch();

  const { loading, fiatOrder, error } = useSelector(
    (state) => state.fiatOrder
  );
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  useEffect(() => {
   
    dispatch(fetchFiatOrder(userID, userToken ));

  }, [dispatch, userID, userToken]);
  
  


  const dropDownRef = useRef(null);

  // DropDown States
  const [isTrade, setIsTrade] = useState(false);
  const [isMarket, setIsMarket] = useState(false);
  const [isSide, setIsSide] = useState(false);

  // DropDowns Handler
  const handleTradeDropDown = () => {
    setIsTrade(!isTrade);
    setIsMarket(false);
    setIsSide(false);

  };

  const handleMarketDropDown = () => {
    setIsMarket(!isMarket);
    setIsTrade(false);
    setIsSide(false);
  };
  const handleSideDropDown = () => {
    setIsMarket(false);
    setIsTrade(false);
    setIsSide(!isSide);

  };

  //For Clicking Outside DROPDOWNS
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsTrade(null);
        setIsMarket(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  

  useEffect(() => {
    console.log("fiatOrder ", fiatOrder);
  }, [fiatOrder]);



  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg text-red-500">Error: {error}</div>
      </div>
    );
  }

 


  return (
    <>
      <div useRef={dropDownRef} className="flex flex-col w-full">
        {/* First card */}
        <div className="flex-col justify-center w-full p-4  bg-white rounded-3xl text-[16px]">
      

          <div className="flex items-center justify-between w-full mt-4">
            {/* Drop Downs  */}
            <div className="flex items-center gap-x-4">
               {/* Trade DropDown */}
               <div className="flex items-center gap-x-2">
                <h6 className="font-semibold">Trading Type</h6>
                
          <div className="relative inline-block text-left">
            
            <div className="flex items-center gap-x-1">
              
              <button
                onClick={handleTradeDropDown}
                type="button"
                className="inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-[8px] text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                id="filter-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                <div className="text-[16px] font-normal">All</div>
                <div className="pl-2 ml-3 border-l-2">
                  <IoIosArrowDown className="" />
                </div>
              </button>
            </div>

            {/* DropDown Content */}
            <div
              className={`${
                isTrade ? "absolute" : "hidden"
              } z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              aria-orientation="vertical"
              tabIndex="-1"
            >
              <div className="py-1 max-h-[300px] overflow-auto" role="none">
                <h6
                  className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  tabIndex="-1"
                  id="filter-item-0"
                >
                  Hello
                </h6>
              </div>
            </div>
          </div>
          </div>

           {/* Market DropDown */}
           <div className="flex items-center gap-x-2">
           <h6 className="font-semibold">Market</h6>
           <div className="relative inline-block text-left">
            <div>
              <button
                onClick={handleMarketDropDown}
                type="button"
                className="inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-[8px] text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                id="filter-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                <div className="text-[16px] font-normal">All</div>
                <div className="pl-2 ml-4 border-l-2">
                  <IoIosArrowDown className="" />
                </div>
              </button>
            </div>

            {/* DropDown Content */}
            <div
              className={`${
                isMarket ? "absolute" : "hidden"
              } z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              aria-orientation="vertical"
              tabIndex="-1"
            >
              <div className="py-1 max-h-[300px] overflow-auto" role="none">
                <h6
                  className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  tabIndex="-1"
                  id="filter-item-0"
                >
                  Hello
                </h6>
              </div>
            </div>
          </div>
          </div>
           {/* Side DropDown */}
           <div className="flex items-center gap-x-2">
           <h6 className="font-semibold">Side</h6>
           <div className="relative inline-block text-left">
            <div>
              <button
                onClick={handleSideDropDown}
                type="button"
                className="inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-[8px] text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                id="filter-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                <div className="text-[16px] font-normal">All</div>
                <div className="pl-2 ml-4 border-l-2">
                  <IoIosArrowDown className="" />
                </div>
              </button>
            </div>

            {/* DropDown Content */}
            <div
              className={`${
                isSide ? "absolute" : "hidden"
              } z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              aria-orientation="vertical"
              tabIndex="-1"
            >
              <div className="py-1 max-h-[300px] overflow-auto" role="none">
                <h6
                  className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  tabIndex="-1"
                  id="filter-item-0"
                >
                  Hello
                </h6>
              </div>
            </div>
          </div>
          </div>
            </div>
            
           
          </div>
        
        </div>

        {/* Table */}
        <FiatOrderTable />
      </div>
    </>
  );
};

export default FiatOrder;
