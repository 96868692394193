import React, { useState } from "react";
import { Modal } from "antd";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { TbBrandGoogle } from "react-icons/tb";

const SetFundPasswordModal = ({
    visible,
    onClose,
    onSet,
    setFundPasswordSettings,
    fundPasswordSettings,
}) => {
    const [showPassword, setShowPassword] = useState([false, false, false]);

    const togglePasswordVisibility = (index) => {
        const updatedShowPassword = [...showPassword];
        updatedShowPassword[index] = !updatedShowPassword[index];
        setShowPassword(updatedShowPassword);
    };

    return (
        <Modal
            title="Set Fund Password"
            visible={visible}
            onCancel={onClose}
            footer={null}
            okText="Set"
            cancelText="Cancel"
        >
            <div className="mt-4">
                <div>
                    <p className="mb-2 font-medium text-[#81858c]">
                        Fund Password
                    </p>
                    <div className="relative">
                        <input
                            type={showPassword[0] ? "text" : "password"}
                            className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2"
                            placeholder="Please Enter"
                            onChange={(e) =>
                                setFundPasswordSettings({
                                    ...fundPasswordSettings,
                                    password: e.target.value,
                                })
                            }
                        />
                        <button
                            type="button"
                            onClick={() => togglePasswordVisibility(0)}
                            className="absolute right-3 top-2/4 transform -translate-y-2/4"
                        >
                            {showPassword[0] ? (
                                <AiOutlineEyeInvisible
                                    className="opacity-35"
                                    size={20}
                                />
                            ) : (
                                <AiOutlineEye
                                    className="opacity-35"
                                    size={20}
                                />
                            )}
                        </button>
                    </div>
                    <p className="text-[13px] mt-2 text-[#81858c]">
                        Password must contain 8-30 characters, including at
                        least one uppercase letter, one lowercase letter and a
                        number.
                    </p>
                    {/* New Password Field */}
                    <div className="mt-6">
                        <p className="mb-2 font-medium text-[#81858c]">
                            Confirm Fund Password
                        </p>
                        <div className="relative">
                            <input
                                type={showPassword[1] ? "text" : "password"}
                                className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2"
                                placeholder="Please Enter"
                                onChange={(e) =>
                                    setFundPasswordSettings({
                                        ...fundPasswordSettings,
                                        confirmPassword: e.target.value,
                                    })
                                }
                            />
                            <button
                                type="button"
                                onClick={() => togglePasswordVisibility(1)}
                                className="absolute right-3 top-2/4 transform -translate-y-2/4"
                            >
                                {showPassword[1] ? (
                                    <AiOutlineEyeInvisible
                                        className="opacity-35"
                                        size={20}
                                    />
                                ) : (
                                    <AiOutlineEye
                                        className="opacity-35"
                                        size={20}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="mt-4 w-full">
                        <div className="flex items-center gap-1 mb-2 font-medium text-[#81858c]">
                            <TbBrandGoogle /> <p>Google verification code</p>
                        </div>
                        <input
                            type="number"
                            className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2 no-arrows"
                            placeholder="Please enter your Google Authenticator code"
                            style={{ appearance: "textfield" }}
                            onChange={(e) =>
                                setFundPasswordSettings({
                                    ...fundPasswordSettings,
                                    g2faOtp: e.target.value,
                                })
                            }
                        />
                    </div>
                    <button
                        onClick={() => {
                            onSet();
                            onClose();
                        }}
                        className="bg-[#EFB81C] mt-5 py-2 text-center rounded-md w-full font-semibold"
                    >
                        Confirm
                    </button>
                </div>
                <style>
                    {`
                .no-arrows::-webkit-inner-spin-button,
                .no-arrows::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .no-arrows {
                    -moz-appearance: textfield; /* For Firefox */
                }
                `}
                </style>
            </div>
        </Modal>
    );
};

export default SetFundPasswordModal;
