import React from "react";

const PopularCard = () => {
    return (
        <div className="w-full bg-[#f5f5f5] p-3 rounded-xl">
            <div className="bg-[#ffffff]  rounded-xl">
                <div className=" p-4">
                    <div className="flex justify-between items-start ">
                        <div className="flex gap-2 items-center justify-center">
                            <div className="w-12 h-12 rounded-lg bg-blue-300">
                                <img src="" alt="" />
                            </div>
                            <div className="text-xl font-semibold ">
                                <h2 className="text-xl font-semibold ">
                                    Balansol
                                </h2>
                                <button className="px-2 rounded-md bg-blue-200 text-blue-700 font-semibold text-sm">
                                    DAQ
                                </button>
                                <button className="ml-2 px-2 rounded-md text-white bg-[#EFB81C] text-sm">
                                    dapps
                                </button>
                            </div>
                        </div>
                        <button className="bg-[#EFB81C] text-white font-semibold px-4 py-1 rounded-md text-sm">
                            Install
                        </button>
                    </div>
                    <p className="mt-4 text-[#a0a0a0] font-semibold text-sm">
                        Sen Farming Lorem ipsum dolor sit amet consectetur
                        adipisicing elit. Consequuntur, quo?
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PopularCard;
