import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { IoMdInformationCircle } from "react-icons/io";
import { DatePicker } from "antd";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { Link } from "react-router-dom";
import { ActiveAd, DelOrder, fetchMyAds, newad, OfflineAd } from "store/actions/p2pActions";
import { createGlobalStyle, styled } from "styled-components";
import Dropdown from "./Dropdown";
import NoData from "assets/img/user/No-data.svg";

const { RangePicker } = DatePicker;

function CombinedPage() {
    const [filters, setFilters] = useState({
        coin: "All assets",
        fiat: "All types",
        type: "All status",
        startTime: "",
        endTime: "",
    });

    const [ads, setAds] = useState([]);
    const [filteredAds, setFilteredAds] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(null);
    const dropdownRefs = useRef({});
    const user = useSelector((state) => state.authUser?.user || {});
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [cryptoOptions, setCryptoOptions] = useState([]);
    const userID = user?.ID;
    const userToken = user?.TOKEN;
    const dispatch = useDispatch();

    const HandleReset = () => {
        handleFiltersChange({
            coin: "All assets", // Reset to default value for asset/type
            fiat: "All types", // Reset to default value for type
            type: "All status", // Reset to default value for status
            startTime: null, // Reset date range
            endTime: null,
        });
    };

    const handleCheckboxChange = (ad) => {
        setSelectedOrders((prevSelected) => {
            const isSelected = prevSelected.some(selected => selected.id === ad.id);
            
            // Create an updated selection
            const updatedSelection = isSelected
                ? prevSelected.filter((selected) => selected.id !== ad.id) // Remove if already selected
                : [...prevSelected, { id: ad.id, online: ad.online }]; // Add new selection with online status
    
            console.log("Selected Orders:", updatedSelection); // Log the updated selection
            return updatedSelection;
        });
    };    
    
    useEffect(() => {
        console.log("Selected Order IDs:", selectedOrders);
    }, [selectedOrders]);  
    
    const handleSelectAll = () => {
        if (isAllSelected) {
            setSelectedOrders([]); // Clear all selected orders
        } else {
            const allOrders = filteredAds.map((ad) => ({
                id: ad.id,
                online: ad.online // Store the status alongside the ID
            }));
            setSelectedOrders(allOrders);
        }
        setIsAllSelected(!isAllSelected); // Toggle the "Select All" state
    };

    useEffect(() => {
        dispatch(fetchMyAds(userID, userToken))
            .then((result) => {
                if (result && result.data) {
                    setAds(result.data.myads || []);
                    setFilteredAds(result.data.myads || []);
                }
            })
            .catch((error) => console.error("Error fetching ads:", error));
    }, [dispatch, userID, userToken]);

    const handleActivateAll = () => {
        const payload = {
            status: 'online',
            ids: selectedOrders.map(ad => ad.id).join(','),
        };
    
        dispatch(ActiveAd(userID, userToken, payload))
            .then((result) => {
                if (result && result.success) {
                        console.log("Ads activated successfully. Fetching updated ads...");
                } else {
                                    dispatch(fetchMyAds(userID, userToken))
                        .then((result) => {
                            if (result && result.data) {
                                setAds(result.data.myads || []);
                                setFilteredAds(result.data.myads || []);
                            }
                        })
                }
            })
            .catch((error) => console.error("Error activating ads:", error));
    };
    

    const handleDisableAll = () => {
        const payload = {
            status: 'offline',
            ids: selectedOrders.map(ad => ad.id).join(','),
        };
        dispatch(ActiveAd(userID, userToken, payload))
        .then((result) => {
            if (result && result.success) {
                console.log("Ads activated successfully. Fetching updated ads...");
            } else {
                dispatch(fetchMyAds(userID, userToken))
                .then((result) => {
                    if (result && result.data) {
                        setAds(result.data.myads || []);
                        setFilteredAds(result.data.myads || []);
                    }
                })
            }
        })
        .catch((error) => console.error("Error activating ads:", error));;
        console.log("Activating Ads with payload: ", payload); // Optional: Log the payload
    };

    useEffect(() => {
        let filtered = ads;

        if (filters.type !== "All status") {
            filtered = filtered.filter((order) => {
                const status = order.online === "0" ? "Offline" : "Online";
                return status.toLowerCase() === filters.type.toLowerCase();
            });
        }

        if (filters.coin !== "All assets") {
            filtered = filtered.filter(
                (order) =>
                    order.coin.toUpperCase() === filters.coin.toUpperCase()
            );
        }

        if (filters.fiat !== "All types") {
            const adType = filters.fiat.toLowerCase() === "buy" ? "1" : "0";
            filtered = filtered.filter((order) => order.ad_type === adType);
        }

        if (filters.startTime && filters.endTime) {
            filtered = filtered.filter((order) => {
                const orderDate = new Date(order.created_at * 1000);
                const startDate = new Date(filters.startTime).setHours(
                    0,
                    0,
                    0,
                    0
                );
                const endDate = new Date(filters.endTime).setHours(
                    23,
                    59,
                    59,
                    999
                );
                return orderDate >= startDate && orderDate <= endDate;
            });
        }

        setFilteredAds(filtered);
    }, [ads, filters]);

    const handleDelete = (orderid) => {
        dispatch(DelOrder(userID, userToken, { orderid }))
            .then(() => {
                dispatch(fetchMyAds(userID, userToken))
                    .then((result) => {
                        if (result) {
                            setAds(result.data.myads);
                            setFilteredAds(result.data.myads);
                        }
                    })
                    .catch((error) =>
                        console.error(
                            "Error fetching ads after deletion:",
                            error
                        )
                    );
            })
            .catch((error) => console.error("Error deleting ad:", error));
    };

    const handleFiltersChange = (updatedFilters) => {
        setFilters((prev) => ({ ...prev, ...updatedFilters }));
    };

    const handleDropdownToggle = useCallback(
        (dropdown) => {
            setOpenDropdown(openDropdown === dropdown ? null : dropdown);
        },
        [openDropdown]
    );

    const closeAllDropdowns = () => {
        setOpenDropdown(null);
    };

    const handleClickOutside = (event) => {
        const dropdownElement = dropdownRefs.current[openDropdown];
        if (dropdownElement && !dropdownElement.contains(event.target)) {
            closeAllDropdowns();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [openDropdown]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await dispatch(newad(userID, userToken));
            if (result) {
                setCryptoOptions(result.data.allow_cryptos || []);
            }
        };
        fetchData();
    }, [dispatch, userID, userToken]);

    const cryptoOption = [
        "All assets", 
        ...cryptoOptions // Use the fetched crypto options here
    ];

    return (
        <div className="container">
            <div className="flex flex-col md:flex-col lg:flex-row gap-1">
                <div className="flex w-full flex-wrap items-center gap-1 mb-4 lg:w-[35%]">
                    {[
                        {
                            key: "coin",
                            label: "Asset/type",
                            options: cryptoOption,
                            selected: filters.coin,
                            setSelected: (value) =>
                                handleFiltersChange({ coin: value }),
                            width: "100%",
                        },
                        {
                            key: "fiat",
                            label: "Type",
                            options: ["All types", "Buy", "Sell"],
                            selected: filters.fiat,
                            setSelected: (value) =>
                                handleFiltersChange({ fiat: value }),
                            width: "100%",
                        },
                        {
                            key: "type",
                            label: "Status",
                            options: ["All status", "Online", "Offline"],
                            selected: filters.type,
                            setSelected: (value) =>
                                handleFiltersChange({ type: value }),
                            width: "100%",
                        },
                    ].map(
                        ({
                            key,
                            label,
                            options,
                            selected,
                            setSelected,
                            width,
                        }) => (
                            <div
                                key={key}
                                className="flex-1 w-full"
                                ref={(el) => (dropdownRefs.current[key] = el)}
                            >
                                <p className="text-sm font-light mb-1">
                                    {label}
                                </p>
                                <Dropdown
                                    label={label}
                                    options={options}
                                    initialSelected={selected}
                                    onSelect={(value) => {
                                        setSelected(value);
                                        setOpenDropdown(null);
                                    }}
                                    isOpen={openDropdown === key}
                                    onToggle={(isOpen) =>
                                        handleDropdownToggle(key)
                                    }
                                    width={width}
                                />
                            </div>
                        )
                    )}
                </div>
                <div>
                    <label className="flex gap-1 !items-start text-[12px] mb-1">
                        Date
                        <IoMdInformationCircle
                            data-tooltip-id="coin-tooltip"
                            data-tooltip-content="Export order list"
                            className="inline cursor-pointer text-gray-400"
                        />
                        <Tooltip id="coin-tooltip" place="top" effect="solid" />
                    </label>
                    <div>
                        <GlobalStyles />
                        <StyledRangePicker
                            className="px-2 py-2 border border-[#DBDBDB]"
                            placeholder={["Start Date", "End Date"]}
                            value={[filters.startTime, filters.endTime]}
                            onChange={(dates) =>
                                handleFiltersChange({
                                    startTime: dates[0],
                                    endTime: dates[1],
                                })
                            }
                        />
                    </div>
                </div>
                <div className="flex justify-between w-full lg:w-[40%]">
                    <div className="ml-0 md:ml-0 lg:ml-5">
                        <p
                            className="text-sm font-light mb-1"
                            style={{ visibility: "hidden" }}
                        >
                            label
                        </p>
                        <div className="flex gap-2">
                            {/* <button className="bg-[#EFB81C] rounded-[4px] px-6 text-sm font-medium py-2 h-fit">
                                Filter
                            </button> */}
                            <button
                                onClick={HandleReset}
                                className="bg-[#EAECEF] rounded-[4px] px-6 text-sm font-medium py-2 h-fit"
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col mt-5 gap-6">
                <Link
                    to="/trade/p2p/ads-history"
                    className="flex items-center text-xs font-semibold text-[#EFB81C]"
                >
                    <span className="text-xs font-semibold underline underline-offset-8">
                        Ad History
                    </span>
                </Link>
                <div className="flex items-center gap-2">
                    <button onClick={handleSelectAll}>
                        {isAllSelected ? (
                            <ImCheckboxChecked className="text-gray-300" />
                        ) : (
                            <ImCheckboxUnchecked className="text-gray-300" />
                        )}
                    </button>
                    <p className="text-sm text-[#474D57] font-medium">
                        {isAllSelected ? `${selectedOrders.length} Ads` : "0 Ads"}
                    </p>
                </div>
                {/* Conditionally hide the buttons */}
                <div className="flex gap-2">
                    <button onClick={handleActivateAll} className="bg-[#EFB81C] rounded-md px-3 md:px-8 lg:px-8 text-sm font-semibold py-1.5 h-fit">
                        Active all
                    </button>
                    <button onClick={handleDisableAll} className="bg-[#EAECEF] rounded-md px-3 md:px-8 lg:px-8 text-sm font-semibold py-1.5 h-fit">
                        Take all offline
                    </button>
                </div>
            </div>
            {filteredAds.length > 0 ? (
                <table className="w-full text-sm font-light border-none table-hover mt-5">
                    <thead className="border-b text-neutral-400">
                        <tr>
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px]"
                            >
                                Select
                            </th>
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px]"
                            >
                                Order ID/Time Created
                            </th>
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px]"
                            >
                                Price
                            </th>
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px]"
                            >
                                Total Amount
                            </th>
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px]"
                            >
                                Type
                            </th>
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px]"
                            >
                                Status
                            </th>
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px]"
                            >
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {filteredAds.map((ad) => (
                            <tr
                                key={ad.id}
                                className="border-b text-neutral-700 text-center"
                            >
                                <td className="py-2 text-[12px]">
                                    <input
                                        type="checkbox"
                                        onChange={() =>
                                            handleCheckboxChange(ad)
                                        }
                                        checked={selectedOrders.some((selected) => selected.id === ad.id)}
                                    />
                                </td>
                                <td className="py-2 text-[12px]">
                                    {ad.orderid} /{" "}
                                    {new Date(
                                        ad.created_at * 1000
                                    ).toLocaleString()}
                                </td>
                                <td className="py-2 text-[12px]">
                                    {parseFloat(ad.fixed_price).toFixed(2)}{" "}
                                    {ad.fiat.toUpperCase()}
                                </td>
                                <td className="py-2 text-[12px]">
                                    {" "}
                                    {parseFloat(ad.available).toFixed(2)}{" "}
                                    {ad.coin.toUpperCase()}
                                </td>
                                <td className="py-2 text-[12px]">
                                    {ad.ad_type === "1" ? "Buy" : "Sell"}
                                </td>
                                <td className="py-2 text-[12px]">
                                    {ad.online === "0" ? "Offline" : "Online"}
                                </td>
                                <td className="py-2 text-[12px]">
                                    <button
                                        onClick={() => handleDelete(ad.orderid)}
                                        className="bg-red-500 text-white px-4 py-1 rounded"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="flex justify-center items-center w-full h-screen">
                    <div className="flex flex-col items-center">
                        <img src={NoData} alt="No data" />
                        <p className="text-sm pt-2">You do not have any Ads</p>
                        <button className="bg-[#EFB81C] mt-4 rounded-md px-12 text-sm font-semibold py-1.5 h-fit">
                            Post now
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

const GlobalStyles = createGlobalStyle`
  .ant-picker:hover {
    border-color: #EFB81C !important;
  }
  .ant-picker-focused {
    border-color: #EFB81C !important;
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important;
  }
  .ant-picker-active-bar {
    background: #EFB81C !important;
  }
  .ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #EFB81C !important;
    color: white !important;
  }
`;

const StyledRangePicker = styled(RangePicker)`
    .ant-picker-input > input:focus {
        box-shadow: none !important;
        border-color: #efb81c !important;
    }
`;

export default CombinedPage;
