import React, { useEffect, useState } from "react";
import { FaCircleQuestion } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import ShowTwoFA from "./ShowTwoFA";
import { forgotCode, confirmCode } from "store/actions/authAction";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import EmailVerificationHelper from "../../../../auth/validations/email/emailVerificationHelp";

const SecurityAuthentication = ({ nextStep }) => {
    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [timer, setTimer] = useState(60);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const email = useSelector((state) => state.authCheck.authStatus.email);
    const password = useSelector((state) => state.myreducer.password);

    const dispatch = useDispatch();
    const [show2FA, setShow2FA] = useState(false);

    const handleShow2FA = () => {
        setShow2FA(true);
    };

    // useEffect(() => {
    //     const storedEmail = localStorage.getItem("email");
    //     const storedPassword = localStorage.getItem("password");
    //     if (storedEmail) setEmail(storedEmail);
    //     if (storedPassword) setPassword(storedPassword);

    //     console.log("Stored:", storedEmail, storedPassword);
    // }, []);

    useEffect(() => {
        let interval = null;
        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        setIsButtonDisabled(false);
                        return 60;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isButtonDisabled]);

    const verifyOtp = async (e) => {
        e.preventDefault();

        const payload = {
            email: email,
            password: password,
            verify_code: otp,
        };
        dispatch(confirmCode(payload, true, handleShow2FA));
    };

    const handleSendCode = () => {
        if (!isButtonDisabled) {
            dispatch(forgotCode({ email: email }));
            // Logic to send the code
            setIsButtonDisabled(true);
            toast.success("Code sent!");
        }
    };

    return (
        <div className="bg-white rounded-xl shadow-lg mb-20">
            <div className="flex flex-col gap-4 my-auto w-4/6 mx-auto  py-4">
                <form onSubmit={(e) => verifyOtp(e)}>
                    <div className="flex flex-col gap-4 items-start justify-center">
                        <h4 className=" font-semibold">
                            Email Verification Code
                        </h4>
                        <div className="flex justify-between bg-gray-100 rounded-md py-2 px-4 w-full">
                            <input
                                type="text"
                                placeholder="Enter Verification code"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                className="bg-transparent text-black font-medium focus:outline-none border-none"
                            />
                            <div>
                                <span
                                    className={`text-[#F1BA19] text-sm ${
                                        isButtonDisabled
                                            ? "cursor-not-allowed opacity-50"
                                            : "cursor-pointer"
                                    }`}
                                    onClick={handleSendCode}
                                >
                                    Send code{" "}
                                    {isButtonDisabled ? `(${timer}s)` : ""}
                                </span>
                            </div>
                        </div>
                        <p className="text-[#B0B0B0] text-xs">
                            Please enter the 6-digit TOTP code in Authenticator
                        </p>
                        <button
                            type="submit"
                            className="btn bg-yellow-500 font-semibold text-center px-20 mx-auto text-white mt-4"
                        >
                            Next
                        </button>
                    </div>
                </form>
                <div className=" w-full">
                    <Link
                        onClick={() => setShowModal(true)}
                        className=" block text-[#F1BA19] hover:no-underline text-center w-full "
                        style={{ cursor: "pointer" }}
                    >
                        Security authentication unavailable?
                    </Link>
                </div>
                <EmailVerificationHelper
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            </div>
            {/* for 2fa  */}

            {show2FA && (
                <ShowTwoFA nextStep={nextStep} setShow2FA={setShow2FA} />
            )}
        </div>
    );
};

export default SecurityAuthentication;