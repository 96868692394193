import axiosObj from './Repository';

const TRADE_ENDPOINT = 'Trade/getTradedata';

export const getTradeData = (marketId, token, userId) => {
  return axiosObj.post(
    TRADE_ENDPOINT,
    new URLSearchParams({ marketid: marketId }), // Use URLSearchParams for form data
    {
      headers: {
        'TOKEN': token,
        'ID': userId,
      },
    }
  );
};
