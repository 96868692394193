// AssetsUserAction
import toast from "react-hot-toast";
import { RepositoryFactory } from "repository/RepositoryFactory";
const earnStaking = RepositoryFactory.get("earnStaking");

export const fetchEarnStakingData = (userid, token) => async (dispatch) => {
    dispatch({ type: "FETCH_EARN_STAKING_DATA_REQUEST" });
    try {
        const response = await earnStaking.earnStaking(userid, token);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_EARN_STAKING_DATA_FAILURE", payload: error.message });
    }
};
export const Staking = (userid, token) => async (dispatch) => {
    dispatch({ type: "FETCH_EARN_STAKING_DATA_REQUEST" });
    try {
        const response = await earnStaking.Staking(userid, token);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: "FETCH_EARN_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_EARN_STAKING_DATA_FAILURE", payload: error.message });
    }
};
export const createStaking = (userid, token, payload,setIsModalOpen,setAmount) => async (dispatch) => {
    dispatch({ type: "FETCH_STAKING_DATA_REQUEST" });
    try {
        console.log(userid, token,payload);
        const response = await earnStaking.createStaking(userid, token,payload);

        // console.log(response.data.data);
        
        
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
            toast.success(response.data.data);
            setIsModalOpen(false);
            setAmount("")
        } else {
            dispatch({
                type: "FETCH_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            toast.error(response.data.data);
            // console.log("Assets Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_STAKING_DATA_FAILURE", payload: error.message });
    }
};

export default fetchEarnStakingData;
