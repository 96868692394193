const initialOrderStatusState = {
    loading: false,
    orderStatus: [],
    error: null
};

const initialReceiveDataState = {
    loading: false,
    receiveData: [],
    error: null
};
const initialMyOpenOrderState = {
    loading: false,
    myOpenOrder: [],
    error: null
};
const initialOrderHistoryState = {
    loading: false,
    orderHistory: [],
    error: null
};
const initialFiatOrderState = {
    loading: false,
    fiatOrder: [],
    error: null
};
const initialOrderViewState = {
    loading: false,
    orderViews: [],
    error: null
};

export const orderStatusReducer = (state = initialOrderStatusState, action) => {
    switch (action.type) {
        case "FETCH_ORDER_STATUS_REQUEST":
            return {
                ...state,
                loading: true,
                error: null
            };
        case "FETCH_ORDER_STATUS_SUCCESS":
            return {
                ...state,
                loading: false,
                orderStatus: action.payload
            };
        case "FETCH_ORDER_STATUS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export const receiveDataReducer = (state = initialReceiveDataState, action) => {
    switch (action.type) {
        case "FETCH_RECEIVE_DATA_REQUEST":
            return {
                ...state,
                loading: true,
                error: null
            };
        case "FETCH_RECEIVE_DATA_SUCCESS":
            return {
                ...state,
                loading: false,
                receiveData: action.payload
            };
        case "FETCH_RECEIVE_DATA_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
export const myOpenOrderReducer = (state = initialMyOpenOrderState, action) => {
    switch (action.type) {
        case "FETCH_OPEN_ORDER_REQUEST":
            return {
                ...state,
                loading: true,
                error: null
            };
        case "FETCH_OPEN_ORDER_SUCCESS":
            return {
                ...state,
                loading: false,
                myOpenOrder: action.payload
            };
        case "FETCH_OPEN_ORDER_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
export const orderHistoryReducer = (state = initialOrderHistoryState, action) => {
    switch (action.type) {
        case "FETCH_ORDER_HISTORY_REQUEST":
            return {
                ...state,
                loading: true,
                error: null
            };
        case "FETCH_ORDER_HISTORY_SUCCESS":
            return {
                ...state,
                loading: false,
                orderHistory: action.payload
            };
        case "FETCH_ORDER_HISTORY_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
export const fiatOrderReducer = (state = initialFiatOrderState, action) => {
    switch (action.type) {
        case "FETCH_FIAT_ORDER_REQUEST":
            return {
                ...state,
                loading: true,
                error: null
            };
        case "FETCH_FIAT_ORDER_SUCCESS":
            return {
                ...state,
                loading: false,
                fiatOrder: action.payload
            };
        case "FETCH_FIAT_ORDER_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
export const orderViewReducer = (state = initialOrderViewState, action) => {
    switch (action.type) {
        case "FETCH_ORDER_VIEW_REQUEST":
            return {
                ...state,
                loading: true,
                error: null
            };
        case "FETCH_ORDER_VIEW_SUCCESS":
            return {
                ...state,
                loading: false,
                orderViews: action.payload
            };
        case "FETCH_ORDER_VIEW_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
