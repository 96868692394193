import React, { useEffect, useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import img1 from "../../../assets/img/img1.svg";
import img2 from "../../../assets/img/img2.svg";
import img3 from "../../../assets/img/img3.svg";
import { useDispatch, useSelector } from "react-redux";
import {
    getCountryName,
    getUserIP,
} from "components/UserLocation/geolocationUtils";
import { setCountry } from "store/reducers/stepOneSlice";
import toast from "react-hot-toast";
import { MerchantApply } from "store/actions/p2pActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Merchant() {
    const [country, setCountries] = useState("");
    const [currencyNames, setCurrencyNames] = useState("");

    const dataa = useSelector((state) => state.p2p.dataa.fiat_list || []);
    const email = useSelector((state) => state.userInfo.userProfile.email);
    const mobile = useSelector((state) => state.userInfo.userProfile.mobile);
    const state = useSelector((state) => state)
    console.log(state);
    
    const approved = useSelector(
        (state) => state.authCheck.authStatus.idcardauth
    );
    const merchant = useSelector(
        (state) => state.authCheck.authStatus.is_merchant
    );
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const initialize = async () => {
            const ip = await getUserIP();
            if (ip) {
                const countryCode = await getCountryName(ip);
                if (countryCode) {
                    dispatch(setCountry(countryCode));
                }
                setCountries(countryCode);
            }

            const storedCurrencyNames = localStorage.getItem("currencyNames");
            if (storedCurrencyNames) {
                setCurrencyNames(storedCurrencyNames);
            } else {
                const names = dataa
                    .map((currency) => currency.name.toUpperCase())
                    .join(", ");
                setCurrencyNames(names);
                localStorage.setItem("currencyNames", names);
            }
        };

        initialize();
    }, [dataa, dispatch]);

    const click = () => {
        if (!email) {
            toast.error("Please complete Email verification first.");
        } else if (!mobile) {
            toast.error("Please complete SMS verification first.");
        } else if (approved != 1) {
            toast.error("Please complete Advanced verification first.");
        } else {
            dispatch(MerchantApply(userId, token)).then((response) => {
                if (response.data.status == 1) {
                    toast.success(response.data.data);
                    history.push("/trade/p2p");
                }
            });
        }
    };

    const navigate = () => {
        history.push("/trade/p2p/post-ads");
    };

    return (
        <div className="flex flex-col items-center p-4 bg-gray-100">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl">
            <div className="bg-white rounded-lg p-6 flex flex-col items-center shadow-lg">
                    <img
                        src={img1}
                        alt="Ad Management Icon"
                        className="w-24 h-24 mb-4"
                    />
                    <h3 className="text-lg font-semibold mb-2">
                        Advanced Ad Management
                    </h3>
                    <p className="text-center text-gray-600 mb-4">
                        Sellers can access more trading tools through the seller
                        portal to better manage their listings and trading
                        activities.
                    </p>
                </div>

                <div className="bg-white rounded-lg p-6 flex flex-col items-center shadow-lg">
                    <img
                        src={img2}
                        alt="Verified Badge Icon"
                        className="w-24 h-24 mb-4"
                    />
                    <h3 className="text-lg font-semibold mb-2">
                        Verified Badges
                    </h3>
                    <p className="text-center text-gray-600 mb-4">
                        To give your brand credibility, a verified badge will be
                        displayed next to your P2P nickname.
                    </p>
                </div>

                <div className="bg-white rounded-lg p-6 flex flex-col items-center shadow-lg">
                    <img
                        src={img3}
                        alt="Customer Support Icon"
                        className="w-24 h-24 mb-4"
                    />
                    <h3 className="text-lg font-semibold mb-2">
                        Dedicated Customer Support
                    </h3>
                    <p className="text-center text-gray-600 mb-4">
                        BuyCex.com provides one-on-one service with quick
                        response to your requests or issues.
                    </p>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-5xl mt-8 w-full">
                <div className="bg-white rounded-lg p-4 shadow-md">
                    <p className="text-sm font-semibold">Country:</p>
                    <p className="text-gray-700">{country}</p>
                    <p className="text-sm font-semibold mt-4">Fiat:</p>
                    <p className="text-gray-700">{currencyNames}</p>
                </div>

                <div className="bg-white rounded-lg p-4 shadow-md">
                    <p className="text-sm font-semibold mb-2">What's needed</p>
                    <ul className="text-gray-700">
                        <li className="flex items-center">
                            {mobile ? (
                                <FaRegCheckCircle className="text-green-500 mr-2" />
                            ) : (
                                <ImCancelCircle className="text-red-500 mr-2" />
                            )}{" "}
                            <span className="text-[16px]">
                                Complete SMS verification
                            </span>
                        </li>
                        <li className="flex items-center mt-2">
                            {email ? (
                                <FaRegCheckCircle className="text-green-500 mr-2" />
                            ) : (
                                <ImCancelCircle className="text-red-500 mr-2" />
                            )}
                            <span className="text-[16px]">
                                Complete Email verification
                            </span>
                        </li>
                        <li className="flex items-center mt-2">
                            {approved == 1 ? (
                                <FaRegCheckCircle className="text-green-500 mr-2" />
                            ) : (
                                <ImCancelCircle className="text-red-500 mr-2" />
                            )}
                            <span className="text-[16px]">
                                Complete the Advanced verification
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            {merchant == 1 ? (
                <button
                    onClick={navigate}
                    className="bg-yellow-500 text-white font-semibold py-2 px-8 rounded-md mt-6 shadow-md"
                >
                    Post a New Ad
                </button>
            ) : (
                <button
                    onClick={click}
                    className="bg-yellow-500 text-white font-semibold py-2 px-8 rounded-md mt-6 shadow-md"
                >
                    Apply
                </button>
            )}
        </div>
    );
}
