import React, { useState } from "react";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-300">
      <div
        className="flex justify-between items-center py-6 cursor-pointer"
        onClick={toggleOpen}
      >
        <h3 className="text-xl font-medium">{question}</h3>
        <button className="text-blue-500">
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          )}
        </button>
      </div>
      {isOpen && <p className="text-gray-600 text-lg pb-6">{answer}</p>}
    </div>
  );
};

const ListingFaq = () => {
  const faqItems = [
    {
      question: "1. What Is the Current Price of VOICE?",
      answer: "The current price of Voice Token (VOICE) is ____. Top cryptocurrency prices are updated in real-time on Binance's price directory.",
    },
    {
      question: "2. What Is the Market Cap of VOICE?",
      answer: "The current market capitalization of Voice Token (VOICE) is ____. Explore top gaining cryptocurrencies by market cap and 24-hour trading volume on Binance.",
    },
    {
      question: "3. What Is the Circulating Supply of VOICE?",
      answer: "The current circulating supply of Voice Token (VOICE) is ____. ",
    },
    {
      question: "4. What Is the All Time High of VOICE?",
      answer: "The all-time high of Voice Token (VOICE) is ____. Explore user price targets and project confidence levels for various coins - known as a Consensus Rating - on our crypto price prediction pages.",
    },
    {
      question: "5. What Is the All Time Low of VOICE?",
      answer: "The all-time low of Voice Token (VOICE) is ____. Head to our altcoin directory to discover today's gainers and losers.",
    },
    {
      question: "6. What Is the 24-hour Trading Volume of VOICE?",
      answer: "The 24-hour trading volume of Voice Token (VOICE) is ____. Discover the most traded cryptocurrencies on Binance and their trading volume in the past 24 hours.",
    },
    {
      question: "7. How To Buy VOICE on Binance?",
      answer: "This Voice Token (VOICE) is currently not available on Binance for purchase or trade. Stay updated about newly listed cryptocurrencies and refer to our How to Buy Coins guides for tokens available on Binance.",
    },
  ];

  return (
    <div className="container mx-auto py-12 px-6 max-w-5xl">
      <h2 className="text-4xl font-semibold mb-10 text-center">FAQ</h2>
      {faqItems.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};

export default ListingFaq;
