import { useEffect, useRef, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Header from "components/header/Header";
import { IoIosArrowDown } from "react-icons/io";

const Orders = (props) => {
  const dropDownRef = useRef(null);
  const [isPage, setIsPage] = useState(false);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(null);

  const pages = [
    { name: "Spot Orders", path: "/user/spot-order/pending" },
    { name: "Fiat Order", path: "/user/fiat-order" },
    { name: "Convert Order", path: "/user/convert-order/pending" },
    { name: "LaunchPad", path: "/user/launchpad" },
    { name: "P2P Order", path: "/user/p2p-order" },
  ];

  const handlePageDropDown = () => {
    setIsPage(!isPage);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsPage(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const matchedPage = pages.find((page) => page.path === location.pathname);
    setCurrentPage(matchedPage || pages[0]);
  }, [location.pathname]);

  return (
    <>
      <Header />
      <div className="bg-[#F0F0F0]">
        <div className="container flex gap-4 py-12">
          <main ref={dropDownRef} className="w-full p-4">
            {/* Pages Drops Down */}
            <div className="relative inline-block text-left">
              <div>
                <div
                  onClick={handlePageDropDown}
                  className="relative flex items-center cursor-pointer gap-x-3 bottom-3"
                >
                  <div className="text-black text-[24px] font-semibold">
                    {currentPage ? currentPage.name : "Spot Orders"}
                  </div>
                  <IoIosArrowDown
                    size={26}
                    className={`${
                      isPage ? "-rotate-180" : "rotate-0"
                    } transition-all duration-300`}
                  />
                </div>
              </div>

              {/* DropDown Content */}
              <div
                className={`${
                  isPage ? "absolute" : "hidden"
                } z-10 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                aria-orientation="vertical"
                tabIndex="-1"
              >
                <div className="max-h-[300px] overflow-auto" role="none">
                  {pages.map((page, index) => (
                    <Link
                      to={page.path}
                      key={index}
                      onClick={handlePageDropDown}
                      className={`block px-4 py-2 text-sm transition-all duration-300 cursor-pointer ${
                        location.pathname === page.path 
                          ? "bg-[#fcf1d2] text-[#c09c39]"
                          : "text-gray-700 hover:bg-[#fcf1d2] hover:text-[#c09c39]"
                      }`}
                    >
                      <h6
                        role="filteritem"
                        tabIndex="-1"
                        id={`filter-item-${index}`}
                      >
                        {page.name}
                      </h6>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            {props.children}
          </main>
        </div>
      </div>
    </>
  );
};

export default Orders;
