import React, { useState, useEffect, useRef } from 'react';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { MdKeyboardArrowRight, MdSettings, MdSpeed } from "react-icons/md";

function CrossMargin({ isVisible, toggleVisibility }) {
    const [dropdownOpen, setDropdownOpen] = useState(false); // State to track dropdown visibility
    const [selectedCoin, setSelectedCoin] = useState('BTC'); // State to track selected coin
    const dropdownRef = useRef(null);

    const coins = ['USDT', 'ETH', 'XRP', 'BTC']; // Coin options

    // Function to toggle dropdown
    const toggleDropdown = () => {
        setDropdownOpen(prev => !prev);
    };

    // Function to select a coin and close dropdown
    const selectCoin = (coin) => {
        setSelectedCoin(coin);
        setDropdownOpen(false);
    };

    // Close dropdown when clicking outside of it
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='w-full mt-6 p-6 rounded-2xl border'>
            <div>
                <div className='flex justify-between items-start gap-1 w-full'>
                    <div className='flex w-[75%] md:w-1/2 justify-between'>
                        <div className='flex w-1/3 flex-col'>
                            <div className='flex gap-1 items-center'>
                                <p className='text-[20px] font-semibold text-ellipsis whitespace-nowrap'>Total Value</p>
                                <button onClick={toggleVisibility} className='mt-1'>
                                    {isVisible ? <FaRegEyeSlash className='text-xl' /> : <FaRegEye className='text-xl' />}
                                </button>
                            </div>
                            <div className='flex items-baseline gap-2'>
                                <p className='text-[32px] font-semibold'>{isVisible ? '0.00000000' : '****'}</p>
                                <div className='relative'>
                                    <button onClick={toggleDropdown} className='text-sm flex items-center gap-1 font-semibold'>
                                        <p className={dropdownOpen ? 'text-yellow-500 text-sm' : ''}>{selectedCoin}</p>
                                        <FaAngleDown className='text-[12px] opacity-40 mt-1' />
                                    </button>
                                    {dropdownOpen && (
                                        <div ref={dropdownRef} className='absolute bg-white border mt-2 rounded shadow-lg'>
                                            {coins.map((coin) => (
                                                <div
                                                    key={coin}
                                                    onClick={() => selectCoin(coin)}
                                                    className={`cursor-pointer text-sm px-4 py-2 hover:bg-gray-200 ${selectedCoin === coin ? 'text-yellow-500' : ''}`}
                                                >
                                                    {coin}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='w-1/3 hidden text-[20px] md:flex flex-col font-semibold'>
                            <p className='text-ellipsis whitespace-nowrap'>Margin Level</p>
                            <p className='text-2xl font-bold flex items-center mt-1 gap-2'>
                                {isVisible ? <MdSpeed /> : '****'}
                                <p className='text-[#2ebd85] text-2xl'>{isVisible ? '999.00' : ''}</p>
                            </p>
                        </div>
                    </div>
                    <div className=' text-end flex gap-1 items-start justify-end font-medium text-[#EFB81C]'>
                            <div className='flex gap-1 items-center'>
                                <p className='whitespace-nowrap'>Cross 5x</p>
                                <MdSettings className='text-black' />
                            </div>
                        </div>
                </div>
                <p className='text-sm'>{isVisible ? '≈ $0.00' : '*****'}</p>
                <div className='flex items-center mt-2'>
                    <p className='text-sm'>Today’s PNL <span className='pl-2 text-sm'>{isVisible ? '$0.00 (0.00%)' : '*****'}</span></p>
                    <MdKeyboardArrowRight className='text-[#707a8a]' />
                </div>

                <div className='flex w-full sm:w-1/2 justify-between'>
                    <div className='flex flex-col gap-1 mt-6 w-1/3'>
                        <div className='flex items-center gap-1'>
                            <p className='text-sm'>Collateral Value USTD</p>
                        </div>
                        <p className='font-extralight'>{isVisible ? '-' : '********'}</p>
                    </div>
                    <div className='flex flex-col gap-1 mt-6 w-1/3'>
                        <div className='flex items-center gap-1'>
                            <p className='text-sm'>Collateral Margin Level:</p>
                        </div>
                        <p className='font-extralight'>{isVisible ? '-' : '********'}</p>
                    </div>
                </div>

                <div className='flex w-full sm:w-1/2 justify-between'>
                    <div className='flex flex-col gap-1 mt-6 w-1/3'>
                        <div className='flex items-center gap-1'>
                            <p className='text-sm'>Total Debt({selectedCoin})</p>
                        </div>
                        <p className='font-semibold text-lg'>{isVisible ? '0.00000000' : '********'}</p>
                        <p className='text-[#707a8a]'>{isVisible ? '≈ $0.00' : '*****'}</p>
                    </div>
                    <div className='flex flex-col gap-1 mt-6 w-1/3'>
                        <div className='flex items-center gap-1'>
                            <p className='text-sm'>Account Equity({selectedCoin})</p>
                        </div>
                        <p className='font-semibold text-lg'>{isVisible ? '0.00000000' : '********'}</p>
                        <p className='text-[#707a8a]'>{isVisible ? '≈ $0.00' : '*****'}</p>
                    </div>
                </div>
                <div className='w-1/3 text-[20px] mt-4 flex md:hidden flex-col font-semibold'>
                    <p className='text-ellipsis whitespace-nowrap'>Margin Level</p>
                    <p className='text-2xl font-bold flex items-center mt-1 gap-2'>
                        {isVisible ? <MdSpeed /> : '****'}
                        <p className='text-[#2ebd85] text-2xl'>{isVisible ? '999.00' : ''}</p>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default CrossMargin;
