import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import WithdrawalRoutingNavbar from "components/withdrawalRoutingNavbar";
import FaQs from "components/FAQs";
import { NavLink } from "react-router-dom";
import withdrawKey from "assets/img/Withdraw-key.png";
import { AiOutlineClose } from "react-icons/ai";
import { ImNotification } from "react-icons/im";
import InternalTransfer from "components/cryptoWithdraw/InternalTransfer";
import ContinueBtn from "components/cryptoWithdraw/ContinueBtn";
import CoinSelect from "components/cryptoWithdraw/CoinSelectWithdraw";
import NetworkSelectDropdown from "components/cryptoWithdraw/NetworkSelectWithdraw";
import { FaCheck, FaArrowRight } from "react-icons/fa";
import toast from "react-hot-toast";
import { doWithdraw } from "store/actions/WithdrawAction";   
import CryptoWithdrawalRecord  from "./cryptowithdrawalRecord";
import CryptoEmailVerificationModal from "./cryptoEmailVerificationModal";
import FAQSection from "./cryptoFaqs";
function CryptoWithdraw() {
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    const [coins, setCoins] = useState([]);
    const [selectedCoin, setSelectedCoin] = useState(null);
    const [networks, setNetworks] = useState([]);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [withdrawalRecords, setWithdrawalRecords] = useState([]);
    const [withdrawAddress, setWithdrawAddress] = useState("");
    const [withdrawAmount, setWithdrawAmount] = useState("");
   
    const [g2faOtp, setG2faOtp] = useState("");
    const [otp, setOtp] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [balance, setBalance] = useState("");
    const [limit, setLimit] = useState("");
    const [feesPercent, setFeesPercent] = useState("");
    const [feesFlat, setFeesFlat] = useState("");
    const history = useHistory();
    const [showOnChainWithdrawal, setShowOnChainWithdrawal] = useState(true);
    const [isEmailVerificationModalVisible, setIsEmailVerificationModalVisible] = useState(false);
    const dispatch = useDispatch();
    const handleEmailVerificationSuccess = () => {
        setIsEmailVerificationModalVisible(false);
        setIsVisible(!isVisible);
  
    };

    const handleEmailVerificationClose = () => {
        setIsEmailVerificationModalVisible(false);
    };
    const handleOnChainWithdrawalClick = () => {
        setShowOnChainWithdrawal(true);
    };

    useEffect(() => {
        // Check on mount if TOKEN or ID is missing
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
            return;
        }

        // Fetch coin data from the API
        fetch("https://api.buycex.com/Index/marketInfo")
            .then((response) => response.json())
            .then((data) => {
                console.log("response marketInfo",data)
                console.log("response marketInfo",TOKEN)
                // Create an array to store coin data
                const coinsData = [];

                // Iterate over the market data to extract coin information
                data.data.market.forEach((coin) => {
                    // Get the coin name and icon URL
                    const name = coin.ticker.split("_")[0].toUpperCase();
                    const icon = coin.icon;

                    // Add the coin to the array if it's not already present
                    if (
                        name !== "LTC" &&
                        name !== "DOGE" &&
                        !coinsData.some((c) => c.value === name)
                    ) {
                        coinsData.push({
                            value: name,
                            label: name,
                            icon: icon,
                            onlySend: `Only send ${name}`,
                        });
                    }
                });

                // Manually add additional coin data
                coinsData.push(
                    {
                        value: "ADA",
                        label: "ADA",
                        icon: "https://api.buycex.com/Upload/coin/65f83ff22d26f.png",
                        onlySend: "Only send ADA",
                    },
                    {
                        value: "Avax",
                        label: "Avax",
                        icon: "https://api.buycex.com/Upload/coin/65f843f1daacc.png",
                        onlySend: "Only send Avax",
                    },
                    {
                        value: "USDT",
                        label: "USDT",
                        icon: "https://api.buycex.com/Upload/coin/USDT.png",
                        onlySend: "Only send USDT",
                    },
                    {
                        value: "TUSDT",
                        label: "TUSDT",
                        icon: "https://api.buycex.com/Upload/coin/65f98a1338aff.png",
                        title: "Tether USDT [TRC20](TUSDT)",
                        onlySend: "Only send USDT",
                    },
                    {
                        value: "BUSDT",
                        label: "BUSDT",
                        icon: "https://api.buycex.com/Upload/coin/65f98a1338aff.png",
                        title: "USDT [Bep20](BUSDT)",
                        onlySend: "Only send USDT",
                    }
                );

                // Set the state with the coin data
                setCoins(coinsData);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [TOKEN, ID, history]);

    useEffect(() => {
        if (!TOKEN || !ID) {
            history.push("/login");
            return;
        }

        if (selectedCoin && TOKEN && ID) {
            fetch(
                `https://api.buycex.com/Crypto/coinbalance/coin/${selectedCoin.value}`,
                {
                    headers: {
                        TOKEN: TOKEN,
                        ID: ID,
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.data) {
                        setNetworks([
                            { value: data.data.symbol, label: data.data.title },
                        ]);
                        setBalance(data.data.total);
                        setLimit(data.data.zhehe);
                        setFeesPercent(data.data.fees_percent);
                        setFeesFlat(data.data.fees_flat);
                    } else {
                        console.log("No data available");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [selectedCoin, TOKEN, ID, history]);

   

  
    const handleImageClick = (coin) => {
        setSelectedNetwork(null);
        setSelectedCoin(coin);
    };

    const handleCoinChange = (selectedOption) => {
        setSelectedCoin(selectedOption);
    };

    const handleNetworkChange = (selectedOption) => {
        setSelectedNetwork(selectedOption);
    };

    // Check if both coin network and withdrawal address are selected before continuing
    const handleButtonClick = () => {
        if (!selectedCoin) {
            toast.error("Please select a Coin");
            return;
        }
        if (!selectedNetwork) {
            toast.error("Please select a network.");
            return;
        }

        if (!withdrawAddress) {
            toast.error("Please provide a withdrawal address.");
            return;
        }
        if (!withdrawAmount) {
            toast.error("Please provide a withdrawal amount.");
            return;
        }
        setIsEmailVerificationModalVisible(true);

    };

    const handleWithdraw = async () => {
        // Check if withdrawal address is empty or null
        if (!withdrawAddress) {
            toast.error("Please provide a withdrawal address.");
            return;
        }

        // Check if the withdrawal address format matches the selected coin
        if (selectedCoin.value === "LTC" && !withdrawAddress.startsWith("L")) {
            toast.error("Invalid withdrawal address format for LTC.");
            return;
        }

        if (selectedCoin.value === "TRX") {
            if (!withdrawAddress.startsWith("TP")) {
                toast.error("Invalid withdrawal address format for TRX.");
                return;
            }

            if (withdrawAddress.length !== 34) {
                toast.error(
                    "Withdrawal address for TRX must be 34 characters long."
                );
                return;
            }
        }

        if (selectedCoin.value === "XRP") {
            if (!withdrawAddress.startsWith("r")) {
                toast.error("Invalid withdrawal address format for XRP.");
                return;
            }

            if (withdrawAddress.length !== 34) {
                toast.error(
                    "Withdrawal address for XRP must be 34 characters long."
                );
                return;
            }
        }

        if (selectedCoin.value === "TUSDT") {
            if (!withdrawAddress.startsWith("TP")) {
                toast.error("Invalid withdrawal address format for TUSDT.");
                return;
            }

            if (withdrawAddress.length !== 34) {
                toast.error(
                    "Withdrawal address for TUSDT must be 34 characters long."
                );
                return;
            }
        }

     
        

      

      

        const withdrawalInputs = {
            coin: selectedCoin.value.toLowerCase(),
            num: withdrawAmount,
            addr: withdrawAddress,
            ga: g2faOtp,
         //   verify: "email", // Example, adjust as needed
        };
        console.log("withdrawalInputs",withdrawalInputs)

        try {
            console.log("withdrawalInputs",withdrawalInputs)
            const response = await dispatch(doWithdraw(TOKEN, ID, withdrawalInputs));
            console.log("withdrawalInputs response",response)
            if (response?.data?.status === 1) {
                toast.success("Successfully transferred the balance.");
                setIsVisible(false);
               
                setWithdrawAddress("");
                setWithdrawAmount("");
                setG2faOtp("");
                setOtp("");
            
            } else {
                toast.error(`Error: ${response?.data?.data || "Something went wrong."}`);
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred while processing the withdrawal.");
        }
    };
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: "10px",
            height: "55px",
            backgroundColor: state.isFocused ? "transparent" : "transparent",
            border: state.isFocused ? "2px solid #EFB81C" : "1px solid grey",
            boxShadow: "none",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: document.documentElement.classList.contains("dark")
                ? "#151415"
                : "white",
            color: document.documentElement.classList.contains("dark")
                ? "white"
                : "black",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: document.documentElement.classList.contains("dark")
                ? "white"
                : "black",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: document.documentElement.classList.contains("dark")
                ? "gray"
                : "gray",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? "#EFB81C"
                : state.isFocused
                    ? document.documentElement.classList.contains("dark")
                        ? "#151415"
                        : "#EFB81C"
                    : "transparent",
            color: state.isSelected ? "black" : "white",
        }),
    };
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate API call
        setTimeout(() => {
            setLoading(false);
        }, 800); // simulate 2 seconds loading time
    }, []);

    return (
        <>
            <section className="dark:bg-black">
                <div className="mt-6">
                    <WithdrawalRoutingNavbar />
                </div>
                <div className="gap-20 container w-100">
                    <div className="flex mt-4 justify-between w-full">
                        <div className="  mb-5 mt-[10 px] md:mt-5">
                            <b className="font-semibold text-[22px] md:text-3xl">
                                Withdraw
                            </b>
                        </div>
                    </div>

                    <section className="flex flex-col w-full gap-20 lg:flex-row">
                        <section className="w-full flex lg:w-1/2 lg:min-w-[53%]">
                            {/* {message && (
                                <div className="mt-4 text-lg text-center text-red-500">
                                    {message}
                                </div>
                            )} */}

                            <div className="flex gap-5 border rounded-[20px] p-6 w-full pb-8 dark:bg-[#1A191B]">
                                <div
                                    className="w-1/6"
                                    style={{ width: "8%" }}
                                >
                                    <div className="flex items-center justify-end">
                                        <div className="flex flex-col items-center">
                                            {/* First Icon with Diamond Shape */}
                                            <div className={`flex items-center justify-center w-8 h-8 rounded-md ${selectedCoin ? 'bg-[#EFB81C] text-white' : 'bg-green-500 text-white'}`}>
                                                <span className="transform text-md font-semibold -rotate-45">
                                                    {selectedCoin ? <FaCheck /> : '1'}
                                                </span>
                                            </div>

                                            {/* Vertical Line */}
                                            <div className={`w-0.5 h-[5.5rem] ${selectedCoin ? 'bg-[#EFB81C] text-white' : 'bg-green-500 text-white'}`}></div>

                                            {/* Second Icon with Diamond Shape */}
                                            <div className="flex items-center justify-center w-6 h-6">
                                            <div className={`flex items-center justify-center w-8 h-8 rounded-md ${withdrawAddress ? 'bg-[#EFB81C] text-white' : 'bg-green-500 text-white'}`}>
                                                    <span className="transform -rotate-45 text-md font-semibold">{withdrawAddress ? <FaCheck /> : '2'}</span>
                                                </div>
                                            </div>


                                            {/* Vertical Line */}
                                            <div className={`w-0.5 h-[8.5rem] ${withdrawAddress ? 'bg-[#EFB81C] text-white' : 'bg-green-500 text-white'}`}></div>

                                            {/* Third Icon with Diamond Shape */}
                                            <div className="flex items-center justify-center w-6 h-6">
                                            <div className={`flex items-center justify-center w-8 h-8 rounded-md ${selectedNetwork ? 'bg-[#EFB81C] text-white' : 'bg-green-500 text-white'}`}>
                                                    <span className="transform -rotate-45 text-white font-semibold">{selectedNetwork ? <FaCheck /> : '3'}</span>
                                                </div>
                                            </div>
                                            {/* Vertical Line */}
                                            <div className={`w-0.5 h-[4.5rem] ${withdrawAddress ? 'bg-[#EFB81C] text-white' : 'bg-green-500 text-white'}`}></div>

                                            {/* Third Icon with Diamond Shape */}
                                            <div className="flex items-center justify-center w-6 h-6">
                                            <div className={`flex items-center justify-center w-8 h-8 rounded-md ${withdrawAmount ? 'bg-[#EFB81C] text-white' : 'bg-green-500 text-white'}`}>
                                                    <span className="transform -rotate-45 text-white font-semibold">{withdrawAmount ? <FaCheck /> : '4'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="w-full">
                                    <div className="flex flex-col lg:flex-row w-full gap-6 justify-between">
                                        <div className="w-full">
                                            <CoinSelect
                                                coins={coins}
                                                selectedCrypto={selectedCoin}
                                                setSelectedCrypto={setSelectedCoin}
                                                handleImageClick={handleImageClick}
                                            />
                                        </div>
                                    </div>
                                    <form>

                                        <div className="flex flex-col lg:flex-row w-full mt-5 gap-6 justify-between">
                                            <div
                                                className="flex gap-5 w-full text-base font-medium border-b-[1px] border-gray-300"
                                                style={{ height: "2.75rem" }}
                                            >
                                                <div
                                                    className={`flex items-center ${showOnChainWithdrawal
                                                        ? "font-bold dark:text-white border-b-2 border-yellow-500"
                                                        : "text-gray-500"
                                                        }`}
                                                >
                                                    <button
                                                        onClick={
                                                            handleOnChainWithdrawalClick
                                                        }
                                                    >
                                                        <div className="text-sm">
                                                            Address
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        {showOnChainWithdrawal && (
                                            <>
                                                <div className="flex flex-col lg:flex-row w-full mt-6 gap-6 justify-between">
                                                    <div className="w-full">
                                                        <p className="text-[#81858c] text-sm mb-1">Withdrawal address</p>
                                                        <input
                                                            type="text"
                                                            className="bg-[#f3f5f7] h-14 rounded-[10px] text-gray-900 text-sm focus:ring-1 focus:ring-[#EFB81C] focus:border-[#EFB81C] dark:border-[#EFB81C] focus:outline-none block w-full p-2.5 dark:bg-transparent dark:placeholder-gray-400 dark:text-white dark:focus:!border-[#EFB81C] dark:focus:outline-none"
                                                            placeholder="Withdrawal address"
                                                            value={withdrawAddress}
                                                            onChange={(e) =>
                                                                setWithdrawAddress(
                                                                    e.target.value
                                                                )
                                                            }
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <NetworkSelectDropdown
                                                    networks={networks}
                                                    selectedNetwork={selectedNetwork}
                                                    setSelectedNetwork={setSelectedNetwork}
                                                />
                                                <div className="flex flex-col lg:flex-row w-full mt-6 gap-6 justify-between">
                                                    <div className="w-full">
                                                        <p className="text-[#81858c] text-sm mb-1">Withdrawable Amount</p>
                                                        <input
                                                            type="number"
                                                            step="0.01"
                                                            min="0.01"
                                                            max={balance}
                                                            className="bg-[#f3f5f7] h-14 rounded-[10px] text-gray-900 text-sm focus:ring-1 focus:ring-[#EFB81C] focus:border-[#EFB81C] dark:border-[#EFB81C] focus:outline-none block w-full p-2.5 dark:bg-transparent dark:placeholder-gray-400 dark:text-white dark:focus:!border-[#EFB81C] dark:focus:outline-none"
                                                            placeholder="Withdrawable Amount"
                                                            value={withdrawAmount}
                                                            onChange={(e) => {
                                                                const amount =
                                                                    parseFloat(
                                                                        e.target.value
                                                                    );
                                                                if (
                                                                    amount <= balance &&
                                                                    amount >= 0.01
                                                                ) {
                                                                    setWithdrawAmount(
                                                                        amount
                                                                    );
                                                                } else if (
                                                                    amount > balance
                                                                ) {
                                                                    setWithdrawAmount(
                                                                        balance
                                                                    );
                                                                } else {
                                                                    setWithdrawAmount(
                                                                        0.01
                                                                    );
                                                                }
                                                            }}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex justify-end mt-4">
                                                    <ContinueBtn
                                                        onClick={handleButtonClick}
                                                    >
                                                        Continue
                                                    </ContinueBtn>
                                                    {isVisible && (
                                                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50 ">
                                                            <div
                                                                className="bg-white p-6 rounded-lg shadow-lg dark:!bg-zinc-900"
                                                                style={{
                                                                    width: "30%",
                                                                    minWidth:
                                                                        "350px",
                                                                }}
                                                            >
                                                                <div className="flex flex-col items-center justify-center">
                                                                    <div
                                                                        className="flex self-end cursor-pointer"
                                                                        onClick={
                                                                            handleButtonClick
                                                                        }
                                                                    >
                                                                        <AiOutlineClose />
                                                                    </div>
                                                                    <img
                                                                        src={
                                                                            withdrawKey
                                                                        }
                                                                        alt="withdrawKey"
                                                                        className="w-40"
                                                                    />
                                                              
                                                                    <button
                                                                        type="button"
                                                                        className="mt-4 pl-4 w-100 pr-4 px-4 py-2 rounded-lg bg-[#EFB81C] text-white"
                                                                        onClick={
                                                                            handleWithdraw
                                                                        }
                                                                    >
                                                                        Withdraw
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <CryptoEmailVerificationModal
                isVisible={isEmailVerificationModalVisible}
                onClose={handleEmailVerificationClose}
                onSuccess={handleEmailVerificationSuccess}
                otp={otp}
                setOtp={setOtp} 
                g2faOtp={g2faOtp}
                setG2faOtp={setG2faOtp} 
            />
                                            </>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </section>
                        <FAQSection/>
                    </section>
                </div>
                <CryptoWithdrawalRecord selectedCoin={selectedCoin} TOKEN={TOKEN} ID={ID} />

                <div className="container">
                    <FaQs />
                </div>
            </section>
        </>
    );
}

export default CryptoWithdraw;