import toast from "react-hot-toast";
import UserProfileRepository from "../../repository/UserRepository";
import {
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    FETCH_AUTH_STATUS_FAILURE,
    FETCH_AUTH_STATUS_REQUEST,
    FETCH_AUTH_STATUS_SUCCESS,
    GET_2FA_QR_FAILURE,
    GET_2FA_QR_REQUEST,
    GET_2FA_QR_SUCCESS,
    SET_2FA_FAILURE,
    SET_2FA_REQUEST,
    SET_2FA_SUCCESS,
    SAVE_ANTI_PISHING_FAILURE,
    SAVE_ANTI_PISHING_SUCCESS,
    SAVE_ANTI_PISHING_REQUEST,
    TOGGLE_SECURE_LOGGING,
    DISABLE_USER_ACCOUNT_FAILURE,
    DISABLE_USER_ACCOUNT_REQUEST,
    DISABLE_USER_ACCOUNT_SUCCESS,
    FETCH_BANK_STATUS_SUCCESS,
    FETCH_BANK_STATUS_FAILURE,
    FETCH_BANK_STATUS_REQUEST,
    FETCH_ANTI_PHISHING_CODE_REQUEST,
    FETCH_ANTI_PHISHING_CODE_SUCCESS,
    FETCH_ANTI_PHISHING_CODE_FAILURE,
    
} from "../types";

export const changePassword = (payload) => async (dispatch) => {
    dispatch({ type: CHANGE_PASSWORD_REQUEST });

    try {
        const api = await UserProfileRepository.changePassword(payload);
        const response = api.data.data;

        if (api.data.status === 1) {
            dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: response });
            toast.dismiss("error-toast");
            toast.success(response);
            return true;
        } else {
            dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: response });
            toast.dismiss("success-toast");
            toast.dismiss("error-toast");
            toast.error(response);
            return false;
        }
    } catch (error) {
        dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: error.message });
        return false;
    }
};

export const updatePassword = (payload) => async (dispatch) => {
    dispatch({ type: CHANGE_PASSWORD_REQUEST });

    try {
        const api = await UserProfileRepository.updatePass(payload);
        const response = api.data.data;

        if (api.data.status === 1) {
            dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: response });
            toast.dismiss("error-toast");
            toast.success(response);
            return true;
        } else {
            dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: response });
            toast.dismiss("success-toast");
            toast.dismiss("error-toast");
            toast.error(response);
            return false;
        }
    } catch (error) {
        dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: error.message });
        return false;
    }
};

export const fetchAuthStatus = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_AUTH_STATUS_REQUEST });

    try {
        const api = await UserProfileRepository.authCheck(userId, token);
        const response = api.data.data;

        if (api.data.status === 1) {
            dispatch({ type: FETCH_AUTH_STATUS_SUCCESS, payload: response });
            return response;
        } else {
            dispatch({ type: FETCH_AUTH_STATUS_FAILURE, payload: response });
            return response;
        }
    } catch (error) {
        dispatch({ type: FETCH_AUTH_STATUS_FAILURE, payload: error.message });
    }
};

export const bank = (userId, token, payload) => async (dispatch) => {
    dispatch({ type: FETCH_BANK_STATUS_REQUEST });

    try {
        const api = await UserProfileRepository.bank(userId, token, payload);

        if (api.data.status === 1) {
            dispatch({ type: FETCH_BANK_STATUS_SUCCESS, payload: api });
            toast.success(api.data.data.msg);
            return true;
        } else {
            dispatch({ type: FETCH_BANK_STATUS_FAILURE, payload: api });
            toast.error(api.data.data);
        }
    } catch (error) {
        dispatch({ type: FETCH_BANK_STATUS_FAILURE, payload: error.message });
    }
};

export const delbank =
    (userId, token, payload, onSuccess) => async (dispatch) => {
        dispatch({ type: FETCH_BANK_STATUS_REQUEST });

        try {
            const api = await UserProfileRepository.delbank(
                userId,
                token,
                payload
            );
            const response = api.data.data;

            if (api.data.status === 1) {
                dispatch({
                    type: FETCH_BANK_STATUS_SUCCESS,
                    payload: response,
                });
                toast.success(response);
                onSuccess(api.data);
                return true;
            } else {
                dispatch({
                    type: FETCH_BANK_STATUS_FAILURE,
                    payload: response,
                });
                toast.error(response);
            }
        } catch (error) {
            dispatch({
                type: FETCH_BANK_STATUS_FAILURE,
                payload: error.message,
            });
        }
    };

export const getbank = async (userId, token, onSuccess) => {
    try {
        const api = await UserProfileRepository.getbank(userId, token);
        const response = api.data;

        if (response.status === 1) {
            onSuccess(response);
        } else {
            toast.error("Failed to fetch bank details");
        }
    } catch (error) {
        console.error("Error fetching bank details:", error.message);
    }
};

export const get2FA = (userId, token) => async (dispatch) => {
    dispatch({ type: GET_2FA_QR_REQUEST });

    try {
        const api = await UserProfileRepository.get2FA(userId, token);
        const response = {
            Asecret: api.data.Asecret,
            qrCodeUrl: api.data.qrCodeUrl,
        };

        if (api.data.status === 1) {
            dispatch({ type: GET_2FA_QR_SUCCESS, payload: response });
            return response;
        } else {
            dispatch({ type: GET_2FA_QR_FAILURE, payload: response });
            return response;
        }
    } catch (error) {
        dispatch({ type: GET_2FA_QR_FAILURE, payload: error.message });
    }
};

export const set2FA = (payload, onSuccess) => async (dispatch) => {
    dispatch({ type: SET_2FA_REQUEST });

    try {
        const api = await UserProfileRepository.set2FA(payload);
        const response = api.data.data;

        if (api.data.status === 1) {
            dispatch({ type: SET_2FA_SUCCESS, payload: response });
            toast.dismiss("error-toast");
            toast.success(response);
            if (onSuccess) {
                if (typeof payload.ga === "number")
                    onSuccess({
                        ...payload,
                        ga: localStorage.getItem("secret"),
                    });
                else onSuccess(payload);
            }
        } else {
            dispatch({ type: SET_2FA_FAILURE, payload: response });
            toast.dismiss("success-toast");
            toast.error(response);
        }
    } catch (error) {
        dispatch({ type: SET_2FA_FAILURE, payload: error.message });
    }
};

export const updateGA =
    (payload, onSuccess = null) =>
    async (dispatch) => {
        dispatch({ type: UPDATE_GA_REQUEST });

        try {
            const api = await UserProfileRepository.updateGA(payload);
            const response = api.data.data;

            if (api.data.status === 1) {
                dispatch({ type: UPDATE_GA_SUCCESS, payload: "success" });
                toast.dismiss("error-toast");
                toast.success("success");
                if (onSuccess) {
                    onSuccess();
                }
            } else {
                dispatch({ type: UPDATE_GA_FAILURE, payload: response });
                toast.dismiss("success-toast");
                toast.error(response);
            }
        } catch (error) {
            dispatch({ type: UPDATE_GA_FAILURE, payload: error.message });
        }
    };

export const saveAntiPishing =
    (payload, onSuccess, onFailure) => async (dispatch) => {
        dispatch({ type: SAVE_ANTI_PISHING_REQUEST });

        try {
            const api = await UserProfileRepository.SaveAntiPishing(payload);
            const response = api.data.data;

            if (api.data.status === 1) {
                dispatch({
                    type: SAVE_ANTI_PISHING_SUCCESS,
                    payload: response,
                });
                toast.dismiss("error-toast");
                toast.success(response);
                if (onSuccess) onSuccess();
            } else {
                dispatch({
                    type: SAVE_ANTI_PISHING_FAILURE,
                    payload: response,
                });
                toast.dismiss("success-toast");
                toast.error(response);
                if (onFailure) onFailure();
            }
        } catch (error) {
            dispatch({
                type: SAVE_ANTI_PISHING_FAILURE,
                payload: error.message,
            });
            if (onFailure) onFailure();
        }
    };

export const toggleSecureLogging = () => ({
    type: TOGGLE_SECURE_LOGGING,
});

export const disableAccount = (payload, userId, token) => async (dispatch) => {
    dispatch({ type: DISABLE_USER_ACCOUNT_REQUEST });

    try {
        const api = await UserProfileRepository.disableAccount(
            payload,
            userId,
            token
        );
        const response = api.data.data;

        if (api.data.status === 1) {
            dispatch({ type: DISABLE_USER_ACCOUNT_SUCCESS, payload: response });
            toast.dismiss("error-toast");
            toast.success(response);
        } else {
            dispatch({ type: DISABLE_USER_ACCOUNT_FAILURE, payload: response });
            toast.dismiss("success-toast");
            toast.dismiss("error-toast");
            toast.error(response);
        }
    } catch (error) {
        dispatch({
            type: DISABLE_USER_ACCOUNT_FAILURE,
            payload: error.message,
        });
    }
};

export const fetchAntiPhishingCode = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_ANTI_PHISHING_CODE_REQUEST });

    try {
        const api = await UserProfileRepository.getUserAntiPhishingCode(userId, token);
        const response = api.data;

        if (response.status === 1) {
            // Dispatch success with the anti-phishing code
            dispatch({ type: FETCH_ANTI_PHISHING_CODE_SUCCESS, payload: response.data });
           
            return response; // Return only the `data` object
        } else {
            // Handle API-level failure
            dispatch({ type: FETCH_ANTI_PHISHING_CODE_FAILURE, payload: response });
            toast.error("Failed to fetch anti-phishing code");
            return response;
        }
    } catch (error) {
        // Handle network or server errors
        console.error("Error occurred while fetching anti-phishing code:", error);
        dispatch({ type: FETCH_ANTI_PHISHING_CODE_FAILURE, payload: error.message });
        toast.error("An error occurred while fetching anti-phishing code");
    }
};


