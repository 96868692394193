import React, { useState, useRef, useEffect } from "react";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import styled from 'styled-components';
import { IoSearchOutline } from "react-icons/io5";
import { Input } from "antd";

const Dropdown = ({
    label,
    options,
    initialSelected,
    onSelect,
    isOpen,
    onToggle,
    width = "auto",
    isSearchable,
}) => {
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null); // Ref for the button
    const [search, setSearch] = useState("");
    const [selected, setSelected] = useState(initialSelected);
    const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);
    const filteredOptions = isSearchable
        ? options.filter((option) =>
            option.toLowerCase().includes(search.toLowerCase())
        )
        : options;

    useEffect(() => {
        setSelected(initialSelected);
    }, [initialSelected]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleOptionClick = (option) => {
        setSelected(option);
        onSelect(option);
        setIsDropdownOpen(false);
        setSearch("");
    };

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setSearch("");
        onToggle(!isDropdownOpen);
    };

    return (
        <div
            className="relative min-w-[190px] inline-block w-1/2 text-left"
            style={{ width }}
            onMouseLeave={() => setIsDropdownOpen(false)} // Close dropdown on mouse leave
        >
            <div
                ref={buttonRef}
                onMouseEnter={() => setIsDropdownOpen(true)} // Open dropdown on hover
            >
                <button
                    onClick={handleToggleDropdown}
                    type="button"
                    className="inline-flex border-[0px] border-[#DBDBDB] items-center w-full justify-between gap-x-1.5 rounded-lg px-3 py-3 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    aria-expanded={isDropdownOpen}
                    aria-haspopup="true"
                >
                    <div className="flex justify-between w-full">
                        <h6 className="text-sm text-[#707A8A]">{label}</h6>
                        <div className="flex items-center gap-2">
                            <p className="font-medium">
                                <span className="text-sm">{selected}</span>
                            </p>
                            {isDropdownOpen ? (
                                <MdOutlineArrowDropUp className="text-[18px] text-[#707A8A]" />
                            ) : (
                                <MdOutlineArrowDropDown className="text-[18px] text-[#707A8A]" />
                            )}
                        </div>
                    </div>
                </button>
            </div>

            {/* Invisible hover area to prevent dropdown from closing */}
            {isDropdownOpen && (
                <div
                    className="absolute left-0 right-0 h-2 cursor-default"
                    style={{ top: '100%' }}
                    onMouseEnter={() => setIsDropdownOpen(true)} // Keep dropdown open on hover
                    onMouseLeave={() => setIsDropdownOpen(false)} // Close dropdown on mouse leave
                ></div>
            )}

            {isDropdownOpen && (
                <div
                    ref={dropdownRef}
                    onMouseEnter={() => setIsDropdownOpen(true)} // Keep dropdown open on hover
                    onMouseLeave={() => setIsDropdownOpen(false)} // Close dropdown on mouse leave
                    className="absolute z-10 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    style={{ top: 'calc(100% + 8px)' }} // Simulate the gap
                    aria-orientation="vertical"
                    tabIndex="-1"
                >
                    <div className="py-1">
                        <div className="m-2">
                            {isSearchable && (
                                <div>
                                    <StyledInput
                                        type="text"
                                        placeholder="search options"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                                        prefix={<IoSearchOutline />}
                                    />
                                </div>
                            )}
                        </div>
                        <ul className="max-h-60 overflow-auto">
                            {filteredOptions.length > 0 ? (
                                filteredOptions.map((option) => (
                                    <li
                                        key={option}
                                        onClick={() => handleOptionClick(option)}
                                        className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                                    >
                                        <h6 className="text-sm text-gray-700">{option}</h6>
                                    </li>
                                ))
                            ) : (
                                <li className="px-4 py-2 text-[14px] text-center text-gray-500">
                                    No results found
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};
const StyledInput = styled(Input)`
  padding: 0.55rem;
  font-weight: bold;
  color: #707A8A;
  border: 2px solid #DBDBDB;
  transition: border-color 0.3s ease; /* Smooth transition for border color */

  &:hover {
    border-color: #EFB81C; /* Border color on hover */
  }

  &:focus, 
  &:focus-within {
    border-color: #EFB81C; /* Border color on focus */
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2); /* Shadow color on focus */
    outline: none; /* Removes default outline */
  }
`;
export default Dropdown;
