import React from 'react'
import { LuArrowUpLeftFromCircle } from "react-icons/lu";
import { FaArrowUpRightDots } from "react-icons/fa6";
import { IoMdTrendingUp } from "react-icons/io";

function CaptureTrading() {
    return (
        <div className='mb-6'>
            <div className="market-top-coins-header flex items-center justify-between mb-6">
                <h2 className="market-top-coins-header__title container text-[34px] font-bold">
                    Capture Every Trading
                </h2>
            </div>
            <div className='flex bg-black mx-2 py-4 rounded-3xl'>
                <div className='px-4 flex flex-col gap-5 my-4 mx-2'>
                    <LuArrowUpLeftFromCircle className='text-white text-[3rem]' />
                    <div>
                        <p className='text-white text-3xl font-semibold py-3'>Spot</p>
                        <p className='text-[#81858c]'>Over <span className='text-sm text-white font-medium'>800+</span> coins available. Buy and sell quickly with automatic calculations of average cost and PnL.</p>

                    </div>
                </div>
                <div className='px-4 flex flex-col gap-5 my-4 mx-2'>
                    <FaArrowUpRightDots className='text-white text-[3rem]' />
                    <div>
                        <p className='text-white text-3xl font-semibold py-3'>Futures</p>
                        <p className='text-[#81858c]'>Supports long/short positions with leverage and profit of up to <span className='text-sm text-white font-medium'>125x</span> from market volatilities.</p>

                    </div>
                </div>
                <div className='px-4 flex flex-col gap-5 my-4 mx-2'>
                    <IoMdTrendingUp className='text-white text-[3rem]' />
                    <div>
                        <p className='text-white text-3xl font-semibold py-3'>Grid Trading</p>
                        <p className='text-[#81858c]'>Create your personal trading strategy and profit <span className='text-sm text-white font-medium'>24/7</span> with spot grid and futures grid trading.</p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CaptureTrading