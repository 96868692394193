import React, { useState } from "react";
import icon from "assets/img/icon.svg";
import blackTick from "assets/img/kyccompletetick.svg";
import yellowTick from "assets/img/kycyellowtick.svg";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
const KYCVerification = () => {
    return (
        <div className=" min-h-screen flex flex-col gap-2 bg-[#f4f4f4] pt-4 pb-6 ">
            <div className=" inline-flex gap-2">
                <img src={icon} alt="" />
                <h1 className=" font-bold text-[21px] ">KYC</h1>
            </div>

            <div className=" bg-white rounded-xl py-8 px-8 flex-row flex justify-around items-center">
                <div className=" border-[1px] p-4 px-6 rounded-[10px] flex justify-center items-center flex-col">
                    <h1 className=" font-bold  text-[25px]">
                        Basic Verification
                    </h1>

                    <div className=" inline-flex gap-2 ">
                        <img src={yellowTick} alt="" />
                        <h1>Basic Verification Approved</h1>
                    </div>
                    <p className="text-[#9c9c9c] text-[13px]">
                        Incorrect Identity Information?{" "}
                        <span className="text-[#ffbe0b] text-[13px]">
                            Verify again
                        </span>
                    </p>
                </div>

                <div className=" flex flex-col gap-1 w-[300px] h-[120px]">
                    <div className="flex flex-row justify-between items-center text-[#9c9c9c]">
                        <span>Nationality</span>
                        <span>India</span>
                    </div>
                    <div className="flex flex-row justify-between items-center text-[#9c9c9c]">
                        <span>Country of Residence</span>
                        <span>India</span>
                    </div>
                    <div className="flex flex-row justify-between items-center text-[#9c9c9c]">
                        <span>Name</span>
                        <span>Name</span>
                    </div>
                    <div className="flex flex-row justify-between items-center text-[#9c9c9c]">
                        <span>ID Type</span>
                        <span>Passport</span>
                    </div>
                    <div className="flex flex-row justify-between items-center text-[#9c9c9c]">
                        <span>ID Number</span>
                        <span>USE***123</span>
                    </div>
                </div>
            </div>
            {/* part 2  */}
            <div className=" bg-white rounded-xl py-3 px-8 flex flex-col justify-center items-center">
                <h1 className=" font-semibold text-[20px]">
                    Advanced Verification
                </h1>
                <ol className=" list-decimal space-y-3 w-[70%] text-[#9c9c9c] mt-3">
                    <li className=" text-[15px]">
                        {" "}
                        To verify your account, you are required to provide an
                        address that matches the region of your Primary
                        Verification.
                    </li>
                    <li className=" text-[15px]">
                        The system will ask you to upload a document that
                        indicates the same address that you provided. The
                        document must include: your name, your address, the date
                        of issue, and the issuing authority.
                    </li>
                </ol>

                <div className=" w-full flex flex-row justify-center items-center gap-3 mt-8 mb-4">
                    <Link
                        to="/user/account/identity-verification/advance-verification"
                        className="text-white"
                    >
                        <button className=" bg-[#efb81c]  rounded-[10px] px-6 py-[5px] font-medium text-black">
                            I’m all set for ID Verification
                        </button>
                    </Link>
                </div>
            </div>

            <div className="flex flex-row w-full gap-6 mt-4">
                <div className=" w-fit px-10 p-3 bg-white shadow-lg rounded-xl">
                    <div className="flex justify-start items-center mb-2 gap-3">
                        <span className="bg-yellow-500  text-xs font-semibold px-3 py-2 rounded-full">
                            Level 1
                        </span>
                        <div className="inline-flex gap-1 bg-yellow-500 px-3 py-2 rounded-full ">
                            <img src={blackTick} alt="" />
                            <span className=" text-xs font-semibold ">
                                Completed
                            </span>
                        </div>
                    </div>
                    <h2 className="text-xl font-semibold mb-2">
                        Fiat Limit of 50K USD Daily
                    </h2>
                    <h3 className="text-lg  mb-2 text-[#9c9c9c]">
                        Requirements
                    </h3>
                    <ul className="list-disc list-inside font-semibold ">
                        <li>Personal information</li>
                        <li>Government-issued ID</li>
                        <li>Facial recognition</li>
                    </ul>
                </div>

                {/* box 2 */}
                <div className=" w-fit px-10 p-3 bg-white shadow-lg rounded-xl">
                    <div className="flex justify-start items-center mb-2 gap-3">
                        <span className="bg-[#cccccc] text-xs font-semibold px-3 py-2 rounded-full">
                            Level 2
                        </span>
                    </div>
                    <h2 className="text-xl font-semibold mb-2">
                        Fiat Limit of 2M USD Daily
                    </h2>
                    <h3 className="text-lg  mb-2 text-[#9c9c9c]">
                        Requirements
                    </h3>
                    <ul className="list-disc list-inside font-semibold ">
                        <li>Proof of Address</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default KYCVerification;
