import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function WithdrawalRoutingNavbar() {
  const location = useLocation();

  return (
    <>
      <div className="flex overflow-auto overflow-y-hidden !lg:overflow-hidden gap-0 leading-[130%] border-b dark:text-white">
        <div className='container !pl-[1rem] !pr-[1rem]'>
        <div className="flex gap-5 items-start grow-0 h-10">
          <div className="relative cursor-pointer">
            <Link 
              to="/crypto/withdraw" 
              className={`relative pb-[1.30rem] no-underline text-[14px]  dark:text-white hover:text-[#EFB81C] dark:hover:text-[#EFB81C] hover:no-underline ${
                location.pathname === '/crypto/withdraw' ? 'font-semibold text-[#EFB81C]' : 'text-[#707A8A]'
              }`}
            >
              Withdraw
              {location.pathname === '/crypto/withdraw' && (
                <span className="absolute left-1/2 bottom-0 w-[10px] h-[4px] mt-2 bg-[#EFB81C] transform -translate-x-1/2"></span>
              )}
            </Link>
          </div>
          <div className="relative cursor-pointer">
            <Link 
              to="/fiat/withdraw" 
              className={`relative pb-[1.30rem] no-underline text-[14px] whitespace-nowrap overflow-hidden text-ellipsis dark:text-white hover:text-[#EFB81C] dark:hover:text-white hover:no-underline ${
                location.pathname === '/fiat/withdraw' ? 'font-semibold text-[#EFB81C]' : 'text-[#707A8A]'
              }`}
            >
              Fiat Withdraw
              {location.pathname === '/fiat/withdraw' && (
                <span className="absolute left-1/2 bottom-0 w-[10px] h-[4px] mt-2 bg-[#EFB81C] transform -translate-x-1/2"></span>
              )}
            </Link>
          </div>
          <div className="relative cursor-pointer">
            <Link 
              to="/crypto/internal-pay" 
              className={`relative pb-[1.30rem] no-underline text-[14px] whitespace-nowrap overflow-hidden text-ellipsis hover:no-underline dark:text-white hover:text-[#EFB81C] dark:hover:text-white ${
                location.pathname === '/crypto/internal-pay' ? 'font-semibold text-[#EFB81C]' : 'text-[#707A8A]'
              }`}
            >
              Internal Pay
              {location.pathname === '/crypto/internal-pay' && (
                <span className="absolute left-1/2 bottom-0 w-[10px] h-[4px] bg-[#EFB81C] transform -translate-x-1/2"></span>
              )}
            </Link>
          </div>
          <div className="relative cursor-pointer">
            <Link 
              to="/crypto/transfer" 
              className={`relative pb-[1.30rem] no-underline text-[14px] hover:no-underline dark:text-white hover:text-[#EFB81C] dark:hover:text-white ${
                location.pathname === '/crypto/transfer' ? 'font-semibold text-[#EFB81C]' : 'text-[#707A8A]'
              }`}
            >
              Transfer
              {location.pathname === '/crypto/transfer' && (
                <span className="absolute left-1/2 bottom-0 w-[10px] h-[4px] bg-[#EFB81C] transform -translate-x-1/2"></span>
              )}
            </Link>
          </div>
          <div className="relative cursor-pointer">
            <Link 
              to="/crypto/convert" 
              className={`relative pb-[1.30rem] no-underline text-[14px]  hover:no-underline dark:text-white hover:text-[#EFB81C] dark:hover:text-white ${
                location.pathname === '/crypto/convert' ? 'font-semibold text-[#EFB81C]' : 'text-[#707A8A]'
              }`}
            >
              Convert
              {location.pathname === '/crypto/convert' && (
                <span className="absolute left-1/2 bottom-0 w-[10px] h-[4px] bg-[#EFB81C] transform -translate-x-1/2"></span>
              )}
            </Link>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default WithdrawalRoutingNavbar;
