import React, { useEffect, useRef, useState } from "react";
import { countries } from "../../../../data";
import { BiSolidLike } from "react-icons/bi";
import { AiFillClockCircle } from "react-icons/ai";
import { CgLoadbar } from "react-icons/cg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
    delpm,
    fetchp2pData,
    newad,
    SellTrade,
} from "../../../../store/actions/p2pActions";
import { MdCheckBoxOutlineBlank, MdOutlineArrowDropDown } from "react-icons/md";
import { IoIosCheckbox } from "react-icons/io";
import { HiCurrencyDollar } from "react-icons/hi2";
import { HiGlobeAlt } from "react-icons/hi2";
import { FaFilter, FaPlus } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";
import { HiRefresh } from "react-icons/hi";
import { MdVerified } from "react-icons/md";
import { AiFillDollarCircle } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { Modal } from "antd";
import AddFundsModal from "../../PostAds/AddFundsModal";
import BankTransfer from "./BankTransferModal";
import { useHistory } from "react-router-dom";
import { delbank, getbank } from "store/actions/userSecuritySettingsAction";
import { DeleteOutlined } from "@ant-design/icons";
import { toast } from "react-hot-toast";
import {
    getCountryCurrency,
    getUserIP,
} from "components/UserLocation/geolocationUtils";

function P2pTable() {
    const [coin, setCoin] = useState("CVM");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [type, setType] = useState("buy");
    const [data, setData] = useState([]);
    const [fiat_qty, setFiatQty] = useState(0);
    const [pm, setPm] = useState(1);
    const history = useHistory();
    const userid = useSelector((state) => state.authUser?.user?.ID);
    const usertoken = useSelector((state) => state.authUser?.user?.TOKEN);
    const [selectedTime, setSelectedTime] = useState("All");
    const [selectedOrders, setselectedOrders] = useState("All");
    const Verified = useSelector((state) => state?.userInfo?.userProfile?.kyc);
    const getPagedData = () => {
        const startIndex = (currentPage - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return data.length > 0 && data.slice(startIndex, endIndex);
    };
    const pageData = getPagedData();

    const truncateToFourDecimals = (value) => {
        // if (typeof value !== 'number') return '0.0000';

        const strValue = value.toString();
        const decimalIndex = strValue.indexOf(".");

        if (decimalIndex === -1) return strValue + ".000"; // If no decimal point, add '.0000'

        const truncated = strValue.slice(0, decimalIndex + 4); // Include 4 digits after the decimal point
        const [integerPart, fractionalPart] = truncated.split(".");

        return `${integerPart}.${(fractionalPart || "").padEnd(3, "0")}`; // Pad with zeros if necessary
    };

    const handleInputChange = (e) => {
        setFiatQty(e.target.value); // Update state with input value
    };

    const balance = useSelector((state) => state.p2p.dataa.user_balance);
    const { dataa, loading, error } = useSelector((state) => state.p2p);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        resetFields();
    };

    const handleLeftClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            resetFields();
        }
    };

    const handleRightClick = () => {
        const totalPages = Math.ceil(data.length / rowsPerPage);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            resetFields();
        }
    };

    const resetFields = () => {
        setSelectedAdId("");
        setReceiveAmount("");
        setSellAmount("");
        setSelectedBankId("");
        setSelectedBankDetails("");
    };

    const getPageRange = () => {
        const totalPages = Math.ceil(data.length / rowsPerPage);
        const visiblePages = Math.min(totalPages, 5); // Show a maximum of 5 pages
        const currentPageStart = Math.max(
            Math.min(
                currentPage - Math.floor(visiblePages / 2),
                totalPages - visiblePages + 1
            ),
            1
        );

        return Array.from(
            { length: visiblePages },
            (_, i) => currentPageStart + i
        );
    };

    const dispatch = useDispatch();
    const kycStatus = useSelector((state) => state?.userReducer);

    const [Currency, setCurrency] = useState("");
    const [selectedCurrency, setSelectedCurrency] = useState("");

    useEffect(() => {
        const initialize = async () => {
            const ip = await getUserIP();
            if (ip) {
                const { currency } = await getCountryCurrency(ip);
                if (currency) {
                    setCurrency(currency);
                    setSelectedCurrency(currency);
                }
            }
        };

        initialize();
    }, []);

    useEffect(() => {}, [Currency, selectedCurrency]);

    const [selectedRefresh, setSelectedRefresh] = useState("Do Not Refresh");

    const handleRefreshSelect = (refreshText) => {
        setSelectedRefresh(refreshText);
        setIsRefresh(false);
    };

    useEffect(() => {
        if (userid && usertoken) {
            const fiatValue =
                selectedCurrency === "Indian Rupees" ? "INR" : selectedCurrency;

            const fetchData = () => {
                dispatch(
                    fetchp2pData(
                        type,
                        coin,
                        fiatValue,
                        userid,
                        usertoken,
                        fiat_qty,
                        pm
                    )
                );
            };

            let interval;

            if (selectedRefresh === "Every 5 Secs") {
                interval = setInterval(fetchData, 5000);
            } else if (selectedRefresh === "Every 10 Secs") {
                interval = setInterval(fetchData, 10000);
            } else if (selectedRefresh === "Every 20 Secs") {
                interval = setInterval(fetchData, 20000);
            } else if (selectedRefresh === "Do Not Refresh") {
                fetchData();
            }

            return () => clearInterval(interval);
        }
    }, [
        selectedRefresh,
        type,
        coin,
        selectedCurrency,
        userid,
        usertoken,
        fiat_qty,
        pm,
        dispatch,
    ]);

    let selectedCurrencyImg = null;
    const handleCurrencySelect = (currency, img) => {
        setSelectedCurrency(currency);
        selectedCurrencyImg = img;
        setIsAmount(false);
    };
    const [isClicked, setIsClicked] = useState(false);
    const dropDownRef = useRef(null);
    const handleMethodSelect = (method) => {
        console.log("Selected Method:", method.name);
        setSelectedMethod(method.name);
        setPm(method.id);
        setIsPayment(false);
    };
    // DropDown States
    const [isAmount, setIsAmount] = useState(false);
    const [isPayment, setIsPayment] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);

    const [selectedMethods, setSelectedMethods] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState("Bank Transfer");
    const [selectedAdId, setSelectedAdId] = useState(null);
    const [secondAdChecked, setSecondAdChecked] = useState(true);
    const [forthadchecked, setForthadchecked] = useState(false);
    const isGa = useSelector((state) => state.authCheck.authStatus.ga);
    const handleSellClick = (id) => {
        if (!isGa || isGa === "null" || isGa === "undefined") {
            toast.error("Please set G2FA First.");
        } else if (Verified !== "1") {
            history.push("/identity-verification");
        } else {
            setSelectedAdId(id);
        }
    };

    const handleBuyClick = (id) => {
        if (!isGa || isGa === "null" || isGa === "undefined") {
            toast.error("Please set G2FA First.");
        } else if (Verified !== "1") {
            history.push("/identity-verification");
        } else {
            setSelectedAdId(id);
        }
    };

    const handleAdTypeToggle = (index) => {
        if (index === 2) {
            setSelectedAdId((prevSelectedAdId) =>
                prevSelectedAdId === 3 ? null : 3
            );
        }
    };

    const [tradeMode, setTradeMode] = useState("buy"); // 'buy' or 'sell'

    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;

    const handleBuyChat = () => {
        history.push("/trade/p2p/chat?mode=buy");
    };

    const [sellAmount, setSellAmount] = useState("");
    const [receiveAmount, setReceiveAmount] = useState("");

    const handleSellChange = (e, fixedPrice) => {
        const amount = e.target.value;
        setSellAmount(amount);

        if (fixedPrice) {
            const calculatedReceiveAmount = amount * fixedPrice;
            setReceiveAmount(calculatedReceiveAmount.toFixed(2));
        } else {
            console.error("Fixed price is undefined.");
        }
    };

    const handleSellChat = () => {
        if (!sellAmount) {
            toast.error("Amount is required.");
            return;
        }

        const types = type == "buy" ? 1 : 2;
        const payload = {
            id: selectedAdId,
            paywith: receiveAmount,
            amount: 222,
            type: types,
            paymethod: selectedBankId
                ? `bank:${selectedBankId}`
                : `pm:${selectedPmId || ""}`,
        };

        dispatch(SellTrade(userId, token, payload)).then((result) => {
            if (result) {
                const orderid = result.orderid;
                const istype = type == "buy" ? "buy" : "sell";
                history.push({
                    pathname: "/trade/p2p/chat",
                    state: {
                        mode: istype,
                        bankDetails: selectedBankDetails,
                        orderid: orderid,
                    },
                });
            }
        });
    };

    useEffect(() => {
        dispatch(newad(userId, token)).then((result) => {
            if (result) {
                setCoins(result.data.allow_cryptos || []);
                console.log(result.data.allow_cryptos);
            }
        });
    }, [dispatch, userId, token]);

    const handleButtonToggler = (str) => {
        if (str === "buy") {
            setIsClicked(false);
            setType("buy");
            resetFields();
        } else {
            setIsClicked(true);
            setType("sell");
            resetFields();
        }
    };

    const handleAmountDropDown = () => {
        setIsAmount(!isAmount);
        setIsPayment(false);
        setIsFilter(false);
        setIsRefresh(false);
        setIsOpen(false);
    };
    const handlePaymentDropDown = () => {
        setIsPayment(!isPayment);
        setIsAmount(false);
        setIsFilter(false);
        setIsRefresh(false);
        setIsOpen(false);
    };
    const handleFilterDropDown = () => {
        setIsFilter(!isFilter);
        setIsPayment(false);
        setIsAmount(false);
        setIsRefresh(false);
        setIsOpen(false);
    };
    const handleDNRefreshDropDown = () => {
        setIsRefresh(!isRefresh);
        setIsPayment(false);
        setIsAmount(false);
        setIsFilter(false);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropDownRef.current &&
                !dropDownRef.current.contains(event.target)
            ) {
                setIsAmount(null);
                setIsPayment(null);
                setIsFilter(null);
                setIsRefresh(null);
                setIsOpen(null);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const paymentMethods = Object.values(dataa?.fiat_methods || {});

    const [selectedCoin, setSelectedCoin] = useState("CVM");

    const [coins, setCoins] = useState([]);

    const [isFundsModalVisible, setIsFundsModalVisible] = useState(false);
    const handleAddFundsClick = () => {
        setIsFundsModalVisible(true); // Show Add Funds Modal when mode is 'sell'
    };
    const handleFundsModalCancel = () => {
        setIsFundsModalVisible(false); // Close Add Funds Modal
    };

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredMethods, setFilteredMethods] = useState(
        paymentMethods || []
    );

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        if (query === "") {
            setFilteredMethods(paymentMethods);
        } else {
            const filtered = paymentMethods.filter((method) =>
                method.name.toLowerCase().includes(query)
            );
            setFilteredMethods(filtered);
        }
    };
    useEffect(() => {
        if (dataa?.fiat_methods) {
            const methods = Object.values(dataa.fiat_methods);
            setFilteredMethods(methods);
        }
    }, [dataa]);
    useEffect(() => {
        if (dataa?.fiat_list) {
            setAllCurrencies(dataa.fiat_list);
            setFilteredCurrencies(dataa.fiat_list);
        }
    }, [dataa]);
    const [searchCurrencyQuery, setSearchCurrencyQuery] = useState("");
    const [filteredCurrencies, setFilteredCurrencies] = useState(
        dataa?.fiat_list || []
    );
    const [allCurrencies, setAllCurrencies] = useState(dataa?.fiat_list || []);

    const handleSearchCurrency = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchCurrencyQuery(query);
        if (query === "") {
            setFilteredCurrencies(allCurrencies);
        } else {
            const filtered = allCurrencies.filter((item) =>
                item.title.toLowerCase().includes(query)
            );
            setFilteredCurrencies(filtered);
        }
    };
    const [selectedFilter, setSelectedFilter] = useState("All Region");

    const handleFilterSelect = (filterText) => {
        setSelectedFilter(filterText);
        setIsFilter(false);
    };
    const filterOptions = countries.map((country, index) => ({
        id: `filter-item-${index}`,
        label: country.name,
    }));

    const filteredOptions = filterOptions.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsRefresh(false);
        setIsPayment(false);
        setIsAmount(false);
        setIsFilter(false);
        setIsOpen(true);
    };

    const handleSelect = (filter) => {
        setIsOpen(false);
    };
    const options = [{ label: "Apply last used filter" }];
    const adtypes = [
        { label: "Tradable Ads Only" },
        { label: "Verified Merchant Ads Only" },
        { label: "Ads With No Verification Required" },
        { label: "Only Show Pro Ads" },
    ];

    const sortOptions = [
        { label: "Price: Lowest to highest", value: "price" },
        { label: "Completed order number", value: "order" },
        { label: "Completion rate", value: "rate" },
    ];

    const timeLimits = ["All", "15", "30", "60", "120", "240", "360", "720"];

    useEffect(() => {
        console.log("dadadad", dataa);
    }, [dataa]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showBankTransferModal = () => {
        setIsModalVisible(true);
        setIsModalOpen(false);
    };

    const BankTransferhandleOk = () => {
        setIsModalVisible(false);
        console.log("Confirmed");
        setTimeout(() => {
            fetchBankDetails(userId, token);
        }, 1000);
    };

    const BankTransferhandleCancel = () => {
        setIsModalVisible(false);
        console.log("Cancelled");
        setTimeout(() => {
            fetchBankDetails(userId, token);
        }, 1000);
    };

    const [bankDetails, setBankDetails] = useState(null);

    const fetchBankDetails = (userId, token) => {
        getbank(userId, token, (response) => {
            console.log("API response:", response);
            if (response && response.data && response.data.userBankList) {
                setBankDetails(response.data); // Set bank details data
                console.log("Updated bankDetails:", response.data);
            } else {
                console.error("API response data missing or empty.");
            }
        });
    };
    const handleDelete = (bankId) => {
        const payload = {
            bank_id: bankId,
        };
        dispatch(delbank(userId, token, payload));
        setTimeout(() => {
            fetchBankDetails(userId, token);
        }, 1000);
    };

    const handleDelet = (methodId) => {
        const payload = {
            id: methodId,
        };
        dispatch(delpm(userId, token, payload));
        setTimeout(() => {
            fetchBankDetails(userId, token);
        }, 1000);
    };

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const showModal = (method) => {
        fetchBankDetails(userId, token);
        setSelectedMethods(method);
        handleModalOpen();
    };

    const handleMethodChange = (method) => {
        setSelectedMethod(method);
        setSelectedBankId(null);
    };

    const [selectedBankId, setSelectedBankId] = useState(null);
    const [selectedBankDetails, setSelectedBankDetails] = useState({
        name: "",
        accountNumber: "",
        bankAddress: "",
        bank: "",
    });
    const [selectedPmId, setSelectedPmId] = useState(null);
    const [selectedPmDetails, setSelectedPmDetails] = useState({
        method: "",
        information: "",
    });
    const [selectedPmName, setselectedPmName] = useState("");

    useEffect(() => {
        console.log("Selected Method:", selectedMethod);
        console.log("Bank Details:", bankDetails);
        console.log("userPaymethod:", bankDetails?.userPaymedthod);
    }, [selectedMethod, bankDetails]);

    useEffect(() => {
        console.log("selectedPmName:", selectedPmName);
    }, [selectedPmName]);

    const handleCardSelect = (bank) => {
        setSelectedBankId(bank.id);
        setSelectedBankDetails({
            name: bank.name,
            accountNumber: bank.bankcard.slice(-4),
            bankAddress: bank.bankaddr,
            bank: bank.bank,
        });
        handleCancel();
    };

    const handleCardSelec = (method) => {
        setSelectedPmId(method.id);
        setSelectedPmDetails({
            method: method.method,
            information: method.information,
        });
        handleCancel();
    };
    const handleCardSelected = (method) => {
        setselectedPmName(method);
        handleCancel();
    };
    const [selectedOption, setSelectedOption] = useState([]);

    const [sortDirection, setSortDirection] = useState("asc");

    const handleSortSelection = (value) => {
        if (selectedOption === value) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSelectedOption(value);
            setSortDirection("asc");
        }
    };

    const sortedData = () => {
        if (!dataa?.ads || Object.keys(dataa.ads).length === 0) {
            return [];
        }

        let sortedEntries = Object.entries(dataa.ads);

        if (selectedOption === "price") {
            // Sort by price (lowest to highest)
            sortedEntries = sortedEntries.sort((a, b) => {
                if (sortDirection === "asc") {
                    return a[1].fixed_price - b[1].fixed_price;
                } else {
                    return b[1].fixed_price - a[1].fixed_price;
                }
            });
        } else if (selectedOption === "order") {
            // Sort by completed order number (highest to lowest)
            sortedEntries = sortedEntries.sort((a, b) => {
                if (sortDirection === "asc") {
                    return a[1].orders - b[1].orders;
                } else {
                    return b[1].orders - a[1].orders;
                }
            });
        } else if (selectedOption === "rate") {
            // Sort by completion rate (highest to lowest)
            sortedEntries = sortedEntries.sort((a, b) => {
                if (sortDirection === "asc") {
                    return a[1].completion - b[1].completion;
                } else {
                    return b[1].completion - a[1].completion;
                }
            });
        }

        return sortedEntries;
    };

    const filteredData = () => {
        const sortedEntries = sortedData();

        return sortedEntries.filter(([key, item]) => {
            if (selectedTime !== "All" && item.time_limit !== selectedTime) {
                return false;
            }
            if (selectedOrders !== "All" && item.orders !== selectedOrders) {
                return false;
            }
            if (
                selectedFilter !== "All Region" &&
                item.region !== selectedFilter
            ) {
                return false;
            }
            if (!item.method.includes(selectedMethod)) {
                return false;
            }

            if (selectedAdId === 3 && item.tags && item.tags.length > 0) {
                return false;
            }
            if (forthadchecked && item.vip === 0) {
                return false;
            }
            return true;
        });
    };

    const [isActivated, setIsActivated] = useState(false);

    const handleSaveFilters = () => {
        localStorage.setItem("selectedAdId", JSON.stringify(selectedAdId));
        localStorage.setItem("selectedOption", JSON.stringify(selectedOption));
        localStorage.setItem("sortDirection", sortDirection);
        localStorage.setItem("selectedTime", selectedTime);
        localStorage.setItem("forthadchecked", JSON.stringify(forthadchecked));
    };

    const handleCheckboxToggle = () => {
        setIsActivated((prevState) => !prevState);
    };

    useEffect(() => {
        if (isActivated) {
            const storedselectedAdId = JSON.parse(
                localStorage.getItem("selectedAdId")
            );
            const storedSelectedOption = JSON.parse(
                localStorage.getItem("selectedOption")
            );
            const storedSortDirection = localStorage.getItem("sortDirection");
            const storedSelectedTime = localStorage.getItem("selectedTime");
            const storedForthadchecked = JSON.parse(
                localStorage.getItem("forthadchecked")
            );

            if (storedselectedAdId !== null)
                setSelectedAdId(storedselectedAdId);
            if (storedSelectedOption) setSelectedOption(storedSelectedOption);
            if (storedSortDirection) setSortDirection(storedSortDirection);
            if (storedSelectedTime) setSelectedTime(storedSelectedTime);
            if (storedForthadchecked !== null)
                setForthadchecked(storedForthadchecked);
        } else {
            handleReset();
        }
    }, [isActivated]);

    const handleReset = () => {
        setSelectedAdId(null);
        setSecondAdChecked(true);
        setSelectedOption([]);
        setSortDirection("asc");
        setSelectedTime("All");
        setForthadchecked(false);
    };

    return (
        <>
            {/* buy/sell Buttons */}
            <div className="flex flex-col justify-start w-full gap-2 pb-4 mt-5">
                <div className="pr-3 flex gap-3 h-[40px] text-white font-medium">
                    <div
                        class="inline-flex rounded-lg p-1 bg-white border-[2px] border-[#e5e7eb]"
                        role="group"
                    >
                        <button
                            type="button"
                            onClick={() => handleButtonToggler("buy")}
                            className={`${
                                !isClicked
                                    ? "bg-[#EAECEF] text-black"
                                    : "text-[#707A8A]"
                            } rounded-md px-8 py-1 font-semibold text-[14px] transition-colors duration-100`}
                        >
                            Buy
                        </button>
                        <button
                            type="button"
                            onClick={() => handleButtonToggler("sell")}
                            className={`${
                                isClicked
                                    ? "bg-[#EAECEF] text-black"
                                    : "text-[#707A8A]"
                            } rounded-md px-8 py-1 font-semibold text-[14px] transition-colors duration-100`}
                        >
                            Sell
                        </button>
                    </div>
                    {/* Dropdown Content */}
                    <div>
                        <div className="flex py-1" role="none">
                            {coins.map((coin) => (
                                <h6
                                    key={coin}
                                    className={`px-4 py-2 text-[13px] cursor-pointer ${
                                        selectedCoin === coin
                                            ? "text-[#EFB81C]"
                                            : "text-[#707A8A]"
                                    }`}
                                    role="option"
                                    tabIndex="-1"
                                    onClick={() => {
                                        setSelectedCoin(coin);
                                        setCoin(coin);
                                        resetFields();
                                    }}
                                >
                                    {coin}
                                </h6>
                            ))}
                        </div>
                    </div>
                </div>
                {/*  <div>
           P2p Popup Box 
          {isPopupOpen && (
            <P2pPopup
              onClose={handleClosePopup}
              onActivate={handleActivateClick}
            />
          )}
          {isPopup2Open && <P2pPopupActive onClose={handleClosePopupActive} />}
        </div>*/}
                {/* Table Navbar */}
                <div
                    ref={dropDownRef}
                    className="relative flex items-center justify-start text-black gap-x-4"
                >
                    {/* Amount Input with Dropdown */}
                    <div
                        tabIndex="0"
                        style={{
                            outline: "none", // Remove default outline
                            border: "1px solid #DBDBDB", // Ensure border is visible
                        }}
                        className="relative flex items-center rounded-lg font-normal text-[12px] focus:ring-[1px] focus:ring-[#EFB81C]"
                        onFocus={(e) =>
                            (e.currentTarget.style.boxShadow =
                                "0 0 0 1px rgba(239, 184, 28, 1)")
                        } // Add custom focus style
                        onBlur={(e) =>
                            (e.currentTarget.style.boxShadow = "none")
                        } // Remove custom focus style when not focused
                    >
                        {/* Input Field for Amount */}
                        <input
                            type="text"
                            placeholder="Transaction Amount"
                            className="flex-grow px-3 py-[8px] bg-transparent border-r border-transparent rounded-l-md focus:outline-none"
                            value={fiat_qty} // Bind input value to state
                            onChange={handleInputChange}
                        />

                        {/* Dropdown Button for Currency Selection */}
                        <button
                            onClick={handleAmountDropDown}
                            type="button"
                            className="relative flex items-center justify-center w-1/2 px-4 py-2 text-sm font-semibold text-gray-900 border-none rounded-md hover:bg-gray-100"
                            id="filter-button"
                            aria-expanded={isAmount}
                            aria-haspopup="true"
                        >
                            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 h-[20px] w-0 border-l border-slate-300"></span>

                            <div className="flex items-center gap-3">
                                <div className="flex items-center gap-1">
                                    <HiCurrencyDollar />
                                    <span className="text-[14px]">
                                        {selectedCurrency}
                                    </span>
                                </div>
                                <MdOutlineArrowDropDown className="text-[18px] text-[#707A8A]" />
                            </div>
                        </button>

                        {/* Dropdown Content */}
                        {isAmount && (
                            <div
                                className="absolute left-0 z-10 w-full mt-2 bg-white rounded-md shadow-lg top-full ring-1 ring-black ring-opacity-5 focus:outline-none"
                                aria-orientation="vertical"
                                tabIndex="-1"
                            >
                                <div
                                    className="py-1 max-h-[300px] overflow-auto"
                                    role="none"
                                >
                                    {/* Search Input */}
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="block w-full px-4 py-2 mb-1 text-sm text-gray-900 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500"
                                        onChange={handleSearchCurrency}
                                        value={searchCurrencyQuery}
                                    />
                                    {/* Dropdown Items */}
                                    {filteredCurrencies.map((item) => (
                                        <h6
                                            key={item.title}
                                            className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                                            role="filteritem"
                                            tabIndex="-1"
                                            onClick={() =>
                                                handleCurrencySelect(
                                                    item.title,
                                                    item.img
                                                )
                                            }
                                        >
                                            {item.title}
                                        </h6>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Payment Dropdown */}
                    <div className="relative inline-block w-1/2 text-left">
                        <div>
                            <button
                                onClick={handlePaymentDropDown}
                                type="button"
                                className="inline-flex items-center w-full border-[0px] border-[#DBDBDB] justify-between gap-x-1.5 rounded-lg px-4 py-2 text-sm font-semibold text-gray-900 ring-[1px] ring-inset ring-gray-300 hover:bg-gray-50"
                                id="filter-button"
                                aria-expanded={isPayment}
                                aria-haspopup="true"
                            >
                                <h6>{selectedMethod}</h6>
                                <MdOutlineArrowDropDown className="text-[18px] text-[#707A8A]" />
                            </button>
                        </div>

                        {/* DropDown Content */}
                        <div
                            className={`${
                                isPayment ? "absolute" : "hidden"
                            } z-10 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                            aria-orientation="vertical"
                            tabIndex="-1"
                        >
                            <div className="py-1" role="none">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="block w-full px-4 py-2 text-sm text-gray-900 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500"
                                    onChange={handleSearch}
                                    value={searchQuery}
                                />
                                {/* <h6
                  className="block px-4 py-2 text-sm text-gray-700 border-b-2 cursor-pointer"
                  role="filteritem"
                  tabIndex="-1"
                  onClick={() => handleMethodSelect("All")}
                >
                  <div className="flex items-center gap-2">
                    {selectedMethod === "All" ? (
                      <IoIosCheckbox className="text-[#EFB81C]" />
                    ) : (
                      <MdCheckBoxOutlineBlank className="text-[#EFB81C]" />
                    )}
                    All Payment Methods
                  </div>
                </h6> */}
                                {filteredMethods.map((item) => (
                                    <h6
                                        key={item.id}
                                        className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                        role="filteritem"
                                        tabIndex="-1"
                                        onClick={() => handleMethodSelect(item)}
                                    >
                                        <div className="flex items-center gap-2">
                                            {selectedMethod === item.name ? (
                                                <IoIosCheckbox className="text-[#EFB81C]" />
                                            ) : (
                                                <MdCheckBoxOutlineBlank className="text-[#EFB81C]" />
                                            )}
                                            {item.name}
                                        </div>
                                    </h6>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* All Region  */}
                    <div className="relative inline-block w-1/2 text-left">
                        <div>
                            <button
                                onClick={handleFilterDropDown}
                                type="button"
                                className="inline-flex border-[0px] border-[#DBDBDB] items-center w-full justify-between gap-x-1.5 rounded-lg px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                id="filter-button"
                                aria-expanded={isFilter}
                                aria-haspopup="true"
                            >
                                <div className="flex items-center gap-2">
                                    {" "}
                                    <HiGlobeAlt />
                                    <h6 className="text-[14px]">
                                        {selectedFilter}
                                    </h6>{" "}
                                </div>
                                {/* Display the selected filter */}
                                <MdOutlineArrowDropDown className="text-[18px] text-[#707A8A]" />
                            </button>
                        </div>

                        {/* DropDown Content */}
                        <div
                            className={`${
                                isFilter ? "absolute" : "hidden"
                            } z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                            aria-orientation="vertical"
                            tabIndex="-1"
                        >
                            <div className="py-1" role="none">
                                {/* Search Input */}
                                <div className="">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchQuery}
                                        onChange={(e) =>
                                            setSearchQuery(e.target.value)
                                        }
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                    />
                                </div>

                                {/* Filter Options */}
                                {filteredOptions.map((option) => (
                                    <div
                                        key={option.id}
                                        className="flex items-center px-4 py-2 cursor-pointer"
                                        onClick={() =>
                                            handleFilterSelect(option.label)
                                        }
                                    >
                                        <h6 className="text-sm text-gray-700">
                                            {option.label}
                                        </h6>
                                        {option.description && (
                                            <div className="bg-[#fff1cc] rounded-xl pl-2 pr-2 ml-2">
                                                <span className="text-[12px] text-[#EFB81C]">
                                                    {option.description}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="relative w-full">
                        <div className="flex justify-between w-full">
                            {/* Filter Dropdown Button */}
                            <button
                                onClick={toggleDropdown}
                                type="button"
                                className="flex items-center justify-center px-4 py-2 border-2 border-gray-300 rounded-lg"
                            >
                                <FaFilter />
                            </button>

                            {/* Filter Dropdown Content */}
                            <div
                                className={`${
                                    isOpen ? "block" : "hidden"
                                } absolute z-50 top-full left-0 mt-2 w-96 origin-top-right py-2 px-3 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
                            >
                                <p className="text-xs text-gray-500">Setting</p>

                                {options.map((option, index) => (
                                    <div
                                        className="flex items-center justify-between"
                                        key={index}
                                    >
                                        <div className="flex items-center">
                                            <p className="px-2 py-2 text-sm text-gray-800">
                                                {option.label}
                                            </p>
                                            {index === 0 && (
                                                <FaCircleInfo className="text-sm text-gray-500" />
                                            )}
                                        </div>

                                        {/* Label and checkbox container */}
                                        <label className="inline-flex items-center cursor-pointer">
                                            {/* Visible checkbox */}
                                            <input
                                                type="checkbox"
                                                value=""
                                                onChange={handleCheckboxToggle}
                                                className="peer sr-only" // This hides the checkbox visually but keeps it functional
                                            />

                                            {/* Custom styled switch */}
                                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-[#EFB81C] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-focus:outline-none dark:bg-gray-700 peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"></div>
                                        </label>
                                    </div>
                                ))}

                                <p className="mt-2 text-xs text-gray-500">
                                    Ad Types
                                </p>

                                {adtypes.map((adtype, index) => (
                                    <div
                                        className="flex items-center justify-between"
                                        key={index}
                                    >
                                        <div className="flex items-center">
                                            <p className="px-2 py-2 text-sm text-gray-800">
                                                {adtype.label}
                                            </p>
                                            {index === 0 && (
                                                <FaCircleInfo className="text-sm text-gray-500" />
                                            )}
                                        </div>
                                        <label class="inline-flex items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                value=""
                                                class="sr-only peer"
                                                checked={
                                                    (index === 1 &&
                                                        secondAdChecked) ||
                                                    (index === 2 &&
                                                        selectedAdId === 3) ||
                                                    (index === 3 &&
                                                        forthadchecked)
                                                }
                                                onChange={() => {
                                                    if (index === 1) {
                                                        setSecondAdChecked(
                                                            !secondAdChecked
                                                        ); // Toggle state for index 1
                                                    } else if (index === 2) {
                                                        handleAdTypeToggle(
                                                            index
                                                        ); // Toggle filter for index 2
                                                    } else if (index === 3) {
                                                        setForthadchecked(
                                                            !forthadchecked
                                                        ); // Toggle state for index 3
                                                    }
                                                }}
                                            />
                                            <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#EFB81C]"></div>
                                        </label>
                                    </div>
                                ))}

                                <p className="mt-2 text-xs text-gray-500">
                                    Sort By
                                </p>
                                {sortOptions.map((sortOption, index) => (
                                    <div
                                        className="flex items-center justify-between"
                                        key={index}
                                        onClick={() =>
                                            handleSortSelection(
                                                sortOption.value
                                            )
                                        }
                                    >
                                        <div className="flex items-center">
                                            <p className="px-2 py-2 text-sm text-gray-800">
                                                {sortOption.label}
                                            </p>
                                        </div>
                                        <input
                                            id={`radio-${index}`}
                                            type="checkbox"
                                            value={sortOption.value}
                                            name="sort-option"
                                            className="w-4 h-4 text-black bg-gray-100 border-gray-300"
                                            checked={selectedOption.includes(
                                                sortOption.value
                                            )}
                                            onChange={() =>
                                                handleSortSelection(
                                                    sortOption.value
                                                )
                                            }
                                        />
                                    </div>
                                ))}

                                <p className="mt-4 text-xs text-gray-500">
                                    Payment Time Limit (minutes)
                                </p>
                                <div className="grid grid-cols-5 gap-3 mt-4">
                                    {timeLimits.map((limit, index) => (
                                        <div
                                            onClick={() =>
                                                setSelectedTime(limit)
                                            }
                                            key={index}
                                            className={`px-4 py-2 text-sm text-center border rounded-lg ${
                                                selectedTime === limit
                                                    ? "bg-blue-500 text-white"
                                                    : ""
                                            } cursor-pointer`}
                                        >
                                            {limit}
                                        </div>
                                    ))}
                                </div>
                                <div className="flex w-full mt-4 space-x-4">
                                    <button
                                        onClick={handleReset}
                                        className="w-1/2 px-4 py-2 rounded-md text-black bg-[#EAECEF] rounded-l"
                                    >
                                        Reset Filters
                                    </button>
                                    <button
                                        onClick={handleSaveFilters}
                                        className="w-1/2 px-4 py-2 rounded-md text-white bg-[#EFB81C] rounded-r"
                                    >
                                        Save Filters
                                    </button>
                                </div>
                            </div>

                            {/* DN Refresh Button */}
                            <div className="relative inline-block text-left">
                                <div>
                                    <button
                                        onClick={handleDNRefreshDropDown}
                                        type="button"
                                        className="inline-flex border-[0px] border-[#DBDBDB] items-center w-full justify-center gap-x-1.5 rounded-lg px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        <div className="flex items-center gap-1">
                                            <HiRefresh />
                                            <h6 className="text-[14px]">
                                                {selectedRefresh}
                                            </h6>
                                        </div>
                                    </button>
                                </div>

                                {/* DN Refresh Dropdown Content */}
                                <div
                                    className={`${
                                        isRefresh ? "block" : "hidden"
                                    } absolute top-full left-0 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
                                >
                                    <div className="py-1">
                                        <h6
                                            className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                            onClick={() =>
                                                handleRefreshSelect(
                                                    "Do Not Refresh"
                                                )
                                            }
                                        >
                                            Do Not Refresh
                                        </h6>
                                        <h6
                                            className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                            onClick={() =>
                                                handleRefreshSelect(
                                                    "Every 5 Secs"
                                                )
                                            }
                                        >
                                            Every 5 Secs
                                        </h6>
                                        <h6
                                            className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                            onClick={() =>
                                                handleRefreshSelect(
                                                    "Every 10 Secs"
                                                )
                                            }
                                        >
                                            Every 10 Secs
                                        </h6>
                                        <h6
                                            className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                            onClick={() =>
                                                handleRefreshSelect(
                                                    "Every 20 Secs"
                                                )
                                            }
                                        >
                                            Every 20 Secs
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{" "}
            </div>
            <div className="w-full">
                {/* <div className="hidden w-full md:block"> */}
                <table className="w-full table-container h-fit">
                    {/* Table Headings */}
                    <thead>
                        <tr className="border-b-2 !h-[40px] border-[#EDEDED] flex justify-between">
                            <th className="text-[#aaaaaa] text-[12px] font-light py-2 w-[32.5%]">
                                Advertiser
                            </th>
                            <th className="text-[#aaaaaa] text-[12px] font-light py-2 w-[24.5%]">
                                Price
                            </th>
                            <th className="text-[#aaaaaa] text-[12px] font-light px-4 py-2 w-1/5">
                                Available/Order Limit
                            </th>
                            <th className="text-[#aaaaaa] text-[12px] font-light px-4 py-2 w-[19%]">
                                Payment method
                            </th>
                            <th className="text-[#aaaaaa] text-[12px] font-light py-1 text-end w-1/4">
                                Trade
                            </th>
                        </tr>
                    </thead>

                    {/* Table Data */}
                    <tbody>
                        {dataa?.ads && Object.keys(dataa.ads).length > 0 ? (
                            <>
                                {filteredData().map(([key, item]) => (
                                    <React.Fragment key={item.id}>
                                        {selectedAdId === item.id ? (
                                            // Display user details box in place of the row
                                            <tr className="flex justify-between w-full pt-1">
                                                <td
                                                    colSpan="5"
                                                    className="w-full"
                                                >
                                                    <div className="flex w-full border rounded-xl">
                                                        <div className="user-details-box py-[24px] px-[32px] w-3/5 rounded-tl-xl rounded-bl-xl bg-[#FAFAFA]">
                                                            <div className="flex w-full gap-2">
                                                                {/* Render your user details here */}
                                                                <div className="flex items-start mt-1">
                                                                    <div className="rounded-xl bg-[#202630] w-[40px] h-[40px] flex items-center justify-center text-white font-semibold text-[18px]">
                                                                        {item.id
                                                                            .charAt(
                                                                                0
                                                                            )
                                                                            .toUpperCase()}
                                                                    </div>
                                                                </div>
                                                                <div className="flex w-full flex-col">
                                                                    <div className="flex gap-3 items-center">
                                                                        {/* <h5 className="font-light text-[16px] text-[#202630]">
                                                                            {
                                                                                item.id
                                                                            }
                                                                        </h5> */}
                                                                        <span className="text-xl font-semibold">
                                                                            {item?.name
                                                                                ? item.name
                                                                                : "Default Username"}
                                                                        </span>
                                                                        {item.vip ==
                                                                            1 && (
                                                                            <MdVerified className="text-[#DEAC0D] h-5 w-5 mt-0.5 -ml-2" />
                                                                        )}
                                                                        <div className="flex gap-1 items-center">
                                                                            <div>
                                                                                <BiSolidLike className="text-[#707A8A] text-sm" />
                                                                            </div>
                                                                            <h6 className="text-sm font-normal text-[#474D57]">
                                                                                {parseFloat(
                                                                                    item?.positive_response
                                                                                ).toFixed(
                                                                                    2
                                                                                )}

                                                                                %
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="relative flex items-center gap-1">
                                                                        <div className="flex items-center w-14">
                                                                            <h6 className="text-sm font-normal text-[#474D57]">
                                                                                {` ${item.orders}  Orders`}
                                                                            </h6>
                                                                        </div>

                                                                        {/* Vertical border */}
                                                                        <span className="h-[10px] border-l border-slate-300"></span>

                                                                        <div className="flex items-center pl-1.5">
                                                                            <h6 className="text-sm font-normal text-[#474D57]">
                                                                                {`${item.completion}%`}{" "}
                                                                                completion
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex py-5 w-full justify-between">
                                                                        <div className="w-1/2">
                                                                            <p className="text-sm font-semibold">
                                                                                {`${item.time_limit} Mins`}
                                                                            </p>
                                                                            <p className="text-xs font-light">
                                                                                Payment
                                                                                Time
                                                                                Limit
                                                                            </p>
                                                                        </div>
                                                                        <div className="w-1/2">
                                                                            <p className="text-sm font-semibold">
                                                                                {(() => {
                                                                                    const timeInSeconds =
                                                                                        parseFloat(
                                                                                            item
                                                                                                .completion_time
                                                                                                .average_completion_time
                                                                                        );
                                                                                    if (
                                                                                        timeInSeconds >=
                                                                                        3600
                                                                                    ) {
                                                                                        const timeInHours =
                                                                                            (
                                                                                                timeInSeconds /
                                                                                                3600
                                                                                            ).toFixed(
                                                                                                2
                                                                                            );
                                                                                        return `${timeInHours} Hours`;
                                                                                    } else {
                                                                                        const timeInMinutes =
                                                                                            (
                                                                                                timeInSeconds /
                                                                                                60
                                                                                            ).toFixed(
                                                                                                2
                                                                                            );
                                                                                        return `${timeInMinutes} Minutes`;
                                                                                    }
                                                                                })()}
                                                                            </p>
                                                                            <p className="text-xs font-light">
                                                                                Avg.
                                                                                Pay
                                                                                Time
                                                                            </p>
                                                                        </div>
                                                                        <div className="w-1/2">
                                                                            <p className="text-sm font-semibold">
                                                                                {parseFloat(
                                                                                    item.available
                                                                                ).toFixed(
                                                                                    2
                                                                                )}{" "}
                                                                                USDT
                                                                            </p>
                                                                            <p className="text-xs font-light">
                                                                                Available
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="border min-h-[360px] rounded-xl p-3">
                                                                        <p className="text-sm font-semibold text-[#707A8A] mb-3">
                                                                            Advertisers'
                                                                            Terms
                                                                            (Please
                                                                            read
                                                                            carefully)
                                                                        </p>
                                                                        <p className="text-sm">
                                                                            {`${item.terms}`}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bg-white rounded-xl px-[24px] py-[32px] w-2/5">
                                                            <div className="flex items-end gap-1">
                                                                <p className="text-sm text-[#707A8A] font-light">
                                                                    Price{" "}
                                                                    <span className="text-[#f6465d] text-sm font-semibold">
                                                                        {" "}
                                                                        {parseFloat(
                                                                            item?.fixed_price
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                        USD
                                                                    </span>
                                                                </p>
                                                                <HiRefresh className="text-[#707a8a]" />
                                                            </div>
                                                            <p className="text-sm text-[#707A8A] font-light py-1 pb-3">
                                                                Processing Fee
                                                                0.05 USDT
                                                            </p>
                                                            <div className="rounded-xl border p-4">
                                                                <p className="text-sm mb-2">
                                                                    {item.ad_type ==
                                                                    1
                                                                        ? `You Sell`
                                                                        : `You Buy`}
                                                                </p>
                                                                <div className="flex w-full gap-3 items-center">
                                                                    <input
                                                                        className="text-[20px] font-bold mb-2 w-full"
                                                                        placeholder="0.00"
                                                                        value={
                                                                            sellAmount
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleSellChange(
                                                                                e,
                                                                                item.fixed_price
                                                                            )
                                                                        }
                                                                    />
                                                                    <button className="text-[#EFB81C] font-medium cursor-pointer">
                                                                        All
                                                                    </button>
                                                                    <AiFillDollarCircle
                                                                        className="text-[30px]"
                                                                        style={{
                                                                            color: "rgb(119 177 141)",
                                                                        }}
                                                                    />
                                                                    <p>
                                                                        {
                                                                            item.coin
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="flex items-center gap-2">
                                                                    <p className="text-sm font-light text-[#707A8A]">
                                                                        Balance:{" "}
                                                                        {
                                                                            balance
                                                                        }{" "}
                                                                        USDT
                                                                    </p>
                                                                    <button
                                                                        onClick={
                                                                            handleAddFundsClick
                                                                        }
                                                                        className="cursor-pointer text-[#EFB81C] font-medium"
                                                                    >
                                                                        Add
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="rounded-xl mt-5 border p-4">
                                                                <p className="text-sm mb-2">
                                                                    {item.ad_type ==
                                                                    1
                                                                        ? `You Recieve`
                                                                        : `You Deliver`}
                                                                </p>
                                                                <div className="flex w-full gap-3 items-center">
                                                                    <input
                                                                        className="text-[20px] font-bold mb-2 w-full"
                                                                        value={
                                                                            receiveAmount
                                                                        }
                                                                        // onChange={
                                                                        //     handleReceiveChange
                                                                        // }
                                                                        readOnly
                                                                        placeholder={`$${parseFloat(
                                                                            item.min_limit
                                                                        ).toFixed(
                                                                            2
                                                                        )} - $${parseFloat(
                                                                            item.max_limit
                                                                        ).toFixed(
                                                                            2
                                                                        )}`}
                                                                    />
                                                                    <AiFillDollarCircle className="text-[30px] text-blue-900" />
                                                                    <p>
                                                                        {
                                                                            selectedCurrency
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {item.ad_type ==
                                                            1 ? (
                                                                <div
                                                                    className="rounded-xl flex justify-between cursor-pointer w-full mt-5 border p-4"
                                                                    onClick={
                                                                        showModal
                                                                    }
                                                                >
                                                                    {selectedMethod ===
                                                                    "Bank Transfer" ? (
                                                                        <>
                                                                            {(() => {
                                                                                if (
                                                                                    selectedBankDetails.name
                                                                                ) {
                                                                                    return `Selected Payment Method: ${selectedBankDetails.name} ****${selectedBankDetails.accountNumber}`;
                                                                                } else if (
                                                                                    item.ad_type ==
                                                                                    1
                                                                                ) {
                                                                                    return "Set my payment method";
                                                                                }
                                                                            })()}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {(() => {
                                                                                if (
                                                                                    selectedPmDetails.method
                                                                                ) {
                                                                                    return `Method: ${selectedPmDetails.method}, Information: ${selectedPmDetails.information}`;
                                                                                } else if (
                                                                                    item.ad_type ==
                                                                                    1
                                                                                ) {
                                                                                    return "Set my payment method";
                                                                                }
                                                                            })()}
                                                                        </>
                                                                    )}
                                                                    <IoIosArrowForward />
                                                                </div>
                                                            ) : (
                                                                <div className="rounded-xl flex justify-between cursor-pointer w-full mt-5 border p-4">
                                                                    Method:{" "}
                                                                    {
                                                                        item.method
                                                                    }
                                                                </div>
                                                            )}
                                                            {/* Add Funds Modal */}
                                                            <AddFundsModal
                                                                visible={
                                                                    isFundsModalVisible
                                                                }
                                                                onCancel={
                                                                    handleFundsModalCancel
                                                                }
                                                            />
                                                            {/* Antd Modal */}
                                                            {item.ad_type ==
                                                            1 ? (
                                                                <Modal
                                                                    title="Select payment method"
                                                                    open={
                                                                        isModalOpen
                                                                    }
                                                                    onCancel={
                                                                        handleCancel
                                                                    }
                                                                    footer={""}
                                                                    wrapClassName="custom-modal" // Custom class
                                                                    bodyStyle={{
                                                                        minHeight:
                                                                            "400px",
                                                                    }} // Inline style applied to modal body
                                                                >
                                                                    <p className="text-sm pt-5">
                                                                        Add
                                                                        supported
                                                                        payment
                                                                        methods
                                                                    </p>
                                                                    {item.method
                                                                        .length >
                                                                    0 ? (
                                                                        item.method.map(
                                                                            (
                                                                                method,
                                                                                index
                                                                            ) => (
                                                                                <div
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    className="rounded-xl flex justify-between items-center cursor-pointer w-full mt-2 border p-4"
                                                                                    onClick={() =>
                                                                                        showModal(
                                                                                            method
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <div className="flex items-center">
                                                                                        <CgLoadbar className="rotate-90 text-[#EFB81C]" />
                                                                                        <p className="font-semibold ml-2">
                                                                                            {
                                                                                                method
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                    <button
                                                                                        onClick={
                                                                                            showBankTransferModal
                                                                                        }
                                                                                    >
                                                                                        <FaPlus />
                                                                                    </button>
                                                                                    <BankTransfer
                                                                                        isModalVisible={
                                                                                            isModalVisible
                                                                                        }
                                                                                        BankTransferhandleOk={
                                                                                            BankTransferhandleOk
                                                                                        }
                                                                                        BankTransferhandleCancel={
                                                                                            BankTransferhandleCancel
                                                                                        }
                                                                                        selectedMethods={
                                                                                            selectedMethods
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        )
                                                                    ) : (
                                                                        <span className="text-[#717171]">
                                                                            No
                                                                            methods
                                                                            available
                                                                        </span>
                                                                    )}
                                                                    <div className="my-4">
                                                                        {item.method.includes(
                                                                            "Bank Transfer"
                                                                        ) && (
                                                                            <button
                                                                                className={`p-2 ${
                                                                                    selectedMethod ===
                                                                                    "Bank Transfer"
                                                                                        ? "bg-[#EFB81C] text-white"
                                                                                        : "bg-gray-200"
                                                                                }`}
                                                                                onClick={() =>
                                                                                    handleMethodChange(
                                                                                        "Bank Transfer"
                                                                                    )
                                                                                }
                                                                            >
                                                                                Bank
                                                                                Transfer
                                                                            </button>
                                                                        )}
                                                                        {item.method.includes(
                                                                            "Cash Deposit"
                                                                        ) && (
                                                                            <button
                                                                                className={`p-2 ${
                                                                                    selectedMethod ===
                                                                                    "Cash Deposit"
                                                                                        ? "bg-[#EFB81C] text-white"
                                                                                        : "bg-gray-200"
                                                                                }`}
                                                                                onClick={() =>
                                                                                    handleMethodChange(
                                                                                        "Cash Deposit"
                                                                                    )
                                                                                }
                                                                            >
                                                                                Cash
                                                                                Deposit
                                                                            </button>
                                                                        )}
                                                                        {item.method.includes(
                                                                            "Paypal"
                                                                        ) && (
                                                                            <button
                                                                                className={`p-2 ${
                                                                                    selectedMethod ===
                                                                                    "Paypal"
                                                                                        ? "bg-[#EFB81C] text-white"
                                                                                        : "bg-gray-200"
                                                                                }`}
                                                                                onClick={() =>
                                                                                    handleMethodChange(
                                                                                        "Paypal"
                                                                                    )
                                                                                }
                                                                            >
                                                                                Paypal
                                                                            </button>
                                                                        )}
                                                                        {item.method.includes(
                                                                            "UPI"
                                                                        ) && (
                                                                            <button
                                                                                className={`p-2 ${
                                                                                    selectedMethod ===
                                                                                    "UPI"
                                                                                        ? "bg-[#EFB81C] text-white"
                                                                                        : "bg-gray-200"
                                                                                }`}
                                                                                onClick={() =>
                                                                                    handleMethodChange(
                                                                                        "UPI"
                                                                                    )
                                                                                }
                                                                            >
                                                                                UPI
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                    {selectedMethod ===
                                                                        "Bank Transfer" &&
                                                                        bankDetails
                                                                            ?.userBankList
                                                                            ?.length >
                                                                            0 && (
                                                                            <div className="mt-3 border-t pt-3">
                                                                                {bankDetails.userBankList.map(
                                                                                    (
                                                                                        bank
                                                                                    ) => (
                                                                                        <div
                                                                                            key={
                                                                                                bank.id
                                                                                            }
                                                                                            className={`bg-white shadow-md rounded-lg p-4 mb-4 flex justify-between items-center cursor-pointer ${
                                                                                                selectedBankId ===
                                                                                                bank.id
                                                                                                    ? "border-yellow-500 border-2"
                                                                                                    : ""
                                                                                            }`}
                                                                                            onClick={() =>
                                                                                                handleCardSelect(
                                                                                                    bank
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <div>
                                                                                                <p className="font-semibold text-lg text-[#333]">
                                                                                                    {
                                                                                                        bank.name
                                                                                                    }
                                                                                                </p>
                                                                                                <p className="text-sm text-[#555]">
                                                                                                    Bank:{" "}
                                                                                                    {
                                                                                                        bank.bank
                                                                                                    }
                                                                                                </p>
                                                                                                <p className="text-sm text-[#555]">
                                                                                                    Address:{" "}
                                                                                                    {
                                                                                                        bank.bankaddr
                                                                                                    }
                                                                                                </p>
                                                                                                <p className="text-sm text-[#555]">
                                                                                                    Account
                                                                                                    Ending:
                                                                                                    ****{" "}
                                                                                                    {bank.bankcard.slice(
                                                                                                        -4
                                                                                                    )}
                                                                                                </p>
                                                                                            </div>
                                                                                            <DeleteOutlined
                                                                                                style={{
                                                                                                    fontSize:
                                                                                                        "20px",
                                                                                                    color: "#ff4d4f",
                                                                                                }}
                                                                                                onClick={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    e.stopPropagation();
                                                                                                    handleDelete(
                                                                                                        bank.id
                                                                                                    );
                                                                                                }}
                                                                                                className="cursor-pointer"
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    {selectedMethod !==
                                                                        "Bank Transfer" && (
                                                                        <div className="mt-3">
                                                                            {bankDetails?.userPaymedthod
                                                                                ?.filter(
                                                                                    (
                                                                                        method
                                                                                    ) => {
                                                                                        const selected =
                                                                                            selectedMethod.toLowerCase();
                                                                                        return (
                                                                                            method.method ===
                                                                                            (selected ===
                                                                                            "cash deposit"
                                                                                                ? "cdm"
                                                                                                : selected)
                                                                                        );
                                                                                    }
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        method
                                                                                    ) => (
                                                                                        <div
                                                                                            key={
                                                                                                method.id
                                                                                            }
                                                                                            className={`bg-white shadow-md rounded-lg p-4 mb-4 flex justify-between items-center cursor-pointer ${
                                                                                                selectedPmId ===
                                                                                                method.id
                                                                                                    ? "border-yellow-500 border-2"
                                                                                                    : ""
                                                                                            }`}
                                                                                            onClick={() =>
                                                                                                handleCardSelec(
                                                                                                    method
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <div>
                                                                                                <p className="font-semibold">
                                                                                                    Method:{" "}
                                                                                                    {method.method ===
                                                                                                    "cdm"
                                                                                                        ? "Cash Deposit"
                                                                                                        : method.method ===
                                                                                                          "upi"
                                                                                                        ? "UPI"
                                                                                                        : method.method ===
                                                                                                          "paypal"
                                                                                                        ? "Paypal"
                                                                                                        : "Unknown Method"}
                                                                                                </p>

                                                                                                <p className="text-sm">
                                                                                                    Information:{" "}
                                                                                                    {
                                                                                                        method.information
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                            <DeleteOutlined
                                                                                                style={{
                                                                                                    fontSize:
                                                                                                        "20px",
                                                                                                    color: "#ff4d4f",
                                                                                                }}
                                                                                                onClick={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    e.stopPropagation();
                                                                                                    handleDelet(
                                                                                                        method.id
                                                                                                    );
                                                                                                }}
                                                                                                className="cursor-pointer"
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                        </div>
                                                                    )}
                                                                </Modal>
                                                            ) : (
                                                                <Modal
                                                                    title="Select payment method"
                                                                    open={
                                                                        isModalOpen
                                                                    }
                                                                    onCancel={
                                                                        handleCancel
                                                                    }
                                                                    footer={""}
                                                                    wrapClassName="custom-modal" // Custom class
                                                                    bodyStyle={{
                                                                        minHeight:
                                                                            "400px",
                                                                    }} // Inline style applied to modal body
                                                                >
                                                                    {item.method
                                                                        .length >
                                                                    0 ? (
                                                                        item.method.map(
                                                                            (
                                                                                method,
                                                                                index
                                                                            ) => (
                                                                                <div
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    onClick={() =>
                                                                                        handleCardSelected(
                                                                                            method
                                                                                        )
                                                                                    }
                                                                                    className="rounded-xl flex justify-between items-center cursor-pointer w-full mt-2 border p-4"
                                                                                >
                                                                                    <div className="flex items-center">
                                                                                        <CgLoadbar className="rotate-90 text-[#EFB81C]" />
                                                                                        <p className="font-semibold ml-2">
                                                                                            {
                                                                                                method
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        )
                                                                    ) : (
                                                                        <span className="text-[#717171]">
                                                                            No
                                                                            methods
                                                                            available
                                                                        </span>
                                                                    )}
                                                                </Modal>
                                                            )}

                                                            <div className="flex gap-2 mt-12 w-full">
                                                                <button
                                                                    onClick={
                                                                        resetFields
                                                                    }
                                                                    className="w-2/5 cursor-pointer text-center font-semibold px-4 py-3 rounded-lg bg-[#EAECEF]"
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    onClick={
                                                                        handleSellChat
                                                                    }
                                                                    className={`w-3/5 text-center text-white font-semibold cursor-pointer px-4 py-3 rounded-lg ${
                                                                        item.ad_type ==
                                                                        1
                                                                            ? "bg-[#FF0000]"
                                                                            : "bg-[#01BC8D]"
                                                                    }`}
                                                                >
                                                                    {item.ad_type ==
                                                                    1 ? (
                                                                        <>
                                                                            Sell{" "}
                                                                            {
                                                                                item.coin
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            Buy{" "}
                                                                            {
                                                                                item.coin
                                                                            }
                                                                        </>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr
                                                key={item.id}
                                                className="flex justify-between w-full pt-1"
                                            >
                                                {/* ID Column */}
                                                <td className="flex w-1/3 py-2 gap-x-2">
                                                    <div className="flex items-start mt-1">
                                                        <div className="rounded-lg bg-[#202630] w-[24px] h-[24px] flex items-center justify-center text-white font-semibold text-xs">
                                                            {item.id
                                                                .charAt(0)
                                                                .toUpperCase()}
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col">
                                                        <div className="flex gap-1 items-center">
                                                            <h5 className="font-light text-[16px] text-[#202630]">
                                                                {/* {item.id}{" "} */}
                                                                <span className="text-xl font-semibold">
                                                                    {item?.name
                                                                        ? item.name
                                                                        : "Default Username"}
                                                                </span>
                                                            </h5>
                                                            {item.vip == 1 && (
                                                                <MdVerified className="text-[#DEAC0D] h-5 w-5 mt-1" />
                                                            )}
                                                        </div>

                                                        {/* div */}
                                                        <div className="relative flex items-center justify-between gap-1">
                                                            <div className="flex items-center w-14">
                                                                <h6 className="text-[12px] font-normal text-[#474D57]">
                                                                    {` ${item.orders}  Orders`}
                                                                </h6>
                                                            </div>

                                                            {/* Vertical border */}
                                                            <span className="h-[10px] border-l border-slate-300"></span>

                                                            <div className="flex items-center pl-1.5">
                                                                <h6 className="text-[12px] font-normal text-[#474D57]">
                                                                    {`${item.completion}%`}{" "}
                                                                    completion
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        {/* </div> */}
                                                        <div className="relative justify-between gap-1 flex items-center w-[80%]">
                                                            <div className="flex items-center gap-1">
                                                                <div>
                                                                    <BiSolidLike className="text-[#707A8A] text-[12px]" />
                                                                </div>
                                                                <h6 className="text-[12px] font-normal text-[#474D57]">
                                                                    {parseFloat(
                                                                        item?.positive_response
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                    %
                                                                </h6>
                                                            </div>

                                                            {/* Vertical border */}
                                                            <div>
                                                                <span className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[10px] border-l border-slate-300"></span>
                                                            </div>
                                                            <div className="flex items-center gap-1">
                                                                <div>
                                                                    <AiFillClockCircle className="text-[#707A8A] text-[12px]" />
                                                                </div>
                                                                <h6 className="text-[12px] font-normal text-[#474D57]">
                                                                    {
                                                                        item.time_limit
                                                                    }
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>

                                                {/* For Price Column */}
                                                <td className="text-[18px] pt-3 font-bold w-1/4">
                                                    {parseFloat(
                                                        item?.fixed_price
                                                    ).toFixed(2)}
                                                    <span className="font-semibold text-[12px]">
                                                        USD
                                                    </span>
                                                </td>

                                                {/* For Limit/Available Column */}
                                                <td className="flex w-1/5 px-4 py-2 gap-x-4">
                                                    <div className="flex flex-col text-[14px]">
                                                        <h5 className="flex gap-1">
                                                            <span className="font-[300] text-[13px]">
                                                                {parseFloat(
                                                                    item.available
                                                                ).toFixed(2)}
                                                            </span>
                                                            <span className="font-[300] text-[#717171] text-[13px]">
                                                                USDT
                                                            </span>
                                                        </h5>
                                                        <h6 className="flex gap-1 text-[13px] mt-1 font-[300]">
                                                            {`$${parseFloat(
                                                                item.min_limit
                                                            ).toFixed(
                                                                2
                                                            )} - $${parseFloat(
                                                                item.max_limit
                                                            ).toFixed(2)}`}
                                                            <span className="font-[300] text-[13px] text-[#717171]">
                                                                USD
                                                            </span>
                                                        </h6>
                                                    </div>
                                                </td>

                                                {/* Method Column */}
                                                <td className="w-1/5 px-4 py-2 pt-3">
                                                    {item.method.length > 0 ? (
                                                        item.method.join(", ")
                                                    ) : (
                                                        <span className="text-[#717171]">
                                                            No methods available
                                                        </span>
                                                    )}
                                                </td>

                                                {/* For Trade */}
                                                <td className="w-1/4 px-4 py-2">
                                                    {/* Display Active Button */}
                                                    <div className="flex items-center justify-end">
                                                        {type === "buy" && (
                                                            <button
                                                                onClick={() =>
                                                                    handleBuyClick(
                                                                        item.id
                                                                    )
                                                                }
                                                                className="font-normal px-12 py-2 text-[14px] rounded-md bg-[#01BC8D] text-white"
                                                            >
                                                                Buy
                                                            </button>
                                                        )}
                                                        {type === "sell" && (
                                                            <button
                                                                onClick={() =>
                                                                    handleSellClick(
                                                                        item.id
                                                                    )
                                                                }
                                                                className="px-12 py-2 font-normal text-[14px] rounded-md bg-[#FF0000] text-white"
                                                            >
                                                                Sell
                                                            </button>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </>
                        ) : (
                            <tr>
                                <td
                                    colSpan="5"
                                    className="text-center py-4 text-[16px] text-[#717171]"
                                >
                                    No ads found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {/* </div> */}

                {/* Pagination buttons */}
                <div className="flex items-center justify-center gap-6 mt-8 text-xl ">
                    <button
                        className="cursor-pointer text-[#dbdbdb]"
                        onClick={handleLeftClick}
                        disabled={currentPage === 1}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    {getPageRange().map((pageNumber) => (
                        <button
                            key={pageNumber}
                            className={`px-2 rounded-lg hover:text-black hover:bg-white ${
                                currentPage === pageNumber
                                    ? "bg-black text-white"
                                    : ""
                            }`}
                            onClick={() => handlePageClick(pageNumber)}
                        >
                            {pageNumber}
                        </button>
                    ))}
                    {getPageRange()[getPageRange().length - 1] !==
                        Math.ceil(data.length / rowsPerPage) && (
                        <span>...</span>
                    )}
                    <button
                        className="cursor-pointer text-[#EFB81C]"
                        onClick={handleRightClick}
                        disabled={
                            currentPage === Math.ceil(data.length / rowsPerPage)
                        }
                    >
                        <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                </div>
                {/* <P2pChat tradeMode={tradeMode} showChat={false} style={{ display: 'none' }}/> */}
            </div>
        </>
    );
}

export default P2pTable;
