// reducer.js or your slice file
const initialState = {
    email: '',
    // other state properties
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_EMAIL":
            return {
                ...state,
                email: action.payload, // Update the email in the state
            };
        // other cases
        default:
            return state;
    }
};

export default reducer;
