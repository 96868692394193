import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Faq from "components/global/FAQ";
import { useDispatch, useSelector } from "react-redux";

import { fetchKycStatus } from "../../../store/actions/AccountuserActions";

import P2pWork from "./P2pComponents/P2pWork";
import AdvantageP2p from "./P2pComponents/AdvantageP2p";
import TopPayment from "./P2pComponents/TopPayment";
import P2pTable from "./P2pComponents/P2pTable";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function P2p() {
    const dispatch = useDispatch();

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};

    useEffect(() => {
        if (userID && userToken) {
            // dispatch(fetchUserProfile(userID, userToken));
            dispatch(fetchKycStatus(userID, userToken));
            // dispatch(get2FA(userId, token));
        }
    }, [dispatch, userID, userToken]);
    useEffect(() => {
        if (TOKEN && ID) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [TOKEN, ID]);
    return (
        <>
            <main className="container w-full h-full  dark:bg-[#000000]">
                <div className="flex flex-col items-center justify-between w-full h-full gap-28">
                    {/* section1 */}
                    <section className="flex flex-col items-center justify-between w-full gap-6 section1">
                        {/* nav (upper) */}
                        <div className="w-full ">
                            {!isAuthenticated && (
                                <div className="mt-4 text-center">
                                    <h1 className="p-4 text-3xl font-bold">
                                        Trade USDT Easily - Buy and Sell Using
                                        Your Favorite Payment Methods
                                    </h1>
                                    <p className="text-[#707A8A] text-[14px]">
                                        Exchange TetherUS on Buycex P2P.Find the
                                        best offers below to Buy and Sell USDT
                                    </p>
                                    <div className="flex items-center justify-center gap-4 py-6">
                                        <Link to="/register" className="bg-[#EFB81C] rounded-lg px-12 py-1.5 text-[14px] font-semibold">
                                            Register
                                        </Link>
                                        <Link to="/login" className="bg-[#EAECEF] rounded-lg px-12 py-1.5 text-[14px] font-semibold">
                                            Log In
                                        </Link>
                                    </div>
                                </div>
                            )}

                            <div className="flex flex-col items-center justify-between w-full h-full gap-28">
                                {/* Table */}
                                <P2pTable />
                                {/* How P2p Work Section */}
                                <P2pWork />
                                {/* Advantage of P2p  */}
                                <AdvantageP2p />
                                {/* Top Payment Methods  */}
                                <TopPayment />
                                {/* FAQ SECTION */}
                                <Faq />
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
}

export default P2p;
