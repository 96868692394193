import { useState } from "react";
import { AiOutlineFileSearch } from "react-icons/ai";

function SuspendedFunction() {
    return <div className="h-52 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
            <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
            <p className="mt-3 text-[#474D57]">No restrictions found</p>
        </div>
    </div>;
}

function FrozenAssets() {
    return <div className="h-52 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
            <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
            <p className="mt-3 text-[#474D57]">No restrictions found</p>
        </div>
    </div>;
}

function RestrictionRemoval() {
    const [activeTab, setActiveTab] = useState('Suspended Function');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    return (
        <div>
            {/* Navbar */}
            <div className='flex gap-8 mt-4 border-b h-10 pl-6'>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'Suspended Function' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('Suspended Function')}
                >
                    Suspended Function
                </button>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'Frozen Assets' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('Frozen Assets')}
                >
                    Frozen Assets
                </button>
            </div>
            <div class="flex mt-6 items-center p-4 mb-4 text-sm text-[#202630] rounded-lg bg-[#D89F000F] dark:bg-gray-800 dark:text-yellow-300" role="alert">
                <svg class="flex-shrink-0 text-[#EFB81C] inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span class="sr-only">Info</span>
                <div>
                    <span className="text-[14px]">If you can't find a prior restriction you submitted an appeal for, it means that it has been resolved. Please check your email for further details.
                    </span>
                </div>
            </div>
            <div className="bg-gray-50 px-3 py-3 w-full rounded-lg">
                <table className="w-full">
                    <tr className="flex w-full justify-between">
                        <th className="font-normal text-[12px] text-[#707A8A]">Suspended function(s)</th>
                        <th className="font-normal text-[12px] text-[#707A8A]">Reason</th>
                        <th className="font-normal text-[12px] text-[#707A8A]">Case</th>
                        <th className="font-normal text-[12px] text-[#707A8A]">Status</th>
                        <th className="font-normal text-[12px] text-[#707A8A]">Effective Until</th>
                    </tr>
                </table>
            </div>
            {/* Content based on active tab */}
            <div className='mt-4'>
                {activeTab === 'Suspended Function' && <SuspendedFunction />}
                {activeTab === 'Frozen Assets' && <FrozenAssets />}
            </div>

        </div>
    )
}

export default RestrictionRemoval