import React, { useEffect, useRef, useState } from "react";
import { BsGift, BsHeadset, BsMegaphone } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { fetchMarketData } from "store/actions/marketActions";

import "./SpotTradeStyle.scss";

const SpotTradeFooter = () => {
    const dispatch = useDispatch();
    const marketData = useSelector((state) => state.market.data?.market || []);
    // const loading = useSelector((state) => state.market.loading);

    useEffect(() => {
        dispatch(fetchMarketData());
    }, [dispatch]);

    const extractCoinName = (name) => {
        const match = name.match(/\(([^)]+)\)/);
        return match ? match[1] : name;
    };

    return (
        <footer className="spot-trade__footer bg-[#F4F4F4]">
            <div className="spot-trade__footer-wrapper">
                <div className="flex items-center justify-between spot-trade__footer-container">
                    <div className="flex items-center gap-3 max-w-[32.5%] px-2 opacity-0 footer__coin-slider">
                        {marketData.map((coin, index) => (
                            <div
                                key={index}
                                className="flex items-center gap-1.5 footer__coin-slider-item"
                            >
                                <span className="text-[12px]">
                                    {extractCoinName(coin.name)}
                                </span>
                                <span
                                    className={`text-[12px] ${
                                        coin.change > 0
                                            ? "text-green-500"
                                            : "text-red-500"
                                    }`}
                                >
                                    {coin.change > 0
                                        ? `+${coin.change.toFixed(2)}%`
                                        : `${coin.change.toFixed(2)}%`}
                                </span>
                            </div>
                        ))}
                    </div>

                    <div className="flex items-center p-0 footer__links">
                        <div className="footer__links-item">
                            <Link
                                to="#!"
                                className="flex items-center gap-1 px-2 py-1"
                                title=""
                                rel=""
                            >
                                <BsGift size={12} />
                                <span className="text-[12px]">Rewards</span>
                            </Link>
                        </div>
                        <div className="footer__links-item">
                            <Link
                                to="#!"
                                className="flex items-center gap-1 px-2 py-1"
                                title=""
                                rel=""
                            >
                                <BsMegaphone size={12} />
                                <span className="text-[12px]">
                                    Announcements
                                </span>
                            </Link>
                        </div>
                        <div className="footer__links-item bg-[#EFB81C60]">
                            <Link
                                to="#!"
                                className="flex items-center gap-1 px-2 py-1"
                                title=""
                                rel=""
                            >
                                <BsHeadset size={12} />
                                <span className="text-[12px]">Support</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default SpotTradeFooter;
