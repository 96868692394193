import React from "react";

import OrderListWrapper from "./OrderListWrapper";

const OrderList = () => {
    return (
        <div className="min-h-[510px] order-list-wrapper bg-[#FFFFFF] dark:bg-[#101014] px-2">
            <OrderListWrapper />
        </div>
    );
};

export default OrderList;
