import React, { useState } from "react";
import info from "assets/img/infoYellow.svg";
import id from "assets/img/idYellow.svg";
import webcam from "assets/img/webcamYellow.svg";
import icon from "assets/img/icon.svg";
import Mask from "assets/img/Mask group.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const CompleteVerification = () => {
    return (
        <div className="min-h-screen flex flex-col justify-start items-center mt-14 w-full gap-8 bg-[#f4f4f4] pt-4 pb-6">
            <div className=" inline-flex gap-3">
                <img src={icon} alt="" />
                <h1 className=" font-bold text-[21px]">
                    Identity Verification
                </h1>
            </div>

            <div className="flex flex-row justify-center items-center bg-white rounded-xl p-3 gap-10 w-[50%]">
                <button className=" gap-3 w-full inline-flex justify-start items-center">
                    <img src={info} alt="" />
                    <h3 className="font-medium  text-yellow-500 ">
                        Basic Info
                    </h3>
                </button>
                <button className=" w-full inline-flex pl-2  border-l-[2px] border-yellow-500 justify-start items-center">
                    <img src={id} alt="" />
                    <h3 className="font-medium  text-yellow-500 ">Upload ID</h3>
                </button>
                <button className=" w-full inline-flex pl-2 justify-start   border-l-[2px] border-yellow-500 items-center">
                    <img src={webcam} alt="" />
                    <h3 className="font-medium text-yellow-500">
                        Face Recognition
                    </h3>
                </button>
            </div>
            {/* Part 2 */}
            <div className=" bg-white p-3 rounded-xl flex flex-col w-[70%] gap-3 justify-center items-center">
                <div className=" inline-flex gap-3 justify-center items-center">
                    <img src={Mask} alt="" />
                    <div className=" flex flex-col justify-start items-start ">
                        <h1>Verification Process is Completed</h1>
                        <p className=" text-gray-500">
                            You will receive an email once the review is
                            complete.
                        </p>
                    </div>
                </div>
                <div className=" w-full flex flex-row justify-center items-center gap-3 mt-8 mb-4">
                    <Link to="/user/account/identity-verification/kyc-verification">
                        {" "}
                        <button className=" bg-[#efb81c] text-black  rounded-[10px] px-6 py-[5px] font-medium">
                            Back to Security
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CompleteVerification;
