import React, { useState } from 'react'
import { AiOutlineFileSearch } from "react-icons/ai";

function Following() {
    return <div className="h-52 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
            <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
            <p className="mt-3 text-[#474D57]">No Comments</p>
        </div>
    </div>;
}

function Follower() {
    return <div className="h-52 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
            <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
            <p className="mt-3 text-[#474D57]">No Comments</p>
        </div>
    </div>;
}

function Follows() {
    const [activeTab, setActiveTab] = useState('Following');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    return (
        <div>
            <div className='pb-6'>
                <p className='text-[20px] font-medium'>Follows</p>
            </div>
            {/* Navbar */}
            <div className='flex gap-8 mt-4 bg-gray-100 rounded-md h-10 pl-6'>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'Following' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('Following')}
                >
                    <div className='flex items-center gap-1'><p className='text-[14px]'>Following</p><p className='text-[14px]'>(0)</p></div>
                    </button>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'Follower' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('Follower')}
                >
                    <div className='flex items-center gap-1'><p className='text-[14px]'>Follower</p><p className='text-[14px]'>(0)</p></div>
                </button>
            </div>

            {/* Content based on active tab */}
            <div className='mt-4'>
                {activeTab === 'Following' && <Following />}
                {activeTab === 'Follower' && <Follower />}
            </div>
        </div>
    )
}

export default Follows