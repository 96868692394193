import React from "react";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";


const Derivate = (props) => {


    return (
        <>
            <Header />
            <main className="bg-[black] ">
             

                {/* Page Contents Wrapper */}
                <div className="">{props.children}</div>

            </main>
            <Footer />
        </>
    );
};

export default Derivate;
