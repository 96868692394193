import React, { useState } from 'react';
import { IoPersonCircle } from "react-icons/io5";
import { FaTimes, FaSearchPlus, FaSearchMinus } from 'react-icons/fa'; // For close and zoom icons

const P2pMessage = ({ message }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1); // 1 is the default zoom level

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setZoomLevel(1); // Reset zoom level when closing
  };

  const zoomIn = () => {
    setZoomLevel(prevZoom => prevZoom + 0.1); // Increase zoom level
  };

  const zoomOut = () => {
    setZoomLevel(prevZoom => Math.max(prevZoom - 0.1, 1)); // Decrease zoom level, not below 1
  };

  return (
    <div className={`flex mb-2 ${message.sender === 'user' ? 'flex-row-reverse justify-start' : 'justify-start'}`}>
      {message.sender !== 'system' && (
        <div className={`flex items-center ${message.sender === 'user' ? 'ml-2' : 'mr-2'}`}>
          {message.sender === 'user' ? (
            <IoPersonCircle className="text-xl text-yellow-500" />
          ) : (
            <IoPersonCircle className="text-xl" />
          )}
        </div>
      )}
      {message.icon && message.sender === 'system' && (
        <div className="flex items-center mr-2">
          {message.icon}
        </div>
      )}
      <div className={`p-2 rounded-lg ${message.sender === 'user' ? 'bg-gray-100 text-black' : 'bg-yellow-100'}`}>
        {message.text ? (
          <p className='text-sm'>{message.text}</p>
        ) : message.file ? (
          <img
            src={message.file} 
            alt={message.fileName || 'Sent image'}
            className="w-48 h-auto cursor-pointer"
            onClick={() => handleImageClick(message.file)}
            onError={(e) => e.target.style.display = 'none'} // Hide if image fails to load
          />
        ) : null}
      </div>

      {/* Modal for Fullscreen Image */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
          <div className="relative">
            <img
              src={selectedImage}
              alt="Expanded view"
              className="object-contain"
              style={{ transform: `scale(${zoomLevel})`, transition: 'transform 0.2s ease' }} // Apply zoom
            />
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 text-white text-3xl"
              onClick={handleCloseModal}
            >
              <FaTimes className='text-black bg-white w-12 h-12 rounded-md'/>
            </button>
            {/* Zoom In Button */}
            <button
              className="absolute bottom-4 right-16 text-black bg-white w-12 h-12 rounded-md text-xl"
              onClick={zoomIn}
            >
              <FaSearchPlus />
            </button>
            {/* Zoom Out Button */}
            <button
              className="absolute bottom-4 right-4 text-black bg-white w-12 h-12 rounded-md text-xl"
              onClick={zoomOut}
            >
              <FaSearchMinus />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default P2pMessage;
