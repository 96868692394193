import React from "react";
import DropdownFilters from "./DropdownFilters";
import NoData from "assets/img/user/No-data.svg";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { IoChevronBack } from "react-icons/io5";

function AdHistory() {
    return (
        <div className="container">
            <div className="flex w-full items-center justify-between">
                <p className="text-[22px] font- pl-0 py-6 container">
                    Ad History
                </p>
                <Link
                    to="/trade/p2p/my-ads"
                    className="flex gap-1 items-center text-xs"
                >
                    <IoChevronBack className="text-[10px]" />
                    Back
                </Link>
            </div>
            <DropdownFilters hideButtons={true} />
            <div className="flex justify-center items-center w-full h-screen">
                <div className="flex flex-col items-center">
                    <img src={NoData} />
                    <p className="text-sm pt-2">You do not have any Ads</p>
                </div>
            </div>
        </div>
    );
}

export default AdHistory;
