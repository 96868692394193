import {
    GET_USER_WALLETS_REQUEST,
    GET_USER_WALLETS_SUCCESS,
    GET_USER_WALLETS_FAILURE,
    ADD_WALLET_WHITELIST_REQUEST,
    ADD_WALLET_WHITELIST_SUCCESS,
    ADD_WALLET_WHITELIST_FAILURE,
    DELETE_WALLET_WHITELIST_REQUEST,
    DELETE_WALLET_WHITELIST_SUCCESS,
    DELETE_WALLET_WHITELIST_FAILURE,
    EDIT_WALLET_WHITELIST_REQUEST,
    EDIT_WALLET_WHITELIST_SUCCESS,
    EDIT_WALLET_WHITELIST_FAILURE,
} from "../types";

import WalletaddresswhitelistRepository from "repository/WalletaddresswhitelistRepository";

// Fetch user wallets
export const getUserWallets = (token, userId) => async (dispatch) => {
    dispatch({ type: GET_USER_WALLETS_REQUEST });

    try {

        console.log(token,userId)
        const response = await WalletaddresswhitelistRepository.getUserWallets(token, userId);
        console.log("Respponse data",response)
        const { status, data } = response.data;

        if (status === 1) {
            dispatch({ type: GET_USER_WALLETS_SUCCESS, payload: data });
        } else {
            dispatch({ type: GET_USER_WALLETS_FAILURE, payload: data || "Failed to fetch wallets" });
        }
    } catch (error) {
        dispatch({ type: GET_USER_WALLETS_FAILURE, payload: error.message || "An unexpected error occurred" });
    }
};

// Add wallet to whitelist
export const addWalletWhitelist = (walletData, token, userId) => async (dispatch) => {
    dispatch({ type: ADD_WALLET_WHITELIST_REQUEST });

    try {
        const response = await WalletaddresswhitelistRepository.addWalletWhitelist(
            walletData.coin_id,
            walletData.name,
            walletData.addr,
            walletData.paypassword,
            token,
            userId
        );
        console.log("walletData", walletData, token, userId);
        console.log("response add wallet", response);

        const { status, data } = response.data;

        if (status === 1) {
            dispatch({ type: ADD_WALLET_WHITELIST_SUCCESS, payload: data });
            return response; // Return the response to the caller
        } else {
            dispatch({ type: ADD_WALLET_WHITELIST_FAILURE, payload: data || "Failed to add wallet" });
            return response; // Return the response to the caller
        }
    } catch (error) {
        dispatch({ type: ADD_WALLET_WHITELIST_FAILURE, payload: error.message || "An unexpected error occurred" });
        return { status: 0, message: error.message || "An unexpected error occurred" }; // Return error response
    }
};



// Delete wallet from whitelist
export const deleteWalletWhitelist = (coinId,token, userId) => async (dispatch) => {
    dispatch({ type: DELETE_WALLET_WHITELIST_REQUEST });

    try {
        console.log("user id ",coinId, token,userId)
        const response = await WalletaddresswhitelistRepository.deleteUserWalletwhitelist(coinId, token,userId);
        console.log("respone delete ", response)
        const { status, data } = response.data;

        if (status === 1) {
            dispatch({ type: DELETE_WALLET_WHITELIST_SUCCESS, payload: walletId });
        } else {
            dispatch({ type: DELETE_WALLET_WHITELIST_FAILURE, payload: data || "Failed to delete wallet" });
        }
    } catch (error) {
        dispatch({ type: DELETE_WALLET_WHITELIST_FAILURE, payload: error.message || "An unexpected error occurred" });
    }
};


export const editWalletWhitelist = (walletData, token, userId) => async (dispatch) => {
    dispatch({ type: EDIT_WALLET_WHITELIST_REQUEST });

    try {
        console.log("editWalletWhitelist response:", walletData);
        const response = await WalletaddresswhitelistRepository.editUserWallet(
            walletData.coin_id,
            walletData.name,
            walletData.addr,
            walletData.paypassword,
            walletData.id, // Wallet ID
            token,
            userId
        );

        console.log("editWalletWhitelist response:", response);

        const { status, data } = response.data;

        if (status === 1) {
            dispatch({ type: EDIT_WALLET_WHITELIST_SUCCESS, payload: data });
            return response; // Return the response to the caller
        } else {
            dispatch({ type: EDIT_WALLET_WHITELIST_FAILURE, payload: data || "Failed to edit wallet" });
            return response; // Return the response to the caller
        }
    } catch (error) {
        dispatch({ type: EDIT_WALLET_WHITELIST_FAILURE, payload: error.message || "An unexpected error occurred" });
        return { status: 0, message: error.message || "An unexpected error occurred" }; // Return error response
    }
};