import React, { useCallback } from 'react';

const AdsNav = ({ setActiveComponent = () => {}, activeComponent }) => {
  // Move useCallback outside of any conditional logic
  const handleClick = useCallback((name) => {
    setActiveComponent(name);
  }, [setActiveComponent]);

  if (typeof setActiveComponent !== 'function') {
    console.error('setActiveComponent is not a function');
    return null;
  }

  const navItems = [
    { name: 'Normal Ads', label: 'Normal Ads' },
    { name: 'Cash Ads', label: 'Cash Ads' },
    { name: 'Block Ads', label: 'Block Ads' },
    { name: 'Fiat Ads', label: 'Fiat Ads' }
  ];

  return (
    <ul className="flex gap-3 overflow-x-auto hide-scrollbar mb-10">
      {navItems.map((item) => (
        <li key={item.name}>
          <button
            className={`px-4 py-2 font-semibold text-sm ${
              activeComponent === item.name ? 'bg-[#EFB81C33] text-[#EFB81C] rounded-lg' : 'text-gray-500'
            }`}
            onClick={() => handleClick(item.name)}
            style={{ minWidth: 'max-content' }}
          >
            {item.label}
          </button>
        </li>
      ))}
      <style>
        {`
          .hide-scrollbar::-webkit-scrollbar {
            display: none;
          }

          .hide-scrollbar {
            scrollbar-width: none;
          }
        `}
      </style>
    </ul>
  );
};

export default AdsNav;
