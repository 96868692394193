import Header from "components/header/Header";
import NetworkStatusChecker from "components/global/network/NetworkStatusChecker";
import { useState } from "react";

const Auth = (props) => {
    const [networkError, setNetworkError] = useState(false);

    return (
        <>
            {/* <Header /> */}
            {props.children}
            {/* Shows Network Status */}
            {networkError && (
                <NetworkStatusChecker setNetworkError={setNetworkError} />
            )}
        </>
    );
};

export default Auth;
