import React, { useState, useEffect } from "react";
import { BsBellFill, BsTrash } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { fetchLoginHistory } from "store/actions/AccountuserActions";

const UserNotification = () => {
    const dispatch = useDispatch();
    const { loginHistory, loading, error } = useSelector(
        (state) => state.userReducer || {}
    );

    const [selectionRange, setSelectionRange] = useState({
        startDate: null,
        endDate: null,
        key: "selection",
    });

    const datePickerRef = React.useRef(null);

    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};

    useEffect(() => {
        if (ID && TOKEN) {
            dispatch(fetchLoginHistory(ID, TOKEN));
        }
    }, [dispatch, ID, TOKEN]);

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [selectedStatus, setSelectedStatus] = useState("All");

    useEffect(() => {
        const storedNotifications = localStorage.getItem("notifications");
        if (storedNotifications) {
            setNotifications(JSON.parse(storedNotifications));
        } else if (loginHistory) {
            const updatedNotifications = loginHistory.map((notification) => ({
                ...notification,
                read: false,
                category: notification.category || "UnCategorized",
                date: new Date(parseInt(notification.addtime) * 1000),
            }));
            setNotifications(updatedNotifications);
        }
    }, [loginHistory]);

    useEffect(() => {
        localStorage.setItem("notifications", JSON.stringify(notifications));
    }, [notifications]);

    const handleSelect = (ranges) => {
        setSelectionRange(ranges.selection);
    };

    const handleToggle = () => {
        setShowDatePicker(!showDatePicker);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                datePickerRef.current &&
                !datePickerRef.current.contains(event.target)
            ) {
                setShowDatePicker(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleRead = (id) => {
        setNotifications((prevNotifications) =>
            prevNotifications.map((notification) =>
                notification.id === id
                    ? { ...notification, read: !notification.read }
                    : notification
            )
        );
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleStatusChange = (status) => {
        setSelectedStatus(status);
    };

    const filteredNotifications = notifications.filter((notification) => {
        // Filter by category
        if (
            selectedCategory !== "All" &&
            notification.category !== selectedCategory
        ) {
            return false;
        }
        // Filter by status
        if (selectedStatus === "Unread" && notification.read) {
            return false;
        }
        if (selectedStatus === "Read" && !notification.read) {
            return false;
        }
        // Filter by date range
        if (selectionRange.startDate && selectionRange.endDate) {
            const notificationDate = new Date(notification.date);
            const startDate = new Date(selectionRange.startDate);
            const endDate = new Date(selectionRange.endDate);
            endDate.setHours(23, 59, 59, 999);
            if (notificationDate < startDate || notificationDate > endDate) {
                return false;
            }
        }
        return true;
    });

    const deleteNotification = (id) => {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((notification) => notification.id !== id)
        );
    };

    // console.log("Filtered notifications:", filteredNotifications); 

    return (
        <div className="w-full mt-10">
            <div className="flex items-center gap-2 mb-[35px]">
                <div>
                    <BsBellFill size={20} />
                </div>
                <div>
                    <span className="text-[22px] font-semibold">
                        Notification Center
                    </span>
                </div>
            </div>
            <div className="flex items-center gap-8 w-max">
                <div className="flex items-center gap-2 user-notification__status-filter-wrapper">
                    <div className="cursor-default user-notification__status-filter__label">
                        <span className="text-[14px] leading-none">
                            Status:
                        </span>
                    </div>
                    <div className="flex items-center gap-1 user-notification__status-filter">
                        <button
                            className={`filter__toggler__btn min-w-[70px] py-[2px] px-[10px] border-[0.5px] bg-[white] rounded-md ${
                                selectedStatus === "All"
                                    ? "active-status-filter"
                                    : ""
                            }`}
                            onClick={() => handleStatusChange("All")}
                        >
                            <span className="text-[12px] font-medium leading-none">
                                All
                            </span>
                        </button>
                        <button
                            className={`filter__toggler__btn min-w-[70px] py-[2px] px-[10px] border-[0.5px] bg-[white] rounded-md ${
                                selectedStatus === "Unread"
                                    ? "active-status-filter"
                                    : ""
                            }`}
                            onClick={() => handleStatusChange("Unread")}
                        >
                            <span className="text-[12px] font-medium leading-none">
                                Unread
                            </span>
                        </button>
                        <button
                            className={`filter__toggler__btn min-w-[70px] py-[2px] px-[10px] border-[0.5px] bg-[white] rounded-md ${
                                selectedStatus === "Read"
                                    ? "active-status-filter"
                                    : ""
                            }`}
                            onClick={() => handleStatusChange("Read")}
                        >
                            <span className="text-[12px] font-medium leading-none">
                                Read
                            </span>
                        </button>
                    </div>
                </div>
                <div className="flex items-center gap-2 user-notification__category-filter-wrapper">
                    <div className="cursor-default user-notification__category-filter__label">
                        <span className="text-[14px] leading-none">
                            Category:
                        </span>
                    </div>
                    <div className="user-notification__category-filter__dropdown">
                        <select
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                            className="border-[0.5px] bg-[white] rounded-md text-[12px] relative"
                        >
                            <option value="All" className="text-[12px]">
                                All
                            </option>
                            <option
                                value="System Messages"
                                className="text-[12px]"
                            >
                                System Messages
                            </option>
                            <option value="Promotions" className="text-[12px]">
                                Promotions
                            </option>
                            <option
                                value="Transactions"
                                className="text-[12px]"
                            >
                                Transactions
                            </option>
                            <option value="Referrals" className="text-[12px]">
                                Referrals
                            </option>
                            <option value="Security" className="text-[12px]">
                                Security
                            </option>
                            <option value="Orders" className="text-[12px]">
                                Orders
                            </option>
                        </select>
                    </div>
                </div>

                <div className="flex items-center gap-2 user-notification__date-range-wrapper">
                    <div className="cursor-default user-notification__date-range__label">
                        <span className="text-[14px] leading-none">
                            Select Date:
                        </span>
                    </div>
                    <div
                        className="relative flex items-center gap-1 user-notification__date-range__dropdown"
                        ref={datePickerRef}
                    >
                        <button
                            type="button"
                            onClick={handleToggle}
                            className="min-w-[70px] py-[2px] px-[10px] border-[0.5px] bg-[white] rounded-md"
                        >
                            <span className="text-[12px]">
                                {selectionRange.startDate &&
                                selectionRange.endDate
                                    ? selectionRange.startDate.toDateString() ===
                                      selectionRange.endDate.toDateString()
                                        ? selectionRange.startDate.toDateString()
                                        : `${selectionRange.startDate.toDateString()} - ${selectionRange.endDate.toDateString()}`
                                    : "All Dates"}
                            </span>
                        </button>
                        {showDatePicker && (
                            <DateRangePicker
                                ranges={[selectionRange]}
                                onChange={handleSelect}
                                showSelectionPreview={true}
                                months={2}
                                direction="horizontal"
                                rangeColors={["#EFB81C"]}
                                minDate={new Date(2024, 0, 1)}
                                maxDate={new Date()}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="user-notification__notification-wrapper bg-white rounded-md min-h-[70vh] mt-3">
                <div className="flex flex-col p-4 notification-wrapper__inner-wrapper">
                    {filteredNotifications
                        .filter((notification) => {
                            if (selectedStatus === "All") return true;
                            if (selectedStatus === "Read")
                                return notification.read;
                            if (selectedStatus === "Unread")
                                return !notification.read;
                            return true;
                        })
                        .map((notification) => (
                            <div
                                key={notification.id}
                                className="notification__item__body-wrapper flex items-center justify-between gap-2 py-2.5 border-b"
                            >
                                <div className="flex flex-col w-full gap-2 notification__item__head">
                                    <div className="flex items-center gap-2">
                                        <div
                                            className={`relative notification__item__name w-max ${
                                                notification.read
                                                    ? ""
                                                    : "unread-notification"
                                            }  `}
                                        >
                                            {/* display notification type/as name */}
                                            <p className="text-[12px] font-semibold">
                                                {notification.type || "Unknown"}
                                            </p>
                                        </div>
                                        {/* To display category */}
                                        <span className="text-[10px] font-medium bg-[#F4F4F4] rounded-full px-2.5 py-1 ml-[10%]">
                                            {notification.category || "Unknown"}
                                        </span>
                                    </div>
                                    <div className="notification__item__content">
                                        <p className="text-[12px] text-[#15141590] max-w-[75%] line-clamp-1 hover:line-clamp-none">
                                            {notification.remark}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex w-full notification__item__date-time">
                                    <div className="flex items-center gap-4 ml-auto">
                                        <span className="notification__item__date-and-time text-[12px] font-medium">
                                            {new Date(
                                                notification.date
                                            ).toLocaleString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                second: "2-digit",
                                                hour12: true,
                                            })}
                                        </span>
                                        <button
                                            onClick={() => {
                                                notification.read
                                                    ? toggleRead(
                                                          notification.id
                                                      )
                                                    : toggleRead(
                                                          notification.id
                                                      );
                                            }}
                                            className="font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#EFB81C] text-[#151415] min-w-max rounded-md text-[10px] transition-all ease-out duration-300"
                                        >
                                            {notification.read
                                                ? "Mark as Unread"
                                                : "Mark as Read"}
                                        </button>
                                        {notification.read ? (
                                            <button
                                                onClick={() =>
                                                    deleteNotification(
                                                        notification.id
                                                    )
                                                }
                                                type="button"
                                                className="font-semibold px-[10px] py-2 bg-[#F4F4F4] hover:bg-[#15141530] text-[#151415] min-w-max rounded-md text-[12px] border border-[#F4F4F4] transition-all ease-out duration-300"
                                            >
                                                <BsTrash size={12} fill="red" />
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default UserNotification;
