import React, { useState, useEffect } from "react";
import bitcoin from "assets/img/profile.png";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { IoEye } from "react-icons/io5";

const TableOverview = ({ assetsData, loading, error, hideAssets }) => {
  const [isFilter, setIsFilter] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (assetsData && (assetsData.cryptoList || assetsData.fiatList)) {
      if (isFilter && assetsData.cryptoList) {
        const coinList = Object.values(assetsData.cryptoList).map(
          (coin, index) => ({
            id: index,
            icon: coin.img || "bitcoin",
            coinName: coin.name.toUpperCase(),
            coinType: coin.title,
            amount: parseFloat(coin.xnbz),
            value: coin.xnb,
            coinPrice: coin.jia,
            change: coin.xnb,
          })
        );
        setTableData(coinList);
      } else if (assetsData.fiatList) {
        const fiatList = Object.values(assetsData.fiatList).map(
          (fiat, index) => ({
            id: index,
            icon: fiat.img || "bitcoin",
            coinName: fiat.name.toUpperCase(),
            coinType: fiat.title,
            amount: parseFloat(fiat.xnbz),
            value: fiat.xnb,
            coinPrice: fiat.jia,
            change: fiat.xnb,
          })
        );
        setTableData(fiatList);
      }
    }
  }, [isFilter, assetsData]);

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedData = [...tableData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setTableData(sortedData);
    setSortConfig({ key, direction });
  };

  const filteredData = tableData.filter(
    (data) =>
      data.coinName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data.coinType.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg text-red-500">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-full p-[28px] mt-3 border bg-white rounded-2xl">
      {/* Heading */}
      <div className="flex items-center justify-between w-full">
        <div className="text-[18px] font-medium flex">
          <h6>My Assets</h6>
        </div>
        <button
          type="button"
          className="px-3 py-1 text-[12px] rounded-xl bg-[#ededed] flex items-center gap-1"
        >
          View All <MdOutlineKeyboardArrowRight />
        </button>
      </div>

      {/* Buttons */}
      <div className="flex w-full  justify-start mt-4 font-medium gap-x-6 text-[16px]">
        <button
          onClick={() => setIsFilter(true)}
          className={`${
            isFilter ? "border-[#c9c9c9]" : "border-transparent"
          } pb-1 text-[16px] border-b-4`}
        >
          Coin View
        </button>
        <button
          onClick={() => setIsFilter(false)}
          className={`${
            isFilter ? "border-transparent" : "border-[#c9c9c9]"
          } pb-1 text-[16px] border-b-4`}
        >
          Wallet View
        </button>
      </div>

      {/* Main Table */}
      <table className="w-full mt-4 table-container h-fit">
        {/* Table Headings */}
        <thead className="mb-1 border-b">
          <tr className="flex justify-between">
            <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-left">
              <div
                className="flex items-center gap-1 text-[15px] cursor-pointer"
                onClick={() => sortData("coinName")}
              >
                {" "}
                Coin
                <span className="relative flex text-[#aaaaaa] text-sm">
                  <span
                    className={`absolute right-0 top-1 ${
                      sortConfig.key === "coinName" &&
                      sortConfig.direction === "ascending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                  <span
                    className={`mt-[9px] ${
                      sortConfig.key === "coinName" &&
                      sortConfig.direction === "descending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </span>
              </div>
            </th>
            <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
              <div
                className="flex items-center justify-center text-[15px] gap-1 cursor-pointer"
                onClick={() => sortData("amount")}
              >
                {" "}
                Amount
                <span className="relative flex text-[#aaaaaa] text-sm">
                  <span
                    className={`absolute right-0 top-1 ${
                      sortConfig.key === "amount" &&
                      sortConfig.direction === "ascending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                  <span
                    className={`mt-[9px] ${
                      sortConfig.key === "amount" &&
                      sortConfig.direction === "descending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </span>
              </div>
            </th>
            <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
              <div
                className="flex items-center justify-center text-[15px] gap-1 cursor-pointer"
                onClick={() => sortData("value")}
              >
                {" "}
                Value
                <span className="relative flex text-[#aaaaaa] text-sm">
                  <span
                    className={`absolute right-0 top-1 ${
                      sortConfig.key === "value" &&
                      sortConfig.direction === "ascending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                  <span
                    className={`mt-[9px] ${
                      sortConfig.key === "value" &&
                      sortConfig.direction === "descending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </span>
              </div>
            </th>
            <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
              <div
                className="flex items-center justify-center text-[15px] gap-1 cursor-pointer"
                onClick={() => sortData("coinPrice")}
              >
                {" "}
                Coin Price
                <span className="relative flex text-[#aaaaaa] text-sm">
                  <span
                    className={`absolute right-0 top-1 ${
                      sortConfig.key === "coinPrice" &&
                      sortConfig.direction === "ascending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                  <span
                    className={`mt-[9px] ${
                      sortConfig.key === "coinPrice" &&
                      sortConfig.direction === "descending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </span>
              </div>
            </th>
            <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
              <div
                className="flex items-center justify-center text-[15px] gap-1 cursor-pointer"
                onClick={() => sortData("change")}
              >
                {" "}
                24h Change
                <span className="relative flex text-[#aaaaaa] text-sm">
                  <span
                    className={`absolute right-0 top-1 ${
                      sortConfig.key === "change" &&
                      sortConfig.direction === "ascending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                  <span
                    className={`mt-[9px] ${
                      sortConfig.key === "change" &&
                      sortConfig.direction === "descending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </span>
              </div>
            </th>
            <th className="text-[#aaaaaa] text-[15px] font-light px-4 py-2 flex-1 text-right">
              Action
            </th>
          </tr>
        </thead>

        {/* Table Data */}
        <tbody>
          {filteredData.map((data) => (
            <tr key={data.id} className="flex justify-between py-1">
              {/* For Coin Column */}
              <td className="flex flex-1 px-4 py-2 text-left gap-x-2">
                <img
                  src={`https://api.buycex.com/Upload/coin/${data.icon}`}
                  alt=""
                  className="w-8 h-8 rounded-full"
                />
                <div className="flex flex-col">
                  <span className="text-[15px]">{data.coinName}</span>
                  <span className="text-[12px] text-[#aaaaaa]">
                    {data.coinType}
                  </span>
                </div>
              </td>
              {/* For Amount Column */}
              <td className="flex-1 px-4 py-2 text-[14px] text-center">
                {hideAssets ? "****" : data.amount.toFixed(2)}
              </td>
              {/* For Value Column */}
              <td className="flex-1 px-4 py-2 text-center  text-[14px]">{data.value}</td>
              {/* For Coin Price Column */}
              <td className="flex-1 px-4 py-2 text-center  text-[14px]">
                ${data.coinPrice.toFixed(2)}
              </td>
              {/* For 24h Change Column */}
              <td
                className="flex-1 px-4 py-2 text-center  text-[14px]"
                style={{ color: data.change < 0 ? "red" : "green" }}
              >
                {data.change}%
              </td>
              {/* For Action Column */}
              <td className="flex-1 px-4 py-2 text-right  text-[14px]">
                <button className="text-black">Deposit</button>{" "}
                <button className="text-yellow-500 ">Trade</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableOverview;
