import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { IoClose } from 'react-icons/io5';
import { RiArrowDropDownLine } from "react-icons/ri";
import { AiFillDollarCircle } from "react-icons/ai";
import { FaBitcoin, FaEthereum, FaDog } from 'react-icons/fa';
import { TbTransferVertical } from "react-icons/tb";
import styled from 'styled-components';
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import { IoInformationCircle } from "react-icons/io5";
import { IoSearchOutline } from "react-icons/io5";

const currencyOptions = [
    { name: 'BTC', description: 'Bitcoin', icon: <FaBitcoin size={24} /> },
    { name: 'ETH', description: 'Ethereum', icon: <FaEthereum size={24} /> },
    { name: 'DOGE', description: 'Dogecoin', icon: <FaDog size={24} /> },
    { name: 'LTC', description: 'Litecoin', icon: <FaEthereum size={24} /> },
    { name: 'XRP', description: 'Ripple', icon: <FaEthereum size={24} /> },
];

const ConvertModal = ({ visible, onCancel }) => {
    const [fromValue, setFromValue] = useState('');
    const [toValue, setToValue] = useState('');
    const [selectedCurrencies, setSelectedCurrencies] = useState({
        from: { name: 'USDT', icon: <AiFillDollarCircle size={20} /> },
        to: { name: 'BTC', icon: <FaBitcoin size={20} /> }
    });
    const [isCurrencyModalVisible, setCurrencyModalVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentInput, setCurrentInput] = useState('from');

    const handleChange = (setter) => (e) => setter(e.target.value);
    const handleCurrencyClick = (type) => {
        setCurrentInput(type);
        setCurrencyModalVisible(true);
    };

    const handleCurrencySelect = (currency) => {
        setSelectedCurrencies((prev) => ({
            ...prev,
            [currentInput]: currency
        }));
        setCurrencyModalVisible(false);
    };

    const handleTransferClick = () => {
        setFromValue(toValue);
        setToValue(fromValue);
        setSelectedCurrencies((prev) => ({
            from: prev.to,
            to: prev.from
        }));
    };

    const [checkedItems, setCheckedItems] = useState({
        spot: false,
        funding: false,
    });

    const handleCheckboxToggle = (name) => {
        setCheckedItems((prevItems) => ({
            ...prevItems,
            [name]: !prevItems[name],
        }));
    };

    const filteredCurrencies = currencyOptions.filter(({ name, description }) =>
        name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        description.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <Modal visible={visible} footer={null} onCancel={onCancel} closeIcon={<IoClose />} title="Convert">
                <div className='flex items-center justify-between w-full my-6'>
                    <div className='flex items-center gap-1'>
                        <p className='text-sm text-[#707a8a]'>Wallet</p>
                        <IoInformationCircle className='text-gray-400' />
                    </div>
                    <div className="flex gap-5">
                        <div className="flex items-center gap-1 cursor-pointer" onClick={() => handleCheckboxToggle('spot')}>
                            {checkedItems.spot ? <MdCheckBox className="text-xl text-[#EFB81C]" /> : <MdCheckBoxOutlineBlank className="text-xl" />}
                            <p className="text-sm">Spot</p>
                        </div>
                        <div className="flex items-center gap-1 cursor-pointer" onClick={() => handleCheckboxToggle('funding')}>
                            {checkedItems.funding ? <MdCheckBox className="text-xl text-[#EFB81C]" /> : <MdCheckBoxOutlineBlank className="text-xl" />}
                            <p className="text-sm">Funding</p>
                        </div>
                    </div>
                </div>
                <CurrencyInput
                    label="From"
                    value={fromValue}
                    onChange={handleChange(setFromValue)}
                    selectedCurrency={selectedCurrencies.from}
                    onSelect={() => handleCurrencyClick('from')}
                />
                <div className='flex justify-center my-5'>
                    <TbTransferVertical className='text-[42px] bg-gray-100 p-2 text-gray-400 rounded-full' onClick={handleTransferClick} />
                </div>
                <CurrencyInput
                    label="To"
                    value={toValue}
                    onChange={handleChange(setToValue)}
                    selectedCurrency={selectedCurrencies.to}
                    onSelect={() => handleCurrencyClick('to')}
                />
                <div className='flex items-center justify-between w-full my-6'>
                    <div className='flex items-center gap-1'>
                        <p className='text-sm text-[#707a8a]'>Rate</p>
                        <IoInformationCircle className='text-gray-400' />
                    </div>
                    <p className='text-sm font-semibold text-[#707a8a]'>1 USDT = 0.00163186 BNB</p>
                </div>
                <button className='font-semibold bg-[#EFB81C] w-full text-center rounded-md py-3'>Enter Amount</button>
            </Modal>

            <CurrencySelectModal
                visible={isCurrencyModalVisible}
                onCancel={() => setCurrencyModalVisible(false)}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                filteredCurrencies={filteredCurrencies}
                onCurrencySelect={handleCurrencySelect}
            />
        </>
    );
};

const CurrencyInput = ({ label, value, onChange, selectedCurrency, onSelect }) => (
    <div className='p-5 bg-gray-100 rounded-sm'>
        <div className='flex justify-between'>
            <p className='text-xs'>{label}</p>
            <p className='text-xs'>Balance: 0</p>
        </div>
        <div className='flex gap-2 mt-5'>
            <Input value={value}
                onChange={onChange} placeholder='0.00'
                className='w-full p-0 text-2xl font-semibold bg-transparent' variant="borderless" />
            <div className='flex items-center justify-center w-1/3 gap-2 py-1 bg-white rounded-full' onClick={onSelect}>
                {selectedCurrency.icon}
                <div className='flex items-center'>
                    <p className='font-medium text-md'>{selectedCurrency.name}</p>
                    <RiArrowDropDownLine />
                </div>
            </div>
        </div>
    </div>
);

const CurrencySelectModal = ({ visible, onCancel, searchQuery, setSearchQuery, filteredCurrencies, onCurrencySelect }) => (
    <StyledModal visible={visible} footer={null} onCancel={onCancel} title="Select Currency">
        <div className="flex flex-col mt-8">
            <StyledInput
                type="text"
                placeholder="Search currency..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="px-3 py-2 mb-5 border border-gray-300 rounded-lg "
                prefix={<IoSearchOutline />}
            />
            {filteredCurrencies.length > 0 ? (
                filteredCurrencies.map((currency, index) => (
                    <div key={index} className="flex items-center p-2 cursor-pointer hover:bg-gray-50" onClick={() => onCurrencySelect(currency)}>
                        <div className="flex items-center justify-center mr-4" style={{ width: '30px', height: '30px' }}>
                            {currency.icon}
                        </div>
                        <div className="flex flex-col">
                            <span className="font-medium">{currency.name}</span>
                            <span className="text-sm text-gray-500">{currency.description}</span>
                        </div>
                    </div>
                ))
            ) : (
                <div className="p-4 text-center text-gray-500">
                    No results found
                </div>
            )}
        </div>
    </StyledModal>
);


const StyledInput = styled(Input)`
  padding: 0.55rem;
  font-weight: bold;
  color: #707A8A;
  border: 2px solid #DBDBDB;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #EFB81C;
  }

  &:focus, 
  &:focus-within {
    border-color: #EFB81C;
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2);
    outline: none;
  }
`;


const StyledModal = styled(Modal)`
  .ant-modal-content {
    height: 600px !important;
    max-height: 600px !important;
  }
  .ant-modal {
    width: 1200px !important;
    max-width: 1200px !important;
    display: flex;
    justify-content: center;
  }
  div[tabindex="-1"] {
    display: flex;
    outline: none;
    justify-content: center;
    align-items: center;
  }
`;


export default ConvertModal;
