// competitionRepository.js
import axios from 'axios';
import Repository from './Repository';

// Endpoints
const ISSUES_ENDPOINT = '/Vote/competition';
const UPDATE_COMPETITION_ENDPOINT = '/Vote/updateCompetition'; // Add the update endpoint

// Fetch competition data
const getCompetitionData = async () => {
  const response = await Repository.get(ISSUES_ENDPOINT);
  return response.data;
};

// Update competition data
const updateCompetitionData = async (competitionId, updatedData) => {
  const response = await Repository.put(`${UPDATE_COMPETITION_ENDPOINT}/${competitionId}`, updatedData);
  return response.data;
};

export default {
  getCompetitionData,
  updateCompetitionData, // Export the update action
};
