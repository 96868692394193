import { FETCH_FIAT_LIST_FAILURE, FETCH_FIAT_LIST_REQUEST, FETCH_FIAT_LIST_SUCCESS } from "../types";

const initialState = {
    loading: false,
    fiatList: [],
    error: null
};

const fiatListReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FIAT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_FIAT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                fiatList: action.payload
            };
        case FETCH_FIAT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default fiatListReducer;