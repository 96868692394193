import React, { useEffect, useState } from "react";
import {
    BsCaretDownFill,
    BsCaretUpFill,
    BsQuestionCircleFill,
    BsStarFill,
} from "react-icons/bs";
import { useMarketData } from "../hooks/useMarketData";
import { useSort } from "../hooks/useSortData";

const SearchDataTable = ({ searchQuery }) => {
    const {
        filterData,
        marketData,
        validIcons,
        formatPrice,
        extractCoinName,
        handleDetailsRedirect,
        handleTradeRedirect,
        isFavoritePair,
        toggleFavoritePair,
    } = useMarketData();

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const filtered = marketData.filter((coin) =>
            coin.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchQuery, marketData]);

    const { data: sortedMarketData, sortOrder, sortData } = useSort(marketData);
    
    return (
        <div className="market-data-table-wrapper">
            <table className="market-data-table table__all-crypto w-full">
            <thead className="market-data-table__header">
                    <tr className="market-data-table__header-row flex justify-between">
                        <th className="market-data-table__header-row__heading">
                            <h4 className="heading-title">Coin Pair</h4>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="data-filter-btn flex items-center gap-1 w-full"
                                onClick={() => sortData("new_price")}
                            >
                                <h4 className="heading-title">Last Price</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.new_price === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.new_price === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="data-filter-btn flex items-center gap-1 w-full"
                                onClick={() => sortData("change")}
                            >
                                <h4 className="heading-title">24h Change</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.change === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.change === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <h4 className="heading-title">24h High</h4>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="data-filter-btn flex items-center gap-1 w-full"
                                onClick={() => sortData("volume")}
                            >
                                <h4 className="heading-title">Total Volume</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <h4 className="heading-title">24h Chart</h4>
                        </th>
                        <th
                            className="market-data-table__header-row__heading"
                            colSpan="2"
                        >
                            <h4 className="heading-title">Trade</h4>
                        </th>
                    </tr>
                </thead>

                <tbody className="market-data-table__body">
                    {filteredData.length ? (
                        filteredData.map((coin) => (
                            <tr
                                key={coin.id}
                                className="market-data-table__body-row flex justify-between"
                            >
                                <td className="market-data-table__body-row__data">
                                    <div className="flex items-center gap-2">
                                        <button
                                            type="button"
                                            onClick={() =>
                                                toggleFavoritePair(coin.id)
                                            }
                                            className="favorite-toggler w-max h-max"
                                        >
                                            <BsStarFill
                                                size={12}
                                                fill={
                                                    isFavoritePair(coin.id)
                                                        ? "#EFB81C"
                                                        : "#15141540"
                                                }
                                            />
                                        </button>
                                        {validIcons[coin.id] ? (
                                            <img
                                                src={coin.icon}
                                                alt={coin.name}
                                                className="w-5 h-5 rounded-full"
                                            />
                                        ) : (
                                            <BsQuestionCircleFill className="w-5 h-5" fill="#15141540" />
                                        )}
                                        <span className="text-[14px] font-[500]">
                                            {extractCoinName(coin.name)}
                                        </span>
                                    </div>
                                </td>

                                <td className="market-data-table__body-row__data">
                                    <span className="text-[13px] font-[500]">
                                        {coin.new_price
                                            ? "$" + (coin.new_price)
                                            : "-"}
                                    </span>
                                </td>

                                <td className="market-data-table__body-row__data">
                                    <span
                                        className={`text-[13px] font-[500] ${
                                            coin.change < 0
                                                ? "text-red-500"
                                                : "text-green-500"
                                        }`}
                                    >
                                        {coin.change > 0
                                            ? `+${coin.change.toFixed(2)}%`
                                            : `${coin.change.toFixed(2)}%`}
                                    </span>
                                </td>

                                <td className="market-data-table__body-row__data">
                                    <span className="text-[13px] font-[500]">
                                        {coin.max_price
                                            ? "$" + coin.max_price
                                            : "-"}
                                    </span>
                                </td>
                                <td className="market-data-table__body-row__data">
                                    <span className="text-[13px] font-[500]">
                                        {coin.volume
                                            ? formatPrice(coin.volume)
                                            : "-"}
                                    </span>
                                </td>

                                <td className="market-data-table__body-row__data">
                                    <a
                                        href={coin.chart || "#"}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src="https://dummyimage.com/50/f4f4f4/000000"
                                            alt={coin.name}
                                            className="w-24 h-12 object-contain mr-auto"
                                        />
                                    </a>
                                </td>
                                <td className="market-data-table__body-row__data">
                                    <div className="flex items-center gap-1 w-max ml-auto">
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleDetailsRedirect(coin.id)
                                            }
                                            className="text-[14px] font-[500] px-4 py-1 rounded-md"
                                        >
                                            Details
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleTradeRedirect(coin.id)
                                            }
                                            className="text-[14px] font-[500] px-4 py-1 rounded-md bg-[#EFB81C]"
                                        >
                                            Trade
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" className="text-center">
                                No results found
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default SearchDataTable;
