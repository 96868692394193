import React, { useEffect, useState } from 'react';
import { BsCheckCircleFill, BsXCircleFill, BsExclamationCircleFill, BsQuestionCircleFill } from "react-icons/bs";
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserProfile } from 'store/actions/userProfileAction';
import { fetchAuthStatus } from 'store/actions/userSecuritySettingsAction';

function SecurityStatus({ authStatus, antiPhishing, withdrawalPasswordValue, antiPhishingNotVerified }) {
    const user = useSelector((state) => state.authUser?.user || {});
    const { userProfile } = useSelector((state) => state.userInfo);
    const kyc = userProfile?.kyc;

    const userId = user?.ID;
    const token = user?.TOKEN;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!userId || !token) {
            history.push("/login");
        } else {
            dispatch(fetchUserProfile(userId, token));
            dispatch(fetchAuthStatus(userId, token));
        }
    }, [userId, token, history, dispatch]);
    const [settings, setSettings] = useState([
        {
            label: "Withdrawal Password",
            description: "Set withdrawal password to protect your assets",
            isSet: withdrawalPasswordValue,
            type: "password",
        },
        {
            label: "Anti-Phishing Code",
            description: "For verifying official emails from BUYCEX",
            isSet: antiPhishingNotVerified,
            type: "popup",
        },
    ]);

    useEffect(() => {
        setSettings((prevSettings) => [
            { ...prevSettings[0], isSet: !!authStatus.paypassword },
            { ...prevSettings[1], isSet: !!authStatus.antiphishing },
            ...prevSettings.slice(2),
        ]);
    }, [authStatus]);

    return (
        <div className='border rounded-xl mt-4 flex flex-col gap-4 md:gap-0 md:flex-row w-full justify-between px-4 lg:px-10 py-5 mb-10'>
            <div className='flex flex-row-reverse md:flex-col gap-2 items-center justify-end md:justify-center'>
                <p className='font-medium'>KYC Verification</p>
                <div className="flex items-center gap-1">
                    {userProfile.kyc === "1" ? (
                        <BsCheckCircleFill
                            size={12}
                            fill="#008000"
                        />
                    ) : userProfile.kyc === "0" ? (
                        <BsXCircleFill
                            size={12}
                            color="#808080"
                        />
                    ) : userProfile.kyc === "2" ? (
                        <BsExclamationCircleFill
                            size={12}
                            color="#EFB81C"
                        />
                    ) : (
                        <BsQuestionCircleFill
                            size={12}
                            color="#808080"
                        />
                    )}
                    <span className="text-[12px] font-medium text-[#15141590]">
                        {userProfile.kyc === "0"
                            ? 'Not Verified'
                            : userProfile.kyc === "1"
                                ? 'Verified'
                                : userProfile.kyc === "2"
                                    ? 'In Review'
                                    : 'Unknown Status'}
                    </span>
                </div>

            </div>
            <div className='flex flex-row-reverse md:flex-col gap-2 items-center justify-end md:justify-center'>
                <p className='font-medium'>Google Authenticator</p>
                <div className='flex items-center'>
                    <div className="flex items-center gap-1">
                        {authStatus.ga ? (
                            <BsCheckCircleFill
                                size={12}
                                fill="#008000"
                            />
                        ) : (
                            <BsXCircleFill
                                size={12}
                                color="#808080"
                            />
                        )}
                        <span className="text-[12px] font-medium text-[#15141590]">
                            {authStatus.ga ? "G2FA Enabled" : "G2FA Not-Enabled"}
                        </span>
                    </div>
                </div>
            </div>
            <div className='flex flex-row-reverse md:flex-col gap-2 items-center justify-end md:justify-center'>
                <p className='font-medium'>Anti-Phishing Code</p>
                <div className="flex items-center gap-1">
                    {settings[1].isSet ? (
                        <BsCheckCircleFill
                            size={12}
                            fill="#008000"
                        />
                    ) : (
                        <BsXCircleFill
                            size={12}
                            color="#808080"
                        />
                    )}
                    <span className="text-[12px] font-medium text-[#15141590]">
                        {settings[1].isSet ? "Enabled" : "Not Configure"}
                    </span>
                </div>
            </div>

            {/* Fund Password Section */}
            <div className='flex flex-row-reverse md:flex-col gap-2 items-center justify-end md:justify-center'>
                <p className='font-medium'>Fund Password</p>
                {settings.map((item, index) => (
                    item.type === "password" && (
                        <div key={index} className="flex items-center gap-1">
                            {item.isSet ? (
                                <BsCheckCircleFill
                                    size={12}
                                    fill="#008000"
                                />
                            ) : (
                                <BsXCircleFill
                                    size={12}
                                    color="#808080"
                                />
                            )}
                            <span className="text-[12px] font-semibold text-[#15141580]">
                                {item.isSet ? "Set" : "Not Configure"}
                            </span>
                        </div>
                    )
                ))}
            </div>
        </div>
    );
}

export default SecurityStatus;
