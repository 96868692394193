import { Modal } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyG2faCode } from '../../../../../../store/actions/authAction'; 

const P2pAuthenticator = ({ authVisible, onClose,onSuccess,orderId }) => {
    const state = useSelector((state) => state.auth.user.ID);
    const userId = useSelector((state) => state.auth.user.ID);
    const token = useSelector((state) => state.auth.user.TOKEN);
    console.log("sdsdsds",state);
    console.log("ddssdsdsdsdsds",userId,token);
    const dispatch = useDispatch();
    const [g2aCode, setG2aCode] = useState(Array(6).fill(""));

    const inputRefs = useRef([]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            const activeIndex = inputRefs.current.findIndex(
                (input) => input === document.activeElement
            );
            handleBackspace(e, activeIndex);
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [g2aCode]);

    const handleG2aCodeChange = (e, index) => {
        const { value } = e.target;
        if (!/^\d*$/.test(value)) return; 
        const newG2aCode = [...g2aCode];
        newG2aCode[index] = value.slice(-1);
        setG2aCode(newG2aCode);

        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        } else if (!value && index > 0) {
            inputRefs.current[index - 1].focus();
        }

        if (newG2aCode.every((digit) => digit !== "")) {
            verifyG2faCodeHandler(newG2aCode);
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && index > 0 && g2aCode[index] === "") {
            inputRefs.current[index - 1].focus();
            const newG2aCode = [...g2aCode];
            newG2aCode[index - 1] = "";
            setG2aCode(newG2aCode);
        }
    };

    const verifyG2faCodeHandler = (code) => {
        const payload = {
            user_id: state,
            ga: parseInt(code.join("")),
        };
        dispatch(verifyG2faCode(payload,onSuccess)); 
    };

    const handleSubmit = () => {
        verifyG2faCodeHandler(g2aCode);
    };

    return (
        <Modal
            title=""
            visible={authVisible}
            onCancel={onClose}
            footer={null}
        >
            <div className='p-4'>
                <p className='text-3xl font-semibold pt-8'>Authenticator App Verification</p>
                <p className='pb-8 mt-6 text-[#aaa] text-sm'>Enter the 6-digit code generated by the Authenticator App.</p>
                <p className='font-semibold mb-1 text-gray-600'>Authenticator App</p>
                <div className='flex items-center mb-7 border rounded-lg'>
                    {g2aCode.map((digit, index) => (
                        <input
                            key={index}
                            ref={el => inputRefs.current[index] = el}
                            type="text"
                            value={digit}
                            onChange={(e) => handleG2aCodeChange(e, index)}
                            maxLength={1}
                            className='h-12 w-full rounded-lg text-center'
                        />
                    ))}
                </div>
                <button 
                    className="bg-[#EFB81C] w-full font-semibold px-3 h-12 text-[16px] rounded-lg" 
                    onClick={handleSubmit}
                >
                    Submit
                </button>
                <p className='px-3 font-semibold text-[#EFB81C] text-sm text-center pt-7'>Switch to Another Verification Method</p>
                {/* <p className='px-3 font-semibold text-[#EFB81C] text-sm text-center pt-4 mb-12'>Switch verification unavailable?</p> */}
            </div>
        </Modal>
    );
};

export default P2pAuthenticator;