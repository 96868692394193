import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotCode, confirmCode } from "store/actions/authAction";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import ModalVerification from "../../../../auth/validations/email/emailVerificationHelp";
import { Spinner } from "reactstrap";

function EmailVerification({ onSuccess = {} }) {
    const [otp, setOtp] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [timer, setTimer] = useState(60);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const email = useSelector((state) => state.authCheck.authStatus.email);
    const password = useSelector((state) => state.myreducer.password);

    const dispatch = useDispatch();

    useEffect(() => {
        let interval = null;
        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        setIsButtonDisabled(false);
                        return 60;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isButtonDisabled]);

    const verifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        const payload = {
            email: email,
            password: password,
            verify_code: otp,
        };
        dispatch(
            confirmCode(payload, true, () => {
                setLoading(false);
                onSuccess();
            })
        );
    };

    const handleSendCode = () => {
        if (!isButtonDisabled) {
            dispatch(forgotCode({ email }));
            setIsButtonDisabled(true);
        }
    };

    const maskedEmail = email ? `${email.slice(0, 3)}****@****.com` : "";

    return (
        <div className="flex flex-col gap-4 my-auto">
            <p className="text-2xl font-semibold">Email Verification</p>
            <form onSubmit={verifyOtp}>
                <div className="flex flex-col gap-4 items-start justify-center">
                    <p className="text-sm text-[#81858c] my-3 mb-4">
                        Enter the 6-digit verification code sent to{" "}
                        <span className="font-medium text-black text-sm">
                            {maskedEmail}
                        </span>
                        .
                    </p>
                    <div className="w-full">
                        <p className="font-medium mb-1">
                            Email Verification Code
                        </p>
                        <div className="flex justify-between bg-gray-100 rounded-md py-2 px-4 w-full">
                            <input
                                type="text"
                                placeholder="Enter Verification code"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                className="bg-transparent text-black font-medium focus:outline-none border-none"
                            />
                            <div>
                                <span
                                    className={`text-[#F1BA19] text-sm ${
                                        isButtonDisabled
                                            ? "cursor-not-allowed opacity-50"
                                            : "cursor-pointer"
                                    }`}
                                    onClick={handleSendCode}
                                >
                                    Send code{" "}
                                    {isButtonDisabled ? `(${timer}s)` : ""}
                                </span>
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="btn bg-yellow-500 text-black py-2 rounded-xl font-semibold text-center w-full focus:no-underline focus:outline-none"
                    >
                        {/* {loading ? <Spinner size="sm" /> : "Next"} */}
                        Next
                    </button>
                </div>
            </form>
            <div className="w-full">
                <button
                    onClick={() => setShowModal(true)}
                    className="block text-[#F1BA19] hover:no-underline text-center w-full"
                    style={{ cursor: "pointer" }}
                >
                    Security authentication unavailable?
                </button>
            </div>
            <ModalVerification
                visible={showModal}
                onClose={() => setShowModal(false)}
                email={email}
            />
        </div>
    );
}

export default EmailVerification;
