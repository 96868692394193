const faqsData = [
    {
        id: 1,
        question: "How to Buy Coins with Your Credit/Debit Card on BuyCex.",
        answer: "Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor.",
    },
    {
        id: 2,
        question: "Troubleshooting: Failed to Link Your Credit/Debit Card",
        answer: "Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor.",
    },
    {
        id: 3,
        question: "How to launch a Webflow website?",
        answer: "Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor.",
    },
    {
        id: 4,
        question: "How to Link or Remove Your Credit/Debit Card on BuyCex",
        answer: "Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor.",
    },
    {
        id: 5,
        question: "Troubleshooting: Failed Payments",
        answer: "Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor.",
    },
{
    id: 6,
    question: "FAQ — Credit/Debit Payments",
    answer: "Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor.",
},
  ];

export default faqsData;