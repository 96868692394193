import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import SwitchToggler from "../SwitchToggler";
// import WithDrawPassword from "../withdraw-password/WithdrawPassword";
import GoogleAccountBinding from "../../securitySettings/googleAccountBinding";
import AntiPhishingPopup from "../../securitySettings/antiPhishing";
import ReactivateAccountPopup from "../../reactivateAccount/reactivateAccountPopup";
import { toggleSecureLogging } from "store/actions/userSecuritySettingsAction";

import {
    BsCheckCircleFill,
    BsCreditCard2BackFill,
    BsFileLock2Fill,
    BsGoogle,
    BsPersonFillGear,
    BsShieldFillCheck,
} from "react-icons/bs";
import toast from "react-hot-toast";
import WithdrawPassword from "../../withdraw-password/WithdrawPassword";

const AdvancedSettings = ({
    authStatus,
    antiPhishingNotVerified,
    withdrawalPasswordValue,
    g2faVerificationValue,
    secureLoggingDuration,
    setSecureLoggingDuration,
    googleAccountBinding,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const secureLogging = useSelector(
        (state) => state.secureLogin.secureLogging
    );
    const [isGoogleBindingPopupOpen, setIsGoogleBindingPopupOpen] =
        useState(false);
    const [isAntiPhishingPopupOpen, setIsAntiPhishingPopupOpen] =
        useState(false);
    const [showWithDrawPassword, setShowWithDrawPassword] = useState(false);
    const [isReactivatePopupVisible, setIsReactivatePopupVisible] =
        useState(false);
    const reason = useSelector(
        (state) => state.authCheck?.authStatus?.freeze_reason
    );
    const status = useSelector((state) => state.authCheck?.authStatus?.status);

    console.log(status, reason);
    

    const handleReactivateClick = () => {
        if (reason !== "deleted" && status == 0) {
            setIsReactivatePopupVisible(true);
        } else {
            toast.error("Your account is already active");
        }
    };

    const handleClosePopup = () => {
        setIsReactivatePopupVisible(false);
    };

    const [settings, setSettings] = useState([
        {
            label: "Withdrawal Password",
            description: "Set withdrawal password to protect your assets",
            isSet: withdrawalPasswordValue,
            icon: <BsCreditCard2BackFill size={20} />,
            type: "password",
        },
        {
            label: "Anti-Phishing Code",
            description: "For verifying official emails from BUYCEX",
            isSet: antiPhishingNotVerified,
            icon: <BsShieldFillCheck size={20} />,
            type: "popup",
        },
        {
            label: "Google Account Binding",
            description: "Log in to BUYCEX with a third-party account",
            isSet: googleAccountBinding,
            icon: <BsGoogle size={20} />,
            type: "popup",
        },
        {
            label: "Secure Logging Duration",
            description:
                "If activated, the login session on the web will be logged out after one hour of inactivity.",
            isSet: secureLoggingDuration,
            type: "switch",
            icon: <BsFileLock2Fill size={20} />,
        },
        {
            label: "Account Management",
            description: "Disable or delete account",
            isSet: true,
            type: "management",
            icon: <BsPersonFillGear size={20} />,
        },
    ]);

    const handleGoogleBindingOpenPopup = () => {
        setIsGoogleBindingPopupOpen(true);
        setIsAntiPhishingPopupOpen(false); // Ensure Anti-Phishing popup is closed
    };

    const handleGoogleBindingClosePopup = () => {
        setIsGoogleBindingPopupOpen(false);
    };

    const handleAntiPhishingOpenPopup = () => {
        setIsAntiPhishingPopupOpen(true);
        setIsGoogleBindingPopupOpen(false); // Ensure Google Binding popup is closed
    };

    const handleAntiPhishingClosePopup = () => {
        setIsAntiPhishingPopupOpen(false);
    };

    const handleWithDrawPassClose = () => {
        setShowWithDrawPassword(false);
    };

    const handleActivate = () => {
        console.log("Google Account Binding Activated");
        setIsGoogleBindingPopupOpen(false); // Close the popup after activation
    };

    const handleSaveAntiPhishingCode = (code) => {
        console.log("Anti-Phishing Code Saved:", code);
        setIsAntiPhishingPopupOpen(false); // Close the popup after saving
    };

    const handleShowWithDrawPassword = () => {
        if (!authStatus.ga) {
            toast.error("Please Set 2FA first to proceed."); // Show error toast
            return; // Exit if ga is not set
        }
        setShowWithDrawPassword(true);
    };

    useEffect(() => {
        setSettings((prevSettings) => [
            { ...prevSettings[0], isSet: !!authStatus.paypassword },
            { ...prevSettings[1], isSet: !!authStatus.antiphishing },
            ...prevSettings.slice(2),
        ]);
    }, [authStatus, secureLogging]);

    const toggleSwitch = () => {
        setSecureLoggingDuration((prevState) => !prevState);
    };

    const handlePopupClick = (item) => {
        if (item.label === "Google Account Binding") {
            handleGoogleBindingOpenPopup();
        } else if (item.label === "Anti-Phishing Code") {
            if (!authStatus.email) {
                toast.error("Email is required");
            } else {
                handleAntiPhishingOpenPopup();
            }
        }
    };

    const handleDisableClick = () => {
        history.push("/user/account/disable");
    };

    const handleDeleteClick = () => {
        history.push("/user/account/delete");
    };

    return (
        <div>
            <div class="font-bold text-[18px] mt-[30px] mb-3">
                <span>Advanced Settings</span>
            </div>
            <div className="bg-[#FFFFFF] border border-[#F4F4F4] rounded-md">
                {settings.map((item, index) => (
                    <div
                        key={index}
                        className="flex justify-between items-center p-4 border-b hover:bg-[#F4F4F430] transition-all ease-out duration-300"
                    >
                        <div className="flex items-center gap-2">
                            <div>
                                <span>{item.icon}</span>
                            </div>
                            <div className="flex flex-col">
                                <div>
                                    <span className="text-[14px] font-semibold">
                                        {item.label}
                                    </span>
                                </div>
                                <p className="text-[#15141590] text-[12px]">
                                    {item.description}
                                </p>
                            </div>
                        </div>
                        <div>
                            {item.type === "management" ? (
                                <div className="flex items-center gap-2">
                                    <button
                                        type="button"
                                        onClick={handleDisableClick}
                                        className="font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                    >
                                        Disable
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleDeleteClick}
                                        className="font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                    >
                                        Delete
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleReactivateClick}
                                        className="font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                    >
                                        Reactivate
                                    </button>
                                </div>
                            ) : item.type === "switch" ? (
                                <SwitchToggler
                                    isChecked={secureLoggingDuration}
                                    onChange={toggleSwitch}
                                />
                            ) : item.type === "password" ? (
                                <div className="flex items-center gap-10">
                                    <div className="flex items-center gap-1">
                                        <BsCheckCircleFill
                                            size={10}
                                            fill={
                                                item.isSet
                                                    ? "#EFB81C"
                                                    : "#15141590"
                                            }
                                        />
                                        <span className="text-[10px] font-semibold text-[#15141580]">
                                            {item.isSet ? "Set" : "Not Set"}
                                        </span>
                                    </div>
                                    <button
                                        onClick={handleShowWithDrawPassword}
                                        className="font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                    >
                                        {item.isSet ? "Change" : "Set"}
                                    </button>
                                </div>
                            ) : (
                                <div className="flex items-center gap-10">
                                    <div className="flex items-center gap-1">
                                        <BsCheckCircleFill
                                            size={10}
                                            fill={
                                                item.isSet
                                                    ? "#EFB81C"
                                                    : "#15141590"
                                            }
                                        />
                                        <span className="text-[10px] font-semibold text-[#15141580]">
                                            {item.isSet ? "Set" : "Not Set"}
                                        </span>
                                    </div>
                                    <button
                                        onClick={() => handlePopupClick(item)}
                                        className="font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                    >
                                        {item.isSet ? "Change" : "Set"}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {isGoogleBindingPopupOpen && (
                <GoogleAccountBinding
                    onClose={handleGoogleBindingClosePopup}
                    onActivate={handleActivate}
                />
            )}
            {isAntiPhishingPopupOpen && (
                <AntiPhishingPopup
                    onClose={handleAntiPhishingClosePopup}
                    onSave={handleSaveAntiPhishingCode}
                    email={authStatus.email}
                />
            )}
            {showWithDrawPassword && authStatus.ga && (
                // <div className="absolute w-[100%] left-0 top-0 h-full">
                <WithdrawPassword
                    isOpen={showWithDrawPassword}
                    onClose={handleWithDrawPassClose}
                />
                // </div>
            )}
            {isReactivatePopupVisible && (
                <ReactivateAccountPopup onClose={handleClosePopup} />
            )}
        </div>
    );
};

export default AdvancedSettings;