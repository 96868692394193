import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import {
    SelectedCoinProvider,
    useSelectedCoin,
} from "components/spot-trade/context/SelectedCoinContext";

import useSpotMarketData from "components/spot-trade/hooks/useSpotMarketData";

import SelectedCoinDetails from "components/spot-trade/components/SelectedCoinDetails";
import SpotTradeChartCanvas from "components/spot-trade/components/SpotTradeChartCanvas";
import OrderBook from "components/spot-trade/components/OrderBook";
import OrderList from "components/spot-trade/components/order-list";
import TradeArea from "components/spot-trade/components/TradeArea";

/**
 * SpotTrade Component
 * Main component to render the Spot Trade page
 * It wraps the SpotTradeInner component inside a SelectedCoinProvider.
 */
const MarginTrade = () => {
    const { marketDataFromStore } = useSpotMarketData(); // Fetch market data using custom hook

    return (
        <SelectedCoinProvider>
            <SpotTradeInner marketDataFromStore={marketDataFromStore} />
        </SelectedCoinProvider>
    );
};

/**
 * SpotTradeInner Component
 * Handles the logic of selecting a default coin, fetching data, and rendering
 * all child components related to Spot Trading.
 *
 * @param {object} marketDataFromStore - The market data fetched from the store.
 */
const SpotTradeInner = ({ marketDataFromStore }) => {
    const { selectedCoin, fetchCoinData } = useSelectedCoin();

    useEffect(() => {
        // Check if a coin is already selected, don't change it
        if (
            !selectedCoin.ticker &&
            marketDataFromStore &&
            marketDataFromStore.length > 0
        ) {
            const defaultCoin = marketDataFromStore.find(
                (coin) => coin.id === 18 || coin.ticker === "btc_usdt"
            );
            if (defaultCoin) {
                fetchCoinData(defaultCoin.ticker);
            }
        }
    }, [marketDataFromStore, selectedCoin, fetchCoinData]);

    // Set dynamic page title based on selected coin's data
    const pageTitle = selectedCoin
        ? `${selectedCoin.ticker
              .toUpperCase()
              .replace("_", "/")} ≈ ${parseFloat(
              selectedCoin.buy_price
          ).toFixed(2)} | Spot Trade on BUYCEX`
        : "BUYCEX SpotTrade - Cryptocurrency Trading Platform";

    return (
        <>
            {/* Helmet for setting meta tags and page title */}
            <Helmet>
                <title>{pageTitle}</title>
                <meta
                    name="description"
                    content="SpotTrade is your go-to cryptocurrency trading platform. Access real-time charts, order books, and market data."
                />
            </Helmet>

            {/* Spot Trade body layout */}
            <section className="my-1 spot-trade__body overflow-x-auto dark:bg-[#000] ">
                <div className="flex w-full gap-1 min-w-[1200px]">
                <div className="w-full h-auto bg-[#FFFFFF] dark:bg-[#101014] dark:text-[#FFFFFF]">
                            <SelectedCoinDetails
                                marketData={marketDataFromStore.map((coin) => ({
                                    ...coin,
                                    id: coin.id,
                                }))}
                                onSelectCoin={fetchCoinData}
                            />
                        </div>
                </div>
                <div className="flex w-full gap-1 min-w-[1200px] mt-1">
                    {/* Main content area with chart, order book, and coin details */}
                    <div className="flex flex-col w-[80%] gap-1 spot-trade__body__main">
                        
                        <div className="flex w-full gap-1 spot-trade__main__chart-view">
                            {/* Spot Trade Chart view */}
                            <div className="w-[74%] max-h-[70lvh] overflow-auto bg-[#FFFFFF] dark:bg-[#101014] dark:text-[#FFFFFF]">
                                <SpotTradeChartCanvas />
                            </div>

                            {/* OrderBook and Market Trades view */}
                            <div className="w-[26%] max-h-[70lvh] overflow-hidden bg-[#FFFFFF] dark:bg-[#101014] dark:text-[#FFFFFF] p-2">
                                <OrderBook />
                            </div>
                        </div>

                        {/* Order List view */}
                        <OrderList />
                    </div>

                    {/* Trade Area (Buy/Sell functionality) */}
                    <div className="flex items-start w-[20%] spot-trade__main__trade-area bg-[#FFFFFF] dark:bg-[#101014] dark:text-[#FFFFFF]">
                        <TradeArea />
                    </div>
                </div>
            </section>
        </>
    );
};

// Prop validation for SpotTradeInner component
SpotTradeInner.propTypes = {
    marketDataFromStore: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            ticker: PropTypes.string.isRequired,
            change: PropTypes.number.isRequired,
            icon: PropTypes.string.isRequired,
            max_price: PropTypes.string.isRequired,
            min_price: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            sell_price: PropTypes.string.isRequired,
            buy_price: PropTypes.string.isRequired,
            volume: PropTypes.number.isRequired,
        })
    ).isRequired,
};

export default MarginTrade;
