import React, { useState, useRef, useCallback, useEffect } from "react";
import Dropdown from "./Dropdown";
import { Tooltip } from "react-tooltip";
import { IoMdInformationCircle } from "react-icons/io";
import { DatePicker } from 'antd';
import { createGlobalStyle, styled } from "styled-components";
import { ImCheckboxUnchecked } from "react-icons/im";
import { Link } from "react-router-dom/cjs/react-router-dom";

const { RangePicker } = DatePicker;

function DropdownFilters({ hideButtons, filters, onFiltersChange }) {
    const [openDropdown, setOpenDropdown] = useState(null);
    const [selectedCoin, setSelectedCoin] = useState("All assets");
    const [selectedType, setSelectedType] = useState("All status");
    const [selectedFiat, setSelectedFiat] = useState("All types");
    const [selectedStartTime, setSelectedStartTime] = useState("");
    const [selectedEndTime, setSelectedEndTime] = useState("");

    const dropdownRefs = useRef({});

    // Handles the toggle of dropdowns
    const handleDropdownToggle = useCallback(
        (dropdown) => {
            setOpenDropdown(openDropdown === dropdown ? null : dropdown);
        },
        [openDropdown]
    );

    // Closes all dropdowns
    const closeAllDropdowns = () => {
        setOpenDropdown(null);
    };

    const handleClickOutside = (event) => {
        const dropdownElement = dropdownRefs.current[openDropdown];
        if (dropdownElement && !dropdownElement.contains(event.target)) {
            closeAllDropdowns();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [openDropdown]);

    // Configures dropdowns with options, their state, and width
    // Updated dropdowns array to conditionally hide the status dropdown
    const dropdowns = [
        {
            key: "coin",
            label: "Asset/type",
            options: ["All", "BNB", "BTC", "CVM", "ETH", "USDT"],
            selected: filters.coin,
            setSelected: (value) => onFiltersChange({ coin: value }),
            width: "100%", // Custom width for this dropdown
        },
        {
            key: "fiat",
            label: "Type",
            options: ["All types", "Buy", "Sell"],
            selected: filters.fiat,
            setSelected: (value) => onFiltersChange({ fiat: value }),
            width: "100%", // Custom width for this dropdown
        },
        !hideButtons && {
            key: "type",
            label: "Status",
            options: ["All status", "Online", "Offline"],
            selected: filters.type,
            setSelected: (value) => onFiltersChange({ type: value }),
            width: "100%",
        },
    ].filter(Boolean);

//     const [searchQuery, setSearchQuery] = useState("");

//     const filteredOrders = orders.filter((order) => {
//         // Filter by order id (search) and tab (status)
//         const matchesSearch = order.orderid.toLowerCase().includes(searchQuery.toLowerCase());
//         const matchesTab = activeTab === "All" || 
//             (activeTab === "Offline" && order.online === "0") ||
//             (activeTab === "Online" && order.online === "1");

//                 const matchesType = selectedType === "All trade Types" || 
//         (selectedType === "Buy" && order.ad_type == 1) || // Assuming ad_type 1 is for Buy
//         (selectedType === "Sell" && order.ad_type == 2);

//         const matchesCoin = selectedCoin === "All" || order.coin.toUpperCase() === selectedCoin.toUpperCase();
//         const matchesFiat = selectedFiat === "All" || order.fiat.toUpperCase() === selectedFiat.toUpperCase();
//         const matchesStartTime = selectedStartTime === "" || order.created_at * 1000 >= new Date(selectedStartTime).getTime();;
//         const matchesEndTime = selectedEndTime === "" || order.created_at * 1000 < new Date(selectedEndTime).getTime() + 86400000;

//         return matchesSearch && matchesTab && matchesType && matchesCoin && matchesFiat && matchesStartTime && matchesEndTime;
//     });

//     const handleDateChange = (startDate, endDate) => {
//     setSelectedStartTime(startDate);
//     setSelectedEndTime(endDate);
// };

    return (
        <>
            <div className="pb-1">
                <div className="container">
                    <div className="flex flex-col md:flex-col lg:flex-row gap-1">
                    <div className={`flex w-full flex-wrap sm:w-full md:w-full items-center gap-1 mb-4 ${hideButtons ? 'lg:w-[30%]' : 'lg:w-[35%]'}`}>
                            {dropdowns.map(
                                ({ key, label, options, selected, setSelected, width }) => (
                                    <div
                                        key={key}
                                        className="flex-1 w-full"
                                        ref={(el) => (dropdownRefs.current[key] = el)}
                                    >
                                        <p className="text-sm font-light mb-1">{label}</p>
                                        <Dropdown
                                            label={label}
                                            options={options}
                                            initialSelected={selected}
                                            onSelect={(value) => {
                                                setSelected(value);
                                                setOpenDropdown(null);
                                            }}
                                            isOpen={openDropdown === key}
                                            onToggle={(isOpen) =>
                                                handleDropdownToggle(key)
                                            }
                                            width={width}
                                        />
                                    </div>
                                )
                            )}
                        </div>
                        <div>
                            <label className="flex gap-1 !items-start text-[12px] mb-1">
                                Date
                                <label
                                    htmlFor="coin"
                                    className="flex items-start text-sm font-medium pb-[0.2rem]"
                                >
                                    <IoMdInformationCircle
                                        data-tooltip-id="coin-tooltip"
                                        data-tooltip-content="Export order list"
                                        className="inline cursor-pointer text-gray-400"
                                    />
                                    <Tooltip
                                        id="coin-tooltip"
                                        place="top"
                                        effect="solid"
                                    />
                                </label>
                            </label>
                            <div>
                                <GlobalStyles />
                                <StyledRangePicker
                                    className="px-2 mt-[0.03rem] py-[0.43rem] border border-[#DBDBDB]"
                                    style={{ borderRadius: '4px' }}
                                    placeholder={["Start Date", "End Date"]}
                                    value={[filters.startTime, filters.endTime]}
                onChange={(dates) => onFiltersChange({ startTime: dates[0], endTime: dates[1] })}
                                />
                            </div>
                        </div>
                        <div className="flex justify-between w-full lg:w-[40%]">
                            <div className="ml-0 md:ml-0 lg:ml-5">
                                <p className="text-sm font-light mb-1" style={{ visibility: 'hidden' }}>label</p>
                                <div className="flex gap-2">
                                    <button className="bg-[#EFB81C] rounded-[4px] px-6 text-sm font-medium py-2 h-fit">Filter</button>
                                    <button className="bg-[#EAECEF] rounded-[4px] px-6 text-sm font-medium py-2 h-fit">Reset</button>
                                </div>
                            </div>
                            <Link to='/trade/p2p/ads-history' className="flex items-center text-xs font-semibold text-[#EFB81C]">
                                {!hideButtons && (<span className="text-xs font-semibold underline underline-offset-8">Ad History</span>)}
                            </Link>
                        </div>
                    </div>
                    <div className="flex mt-5 gap-6 items-center">
                        {!hideButtons && (
                            <>
                                <div className="flex items-center gap-2">
                                    <ImCheckboxUnchecked className="text-gray-300" />
                                    <p className="text-sm text-[#474D57] font-medium">0 Ads</p>
                                </div>
                                {/* Conditionally hide the buttons */}
                                <div className="flex gap-2">
                                    <button className="bg-[#EFB81C] rounded-md px-3 md:px-8 lg:px-8 text-sm font-semibold py-1.5 h-fit">Active all</button>
                                    <button className="bg-[#EAECEF] rounded-md px-3 md:px-8 lg:px-8 text-sm font-semibold py-1.5 h-fit">Take all offline</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

const GlobalStyles = createGlobalStyle`
  // Override hover state for picker
  .ant-picker:hover {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on hover */
  }

  // Override focus state for picker
  .ant-picker-focused {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on focus */
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important; /* Add shadow with color #EFB81C */
  }

  // Style for the active state bar in RangePicker
  .ant-picker-active-bar {
    background: #EFB81C !important; /* Change the active border bar color to #EFB81C */
  }

  // Style calendar cells with selected date
  .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EFB81C !important; /* Change the background color of selected date */
    color: white !important; /* Keep the text color white for contrast */
  }

  // Style for "Today" cell in the calendar dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    border: 1px solid #EFB81C !important; /* Change border color to #EFB81C for the "Today" cell */
    border-radius: 4px;
    content: "";
  }
    // Change color of calendar header buttons on hover
  .ant-picker-dropdown .ant-picker-header-view > button:hover {
    color: #EFB81C !important; /* Change hover color to #EFB81C */
  }
`;

const StyledRangePicker = styled(RangePicker)`
  // Custom styling for RangePicker input border when focused
  .ant-picker-input > input:focus {
    box-shadow: none !important; /* Remove any focus box-shadow */
    border-color: #EFB81C !important; /* Ensure border stays #EFB81C */
  }
`;

export default DropdownFilters;
