import React, { useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { NavLink, useLocation } from "react-router-dom";

const AssetsSideBar = () => {
    const location = useLocation();
    const path = location.pathname;

    const [isSpotOpen, setIsSpotOpen] = useState(false);
    const [isMarginOpen, setIsMarginOpen] = useState(false);
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);

    useEffect(() => {
        if (
            path.includes("/user/assets/spot") ||
            path.includes("/user/assets/deposit") ||
            path.includes("/user/assets/WithdrawRecord") ||
            path.includes("/user/assets/TransferRecord")
        ) {
            setIsSpotOpen(true);
        }
        if (
            path.includes("/user/assets/margin") ||
            path.includes("/user/assets/borrowing")
        ) {
            setIsMarginOpen(true);
        }
        if (
            path.includes("/user/history/spot") ||
            path.includes("/user/history/margin") ||
            path.includes("/user/history/funding") ||
            path.includes("/user/history/earn")
        ) {
            setIsHistoryOpen(true);
        }
    }, [path]);

    const toggleSpotDropdown = () => {
        setIsSpotOpen(!isSpotOpen);
    };

    const toggleMarginDropdown = () => {
        setIsMarginOpen(!isMarginOpen);
    };

    const toggleHistoryDropdown = () => {
        setIsHistoryOpen(!isHistoryOpen);
    };

    const menuItems = [
        {
            name: "Overview",
            icon: <i className="bi bi-binoculars-fill"></i>,
            to: "/user/assets/overview",
        },
        {
            name: "Spot",
            icon: <i className="bi bi-bar-chart-fill"></i>,
            to: "#",
            isDropdown: true,
            toggleDropdown: toggleSpotDropdown,
            isOpen: isSpotOpen,
            subItems: [
                {
                    name: "Asset",
                    icon: <i className="bi bi-bar-chart-fill"></i>,
                    to: "/user/assets/spot",
                },
                {
                    name: "Deposit Records",
                    icon: <i className="bi bi-clipboard2-data-fill"></i>,
                    to: "/user/assets/deposit",
                },
                {
                    name: "Withdrawal Records",
                    icon: <i className="bi bi-cash-coin"></i>,
                    to: "/user/assets/WithdrawRecord",
                },
                {
                    name: "Transfer History",
                    icon: <i className="bi bi-clock-history"></i>,
                    to: "/user/assets/TransferRecord",
                },
            ],
        },
        {
            name: "Margin",
            icon: <i className="bi bi-sticky-fill"></i>,
            to: "#",
            isDropdown: true,
            toggleDropdown: toggleMarginDropdown,
            isOpen: isMarginOpen,
            subItems: [
                {
                    name: "Asset",
                    icon: <i className="bi bi-bar-chart-fill"></i>,
                    to: "/user/assets/margin",
                },
                {
                    name: "Borrowing Records",
                    icon: <i className="bi bi-clipboard2-data-fill"></i>,
                    to: "/user/assets/borrowing",
                },
            ],
        },
        {
            name: "Funding",
            icon: <i className="bi bi-wallet-fill"></i>,
            to: "/user/assets/funding",
        },
        {
            name: "Earn",
            icon: <i className="bi bi-cash-stack"></i>,
            to: "/user/assets/earn",
        },
    ];

    const historyItems = [
        {
            name: "Spot History",
            icon: <i className="bi bi-bar-chart-fill"></i>,
            to: "/user/history/spot",
        },
        {
            name: "Margin History",
            icon: <i className="bi bi-sticky-fill"></i>,
            to: "/user/history/margin",
        },
        {
            name: "Funding History",
            icon: <i className="bi bi-wallet-fill"></i>,
            to: "/user/history/funding",
        },
        {
            name: "Earn History",
            icon: <i className="bi bi-cash-stack"></i>,
            to: "/user/history/earn",
        },
    ];

    return (
        <div className="flex flex-col gap-y-5">
            <nav className="w-64 h-full">
                <ul className="flex flex-col gap-4 p-2 bg-white rounded-md shadow-md">
                    {menuItems.map((item) => (
                        <li key={item.name}>
                            <div>
                                <NavLink
                                    to={item.to}
                                    className={`${
                                        path.includes(item.to)
                                            ? "font-semibold bg-[#F4F4F4]"
                                            : "font-medium"
                                    } flex items-center px-3 py-2 gap-3 text-gray dark:text-white hover:text-[#151415] hover:bg-[#F4F4F4] rounded-md cursor-pointer`}
                                    onClick={
                                        item.isDropdown
                                            ? item.toggleDropdown
                                            : undefined
                                    }
                                >
                                    {item.icon}
                                    <span className="text-[15px]">
                                        {item.name}
                                    </span>
                                    {item.isDropdown && (
                                        <IoIosArrowDown
                                            size={20}
                                            className={`ml-auto ${
                                                item.isOpen
                                                    ? "-rotate-180 "
                                                    : "rotate-0"
                                            } transition-all duration-300`}
                                        />
                                    )}
                                </NavLink>
                                {item.isDropdown && item.isOpen && (
                                    <ul className="mt-2 ml-6">
                                        {item.subItems.map((subItem) => (
                                            <li key={subItem.name}>
                                                <NavLink
                                                    to={subItem.to}
                                                    className={`${
                                                        path.includes(
                                                            subItem.to
                                                        )
                                                            ? "font-semibold bg-[#F4F4F4]"
                                                            : "font-medium"
                                                    } flex items-center px-3 py-2 gap-3 text-gray dark:text-white hover:text-[#151415] hover:bg-[#F4F4F4] rounded-md`}
                                                >
                                                    {subItem.icon}
                                                    <span className="text-[15px]">
                                                        {subItem.name}
                                                    </span>
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </nav>

            {/* Separate History Card */}
            <div className="shadow-md">
                <div
                    className={`${
                        isHistoryOpen ? "shadow-md" : ""
                    } w-64 bg-white rounded-md py-3 px-4 transition-all duration-300`}
                >
                    <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={toggleHistoryDropdown}
                    >
                        <span className="font-semibold text-gray-700">
                            History
                        </span>
                        <IoIosArrowDown
                            size={20}
                            className={`${
                                isHistoryOpen ? "-rotate-180" : "rotate-0"
                            } transition-transform duration-300`}
                        />
                    </div>
                    {isHistoryOpen && (
                        <ul className="mt-4 space-y-2">
                            {historyItems.map((item) => (
                                <li key={item.name}>
                                    <NavLink
                                        to={item.to}
                                        className={`${
                                            path.includes(item.to)
                                                ? "font-semibold bg-[#F4F4F4]"
                                                : "font-medium"
                                        } flex items-center  py-2 gap-3 text-gray dark:text-white hover:text-[#151415] hover:bg-[#F4F4F4] rounded-md`}
                                    >
                                        {item.icon}
                                        <span className="text-[15px]">
                                            {item.name}
                                        </span>
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AssetsSideBar;
