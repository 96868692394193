import {
    SET_ID_FRONT_IMAGE,
    SET_ID_BACK_IMAGE,
    SET_PROFILE_IMAGE,
    SET_ID_CARD,
    SET_USER_DETAILS,
} from "../actions/imageAction";

const initialState = {
    idFrontImage: null,
    idBackImage: null,
    profileImage: null,
    idCard: null,
    userDetails: {
        id: null,
        firstname: null,
        lastname: null,
        country: null,
        dob: null,
        backidphoto: null,
        backphoto: null,
        frontphoto: null,
        idcard: null,
        idcardimg1: null,
        profilephoto: null,
    },
};

const imageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ID_FRONT_IMAGE:
            return { ...state, idFrontImage: action.payload };
        case SET_ID_BACK_IMAGE:
            return { ...state, idBackImage: action.payload };
        case SET_PROFILE_IMAGE:
            return { ...state, profileImage: action.payload };
        case SET_ID_CARD:
            return { ...state, idCard: action.payload };
        case SET_USER_DETAILS:
            return {
                ...state,
                userDetails: { ...state.userDetails, ...action.payload },
            };
        default:
            return state;
    }
};

export default imageReducer;
