import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { CgLoadbar } from "react-icons/cg";
import { FaPlus } from "react-icons/fa";
import { DeleteOutlined } from "@ant-design/icons";
import BankTransfer from "../P2P/P2pComponents/BankTransferModal";
import { delbank, getbank } from "store/actions/userSecuritySettingsAction";
import { useDispatch, useSelector } from "react-redux";
import { delpm } from "store/actions/p2pActions";

const YourPaymentMethodModal = ({ visible, onCancel, onSelect }) => {
    const paymentMethods = [
        { name: "Bank Transfer" },
        { name: "Cash Deposit" },
        { name: "Paypal" },
        { name: "UPI" },
    ];

    const [selectedMethods, setSelectedMethods] = useState("Bank Transfer");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [bankDetails, setBankDetails] = useState(null);
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;
    const dispatch = useDispatch();

    const fetchBankDetails = () => {
        getbank(userId, token, (response) => {
            console.log("API response:", response);
            if (response?.data?.userBankList) {
                setBankDetails(response.data);
                console.log("Updated bankDetails:", response.data);
            } else {
                console.error("API response data missing or empty.");
            }
        });
    };

    useEffect(() => {
        let isMounted = true; // Track if component is still mounted

        if (visible) {
            getbank(userId, token, (response) => {
                if (isMounted && response?.data) {
                    setBankDetails(response.data);
                }
            });
        }

        return () => {
            isMounted = false; // Cleanup: mark as unmounted
        };
    }, [visible, userId, token]);

    const BankTransferhandleOk = () => {
        setIsModalVisible(false);
        // if (selectedMethods) {
        //     onSelect(selectedMethods);
        //     // onCancel();
        // }
        setTimeout(() => {
            fetchBankDetails(userId, token);
        }, 1000);
    };

    const BankTransferhandleCancel = () => {
        setIsModalVisible(false);
        setTimeout(() => {
            fetchBankDetails(userId, token);
        }, 1000);
    };

    const showBankTransferModal = () => {
        setIsModalVisible(true);
    };

    const handleMethodChange = (method) => {
        setSelectedMethods(method);
        fetchBankDetails(userId, token);
    };

    const handleDelete = (bankId) => {
        const payload = {
            bank_id: bankId,
        };
        dispatch(delbank(userId, token, payload));
        setTimeout(() => {
            fetchBankDetails(userId, token);
        }, 1000);
    };

    const handleDelet = (methodId) => {
        const payload = {
            id: methodId,
        };
        dispatch(delpm(userId, token, payload));
        setTimeout(() => {
            fetchBankDetails(userId, token);
        }, 1000);
    };

    return (
        <Modal
            title="Select Payment Method"
            open={visible}
            onCancel={onCancel}
            footer={null}
            wrapClassName="custom-modal"
            bodyStyle={{ minHeight: "400px" }}
        >
            <p className="text-sm pt-5">Add supported payment methods</p>
            {paymentMethods.length > 0 ? (
                paymentMethods.map((method, index) => (
                    <div
                        key={index}
                        className="rounded-xl flex justify-between items-center cursor-pointer w-full mt-2 border p-4"
                        onClick={() => handleMethodChange(method.name)}
                    >
                        <div className="flex items-center">
                            <CgLoadbar className="rotate-90 text-[#EFB81C]" />
                            <p className="font-semibold ml-2">{method.name}</p>
                        </div>
                        <button onClick={showBankTransferModal}>
                            <FaPlus />
                        </button>
                    </div>
                ))
            ) : (
                <span className="text-[#717171]">No methods available</span>
            )}
            <div className="my-4">
                {paymentMethods.map((item) => (
                    <button
                        key={item.name}
                        className={`p-2 ${
                            selectedMethods === item.name
                                ? "bg-[#EFB81C] text-white"
                                : "bg-gray-200"
                        }`}
                        onClick={() => handleMethodChange(item.name)}
                    >
                        {item.name}
                    </button>
                ))}
            </div>
            {selectedMethods === "Bank Transfer" &&
                bankDetails?.userBankList?.length > 0 && (
                    <div className="mt-3 border-t pt-3">
                        {bankDetails?.userBankList?.map((bank) => (
                            <div
                                key={bank?.id}
                                className={`bg-white shadow-md rounded-lg p-4 mb-4 flex justify-between items-center cursor-pointer ${
                                    selectedMethods === bank.id
                                        ? "border-yellow-500 border-2"
                                        : ""
                                }`}
                                onClick={() => {
                                    setSelectedMethods(bank);
                                    onSelect(bank);
                                }}
                            >
                                <div>
                                    <p className="font-semibold text-lg text-[#333]">
                                        {bank?.name ||
                                            "Bank Name Not Available"}
                                    </p>
                                    <p className="text-sm text-[#555]">
                                        Account Ending: ****{" "}
                                        {bank?.bankcard?.slice(-4) || "XXXX"}
                                    </p>
                                </div>
                                <DeleteOutlined
                                    style={{
                                        fontSize: "20px",
                                        color: "#ff4d4f",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(bank.id);
                                    }}
                                    className="cursor-pointer"
                                />
                            </div>
                        ))}
                    </div>
                )}

            {selectedMethods !== "Bank Transfer" && (
                <div className="mt-3">
                    {bankDetails?.userPaymedthod
                        ?.filter((method) => {
                            const selectedMethodName =
                                String(selectedMethods).toLowerCase();
                            return (
                                method.method.toLowerCase() ===
                                (selectedMethodName === "cash deposit"
                                    ? "cdm"
                                    : selectedMethodName)
                            );
                        })
                        .map((method) => (
                            <div
                                key={method.id}
                                className={`bg-white shadow-md rounded-lg p-4 mb-4 flex justify-between items-center cursor-pointer ${
                                    selectedMethods === method.id
                                        ? "border-yellow-500 border-2"
                                        : ""
                                }`}
                                onClick={() => {
                                    setSelectedMethods(method);
                                    onSelect(method);
                                }}
                            >
                                <div>
                                    <p className="font-semibold">
                                        Method:{" "}
                                        {method.method === "cdm"
                                            ? "Cash Deposit"
                                            : method.method === "paypal"
                                            ? "Paypal"
                                            : method.method === "upi"
                                            ? "UPI"
                                            : method.method}
                                    </p>
                                    <p className="text-sm">
                                        Information: {method.information}
                                    </p>
                                </div>
                                <DeleteOutlined
                                    style={{
                                        fontSize: "20px",
                                        color: "#ff4d4f",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelet(method.id);
                                    }}
                                    className="cursor-pointer"
                                />
                            </div>
                        ))}
                </div>
            )}

            <BankTransfer
                isModalVisible={isModalVisible}
                BankTransferhandleOk={BankTransferhandleOk}
                BankTransferhandleCancel={BankTransferhandleCancel}
                selectedMethods={selectedMethods}
            />
        </Modal>
    );
};

export default YourPaymentMethodModal;