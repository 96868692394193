import {
    FETCH_FIAT_CURRENCY_LIST_SUCCESS,
    FETCH_FIAT_CURRENCY_LIST_FAILURE,
} from "../types";

const initialState = {
    loading: false,
    dropdownValue: [],
    error: null,
};

const settingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FIAT_CURRENCY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                dropdownValue: action.payload,
            };
        case FETCH_FIAT_CURRENCY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                dropdownValue: [],
            };
        default:
            return state;
    }
};

export default settingReducer;
