import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import CategorySidebar from 'components/AnnouncementComponents/CategorySidebar';
import NewsItem from 'components/AnnouncementComponents/NewsItem';
import ListingNewsFilter from 'components/AnnouncementComponents/ListingNewsFilter';

const allNewsData = [
  { title: 'Demo Trading Tournament: Trade With No Losses and Share the 27,300 USDT Prize Pool!', date: 'Jun 20, 2024', tags: ['Latest Activities'], category: 'latest-activities' },
  { title: 'Some Fiat Deposit News', date: 'Jun 12, 2024', tags: ['Top', 'New Listing', 'Fiat Deposit'], category: 'new-listings', listingType: 'fiat-deposit' },
  { title: 'Some Copy trading news', date: 'Jun 12, 2024', tags: ['Top', 'New Listing', 'Copy Trading'], category: 'new-listings', listingType: 'copy-trading' },
  { title: 'Some Earn news', date: 'Jun 12, 2024', tags: ['Top', 'New Listing', 'Earn'], category: 'new-listings', listingType: 'earn' },
  { title: 'Some Spot news', date: 'Jun 12, 2024', tags: ['Top', 'New Listing', 'Spot'], category: 'new-listings', listingType: 'spot' },
  { title: 'Some Spot news', date: 'Jun 12, 2024', tags: ['Top', 'New Listing', 'Spot'], category: 'new-listings', listingType: 'spot' },
  { title: 'Demo Trading Tournament: Trade With No Losses and Share the 27,300 USDT Prize Pool!', date: 'Jun 20, 2024', tags: ['Latest Activities'], category: 'latest-activities' },
  { title: 'Crypto Cup 2024: Predict the Winner to Share the 500,000 USDT Prize Pool and Win a Trip to Austria!', date: 'Jun 12, 2024', tags: ['Latest Buycex News'], category: 'latest-buycex-news' },
  { title: 'Demo Trading Tournament: Trade With No Losses and Share the 27,300 USDT Prize Pool!', date: 'Jun 20, 2024', tags: ['Latest Activities'], category: 'latest-activities' },
  { title: 'Crypto Cup 2024: Predict the Winner to Share the 500,000 USDT Prize Pool and Win a Trip to Austria!', date: 'Jun 12, 2024', tags: ['Web 3'], category: 'web3' },
];

const Announcement = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const currentCategory = queryParams.get('category') || 'all';
  const currentPage = parseInt(queryParams.get('page')) || 1;

  const itemsPerPage = 8;
  const [newsData, setNewsData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [filteredCategory, setFilteredCategory] = useState(currentCategory);
  const [selectedFilter, setSelectedFilter] = useState('all-listings');

  useEffect(() => {
    setFilteredCategory(currentCategory);
  }, [currentCategory]);

  useEffect(() => {
    const filteredNews = filteredCategory === 'all'
      ? allNewsData
      : allNewsData.filter(news => news.category === filteredCategory);

    let filteredNewsData = [];

    if (filteredCategory === 'new-listings' && selectedFilter !== 'all-listings') {
      filteredNewsData = filteredNews.filter(news => news.listingType === selectedFilter);
    } else {
      const topNews = filteredNews.filter(news => news.tags.includes('Top'));
      const otherNews = filteredNews.filter(news => !news.tags.includes('Top'));
      filteredNewsData = [...topNews, ...otherNews];
    }

    const paginatedNews = filteredNewsData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    setNewsData(paginatedNews);
    setTotalPages(Math.ceil(filteredNewsData.length / itemsPerPage));
  }, [filteredCategory, currentPage, selectedFilter]);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    queryParams.set('page', page);
    history.push(`/announcements?${queryParams.toString()}`);
    window.scrollTo(0, 0);
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
  };

  const handleCategorySelect = (category) => {
    setFilteredCategory(category);
    setSelectedFilter('all-listings');
    queryParams.set('category', category);
    queryParams.set('page', 1);
    history.push(`/announcements?${queryParams.toString()}`);
    window.scrollTo(0, 0);
  };

  const renderPagination = () => {
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`block px-2 mx-1 ${currentPage === i ? 'bg-yellow-500' : 'bg-inherit'} rounded border`}
        >
          {i}
        </button>
      );
    }
    return pageButtons;
  };

  return (
    <section>
      <div className='hidden lg:flex lg:flex-col lg:w-full lg:py-12 lg:px-8 lg:gap-4'>
        <h1 className='text-3xl font-bold'>Announcement</h1>
        <p>Be in the know on the latest happenings at Buycex — new products, our latest trading pairs and more!</p>
      </div>
      <div className="flex flex-col lg:flex-row border-zinc-100 dark:border-zinc-800 rounded-lg dark:bg-zinc-950 mb-4">
        <CategorySidebar onCategorySelect={handleCategorySelect} />
        <div className="w-full lg:w-3/4 lg:p-4">
          {filteredCategory === 'new-listings' && (
            <ListingNewsFilter newsData={allNewsData} currentCategory={filteredCategory} onFilterSelect={handleFilterSelect} selectedFilter={selectedFilter} />
          )}
          {newsData.map((news, index) => (
            <NewsItem key={index} {...news} />
          ))}
          <div className="mt-4 flex justify-center lg:justify-end">
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="block px-2 mx-1 bg-inherit rounded border">
              {'<'}
            </button>
            {renderPagination()}
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="block px-2 mx-1 bg-inherit rounded border">
              {'>'}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Announcement;
