import React from "react";

import SingleRowItem from "./SingleRowItem";

const SingleRow = ({ currentItems }) => {
    return (
        <>
            {currentItems &&
                currentItems.map((item, index) => (
                    <SingleRowItem key={index} item={item} />
                ))}
        </>
    );
};

export default SingleRow;
