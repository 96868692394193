import React, { useState } from 'react';
import HistoryDropdown from '../SpotHistory/DropDownUI';
import { DatePicker, Modal } from 'antd';
import { createGlobalStyle, styled } from "styled-components";
import NoData from "assets/img/user/No-data.svg";
import { FaCheckCircle } from "react-icons/fa";

const { RangePicker } = DatePicker;

function PaymentsHistory() {
    const options1 = ["All", "Paid", "Receive", "Pre-Authorization"];
    const handleSelect1 = (selected) => {
        console.log("Dropdown 1 selected:", selected);
    };

    const paymentData = [
        {
            date: "2021-10-02 21:19:56",
            type: "Receive",
            toFrom: "BuycexSeller",
            amount: "+28",
            currency: "USDT",
            status: "Successful",
            action: "Details"
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);

    const showModal = (payment) => {
        setSelectedPayment(payment);
        setIsModalVisible(true);
    };

    // Function to hide the modal
    const handleCloseModal = () => {
        setIsModalVisible(false);
        setSelectedPayment(null);
    };

    return (
        <div className='mt-5 container !p-0'>
            <div className='px-3 flex items-center gap-6 pt-2'>
                <HistoryDropdown
                    label="Type"
                    options={options1}
                    initialSelected="All"
                    onSelect={handleSelect1}
                    isSearchable={false}
                    width="80px"
                />
                <GlobalStyles />
                <StyledRangePicker className="px-2 w-[600px] h-[40px] bg-white sm:w-1/4 py-[0.55rem] border-l" />
                <button className='bg-[#EAECEF] px-4 py-2 rounded-lg font-medium'>Reset</button>
                <button className='bg-[#EFB81C] px-4 py-2 rounded-lg font-medium'>Search</button>
            </div>
            <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
                <div className="flex justify-between border-b pb-3 w-full">
                    <p className="flex-[2] text-start pl-5">Date</p>
                    <p className="flex-1 text-start">Type</p>
                    <p className="flex-[2] text-start">To/From</p>
                    <p className="flex-1 text-start">Amount</p>
                    <p className="flex-1 text-start">Currency</p>
                    <p className="flex-1 text-center">Status</p>
                    <p className="flex-1 text-center">Action</p>
                </div>

                {paymentData.length > 0 ? paymentData.map((item, index) => (
                    <div key={index} className="flex justify-between border-b w-full">
                        <p className="flex-[2] text-[14px] text-black py-6 text-start pl-5">{item.date}</p>
                        <p className="flex-1 text-[14px] text-black py-6 text-start">{item.type}</p>
                        <p className="flex-[2] text-[14px] text-black py-6 text-start">{item.toFrom}</p>
                        <p className="flex-1 text-[14px] text-black py-6 text-start">{item.amount}</p>
                        <p className="flex-1 text-[14px] text-black py-6 text-start">{item.currency}</p>
                        <p className="flex-1 text-[14px] text-black py-6 text-center">{item.status}</p>
                        <p
                            className="flex-1 text-[14px] text-[#EFB81C] py-6 text-center cursor-pointer"
                            onClick={() => showModal(item)} 
                        >
                            {item.action}
                        </p>
                    </div>
                )) : (
                    <div className="w-full flex justify-center items-center h-96">
                        <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                            <img src={NoData} alt="No Data" />
                            <p className='text-[#707a8a] text-sm'>No records</p>
                        </div>
                    </div>
                )}

                <Modal
                    title="Transaction Details"
                    visible={isModalVisible}
                    onOk={handleCloseModal}
                    onCancel={handleCloseModal}
                    footer={null}
                >
                    {selectedPayment ? (
                        <div>
                            <div className='border-b pb-10'>
                                <div className='text-center flex flex-col items-center'><p>Amount </p><div className='flex gap-1 items-center'><p className='text-3xl font-semibold'>{selectedPayment.amount}</p><p className='font-semibold'> {selectedPayment.currency}</p></div></div>
                                <p className='flex items-center justify-center text-[#2EBD85] gap-2'><FaCheckCircle /> <p className='text-lg font-semibold'>{selectedPayment.status}</p></p>
                            </div>
                            <div className='flex py-3 pt-2 justify-between w-full items-center'>
                                <p>From</p>
                                <p>{selectedPayment.toFrom}</p>
                            </div>
                            <div className='flex pb-3 justify-between w-full items-center'>
                                <p>Note</p>
                                <p>--</p>
                            </div>
                            <div className='flex pb-3 justify-between w-full items-center'>
                                <p>Time Created</p>
                                <p>{selectedPayment.date}</p>
                            </div>
                            <div className='flex pb-3 justify-between w-full items-center'>
                                <p>Order ID</p>
                                <p>0930390278303023</p>
                            </div>
                        </div>
                    ) : null}
                </Modal>
            </div>
        </div>
    );
}

const GlobalStyles = createGlobalStyle`
  .ant-picker:hover {
    border-color: #EFB81C !important;
  }
  .ant-picker-focused {
    border-color: #EFB81C !important;
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important;
  }
  .ant-picker-active-bar {
    background: #EFB81C !important;
  }
  .ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #EFB81C !important;
    color: white !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #EFB81C !important;
  }
  .ant-picker-dropdown .ant-picker-header-view > button:hover {
    color: #EFB81C !important;
  }
`;

const StyledRangePicker = styled(RangePicker)`
  .ant-picker-input > input:focus {
    box-shadow: none !important;
    border-color: #EFB81C !important;
  }
`;

export default PaymentsHistory;
