import React, { useEffect, useRef, useState } from "react";
import SpotOrderPendingTable from "components/user/orders/tables/SpotOrderPendingTable";
import { useLocation, Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchMyOpenOrder } from 'store/actions/OrderUserAction';

const SpotOrderPending = () => {
  
  const location = useLocation();
  const dropDownRef = useRef(null);

  // DropDown States
  const [isTrade, setIsTrade] = useState(false);
  const [isMarket, setIsMarket] = useState(false);
  const [isSide, setIsSide] = useState(false);

  // DropDowns Handler
  const handleTradeDropDown = () => {
    setIsTrade(!isTrade);
    setIsMarket(false);
    setIsSide(false);

  };

  const handleMarketDropDown = () => {
    setIsMarket(!isMarket);
    setIsTrade(false);
    setIsSide(false);
  };
  const handleSideDropDown = () => {
    setIsMarket(false);
    setIsTrade(false);
    setIsSide(!isSide);

  };

  //For Clicking Outside DROPDOWNS
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsTrade(null);
        setIsMarket(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const navbar = [
    {
      name: "Pending Orders",
      link: "/user/spot-order/pending",
    },
    {
      name: "Orders History",
      link: "/user/spot-order/order-history",
    },
    {
      name: "Trade History",
      link: "/user/spot-order/trade-history",
    },
    {
      name: "Margin",
      link: "/user/spot-order/margin-history",
    },
  ];
  const dispatch = useDispatch();

  const {loading, myOpenOrder, error} = useSelector(
    (state) => state.myOpenOrder
  );
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  useEffect(() => {
    dispatch(fetchMyOpenOrder(userID, userToken));
  }, [dispatch, userID, userToken]);

  useEffect(() => {
    console.log("myOpenOrder ", myOpenOrder);
    console.log("TOKEN", userToken);
    console.log("USER ID", userID);
  }, [myOpenOrder]);
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg text-red-500">Error: {error}</div>
      </div>
    );
  }
  return (
    <>
      <div useRef={dropDownRef} className="flex flex-col w-full">
        {/* First card */}
        <div className="flex-col justify-center w-full p-4  bg-white rounded-3xl text-[16px]">
          {/* Navbar */}
          <div className="flex items-center gap-x-3">
            {navbar.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                className={`${
                  location.pathname === item.link
                    ? "text-[#efb81c] border-[#efb81c]"
                    : " border-gray-300 text-gray-300"
                } px-3 py-1  border-[1px] rounded-md  `}
              >
                <button>{item.name}</button>
              </Link>
            ))}
          </div>

          <div className="flex items-center justify-between w-full mt-4">
            {/* Drop Downs  */}
            <div className="flex items-center gap-x-4">
               {/* Trade DropDown */}
               <div className="flex items-center gap-x-2">
                <h6 className="font-semibold">Trading Type</h6>
                
          <div className="relative inline-block text-left">
            
            <div className="flex items-center gap-x-1">
              
              <button
                onClick={handleTradeDropDown}
                type="button"
                className="inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-[8px] text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                id="filter-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                <div className="text-[16px] font-normal">All</div>
                <div className="pl-2 ml-3 border-l-2">
                  <IoIosArrowDown className="" />
                </div>
              </button>
            </div>

            {/* DropDown Content */}
            <div
              className={`${
                isTrade ? "absolute" : "hidden"
              } z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              aria-orientation="vertical"
              tabIndex="-1"
            >
              <div className="py-1 max-h-[300px] overflow-auto" role="none">
                <h6
                  className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  role="filteritem"
                  tabIndex="-1"
                  id="filter-item-0"
                >
                  Hello
                </h6>
              </div>
            </div>
          </div>
          </div>

           {/* Market DropDown */}
           <div className="flex items-center gap-x-2">
           <h6 className="font-semibold">Market</h6>
           <div className="relative inline-block text-left">
            <div>
              <button
                onClick={handleMarketDropDown}
                type="button"
                className="inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-[8px] text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                id="filter-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                <div className="text-[16px] font-normal">All</div>
                <div className="pl-2 ml-4 border-l-2">
                  <IoIosArrowDown className="" />
                </div>
              </button>
            </div>

            {/* DropDown Content */}
            <div
              className={`${
                isMarket ? "absolute" : "hidden"
              } z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              aria-orientation="vertical"
              tabIndex="-1"
            >
              <div className="py-1 max-h-[300px] overflow-auto" role="none">
                <h6
                  className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  role="filteritem"
                  tabIndex="-1"
                  id="filter-item-0"
                >
                  Hello
                </h6>
              </div>
            </div>
          </div>
          </div>
           {/* Side DropDown */}
           <div className="flex items-center gap-x-2">
           <h6 className="font-semibold">Side</h6>
           <div className="relative inline-block text-left">
            <div>
              <button
                onClick={handleSideDropDown}
                type="button"
                className="inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-[8px] text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                id="filter-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                <div className="text-[16px] font-normal">All</div>
                <div className="pl-2 ml-4 border-l-2">
                  <IoIosArrowDown className="" />
                </div>
              </button>
            </div>

            {/* DropDown Content */}
            <div
              className={`${
                isSide ? "absolute" : "hidden"
              } z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              aria-orientation="vertical"
              tabIndex="-1"
            >
              <div className="py-1 max-h-[300px] overflow-auto" role="none">
                <h6
                  className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  role="filteritem"
                  tabIndex="-1"
                  id="filter-item-0"
                >
                  Hello
                </h6>
              </div>
            </div>
          </div>
          </div>
            </div>
            
            <div className="flex items-center gap-x-2">
              <h6 className="font-semibold">Order Type</h6>
              <div className="flex items-center gap-x-2">
             
                <button  className={`"text-[#efb81c] border-[#efb81c] text-[#efb81c] px-3 py-1  border-[1px] rounded-md `}>Normal Order</button>
                <button  className={`"text-[#efb81c] border-gray-400 text-gray-400 px-3 py-1  border-[1px] rounded-md `}>Stop Order</button>
                <button  className={`"text-[#efb81c] bg-[#f1d27c] text-[#9b7f31] px-3 py-1  rounded-md `}>Reset</button>
              </div>
              
            </div>
          </div>
        </div>

        {/* Table */}
        <SpotOrderPendingTable />
      </div>
    </>
  );
};

export default SpotOrderPending;
