import React, { useEffect, useState } from 'react';
import FundingTable from './FundingTable';
import EstimatedFundings from './EstimatedFundings';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';

import { FinanceUserFunding } from 'store/actions/AssetsUserAction';
function FundingAssets() {
  const [isVisible, setIsVisible] = useState(false); // State to track visibility

  // Function to toggle visibility
  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, assetsData, error } = useSelector(
      (state) => state.assetsData
  );
  const { data} = useSelector((state) => state.financeUserFunding);

  console.log('Funding Data from Redux:', data); 
  // Log state to check if fundingData is updated correctly
 
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  useEffect(() => {
   
      dispatch(FinanceUserFunding(userID, userToken));
  }, [dispatch, userID, userToken, history]);
  
  useEffect(() => {
      console.log("spot data-check", assetsData);
      console.log("spot data-type-check", assetsData.type);
 console.log("fundingData", data);
  }, [assetsData]);
  console.log("Funding Data from Redux: ", data);
  return (
    <div className='px-0 lg:px-3'>
      <div className='max-w-[1400px] mx-auto !p-0'>
        <EstimatedFundings isVisible={isVisible} toggleVisibility={toggleVisibility} data={data}/>
        <FundingTable isVisible={isVisible} data={data}/>
      </div>
    </div>
  );
}

export default FundingAssets;
