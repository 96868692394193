// src/repository/IssueRepository.js
import Repository from './Repository';

// Endpoints
const ISSUES_ENDPOINT = '/issue/index';

// Helper function to generate headers
const getHeaders = (token, id) => ({
    'TOKEN': token,
    'ID': id,                    // Custom header for user ID
     'Content-Type': 'application/json', // Assuming JSON payload
});

const IssueRepository = {
  // Function to fetch issues
  async fetchIssues(token, id) {
    try {
      const response = await Repository.get(ISSUES_ENDPOINT, {
        headers: getHeaders(token, id),
      });
      console.log("Fetch Issues API Response", response);
      return response.data; // Return the data from the response
    } catch (error) {
      console.error("Error fetching issues:", error);
      throw error; // Rethrow the error for further handling
    }
  },
};

export default IssueRepository;
