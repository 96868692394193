import React, { useState } from 'react';
import OpenOrder from './OpenOrder/OpenOrder';
import OrderHistory from './OrderHistory/OrderHistory';

const PreListTrade = () => {
    const [activeItem, setActiveItem] = useState('Open Orders'); 

    const discoverNavItems = ['Open Orders', 'Order History'];

    const renderContent = () => {
        switch (activeItem) {
            case 'Open Orders':
                return <OpenOrder />;
            case 'Order History':
                return < OrderHistory />;
            default:
                return < OpenOrder />;
        }
    };

    return (
        <div className=''>
            <div className='container mt-5 !p-0'>
                <nav className='flex gap-3 w-[60%] px-3 border-gray-200 pt-2'>
                    {discoverNavItems.map((item) => (
                        <button
                            key={item}
                            onClick={() => setActiveItem(item)}
                            className={`relative py-2 px-2 text-md font-semibold ${activeItem === item ? '' : 'text-gray-400'
                                }`}
                        >
                            {item}
                            {activeItem === item && (
                                <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                            )}
                        </button>
                    ))}
                </nav>

                <div className="mt-6">{renderContent()}</div>
            </div>
        </div>
    );
};

export default PreListTrade;
