import React, { useState } from "react";
import ChatBotIcon from "../icons/chat-bot.svg";
import ChatField from "./ChatField";
import "./ChatBotStyle.css";

const ChatSupportBtn = () => {
    const [showChatField, setShowChatField] = useState(false);

    const handleChatBotClick = () => {
        setShowChatField(!showChatField);
    };

    const handleCloseChatField = () => {
        setShowChatField(false);
    };

    return (
        <>
            {!showChatField && (
                <div className="chat-support-btn fixed bottom-[20px] right-[20px] bg-[#EFB81C] p-2 rounded-full w-[50px] h-[50px]">
                    <button
                        type="button"
                        onClick={handleChatBotClick}
                        style={{ cursor: "pointer" }}
                    >
                        <img
                            src={ChatBotIcon}
                            alt="BUYCEX Chat Bot"
                            className="w-[50px]"
                            title="BUYCEX Chat Bot"
                        />
                    </button>
                </div>
            )}

            {/* Conditionally Render Chat Field */}
            {showChatField && (
                <ChatField handleCloseChatField={handleCloseChatField} />
            )}
        </>
    );
};

export default ChatSupportBtn;
