import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WebCamModal from "./WebCamModal";
import { Tooltip } from "react-tooltip";

import {
    setIdFrontImage,
    setIdBackImage,
    setIdCard,
    setUserDetails,
} from "store/actions/imageAction";
import { useHistory } from "react-router-dom";
import { BsCloudArrowUpFill, BsPersonVcardFill } from "react-icons/bs";
import toast from "react-hot-toast";

const UploadID = () => {
    const history = useHistory();
    const [modal, setModal] = useState(false);
    const [imageType, setImageType] = useState(null);
    const [idCardNumber, setIdCardNumber] = useState("");
    const [selectedIdType, setSelectedIdType] = useState("");
    const [idfrontImage, setIdfrontImage] = useState(null);
    const [idbackImage, setIdbackImage] = useState(null);

    const state = useSelector((state) => state);
    console.log(state)

    // State for checkbox values
    const [isIdCardChecked, setIsIdCardChecked] = useState(false);
    const [isPassportChecked, setIsPassportChecked] = useState(false);
    const [isDriverLicenseChecked, setIsDriverLicenseChecked] = useState(false);

    const dispatch = useDispatch();
    const idFrontImage = useSelector((state) => state.images.idFrontImage);
    const idBackImage = useSelector((state) => state.images.idBackImage);

    const isFormValid = () =>
        idCardNumber.trim() !== "" &&
        idFrontImage !== null &&
        idBackImage !== null &&
        (isIdCardChecked || isPassportChecked || isDriverLicenseChecked);

    useEffect(() => {}, [
        idCardNumber,
        idFrontImage,
        idBackImage,
        isIdCardChecked,
        isPassportChecked,
        isDriverLicenseChecked,
    ]);

    const handleIdTypeChange = (type) => {
        setSelectedIdType(type);
        // Update checked states based on selected type
        setIsIdCardChecked(type === "id_card");
        setIsPassportChecked(type === "passport");
        setIsDriverLicenseChecked(type === "driver_license");
        // Update local storage here or call handleLocalStorage
    };

    const handleImageCapture = (imageObject) => {
        if (!imageType) return;

        switch (imageType) {
            case "front":
                dispatch(setIdFrontImage(imageObject));
                break;
            case "back":
                dispatch(setIdBackImage(imageObject));
                break;
            default:
                break;
        }

        setModal(false);
    };

    const handleConfirm = () => {
        setModal(false);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleIdCardNumberChange = (e) => {
        const number = e.target.value;
        setIdCardNumber(number);
        dispatch(setIdCard(number));
    };

    const openModalForType = (type) => {
        setImageType(type);
        setModal(true);
    };


    useEffect(() => {
        // Retrieve stored data from local storage
        const storedData = JSON.parse(localStorage.getItem("userIdDetails"));
        
        if (storedData) {
            setIdCardNumber(storedData.idCardNumber || "");
            setSelectedIdType(storedData.selectedIdType || "");
            setIsIdCardChecked(storedData.isIdCardChecked || false);
            setIsPassportChecked(storedData.isPassportChecked || false);
            setIsDriverLicenseChecked(storedData.isDriverLicenseChecked || false);
        }
    }, []);
    
  

    const isAnyFieldValid = () => {
        return (
            idCardNumber.trim() ||
            idFrontImage ||
            idBackImage ||
            isIdCardChecked ||
            isPassportChecked ||
            isDriverLicenseChecked
        );
    };

    const handleLocalStorage = () => {
        const data = {
            idCardNumber,
            idFrontImage,
            idBackImage,
            selectedIdType,
            isIdCardChecked,
            isPassportChecked,
            isDriverLicenseChecked,
        };
        localStorage.setItem("userIdDetails", JSON.stringify(data));
    };

    useEffect(() => {
        // Load images from localStorage when the component mounts
        const savedFrontImage = localStorage.getItem('idfrontImage');
        const savedBackImage = localStorage.getItem('idbackImage');

        if (savedFrontImage) {
            setIdfrontImage(savedFrontImage);
        }
        if (savedBackImage) {
            setIdbackImage(savedBackImage);
        }
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 2 * 1024 * 1024) {
                toast.error("File size exceeds 2MB.");
                return;
            }
            if (!["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
                toast.error("Invalid file type. Please upload a PNG, JPG, or JPEG file.");
                return;
            }

            const imageUrl = URL.createObjectURL(file);
    
            if (imageType === "front") {
                dispatch(setIdFrontImage(file)); // Store the file directly in Redux
                setIdfrontImage(imageUrl);
                localStorage.setItem('idfrontImage', imageUrl);
                localStorage.setItem("idFrontImageMeta", JSON.stringify({
                    name: file.name,
                    type: file.type,
                    size: file.size,
                }));
            } else if (imageType === "back") {
                dispatch(setIdBackImage(file)); // Store the file directly in Redux
                setIdbackImage(imageUrl);
                localStorage.setItem('idbackImage', imageUrl);
                localStorage.setItem("idBackImageMeta", JSON.stringify({
                    name: file.name,
                    type: file.type,
                    size: file.size,
                }));
            }
    
            setModal(false);
        }
    };
    
    const uploadIdPhoto = (type) => {
        setImageType(type);
        const inputElement = document.querySelector(`#${type}-image-picker`);
        if (inputElement) {
            inputElement.click();
        }
    };

    useEffect(() => {
        const storedIdFrontImageMeta = localStorage.getItem("idFrontImageMeta");
        if (storedIdFrontImageMeta) {
            const frontImageMeta = JSON.parse(storedIdFrontImageMeta);
            console.log("Front Image Metadata:", frontImageMeta); // You can use this for display
        }
    
        const storedIdBackImageMeta = localStorage.getItem("idBackImageMeta");
        if (storedIdBackImageMeta) {
            const backImageMeta = JSON.parse(storedIdBackImageMeta);
            console.log("Back Image Metadata:", backImageMeta); // You can use this for display
        }
    }, []);
     

    const handleBack = () => {
        history.push("/user/account/identity-verification/basic-verification");
    };

    const handleReset = () => {
        setIdCardNumber("");
        setIsIdCardChecked(false);
        setIsPassportChecked(false);
        setIsDriverLicenseChecked(false);
        setIdfrontImage(null)
        setIdbackImage(null)
        dispatch(setIdFrontImage(null));
        dispatch(setIdBackImage(null));
        localStorage.removeItem("userIdDetails");
        localStorage.removeItem("idFrontImage");
        localStorage.removeItem("idBackImage");
        localStorage.removeItem("idbackImage");
        localStorage.removeItem("idfrontImage");
    };

    const handleNext = () => {
        if (isFormValid()) {
            handleLocalStorage();
            const formData = new FormData();
            formData.append("idCardNumber", idCardNumber);
            const userDetails = {
                idCardNumber,
                idType: selectedIdType,
            }
            dispatch(setUserDetails(userDetails));
            if (idFrontImage) formData.append("idFrontImage", idFrontImage);
            if (idBackImage) formData.append("idBackImage", idBackImage);
            history.push(
                "/user/account/identity-verification/face-recognition"
            );
        }
    };
    return (
        <div className="w-full">
            <div className="flex items-center gap-2 mb-[35px]">
                <div>
                    <BsPersonVcardFill size={20} />
                </div>
                <div>
                    <span className="text-[22px] font-semibold">
                        Identity Verification
                    </span>
                </div>
            </div>
            <section className="flex flex-col gap-3">
                <div className="flex flex-col gap-5 p-4 bg-white rounded-md border border-[#F4F4F4]">
                    <div>
                        <span className="text-[14px] font-semibold">
                            Upload ID
                        </span>
                    </div>
                    <div className="relative flex items-center justify-between gap-2">
                        {["Basic Info", "Upload ID", "Face Recognition"].map(
                            (step, index) => (
                                <div
                                    key={index}
                                    className={`user-select-none min-w-[30%] inline-flex gap-3 items-center py-3 px-4 border-[0.5px] rounded-md ${
                                        index === 0 || index === 1
                                            ? "bg-[#EFB81C50]"
                                            : ""
                                    }`}
                                >
                                    <div className="flex w-[22px] h-[22px] border-[0.5px] bg-[#EFB81C] rounded-full">
                                        <span className="text-[12px] leading-none m-auto">
                                            {index + 1}
                                        </span>
                                    </div>
                                    <span className="text-[14px] font-semibold leading-none">
                                        {step}
                                    </span>
                                </div>
                            )
                        )}
                    </div>
                </div>
                <div className="flex flex-col gap-6 bg-[#FFFFFF] rounded-md border border-[#F4F4F4] p-4">
                    <div className="flex items-center justify-between w-full">
                        {/* Choose ID Type */}
                        <div className="flex flex-col gap-2 w-[45%]">
                            <div className="w-max">
                                <span className="text-[14px] font-semibold">
                                    Country of Residence ID
                                </span>
                            </div>
                            <div className="inline-flex justify-between gap-3">
                                {["ID Card", "Passport", "Driver License"].map(
                                    (type, index) => (
                                        <div
                                            key={index}
                                            className="flex items-center gap-2 px-4 py-3 border rounded-md"
                                        >
                                            <input
                                                type="radio"
                                                name="idType"
                                                id={type}
                                                value={type}
                                                checked={
                                                    (isIdCardChecked &&
                                                        type === "ID Card") ||
                                                    (isPassportChecked &&
                                                        type === "Passport") ||
                                                    (isDriverLicenseChecked &&
                                                        type ===
                                                            "Driver License")
                                                }
                                                onChange={() => handleIdTypeChange(type.toLowerCase().replace(" ", "_"))}
                                                className="accent-[#EFB81C] border-none focus:shadow-none focus:z-10 focus:outline-none focus:border-[#EFB81C] focus:ring-[#EFB81C] cursor-pointer"
                                            />
                                            <label
                                                htmlFor={type}
                                                className="text-[12px] font-medium m-0 cursor-pointer"
                                            >
                                                {type}
                                            </label>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                        {/* ID Number */}
                        <div className="flex flex-col gap-2 w-[45%]">
                            <label
                                htmlFor="idCardNumber"
                                className="text-[14px] font-semibold w-max m-0"
                            >
                                ID Number
                            </label>
                            <input
                                type="text"
                                max="30"
                                id="idCardNumber"
                                name="idCardNumber"
                                pattern="[0-9a-zA-Z]{6,20}"
                                placeholder="Please enter ID number"
                                className="py-3 px-4 ps-16 block w-full border rounded-md text-[12px] font-medium focus:z-10 focus:outline-none focus:border-[#EFB81C] focus:ring-[#EFB81C] disabled:opacity-50 disabled:pointer-events-none"
                                value={idCardNumber}
                                onChange={handleIdCardNumberChange}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-full gap-6">
                        {/* Upload ID */}
                        <div className="flex flex-col gap-2">
                            <div className="w-max">
                                <span className="text-[14px] font-semibold">
                                    Upload ID Photos
                                </span>
                            </div>
                            <p className="text-[12px] text-[#15141595] w-[65%]">
                                To successfully upload your ID document, please
                                submit a clear, complete image of the original,
                                unexpired document. Ensure all details are
                                legible by placing the document against a
                                solid-colored background when capturing the
                                image.
                            </p>
                        </div>
                        <div className="inline-flex w-full gap-3">
                            {/* ID Card Front Image */}
                            <div className="flex flex-col items-start justify-start w-full">
                                <div className="mb-2 w-max">
                                    <span className="text-[14px] font-semibold">
                                        Front Side of ID Card
                                    </span>
                                </div>
                                {idfrontImage ? (
                                    <div className="w-full h-[200px] p-[4px] rounded-[12px] border border-[#F4F4F4]">
                                        <img
                                            src={idfrontImage}
                                            alt="Id card front side"
                                            className="w-full h-full object-contain rounded-[8px]"
                                            onError={() =>
                                                toast.error(
                                                    "Failed to load image."
                                                )
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div className="id-image-picker flex items-center justify-center w-full h-[200px] p-4 border-2 border-gray-300 border-dashed rounded-lg bg-gray-50">
                                        <button
                                            type="button"
                                            onClick={() =>
                                                uploadIdPhoto("front")
                                            }
                                            className="flex flex-col items-center justify-center"
                                        >
                                            <BsCloudArrowUpFill
                                                size={40}
                                                fill="#15141530"
                                            />
                                            <p className="mb-2 text-sm dark:text-[#15141550]">
                                                <span className="text-[14px] font-semibold">
                                                    Click to upload
                                                </span>{" "}
                                                <span className="text-[12px] font-medium">
                                                    or drag and drop
                                                </span>
                                            </p>
                                            <p className="text-[12px] text-[#15141550]">
                                                PNG, JPG or JPEG
                                            </p>
                                        </button>
                                        <input
                                            id="front-image-picker"
                                            name="idFrontImage"
                                            type="file"
                                            accept="image/png, image/jpeg, image/jpg"
                                            className="hidden"
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                )}
                            </div>

                            {/* ID Card Back Image */}
                            <div className="flex flex-col items-start justify-start w-full">
                                <div className="mb-2 w-max">
                                    <span className="text-[14px] font-semibold">
                                        Back Side of ID Card
                                    </span>
                                </div>
                                {idbackImage ? (
                                    <div className="w-full h-[200px] p-[4px] rounded-[12px] border border-[#F4F4F4]">
                                        <img
                                            src={idbackImage}
                                            alt="Id card back side"
                                            className="w-full h-full object-contain rounded-[8px]"
                                            onError={() =>
                                                toast.error(
                                                    "Failed to load image."
                                                )
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div className="id-image-picker flex items-center justify-center w-full h-[200px] p-4 border-2 border-gray-300 border-dashed rounded-lg bg-gray-50">
                                        <button
                                            type="button"
                                            onClick={() =>
                                                uploadIdPhoto("back")
                                            }
                                            className="flex flex-col items-center justify-center w-full h-full"
                                        >
                                            <BsCloudArrowUpFill
                                                size={40}
                                                fill="#15141530"
                                            />
                                            <p className="mb-2 text-sm dark:text-[#15141550]">
                                                <span className="text-[14px] font-semibold">
                                                    Click to upload
                                                </span>{" "}
                                                <span className="text-[12px] font-medium">
                                                    or drag and drop
                                                </span>
                                            </p>
                                            <p className="text-[12px] text-[#15141550]">
                                                PNG, JPG or JPEG
                                            </p>
                                        </button>
                                        <input
                                            id="back-image-picker"
                                            name="idBackImage"
                                            type="file"
                                            accept="image/png, image/jpeg, image/jpg"
                                            className="hidden"
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Alternate KYC Option separator */}
                    <div className="inline-flex items-center justify-center w-full gap-3">
                        <div className="h-[1px] bg-[#EFB81C] w-full"></div>
                        <div>
                            <span>Or</span>
                        </div>
                        <div className="h-[1px] bg-[#EFB81C] w-full"></div>
                    </div>
                    {/* Alternate KYC Option separator */}

                    <div
                        className="relative w-full opacity-50 cursor-not-allowed"
                        data-tooltip-id="mobileAppInfo"
                        data-tooltip-content="Coming Soon"
                        data-tooltip-place="top"
                        data-tooltip-offset="10"
                        data-tooltip-arrow
                    >
                        <div className="inline-flex w-full gap-2">
                            <div className="flex flex-col items-center justify-center w-full gap-0 p-4 border rounded-md">
                                <div className="w-max mb-[5px]">
                                    <span className="text-[14px] font-semibold">
                                        Complete KYC Using BUYCEX Mobile App
                                    </span>
                                </div>
                                <div className="w-max mb-[5px]">
                                    <span className="text-[12px]">
                                        Click the button below to download the
                                        app
                                    </span>
                                </div>
                                <button
                                    className="inline-flex bg-[#EFB81C] rounded-md px-10 py-2 transition-all ease-out duration-500"
                                    onClick={() => openModalForType("profile")}
                                >
                                    <span className="text-[12px] font-semibold">
                                        Go to Buycex App
                                    </span>
                                </button>
                            </div>
                        </div>
                        <Tooltip id="mobileAppInfo" />
                    </div>

                    <div className="flex flex-row items-center justify-center w-full gap-3 mt-4">
                        <button
                            type="button"
                            onClick={handleBack}
                            className="inline-flex bg-[#efb81c] rounded-md px-10 py-2 transition-all ease-out duration-500"
                        >
                            <span className="text-[12px] font-semibold m-auto">
                                Back
                            </span>
                        </button>
                        <button
                            type="reset"
                            className={`inline-flex rounded-md px-10 py-2 ${
                                isAnyFieldValid()
                                    ? "bg-[#F4F4F4] hover:bg-[#D1001F]"
                                    : "opacity-50 cursor-not-allowed bg-[#F4F4F4]"
                            } transition-all ease-out duration-500`}
                            disabled={!isAnyFieldValid()}
                            onClick={handleReset}
                        >
                            <span className="text-[12px] font-semibold m-auto">
                                Reset
                            </span>
                        </button>
                        <button
                            type="button"
                            className={`inline-flex rounded-md px-10 py-2 ${
                                isFormValid()
                                    ? "bg-[#EFB81C]"
                                    : "opacity-50 cursor-not-allowed bg-[#F4F4F4]"
                            } transition-all ease-out duration-500`}
                            disabled={!isFormValid()}
                            onClick={handleNext}
                        >
                            <span className="text-[12px] font-semibold m-auto">
                                Next
                            </span>
                        </button>
                    </div>
                </div>
            </section>

            {modal && (
                <WebCamModal
                    isOpen={modal}
                    onConfirm={handleConfirm}
                    onClose={handleClose}
                    onCapture={handleImageCapture}
                />
            )}
        </div>
    );
};

export default UploadID;