import React, { useState } from 'react';
import FusionRankings from './FusionRankings/FusionRankings';
import FutureGrids from './FutureGrids/FutureGrids';
import FuturesMartingale from './FuturesMartingale/FuturesMartingale';
import SpotGrid from './SpotGrid/SpotGrid';
import HistoryDropdown from 'views/profile/History/SpotHistory/DropDownUI';

const Tables = () => {
    const [activeItem, setActiveItem] = useState('Fusion Rankings');

    const discoverNavItems = ['Fusion Rankings', 'Future Grids', 'Futures Martingale', 'Spot Grid'];
    const options1 = ["1000000AIDOGE", "1000000BABYDODGE", "1000000MOG", "1000000PEIPEI", "1000000COQ", "1000000LADYS", "1000000WEN"];
    const options2 = ["All", "< 1 Days", "1-7 Days", "7-30 Days", "> 30 Days"];
    const options3 = ["All", ">100%", "70-100%", "50-70%", "20-50%", "10-20%", "5-10%", "0-5%"];
    const options4 = ["All", "Layer 2", "Layer 1", "MEME", "LSD", "DEFI"];
    const options5 = ["Recommended", "ROI", "Copiers"]

    const handleSelect1 = (selected) => {
        console.log("Dropdown 1 selected:", selected);
    };

    const renderContent = () => {
        switch (activeItem) {
            case 'Fusion Rankings':
                return <FusionRankings />;
            case 'Future Grids':
                return < FutureGrids />;
            case 'Futures Martingale':
                return <FuturesMartingale />
            case 'Spot Grid':
                return <SpotGrid />
            default:
                return < FusionRankings />;
        }
    };

    return (
        <div className='px-3 pb-10'>
            <div className='container mt-5 !p-0'>
                <p className='text-3xl font-semibold py-6'>Hourly Top Picks</p>
                <nav className='flex gap-3 w-[60%] border-gray-200 pt-2'>
                    {discoverNavItems.map((item) => (
                        <button
                            key={item}
                            onClick={() => setActiveItem(item)}
                            className={`relative py-2 px-2 text-md font-semibold ${activeItem === item ? '' : 'text-gray-400'
                                }`}
                        >
                            {item}
                            {activeItem === item && (
                                <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                            )}
                        </button>
                    ))}
                </nav>
                <div className='flex mt-6 justify-between'>
               <div className='flex gap-4'>
               <HistoryDropdown
                    label="Market"
                    options={options1}
                    initialSelected="All"
                    onSelect={handleSelect1}
                    isSearchable={true}
                    width="200px"
                />
                <HistoryDropdown
                    label="Running Time"
                    options={options2}
                    initialSelected="All"
                    onSelect={handleSelect1}
                    isSearchable={false}
                    width="200px"
                />
                <HistoryDropdown
                    label="ROI"
                    options={options3}
                    initialSelected="All"
                    onSelect={handleSelect1}
                    isSearchable={false}
                    width="100px"
                />
                <HistoryDropdown
                    label="Categories"
                    options={options4}
                    initialSelected="All"
                    onSelect={handleSelect1}
                    isSearchable={false}
                    width="100px"
                />
               </div>
                <HistoryDropdown
                    label="Rank by"
                    options={options5}
                    initialSelected="Recommended"
                    onSelect={handleSelect1}
                    isSearchable={false}
                    width="250px"
                />
                </div>
                <div className="mt-6">{renderContent()}</div>
            </div>
        </div>
    );
};

export default Tables;
