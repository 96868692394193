import React from "react";
import { useParams } from "react-router-dom";
import Mobile from "./mobile.avif";

const data = [
    {
        id: 1,
        title: "Universal Mobile",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 300,
        category: "Electronics",
        img: Mobile,
    },
    {
        id: 2,
        title: "OPPO A7",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 200,
        category: "Electronics",
        img: Mobile,
    },
    {
        id: 3,
        title: "SAMSUNG S7",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 800,
        category: "Electronics",
        img: Mobile,
    },
    {
        id: 4,
        title: "HP Core 2",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 900,
        category: "Computers",
        img: Mobile,
    },
    {
        id: 5,
        title: "DELL Core 2",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 400,
        category: "Computers",
        img: Mobile,
    },
    {
        id: 6,
        title: "HP Core 2",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 1200,
        category: "Computers",
        img: Mobile,
    },
    {
        id: 7,
        title: "HP Core 2",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 700,
        category: "Health",
        img: Mobile,
    },
    {
        id: 8,
        title: "HP Core 2",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 550,
        category: "Computers",
        img: Mobile,
    },
    {
        id: 9,
        title: "HP Core 2",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 1100,
        category: "Computers",
        img: Mobile,
    },
];

const SingleProductDetail = () => {
    const { id } = useParams(); // Get the ID from the URL
    const product = data.find((item) => item.id === parseInt(id)); // Find the product by ID

    if (!product) {
        return <div>Product not found</div>; // Handle case where product is not found
    }

    return (
        <div className="product-detail">
            <img src={product.img} alt={product.title} />

            <div className="px-10">
                <div className="container">
                    <h1 className="mt-4 text-center text-5xl font-bold">
                        {product.title}
                    </h1>
                    <p className="mt-4 mb-4 text-xl font-semibold">
                        {product.desc}.
                    </p>
                    <p className="">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Assumenda, maiores? Dolorem assumenda excepturi enim.
                        Ipsam modi necessitatibus nulla tenetur ut quisquam
                        laudantium id veritatis nobis facilis aperiam, quasi
                        architecto voluptatibus dolorum assumenda cumque ab sit
                        ducimus sunt quaerat dicta maxime itaque. Error eligendi
                        omnis corrupti nisi tenetur ipsam iste repudiandae fugit
                        id dolor, quia, accusantium temporibus recusandae unde
                        sed, et ipsum iure facere voluptas suscipit! Suscipit
                        saepe quia tempora reiciendis ex fuga! Dolorem
                        accusantium odit odio earum, saepe nesciunt, voluptate
                        animi repellat nisi consectetur, reprehenderit
                        recusandae vel sunt architecto inventore. Labore unde
                        deserunt repudiandae sapiente possimus quidem esse
                        aliquam quae!
                    </p>
                    <div className="mt-4 mb-4 text-2xl font-bold h-10 bg-[#ffb11a] rounded-[10px] w-full text-center">
                        ${product.price}
                    </div>
                    <div className="w-96 mx-auto mt-6 mb-6">
                        <button className="w-full h-10 bg-[#ffb11a] rounded-[10px]  font-bold text-black">
                            Buy Now
                        </button>
                    </div>
                </div>
            </div>
            {/* Add more product details here */}
        </div>
    );
};

export default SingleProductDetail;
