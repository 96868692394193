import React, { useState, useEffect } from 'react';
import HistoryDropdown from '../../SpotHistory/DropDownUI';
import Cross from './Cross';
import Isolated from './Isolated';
import { DatePicker } from 'antd';
import { createGlobalStyle, styled } from "styled-components";

const { RangePicker } = DatePicker;

const OrderHistory = () => {
    const [activeItem, setActiveItem] = useState('Open Orders');
    const [activeSubItem, setActiveSubItem] = useState('Cross');

    const processingSubItems = ['Cross', 'Isolated'];

    useEffect(() => {
        if (activeItem === 'Open Orders') {
            setActiveSubItem('Cross');
        } else if (activeItem === 'Cross') {
            setActiveSubItem('Cross');
        }
    }, [activeItem]);

    const renderContent = () => {
        switch (activeSubItem) {
            case 'Isolated':
                return <Isolated subItem={activeSubItem} />;
            case 'All':
            default:
                return <Cross subItem={activeSubItem} />;
        }
    };

    const renderSubItems = () => {
        const subItems = activeItem === 'Open Orders' ? processingSubItems : [];

        const options1 = ["All", "1000SATS", "1INCH", "1MBABYDODGE", "AAVE", "ACA"];
        const options2 = ["All", "USDT", "TUSD", "USDC", "FDUSD", "BUSD"];
        const options3 = ["All", "Buy", "Sell"];
        const options4 = ["Order Time"]

        const handleSelect1 = (selected) => {
            console.log("Dropdown 1 selected:", selected);
        };

        return (
            <>
                <div className='flex gap-4 items-center'>
                    <div className='inline-flex flex-wrap border rounded-lg pt-[0.20rem] pb-[0.25rem] px-1 gap-2'>
                        {subItems.map((subItem) => (
                            <button
                                key={subItem}
                                onClick={() => setActiveSubItem(subItem)}
                                className={`w-auto py-1.5 px-6 text-sm font-medium ${activeSubItem === subItem ? 'bg-[#F5F5F5] rounded-md' : 'text-gray-400'}`}
                            >
                                {subItem}
                            </button>
                        ))}
                    </div>

                    <div className='flex items-center gap-2'>
                        <GlobalStyles />
                        <StyledRangePicker className="px-2 w-[600px] h-[40px] bg-white sm:w-1/3 py-[0.55rem] border-l" />
                        <HistoryDropdown
                            label="Base"
                            options={options1}
                            initialSelected="All"
                            onSelect={handleSelect1}
                            isSearchable={true}
                            width="200px"
                        />
                        -
                        <HistoryDropdown
                            label="Quote"
                            options={options2}
                            initialSelected="All"
                            onSelect={handleSelect1}
                            isSearchable={false}
                            width="200px"
                        />
                        <HistoryDropdown
                            label="Side"
                            options={options3}
                            initialSelected="All"
                            onSelect={handleSelect1}
                            isSearchable={false}
                            width="200px"
                        />
                    </div>
                </div>
                <div className='mt-4 flex gap-4'>
                    <HistoryDropdown
                        label="Sort By"
                        options={options4}
                        initialSelected="Order Time"
                        onSelect={handleSelect1}
                        isSearchable={false}
                        width="200px"
                    />
                    <button className='bg-[#EFB81C] px-4 py-2 rounded-lg font-medium text-sm'>Search</button>
                    <button className='px-4 py-2 rounded-lg font-medium text-sm'>Reset</button>
                </div>
            </>
        );
    };

    return (
        <div className='px-3'>
            <div className='container mt-5 !p-0'>

                {renderSubItems()}

                <div className="mt-6">{renderContent()}</div>
            </div>
        </div>
    );
};

const GlobalStyles = createGlobalStyle`
  // Override hover state for picker
  .ant-picker:hover {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on hover */
  }

  // Override focus state for picker
  .ant-picker-focused {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on focus */
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important; /* Add shadow with color #EFB81C */
  }

  // Style for the active state bar in RangePicker
  .ant-picker-active-bar {
    background: #EFB81C !important; /* Change the active border bar color to #EFB81C */
  }

  // Style calendar cells with selected date
  .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EFB81C !important; /* Change the background color of selected date */
    color: white !important; /* Keep the text color white for contrast */
  }

  // Style for "Today" cell in the calendar dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    border: 1px solid #EFB81C !important; /* Change border color to #EFB81C for the "Today" cell */
    border-radius: 4px;
    content: "";
  }
    // Change color of calendar header buttons on hover
  .ant-picker-dropdown .ant-picker-header-view > button:hover {
    color: #EFB81C !important; /* Change hover color to #EFB81C */
  }
`;

const StyledRangePicker = styled(RangePicker)`
  // Custom styling for RangePicker input border when focused
  .ant-picker-input > input:focus {
    box-shadow: none !important; /* Remove any focus box-shadow */
    border-color: #EFB81C !important; /* Ensure border stays #EFB81C */
  }
`;


export default OrderHistory;
