import React, { useState } from "react";
import { FaFileCircleCheck } from "react-icons/fa6";

const TransferRecord = () => {
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div className="relative flex items-center gap-x-3 bottom-3">
        <FaFileCircleCheck size={26} />
        <div className="text-black text-[24px] font-semibold">Spot <span className="text-gray-600"> &gt; </span> Transfer Records</div>
      </div>
      <div className="flex flex-col w-full">
        {/* First card */}
        <div className="flex-col justify-center w-full p-4 space-x-4 bg-white rounded-3xl">
          <div className="flex items-center w-full gap-x-5">
            <div className="flex items-center space-x-2">
              <label htmlFor="asset" className="text-gray-700">
                Asset
              </label>
              <select id="asset" className="p-2 border rounded-md">
                <option>All</option>
              </select>
            </div>
            <div className="flex items-center space-x-2">
              <label htmlFor="operation" className="text-gray-700">
                Operation
              </label>
              <select id="operation" className="p-2 border rounded-md">
                <option>All</option>
              </select>
            </div>
          </div>
          <div className="flex items-center w-full mt-3 gap-x-3">
            <div className="flex items-center gap-x-2">
              <label className="text-gray-700">Time</label>
              <button className="p-2 bg-yellow-200 rounded-md">
                Last 7 days
              </button>
              <button className="p-2 bg-gray-200 rounded-md">
                Last 30 days
              </button>
              <button className="p-2 bg-gray-200 rounded-md">All</button>
            </div>
            <div className="flex items-center space-x-2">
              <input
                type="date"
                name="startDate"
                value={dateRange.startDate}
                onChange={handleDateChange}
                className="p-2 border rounded-md"
              />
              <span className="text-gray-700">-</span>
              <input
                type="date"
                name="endDate"
                value={dateRange.endDate}
                onChange={handleDateChange}
                className="p-2 border rounded-md"
              />
            </div>
            <button className="p-2 bg-yellow-200 rounded-md">Export</button>
          </div>
        </div>

        {/* Second Card */}
        <div className="bg-white rounded-2xl w-full min-h-[500px] mt-3">
          {/* Content for the second card */}
        </div>
      </div>
    </>
  );
};

export default TransferRecord;
