import React, { useState } from 'react';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";

function EstimatedCopyTrading({ isVisible, toggleVisibility, data }) {
    const [activeButton, setActiveButton] = useState('Copy Trading');
    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
    };

    const getButtonClass = (buttonType) => {
        return buttonType === activeButton
            ? 'bg-[#EFB81C] px-3 py-2 rounded-md text-sm font-semibold'
            : 'border border px-3 py-2 rounded-md text-sm font-semibold';
    };
    return (
        <div>
        <div className='mt-0 md:mt-6 p-3 md:p-6 flex flex-col sm:flex-row justify-between w-full border rounded-2xl'>
            <div className='flex flex-col justify-between'>
                <div>
                    <div className='flex items-center gap-1'>
                        <p className='text-[20px] font-semibold'>Copy Trading Account</p>
                        <button onClick={toggleVisibility} className='mt-1'>
                            {isVisible ? <FaRegEyeSlash className='text-xl' /> : <FaRegEye className='text-xl' />}
                        </button>
                    </div>
                
            </div>
            <div className='flex justify-between w-1/2'>
                <div>
                    <p className='text-sm text-[#707A8A] pt-2 underline underline-offset-4 decoration-dashed'>Total Equity</p>
                    <div className='flex items-baseline gap-2'>
                        <p className='text-[32px] pb- font-semibold'>{isVisible ? '0.00' : '*****'}</p>
                        <span className='text-sm flex items-center gap-1 font-semibold'>USD</span>
                    </div>
                    <p className='text-sm text-[#707A8A]'>{isVisible ? `≈ $0.00000000 BTC` : '****'}</p>
                </div>
            </div>
            </div>
            <div className='flex flex-col items-start md:items-end justify-between'>
                    <div>
                        <button
                            onClick={() => handleButtonClick('Copy Trading')}
                            className={getButtonClass('Copy Trading')}
                        >
                            Copy Trading
                        </button>
                        <button
                            onClick={() => handleButtonClick('History')}
                            className={`ml-4 ${getButtonClass('History')}`}
                        >
                            History
                        </button>
                    </div>
                </div>
        </div>
        </div>
    );
}

export default EstimatedCopyTrading;
