import React, { useState } from 'react';
import { AiOutlineFileSearch } from "react-icons/ai";

function NormalReviews() {
    return <div>
        <p className='py-2'>Online Buy Ads</p>
        <table className="w-full border-b table-container h-96">
            {/* Table Headings */}
            <thead className="w-full">
                <tr className="!h-[40px] bg-[#FAFAFA] px-4 flex justify-between items-center">
                    <th className="text-[#aaaaaa] text-[12px] font-light py-2 text-left">
                        Coin
                    </th>
                    <th className="text-[#aaaaaa] text-[12px] font-light py-2 text-left">
                        Price
                    </th>
                    <th className="text-[#aaaaaa] text-[12px] font-light px-4 py-2  text-left" style={{ width: 'max-content' }}>
                        Order Limit/Available
                    </th>
                    <th className="text-[#aaaaaa] text-[12px] font-light px-4 py-2 text-left">
                        Payment
                    </th>
                    <th className="text-[#aaaaaa] text-[12px] font-light py-1 text-end">
                        Trade
                    </th>
                </tr>
            </thead>

            <tbody>
                {/* Empty state */}
                <tr className="w-full flex flex-col py-5 h-96 justify-center items-center">
                    <td colSpan="5" className="flex flex-col justify-center items-center">
                        <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
                        <p className="mt-3 text-[#474D57]">No Ads</p>
                    </td>
                </tr>
            </tbody>
        </table>

        <p className='pt-2'>Online Sell Ads</p>
        <table className="w-full mt-3 table-container h-96">
            {/* Table Headings */}
            <thead className="w-full">
                <tr className="!h-[40px] bg-[#FAFAFA] px-4 flex justify-between items-center">
                    <th className="text-[#aaaaaa] text-[12px] font-light py-2 text-left">
                        Coin
                    </th>
                    <th className="text-[#aaaaaa] text-[12px] font-light py-2 text-left">
                        Price
                    </th>
                    <th className="text-[#aaaaaa] text-[12px] font-light px-4 py-2  text-left" style={{ width: 'max-content' }}>
                        Order Limit/Available
                    </th>
                    <th className="text-[#aaaaaa] text-[12px] font-light px-4 py-2 text-left">
                        Payment
                    </th>
                    <th className="text-[#aaaaaa] text-[12px] font-light py-1 text-end">
                        Trade
                    </th>
                </tr>
            </thead>
            <tbody>
                <div className="flex flex-col h-96 py-5 justify-center items-center">
                    <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
                    <p className="mt-3 text-[#474D57]">No Ads</p>
                </div>
            </tbody>
        </table>
    </div>;
}

function CashReviews() {
    return <div className="h-52 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
            <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
            <p className="mt-3 text-[#474D57]">No Comments</p>
        </div>
    </div>;
}

function BlockReviews() {
    return <div className="h-52 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
            <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
            <p className="mt-3 text-[#474D57]">No Comments</p>
        </div>
    </div>;
}
function FiatReviews() {
    return <div className="h-52 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
            <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
            <p className="mt-3 text-[#474D57]">No Comments</p>
        </div>
    </div>;
}

function OnlineAds() {
    const [activeTab, setActiveTab] = useState('Normal');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div>

            {/* Navbar */}
            <div className='flex gap-8 mt-4 border-b h-10'>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'Normal' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('Normal')}
                >
                    Normal(0)
                </button>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'Cash' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('Cash')}
                >
                    <div className='flex items-center gap-1'><p className='text-[14px]'>Cash</p><p className='text-[14px]'>(0)</p></div>
                </button>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'Block' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('Block')}
                >
                    <div className='flex items-center gap-1'><p className='text-[14px]'>Block</p><p className='text-[14px]'>(0)</p></div>
                </button>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'Fiat' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('Fiat')}
                >
                    <div className='flex items-center gap-1'><p className='text-[14px]'>Fiat</p><p className='text-[14px]'>(0)</p></div>
                </button>
            </div>

            {/* Content based on active tab */}
            <div className='mt-4'>
                {activeTab === 'Normal' && <NormalReviews />}
                {activeTab === 'Cash' && <CashReviews />}
                {activeTab === 'Block' && <BlockReviews />}
                {activeTab === 'Fiat' && <FiatReviews />}
            </div>
        </div>
    );
}

export default OnlineAds;
