// reducers/authphoneReducer.js

import {
    CHECK_CELLPHONE_REQUEST,
    CHECK_CELLPHONE_SUCCESS,
    CHECK_CELLPHONE_FAILURE,
    SEND_NEW_CELLPHONE_REQUEST,
    SEND_NEW_CELLPHONE_SUCCESS,
    SEND_NEW_CELLPHONE_FAILURE,
    UPDATE_CELLPHONE_REQUEST,
    UPDATE_CELLPHONE_SUCCESS,
    UPDATE_CELLPHONE_FAILURE
} from "../types"; // Import action types

const initialState = {
    loading: false,
    error: null,
    available: null,
    newCellphoneSuccess: false,
    success: false,
    data: null,
};

// Reducer for authPhone actions
const authPhoneReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_CELLPHONE_REQUEST:
            return { ...state, loading: true, error: null };
        case CHECK_CELLPHONE_SUCCESS:
            return { ...state, loading: false, available: action.payload, error: null };
        case CHECK_CELLPHONE_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case SEND_NEW_CELLPHONE_REQUEST:
            return { ...state, loading: true, error: null };
        case SEND_NEW_CELLPHONE_SUCCESS:
            return { ...state, loading: false, newCellphoneSuccess: true, error: null };
        case SEND_NEW_CELLPHONE_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case UPDATE_CELLPHONE_REQUEST:
            return { ...state, loading: true, error: null };
        case UPDATE_CELLPHONE_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload };
        case UPDATE_CELLPHONE_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload };

        default:
            return state;
    }
};

export default authPhoneReducer;
