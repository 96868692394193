import React from 'react';
import image20Million from '../../assets/img/coin.png'; // Replace with your actual image path
import { FaTwitter, FaTelegram, FaFacebookF } from 'react-icons/fa';
const GetStartedSection = () => {
  const handleMoreClick = () => {
    console.log('More button clicked!'); // Replace with your desired action
  };
  return (
    <div className="p-6 bg-gray-50 mx-4 md:mx-10">
   
   <div className="flex justify-between items-center mb-6">
      <h2 className="text-3xl font-bold">Get Started</h2>

      {/* Social Icons and More button */}
      <div className="flex items-center space-x-4">
        {/* Twitter Icon */}
        <a href="#" className="text-gray-700 hover:text-blue-500">
          <FaTwitter className="h-6 w-6" />
        </a>

        {/* Telegram Icon */}
        <a href="#" className="text-gray-700 hover:text-blue-500">
          <FaTelegram className="h-6 w-6" />
        </a>

        {/* Facebook Icon */}
        <a href="#" className="text-gray-700 hover:text-blue-500">
          <FaFacebookF className="h-6 w-6" />
        </a>

        {/* More button */}
        <button
          onClick={handleMoreClick}
          className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700"
          id="more-button"
        >
          More
        </button>
      </div>
    </div>
    
     
      <div className="flex flex-col md:flex-row">
        {/* Left Section */}
        <div className="relative w-full md:w-1/2">
          <div className="aspect-w-16 aspect-h-9">
            <img
              src={image20Million} // Replace with the correct image path
              alt="Gate.io Startup participation tutorial"
              className="w-full h-full object-cover rounded-lg"
            />
            <button className="absolute inset-0 flex items-center justify-center">
              <div className="bg-black bg-opacity-50 p-4 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14.752 11.168l-3.197-2.132A1 1 0 0010 10v4a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4.5c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5-7.5-3.358-7.5-7.5 3.358-7.5 7.5-7.5z"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-full md:w-1/2 flex flex-col md:pl-6 mt-6 md:mt-0">
        <h3 className="text-6xl font-semibold mb-6 pt-4 pb-4">FAQ</h3>

  <ul className="text-2xl space-y-6">
    <li className=" flex justify-between items-center py-4">
      <a href="#" className=" text-[#EFB81C] hover:underline">
        Startup FAQ
      </a>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 text-gray-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 7l5 5m0 0l-5 5m5-5H6"
        />
      </svg>
    </li>
    <li className="flex justify-between items-center py-4">
      <a href="#" className="text-[#EFB81C] hover:underline">
        Startup Participation Tutorial
      </a>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 text-gray-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 7l5 5m0 0l-5 5m5-5H6"
        />
      </svg>
    </li>
    <li className="flex justify-between items-center py-4">
      <a href="#" className="text-[#EFB81C] hover:underline">
        Gate VIP Tiered Fee
      </a>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 text-gray-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 7l5 5m0 0l-5 5m5-5H6"
        />
      </svg>
    </li>
  </ul>
</div>

      </div>
    </div>
  );
};

export default GetStartedSection;
