import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaMobile } from "react-icons/fa";
import {
    IoMdHelpCircleOutline,
    IoMdCheckmarkCircleOutline,
    IoIosCopy,
} from "react-icons/io";
import QRCode from "qrcode.react";
import GoogleIcon from "assets/img/GoogleOTPSVG.png";
import YellowTick from "assets/img/yellow-bg-tick.png";
import ShieldLock from "assets/img/shield-lock.png";
import G2faVerification from "./helpers/G2faVerify";
import EmailVerification from "./helpers/EmailVerify";
import {
    fetchAuthStatus,
    get2FA,
} from "store/actions/userSecuritySettingsAction";
import { forgotCode } from "store/actions/authAction";
import QRCodeDisplay from "./helpers/QRCodeDisplay";
import { Modal } from "antd";

const GoogleAuthenticatorChangeModal = ({
    show,
    onClose,
    onSubmit,
    AlertMessage,
    closeAlert,
    setAlertMessage,
    SuccessMessage,
    email,
    username,
    userId,
    token,
}) => {
    const [step, setStep] = useState(1);
    const [otpCode, setOtpCode] = useState("");
    const [newOTP, setNewOTP] = useState("");
    const [showOtpMethodModal, setShowOtpMethodModal] = useState(false);
    const [otpMethod, setOtpMethod] = useState("authenticator");
    const get2FAState = useSelector((state) => state.get2FAQR);
    const set2FAState = useSelector((state) => state.set2FA);
    const {
        loading: get2FALoading,
        data: get2FAData,
        error: get2FAError,
    } = get2FAState;
    const {
        loading: set2FALoading,
        data: set2FAData,
        error: set2FAError,
    } = set2FAState;
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);

    const dispatch = useDispatch();

    useEffect(() => {
        if (show && step === 1) {
            console.log("Email:" + email + ".");
            let payload = {
                email: email,
            };
            // dispatch(forgotCode(payload));
        }
    }, [dispatch, email, step, show]);

    useEffect(() => {
        if (show && step === 2) {
            dispatch(get2FA(userId, token));
        }
    }, [dispatch, token, userId, step, show]);

    const state = useSelector((state) => state);
    console.log(state);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const modal = document.querySelector(".modal-inner");
            if (modal && !modal.contains(event.target)) {
                onClose();
            }
            const outermodal = document.querySelector(".modal-outer");
            if (outermodal && !modal.contains(event.target)) {
                onClose();
            }
        };

        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show, onClose]);

    if (!show) return null;

    const handleNextStep = () => {
        if (step === 2) {
            onClose();
            setStep(2);
            setNewOTP("");
            if (userID && userToken) {
                dispatch(fetchAuthStatus(userID, userToken));
            }
            return;
        }
        setStep(step + 1);
    };

    const handleCancel = () => {
        setStep(1);
        setOtpCode("");
        setNewOTP("");
        onClose();
        if (userID && userToken) {
            dispatch(fetchAuthStatus(userID, userToken));
        }
    };

    const handleOtpMethodChange = (value) => {
        setOtpMethod(value);
    };

    const handleSwitchVerificationTool = () => {
        setShowOtpMethodModal(true);
    };

    const handleOtpMethodContinue = () => {
        setShowOtpMethodModal(false);
        setStep(2); // Ensure step 2 is shown after selecting the OTP method
    };

    return (
        <Modal
            visible={show}
            onCancel={handleCancel}
            footer={null}
            title={null}
            centered
            className="modal-inner w-1/3"
        >
            {/* {AlertMessage && (
                    <div className={`px-4 py-2 my-3 rounded ${AlertMessage.type === 'error' ? 'bg-red-200 text-red-800' : 'bg-green-200 text-green-800'}`}>
                        <div className="flex justify-between items-center">
                            <span>{AlertMessage.message}</span>
                            <button className="text-xl leading-none" onClick={closeAlert}>&times;</button>
                        </div>
                    </div>
                )} */}
            {/* Step Tracer */}
            {step === 1 && <EmailVerification onSuccess={handleNextStep} />}
            {step === 2 && (
                <G2faVerification
                    // handleSwitchVerificationTool={handleSwitchVerificationTool}
                    onSuccess={handleNextStep}
                    emailasprop={email}
                    userId={userId}
                    token={token}
                    type="delete"
                />
            )}
            {/* {showOtpMethodModal && (
                <div className="fixed inset-0 modal-outer flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-1/3">
                        <div className="mb-4">
                          <h2 className="text-2xl font-semibold mb-2 text-left">Security Settings</h2>
                          <p>For your account and asset security, please bind any of the following settings</p>
                        </div>
                        <div className="mb-4">
                          <div
                              className={`flex gap-1 items-center bg-gray-100 py-2 rounded-md cursor-pointer ${otpMethod === 'authenticator' ? 'componentBorderSelected' : 'componentBorder'}`}
                              onClick={() => handleOtpMethodChange('authenticator')}
                          >
                              <img src={ShieldLock} alt="shield-lock-icon" />
                              <h3 className="font-medium text-lg">2FA Authenticator</h3>
                              <IoMdCheckmarkCircleOutline className={`ml-auto mr-2 text-xl ${otpMethod === 'authenticator' ? 'text-yellow-500' : 'text-gray-500'}`} />
                          </div>
                          <div
                              className={`flex gap-1 items-center bg-gray-100 py-2 rounded-md cursor-pointer mt-4 ${otpMethod === 'mobile' ? 'componentBorderSelected' : 'componentBorder'}`}
                              onClick={() => handleOtpMethodChange('mobile')}
                          >
                              <FaMobile className="text-2xl mx-2"/>
                              <h3 className="font-medium text-lg">Mobile</h3>
                              <IoMdCheckmarkCircleOutline className={`ml-auto text-xl mr-2 ${otpMethod === 'mobile' ? 'text-yellow-500' : 'text-gray-500'}`} />
                          </div>
                        </div>
                        <button className="bg-yellow-500 text-white py-2 px-4 rounded focus:outline-none w-full" onClick={handleOtpMethodContinue}>Continue</button>
                    </div>
                </div>
            )} */}
        </Modal>
    );
};

export default GoogleAuthenticatorChangeModal;
