import React, { useEffect, useRef, useState } from "react";

import { useLocation, Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import SpotOrderMarginTable from "../../tables/SpotOrderMarginTable";
import ConvertOrderCompletedTable from "../../tables/ConvertOrderCompletedTable";

const ConvertOrderCompleted = () => {
  const location = useLocation();



  const dropDownRef = useRef(null);

  // DropDown States
  const [isTrade, setIsTrade] = useState(false);
  const [isSide, setIsSide] = useState(false);

  // DropDowns Handler
  const handleTradeDropDown = () => {
    setIsTrade(!isTrade);
    setIsSide(false);

  };

  const handleSideDropDown = () => {
    setIsTrade(false);
    setIsSide(!isSide);

  };

  //For Clicking Outside DROPDOWNS
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsTrade(null);
        setIsSide(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const navbar = [
    {
      name: "Pending",
      link: "/user/convert-order/pending",
    },
    {
      name: "Completed",
      link: "/user/convert-order/completed",
    },

  ];

  return (
    <>
      <div useRef={dropDownRef} className="flex flex-col w-full">
        {/* First card */}
        <div className="flex-col justify-center w-full p-4  bg-white rounded-3xl text-[16px]">
          {/* Navbar */}
          <div className="flex items-center gap-x-3">
            {navbar.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                className={`${
                  location.pathname === item.link
                    ? "text-[#efb81c] border-[#efb81c]"
                    : " border-gray-300 text-gray-300"
                } px-3 py-1  border-[1px] rounded-md  `}
              >
                <button>{item.name}</button>
              </Link>
            ))}
          </div>

          <div className="flex items-center justify-between w-full mt-4">
            {/* Drop Downs  */}
            <div className="flex items-center gap-x-4">

    
           {/* From DropDown */}
           <div className="flex items-center gap-x-2">
           <h6 className="font-semibold">From</h6>
           <div className="relative inline-block text-left">
            <div>
              <button
                onClick={handleTradeDropDown}
                type="button"
                className="inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-[8px] text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                id="filter-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                <div className="text-[16px] font-normal">All</div>
                <div className="pl-2 ml-4 border-l-2">
                  <IoIosArrowDown className="" />
                </div>
              </button>
            </div>

            {/* DropDown Content */}
            <div
              className={`${
                isTrade ? "absolute" : "hidden"
              } z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              aria-orientation="vertical"
              tabIndex="-1"
            >
              <div className="py-1 max-h-[300px] overflow-auto" role="none">
                <h6
                  className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  role="filteritem"
                  tabIndex="-1"
                  id="filter-item-0"
                >
                  Hello
                </h6>
              </div>
            </div>
          </div>
          </div>
           {/* To DropDown */}
           <div className="flex items-center gap-x-2">
           <h6 className="font-semibold">To</h6>
           <div className="relative inline-block text-left">
            <div>
              <button
                onClick={handleSideDropDown}
                type="button"
                className="inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-[8px] text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                id="filter-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                <div className="text-[16px] font-normal">All</div>
                <div className="pl-2 ml-4 border-l-2">
                  <IoIosArrowDown className="" />
                </div>
              </button>
            </div>

            {/* DropDown Content */}
            <div
              className={`${
                isSide ? "absolute" : "hidden"
              } z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              aria-orientation="vertical"
              tabIndex="-1"
            >
              <div className="py-1 max-h-[300px] overflow-auto" role="none">
                <h6
                  className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                  role="filteritem"
                  tabIndex="-1"
                  id="filter-item-0"
                >
                  Hello
                </h6>
              </div>
            </div>
          </div>
          </div>
            </div>
            
           
          </div>
        </div>

        {/* Table */}
        <ConvertOrderCompletedTable />
      </div>
    </>
  );
};

export default ConvertOrderCompleted;
