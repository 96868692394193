import { voteRepository } from "../../repository/voteRepository";
import Toast from "react-hot-toast";

// Define the upvote action
export const upvoteItem = (type, coinId, userId, token) => async (dispatch) => {
  try {
    const response = await voteRepository.vote(type, coinId, userId, token);
    if (response.success) {
      Toast.success(`Coin ${coinId} upvoted successfully!`);
      dispatch({ type: "VOTE_SUCCESS", payload: { coinId, voteType: "upvote" } });
    } else {
      throw new Error(response.message || "Failed to upvote");
    }
  } catch (error) {
    Toast.error(error.message);
    dispatch({ type: "VOTE_ERROR", payload: error.message });
  }
};

// Define the downvote action
export const downvoteItem = (type, coinId, userId, token) => async (dispatch) => {
  try {
    const response = await voteRepository.vote(type, coinId, userId, token);
    if (response.success) {
      Toast.success(`Coin ${coinId} downvoted successfully!`);
      dispatch({ type: "VOTE_SUCCESS", payload: { coinId, voteType: "downvote" } });
    } else {
      throw new Error(response.message || "Failed to downvote");
    }
  } catch (error) {
    Toast.error(error.message);
    dispatch({ type: "VOTE_ERROR", payload: error.message });
  }
};
