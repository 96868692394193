import React,{useState} from 'react'
import Derivatives from './Derivatives';
import FutureTable from './FutureTable';

function FutureAssets() {
  const [isVisible, setIsVisible] = useState(false); 

  // Function to toggle visibility
  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };
  return (
    <div className='px-0 lg:px-3'>
      <div className='max-w-[1400px] mx-auto !p-0'>
      <Derivatives isVisible={isVisible} toggleVisibility={toggleVisibility} />
      <FutureTable isVisible={isVisible}/>
    </div>
    </div>
  )
}

export default FutureAssets