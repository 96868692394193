import React from "react";

function PersonalInformation({
    truename,
    dob,
    address,
    IdCard,
    email,
    cellphone,
}) {
    const maskedEmail = email ? `${email.slice(0, 3)}****@****.com` : "";
    return (
        <div>
            <div className="my-3">
                <span className="text-[24px] font-semibold">
                    Personal Information
                </span>
                <div className="border rounded-xl p-8 mt-5">
                    <div className="flex text-sm mb-4 font-medium w-full justify-between">
                        <p className="text-[#969a9d]">Legal Name</p>
                        <p className="text-[#666769]">{truename}</p>
                    </div>
                    <div className="flex text-sm mb-4 font-medium w-full justify-between">
                        <p className="text-[#969a9d]">Date of Birth</p>
                        <p className="text-[#666769]">{dob}</p>
                    </div>
                    <div className="flex text-sm mb-4 font-medium w-full justify-between">
                        <p className="text-[#969a9d]">Address</p>
                        <div>
                            <p className="text-[#666769]">{address}</p>
                            <button className="flex text-xs w-full justify-end text-[#EFB81C]">
                                Edit Address
                            </button>
                        </div>
                    </div>
                    <div className="flex text-sm mb-4 font-medium w-full justify-between">
                        <p className="text-[#969a9d]">
                            Identification Documents
                        </p>
                        <p className="text-[#666769]">
                            National ID Upload & Verify , {IdCard}
                        </p>
                    </div>
                    <div className="flex text-sm mb-4 font-medium w-full justify-between">
                        <p className="text-[#969a9d]">Email Address</p>
                        <p className="text-[#666769]">{maskedEmail}</p>
                    </div>
                    <div className="flex text-sm mb-4 font-medium w-full justify-between">
                        <p className="text-[#969a9d]">Phone Number</p>
                        <p className="text-[#666769]">
                            {cellphone ? `+${cellphone}` : "Not Set"}
                        </p>
                    </div>
                    <div className="flex text-sm font-medium w-full items-end justify-between">
                        <p className="w-1/2 text-lg font-bold">BuyCex Exchange Investment %</p>
                        <div className="flex text-sm items-end font-medium w-1/5 justify-between">
                            <p className="text-[#22c55e]">0.01%</p>
                            <div className="flex flex-col items-end">
                                <p className="text-[#666769] text-[16px] font-semibold">Amount</p>
                                <p className="text-[#22c55e]">1200 USDT</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PersonalInformation;
