import React from "react";
import { IoClose } from "react-icons/io5";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const ReactivateAccountSecondPopup = ({ onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center backdrop-blur-[1px] z-30">
            <div className="bg-white p-8 rounded-xl shadow-lg relative max-w-md w-full">
                <button
                    onClick={onClose}
                    aria-label="Close"
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                >
                    <IoClose className="text-2xl text-gray-400" />
                </button>
                <div className="flex justify-center items-center mb-3">
                    <BsExclamationDiamondFill className="text-8xl text-[#EFB81C]" />
                </div>
                <p className="text-center font-bold mt-3">Reminder</p>
                <p className="text-sm mt-1 mb-5">
                    <p className="text-sm">
                        To reactivate your account through self-service, we will
                        need to verify your identity. This process may require
                        additional documentation or information.
                    </p>{" "}
                    Please allow up to 7 business days for your account to be
                    reviewed after submitting your request. We appreciate your
                    patience.
                </p>
                <div className="flex justify-center">
                        <button
                            onClick={onClose}
                            type="button"
                            aria-label="Proceed"
                            className="bg-[#EFB81C] text-white hover:bg-[#d4a800] font-medium rounded-lg text-md px-5 py-2"
                        >
                            Apply to Reactivate
                        </button>
                </div>
            </div>
        </div>
    );
};

export default ReactivateAccountSecondPopup;
