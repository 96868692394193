import { Image as ImageJs } from "image-js";

const randInt = (min = 0, max = 2147483646) =>
    Math.floor(Math.random() * (max - min + 1) + min);

const sizes = {
    WIDTH: 480,
    HEIGHT: 240,
    PUZZLE: 91,
    PADDING: 20,
};

const puzzlePieceSvg = ({
    distort = false,
    rotate = false,
    fill = "#000",
    stroke = "#fff",
    seed = 0,
    opacity = "1",
    strokeWidth = "0",
} = {}) =>
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" height="${
        sizes.PUZZLE
    }" width="${
        sizes.PUZZLE
    }"><filter id="noise"><feTurbulence type="turbulence" baseFrequency="0.05" seed="${seed}" numOctaves="2" result="turbulence"/><feDisplacementMap in2="turbulence" in="SourceGraphic" scale="2.5" xChannelSelector="R" yChannelSelector="G"/></filter><path ${
        distort ? 'filter="url(#noise)"' : ""
    } ${
        rotate ? `transform="rotate(${seed}, 10, 10)"` : ""
    } d="M5.56.56a2.305 2.305 0 00-2.296 2.304 2.305 2.305 0 00.801 1.747H.135v4.295a2.305 2.305 0 011.8-.865 2.305 2.305 0 012.304 2.306 2.305 2.305 0 01-2.305 2.304 2.305 2.305 0 01-1.8-.865v4.226H11.26v-4.258a2.305 2.305 0 001.781.842 2.305 2.305 0 002.305-2.305 2.305 2.305 0 00-2.305-2.305 2.305 2.305 0 00-1.78.841V4.611H7.072a2.305 2.305 0 00.801-1.747A2.305 2.305 0 005.57.559a2.305 2.305 0 00-.009 0z" opacity="${opacity}" stroke="${stroke}" fill="${fill}" stroke-width="${strokeWidth}" stroke-linejoin="round"/></svg>`;

const sliderCaptchaImgCreate = async ({
    distort = false,
    rotate = false,
    fill = "#000",
    stroke = "#fff",
    strokeWidth = "0",
    opacity = "1",
} = {}) => {
    try {
        const image = await ImageJs.load(
            "https://picsum.photos/" +
                sizes.WIDTH +
                "/" +
                sizes.HEIGHT +
                "/?image=" +
                Math.round(Math.random() * 20)
        );
        const seed = randInt();
        //   const overlay = await Image.load(
        //     puzzlePieceSvg({
        //       rotate,
        //       distort,
        //       fill,
        //       stroke,
        //       strokeWidth,
        //       opacity,
        //       seed,
        //     }),
        //   );

        const location = {
            // Solution for slider
            left: randInt(
                sizes.PUZZLE + sizes.PADDING,
                sizes.WIDTH - (sizes.PUZZLE + sizes.PADDING)
            ),
            // Vertical offset
            top: randInt(
                sizes.PADDING,
                sizes.HEIGHT - (sizes.PUZZLE + sizes.PADDING)
            ),
        };

        // Select the element:
        const $svg = puzzlePieceSvg({
            rotate,
            distort,
            seed,
            strokeWidth,
            fill: "#fff",
            stroke: "#fff",
            opacity: "1",
        });

        // This function returns a Promise whenever the $img is loaded
        const loadImage = (url) => {
            let $img = new Image();
            $img.src = url;
            return new Promise((resolve, reject) => {
                $img.onload = () => resolve($img);
                $img.onerror = reject;
            });
        };

        const svgDOM = new DOMParser().parseFromString($svg, "text/xml");
        const img = await loadImage("data:image/svg+xml;base64," + btoa($svg));
        const svgElement = svgDOM.querySelector("svg");
        const svgWidth = svgElement.width.baseVal.value;
        const svgHeight = svgElement.height.baseVal.value;

        let $canvas = document.createElement("canvas");
        $canvas.width = sizes.WIDTH;
        $canvas.height = sizes.HEIGHT;
        $canvas.getContext("2d").clearRect(0, 0, sizes.WIDTH, sizes.HEIGHT);
        //$canvas.getContext('2d').clip(new Path2D(svgDOM.getElementsByTagName("path").item(0).getAttribute("d")));
        $canvas
            .getContext("2d")
            .drawImage(img, location.left, location.top, svgWidth, svgHeight);

        const mask = ImageJs.fromCanvas($canvas);

        $canvas.remove();
        $canvas = document.createElement("canvas");
        $canvas.width = sizes.WIDTH;
        $canvas.height = sizes.HEIGHT;
        $canvas.getContext("2d").clearRect(0, 0, sizes.WIDTH, sizes.HEIGHT);
        //$canvas.getContext('2d').clip(new Path2D(svgDOM.getElementsByTagName("path").item(0).getAttribute("d")));
        $canvas.getContext("2d").drawImage(
            await loadImage(
                "data:image/svg+xml;base64," +
                    btoa(
                        puzzlePieceSvg({
                            rotate,
                            distort,
                            seed,
                            strokeWidth,
                            fill: "#ff0",
                            stroke: "#ff0",
                            opacity: "1",
                        })
                    )
            ),
            location.left,
            location.top,
            svgWidth,
            svgHeight
        );

        const maskColored = ImageJs.fromCanvas($canvas);

        let puzzle = image.subtractImage(image.subtractImage(mask)).crop({
            x: location.left,
            y: 0,
            width: svgWidth,
            height: sizes.HEIGHT,
        });

        $canvas = $canvas.remove();
        $canvas = document.createElement("canvas");
        $canvas.width = puzzle.width;
        $canvas.height = puzzle.height;
        let ctx = $canvas.getContext("2d");
        ctx.clearRect(0, 0, puzzle.width, puzzle.height); // Clear the entire canvas first

        // Create a clip path
        const clipPath = new Path2D(
            svgDOM.getElementsByTagName("path").item(0).getAttribute("d")
        );

        const m = document
            .createElementNS("http://www.w3.org/2000/svg", "svg")
            .createSVGMatrix();
        const p = new Path2D();
        const r = m.translate(0, location.top, 0);
        const t = r.scale(4.58);
        p.addPath(clipPath, t);

        ctx.clip(p);

        ctx.drawImage(
            await loadImage(puzzle.toDataURL()),
            0,
            0,
            puzzle.width,
            puzzle.height
        );
        ctx.lineWidth = 5;
        ctx.strokeStyle = "yellow";
        ctx.stroke(p);

        puzzle = ImageJs.fromCanvas($canvas);

        return {
            bg: image.subtractImage(mask).add(maskColored).toDataURL(),
            puzzle: puzzle.toDataURL(),
            left: location.left,
        };
    } catch (err) {
        console.log(err);
        throw Error(err);
    }
};

export default sliderCaptchaImgCreate;
