import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
const SingleCardTable = () => {
    const [type, setType] = useState("buy");
    
    return (
        <div className="p-[32px] mb-20  rounded-[16px] mt-[24px]   bg-[#fff] shadow-lg  relative">
            <div className="flex items-center h-10 justify-start gap-[16px]  ">
                <button
                    onClick={() => setType("buy")}
                    className={`h-10 border-b-2  font-semibold  hover:border-yellow-500 ${
                        type === "buy"
                            ? "border-yellow-500 text-black"
                            : "border-transparent text-[#81858c]"
                    }`}
                >
                    Buy
                </button>
                <button
                    onClick={() => setType("sell")}
                    className={`h-10 border-b-2  font-semibold  hover:border-yellow-500 ${
                        type === "sell"
                            ? "border-yellow-500 text-black"
                            : "border-transparent text-[#81858c]"
                    }`}
                >
                    Sell
                </button>
                <button
                    onClick={() => setType("filled")}
                    className={`h-10 border-b-2  font-semibold  hover:border-yellow-500 ${
                        type === "filled"
                            ? "border-yellow-500 text-black"
                            : "border-transparent text-[#81858c]"
                    }`}
                >
                    Filled
                </button>
            </div>
            <div className="flex items-end justify-between mt-[16px]">
                <div className="flex items-end justify-start gap-[16px]">
                    <div className="flex flex-col h-[70px]">
                        <h1 className="text-[#81858c] text-[12px]">
                            Order Value range
                        </h1>
                        <div className="mt-[8px] flex items-center justify-start gap-[8px]">
                            <div className="w-[132px]">
                                <div className="px-[10px] bg-[#f3f5f7] border-[1px] border-[#f3f5f7] rounded-sm flex items-center h-[40px] overflow-hidden">
                                    <input
                                        type="text"
                                        className="bg-[#f3f5f7] w-full outline-none text-[12px]"
                                        placeholder="Min.Amount"
                                    />
                                </div>
                            </div>
                            <div className="text-[#81858c]">/</div>
                            <div className="w-[132px]">
                                <div className="px-[10px] bg-[#f3f5f7] border-[1px] border-[#f3f5f7] rounded-sm flex items-center h-[40px] overflow-hidden">
                                    <input
                                        type="text"
                                        className="bg-[#f3f5f7] w-full outline-none text-[12px]"
                                        placeholder="Max.Amount"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col h-[70px]">
                        <h1 className="text-[#81858c] text-[12px]">
                            Price Range
                        </h1>
                        <div className="mt-[8px] flex items-center justify-start gap-[8px]">
                            <div className="w-[132px]">
                                <div className="px-[10px] bg-[#f3f5f7] border-[1px] border-[#f3f5f7] rounded-sm flex items-center h-[40px] overflow-hidden">
                                    <input
                                        type="text"
                                        className="bg-[#f3f5f7] w-full outline-none text-[12px]"
                                        placeholder="Min.Amount"
                                    />
                                </div>
                            </div>
                            <div className="text-[#81858c]">/</div>
                            <div className="w-[132px]">
                                <div className="px-[10px] bg-[#f3f5f7] border-[1px] border-[#f3f5f7] rounded-sm flex items-center h-[40px] overflow-hidden">
                                    <input
                                        type="text"
                                        className="bg-[#f3f5f7] w-full outline-none text-[12px]"
                                        placeholder="Max.Amount"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="text-black font-semibold px-[16px] py-[7px] text-sm border-[1px] rounded-sm border-[#81858c]">
                        Reset
                    </button>
                    <button className="text-black rounded-sm font-semibold px-[16px] py-[8px] text-sm bg-[#f7a600]">
                        Search
                    </button>
                </div>
                <div></div>
            </div>
            <div className="mt-[24px]">
                <div>
                    <div>
                        <table className="w-full ">
                            <thead>
                                <tr>
                                    <th className="text-left text-[#81858c] text-[12px] p-[12px] bg-[#f9fafb] font-medium">
                                        User
                                    </th>
                                    <th className="text-left text-[#81858c] text-[12px] p-[12px] bg-[#f9fafb] font-medium">
                                        Price
                                    </th>
                                    <th className="text-left text-[#81858c] text-[12px] p-[12px] bg-[#f9fafb] font-medium">
                                        Qty.
                                    </th>
                                    <th className="text-left text-[#81858c] text-[12px] p-[12px] bg-[#f9fafb] font-medium">
                                        Order Value
                                    </th>
                                    <th className="text-left text-[#81858c] text-[12px] p-[12px] bg-[#f9fafb] font-medium">
                                        Collateral Value
                                    </th>
                                    <th className="text-left text-[#81858c] text-[12px] p-[12px] bg-[#f9fafb]">
                                        <div className="text-right text-[#81858c] text-[12px]  bg-[#f9fafb] font-medium">
                                            Action
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        800**03
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        0.098 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        5,000 HMSTR
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        490 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        490 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        <div className="text-right">
                                            <button className="text-xs bg-[#20b26c] px-[12px] py-[6px] rounded-sm text-white font-medium">
                                                Buy
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        800**03
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        0.098 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        5,000 HMSTR
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        490 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        490 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        <div className="text-right">
                                            <button className="text-xs bg-[#20b26c] px-[12px] py-[6px] rounded-sm text-white font-medium">
                                                Buy
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        800**03
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        0.098 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        5,000 HMSTR
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        490 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        490 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        <div className="text-right">
                                            <button className="text-xs bg-[#20b26c] px-[12px] py-[6px] rounded-sm text-white font-medium">
                                                Buy
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        800**03
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        0.098 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        5,000 HMSTR
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        490 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        490 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        <div className="text-right">
                                            <button className="text-xs bg-[#20b26c] px-[12px] py-[6px] rounded-sm text-white font-medium">
                                                Buy
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        800**03
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        0.098 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        5,000 HMSTR
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        490 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        490 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        <div className="text-right">
                                            <button className="text-xs bg-[#20b26c] px-[12px] py-[6px] rounded-sm text-white font-medium">
                                                Buy
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        800**03
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        0.098 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        5,000 HMSTR
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        490 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        490 USDT
                                    </td>
                                    <td className="p-[12px] text-[14px] font-medium border-b-[1px] border-[#f3f5f7]">
                                        <div className="text-right">
                                            <button className="text-xs bg-[#20b26c] px-[12px] py-[6px] rounded-sm text-white font-medium">
                                                Buy
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex mt-[16px] gap-[16px] justify-end">
                    <button className="py-[8px] px-[16px] rounded-sm border-[1px] border-[#d9d9d9]">
                        <FaArrowLeft size={14} />
                    </button>
                    <button className="py-[8px] px-[16px] rounded-sm border-[1px] border-[#d9d9d9] ">
                        <FaArrowRight size={14} />
                    </button>
                </div>
            </div>
        </div>
    );
};
export default SingleCardTable;