import React, { useState } from "react";
import {
    BsCheckCircleFill,
    BsEnvelopeFill,
    BsLockFill,
    BsEyeFill,
    BsEyeSlashFill,
} from "react-icons/bs";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { sendCodeToEmail, SetEmail } from "store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import {
    BsPhoneFill,
    BsXCircleFill,
    BsQuestionCircleFill,
    BsShieldFillExclamation,
} from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import EmailChangeModal from "./EmailChangeModal";
import EmailSetModal from "./EmailSetModal";

const BasicSettings = ({
    authStatus,
    userProfile,
    onPasswordChangeClick,
    onGoogleAuthenticatorChangeClick,
    onGoogleAuthenticatorSetClick,
    onMobileChangeClick,
    onMobileSetWhen2faActiveClick,
    onMobileSetWhen2faInActiveClick,
}) => {
    const email = authStatus?.email;
    const [showEmailMethodModal, setShowEmailMethodModal] = useState(false);
    const [isEmailVisible, setIsEmailVisible] = useState(false);
    const [showEmailChangeMethodModal, setShowEmailChangeMethodModal] =
        useState(false);
    const dispatch = useDispatch();
    const state = useSelector((state) => state.Newemail.email);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const stateS = useSelector((state) => state.userInfo.userProfile.email);
    const [showEmailConfirmModal, setShowEmailConfirmModal] = useState(false);
    const [iscellphoneVisible, setIsCellphoneVisible] = useState(false);

    const togglePhoneVisibility = () => {
        setIsCellphoneVisible(!iscellphoneVisible);
    };
    const handlePopupClick = () => {
        if (email) {
            // If email is set, show change modal
            setShowEmailChangeMethodModal(true);
            // const payload = {
            //     email: email,
            //     user_id: userID,
            // };
            // dispatch(sendCodeToEmail(payload));
        } else {
            // If email is not set, show set modal
            setShowEmailMethodModal(true);
        }
    };

    const handleEmailPopupClose = () => {
        setShowEmailMethodModal(false);
        setShowEmailChangeMethodModal(false); // Close the change modal too
    };
    const toggleEmailVisibility = () => {
        setIsEmailVisible(!isEmailVisible);
    };

    const maskedEmail = email ? `${email.slice(0, 3)}****@****.com` : "";
    const handleMobileButtonClick = () => {
        if (!authStatus.ga && !authStatus.cellphone) {
            onMobileSetWhen2faInActiveClick();
        } else {
            authStatus.cellphone
                ? onMobileChangeClick()
                : onMobileSetWhen2faActiveClick();
        }
    };

    return (
        <div>
            <div className="font-bold text-[18px] my-3">
                <span className="text-[24px] font-semibold uppercase">
                    User Security
                </span>
            </div>
            <div className="px-4 border py-2 rounded-xl">
                <div className="w-full flex items-start lg:items-center gap-3 bg-[#FFFFFF] border-b border-[#F4F4F4] rounded-md py-6">
                    <div className="bg-[#F4F4F4] rounded-full p-2">
                        <BsLockFill size={24} />
                    </div>
                    <div className="w-full flex-col lg:flex-row flex items-start gap-4 lg:gap-0 lg:items-center justify-between">
                        <div className="flex flex-col">
                            <div className="text-[18px] font-semibold w-max">
                                Login Password
                            </div>
                            <p className="text-[14px] text-[#15141590]">
                                User Account Login Password to access BuyCex
                                Crypto Exchange
                            </p>
                        </div>
                        <div className="flex justify-end lg:justify-normal w-full lg:w-auto">
                        <button
                            type="button"
                            className="font-bold sm:px-[15px] px-[8px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[50px] sm:min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                            onClick={onPasswordChangeClick}
                        >
                            Change
                        </button>
                        </div>
                    </div>
                </div>
                <div className="w-full flex items-start lg:items-center gap-3 bg-[#FFFFFF] border-b border-[#F4F4F4] py-6">
                    <div className="bg-[#F4F4F4] rounded-full p-2">
                        <BsEnvelopeFill size={24} />
                    </div>
                    <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 items-start lg:items-center justify-between w-full">
                        <div className="flex flex-col">
                            <div className="text-[18px] font-semibold w-max">
                                Email Authentication
                            </div>
                            <p className="text-[14px] text-[#15141590]">
                                For Login, withdraw, password retrieval,
                                security settings change
                            </p>
                        </div>
                        <div className="flex w-full lg:w-auto justify-between lg:justify-end">
                            <div className="flex items-center">
                                {email ? (
                                    <BsCheckCircleFill size={12} fill="#008000" />
                                ) : (
                                    <BsXCircleFill size={12} color="#808080" />
                                )}
                                <span className="text-[12px] flex items-center font-semibold text-[#15141580] ml-1 mr-6">
                                    {email
                                        ? isEmailVisible
                                            ? email
                                            : maskedEmail
                                        : "Not Set"}
                                    {email && (
                                        <button
                                            onClick={toggleEmailVisibility}
                                            className="text-[#151415] ml-2"
                                        >
                                            {isEmailVisible ? (
                                                <FiEye size={12} />
                                            ) : (
                                                <FiEyeOff size={12} />
                                            )}
                                        </button>
                                    )}
                                </span>
                            </div>
                            <button
                                onClick={handlePopupClick} // Handle popup click
                                className="font-bold sm:px-[15px] px-[8px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[50px] sm:min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                            >
                                {email ? "Change Email" : "Set"}
                            </button>
                        </div>
                    </div>
                </div>

                {showEmailMethodModal && (
                    <EmailSetModal
                        show={showEmailMethodModal}
                        onClose={handleEmailPopupClose}
                        email={authStatus.email}
                    />
                )}
                {showEmailChangeMethodModal && (
                    <EmailChangeModal
                        show={showEmailChangeMethodModal}
                        onClose={handleEmailPopupClose}
                        email={authStatus.email}
                    />
                )}
                <div>
                    <div className="bg-[#FFFFFF] rounded-md">
                        {/* Mobile Verification Section */}
                        <div className="flex py-6 justify-between border-b border-[#F4F4F4] items-start lg:items-center">
                            <div className="flex items-start lg:items-center gap-2 w-full">
                                <div className="bg-[#F4F4F4] rounded-full p-2">
                                    <BsPhoneFill size={24} />
                                </div>
                                <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 justify-between w-full">
                                    <div className="flex flex-col">
                                        <div className="font-semibold text-[18px]">
                                            SMS Authentication
                                        </div>
                                        <p className="text-[14px] text-[#15141590]">
                                            For login, password reset, and change of
                                            security settings
                                        </p>
                                    </div>

                                    <div className="flex items-center justify-between gap-10">
                                        <div className="flex items-center gap-1">
                                            {authStatus.cellphone ? (
                                                <BsCheckCircleFill
                                                    size={12}
                                                    fill="#008000"
                                                />
                                            ) : (
                                                <BsXCircleFill
                                                    size={12}
                                                    color="#808080"
                                                />
                                            )}
                                            <span className="text-[12px] font-medium text-[#15141590]">
                                                {iscellphoneVisible
                                                    ? authStatus.cellphone || "Not Configured" // Show phone number if visible
                                                    : "••••••••"} {/* Hidden placeholder when not visible */}
                                            </span>

                                            <button onClick={togglePhoneVisibility} className="text-[#151415] ml-2">
                                                {iscellphoneVisible ? (
                                                    <FiEye size={12} /> // Icon to hide (visible state)
                                                ) : (
                                                    <FiEyeOff size={12} /> // Icon to show (hidden state)
                                                )}
                                            </button>

                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                className="font-bold sm:px-[15px] px-[8px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[50px] sm:min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                                onClick={
                                                    authStatus.cellphone
                                                        ? handleMobileButtonClick
                                                        : onMobileChangeClick
                                                }
                                            >
                                                <span className="text-[12px]">
                                                    {authStatus.cellphone
                                                        ? "Change"
                                                        : "Set"}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex py-6 justify-between items-center border-[#F4F4F4] w-full">
                            <div className="flex items-start lg:items-center gap-2 w-full">
                                <div className="bg-[#F4F4F4] rounded-full p-2">
                                    <BsShieldFillExclamation size={24} />
                                </div>
                                <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 w-full">
                                    <div className="flex justify-between w-full items-center">
                                        <div className="flex flex-col">
                                            <div className="flex items-center gap-2">
                                                <div className="text-[18px] font-semibold">
                                                    Google Two Factor Authentication
                                                </div>
                                                <span
                                                    data-tooltip-id="g2fa-tooltip"
                                                    data-tooltip-content="Verify through Google Authenticator's onetime 6 digit verification code."
                                                    data-tooltip-place="right"
                                                >
                                                    <BsQuestionCircleFill
                                                        size={14}
                                                        fill="#15141590"
                                                    />
                                                </span>
                                                <Tooltip id="g2fa-tooltip" />
                                            </div>
                                            <p className="text-[14  px] text-[#15141590] ">
                                                For login, withdrawal, password reset,
                                                change of security settings
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between gap-10">
                                        <div className="flex items-center gap-1">
                                            {authStatus.ga ? (
                                                <BsCheckCircleFill
                                                    size={12}
                                                    fill="#008000"
                                                />
                                            ) : (
                                                <BsXCircleFill
                                                    size={12}
                                                    color="#808080"
                                                />
                                            )}
                                            <span className="text-[12px] font-medium text-[#15141590]">
                                                {authStatus.ga
                                                    ? "Enabled"
                                                    : "Not Configure"}
                                            </span>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                className="font-bold sm:px-[15px] px-[8px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[50px] sm:min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                                onClick={
                                                    authStatus.ga
                                                        ? onGoogleAuthenticatorChangeClick
                                                        : onGoogleAuthenticatorSetClick
                                                }
                                            >
                                                <span className="text-[12px]">
                                                    {authStatus.ga ? "Disable" : "Set"}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicSettings;
