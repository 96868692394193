import React, { useEffect, useRef, useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";

const faqData = [
  {
    question: "Will there be any transaction fees if I purchase crypto via Buycex's fiat service providers?",
    answer: "Most service providers charge transaction fees for crypto purchases. Please check the official website of the respective service provider for the actual fee.",
  },
  {
    question: "Will Buycex charge any transaction fee?",
    answer: "No, Buycex will not charge users any transaction fee.",
  },
  {
    question: "Why is the final price quote from the service provider different from the quote I saw on Buycex?",
    answer: "The prices quoted on Buycex are derived from prices provided by third-party service providers, and are for references only. It may differ from the final quote due to market movement or rounding error. Please refer to the respective service providers' official website for accurate quotes.",
  },
  {
    question: "Why is my final exchange rate different from the one I saw on the Buycex platform?",
    answer: "The figures stated on Buycex only serves to be indicative and are quoted based on the trader's last inquiry. It does not dynamically change based on the price movement of the cryptocurrency. For the final exchange rates and figures, please refer to our third party providers’ website.",
  },
  {
    question: "When will I receive the cryptocurrency I purchased?",
    answer: "The cryptocurrency is usually deposited into your Buycex account in 2 to 30 minutes after purchase. It may take longer, however, depending on blockchain network condition and service level of the respective service provider. For new users, it may take up to one day.",
  },
];

const Faq = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const faqRef = useRef(null);

    const handleToggle = (index) => {
        setOpenIndex(prevIndex => (prevIndex === index ? null : index));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (faqRef.current && !faqRef.current.contains(event.target)) {
                setOpenIndex(null);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <section className="relative mt-20 flex flex-col items-center justify-center w-full bg-transparent rounded-t-3xl bottom-10 gap-y-2">
            <h6 className="w-full ml-2 text-2xl text-black dark:text-white font-semibold">FAQ</h6>
            <div
                className="flex flex-col gap-y-5 md:gap-y-0 md:flex-row md:gap-x-5 items-center justify-center md:items-start md:justify-evenly xl:justify-center xl:gap-x-10 w-[100%] xl:w-[100%] mt-3"
                ref={faqRef}
            >
                {/* Left FAQs */}
                <div className="flex flex-col items-center justify-center w-[100%] md:w-[350px] lg:w-[650px] xl:w-[800px] gap-y-5">
                    {faqData.slice(0, 3).map((faq, index) => (
                        <div
                            key={index}
                            className={`${
                                openIndex === index ? "max-h-[500px]" : "max-h-[97px]"
                            } shadow-sm border flex flex-col bg-transparent dark:bg-[#1A191B] w-[100%] rounded-xl transition-all duration-500 p-4 overflow-hidden`}
                            style={{ transition: 'max-height 0.5s ease' }} // Smooth transition
                        >
                            <div className="flex items-center justify-between mt-1">
                                <div className="text-15px lg:text-[17px] flex items-center gap-x-4 w-full">
                                    <h6 className={`py-[5px] max-w-[88%] text-black dark:text-white font-semibold`}>
                                        {faq.question}
                                    </h6>
                                </div>
                                <div className="cursor-pointer bg-transparent" onClick={(e) => {
                                    e.stopPropagation();
                                    handleToggle(index);
                                }}>
                                    {openIndex === index ? (
                                        <FiMinus
                                            size={37}
                                            className="transition-all duration-700 p-[6px] bg-[#efb81c] rounded-md text-[#ffffff]"
                                        />
                                    ) : (
                                        <FiPlus
                                            size={37}
                                            className="transition-all duration-700 p-[6px] bg-[#f7f7ff]  dark:bg-[#1A191B]  rounded-md text-[#6f6c8f] dark:text-white"
                                        />
                                    )}
                                </div>
                            </div>
                            {openIndex === index && (
                                <div className="flex flex-col text-sm mt-[35px] text-black dark:text-white gap-y-5 lg:text-base">
                                    <p>{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* Right FAQs */}
                <div className="flex flex-col items-center justify-center w-[100%] md:w-[350px] lg:w-[650px] xl:w-[800px] gap-y-5">
                    {faqData.slice(3, 6).map((faq, index) => (
                        <div
                            key={index + 3} // Adjust index for the second column
                            className={`${
                                openIndex === index + 3 ? "max-h-[500px]" : "max-h-[97px]"
                            } shadow-sm border bg-transparent dark:bg-[#1A191B] flex flex-col w-[100%] rounded-xl transition-all duration-500 p-4 overflow-hidden`}
                            style={{ transition: 'max-height 0.5s ease' }} // Smooth transition
                        >
                            <div className="flex items-center justify-between mt-1">
                                <div className="text-15px lg:text-[17px] flex items-center gap-x-4 w-full">
                                    <h6 className={`py-[5px] max-w-[88%] text-black dark:text-white font-semibold`}>
                                        {faq.question}
                                    </h6>
                                </div>
                                <div className="cursor-pointer bg-transparent" onClick={(e) => {
                                    e.stopPropagation();
                                    handleToggle(index + 3);
                                }}>
                                    {openIndex === index + 3 ? (
                                        <FiMinus
                                            size={37}
                                            className="transition-all duration-700 p-[6px] bg-[#efb81c] rounded-md text-[#ffffff]"
                                        />
                                    ) : (
                                        <FiPlus
                                            size={37}
                                            className="transition-all duration-700 p-[6px] bg-[#f7f7ff]  dark:bg-[#1A191B]  rounded-md text-[#6f6c8f] dark:text-white"
                                        />
                                    )} 
                                </div>
                            </div>
                            {openIndex === index + 3 && (
                                <div className="flex flex-col text-black dark:text-white text-sm mt-[35px] gap-y-5 lg:text-base">
                                    <p>{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Faq;
