import React, { useEffect, useState } from 'react';
import EstimatedFiatBalace from './EstimatedFiatBalance';
import FiatBalanceTable from './FiatBalanceTable';
import { fetchFiatBalance } from 'store/actions/AssetsUserAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
function FiatBalanceAssets() {
  const [isVisible, setIsVisible] = useState(false); // State to track visibility
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.authUser?.user || {});
  const userId = user?.ID;
  const token = user?.TOKEN;
  // Function to toggle visibility
  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };

  useEffect(() => {
    dispatch(fetchFiatBalance(userId, token));
 
  }, [dispatch, userId, token,history]);
  const { data, loading, error } = useSelector((state) => state.fiatBalance);

  console.log("data",data);
  return (
    <div className='px-0 lg:px-3'>
      <div className='max-w-[1400px] mx-auto !p-0'>
        <EstimatedFiatBalace isVisible={isVisible} toggleVisibility={toggleVisibility} data={data} />
        <FiatBalanceTable isVisible={isVisible} data={data} />
      </div>
    </div>
  );
}

export default FiatBalanceAssets;
