const cryptoDepositHistoryInitialState = {
  data: [],
  loading: false,
  error: null,
};

export const cryptoDepositHistoryReducer = (state = cryptoDepositHistoryInitialState, action) => {
  switch (action.type) {
    case "FETCH_CRYPTO_DEPOSIT_HISTORY_SUCCESS":
      return { ...state, data: action.payload, loading: false, error: null };
    case "FETCH_CRYPTO_DEPOSIT_HISTORY_FAILURE":
      return { ...state, data: [], loading: false, error: action.payload };
    case "FETCH_P2P_DATA_LOADING":
      return { ...state, loading: action.payload };
   
    default:
      return state;
  }
};

export const cryptoWithdrawHistoryReducer = (state = cryptoDepositHistoryInitialState, action) => {
  switch (action.type) {
    case "FETCH_CRYPTO_WITHDRAW_HISTORY_SUCCESS":
      return { ...state, data: action.payload, loading: false, error: null };
    case "FETCH_CRYPTO_WITHDRAW_HISTORY_FAILURE":
      return { ...state, data: [], loading: false, error: action.payload };
    case "FETCH_P2P_DATA_LOADING":
      return { ...state, loading: action.payload };
   
    default:
      return state;
  }
};
export const transferHistoryReducer = (state = cryptoDepositHistoryInitialState, action) => {
  switch (action.type) {
    case "FETCH_TRANSFER_HISTORY_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_TRANSFER_HISTORY_SUCCESS":
      return { ...state, data: action.payload, loading: false, error: null };
    case "FETCH_TRANSFER_HISTORY_FAILURE":
      return { ...state, data: [], loading: false, error: action.payload };
    default:
      return state;
  }
};

export const convertTradeHistoryReducer = (state = cryptoDepositHistoryInitialState, action) => {
  switch (action.type) {
    case "FETCH_CONVERT_TRADE_HISTORY_REQUEST":
      return { ...state, loading: true, error: null };

    case "FETCH_CONVERT_TRADE_HISTORY_SUCCESS":
      return { ...state, data: action.payload, loading: false, error: null };

    case "FETCH_CONVERT_TRADE_HISTORY_FAILURE":
      return { ...state, data: [], loading: false, error: action.payload };

    default:
      return state;
  }
};