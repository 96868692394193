import React from 'react';
import { Link } from 'react-router-dom';
import { GrAnnounce } from "react-icons/gr";
import { IoMdArrowForward } from "react-icons/io";


const AnnouncementSection = () => (
  <div className="hidden md:flex py-1 bg-yellow-200 dark:bg-yellow-800 text-center justify-around items-center m-[-40px]">
    <GrAnnounce />
    <p className="text-sm lg:text-lg">2****4 Earned 21.49 USDT in Commissions.</p>
    <p className="text-sm lg:text-lg">7****4 Earned 21.56 USDT in Commissions.</p>
    <Link to="#" className="text-yellow-700 dark:text-yellow-500 underline inline-flex items-center">View Ranking<IoMdArrowForward /></Link>
  </div>
);

export default AnnouncementSection;
