import React, { useState } from 'react';

const ResetConfirmation = ({ nextStep }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="bg-white rounded-xl shadow-lg mb-20">
      <div className="w-5/6 mx-auto py-8">
        <h1 className="font-semibold">Reset Instructions</h1>
        <p className="text-xs text-[#B0B0B0] mt-1">
          Please verify your identity and submit the required documents before resetting your withdrawal password; we will review your application within 1-3 business days, and withdrawal services will be unavailable for 24 hours after verification.
        </p>
        <div className="mt-20 flex items-center justify-center gap-2">
          <input
            type="checkbox"
            className="text-yellow-500"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <p className="text-xs">I have carefully reviewed the above and agree to comply with all terms and conditions.</p>
        </div>

        <div className="mt-8 text-center">
          <button
            onClick={isChecked ? nextStep : null}
            className={`bg-${isChecked ? 'yellow-500 text-white' : '[#E6E6E6]'} font-semibold py-2 px-20 rounded-xl`}
            disabled={!isChecked}
          >
            Confirm Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetConfirmation;