import React from 'react';
import FaQs from 'components/FAQs';
import { ForwardIconSvg, ConnectFriendsSvg, GetMultipleRewardsSvg, ShareCodeSVG, DownwardIconSvg } from 'components/ReferralpageComponents/ReferralSVGs'; 
import CryptoCoin from 'assets/img/crypto-png.png';
import AnnouncementSection from 'components/ReferralpageComponents/AnnouncementSection';
import RewardCard from 'components/ReferralpageComponents/RewardCard';
import InviteStep from 'components/ReferralpageComponents/InviteStep';



const ReferralPage = () => {
  return (
    <div className="min-h-screen text-neutral-900 dark:text-gray-100">
        <div className="flex flex-col justify-around py-8 h-screen">
            <div className='flex md:flex-row lg:gap-64'>
                <div className='  flex flex-col justify-center'>
                    <h1 className=" text-yellow-500 text-4xl font-bold">Invite Friends to Earn Over<br /> 1710 USDT and 30% Commission</h1>
                    <p className=" mt-2 text-lg">Earn rewards together with your friends via Spot, Derivatives, Copy Trading <br /> and BuyCex Card</p>
                    <button className="mt-4 px-4 py-2 bg-yellow-500 w-48 font-medium rounded-md">Invite Friends</button>
                </div>
                <img src={CryptoCoin} alt='crypto_coin'  className='hidden md:block h-80 w-80'/>
            </div>
            <AnnouncementSection />
        </div>

        <section className="h-screen">
            <h2 className="text-3xl font-bold text-left">One Invitation for Multiple Rewards</h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8 h-2/3">
                <RewardCard
                amount="1025 + 30%"
                description="From inviting new friends to BuyCex"
                />
                <RewardCard
                amount="665"
                description="From Copy Trading"
                linkText="Learn More"
                />
                <RewardCard
                amount="20"
                description="For Every BuyCex Card Referral"
                linkText="Learn More"
                />
            </div>
        </section>

        <section className="lg:h-screen py-12">
            <h2 className="text-4xl font-bold text-left">How to Invite</h2>
            <div className="flex flex-col md:flex-row justify-between items-center mt-12 py-4">
            <InviteStep
                icon={<ShareCodeSVG />}
                title="Share Your Code and Link"
                description="You can invite your friends to use all Buycex products with just one referral code."
            />
            <ForwardIconSvg />
            <DownwardIconSvg />
            <InviteStep
                icon={<ConnectFriendsSvg />}
                title="Connect with Your Friends"
                description="Your friends will be associated with you after they sign up."
            />
            <ForwardIconSvg />
            <DownwardIconSvg />
            <InviteStep
                icon={<GetMultipleRewardsSvg />}
                title="Get Multiple Rewards"
                description="Automatically get Trading Commissions, Buycex Card Rewards and Copy Trading Rewards when your friends trade, apply for a Buycex Card or start copy trading."
            />
            </div>
        </section>

      <FaQs />
    </div>
  );
};

export default ReferralPage;
