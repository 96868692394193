import React, { useState } from "react";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";

const items = [
    { label: "Health", key: "Health", icon: <UserOutlined /> },
    { label: "Electronics", key: "Electronics", icon: <UserOutlined /> },
    { label: "Cars", key: "Cars", icon: <UserOutlined /> },
    {
        label: "Furniture",
        key: "Furniture",
        icon: <UserOutlined />,
        disabled: true,
    },
    { label: "Reset", key: "ResetCategory", icon: <UserOutlined /> }, // Reset option for categories
];

const priceItems = [
    // Reset option for price
    { label: "Below $200", key: "Below $200", icon: <UserOutlined /> },
    { label: "Below $500", key: "Below $500", icon: <UserOutlined /> },
    { label: "Below $800", key: "Below $800", icon: <UserOutlined /> },
    {
        label: "Below $1000",
        key: "Below $1000",
        icon: <UserOutlined />,
        disabled: true,
    },
    {
        label: "Above $1000",
        key: "Above $1000",
        icon: <UserOutlined />,
        disabled: true,
    },
    { label: "Reset", key: "ResetPrice", icon: <UserOutlined /> },
];

const SideCategories = ({
    search,
    categories,
    price,
    onSearch,
    onCategoryChange,
    onPriceChange,
}) => {
    const [selectedPrice, setSelectedPrice] = useState("Price");
    const [selectedCategory, setSelectedCategory] = useState("Categories");

    const handlePriceChange = (e) => {
        if (e.key === "ResetPrice") {
            setSelectedPrice("Price"); // Reset to default label
            onPriceChange(null); // Clear price selection in parent
        } else {
            setSelectedPrice(e.key); // Update with selected price
            onPriceChange(e.key);
        }
    };

    const handleCategoryChange = (e) => {
        if (e.key === "ResetCategory") {
            setSelectedCategory("Categories"); // Reset to default label
            onCategoryChange(null); // Clear category selection in parent
        } else {
            setSelectedCategory(e.key); // Update with selected category
            onCategoryChange(e.key);
        }
    };

    return (
        <Space wrap>
            {search && (
                <Button className="!w-[190px]">
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => onSearch(e.target.value)}
                        className="outline-none border-none !w-[190px]"
                    />
                </Button>
            )}
            {categories && (
                <Dropdown menu={{ items, onClick: handleCategoryChange }}>
                    <Button className="!w-[190px]">
                        <Space className="!w-full flex justify-between font-semibold">
                            {selectedCategory}
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            )}
            {price && (
                <Dropdown
                    menu={{
                        items: priceItems,
                        onClick: handlePriceChange,
                    }}
                >
                    <Button className="!w-[190px]">
                        <Space className="!w-full flex justify-between font-semibold">
                            {selectedPrice}
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            )}
        </Space>
    );
};

export default SideCategories;
