import React, { useState, useEffect, useRef } from 'react';
import P2pMessage from './P2pMessage';
import P2pChatInput from './P2pChatInput';
import { IoPersonCircle } from 'react-icons/io5';
import { MdVerified } from 'react-icons/md';
import { GoDotFill } from 'react-icons/go';
import { BsThreeDots } from 'react-icons/bs';
import { IoMdAlert } from 'react-icons/io';
import { Modal } from 'antd'
import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';

function P2pChatBox({ toggleChatBox }) {
    const [messages, setMessages] = useState([]);
    const [chatStartTime, setChatStartTime] = useState('');
    const [showAlert, setShowAlert] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const chatContainerRef = useRef(null); // Ref for chat container

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        const startTime = new Date();
        const formattedStartTime = `${startTime.toLocaleDateString()} ${startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
        setChatStartTime(formattedStartTime);
    }, []);

    const handleSendMessage = (message) => {
        const timestamp = new Date();
        const formattedTimestamp = `${timestamp.toLocaleDateString()} ${timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;

        if (message.type === 'text') {
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: message.content, sender: 'user', time: formattedTimestamp },
                { text: 'Thank you for your contact!', sender: 'system', icon: <IoPersonCircle className="text-xl text-gray-600" />, time: formattedTimestamp },
            ]);
        } else if (message.type === 'file') {
            const fileUrl = URL.createObjectURL(message.content); // Convert file to URL
            setMessages((prevMessages) => [
                ...prevMessages,
                { file: fileUrl, fileName: message.content.name, sender: 'user', time: formattedTimestamp },
                { text: 'Thank you for your contact!', sender: 'system', icon: <IoPersonCircle className="text-xl text-gray-600" />, time: formattedTimestamp },
            ]);
        }
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    // Auto-scroll to the bottom when new messages arrive
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className="flex flex-col h-full my-0 md:my-0 lg:my-4 bg-white rounded-none sm:rounded-2xl md:rounded-2xl lg:rounded-2xl border-[3px] border-gray-200">
            <div className='p-3 flex items-center border-b'>
                <div className='flex w-full items-start gap-1'>
                    <IoPersonCircle className='text-3xl' />
                    <div className='flex flex-col'>
                        <div className='flex !items-center gap-2'><p className='text-sm md:text-[16px]'>Buycex User</p><MdVerified className='text-[#EFB81C]' /></div>
                        <p className='text-[10px] md:text-[12px] flex !items-center py-1'><GoDotFill className='text-[10px] text-[#2ECC71]' />Online</p>
                    </div>
                </div>
                <div className='flex gap-2 items-center'>
                    <div><BsThreeDots /></div>
                    <button className='block sm:hidden lg:hidden md:hidden' onClick={toggleChatBox}>
                        <IoClose className="text-2xl font-bold" />
                    </button>
                </div>
            </div>
            {showAlert && (
                <div className="flex items-center p-4 mb-4 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
                    <IoMdAlert className='text-2xl' />
                    <span className="sr-only">Info</span>
                    <div className="ms-3 text-sm font-medium">
                        <div>
                            <p className='text-sm'>
                                Please read the seller's
                                <a
                                    href="#s"
                                    className="font-semibold underline text-sm hover:no-underline ml-1"
                                    onClick={(e) => {
                                        e.preventDefault(); // Prevents the default anchor behavior
                                        showModal(); // Show modal on click
                                    }}
                                >
                                    terms and conditions
                                </a>. carefully.Before pressing the "Paid" button ......
                            </p>

                            <div style={{ zIndex: '9999', position: 'relative' }}>
                                {/* Modal Popup */}
                                <StyledModal
                                    title=""
                                    visible={isModalVisible}
                                    onCancel={handleCancel}
                                    footer={null}
                                    getContainer={false}  // This will render the modal in the current DOM node, not in a portal
                                >
                                    <p className="font-bold text-lg text-center" style={{ zIndex: '9999' }}>Notice</p>
                                    <div className="text-center p-3">
                                        <p>Please read the seller's terms and conditions carefully. Before pressing the "Paid" button, ensure that you transfer the amount to the seller's account through your banking application. You can obtain the seller's bank account details from the payment information in the order details.</p>
                                        <p>Do not release your crypto until you have received the payment from the buyer in your bank account.</p>
                                        <p>If the buyer asks you to release the crypto before making the payment, immediately report it to Buycex via this email: (p2p@buycex.com).</p>
                                    </div>
                                    <button
                                        className="bg-[#EFB81C] w-full text-center mt-4 font-semibold text-md px-3 py-2 h-10 rounded-md"
                                        onClick={handleCancel}  // Close modal on "Ok" button click
                                    >
                                        Ok
                                    </button>
                                </StyledModal>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        onClick={handleCloseAlert}
                        className="ms-auto -mx-1.5 -my-1.5 bg-yellow-50 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 hover:bg-yellow-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-yellow-300 dark:hover:bg-gray-700"
                        aria-label="Close"
                    >
                        <span className="sr-only">Close</span>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                    </button>
                </div>
            )}
            {/* Display Chat Start Time */}
            <div className="p-2 text-center text-[12px] text-gray-500">
                {chatStartTime}
            </div>
            <div className="flex-1 overflow-y-auto p-4" ref={chatContainerRef}> {/* Ref for chat container */}
                {messages.map((msg, index) => (
                    <P2pMessage key={index} message={msg} />
                ))}
            </div>
            <div className="p-2 border-t border-gray-200">
                <P2pChatInput onSendMessage={handleSendMessage} />
            </div>
        </div>
    );
}

const StyledModal = styled(Modal)`
  z-index: 9999 !important;
  
  .ant-modal-content {
    z-index: 9999 !important;
  }
  
  .ant-modal-mask {
    z-index: 9998 !important;  /* Slightly lower than modal itself */
  }
`;
export default P2pChatBox;
