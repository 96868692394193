// src/store/reducers/userReducer.js
import {
    FETCH_LOGIN_STATUS_REQUEST,
    FETCH_LOGIN_STATUS_SUCCESS,
    FETCH_LOGIN_STATUS_FAILURE,
    FETCH_LOGIN_HISTORY_REQUEST,
    FETCH_LOGIN_HISTORY_SUCCESS,
    FETCH_LOGIN_HISTORY_FAILURE,
    FETCH_KYC_STATUS_REQUEST,
    FETCH_KYC_STATUS_SUCCESS,
    FETCH_KYC_STATUS_FAILURE
} from '../actions/AccountuserActions';

const initialState = {
    loginHistory: [],
    kycStatus: [],
    loading: false,
    error: null
};

const userReducer = (state = initialState, action) => {
 
    switch (action.type) {
        case FETCH_LOGIN_HISTORY_REQUEST:
          
            return { ...state, loading: true, error: null };
        case FETCH_KYC_STATUS_REQUEST:
          
            return { ...state, loading: true, error: null };
        case FETCH_LOGIN_HISTORY_SUCCESS:
       
            return { ...state, loading: false, loginHistory: action.payload };
        case  FETCH_KYC_STATUS_SUCCESS:
            console.log(' FETCH_KYC_STATUS_SUCCESS received', action.payload);
            return { ...state, loading: false, kycStatus: action.payload };
        case FETCH_LOGIN_HISTORY_FAILURE:
           
            return { ...state, loading: false, error: action.error };
        case FETCH_KYC_STATUS_FAILURE:
           
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
};

export default userReducer;
