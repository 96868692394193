import React, { useEffect } from "react";

const SpotOrderHistoryTable = ({ list, loading, error }) => {
  useEffect(() => {
    console.log("list Data:", list);
  }, [list]);

   // Function to calculate average price from max and min prices
   const calculateAveragePrice = (maxPrice, minPrice) => {
    const max = parseFloat(maxPrice);
    const min = parseFloat(minPrice);
    
    // Return the average if both values are valid, otherwise return "N/A"
    if (!isNaN(max) && !isNaN(min)) {
      return ((max + min) / 2).toFixed(2);
    }
    return "N/A";
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg text-red-500">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-2xl w-full min-h-[500px] mt-3">
      <div className="flex flex-col items-center px-3 w-full min-h-[500px] pt-3">
        <table className="w-full text-left">
          <thead className="text-gray-400">
            <tr className="pb-3 border-b border-gray-200">
              <th className="px-4 pt-2 pb-3 font-medium">Time</th>
              <th className="px-4 py-2 pb-3 font-medium">Market</th>
              <th className="px-4 py-2 pb-3 font-medium">Type</th>
              <th className="px-4 py-2 pb-3 font-medium">Side</th>
              <th className="px-4 py-2 pb-3 font-medium">Order Price</th>
              <th className="px-4 py-2 pb-3 font-medium">Amount</th>
              <th className="px-4 py-2 pb-3 font-medium">Avg Price</th>
              <th className="px-4 py-2 pb-3 font-medium">Executed</th>
              <th className="px-4 py-2 pb-3 font-medium">Executed Status</th>
              <th className="px-4 py-2 pb-3 font-medium">Operation</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(list).length > 0 ? (
              Object.entries(list).map(([key, value], index) => (
                <tr key={index}>
                  <td className="px-4 py-2">{value.addtime}</td>
                  <td className="px-4 py-2 font-semibold capitalize">{value.market}</td>
                  <td className="px-4 py-2">{value.type
                  }</td>
                  <td className="px-4 py-2">1</td>
                  <td className="px-4 py-2">{value.price}</td>
                  <td className="px-4 py-2">{value.num}</td>
                  <td className="px-4 py-2">
                  {calculateAveragePrice(value.fee_sell, value.fee_buy)}
                  </td>
                  <td className="px-4 py-2">1</td>
                  <td className="px-4 py-2">{value.status}</td>
                  <td className="px-4 py-2">1</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="py-10 text-center text-gray-400">
                  <div className="text-center">
                    <svg
                      className="mx-auto mb-4"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      width="48"
                      height="48"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                    <p>No data available.</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SpotOrderHistoryTable;
