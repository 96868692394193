import React from 'react'
import Faq from 'react-faq-component';
import { FaArrowRight } from "react-icons/fa";

const FaqsSection = () => {
    
const data = {
    title: "FAQ",
    rows: [
      {
        title: "1. What is Pre-Market Trading?",
        content: "Pre-Market Trading is an over-the-counter (OTC) trading platform specifically designed for trading new tokens before they are officially listed, it allows buyers and sellers to set up quotes and match trades, ensuring in advance that they are transacted at the desired price, if you need to trade pre-market, please make sure that you have sufficient funds prior to the trade and that you are able to complete the delivery within the stipulated period of time, failure to fulfill the trading obligations in a timely manner will result in the loss of your pledged collateral. "
      },
      {
        title: "2. How do I complete the delivery?",
        content: "As long as you have enough of the tokens in your Unified Trading Account, you can complete delivery for your pre-market trades. Here are two (2) ways you can do this: <br/> 1. Deposit Enough Tokens From an External Wallet <br/> Make sure your tokens are of the correct token address and are deposited to the correct wallet address. Transfer enough tokens to your Unified Trading Account by the end of the delivery phase. This method is recommended for most users, and we advise selling only the amount you currently possess or will receive from an airdrop to limit risks. <br/> 2. Purchase Tokens in the Spot Market <br/> Alternatively, you may also purchase the tokens required for delivery through the spot market after the trading pair is listed, and ensure that your Unified Trading Account has enough of the required tokens by the end of the delivery phase. However, please be aware of the risks when choosing to rely on token delivery using this method, as the cost of purchasing tokens in the spot market may exceed your proceeds from your pre-market sale. <br/> Note: Only tokens in your Unified Trading Account will be used for delivery. Tokens stored in other accounts, as well as tokens purchased in other pre-market trades will not be usable for delivery. Failure to deliver the tokens in full and on time will result in the loss of all collateral placed for the corresponding pre-market order."
      },
      {
        title: "3. What if I am unable to complete the transaction within the specified time?",
        content: "Failure to fulfill your obligation to deliver tokens in full and on time will result in the loss of all your pledged collateral, so please ensure that you meet the trading conditions on time."
      },
      {
        title: "4. What if there is a delay in the token listing schedule?",
        content: "If the listing of tokens is delayed, the delivery phase for pre-market trades will also be delayed.However, matched orders will remain valid, and a new delivery phase will be announced separately by Bybit."
      },
      {
        title: "5. Does Pre-Market Trading affect the initial listing price of tokens on Bybit?",
        content: "While pre-market trading may reflect market expectations, the official listing price itself may be influenced by other different factors.It is worth noting that both the pre-market price and the official listing price are ultimately determined by the market and there is not necessarily a direct correlation between the two."
      },
    ]
  }
  
  return (
    <div className='mt-20 mb-10 '>
        <Faq data={data} styles={{
        titleTextColor: "black",
        rowTitleColor: "black",
         rowContentColor: "#81858c",
         bgColor:"white",
         arrowColor:"black"
        
    }} 
/>
     <button className='text-yellow-500 flex items-center gap-2 mt-4 text-lg'>More <FaArrowRight className=''  size={12}/></button>
    </div>
  )
}

export default FaqsSection