import React, { useState } from 'react';
import CrossMargin from './CrossMargin/CrossMargin';
import IsolatedMargin from './IsolatedMargin/IsolatedMargin';
import FundTable from './CrossMargin/FundTable';
import PositionTable from './CrossMargin/PositionTable';
import IsolatedMarginFundTable from './IsolatedMargin/FundTable';
import IsolatedMarginPositionTable from './IsolatedMargin/PositionTable';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";
import ConvertModal from 'views/crypto/ConvertModal';
import TransferModal from 'views/trade/PostAds/TransferModal';

const MarginAssets = () => {
    const [activeItem, setActiveItem] = useState('Cross Margin');
    const [activeSubItem, setActiveSubItem] = useState('Funds');
    const [searchTerm, setSearchTerm] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
    
    const handleTransferClick = () => {
        setIsTransferModalVisible(true);
    };

    const handleTransferCancel = () => {
        setIsTransferModalVisible(false);
    };

    const toggleVisibility = () => {
        setIsVisible(prev => !prev);
    };

    const discoverNavItems = ['Cross Margin', 'Isolated Margin'];

    const renderContent = () => {
        switch (activeItem) {
            case 'Cross Margin':
                return <CrossMargin isVisible={isVisible} toggleVisibility={toggleVisibility} />;
            case 'Isolated Margin':
                return <IsolatedMargin isVisible={isVisible} toggleVisibility={toggleVisibility} />;
            default:
                return <CrossMargin />;
        }
    };

    const renderSubComponent = () => {
        const filterProps = { searchTerm }; // Pass the search term as a prop for filtering

        if (activeItem === 'Cross Margin') {
            switch (activeSubItem) {
                case 'Funds':
                    return <FundTable isVisible={isVisible} toggleVisibility={toggleVisibility} {...filterProps} />;
                case 'Positions':
                    return <PositionTable searchTerm={searchTerm} isVisible={isVisible} toggleVisibility={toggleVisibility} />;
                default:
                    return null;
            }
        } else if (activeItem === 'Isolated Margin') {
            switch (activeSubItem) {
                case 'Funds':
                    return <IsolatedMarginFundTable searchTerm={searchTerm} isVisible={isVisible} toggleVisibility={toggleVisibility}  {...filterProps} />;
                case 'Positions':
                    return <IsolatedMarginPositionTable searchTerm={searchTerm} isVisible={isVisible} toggleVisibility={toggleVisibility} {...filterProps} />;
                default:
                    return null;
            }
        }
        return null;
    };

    const renderSubNavItems = () => {
        const subNavItems = {
            'Cross Margin': ['Funds', 'Positions'],
            'Isolated Margin': ['Funds', 'Positions']
        };

        return (
            <div className="flex gap-8">
                {subNavItems[activeItem]?.map((subItem) => (
                    <button
                        key={subItem}
                        onClick={() => setActiveSubItem(subItem)}
                        className={`relative py-1 text-md font-medium ${activeSubItem === subItem ? 'text-black' : 'text-gray-400'}`}
                    >
                        {subItem}
                        {activeSubItem === subItem && (
                            <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                        )}
                    </button>
                ))}
            </div>
        );
    };

    const handleMainNavClick = (item) => {
        setActiveItem(item);
        setActiveSubItem('Funds'); // Always show 'Fund Table' by default when switching main items
    };

    const handleCheckboxClick = () => {
        setIsChecked((prev) => !prev); // Toggle the checkbox state
    };

    return (
        <div className='px-0 md:px-3'>
            <div className='mt-0 md:mt-5 max-w-[1400px] mx-auto !p-0'>
                <div className='flex w-full items-center justify-between'>
                    <nav className='flex gap-8 w-[60%] border-gray-200 pt-2'>
                        {discoverNavItems.map((item) => (
                            <button
                                key={item}
                                onClick={() => handleMainNavClick(item)}
                                className={`relative py-2 text-ellipsis whitespace-nowrap text-md font-semibold ${activeItem === item ? '' : 'text-gray-400'}`}
                            >
                                {item}
                                {activeItem === item && (
                                    <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                                )}
                            </button>
                        ))}
                    </nav>
                    <div className='hidden md:flex gap-2'>
                        <button className='bg-[#EFB81C] py-1 self-start font-semibold text-sm rounded-md px-4'>Borrow</button>
                        <button className='bg-[#EAECEF] py-1 self-start font-semibold text-sm rounded-md px-4'>Repay</button>
                        <button className='bg-[#EAECEF] py-1 self-start font-semibold text-sm rounded-md px-4' onClick={handleTransferClick}>Transfer</button>
                        <TransferModal visible={isTransferModalVisible} onCancel={handleTransferCancel} />
                    </div>
                </div>

                <div className="mt-4">
                    {renderContent()}
                    <div className='flex mt-6 md:hidden gap-2'>
                        <button className='bg-[#EFB81C] py-1 self-start font-semibold text-sm rounded-md px-4'>Borrow</button>
                        <button className='bg-[#EAECEF] py-1 self-start font-semibold text-sm rounded-md px-4'>Repay</button>
                        <button className='bg-[#EAECEF] py-1 self-start font-semibold text-sm rounded-md px-4' onClick={handleTransferClick}>Transfer</button>
                        <TransferModal visible={isTransferModalVisible} onCancel={handleTransferCancel} />
                    </div>
                    <div className='w-full mt-6 p-6 rounded-2xl border'>
                        <div className='flex w-full flex-col-reverse flex-wrap md:flex-row-reverse items-start md:items-center justify-between'>
                            <div className='flex gap-3 mt-3 md:mt-0 items-center flex-row-reverse'>
                                <input
                                    type="text"
                                    placeholder="Search coins"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="p-2 h-6 bg-gray-100 py-1 border rounded-md"
                                />
                                {/* Checkbox icon */}
                                <div className='hidden md:flex gap-1 items-center'>
                                    <div onClick={handleCheckboxClick} className="cursor-pointer">
                                        {isChecked ? <MdOutlineCheckBox /> : <MdCheckBoxOutlineBlank />}
                                    </div>
                                    <p className='text-sm font-semibold'>Hide low positions</p>
                                </div>
                                <div className='hidden md:flex gap-1 items-center'>
                                    <div onClick={handleCheckboxClick} className="cursor-pointer">
                                        {isChecked ? <MdOutlineCheckBox /> : <MdCheckBoxOutlineBlank />}
                                    </div>
                                    <p className='text-sm font-semibold'>Only Show debts</p>
                                </div>
                            </div>
                            <div>{renderSubNavItems()}</div>
                        </div>
                        {renderSubComponent()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarginAssets;
