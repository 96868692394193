import React from 'react'
import { TbCaretUpDownFilled } from "react-icons/tb";
import NoData from "assets/img/user/No-data.svg";

function DOTSlotAuction() {
    return (
        <div className='flex flex-col'>
            <div className='flex w-full my-6'>
                <div className='flex items-center gap-1 w-1/6'>
                    <p className='text-xs text-[#929AA5]'>Coins</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-start gap-1 w-1/6'>
                    <p className='text-xs text-[#929AA5]'>Project</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-start gap-1 w-1/6'>
                    <p className='text-xs text-[#929AA5]'>Total Votes</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/3'>
                    <p className='text-xs text-[#929AA5]'>Cumulative Warm-Up Bonus Rewards</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-center gap-1 w-1/3'>
                    <p className='text-xs text-[#929AA5]'>Cumulative Lease Lock-up Rewards</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Rewards End Date</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Redemption Date</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Status</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
            </div>

            <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                <img src={NoData} />
                <p className='text-[#707a8a] text-sm'>No records</p>
            </div>
        </div>
    )
}

export default DOTSlotAuction