import React, { useState } from "react";
import { Modal } from "antd";
import { FaRotate } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";

const DepositModel = ({ showDepositModel }) => {
    const [isModalOpen, setIsModalOpen] = useState(true);

    const handleOk = () => {
        setIsModalOpen(false);
        showDepositModel(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        showDepositModel(false);
    };
    return (
        <div>
            <div className="model">
                <Modal
                    title="Deposit"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    className="convert-model"
                >
                    <div className="content">
                        <div className="mt-10">
                            <div className="text-gray-400">Coin</div>
                            <div className="mt-1">
                                <div className=" h-12 flex items-center justify-start font-semibold text-sm bg-gray-100 rounded-xl px-2 dark:bg-opacity-10  dark:text-[#ffffff]">
                                    <select
                                        name=""
                                        id=""
                                        className="w-full bg-transparent border-none"
                                    >
                                        <option value="">USDT</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-gray-400">Chain type</div>
                            <div className="mt-1">
                                <div className=" h-12 flex items-center justify-start font-semibold text-sm bg-gray-100 rounded-xl px-2 dark:bg-opacity-10 dark:text-[#ffffff]">
                                    <select
                                        name=""
                                        id=""
                                        className="w-full bg-transparent border-none"
                                    >
                                        <option value="">USDT</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 mb-6 text-center flex items-center justify-center text-sm font-bold bg-yellow-500 rounded-xl h-12">
                            Confirm
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default DepositModel;
