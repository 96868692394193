import React from 'react'
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SubmitSuccessfully = () => {
  return (
    <div className="bg-white rounded-xl shadow-lg mb-20  py-10 text-sm">
      <div className="w-[60%] mx-auto flex gap-4">
      <FaCheckCircle className="text-5xl text-yellow-500" />
        <div>
          <h1 className="font-semibold">Your Application has been submitted</h1>
          <p className="mt-1 text-[#9C9C9C] text-xs">An email notification will be sent once the review is complete, which typically takes 1-3 business days. Withdrawal services will be unavailable for the next 24 hours after changing the password.</p>
          <div className="mt-20 text-center">
            <Link to="/user/account/security" className="font-semibold py-2 px-16 bg-yellow-500 rounded-xl hover:text-black">Back to Settings</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubmitSuccessfully
