// src/api/apiService.js
import axiosObj from "./Repository";

// Function to fetch login status
export const fetchLoginStatusFromAPI = async (userID, userToken) => {
    try {
        const response = await axiosObj.get("User/log", {
            headers: {
                TOKEN: userToken,
                ID: userID,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching login status:", error);
        throw error;
    }
};

// Function to fetch login history
export const fetchLoginHistoryFromAPI = async (userID, userToken) => {
    try {
        const response = await axiosObj.get("User/log", {
            headers: {
                TOKEN: userToken,
                ID: userID,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching login history:", error);
        throw error;
    }
};

// Function to fetch KYC STATUS
export const fetchKycStatusFromAPI = async (userID, usertoken) => {
    try {
        const response = await axiosObj.get("User/checkkycstatus", {
            headers: {
                TOKEN: usertoken,
                ID: userID,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching kyc Status:", error);
        throw error;
    }
};
