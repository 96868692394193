import React from 'react';
import { Modal, Button } from 'antd'; // Ensure you're importing from 'antd' or your modal library
import { FaAngleRight } from 'react-icons/fa';

const P2pStepCancel = ({ visible, onClose }) => {
    const stepsData = [
        { number: 1, title: 'Prepare Proof of Payment', description: 'Take a screenshot or video of the payment you made', isLast: false },
        { number: 2, title: 'Request Crypto Transfer', description: 'Tap the button below to provide the Buycex ID in chat and request the seller to transfer the purchased crypto via Buycex Pay.', isLast: false, buttonText: 'Request Crypto Transfer' },
        { number: 3, title: 'Place A New Order', description: "If seller does not respond, place a new order with seller for the amount you paid. After creating the order, tap 'Transferred, Notify Seller' directly.", isLast: false, buttonText: 'Place Another Order' },
        { number: 4, title: 'Raise an Appeal If Not Resolved', description: 'File a dispute or contact CS if you are unable to resolve the issue with the seller.', isLast: true, buttonText: 'Apeal' }
    ];

    return (
        <Modal
            title="Order Cancellation Steps"
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="close" onClick={onClose}>
                    Close
                </Button>
            ]}
        >
            {/* Label text below modal title */}
            <p className='pb-4 text-gray-600'>Follow these steps to resolve the issue:</p>

            {/* Map through the steps data and render each step */}
            {stepsData.map((step, index) => (
                <div key={index} className='flex'>
                    {/* Step number with a rotated square */}
                    <div className="flex flex-col items-center">
                        <div className="relative top-[-2px] w-4 h-4 flex justify-center items-center">
                            <div
                                className="absolute top-0 left-0 w-full h-full bg-black"
                                style={{
                                    transform: 'rotate(45deg)',
                                    zIndex: 0
                                }}
                            />
                            <span className="text-white relative text-sm font-semibold z-10">
                                {step.number}
                            </span>
                        </div>
                        {/* Vertical line for steps if not the last step */}
                        {!step.isLast && <div className="flex-grow border-l-2 border-black"></div>}
                    </div>

                    {/* Step content */}
                    <div className='ml-4 pb-3 w-full'>
                        <h2 className="text-sm pb-1 font-semibold">{step.title}</h2>
                        <p className=' pb-3 text-[#707A8A] text-sm'>{step.description}</p>
                        {/* Conditionally render button if buttonText is provided */}
                        {step.buttonText && (
                            <button className="my-2 w-52 font-medium text-sm px-4 py-1.5 bg-[#EFB81C] text-black rounded-lg">
                                {step.buttonText}
                            </button>
                        )}
                    </div>
                </div>
            ))}
        </Modal>
    );
};

export default P2pStepCancel;
