import React, { useRef } from "react";

const ResetPasswordGuide = ({ visible, onClose, onResetPassword }) => {
    const modalContentRef = useRef(null);

    const handleOnClose = (e) => {
        if (e.target.id === "ModalClose" || !modalContentRef.current.contains(e.target)) {
            onClose();
        }
    };

    return (
        <div>
            {visible && (
                <div id="ModalClose" onClick={handleOnClose} className="fixed inset-0 z-50">
                    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
                    <div className="fixed inset-0 flex items-center justify-center">
                        <div
                            ref={modalContentRef}
                            className="bg-white p-4 dark:bg-[#151415] sm:w-[438px] w-full rounded-xl"
                        >
                            <p className="md:text-[21px] text-[16px] dark:text-white text-[#151415] font-bold mb-2">
                                Having trouble resetting your password?
                            </p>
                            <p className="md:text-[16px] text-[14px] font-normal mb-3 dark:text-white text-[#151415]">
                                Follow these steps to reset your password:
                            </p>
                            <div className="bg-[#F3F3F3] dark:[#201E20] px-6 py-3 rounded-xl">
                                <ul className="list-disc md:text-[13px] text-[12px] dark:text-white text-[#151415]">
                                    <li className="mt-2">
                                        Ensure your current password is correct if trying to change it.
                                    </li>
                                    <li className="mt-2">
                                        Ensure your new password meets the required criteria (e.g., at least 8 characters, includes numbers and symbols).
                                    </li>
                                    <li className="mt-2">
                                        Check your email inbox (including the spam folder) for a password reset link if you've requested one.
                                    </li>
                                    <li className="mt-2">
                                        If the reset link has expired, request a new password reset.
                                    </li>
                                    <li className="mt-2">
                                        Still having issues? Contact our customer support team for assistance.
                                    </li>
                                </ul>
                            </div>

                            <button
                                onClick={onResetPassword}
                                className="bg-[#F1BA19] hover:bg-[#f1bb19db] mt-4 p-[8px] w-full text-[12px] md:text-[14px] text-white rounded-[10px] font-medium"
                            >
                                Reset Password
                            </button>

                            <button
                                onClick={onClose}
                                className="bg-gray-200 hover:bg-gray-300 mt-2 p-[8px] w-full text-[12px] md:text-[14px] text-[#151415] dark:text-white rounded-[10px] font-medium"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResetPasswordGuide;
