import React from "react";
import { MdAccountCircle, MdNavigateNext } from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function UnableDelete() {
    return (
        <>
            <div className="flex items-center">
                <div className="flex items-center gap-2">
                    <MdAccountCircle className="text-[24px]" />
                    <span className="text-[24px] font-bold">
                        Account Settings
                    </span>
                </div>
                <div>
                    <MdNavigateNext className="text-[24px] text-[#DADADA]" />
                </div>
                <span className="text-[24px] font-bold">Delete Account</span>
            </div>
            <div className="bg-[#fff] mt-2 shadow rounded-xl p-20">
                <p className="font-semibold text-xl mb-3">
                    Unable to Initiate Deletion Process!
                </p>
                <div className="bg-[#F3F3F3] rounded-xl p-3 !pl-10 !pr-10 mt-2 mb-14">
                    <span className="font-bold">
                        Ensure the current account does not have any of the
                        listed conditions:
                    </span>
                    <li className="mt-2">
                        The account is in an abnormal state, such as being
                        disabled, stolen, or compromised by illegal
                        transactions.
                    </li>
                    <li>
                        The estimated value of the remaining assets in the
                        account surpasses 20 USD.
                    </li>
                    <li>
                        The account's security settings (email, mobile, OTP, or
                        password) have been modified within the past seven days.
                    </li>
                    <li>
                        The account contains unfulfilled orders or open market
                        positions.
                    </li>
                </div>
                <span className="text-[#9C9C9C] mt-5">
                    Confirm that your account complies with all requirements
                    before requesting deletion.
                </span>
                <Link to="/user/account/security">
                <button
                    type="button"
                    className="text-black mt-3 flex justify-start w-52 bg-[#EFB81C] hover:bg-[#EFB81C] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5"
                >
                    Back to Settings
                </button>
            </Link>
            </div>
           
        </>
    );
}

export default UnableDelete;
