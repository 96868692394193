// src/store/actions/AccountuserActions.js
import {
    fetchLoginStatusFromAPI,
    fetchLoginHistoryFromAPI,
    fetchKycStatusFromAPI,
} from "repository/fetchLoginStatus";

export const FETCH_LOGIN_STATUS_REQUEST = "FETCH_LOGIN_STATUS_REQUEST";
export const FETCH_LOGIN_STATUS_SUCCESS = "FETCH_LOGIN_STATUS_SUCCESS";
export const FETCH_LOGIN_STATUS_FAILURE = "FETCH_LOGIN_STATUS_FAILURE";
export const FETCH_LOGIN_HISTORY_REQUEST = "FETCH_LOGIN_HISTORY_REQUEST";
export const FETCH_LOGIN_HISTORY_SUCCESS = "FETCH_LOGIN_HISTORY_SUCCESS";
export const FETCH_LOGIN_HISTORY_FAILURE = "FETCH_LOGIN_HISTORY_FAILURE";
export const FETCH_KYC_STATUS_REQUEST = "FETCH_KYC_STATUS_REQUEST";
export const FETCH_KYC_STATUS_SUCCESS = "FETCH_KYC_STATUS_SUCCESS";
export const FETCH_KYC_STATUS_FAILURE = "FETCH_KYC_STATUS_FAILURE";

export const fetchLoginHistory = (userID, userToken) => async (dispatch) => {
    dispatch({ type: FETCH_LOGIN_HISTORY_REQUEST });
    try {
        const data = await fetchLoginHistoryFromAPI(userID, userToken);

        dispatch({
            type: FETCH_LOGIN_HISTORY_SUCCESS,
            payload: data.data.list,
        });
    } catch (error) {
        console.error("Error fetching login history:", error);
        dispatch({ type: FETCH_LOGIN_HISTORY_FAILURE, error });
    }
};

export const fetchKycStatus = (userID, userToken) => async (dispatch) => {
    dispatch({ type: FETCH_KYC_STATUS_REQUEST });
    try {
        const data = await fetchKycStatusFromAPI(userID, userToken);
        console.log("API Response:", data.data); // Verify API response
        dispatch({ type: FETCH_KYC_STATUS_SUCCESS, payload: data });
    } catch (error) {
        console.error("Error fetching login history:", error);
        dispatch({ type: FETCH_KYC_STATUS_FAILURE, error });
    }
};
