import {
    FETCH_ISSUES_REQUEST,
    FETCH_ISSUES_SUCCESS,
    FETCH_ISSUES_FAILURE,
  } from '../types';
  
  const initialState = {
    loading: false,
    issues: [],
    error: '',
  };
  
  const issuesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ISSUES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_ISSUES_SUCCESS:
        return {
          loading: false,
          issues: action.payload,
          error: '',
        };
      case FETCH_ISSUES_FAILURE:
        return {
          loading: false,
          issues: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default issuesReducer;
  