import React from 'react';

const NewsItem = ({ title, date, tags }) => {
  const hasTopTag = tags.includes('Top');

  return (
    <article className="p-3 cursor-pointer hover:bg-zinc-200 dark:hover:bg-gray-800">
      <header>
        <h2 className="text-xl font-semibold">
          {hasTopTag && <span className="text-xs rounded px-2 py-1 mr-2 bg-red-100 text-[#ef454a] ">Top</span>}
          {title}
        </h2>
      </header>
      <div className="mt-2 flex gap-2 items-center">
        <p className="text-sm text-gray-500">{date}</p>
        {tags
          .filter(tag => tag !== 'Top')
          .map((tag, index) => (
            <span key={index} className="text-xs border border-gray-300 rounded px-2 py-1">{tag}</span>
          ))}
      </div>
    </article>
  );
};

export default NewsItem;
