// reducers/walletReducer.js

import {
    FETCH_WALLET_REQUEST,
    FETCH_WALLET_SUCCESS,
    FETCH_WALLET_FAILURE
} from '../types';

const initialState = {
    loading: false,
    walletData: [],
    error: '',
};

const walletReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WALLET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_WALLET_SUCCESS:
            return {
                loading: false,
                walletData: action.payload,
                error: '',
            };
        case FETCH_WALLET_FAILURE:
            return {
                loading: false,
                walletData: [],
                error: action.payload,
            };
        default:
            return state;
    }
};

export default walletReducer;
