import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import USDT from "../../../assets/img/earn/usdt.png";
import { useDispatch, useSelector } from "react-redux";
import { createStaking } from "store/actions/EarnStaking";
import { id } from "date-fns/locale";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import toast from "react-hot-toast";
const StakeNowModel = () => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userid = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const token = useSelector((state) => state.authUser?.user?.TOKEN);
    const location = useLocation(); 
    const {coin,id,value,imgPath} = location.state || {};

    const [amount, setAmount] = useState("");
     
    const handleEnableButton = () => {
        setIsButtonEnabled(!isButtonEnabled); 
    };
    const handleInputChange = (e) => {
        setAmount(e.target.value);
    };

    const currentDate = new Date();
    const currentformattedDate = currentDate.toLocaleString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });
    
    const handleSubmit = () => {
        if(!amount) {
            toast.error("Please enter amount");
            return;
        }
        const payload =  {
            id: id,
            amount: amount || "0",
            period: value,
        }
        console.log(payload);
        dispatch(createStaking(userid, token,payload,setIsModalOpen,setAmount));
    }
    useEffect(() => {
        // handleSubmit()
        }, [dispatch, userID, userToken]);

    const showModal = () => {
        setAmount("");
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setAmount("")
        setIsModalOpen(false);
    };
    return (
        <>
            <button
                onClick={showModal}
                className="flex items-center text-[#121214] font-semibold text-[14px] w-[150px] h-[38px] justify-center rounded-[8px] shadow-lg bg-[#f7a600]"
            >
                Stake Now
            </button>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                className="stake-model"
                width={900}
                onCancel={handleCancel}
            >
                <div
                    style={{ boxShadow: "0 1px 20px rgba(0, 0, 0, .08)" }}
                    className="flex bg-[#fff] justify-between  rounded-[16px] overflow-hidden"
                >
                    <div className="flex-1 p-6">
                        <h6 className="text-[20px] font-semibold text-[#121214]">
                            USDT Flexible Savings
                        </h6>
                        <div className="mt-4 text-[#81858c]">
                            <p className="text-[14px]">Amount</p>
                            <div className="mt-2 bg-[#f3f5f7] border-[1px] border-solid border-[#f3f5f7] rounded-[8px] text-[16px] h-[48px] flex justify-between items-center px-4">
                                <input
                                    type="text"
                                    placeholder="1 ~ 3000000"
                                    onChange={handleInputChange}
                                    className="bg-transparent outline-none font-semibold"
                                />
                                <div className="flex items-center gap-4">
                                    <p className="text-[#d6850d] font-semibold text-[14px]">
                                        Max
                                    </p>
                                    <div className="flex items-center gap-2">
                                    {imgPath && (
                <img src={imgPath} alt={`${coin} logo`} className="w-6 h-6 rounded-full" />
            )}   
                                        <p className="font-semibold text-[#000] text-[14px]">
                                            {coin}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 flex items-center justify-between">
                                <div>
                                    <p className="text-[12px] text-[#81858c] flex items-center gap-1">
                                        Funding Account (0.0000 USDT)
                                        <span>
                                            <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g id="icon_arrow/tri">
                                                    <path
                                                        id="Union"
                                                        d="M7.67988 4.71571L10.3372 8.30091C10.6121 8.67183 10.5306 9.19267 10.1551 9.46424C10.0106 9.56869 9.83629 9.625 9.65728 9.625H4.34266C3.87727 9.625 3.5 9.25233 3.5 8.79263C3.5 8.61581 3.557 8.44358 3.66275 8.30091L6.32006 4.71571C6.59498 4.34479 7.12226 4.26425 7.49776 4.53582C7.56744 4.58621 7.62887 4.64689 7.67988 4.71571Z"
                                                        fill="#81858C"
                                                    ></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </p>
                                </div>
                                <div className="flex items-center justify-center gap-1">
                                    <span className="text-[#d6850d] font-semibold text-[14px]">
                                        Deposit
                                    </span>
                                    <span>|</span>
                                    <span className="text-[#d6850d] font-semibold text-[14px]">
                                        Transfer
                                    </span>
                                </div>
                            </div>
                            <div className="mt-4">
                                <p className="text-[#81858c] text-[14px]">
                                    Est.APR
                                </p>
                                <div className="mt-2 flex justify-between">
                                    <div className="flex items-center gap-2">
                                        <span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                fill="none"
                                                viewBox="0 0 14 14"
                                            >
                                                <path
                                                    fill="#D5DAE0"
                                                    fill-rule="evenodd"
                                                    d="M7 1.75a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5ZM.583 7a6.417 6.417 0 1 1 12.834 0A6.417 6.417 0 0 1 .583 7Z"
                                                    clip-rule="evenodd"
                                                ></path>
                                                <path
                                                    fill="#D5DAE0"
                                                    fill-rule="evenodd"
                                                    d="M9.746 5.42a.583.583 0 0 1 0 .825L6.83 9.168a.583.583 0 0 1-.826 0l-1.75-1.75a.583.583 0 1 1 .825-.825L6.416 7.93l2.505-2.509a.583.583 0 0 1 .825 0Z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                        </span>
                                        <p className="">{`<`}= 500 USDT</p>
                                    </div>
                                    <div className="text-[#000] font-semibold">
                                        {value}%
                                    </div>
                                </div>
                                <div className="mt-2 flex justify-between">
                                    <div className="flex items-center gap-2">
                                        <p className="">
                                            Your Current Staking Amount
                                        </p>
                                    </div>
                                    <div className="text-[#000] font-semibold">
                                        0 USDT
                                    </div>
                                </div>
                                <div className="mt-2 flex justify-between">
                                    <div className="flex items-center gap-2">
                                        <p className="">Remaining Pool Size</p>
                                    </div>
                                    <div className="text-[#000] font-semibold">
                                        277,386,425.7215 USDT
                                    </div>
                                </div>
                                <div className="mt-2 flex justify-between">
                                    <div className="flex items-center gap-2">
                                        <p className="">Est. Daily Yield</p>
                                    </div>
                                    <div className="text-[#000] font-semibold">
                                        -- USDT
                                    </div>
                                </div>
                                <div className="mt-2 flex justify-between">
                                    <div className="flex items-center gap-2">
                                        <p className="">Auto Savings</p>
                                    </div>
                                    <div className="text-[#000] font-semibold">
                                        <label>
                                            <input
                                                type="checkbox"
                                                class="toggle-checkbox"
                                            />
                                            <div class="toggle-switch"></div>
                                        </label>
                                    </div>
                                </div>
                                <div onClick={handleEnableButton} className="mt-4 flex items-center gap-2">
                                    <input type="checkbox" />
                                    <p className="text-[#6e747b] text-[12px]">
                                        I have read and agreed to
                                        <span className="text-[#d6850d] font-semibold ml-1">
                                            Agreement for Bybit Savings.
                                        </span>
                                    </p>
                                </div>
                                <button disabled={!isButtonEnabled} onClick={handleSubmit} className={`mt-4 mb-4 w-full rounded-[8px] h-[48px] flex items-center justify-center font-semibold ${
        isButtonEnabled
            ? "bg-[#d6850d] text-[#000] opacity-100" // Fully visible when enabled
            : "bg-[#d6850d] text-[#000] opacity-50 cursor-not-allowed" // Partially transparent when disabled
    }`}>
                                    Stake Now
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 p-6 bg-[#f5f7fa]">
                        <h6 className="text-[20px] font-semibold text-[#121214]">
                            Summary
                        </h6>
                        <div className="mt-4">
                            <div className="mt-2 flex justify-between">
                                <div className="flex items-center gap-2">
                                    <p className="text-[12px] text-[#81858c]">
                                        Stake Date (UTC)
                                    </p>
                                </div>
                                <div className="text-[#000] font-semibold text-[12px]">
                                    {currentformattedDate}
                                </div>
                            </div>
                            <div className="mt-2 flex justify-between">
                                <div className="flex items-center gap-2">
                                    <p className="text-[12px] text-[#81858c]">
                                        Yield Start Time (UTC)
                                    </p>
                                </div>
                                <div className="text-[#000] font-semibold text-[12px]">
                                    {currentformattedDate}
                                </div>
                            </div>
                            <div className="mt-2 flex justify-between">
                                <div className="flex items-center gap-2">
                                    <p className="text-[12px] text-[#81858c]">
                                        Yield Accrual Time (UTC)
                                    </p>
                                </div>
                                <div className="text-[#000] font-semibold text-[12px]">
                                    {currentformattedDate}
                                </div>
                            </div>
                            <div className="mt-2 flex justify-between">
                                <div className="flex items-center gap-2">
                                    <p className="text-[12px] text-[#81858c]">
                                        Yield Distribution Time (UTC)
                                    </p>
                                </div>
                                <div className="text-[#000] font-semibold text-[12px]">
                                    {currentformattedDate}
                                </div>
                            </div>
                        </div>
                        <div className="mt-6">
                            <h6 className="text-[14px] font-semibold text-[#121214] ">
                                24H Real-Time APR
                            </h6>
                            <div className="mt-4 h-[168px] "></div>
                        </div>
                        <div className="mt-6">
                            <h6 className="text-[14px] font-semibold text-[#121214] ">
                                Notice
                            </h6>
                            <div className="mt-2 text-[#81858c] text-[14px] h-[65px] overflow-y-scroll">
                                In rare situations, when there is an
                                exceptionally high market demand for the listed
                                cryptocurrency, the redemption of the principal
                                may face delays, with an expected processing
                                time of up to 48 hours.
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default StakeNowModel;
