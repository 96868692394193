import React, { useEffect } from "react";

import { useLocation, Link } from "react-router-dom";
import SpotOrderHistoryTable from "../../tables/SpotOrderHistoryTable";
import { useSelector,  useDispatch } from "react-redux";
import { fetchOrderHistory } from "store/actions/OrderUserAction";

const SpotOrderHistory = () => {
  const dispatch = useDispatch();

  const { loading, orderHistory, error } = useSelector(
    (state) => state.orderHistory
  );
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  useEffect(() => {
    if (userID && userToken) {
      const payload = {
        userID: userID,
        market: 'cvm_usdt', // Ensure market is defined in your component
        type: 'p2p',     // Ensure type is defined in your component
      };
    dispatch(fetchOrderHistory(userID, userToken, payload ));
  }
  }, [dispatch, userID, userToken]);
  
  const location = useLocation();

  



  const navbar = [
    {
      name: "Pending Orders",
      link: "/user/spot-order/pending",
    },
    {
      name: "Orders History",
      link: "/user/spot-order/order-history",
    },
    {
      name: "Trade History",
      link: "/user/spot-order/trade-history",
    },
    {
      name: "Margin",
      link: "/user/spot-order/margin-history",
    },
  ];

  useEffect(() => {
    console.log("orderHistory ", orderHistory);
  }, [orderHistory]);
  
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg text-red-500">Error: {error}</div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col w-full">
        {/* First card */}
        <div className="flex-col justify-center w-full p-4  bg-white rounded-3xl text-[16px]">
          {/* Navbar */}
          <div className="flex items-center gap-x-3">
            {navbar.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                className={`${
                  location.pathname === item.link
                    ? "text-[#efb81c] border-[#efb81c]"
                    : " border-gray-300 text-gray-300"
                } px-3 py-1  border-[1px] rounded-md  `}
              >
                <button>{item.name}</button>
              </Link>
            ))}
          </div>

          <div className="flex items-center justify-end w-full my-3"> 
            <div className="flex items-center gap-x-2">
              <h6 className="font-semibold">Order Type</h6>
              <div className="flex items-center gap-x-2">
             
                <button  className={`"text-[#efb81c] border-[#efb81c] text-[#efb81c] px-3 py-1  border-[1px] rounded-md `}>Normal Order</button>
                <button  className={`"text-[#efb81c] border-gray-400 text-gray-400 px-3 py-1  border-[1px] rounded-md `}>Stop Order</button>
                <button  className={`"text-[#efb81c] bg-[#f1d27c] text-[#9b7f31] px-3 py-1  rounded-md `}>Reset</button>
              </div>
              
            </div>
          </div>
          <div className="flex items-center justify-between w-full mt-4 gap-x-2">
          <div className="flex items-center gap-x-2">
              <h6 className="font-semibold">Order Type</h6>
              <div className="flex items-center gap-x-2">
             
                <button  className={`"text-[#efb81c] border-[#efb81c] text-[#efb81c] px-3 py-1  border-[1px] rounded-md `}>Last 30 Days</button>
                <button  className={`"text-[#efb81c] border-gray-400 text-gray-400 px-3 py-1  border-[1px] rounded-md `}> Last 90 Days</button>
                <button  className={`"text-[#efb81c] border-gray-400 text-gray-400 px-3 py-1  border-[1px] rounded-md `}>Last 180 Days</button>
                <button  className={`"text-[#efb81c] border-gray-400 text-gray-400 px-3 py-1  border-[1px] rounded-md `}>All</button>
                <button  className={`"text-[#efb81c] border-gray-400 text-black px-3 py-1  border-[1px] rounded-md `}>2024-01-10 to 2024-06-17</button>
              </div>
              
            </div>
            <button  className={`"text-[#efb81c] border-gray-400 text-gray-400 px-3 py-1  border-[1px] rounded-md `}>Export</button>
          </div>
         
        </div>

        {/* Table */}
        <SpotOrderHistoryTable loading={loading} error={error}  list={orderHistory?.list || {}} />
      </div>
    </>
  );
};

export default SpotOrderHistory;
