const initialState = {
    email: "",
    password: "",
};

const Realreducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_PASSWORD":
            return {
                ...state,
                email: action.payload.email,
                password: action.payload.password,
            };
        default:
            return state;
    }
};

export default Realreducer;
