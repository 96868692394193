import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TbFileTime, TbCirclesRelation } from "react-icons/tb";
import { Spinner } from 'reactstrap';

import OTCForm from './OTCForm';
import OtcContactUs from './OtcContactUs'
import FaQs from 'components/FAQs';
import { fetchCoinBalance } from 'store/actions/cryptoConvertAction';
import { fetchAssetsData } from "store/actions/AssetsUserAction";

import coin_1 from 'assets/img/small_coin_1.png';
import coin_2 from 'assets/img/small_coin_2.png';
import { fetchBalanceTypes } from 'store/actions/convertbalanceActions';
import OTCBG from '../../../assets/img/global/buycex-card-bg.svg';
import { fetchOtcCoin, getConversion } from 'store/actions/OTCAction';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const OTCTrading = () => {
    const [selectedCoinFrom, setSelectedCoinFrom] = useState(null);
    const [selectedCoinType, setSelectedCoinType] = useState(null);
    const [selectedCoinTo, setSelectedCoinTo] = useState(null);
    const [valueFrom, setValueFrom] = useState("");
    const [valueTo, setValueTo] = useState("");
    const [selectedCoinToOptions, setSelectedCoinToOptions] = useState([]);
    const [selectedCoinFromOptions, setSelectedCoinFromOptions] = useState([]);
    const [currencyConversionRates, setCurrencyConversionRates] = useState({});
    const [otcCoinData, setOtcCoinData] = useState([]);
    const [otcLoading, setOtcLoading] = useState(false);
    const [otcError, setOtcError] = useState(null);

    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;
    const dispatch = useDispatch();
    const history = useHistory();

    const { balanceTypes, loading, error } = useSelector((state) => state.balance);
    const { assetsData, loading: assetsLoading } = useSelector((state) => state.assetsData || {});

    const IMAGE_BASE_URL = "https://api.buycex.com/Upload/coin/";
    useEffect(() => {
        if (userId && token) {
            setOtcLoading(true);
            dispatch(fetchOtcCoin(userId, token))
                .then((response) => {
                    if (response.success) {
                        const data = response.data;

                        // Extract base coins and trade coins
                        const baseCoins = data.base_coins.map((coin) => ({
                            value: coin.symbol.toUpperCase(),
                            ticker: coin.symbol.toUpperCase(),
                            label: coin.symbol.toUpperCase(),
                            icon: coin.image,
                            min: coin.min,
                            max: coin.max,
                        }));

                        const tradeCoins = data.trade_coins.map((coin) => ({
                            value: coin.symbol.toUpperCase(),
                            ticker: coin.symbol.toUpperCase(),
                            label: coin.symbol.toUpperCase(),
                            icon: coin.image,
                            min: parseFloat(coin.min),
                            max: parseFloat(coin.max),
                        }));

                        // Default coins
                        const defaultCoinFrom = {
                            value: data.default_coin_trade.symbol.toUpperCase(),
                            ticker: data.default_coin_trade.symbol.toUpperCase(),
                            label: data.default_coin_trade.symbol.toUpperCase(),
                            icon: data.default_coin_trade.image,
                        };

                        const defaultCoinTo = {
                            value: data.default_coin_base.symbol.toUpperCase(),
                            ticker: data.default_coin_base.symbol.toUpperCase(),
                            label: data.default_coin_base.symbol.toUpperCase(),
                            icon: data.default_coin_base.image,
                        };

                        // Set states
                        setOtcCoinData(data);
                        setSelectedCoinFrom(defaultCoinTo);
                        setSelectedCoinTo(defaultCoinFrom);
                        setSelectedCoinFromOptions(baseCoins);
                        setSelectedCoinToOptions(tradeCoins);
                    } else {
                        setOtcError("Failed to fetch OTC coin data");
                    }
                })
                .catch((err) => {
                    setOtcError(err.message || "An error occurred");
                })
                .finally(() => {
                    setOtcLoading(false);
                });
        } else {
            history.push('/login'); // Redirect if not authenticated
        }
    }, [dispatch, userId, token, history]);


    console.log("otc data ", otcCoinData)
    const otcHistoryRedirect = () => {
        history.push('/user/history/otc-history');
    }

    // Fetch balance types for the user
    useEffect(() => {
        dispatch(fetchBalanceTypes(token, userId));
    }, [dispatch, token, userId]);
    console.log("balanceTypes", balanceTypes)
    // Fetch assets data
    useEffect(() => {
        if (!userId || !token) {
            history.push('/crypto-convert');
            return;
        }
        dispatch(fetchAssetsData(userId, token, "fund"));
    }, [dispatch, userId, token, history]);


    useEffect(() => {
        if (balanceTypes) {
            console.log("Balance Types: ", balanceTypes);
        }
    }, [balanceTypes]);
    // Fetch market info for conversion rates







    // Reset input fields when selectedCoinFrom or selectedCoinTo changes
    useEffect(() => {
        setValueFrom("");
        setValueTo("");
    }, [selectedCoinFrom, selectedCoinTo]);

    // Fetch balance for selectedCoinFrom
    useEffect(() => {
        if (selectedCoinFrom) {
            dispatch(fetchCoinBalance({ user_id: userId, token: token, coinname: selectedCoinFrom.value.toLowerCase() }));
        }
    }, [selectedCoinFrom, userId, token, dispatch]);

    // Fetch balance for selectedCoinTo
    useEffect(() => {
        if (selectedCoinTo) {
            dispatch(fetchCoinBalance({ user_id: userId, token: token, coinname: selectedCoinTo.value.toLowerCase() }));
        }
    }, [selectedCoinTo, userId, token, dispatch]);

    const handleSwap = () => {
        setSelectedCoinFrom(selectedCoinTo);
        setSelectedCoinTo(selectedCoinFrom);
        setSelectedCoinFromOptions(selectedCoinToOptions);
        setSelectedCoinToOptions(selectedCoinFromOptions);
        // Reset input fields after swap
        setValueFrom("");
        setValueTo("");
    };



    const handleValueChangeFrom = (e) => {
        setValueFrom(e.target.value);
    };
    console.log("balanceTypes", balanceTypes)
    return (
        <div>
            <section
                className="relative flex bg-black items-center justify-center w-[100%] gap-2 md:gap-4"

            >

                <div className="relative z-10 mb-4 h-36 flex flex-col justify-center items-center text-center text-[#151415]">
                    <h1 className="text-3xl md:text-5xl font-semibold mb-1 text-white">OTC</h1>
                    <p className="text-sm md:text-lg font-medium text-gray-300">
                        BUYCEX OTC & Execution Solutions
                    </p>
                </div>

                <div className="relative z-10 hidden justify-between items-center mt-8 text-[#151415] w-full gap-8 font-medium">
                    {/* Left-side Button */}
                    <button className='px-3 py-2 rounded-lg inline-flex items-center text-xs'>
                        <TbFileTime className='mr-1' />Spot
                    </button>

                    {/* Right-side Buttons */}
                    <div className="flex gap-8">
                        <button className='px-3 py-2 rounded-lg inline-flex items-center text-xs'>
                            <TbFileTime className='mr-1' />Chat with OTC
                        </button>
                        <button className=' px-3 py-2 rounded-lg inline-flex items-center text-xs' onClick={otcHistoryRedirect}>
                            <TbCirclesRelation className='mr-1' />OTC Trading History
                        </button>

                    </div>
                </div>
            </section>

            <section className="relative container flex bg-white flex-col items-center justify-center w-[100%] gap-6 md:gap-4">
                {/* {assetsLoading || (!selectedCoinFrom || !selectedCoinTo) ? (
                    <div className="relative z-10 bg-neutral-50 dark:bg-zinc-900 p-4 flex items-center justify-center rounded-3xl shadow-lg lg:w-2/5">
                        <Spinner color="primary" />
                    </div>
                ) : ( */}
                <div className='mt-8'>
                    <p className='uppercase text-3xl font-semibold'>Over the Counter</p>
                    <div className='flex items-center justify-between mt-2'>
                        <p className='text-sm'>Instant Price</p>
                        <p className='flex text-sm'><p className='pr-2'>|</p>Guaranteed Price</p>
                        <p className='flex text-sm'><p className='pr-2'>|</p>Any Pair</p>
                    </div>
                </div>
                <Link to="/user/history/transfer-history" className="flex w-full relative mr-4 justify-end">
                    <button className='bg-[#EFB81C] text-white mt-[-80px] font-semibold self-start px-3 py-0 sm:py-2 rounded-lg inline-flex items-center text-sm'>
                        <TbFileTime className='mr-1 hidden sm:block text-white' /> OTC history
                    </button>
                </Link>
                <div className="relative bg-white z-10 dark:bg-zinc-900 p-4 rounded-2xl border w-full min-w-full sm:min-w-[400px] my-5 sm:w-2/6">
                    <div className="flex justify-start">
                    </div>

                    <OTCForm
                        selectedCoinFrom={selectedCoinFrom}
                        selectedCoinFromOptions={selectedCoinFromOptions}
                        selectedCoinTo={selectedCoinTo}
                        selectedCoinToOptions={selectedCoinToOptions}

                        valueFrom={valueFrom}
                        setValueFrom={setValueFrom}
                        valueTo={valueTo}
                        setValueTo={setValueTo}
                        handleValueChangeFrom={handleValueChangeFrom}
                        handleSwap={handleSwap}
                        excludeValuesTo={[selectedCoinTo?.value]}
                        excludeValuesFrom={[selectedCoinFrom?.value]}
                        setSelectedCoinFrom={setSelectedCoinFrom}
                        setSelectedCoinTo={setSelectedCoinTo}
                        getConversion={getConversion}
                    />
                </div>
                <div>
                <a href="https://t.me/BUYCEXOTC"><p className='text-lg font-semibold text-center text-[#31a64b]'>Chat With OTC</p></a>
                <p className='text-lg font-semibold text-center pt-1'>9.00 AM to 5.00 PM GMT +5.30</p>
                </div>
                {/* )} */}
            </section>
            <section>
                {/* <OtcContactUs /> */}
            </section>



        </div>
    );
}

export default OTCTrading;
