import React from 'react'
import Card from "assets/img/idcard-withdraw2.png"
import { FaFileUpload } from "react-icons/fa";

const IdentityVerification = ({nextStep}) => {
  return (
    <div className="bg-white rounded-xl shadow-lg mb-20 px-10 py-4 text-sm">
      <div className="bg-[#F3F3F3] p-4 rounded-xl">
        <h1 className="font-semibold">Reminder</h1>
        <p className="text-xs">The submitted ID information must match the applicant's KYC verification; forgot ID information? <span className="text-xs text-yellow-500">View approval details</span> </p>
      </div>
      <h1 className="font-semibold mt-6">Follow These steps for successfull ID Submission.</h1>
      <div className="mt-2">
        <h1 className="font-semibold">1. Prepare your ID document.</h1>
        <p className="text-[#9C9C9C] text-xs px-3">       Use the same ID you verified your account with.</p>
      </div>
      <div className="mt-4 pt-4 border-t flex justify-between gap-8">
        <div className="flex-1">
        <h1 className="font-semibold">2. Write a clear and legible statement on a piece of paper.</h1>
        <p className="text-[#9C9C9C] text-xs px-3">           To guarantee approval, kindly adhere to the application format outlined        on the right side.</p>
        </div>
        <div className="mt-4 w-80 bg-[#F8F8F8] rounded-xl border-2 px-4 py-3">
          <h1 className="font-medium  text-xs">BUYCEX.</h1>
          <p className="font-medium mt-2  text-xs">Application for Resetting Withdrawal .</p>
          <p className="font-medium  text-xs">Password on BUYCEX</p>
          <p className="mt-8 font-medium  text-xs">Name of the Applicant</p>
          <p className="mt-1 font-medium  text-xs">DD/MM/YYYY</p>
        </div>
      </div>

      <div className="mt-4 pt-4 border-t flex justify-between gap-8">
        <div className="flex-1">
        <h1 className="font-semibold">3. Take a clear photo of yourself holding both items.</h1>
        <ul className="list-disc px-6">
        <li className="text-[#9C9C9C] text-xs">Hold your ID document and the handwritten statement in your hand.</li>
        <li className="text-[#9C9C9C] text-xs">Ensure your face, hands, and the contents of both documents are visible.</li>
        </ul>
       
        </div>
        <div className="mt-4 w-80">
         <img src={Card} alt="" className="w-full" />
        </div>
      </div>
      <div className="mt-4 pt-4 border-t flex justify-between gap-8">
        <div className="flex-1">
        <h1 className="font-semibold">4. Upload ID Card</h1>
        
        <p className="text-[#9C9C9C] text-xs px-3">       Upload as a single JPG, PNG, or JPEG file, maximum 5MB.</p>
        
        
       
        </div>
        <div className="mt-4 w-80 border-[1px] border-dashed rounded-2xl py-8 pl-8">
         <h1 className="font-semibold">Click To Upload</h1>
         <div className="flex items-center gap-2 mt-1">
           <div className="">
      <input
        type="file"
        id="fileInput"
        className="hidden"  
      />
      <label
        htmlFor="fileInput"
        className="bg-neutral-200 rounded-2xl py-1 px-2 flex items-center gap-1 text-xs cursor-pointer mt-2"
      >
        <FaFileUpload /> Upload
      </label>
    </div>
           <p className="text-[#9C9C9C] text-[10px]">Max 5 MB in .jpg/.jpeg/.png format</p>

         </div>
        </div>
      </div>
      <div className="text-center mt-8 mb-8">
        <button onClick={nextStep} className="px-20 py-2 bg-yellow-500 font-semibold rounded-xl">Confirm</button>
      </div>
    </div>
  )
}

export default IdentityVerification
