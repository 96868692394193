import React , { useState }from "react";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FaBitcoin } from "react-icons/fa" 

function SubHeader({ assetsData = { baltypes: [] } }) {
  const location = useLocation();
  const path = location.pathname;
  const [isSpotExpanded, setIsSpotExpanded] = useState(false);
   // Toggle the expanded status of the "Spot" section
   const toggleSpotMenu  = () => {
    setIsSpotExpanded(!isSpotExpanded);
  };

  // Ensure assetsData and baltypes are defined and have elements
  const baltypes = assetsData?.baltypes || [];

  return (
    <div className="flex mt-10 items-center justify-between font-medium w-full border-b-[1px] border-gray-300 text-white">
      {/* Left Content */}
      <div className={`w-full flex justify-center md:justify-start gap-x-[30px] md:gap-x-[40px] items-center`}>
        <div className="flex items-center justify-center text-[16px] px-5 gap-x-[35px]">
          <Link
            to="/user/assets/overview"
            className={`${path.includes("/user/assets/overview") ? "border-[#efb81c]" : "border-transparent"} border-b-4 focus:border-solid hover:border-[#efb81c] pb-[0.95rem] text-black dark:text-white hover:text-black dark:hover:text-white`}
          >
            Overviews
          </Link>
          
          <div>
            <div
              onClick={toggleSpotMenu}
              className={`cursor-pointer ${isSpotExpanded ? "border-[#efb81c]" : "border-transparent"} border-b-4 focus:border-solid hover:border-[#efb81c] capitalize pb-[0.95rem] text-black dark:text-white hover:text-black dark:hover:text-white flex items-center gap-2`}
            >
              <FaBitcoin />
              Spot
            </div>
            {isSpotExpanded && (
              <div className="ml-5">
                <Link
                  to="/user/assets/deposit"
                  className={`${path.includes("/user/assets/deposit") ? "border-[#efb81c]" : "border-transparent"} border-b-4 focus:border-solid capitalize hover:border-[#efb81c] pb-[0.95rem] text-black dark:text-white hover:text-black dark:hover:text-white`}
                >
                  Deposit Records
                </Link>
                <Link
                  to="/user/assets/WithdrawRecord"
                  className={`${path.includes("/user/assets/WithdrawRecord") ? "border-[#efb81c]" : "border-transparent"} border-b-4 focus:border-solid capitalize hover:border-[#efb81c] pb-[0.95rem] text-black dark:text-white hover:text-black dark:hover:text-white`}
                >
                  Withdrawal Records
                </Link>
                <Link
                  to="/user/assets/TransferRecord"
                  className={`${path.includes("/user/assets/TransferRecord") ? "border-[#efb81c]" : "border-transparent"} border-b-4 focus:border-solid capitalize hover:border-[#efb81c] pb-[0.95rem] text-black dark:text-white hover:text-black dark:hover:text-white`}
                >
                  Transfer Records
                </Link>
                <Link
                  to="/user/assets/UncreditedDepositForm"
                  className={`${path.includes("/user/assets/UncreditedDepositForm") ? "border-[#efb81c]" : "border-transparent"} border-b-4 focus:border-solid capitalize hover:border-[#efb81c] pb-[0.95rem] text-black dark:text-white hover:text-black dark:hover:text-white`}
                >
                  UncreditedDepositForm Records
                </Link>
              </div>
            )}
          </div>
            <Link
              to="/user/assets/funding"
              className={`${path.includes("/user/assets/funding") ? "border-[#efb81c]" : "border-transparent"} border-b-4 focus:border-solid capitalize hover:border-[#efb81c] pb-[0.95rem] text-black dark:text-white hover:text-black dark:hover:text-white`}
            >
              Funding
            </Link>
          
            <Link
              to="/user/assets/earn"
              className={`${path.includes("/user/assets/earn") ? "border-[#efb81c]" : "border-transparent"} border-b-4 focus:border-solid hover:border-[#efb81c] capitalize pb-[0.95rem] text-black dark:text-white hover:text-black dark:hover:text-white`}
            >
              Earn
            </Link>
        </div>
      </div>
    </div>
  );
}

export default SubHeader;
