import React from "react";
import { BsX } from "react-icons/bs";

import PropTypes from "prop-types";

const BuyOrderDetails = ({
    closeBuyDetailsModal,
    orderDetails,
    selectedCoin,
}) => {
    return (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full backdrop-blur-[0.5px] z-[99999] bg-black/30">
            <div className="modal-content bg-[#FFFFFF] w-[250px] md:w-[300px] lg:w-[450px] rounded-md h-auto p-4 flex flex-col gap-3">
                <div className="flex items-center justify-between w-full gap-1">
                    <h3 className="text-[14px] w-full font-bold flex items-center gap-2">
                        <span className="text-[16px]">
                            {orderDetails.orderAction}
                        </span>
                        <span className="text-[16px]">
                            {orderDetails.orderActionType}
                        </span>
                        <span className="text-[16px]">
                            {orderDetails.selectedCoinName}
                        </span>
                    </h3>
                    <button
                        type="button"
                        className="h-auto w-max"
                        onClick={closeBuyDetailsModal}
                    >
                        <BsX size={20} />
                    </button>
                </div>
                <div className="flex flex-col w-full gap-2.5 modal-data-table">
                    <div className="flex items-center justify-between gap-2">
                        <div className="fields text-[13px] font-bold text-[#15141590]">
                            Order Price
                        </div>
                        <div className="fields-data text-[12px] font-[500]">
                            ${orderDetails.selectedCoinBuyPrice}{" "}
                            {
                                selectedCoin.ticker
                                    .toUpperCase()
                                    .replace("_", "/")
                                    .split("/")[1]
                            }
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-2">
                        <div className="fields text-[13px] font-bold text-[#15141590]">
                            Quantity
                        </div>
                        <div className="fields-data text-[12px] font-[500]">
                            {orderDetails.orderQuantity}{" "}
                            {orderDetails.selectedCoinName}
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-2">
                        <div className="fields text-[13px] font-bold text-[#15141590]">
                            Total Value
                        </div>
                        <div className="fields-data text-[12px] font-[500]">
                            ${orderDetails.totalOrderValue}{" "}
                            {
                                selectedCoin.ticker
                                    .toUpperCase()
                                    .replace("_", "/")
                                    .split("/")[1]
                            }
                        </div>
                    </div>
                </div>

                {/* Button to confirm or cancel order */}
                <div className="flex justify-between gap-4 mt-2 modal-confirm-btn">
                    <button
                        type="button"
                        className="px-4 w-full rounded-md py-2 font=[500] text-[#151415] bg-[#EF454A]"
                        onClick={closeBuyDetailsModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="px-4 w-full rounded-md py-2 font=[500] bg-[#20B26C] text-white"
                        onClick={closeBuyDetailsModal}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

BuyOrderDetails.propTypes = {
    closeBuyDetailsModal: PropTypes.func,
    orderDetails: PropTypes.object,
};

export default BuyOrderDetails;
