import React from "react";

import UserGuideModal from "./UserGuideModal";

import {
    BsCheckCircleFill,
    BsPhoneFill,
    BsQuestionCircleFill,
    BsShieldFillExclamation,
} from "react-icons/bs";
import { Tooltip } from "react-tooltip";

const TwoFactorAuth = ({
    authStatus,
    onGoogleAuthenticatorChangeClick,
    onGoogleAuthenticatorSetClick,
    onMobileChangeClick,
    onMobileSetWhen2faActiveClick,
    onMobileSetWhen2faInActiveClick,
}) => {
    const handleMobileButtonClick = () => {
        if (!authStatus.ga && !authStatus.cellphone) {
            onMobileSetWhen2faInActiveClick();
        } else {
            authStatus.cellphone
                ? onMobileChangeClick()
                : onMobileSetWhen2faActiveClick();
        }
    };

    return (
        <div>
            <div className="font-bold text-[18px] mt-[30px] mb-3">
                <span>Two-Factor Authentication (2FA)</span>
            </div>
            <div className="bg-[#FFFFFF] rounded-md border border-[#F4F4F4]">
                <div className="flex p-4 justify-between items-center border-b">
                    <div className="flex items-center gap-2">
                        <div>
                            <BsShieldFillExclamation size={20} />
                        </div>
                        <div className="flex flex-col">
                            <div className="flex items-center gap-2">
                                <div className="text-[14px] font-semibold">
                                    G2FA Verification
                                </div>
                                <span
                                    data-tooltip-id="g2fa-tooltip"
                                    data-tooltip-content="Verify through Google Authenticator's onetime 6 digit verification code."
                                    data-tooltip-place="right"
                                >
                                    <BsQuestionCircleFill
                                        size={10}
                                        fill="#15141590"
                                    />
                                </span>
                                <Tooltip id="g2fa-tooltip" />
                            </div>
                            <p className="text-[12px] text-[#15141590] max-w-[300px]">
                                Use G2FA Authentication to Protect Your account
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-10">
                        <div className="flex items-center gap-1">
                            <BsCheckCircleFill
                                size={10}
                                fill={authStatus.ga ? "#EFB81C" : "#15141590"}
                            />
                            <span className="text-[10px] font-medium text-[#15141590]">
                                {authStatus.ga ? "Set" : "Not Set"}
                            </span>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                onClick={
                                    authStatus.ga
                                        ? onGoogleAuthenticatorChangeClick
                                        : onGoogleAuthenticatorSetClick
                                }
                            >
                                <span className="text-[12px]">
                                    {authStatus.ga ? "Change" : "Set"}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                {/* Mobile Verification Section */}
                <div className="flex p-4 justify-between items-center">
                    <div className="flex items-center gap-2">
                        <div>
                            <BsPhoneFill size={20} />
                        </div>
                        <div className="flex flex-col">
                            <div className="font-semibold text-[14px]">
                                Mobile
                            </div>
                            <p className="text-[12px] text-[#15141590] max-w-[300px]">
                                Use SMS verification to protect your account
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center justify-between gap-10">
                        <div className="flex items-center gap-1">
                            <BsCheckCircleFill
                                size={10}
                                fill={
                                    authStatus.cellphone
                                        ? "#EFB81C"
                                        : "#15141590"
                                }
                            />
                            <span className="text-[10px] font-medium text-[#15141590]">
                                {authStatus.cellphone ? "Set" : "Not Set"}
                            </span>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                                onClick={
                                    authStatus.cellphone
                                        ? handleMobileButtonClick
                                        : onMobileChangeClick
                                }
                            >
                                <span className="text-[12px]">
                                    {authStatus.cellphone ? "Change" : "Set"}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwoFactorAuth;