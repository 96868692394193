import React, { useState } from "react";
import EmailSetModal from "../EmailSetModal";
import EmailChangeModal from "../EmailChangeModal";

import { BsCheckCircleFill, BsEnvelopeFill, BsLockFill } from "react-icons/bs";
import { sendCodeToEmail, SetEmail } from "store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";

const BasicSettings = ({ authStatus, userProfile, onPasswordChangeClick }) => {
    const email = authStatus?.email;
    const [showEmailMethodModal, setShowEmailMethodModal] = useState(false);
    const [showEmailChangeMethodModal, setShowEmailChangeMethodModal] =
        useState(false);
        const dispatch = useDispatch();    
        const state = useSelector((state) => state.Newemail.email);
        const userID = useSelector((state) => state.authUser?.user?.ID);
        const stateS = useSelector((state) => state.userInfo.userProfile.email);

    const handlePopupClick = () => {
        if (email) {
            // If email is set, show change modal
            setShowEmailChangeMethodModal(true);
            const payload = {
                email: email,
                user_id: userID,
            };
            dispatch(sendCodeToEmail(payload));
        } else {
            // If email is not set, show set modal
            setShowEmailMethodModal(true);
        }
    };

    const handleEmailPopupClose = () => {
        setShowEmailMethodModal(false);
        setShowEmailChangeMethodModal(false); // Close the change modal too
    };

    return (
        <div>
            <div className="font-bold text-[18px] mt-[30px] mb-3">
                <span>Basic Settings</span>
            </div>
            <div className="flex justify-between gap-40 lg:gap-10">
                <div className="w-full md:w-[50%] flex items-center gap-3 bg-[#FFFFFF] rounded-md px-4 py-3 border border-[#F4F4F4]">
                    <div>
                        <BsEnvelopeFill size={20} />
                    </div>
                    <div className="flex items-center justify-between w-full">
                        <div className="flex flex-col gap-0">
                            <div className="text-[14px] font-semibold w-max">
                                Email
                            </div>
                            <p className="text-[12px] text-[#15141590]">
                                {email}
                            </p>
                        </div>
                        <div className="flex items-center ml-auto">
                            <BsCheckCircleFill
                                size={10}
                                fill={email ? "#EFB81C" : "#15141590"}
                            />
                            <span className="text-[10px] font-semibold text-[#15141580] ml-1 mr-6">
                                {email ? "Set" : "Not Set"}
                            </span>
                        </div>
                        <button
                            onClick={handlePopupClick} // Handle popup click
                            className="font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                        >
                            {email ? "Change" : "Set"}
                        </button>
                    </div>
                </div>
                <div className="w-full md:w-[50%] flex items-center gap-3 bg-[#FFFFFF] rounded-md px-4 py-3 border border-[#F4F4F4]">
                    <div>
                        <BsLockFill size={20} />
                    </div>
                    <div className="w-full flex items-center justify-between">
                        <div className="flex flex-col gap-0">
                            <div className="text-[14px] font-semibold w-max">
                                Password
                            </div>
                            <p className="text-[12px] text-[#15141590]">
                                Last change: 2024-07-15 23:38:14
                            </p>
                        </div>
                        <button
                            type="button"
                            className="font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300"
                            onClick={onPasswordChangeClick}
                        >
                            Change
                        </button>
                    </div>
                </div>
            </div>

            {showEmailMethodModal && (
                <EmailSetModal
                    show={showEmailMethodModal}
                    onClose={handleEmailPopupClose}
                    email={authStatus.email}
                />
            )}
            {showEmailChangeMethodModal && (
                <EmailChangeModal
                    show={showEmailChangeMethodModal}
                    onClose={handleEmailPopupClose}
                    email={authStatus.email}
                />
            )}
        </div>
    );
};

export default BasicSettings;