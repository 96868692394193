import React from "react";
import { BsDatabaseExclamation } from "react-icons/bs";

const OpenOrdersTable = () => {
    return (
        <table className="w-full order-list-body__table">
            <thead className="w-full">
                <tr className="flex justify-between w-full">
                    <th className="text-left text-[12px] font-normal text-[#15141580]  dark:text-[#71777A] px-1 py-2.5">
                        Date
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580]  dark:text-[#71777A] px-1 py-2.5">
                        Trading Pair
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580] dark:text-[#71777A]  px-1 py-2.5">
                        Order Type
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580]  dark:text-[#71777A]  px-1 py-2.5">
                        Price
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580]  dark:text-[#71777A]  px-1 py-2.5">
                        Amount
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580]  dark:text-[#71777A]  px-1 py-2.5">
                        Filled | Unfilled
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580]  dark:text-[#71777A] px-1 py-2.5">
                        Order Status
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580] dark:text-[#71777A]  px-1 py-2.5">
                        Order Time
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580]  dark:text-[#71777A]  px-1 py-2.5">
                        Order ID
                    </th>
                    <th className="text-left text-[12px] font-normal text-[#15141580]  dark:text-[#71777A] px-1 py-2.5">
                        Action
                    </th>
                </tr>
            </thead>

            <tbody className="flex flex-col w-full gap-2 h-[40lvh] overflow-y-auto">
                <tr>
                    <td className="flex flex-col items-center justify-center w-full h-full gap-2">
                        <BsDatabaseExclamation size={28} fill="#EFB81C50" />
                        <p className="text-[12px] text-[#15141580]  dark:text-[#FFFFFF] font-[500] text-center w-max">
                            No open orders found.
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default OpenOrdersTable;
