import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { forgotCode, sendForgotCode } from "store/actions/authAction";
import {
    getUserIP,
    getCountryCode,
} from "../../../UserLocation/geolocationUtils";
import { FaCircleExclamation } from "react-icons/fa6";
import CaptchaModal from "views/auth/captchaSlider";
import sliderCaptchaImgCreate from "helpers/sliderCaptchaImgCreate";

const ForgetPassword = () => {
    const [toggler, setToggler] = useState(1);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [inputHasValue, setInputHasValue] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [defaultCountry, setDefaultCountry] = useState("");
    const Semail = useSelector((state) => state.authCheck.authStatus.email);
    const [showCaptchaModal, setShowCaptchaModal] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);
    const [captchaImage, setCaptchaImage] = useState({
        bgUrl: "",
        puzzleUrl: "",
    });
    const [captchaVal, setCaptchaVal] = useState(-1);

    const handleCaptchaSuccess = async () => {
        onCaptchaSuccess()
    };

    const handleCaptchaFailure = () => {
        setCaptchaVerified(false);
        // setShowCaptchaModal(false);
    };

    const showCaptchaModel = async (e) => {
        e.preventDefault();
        setShowCaptchaModal(true)

        await refetchCaptcha();

        await setNextLoading(false);
        await setShowCaptchaModal(true);
    };

    const refetchCaptcha = async () => {
        await setShowCaptchaModal(false);
        await setNextLoading(true);
        const val = await sliderCaptchaImgCreate();
        await setCaptchaVal(val.left);

        await setCaptchaImage({
            bgUrl: val.bg,
            puzzleUrl: val.puzzle,
        });
  
    };

    useEffect(() => {
        const initialize = async () => {
            const ip = await getUserIP();
            if (ip) {
                const countryCode = await getCountryCode(ip);
                if (countryCode) {
                    setDefaultCountry(countryCode);
                }
            }
        };

        initialize();
    }, []);

    useEffect(() => {
        const isEmailValid = email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        if ((toggler === 1 && isEmailValid) || (toggler === 2 && phone)) {
            setInputHasValue(true);
        } else {
            setInputHasValue(false);
        }
    }, [email, phone, toggler]);

    const sendVerificationCode = async (e) => {
        e.preventDefault();

    // Show Captcha Modal first
    setShowCaptchaModal(true);
};

const onCaptchaSuccess = async () => {
    setShowCaptchaModal(false); // Close captcha modal

    // Continue with sending the verification code
    if (toggler === 1) {
        let payload = { email };    
      
        console.log("payload email",payload)
        try {
            const isSuccess = await dispatch(forgotCode(payload));

            if (isSuccess) {
                console.log("Email check data",email)
                localStorage.setItem("resetEmail", email); // Store email in localStorage
                localStorage.removeItem("resetPhone"); // Ensure phone is cleared
                history.push("/user/reset-password");
            }
        } catch (error) {
            console.error("Error in sendVerificationCode:", error);
        }
    } else {
        let payload = { phone };

        try {
            console.log("payload phone",payload)
            const isSuccess = await dispatch(sendForgotCode(payload));
            console.log("isSuccess",isSuccess)
            if (isSuccess) {
                localStorage.setItem("resetPhone", phone); // Store phone in localStorage
                localStorage.removeItem("resetEmail"); // Ensure email is cleared
                history.push("/user/reset-password");
            }
        } catch (error) {
            console.error("Error in sendVerificationCode:", error);
        }
    }
};
const getCaptchaHandler = async (e) => {
    e.preventDefault();
    await setShowCaptchaModal(false);
    await setNextLoading(true);
    const val = await sliderCaptchaImgCreate();
    await setCaptchaVal(val.left);

    await setCaptchaImage({
        bgUrl: val.bg,
        puzzleUrl: val.puzzle,
    });

    await setShowCaptchaModal(true);
    await setNextLoading(false);
    localStorage.setItem("active_email", email);
};

    return (
        <div className="fixed inset-0 w-full h-full bg-[#F4F4F4] dark:bg-[#151415]">
            <div className="w-[30%] max-w-[450px] h-max m-auto p-8 absolute inset-0 bg-[#FFFFFF] dark:bg-[#1A191B] flex gap-2 items-center justify-center text-[#151415] dark:text-[#F4F4F4] rounded-lg">
                <div className="flex flex-col gap-2">
                    <form onSubmit={sendVerificationCode}>
                        <div className="mb-2">
                            <h3 className="mb-4 text-xl font-bold">
                                Reset Password
                            </h3>
                            <div className="flex items-center gap-8 border-b pb-1.5 border-[#F4F4F4] w-full mb-1">
                                <button
                                    type="button"
                                    className={`relative text-[13px] transition-all ${
                                        toggler === 1
                                            ? "active text-[#EFB81C]"
                                            : "not-active"
                                    }`}
                                    onClick={() => setToggler(1)}
                                    role="tab"
                                    aria-selected={toggler === 1}
                                >
                                    Email
                                </button>
                                <button
                                    type="button"
                                    className={`relative text-[13px] transition-all  ${
                                        toggler === 2
                                            ? "active text-[#EFB81C]"
                                            : "not-active"
                                    }`}
                                    onClick={() => setToggler(2)}
                                    role="tab"
                                    aria-selected={toggler === 2}
                                >
                                    Mobile Number
                                </button>
                                <style>
                                    {`.${
                                        toggler === 1 ? "active" : "not-active"
                                    }:after {
                                    content: "";
                                    position: absolute;
                                    bottom: -6px;
                                    left: 0;
                                    width: 100%;
                                    height: 2px;
                                    border-radius: 10px;
                                    background-color: ${
                                        toggler === 1
                                            ? "#EFB81C"
                                            : "transparent"
                                    };
                                }`}
                                    {`.${
                                        toggler === 2 ? "active" : "not-active"
                                    }:after {
                                    content: "";
                                    position: absolute;
                                    bottom: -6px;
                                    left: 0;
                                    width: 100%;
                                    height: 2px;
                                    border-radius: 10px;
                                    background-color: ${
                                        toggler === 2
                                            ? "#EFB81C"
                                            : "transparent"
                                    };
                                }`}
                                </style>
                            </div>
                        </div>
                        <div className="bg-[#fff5e0] mb-4 p-2 flex items-start gap-1 rounded-md">
                            <FaCircleExclamation className="text-xs w-1/6 mt-0.5 text-[#EFB81C]" />
                            <p className="text-xs font-normal">
                                Important: After changing your account password,
                                on-chain withdrawals, internal transfers, fiat
                                withdrawals, P2P transaction, and BuyCex Card
                                transactions will be temporarily suspended for
                                24 hours.
                            </p>
                        </div>
                        <div
                            className={
                                toggler === 1 ? "flex flex-col gap-2" : "hidden"
                            }
                        >
                            <label className="text-sm">Email</label>
                            <input
                                type="email"
                                className="w-full min-w-[300px] px-3 py-2.5 bg-gray-100 placeholder:text-[12px] text-[13px] focus:ring-0 ring-0 hover:border-[#EFB81C] focus:outline-none border border-gray-100 dark:border-[#F4F4F4] focus:border-red-400 rounded-md"
                                placeholder="Enter your email"
                                id="email"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div
                            className={
                                toggler === 2 ? "flex flex-col gap-2" : "hidden"
                            }
                        >
                            <label className="text-sm">Phone</label>
                            <PhoneInput
                                country={defaultCountry}
                                value={phone}
                                onChange={setPhone}
                                inputClass="w-full min-w-[300px] px-3 py-2.5 bg-transparent placeholder:text-gray-300 placeholder:text-[12px] text-[13px] focus:ring-0 ring-0 hover:border-[#EFB81C] focus:outline-none border border-[#151415] dark:border-[#F4F4F4] focus:border-red-400 rounded-md"
                                containerClass="w-full"
                                inputProps={{
                                    name: "phone",
                                    id: "phone",
                                    autoComplete: "phone",
                                }}
                                placeholder="Enter your phone"
                            />
                        </div>
                        <button
                            type="submit"
                            onClick={getCaptchaHandler}
                            className={`btn font-semibold text-[14px] py-2 mt-6 w-full bg-[#EFB81C] ${
                                inputHasValue
                                    ? "opacity-100 cursor-pointer"
                                    : "opacity-50 cursor-not-allowed"
                            }`}
                            disabled={!inputHasValue}
                        >
                            Next
                        </button>
                    </form>
                    {showCaptchaModal && (
                    <CaptchaModal
                        isOpen={showCaptchaModal}
                        onClose={() => setShowCaptchaModal(false)}
                        onSuccess={handleCaptchaSuccess}
                        onFailure={handleCaptchaFailure}
                        captchaVal={captchaVal}
                        captchaImage={captchaImage}
                        getCaptchaHandler={showCaptchaModel}
                    />
                )}
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
