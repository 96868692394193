// src/redux/actions.js
import IssueRepository from 'repository/IssueRepository';
import {
  FETCH_ISSUES_REQUEST,
  FETCH_ISSUES_SUCCESS,
  FETCH_ISSUES_FAILURE,
} from '../types';

export const fetchIssues = (token, userId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_ISSUES_REQUEST });
    try {
      const issues = await IssueRepository.fetchIssues(token, userId);
     
      dispatch({ type: FETCH_ISSUES_SUCCESS, payload: issues });
    } catch (error) {
      dispatch({ type: FETCH_ISSUES_FAILURE, payload: error.message });
    }
  };
};
