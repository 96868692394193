import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RiShieldStarFill } from "react-icons/ri";
import { fetchUserProfile } from "store/actions/userProfileAction";
import {
    changePassword,
    fetchAuthStatus,
    updatePassword,
} from "store/actions/userSecuritySettingsAction";
import toast from "react-hot-toast";

import { BsShieldLockFill } from "react-icons/bs";
import AdvancedSettings from "./AdvancedSettings";
import BasicSettings from "./BasicSettings";
import SecurityStatus from "./SecurityStatus";
import UserGuideModal from "./UserGuideModal";
import MobileSetOn2FAInActiveModal from "./MobileSetOn2faInActive";
import MobileSetOn2FAActiveModal from "./MobileSetOn2faActive";
import MobileChangeModal from "./MobileChangeModal";
import GoogleAuthenticatorSetModal from "./GoogleAuthenticatorSetModal";
import GoogleAuthenticatorChangeModal from "./GoogleAuthenticatorChangeModal";
import PasswordChangeModal from "./PasswordChangeModal";
import ConfirmationPopUp from "./ConfirmationPopUp";
import LoginPasswordModal from "./LoginPasswordModal";
import MobileNoChangeModal from "./MobileNoChangeModal";

const SecuritySettingsNew = () => {
    const [modalShiftTimer, setModalShiftTimer] = useState(60);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationText, setConfirmationText] = useState("");
    const [confirmationAction, setConfirmationAction] = useState(
        () => () => {}
    );

    const [showPasswordChangeModal, setShowPasswordChangeModal] =
        useState(false);
    const [
        showGoogleAuthenticatorChangeModal,
        setShowGoogleAuthenticatorChangeModal,
    ] = useState(false);
    const [
        showGoogleAuthenticatorSetModal,
        setShowGoogleAuthenticatorSetModal,
    ] = useState(false);
    const [showMobileChangeModal, setShowMobileChangeModal] = useState(false);
    const [showMobileSetModalOn2FAActive, setShowMobileSetModalOn2FAActive] =
        useState(false);
    const [
        showMobileSetModalOn2FAInActive,
        setShowMobileSetModalOn2FAInActive,
    ] = useState(false);
    const [showUserGuideModal, setShowUserGuideModal] = useState(false);

    const [changePasswordAlertMessage, setChangePasswordAlertMessage] =
        useState(null);
    const [changeGoogleAuthAlertMessage, setChangeGoogleAuthAlertMessage] =
        useState(null);
    const [setGoogleAuthAlertMessage, setSetGoogleAuthAlertMessage] =
        useState(null);
    const [changeMobileAlertMessage, setChangeMobileAlertMessage] =
        useState(null);
    const [
        setMobileOn2FAActiveAlertMessage,
        setSetMobileOn2FAActiveAlertMessage,
    ] = useState(null);
    const [
        setMobileOn2FAInActiveAlertMessage,
        setSetMobileOn2FAInActiveAlertMessage,
    ] = useState(null);

    const { userProfile } = useSelector((state) => state.userInfo);
    const { changePasswordMessage, changePasswordError } = useSelector(
        (state) => state.changePassword
    );
    const { authStatus, authStatusError } = useSelector(
        (state) => state.authCheck
    );
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;

    const isMailVerified = !userProfile?.email ? "False" : "True";
    const antiPhishingValue = !userProfile?.antiphishing ? "False" : "True";
    const withdrawalPasswordValue = !userProfile?.withdrawpass
        ? "False"
        : "True";
    const g2faVerificationValue = !userProfile?.ga ? "False" : "True";

    const [secureLoggingDuration, setSecureLoggingDuration] = useState(false);
    const [googleAccountBinding] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (!userId || !token) {
            history.push("/login");
        } else {
            dispatch(fetchUserProfile(userId, token));
            dispatch(fetchAuthStatus(userId, token));
        }
    }, [userId, token, history, dispatch]);

    useEffect(() => {}, [authStatus]);

    const handlePasswordChange = async (oldpassword, newpassword) => {
        const payload = {
            id: userId,
            token: token,
            oldpassword: oldpassword,
            newpassword: newpassword,
        };
        return await dispatch(changePassword(payload));
        // updatechangePasswordAlertMessage();
    };

    const updatePasswordHandler = async ({
        oldPassword,
        newPassword,
        confirmNewPassword,
    }) => {
        const payload = {
            oldPassword,
            newPassword,
            confirmNewPassword,
            id: userId,
            token: token,
        };

        return await dispatch(updatePassword(payload));
    };

    const handleOTPInputForChange = (otpcode) => {
        console.log("otpcode: ", otpcode);
    };

    const handleOTPInputForSet = (otpcode) => {
        console.log("otpcode: ", otpcode);
    };

    const handleMobileChange = (phone, phoneotp) => {
        console.log("mobile: ", phone, "phoneotp: ", phoneotp);
    };

    const handleMobileSetOn2faActive = (phone, phoneotp) => {
        console.log("mobile: ", phone, "phoneotp: ", phoneotp);
    };

    const handleMobileSeton2faInActive = (phone, phoneotp) => {
        console.log("mobile: ", phone, "phoneotp: ", phoneotp);
    };

    const closeAlert = () => {
        setChangePasswordAlertMessage(null);
        setChangeGoogleAuthAlertMessage(null);
        setSetGoogleAuthAlertMessage(null);
        setChangeMobileAlertMessage(null);
        setSetMobileOn2FAActiveAlertMessage(null);
        setSetMobileOn2FAInActiveAlertMessage(null);
    };
    const [showLoginPasswordModal, setShowLoginPasswordModal] = useState(false);

    const handleConfirmation = (text, action) => {
        if (!authStatus.ga || authStatus.ga.trim() === "") {
            toast.error("Please Set 2fa first to change password", {
                id: "error-toast",
            });
        } else {
            // setConfirmationText(text);
            // setConfirmationAction(() => action);
            setShowNewMobileModal(true);
        }
    };


    const handlePasswordChangeClick = () => {
        if (!authStatus.ga || authStatus.ga.trim() === "") {
            toast.error("Please Set 2fa first to change password", {
                id: "error-toast",
            });
        } else {
            setShowLoginPasswordModal(true);
            console.log(authStatus);
        }
    };

    const handleGoogleAuthenticatorChangeClick = () => {
        setConfirmationText(
            "Are you sure you want to proceed with changing your Google Authenticator settings?"
        );
        setConfirmationAction(() => () =>
            setShowGoogleAuthenticatorChangeModal(true)
        );
        setShowConfirmationModal(true);
    };
    

    const handleUserGuideClose = () => {
        // sessionStorage.setItem("userGuideShown", "true");
        setShowUserGuideModal(false);
        setShowGoogleAuthenticatorChangeModal(true);
    };

    const [showNewMobileModal, setShowNewMobileModal] = useState(false);

    const handleNewMobileChange = (phone, phoneotp) => {
        console.log("mobile: ", phone, "phoneotp: ", phoneotp);
        setShowNewMobileModal(true);
    };

    return (
        <div className="w-full px-0 lg:px-3 mt-10">
            <div className="flex items-center gap-2">
                <div>
                    <span className="text-[24px] uppercase font-semibold">
                        Security Status
                    </span>
                </div>
            </div>
            {authStatusError && (
                <div className="bg-red-200 text-red-800 p-4 mb-4 rounded-lg flex justify-between items-center">
                    <span>{authStatusError}</span>
                </div>
            )}
            <SecurityStatus
                authStatus={authStatus}
                antiPhishing={antiPhishingValue}
                withdrawalPassword={withdrawalPasswordValue}
            />
            <BasicSettings
                authStatus={authStatus}
                userProfile={userProfile}
                onPasswordChangeClick={() =>
                    handlePasswordChangeClick(
                        "For your account security, the withdrawal service will not be available for 24 hours after resetting password.",
                        () => setShowPasswordChangeModal(true)
                    )
                }
                isMailVerified={isMailVerified}
                onGoogleAuthenticatorChangeClick={
                    handleGoogleAuthenticatorChangeClick
                }
                onGoogleAuthenticatorSetClick={() => {
                    authStatus.email
                        ? setShowGoogleAuthenticatorSetModal(true)
                        : toast.error("Please set email first.");
                }}
                onMobileChangeClick={() =>
                    handleConfirmation(
                        "For your account security, the withdrawal service will not be available for 24 hours after changing Mobile",
                        () => setShowNewMobileModal(true)
                    )
                }
                onMobileSetWhen2faActiveClick={() =>
                    handleConfirmation(
                        "For your account security, the withdrawal service will not be available for 24 hours after setting Mobile.",
                        () => setShowMobileSetModalOn2FAActive(true)
                    )
                }
                onMobileSetWhen2faInActiveClick={() =>
                    handleConfirmation(
                        "For your account security, the withdrawal service will not be available for 24 hours after setting Mobile.",
                        () => setShowMobileSetModalOn2FAInActive(true)
                    )
                }
            />

            <AdvancedSettings
                authStatus={authStatus}
                antiPhishing={antiPhishingValue}
                withdrawalPassword={withdrawalPasswordValue}
                g2faVerificationValue={g2faVerificationValue}
                secureLoggingDuration={secureLoggingDuration}
                setSecureLoggingDuration={setSecureLoggingDuration}
                googleAccountBinding={googleAccountBinding}
            />
            <ConfirmationPopUp
                show={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onSubmit={() => {
                    setShowConfirmationModal(false);
                    confirmationAction();
                }}
                text={confirmationText}
            />
            {showLoginPasswordModal && (
                <LoginPasswordModal
                    show={showLoginPasswordModal}
                    onClose={() => setShowLoginPasswordModal(false)}
                    onSubmit={({
                        oldPassword,
                        newPassword,
                        confirmNewPassword,
                    }) =>
                        updatePasswordHandler({
                            oldPassword,
                            newPassword,
                            confirmNewPassword,
                        })
                    }
                />
            )}
            <PasswordChangeModal
                show={showPasswordChangeModal}
                onClose={() => setShowPasswordChangeModal(false)}
                onSubmit={handlePasswordChange}
                AlertMessage={changePasswordAlertMessage}
                closeAlert={closeAlert}
                setAlertMessage={setChangePasswordAlertMessage}
                changePasswordMessage={changePasswordMessage}
                email={authStatus.email}
                username={authStatus.username}
                phone={authStatus.cellphone}
                userId={userId}
                token={token}
            />
            <GoogleAuthenticatorChangeModal
                show={showGoogleAuthenticatorChangeModal}
                onClose={() => setShowGoogleAuthenticatorChangeModal(false)}
                onSubmit={handleOTPInputForChange}
                closeAlert={closeAlert}
                AlertMessage={changeGoogleAuthAlertMessage}
                setAlertMessage={setChangeGoogleAuthAlertMessage}
                email={authStatus.email}
                username={authStatus.username}
                userId={userId}
                token={token}
            />
            <GoogleAuthenticatorSetModal
                show={showGoogleAuthenticatorSetModal}
                onClose={() => setShowGoogleAuthenticatorSetModal(false)}
                onSubmit={handleOTPInputForSet}
                closeAlert={closeAlert}
                AlertMessage={setGoogleAuthAlertMessage}
                setAlertMessage={setSetGoogleAuthAlertMessage}
                email={authStatus.email}
                username={authStatus.username}
                userId={userId}
                token={token}
            />
            <MobileNoChangeModal
                show={showNewMobileModal}
                onClose={() => setShowNewMobileModal(false)}
                onSubmit={handleNewMobileChange}
                closeAlert={closeAlert}
                AlertMessage={changeMobileAlertMessage}
                setAlertMessage={setChangeMobileAlertMessage}
                email={authStatus.email}
                username={authStatus.username}
                phone={authStatus.cellphone}
                userId={userId}
                token={token}
            />
            <MobileSetOn2FAActiveModal
                show={showMobileSetModalOn2FAActive}
                onClose={() => setShowMobileSetModalOn2FAActive(false)}
                onSubmit={handleMobileSetOn2faActive}
                closeAlert={closeAlert}
                AlertMessage={setMobileOn2FAActiveAlertMessage}
                setAlertMessage={setSetMobileOn2FAActiveAlertMessage}
                email={authStatus.email}
                username={authStatus.username}
                phone={authStatus.cellphone}
            />
            <MobileSetOn2FAInActiveModal
                show={showMobileSetModalOn2FAInActive}
                onClose={() => setShowMobileSetModalOn2FAInActive(false)}
                onSubmit={handleMobileSeton2faInActive}
                closeAlert={closeAlert}
                AlertMessage={setMobileOn2FAInActiveAlertMessage}
                setAlertMessage={setSetMobileOn2FAInActiveAlertMessage}
                email={authStatus.email}
                username={authStatus.username}
                phone={authStatus.cellphone}
            />
            <UserGuideModal
                show={showUserGuideModal}
                onClose={handleUserGuideClose}
            />
            {/* Add UserGuideModal */}
        </div>
    );
};

export default SecuritySettingsNew;
