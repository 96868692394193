import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";

import buycex from "assets/img/header/buycex-official-logo.png";
import X from "assets/img/X.svg";
import insta from "assets/img/insta.svg";
import twitter from "assets/img/twitter.svg";
import youtube from "assets/img/youtube.svg";
import whatsapp from "assets/img/whatsapp.svg";
import tiktokDark from "assets/img/tiktokDark.svg";
import footerIcon4 from "assets/img/footerIcon4.svg";
import facebook from "assets/img/facebook.svg";

const ScrollToTopLink = ({ to, children, ...props }) => {
    const history = useHistory();

    const handleClick = (event) => {
        event.preventDefault();
        history.push(to);
        window.scrollTo(0, 0);
    };

    return (
        <Link to={to} onClick={handleClick} {...props}>
            {children}
        </Link>
    );
};
const MenuSection = ({ title, index, links, openMenu, toggleMenu }) => (
    <div className="flex flex-col w-full menu-section">
        <div
            onClick={() => toggleMenu(index)}
            className="text-xl font-semibold text-[#EFB81C] transition-all ease-in-out duration-300 cursor-pointer w-max mb-2 mt-4 flex justify-between items-center gap-2 md:gap-0"
        >
            {title}
            <FaChevronDown className="text-sm lg:hidden" />
        </div>
        {openMenu[index] && (
            <div className="flex flex-col w-full links">
                {links.map((link, idx) => (
                    <ScrollToTopLink
                        key={idx}
                        to={link.url}
                        className="mt-3 hover:no-underline hover:text-[#EFB81C] transition-all ease-in-out duration-300"
                    >
                        {link.label}
                    </ScrollToTopLink>
                ))}
            </div>
        )}
        <div className="flex-col hidden w-full links lg:flex hover:no-underline">
            {links.map((link, idx) => (
                <ScrollToTopLink
                    key={idx}
                    to={link.url}
                    className="mt-3 hover:no-underline hover:text-[#EFB81C] transition-all ease-in-out duration-300"
                >
                    {link.label}
                </ScrollToTopLink>
            ))}
        </div>
    </div>
);

const SocialIcons = () => {
    const icons = [
        facebook,
        X,
        insta,
        footerIcon4,
        youtube,
        tiktokDark,
        twitter,
        whatsapp,
    ];
    return (
        <div className="flex flex-wrap gap-3 mt-6">
            {icons.map((icon, idx) => (
                <div
                    key={idx}
                    className="flex rounded-full justify-between items-center bg-[#DBDBDB] w-[40px] h-[40px] md:w-[30px] md:h-[30px] hover:opacity-60 ease-in-out transition-opacity duration-300"
                >
                    <img loading="lazy" src={icon} alt="" />
                </div>
            ))}
        </div>
    );
};

const Footer = () => {
    const [openMenu, setOpenMenu] = useState({});
    const [isVisible, setIsVisible] = useState(false);
    const [details, setDetails] = useState(null);

    const handleRowClick = (ShowDetails) => {
        setDetails(ShowDetails);
        setIsVisible(true);
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    const toggleMenu = (index) => {
        setOpenMenu((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const menus = [
        {
            title: "About",
            index: 1,
            links: [
                { label: "Referral", url: "/referral" },
                { label: "Submit Case", url: "/submit-case" },
                { label: "Buycex Communities", url: "/communities" },
                { label: "Risk Disclosure", url: "/risk-disclosure" },
                { label: "Announcements", url: "/announcements" },
                { label: "Proof of Reserve", url: "/proof-of-reserve" },
                { label: "Careers", url: "#!" },
            ],
        },
        {
            title: "Services",
            index: 2,
            links: [
                { label: "About Us", url: "/about-buycex" },
                { label: "Privacy Policy", url: "/privacy-policy" },
                { label: "Help Center", url: "/help-center-list" },
                { label: "Terms and Condition", url: "/terms-and-condition" },
                { label: "Public Voting", url: "/public-voting" },
                { label: "Spot X", url: "/spot-x" },
                { label: "Tax API", url: "#!" },
                { label: "Audit", url: "#!" },
            ],
        },
        {
            title: "Support",
            index: 3,
            links: [
                { label: "Submit a Request", url: "#!" },
                { label: "Help Center", url: "/help-center" },
                { label: "User Feedback", url: "#!" },
                { label: "Buycex Learn", url: "#!" },
                { label: "Trading Fee", url: "#!" },
                { label: "API", url: "#!" },
                { label: "Authenticity Check", url: "#!" },
                { label: "P2P FAQ", url: "#!" },
            ],
        },
        {
            title: "Products",
            index: 4,
            links: [
                { label: "Trade", url: "/trade/spot" },
                { label: "Derivatives", url: "#!" },
                { label: "Earn", url: "/assets/earn" },
                { label: "Launchpad", url: "#!" },
                { label: "NFT", url: "#!" },
                { label: "Buycex Card", url: "#!" },
            ],
        },
    ];

    return (
        <footer className="footer w-full h-full dark:bg-black bg-[#fff]">
            <div className="footer-inner container dark:bg-black bg-[#ffffff] flex flex-col justify-between items-center">
                <div className="flex flex-col items-start justify-between w-full py-4 border-b footer-titles lg:py-8 lg:flex-row">
                    {menus.map((menu) => (
                        <MenuSection
                            key={menu.index}
                            title={menu.title}
                            index={menu.index}
                            links={menu.links}
                            openMenu={openMenu}
                            toggleMenu={toggleMenu}
                        />
                    ))}
                </div>
                <SocialIcons />
                <div className="flex flex-col items-center justify-center text-xs lg:flex-row my-14 text-neutral-400">
                    <div className="flex gap-1 self-start px-5 lg:text-[18px] text-[16px] hover:opacity-60 ease-in-out transition-opacity duration-300">
                        <span>2018-2024 Buycex.com. All rights reserved.</span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
