import { RepositoryFactory } from "repository/RepositoryFactory";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

const commonRepo = RepositoryFactory.get("commonRepo");

export const cryptoDepositHistory = (userId, token) => async (dispatch) => {
    dispatch({type: "FETCH_CRYPTO_DEPOSIT_HISTORY_REQUEST"});
    try {
        const response = await commonRepo.crypto_deposit_history(userId, token);
        const res = response.data.data
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_CRYPTO_DEPOSIT_HISTORY_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: res };
        } else {
            dispatch({
                type: "FETCH_CRYPTO_DEPOSIT_HISTORY_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)            
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_CRYPTO_DEPOSIT_HISTORY_FAILURE", payload: error.message });
        return false;
    }
};

export const cryptoWithdrawHistory = (userId, token) => async (dispatch) => {
    dispatch({type: "FETCH_CRYPTO_WITHDRAW_HISTORY_REQUEST"});
    try {
        const response = await commonRepo.crypto_withdraw_history(userId, token);
        const res = response.data.data
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_CRYPTO_WITHDRAW_HISTORY_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: res };
        } else {
            dispatch({
                type: "FETCH_CRYPTO_WITHDRAW_HISTORY_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)            
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_CRYPTO_WITHDRAW_HISTORY_FAILURE", payload: error.message });
        return false;
    }
};

export const transferHistory = (userId, token) => async (dispatch) => {
    dispatch({ type: "FETCH_TRANSFER_HISTORY_REQUEST" });
    try {
      const response = await commonRepo.transfer_history(userId, token); // Ensure this is defined
      const res = response.data;
      console.log("API Response: ", res);
  
      if (res.status === 1) {
        dispatch({
          type: "FETCH_TRANSFER_HISTORY_SUCCESS",
          payload: res.data, // API data array
        });
        return { success: true, data: res.data };
      } else {
        dispatch({
          type: "FETCH_TRANSFER_HISTORY_FAILURE",
          payload: res.message || "An error occurred",
        });
        toast.error(res.message || "Failed to fetch transfer history.");
        return false;
      }
    } catch (error) {
      dispatch({
        type: "FETCH_TRANSFER_HISTORY_FAILURE",
        payload: error.message,
      });
      toast.error("Failed to fetch transfer history.");
      return false;
    }
  };
  export const convertTradeHistory = (userId, token) => async (dispatch) => {
    dispatch({ type: "FETCH_CONVERT_TRADE_HISTORY_REQUEST" }); // Dispatch request action
    try {
        const response = await commonRepo.convert_trade_history(userId, token); // Call repository method
        console.log("Response of the api",response)
        const res = response.data;
        console.log("Response of the api",res)
        if (res.status === 1) {
            dispatch({
                type: "FETCH_CONVERT_TRADE_HISTORY_SUCCESS",
                payload: res.data, // API data array
            });
            return { success: true, data: res.data };
        } else {
            dispatch({
                type: "FETCH_CONVERT_TRADE_HISTORY_FAILURE",
                payload: res.message || "An error occurred",
            });
            toast.error(res.message || "Failed to fetch convert trade history.");
            return false;
        }
    } catch (error) {
        dispatch({
            type: "FETCH_CONVERT_TRADE_HISTORY_FAILURE",
            payload: error.message,
        });
        toast.error("Failed to fetch convert trade history.");
        return false;
    }
};
