import React, { useState } from 'react';
import HistoryDropdown from '../../SpotHistory/DropDownUI';
import { DatePicker, Modal } from 'antd';
import { createGlobalStyle, styled } from "styled-components";
import dayjs from 'dayjs';
import StakingTable from './StakingTable';

const { RangePicker } = DatePicker;

function Staking() {
    const [selectedOption, setSelectedOption] = useState("Past 180 days");
    const options2 = ["Stake", "Distribution", "Wrap BETH", "Unwrap WBETH", "Redemption"];
    const option3 = ["Past 7 days", "Past 30 days", "Past 90 days",, "Past 180 days", "Customized"];


    const [selectedDates, setSelectedDates] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleSelect1 = (selected) => {
        console.log("Dropdown 1 selected:", selected);
    };

    const handleSelect2 = (selected) => {
        if (selected === "Customized") {
            setIsModalVisible(true);
        } else {
            setSelectedOption(selected);
        }
    };

    const handleOk = () => {
        if (selectedDates && selectedDates.length === 2) {
            const formattedStartDate = dayjs(selectedDates[0]).format('YYYY-MM-DD');
            const formattedEndDate = dayjs(selectedDates[1]).format('YYYY-MM-DD');
            setSelectedOption(`${formattedStartDate} - ${formattedEndDate}`);
        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            <nav className='inline-flex flex-wrap border rounded-lg pt-[0.20rem] pb-[0.25rem] px-1 gap-2'>
                <button
                    className={`w-auto py-1.5 px-3 text-sm font-medium bg-[#F5F5F5] rounded-md`}
                >
                    ETH Staking
                </button>
            </nav>
            <br />
            <div className='mt-6 flex gap-4'>
                <HistoryDropdown
                    label="Type"
                    options={options2}
                    initialSelected="Stake"
                    onSelect={handleSelect1}
                    isSearchable={false}
                    width="200px"
                />
                <HistoryDropdown
                label="Time"
                options={option3}
                initialSelected={selectedOption}
                onSelect={handleSelect2}
                isSearchable={false}
                width="120px"
            />
            <Modal
                title="Select Date Range"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <div className="flex flex-col gap-4">
                    <p className='mt-10'>Select your time range within 12 months.</p>
                    <GlobalStyles />
                    <StyledRangePicker
                        onChange={(dates) => setSelectedDates(dates)}
                    />
                    <button key="ok" type="primary" onClick={handleOk} className='px-4 py-2 my-7 font-semibold bg-[#EFB81C] rounded-lg'>Continue</button>
                </div>
            </Modal>
                <button className='px-4 py-2 rounded-lg font-medium'>Reset</button>
                <button className='bg-[#EFB81C] px-4 py-2 rounded-lg font-medium'>Search</button>
            </div>
            <StakingTable />
        </div>
    );
}

const GlobalStyles = createGlobalStyle`
  .ant-picker:hover {
    border-color: #EFB81C !important;
  }

  .ant-picker-focused {
    border-color: #EFB81C !important;
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important;
  }

  .ant-picker-active-bar {
    background: #EFB81C !important;
  }

  .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EFB81C !important;
    color: white !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    border: 1px solid #EFB81C !important;
    border-radius: 4px;
    content: "";
  }

  .ant-picker-dropdown .ant-picker-header-view > button:hover {
    color: #EFB81C !important;
  }
`;

const StyledRangePicker = styled(RangePicker)`
  .ant-picker-input > input:focus {
    box-shadow: none !important;
    border-color: #EFB81C !important;
  }
`;

export default Staking;
