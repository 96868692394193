import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GoogleIcon from "assets/img/GoogleOTPSVG.png";
import YellowTick from "assets/img/yellow-bg-tick.png";
import EmailVerification from "./helpers/EmailVerify";
import G2faVerification from "./helpers/G2faVerify";
import { forgotCode } from "store/actions/authAction";
import QRCodeDisplay from "./helpers/QRCodeDisplay";
import { fetchAuthStatus } from "store/actions/userSecuritySettingsAction";
import { Modal } from "antd";

const GoogleAuthenticatorSetModal = ({
    show,
    onClose,
    onSubmit,
    AlertMessage,
    closeAlert,
    setAlertMessage,
    SuccessMessage,
    email,
    username,
    userId,
    token,
}) => {
    const [step, setStep] = useState(1);
    const [newOTP, setNewOTP] = useState("");
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);

    const dispatch = useDispatch();

    useEffect(() => {
        const handleClickOutside = (event) => {
            const modal = document.querySelector(".modal-inner");
            if (modal && !modal.contains(event.target)) {
                onClose();
            }
            const outermodal = document.querySelector(".modal-outer");
            if (outermodal && !modal.contains(event.target)) {
                onClose();
            }
        };

        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show, onClose]);

    const handleNextStep = () => {
        if (step < 3) {
            setStep(step + 1);
        }
    };

    if (!show) return null;

    const handleCancel = () => {
        setStep(0);
        setNewOTP("");
        onClose();
        if (userID && userToken) {
            dispatch(fetchAuthStatus(userID, userToken));
        }
    };

    return (
        <Modal
            visible={show}
            onCancel={handleCancel}
            footer={null}
            title={null}
            centered
            className="modal-inner w-1/3"
        >
            {step === 1 && <EmailVerification onSuccess={handleNextStep} />}
            {step === 2 && (
                <div>
                    <div className="flex flex-col w-full">
                        <p className="text-2xl font-semibold">
                            Link an Authenticator
                        </p>
                        <p className="text-sm text-[#81858c] my-3 mb-4">
                            Scan this QR code in authenticator app
                        </p>

                        <QRCodeDisplay userId={userId} token={token} />
                        <p className="text-center text-sm text-[#81858c]">
                            If you are unable to scan to QR code, please enter
                            this code manually into the app.
                        </p>
                        <p className="text-gray-300 text-[10px] my-3 text-left">
                            1. Download and install OTP Authenticator Apps, such
                            us Google Authenticator.
                            <br />
                            2. Add your BUYCEX account name and key above in the
                            Authenticator App
                        </p>
                    </div>

                    <div className="flex justify-between">
                        <button
                            className={`bg-yellow-500 text-black font-medium py-2 px-4 rounded focus:outline-none w-full`}
                            onClick={handleNextStep}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            )}
            {step === 3 && (
                <G2faVerification
                    onSuccess={handleCancel}
                    emailasprop={email}
                    userId={userId}
                    token={token}
                    type="add"
                />
            )}
        </Modal>
    );
};

export default GoogleAuthenticatorSetModal;
