import React, { useEffect, useState } from "react";
import StepOneContent from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { doNewAd } from "store/actions/p2pActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { resetState } from "store/reducers/stepOneSlice";
import toast from "react-hot-toast";

const steps = [
    { title: "Set Type & Price" },
    { title: "Set Total Amount & Payment Method" },
    { title: "Set Remarks & Automatic Response" },
];

function PostAds() {
    const [current, setCurrent] = useState(0);
    const [mode, setMode] = useState("buy");
    const [isFixed, setIsFixed] = useState(true);
    const [value, setValue] = useState(0);
    const [val, setVal] = useState(1);
    const [selectedCrypto, setSelectedCrypto] = useState("");
    const [selectedFiat, setSelectedFiat] = useState("");
    const [resultValue, setResultValue] = useState("");
    const [currentVal, setCurrentVal] = useState("0.00");
    const [totalamount, settotalamount] = useState(0);
    const [fromamount, setfromamount] = useState(0);
    const [toamount, settoamount] = useState(0);
    const [time, settime] = useState(0);
    const [description, setdescription] = useState("");
    const [autoreply, setautoreply] = useState("");
    const [checkitem, setcheckitem] = useState("");
    const [statuschange, onStatusChange] = useState("");
    const [regtimeinput, setregtimeinput] = useState("");
    const [balanceinput, setbalanceinput] = useState("");
    const [float, setFloat] = useState(100);
    const dispatch = useDispatch();
    const history = useHistory();
    const [modeValue, setModeValue] = useState(1); // Initial state for mode value
    const state = useSelector((state) => state.stepone);
    console.log("staet", state);

    const handleModeChange = (newMode) => {
        setMode(newMode);
        setModeValue(newMode === "buy" ? 1 : 2); // Set mode value based on the selected mode
        dispatch(resetState());
    };

    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;

    const next = () => setCurrent((prev) => prev + 1);
    const prev = () => setCurrent((prev) => prev - 1);

    const increment = () => {
        setValue((prev) =>
            isFixed ? prev + 1 : parseFloat((prev + 0.01).toFixed(2))
        );
    };

    const decrement = () => {
        setValue((prev) =>
            isFixed ? prev - 1 : parseFloat((prev - 0.01).toFixed(2))
        );
    };

    const handleRadioChange = (type) => {
        setIsFixed(type === "fixed");
        setVal(type === "fixed" ? 1 : 2);
    };

    const formatValue = () => (isFixed ? value : `${value.toFixed(2)}%`);

    const handleInputChange = (e) => {
        const newValue = parseFloat(e.target.value);
        setValue(isFixed ? newValue : parseFloat(newValue.toFixed(2)));
    };

    
    const onSubmit = async () => {
        const fiatValue = state.selectedFiat === "Indian Rupees" ? "INR" : state.selectedFiat;
        const status = state.selectedStatus === "offline" ? 2 : 1;

        const selectedPaymentMethods = state.selectedPaymentMethod;
        const selectedStatus = state.selectedPaymentMethodIds;
        
        // Separate into bankList and otherList based on the presence of 'bank'
        const bankList = selectedPaymentMethods
            .filter((method) => method.bank)
            .map((method) => method.id);
        
        const otherList = selectedPaymentMethods
            .filter((method) => !method.bank)
            .map((method) => method.id);
        
        // Process based on selectedStatus
        const bankListFromStatus = selectedStatus.filter((id) => id == 1);
        const otherListFromStatus = selectedStatus.filter((id) => id != 1);
        
        // Combine the results
        const finalBankList = [...bankList, ...bankListFromStatus];
        const finalOtherList = [...otherList, ...otherListFromStatus];
        
        // Validation for both fields being empty
        if (finalBankList.length === 0 && finalOtherList.length === 0) {
            toast.error("Please select a payment method.");
            return;
        }
        
        // Mode-specific logic
        let bankListPayload = [];
        let otherListPayload = [];
        
        if (mode === "buy") {
            // In buy mode, use data from selectedStatus (selectedPaymentMethodIds)
            bankListPayload = bankListFromStatus; // IDs from selectedStatus
            otherListPayload = otherListFromStatus; // IDs from selectedStatus
        } else if (mode === "sell") {
            // In sell mode, use data from selectedPaymentMethods (full method details)
            // bankListPayload = selectedPaymentMethods.filter((method) => finalBankList.includes(method.id));
            bankListPayload = bankList;
            // otherListPayload = selectedPaymentMethods.filter((method) => finalOtherList.includes(method.id));
            otherListPayload = otherList;
        }
        
        const payload = {
            crypto: state.selectedCrypto,
            fiat: fiatValue,
            price_type: state.val,
            user_price: state.currentValue,
            user_float: state.userFloat,
            bankList: bankListPayload,
            otherList: otherListPayload,
            user_total: Number(state.totalAmount),
            order_min: Number(state.fromAmount),
            order_max: Number(state.toAmount),
            payment_time: state.time,
            description: state.description,
            autoreply: state.autoReply,
            kyc_required: state.checkedItems.kyc_required ? 1 : 0,
            reg_time_required: state.checkedItems.reg_time_required ? 1 : 0,
            balance_required: state.checkedItems.balance_required ? 1 : 0,
            reg_time: Number(state.regTimeInput),
            min_balance: state.balanceInput,
            online: status,
            type: modeValue,
            region: state.country,
            tags: state.tags.join(','),
        };
    
        try {
            const result = await dispatch(doNewAd(userId, token, payload));
            if (result) {
                history.push("/trade/p2p");
                dispatch(resetState());
            }
        } catch (error) {
            console.error("Error submitting ad:", error);
        }
    };

    return (
        <div className="bg-[#FAFAFA] min-h-screen">
            <div className="bg-white shadow-md">
                <p className="text-[22px] font-bold py-6 container mx-auto">
                    Post Normal Ad
                </p>
            </div>
            <div className="container flex w-full">
                {steps.map((step, index) => (
                    <div
                        key={index}
                        className={`${
                            index === 0
                                ? "w-[47%]"
                                : index === 1
                                ? "w-1/2"
                                : "w-1/4"
                        } text-sm mt-2`}
                    >
                        {step.title}
                    </div>
                ))}
            </div>
            <div className="container mx-auto">
                <div className="flex items-center justify-between px-20 my-5">
                    {steps.map((_, index) => (
                        <div
                            key={index}
                            className={`${
                                index === 0 || index === 1 ? "w-1/2" : "w-20"
                            }`}
                        >
                            <div className="flex items-center">
                                <div
                                    className={`h-7 w-7 rounded-full flex items-center justify-center font-normal text-black ${
                                        current === index
                                            ? "bg-[#EFB81C]"
                                            : "bg-gray-300"
                                    }`}
                                >
                                    {index + 1}
                                </div>
                                {index < 2 && (
                                    <div
                                        className={`flex-1 h-1 mx-2 ${
                                            current >= index
                                                ? "bg-[#EFB81C]"
                                                : "bg-gray-300"
                                        }`}
                                    ></div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col w-full my-3 bg-white rounded-md shadow-md">
                    <div className="flex w-full">
                        <button
                            className={`py-3 px-6 w-1/2 text-black ${
                                mode === "buy" ? "bg-white" : "bg-gray-100"
                            }`}
                            onClick={() => handleModeChange("buy")}
                        >
                            I want to buy
                        </button>
                        <button
                            className={`py-3 px-6 w-1/2 text-black ${
                                mode === "sell" ? "bg-white" : "bg-gray-100"
                            }`}
                            onClick={() => handleModeChange("sell")}
                        >
                            I want to sell
                        </button>
                    </div>
                    <div>
                        {current === 0 && (
                            <StepOneContent
                                isFixed={isFixed}
                                // handleRadioChange={handleRadioChange}
                                formatValue={formatValue}
                                handleInputChange={handleInputChange}
                                increment={increment}
                                decrement={decrement}
                                onCryptoSelect={setSelectedCrypto}
                                onFiatSelect={setSelectedFiat}
                                onResultValueChange={setResultValue}
                                setCurrentVal={setCurrentVal}
                                setFloat={setFloat}
                                val={val}
                            />
                        )}
                        {current === 1 && (
                            <StepTwo
                                mode={mode}
                                val={val}
                                selectedCrypto={selectedCrypto}
                                selectedFiat={selectedFiat}
                                resultValue={resultValue}
                                currentVal={currentVal}
                                settotalamount={settotalamount}
                                setfromamount={setfromamount}
                                settoamount={settoamount}
                                settime={settime}
                            />
                        )}

                        {current === 2 && (
                            <StepThree
                                setdescription={setdescription}
                                setautoreply={setautoreply}
                                setcheckitem={setcheckitem}
                                onStatusChange={onStatusChange}
                                setregtimeinput={setregtimeinput}
                                setbalanceinput={setbalanceinput}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0 z-50 flex items-center px-6 py-4 bg-white shadow-lg">
                <div className="container flex justify-end w-full">
                    <div className="gap-4">
                        {current > 0 && (
                            <button
                                className="bg-[#EAECEF] mr-3 text-sm font-medium text-black py-2.5 px-16 rounded hover:bg-[#F5F5F5] transition"
                                onClick={prev}
                            >
                                Previous
                            </button>
                        )}
                        <button
                            className="bg-[#EFB81C] text-black text-sm font-medium py-2.5 px-20 rounded ml-auto hover:bg-[#EFB81C] transition"
                            onClick={() => {
                                if (current === steps.length - 1) {
                                    onSubmit(); // Call onSubmit when on the last step
                                } else {
                                    next(); // Proceed to next step
                                }
                            }}
                        >
                            {current === steps.length - 1 ? "Done" : "Next"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PostAds;