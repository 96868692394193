// geolocationUtils.js
// Utility to get the user's IP address
export const getUserIP = async () => {
    try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        return data.ip;
    } catch (error) {
        console.error("Failed to fetch IP address", error);
        return null;
    }
};

// Utility to get the country code based on IP address
export const getCountryCode = async (ip) => {
    try {
        const response = await fetch(`https://ipapi.co/${ip}/json/`);
        const data = await response.json();
        return data.country_code.toLowerCase(); // Ensure country code is lowercase
    } catch (error) {
        console.error("Failed to fetch country code", error);
        return null;
    }
};

export const getCountryName = async (ip) => {
    try {
        const response = await fetch(`https://ipapi.co/${ip}/json/`);
        const data = await response.json();
        return data.country_name; // Fetch the country name instead of the code
    } catch (error) {
        console.error("Failed to fetch country name", error);
        return null;
    }
};

export const getCountryCurrency = async (ip) => {
    try {
        const response = await fetch(`https://ipapi.co/${ip}/json/`);
        const data = await response.json();
        return { currency: data.currency };
    } catch (error) {
        console.error("Failed to fetch country or currency", error);
        return { currency: null };
    }
};