import React from 'react'
import { TbCaretUpDownFilled } from "react-icons/tb";
import NoData from "assets/img/user/No-data.svg";

function LockedNFTRewards() {
    return (
        <div className='w-full overflow-x-auto flex flex-col'>
            <div className='flex w-[150%] my-6'>
                <div className='flex items-center gap-1 w-1/4'>
                    <p className='text-xs text-[#929AA5]'>Collection/NFT Name</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-start gap-1 w-1/4'>
                    <p className='text-xs text-[#929AA5]'>Reward Coin</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-start gap-1 w-1/4'>
                    <p className='text-xs text-[#929AA5]'>Subscription Date</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-center gap-1 w-1/3'>
                    <p className='text-xs text-[#929AA5]'>Locked Period(Days)</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-center gap-1 w-1/3'>
                    <p className='text-xs text-[#929AA5]'>Rewards End Date</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-center gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Accrue Days</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-center gap-1 w-1/4'>
                    <p className='text-xs text-[#929AA5]'>Cumulative Rewards</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-center gap-1 w-1/2'>
                    <p className='text-xs text-[#929AA5]'>Estimated Next Distribution Amount</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-center gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Rewards Date</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-center gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Rewards Period</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-center gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Status</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-center gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Action</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
            </div>

            <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                <img src={NoData} />
                <p className='text-[#707a8a] text-sm'>No records</p>
            </div>
        </div>
    )
}

export default LockedNFTRewards