import React, { useState } from "react";
import {
    BsCaretDownFill,
    BsCaretUpFill,
    BsQuestionCircleFill,
    BsStarFill,
} from "react-icons/bs";
import { useMarketData } from "../hooks/useMarketData";
import { useSort } from "../hooks/useSortData";

const SpotMarginDataTable = ({ navActive }) => {
    const {
        marketData,
        validIcons,
        formatPrice,
        extractCoinName,
        handleDetailsRedirect,
        handleTradeRedirect,
        isFavoritePair,
        toggleFavoritePair,
        svgDataList,
    } = useMarketData(navActive);

    const { data: sortedMarketData, sortOrder, sortData } = useSort(marketData);
    const [activeFilter, setActiveFilter] = useState("USDT"); // State for active filter

    // Filter the sorted market data based on the active filter
    const filteredMarketData = (sortedMarketData || []).filter((coin) => {
        const secondValue = coin.ticker.split("_")[1]?.toLowerCase();

        if (activeFilter === "USDT") {
            return secondValue === "usdt";
        } else if (activeFilter === "BTC") {
            return secondValue === "btc";
        } else if (activeFilter === "ETH") {
            return secondValue === "eth";
        } else if (activeFilter === "TRY") {
            return secondValue === "try";
        } else if (activeFilter === "TRX") {
            return secondValue === "trx";
        } else if (activeFilter === "EUR") {
            return secondValue === "eur";
        }
        return false;
    });

    return (
        <div className="market-data-table-wrapper ">
            <div className="flex justify-start gap-4 mb-4">
                <button
                    className={`px-4 py-1 font-semibold rounded ${
                        activeFilter === "USDT" ? "bg-[#EFB81C]" : "bg-gray-300"
                    }`}
                    onClick={() => setActiveFilter("USDT")}
                >
                    USDT
                </button>
                <button
                    className={`px-4 py-1 font-semibold rounded ${
                        activeFilter === "BTC" ? "bg-[#EFB81C]" : "bg-gray-300"
                    }`}
                    onClick={() => setActiveFilter("BTC")}
                >
                    BTC
                </button>
                <button
                    className={`px-4 py-1 font-semibold rounded ${
                        activeFilter === "ETH" ? "bg-[#EFB81C]" : "bg-gray-300"
                    }`}
                    onClick={() => setActiveFilter("ETH")}
                >
                    ETH
                </button>
                <button
                    className={`px-4 py-1 font-semibold rounded ${
                        activeFilter === "TRY" ? "bg-[#EFB81C]" : "bg-gray-300"
                    }`}
                    onClick={() => setActiveFilter("TRY")}
                >
                    TRY
                </button>

                <button
                    className={`px-4 py-1 font-semibold rounded ${
                        activeFilter === "EUR" ? "bg-[#EFB81C]" : "bg-gray-300"
                    }`}
                    onClick={() => setActiveFilter("EUR")}
                >
                    EUR
                </button>
            </div>
            <table className="w-full market-data-table table__all-crypto bg-solid">
                <thead className="market-data-table__header">
                    <tr className="flex justify-between market-data-table__header-row">
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="flex items-center w-full gap-1 data-filter-btn"
                                onClick={() => sortData("volume")}
                            >
                                <h4 className="heading-title">Name</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="flex items-center w-full gap-1 data-filter-btn"
                                onClick={() => sortData("new_price")}
                            >
                                <h4 className="heading-title">Price</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.new_price === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.new_price === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="flex items-center w-full gap-1 data-filter-btn"
                                onClick={() => sortData("change")}
                            >
                                <h4 className="heading-title">24h Change</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.change === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.change === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="flex items-center w-full gap-1 data-filter-btn"
                                onClick={() => sortData("volume")}
                            >
                                <h4 className="heading-title">24h Volume</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="flex items-center w-full gap-1 data-filter-btn"
                                onClick={() => sortData("volume")}
                            >
                                <h4 className="heading-title">Market Cap</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th className="market-data-table__header-row__heading">
                            <button
                                type="button"
                                className="flex items-center w-full gap-1 data-filter-btn"
                                onClick={() => sortData("volume")}
                            >
                                <h4 className="heading-title">24h Chart</h4>
                                <div className="flex flex-col gap-0">
                                    <BsCaretUpFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "asc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                    <BsCaretDownFill
                                        size={6}
                                        color={
                                            sortOrder.volume === "desc"
                                                ? "#EFB81C"
                                                : "#15141540"
                                        }
                                    />
                                </div>
                            </button>
                        </th>
                        <th
                            className="market-data-table__header-row__heading"
                            colSpan="2"
                        >
                            <h4 className="heading-title">Actions</h4>
                        </th>
                    </tr>
                </thead>

                <tbody className="market-data-table__body">
                    {filteredMarketData.map((coin, index) => (
                        <tr
                            key={coin.id}
                            className="flex justify-between border-b market-data-table__body-row"
                        >
                            <td className="market-data-table__body-row__data">
                                <div className="flex items-center gap-2">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            toggleFavoritePair(coin.id)
                                        }
                                        className="favorite-toggler w-max h-max"
                                    >
                                        <BsStarFill
                                            size={12}
                                            fill={
                                                isFavoritePair(coin.id)
                                                    ? "#EFB81C"
                                                    : "#15141540"
                                            }
                                        />
                                    </button>
                                    {validIcons[coin.id] ? (
                                        <img
                                            src={coin.icon}
                                            alt={coin.name}
                                            className="w-5 h-5 rounded-full"
                                        />
                                    ) : (
                                        <BsQuestionCircleFill
                                            className="w-5 h-5"
                                            fill="#15141540"
                                        />
                                    )}
                                    <span className="text-[14px] font-[500]">
                                        {extractCoinName(coin.name)}
                                    </span>
                                </div>
                            </td>

                            <td className="market-data-table__body-row__data">
                                <span className="text-[13px] font-[500]">
                                    {coin.new_price
                                        ? "$" + coin.new_price
                                        : "-"}
                                </span>
                            </td>

                            <td className="market-data-table__body-row__data">
                                <span
                                    className={`text-[13px] font-[500] ${
                                        coin.change < 0
                                            ? "text-red-500"
                                            : "text-green-500"
                                    }`}
                                >
                                    {coin.change > 0
                                        ? `+${coin.change.toFixed(2)}%`
                                        : `${coin.change.toFixed(2)}%`}
                                </span>
                            </td>

                            <td className="market-data-table__body-row__data">
                                <span className="text-[13px] font-[500]">
                                    {coin.max_price
                                        ? "$" + coin.max_price
                                        : "-"}
                                </span>
                            </td>

                            <td className="market-data-table__body-row__data">
                                <span className="text-[13px] font-[500]">
                                    {coin.volume
                                        ? formatPrice(coin.volume)
                                        : "-"}
                                </span>
                            </td>

                            <td className="market-data-table__body-row__data">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: svgDataList[index],
                                    }}
                                />
                            </td>
                            <td className="market-data-table__body-row__data">
                                <div className="flex items-center gap-1 ml-auto w-max">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            handleDetailsRedirect(coin.id)
                                        }
                                        className="text-[14px] font-[500] px-4 py-1 rounded-md text-[#EFB81C]"
                                    >
                                        Details
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            handleTradeRedirect(coin.id)
                                        }
                                        className="text-[14px] font-[500] px-4 py-1 rounded-md bg-[#EFB81C]"
                                    >
                                        Trade
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SpotMarginDataTable;
