import React, { useState } from 'react';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';

function PaymentDetails({ inputValue, selectedPayment }) {
    const [isChecked, setIsChecked] = useState(false);

    const toggleCheckbox = () => {
        setIsChecked(prev => !prev);
    };

    return (
        <>
            <div className="flex gap-2 items-center mb-14 lg:mb-14">
                <span className="bg-[#EAECEF] flex items-center font-semibold p-2 h-7 w-7 rounded-full">3</span>
                <p className='text-[22px] font-medium'>Payment details</p>
            </div>

            <div className='border rounded-lg p-5'>
                {/* Display input value and payment method only if inputValue exists */}
                    <div className='flex w-full mb-3 justify-between items-center'><p>You have entered: </p><p className='font-semibold'>{inputValue}</p></div>
                {/* Show payment method only when inputValue has some value */}
                    <div className='flex w-full mb-3 justify-between items-center'><p>Payment method is: </p><p className='font-semibold'>{selectedPayment}</p></div>
                    <div className='flex w-full mb-3 justify-between items-center'><p>Unit Price </p><p className='font-semibold'>1 USDT ≈ 0.99 EUR</p></div>
                    <div className='flex w-full mb-3 justify-between items-center border-b pb-5'><p>You will get </p><p className='font-semibold'>90.27 USDT</p></div>

                <h3 className='font-bold text-md py-3'>Disclaimer</h3>
                <p className='text-justify text-sm'>
                    You are now leaving the Buycex website and an external link will be opened in a new browser window. Websites accessed
                    through this hyperlink are not Buycex websites and the use of this link is entirely at your risk. We are not responsible
                    for any of the contents, advertisements, products, services, or other materials on external sites, nor does this link
                    represent an endorsement or solicitation of any products or services provided on or via such websites. We will not be liable
                    for any loss or damage caused in connection with the use or reliance on any content or services available on or through any
                    third-party website. Should you have any issue during the use of such websites' services, please contact them directly.
                </p>

                {/* Checkbox Section */}
                <div className='flex items-center mt-5 cursor-pointer' onClick={toggleCheckbox}>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={toggleCheckbox}
                        className="hidden"
                        id="agreeCheckbox"
                    />
                    {isChecked ? (
                        <MdCheckBox className='text-[#EFB81C] mr-2' />
                    ) : (
                        <MdCheckBoxOutlineBlank className='text-[#EFB81C] mr-2' />
                    )}
                    <label htmlFor="agreeCheckbox" className='text-[16px] text-sm cursor-pointer'>
                        I agree to the terms and conditions
                    </label>
                </div>
            </div>
            </>
    );
}

export default PaymentDetails;
