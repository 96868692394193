import React from "react";
import { Modal, Button } from "antd";
import { BsExclamationLg } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

const ConfirmationPopUp = ({ show, onClose, onSubmit, text }) => {
    return (
        <Modal
            open={show}
            onCancel={onClose}
            footer={null}
            centered
            bodyStyle={{ padding: "24px" }}
            closeIcon={
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            }
        >
            <div className="flex w-full justify-center">
            <div className="flex justify-center w-fit items-center p-2 bg-[#f7e3b5] rounded-full">
            <div className="flex justify-center w-fit items-center bg-yellow-50 rounded-full">
                <BsExclamationLg className="text-[#F59E0B]" size={60}/>
            </div>
            </div>
            </div>
            <p className="text-lg font-semibold text-center my-4">Confirm Removing Google 2FA Authentication?</p>
            <div className="bg-gray-100 p-3 mb-5 rounded-lg">
                    <p className="font-semibold text-[16px]">Note</p>
                    <div className="flex gap-2 mt-3">
                    <GoDotFill size={30} className="opacity-50 mt-[-4px]"/><p className="text-[14px]">
                        To protect your account, unlinking Google Authentication will temporarily suspended on-chain withdrawals, 
                        internal transfers, fiat withdrawals, P2P transaction, and BuyCex Card transaction for 24 hours.
                    </p>
                    </div>
                </div>
            <div className="flex gap-5 mt-6 w-full">
                    <button onClick={onSubmit} className="bg-[#EFB81C] py-3 font-semibold rounded-lg w-1/2">Remove</button>
                    <button onClick={onClose} className="border-2 py-3 rounded-lg font-semibold w-1/2">Cancel</button>
                </div>
        </Modal>
    );
};

export default ConfirmationPopUp;
