const initialState = {
    bit: parseInt(localStorage.getItem("bit") || "0", 10), // Load from localStorage or default to 0
    loading: false,
    error: null,
};

const bitReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_BIT_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "SET_BIT_SUCCESS":
            localStorage.setItem("bit", "1"); // Save to localStorage
            return {
                ...state,
                loading: false,
                bit: 1, // Set bit to 1 for success
            };
        case "SET_BIT_FAILURE":
            localStorage.setItem("bit", "0"); // Save to localStorage
            return {
                ...state,
                loading: false,
                bit: 0, // Set bit to 0 for failure
                error: action.payload, // Store error message
            };
        default:
            return state;
    }
};

export default bitReducer;
