import React from 'react'
import NoData from "assets/img/user/No-data.svg";
function USDM() {
  return (
    <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
      <div className="w-full flex justify-center items-center h-96">
        <div className='flex flex-col gap-1 h-44 justify-center items-center'>
          <img src={NoData} alt="No Data" />
          <p className='text-[#707a8a] text-sm'>No records</p>
        </div>
      </div>
    </div>
  )
}

export default USDM