import React, { useEffect, useState } from "react";

// Hook to fetch market asset details
const useMakerTakerConditions = () => {
    const [makerTakerData, setMakerTakerData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMakerTakerAssetData = async () => {
            try {
                const response = await fetch(
                    "https://api.buycex.com/cmc/assets"
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const data = await response.json();

                // Clean the makerTakerData data
                const cleanedData = Object.fromEntries(
                    Object.entries(data.data).map(([key, value]) => {
                        const cleanedKey = key.replace(/"/g, "").trim();
                        return [cleanedKey, value];
                    })
                );

                // Clean each asset object within cleanedData
                const finalData = {};
                for (const [key, value] of Object.entries(cleanedData)) {
                    finalData[key] = {
                        ...value,
                        max_withdraw:
                            value["max_withdraw "] || value.max_withdraw,
                    };
                    delete finalData[key]["max_withdraw "];
                }

                setMakerTakerData(finalData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchMakerTakerAssetData();
    }, []);

    return { makerTakerData, loading, error };
};

export default useMakerTakerConditions;
