import React from "react";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const ReactivateAccountPopup = ({ onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center backdrop-blur-[1px] z-20">
            {" "}
            <div className="bg-white p-8 rounded-xl shadow-lg relative max-w-md w-full">
                <button
                    onClick={onClose}
                    aria-label="Close"
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                >
                    <IoClose className="text-2xl text-gray-400" />
                </button>
                <div className="flex justify-center items-center mb-3">
                    <BsExclamationDiamondFill className="text-8xl text-[#EFB81C]" />
                </div>
                <p className="text-center font-bold">Reminder</p>
                <p className="text-sm mt-3 mb-5">
                    Your account is disabled via self-service and the login is
                    prohibited. Are you sure to re-activate?
                </p>
                <div className="flex !w-full justify-between gap-2">
                    <button
                        type="button"
                        onClick={onClose}
                        aria-label="Cancel"
                        className="w-[223px] bg-[#fff1cc] text-[#EFB81C] hover:bg-[#f7e6a4] font-medium rounded-lg text-sm px-3 py-2"
                    >
                        Cancel
                    </button>
                    <Link to="/user/account/re-activate">
                    <button
                        type="button"
                        onClick={onClose}
                        aria-label="Confirm"
                        className="w-[200px] bg-[#EFB81C] text-white hover:bg-[#d4a800] font-medium rounded-lg text-sm px-4 py-2"
                    >
                        Reactivate Now
                    </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ReactivateAccountPopup;
