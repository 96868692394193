import React from "react";
import USDT from "../../../assets/img/earn/usdt.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import StakeNowModel from "./StakeNowModel";
// update

const MainTable = () => {
    const location = useLocation(); // Get the current location object
    const currentPath = location.pathname;
    const {coin,id,value,imgPath } = location.state || {};
    console.log("coin", coin, "id", id, "value", value);
    return (
        <div>
            <div>
                <div className="flex items-center justify-between">
                    <div className="text-[#15192a] text-[28px] font-bold">
                        All Products
                    </div>
                    <div className="flex items-center gap-8">
                        <div className="flex gap-2 items-center">
                            <input type="checkbox" />
                            <span className="text-[#15192a] text-[12px]">
                                Match My Asset
                            </span>
                        </div>
                        <div className="flex gap-2 items-center">
                            <input type="checkbox" />
                            <span className="text-[#15192a] text-[12px]">
                                Show Available Only
                            </span>
                        </div>
                        <div className="w-[200px] h-[32px] bg-[#f3f5f7] rounded-[100px] px-3 flex items-center justify-between ">
                            <span>
                                <svg
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    data-icon="search"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                                </svg>
                            </span>
                            <span>
                                <input
                                    type="text"
                                    placeholder="USDT"
                                    className="bg-transparent outline-none"
                                />
                            </span>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div
                    className="mt-6
                     border-b-[1px] border-solid border-[#f0f0f0]
                "
                >
                    <button className="py-6 text-[14px] text-[#121214] font-semibold border-b-[4px] border-solid border-[#f7a600]">
                        {currentPath === "/earn/home/flexible" ? (
                            <>Flexible Term</>
                        ) : (
                            <>Fixed Term</>
                        )}
                    </button>
                </div>
                {/* main table  */}
                <div
                    className="mt-2
                     border-b-[1px] border-solid border-[#f0f0f0] py-6 flex items-center justify-between gap-2
                "
                >
                    <div className="text-[#81858c] text-[12px] flex-1">
                        Coins
                    </div>
                    <div className="text-[#81858c] text-[12px] flex-1">APR</div>
                    <div className="text-[#81858c] text-[12px] flex-1">
                        Duration
                    </div>
                    <div className="text-[#81858c] text-[12px] flex-1 text-right">
                        Action
                    </div>
                </div>
                {/* main row  */}
                <div
                    className="mt-2
                     border-b-[1px] border-solid border-[#f0f0f0] py-6 flex items-center justify-between gap-2
                "
                >
                    <div className=" flex-1 flex gap-2 items-center">
                    {imgPath && (
                <img src={imgPath} alt={`${coin} logo`} className="w-6 h-6 rounded-full" />
            )}                        <span className="text-[#121214] text-[16px] font-bold">
                        {coin.toUpperCase()}
                        </span>
                    </div>
                    <div className="text-[#81858c] text-[12px] flex-1">
                        <span className="text-orange-400 text-[16px] font-bold">
                            {value}%
                        </span>
                    </div>
                    <div className="text-[#81858c] text-[12px] flex-1">
                        <span className="text-[#121214] text-[16px] font-bold">
                            {currentPath === "/earn/home/flexible" ? (
                                <>Flexible </>
                            ) : (
                                <>1 Day</>
                            )}
                        </span>
                    </div>
                    <div className="text-[#81858c] text-[12px] flex-1 text-center items-center flex justify-end">
                        <StakeNowModel />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainTable;