import React, { useState } from 'react';
import { TbCaretUpDownFilled } from "react-icons/tb";
import NoData from "assets/img/user/No-data.svg";
import MyIndex from './MyIndex';

function AutoInvest() {
    const [activeButton, setActiveButton] = useState('My Portfolio'); // State to track the active button

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName); // Set the clicked button as active
    };

    const getButtonClass = (buttonName) => (
        `px-2 py-2 rounded-md font-medium text-sm ${activeButton === buttonName ? 'bg-[#f5f5f5]' : ''}`
    );

    return (
        <div className='border p-6 rounded-xl'>
            <div className='w-full flex justify-between'>
                <div className='flex gap-3'>
                    <button
                        onClick={() => handleButtonClick('My Portfolio')}
                        className={getButtonClass('My Portfolio')}
                    >
                        My Portfolio
                    </button>
                    <button
                        onClick={() => handleButtonClick('My Index-Linked')}
                        className={getButtonClass('My Index-Linked')}
                    >
                        My Index-Linked
                    </button>
                </div>
                <div className='flex gap-2 items-center'>
                    <button className='flex px-2 py-1.5 text-sm bg-[#EFB81C] font-semibold rounded-[4px] items-center gap-1'>
                        Create a Plan
                    </button>
                </div>
            </div>

            {/* Header rows displayed based on the active button */}
            {activeButton === 'My Portfolio' && (
                <div className='w-full'>
                    {/* Header row */}
                    <div className='my-6 px-6'>
                        <ul className='list-disc'>
                            <li className='text-xs text-[#929AA5]'>To redeem your purchased crypto assets, go to <span className='text-[#EFB81C]'>Earn Wallet</span>, choose asset and click &quot;Redeem&quot;.</li>
                            <li className='text-xs text-[#929AA5]'>Purchased assets are deposited into BNB Vault account, Simple Earn Flexible Products account and/or Spot Account</li>
                            <li className='text-xs text-[#929AA5]'>Plans listed below are recurring Auto-Invest plans. To view One-Time purchased assets, go to your BNB Vault account, your Simple Earn Flexible Products account, and/or your Spot account.</li>
                        </ul>
                    </div>
                    <div className='overflow-x-auto'>
                        <div className='flex w-[150%] my-8'>
                            <div className='flex items-center gap-1 w-1/4'>
                                <p className='text-xs text-[#929AA5]'>Plan Name</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex items-center justify-start gap-1 w-1/4'>
                                <p className='text-xs text-[#929AA5]'>Auto-Invest Schedule</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex items-center justify-start gap-1 w-1/4'>
                                <p className='text-xs text-[#929AA5]'>On/Off</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex items-center justify-center gap-1 w-1/3'>
                                <p className='text-xs text-[#929AA5]'>Total Invested</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex items-center justify-center gap-1 w-1/3'>
                                <p className='text-xs text-[#929AA5]'>Purchased Amount</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex justify-center gap-1 w-1/5'>
                                <p className='text-xs text-[#929AA5]'>Average Price</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex justify-center gap-1 w-1/4'>
                                <p className='text-xs text-[#929AA5]'>PNL</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex justify-center gap-1 w-1/2'>
                                <p className='text-xs text-[#929AA5]'>ROI</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex justify-center gap-1 w-1/2'>
                                <p className='text-xs text-[#929AA5]'>Next Auto-Invest Date</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex justify-center gap-1 w-1/5'>
                                <p className='text-xs text-[#929AA5]'>Plan Start Date</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex justify-center gap-1 w-1/5'>
                                <p className='text-xs text-[#929AA5]'>Action</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                        </div>

                        <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                            <img src={NoData} />
                            <p className='text-[#707a8a] text-sm'>No records</p>
                        </div>
                    </div>

                </div>
            )}
            {activeButton === 'My Index-Linked' && (
                <MyIndex />
            )}
        </div>
    );
}

export default AutoInvest;
