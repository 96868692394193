import React, { useEffect, useState } from "react";
import AssetDropdown from './DropDownUI';
import NoData from "assets/img/user/No-data.svg";
import { useDispatch, useSelector } from "react-redux";
import { FinanceOpenOrders } from "store/actions/AssetsUserAction";

const OpenOrder = () => {
    const dispatch = useDispatch();
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const [orderListData, setOrderListData] = useState([]);
    const [marketListData, setMarketListData] = useState([]);
    const [options2, setOptions2] = useState(["All"]);
    const [filteredOrderListData, setFilteredOrderListData] = useState([]);

    const options1 = ["All", "Limit Order", "Stop Limit", "Stop Market", "Limit-Maker", "Trailing Stop"];
    // const options1 = ["All", "Transaction","Completed","Revoked"];
    const options3 = ["All", "Buy", "Sell"];    

    useEffect(() => {
        const payload = {
            user_id: userID,
        };

        dispatch(FinanceOpenOrders(userID, userToken, payload))
        .then((response) => {
            if (response) {
                const orderList = response.data?.data?.entrust || [];
                const marketList = response.data?.data?.market_list || [];
                setOrderListData(orderList);
                setMarketListData(marketList);
                setFilteredOrderListData(orderList);
                // console.log(response.data.data);
                // Extract unique market names for options2
                const uniqueMarkets = ["All", ...new Set(orderList.map(order => getMarketName(order.market)))];
                setOptions2(uniqueMarkets);
            } else {
                console.error(response);
            }
        });
    }, [dispatch, userID, userToken]);

    const handleSelect1 = (selected) => {
        console.log("Dropdown 1 selected:", selected);
        if(selected === "All") {
            setFilteredOrderListData(orderListData);
        } else if (selected === "Transaction") {
            const filteredOrders = orderListData.filter(order => order.status == "0");
            setFilteredOrderListData(filteredOrders);
        }
        else if (selected === "Completed") {
            const filteredOrders = orderListData.filter(order => order.status == "1");
            setFilteredOrderListData(filteredOrders);
        }
        else if (selected === "Revoked") {
            const filteredOrders = orderListData.filter(order => order.status == "2");
            setFilteredOrderListData(filteredOrders);
        }
    };
    const handleSelectPair = (selected) => {
        // console.log("Dropdown Pair selected:", selected);
        if (selected === "All") {
            setFilteredOrderListData(orderListData);
        } else {
            const explodeMarketNameValue = explodeMarketName(selected);
            const filteredOrders = orderListData.filter(order => order.market == explodeMarketNameValue);
            setFilteredOrderListData(filteredOrders);
        }
    };

    const handleSelectType = (selected) => {
        // console.log("Dropdown Type selected:", selected);
        if(selected === "All") {
            setFilteredOrderListData(orderListData);
        } else if (selected === "Buy") {
            const filteredOrders = orderListData.filter(order => order.type == "1");
            setFilteredOrderListData(filteredOrders);
        } else if (selected === "Sell") {
            const filteredOrders = orderListData.filter(order => order.type == "2");
            setFilteredOrderListData(filteredOrders);
        }
    };

    const getMarketName = (market) => {
        const marketName = Object.values(marketListData).find((item) => item.name == market);
        const marketNameXNB = marketName?.xnb.toUpperCase();
        const marketNameRMB = marketName?.rmb.toUpperCase();
        return marketNameRMB + "/" + marketNameXNB;
    };

    const explodeMarketName = (market) => {
        var splitMarket = market.split("/");
        return splitMarket[1].toLowerCase() + "_" + splitMarket[0].toLowerCase();
    };
    

    return (
        <div>
            <div className="w-1/2 flex gap-4">
                <AssetDropdown
                    label="Filter"
                    options={options1}
                    initialSelected="All"
                    onSelect={handleSelect1}
                    isSearchable={false}
                    width="300px"
                />
                <AssetDropdown
                    label="Pair"
                    options={options2}
                    initialSelected="All"
                    onSelect={handleSelectPair}
                    isSearchable={true}
                    width="300px"
                />
                <AssetDropdown
                    label="Side"
                    options={options3}
                    initialSelected="All"
                    onSelect={handleSelectType}
                    isSearchable={false}
                    width="300px"
                />
            </div>

            {/* Table for displaying order data */}
            <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
                <table className="min-w-full border-collapse">
                    <thead>
                        <tr className="bg-gray-200">
                            <th>Market</th>
                            <th>Time</th>
                            <th>Type</th>
                            <th>Price</th>
                            <th>Stop</th>
                            <th>Qty</th>
                            <th>Dealt</th>
                            <th>Total</th>
                            {/* <th>Option</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOrderListData.length > 0 ? (
                            filteredOrderListData.map((order) => (

                                <tr key={order.id}>
                                    <td className="text-center">{getMarketName(order.market)}</td>
                                    <td  className="text-center">{order.addtime}</td>
                                    <td  className="text-center">{order.tradetype}</td>
                                    <td  className="text-center">{order.price}</td>
                                    <td  className="text-center">{order.condition}</td>
                                    <td  className="text-center">{order.num}</td>
                                    <td  className="text-center">{order.deal}</td>
                                    <td  className="text-center">{order.price * order.num}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center">
                                    <div className="flex flex-col items-center mt-10">
                                        <img src={NoData} alt="No Data" className="mb-2" />
                                        <p className="text-[#707a8a] text-sm">No orders available</p>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OpenOrder;
