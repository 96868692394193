import React from 'react'
import Reserve from 'assets/img/white_label.svg'
import RightArrow from 'assets/img/right-arrow.png'
function ProofOfReserve() {
  return (
    <> 
<section className='py-10'>
  <div className='container'>
     <div className='grid gap-4 md:grid-cols-5'>
      <div className='my-auto md:col-span-3'>
      <h2 className='mb-3 text-2xl font-semibold lg:text-5xl md:text-3xl'>Proof of Reserves</h2> 
        <p className='md:text-lg text-base text-[#81858c] '>Bybit has a 1:1 reserve on all user assets on the platform. Reserves and user assets will be published to the public in a completely open and transparent manner.</p>
       <div className='flex flex-wrap items-center gap-2 mt-3'>
          <a href="#s" className='bg-[#EFB81C]  px-3 py-2 rounded text-white text-center hover:no-underline md:text-lg text-base'>View my audit data</a>
        <a href="#s"  className='bg-[#fff] px-3 py-2 rounded text-black border text-center hover:no-underline md:text-lg text-base'>View the Bybit Reserve Ratio</a>
       </div> 
      </div>
      <div className='mx-auto my-auto md:col-span-2 '>
       
  <img className='w-full h-[300px] object-contain ' src= {Reserve} alt="" />
      </div> 
     </div> 
  </div>
</section> 
<section>
  <div className='container'>
     <div className='grid gap-4 md:grid-cols-2'>
      <div className='p-5 rounded-lg shadow dark:bg-zinc-800'>
        <h5 className='mb-3 text-xl font-semibold text-center'>Verify My Account</h5>
        <p className='mb-3 text-base text-center'>Bybit provides a visual verification page and open source tools to help you verify your reserves</p>
        <a href="#s" className='text-[16px] flex items-center mb-3 text-[#EFB81C]'>Click to learn about verification<img alt='' src={RightArrow} className='w-3 h-3 ms-1' /></a> 
        <div className='flex flex-wrap items-center gap-2 mt-3'>
          <a href="#s" className='bg-[#EFB81C]  px-3 py-2 rounded text-white text-center hover:no-underline text-[14px]'>View my audit data</a>
        <a href="#s"  className='bg-[#fff] px-3 py-2 rounded text-black border text-center hover:no-underline text-[14px]'>View the Bybit Reserve Ratio</a>
       </div> 
      </div> 
      <div className='p-5 rounded-lg shadow dark:bg-zinc-800'>
        <h5 className='mb-3 text-xl font-semibold text-center'>Proving our ownership of the wallet and its balance</h5>
        <p className='mb-3 text-base text-center'>We use the "Send to Self" method to prove ownership of a published wallet address and use the published address to transfer a specific amount of assets to ourselves at a specified time, following the method used by third-party auditing companies to prove ownership of a wallet. </p>
        <a href="#s" className='text-[16px] flex items-center mb-3 text-[#EFB81C]'>Click to learn about verification mechanisms <img  alt='' src={RightArrow} className='w-3 h-3 ms-1' /></a>  
      </div>
     </div> 
  </div>
</section> 
<section className='mt-10'>
  <div className='container'>
     <div className=''> 
     <h2 class="md:text-4xl text-2xl mb-3 font-semibold">Audit Scope</h2>
      <div className='border rounded-lg shadow dark:bg-zinc-800'>
        <h3 className='p-3 text-lg font-semibold'>Our Audit Scope</h3> 
    <div class="      overflow-x-auto">
      <div
        class="inline-block min-w-full overflow-hidden"
      >
        <table class="min-w-full leading-normal ">
          <thead>
            <tr>
              <th
                class="px-5 py-3 border-b-2   text-left text-xs font-semibold uppercase tracking-wider"
              >
        Assets		
        </th>

              <th
                class="px-5 py-3 border-b-2   text-left text-xs font-semibold uppercase tracking-wider"
              >
                Chain 
              </th>
              <th
                class="px-5 py-3 border-b-2   text-left text-xs font-semibold uppercase tracking-wider"
              >
                	Snapshot Time
              </th>
              <th
                class="px-5 py-3 border-b-2   text-left text-xs font-semibold uppercase tracking-wider"
              >
                	Balance
              </th>
               
            </tr>
          </thead>
          <tbody className='w-full'>
            <tr>
              <td class="px-5 py-3 hover:bg-slate-600 border-b border-gray-200 dark:bg-zinc-800 text-sm">
                <div class="flex items-center">
                  <div class="flex-shrink-0 w-10 h-10">
                    <img
                      class="w-full h-full rounded-full"
                      src="https://s1.bycsi.com/app/assets/token/5ff13059ebc5b6d135ba72e5c4b4db54.svg"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <p class="  font-semibold whitespace-no-wrap">
                    AGI
                    </p>
                  </div>
                </div>
              </td>
              <td class="px-5 py-3 hover:bg-slate-600 border-b border-gray-200 dark:bg-zinc-800 text-sm">
                
                <p class="  whitespace-no-wrap font-semibold">BSC Ethereum</p>
              </td>
              <td class="px-5 py-3 hover:bg-slate-600 border-b border-gray-200 dark:bg-zinc-800 text-sm">
                <p class=" whitespace-no-wrap font-semibold">		2024-06-06 04:00 UTC</p>
                 
              </td>
              
              <td class="px-5 py-3 hover:bg-slate-600 border-b border-gray-200 dark:bg-zinc-800 text-sm">
                <p class="  whitespace-no-wrap font-semibold">	52,311,063</p>
                 
              </td>
            </tr>
            
          </tbody>
        </table>
      </div>
    </div> 
      </div> 
     </div> 
  </div>
</section> 
   <section className='py-10'>
  <div className='container'>
    <div className=''>
      <h2 className='mb-3 text-2xl font-semibold text-center md:text-4xl'>Range of user accounts included in the audit</h2>
      <p className='text-center md:text-lg text-base text-[#81858c] '>Our Proof of Reserve is validation that we do actually hold the assets our customers have stored on the Bybit platform. It confirms that we are holding all tokens in the user's account on the Bybit platform on a 1:1 ratio. The users' Proof of Reserve assets include portions of the following accounts that are personal assets.</p>
      <p className='text-center md:text-lg text-base text-[#81858c] '>Trading accounts: including Spot, Futures, Options, and Unified Margin.</p>
      <p className='text-center md:text-lg text-base text-[#81858c] '>Funding accounts: including crypto holdings and financial balances.</p>
      <p className='text-center md:text-lg text-base text-[#81858c] '>Note that the Subaccount assets will be combined and displayed together with the main account assets. The net value of these assets may fluctuate slightly due to the impact of floating assets including open P&L, leveraged trading, financing and borrowing, wealth management investments, etc.</p>
    </div>
  </div>
</section> 
<section className='py-10'>
  <div className='container'>
  <h2 className='mb-3 text-2xl font-semibold md:text-4xl'>Learn More about Bybit Proof of Reserves</h2>
     <div className='grid gap-4 md:grid-cols-2'>
      <div className=''>
        <a href="#s" className='text-[16px] block mb-10 text-[#EFB81C] '>Proof of Reserves (PoR): Rethinking The Exchange-User Dynamic</a>
        <a href="#s" className='text-[16px] block mb-10 text-[#EFB81C] '>Bybit Crypto Ark: Our Commitment to Total Transparency and Next-Level Reliability</a>
<a href="#s" className='text-[16px] block mb-10   '>Bybit User Protection</a>
      </div>
      <div className=''>
<a href="#s" className='text-[16px] block mb-10 text-[#EFB81C] '>What is Merkle Tree?s</a>
<a href="#s" className='text-[16px] block mb-10 text-[#EFB81C] '>Statement From Ben on Bybit’s Wallets</a>
<a href="#s" className='text-[16px] flex items-center mb-10 text-[#EFB81C] '>
View more<img alt='' src={RightArrow} className='w-3 h-3 ms-1' />
</a>
      </div> 
     </div> 
  </div>
</section> 
    </>
  )
}

export default ProofOfReserve
