import {
    LOGIN,
    LOGIN_ERROR,
    LOADING,
    SET_FUND_PASSWORD,
    CHECK_FUND_PASSWORD,
    RESET_PASSWORD_EMAIL_REQUEST,
    RESET_PASSWORD_EMAIL_SUCCESS,
    RESET_PASSWORD_EMAIL_FAILURE,
} from "../types";

const initialState = {
    loading: false,
    user: [],
    fundPassword: {},
    error: null,
};

const initialStateGoogle = {
    isLoading: false,
    googleData: [],
    error: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case "loading":
            return {
                ...state,
                loading: action.payload,
            };
        case LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case SET_FUND_PASSWORD:
            return {
                ...state,
                loading: false,
                fundPassword: action.payload,
            };
        case CHECK_FUND_PASSWORD:
            return {
                ...state,
                loading: false,
                fundPassword: action.payload,
            };
        case LOGIN:
            return {
                ...state,
                loading: false,
                user: action.payload,
                error: null,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                user: null,
                error: action.payload,
            };
      
        case RESET_PASSWORD_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
                resetPasswordStatus: null, 
                error: null,
            };

        case RESET_PASSWORD_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                resetPasswordStatus: "success",
                error: null,
            };

        case RESET_PASSWORD_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                resetPasswordStatus: "failure",
                error: action.payload,
            };
        default:
            return state;
    }
};

const googleReducer = (state = initialStateGoogle, action) => {
    switch (action.type) {
        case "FETCH_GOOGLE_LOGIN_REQUEST":
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case "FETCH_GOOGLE_LOGIN_SUCCESS":
            return {
                ...state,
                isLoading: false,
                googleData: action.payload,
            };
        case "FETCH_GOOGLE_LOGIN_FAILURE":
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export { authReducer, googleReducer };
