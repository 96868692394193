import React from 'react'
import  SecureStorage from 'assets/img/user-protection/safe-sign-in.png';
import  RealTime from 'assets/img/user-protection/device.png';
import  OrganizeSecurity from 'assets/img/user-protection/notification.png';
 
function UserLevelSceurity() {

    const securityData = [
        {
            id: 1 ,
            title : "Safe Sign In",
            Desc: "BUYCEX enforces strict sign-in protocols with two-factor authentication, utilizing hardware, app-based, SMS, and email methods.",
            img :  SecureStorage,
            
        },
        {
            id: 2 ,
            title : "Access Control",
            Desc: " Advanced access control offers users optional security features like IP and wallet address whitelisting, API access control, and device management.",
            img :  RealTime,
        },
        {
            id: 3 ,
            title : "Security Notifications",
            Desc: "Receive emails, notifications, and security alerts when suspicious activity is detected.Restricting access to unwanted third parties is an instance where users can secure their accounts.",
            img :  OrganizeSecurity,
        },
        
    ]
  return (
   <>
{
  securityData.map((data) => ( 

    <div className="flex items-center gap-3 mt-4"   key={data.id}>
                <div className='w-[150px] h-auto first-letter: overflow-hidden'>
                  <img
                    className=" w-full h-full object-contain "
                    src= {data.img}
                    alt="img"
                  />
                </div>
                <div>
                  <h2 className="text-lg font-semibold ">{data.title}</h2>
                  <p>
                    {data.Desc}
                  </p>
                </div>
              </div> 

  ))
} 
   </>
  )
}

export default UserLevelSceurity
