const initialState = {
  user: null,
  error: null,
  uid: null,
  sid: null,
  Newemail: null,
  auth: null,
  authCheck: null,
  authUser: null,
  changePassword: null,
  disableAccount: null,
  favorites: null,
  get2FAQR: null,
  set2FA: null,
  googleRegister: null,
  myreducer: null,
  saveAntiPishing: null,
  secureLogin: null,
  userReducer: null,
  userInfo: null,
};

const authUser = (state = initialState, action) => {
  switch (action.type) {
      case "LOGIN":
          return {
              ...state,
              user: action.payload,
              uid: action.payload.UserId,
          };
          case "SIGNUP":
            return {
                ...state,
                sid: action.payload,
            };
          case "PHONE_SIGNUP":
            return {
                ...state,
                sid: action.payload,
            };
      case "LOGIN_ERROR":
          return {
            ...state,
            error: action.payload,
          };
      case "LOGOUT":
          localStorage.removeItem("auth");
          localStorage.removeItem("myreducer");
          return {
            initialState,
              ...state,
              user: null,
              uid: null,
              Newemail: null,
              auth: null,
              authCheck: null,
              authUser: null,
              changePassword: null,
              disableAccount: null,
              favorites: null,
              get2FAQR: null,
              set2FA: null,
              googleRegister: null,
              myreducer: null,
              saveAntiPishing: null,
              secureLogin: null,
              userReducer: null,
              userInfo: null,
          };
      default:
          return state;
  }
};

export default authUser;