import React, { useState, useEffect } from 'react';
import Crypto from './Crypto/Crypto';
import Transfer from './Transfer/Transfer';
import Distribution from './Distribution/Distribution';
import BNBConvert from './BNBConvert/BNBConvert';
import Loan from './Loan/Loan';
import FundingWallet from './FundingWallet/FundingWallet';
import Referral from './Referral/Referral';
import { RiInformationFill } from "react-icons/ri";
import HistoryDropdown from '../SpotHistory/DropDownUI';

const TransferHistory = () => {
    const [activeItem, setActiveItem] = useState('Transfer');
    const [activeSubItem, setActiveSubItem] = useState(null);

    const discoverNavItems = ['Transfer'];
    const referralSubItems = ['Commission Rebate', 'Shared Commission'];

    const option1 = ["All Accounts", "Spot (Margin Included)", "Futures", "Mining"];

    const handleSelect1 = (selected) => {
        console.log("Dropdown 1 selected:", selected);
    };

    useEffect(() => {
        if (activeItem === 'Referral') {
            setActiveSubItem('Commission Rebate');
        }
    }, [activeItem]);

    const renderContent = () => {
        switch (activeItem) {
            case 'Crypto':
                return <Crypto />;
            case 'Transfer':
                return <Transfer />;
            case 'Distribution':
                return <Distribution />;
            case 'BNBConvert':
                return <BNBConvert />;
            case 'Loan':
                return <Loan />;
            case 'Funding Wallet':
                return <FundingWallet />;
            case 'Referral':
                return <Referral subItem={activeSubItem} />;
            default:
                return <Crypto />;
        }
    };

    const renderSubItems = () => {
        if (activeItem !== 'Referral') return null;

        return (
            <>
                <div className='bg-[#F0B90B1A] mt-6 rounded-xl'>
                    <p className='flex py-4 px-2 gap-1 items-center text-sm'><RiInformationFill className='text-lg' />Only records from the past 7 days are shown. For your detailed transaction history, tap "Export" in the top right of the page. To view more referral information, <span className='text-[#EFB81C] text-sm'>click here</span></p>
                </div>
                <div className='flex gap-2 mt-6 items-center'>
                    <div className='inline-flex flex-wrap border rounded-lg pt-[0.20rem] pb-[0.25rem] px-1 gap-2'>
                        {referralSubItems.map((subItem) => (
                            <button
                                key={subItem}
                                onClick={() => setActiveSubItem(subItem)}
                                className={`w-auto py-1.5 px-6 text-sm font-medium ${activeSubItem === subItem ? 'bg-[#F5F5F5] rounded-md' : 'text-gray-400'}`}
                            >
                                {subItem}
                            </button>
                        ))}
                    </div>
                    <HistoryDropdown
                        label="Account"
                        options={option1}
                        initialSelected="All Accounts"
                        onSelect={handleSelect1}
                        isSearchable={false}
                        width="80px"
                    />
                    <button className='font-semibold ml-4 text-[#EFB81C]'>Reset</button>
                </div>
            </>
        );
    };

    return (
        <div className='px-3'>
            <div className='container mt-5 !p-0'>
                <nav className='flex gap-3 w-[60%] border-gray-200 pt-2'>
                    {discoverNavItems.map((item) => (
                        <button
                            key={item}
                            onClick={() => {
                                setActiveItem(item);
                                if (item !== 'Referral') {
                                    setActiveSubItem(null); // Reset subitem if not on Referral
                                }
                            }}
                            className={`relative py-2 px-2 text-md font-semibold ${activeItem === item ? '' : 'text-gray-400'}`}
                        >
                            {item}
                            {activeItem === item && (
                                <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                            )}
                        </button>
                    ))}
                </nav>

                {renderSubItems()}

                <div className="mt-6">{renderContent()}</div>
            </div>
        </div>
    );
};

export default TransferHistory;
