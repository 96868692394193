import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ endtime }) => {
  const calculateTimeLeft = () => {
    const endDate = new Date(endtime).getTime();
    const now = new Date().getTime();
    const difference = endDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [ endtime]);

  return (
    <div className="flex items-start justify-center gap-2">
      <div>
        <button className="px-2 font-semibold bg-yellow-500 rounded-sm">{timeLeft.days}</button>
        <div className="text-[#81858c] text-[12px]">Day</div>
      </div>
      <button>:</button>
      <div>
        <button className="px-2 font-semibold bg-yellow-500 rounded-sm">{timeLeft.hours}</button>
        <div className="text-[#81858c] text-[12px]">Hour</div>
      </div>
      <button>:</button>
      <div>
        <button className="px-2 font-semibold bg-yellow-500 rounded-sm">{timeLeft.minutes}</button>
        <div className="text-[#81858c] text-[12px]">Minute</div>
      </div>
      <button>:</button>
      <div>
        <button className="px-2 font-semibold bg-yellow-500 rounded-sm">{timeLeft.seconds}</button>
        <div className="text-[#81858c] text-[12px]">Second</div>
      </div>
    </div>
  );
};

export default CountdownTimer;