import React from "react";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

const DisableConfirmation = ({ isOpen, onClose, onConfirm, source }) => {
    if (!isOpen) return null;

    const getMessage = () => {
        if (source === "deleteAccount") {
            return "Deleting your account will result in the permanent loss of all your data and assets.";
        }
        return "Disabling your account will result in the immediate termination of all account services and functions.";
    };

    const getMessageAlert = () => {
        if (source === "deleteAccount") {
            return "Delete Confirmation";
        }
        return " Disable Confirmation";
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center backdrop-blur-[1px] z-20">
            {" "}
            <div className="bg-white p-8 rounded-xl shadow-lg relative max-w-md w-full">
                <button
                    onClick={onClose}
                    aria-label="Close"
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                >
                    <IoClose className="text-2xl text-gray-400" />
                </button>
                <div className="flex justify-center items-center mb-5">
                    <BsExclamationDiamondFill className="text-6xl text-[#EFB81C]" />
                </div>
                <p className="text-center font-bold text-lg mb-2">
                   {getMessageAlert()}
                </p>
                <p className="text-sm text-gray-700 mb-4">{getMessage()} </p>
                <div className="flex justify-center gap-4">
                    <button
                        type="button"
                        onClick={onClose}
                        aria-label="Cancel"
                        className="w-1/2 bg-[#fff1cc] text-[#EFB81C] hover:bg-[#f7e6a4] font-medium rounded-lg text-md px-5 py-2"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={onConfirm}
                        aria-label="Confirm"
                        className="w-1/2 bg-[#EFB81C] text-white hover:bg-[#d4a800] font-medium rounded-lg text-md px-5 py-2"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DisableConfirmation;