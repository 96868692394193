import React from 'react';
import { TbCaretUpDownFilled } from "react-icons/tb";
import NoData from "assets/img/user/No-data.svg";
import { MdKeyboardArrowDown } from "react-icons/md";
import { HiOutlineDotsVertical } from "react-icons/hi";
import FromTransferFunds from "assets/img/From-transferFunds.svg";
import ToTransferFunds from "assets/img/To-transferFunds.svg";

function WalletView({ isVisible, searchQuery, showGreaterThanOne }) {
    const popularCoins = [
        {
            name: 'Spot',
            icon: <img src={FromTransferFunds} alt="Spot" className='w-8 h-8' />, // Replace icon with image
            color: '#F7931A',
            amount: '0.0',
            ratio: '--',
            descriptions: {
                amount: '$0.0',
            }
        },
        {
            name: 'Funding',
            icon: <img src={ToTransferFunds} alt="Funding" className='w-8 h-8' />, // Replace icon with image
            color: '#3C3C3D',
            amount: '0', // Updated amount to test the filtering
            ratio: '--',
            descriptions: {
                amount: '$0.0',
            }
        },
        {
            name: 'Earn',
            icon: <img src={ToTransferFunds} alt="Funding" className='w-8 h-8' />, // Replace icon with image
            color: '#3C3C3D',
            amount: '$0.0',
            ratio: '--',
            descriptions: {
                amount: '$0.0',
            }
        },
        {
            name: 'USDⓈ-M Future',
            icon: <img src={ToTransferFunds} alt="Funding" className='w-8 h-8' />, // Replace icon with image
            active: <div className='bg-gray-50 rounded-xl py-1 px-2'>inactive</div>,
            color: '#3C3C3D',
            amount: '$0.0',
            ratio: '--',
            growth: '150%',
            descriptions: {
                amount: '$0.0',
            }
        },
    ];

    // Filter coins based on the search query and the checkbox state
    const filteredCoins = popularCoins.filter(coin => {
        const matchesSearch = coin.name.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesAmount = showGreaterThanOne ? parseFloat(coin.amount) > 1 : true; // Check the amount condition
        return matchesSearch && matchesAmount;
    });

    return (
        <div className='w-full'>
            {/* Header row */}
            <div className='flex w-full mb-8'>
                <div className='flex items-center gap-1 w-2/5'>
                    <p className='text-xs text-[#929AA5]'>Coins</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Amount</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Ratio</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Action</p>
                </div>
            </div>

            {/* Check if there are any filtered coins and display the appropriate message */}
            {filteredCoins.length === 0 ? (
                <div className='text-center text-md'>
                    <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                        <img src={NoData} alt="No Data" />
                        <p className='text-[#707a8a] text-sm'>No records</p>
                    </div>
                </div>
            ) : (
                filteredCoins.map((coin, index) => (
                    <div key={index} className='flex w-full items-center mb-4'>
                        <div className='flex gap-2 w-2/5'>
                            <div className='flex items-center gap-2'>
                                <div className='text-lg' style={{ color: coin.color }}>
                                    {coin.icon}
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <div className='flex gap-2'><p className='text-md font-normal'>{coin.name}</p><span>{coin.active}</span></div>
                                <p className='text-sm text-[#929AA5]'>{coin.descriptions.coin}</p>
                            </div>
                        </div>
                        <div className='flex flex-col gap-1 w-1/5'>
                            <p className='text-md text-end font-normal'>{isVisible ? coin.amount : '****'}</p>
                            <p className='text-sm text-end text-[#707a8a]'>{isVisible ? coin.descriptions.amount : '****'}</p>
                        </div>
                        <div className='flex flex-col gap-1 w-1/5'>
                            <p className='text-md text-end font-normal'>{coin.ratio}</p>
                        </div>
                        <div className='flex justify-end w-1/5'>
                            <button className='text-sm underline flex items-center'><HiOutlineDotsVertical className='text-lg text-[#707a8a]' /></button>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}

export default WalletView;
