import React, { useEffect, useState } from "react";
import TableOverview from "components/user/assets/tables/TableOverview";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import fetchAssetsData from "store/actions/AssetsUserAction";
import { useDispatch, useSelector } from "react-redux";
import { GrOverview } from "react-icons/gr";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Balance from "components/user/assets/Balance";
import { FaRegQuestionCircle } from "react-icons/fa";
import { IoEye } from "react-icons/io5";

const AssetsOverview = () => {
  const [hideAssets, sethideAssets] = useState(false);
  const dispatch = useDispatch();
  const { loading, assetsData = {}, error } = useSelector(
    (state) => state.assetsData
  );
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);

  const history = useHistory();

  useEffect(() => {
    if(!userID && !userToken){
      history.push("/login");
    }
    if (userID && userToken) {
      dispatch(fetchAssetsData(userID, userToken, "p2p"));
    }
  }, [dispatch, userID, userToken, history]);

  const threeCards = [
    {
      id: 0,
      heading: "Crypto Direct Buy",
      desc: "using your preferred payment option",
    },
    {
      id: 1,
      heading: "Crypto Deposit",
      desc: "by using your deposit address",
    },
    {
      id: 2,
      heading: "Buy Crypto by Depositing Fiat",
      desc: "by using your bank account",
    },
  ];

  useEffect(() => {
    console.log("data ", assetsData);
  }, [assetsData]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg text-red-500">Error: {error}</div>
      </div>
    );
  }

  return (
    <>
      <div className="relative flex items-center gap-x-3 bottom-3">
      <i class="bi bi-binoculars-fill text-[26px]"> </i>
        <div className="text-black text-[24px] font-semibold">Overview</div>
      </div>
      {/* Estimated Balance Container */}

      <div className="w-full p-4 border-[1px] rounded-2xl bg-white">
        {/* Headings */}
        <div className="flex items-center justify-between w-full">
          <div className="text-[16px] flex items-center gap-x-2 font-semibold">
            <h6>Total Assets</h6>
            <IoEye
              onClick={() => sethideAssets(!hideAssets)}
              size={20}
              className="cursor-pointer text-[#868686]"
            />
          </div>
          <div className="flex items-center gap-x-3 text-[#202020] ">
            <button className="bg-[#F4F4F4] text-[14px]  px-3 py-2 rounded-[10px]">
              Buy Crypto via P2P
            </button>
            <button className="bg-[#F4F4F4] text-[14px]  px-3 py-2 rounded-[10px]">
              Buy Crypto via Third Party
            </button>
          </div>
        </div>

        {/* Price */}
        <div className="flex items-center my-2 gap-x-3">
          <div className="text-[20px] font-bold">
            {hideAssets ? "****" : parseFloat(assetsData.conv_total).toFixed(2)}
          </div>
          <select
            name=""
            id=""
            className="bg-[#ededed] w-[70px] h-[10px] py-0 text-[12px] focus:outline-none rounded-md  cursor-pointer px-1"
          >
            <option className=" text-[12px]" value={assetsData.conversion_coin}>
              {assetsData.conversion_coin}
            </option>
          </select>
        </div>

        {/* Total Value & buttons */}
        <div className="flex items-start justify-between">
          <div className="text-[16px] text-neutral-300 font-medium">
            = $
            {hideAssets ? "****" : parseFloat(assetsData?.usd?.zj).toFixed(2)}
            <div className="flex gap-2">
              <p className="text-[16px] text-neutral-300 flex items-center gap-1">
                Today's PnL <FaRegQuestionCircle />
              </p>
              <p className="text-[16px] dark:text-white text-neutral-900 font-semibold">
                {hideAssets
                  ? "****"
                  : `${parseFloat(assetsData?.usd?.ky).toFixed(
                      2
                    )} (${parseFloat(assetsData?.usd?.dj).toFixed(2)}%)`}
              </p>
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex mt-3 text-[#202020] font-medium items-center gap-x-3 text-[16px]">
          <button className="bg-[#F4F4F4] text-[14px]  px-3 py-2 rounded-[10px]">
            Deposit
          </button>
          <button className="bg-[#F4F4F4] text-[14px]  px-3 py-2 rounded-[10px]">
            Withdraw
          </button>
        </div>
      </div>

      {/* Table */}
      <TableOverview
        hideAssets={hideAssets}
        error={error}
        loading={loading}
        assetsData={assetsData}
      />
    </>
  );
};

export default AssetsOverview;
