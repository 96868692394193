import Repository from "./Repository";

const voteUrl = "/Vote/up";

const getHeaders = (token, userId) => ({
    'TOKEN': token,
    'ID': userId,  // User ID as a custom header
    'Content-Type': 'application/x-www-form-urlencoded'  // Form data content type
});

export const voteRepository = {
  vote: async (type, coinId, userId, token) => {
    try {
      // Log to verify parameter values
      console.log("Type:", type);
      console.log("Coin ID:", coinId);
      console.log("User ID:", userId);
      console.log("Token:", token);

      // Prepare form data for the request body
      const formData = new URLSearchParams();
      formData.append('type', type);
      formData.append('id', coinId);  // Coin ID in the form data

      const response = await Repository.post(
        voteUrl,
        formData,
        { headers: getHeaders(token, userId) }
      );

      console.log("Response from vote API:", response.data);

      // Return success based on API response
      return { success: response.data.status === 1, message: response.data.data };
    } catch (error) {
      console.error("Error in vote API:", error);
      return { success: false, message: error.message };
    }
  },
};
