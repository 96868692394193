import React, { useState } from 'react';

const content = {
    buy: [
        {
            title: "1. Place an Order",
            description: "Once you place a P2P order, the crypto asset will be escrowed by Buycex P2P.",
        },
        {
            title: "2. Pay the Seller",
            description: `Send money to the seller via the suggested payment methods. Complete the fiat transaction and click "Transferred, notify seller" on Buycex P2P.`
        },
        {
            title: "3. Receive Crypto",
            description: "Once the seller confirms receipt of money, the escrowed crypto will be released to you.",
        }
    ],
    sell: [
        {
            title: "1. Place an Order",
            description: "After you place an order, your crypto will be escrowed by Buycex P2P.",
        },
        {
            title: "2. Verify Payment",
            description: "Check the transaction record in the given payment account, and make sure you receive the money sent by the buyer.",
        },
        {
            title: "3. Release Crypto",
            description: "Once you confirm the receipt of money, release crypto to the buyer on Buycex P2P.",
        }
    ]
};

function P2pWork() {
    const [isClicked, setIsClicked] = useState(true); // Default to Buy button

    const handleButtonToggler = (type) => {
        setIsClicked(type === 'buy');
    };

    const currentContent = isClicked ? content.buy : content.sell;

    return (
        <div>
            {/* Steps Section */}
            <div className="flex flex-col items-start justify-between w-full gap-8">
                <div className='flex flex-col gap-5 sm:gap-5 md:gap-5 lg:gap-0 md:flex-col lg:flex-row justify-between w-full'>
                    <h3 className="text-[20px] md:text-[24px] lg:text-[40px] font-bold capitalize">
                        How P2P Works
                    </h3>
                    <div className="flex gap-3">
                        <button
                            onClick={() => handleButtonToggler('buy')}
                            className={`pb-1 text-[14px] h-[35px] font-medium px-4 py-1 rounded-lg ${isClicked ? 'bg-[#F5F5F5]' : 'bg-transparent text-[#707A8A]'} `}
                        >
                            Buy Crypto
                        </button>
                        <button
                            onClick={() => handleButtonToggler('sell')}
                            className={`pb-1 text-[14px] h-[35px] font-medium px-4 py-1 rounded-lg ${!isClicked ? 'bg-[#F5F5F5]' : 'bg-transparent text-[#707A8A]'} `}
                        >
                            Sell Crypto
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap gap-6">
                    {currentContent.map((item, index) => (
                        <div
                            key={index}
                            className="w-full sm:w-[48%] lg:w-[32%] flex flex-col justify-between rounded-[20px] px-8 py-4 border text-start bg-white min-h-[300px]"
                        >
                            <div className="rounded-full w-[80px] h-[80px] bg-[#D9D9D9] mb-4"></div>
                            <h4 className="text-[20px] font-medium mb-2">
                                {item.title}
                            </h4>
                            <p className="text-[14px] flex-1">
                                {item.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default P2pWork;
