import React from "react";
import SummaryCard from "./SummaryCard";
import { NavLink } from "reactstrap";

const topbuttons = [
    {
        id: 1,
        title: "Trade Now",
        link: "/trade/spot",
        icon: "//s1.bycsi.com/bybit/deadpool/image-f921f812edc64b5c8a1809617f250a8e.svg",
    },
    {
        id: 2,
        title: "Convert",
        link: "/user/assets/spot",
        icon: "//s1.bycsi.com/bybit/deadpool/image-96389ab17bda4838b8479dc0b1871e2c.svg",
    },
];
const BcxToken = () => {
    return (
        <div className="w-full bg-white">
            <div className="earn-overview-header h-96">
                <div className="px-10">
                    <div className=" container flex flex-col lg:flex-row items-center justify-between gap-4  ">
                        <div className="w-full lg:w-[50%] ">
                           
                            <span className=" text-[48px] font-[900] text-[#EFB81C] mt-12">  BUYCEX OWNER</span>
                            <p className="mt-[16px] lg:text-[18px] text-[16px] font-base  text-[#81858c]">
                                Buycex Token Is Most Powerful Token. You Can
                                Make High Earning On Every Month. When You Hold
                                BCX TokenYou Get a reward everymonth distributed
                                from Exchange Profit.
                            </p>
                            {/* the link buttons  */}
                            <div className="flex items-center gap-4 mt-4 premarket-navbutton">
                                {topbuttons.map((button) => (
                                    <NavLink to={button.link} key={button.id}>
                                        <button
                                            className="flex bg-yellow-400  
               p-[8px] h-[24px] rounded-[4px] gap-1 font-medium items-center text-white text-[12px] "
                                        >
                                            {" "}
                                            <img
                                                src={button.icon}
                                                alt=""
                                                className="w-[14px] h-[14px]"
                                            />{" "}
                                            {button.title}
                                        </button>
                                    </NavLink>
                                ))}
                            </div>
                            {/* the total vlaues section  */}
                            <div className="flex items-center text-[#EFB81C] gap-[16px] md:gap-[24px] mt-[24px] pb-2">
                                <div className="flex gap-4 md:gap-6 items-center">
                                    <div>
                                        <h1 className="md:text-[24px] text-[18px] font-semibold default-font ">
                                            94,650
                                        </h1>
                                        <p className="text-[#81858c] font-medium text-[12px]">
                                            Token Sold
                                        </p>
                                    </div>
                                    <div className="text-3xl">|</div>
                                </div>
                                <div className="flex gap-4 md:gap-6 items-center">
                                    <div>
                                        <h1 className="md:text-[24px] text-[18px] font-semibold default-font ">
                                            $20,030,495
                                        </h1>
                                        <p className="text-[#81858c] font-medium text-[12px]">
                                            Total Trading Volume (USD)
                                        </p>
                                    </div>
                                    <div className="text-3xl ">|</div>
                                </div>
                                <div className="">
                                    <div>
                                        <h1 className="md:text-[24px] text-[18px] font-semibold default-font ">
                                            25,000,000 USDT
                                        </h1>
                                        <p className="text-[#81858c] font-medium text-[12px]">
                                            Last Month Exchange Earning
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-[50%] w-full">
    <div className="border-2 border-[#EFB81C] text-[#fff] rounded-[14px] shadow-lg p-10 flex items-center justify-between gap-8">
        <div className="flex-1 flex flex-col gap-4">
            <p className="text-[#adb1b8] text-[14px] font-semibold">
                BCX Token Holdings
            </p>
            <div className="flex items-center gap-1">
                <p className="text-[20px] font-semibold text-[#fff]">
                    100{" "}
                    <span className="text-[20px] font-semibold text-yellow-500">
                        BCX
                    </span>
                </p>
                <button></button>
            </div>
            <button className="w-full bg-[#ffb11a] h-[40px] rounded-[8px] font-semibold">
                My Earnings
            </button>
        </div>
        <div className="flex-1 flex flex-col gap-4 items-end">
            <p className="text-[#adb1b8] text-[14px] font-semibold">
                Market Price
            </p>
            <div className="flex items-center gap-1">
                <p className="text-[20px] font-semibold text-[#20b26c]">
                    1000 USDT
                </p>
                <button></button>
            </div>
            <button className="w-full h-[40px] font-semibold border-solid border-[1px] hover:bg-[#ffb11a] text-black border-[#595d61] rounded-[8px]">
                Order History
            </button>
        </div>
    </div>
</div>

                    </div>
                </div>
            </div>
            <div className="px-10">
                <div className="container py-4 bg-white">
                    {/* <h2 className=" font-bold text-3xl ">Summary</h2>
                    <div className="mt-4 flex justify-between gap-4">
                        <SummaryCard />
                        <SummaryCard />
                        <SummaryCard />
                        <SummaryCard />
                    </div> */}
                  
                    <div className="mt-8">
                        <h2 className=" font-bold text-3xl ">
                            Top Buycex Holder{" "}
                        </h2>
                    </div>
                    <div className="mt-8 flex justify-between gap-4">
                        <SummaryCard threestar />
                        <SummaryCard negative twostar />
                        <SummaryCard negative />
                        <SummaryCard threestar />
                    </div>
                    <div className="mt-8">
                        <h2 className=" font-bold text-3xl ">
                            Account Summary{" "}
                        </h2>
                    </div>
                    <div className="mt-8 border border-solid border-[#EFB81C] rounded-xl  p-6">
                        <div className="flex justify-between items-start">
                            <div>
                                <h2 className=" font-bold text-2xl text-[#a0a0a0] ">
                                    Your Holdings
                                </h2>
                                <p className="font-semibold">
                                    Available Balance
                                </p>
                                <h2 className="font-semibold text-xl">
                                    $ 0 USDT
                                </h2>
                            </div>
                            <div className="flex items-center justify-between gap-2">
                                <button className="font-medium  w-28 py-2 rounded-lg bg-yellow-500 text-white">
                                    Deposit
                                </button>
                                <button className="font-medium border border-solid border-[#a0a0a0] w-28 py-2 rounded-lg">
                                    Withdraw
                                </button>
                                <button className="font-medium border border-solid border-[#a0a0a0] w-28 py-2 rounded-lg">
                                    Transfer
                                </button>
                            </div>
                        </div>
                        <div className="mt-8">
                            <div className="flex border-b py-4 font-semibold border-solid border-[#a0a0a0] gap-1 !text-[#a0a0a0] ">
                                <div className="flex-1">Coin</div>
                                <div className="flex-1">Price</div>
                                <div className="flex-1">Total Hold</div>
                                <div className="flex-1">Balance (USD)</div>
                                <div className="flex-1">Freeze</div>
                                <div className="flex-1 text-right">Options</div>
                            </div>
                            <div className="flex  py-4 font-semibold  gap-1">
                                <div className="flex-1">BuyCex (BCX)</div>
                                <div className="flex-1">$0.00</div>
                                <div className="flex-1">0.00</div>
                                <div className="flex-1">0.00</div>
                                <div className="flex-1">0.00</div>
                                <div className="flex-1 text-right">
                                    <button className="font-medium  w-28 py-2 rounded-lg bg-yellow-500 text-white">
                                        Deposit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BcxToken;
