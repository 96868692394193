import React, { useState, useRef, useEffect, useMemo } from "react";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import styled from 'styled-components';
import { IoSearchOutline } from "react-icons/io5";
import { Input } from "antd";

const HistoryDropdown = ({
  label,
  options,
  initialSelected,
  onSelect,
  isOpen,
  onToggle,
  width = "auto",
  isSearchable,
}) => {
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(initialSelected);
  const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);

  const filteredOptions = useMemo(() =>
    isSearchable
      ? options.filter((option) =>
        option.toLowerCase().includes(search.toLowerCase())
      )
      : options,
    [search, options, isSearchable]
  );

  useEffect(() => {
    setSelected(initialSelected);
  }, [initialSelected]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleOptionClick = (option) => {
    setSelected(option);
    onSelect(option);
    setIsDropdownOpen(false);
    setSearch("");
  };

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setSearch("");
    onToggle && onToggle(!isDropdownOpen);
  };

  const MAX_CHARACTERS = 10; // Adjust this value as needed

  const truncateText = (text) => {
    if (text.length > MAX_CHARACTERS) {
      return `${text.substring(0, MAX_CHARACTERS)}...`;
    }
    return text;
  };


  return (
    <div
      className="relative min-w-[190px] inline-block text-left"
      style={{ width }}
    >
      <div ref={buttonRef}>
        <button
          onClick={handleToggleDropdown}
          type="button"
          className="inline-flex items-center w-full justify-between gap-x-1.5 rounded-lg px-3 py-2.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-200 hover:bg-gray-50"
          aria-expanded={isDropdownOpen}
          aria-haspopup="true"
        >
          <div className="flex gap-4 justify-between w-full">
            <h6 className="text-sm text-[#707A8A] whitespace-nowrap">{label}</h6>
            <div className="flex items-center gap-2">
              <p className="font-medium">
                <span className="text-sm truncate min-w-[80px] overflow-hidden whitespace-nowrap">
                  {truncateText(selected)}
                </span>
              </p>
              {isDropdownOpen ? (
                <MdOutlineArrowDropUp className="text-[18px] text-[#707A8A]" />
              ) : (
                <MdOutlineArrowDropDown className="text-[18px] text-[#707A8A]" />
              )}
            </div>
          </div>
        </button>

      </div>

      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute z-10 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          style={{ top: 'calc(100% + 8px)' }}
          aria-orientation="vertical"
        >
          <div className="py-1">
            {isSearchable && (
              <div className="m-2">
                <StyledInput
                  type="text"
                  placeholder="Search options"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                  prefix={<IoSearchOutline />}
                />
              </div>
            )}
            <ul className="max-h-60 overflow-auto">
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <li
                    key={option}
                    onClick={() => handleOptionClick(option)}
                    className={`flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 ${selected === option ? 'text-[#EFB81C]' : ''}`}
                  >
                    <h6 className="text-sm">{option}</h6>
                  </li>
                ))
              ) : (
                <li className="px-4 py-2 text-[14px] text-center text-gray-500">
                  No results found
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

const StyledInput = styled(Input)`
  padding: 0.55rem;
  font-weight: bold;
  color: #707A8A;
  border: 1.5px solid #DBDBDB;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #EFB81C;
  }

  &:focus,
  &:focus-within {
    border-color: #EFB81C;
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2);
    outline: none;
  }
`;

export default HistoryDropdown;
