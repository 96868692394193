import React, { useEffect, useState } from 'react';
import { TbCaretUpDownFilled } from "react-icons/tb";
import { BsThreeDotsVertical } from "react-icons/bs";
import ConvertModal from 'views/crypto/ConvertModal';
import TransferModal from 'views/trade/PostAds/TransferModal';

function IsolatedMarginPositionTable({ searchTerm, isVisible, toggleVisibility }) {
  const [data, setData] = useState([]);
  const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
  
  const handleTransferClick = () => {
    setIsTransferModalVisible(true);
  };

  const handleTransferCancel = () => {
    setIsTransferModalVisible(false);
  };

  const popularCoins = [
    {
      pair: '1000SATS/FDUSD',
      name: '1000SATS',
      amount: '0.00000000',
      price: '0.00000000',
      growth: '0.00000000',
      descriptions: {
        coin: 'FDUSD',
        amount: '0.00000000',
        price: '0.00000000',
        growth: '0.00000000',
        margin: '999',
      }
    },
    {
      pair: '1000SATS/USDC',
      name: '1000SATS',
      amount: '0.00000000',
      price: '0.00000000',
      growth: '0.00000000',
      descriptions: {
        coin: 'USDC',
        amount: '0.00000000',
        price: '0.00000000',
        growth: '0.00000000',
        margin: '999',
      }
    },
    // Add more coins with descriptions as needed
  ];

  const filteredData = popularCoins.filter(coin =>
    coin.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="w-full overflow-x-auto">
      <div className='flex min-w-[700px] mt-6 mb-8'>
        <div className='flex items-center gap-1 w-2/5'>
          <p className='text-xs text-[#929AA5]'>Pair</p>
          <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
        </div>
        <div className='flex items-center gap-1 w-1/4'>
          <p className='text-xs text-[#929AA5]'>Coin</p>
          <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
        </div>
        <div className='flex items-center justify-end gap-1 w-1/4'>
          <p className='text-xs text-[#929AA5]'>Position</p>
          <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
        </div>
        <div className='flex items-center justify-end gap-1 w-1/4'>
          <p className='text-xs text-[#929AA5] text-ellipsis whitespace-nowrap'>Position Value</p>
          <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
        </div>
        <div className='flex items-center justify-end gap-1 w-1/4'>
          <p className='text-xs text-[#929AA5]'>Price Index</p>
          <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
        </div>
        <div className='flex items-center justify-end gap-1 w-1/4'>
          <p className='text-xs text-[#929AA5] text-ellipsis whitespace-nowrap'>Liquidation Price</p>
          <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
        </div>
        <div style={{ boxShadow: '-5px 0 5px -5px rgba(0, 0, 0, 0.2)' }} className='flex justify-end gap-1 w-2/5'>
          <p className='text-xs text-[#929AA5]'>Action</p>
        </div>
      </div>

      {/* Rendering each coin data */}
      <div>
        {filteredData.length > 0 ? (
          filteredData.map((coin, index) => (
            <div key={index} className='flex min-w-[700px] mb-4 items-center'>
              <div className='flex flex-col items-start gap-1 w-2/5'>
                <div className='flex items-center gap-2'>
                  <span className='text-sm font-semibold'>{coin.pair}</span>
                </div>
                <p className='text-xs text-[#2ebd85]'>Margin Level {coin.descriptions.margin}</p>
              </div>
              <div className='flex flex-col items-start gap-1 w-1/4'>
                {isVisible ? <p className='text-sm text-end'><p>{coin.name}</p>
                  <p className='text-sm text-end'>{coin.descriptions.name}</p></p> : <p className='text-end'>********</p>}
              </div>
              <div className='flex flex-col justify-end w-1/4'>
                {isVisible ? <p className='text-sm text-end'><p>{coin.amount}</p>
                  <p className='text-sm text-end'>{coin.descriptions.amount}</p></p> : <p className='text-end'>********</p>}
              </div>
              <div className='flex flex-col justify-end w-1/4'>
                {isVisible ? <p className='text-sm text-end'><p>{coin.price}</p>
                  <p className='text-sm text-end'>{coin.descriptions.price}</p></p> : <p className='text-end'>********</p>}
              </div>
              <div className='flex flex-col justify-end w-1/4'>
                {isVisible ? <p className='text-sm text-end'><p>{coin.growth}</p>
                  <p className='text-sm text-end'>{coin.descriptions.growth}</p></p> : <p className='text-end'>********</p>}
              </div>
              <div className='flex items-center justify-end w-1/4'>
              <p className='text-sm'>{isVisible ?'/' : '********'}</p>
              </div>
              <div className='flex items-center justify-end w-2/5'>
                <button onClick={handleTransferClick} className='px-2 py-1 text-[#EFB81C] text-sm font-semibold rounded'>Transfer</button>
                <TransferModal visible={isTransferModalVisible} onCancel={handleTransferCancel} />
                <button className='px-2 py-1 text-[#EFB81C] text-sm font-semibold rounded'>Trade</button>
                <BsThreeDotsVertical />
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500 mt-4">No data found</p> // No data message
        )}
      </div>
      </div>
    </div>
  );
}

export default IsolatedMarginPositionTable;
