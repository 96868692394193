// src/components/securitySettings/antiPhishing/AntiPhishingPopup.js
import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import AntiPishingImg from "assets/img/anti-pushing.svg";
import { Input } from "antd";
import { Spinner } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { FaCircleCheck } from "react-icons/fa6";
import EmailVerification from "../SecuritSettingsComponents/helpers/EmailVerify";
import { forgotCode } from "store/actions/authAction";
import { fetchAuthStatus, saveAntiPishing } from "store/actions/userSecuritySettingsAction";

function AntiPhishingPopup({ onClose, email }) {
    const [step, setStep] = useState(1);
    const [antipishing, setAntipishing] = useState('');
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;
    const dispatch = useDispatch();

    useEffect(() => {
        const handleClickOutside = (event) => {
            const modal = document.querySelector(".modal-inner");
            const outermodal = document.querySelector(".modal-outer");
            if (modal && !modal.contains(event.target) && outermodal && !outermodal.contains(event.target)) {
                onClose();
            }
        };
        
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    useEffect(() => {
        if (step === 1) {
            const payload = { email };
            dispatch(forgotCode(payload));
        }
    }, [dispatch, email, step]);

    const handleNextClick = () => {
        if (step === 2) {
            setLoading(true);
            const payload = {
                ID: userId,
                TOKEN: token,
                antiphishing: antipishing
            };
            dispatch(saveAntiPishing(payload, () => {
                setLoading(false);
                setStep((prevStep) => prevStep + 1);
            }, () => {
                setLoading(false);
            }));
        } else if (step===3) {
            onClose();
            if (userId && token) {
                dispatch(fetchAuthStatus(userId, token));
            }
        } else {
            setStep((prevStep) => prevStep + 1);
        }
    };

    return (
        <div className="fixed inset-0 modal-outer flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="relative modal-inner bg-white rounded-xl shadow-lg p-6 w-2/5">
                <button
                    onClick={onClose}
                    aria-label="Close"
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                >
                    <IoClose className="font-bold text-[34px] m-2 text-[#D7D7D7]" />
                </button>
                <div>
                    <div className="flex items-center gap-4 mb-4">
                        <img src={AntiPishingImg} alt="Anti-phishing settings" />
                        <h2 className="text-xl font-bold">Anti-Phishing Code Settings</h2>
                    </div>
                    <div className="p-4">
                        <ul className="list-disc">
                            <li className="text-[#F65454] text-sm">
                                Check out whether incoming mail comes from BUYCEX via the anti-phishing code.
                            </li>
                            <li className="text-[#F65454] text-sm">
                                Do not share your code with anyone, including BUYCEX Customer Service.
                            </li>
                        </ul>
                    </div>
                    <div className="p-4 pl-4 pr-4 mb-3">
                        <div className="flex items-center">
                            <div className="relative flex items-center justify-center rounded-full border-2 border-[#EFB81C]">
                                <span className="text-[12px] pl-2 pr-2">Email Confirm</span>
                            </div>
                            <div className="flex-1 h-[2px] bg-[#EFB81C]"></div>
                            <div className={`relative flex items-center justify-center rounded-full border-2 ${step >= 2 ? 'border-[#EFB81C]' : 'border-gray-300'}`}>
                                <span className="text-[12px] pl-2 pr-2">Anti-Phishing Code Settings</span>
                            </div>
                            <div className={`flex-1 h-[2px] ${step >= 3 ? 'bg-[#EFB81C]' : 'bg-gray-300'}`}></div>
                            <div className={`relative flex items-center justify-center rounded-full border-2 ${step >= 3 ? 'border-[#EFB81C]' : 'border-gray-300'}`}>
                                <span className="text-[12px] pl-2 pr-2">Set successfully</span>
                            </div>
                        </div>
                    </div>
                    {step === 1 && (
                        <EmailVerification onSuccess={handleNextClick}/>
                    )}
                    {step === 2 && (
                        <div>
                            <div className="mt-4">
                                <span className="text-md font-semibold">Please enter anti-phishing code</span>
                                <Input
                                    className="mt-2"
                                    variant="filled"
                                    placeholder="Please enter anti-phishing code"
                                    style={{ height: '50px' }}
                                    value={antipishing}
                                    onChange={(e) => setAntipishing(e.target.value)}
                                />
                                <span className="text-[12px] text-[#B0B0B0]">Support only upper/lowercase letters and digits between 4-20 characters</span>
                            </div>
                            <button
                                type="button"
                                onClick={handleNextClick}
                                className="btn bg-yellow-500 font-semibold text-center w-full focus:no-underline focus:outline-none"
                                disabled={loading}
                            >
                                {loading ? <Spinner size="sm" />  : 'Next'}
                            </button>
                        </div>
                    )}
                    {step === 3 && (
                        <div>
                            <div className="mt-4 flex items-center gap-2 justify-center mb-4">
                                <FaCircleCheck className="text-[#EFB81C] text-[30px]"/>
                                <span className="text-md font-semibold">Anti-Phishing Code Set successfully</span>
                            </div>
                            <button
                                type="button"
                                onClick={handleNextClick}
                                className="btn bg-yellow-500 font-semibold text-center w-full focus:no-underline focus:outline-none"
                            >
                                Done
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AntiPhishingPopup;