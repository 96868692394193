import {
    WITHDRAW_REQUEST,
    WITHDRAW_SUCCESS,
    WITHDRAW_FAILURE,
} from "../types";

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const withdrawReducer = (state = initialState, action) => {
    switch (action.type) {
        case WITHDRAW_REQUEST:
            return {
                ...state,
                loading: true,
                error: null, // Clear errors on a new request
            };

        case WITHDRAW_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload, // Store API success data
                error: null, // Clear errors
            };

        case WITHDRAW_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error || action.payload, // Store error details
            };

        default:
            return state;
    }
};

export default withdrawReducer;
