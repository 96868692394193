import React, { useState } from 'react';
import { AiOutlineFileSearch } from "react-icons/ai";

function AllReviews() {
    return <div className="h-52 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
            <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
            <p className="mt-3 text-[#474D57]">No Comments</p>
        </div>
    </div>;
}

function PositiveReviews() {
    return <div className="h-52 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
            <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
            <p className="mt-3 text-[#474D57]">No Comments</p>
        </div>
    </div>;
}

function NegativeReviews() {
    return <div className="h-52 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
            <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
            <p className="mt-3 text-[#474D57]">No Comments</p>
        </div>
    </div>;
}

function Feedback() {
    const [activeTab, setActiveTab] = useState('All');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div>
            {/* Display percentage */}
            <div className='flex gap-1'>
                <p className='text-[24px] font-bold'>0.00</p>
                <p className='text-[24px] font-bold'>%</p>
            </div>

            {/* Display reviews */}
            <div>
                <p className='text-[#707A8A] text-[14px]'>0 Reviews</p>
            </div>

            {/* Navbar */}
            <div className='flex gap-8 mt-4 border-b h-10 pl-6'>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'All' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('All')}
                >
                    All
                </button>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'Positive' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('Positive')}
                >
                    <div className='flex items-center gap-1'><p className='text-[14px]'>Positive</p><p className='text-[14px]'>(0)</p></div>
                </button>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'Negative' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('Negative')}
                >
                    <div className='flex items-center gap-1'><p className='text-[14px]'>Negative</p><p className='text-[14px]'>(0)</p></div>
                </button>
            </div>

            {/* Content based on active tab */}
            <div className='mt-4'>
                {activeTab === 'All' && <AllReviews />}
                {activeTab === 'Positive' && <PositiveReviews />}
                {activeTab === 'Negative' && <NegativeReviews />}
            </div>
        </div>
    );
}

export default Feedback;
