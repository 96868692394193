import { Link } from "react-router-dom/cjs/react-router-dom.min";
import React from "react";
const PrivacyPolicy = () => {
    return (
        <>
            <main className="flex container  flex-col py-20 gap-y-9 justify-between items-center w-full h-full bg-[#f4f4f4] dark:bg-black">
                <h1 className="text-black my-12 dark:text-white text-5xl font-bold text-center w-full h-fit">
                    BuyCex Privacy Policy
                </h1>
                {/* main content */}
                {/*part 1 */}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[28px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        EFFECTIVE DATE: 21 June 2024
                    </h3>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        This Privacy Policy, referred to as the <b>"Policy,"</b>
                        pertains to the trading platform, which includes any
                        relevant mobile applications and websites used to access
                        it, collectively known as the <b>"Platform,"</b>{" "}
                        provided by Bybit Fintech Limited, also known as the{" "}
                        <b>"Company,"</b>
                        <b>"we," "us,"</b> or <b>"our."</b> The Policy outlines
                        how the Company collects, uses, and discloses Personal
                        Information obtained from Users of the Platform and any
                        account services provided through it, as well as how
                        that information is utilized and disclosed. Within this
                        Policy, the term <b>"Personal Information"</b> refers to
                        data supplied by a User that has the ability to identify
                        the User either directly or indirectly.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        By registering for and using the Platform, you consent
                        to the handling of your Personal Information as outlined
                        in this Policy and the Terms and Conditions applicable
                        to the Platform, known as the "Service Agreement"; any
                        capitalized terms used in this document have the same
                        meaning as defined in the Service Agreement.
                    </p>
                </div>
                {/*part 2 */}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        Our Data Collection Practices and How We Gather
                        Information from You
                    </h3>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        We acquire and process Personal Information pertaining
                        to you directly from you upon your registration to
                        utilize the Platform or when you provide such
                        information as a component of the Know-Your-Client (
                        <b>"KYC"</b>) process, in addition to automatically
                        gathering it through your utilization of the Platform.
                    </p>
                </div>
                {/*part 3 */}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        Information We Collect Directly From You
                    </h3>
                    {/* (i) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b> Personal Account and Profile Information:</b> There
                        are various types of accounts that can be created on the
                        Platform. Upon registration of an Account, we gather the
                        subsequent details: full name, cellphone number, and/or
                        email address. Should your Account satisfy specific
                        requirements, we might gather extra details including
                        your full name as stated on your identity card/passport,
                        identity card/passport number, date of birth,
                        nationality, mailing address, residential address,
                        mobile phone number, and email address.
                    </p>
                    {/* (ii) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b> Financial Information:</b> In order to confirm your
                        status and eligibility to establish other types of
                        accounts or carry out specific transactions on the
                        Platform, we may collect additional financial and
                        investment information from you. This could encompass
                        information regarding your investment background,
                        qualifications and training in finance (including
                        certifications obtained, issuing organization, and date
                        of issuance), annual income, liquid net worth, estimated
                        net worth, origin of funds, source of wealth, and any
                        prior instances of declined account applications with
                        other financial institutions.
                    </p>
                    {/* (iii) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b> Other Required Information:</b> We may need to
                        gather certain additional information to comply with
                        legal obligations. This could include determining
                        whether you are a Politically Exposed Individual or on
                        any restricted persons list, as well as obtaining
                        applicable tax reporting forms such as a W8-BEN.
                    </p>
                    {/* (iv) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b> Communications with Us:</b> Any information you
                        provide during support and feedback communications via
                        email or contact forms on the Platform will be collected
                        by us. We utilize this information to address your
                        inquiries, offer support, facilitate transactions, and
                        enhance our Platform.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        Kindly note that if you are serving as an Authorized
                        Individual representing a User and sharing Personal
                        Information on their behalf, it is your duty to
                        guarantee that you have acquired all essential
                        permissions and consents to share such personal
                        information with us for utilization on the Platform.
                        Additionally, you must ensure that our use of the
                        Personal Information you provide does not violate any
                        applicable laws.
                    </p>
                </div>
                {/*part 4 */}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        Information We Collect Automatically
                    </h3>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        When utilizing the Platform, our servers automatically
                        gather data through cookies and other tracking
                        technologies. This data includes information sent by
                        your browser when visiting the Platform or by your
                        mobile application while in use. The log data may
                        consist of your Internet Protocol address, the previous
                        web page visited before accessing the Platform, browser
                        type and settings, request date and time, details about
                        your browser configuration and plug-ins, language
                        preferences, and cookie data. This log data is retained
                        and not routinely deleted.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        Furthermore, we may gather details about the device you
                        use for the Platform, such as device type, operating
                        system, device settings, unique identifiers, and crash
                        data. The extent of information collected may vary based
                        on the device type and its settings.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        We may merge this data with other information we have on
                        record about you, including, if applicable, your name,
                        user name, email address, and other Personal Information
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        Geolocation. If you have authorized us through your
                        mobile device to gather location data using a mobile
                        app, we might obtain your precise location coordinates
                        in the form of latitude and longitude from technologies
                        such as GPS, Wi-Fi, or cell tower proximity. You have
                        the option to revoke permission for us to obtain such
                        physical location information from your mobile device
                        through your device settings, although we do not oversee
                        this process. For guidance on disabling your mobile
                        device’s location services, we suggest reaching out to
                        your mobile device service provider or the device
                        manufacturer.
                    </p>
                </div>
                {/*part 5 */}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        How We Use Your Information
                    </h3>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        We utilize the information you provide, including your
                        Personal Information, for the following objectives:
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>1. </b>To offer our Platform to you, enabling smooth
                        communication and transactions, addressing your
                        inquiries, fulfilling your orders, and providing
                        customer service.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>2. </b> To customize the content and information we
                        send or display to you, offering location-specific
                        customization, personalized assistance, and
                        instructions, thereby enhancing your user experience on
                        our Platform.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>3. </b> To gain a deeper understanding of how users
                        access and utilize the Platform, both in an aggregated
                        and individualized manner. This helps us improve our
                        Platform, cater to user preferences, and conduct
                        research and analysis.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>4. </b> For marketing and promotional purposes, we
                        may use your information, such as your registered email
                        address or contact number, to send you news,
                        newsletters, special offers, promotions, conduct
                        sweepstakes and contests, or contact you regarding
                        products or information that may be of interest to you.
                        Additionally, we may utilize the information we gather
                        about you to assist us in advertising our Platform on
                        third-party websites.
                    </p>
                </div>
                {/*part 6 */}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        How We Share Your Information
                    </h3>
                    {/* (i) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>Affiliates:</b> The information collected from you
                        may be shared with our affiliates or subsidiaries for
                        the sole purpose of providing the Platform or related
                        services, including subsite services. However, any use
                        and disclosure of your personally identifiable
                        information by these affiliates and subsidiaries will be
                        in accordance with this Policy.
                    </p>
                    {/* (ii) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b> Service Providers:</b> Information collected from
                        you may be disclosed to third-party vendors, service
                        providers, contractors, or agents who perform functions
                        on our behalf, as long as they agree to use the
                        information solely to provide services to us.
                    </p>
                    {/* (iii) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b> Business Transfers:</b> In the event that we are in
                        negotiations with, acquired by, or merged with another
                        company or entity, or if our assets are transferred to
                        another company or entity, or as part of a bankruptcy
                        proceeding, the information collected from you may be
                        transferred to the other company or entity.
                    </p>
                    {/* (iv) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>In Response to Legal Process:</b> We may disclose the
                        information collected from you to comply with the law, a
                        judicial proceeding, court order, or other legal
                        process, such as in response to a subpoena.
                    </p>
                    {/* (v) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>To Protect Us and Others: </b>In order to safeguard
                        both ourselves and others, any information gathered from
                        you may be revealed if we deem it essential to probe,
                        thwart, or address unlawful activities, suspected
                        deceit, possible safety risks, breaches of our Service
                        Agreement or this Policy, or as proof in legal
                        proceedings.
                    </p>
                    {/* (vi) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>Aggregate and De-Identified Information:</b> We may
                        collect, process, analyze, and share aggregate or
                        de-identified information about users with third parties
                        and publicly for purposes such as product development,
                        marketing, advertising, research, or similar purposes.
                    </p>
                </div>
                {/* part 7 */}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                    Our Use of Cookies and Other Tracking Mechanisms
                                        </h3>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        We utilize cookies and other tracking mechanisms, along
                        with our third-party service providers, to monitor and
                        gather data regarding your usage of our Platform. It is
                        possible for us to merge this information with other
                        Personal Information that we collect from you, and our
                        third-party service providers may also do so on our
                        behalf.
                    </p>
                    {/* (i) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>Cookies:</b> Cookies are alphanumeric codes that we
                        send to your computer's hard drive via your web browser
                        to keep records. Certain cookies help us enhance your
                        browsing experience on our platform, while others
                        facilitate a quicker login process or enable us to
                        monitor your activities on our platform. We categorize
                        cookies into two types: session cookies and persistent
                        cookies.
                    </p>
                    {/* (ii) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>Session Cookies:</b> Session cookies are short-lived
                        cookies that are operational solely throughout an online
                        session. They are automatically deleted from your
                        computer as soon as you close your browser or power off
                        your computer. The purpose of session cookies is to
                        enable our systems to distinguish you uniquely
                        throughout a session or while you are logged into our
                        Platform. This functionality facilitates the smooth
                        processing of your online transactions and requests, as
                        well as the verification of your identity as you
                        navigate through our Platform.
                    </p>
                    {/* (iii) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b> Persistent Cookies: </b>Persistent cookies are
                        stored on your computer even after you close your
                        browser or shut down your computer. These cookies are
                        utilized to monitor aggregate and statistical data
                        regarding user activity. Please be aware that our
                        systems do not acknowledge do-not-track signals.
                    </p>
                    {/* (iv) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>Disabling Cookies:</b> By default, the majority of
                        web browsers will accept cookies automatically. However,
                        if you so choose, you have the option to modify your
                        browser settings to prevent the acceptance of cookies in
                        the future. The general settings on most browsers will
                        provide instructions on how to block new cookies,
                        receive notifications when a new cookie is received, or
                        disable cookies entirely. It is important to note that
                        users of our Platform who disable cookies may encounter
                        limitations in accessing certain areas of the
                        platformutilize automated devices and applications, like
                        Google Analytics, to assess the usage of our Platform.
                        Additionally, we may employ other analytical methods to
                        evaluate our Platform. These tools are instrumental in
                        enhancing our Platform, performance, and user
                        experiences. These entities may utilize cookies and
                        other tracking technologies to carry out their services.
                        Rest assured, we do not disclose your Personal
                        Information to these third parties.rm.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        Third Party Analytics. We utilize automated devices and
                        applications, like Google Analytics, to assess the usage
                        of our Platform. Additionally, we may employ other
                        analytical methods to evaluate our Platform. These tools
                        are instrumental in enhancing our Platform, performance,
                        and user experiences. These entities may utilize cookies
                        and other tracking technologies to carry out their
                        services. Rest assured, we do not disclose your Personal
                        Information to these third parties.
                    </p>
                    {/* (v) */}
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>Third Party Analytics: </b>We utilize automated
                        devices and applications, like Google Analytics, to
                        assess the usage of our Platform. Additionally, we may
                        employ other analytical methods to evaluate our
                        Platform. These tools are instrumental in enhancing our
                        Platform, performance, and user experiences. These
                        entities may utilize cookies and other tracking
                        technologies to carry out their services. Rest assured,
                        we do not disclose your Personal Information to these
                        third parties.
                    </p>
                </div>
                {/*part 8*/}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        Third-Party Links
                    </h3>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        Our Platform may include hyperlinks to external
                        websites. The use of these linked websites is subject to
                        the privacy policies of the respective third-party
                        websites, not to our Policy. We do not oversee the
                        information practices of these third-party websites.
                    </p>
                </div>
                {/*part 9 */}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        Security of Your Personal Information
                    </h3>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        We have taken reasonable precautions in accordance with
                        applicable laws and regulations to safeguard the
                        Personal Information we collect from loss, misuse, and
                        unauthorized access, disclosure, alteration, and
                        destruction. It is important to note that despite our
                        best efforts, no security measures can provide absolute
                        protection. Therefore, all Platform Users (including
                        their Authorized Individuals) must adhere to the
                        security requirements outlined in the Terms and follow
                        these additional steps to ensure the security of their
                        Personal Information and account access:
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>1. </b> Choose strong and unique User Credentials
                        (user identification, password, and PIN) that are not
                        easily guessable and known only to you. These User
                        Credentials must meet the requirements specified during
                        their setup. Keep your User Credentials confidential and
                        do not share them with any third party.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>2. </b> Install reliable anti-virus, anti-spyware,
                        and firewall software on your personal computers and
                        mobile devices.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>3. </b> Regularly update your operating systems,
                        anti-virus software, and firewall products with security
                        patches or newer versions.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>4. </b> Disable file and printer sharing on your
                        computers, especially when connected to the internet.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>5. </b> Create regular backups of your important
                        data.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>6. </b>Consider utilizing encryption technology to
                        protect highly sensitive or confidential information.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>7. </b> Always log off and clear your browser cache
                        after each online session with the Platform.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>8. </b> Avoid installing software or running programs
                        from unknown sources.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>9. </b> Delete junk or chain emails without opening
                        them.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>10. </b> Refrain from opening email attachments sent
                        by unfamiliar individuals.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>11. </b> Exercise caution when sharing personal,
                        financial, or credit card information on unfamiliar or
                        suspicious websites.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>12. </b> Avoid using computers or devices that cannot
                        be trusted.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>13. </b> Refrain from using public or internet café
                        computers for accessing the Platform.
                    </p>
                    <p className="text-black  dark:text-white text-[14px] text-justify  w-full h-fit">
                        If you discover any unauthorized use or access to your
                        Account or User Credentials, please promptly inform us
                        at{" "}
                        <Link to="" className="text-[#EFB81C] hover:text-amber-400 hover:no-underline no-underline">
                            support@buycex.com
                        </Link>{" "}
                        . We cannot be held accountable for any lost, stolen, or
                        compromised User Credentials, nor for any unauthorized
                        activity on your Account resulting from the misuse of
                        your User Credentials.
                    </p>
                </div>
                {/* part 10 */}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        Rights of access, correction, and deletion{" "}
                    </h3>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        You have the ability to access, modify, update, or
                        remove your Account or Personal Information that we have
                        gathered at any given time by either accessing your
                        account settings or sending us an email at{" "}
                        <Link to="" className="text-[#EFB81C] hover:text-amber-400 hover:no-underline no-underline">
                            support@buycex.com
                        </Link>
                        . We will make sure to address your requests within a
                        reasonable timeframe, as required by law.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        However, please be aware that there may be situations
                        where we are unable to fulfill a request to modify,
                        update, access, or delete an account profile or Personal
                        Information. These situations encompass, yet are not
                        restricted to:
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>1. </b> Instances where the request is denied under
                        applicable law.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>2. </b> Cases where we are required to retain the
                        information to comply with federal, state, or local
                        laws, or for accounting or tax purposes.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>3. </b>Situations where we need to comply with a
                        civil, criminal, or regulatory inquiry, investigation,
                        subpoena, or summons by federal, state, or local
                        authorities.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>4. </b> Instances where we need to cooperate with law
                        enforcement agencies regarding conduct or activity that
                        we reasonably and in good faith believe may violate
                        federal, state, or local law.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>5. </b> Cases where we need to retain information to
                        exercise or defend legal claims.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>6. </b> Situations where the information contains
                        legal privilege or proprietary information of another
                        party.
                    </p>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        <b>7. </b> Instances where fulfilling the request would
                        compromise the privacy or other legitimate rights of
                        others.
                    </p>
                    <p className="text-black  dark:text-white text-[14px] text-justify  w-full h-fit">
                        If we determine that we are unable to respond to a
                        specific request, we will provide you with an
                        explanation as to why that decision has been made, along
                        with a contact point for any further inquiries. To
                        safeguard your privacy, we will take commercially
                        reasonable steps to verify your identity before
                        addressing any request under this provision, in
                        accordance with any applicable legal requirements.
                    </p>
                </div>
                {/*part 11*/}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        Transfer of Information
                    </h3>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        Kindly be advised that your Personal Information may be
                        stored on servers situated in the countries where our
                        primary offices are situated. If the jurisdiction
                        differs from your place of residence, you agree to the
                        transfer of your Personal Information to that
                        jurisdiction for the purpose of offering the Platform to
                        you, even if the other jurisdiction provides less
                        protection for Personal Information compared to your
                        place of residence. Rest assured, we will implement
                        security measures in accordance with our legal
                        obligations to safeguard your Personal Information under
                        the laws of your place of residence.
                    </p>
                </div>
                {/*part 12*/}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        Retention{" "}
                    </h3>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        Your Personal Information will be kept on file as long
                        as you have an active Account on the Platform. We will
                        stop storing your Personal Information, or eliminate the
                        ability to link it to specific individuals, once it is
                        no longer needed for the original purpose it was
                        collected for, and when retention is no longer essential
                        for legal, accounting, or business reasons. Please be
                        aware that some regulations may mandate the retention of
                        transaction or account records for a specific duration.
                    </p>
                </div>

                {/*part 13*/}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        Children Under 18{" "}
                    </h3>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        Our Platform is specifically intended for individuals
                        who are 18 years old and above. In the event that we
                        become aware that a person under the age of 18 has
                        shared Personal Information with us, we will promptly
                        remove such information from our systems.
                    </p>
                </div>
                {/*part 14*/}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        Contact Us
                    </h3>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        For inquiries regarding the privacy features of our
                        Platform or to file a complaint, please reach out to us
                        through our live chat support or by emailing us at{" "}
                        <Link to="" className="text-[#EFB81C] hover:text-amber-400 hover:no-underline no-underline">
                            support@buycex.com
                        </Link>
                        .
                    </p>
                </div>
                {/*part 15*/}
                <div className="flex flex-col justify-between gap-4 items-center w-full h-full">
                    <h3 className="text-[20px] mb-3 border-b pb-2 text-black dark:text-white font-bold  w-full h-fit">
                        Changes to this Policy
                    </h3>
                    <p className="text-black hover:bg-gray-200 p-[6px]  rounded dark:hover:bg-[#1A191B] dark:text-white text-[14px] text-justify  w-full h-fit">
                        This Policy shall be in effect starting from the
                        aforementioned date. We reserve the right to modify this
                        Policy periodically, so please remember to check back
                        regularly. Any changes to this Policy will be posted on
                        the Platform. If there are any significant changes that
                        impact how we handle the Personal Information we have
                        collected from you, we will make an effort to notify you
                        in advance. This notification may be done by
                        highlighting the change on our Platform, sending a push
                        notification through the Site (if your Site settings
                        allow it), or sending an email to the address provided
                        in your Account. Therefore, it is important to promptly
                        update your account information if it changes.
                    </p>
                </div>
            </main>
        </>
    );
};

export default PrivacyPolicy;
