import React, { useEffect, useState } from "react";

import { BsX } from "react-icons/bs";
import ChatBotRobotIcon from "../icons/chat-bot-assistant-robot.jpg";

import ChatDialogContainer from "./ChatDialogContainer";
import QuickQuestions from "./QuickQuestions";
import { useMediaQuery } from "@mui/system";

import "./ChatBotStyle.css";

const ChatField = ({ handleCloseChatField }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [chatStarted, setChatStarted] = useState(false);

    const isMobile = useMediaQuery("max-width: 991px");

    useEffect(() => {
        setTimeout(() => setIsVisible(true));
    }, []);

    const handleCloseClick = () => {
        setIsVisible(false);
        setTimeout(handleCloseChatField, 700);
    };

    const handleStartChat = () => {
        setChatStarted(true);
    };

    return (
        <div
            className={`chat-field-wrapper fixed bottom-[30px] max-lg:bottom-[0px] right-[20px] max-lg:right-[0px] bg-white rounded-lg max-lg:rounded-none overflow-hidden duration-700 ease-in-out transform ${
                isVisible
                    ? "w-[400px] max-lg:w-[100vw] h-[65vh] max-lg:h-[100vh] scale-100 translate-x-0 translate-y-0 opacity-100"
                    : "w-[0px] h-[0vh] scale-0 translate-x-60 translate-y-60 opacity-0"
            }
            `}
            style={{
                zIndex: 9999,
                boxShadow:
                    "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                transition: `
                    width 1.0s cubic-bezier(0.96, 0.2, 0.06, 0.94),
                    height 0.8s cubic-bezier(0.96, 0.2, 0.06, 0.94),
                    transform 0.8s cubic-bezier(0.72, 0.36, 0.03, 0.98),
                    scale 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55),
                    opacity 0.4s ease-in-out,
                `,
            }}
        >
            {!chatStarted ? (
                <div className="flex flex-col justify-between w-full h-full">
                    <div
                        className="flex items-center justify-between p-4 chat-field__header"
                        style={{
                            boxShadow:
                                "rgba(0, 0, 0, 0.20) 0px 15px 10px -20px",
                        }}
                    >
                        <div className="flex items-center gap-2">
                            <div className="flex items-center justify-center overflow-hidden rounded-md">
                                <img
                                    src={ChatBotRobotIcon}
                                    alt="Chat Bot"
                                    className="w-[45px]"
                                    title="Chat Bot"
                                    style={{ objectFit: "cover" }}
                                    loading="lazy"
                                />
                            </div>
                            <div className="flex flex-col gap-1 chat-field__header__title-wrap">
                                <div className="chat-field__title text-[14px] font-bold leading-none">
                                    BUYCEX Chat Assistant: 24/7 Support
                                </div>
                                <p className="text-[12px]">
                                    I'm here to help. Please ask me your
                                    queries.
                                </p>
                            </div>
                        </div>

                        <button
                            type="button"
                            onClick={handleCloseClick}
                            title="Close Chat"
                            className="text-gray-600 hover:text-gray-800"
                            aria-label="Close"
                        >
                            <BsX size={24} />
                        </button>
                    </div>

                    <div className="w-full mb-auto overflow-y-scroll chat-field__body">
                        <div className="flex flex-col items-center justify-center w-full gap-4 chat-field__body">
                            <QuickQuestions />
                        </div>
                    </div>

                    <div
                        className="flex items-center justify-between px-4 py-3 chat-field__footer"
                        style={{
                            boxShadow:
                                "rgba(0, 0, 0, 0.30) 0px -15px 10px -20px",
                        }}
                    >
                        <div className="w-full">
                            <button
                                type="button"
                                onClick={handleStartChat}
                                className="w-full px-4 py-3.5 bg-[#EFB81C] rounded-md text-[16px] font-[500] leading-none"
                            >
                                Start
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <ChatDialogContainer setChatStarted={setChatStarted} />
            )}
        </div>
    );
};

export default ChatField;
