import Repository from "./Repository";

const KYCSUBMIT = "/User/submitkyc ";
const basicVerification = "/User/basicVerification";
const SHOWbasicVerification = "/User/showbasicVerification";
export default {
    submitkyc(formData, userID, userToken) {
        return Repository.post(`${KYCSUBMIT}`, formData, {
            headers: {
                token: userToken,
                id: userID,
                'Content-type': 'multipart/form-data'
            },
        });
    },
    basicVerification(formData, userID, userToken) {
        return Repository.post(`${basicVerification}`, formData, {
            headers: {
                token: userToken,
                id: userID,
            },
            // headers: {
            //     'Content-type': 'multipart/form-data'
            // }
        });
    },
    showbasicVerification(userID, userToken) {
        return Repository.get(`${SHOWbasicVerification}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
};
