import * as types from "../types";

const initialState = {
    loading: false,
    username: "",
    p2pNickname: "",
    selectedCurrency: "USD",
    error: null,
    selectedLanguage: "en",
};

const userUpdateReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_USERNAME_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case types.UPDATE_USERNAME_SUCCESS:
            return {
                ...state,
                loading: false,
                username: action.payload,
                error: null,
            };
        case types.UPDATE_USERNAME_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.UPDATE_USER_NICK_NAME_SUCCESS:
            return {
                ...state,
                loading: false,
                p2pNickname: action.payload,
                error: null,
            };
        case types.UPDATE_USER_NICK_NAME_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.UPDATE_USER_CURRENCY:
            return {
                ...state,
                loading: false,
                selectedCurrency: action.payload,
            };

        case types.UPDATE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            };
        default:
            return state;
    }
};

export default userUpdateReducer;
