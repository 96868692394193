// ListingCard.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from './ListingModel';
import Toast from 'react-hot-toast';
import { upvoteItem, downvoteItem } from 'store/actions/voteActions';

const ListingCard = ({ logo, name,coinId, completePercent, remainingPercent, votecoin, assumnum }) => {
  const dispatch = useDispatch();

  const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {}; // Retrieve ID and Token from Redux
  const isComplete = completePercent === 100 && remainingPercent === 0;
console.log("Token", TOKEN, ID);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [voteType, setVoteType] = useState(null);

  // Function to handle vote button click
  const handleVoteClick = (type) => {
    if (!ID || !TOKEN) {
      Toast.error('Please log in first!');
      return;
    }
    setVoteType(type);
    setIsModalOpen(true);
  };

  // Function to confirm the vote
  const handleConfirmVote = () => {
    const type = voteType === 'upvote' ? 1 : 2; // Set type to 1 for upvote and 2 for downvote

  
    if (voteType === 'upvote') {
      dispatch(upvoteItem(type, coinId, ID, TOKEN));
    } else {
      dispatch(downvoteItem(type, coinId, ID, TOKEN));
    }
    setIsModalOpen(false);
  };

  // Function to cancel the vote
  const handleCancelVote = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 m-4 w-60">
      <div className="flex flex-col items-center">
        <img src={logo} alt={`${name} logo`} className="h-16 w-16 mb-4 rounded-full border-2 border-yellow-500" />
        <h2 className="text-lg font-semibold mb-2">{name}</h2>
        <div className="flex items-center text-gray-500 mb-2">
          <span>2 w | M</span>
          <span className="mx-2">|</span>
          <span className="bg-gray-200 px-2 py-1 rounded">Supreme</span>
          <span className="mx-2">|</span>
          <span className="bg-gray-200 px-2 py-1 rounded">UTA</span>
        </div>

        <div className="flex justify-between w-full text-green-600 font-bold mb-2" style={{ height: '48px' }}>
          {isComplete ? (
            <span className="text-green-600 text-center text-xl font-semibold w-full">Project Complete!</span>
          ) : (
            <div className="flex justify-between w-full">
              <div className="flex flex-col items-center text-center">
                <span className="text-gray-500">Complete%</span>
                <span className="text-green-600 text-xl">{completePercent}%</span>
              </div>
              <div className="flex flex-col items-center text-center">
                <span className="text-gray-500">Remaining%</span>
                <span className="text-red-600 text-xl">{remainingPercent}%</span>
              </div>
            </div>
          )}
        </div>

        <div className="flex w-full space-x-4 mt-4">
          <button
            className="flex items-center justify-center w-32 h-12 bg-yellow-500 hover:bg-yellow-600 text-black font-bold rounded-full shadow-md transition duration-200 ease-in-out"
            onClick={() => handleVoteClick('downvote')}
          >
            - DownVote
          </button>
          <button
            className="flex items-center justify-center w-32 h-12 bg-[#EAECEF] hover:bg-yellow-600 text-black font-bold rounded-full shadow-md transition duration-200 ease-in-out"
            onClick={() => handleVoteClick('upvote')}
          >
            + UpVote
          </button>
        </div>

        {isModalOpen && (
          <Modal onConfirm={handleConfirmVote} onCancel={handleCancelVote}>
            Voting will cost {votecoin} {assumnum}. Are you sure you want to {voteType} for {name}?
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ListingCard;
