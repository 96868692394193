import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import menuItems from "./MenuItems";
import { useSelector } from "react-redux";
import clsx from "clsx";

const SideBar = ({ children }) => {
    const location = useLocation();
    const path = location.pathname;

    const [activeItem, setActiveItem] = useState(null);
    const [isContentVisible, setIsContentVisible] = useState({});
    const [showChildren, setShowChildren] = useState(false);

    const state = useSelector((state) => state);

    useEffect(() => {
        const activeMenuItem = menuItems.find((item) => path.includes(item.to));
        if (activeMenuItem) {
            setActiveItem(activeMenuItem.name);
            setIsContentVisible({ [activeMenuItem.name]: true });
            setShowChildren(true);
        }
    }, [path]);

    useEffect(() => {
        const activeMenuItem = menuItems.find((item) =>
            path.includes(item.to) ||
            item.subItems?.some((subItem) => path.includes(subItem.to))
        );

        if (activeMenuItem) {
            setActiveItem(activeMenuItem.name);
            setIsContentVisible({ [activeMenuItem.name]: true });
            setShowChildren(true);
        }
    }, [path]);



    const handleToggleContent = (itemName) => {
        if (activeItem === itemName) {
            setIsContentVisible((prevState) => ({
                ...prevState,
                [itemName]: !prevState[itemName],
            }));
            setShowChildren(!isContentVisible[itemName]);
        } else {
            setActiveItem(itemName);
            setIsContentVisible({ [itemName]: true });
            setShowChildren(true);
        }
    };

    return (
        <div className="flex flex-col gap-y-5 lg:flex-row lg:gap-x-4">
            <nav className="w-full lg:w-[248px]">
                <ul className="flex flex-col mt-7 bg-white rounded-md gap-1">
                    {menuItems.map((item) => (
                        <li
                            key={item.name}
                            className={clsx(
                                "lg:block",
                                showChildren
                                    ? activeItem === item.name
                                        ? "block md:block"
                                        : "hidden md:hidden"
                                    : "block md:block"
                            )}
                        >
                            <NavLink
                                to={item.to || "#"}
                                className={clsx(
                                    "flex items-center pl-6 px-3 py-3 gap-3 rounded-md",
                                    path.includes(item.to) &&
                                        !item.subItems?.some((subItem) => path.includes(subItem.to))
                                        ? "font-semibold lg:bg-[#F4F4F4] border-b lg:border-none"
                                        : "opacity-60 font-medium"

                                )}
                                onClick={() => handleToggleContent(item.name)}
                            >
                                <div className="flex w-full items-center justify-between">
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex gap-3 items-center">
                                            {item.icon} {item.name}
                                        </div>
                                        {activeItem === item.name && (
                                            isContentVisible[item.name] ? (
                                                <FaChevronUp className="opacity-60 text-sm block lg:hidden" />
                                            ) : (
                                                <FaChevronDown className="opacity-60 text-sm block lg:hidden" />
                                            )
                                        )}
                                    </div>
                                    {item.subItems && (
                                        <div className="md:block hidden">
                                            {isContentVisible[item.name] ? (
                                                <FaChevronUp className="opacity-60 text-sm" />
                                            ) : (
                                                <FaChevronDown className="opacity-60 text-sm" />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </NavLink>

                            {/* Render sub-items */}
                            {item.subItems && (
                                <ul
                                    className={clsx(
                                        isContentVisible[item.name] && activeItem === item.name
                                            ? "block"
                                            : "hidden"
                                    )}
                                >
                                    {item.subItems.map((subItem) => (
                                        <li key={subItem.name}>
                                            <NavLink
                                                to={
                                                    item.name === "Account" && subItem.name === "Identification"
                                                        ? state.IdVerification.basicData.firstname
                                                            ? "/user/account/identity-verification/advance-verification"
                                                            : "/user/account/identity-verification/information"
                                                        : subItem.to
                                                }
                                                className={clsx(
                                                    "flex items-center pl-6 px-3 py-3 gap-3 rounded-md font-medium hover:text-[#151415] hover:bg-[#F4F4F4]",
                                                    path.includes(subItem.to) && "font-semibold bg-[#F4F4F4]"
                                                )}
                                            >
                                                <span className="invisible">{subItem.icon}</span>
                                                {subItem.name}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            )}


                            {/* Children content */}
                            {isContentVisible[item.name] && activeItem === item.name && (
                                <div className="lg:hidden block mt-4 p-4 rounded-md">
                                    {showChildren && children}
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default SideBar;
