import Repository from "./Repository";
const P2pReceiveOrder = "/P2p/markAsPaid";
const orderStatus = "/P2p/getOrderStatusAjax";
const myOpenOrder = "/Exchange/MyOpenOrders/market/eth_usd";
const orderHistory = "/Finance/mycj";
const fiatOrders = '/Crypto/viewFiatOrder/id/28'
const orderView = '/P2p/vieworder'
export default {
  // Function for posting order data
  postP2pReceiveData(userId, token, payloads) {
    return Repository.post(`${P2pReceiveOrder}`, payloads,{
        headers: {
          'ID': userId,
          'TOKEN': token,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  postP2pOrderStatus(userId, token, orderId, viewerType) {
    return Repository.post(
      orderStatus,
      {
        order_id: orderId,
        viewer_type: viewerType,
      },
      {
        headers: {
          token: token,
          id: userId,
        },
      }
    );
  },
  postMyOpenOrder(userId, token) {
    return Repository.post(
      myOpenOrder,
      {},
      {
        headers: {
          token: token,
          id: userId,
        },
      }
    );
  },
  postOrderHistory (userId, token, payload)  {
    const formData = new FormData();
    formData.append('user_id', payload.userID);
    formData.append('market', payload.market);
    formData.append('type', payload.type);
  
    return Repository.post(
      orderHistory, // Assuming orderHistory is the endpoint URL
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': token,
          'id': userId,
        },
      }
    );
  },
  getFiatOrders(userId, token) {

    return Repository.get(`${fiatOrders}`, {
        headers: {
            'token': token,
            'id': userId
        }
    });
},
  getOrdersView(userId, token,payload) {

    return Repository.post(`${orderView}`,payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
            'token': token,
            'id': userId
        }
    });
}
};
