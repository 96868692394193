import React, { useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';

const faqData = [
  {
    question: 'How to Deposit ARS on Buycex',
    answer: 'Depositing ARS on Buycex is a straightforward process designed to make it easy for users to start trading digital assets.',
  },
  {
    question: 'How to Use Fiat Deposit',
    answer: 'Fiat deposit allows you to convert your local currency into cryptocurrency with ease.',
  },
  {
    question: 'How to Deposit BRL on Buycex',
    answer: 'Depositing BRL on Buycex is quick and easy, enabling you to start trading in no time.',
  },
  {
    question: 'How to Buy Coins With Fiat Balance',
    answer: 'Buying coins with your fiat balance is simple and secure on our platform.',
  },
];

function FaQs({ selectedCurrencyForDeposit = 'ARS' }) {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAnswer = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <>
      <div className="mt-40 text-3xl font-semibold leading-9 text-[#151415] dark:text-[#F4F4F4] max-md:mt-10 max-md:max-w-full">
        FAQ
      </div>
      <div className="mt-9 max-md:max-w-full mb-8">
        <div className="grid grid-cols-2 gap-5 max-md:grid-cols-1">
          {faqData.map((faq, index) => (
            <div key={index} className="col-span-1 flex flex-col w-full max-md:w-full max-md:mb-5">
              <div className="flex flex-col grow text-base text-neutral-900 dark:text-white max-md:mt-0 max-md:w-full">
                <div className="flex flex-col px-8 py-8 border border-zinc-300 rounded-3xl shadow-sm w-full max-md:px-5 max-md:py-5">
                  <div className="flex justify-between items-center font-semibold leading-7 text-neutral-900 dark:text-white w-full">
                    <div className="my-auto max-md:w-full max-md:whitespace-normal">{faq.question}</div>
                    <div
                      className={`shrink-0 aspect-square w-[25px] cursor-pointer flex items-center justify-center ${expandedIndex === index ? 'bg-[#EFB81C]' : 'bg-[#F7F7FF] dark:bg-gray-700'} rounded-sm`}
                      onClick={() => toggleAnswer(index)}>
                      {expandedIndex === index ? <MdRemove className="w-full h-full" /> : <MdAdd className="w-full h-full" />}
                    </div>
                  </div>
                  {expandedIndex === index && (
                    <div className="mt-3 leading-8 text-neutral-900 dark:text-white w-full max-md:leading-6 max-md:mt-2">
                      {faq.answer}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default FaQs;
