import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FaMobile } from "react-icons/fa";
import { IoMdHelpCircleOutline, IoMdCheckmarkCircleOutline, IoMdCloseCircleOutline } from "react-icons/io";
import { Eye, EyeOff } from "lucide-react";
import { forgotCode, logout, sendForgotCode } from "store/actions/authAction";
import PasswordIcon from "assets/img/password-icon.png";
import YellowTick from "assets/img/yellow-bg-tick.png";
import ShieldLock from "assets/img/shield-lock.png";
import EmailVerification from "./helpers/EmailVerify";
import G2faVerification from "./helpers/G2faVerify";
import PhoneVerification from "./helpers/phoneVerify";
import toast from "react-hot-toast";
import { fetchAuthStatus } from "store/actions/userSecuritySettingsAction";


const PasswordChangeModal = ({ show, onClose, onSubmit, AlertMessage, closeAlert, setAlertMessage, changePasswordMessage, email, username, phone="", userId, token }) => {
    const [step, setStep] = useState(1);
    const [emailCode, setEmailCode] = useState('');
    const [otpCode, setOtpCode] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [showOtpMethodModal, setShowOtpMethodModal] = useState(false);
    const [otpMethod, setOtpMethod] = useState('authenticator');
    const [newPassIsValid, setNewPassIsValid] = useState(false);
    const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const [passwordValidations, setPasswordValidations] = useState({
      length: false,
      capitalLetter: false,
      smallLetter: false,
      number: false,
      specialChar: false
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (show && step === 1) {
            let payload = {
                email: email,
            };
            dispatch(forgotCode(payload));
        }
    }, [dispatch, email, step, show]);

    const isValidEmailAddress = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    useEffect(() => {
    }, [email]);

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         const modal = document.querySelector(".modal-inner");
    //         if (modal && !modal.contains(event.target)) {
    //             onClose();
    //         }
    //         // const outermodal = document.querySelector(".modal-outer");
    //         // if (outermodal && !modal.contains(event.target)) {
    //         //     onClose();
    //         // }
    //     };

    //     if (show) {
    //         document.addEventListener("mousedown", handleClickOutside);
    //     } else {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     }

    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [show, onClose]);

    const handlePasswordChange = (e) => {
      const password = e.target.value;
      setNewPassword(password);

      const newpasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/;
      
      const validations = {
        length: password.length >= 8 && password.length <= 30,
        capitalLetter: /[A-Z]/.test(password),
        smallLetter: /[a-z]/.test(password),
        number: /\d/.test(password),
        specialChar: /[@$!%*?&]/.test(password),
      };

      setPasswordValidations(validations);

      if (Object.values(validations).every(Boolean)) {
          setNewPassIsValid(true);
          setShowPasswordTooltip(false); // Hide tooltip when all requirements are met
      } else {
          setNewPassIsValid(false);
          setShowPasswordTooltip(true); // Show tooltip if any requirement is not met
      }
    };

    const history = useHistory();

    if (!show) return null;

    

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handleSubmit = async () => {
        try {
            const success = await onSubmit(oldPassword, newPassword); // Await the result of password change
            
            if (success) {
                setStep(4); // Move to the success step
            } else {
                setStep(3); // Stay on the current step if not successful
            }
        } catch (error) {
            console.error("Password change failed", error);
            setStep(3); // Handle failure, go back to the previous step
        }
    };
    
    

    const handleCancel = () => {
        setStep(1);
        setEmailCode('');
        setOtpCode('');
        setOldPassword('');
        setNewPassword('');
        setShowPasswordTooltip(false);
        onClose();
        if (userID && userToken) {
            dispatch(fetchAuthStatus(userID, userToken));
        }
    };

    const handleSuccessCompletion = () => {
      setStep(1);
      setEmailCode('');
      setOtpCode('');
      setOldPassword('');
      setNewPassword('');
      setShowPasswordTooltip(false);
      onClose();
      history.push('/login');
      dispatch(logout());
      if (userID && userToken) {
        dispatch(fetchAuthStatus(userID, userToken));
    }
    };
    
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleOtpMethodChange = async (value, event) => {
        event.stopPropagation(); // Prevent the modal from closing
        setOtpMethod(value); // Set the OTP method
        setShowOtpMethodModal(false); // Close the modal
    
        // Only proceed with the API call if the selected method is 'mobile'
        if (value === 'mobile') {
            // Retrieve authUser data from local storage
            let payload = {
                phone: phone,
            };
            dispatch(sendForgotCode(payload))
        }
    
        console.log('OTP Method selected:', value); // Log the selected method
    };    

    const handleSwitchVerificationTool = () => {
        setShowOtpMethodModal(true);
    };

    const handleOtpMethodContinue = (event) => {
        event.stopPropagation(); // Prevent the modal from closing
        setShowOtpMethodModal(false);
        setStep(2); // Ensure step 2 is shown after selecting the OTP method
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50 modal-outer">
            <div className="relative w-1/3 p-6 bg-white shadow-lg modal-inner rounded-xl" onClick={(e) => e.stopPropagation()} >
                <button
                    onClick={handleCancel}
                    className="absolute text-gray-500 top-3 right-3 hover:text-gray-700 focus:outline-none"
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                {/* {AlertMessage && (
                    <div className={`px-4 py-2 my-3 rounded ${AlertMessage.type === 'error' ? 'bg-red-200 text-red-800' : 'bg-green-200 text-green-800'}`}>
                        <div className="flex items-center justify-between">
                            <span>{AlertMessage.message}</span>
                            <button className="text-xl leading-none" onClick={closeAlert}>&times;</button>
                        </div>
                    </div>
                )} */}
                <div className="flex gap-2">
                    <img src={PasswordIcon} alt="password-icon" className="w-7 h-7" />
                    <h1 className="text-2xl font-semibold">Reset Password</h1>
                </div>
                {/* Step Tracer */}
                <div className="relative flex items-center gap-4 mt-4 mb-8">
                    <div className={`componentBorder rounded-xl text-center px-1 w-24 relative ${step >= 1 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">Email Verification</p>
                        <div className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-4 ${step >= 1 ? 'bg-yellow-500' : 'bg-gray-300'} transition-colors duration-300`}></div>
                    </div>
                    <div className={`componentBorder rounded-xl text-center px-1 w-24 relative ${step >= 2 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">2FA</p>
                        <div className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-4 ${step >= 2 ? 'bg-yellow-500' : 'bg-gray-300'} transition-colors duration-300`}></div>
                    </div>
                    <div className={`componentBorder rounded-xl text-center px-1 w-24 relative ${step >= 3 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">Set New Password</p>
                        <div className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-4 ${step >= 3 ? 'bg-yellow-500' : 'bg-gray-300'} transition-colors duration-300`}></div>
                    </div>
                    <div className={`componentBorder rounded-xl text-center px-1 w-24 relative ${step >= 4 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">Reset successfully</p>
                    </div>
                </div>

                {step === 1 && isValidEmailAddress(email) && (
                    <EmailVerification
                        email={email}
                        onSuccess={handleNextStep}
                    />
                )}
                {step === 2 && (
                    <div>
                        { otpMethod === 'authenticator' ? (
                          <G2faVerification 
                            onSuccess={handleNextStep}
                            emailasprop={email}
                            handleSwitchVerificationTool={handleSwitchVerificationTool}
                            userId={userId}
                            token={token}
                            type="update"
                          /> ) : (
                            phone ? (
                                <PhoneVerification
                                    onSuccess={handleNextStep}
                                    phoneasprop={phone}
                                    handleSwitchVerificationTool={handleSwitchVerificationTool}
                                />
                            ) : null
                        )}
                    </div>
                )}
                {step === 3 && (
                    <div>
                        <h2 className="mb-2 font-medium text-left text-md">Old Password</h2>
                        <input
                            type="password"
                            className="w-full p-2 mb-4 border rounded focus:outline-none"
                            placeholder="Old Password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <h2 className="mb-2 font-medium text-left text-md">New Password</h2>
                        <div className="relative">
                          <input
                              type={passwordVisible ? "text" : "password"}
                              className="w-full p-2 mb-4 border rounded focus:outline-none"
                              placeholder="New Password"
                              value={newPassword}
                              onChange={handlePasswordChange}
                              onFocus={() => setShowPasswordTooltip(true)}
                          />
                          { showPasswordTooltip && (
                            <div className="absolute px-1 py-1 ml-2 bg-white border rounded shadow-lg -top-8 left-full w-max">
                              <ul className="text-sm list-disc">
                                  <li className={`flex items-center ${passwordValidations.length ? 'text-green-500' : 'text-red-500'}`}>
                                      {passwordValidations.length ? <IoMdCheckmarkCircleOutline /> : <IoMdCloseCircleOutline />}
                                      <span className="ml-2">8-30 characters</span>
                                  </li>
                                  <li className={`flex items-center ${passwordValidations.capitalLetter ? 'text-green-500' : 'text-red-500'}`}>
                                      {passwordValidations.capitalLetter ? <IoMdCheckmarkCircleOutline /> : <IoMdCloseCircleOutline />}
                                      <span className="ml-2">at least one uppercase</span>
                                  </li>
                                  <li className={`flex items-center ${passwordValidations.smallLetter ? 'text-green-500' : 'text-red-500'}`}>
                                      {passwordValidations.smallLetter ? <IoMdCheckmarkCircleOutline /> : <IoMdCloseCircleOutline />}
                                      <span className="ml-2">at least one lowercase</span>
                                  </li>
                                  <li className={`flex items-center ${passwordValidations.number ? 'text-green-500' : 'text-red-500'}`}>
                                      {passwordValidations.number ? <IoMdCheckmarkCircleOutline /> : <IoMdCloseCircleOutline />}
                                      <span className="ml-2">at least one number</span>
                                  </li>
                                  <li className={`flex items-center ${passwordValidations.specialChar ? 'text-green-500' : 'text-red-500'}`}>
                                      {passwordValidations.specialChar ? <IoMdCheckmarkCircleOutline /> : <IoMdCloseCircleOutline />}
                                      <span className="ml-2">at least one special character</span>
                                  </li>
                              </ul>
                            </div>
                          )}
                          <button type="button" className="absolute bottom-[28px] right-[10px] p-2 cursor-pointer text-[#c4c4c4]" onClick={togglePasswordVisibility}>
                            {passwordVisible ? <Eye width={'15px'} height={'15px'} /> : <EyeOff width={'15px'} height={'15px'} />}
                          </button>
                      </div>
                        <div className="flex justify-between">
                        <button className={`bg-yellow-500 text-white py-2 px-4 rounded focus:outline-none w-full ${newPassIsValid ? '' : 'opacity-50 cursor-not-allowed'}`} onClick={handleSubmit} disabled={!newPassIsValid}>Continue</button>
                        </div>
                    </div>
                )}
                {step === 4 && (
                    <div>
                        <div className="flex items-center justify-center py-4 mb-2 "><img src={YellowTick} alt="success" className="mr-2"/><h2 className="text-lg font-medium">Reset Password Successfully</h2></div>
                        <button className="w-full px-4 py-2 text-white bg-yellow-500 rounded focus:outline-none" onClick={handleSuccessCompletion}>Done</button>
                    </div>
                )}
            </div>
            {showOtpMethodModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50 modal-outer">
                    <div className="w-1/3 p-6 bg-white rounded-lg shadow-lg">
                        <div className="mb-4">
                          <h2 className="mb-2 text-2xl font-semibold text-left">Security Settings</h2>
                          <p>For your account and asset security, please bind any of the following settings</p>
                        </div>
                        <div className="mb-4">
                          <div
                              className={`flex gap-1 items-center bg-gray-100 py-2 rounded-md cursor-pointer ${otpMethod === 'authenticator' ? 'componentBorderSelected' : 'componentBorder'}`}
                              onClick={(event) => handleOtpMethodChange('authenticator', event)}
                          >
                              <img src={ShieldLock} alt="shield-lock-icon" />
                              <h3 className="text-lg font-medium">2FA Authenticator</h3>
                              <IoMdCheckmarkCircleOutline className={`ml-auto mr-2 text-xl ${otpMethod === 'authenticator' ? 'text-yellow-500' : 'text-gray-500'}`} />
                          </div>
                          {phone && (
                          <div
                              className={`flex gap-1 items-center bg-gray-100 py-2 rounded-md cursor-pointer mt-4 ${otpMethod === 'mobile' ? 'componentBorderSelected' : 'componentBorder'}`}
                              onClick={(event) => handleOtpMethodChange('mobile', event)}
                          >
                              <FaMobile className="mx-2 text-2xl"/>
                              <h3 className="text-lg font-medium">Mobile</h3>
                              <IoMdCheckmarkCircleOutline className={`ml-auto text-xl mr-2 ${otpMethod === 'mobile' ? 'text-yellow-500' : 'text-gray-500'}`} />
                          </div>
                          )}
                        </div>
                        <button className="w-full px-4 py-2 text-white bg-yellow-500 rounded focus:outline-none" onClick={handleOtpMethodContinue}>Continue</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PasswordChangeModal;