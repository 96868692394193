import Repository from "./Repository";
const recordsEndPoint = "/Otc/records";
const otcCoinEndPoint = "/Otc/index"; 
const QuoteEndPoint = "/Otc/getquote";

const ConversionEndPoint="/Otc/getConversionnew";
export default {
   
    records(userId, token) {
        return Repository.get(`${recordsEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },


    otcCoin(userId, token) {
        return Repository.get(`${otcCoinEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    getQuote(tradeCoin, baseCoin, input1, input2, tradeType, token,userId) {
        const formData = new FormData();
        formData.append("trade_coin", tradeCoin);
        formData.append("base_coin", baseCoin);
        formData.append("input1", input1);
        formData.append("input2", input2);
        formData.append("tradetype", tradeType);

        return Repository.post(QuoteEndPoint, formData, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    getConversion(fromCoin, amount, toCoin, token,userId) {
        const formData = new FormData();
        formData.append("fromcoin", fromCoin);
        formData.append("amount", amount);
        formData.append("tocoin", toCoin);

        return Repository.post(ConversionEndPoint, formData, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
};
