import React, { useState, useEffect, useRef } from "react";

const NetworkSelectDepositModal = ({ networks, selectedNetwork, setSelectedNetwork }) => {
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredNetworks, setFilteredNetworks] = useState(networks);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 800);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const filtered = networks.filter((network) =>
            network.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredNetworks(filtered);
    }, [searchTerm, networks]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectNetwork = (network) => {
        setSelectedNetwork(network);
        setIsOpen(false);
        setSearchTerm("");
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="relative flex flex-col lg:flex-row gap-6 w-full" ref={dropdownRef}>
            <div className="w-full">
                <label className="block mb-1 text-xs font-normal text-[#81858c] dark:text-white">
                    Chain Type
                </label>

                <div className="relative">
                    <div
                        className="py-2 px-4 rounded-md bg-[#f3f5f7] cursor-pointer flex items-center justify-between dark:bg-[#1a202c] border-gray-300 dark:border-gray-600"
                        onClick={toggleDropdown}
                    >
                        <span className="text-black text-sm font-normal dark:text-white">
                            {selectedNetwork ? selectedNetwork.label : "Select Network"}
                        </span>
                        <svg
                            className={`w-3 h-3 transition-transform ${isOpen ? "rotate-180" : ""
                                }`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </div>
                    {isOpen && (
                        <div className="absolute w-full mt-1 bg-white dark:bg-[#1a202c] border border-gray-300 dark:border-gray-600 rounded-lg z-10">
                            <input
                                type="text"
                                className="py-2 ml-3 mr-3 w-[96%] my-2 rounded-md px-2 bg-[#F3F5F7] dark:border-gray-700 dark:bg-gray-800 dark:text-white"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                aria-label="Search Network"
                            />
                            <ul className="max-h-60 overflow-y-auto">
                                {filteredNetworks.length > 0 ? (
                                    filteredNetworks.map((network) => (
                                        <li
                                            key={network.value}
                                            className="px-4 py-2 text-[14px] cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                                            onClick={() => handleSelectNetwork(network)}
                                        >
                                            {network.label}
                                        </li>
                                    ))
                                ) : (
                                    <li className="px-4 py-2 text-gray-500 text-center dark:text-gray-400">
                                        No networks found
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default NetworkSelectDepositModal;
