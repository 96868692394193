import React, { useState } from 'react';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6"; // Import both eye icons
import { FaAngleDown } from "react-icons/fa6";
import { RiExchangeDollarLine } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa6";
import CryptoDepositModal from 'components/cryptoDeposit/CryptoDepositPopup/CryptoDepositModal';
import TransferModal from 'views/trade/PostAds/TransferModal';
import ConvertModal from 'views/trade/PostAds/ConvertModal';

function Derivatives({ isVisible, toggleVisibility, data }) {
    const [activeButton, setActiveButton] = useState('Transfer');
    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
    const handleTransferClick = () => {
      setIsTransferModalVisible(true);
    };
  
    const handleTransferCancel = () => {
      setIsTransferModalVisible(false);
    };

    const [cryptoDepositModalVisible, setCryptoDepositModalVisible] = useState(false);
  const showCryptoDepositModal = () => {
    setCryptoDepositModalVisible(true);
  };

  const handleCloseCryptoDepositModal = () => {
    setCryptoDepositModalVisible(false);
  };

  const [isConvertModalVisible, setIsConvertModalVisible] = useState(false);
  const handleConvertClick = () => {
    setIsConvertModalVisible(true);
  };

  const handleConvertCancel = () => {
    setIsConvertModalVisible(false);
  };
  
  const handleButtonClick = (buttonType) => {
    if (buttonType === 'Deposit') {
      showCryptoDepositModal();
    } else if (buttonType === 'Transfer') {
      handleTransferClick();
    } else if (buttonType === 'Convert') {
      handleConvertClick();
    }
    setActiveButton(buttonType);
  };

    const getButtonClass = (buttonType) => {
        return buttonType === activeButton
            ? 'bg-[#EFB81C] px-3 py-2 rounded-md text-sm font-semibold'
            : 'border border px-3 py-2 rounded-md text-sm font-semibold';
    };
    return (
        <div>
        <div className='p-3 mt-0 md:mt-6 md:p-6 border rounded-2xl'>
            <div className='flex flex-col md:flex-row w-full justify-between'>
                <div>
                    <div className='flex items-center gap-1'>
                        <p className='text-[20px] font-semibold'>Derivatives</p>
                        <button onClick={toggleVisibility} className='mt-1'>
                            {isVisible ? <FaRegEyeSlash className='text-xl' /> : <FaRegEye className='text-xl' />}
                        </button>
                    </div>
                </div>
                <div className='flex flex-col items-start mt-5 md:mt-0 md:items-end justify-between'>
                    <div className='flex gap-4 flex-wrap'>
                        <button
                            onClick={() => handleButtonClick('Transfer')}
                            className={getButtonClass('Transfer')}
                        >
                            Transfer
                        </button>
                        <TransferModal visible={isTransferModalVisible} onCancel={handleTransferCancel} />
                        <button
                            onClick={() => handleButtonClick('Deposit')}
                            className={`${getButtonClass('Deposit')}`}
                        >
                            Deposit
                        </button>
                        <CryptoDepositModal visible={cryptoDepositModalVisible} onClose={handleCloseCryptoDepositModal} />
                        <button
                            onClick={() => handleButtonClick('Convert')}
                            className={`${getButtonClass('Convert')}`}
                        >
                            Convert
                        </button>
                        <ConvertModal visible={isConvertModalVisible} onCancel={handleConvertCancel} />
                        <button
                            onClick={() => handleButtonClick('Loan')}
                            className={`${getButtonClass('Loan')}`}
                        >
                            Loan
                        </button>
                        <button
                            onClick={() => handleButtonClick('History')}
                            className={`${getButtonClass('History')}`}
                        >
                            History
                        </button>
                        <button
                            onClick={() => handleButtonClick('Orders')}
                            className={`${getButtonClass('Orders')}`}
                        >
                            Orders
                        </button>
                    </div>
                </div>
            </div>
            <div className='flex justify-between flex-wrap mt-4 w-1/2'>
                <div>
                    <p className='text-sm pt-2'>Total Equity</p>
                    <div className='flex items-baseline gap-2'>
                        <p className='text-[32px] pb- font-semibold'>{isVisible ? '0.00' : '*****'}</p>
                        <span className='text-sm flex items-center gap-1 font-semibold'>USD<FaAngleDown className='text-[12px] opacity-40 mt-1' /></span>
                    </div>
                    <p className='text-sm'>{isVisible ? `≈ $0.00000000 BTC` : '****'}</p>
                </div>
                <div>
                    <p className='text-sm pt-2'>Unrealized P&L</p>
                    <div className='flex items-baseline gap-2'>
                        <p className='text-[32px] pb- font-semibold'>{isVisible ? '0.00' : '*****'}</p>
                        <span className='text-sm flex items-center gap-1 font-semibold'>USD<FaAngleDown className='text-[12px] opacity-40 mt-1' /></span>
                    </div>
                    <p className='text-sm'>{isVisible ? `≈ $0.00000000 BTC` : '****'}</p>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Derivatives;
