const initialState = {
  data: [],
  loading: false,
  error: null,
};

const financeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FINANCE_HISTORY_SUCCESS":
      return { ...state, data: action.payload, loading: false, error: null };
    case "FETCH_FINANCE_HISTORY_FAILURE":
      return { ...state, data: [], loading: false, error: action.payload };
    case "FETCH_P2P_DATA_LOADING":
      return { ...state, loading: action.payload };
   
    default:
      return state;
  }
};

export const financeOpenHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FINANCE_OPEN_ORDERS_SUCCESS":
      return { ...state, data: action.payload, loading: false, error: null };
    case "FETCH_FINANCE_OPEN_ORDERS_FAILURE":
      return { ...state, data: [], loading: false, error: action.payload };
    case "FETCH_FINANCE_OPEN_ORDERS_LOADING":
      return { ...state, loading: action.payload };
   
    default:
      return state;
  }
};

export const financeOrdersHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FINANCE_ORDERS_HISTORY_SUCCESS":
      return { ...state, data: action.payload, loading: false, error: null };
    case "FETCH_FINANCE_ORDERS_HISTORY_FAILURE":
      return { ...state, data: [], loading: false, error: action.payload };
    case "FETCH_FINANCE_ORDERS_HISTORY_LOADING":
      return { ...state, loading: action.payload };
   
    default:
      return state;
  }
};

export const financeOrdersTradeHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FINANCE_ORDERS_TRADE_HISTORY_SUCCESS":
      return { ...state, data: action.payload, loading: false, error: null };
    case "FETCH_FINANCE_ORDERS_TRADE_HISTORY_FAILURE":
      return { ...state, data: [], loading: false, error: action.payload };
    case "FETCH_FINANCE_ORDERS_TRADE_HISTORY_LOADING":
      return { ...state, loading: action.payload };
   
    default:
      return state;
  }
};

// Reducer for Finance User Spot
export const financeUserSpotReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FINANCE_USER_SPOT_SUCCESS":
      return { ...state, data: action.payload, loading: false, error: null };
    case "FETCH_FINANCE_USER_SPOT_FAILURE":
      return { ...state, data: [], loading: false, error: action.payload };
    case "FETCH_FINANCE_USER_SPOT_LOADING":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

// New Reducer for Finance User Funding
export const financeUserFundingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FINANCE_USER_FUNDING_LOADING":
      return { ...state, loading: action.payload };
    case "FETCH_FINANCE_USER_FUNDING_SUCCESS":
      return { ...state, data: action.payload, loading: false, error: null }; 
    case "FETCH_FINANCE_USER_FUNDING_FAILURE":
      return { ...state, data: null, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const financeUserEarnReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FINANCE_USER_EARN_LOADING":
      return { ...state, loading: action.payload };
    case "FETCH_FINANCE_USER_EARN_SUCCESS":
      return { ...state, data: action.payload, loading: false, error: null };
    case "FETCH_FINANCE_USER_EARN_FAILURE":
      return { ...state, data: null, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const fiatBalanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FIAT_BALANCE_LOADING":
      return { ...state, loading: true, error: null };
    case "FETCH_FIAT_BALANCE_SUCCESS":
      return { ...state, data: action.payload, loading: false, error: null };
    case "FETCH_FIAT_BALANCE_FAILURE":
      return { ...state, data: null, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default financeReducer;
