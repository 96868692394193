import React from "react";
import NoData from "assets/img/user/No-data.svg";

function FiatAds() {
    return (
        <div>
            <div className="flex justify-center items-center w-full h-screen">
                <div className="flex flex-col items-center">
                    <img src={NoData} />
                    <p className="text-sm pt-2">You do not have any Ads</p>
                    <button className="bg-[#EFB81C] mt-4 rounded-md px-12 text-sm font-semibold py-1.5 h-fit">
                        Post now
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FiatAds;
