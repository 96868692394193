import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaGoogle } from "react-icons/fa";
import { BsExclamationDiamondFill } from "react-icons/bs";

// SecondPopup Component
function SecondPopup({ onClose }) {
    return (
        <div className="fixed inset-0 flex items-center justify-center backdrop-blur-[1px] z-20">
            <div className="bg-white p-8 rounded-xl shadow-lg relative w-[38%]">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                >
                    <IoClose className="font-bold text-[34px] m-2 text-[#D7D7D7]" />
                </button>
                <div>
                <div className="flex justify-center items-center"><BsExclamationDiamondFill className="text-[146px] text-[#EFB81C] mb-5"/></div>

                    <p className="text-center font-bold text-lg pb-4">Are you sure to unbind Google Account?</p>
                    <div className="flex justify-center mt-4 gap-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="text-[#EFB81C] w-50 bg-[#fff1cc] hover:bg-[#fff1cc] font-medium rounded-lg text-md px-5 py-2 me-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="text-white w-50 bg-[#EFB81C] hover:bg-[#EFB81C] font-medium rounded-lg text-md px-5 py-2"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

// GoogleAccountBinding Component
function GoogleAccountBinding({ 
        show,
        onSubmit,
        onClose,
        email,
        username,
        token
    }) {
    const [isBinding, setIsBinding] = useState(false);
    const [isBound, setIsBound] = useState(false);
    const [showSecondPopup, setShowSecondPopup] = useState(false);

    const handleBindClick = () => {
        if (isBound) {
            setShowSecondPopup(true);
        } else {
            setIsBinding(true);
            // Simulate an API call with a timeout
            setTimeout(() => {
                setIsBinding(false);
                setIsBound(true);
            }, 2000);
        }
    };

    const handleCloseSecondPopup = () => {
        setShowSecondPopup(false);
    };

    return (
        <>
            <div className={`fixed inset-0 flex items-center justify-center backdrop-blur-[1px] z-10 ${showSecondPopup ? "pointer-events-none opacity-50" : ""}`}>
                <div className="bg-white p-8 rounded-xl shadow-lg relative w-[38%]">
                    <button
                        onClick={onClose}
                        className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                    >
                        <IoClose className="font-bold text-[34px] m-2 text-[#D7D7D7]" />
                    </button>
                    <div>
                        <div className="flex items-center gap-4 mb-4">
                            <FaGoogle className="text-[#EFB81C] text-xl" />
                            <h2 className="text-xl font-bold">Account Binding</h2>
                        </div>
                        <div className="p-3 flex items-center bg-[#F3F3F3] rounded-xl justify-between">
                            <div className="flex items-center gap-4 pl-2">
                                <div>
                                    <FaGoogle className="text-[28px]" />
                                </div>
                                <div className="flex flex-col">
                                    <div>
                                        <span className="font-semibold">
                                            Enter Verification Code
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-1 text-[#9D9D9D]">
                                        <IoIosCheckmarkCircle className="text-sm" />
                                        <span className="text-[12px]">
                                            {isBound ? "Bound" : "Unbound"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    onClick={handleBindClick}
                                    disabled={isBinding}
                                    className="text-[#EFB81C] hover:text-white border-2 border-[#EFB81C] hover:bg-[#EFB81C] focus:outline-none focus:ring-[#EFB81C] font-medium rounded-lg text-[12px] px-4 py-1.5 text-center me-2 mb-2 dark:border-[#EFB81C] dark:text-[#EFB81C] dark:hover:text-white dark:hover:bg-[#EFB81C] dark:focus:ring-[#EFB81C]"
                                >
                                    {isBinding ? "Binding..." : isBound ? "Unbind" : "Bind"}
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center justify-between mt-3">
                            <div className="flex items-center gap-2">
                                <div className="cursor-pointer"></div>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="focus:outline-none w-full mt-3 text-white bg-[#EFB81C] hover:bg-[#EFB81C] font-medium rounded-[10px] text-md px-5 py-3 me-2 mb-2"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
            {showSecondPopup && (
                <SecondPopup onClose={handleCloseSecondPopup} />
            )}
        </>
    );
}

export default GoogleAccountBinding;
