import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { BsCaretDownFill, BsJournalBookmark, BsSearch } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { useSelectedCoin } from "../context/SelectedCoinContext";
import { GiHamburgerMenu } from "react-icons/gi";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMainFilter } from "../../../store/actions/tradeAction";
import DarkTheme from "./DarkTheme";

const OptionSelectedCoinDetails = ({ marketData, onSelectCoin }) => {
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef(null);
    const history = useHistory();
    // for the dropdown on coin hamburger
    // const [selectedMainFilter, setSelectedMainFilter] = useState("Spot");
    const [selectedSubMainFilter, setSelectedSubMainFilter] = useState("BTC");
    const [selectedDetailCoin, setSelectedDetailCoin] = useState("All");
    const dispatch = useDispatch();
    const selectedMainFilter = useSelector(
        (state) => state.trade.selectedMainFilter
    );

    const mainfilters = ["Spot", "Perpetual", "Future", "Option", "Margin"];
    // define array list for differt main filter tabs
    const spotfilter = [
        "USDT",
        "USDC",
        "USDE",
        "EUR",
        "BRL",
        "PLN",
        "TRY",
        "BTC",
        "ETH",
        "DAI",
        "BRZ",
    ];
    const perpetualfilter = ["USDT", "USDC", "Inverse Perpetual"];
    const futurefilter = ["USDC", "Inverse Futures"];
    const optionsfilter = ["BTC", "ETH", "SOL"];
    const marginfilter = ["USDT", "USDC", "BTC", "ETH"];
    // for more detail filters
    const USDTCoin = [
        "All",
        "New",
        "0 Fee",
        "Margin Trading",
        "Adventure Zone",
        // "SOL Ecosystem",
        // "ETH Ecosystem",
        // "BTC Ecosystem",
        // "AI",
        // "Modular-BCs",
    ];
    const USDCCoin = [
        "All",

        "0 Fee",
        "Margin Trading",
        "Adventure Zone",
        "SOL Ecosystem",
        // "ETH Ecosystem",
        // "BTC Ecosystem",
        // "AI",
        // "Modular-BCs",
    ];
    const EURCoin = [
        "All",
        "SOL Ecosystem",
        "ETH Ecosystem",
        "BTC Ecosystem",
        "Meme",
    ];
    const ETHCoin = ["All", "ETH Ecosystem"];

    const SubMainCoins =
        selectedMainFilter === "Spot"
            ? spotfilter
            : selectedMainFilter === "Perpetual"
            ? perpetualfilter
            : selectedMainFilter === "Future"
            ? futurefilter
            : selectedMainFilter === "Option"
            ? optionsfilter
            : selectedMainFilter === "Margin"
            ? marginfilter
            : [];
    // more detail of coin
    const subDetailCoin =
        selectedSubMainFilter === "USDT"
            ? USDTCoin
            : selectedSubMainFilter === "USDC"
            ? USDCCoin
            : selectedSubMainFilter === "EUR"
            ? EURCoin
            : selectedSubMainFilter === "ETH"
            ? ETHCoin
            : USDTCoin;

    const { selectedCoin, setSelectedCoin } = useSelectedCoin();

    const selectedCoinPair =
        selectedCoin?.ticker?.toUpperCase().replace("_", "/") || "BTC/USDT";

    console.log("SelectedCoin:", selectedCoin);

    const updateCoin = setSelectedCoin;
    const handleCoinSelectChange = (coin) => {
        if (coin.ticker) {
            onSelectCoin(coin.ticker.toLowerCase().replace("/", "_"));
            setDropdownIsOpen(false);

            updateCoin((prev) => ({
                ...prev,
                ticker: coin.ticker.toLowerCase().replace("/", "_"),
            }));
        }
        history.push(
            `/trade/${selectedMainFilter.toLowerCase()}/${coin.ticker}`
        );
    };

    const handleToggle = () => {
        setDropdownIsOpen(!dropdownIsOpen);
    };

    const handleUserGuide = () => {
        // Add the user guide redirection here
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const changeColor =
        selectedCoin.change && parseFloat(selectedCoin.change) > 0
            ? "text-green-500"
            : "text-red-500";

    const filteredCoinName = selectedCoin.name
        .split(/[^a-zA-Z]/)[0]
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

    const formatNumber = (value) => {
        const num = typeof value === "string" ? parseFloat(value) : value;
        return !isNaN(num) ? (Math.round(num * 100) / 100).toFixed(2) : value;
    };

    // Format price for display
    const formatPrice = (price) => {
        const numPrice = Number(price);
        if (isNaN(numPrice)) return "N/A";
        if (numPrice >= 1e9) return `$${(numPrice / 1e9).toFixed(2)}B`;
        if (numPrice >= 1e6) return `$${(numPrice / 1e6).toFixed(2)}M`;
        if (numPrice >= 1e3) return `$${(numPrice / 1e3).toFixed(2)}K`;
        return `$${numPrice.toFixed(2)}`;
    };

    // Filter marketData based on search term (ignoring case)
    const filteredCoins = marketData.filter(
        (coin) =>
            coin.ticker.toLowerCase().includes(searchTerm.toLowerCase()) ||
            coin.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <section className="flex items-center gap-3 px-2 spot-trade__coin-details-wrapper bg-[#FFFFFF] dark:bg-[#101014] dark:!text-white">
            <div
                className="relative spot-trade__selected-coin  "
                tabIndex={0}
                onMouseEnter={() => setDropdownIsOpen(true)}
                onMouseLeave={() => setDropdownIsOpen(false)}
                ref={dropdownRef}
            >
                <button
                    onClick={handleToggle}
                    type="button"
                    className=" px-2 py-4"
                >
                    <GiHamburgerMenu />
                </button>

                {dropdownIsOpen && (
                    <div
                        className="absolute border min-w-[330px] top-[46px] left-0 flex flex-col gap-1 p-2 bg-white rounded-md dark:text-[#ffffff] dark:bg-[#101014] dark:border-transparent"
                        style={{
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        }}
                    >
                        <div className="flex flex-col w-full gap-1">
                            <div className="flex items-center w-full gap-1.5 px-2 border rounded-md dark:bg-[#25282C]  dark:text-[#71777A] dark:border-transparent">
                                <BsSearch size={14} />
                                <input
                                    type="text"
                                    id="search"
                                    name="search"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    autoComplete="off"
                                    className="w-full border-none outline-none py-2 text-[12px] placeholder:text-[11px] bg-transparent"
                                />
                            </div>
                            <ul className="w-full flex flex-col gap-2">
                                <li className="flex justify-start gap-2  text-xs">
                                    {mainfilters.map((filter) => (
                                        <button
                                            key={filter}
                                            className={`px-1 py-1 ${
                                                selectedMainFilter === filter
                                                    ? " text-[#EFB81C]"
                                                    : "text-[#15141590] dark:text-[#71777A] "
                                            } hover:bg-[#EAEAEA] dark:hover:bg-[#25282C] transition-all duration-200 rounded font-bold text-[12px] dark:hover:bg-opacity-60`}
                                            onClick={() =>
                                                dispatch(
                                                    setSelectedMainFilter(
                                                        filter
                                                    )
                                                )
                                            }
                                        >
                                            {filter}
                                        </button>
                                    ))}
                                </li>
                                <li className="flex justify-start gap-1 text-xs ">
                                    {SubMainCoins.map((filter) => (
                                        <button
                                            key={filter}
                                            className={`px-1 py-1 ${
                                                selectedSubMainFilter === filter
                                                    ? "text-white bg-[#EFB81C]"
                                                    : "text-[#15141590] bg-[#EAEAEA]  dark:text-[#71777A] dark:bg-[#25282C]"
                                            } hover:bg-[#EAEAEA] transition-all duration-200 rounded font-bold text-[10px] dark:hover:bg-[#25282C]`}
                                            onClick={() =>
                                                setSelectedSubMainFilter(filter)
                                            }
                                        >
                                            {filter}
                                        </button>
                                    ))}
                                </li>
                                <li className="flex justify-start gap-2  text-xs ">
                                    {subDetailCoin.map((filter) => (
                                        <button
                                            key={filter}
                                            className={`px-1  py-1 ${
                                                selectedDetailCoin === filter
                                                    ? " text-[#EFB81C]"
                                                    : "text-[#15141590] dark:text-[#71777A] "
                                            } hover:bg-[#EAEAEA] dark:hover:bg-[#25282C] transition-all duration-200 rounded font-bold text-[10px] dark:hover:bg-opacity-60`}
                                            onClick={() =>
                                                setSelectedDetailCoin(filter)
                                            }
                                        >
                                            {filter}
                                        </button>
                                    ))}
                                </li>
                            </ul>
                            <table className="w-full">
                                <thead>
                                    <tr className="flex w-full gap-1 border-b">
                                        <th
                                            className="text-[12px] text-[#15141590] p-1 text-left dark:text-[#71777A] "
                                            style={{
                                                width: "calc(100%/4)",
                                                minWidth: "90px",
                                            }}
                                        >
                                            Trading Pair
                                        </th>
                                        <th
                                            className="text-[12px] text-[#15141590] p-1 text-right dark:text-[#71777A] "
                                            style={{
                                                width: "calc(100%/4)",
                                                minWidth: "90px",
                                            }}
                                        >
                                            Buy Price
                                        </th>
                                        <th
                                            className="text-[12px] text-[#15141590] p-1 text-right dark:text-[#71777A]"
                                            style={{
                                                width: "calc(100%/4)",
                                                minWidth: "90px",
                                            }}
                                        >
                                            24H Change
                                        </th>
                                        <th
                                            className="text-[12px] text-[#15141590] p-1 text-right dark:text-[#71777A] "
                                            style={{
                                                width: "calc(100%/4)",
                                                minWidth: "90px",
                                            }}
                                        >
                                            Total Volume
                                        </th>
                                    </tr>
                                </thead>

                                <tbody className="max-h-[60vh] flex flex-col justify-between overflow-y-auto w-full ">
                                    {filteredCoins.length > 0 ? (
                                        filteredCoins.map((coin, index) => (
                                            <tr
                                                key={index}
                                                onClick={() =>
                                                    handleCoinSelectChange(coin)
                                                }
                                                className={`w-full  cursor-pointer hover:bg-[#F4F4F4] dark:hover:bg-[#25282C] flex gap-1 ${
                                                    selectedCoin.ticker ===
                                                    coin.ticker
                                                        ? "bg-[#F4F4F4] dark:bg-[#25282C]"
                                                        : ""
                                                }`}
                                            >
                                                <td
                                                    className="px-1.5 py-2.5 text-left"
                                                    style={{
                                                        width: "calc(100%/4)",
                                                    }}
                                                >
                                                    <div className="flex items-center gap-1">
                                                        <img
                                                            src={coin.icon}
                                                            alt={coin.name}
                                                            title={coin.name}
                                                            className="w-4 h-4"
                                                        />
                                                        <span className="text-[12px] font-[500]">
                                                            {coin.ticker
                                                                .toUpperCase()
                                                                .replace(
                                                                    "_",
                                                                    "/"
                                                                )}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td
                                                    className="text-[12px] font-[500] px-1.5 py-2.5 text-right"
                                                    style={{
                                                        width: "calc(100%/4)",
                                                    }}
                                                >
                                                    {`${parseFloat(
                                                        coin.buy_price
                                                    ).toFixed(4)}`}
                                                </td>
                                                <td
                                                    className={`text-[12px] font-[500] px-1.5 py-2.5 text-right" ${
                                                        parseFloat(
                                                            coin.change
                                                        ) > 0
                                                            ? "text-green-500"
                                                            : "text-red-500"
                                                    }`}
                                                    style={{
                                                        width: "calc(100%/4)",
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    {parseFloat(coin.change) > 0
                                                        ? `+${coin.change}%`
                                                        : `${coin.change}%`}
                                                </td>
                                                <td
                                                    className="text-[12px] font-[500] px-1.5 py-2.5 text-right"
                                                    style={{
                                                        width: "calc(100%/4)",
                                                    }}
                                                >
                                                    {coin.volume
                                                        ? formatPrice(
                                                              coin.volume
                                                          )
                                                        : "--"}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan="3"
                                                className="text-center text-[12px] text-gray-500 py-2"
                                            >
                                                No matching coin pair found.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
            <button
                type="button"
                className="flex items-center gap-1.5 border-r-2 px-2 py-2 pr-4  -ml-2"
            >
                <img
                    src={
                        selectedCoin.icon
                            ? selectedCoin.icon
                            : "https://api.buycex.com/Upload/coin/BTC.png"
                    }
                    alt={selectedCoin.name || "BTC/USDT"}
                    title={selectedCoin.name}
                    className="w-6 h-6 bg-transparent"
                />
                <div className="flex flex-col items-start gap-0">
                    <div className="flex items-center gap-2">
                        <span className="text-[14px] font-bold">
                            {`${selectedCoinPair}` || "BTC/USDT"}
                        </span>
                        <span className="text-xs font-semibold bg-red-50 text-yellow-500 px-1 py-[1px] rounded-[4px]">
                            10x
                        </span>
                    </div>
                    <span className="text-[11px] font-normal text-[#15141580] flex gap-2 dark:text-[#71777A]">
                        <span className="border-b boder-[1px] border-[#15141580] border-dashed dark:border-[#71777A]">
                            {" "}
                            {filteredCoinName}
                        </span>{" "}
                        <span>|</span>{" "}
                        <span className="border-b boder-[1px] border-[#15141580] border-dashed dark:text-[#71777A] dark:border-[#71777A]">
                            10 Networks
                        </span>
                    </span>
                </div>
            </button>

            {/* Displaying the selected coin details */}
            <div className="flex items-center justify-between flex-1">
                <div className="flex items-center gap-8 p-2 spot-trade__selected-coin-details">
                    <div className="flex items-center gap-3">
                        <img
                            src={
                                selectedCoin.icon
                                    ? selectedCoin.icon
                                    : "https://api.buycex.com/Upload/coin/BTC.png"
                            }
                            alt={selectedCoin.name || "BTC/USDT"}
                            title={selectedCoin.name}
                            className="w-6 h-6"
                        />
                        <div className="flex flex-col items-start justify-start ">
                            <span
                                className={`text-[16px] text-black font-semibold dark:text-[#71777A] `}
                            >
                                BTC-Options
                            </span>
                            <span
                                className={`text-[12px] font-[500] ${changeColor}`}
                            >
                                70,419.20
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <img
                            src={
                                selectedCoin.icon
                                    ? selectedCoin.icon
                                    : "https://api.buycex.com/Upload/coin/BTC.png"
                            }
                            alt={selectedCoin.name || "BTC/USDT"}
                            title={selectedCoin.name}
                            className="w-6 h-6"
                        />
                        <div className="flex flex-col items-start justify-start ">
                            <span
                                className={`text-[16px] text-black font-semibold dark:text-[#71777A] `}
                            >
                                ETH-Options
                            </span>
                            <span
                                className={`text-[12px] font-[500] ${changeColor}`}
                            >
                                70,419.20
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <img
                            src={
                                selectedCoin.icon
                                    ? selectedCoin.icon
                                    : "https://api.buycex.com/Upload/coin/BTC.png"
                            }
                            alt={selectedCoin.name || "BTC/USDT"}
                            title={selectedCoin.name}
                            className="w-6 h-6"
                        />
                        <div className="flex flex-col items-start justify-start ">
                            <span
                                className={`text-[16px] text-black font-semibold dark:text-[#71777A]`}
                            >
                                SOL-Options
                            </span>
                            <span
                                className={`text-[12px] font-[500] ${changeColor}`}
                            >
                                70,419.20
                            </span>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-16">
                    <div className="flex items-center gap-4">
                        <div className="flex flex-col items-start justify-start ">
                            <span
                                className={`text-[12px] text-black font-semibold dark:text-[#71777A]`}
                            >
                                <div> IM</div>
                            </span>
                            <span
                                className={`text-[12px] font-[500] dark:text-[#71777A] `}
                            >
                                <div> MM</div>
                            </span>
                        </div>
                        <div className="flex flex-col items-start justify-start ">
                            <span
                                className={`text-[12px] text-black font-semibold dark:text-[#ffffff]`}
                            >
                                <div> 0.00%</div>
                            </span>
                            <span className={`text-[12px] font-[500] `}>
                                <div> 0.00%</div>
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <div className="flex flex-col items-start justify-start ">
                            <span
                                className={`text-[12px] text-black  dark:text-[#71777A] font-semibold `}
                            >
                                <div> Margin Balance</div>
                            </span>
                            <span
                                className={`text-[12px] font-[500] dark:text-[#71777A]`}
                            >
                                <div> Available Balance</div>
                            </span>
                        </div>
                        <div className="flex flex-col items-start justify-start ">
                            <span
                                className={`text-[12px] text-black  dark:text-[#ffffff] font-semibold `}
                            >
                                <div> 0.0000</div>
                            </span>
                            <span className={`text-[12px] font-[500] `}>
                                <div> 0.00000</div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-2">
                <DarkTheme />
            </div>
            <button
                type="button"
                data-tooltip-id="user-guide-tooltip"
                data-tooltip-content="User Trading Guide"
                data-tooltip-place="left"
                className="flex items-center ml-auto mr-2"
                onClick={handleUserGuide}
            >
                <BsJournalBookmark size={15} className="cursor-pointer" />
                <Tooltip id="user-guide-tooltip" />
            </button>
        </section>
    );
};

OptionSelectedCoinDetails.propTypes = {
    selectedCoin: PropTypes.shape({
        ticker: PropTypes.string,
        name: PropTypes.string,
        icon: PropTypes.string,
        buy_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sell_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        max_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        min_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        volume: PropTypes.oneOfType([PropTypes.number, PropTypes.number]),
        change: PropTypes.oneOfType([PropTypes.number]),
    }).isRequired,
    marketData: PropTypes.arrayOf(
        PropTypes.shape({
            ticker: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
            buy_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            sell_price: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            max_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            min_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            volume: PropTypes.oneOfType([PropTypes.number, PropTypes.number])
                .isRequired,
            change: PropTypes.oneOfType([PropTypes.number]).isRequired,
        })
    ).isRequired,
    onSelectCoin: PropTypes.func.isRequired,
};

export default OptionSelectedCoinDetails;
