import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import menuItems from "./MenuItems";

const EarnSidebar = () => {
    const location = useLocation();
    const path = location.pathname;

    // Utility to normalize paths (removes trailing slashes)
    const normalizePath = (p) => p.replace(/\/+$/, "");

    // Get the "Earn" sub-items
    const earnItems = menuItems.find(item => item.name === "Earn")?.subItems || [];

    return (
        <div className="flex flex-col gap-y-5 mt-7">
            <nav className="w-[248px] h-full">
                {/* Render Earn sub-items */}
                {earnItems.length > 0 && (
                    <ul className="flex flex-col bg-white rounded-md gap-1">
                        {earnItems.map(subItem => {
                            const isActive = normalizePath(path) === normalizePath(subItem.to);
                            return (
                                <li key={subItem.name}>
                                    <NavLink
                                        to={subItem.to}
                                        className={`${
                                            isActive
                                                ? "font-semibold bg-[#F4F4F4]"
                                                : "font-medium"
                                        } flex items-center pl-6 px-3 py-3 gap-3 text-gray dark:text-white hover:text-[#151415] hover:bg-[#F4F4F4] rounded-lg`}
                                    >
                                        {subItem.icon}
                                        <span className="text-[16px]">{subItem.name}</span>
                                    </NavLink>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </nav>
        </div>
    );
};

export default EarnSidebar;
