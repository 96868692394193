import React, { useEffect, useState } from "react";
import "../../components/home-page-sections/hero-section/./HeroSection.scss";
import LaunchpadProjects from '../../components/Launchpad/LaunchpadProjects';
const heroImages = [HeroImageOne, HeroImageTwo, HeroImageThird];
import HeroImageOne from "assets/img/Aboutsection4.png";
import HeroImageTwo from "assets/img/algo-orders.png";
import HeroImageThird from "assets/img/Aboutsection3.png";
import StakingPool from "components/Launchpad/Startupmining/StakingPool ";
import Launchnavbar from "components/Launchpad/Launchnavbar";
const Startupmining = () => {

  const flippingWords = ["Staking", "Earning", "Potential"];
  const [currentWord, setCurrentWord] = useState("Staking");
  const [flipState, setFlipState] = useState("flip-enter");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageTransitionState, setImageTransitionState] = useState("fade-in");

  useEffect(() => {
    const wordInterval = setInterval(() => {
        setFlipState("flip-exit");

        setTimeout(() => {
            setCurrentWord((prev) => {
                const nextIndex =
                    (flippingWords.indexOf(prev) + 1) %
                    flippingWords.length;
                return flippingWords[nextIndex];
            });
            setFlipState("flip-enter");
        }, 600);
    }, 3000);

    return () => clearInterval(wordInterval);
}, []);

useEffect(() => {
    const imageInterval = setInterval(() => {
        setImageTransitionState("fade-out");

        setTimeout(() => {
            setCurrentImageIndex((prevIndex) => {
                return (prevIndex + 1) % heroImages.length;
            });
            setImageTransitionState("fade-in");
        }, 600);
    }, 3000);

    return () => clearInterval(imageInterval);
}, []);
  return (
<>
<div className="text-black min-h-screen">
<Launchnavbar/>


<section className="hero-section border-b flex relative mb-8 pb-8"> {/* Reduced mb and pb */}
  <div className="hero-section__wrapper container flex items-center justify-between gap-8 w-full max-h-[60vh] m-auto max-md:flex-col">
    {/* Left Section */}
    <div className="hero-title-wrapper flex flex-col gap-4 w-[60%] mb-10"> {/* Reduced bottom margin */}
      <h1 className="hero-title flex flex-col gap-1 text-[48px] font-[600] leading-tight">
        <span className="text-[48px] font-[900] text-[#EFB81C]">Startup</span>
      </h1>

      <p className="hero-description text-xl mb-4"> {/* Reduced margin-bottom */}
        <span>Quick</span>{" "}
        <span className={`flip-text ${flipState}`}>{currentWord}</span>{" "}
        <span>new Token</span>
      </p>

      {/* Stats Section */} 
      <div className="stats-wrapper flex items-center text-[#EFB81C] rounded-lg space-x-8"> {/* Adjust the space-x value */}
  <div className="stat-item text-center">
    <p className="text-2xl font-bold">$105.1M</p>
    <p className="text-gray-400">Staked</p>
  </div>
  <div className="stat-item text-center">
    <p className="text-2xl font-bold">$1.60B</p>
    <p className="text-gray-400">Funds Raised</p>
  </div>
  <div className="stat-item text-center">
    <p className="text-2xl font-bold">53</p>
    <p className="text-gray-400">Projects</p>
  </div>
  <div className="stat-item text-center">
    <p className="text-2xl font-bold">377,018</p>
    <p className="text-gray-400">Participants</p>
  </div>
</div>




      <div className="hero-btn-wrap flex items-center gap-4 mt-4 mb-8"> {/* Reduced margin-top and margin-bottom */}
        <button className="bg-[#EFB81C] text-white py-2 px-6 rounded-md">
          About Startup Mining
        </button>
      </div>
    </div>

    {/* Right Section: Hero Image */}
    <div className="hero-image-wrapper relative w-[40%] min-h-[400px] rounded-xl overflow-hidden">
      {heroImages.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`hero-img-${index}`}
          className={`hero-image absolute top-0 left-0 w-full h-full rounded-xl object-contain transition-opacity duration-700 ${imageTransitionState} ${
            index === currentImageIndex ? "active" : "inactive"
          }`}
        />
      ))}
    </div>
  </div>
</section>



    <StakingPool/>
  </div>
  
</>

  );
};

export default Startupmining;
