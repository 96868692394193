import { Radio } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import {
    MdOutlineArrowDropDown,
    MdOutlineArrowDropUp,
    MdClose,
} from "react-icons/md";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
    setDescription,
    setAutoReply,
    setRegTimeInput,
    setBalanceInput,
    setCheckedItems,
    setSelectedStatus,
    setCountry,
    setTags,
} from "../../../store/reducers/stepOneSlice";
import {
    getCountryCode,
    getCountryName,
    getUserIP,
} from "components/UserLocation/geolocationUtils";

function StepThree({ onStatusChange }) {
    const termOptions = [
        {
            label: "Bank Statement Required",
            description:
                "Bank statement will be required for additional verification",
        },
        {
            label: "Extra KYC required",
            description: "Need to complete one-time extra KYC verification",
        },
        {
            label: "No Additional Verification Needed",
            description:
                "No additional verification requirements from the maker",
        },
        {
            label: "No Payment Receipt Needed",
            description: "Receipt not required for this trade",
        },
        {
            label: "Payment Receipt Required",
            description:
                "You must provide a transaction receipt to complete the trade",
        },
        {
            label: "Photo ID Required",
            description: "Valid government-issued photo ID required",
        },
    ];

    const regionOptions = [
        { label: "All Region" },
        { label: "United States", code: "US", flag: "🇺🇸" },
        { label: "Canada", code: "CA", flag: "🇨🇦" },
        { label: "United Kingdom", code: "GB", flag: "🇬🇧" },
        { label: "Australia", code: "AU", flag: "🇦🇺" },
        // Add more regions as needed
    ];

    const dispatch = useDispatch();
    const {
        checkedItems,
        regTimeInput,
        description,
        autoReply,
        balanceInput,
        selectedStatus,
        country,
        tags,
    } = useSelector((state) => state.stepone);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [remarksText, setRemarksText] = useState(description);
    const [autoReplyText, setAutoReplyText] = useState(autoReply);
    const charLimit = 1000;

    const state = useSelector((state) => state.authCheck.authStatus.addr);
    console.log(state);

    const handleRegTimeChange = (event) => {
        const value = event.target.value;
        setRegTimeInput(value);
        dispatch(setRegTimeInput(value));
    };

    // Function to handle changes in the balance input
    const handleBalanceChange = (event) => {
        const value = event.target.value;
        setBalanceInput(value);
        dispatch(setBalanceInput(value));
    };

    useEffect(() => {
        if (tags) {
            setSelectedOptions(tags); 
        }
    }, [tags]);

    const handleSelect = (option) => {
        const isSelected = selectedOptions.includes(option.label);
        const updatedOptions = isSelected
            ? selectedOptions.filter((item) => item !== option.label)
            : [...selectedOptions, option.label];

        setSelectedOptions(updatedOptions);
        dispatch(setTags(updatedOptions)); 
    };

    const handleRegionSelect = (region) => {
        setSelectedRegion(region);
    };

    const countCharacters = (text) => {
        return text.length;
    };

    const handleToggle = (isOpen) => {
        console.log("Dropdown is", isOpen ? "Open" : "Closed");
    };

    const handleRemove = (label) => {
        const updatedOptions = selectedOptions.filter((item) => item !== label);
        setSelectedOptions(updatedOptions);
        dispatch(setTags(updatedOptions));
    };

    const handleRemarksChange = (e) => {
        if (e.target.value.length <= charLimit) {
            setRemarksText(e.target.value);
            dispatch(setDescription(e.target.value));
        }
    };

    const handleAutoReplyChange = (e) => {
        if (e.target.value.length <= charLimit) {
            setAutoReplyText(e.target.value);
            dispatch(setAutoReply(e.target.value));
        }
    };

    // const [checkedItems, setCheckedItems] = useState({
    //     kyc_required:false,
    //     reg_time_required: false,
    //     balance_required: false,
    // });

    const handleCheckboxToggle = (key) => {
        const newCheckedItems = {
            ...checkedItems,
            [key]: !checkedItems[key],
        };
        dispatch(setCheckedItems(newCheckedItems));
    };

    // useEffect(() => {
    //     // Send initial state to parent when component mounts
    //     setcheckitem(checkedItems);
    // }, []);

    // const [selectedStatus, setSelectedStatus] = useState('');
    const [selected, setSelected] = useState("online");

    useEffect(() => {
        dispatch(setSelectedStatus(selected)); // Use the renamed function here
        onStatusChange(1); // Send 'online' status value
    }, [dispatch, onStatusChange, selectedStatus]);

    const handleStatusChange = (event) => {
        const statusValue = event.target.value; // Get the value of the radio button
        setSelectedStatus(statusValue);
        // Send the appropriate value to the parent
        onStatusChange(statusValue === "online" ? 1 : 2);
        dispatch(setSelectedStatus(statusValue));
    };
    // const [defaultCountry, setDefaultCountry] = useState("");

    useEffect(() => {
        const initialize = async () => {
            const ip = await getUserIP();
            if (ip) {
                const countryCode = await getCountryName(ip);
                if (countryCode) {
                    dispatch(setCountry(countryCode));
                }
            }
        };

        initialize();
    }, []);

    // console.log(defaultCountry);

    return (
        <div className="px-14 py-10">
            <div
                className="flex items-baseline p-4 mb-4 text-sm rounded-lg bg-[#D89F0014]"
                role="alert"
            >
                <IoMdInformationCircle className="relative flex-shrink-0 w-6 h-6 me-1" />
                <span className="text-[12px] leading-normal">
                    <p className="text-sm">
                        Please ensure that you comply with P2P rules to avoid
                        account suspension or expulsion from the Binance
                        Merchant Program. Especially:
                    </p>
                    <p className="text-sm">
                        1. If you require taker's document for verification,
                        it's necessary to indicate the requirement in the
                        'Remarks' section of your advertisement.
                    </p>
                    <p className="text-sm">
                        2. Imposing extra fees on takers is not allowed in all
                        scenarios.
                    </p>
                </span>
            </div>
            <p className="text-sm mb-2">Terms Tags (Optional)</p>
            <Dropdown
                label="Add tags"
                options={termOptions}
                selectedOptions={selectedOptions}
                onSelect={handleSelect}
                onRemove={handleRemove}
                isOpen={false}
                onToggle={handleToggle}
                width="50%"
            />
            <p className="text-sm text-[#707A8A] mt-1">Select up to 3 tags</p>

            {/* Remarks Section */}
            <div className="relative w-full">
                <p className="text-sm mb-2 mt-10">Remarks (Optional)</p>
                <textarea
                    value={remarksText}
                    onChange={handleRemarksChange}
                    className="w-full px-4 py-1 text-sm font-light border border-gray-300 rounded-sm focus:outline-none focus:border-[#EFB81C] focus:border-2 resize-none h-20"
                    placeholder="Please do not include any crypto-related words, such as crypto, P2P, C2C, BTC, USDT, ETH etc."
                ></textarea>
                <div className="absolute bottom-2 right-2 text-xs text-gray-500">
                    {countCharacters(remarksText)}/{charLimit}
                </div>
            </div>

            {/* Auto Reply Section */}
            <div className="border-b pb-10">
                <div className="relative w-full mt-6">
                    <p className="text-sm mb-2">Auto Reply (Optional)</p>
                    <textarea
                        value={autoReplyText}
                        onChange={handleAutoReplyChange}
                        className="w-full px-4 py-1 text-sm font-light border border-gray-300 rounded-sm focus:outline-none focus:border-[#EFB81C] focus:border-2 resize-none h-20"
                        placeholder="Auto reply message will be sent to the counterparty once the order is created"
                    ></textarea>
                    <div className="absolute bottom-2 right-2 text-xs text-gray-500">
                        {countCharacters(autoReplyText)}/{charLimit}
                    </div>
                </div>
            </div>

            {/* Regions Section*/}
            <div className="relative border-b pb-10 w-full mt-10">
                <p className="text-sm mb-2">Select Region</p>
                {/* <Dropdown
                    // label={selectedRegion ? `${selectedRegion.flag} ${selectedRegion.label}` : "Choose region"}
                    label={defaultCountry}
                    options={regionOptions}
                    selectedOptions={selectedRegion ? [selectedRegion.label] : []}
                    onSelect={handleRegionSelect}
                    onRemove={() => setSelectedRegion(null)}
                    isOpen={false}
                    onToggle={() => { }}
                    width="50%"
                /> */}
                <Dropdown
                    label={country || "Choose region"} // Default label when no country is loaded
                    options={[]} // Empty options array to prevent displaying options
                    selectedOptions={
                        selectedRegion ? [selectedRegion.label] : []
                    }
                    onSelect={handleRegionSelect}
                    onRemove={() => setSelectedRegion(null)}
                    isOpen={false} // Ensure the dropdown does not open
                    onToggle={() => {}} // Empty function to disable toggling
                    width="50%"
                />
            </div>
            <div className="w-full mt-10">
                <p className="text-sm mb-2">Counterparty Conditions</p>
                <div className="space-y-4">
                    <div
                        className="flex items-center gap-2 cursor-pointer"
                        checked={checkedItems.kyc_required}
                        onClick={() => handleCheckboxToggle("kyc_required")}
                    >
                        {checkedItems.kyc_required ? (
                            <MdCheckBox className="text-[#D89F00]" />
                        ) : (
                            <MdCheckBoxOutlineBlank />
                        )}
                        <p className="text-sm font-light">Completed KYC</p>
                    </div>
                    <div
                        className="flex items-center gap-2 cursor-pointer"
                        checked={checkedItems.reg_time_required}
                        onClick={() =>
                            handleCheckboxToggle("reg_time_required")
                        }
                    >
                        {checkedItems.reg_time_required ? (
                            <MdCheckBox className="text-[#D89F00]" />
                        ) : (
                            <MdCheckBoxOutlineBlank />
                        )}
                        <p className="text-sm font-light">Registered</p>
                        <input
                            value={regTimeInput}
                            onChange={handleRegTimeChange}
                            className="bg-gray-100 w-28 rounded-md"
                        />
                        <p className="text-sm font-light">day(s) ago</p>
                    </div>

                    <div
                        className="flex items-center gap-2 cursor-pointer"
                        checked={checkedItems.balance_required}
                        onClick={() => handleCheckboxToggle("balance_required")}
                    >
                        {checkedItems.balance_required ? (
                            <MdCheckBox className="text-[#D89F00]" />
                        ) : (
                            <MdCheckBoxOutlineBlank />
                        )}
                        <p className="text-sm font-light">Holdings more than</p>
                        <input
                            value={balanceInput}
                            onChange={handleBalanceChange}
                            className="bg-gray-100 w-28 rounded-md"
                        />
                        <p className="text-sm font-light">BTC</p>
                    </div>
                </div>
            </div>

            <div className="my-8">
                <p className="text-sm mb-2 font-light">Status</p>
                <div className="flex gap-20">
                    <label>
                        <StyledRadio
                            value="online"
                            checked={selectedStatus === "online"}
                            onChange={handleStatusChange}
                        />
                        <span className="ml-2 text-sm font-light">
                            Online right now
                        </span>
                    </label>

                    <label>
                        <StyledRadio
                            value="offline"
                            checked={selectedStatus === "offline"}
                            onChange={handleStatusChange}
                        />
                        <span className="ml-2 text-sm font-light">
                            Offline, manually later
                        </span>
                    </label>
                </div>
            </div>
        </div>
    );
}

const Dropdown = ({
    label,
    options,
    selectedOptions,
    onSelect,
    onRemove,
    isOpen,
    onToggle,
    width = "auto",
}) => {
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
        onToggle(!isDropdownOpen);
    };

    return (
        <div
            className="relative inline-block text-left"
            style={{ width }}
            onMouseLeave={() => setIsDropdownOpen(false)} // Close dropdown on mouse leave
        >
            <div
                ref={buttonRef}
                onMouseEnter={() => setIsDropdownOpen(true)} // Open dropdown on hover
            >
                <button
                    onClick={handleToggleDropdown}
                    type="button"
                    className="inline-flex items-center gap-2 px-3 py-2 text-xs font-light text-gray-900 w-full border rounded-lg"
                    aria-expanded={isDropdownOpen}
                    aria-haspopup="true"
                >
                    <div className="flex w-full flex-wrap gap-1">
                        {selectedOptions.length > 0 ? (
                            selectedOptions.map((item) => (
                                <div
                                    key={item}
                                    className="flex items-center bg-gray-200 rounded-sm px-2 py-1 font-light text-gray-800"
                                >
                                    <span className="text-xs">{item}</span>
                                    <button
                                        onClick={() => onRemove(item)} // Use onRemove here
                                        className="ml-1 text-gray-400 hover:text-gray-600"
                                        aria-label={`Remove ${item}`}
                                    >
                                        <MdClose />
                                    </button>
                                </div>
                            ))
                        ) : (
                            <span className="font-semibold text-sm text-[#707A8A]">
                                {label}
                            </span>
                        )}
                    </div>
                    {isDropdownOpen ? (
                        <MdOutlineArrowDropUp className="text-[18px] text-[#707A8A]" />
                    ) : (
                        <MdOutlineArrowDropDown className="text-[18px] text-[#707A8A]" />
                    )}
                </button>
            </div>

            {isDropdownOpen && (
                <div
                    className="absolute left-0 right-0 h-2 cursor-default"
                    style={{ top: "100%" }}
                    onMouseEnter={() => setIsDropdownOpen(true)} // Keep dropdown open on hover
                    onMouseLeave={() => setIsDropdownOpen(false)} // Close dropdown on mouse leave
                ></div>
            )}

            {isDropdownOpen && (
                <div
                    ref={dropdownRef}
                    onMouseEnter={() => setIsDropdownOpen(true)} // Keep dropdown open on hover
                    onMouseLeave={() => setIsDropdownOpen(false)} // Close dropdown on mouse leave
                    className="absolute z-10 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    style={{ top: "calc(100% + 8px)" }} // Simulate the gap
                    aria-orientation="vertical"
                    tabIndex="-1"
                >
                    <div className="py-1">
                        <ul className="">
                            {options.length > 0 ? (
                                options.map((option) => (
                                    <li
                                        key={option.label}
                                        onClick={() => onSelect(option)}
                                        className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                                    >
                                        <div className="flex items-center gap-2">
                                            {selectedOptions.includes(
                                                option.label
                                            ) ? (
                                                <MdCheckBox />
                                            ) : (
                                                <MdCheckBoxOutlineBlank />
                                            )}
                                            <div className="flex flex-col w-full">
                                                <p className="text-sm font-semibold">
                                                    {option.label}
                                                </p>
                                                {option.description && (
                                                    <p className="text-xs text-gray-500">
                                                        {option.description}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                ))
                            ) : (
                                <li className="px-4 py-2 text-[14px] text-center text-gray-500">
                                    No results found
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

const StyledRadio = styled(Radio)`
    .ant-radio-inner {
        border-color: #efb81c; /* Change the border color of the radio */
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #efb81c; /* Change border color when checked */
        background-color: #efb81c; /* Change the background color when checked */
    }

    .ant-radio-checked .ant-radio-inner::after {
        background-color: #fff; /* Inner dot color when selected */
    }

    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2); /* Focus state outline color */
    }

    /* Change label color */
    .ant-radio-wrapper {
        color: #1f2937; /* Text color for the radio label */
    }
`;

export default StepThree;
