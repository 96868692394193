import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMarketData } from "store/actions/marketActions";

const useSpotMarketData = () => {
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};

    const dispatch = useDispatch();

    // Fetch market data from Redux store
    const marketDataFromStore = useSelector(
        (state) => state.market.data?.market || []
    );

    // Fetch data when the component mounts
    useEffect(() => {
        if (TOKEN && ID) {
            dispatch(fetchMarketData());
        }
    }, [dispatch, TOKEN, ID]);

    // Periodically fetch market data
    useEffect(() => {
        if (TOKEN && ID) {
            dispatch(fetchMarketData());
        }
    }, [dispatch, TOKEN, ID]);

    // Function to fetch coin data by ticker
    return {
        marketDataFromStore,
    };
};

export default useSpotMarketData;
