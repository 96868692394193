import React, { useState } from "react";
import { Modal } from "antd";
import { FaRotate } from "react-icons/fa6";

const CoverTradeModel = ({ setOrderTab, selectedCoin, showConvertModel }) => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [rotate, setRotate] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
        setOrderTab && setOrderTab("Spot");
        showConvertModel && showConvertModel(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setOrderTab && setOrderTab("Spot");
        showConvertModel && showConvertModel(false);
    };
    return (
        <div>
            <div className="model">
                <Modal
                    title="Convert"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    className="convert-model"
                >
                    <div className="content">
                        <div className="mt-4">
                            <select
                                name=""
                                id=""
                                className="text-xs text-black w-28 dark:bg-[#101014] dark:text-[#ffffff]"
                            >
                                <option value="funding">Funding</option>
                                <option value="funding">Unified Trading</option>
                            </select>
                        </div>
                        <div className="mt-4 flex flex-col gap-4 relative">
                            <div className="p-4 bg-gray-100 rounded-xl dark:bg-[#25282C]">
                                <div className="flex items-center justify-between text-gray-500 text-xs ">
                                    <div>From</div>
                                    <div>
                                        <span>Available: 0 BTC</span>{" "}
                                        <span className="text-yellow-500">
                                            Deposit Transfer
                                        </span>
                                    </div>
                                </div>
                                <div className="mt-2 flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <img
                                            src={selectedCoin.icon}
                                            alt=""
                                            className="w-6 h-6"
                                        />
                                        <div className="flex flex-col">
                                            <div className="font-semibold leading-tight dark:text-[#ffffff]">
                                                BTC
                                            </div>
                                            <div className="text-xs text-gray-500 leading-tight ">
                                                Bitcoin
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-end gap-2 ">
                                        <input
                                            placeholder="0.00005-2.9999"
                                            type="text"
                                            className="outline-none bg-transparent border-none text-xl placeholder:text-gray-500 placeholder:text-xs text-right "
                                        />

                                        <div className="font-bol text-yellow-500">
                                            All
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-4 bg-gray-100 rounded-xl dark:bg-[#25282C]">
                                <div className="flex items-center justify-between text-gray-500 text-xs ">
                                    <div>To</div>
                                    {/* <div>
                                        <span>Available: 0 BTC</span>{" "}
                                        <span className="text-yellow-500">
                                            Deposit Transfer
                                        </span>
                                    </div> */}
                                </div>
                                <div className="mt-2 flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <img
                                            src={selectedCoin.icon}
                                            alt=""
                                            className="w-6 h-6"
                                        />
                                        <div className="flex flex-col">
                                            <div className="font-semibold leading-tight  dark:text-[#ffffff]">
                                                BTC
                                            </div>
                                            <div className="text-xs text-gray-500 leading-tight ">
                                                Bitcoin
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-end gap-2 ">
                                        <input
                                            placeholder="0.00005-2.9999"
                                            type="text"
                                            className="outline-none bg-transparent border-none text-xl placeholder:text-gray-500 placeholder:text-xs text-right "
                                        />

                                        {/* <div className="font-bol text-yellow-500">
                                            All
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <button
                                onClick={() => setRotate(!rotate)}
                                className="absolute top-20 left-52 bg-white rounded-full p-1 border-1 border-gray-500 w-fit  felx items-center justify-center"
                            >
                                <div className="text-black font-bold p-2 w-fit rounded-full bg-yellow-500  flex items-center justify-center">
                                    <FaRotate size={20} />
                                </div>
                            </button>
                        </div>
                        <div className="mt-6 flex items-center justify-between gap-4">
                            <div className="h-[1px] flex-1 bg-gray-300"></div>
                            <div className="text-xs text-gray-400">
                                1 BTC≈--USDT
                            </div>
                            <div className="h-[1px] flex-1 bg-gray-300"></div>
                        </div>
                        <div className="mt-8">
                            <div className="flex items-center justify-between">
                                <span className="text-xs text-gray-400">
                                    Transaction Fees
                                </span>
                                <span className="text-green-500 bg-green-50 px-2 py-1 font-semibold rounded-lg text-xs">
                                    0 Fee
                                </span>
                            </div>
                            <div className="flex items-center justify-between mt-2">
                                <span className="text-xs text-gray-400">
                                    Receivables
                                </span>
                                <span className=" font-semibold  text-xs  dark:text-[#ffffff]">
                                    --USDT
                                </span>
                            </div>
                        </div>

                        <div className="mt-6 text-center flex items-center justify-center text-sm font-bold bg-yellow-500 rounded-xl h-12">
                            Get a Quote
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default CoverTradeModel;
