import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import CryptoJS from "crypto-js";

let mode = "DEVELOPMENT";

const saveToLocalStorage = (state) => {
    let serializedUid = "";
    let serializedFavorites = "";
    let serializedMyReducer = "";
    let serializedNewEmail = "";
    let serializedAuth = "";
    let serializedUserInfo = "";
    let serializedAuthCheck = "";
    let serializedGet2FAQR = "";
    let serializedSet2FA = "";
    let serializedUser = "";
    let serializedSaveAntiPishing = "";
    let serializedSecureLogin = "";
    let serializedDisableAccount = "";
    let serializedGoogleRegister = "";
    let serializedChangePassword = "";
    let serializedbanks = "";
    let serializedUserUpdate = "";

    if (mode !== "DEVELOPMENT") {
        serializedUid = CryptoJS.AES.encrypt(
            JSON.stringify(state.authUser),
            "my-secret-key"
        ).toString();
        serializedFavorites = CryptoJS.AES.encrypt(
            JSON.stringify(state.favorites),
            "my-secret-key"
        ).toString();
        serializedMyReducer = CryptoJS.AES.encrypt(
            JSON.stringify(state.myreducer),
            "my-secret-key"
        ).toString();
        serializedNewEmail = CryptoJS.AES.encrypt(
            JSON.stringify(state.Newemail),
            "my-secret-key"
        ).toString();
        serializedUserUpdate = CryptoJS.AES.encrypt(
            JSON.stringify(state.userUpdate),
            "my-secret-key"
        ).toString();

        serializedAuth = CryptoJS.AES.encrypt(
            JSON.stringify(state.auth),
            "my-secret-key"
        ).toString();
        serializedUserInfo = CryptoJS.AES.encrypt(
            JSON.stringify(state.userInfo),
            "my-secret-key"
        ).toString();
        serializedAuthCheck = CryptoJS.AES.encrypt(
            JSON.stringify(state.authCheck),
            "my-secret-key"
        ).toString();
        serializedGet2FAQR = CryptoJS.AES.encrypt(
            JSON.stringify(state.get2FAQR),
            "my-secret-key"
        ).toString();
        serializedSet2FA = CryptoJS.AES.encrypt(
            JSON.stringify(state.set2FA),
            "my-secret-key"
        ).toString();
        serializedUser = CryptoJS.AES.encrypt(
            JSON.stringify(state.userReducer),
            "my-secret-key"
        ).toString();
        serializedSaveAntiPishing = CryptoJS.AES.encrypt(
            JSON.stringify(state.saveAntiPishing),
            "my-secret-key"
        ).toString();
        serializedSecureLogin = CryptoJS.AES.encrypt(
            JSON.stringify(state.secureLogin),
            "my-secret-key"
        ).toString();
        serializedDisableAccount = CryptoJS.AES.encrypt(
            JSON.stringify(state.disableAccount),
            "my-secret-key"
        ).toString();
        serializedGoogleRegister = CryptoJS.AES.encrypt(
            JSON.stringify(state.googleRegister),
            "my-secret-key"
        ).toString();
        serializedChangePassword = CryptoJS.AES.encrypt(
            JSON.stringify(state.changePassword),
            "my-secret-key"
        ).toString();
    } else {
        serializedUid = JSON.stringify(state.authUser);
        serializedFavorites = JSON.stringify(state.favorites);
        serializedMyReducer = JSON.stringify(state.myreducer);
        serializedNewEmail = JSON.stringify(state.Newemail);
        serializedAuth = JSON.stringify(state.auth);
        serializedUserInfo = JSON.stringify(state.userInfo);
        serializedAuthCheck = JSON.stringify(state.authCheck);
        serializedGet2FAQR = JSON.stringify(state.get2FAQR);
        serializedSet2FA = JSON.stringify(state.set2FA);
        serializedUser = JSON.stringify(state.userReducer);
        serializedSaveAntiPishing = JSON.stringify(state.saveAntiPishing);
        serializedSecureLogin = JSON.stringify(state.secureLogin);
        serializedDisableAccount = JSON.stringify(state.disableAccount);
        serializedGoogleRegister = JSON.stringify(state.googleRegister);
        serializedChangePassword = JSON.stringify(state.changePassword);
        serializedbanks = JSON.stringify(state.banks);
        serializedUserUpdate = JSON.stringify(state.userUpdate);
    }

    localStorage.setItem("authUser", serializedUid);
    localStorage.setItem("favorites", serializedFavorites);
    localStorage.setItem("myreducer", serializedMyReducer);
    localStorage.setItem("Newemail", serializedNewEmail);
    localStorage.setItem("auth", serializedAuth);
    localStorage.setItem("userInfo", serializedUserInfo);
    localStorage.setItem("authCheck", serializedAuthCheck);
    localStorage.setItem("get2FAQR", serializedGet2FAQR);
    localStorage.setItem("set2FA", serializedSet2FA);
    localStorage.setItem("userReducer", serializedUser);
    localStorage.setItem("saveAntiPishing", serializedSaveAntiPishing);
    localStorage.setItem("secureLogin", serializedSecureLogin);
    localStorage.setItem("disableAccount", serializedDisableAccount);
    localStorage.setItem("googleRegister", serializedGoogleRegister);
    localStorage.setItem("changePassword", serializedChangePassword);
    localStorage.setItem("banks", serializedbanks);
    localStorage.setItem("userUpdate", serializedUserUpdate);
};

const checkLocalStorage = () => {
    const serializedUid = localStorage.getItem("authUser");
    const serializedFavorites = localStorage.getItem("favorites");
    const serializedMyReducer = localStorage.getItem("myreducer");
    const serializedNewEmail = localStorage.getItem("Newemail");
    const serializedAuth = localStorage.getItem("auth");
    const serializedUserInfo = localStorage.getItem("userInfo");
    const serializedAuthCheck = localStorage.getItem("authCheck");
    const serializedGet2FAQR = localStorage.getItem("get2FAQR");
    const serializedSet2FA = localStorage.getItem("set2FA");
    const serializedUser = localStorage.getItem("userReducer");
    const serializedSaveAntiPishing = localStorage.getItem("saveAntiPishing");
    const serializedSecureLogin = localStorage.getItem("secureLogin");
    const serializedDisableAccount = localStorage.getItem("disableAccount");
    const serializedGoogleRegister = localStorage.getItem("googleRegister");
    const serializedChangePassword = localStorage.getItem("changePassword");
    const serializedbanks = localStorage.getItem("banks");
    const serializedUserUpdate = localStorage.getItem("userUpdate");

    if (
        serializedUid === null ||
        serializedFavorites === null ||
        serializedMyReducer === null ||
        serializedNewEmail === null ||
        serializedAuth === null ||
        serializedUserInfo === null ||
        serializedAuthCheck === null ||
        serializedGet2FAQR === null ||
        serializedSet2FA === null ||
        serializedUser === null ||
        serializedSaveAntiPishing === null ||
        serializedSecureLogin === null ||
        serializedDisableAccount === null ||
        serializedGoogleRegister === null ||
        serializedChangePassword === null ||
        serializedbanks === null ||
        serializedUserUpdate === null
    )
        return undefined;

    let authUser = "";
    let favorites = "";
    let myreducer = "";
    let Newemail = "";
    let auth = "";
    let userInfo = "";
    let authCheck = "";
    let get2FAQR = "";
    let set2FA = "";
    let user = "";
    let saveAntiPishing = "";
    let secureLogin = "";
    let disableAccount = "";
    let googleRegister = "";
    let changePassword = "";
    let banks = "";
    let userUpdate = "";

    if (mode !== "DEVELOPMENT") {
        authUser = JSON.parse(
            CryptoJS.AES.decrypt(serializedUid, "my-secret-key").toString(
                CryptoJS.enc.Utf8
            )
        );
        favorites = JSON.parse(
            CryptoJS.AES.decrypt(serializedFavorites, "my-secret-key").toString(
                CryptoJS.enc.Utf8
            )
        );
        myreducer = JSON.parse(
            CryptoJS.AES.decrypt(serializedMyReducer, "my-secret-key").toString(
                CryptoJS.enc.Utf8
            )
        );
        Newemail = JSON.parse(
            CryptoJS.AES.decrypt(serializedNewEmail, "my-secret-key").toString(
                CryptoJS.enc.Utf8
            )
        );
        auth = JSON.parse(
            CryptoJS.AES.decrypt(serializedAuth, "my-secret-key").toString(
                CryptoJS.enc.Utf8
            )
        );
        userInfo = JSON.parse(
            CryptoJS.AES.decrypt(serializedUserInfo, "my-secret-key").toString(
                CryptoJS.enc.Utf8
            )
        );
        authCheck = JSON.parse(
            CryptoJS.AES.decrypt(serializedAuthCheck, "my-secret-key").toString(
                CryptoJS.enc.Utf8
            )
        );
        get2FAQR = JSON.parse(
            CryptoJS.AES.decrypt(serializedGet2FAQR, "my-secret-key").toString(
                CryptoJS.enc.Utf8
            )
        );
        set2FA = JSON.parse(
            CryptoJS.AES.decrypt(serializedSet2FA, "my-secret-key").toString(
                CryptoJS.enc.Utf8
            )
        );
        user = JSON.parse(
            CryptoJS.AES.decrypt(serializedUser, "my-secret-key").toString(
                CryptoJS.enc.Utf8
            )
        );
        saveAntiPishing = JSON.parse(
            CryptoJS.AES.decrypt(
                serializedSaveAntiPishing,
                "my-secret-key"
            ).toString(CryptoJS.enc.Utf8)
        );
        secureLogin = JSON.parse(
            CryptoJS.AES.decrypt(
                serializedSecureLogin,
                "my-secret-key"
            ).toString(CryptoJS.enc.Utf8)
        );
        disableAccount = JSON.parse(
            CryptoJS.AES.decrypt(
                serializedDisableAccount,
                "my-secret-key"
            ).toString(CryptoJS.enc.Utf8)
        );
        googleRegister = JSON.parse(
            CryptoJS.AES.decrypt(
                serializedGoogleRegister,
                "my-secret-key"
            ).toString(CryptoJS.enc.Utf8)
        );
        changePassword = JSON.parse(
            CryptoJS.AES.decrypt(
                serializedChangePassword,
                "my-secret-key"
            ).toString(CryptoJS.enc.Utf8)
        );
        banks = JSON.parse(
            CryptoJS.AES.decrypt(serializedbanks, "my-secret-key").toString(
                CryptoJS.enc.Utf8
            )
        );
        userUpdate = JSON.parse(
            CryptoJS.AES.decrypt(
                serializedUserUpdate,
                "my-secret-key"
            ).toString(CryptoJS.enc.Utf8)
        );
    } else {
        authUser = JSON.parse(serializedUid);
        favorites = JSON.parse(serializedFavorites);
        myreducer = JSON.parse(serializedMyReducer);
        Newemail = JSON.parse(serializedNewEmail);
        auth = JSON.parse(serializedAuth);
        userInfo = JSON.parse(serializedUserInfo);
        authCheck = JSON.parse(serializedAuthCheck);
        get2FAQR = JSON.parse(serializedGet2FAQR);
        set2FA = JSON.parse(serializedSet2FA);
        user = JSON.parse(serializedUser);
        saveAntiPishing = JSON.parse(serializedSaveAntiPishing);
        secureLogin = JSON.parse(serializedSecureLogin);
        disableAccount = JSON.parse(serializedDisableAccount);
        googleRegister = JSON.parse(serializedGoogleRegister);
        changePassword = JSON.parse(serializedChangePassword);
        banks = JSON.parse(serializedbanks);
        userUpdate = JSON.parse(serializedUserUpdate);
    }

    return {
        authUser,
        favorites,
        myreducer,
        Newemail,
        auth,
        userInfo,
        authCheck,
        get2FAQR,
        set2FA,
        userReducer: user,
        saveAntiPishing,
        secureLogin,
        disableAccount,
        googleRegister,
        changePassword,
        banks,
        userUpdate,
    };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
    rootReducer,
    checkLocalStorage(),
    composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
