import React, { useState } from 'react';
import { AiOutlineFileSearch, AiFillLike, AiFillDislike } from "react-icons/ai";
import { Progress } from 'antd';
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import { IoMdInformationCircle } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";

// Review component to display individual reviews
function Review({ username, date, feedback, type }) {
    return (
        <div className="mb-4">
            <div className="flex py-2 gap-2 items-center">
                <FaUserCircle className="text-[#474D57] text-2xl" />
                <p className="font-semibold">{username}</p>
                <p className="font-[300] text-[#474D57]">{date}</p>
            </div>
            <div className="flex items-center py-2 gap-2">
                {type === 'positive' || type === 'all' ? (
                    <AiFillLike className="text-[#01BC8D] text-sm" />
                ) : (
                    <AiFillDislike className="text-[#FF4D4F] text-sm" />
                )}
                <p className="text-[#474D57] ml-2">{feedback}</p>
            </div>
        </div>
    );
}

function AllReviews() {
    const [isChecked, setIsChecked] = useState(true);

    // Array of positive reviews
    const reviews = [
        { username: 'Buycex trader', date: '2024-09-11', feedback: 'Fast transaction, Safe and trustworthy' },
        { username: 'CryptoBuyer', date: '2024-09-10', feedback: 'Great experience, would use again' },
        { username: 'JaneDoe123', date: '2024-09-09', feedback: 'Reliable service, highly recommended' },
        { username: 'JohnSmith', date: '2024-09-08', feedback: 'Seamless transaction, no issues at all' }
    ];

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    return (
        <div>
            {/* Checkbox and Hidden Feedback Section */}
            <div className="flex gap-2 items-center mb-4">
                <button onClick={toggleCheckbox}>
                    {isChecked ? <MdCheckBoxOutlineBlank /> : <MdCheckBox className="text-[#EFB81C]" />}
                </button>
                <p className="text-[#474D57]">Hidden Feedback</p>
                <IoMdInformationCircle className="text-[#474D57]" />
            </div>

            {/* Render Positive Reviews */}
            {reviews.map((review, index) => (
                <Review
                    key={index}
                    username={review.username}
                    date={review.date}
                    feedback={review.feedback}
                    type="all"
                />
            ))}
        </div>
    );
}
function PositiveReviews() {
    // Same positive reviews as in AllReviews
    const positiveReviews = [
        { username: 'Buycex trader', date: '2024-09-11', feedback: 'Fast transaction, Safe and trustworthy' },
        { username: 'CryptoBuyer', date: '2024-09-10', feedback: 'Great experience, would use again' },
        { username: 'JaneDoe123', date: '2024-09-09', feedback: 'Reliable service, highly recommended' },
        { username: 'JohnSmith', date: '2024-09-08', feedback: 'Seamless transaction, no issues at all' }
    ];

    return (
        <div>
            {positiveReviews.length === 0 ? (
                // Display "No Comments" if there are no reviews
                <div className="h-52 flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                        <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
                        <p className="mt-3 text-[#474D57]">No Comments</p>
                    </div>
                </div>
            ) : (
                // Display positive reviews
                positiveReviews.map((review, index) => (
                    <Review
                        key={index}
                        username={review.username}
                        date={review.date}
                        feedback={review.feedback}
                        type="positive"
                    />
                ))
            )}
        </div>
    );
}

function NegativeReviews() {
    // Array of negative reviews
    const negativeReviews = [
        { username: 'UnhappyUser1', date: '2024-09-07', feedback: 'Slow transaction, took way longer than expected' },
        { username: 'FrustratedBuyer', date: '2024-09-06', feedback: 'Customer service was unhelpful and slow' },
        { username: 'JohnDoeNegative', date: '2024-09-05', feedback: 'Encountered issues with withdrawing funds' },
        { username: 'Anonymous123', date: '2024-09-04', feedback: 'Platform is too complicated for beginners' }
    ];

    return (
        <div>
            {negativeReviews.length === 0 ? (
                // Display "No Comments" if there are no reviews
                <div className="h-52 flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                        <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
                        <p className="mt-3 text-[#474D57]">No Comments</p>
                    </div>
                </div>
            ) : (
                // Display negative reviews
                negativeReviews.map((review, index) => (
                    <Review
                        key={index}
                        username={review.username}
                        date={review.date}
                        feedback={review.feedback}
                        type="negative"
                    />
                ))
            )}
        </div>
    );
}

function Feedback() {
    const [activeTab, setActiveTab] = useState('All');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div>
            {/* Display percentage */}
            <div className='flex items-center gap-12'>
                <div>
                    <div className='flex gap-1'>
                        <p className='text-[24px] font-bold'>98.84</p>
                        <p className='text-[24px] font-bold'>%</p>
                    </div>

                    {/* Display reviews */}
                    <div>
                        <p className='text-[#707A8A] text-[14px]'>346 Reviews</p>
                    </div>
                </div>
                <div className='flex-col w-52'>
                    <div className='flex items-center gap-2'>
                        <AiFillLike className='text-[#01BC8D]' />
                        <Progress percent={98.84} status="active" strokeColor="#01BC8D" />
                    </div>
                    <div className='flex items-center gap-2'>
                        <AiFillDislike className='text-[#F65454]' />
                        <Progress percent={1.16} status="active" strokeColor="#F65454" />
                    </div>
                </div>

            </div>

            {/* Navbar */}
            <div className='flex gap-8 mt-4 border-b h-10 pl-6'>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'All' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('All')}
                >
                    All
                </button>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'Positive' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('Positive')}
                >
                    <div className='flex items-center gap-1'><p className='text-[14px]'>Positive</p><p className='text-[14px]'>(342)</p></div>
                </button>
                <button
                    className={`text-[14px] font-medium ${activeTab === 'Negative' ? 'border-b-2 border-[#EFB81C]' : 'text-[#707A8A]'
                        }`}
                    onClick={() => handleTabClick('Negative')}
                >
                    <div className='flex items-center gap-1'><p className='text-[14px]'>Negative</p><p className='text-[14px]'>(4)</p></div>
                </button>
            </div>

            {/* Content based on active tab */}
            <div className='mt-4'>
                {activeTab === 'All' && <AllReviews />}
                {activeTab === 'Positive' && <PositiveReviews />}
                {activeTab === 'Negative' && <NegativeReviews />}
            </div>
        </div>
    );
}

export default Feedback;
