// store/actions/marketActions.js
import { RepositoryFactory } from "repository/RepositoryFactory";
import { toast } from "react-toastify";
import {
    FETCH_MARKET_DATA,
    FETCH_MARKET_DATA_ERROR,
    FETCH_MARKET_DATA_LOADING,
} from "../types";

const marketRepository = RepositoryFactory.get("market");

export const fetchMarketData = () => async (dispatch) => {
    dispatch(fetchMarketDataLoading(true));
    try {
        const response = await marketRepository.getMarketInfo();
        const { data } = response;
        if (data.status === 1) {
            dispatch({ type: FETCH_MARKET_DATA, payload: data.data });
     
        } else {
            dispatch({ type: FETCH_MARKET_DATA_ERROR, payload: data.detail });
            toast.error(data.detail);
            console.log("error in data.status");
        }
    } catch (error) {
        dispatch({ type: FETCH_MARKET_DATA_ERROR, payload: error.message });
        toast.error("Error fetching market data");
    } finally {
        dispatch(fetchMarketDataLoading(false));
    }
};

export const fetchMarketDataLoading = (val) => {
    return { type: FETCH_MARKET_DATA_LOADING, payload: val };
};
