import React from "react";
import { Select, Input } from "antd";
const SellComponent = ({
    convertedAmount,
    handleReceiveCoinChange,
    p2pfiatCurrencies,
    sellCoins,
}) => {
    return (
        <>
            <div className="bg-[#F3F5F7] flex gap-2 w-full mt-4 rounded-xl p-2">
                <div className="w-[60%] md:w-[60%] lg:w-[60%]">
                    <p className="pl-2">Receive ≈</p>
                    <Input
                        className="text-[20px] md:text-[24px] lg:text-[24px] focus:outline-none focus:border-transparent focus:ring-0 focus:ring-transparent"
                        placeholder="Converted amount"
                        style={{
                            fontWeight: "600",
                            width: "-webkit-fill-available",
                            backgroundColor: "transparent",
                        }}
                        variant="Enter Amount"
                        value={convertedAmount}
                        readOnly
                    />
                </div>
                <Select
                    value={sellCoins}
                    style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: 200,
                        height: "auto",
                    }}
                    variant="borderless"
                    onChange={handleReceiveCoinChange}
                    options={p2pfiatCurrencies?.map((currency) => {
                        return {
                            value: currency?.name,
                            label: (
                                <div className="flex items-center">
                                    <img
                                        src={`https://api.buycex.com/Upload/coin/${currency?.img}`}
                                        alt="BTC"
                                        className="w-6 h-6 mr-2"
                                    />
                                    {currency?.title}
                                </div>
                            ),
                        };
                    })}
                />
            </div>
        </>
    );
};
export default SellComponent;
