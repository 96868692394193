import {
    FETCH_ASSETS_REQUEST,
    FETCH_ASSETS_SUCCESS,
    FETCH_ASSETS_FAILURE
} from '../types';

const initialState = {
    assets: [],
    status: 'idle',
    error: null,
};

const assetReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ASSETS_REQUEST:
            return { ...state, status: 'loading' };
        case FETCH_ASSETS_SUCCESS:
            return { ...state, status: 'succeeded', assets: action.payload };
        case FETCH_ASSETS_FAILURE:
            return { ...state, status: 'failed', error: action.payload };
        default:
            return state;
    }
};

export default assetReducer;
