import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";

import "./DownloadApps.scss";

import QrScannerImage from "./images/buycex-app-qr-scanner.jpg";

import TradingWebView from "./images/start-trade-on-buycex-web-app.png";
import TradingMobileView from "./images/start-trading-on-buycex-mobile-app.png";
import { BsAndroid2, BsApple } from "react-icons/bs";

const images = [
    { src: TradingWebView, width: "80%" },
    { src: TradingMobileView, width: "65%" },
];

const AppSection = () => {
    const history = useHistory();
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 4500);

        return () => clearInterval(interval);
    }, []);

    const handleAppleStoreRedirection = () => {
        history.push("#!");
    };

    const handlePlayStoreRedirection = () => {
        history.push("#!");
    };

    return (
        <section className="download-apps-section border-y">
            <div className="container">
                <div className="wrapper-container flex items-center justify-between gap-8">
                    <div className="title-content-wrap w-[40%] py-10">
                        <div className="title-wrap">
                            <h3 className="text-[34px] font-bold leading-tight mb-2">
                                Make Your Trades <br /> Easier Than Ever
                            </h3>
                            <p className="text-[16px] font-[400]">
                                Trade Whenever, Wherever - Crypto in Your Pocket
                            </p>
                        </div>
                        <div className="download-btn-wrap flex flex-col items-end gap-4 mt-10 w-full">
                            <div className="qr-scanner mr-auto flex items-center gap-4 p-4 border rounded-md overflow-hidden w-[90%]">
                                <img
                                    src={QrScannerImage}
                                    alt="QR Scanner"
                                    className="w-[40%] m-auto"
                                    loading="lazy"
                                    decoding="async"
                                />
                                <p className="text-center text-[16px] font-[500] m-auto">
                                    Scan QR Code to Download <br /> Android and
                                    iOS App
                                </p>
                            </div>
                            <div className="app-buttons flex items-center gap-4 w-[90%] mr-auto">
                                <button
                                    type="button"
                                    onClick={handleAppleStoreRedirection}
                                    className="w-max border rounded-md p-4 flex items-center justify-center gap-2"
                                >
                                    <BsApple size={32} />
                                    <span className="text-[16px]">iOS</span>
                                </button>
                                <button
                                    type="button"
                                    onClick={handlePlayStoreRedirection}
                                    className="w-max border rounded-md p-4 flex items-center justify-center gap-2"
                                >
                                    <BsAndroid2 size={32} />
                                    <span className="text-[16px]">Android</span>
                                </button>
                                <Link
                                    to="/#!"
                                    className="w-full border rounded-md p-5 flex items-center justify-center text-[16px]"
                                    title="More Download Options"
                                >
                                    More Download Options
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="app-ui-slider-wrapper w-[60%]">
                        <div className="slider-wrapper relative h-[600px] overflow-hidden rounded-lg">
                            {images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image.src}
                                    alt=""
                                    className={`absolute block transition-opacity duration-1000 ease-in-out ${
                                        index === currentIndex
                                            ? "opacity-100"
                                            : "opacity-0"
                                    }`}
                                    style={{
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: image.width,
                                    }}
                                    loading="lazy"
                                    decoding="async"
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AppSection;
