import React, { useState, useEffect, useRef } from "react";

const NetworkSelectDropdown = ({ networks, selectedNetwork, setSelectedNetwork }) => {
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredNetworks, setFilteredNetworks] = useState(networks);
    const dropdownRef = useRef(null);

    useEffect(() => {
        // Simulate loading time for network data
        const timer = setTimeout(() => {
            setLoading(false);
        }, 800); // Simulating 800ms loading time

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        // Filter networks based on the search term
        const filtered = networks.filter((network) =>
            network.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredNetworks(filtered);
    }, [searchTerm, networks]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectNetwork = (network) => {
        setSelectedNetwork(network);
        setIsOpen(false);
        setSearchTerm(""); // Reset search term after selection
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        // Attach event listener to handle clicks outside the dropdown
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="relative flex flex-col lg:flex-row gap-6 w-full" ref={dropdownRef}>
            <div className="w-full">
            <label className="block mb-1 mt-6 w-full lg:w-1/5 text-sm text-[#81858c] dark:text-white">
                Select Network
            </label>
                {loading ? (
                    <div className="animate-pulse h-12 rounded-[10px] flex items-center justify-between bg-transparent dark:bg-[#1a202c] border-gray-300 dark:border-gray-600">
                        <div className="flex-grow bg-gray-200 h-6 rounded"></div>
                        <div className="text-black dark:text-white">▼</div>
                    </div>
                ) : (
                    <div className="relative">
                        <div
                            className="h-12 px-4 rounded-[10px] bg-[#f3f5f7] cursor-pointer flex items-center justify-between dark:bg-[#1a202c] border-gray-300 dark:border-gray-600"
                            onClick={toggleDropdown}
                        >
                            <span className="text-black text-[17px] font-semibold dark:text-white">
                                {selectedNetwork ? selectedNetwork.label : "Select Network"}
                            </span>
                            <svg
                                className={`w-3 h-3 transition-transform ${isOpen ? "rotate-180" : ""
                                    }`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </div>
                        {isOpen && (
                            <div className="absolute w-full mt-1 bg-white dark:bg-[#1a202c] border border-gray-300 dark:border-gray-600 rounded-lg z-10">
                                <input
                                    type="text"
                                    className="py-2 ml-3 mr-3 w-[96%] my-2 rounded-[10px] px-2 bg-[#F3F5F7] dark:border-gray-700 dark:bg-gray-800 dark:text-white"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    aria-label="Search Network"
                                />
                                <ul className="max-h-60 overflow-y-auto">
                                    {filteredNetworks.length > 0 ? (
                                        filteredNetworks.map((network) => (
                                            <li
                                                key={network.value}
                                                className="px-4 py-2 text-[14px] cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                                                onClick={() => handleSelectNetwork(network)}
                                            >
                                                {network.label}
                                            </li>
                                        ))
                                    ) : (
                                        <li className="px-4 py-2 text-gray-500 text-center dark:text-gray-400">
                                            No networks found
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default NetworkSelectDropdown;
