import AuthRepository from "./authRepository";

import FiatDepositRepository from "./FiatDepositRepository";
import FiatListRepository from "./FiatListRepository";
import FiatWithdrawRepository from "./FiatWithdrawRepository";
import MarketRepository from "./MarketRepository";
import p2pRepository from "./P2pRepository";
import cryptoConvertRepository from "./CryptoConvertRepository";
import AssetsUserRepository from "./AssetsUserRepository";
import dashboardAssetsData from "./dashboardAssetsData";
import UserRepository from "./UserRepository";
import ChartMarketRepository from "./ChartMarketRepository";
import OrderUserRepository from "./OrderUserRepository";
import idVerificationRepository from "./idVerificationRepository";
import EarnStaking from "./EarnStakingRepository";
import FinanceRepository from "./FinanceRepository";
import OTCRepository from "./OTCRepository";
import CommonRepository from "./commonRepository";

const repositories = {
  auth: AuthRepository,
  market: MarketRepository,
  fiatDeposit: FiatDepositRepository,
  fiatList: FiatListRepository,
  fiatWithdrawal: FiatWithdrawRepository,
  p2p: p2pRepository,
  cryptoConvert: cryptoConvertRepository,
  assetsData : AssetsUserRepository,
  earnStaking : EarnStaking,
  userDashboard : UserRepository,
  chartData : ChartMarketRepository,
  orderData : OrderUserRepository,
  idVerification: idVerificationRepository,
  dashboardAssetsData : dashboardAssetsData,
  finance: FinanceRepository,
  commonRepo: CommonRepository,
  otc: OTCRepository
};
export const RepositoryFactory = {
  get: (name) => repositories[name],
};

export default RepositoryFactory;