import React, { useState } from 'react';
import { IoAttach, IoSend } from 'react-icons/io5';

const P2pChatInput = ({ onSendMessage }) => {
    const [input, setInput] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (input.trim()) {
            onSendMessage({ type: 'text', content: input });
            setInput('');
        }
        if (selectedFile) {
            onSendMessage({ type: 'file', content: selectedFile });
            setSelectedFile(null);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="flex items-center gap-2">
            <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type a message"
                className="w-full p-2 bg-gray-200 rounded-lg"
            />

            {/* Hidden file input */}
            <input
                type="file"
                id="file-input"
                className="hidden"
                onChange={handleFileChange}
            />

            {/* Attachment icon as a label for file input */}
            <label htmlFor="file-input" className="cursor-pointer">
                <IoAttach className="text-2xl text-gray-600 hover:text-gray-800" />
            </label>

            {/* Send icon as a button */}
            <button
                type="submit"
                className="p-2 rounded-lg focus:outline-none"
            >
                <IoSend className="text-2xl text-blue hover:text-gray-700" />
            </button>
        </form>
    );
};

export default P2pChatInput;
