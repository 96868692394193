import React, { useState } from 'react'
import Footer from "components/footer/Footer";
import { FaClipboardList } from "react-icons/fa";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { GiTrophyCup } from "react-icons/gi";
import { AiOutlineSchedule } from "react-icons/ai";
import FAQ from "components/global/FAQ";
import faqsData from 'components/global/faqsData';
//  images for top header 
import Image1 from "assets/img/rewardimage2.png"
import Image2 from "assets/img/rewardimage3.png"
import Image3 from "assets/img/rewardimage4.png"




const RewardsHub = () => {
  const [filterBtns,setFitlerBtns]=useState("My Task");
//  we set buttons by state and update them because latter we integrate differnt data for each button so instead of diffenrt pages render we render tables data for each button on states switching
  const handleFilterBtns=(title)=>{
    setFitlerBtns(title);
  }
  const MainButtons=[
    {
      id:1,
      title:"My Task",
      icon:FaClipboardList,
    },
    {
      id:2,
      title:"My Events",
      icon:HiOutlineSpeakerphone,
    },
    {
      id:3,
      title:"My Rewards",
      icon:GiTrophyCup,
    },
  ];
  return (
    <div>
      {/* top header box */}
      <div className="bg-[#EFB81C] px-3 w-full flex flex-col items-center justify-center gap-4 min-h-60 text-white relative">
          <h1 className="text-3xl font-bold">Reward Hub</h1>
          <p className="font-semibold">Claim rewards by completing tasks and joining events</p>
          {/* images  */}
          <div className="absolute top-4 right-40">
            <img src={Image1} alt="some pic"/>
          </div>
          <div  className="absolute top-16 left-40">
            <img src={Image3} alt="some pic"/>
          </div>
          <div  className="absolute bottom-6 right-96">
            <img src={Image2} alt="some pic"/>
          </div>
      </div>
      {/* main section  */}
      <div className="px-3 mt-12">
        {/* top buttons */}
        <div className=" border text-sm p-1 rounded-lg flex items-center gap-2 w-fit">
          
          {MainButtons.map((data)=>(
            <div onClick={() => handleFilterBtns(data.title)}  key={data.id} className={`${filterBtns===data.title ? "bg-yellow-500 text-white" :""} flex items-center gap-1 px-2 py-2  rounded-lg cursor-pointer hover:bg-yellow-500 hover:text-white font-semibold`}>
            <span><data.icon/></span>
            <span>{data.title}</span>
            </div>
          ))}
        </div>

        {/* main section data for differnt buttons */}
        <div className=" mt-10">
          {/* for my task btn  */}
        {filterBtns === "My Task" && (
          <div>
            <div className="flex items-start gap-4">
              <div className="shadow-lg rounded-2xl p-3 w-1/6 mt-9">
                <h1 className="font-semibold bg-yellow-500 text-white  rounded-lg px-2 py-1 cursor-pointer">Regular Task</h1>
                <h1 className="font-semibold hover:bg-yellow-500 hover:text-white  rounded-lg px-2 py-1 mt-2 cursor-pointer">Task History</h1>
              </div>
              <div  className=" w-5/6 ">
                <h1 className="font-semibold text-lg px-1">Regular Tasks</h1>
                <div className="shadow-lg rounded-2xl p-2 flex items-center justify-center flex-col gap-6 min-h-96 mt-2">

                  <h1 className=""><AiOutlineSchedule className="text-yellow-500 text-6xl" /></h1>
                  <p className="text-[#888888]">You don't have any tasks now. Join a event to claim more rewards!</p>
                  <button className="font semibold text-white bg-yellow-500 px-4 py-2 rounded-xl">Explore Events</button>
                  
                </div>
              </div>
            </div>
          </div>
        )}
        {/* for my events btn */}
        
        {filterBtns === "My Events" && (
          <div>
            <h2 className="text-xl font-bold mt-4">My Events Content</h2>
            <p>This content is displayed when "My Events" is selected.</p>
          </div>
        )}
        {/* for my rewards btn */}

        {filterBtns === "My Rewards" && (
          <div>
            <h2 className="text-xl font-bold mt-4">My Rewards Content</h2>
            <p>This content is displayed when "My Rewards" is selected.</p>
          </div>
        )}
        </div>
       

        {/* faqs section  */}
      <div className="mt-32 ">
        
        <FAQ faqsData={faqsData}/>
        
      </div>
       {/* Rules and terms section  */}
       <div className=" my-10">
        <h1 className="font-semibold text-xl">Rules and Terms</h1>
        <div className="mt-2  rounded-2xl shadow-lg p-4 flex flex-col gap-3 text-sm">
          <p>All tasks and rewards are limited and will be issued based on a first-come, first-served basis.</p>
          <p>Users have to complete Identity Verification Lv. 1 in order to receive the rewards, if any.</p>
          <p>Multi-account registration to farm BuyCex rewards or any other abusive or fraudulent behaviors will result in immediate termination of all associated accounts.</p>
          <p>Please refer to the Latest BuyCex Promotions, Bonus and Coupons FAQ for more information. BuyCex reserves the right of final interpretation of all terms and conditions.</p>
          <p>Trading Vouchers (for Spot) are currently not supported on the Unified Trading Account. By upgrading to a Unified Trading Account, you will forfeit all existing trading vouchers that you hold, and no longer be able to claim any new trading vouchers until further notice.</p>
          <p>Per local laws and regulations, users from regions where Derivatives trading is prohibited (e.g., Brazil, Netherlands, Hong Kong) may not be eligible for rewards associated with Derivatives trades. BuyCex will offer region-specific events and rewards instead. Stay tuned for our latest announcements.</p>
          <p>Your task or progress will be updated within four (4) hours. Refresh the page later for updates.</p>
        </div>

      </div>

        
      </div>
     
     
      {/* footer  */}
      <Footer/>
    </div>
  )
}

export default RewardsHub
