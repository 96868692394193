import React from 'react';
import image20Million from '../../assets/img/Aboutsection4.png'; 

// Project Card Component
const ProjectCard = ({ project }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-4 w-full sm:w-1/2 md:w-1/4">
      <img src={project.image} alt={project.name} className="w-full h-32 object-cover rounded-t-lg" />
      <div className="mt-4">
        <span className="bg-[#EFB81C] text-white text-xs font-semibold px-2 py-1 rounded">{project.badge}</span>
        <span className="ml-2 text-gray-500 text-xs">{project.type}</span>
        <h3 className="text-lg font-semibold mt-2">{project.name}</h3>
        <p className="text-gray-600 text-sm">Fundraised: {project.fundraised}</p>
        <p className="text-gray-600 text-sm">Fundraising Progress: {project.progress}</p>
        <button className="mt-4 w-full bg-gray-200 text-gray-500 text-sm font-bold py-2 px-4 rounded-lg cursor-not-allowed">
          Trading starts soon
        </button>
      </div>
    </div>
  );
};

// Main Component for Project Grid
const ProjectGrid = () => {
  const projects = [
    {
      name: 'Tap Protocol',
      image: image20Million, // Replace with actual image URL
      fundraised: '84,742,420 USDT',
      progress: '282,474.73%',
      badge: 'Airdrop',
      type: 'INITIAL',
    },
    {
      name: 'Infinitar',
      image: image20Million, // Replace with actual image URL
      fundraised: '160,609,200 USDT',
      progress: '321,218.40%',
      badge: 'Airdrop',
      type: 'INITIAL',
    },
    {
      name: 'Baby Neiro',
      image: image20Million, // Replace with actual image URL
      fundraised: '20,375,750 GT',
      progress: '1,834,000.90%',
      badge: 'Airdrop',
      type: 'GT Holders Exclusive',
    },
  
  ];

  const handleMoreClick = () => {
    console.log('More button clicked!'); // Replace with your desired action
  };

  return (
    <div className="p-8">
      {/* Header Section: Flex layout to align Finished (left) and More button (right) */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold">Finished (1439)</h2>

        {/* More button */}
        <button
          onClick={handleMoreClick}
          className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700"
          id="more-button"
        >
          More
        </button>
      </div>
    
      {/* Project Cards Grid */}
      <div className="flex flex-wrap gap-4 justify-center">
        {projects.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </div>
    </div>
  );
};

export default ProjectGrid;
