import FiatListRepository from "repository/FiatListRepository";
import {
    FETCH_FIAT_LIST_FAILURE,
    FETCH_FIAT_LIST_REQUEST,
    FETCH_FIAT_LIST_SUCCESS,
} from "../types";

export const fetchFiatList = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_FIAT_LIST_REQUEST });
    try {
        const response = await FiatListRepository.getFiatList(userId, token);
        const fiatList = response.data.data[0].fiats;
        if (response.data.status === 1) {
            dispatch({ type: FETCH_FIAT_LIST_SUCCESS, payload: fiatList });
        } else {
            dispatch({ type: FETCH_FIAT_LIST_FAILURE, payload: fiatList });
        }
    } catch (error) {
        dispatch({ type: FETCH_FIAT_LIST_FAILURE, payload: error.message });
    }
};
