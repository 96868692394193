import React, { useState } from 'react';
import { IoArrowForwardOutline } from "react-icons/io5";

function Tutorial() {
  const [activeGroup, setActiveGroup] = useState(1);

  const cardGroups = {
    1: [
      { id: '1A', title: 'Card 1A', content: 'How to Use Buycex Futures Grid Bot' },
      { id: '1B', title: 'Card 1B', content: 'How to Make Profits With Buycex Futures Grid Bot' },
      { id: '1C', title: 'Card 1C', content: 'How Does Buycex Incorporate AI in Trading Bots to Maximize Profit?' },
    ],
    2: [
      { id: '2A', title: 'Card 2A', content: 'What Is a Spot Grid Bot, and How Does It Work?' },
      { id: '2B', title: 'Card 2B', content: '10 Reasons Why You Need to Use a Grid Trading Bot' },
      { id: '2C', title: 'Card 2C', content: 'How Does Buycex Incorporate AI in Trading Bots to Maximize Profit?' },
    ],
    3: [
      { id: '3A', title: 'Card 3A', content: 'Learn How to DCA Into Crypto With Buycex’s DCA Trading Bots' },
      { id: '3B', title: 'Card 3B', content: '10 Reasons To Invest in Buycex’s DCA Trading Bots' },
      { id: '3C', title: 'Card 3C', content: 'How Does Buycex Incorporate AI in Trading Bots to Maximize Profit?' },
    ],
  };

  const handleGroupChange = (groupNumber) => {
    setActiveGroup(groupNumber);
  };

  return (
    <div className='container mx-auto p-4'>
      <div className='flex w-full justify-between mb-4'>
        <p className='text-3xl font-semibold'>Tutorial</p>
        <p className='flex items-center gap-2 font-semibold cursor-pointer'>
          View More <IoArrowForwardOutline />
        </p>
      </div>

      <div className='flex gap-4 mb-4'>
        <button 
          onClick={() => handleGroupChange(1)} 
          className={`py-2 px-4 font-semibold text-sm rounded ${activeGroup === 1 ? 'bg-[#F7A6001F] text-[#d6850d]' : 'bg-[#F3F5F7] text-[#80858C]'}`}
        >
          Futures Grid
        </button>
        <button 
          onClick={() => handleGroupChange(2)} 
          className={`py-2 px-4 font-semibold text-sm rounded ${activeGroup === 2 ? 'bg-[#F7A6001F] text-[#d6850d]' : 'bg-[#F3F5F7] text-[#80858C]'}`}
        >
          Spot Grid
        </button>
        <button 
          onClick={() => handleGroupChange(3)} 
          className={`py-2 px-4 font-semibold text-sm rounded ${activeGroup === 3 ? 'bg-[#F7A6001F] text-[#d6850d]' : 'bg-[#F3F5F7] text-[#80858C]'}`}
        >
          DCA Bot
        </button>
      </div>

      <div className='flex mt-6 gap-4'>
        {cardGroups[activeGroup].map((card) => (
          <div 
            key={card.id} 
            className='flex-1 bg-white p-3 rounded-lg border transform transition-all hover:shadow-lg hover:scale-105'
          >
            <h3 className='text-xl font-bold mb-2 h-48 w-full bg-gray-200 rounded-lg hover:bg-gray-300 transition-all'></h3>
            <p className='font-semibold text-lg py-4'>{card.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tutorial;
