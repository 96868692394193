import React, { useState } from "react";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-300">
      <div
        className="flex justify-between items-center py-6 cursor-pointer"
        onClick={toggleOpen}
      >
        <h3 className="text-xl font-medium">{question}</h3>
        <button className="text-blue-500">
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
          )}
        </button>
      </div>
      {isOpen && <p className="text-gray-600 text-lg pb-6">{answer}</p>}
    </div>
  );
};

const FAQ = () => {
  const faqItems = [
    {
      question: "1. What is Startup Task?",
      answer:
        "Startup Task is a special platform where users can complete tasks to earn entries for airdrops...",
    },
    {
      question: "2. How to earn entries?",
      answer:
        "You can earn entries by completing tasks such as following social media, inviting friends, etc.",
    },
    {
      question: "3. How to verify task completion?",
      answer: "Task completion can be verified on the platform dashboard...",
    },
    {
      question: "4. How are winners selected?",
      answer: "Winners are selected randomly from the pool of entries...",
    },
    {
      question: "5. When will the rewards be distributed?",
      answer: "Rewards will be distributed within 7 days after the event ends...",
    },
    {
      question: "6. Can the entries be accumulated to the next event?",
      answer: "Entries cannot be accumulated and will reset after each event.",
    },
  ];

  return (
    <div className="container mx-auto py-12 px-6 max-w-5xl">
      <h2 className="text-4xl font-semibold mb-10 text-center">FAQ</h2>
      {faqItems.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
};

export default FAQ;
