import React, { useState } from "react";
import p2pSymbol from "assets/img/p2p-symbol.png";
import { IoClose } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";

const Popup = ({ show, onClose, onSelectMethod, paymentMethods }) => {
    const [selectedOption, setSelectedOption] = useState(""); // State to track the selected option

    if (!show) return null; // Don't render the popup if 'show' is false

    // const paymentMethods = [
    //     { name: "Bank Transfer", amount: "Rs. 989", rate: "≈ 1 USDT" },
    //     { name: "Jazzcash", amount: "Rs. 989", rate: "≈ 1 USDT" },
    //     {
    //         name: "Transfer with specific bank",
    //         amount: "Rs. 989",
    //         rate: "≈ 1 USDT",
    //     },
    //     { name: "Easypaisa-PK Only", amount: "Rs. 989", rate: "≈ 1 USDT" },
    // ];

    // Handle method selection
    const handleMethodClick = (method) => {
        setSelectedOption(method); // Set the selected option locally in the popup
        onSelectMethod(method); // Notify the parent component of the selected method
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 w-96 md:w-[50%] lg:w-[35%] rounded-lg shadow-lg relative">
                <div className="flex w-full items-start justify-between">
                    <p className="font-bold text-[18px]">
                        Select Payment Method
                    </p>
                    <button onClick={onClose}>
                        <IoClose className="mb-4 text-[25px]" />
                    </button>
                </div>
                <p className="font-bold text-sm mt-4">Recommended</p>

                {paymentMethods.map((method, index) => (
                    <div
                        key={index}
                        onClick={() => handleMethodClick(method.name)}
                    >
                        <div
                            className={`border mt-2 px-4 py-4 rounded-lg cursor-pointer ${
                                selectedOption === method.name
                                    ? "border-yellow-500"
                                    : ""
                            }`}
                        >
                            <div className="flex items-center gap-4">
                                <img
                                    src={p2pSymbol}
                                    className="w-[32px] h-[32px]"
                                    alt="p2p symbol"
                                />
                                <div className="flex justify-between items-center w-full">
                                    <div>
                                        <p className="font-semibold text-md">
                                            {method.name}
                                        </p>
                                    </div>
                                    <div>
                                        <div className="flex items-center gap-2">
                                            <div className="flex flex-col">
                                                <p>{method.amount || "Rs. 989"}</p>
                                            </div>
                                        </div>
                                        <div className="flex justify-end">
                                            <p className="text-[12px] text-[#81858c]">
                                                {method.rate || "≈ 1 USDT"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Render the P2P text under the first item */}
                        {index === 0 && (
                            <div className="pt-3 pb-3">
                                <span className="font-bold text-sm">P2P</span>
                                <span className="text-[12px] ml-1">
                                    Match with sellers in just one click
                                </span>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Popup;
