import React, { useState, useRef, useEffect } from 'react';
import { FaCheckCircle } from "react-icons/fa";
import Dropdown from 'views/trade/MyAds/Dropdown';
import { TbCaretUpDownFilled } from "react-icons/tb";
import NoData from "assets/img/user/No-data.svg";
import DOTSlotAuction from './DOTSlotAuction';
import FlexibleNFTRewards from './FlexibleNFTRewards';
import LockedNFTRewards from './LockedNFTRewards';
import { FaAngleDown } from "react-icons/fa6";

function FixedTerm({ data }) {
    const [dropdownOpen, setDropdownOpen] = useState(false); // State to track dropdown visibility
    const [selectedCoin, setSelectedCoin] = useState('60 day'); // State to track selected coin
    const [activeButton, setActiveButton] = useState('Fixed Term'); // State to track the active button
    const dropdownRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const coins = ['30 day', '60 day', '180 day', '365 day']; // Coin options

    // Function to toggle dropdown
    const toggleDropdown = () => {
        setDropdownOpen(prev => !prev);
    };

    const selectCoin = (coin) => {
        setSelectedCoin(coin);
        setDropdownOpen(false);
    };

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName); // Set the clicked button as active
    };

    // Close dropdown when clicking outside of it
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getButtonClass = (buttonName) => (
        `px-2 py-2 rounded-md font-medium text-sm ${activeButton === buttonName ? 'bg-[#f5f5f5]' : ''}`
    );

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentPageData = data.slice(startIndex, startIndex + itemsPerPage);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className='border p-3 sm:p-6 rounded-xl'>
            <div className='w-full flex justify-between'>
                <div className='flex gap-3'>
                    <button
                        onClick={() => handleButtonClick('Fixed Term')}
                        className={getButtonClass('Fixed Term')}
                    >
                        Fixed Term
                    </button>
                    {/* <button
                        onClick={() => handleButtonClick('DOT Slot Auction')}
                        className={getButtonClass('DOT Slot Auction')}
                    >
                        DOT Slot Auction
                    </button>
                    <button
                        onClick={() => handleButtonClick('Flexible NFT Rewards')}
                        className={getButtonClass('Flexible NFT Rewards')}
                    >
                        Flexible NFT Rewards
                    </button>
                    <button
                        onClick={() => handleButtonClick('Locked NFT Rewards')}
                        className={getButtonClass('Locked NFT Rewards')}
                    >
                        Locked NFT Rewards
                    </button> */}
                </div>

            </div>

            {/* Header rows displayed based on the active button */}
            {activeButton === 'Fixed Term' && (
                <div className='w-full'>
                    {/* Header row */}
                    <div className="w-full overflow-x-auto">
                        <div className='flex min-w-[800px] my-6'>
                            <div className='flex items-center gap-1 w-1/5'>
                                <p className='text-xs text-[#929AA5]'>Coin Name</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex items-center justify-end gap-1 w-1/5'>
                                <p className='text-xs text-[#929AA5]'>Duration</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex items-center justify-end gap-1 w-1/5'>
                                <p className='text-xs text-[#929AA5]'>staking date</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex items-center justify-end gap-1 w-1/5'>
                                <p className='text-xs text-[#929AA5]'>Amount</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex justify-end gap-1 w-1/5'>
                                <p className='text-xs text-[#929AA5]'>End date</p>
                            </div>
                            <div className='flex justify-end gap-1 w-1/5'>
                                <p className='text-xs text-[#929AA5]'>Action</p>
                            </div>
                        </div>

                        {/* Check if there are any filtered coins and display the appropriate message */}
                        {
                            currentPageData.length === 0 ? (
                                <div className='text-center text-md'>
                                    <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                                        <img src={NoData} alt="No Data" />
                                        <p className='text-[#707a8a] text-sm'>No records</p>
                                    </div>
                                </div>
                            ) : (
                                currentPageData.map((data) => (
                                    <div key={data.id} className='flex min-w-[800px] items-center mb-4'>
                                        <div className='flex gap-2 w-1/5'>
                                            <div className='flex items-center gap-2'>
                                                <img

                                                    src={`https://api.buycex.com/Upload/coin/${data.img}`}
                                                    alt=""
                                                    className="w-6 h-6 rounded-full"
                                                />
                                                <div className="flex flex-col">
                                                    <p>{data.coinName}</p>
                                                    <p className="text-[14px] text-[#aaaaaa]">
                                                        {data.coinType}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col gap-1 w-1/5 relative'>
                                            <button className='flex items-center justify-end gap-1'>
                                                <p className='text-md'>60 days</p>
                                            </button>
                                        </div>
                                        <div className='flex flex-col gap-1 w-1/5'>
                                            <p className='text-md text-end font-normal'>25-12-2024</p>
                                        </div>
                                        <div className='flex flex-col gap-1 w-1/5'>
                                            <p className='text-md text-end font-normal'>{data.amount} {data.coinName}</p>
                                        </div>
                                        <div className='flex justify-end gap-2 w-1/5'>
                                            <button className='text-sm flex items-center'>25-02-2025</button>
                                        </div>
                                        <div className='flex justify-end gap-2 w-1/5'>
                                            <button className='text-sm flex items-center px-2 py-1 border rounded-md bg-[#EFB81C]'>Unstack</button>
                                        </div>
                                    </div>
                                ))
                            )
                        }


                    </div>
                </div>
            )}

            {activeButton === 'DOT Slot Auction' && (
                <DOTSlotAuction />
            )}

            {activeButton === 'Flexible NFT Rewards' && (
                <FlexibleNFTRewards />
            )}
            {activeButton === 'Locked NFT Rewards' && (
                <LockedNFTRewards />
            )}
            {/* Pagination controls */}
            {totalPages > 1 && (
                <div className="flex justify-center gap-4 mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-3 py-1 mx-1 text-xs font-semibold border rounded ${currentPage === index + 1 ? 'bg-yellow-500 text-black' : 'bg-gray-100 text-gray-700'}`}
                    >
                        {index + 1}
                    </button>
                ))}
                </div>
            )}
        </div>
    );
}

export default FixedTerm;
