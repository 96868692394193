import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Header from "components/header/Header";
import SideBar from "views/profile/Dashboard/Sidebar";
import HistorySidebar from "views/profile/Dashboard/HistorySidebar";
import EarnSidebar from "views/profile/Dashboard/EarnSidebar";
import { useSelector } from "react-redux";

const User = (props) => {
    const location = useLocation();
    const history = useHistory();
    const path = location.pathname;
    const [showNotification, setShowNotification] = useState(false);

    const isHistoryPage = path.startsWith("/user/history");
    const isEarnOverviewPage = path.startsWith("/user/assets/earn-overview");

    const verified = useSelector(
        (state) => state.authCheck.authStatus.idcardauth
    );

    return (
        <>
            <Header />
            {verified === "0" && !showNotification ? (
                <div className="bg-yellow-200 text-gray-800 px-4 py-2 flex items-center justify-between font-medium text-sm">
                    <div className="flex items-center">
                        <span>
                            Complete Identity Verification to continue using
                            BuyCex services.
                        </span>
                        <div
                            onClick={() => {
                                history.push("/identity-verification");
                            }}
                            className="text-yellow-600 font-semibold ml-2 cursor-pointer"
                        >
                            Verify Now &rarr;
                        </div>
                    </div>
                    <button
                        onClick={() => setShowNotification(true)}
                        className="text-gray-600 hover:text-gray-800"
                    >
                        &times;
                    </button>
                </div>
            ) : null}

            <div className="bg-white z-1 flex flex-col lg:flex-row">
                {isHistoryPage ? (
                    <HistorySidebar className="lg:w-[248px] flex-shrink-0 lg:mr-4">
                        {props.children}
                    </HistorySidebar>
                ) : isEarnOverviewPage ? (
                    <EarnSidebar className="lg:w-[248px] flex-shrink-0 lg:mr-4">
                        {props.children}
                    </EarnSidebar>
                ) : (
                    <SideBar className="lg:w-[248px] flex-shrink-0 lg:mr-4">
                        {props.children}
                    </SideBar>
                )}

                <div className="flex-1 hidden lg:block mt-4 lg:mt-0">
                    {props.children}
                </div>
            </div>
        </>
    );
};

export default User;
