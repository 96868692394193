import React, { useEffect, useState } from "react";

import ReactPaginate from "react-paginate";
import SingleTableRow from "./SingleTableRow";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import fetchEarnStakingData from "store/actions/EarnStaking";

// Example items, to simulate fetching from another resources.
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

const MainTable = () => {
    const [itemOffset, setItemOffset] = useState(0);

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
    dispatch(fetchEarnStakingData(userID, userToken));
    }, [dispatch, userID, userToken, history]);
    const { earnStaking} = useSelector(
        (state) => state.earnStaking
    );
    console.log("earnStaking", earnStaking);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + 10;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = earnStaking.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(earnStaking.length / 10);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * 10) % earnStaking.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };
    return (
        <div
            style={{ boxShadow: "0 4px 24px rgba(0,0,0,.06)" }}
            className="bg-[#fff] rounded-[16px] p-4 earn-overview"
        >
            <div className="flex justify-between">
                <div>
                    <h2 className="text-[#121214] font-semibold text-[24px]">
                        Plans
                    </h2>
                </div>
                <div className="w-[180px] bg-[#f3f5f7] rounded-[100px] h-[32px] text-[#56565b] text-[12px] flex items-center gap-1">
                    <div className="flex items-center justify-center px-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 16 16"
                            class="products_searchIcon__bsyqk"
                        >
                            <path
                                fill="#81858C"
                                fill-rule="evenodd"
                                d="M2 7.25a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0ZM7.25.5a6.75 6.75 0 1 0 4.213 12.024l2.757 2.756a.75.75 0 1 0 1.06-1.06l-2.756-2.757A6.75 6.75 0 0 0 7.25.5Z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </div>
                    <input
                        type="text"
                        className="bg-transparent"
                        placeholder="Search coin name"
                    />
                </div>
            </div>
            {/* header */}
            <div className="p-3 mt-2 flex justify-between gap-4 border-b-[1px] border-solid border-[#f3f5f7]">
                <div className="text-[#81858c] text-[12px] flex-1">Coins</div>
                <div className="text-[#81858c] text-[12px] flex-1">APR</div>
                <div className="text-[#81858c] text-[12px] flex-1 ">
                    Duration
                </div>
                <div className="text-[#81858c] text-[12px] flex-1"></div>
                <div className="text-[#81858c] text-[12px] flex-1 text-right ">
                    Action
                </div>
            </div>
            <SingleTableRow currentItems={earnStaking}/>
            <ReactPaginate
                breakLabel="..."
                nextLabel=" >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={"auto"}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                className="flex gap-2 mt-4 items-center justify-center"
                pageClassName="items"
                previousClassName="items"
                nextClassName="items"
                activeClassName="items active"
            />
        </div>
    );
};

export default MainTable;