import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "store/actions/userProfileAction";
import { Link } from "react-router-dom";

import "./StartTrade.scss";

const UserRedirection = () => {
    const dispatch = useDispatch();

    const { userProfile } = useSelector((state) => state.userInfo);

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const token = useSelector((state) => state.authUser?.user?.TOKEN);

    const username = userProfile?.username;

    useEffect(() => {
        if (userID && token) {
            dispatch(fetchUserProfile(userID, token));
        }
    }, [dispatch, userID, token]);

    const userLoggedIn = userID && token;

    return (
        <section className="user-redirection-section py-8 border-y">
            <div className="container">
                <div className="flex h-full w-full min-h-[35vh]">
                    <div className="user-redirection__wrapper p-4 flex flex-col gap-4 text-center m-auto">
                        <div className="user-redirection__header">
                            <h6 className="text-[34px] font-bold capitalize">
                                {userLoggedIn
                                    ? `Welcome Back, ${username}!`
                                    : "Start Your Trading Journey Today!"}
                            </h6>
                            <p>
                                {userLoggedIn
                                    ? "Good to see you again! Dive back into the markets and start trading now."
                                    : "Sign in to access your account and explore the world of crypto trading."}
                            </p>
                        </div>
                        {userLoggedIn ? (
                            <Link
                                to="/trade/spot/btc_usdt"
                                className="w-max m-auto px-6 py-2 text-[16px] font-[500] text-center rounded-md bg-[#EFB81C]"
                            >
                                Trade Now
                            </Link>
                        ) : (
                            <Link
                                to="/login"
                                className="w-max m-auto px-6 py-2 text-[16px] font-[500] text-center rounded-md bg-[#EFB81C]"
                            >
                                Login
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UserRedirection;
