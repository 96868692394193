import React from 'react'

function TermAndCondition() {
  return (
    <section className='my-10'>
      <div className='container'>
        <h1 className='text-3xl font-bold mb-3 '>Terms and Conditions</h1>
        <p className='text-base  '>
          These terms and conditions (the <b>“Terms”</b> or this <b>“Agreement”</b>) constitute a legally
          binding agreement between you (<b>“you”</b> or <b><b></b></b>“your”) and <b>BUYCEX</b> ( <b>“BUYCEX”</b>, <b>“we”</b> ,
          <b>“our”</b> or <b>“us”</b>). The Terms govern your use of the<b>BUYCEX</b>  Services made available to
          you on or through the Platform or otherwise. <b>BUYCEX</b> Services may be provided by
          <b>BUYCEX</b> or, if specified in these Terms, any Product Terms or any additional terms, by
          any <b>BUYCEX</b> Affiliate. By creating an account, downloading the App, and utilizing the
          Platform, you attest to your agreement to be bound by this Agreement and the related
          <b>Privacy Policy</b>. You must remove the App right away and stop using it as well as the
          Platform if you disagree with these Terms.
        </p>
        <h1 className='text-2xl font-bold mb-3  mt-5'>RISK WARNING</h1>
        <p className='text-base mb-3 '>
          The value of Digital Assets may experience significant fluctuations, posing a substantial
          risk of financial loss for individuals engaging in the buying, selling, holding, or investing
          in such assets. Therefore, taking into account your financial situation, you should decide
          if trading or owning Digital Assets is right for you.
        </p>
        <p className='text-base  mb-2'>
          Further information on the risks associated with using the BUYCEX Services is set out
          in our <b> Risk Warning</b>, which may be updated from time to time. Although you should
          carefully read the Risk Warning, it does not cover all potential dangers or how they
          apply to your particular situation.

        </p>
        <p className='text-base mb-3'>It is important that you fully understand the risks involved before deciding to use
          BUYCEX Services.
        </p>


        <h3 className='text-xl font-bold mb-3'>1. Definitions</h3>


        <p className='text-base mb-3'> 1.1 All capitalized terms shall have the meanings assigned to them in these Terms,
          unless the context dictates otherwise or otherwise defined:</p>
          
        <ol style={{ listStyle: "lower-alpha" }} className='ps-8'>
          <li> The term <b>"Account"</b>  refers to the account created by a User who has downloaded
            the App or accessed the Site and completed the registration process with the Company
            in order to utilize the Site and the Platform.</li>
          <li> <b>"App"</b>   refers to the smartphone app that the Company offers to access the Platform.</li>
          <li>
            An <b> "Authorized Individual"</b>  refers to any individual who has been granted
            authorization to access and utilize the Site (including the App) and Platform on behalf of
            a User.</li>
          <li>
            <b> “Biometric Authentication”</b>  refers to the process of authenticating an identity
            through the use of biometric credentials, such as fingerprints, face recognition, or any
            other biometric data that we may occasionally allow.</li>
          <li> <b>"Digital Assets"</b>  refers to cryptocurrencies such as Bitcoin, Ether, and other virtual
            money or assets.</li>
          <li>
            <b>"Digital Platforms"</b> are third-party distribution systems, such as the Apple App Store
            and Google Play, that allow users to access and download mobile applications and
            other software.</li>
          <li><b>"Governmental Authority"</b>   refers to any country, state, province, or other political
            subdivision thereof; it also includes any agency, department, board, commission, or
            instrumentality of a government; any court, tribunal, or arbitrator; and any self-regulatory
            organization that exercises executive, legislative, judicial, regulatory, or administrative
            functions of or pertaining to government.</li>
          <li> <b> "Material"</b> refers to any information supplied, including offering materials, term
            sheets, market statistics, research reports, and documentation for goods and services.</li>
          <li> <b> "Personal Information"</b> is defined as data provided by a user from which that user's
            identity may be determined, either directly or indirectly.</li>
          <li><b>"Privacy Policy"</b>  refers to the further guidelines that are available at <a href='https://www.buycex.com/terms/privacyPolicy '>https://www.buycex.com/terms/privacyPolicy </a>
            and that control the gathering, use, and
            sharing of each User's Personal Information. To use the App or the Site, each User
            must read the Privacy Policy and accept it.</li>
          <li> <b>"Service Notifications"</b>  are one-way messages sent by the Company by text
            message, email, or, in certain cases, push notification through the Site. These
            notifications may include security-related information. These alerts are provided to the
            user about certain events or information pertaining to an account that the user can
            access via the platform.</li>
          <li>  A <b>"Third-Party </b> Financial Services Provider" is any other entity that has an account
            that may be registered and accessible through the Platform for trading, fiat-crypto
            exchange, or other financial services.</li>
          <li>  A <b>"Third Party Account"</b> is a distinct financial services account that a user opens
            with a Third-Party Services Provider in order to carry out business.</li>
          <li>
            The term <b> "User"</b> refers to any individual who has completed the registration process
            with the Company in order to utilize the Site, access the Platform, and any Authorized
            Individual who is acting on their behalf.</li>
          <li> The <b>“User Identification Policy” </b>refers to the know-your-client policy and
            procedures implemented by the Company periodically in relation to the User's usage of
            the Platform.</li>
          <li> The term <b>"User Credentials"</b>  refers to the collection of user identification, password,
            personal identification number, token, and any other information or device granted to a
            User for accessing the Platform</li>

        </ol>









        <h3 className='text-xl font-bold mb-3'>2. Changes
        </h3>


        <p className='text-base mb-3'> 2.1 We retain the right to:</p>


        <ol style={{ listStyle: "lower-alpha" }} className='ps-8'>
          <li>amend, update, or alter the terms and conditions of this Agreement or our Privacy
            Policy</li>
          <li>modify, update, or change the Site and Platform, including removing or discontinuing
            any content or feature of the Site or Platform; or</li>
          <li>introduce fees, charges, or other conditions for the use of the Platform or parts
            thereof (with reasonable notice) (all of the above referred to as “Changes”).</li>

        </ol>
        <p className='text-base mb-3'>2.2 Apart from what is stated in subsection (c) above, We may implement such
          modifications at any time and without prior notice. Any changes to this agreement will
          be communicated to you via email to the email address linked to your account, push
          notifications via the Site, or postings on our website. Therefore, it is advisable to
          regularly check our website, agree to receiving push notifications from the Site, and
          ensure that your account is updated with your email address and other contact details.
          By continuing to use the Site and Platform after modifications have been made, you are
          consenting to the changes. </p>


        <h3 className='text-xl font-bold mb-3'>3. Digital Platform Terms</h3>


        <p className='text-base mb-3'>3.1 The App may be available for download from one or more Digital Platforms. The
          terms and conditions and privacy policies of the appropriate Digital Platform (the "Digital
          Platform Terms") also apply to your download, installation, access to, and use of the
          App. These Terms shall take precedence over the Digital Platform Terms in the event of
          a dispute.
        </p>
        <p className='text-base mb-3'> 3.2 The App is not connected to or related with any Digital Platform in any manner. It is
          independent of all of them. You and we both understand that this Agreement is made
          exclusively between you and us, not with any Digital Platform, and that, to the extent
          described in this agreement, the app and its contents are completely our responsibility.
        </p>
        <p className='text-base mb-3'> 3.3 You and we both understand and agree that the applicable Digital Platform and its
          subsidiaries are third-party beneficiaries of these Terms. You also understand and agree
          that, in the event that you accept these Terms, Digital Platform will be deemed to have
          accepted the right to enforce these Terms against you on behalf of the other party.
        </p>

        <h3 className='text-xl font-bold mb-3'>4. Network Device and Carrier Requirements</h3>


        <p className='text-base mb-3'>4.1 You are aware that your agreement with your mobile and Internet network provider
          (referred to as the "Network Provider") will be applicable to your usage of the Site. You
          acknowledge that certain features of the Site may incur data service charges from your
          Network Provider or other third-party charges, and you assume full responsibility for
          such charges. If you are not the user of the mobile device or Internet connection
          accessing the Site, it will be assumed that you have obtained authorization from the bill
          payer. Furthermore, it is essential to ensure that your use of the Site complies with any
          agreements you have for cellular data services, mobile devices, or Internet devices. </p>

        <h3 className='text-xl font-bold mb-3'>5. Eligibility and Registration
        </h3>


        <p className='text-base mb-3'> 5.1 You are required to be a minimum of 18 years old in order to access and utilize the Site and
          Platform. Additionally, you confirm that you possess the full capacity and competence to agree
          to the terms, conditions, responsibilities, assertions, representations, and guarantees specified
          in these Terms, and to adhere to and follow these Terms. Registration with the Company is
          necessary to use the Site and the Platform. By registering, you commit to providing accurate,
          comprehensive information and maintaining its accuracy.
        </p>
        <p className='text-base mb-3'>5.2 We retain the authority to grant or deny approval for your Platform registration. Our clientele
          will solely consist of Users whose registrations have been accepted by us </p>

        <h3 className='text-xl font-bold mb-3'>6. Intellectual Property</h3>


        <p className='text-base mb-3'> 6.1 The Site and Platform, along with any information exchanged through them, are the property
          of the Company or its licensors. The Company or its licensors maintain all rights of ownership
          and intellectual property in relation to the Site and Platform, as well as the information regarding
          their use. It should be noted that the Platform does not grant any User a license to use the
          Company's or any third party's ownership or intellectual property rights, except as expressly
          stated here.
        </p>
        <p className='text-base mb-3'>6.2 Through the Platform and App, you may have access to various materials such as quotes,
          images, videos, audio files, and other content (referred to as    <b>"Third-Party Content"</b>) provided
          by specific third parties known as <b> "Third-Party Content Providers."</b> The Company does not
          endorse, recommend, or verify the correctness, validity, or completeness of the  Third-Party
          Content available on the Site or Platform. Your use or reliance on such Third-Party Content is
          solely at your own risk. The respective Third-Party Content Provider retains all ownership, title,
          and intellectual property rights in relation to the Third-Party Content. Unless explicitly stated
          here, nothing on the Platform should be interpreted as granting any User a license to use the
          name, ownership, or intellectual property rights of any Third-Party Content Provider, whether
          through implication, estoppel, or any other means. </p>
        <p className='text-base mb-3'> 6.3 If you adhere to these Terms, you are permitted to download and use the Site on a single
          mobile device and access the Platform with the appropriate User Credentials. The Company
          maintains all other rights to the Site. We have the authority to immediately terminate your
          access to the Site and Platform if you violate these Terms</p>
        <p className='text-base mb-3'>6.4 You must no </p>
        <ol style={{ listStyle: "lower-alpha" }} className='ps-8'>
          <li>try to reverse engineer, decompile, disassemble, or attempt to uncover the source code of
            the Site or Platform; modify, adapt, reproduce, translate, or create derivative works of the Site or
            Platform, or any data or content (including Third-Party Content) provided through the Site or
            Platform, or any part thereof;</li>
          <li> You are prohibited from eliminating any copyright notice, trademark, legend, logo, or product
            identification from the Site or Platform.</li>
          <li> deceive users by using text or images from the website or the company's managed banners
            and/or text links to make other websites appear as the company's website, through search
            engine marketing or any other online and offline campaign, or by infringing the company's
            intellectual property rights in any manner.</li>
          <li>change, modify, filter, truncate, or rearrange the content in any section of the company's
            websites; or remove, hide, or diminish any part of the company's website without authorization;</li>
          <li>utilize the Company's trademark, brand name, logo, or platform for any form of commercial
            benefit.
          </li>

        </ol>
        <p className='text-base mb-3'>6.5 Each User authorizes the Company to utilize all data or content provided by the User or
          handled in connection with the use of the Site and Platform (including Personal Information,
          device information, and geographic data) for the purpose of providing services or products on
          the Platform and guaranteeing the secure use of the Site and Platform. </p>

        <h3 className='text-xl font-bold mb-3'>7. Accoun</h3>


        <p className='text-base mb-3'> 7.1 You must register for an account on the Platform (the "Account") in order to utilize the
          services offered by the Platform. You will use the Account to record different Digital Assets that
          you transfer onto the Platform and to carry out Platform transactions. Any person who is at least
          eighteen years old may register an account, as may an institution through its duly authorized
          representatives; however, both the institution and the individual must have read and
          comprehended the Risk Disclosure Statements, which are incorporated into and will remain a
          part of this Agreement. On the Platform, each User may only register one trading account.
          Multiple trading accounts may be registered in contravention of these terms, which could result
          in the accounts' immediate deletion.</p>
        <p className='text-base mb-3'> 7.2 Neither the Account nor the Digital Assets it contains are bank accounts or other financial
          instruments. No interest will be paid on any money or Digital Assets under your account unless
          the Platform specifically permits otherwise, and none of the Governmental Authorities will insure
          any Digital Assets that we hold directly on your behalf.
        </p>
        <p className='text-base mb-3'>7.3 Digital Assets from your third-party accounts may be transferred into the Account to fund it.
          The Platform does not charge fees for funding the Account; however, other parties, such as your
          bank, can impose transaction fees and other costs. The omnibus user account's address on the
          Platform will get the Digital Assets. After that, the Platform will record the appropriate number of
          Digital Assets in your account on the Platform's ledger.
        </p>
        <p className='text-base mb-3'>7.4 All or a portion of the Digital Assets registered in your name on the Platform's ledger may be
          withdrawn. Maintaining your status as a User does not require you to have a minimum quantity
          of Digital Assets. Digital assets will be moved to the particular Digital Assets address you supply
          using the Platform's omnibus user account. Withdrawals can take up to three (3) days to
          process; however, bigger withdrawals could take up to thirty (30) days, and any withdrawal
          could be postponed in order to comply with the User Identification Policy of the Platform or
          applicable law.
        </p>


        <h3 className='text-xl font-bold mb-3'>8. Trading</h3>


        <p className='text-base mb-3'> 8.1 The Platform is an order-placement and order-matching platform that helps you settle orders
          for the acquisition or sale of digital assets or their derivatives with other users. All that the
          Platform does is match users' buy and sale orders and help users fulfill their intentions as stated
          in the orders. Neither the Platform nor the Company are participating in those transactions as
          other parties or as a principle, unless otherwise specifically stated in this Agreement. It is not the
          responsibility of the Company or the Platform to resolve disagreements among Users over any
          transaction.</p>
        <p className='text-base mb-3'> 8.2 The Platform pairs matching orders automatically using its in-house models and proprietary
          algorithms. The Platform then notifies the appropriate Users that their order has been
          completed. The order is immediately executed and cleared upon the discovery of a match.
          ONLY PUT IN AN ORDER IF YOU ENTIRELY INTEND TO FINISH THE TRANSACTION. By
          starting the processes through your account to close an open order, you have the right to halt a
          preauthorized order.</p>
        <p className='text-base mb-3'> 8.3 Unless the Platform specifically permits differently, you may only sell the quantity of Digital
          Assets that you have in your Account and that are shown in the Platform ledger, less the
          appropriate Transaction Fee (as described below). If you try to sell more Digital Assets than
          what the Platform records indicate are in your account after the relevant Transaction Fee is
          deducted, it will be an unsuccessful trade and could lead to the account being terminated.
        </p>
        <p className='text-base mb-3'> 8.4 You understand that, even before an order is matched or performed, it might not always be
          feasible to change or cancel it. You understand and agree that, in the event that an order cannot
          be canceled or amended, you are obligated to follow the terms of the original order execution.
          We do not take responsibility for making sure that an order is changed or canceled. You also
          understand that trying to change, cancel, and then replace an order could cause over-execution
          or the execution of duplicate orders, and that you will be held accountable for all of those
          executions.</p>
        <p className='text-base mb-3'>8.5 Whether or not you really receive or become aware of such recognition, you will be
          considered to have given orders through the Platform when we acknowledge such orders
          through the Platform or by any other methods we may select. You are aware that all of your
          open orders and pending instructions can be seen on the Platform in real-time. You additionally
          accept that until the Platform acknowledges the full execution, cancellation, or rejection of the
          orders or instructions, you are responsible for keeping an eye on your open orders and pending
          instructions; if you neglect to do so, we disclaim all liability. We reserve the right, in good faith, to
          require you to accept the trade or to remove the trade from your Account at your sole benefit or
          loss if you fail to promptly notify us of any error in the real-time acknowledgment of the status of
          any of your open orders or pending instructions, including the Platform's failure to promptly
          acknowledge the receipt of an order after you transmit such an order. If the activity on your
          account deviates from what we consider to be typical, we may, in some circumstances and at
          our sole discretion, need additional written, verbal, or electronic confirmation before taking any
          further action.
        </p>
        <p className='text-base mb-3'> 8.6 Trading limits that we may set, alter, and notify you of from time to time will apply to your
          orders.</p>
        <p className='text-base mb-3'>8.8 You agree that the price of Digital Assets presented on the Site may be delayed and may
          not accurately reflect the current, live market value of such Digital Assets owing to technical and
          other limitations. However, you acknowledge that the value of your Account and how you utilize
          the Platform and Site are determined by the prices listed on the Site.
        </p>
        <p className='text-base mb-3'> 8.9 You understand and accept that the Platform cannot and does not promise that an order you
          place will be filled at the lowest price that is listed.
        </p>
        <p className='text-base mb-3'> 8.10 Without the parties' approval, we reserve the right to revoke or terminate trades in the
          following circumstances:
        </p>
        <ol style={{ listStyle: "lower-alpha" }} className='ps-8'>
          <li> the trade was the consequence of a discernible hiccup or failure in the system of execution,
            settlement, or communication;</li>
          <li>the trade that the Company deems, at its sole discretion, to be dishonest, deceptive, or
            disruptive to the Platform or other Users;</li>
          <li> If we decide in good faith that canceling the trades will be in the best interests of users or the
            Platform, and the trade was performed by any Account that has been compromised by
            unauthorized users; or</li>
          <li> The Company determines, at its sole discretion, that any trading activity conducted via your
            Account violates these Terms.
          </li>

        </ol>

        <h3 className='text-xl font-bold mb-3'>9. Third-Party Accounts</h3>


        <p className='text-base mb-3'>9.1 One of the Third-Party Services Providers may offer you the opportunity to register and
          create a Third-Party Account. The policies and terms set forth by the Third-Party Services
          Provider for that particular Third-Party Account (the <b>"Third-Party Services Provider Terms"</b> )
          will apply to such Third-Party Account. </p>
        <p className='text-base mb-3'> 9.2 Before opening a Third-Party Account with a Third-Party Services Provider, you should
          carefully read the Third-Party Services Provider Terms. You shouldn't register and open a
          Third-Party Account with it if you disagree with the Third-Party Services Provider Terms. The
          Third-Party Services Provider Terms will govern all trades and other transactions made using
          the Third-Party Account. Furthermore, you acknowledge and concur that:</p>

        <ol style={{ listStyle: "lower-alpha" }} className='ps-8'>
          <li> With regard to the Third-Party Accounts, the Company will only function as the Third-Party
            Service Provider's platform administrator and service provider. Therefore, in order to open the
            Third-Party Account and provide the Platform for transactions made through the Third-Party
            Account, the Company may gather your Personal Information as well as additional data on
            behalf of the Third-Party Services Provider. The Company will handle such Personal Information
            in compliance with its privacy policy, and it will also be shared with the Third-Party Services
            Provider, who will handle it in compliance with its own privacy policy.
          </li>
          <li> The Third-Party Account is not being offered to you by the Company, and the Third-Party
            Account, any transactions made through the Third-Party Account, and any actions or inactions
            on the part of the Third-Party Services Provider with regard to the Third-Party Accounts,
            Third-Party Services Provider Terms, or their handling of your Personal Information are all
            beyond the Company's control and liability. Regarding your Third-Party Account, the Company
            shall not be liable for the transactions made by you or your Authorized Individuals. The
            Company will direct all queries and questions you submit to us about trading activities or other
            services related to the Third-Party Accounts to the Third-Party Services Provider. </li>

        </ol>
        <h3 className='text-xl font-bold mb-3'>10. Fees</h3>


        <p className='text-base mb-3'> 10.1 Downloading the app and registering as a user are free of cost; however, we reserve the
          right to charge for certain in-app purchases and other features as we see fit.
        </p>
        <p className='text-base mb-3'>10.2 You agree to pay a fee on each settled transaction that you initiate (referred to as a
          <b>"Transaction Fee"</b>  ) in exchange for access to the Platform and the Services. Once you connect
          into your Account on the Site, you can view the current Transaction Fee. The Transaction Fee
          may be altered, modified, or increased at any moment and without prior notice by us. Any such
          adjustments, alterations, or increases will take effect as soon as they are posted on the website.
          You should cease using the Account in the manner specified below if you do not agree to the
          posted adjustments, revisions, or increases. All modifications or amendments to the Transaction
          Fee will be deemed accepted by you if you continue to use the Account after they are posted on
          the Site.
        </p>
        <p className='text-base mb-3'>10.3 Deal In every transaction, the trading parties pay the fees. Each trading party shall pay a
          fee in USDT/USDC or any other currency that we may from time to time allow. Any fees
          imposed by third parties in connection with the transfer of Digital Assets into your Platform
          account are your responsibility. When transferring Digital Assets from your Account, the
          Platform charges a fee.
        </p>
        <p className='text-base mb-3'> 10.4 You must tell the Platform right away of any errors you think you may have made in relation
          to the Transaction Fee, as well as any more details you may have about the transaction. You
          shall be presumed to have accepted the fee for all purposes if, within thirty (30) days of the
          alleged incorrect Transaction Fee appearing on any Account statement, you do not object or
          raise any questions.</p>
        <p className='text-base mb-3'>10.5 Transaction and other fees related to your Third-Party Account may be incurred. The terms
          of the third-party service provider outline any such costs. Regarding any fees or other charges
          you could pay in connection with such a Third-Party Account, we bear neither duty nor liability. </p>


        <h3 className='text-xl font-bold mb-3'>11. User Access Obligations</h3>
        <p className='text-base mb-3'> 11.1 Upon registration, the Company will provide each User with a distinct set of User
          Credentials to access the Site and Platform. With these User Credentials, the User's access to
          the Account is limited. Each User must quickly notify the Company that they have received such
          User Credentials.
        </p>
        <p className='text-base mb-3'>11.2 You acknowledge and consent that we are not authorized to provide the Platform or the
          information and services it offers, nor may the information and services be used by, for the
          benefit of, any individual or entity in any jurisdiction in which their provision or use would be
          illegal. In addition, we do not provide services or goods to users in the following excluded
          jurisdictions: the United States, mainland China, Hong Kong, Singapore, Canada, the United
          Kingdom, North Korea, Cuba, Iran, Syrian territory, Russian-controlled areas of Ukraine
          (currently the regions of Crimea, Donetsk, and Luhansk), and any other jurisdiction in which we
          may periodically decide, at our sole discretion, to stop providing services (collectively, the
          <b>"Excluded Jurisdictions"</b>). In the event that you move to one of the Excluded Jurisdictions or
          become aware of any Users who do, you should notify us right away. You understand and agree
          that the Company reserves the right to take any appropriate actions in compliance with this
          restriction or in compliance with the law of a relevant jurisdiction, including immediate account
          termination and liquidation of any open positions, if it is found that you have provided false
          representations about your location or place of residence. We also don't provide services to
          individuals or organizations listed on the EU's Consolidated Financial Sanctions List, the UK
          Sanctions List, the US Treasury Department's List of Specially Designated Nationals or Blocked
          Persons, or any entity owned or controlled (by a person or organization listed on any of these
          lists to the extent that it is 50% or more) by one or more of these entities (collectively,
          <b>"Prohibited Parties"</b>). We also don't provide services that involve or otherwise benefit
          Prohibited Parties. You acknowledge that the Company may take any reasonable steps to
          comply with this restriction or the laws of any relevant jurisdiction; such actions may include
          promptly terminating any account and closing any open positions.
        </p>
        <p className='text-base mb-3'> 11.3Every User needs to:
        </p>

        <ol style={{ listStyle: "lower-alpha" }} className='ps-8'>
          <li> maintain the absolute confidentiality of their User Credentials and refrain from disclosing
            them to any third party for any reason, including but not restricted to starting or carrying out any
            financial transaction pertaining to the Account. Additionally, the User agrees not to reveal his or
            her User Credentials to outside parties on any device in an identifiable manner (e.g., by writing
            down or recording the User Credentials without hiding them);
          </li>
          <li> make every effort to safeguard any information pertaining to his or her user credentials; this
            includes, but is not limited to, storing the information in a physically safe location that is only
            known to the user and in a location where it is unlikely that a third party will access it; </li>
          <li>take all appropriate precautions to adhere to the security guidelines supplied by the Company
            and to safeguard the security in general, preventing tampering or unauthorized use of the User
            Credentials, Site, or Platform, including the security measures outlined in our Privacy Policy;  </li>
          <li>tell the company right away in the following situations via whichever channel the company
            specifies:
            <ol style={{ listStyle: "lower-roman" }} className='ps-8'>
              <li> of your user credentials being lost;</li>
              <li>that there has been any compromise or disclosure of your User Credentials to unidentified
                third parties;
              </li>
              <li>that you have a good basis to believe that your user credentials are being used unlawfully;
              </li>

            </ol>

          </li>
          <li> establish strong PINs and passwords (e.g., by avoiding consecutive digits or basing the PIN
            on the user's password history) utilizing a combination of letters, numbers, and special
            characters and avoiding readily accessible personal information.</li>

        </ol>



        <p className='text-base mb-3'>11.4 The relevant User will be considered to have accessed the Platform if they do so by
          correctly entering their user credentials or by using the App. Any activities taken by an
          Authorized Individual using this access to access the Platform on your behalf are your
          responsibility and liability. The business is under no obligation to look into or take any other
          action to confirm the identity of any User or Authorized Individual. If someone else uses your
          account or user credentials without your permission, the Company will not be responsible for
          any losses you suffer. Upon receipt of notification under Section 11.3(d), the Company will
          promptly disable the relevant User Credentials and restrict access to the Platform or the Site. </p>
        <p className='text-base mb-3'> 11.5Each User is responsible for safeguarding any systems or devices they use to access the
          Platform (such as the App). This includes, but is not limited to, setting up and keeping up to date
          security updates, antivirus, anti-malware, and other pertinent software on the systems or
          devices. Any User who uses the Platform, the Site, and User Credentials must also abide by all
          guidelines, policies, and instructions that the Company may from time to time notify them of.
          These guidelines may include, but are not limited to, risk management and other policies that
          are announced on the Platform login page.
        </p>


        <h3 className='text-xl font-bold mb-3'>12. Prohibited Uses</h3>
        <p className='text-base mb-3'>12.1You must adhere to these Terms when using the Site or Platform, strictly for your own
          Account or internal business purposes. You are prohibited from selling, leasing, or granting
          access to the Site or Platform to any third party, as well as acting as a service bureau, or using
          the Site or Platform on behalf of any third party.
        </p>

        <p className='text-base mb-3'>12.2You are not permitted to use the Site or Platform in any manner, to submit any information
          or content, or to use the Site or Platform in any way that would </p>

        <ol style={{ listStyle: "lower-alpha" }} className='ps-8'>
          <li> is not authorized, unlawful, or illegal; </li>
          <li>is disparaging to any other individual;  </li>
          <li>  is offensive, pornographic, or obscene;</li>
          <li> promotes or advertises any other company or product;  </li>
          <li>  has the potential to bother, agitate, shame, frighten, or irritate anyone else;</li>
          <li>is likely to cause any kind of disruption to the Platform, or to encourage discrimination on the
            basis of age, sex, race, religion, nationality, handicap, or sexual orientation;  </li>
          <li>violates any other person's copyright, trademark, trade secret, or other proprietary right;</li>
          <li>prevents or hinders the use of the Platform by any other person, including, without limitation,
            by "hacking" or defacing any area of the Platform;</li>
          <li>interferes with, modifies, or impairs the Platform's functionality or appearance;</li>
          <li>"frames" or "mirrors" any portion of the Platform without getting our permission in writing
            beforehand;</li>
          <li>makes use of any robot, spider, site search/retrieval software, or other manual or automated
            tool or procedure to download, obtain, index, "data mine," "scrape," "harvest," or in any other
            way replicate or evade the Platform's navigational framework or content display;</li>
          <li> gathers data or harvests information about other users without getting their permission;</li>
          <li>sends spam, chain letters, unsolicited or illegal advertisements to other platform users;</li>
          <li>open multiple accounts unless specifically authorized by the Platform, unless otherwise
            granted in writing by the Company;</li>
          <li>engage in regular, intense trading, whether or not the Platform has allowed the use of
            software or trading instruments;</li>
          <li> sends any content that includes computer viruses or other malicious files, programs, or
            computer code; or</li>
          <li>endoresses, encourages, or supports acts of violence or other illegal activity.</li>

        </ol>

        <p className='text-base mb-3'> 12.3 BUYCEX does not offer services or products to Users in excluded jurisdictions such as
          the United States, mainland China, Hong Kong, Singapore, Canada, the United Kingdom, North
          Korea, Cuba, Iran, Uzbekistan, Russian-controlled regions of Ukraine (currently including the
          Crimea, Donetsk, and Luhansk regions), Syria, and any other jurisdictions that we may choose
          to terminate services at our sole discretion. In the event that you move to one of the Excluded
          Jurisdictions or become aware of any Users who do, you should notify us right away. You
          understand and agree that the Company reserves the right to take any appropriate actions in
          compliance with this restriction or in compliance with the law of a relevant jurisdiction, including
          immediate account termination and liquidation of any open positions, if it is found that you have
          provided false representations about your location or place of residence. We also don't provide
          services to individuals or organizations listed on the EU's Consolidated Financial Sanctions List,
          the UK Sanctions List, the US Treasury Department's List of Specially Designated Nationals or
          Blocked Persons, or any entity owned or controlled (by a person or organization listed on any of
          these lists to the extent that it is 50% or more) by one or more of these entities (collectively,
          "Prohibited Parties"). We also don't provide services that involve or otherwise benefit Prohibited
          Parties. You acknowledge that the Company may take any reasonable steps to comply with this
          restriction or the laws of any relevant jurisdiction; such actions may include promptly terminating
          any account and closing any open positions.</p>



        <h3 className='text-xl font-bold mb-3'>13. Security</h3>

        <p className='text-base mb-3'>13.1 We are free to employ any technology, services, or procedures related to authentication or
          verification that we see fit or acceptable. These precautions could involve using biometric data
          or multi-factor authentication in order to access the platform and app. It cannot be guaranteed
          that the technologies, services, or processes used for authentication will be totally safe,
          sufficient, or effective in preventing hacking or identity theft, as well as unauthorized access to
          or use of the Platform, your Long Bridge Account, or Trading Account.
        </p>
        <p className='text-base mb-3'>13.1 We are free to employ any technology, services, or procedures related to authentication or
          verification that we see fit or acceptable. These precautions could involve using biometric data
          or multi-factor authentication in order to access the platform and app. It cannot be guaranteed
          that the technologies, services, or processes used for authentication will be totally safe,
          sufficient, or effective in preventing hacking or identity theft, as well as unauthorized access to
          or use of the Platform, your Long Bridge Account, or Trading Account.
        </p>
        <p className='text-base mb-3'>13.3 We cannot guarantee the security of all transmissions or any network or system on which
          your Personal Information, account information, or transaction information is stored or
          processed, even though we take reasonable security measures to protect the security and
          confidentiality of the Platform and your Personal Information in compliance with applicable law.
          We shall alert you to any unauthorized access, use, or disclosure of your Personal Information
          that we become aware of, to the extent that is required by law. If you receive such a notice, you
          are in charge of adhering to its directions, which include changing your User Credentials right
          away and taking other precautions to stop illegal access to your account or Personal
          Information.
        </p>

        <h3 className='text-xl font-bold mb-3'>14. Authorized Individuals</h3>
        <p className='text-base mb-3'>14.1 By accepting these Terms, each User and Authorized Individual certifies that they have
          read and understood them. The User acknowledges and represents that, to the extent that it is
          technically possible, any Authorized Individual granted access to the Platform and an Account
          on the User's behalf is duly authorized to (i) access and use the Platform on the User's behalf
          and, if applicable, to exercise the same powers conferred upon the Authorized Individual in
          accordance with any underlying power of attorney; and (ii) that the services provided under the
          Platform are comparable to those the User may obtain through other channels. (ii) consent to
          any modifications made to these terms on the user's behalf; and (iii) apply for or subscribe to
          any services provided by the platform that call for a separate application or membership.
        </p>

        <p className='text-base mb-3'> 14.2 Each User shall ensure that these Terms and, where applicable, the Third-Party Services
          Provider Terms, are known to, accepted, and complied with by each Authorized Individual acting
          on their behalf. When using the Platform and doing any transactions through your Account, you,
          as the designated Authorized Individual, are completely responsible for all actions, inactions,
          and noncompliance.
        </p>
        <p className='text-base mb-3'>14.3Each User agrees to hold the Company, its affiliated subsidiaries and affiliates, officer,
          directors, employees, agents, and representatives fully harmless from any and all liabilities,
          costs, claims, losses, expenses (including reasonable legal fees), and damages resulting from
          or connected to (i) an Authorized Individual's breach of these Terms; and (ii) any claim or action
          brought by their Authorized Individual against the Company </p>
        <p className='text-base mb-3'>14.4 In compliance with these Terms and the Privacy Policy, you represent, undertake, and
          certify that you have obtained the consent of your Authorized Individuals for the collection, use,
          transfer, disclosure, and processing of their Personal Information. </p>

        <h3 className='text-xl font-bold mb-3'>15. Provision of Material and Information</h3>
        <p className='text-base mb-3'>15.1 Each User agrees, by selecting to use the Platform, that: </p>


        <ol style={{ listStyle: "lower-alpha" }} className='ps-8'>
          <li> The Company is not obligated in any way to grant the User's request for Material on any
            goods or services; and </li>
          <li> Any Material, if any, was sent solely for the User and may not be shared further without the
            Company's express written consent. </li>

        </ol>


        <p className='text-base mb-3'>15.2 You understand that the Platform and the Company are not your fiduciaries or investment
          advisers. You further agree that nothing on the Platform that we offer or make available is
          intended to be a suggestion or an invitation for you to engage in any specific transaction, or that
          any specific transaction is right or appropriate for you. </p>
        <p className='text-base mb-3'> 15.3 You understand that we are under no need or responsibility to confirm, amend, finish, or
          update any of the Material that is posted on the Platform. Documents, such as market data,
          price quotes, news, and research, among others, can have been created by information sources
          outside of our control. We make no guarantees on the accuracy, completeness, or timeliness of
          the Material. Before making any investing decisions, you should carry out more investigation
          and analysis or speak with an investment professional. You use the resources at your own risk
          and assume all liability for their use. If we encounter technical issues with accessing the
          Platform, we are not required to notify you.</p>
        <p className='text-base mb-3'>15.4 You can examine information about the balance of your Digital Assets and the account
          status at any time (during downtime) on the Site in electronic format. All past transactions,
          including pending orders, positions, deposits, and withdrawals, can be viewed online for the
          prior year or any other period the company may want to specify at its discretion. Additionally, a
          trade ticket, receipt, or other documentation attesting to a transaction may be sent to you. The
          transaction history should not be interpreted as a valuation in any way. You admit that mistakes
          can happen occasionally and that they have no bearing on the real methods and outcomes of a
          particular transaction. Until we receive a written notice from you to the contrary within three
          calendar days of the communication being sent or posted on the Site, any transaction listed in
          the statement or other communication with you will be considered and treated as authorized,
          correct, approved, and confirmed by you. </p>
        <p className='text-base mb-3'> 15.5 Owing to legislative limitations, some countries' citizens or specific investor categories may
          not be able to purchase or access the content and information about goods and services that is
          displayed on the Platform.
        </p>


        <h3 className='text-xl font-bold mb-3'>16. Service and Other Notifications</h3>
        <p className='text-base mb-3'>16.1 Using service notifications requires sending and receiving messages via unprotected
          networks. To allow us to send you Service Notifications, you must give us your full, correct email
          address or phone number. You undertake to maintain your email address current and to
          promptly tell us of any changes so that you get any correspondence. Any correspondence sent
          to the registered email account is regarded as legitimate. In the event that any email
          correspondence is returned as undeliverable, we reserve the right to prevent you from using the
          Platform until you verify and supply a new email address. If you have given us more than one
          email address or phone number, you must indicate which of those numbers is your preferred
          method of communication in order to receive Service Notifications. If your account is joint, you
          will need to let us know if you would like Service Notifications to go to each joint account holder
          individually or to all of them.
        </p>
        <p className='text-base mb-3'> 16.2 By accepting Service Notifications, you consent to receive updates about the App,
          Platform, your Account, and the Terms. You accept that these Service Notifications will serve as
          valid notice in place of any written, mailed, or other notice that may be needed in accordance
          with relevant legislation.
        </p>
        <p className='text-base mb-3'> 16.3 You bear the exclusive obligation for keeping an eye on the relevant email address or
          phone number, devoid of any additional prompts or repeated notifications from the Company.
          You must report any unauthorized use or access to the Platform right away</p>
        <p className='text-base mb-3'>16.4 To the extent allowed by law, you relieve the Company from any liability for losses or
          damages arising from the use of the Service Notifications. Regarding the accuracy,
          completeness, and timeliness of the information given through Service Notifications, the
          Company makes no guarantees and assumes no duty </p>

        <h3 className='text-xl font-bold mb-3'>17. Personal Information
        </h3>
        <p className='text-base mb-3'>17.1 The Company may gather, use, transmit, disclose, or otherwise treat the User's Personal
          Information as part of the Platform in compliance with the Privacy Policy. Before utilizing the Site
          and Platform and registering, you should carefully read the Privacy Policy. You give permission
          for your Personal Information to be collected, used, and disclosed in line with the Privacy Policy
          and these Terms. This includes, but is not limited to, disclosure to the Third-Party Services
          Provider for the purposes of performing transactions and offering services related to the
          Account.
        </p>
        <p className='text-base mb-3'> 17.2 By providing truthful, accurate, complete, and current personal information, you agree.
          Furthermore, throughout the duration of this Agreement, you promise to maintain and quickly
          update the Personal Information to keep it true, accurate, current, and complete at all times.</p>
        <p className='text-base mb-3'>17.3 You are required to notify us right once of any changes, including but not limited to
          modifications to the Personal Information related to the Platform. We have the right to suspend
          or terminate the Account, deny you access to the Platform and Site for any reason at all, subject
          you to civil liability, or refer you to the relevant law enforcement authorities for criminal
          prosecution if you provide any information that is false, inaccurate, out of date, or incomplete. If
          we, or any of our authorized agents, have reasonable grounds to suspect that such information
          is false, inaccurate, out of date, or incomplete. After such suspension, termination, or incapacity
          to perform, we shall not be obligated to provide any kind of payment or other form of
          compensation.
        </p>
        <p className='text-base mb-3'>17.4 You agree to abide by our reasonable requests for any records, agreements, and
          information pertaining to any transactions involving the use of the Site or Platform. You
          acknowledge that, in accordance with the Privacy Policy, we may report such information to any
          regulatory authorities that we deem appropriate.
        </p>
        <p className='text-base mb-3'>17.5 Please be aware that we may use tracking technologies to gather data about your device,
          including its IP address, network provider, mobile carrier, type of mobile browser, timestamp,
          time zone, and other device-identifying information. The user gives permission for tracking
          technology to be used in this way, and they understand that the information collected—including
          Personal Information—may be compared to public or private data that the company or any
          Third-Party Services Provider has access to. In order to provide and maintain the tracking
          technologies and associated services, the User also agrees to the sharing of such information
          with the service providers of the Company and Third-Party Services Provider. Additionally, we
          might gather exact geolocation information from your device or its surroundings. This
          information could be represented as latitude-longitude coordinates derived from GPS devices,
          WiFi signals, cell tower triangulation, or other methods. Our Privacy Policy details how we utilize
          this information.
        </p>

        <h3 className='text-xl font-bold mb-3'>18. Market Makers</h3>
        <p className='text-base mb-3'> 18.1 We may assign one or more market makers to serve as liquidity providers on the Platform;
          these market makers may or may not be associated with us. You acknowledge and accept that,
          as a result of the services these market makers provide, they might be entitled to terms or
          pricing that are more favorable to you.
        </p>
        <h3 className='text-xl font-bold mb-3'>19. Insurance Fund; Auto-Deleverage (ADL)
        </h3>
        <p className='text-base mb-3'>19.1 In order to offset the disproportionate losses resulting from liquidated positions closed at
          prices worse than bankruptcy, the company keeps an insurance reserve. The remaining margin
          of liquidated positions that are closed at prices lower than bankruptcy is where the insurance
          fund is gathered. The "Daily Insurance Fund Balance" tab of the platform will show the
          insurance fund's current balance. </p>
        <p className='text-base mb-3'> 19.2 You agree, however, that the Platform will automatically deleverage the opposing position
          from the Users with the highest ADL ranking at the bankruptcy price of the liquidated order in
          extreme market conditions, if a position loss during a certain period of time has exhausted the
          insurance fund. In order to make up for other users' margin deficit, the users with the highest
          ADL score will have their winning positions either partially or totally closed, depending on the
          amount of the liquidated position. Your ADL ranking can be available to you on the Platform.
          The Platform determines your ADL rating by looking at the profit ratio of your holdings and your
          leverage. Further information on ADL is available at
          <a href='https://help.buycex.com/'>https://help.buycex.com/</a> https://help.buycex.com/ </p>

        <h3 className='text-xl font-bold mb-3'>20. Disclaimer and Risks of Use of Platform</h3>
        <p className='text-base mb-3'>20.1 The Platform and Site are offered "As Is" and "As Available" at the User's own risk and
          without any representations or guarantees, along with all content (including Third-Party
          Content), features, and any connected services. We make no guarantees on the availability or
          accessibility of the Platform or App for the User at all times. </p>

        <p className='text-base mb-3'>20.2 There are certain risks involved with using the Platform, namely related to downloading,
          installing, or using the Site and any related reference points with third parties (distribution
          platform providers, network providers, and device manufacturers).
        </p>

        <ol style={{ listStyle: "lower-alpha" }} className='ps-8'>
          <li> giving third parties access to your personal information or other information, as well as the
            fact that you have a relationship with the third-party services provider;
          </li>
          <li>  system outages, security-related restrictions and unauthorized removal of use restrictions on
            the end device, and other disturbances which may make use impossible </li>
          <li>   misuse resulting from malware manipulation or unauthorized use, including in the event the
            User's device used to access the Site or the Platform is lost or stolen;</li>

        </ol>

        <p className='text-base mb-3'> Furthermore, you acknowledge that you have received, read, and comprehended any Risk
          Disclosure Statements and that you are fully aware of the risks connected to using the Platform,
          accessing it, and conducting trading activities with the Account.
        </p>

        <p className='text-base mb-3'> 20.3 If the operating system or manufacturer of the end device on which the site is installed has
          modified any security features at any point (such as on a device that has been "jailbroken"), we
          have the right to prohibit or disable access to the site. As a result, we cannot guarantee that the
          App will work and function on older end devices that do not meet the technical requirements for
          using the Site or gaining access to the Platform, or on end devices that have been altered in this
          manner.
        </p>
        <p className='text-base mb-3'>20.4 TO THE FULLEST EXTENT PERMITTED BY LAW, THE SITE AND PLATFORM ARE
          EXCLUDING ALL WARRANTIES, CONDITIONS OR TERMS (WHETHER EXPRESS,
          IMPLIED, STATUTORY OR OTHERWISE), INCLUDING WITHOUT LIMITATION RELATING TO
          QUALITY, MERCHANTABILITY, FITNESS FOR PURPOSE, OR UNINTERRUPTED,
          ERROR-FREE ACCESS. </p>
        <p className='text-base mb-3'>20.5 No representation or warranty, express or implied, can be given as to the accuracy or
          completeness of the information provided in the Platform. </p>
        <p className='text-base mb-3'> 20.6 Each User agrees to the risks associated with conducting Internet transactions via publicly
          accessible open systems. They also understand that, even with data encryption, the connection
          made by the User's computer or mobile device to the Platform over the Internet may be visible
          to third parties. In order to provide any part of the Platform, we may also use servers and other
          computer hardware located in any country in the globe.</p>
        <p className='text-base mb-3'> 20.7 We disclaim all duty for any loss or damage resulting from technical malfunctions,
          breakdowns, business interruptions, or unauthorized access to the User's or any third party's
          computers or IT systems (including those in the public domain).</p>


        <h3 className='text-xl font-bold mb-3'>21. Release
        </h3>
        <p className='text-base mb-3'> 21.1To the maximum extent permitted by law, you hereby release, discharge, and absolve us,
          our parent company, affiliates, subsidiaries, and all of their respective officers, directors,
          shareholders, members, partners, attorneys, employees, independent contractors,
          telecommunications providers, and agents (collectively referred to as the "Indemnified Parties")
          from any and all claims, charges, debts, causes of action, allegations, and losses arising from
          the use of or activities related to the Site, Platform, any Account, and any services or Third-Party
          Content provided through the Site, Platform, or any Account. This includes claims related to
          negligence, gross negligence, intentional interference with contract or advantageous business
          relationship, defamation, privacy, publicity, misrepresentation, false identities, fraudulent acts by
          others, invasion of privacy, release of Personal Information, failed transactions, purchases or
          functionality of the Platform, unavailability of the Site, the Platform, Third-Party Content or any
          Account, technical failures resulting in inaccessibility to the Site, the Platform, Third-Party
          Content or any Account, or any claim based on vicarious liability for torts committed by
          individuals encountered or transacted with through the Site, Platform, Third-Party Content, and
          any Account. Such claims may include fraud, computer hacking, theft or misuse of Personal
          Information, assault, battery, stalking, rape, cheating, perjury, manslaughter, or murder. Please
          note that the above list is not exhaustive and is only intended to provide examples of the types
          of claims released by us. The parties intend for this release to be interpreted broadly in our
          favor, and any ambiguity shall be resolved in a manner that provides the broadest release of
          claims. This release is intended to be comprehensive, and the parties acknowledge the legally
          binding nature of this provision.
        </p>

        <h3 className='text-xl font-bold mb-3'>22. Indemnification and Limitation of Liability</h3>
        <p className='text-base mb-3'> 22.1To the fullest extent permitted by applicable law, you are obligated to protect, compensate,
          and absolve the Indemnified Parties from any and all claims (including those made by third
          parties), actions, losses, liabilities, expenses, costs, or demands, including legal and accounting
          fees, directly or indirectly resulting from your use, misuse, or inability to use the Site, the
          Platform, any Account on the Platform, or any of the content, including Third-Party Content
          contained therein, as well as any content or information you provided to the Platform.
          Additionally, you are responsible for any violation of these Terms or the Third-Party Services
          Provider Terms, including any documents explicitly incorporated into these Terms or the
          Third-Party Services Provider Terms by reference, which are an integral part of these Terms or
          the Third-Party Services Provider Terms.</p>
        <p className='text-base mb-3'> 22.2 We will inform you via email, post, or other suitable methods about any claim or lawsuit,
          and will cooperate reasonably (at your cost) in the defense of said claim or lawsuit. We retain
          the option to join in the defense of such claim or select our own legal representation, although
          we are not required to do so.</p>
        <p className='text-base mb-3'>22.3under no circumstances and under no legal theory (tort, contract, strict liability or otherwise), shall we or any of the indemnities be liable to you or any other person for any damages resulting from the use or misuse of, or inability to use, the platform, the site, third-party content or any account. these damages may be direct, indirect, special, incidental, or consequential damages of any kind, including damages for trading losses, loss of information, business interruption, lost profits, lost savings, or loss of data, or liabilities under any contract, negligence, strict liability, or other theory arising out of or relating in any way to the site, the platform, third-party content or any account, or for any claim or demand by any third party, even if we knew or had reason to know of the possibility of such damages, claim or demand. if the foregoing disclaimer and waiver of liability should be deemed invalid or ineffective, some jurisdictions do not allow the exclusion or limitation of certain warranties and/or liabilities, so certain of the above limitations or exclusions may not apply to you. </p>
        <p className='text-base mb-3'>22.4 Under no circumstances shall our liability, irrespective of the nature of the claim and the
          extent of damages incurred by you, surpass the total fees paid by you to us for utilizing the
          platform or the site, or the sum of 10,000 U.S. dollars, whichever amount is higher.
        </p>
        <p className='text-base mb-3'> 22.5 We shall not be held responsible for any inability to fulfill our obligations under these Terms
          due to circumstances beyond our control. The timeframe for fulfilling said obligations will be
          extended by a duration equal to that of the unforeseen events. Such events include, but are not
          limited to, acts of God, war, riots, embargoes, strikes, equipment failures, natural disasters,
          shortages, government regulations, terrorism, technical issues, and disruptions in services.</p>


        <h3 className='text-xl font-bold mb-3'>23. Suspicion or Termination in Whole or in Part</h3>
        <p className='text-base mb-3'> 23.1 The Platform's access can be suspended or terminated by either the User or us at any time
          as per the Terms. Furthermore, we hold the right to immediately suspend or terminate any
          User's access to the Site and the Platform without notice if they breach any provision of the
          Terms or as outlined in Section 23.2. Your access to the Platform will cease automatically upon
          the closure of your Account. Sections 1, 2, 3, 6, 10-17, and 20-26, along with any claims for
          violation of the Terms, will remain valid even after such termination.</p>
        <p className='text-base mb-3'>We reserve the right to limit, suspend, or terminate the Platform or your Account at any time and
          at our discretion. This may include terminating your Account or certain functionalities such as
          uploading, receiving, sending, and/or withdrawing Digital Assets. We may take these actions if:
        </p>

        <ol style={{ listStyle: "lower-alpha" }} className='ps-8'>
          <li>We believe it is necessary to protect the security of your Account.  </li>
          <li> We deem any transactions to be in breach of this Agreement or the security requirements of
            the Account, or if they are suspicious, unauthorized, or fraudulent, including activities related to
            money laundering, terrorism financing, fraud, or other illegal activities. </li>
          <li>  We suspect that any Digital Assets or funds in your Account may be associated with criminal
            proceeds or are not lawfully possessed by you.</li>
          <li>  The User becomes insolvent, liquidated, wound up, bankrupt, under administration,
            receivership, or dissolved, or if we believe there is a threat of such actions against you. </li>
          <li> We are unable to verify or authenticate the information you provided. </li>
          <li> We believe, at our sole discretion, that your actions may result in legal liability for you, the
            Platform, or other Users. </li>
          <li>  We decide to cease operations or discontinue any services or options provided by the
            Platform, or parts thereof. </li>
          <li> There is a change in your circumstances, including a deterioration or change in your
            financial position, which we consider material to the continuation of your Account. </li>
          <li>  We are directed to do so by a Governmental Authority.</li>
          <li>We are required to do so by applicable law.  </li>
          <li> There is a disruptive market event that triggers a trading halt. </li>
          <li>   We determine, at our sole discretion, that termination or suspension of the Account, the
            Platform, or the Terms is necessary.</li>

        </ol>


        <p className='text-base mb-3'>23.3We are not required to notify you about the reasons behind suspending, terminating, or
          freezing your Account or any digital assets in your Account, or any other actions we may take
          concerning the Site, the Account, or the Platform.
        </p>
        <p className='text-base mb-3'> 23.4 The Company, the Platform, or any third party acting on their behalf shall not be held
          responsible for any suspension, restriction, or termination of your Account or your access to any
          section of the Platform as per the terms of this Agreement.</p>
        <p className='text-base mb-3'> 23.5 If your access to the Platform is terminated by us, you are not allowed to try to regain
          access, whether by using the same or a different username, without obtaining our prior written
          consent.
        </p>
        <p className='text-base mb-3'> 23.6 The Company reserves the right to inform your counterparty of the intended termination if
          there is any active transaction on the Account that is subject to termination procedures.</p>
        <p className='text-base mb-3'> 23.7 The assets, funds, and user data/information are under the full custody of the Company,
          and may be disclosed to Governmental Authorities if your Account is suspended or terminated
          due to fraud investigations, violations of the law, or breaches of these Terms. We shall not be
          held responsible for any loss or damage incurred as a result of delays, transmission errors,
          technical issues, system failures, unauthorized access, or interference in the information and
          services provided, as well as any difficulties or delays in executing orders or transactions
          through any Account. Furthermore, we shall not be liable for any loss or damage resulting from
          delays, technical issues, or interruptions in the availability of the Site, the Platform, or any
          Account (including necessary maintenance work on our systems).</p>


        <h3 className='text-xl font-bold mb-3'>24. Records Conclusive</h3>


        <h3 className='text-xl font-bold mb-3'>24.1 The Company's system will maintain the calculation and records pertaining to the Platform
          and any Account. This includes transaction history and balance on all User Accounts. These
          records will be considered final and conclusive, and will be binding on each User for all
          purposes. Each User acknowledges that these records are admissible in evidence and agrees
          to waive any rights to challenge or dispute their admissibility, reliability, accuracy, or authenticity
          solely on the grounds that they were generated by a computer system or presented in electronic
          form.</h3>

        <h3 className='text-xl font-bold mb-3'>25. General</h3>
        <p className='text-base mb-3'> 25.1These Terms, along with the Privacy Policy and other policies incorporated herein,
          constitute the complete and exclusive agreement between you and the Company regarding the
          subject matter of these Terms. They replace any previous or contemporaneous agreements,
          representations, warranties, and understandings, whether written or oral, regarding the subject
          matter of these Terms. If any provision of these Terms is determined to be unlawful, void, or
          unenforceable for any reason, that provision will be considered separate from these Terms and
          will not affect the validity and enforceability of the remaining provisions. The Company is the
          only entity authorized to change, waive, or modify these Terms, as outlined herein. You are not
          permitted to assign, transfer, delegate, or sublicense these Terms or any rights, obligations, or
          remedies hereunder without our prior written consent. Any attempted assignment, transfer,
          delegation, or sublicense will be invalid. No waiver by any party of any breach or default under
          these Terms will be considered a waiver of any prior or subsequent breach or default. The
          headings, captions, or section titles in these Terms are included for convenience purposes only
          and do not define or explain any section or provision herein.</p>



        <h3 className='text-xl font-bold mb-3'>26. Governing Law and Dispute Resolution</h3>


        <p className='text-base mb-3'> 26.1 The laws of Singapore shall govern these Terms.</p>
        <p className='text-base mb-3'>26.2 In case of any disagreement related to these Terms or the Platform, including any issues
          regarding its validity, existence, or termination, it will be resolved through arbitration in
          Singapore. The arbitration will be conducted in accordance with the Arbitration Rules of the
          Singapore International Arbitration Centre. The arbitration panel will consist of one arbitrator,
          and the proceedings will be conducted in English. The arbitration will take place in Singapore,
          and any final award can be enforced in a court of competent jurisdiction. Both parties are
          required to fulfill their obligations until the arbitral award is issued. </p>


        <h3 className='text-xl font-bold mb-3'>27. Contacting Us</h3>


        <p className='text-base mb-3'>For inquiries regarding these Terms, the Site, or the Platform, please feel free to reach out to the
          Company at support@buycex.com. </p>


      </div>
    </section>
  )
}

export default TermAndCondition
