import React, { useState } from "react";

import {
    BsCurrencyDollar,
    BsEnvelope,
    BsFileEarmarkCheckFill,
    BsGlobe,
    BsSliders,
    BsArrowReturnRight,
} from "react-icons/bs";

const Index = () => {
    const [spotTradingToggle, setSpotTradingToggle] = useState(true);
    const [listingAnnouncementToggle, setListingAnnouncementToggle] =
        useState(true);
    const [latestPromotionsToggle, setLatestPromotionsToggle] = useState(true);
    const [blogsToggle, setBlogsToggle] = useState(true);

    const handleSpotTradingToggle = () => {
        setSpotTradingToggle(!spotTradingToggle);
    };

    const handleListingAnnouncementToggle = () => {
        setListingAnnouncementToggle(!listingAnnouncementToggle);
    };
    const handleLatestPromotionsToggle = () => {
        setLatestPromotionsToggle(!latestPromotionsToggle);
    };
    const handleBlogsToggle = () => {
        setBlogsToggle(!blogsToggle);
    };

    return (
        <div className="w-full">
            <div className="flex items-center gap-2 mb-[35px]">
                <div>
                    <BsSliders size={20} />
                </div>
                <div>
                    <span className="font-semibold text-[22px]">
                        Advancements
                    </span>
                </div>
            </div>

            <div className="flex flex-col gap-3">
                {/* first card */}
                <div className="w-full bg-[#FFFFFF] rounded-md border border-[#F4F4F4]">
                    <div className="flex flex-col gap-4 p-4 w-full">
                        <div className="flex items-center gap-2">
                            <div>
                                <BsFileEarmarkCheckFill size={20} />
                            </div>
                            <div className="flex flex-col">
                                <div>
                                    <span className="text-[14px] font-semibold">
                                        Order Confirmation
                                    </span>
                                </div>
                                <p className="text-[#15141590] text-[12px]">
                                    Enable to receive a pop-up Order
                                    Confirmation with order details when you
                                    place orders.
                                </p>
                            </div>
                        </div>

                        <div className="flex items-center gap-3 py-2 pl-16 w-full">
                            <div>
                                <BsArrowReturnRight
                                    size={18}
                                    fill="#15141540"
                                />
                            </div>
                            <div className="flex items-center justify-between w-full">
                                <div>
                                    <span className="text-[14px] font-medium">
                                        Spot Trading
                                    </span>
                                </div>
                                <button
                                    type="button"
                                    className="flex min-w-[85px]"
                                    onClick={handleSpotTradingToggle}
                                >
                                    <input
                                        type="checkbox"
                                        className="m-auto buycex__toggle-check"
                                        checked={spotTradingToggle}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Second card */}
                <div className="w-full bg-[#FFFFFF] rounded-md border border-[#F4F4F4]">
                    <div className="flex items-center justify-between p-4">
                        <div className="flex items-center gap-2">
                            <div>
                                <BsCurrencyDollar size={20} />
                            </div>
                            <div className="flex flex-col">
                                <div>
                                    <span className="text-[14px] font-semibold">
                                        Currency
                                    </span>
                                </div>
                                <p className="text-[#15141590] text-[12px]">
                                    Select the currency you want to use as
                                    default currency in your account.
                                </p>
                            </div>
                        </div>

                        {/* Currency DropDown */}
                        <div className="cursor-not-allowed text-center font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300">
                            <span className="text-[12px]">USD</span>
                        </div>
                    </div>
                    <div className="flex items-center justify-between p-4">
                        <div className="flex items-center gap-2">
                            <div>
                                <BsGlobe size={20} />
                            </div>
                            <div className="flex flex-col">
                                <div>
                                    <span className="text-[14px] font-semibold">
                                        Language
                                    </span>
                                </div>
                                <p className="text-[#15141590] text-[12px]">
                                    Change the default language for your
                                    account.
                                </p>
                            </div>
                        </div>

                        {/* Language DropDown */}
                        <div className="cursor-not-allowed text-center font-semibold px-[15px] py-2 bg-[#F4F4F4] hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px] transition-all ease-out duration-300">
                            <span className="text-[12px]">English</span>
                        </div>
                    </div>
                </div>

                {/* third card */}
                <div className="w-full bg-[#FFFFFF] rounded-md border ">
                    <div className="flex flex-col gap-4 p-4 w-full">
                        <div className="flex items-center gap-2">
                            <BsEnvelope size={20} />
                            <div>
                                <div>
                                    <span className="text-[14px] font-semibold">
                                        Email Subscriptions
                                    </span>
                                </div>
                                <div className="text-[12px] text-[#15141590]">
                                    Enable to receive email notification in the
                                    following categories.
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center gap-3 py-2 pl-16 w-full">
                            <div>
                                <BsArrowReturnRight
                                    size={18}
                                    fill="#15141540"
                                />
                            </div>
                            <div className="flex items-center justify-between w-full">
                                <div>
                                    <span className="text-[14px] font-medium">
                                        Listing Announcements
                                    </span>
                                </div>
                                <button
                                    type="button"
                                    className="flex min-w-[85px]"
                                    onClick={handleListingAnnouncementToggle}
                                >
                                    <input
                                        type="checkbox"
                                        className="m-auto buycex__toggle-check"
                                        checked={listingAnnouncementToggle}
                                    />
                                </button>
                            </div>
                        </div>

                        <div className="flex items-center gap-3 py-2 pl-16 w-full">
                            <div>
                                <BsArrowReturnRight
                                    size={18}
                                    fill="#15141540"
                                />
                            </div>
                            <div className="flex items-center justify-between w-full">
                                <div>
                                    <span className="text-[14px] font-medium">
                                        Latest Promotions
                                    </span>
                                </div>
                                <button
                                    type="button"
                                    className="flex min-w-[85px]"
                                    onClick={handleLatestPromotionsToggle}
                                >
                                    <input
                                        type="checkbox"
                                        className="m-auto buycex__toggle-check"
                                        checked={latestPromotionsToggle}
                                    />
                                </button>
                            </div>
                        </div>

                        <div className="flex items-center gap-3 py-2 pl-16 w-full">
                            <div>
                                <BsArrowReturnRight
                                    size={18}
                                    fill="#15141540"
                                />
                            </div>
                            <div className="flex items-center justify-between w-full">
                                <div>
                                    <span className="text-[14px] font-medium">
                                        Blog
                                    </span>
                                </div>
                                <button
                                    type="button"
                                    className="flex min-w-[85px]"
                                    onClick={handleBlogsToggle}
                                >
                                    <input
                                        type="checkbox"
                                        className="m-auto buycex__toggle-check"
                                        checked={blogsToggle}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
