import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const CardSlider = ({data}) => {
  return (
    <div className=" premarket-slider-card">
       <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >

          {data && data.map((data)=>(
            <SwiperSlide key={data.id}>
                <div className=" h-[160px] max-w-[480px] w-full p-[24px] flex items-center justify-between gap-8 border-[2px] border-[#EFB81C]  rounded-xl">
                    <div className="flex-1 mr-[24px] mb-[12px]">
                        <h2 className="text-[20px] font-bold flex items-center gap-2 ">{data.name} <span className="text-green-500">{data.coinname}</span> </h2>
                        
                        <p className="text-[#81858c] mt-[1px] text-[14px] font-base">{data.content ? (<>{data.content}</>):(<>A short description about The coin, Its just a dummy text bcz currently this coin have no description.</>)}</p>
                    </div>
                    <div className="w-[64px] h-[64px] md:h-[96px] md:w-[96px]  mb-[12px] ">
                        <img src={`https://api.buycex.com/Upload/issue/${data.image}`} alt="" className="w-full h-full  object-cover " />
                    </div>
                </div>
            </SwiperSlide>
          ))}
{/* use one extra card if from api only 1 card or less than one so to check slider  */}
{data.length <= 1 && (
   <>
    {data && data.map((data)=>(
         <SwiperSlide key={data.id}>
         <div className=" h-[160px] max-w-[480px] w-full p-[24px] flex items-center justify-between gap-8 border-[1px] border-[#EFB81C]  rounded-xl">
             <div className="flex-1 mr-[24px] mb-[12px]">
                 <h2 className="text-[20px] font-bold flex items-center gap-2 ">{data.name} <span className="text-[#81858c]">{data.coinname}</span> </h2>
                 
                 <p className="text-[#81858c] mt-[1px] text-[14px] font-base">{data.content ? (<>{data.content}</>):(<>A short description about The coin, Its just a dummy text bcz currently this coin have no description.</>)}</p>
             </div>
             <div className="w-[64px] h-[64px] md:h-[96px] md:w-[96px]  mb-[12px] ">
                 <img src={`https://api.buycex.com/Upload/issue/${data.image}`} alt="" className="w-full h-full  object-cover " />
             </div>
         </div>
     </SwiperSlide>
      ))}
   </>

)}
          
        
        
      </Swiper>
    </>
    </div>
  )
}

export default CardSlider