import React, { useEffect, useState } from 'react';
import ConvertHistoryPage from './ConvertHistoryPage';
import OpenOrders from './OpenOrders/OpenOrders';
import { useDispatch, useSelector } from 'react-redux';
import { convertTradeHistory } from 'store/actions/commonActions';
const ConvertHistory = () => {
    const [activeItem, setActiveItem] = useState('Convert History');

    const discoverNavItems = ['Convert History', 'Open Orders'];
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);

    const dispatch = useDispatch();
    const { data, loading, error } = useSelector((state) => state.convertTradeHistory);

    useEffect(() => {
        if (userID && userToken) {
            dispatch(convertTradeHistory(userID, userToken));
        }
    }, [dispatch, userID, userToken]);

  
    const renderContent = () => {
        switch (activeItem) {
            case 'Convert History':
                return <ConvertHistoryPage data={data} />;
            case 'Open Orders':
                return < OpenOrders />;
            default:
                return < ConvertHistoryPage />;
        }
    };

    return (
        <div className='px-3'>
            <div className='container mt-5 !p-0'>
                <nav className='flex gap-3 w-[60%] border-gray-200 pt-2'>
                    {discoverNavItems.map((item) => (
                        <button
                            key={item}
                            onClick={() => setActiveItem(item)}
                            className={`relative py-2 px-2 text-md font-semibold ${activeItem === item ? '' : 'text-gray-400'
                                }`}
                        >
                            {item}
                            {activeItem === item && (
                                <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                            )}
                        </button>
                    ))}
                </nav>

                <div className="mt-6">{renderContent()}</div>
            </div>
        </div>
    );
};

export default ConvertHistory;
