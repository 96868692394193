import CryptoConvertRepository from 'repository/CryptoConvertRepository';
import { 
    FETCH_BALANCE_TYPES_REQUEST, 
    FETCH_BALANCE_TYPES_SUCCESS, 
    FETCH_BALANCE_TYPES_FAILURE 
} from '../types';

export const fetchBalanceTypes = (token, id) => async (dispatch) => {
    dispatch({ type: FETCH_BALANCE_TYPES_REQUEST });

    try {
   
        const response = await CryptoConvertRepository.getBalanceTypes(token, id);
  
        console.log("get balnce type",response);
        if (response.data.status === 1) {
         
            dispatch({ type: FETCH_BALANCE_TYPES_SUCCESS, payload: response.data.data });
        } else {
            dispatch({ type: FETCH_BALANCE_TYPES_FAILURE, payload: "Failed to fetch balance types!" });
        }
    } catch (error) {
        dispatch({ type: FETCH_BALANCE_TYPES_FAILURE, payload: error.message });
    }
};
