import React, { useState, useEffect } from "react";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import styled from 'styled-components';
import { IoSearchOutline } from "react-icons/io5";
import { Input } from "antd";

const Dropdown = ({ label, options, onSelect, initialSelected }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedOption, setSelectedOption] = useState(initialSelected);

  // Sync search and selectedOption state with initialSelected prop
  useEffect(() => {
    setSearch("");
    setSelectedOption(initialSelected);
  }, [initialSelected]);

  const filteredOptions = search
    ? options.filter(option => option.toLowerCase().includes(search.toLowerCase()))
    : options;

  const handleToggleDropdown = () => {
    setIsDropdownOpen(prev => !prev);
    if (!isDropdownOpen) {
      setSearch(""); // Reset search when opening
    }
  };

  const handleSelect = (option) => {
    setSelectedOption(option); // Update selected option in state
    onSelect(option);          // Call the onSelect callback
    setIsDropdownOpen(false);
    setSearch(option);         // Set selected option as search
  };

  return (
    <div className="relative min-w-[190px] inline-block text-left">
      <button
        onClick={handleToggleDropdown}
        className="inline-flex border-[0px] border-[#DBDBDB] items-center w-full justify-between gap-x-1.5 rounded-lg px-3 py-3 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <h6 className="text-sm text-[#707A8A]">{label}</h6>
        <div className="flex items-center gap-2">
          <p className="font-medium">{selectedOption}</p> {/* Display selected option */}
          {isDropdownOpen ? (
            <MdOutlineArrowDropUp className="text-[18px] text-[#707A8A]" />
          ) : (
            <MdOutlineArrowDropDown className="text-[18px] text-[#707A8A]" />
          )}
        </div>
      </button>

      {isDropdownOpen && (
        <div className="absolute z-10 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="m-2">
            <StyledInput
              type="text"
              placeholder="Search options"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              prefix={<IoSearchOutline />}
            />
          </div>
          <ul className="max-h-60 overflow-auto">
            {filteredOptions.length > 0 ? (
              filteredOptions.map(option => (
                <li
                  key={option}
                  onClick={() => handleSelect(option)} // Use handleSelect to update state
                  className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                >
                  <h6 className="text-sm text-gray-700">{option}</h6>
                </li>
              ))
            ) : (
              <li className="px-4 py-2 text-[14px] text-center text-gray-500">
                No results found
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

const StyledInput = styled(Input)`
  padding: 0.55rem;
  font-weight: bold;
  color: #707A8A;
  border: 2px solid #DBDBDB;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #EFB81C;
  }

  &:focus, 
  &:focus-within {
    border-color: #EFB81C;
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2);
    outline: none;
  }
`;

export default Dropdown;