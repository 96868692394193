import React from "react";
import Image from "./web2.avif";
import Card from "./Card";
import PopularCard from "./PopularCard";

const WebNetwork = () => {
    return (
        <div className="">
            <div className="px-10 mb-10">
                <div className="container">
                <section className="hero-section border-b flex relative mb-12 "> {/* Added mb-12 and pb-12 */}
  <div className="hero-section__wrapper container flex items-center justify-between gap-8 w-full max-h-[80vh] m-auto max-md:flex-col">
    {/* Left Section */}
    <div className="hero-title-wrapper flex flex-col gap-4 w-[60%] mb-16">
    <h1 className="hero-title flex flex-col gap-2 text-[48px] font-[600] leading-tight">
  <span className="text-[48px] font-[900] text-[#EFB81C]">  Balansol</span>
  <span className="text-[48px]">Your Balancer Pool Model on Solana</span>
  
</h1>
<p className="hero-description -mt-2"> {/* Negative margin to further reduce gap */}


  <span>  Provide a one-stop solution to launch up to 8
                                types of tokens with limited funds while
                                bringing a good boding curve</span>
</p>

      <div className="hero-btn-wrap flex items-center gap-4 mt-2 mb-12">
        <button className="bg-[#EFB81C] text-white py-1 px-4 rounded-md">
        Explore Now
        </button>
       
      </div>
    </div>

     {/* Right Section: Hero Image */}
     <div className="hero-image-wrapper relative w-[40%] min-h-[400px] rounded-xl overflow-hidden">
     
    

<img src={Image} alt="" className="w-full h-full" />
   
    </div>







  </div>


</section>
                    <div className="mt-20 mb-10">
                        <h2 className="text-3xl font-bold">New</h2>
                        <div className="mt-10 flex justify-between gap-4">
                            <div className="flex-1">
                                <Card />
                            </div>
                            <div className="flex-1">
                                <Card />
                            </div>
                            <div className="flex-1">
                                <Card />
                            </div>
                        </div>
                    </div>
                    <div className="mt-20 mb-10">
                        <h2 className="text-3xl font-bold">Popular</h2>
                        {/* first row  */}
                        <div className="mt-10 flex justify-between gap-4">
                            <div className="flex-1">
                                <PopularCard />
                            </div>
                            <div className="flex-1">
                                <PopularCard />
                            </div>
                            <div className="flex-1">
                                <PopularCard />
                            </div>
                        </div>
                        {/* second row  */}
                        <div className="mt-10 flex justify-between gap-4">
                            <div className="flex-1">
                                <PopularCard />
                            </div>
                            <div className="flex-1">
                                <PopularCard />
                            </div>
                            <div className="flex-1">
                                <PopularCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebNetwork;
