import React from "react";
import notification from "assets/img/notification-p2p.svg";

function NotificationSettings() {
    return (
        <div className="border px-6 py-8 rounded-xl">
            <div className="flex items-start lg:items-center md:items-start  gap-4">
                <img
                    src={notification}
                    className="w-8 h-8 md:w-8 md:h-8 lg:w-16 lg:h-16"
                />
                <div className="flex justify-between w-full">
                    <div className="flex flex-col">
                        <p className="text-[16px] font-semibold md:text-[16px] lg:text-[20px]">
                            Sound Notification
                        </p>
                        <p className="text-[#707A8A] text-[14px] md:text-[14px] lg:text-[16px]">
                            Turning off Sound Notifications means you won't hear
                            any sound alerts, such as for new orders, in your
                            browser. We recommend keeping it on for the best
                            experience unless you have a specific reason not to.
                        </p>
                    </div>
                    <label class="inline-flex items-start cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" />
                        <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none  dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#EFB81C]"></div>
                    </label>
                </div>
            </div>
        </div>
    );
}

export default NotificationSettings;
