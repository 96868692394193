import React from 'react'
import  SecureStorage from 'assets/img/user-protection/storage.png';
import  RealTime from 'assets/img/user-protection/real-time.png';
import  OrganizeSecurity from 'assets/img/user-protection/orgsecurity.png';
import  AdvancedData  from 'assets/img/user-protection/ade.png';
 
function SecurityCard() { 

  const securityData = [
    {
        id: 1 ,
        title : "Secure Storage",
        Desc: " Most user funds and assets are securely stored in offline cold storage facilities.",
        img :  SecureStorage,
        
    },
    {
        id: 2 ,
        title : "Live Monitoring",
        Desc: " Our risk management system scrutinizes every withdrawal attempt, password reset, two-factor authentication reset, and email address change. Any unusual activity results in a suspension of withdrawals for a minimum of 24 to 48 hours.",
        img :  RealTime,
    },
    {
        id: 3 ,
        title : "Organizational Security",
        Desc: "Our wallet and personnel infrastructure incorporate advanced security measures, such as multi-signature and threshold signature schemes (TSS), to ensure the safety and integrity of our users' funds.",
        img :  OrganizeSecurity,
    },
    {
        id: 4 ,
        title : "Advanced Data Encryption",
        Desc: "We safeguard user data and personal information, including Know-Your-Customer(KYC) details, by encrypting data at rest. Additionally, end-to-end encryption protects data in transit, so that only users may access their data. ",
        img :  AdvancedData,
    },
]
  return (
    <>
{
  securityData.map((data) => ( 

    <div className="flex items-center gap-3 mt-4"   key={data.id}>
                <div className='w-[150px] h-auto first-letter: overflow-hidden'>
                  <img
                    className=" w-full h-full object-contain "
                    src= {data.img}
                    alt="img"
                  />
                </div>
                <div>
                  <h2 className="text-lg font-semibold ">{data.title}</h2>
                  <p>
                    {data.Desc}
                  </p>
                </div>
              </div> 

  ))
}   
    </>
  )
}

export default SecurityCard

