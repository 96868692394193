import React from 'react';

const ProfileNav = ({ setActiveComponent = () => {}, activeComponent }) => {
  if (typeof setActiveComponent !== 'function') {
    console.error('setActiveComponent is not a function');
    return null;
  }

  const navItems = [
    { name: 'P2P Payment Methods', label: 'P2P Payment Methods' },
    { name: 'Feedback (3)', label: 'Feedback (3)' },
    { name: 'Blocked Users', label: 'Blocked Users' },
    { name: 'Follows', label: 'Follows' },
    { name: 'Restrictions Removal Center', label: 'Restrictions Removal Center' },
    { name: 'Notification Settings', label: 'Notification Settings' }
  ];

  return (
    <ul className="flex gap-3 overflow-x-auto hide-scrollbar mt-5 mb-10" >
      {navItems.map((item) => (
        <li key={item.name}>
          <button
            className={`px-4 py-2 font-semibold text-sm ${
              activeComponent === item.name ? 'bg-[#EFB81C33] text-[#EFB81C] rounded-lg' : 'text-gray-500'
            }`}
            onClick={() => setActiveComponent(item.name)}
            style={{minWidth:'max-content'}}
          >
            {item.label}
          </button>
        </li>
      ))}
          <style>
    {`
    /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
    .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for Firefox */
    .hide-scrollbar {
        scrollbar-width: none;
    }
    `}
  </style>
  </ul>
  );
};

export default ProfileNav;
