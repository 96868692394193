import React from 'react';
import { IoArrowForwardOutline } from "react-icons/io5";
import { BsCashCoin } from "react-icons/bs";
import { Line } from 'react-chartjs-2';
import { Chart, LinearScale, CategoryScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { FiRefreshCcw } from "react-icons/fi";

Chart.register(LinearScale, CategoryScale, PointElement, LineElement, Filler, Tooltip, Legend);

function AuraAIZone() {
    const boxes = [
        { icon: <BsCashCoin className='text-2xl' />, coin: "LINKUSDT", coinDetails: "Future Grid", mutliple: "Long3x", heading: 'Futures Grid', downRatio: '4.87%', VolRatio: '217.87%', HighestAPR: '890.50%', highLowData: [5, 15, 25, 35, 30] },
        { icon: <BsCashCoin className='text-2xl' />, coin: "DODGEUSDT", coinDetails: "Future Grid", mutliple: "Long3x", heading: 'Futures Martingale', downRatio: '12.17%', VolRatio: '217.87%', HighestAPR: '60%', highLowData: [10, 20, 30, 40, 35] },
        { icon: <BsCashCoin className='text-2xl' />, coin: "DOGE/USDT", coinDetails: "Future Grid", mutliple: "Long3x", heading: 'Futures Combo', downRatio: '6.21%', VolRatio: '217.87%', HighestAPR: '250%', highLowData: [8, 15, 22, 28, 25] },
        { icon: <BsCashCoin className='text-2xl' />, coin: "ETHUSDT", coinDetails: "Future Grid", mutliple: "Long3x", heading: 'Spot Grid', downRatio: '1.57%', VolRatio: '217.87%', HighestAPR: '180%', highLowData: [10, 18, 28, 36, 32] },
        { icon: <BsCashCoin className='text-2xl' />, coin: "DODGE/USDT", coinDetails: "Future Grid", mutliple: "Long3x", heading: 'DCA', downRatio: '33.7%', VolRatio: '217.87%', HighestAPR: '732%', highLowData: [5, 12, 20, 25, 22] },
        { icon: <BsCashCoin className='text-2xl' />, coin: "DODGEUSDT", coinDetails: "Future Grid", mutliple: "Long3x", heading: 'DCA', downRatio: '9.97%', VolRatio: '217.87%', HighestAPR: '150%', highLowData: [7, 14, 21, 29, 24] }
    ];

    return (
        <div className='h-screen bg-gradient-to-r from-[#e3e0f7] via-white to-[#e3e0f7]'>
            <div className='container py-12'>
                <div className='flex items-center gap-2'>
                    <p className='text-3xl font-semibold'>Backtest Recommendations</p>
                    <p className='text-3xl font-semibold bg-gradient-to-r from-[#cbbae2] via-white to-[#eabfde] px-3'>Aurora AI Zone</p>
                </div>
                <div className='flex items-center font-medium gap-2 text-[#81858c] my-6'>
                    <p>AI+ Recommendations</p>
                    <p className='flex items-center gap-2 font-medium text-[#EFB81C]'>About Aurora <IoArrowForwardOutline /></p>
                </div>
                <div className='grid grid-cols-3 gap-4 my-8'>
                    {boxes.map((box, index) => (
                        <div key={index} className='bg-white rounded-xl w-full p-6 flex flex-col justify-between'>
                            <div>
                                <div className='flex items-center justify-between w-full'>
                                    <div className='flex items-center gap-2'>
                                        <p className='text-lg font-bold bg-slate-100 p-3 rounded-full'>{box.icon}</p>
                                        <div className='flex flex-col'>
                                            <p className='font-semibold'>{box.coin}</p>
                                            <div className='flex text-xs'>
                                                <p className='border-r pr-2 text-[#81858c]'>{box.coinDetails}</p>
                                                <p className='pl-2 text-[#EFB81C]'>{box.mutliple}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <IoArrowForwardOutline className='text-xl' />
                                </div>
                                <div className='flex w-full justify-between'>
                                    <div className='w-1/2'>
                                        <p className='text-md text-[#81858c] mt-3'>APR</p>
                                        <p className='text-xl font-bold text-[#20b26c]'>{box.HighestAPR}</p>
                                    </div>
                                    {/* Line chart for high and low values */}
                                    <div className='mt-4 w-1/2'>
                                        <Line
                                            data={{
                                                labels: box.highLowData.map((_, i) => `${i + 1}D`),
                                                datasets: [
                                                    {
                                                        data: box.highLowData,
                                                        borderColor: '#20b26c',
                                                        backgroundColor: 'rgba(32, 178, 108, 0.2)',
                                                        fill: true,
                                                        tension: 0.3,
                                                    },
                                                ],
                                            }}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                plugins: {
                                                    legend: { display: false },
                                                    tooltip: { enabled: false },
                                                },
                                                scales: {
                                                    x: { display: false },
                                                    y: { display: false },
                                                },
                                            }}
                                            height={50}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex gap-3 mt-4 items-center justify-between w-full'>
                                <div className='flex gap-3'>
                                    <div className='flex flex-col gap-1'>
                                        <p className='text-xs text-[#81858c]'>Drawdown Ratio</p>
                                        <p className='font-medium text-sm'>{box.downRatio}</p>
                                    </div>
                                    <div className='flex flex-col gap-1'>
                                        <p className='text-xs text-[#81858c]'>Volatility Ratio</p>
                                        <p className='font-medium text-sm'>{box.VolRatio}</p>
                                    </div>
                                </div>
                                <button className="px-3 py-2 border font-medium rounded-md">Use</button>
                            </div>
                        </div>
                    ))}
                </div>
                <p className='text-center flex items-center gap-2 justify-center text-sm font-semibold'><FiRefreshCcw/>Refresh</p>

            </div>
        </div>
    );
}

export default AuraAIZone;
