import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
const Notes = () => {
  return (
    <div className="flex-[1] p-4 border rounded shadow-sm bg-white">
      <h3 className="text-lg font-medium text-gray-900">Notes</h3>
      <ol className="list-decimal list-inside mt-2">
        <li>We will assist you to retrieve your assets according to the information you submit, please fill in carefully.</li>
        <li>If your withdrawal is still being processed or failed on the counter-party’s platform, and these assets are not yet credited to CoinEx, please contact the withdrawal platform for assistance.</li>
      </ol>
      <h4 className="mt-4 text-md font-medium text-gray-900">Retrieving Procedures</h4>
      <ol className="list-none list-inside mt-2">
        <li className="relative">
          <span className="inline-block w-6 h-6 text-center leading-6 rounded-full bg-yellow-300 text-yellow-700">1</span>
          <span className="ml-8">Submit Application</span>
        </li>
        <li className="relative mt-2">
          <span className="inline-block w-6 h-6 text-center leading-6 rounded-full bg-yellow-300 text-yellow-700">2</span>
          <span className="ml-8">Manual Audit</span>
        </li>
        <li className="relative mt-2">
          <span className="inline-block w-6 h-6 text-center leading-6 rounded-full bg-yellow-300 text-yellow-700">3</span>
          <span className="ml-8">Fund Processing</span>
        </li>
      </ol>
      <ul className="mt-4 space-y-2">
        <li className="flex items-center">
          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2 text-yellow-500" />
          How To Write Application?
        </li>
        <li className="flex items-center">
          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2 text-yellow-500" />
          Submit Ticket?
        </li>
      </ul>
    </div>
  );
};  

export default Notes;
