import React, { useState } from 'react';
import toast from "react-hot-toast";
import { useSelector } from 'react-redux';
import { fetchAuthStatus } from 'store/actions/userSecuritySettingsAction';

const SetPassword = ({ nextStep,handleExistingPassword }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const userID = useSelector((state) => state.authUser?.user?.ID);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (newPassword !== confirmPassword) {
      toast.error("New Password and Confirm Password do not match");
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('password', newPassword);
      formData.append('repassword', confirmPassword);
      formData.append('user_id', userID);
  
      const response = await fetch('https://api.buycex.com/Login/findpaypwd', {
        method: 'POST',
        body: formData,
      });
  
      const data = await response.json(); // Parse the JSON response
  
      if (data.status === 1) {
        toast.success(data.data || 'Password set successfully'); // Show success message from data
        handleExistingPassword(); // Set a check for existing password
        nextStep(); // Proceed to the next step
      } else if (data.status === 0) {
        toast.error(`Error setting password: ${data.data || 'Unknown error'}`); // Show error message from data
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error setting password. Please check the console for more details.'); // General error message
    }
  };  

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <h1 className="font-semibold mt-4">New Password</h1>
          <div className="rounded-md w-full bg-gray-100 bg-opacity-70 mt-1 py-2 px-3">
            <input
              type="password"
              className="outline-none bg-transparent w-full"
              placeholder="Please Enter 6 digits"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <h1 className="font-semibold mt-2">Confirm Password</h1>
          <div className="rounded-md w-full bg-gray-100 bg-opacity-70 mt-1 py-2 px-3">
            <input
              type="password"
              className="outline-none bg-transparent w-full"
              placeholder="Please Enter 6 digits"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mt-4 text-center">
          <button
            type="submit"
            className="w-full bg-yellow-500 font-semibold text-white py-2 rounded-md"
          >
            Confirm
          </button>
        </div>
      </form>
    </div>
  );
};

export default SetPassword;