import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const categories = [
  { name: 'ALL', path: 'all' },
  { name: 'New Listings', path: 'new-listings' },
  { name: 'Latest Activities', path: 'latest-activities' },
  { name: 'Latest Buycex News', path: 'latest-buycex-news' },
  { name: 'Partnership Announcement', path: 'partnership-announcement' },
  { name: 'Web3', path: 'web3' },
  { name: 'Maintenance Updates', path: 'maintenance-updates' },
  { name: 'Delistings', path: 'delistings' },
  { name: 'More', path: 'more' }
];

const CategorySidebar = ({ onCategorySelect }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentCategory = queryParams.get('category') || 'all';

  return (
    <div className="w-full lg:w-1/4 lg:p-4 lg:border-r pr-4">
      <nav className="overflow-x-auto">
        <ul className="flex whitespace-nowrap lg:flex-col">
          {categories.map((category, index) => (
            <li
              key={index}
              className={`lg:mb-2 m-1 ${
                currentCategory === category.path ? ' dark:bg-gray-800 bg-gray-300 border-b-4 border-yellow-500 lg:border-b-0 lg:border-l-4 font-bold' : ''
              }`}
            >
              <Link to={`/announcements?category=${category.path}&page=1`} className="block text-base hover:text-yellow-500 p-3 lg:p-2 lg:w-full  hover:no-underline" onClick={() => onCategorySelect(category.path)}>
                {category.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default CategorySidebar;
