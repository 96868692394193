// src/components/LoginStatusManagement.js
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "store/actions/authAction";

const LoginStatusManagement = () => {
    const [loginStatus, setLoginStatus] = useState([]);
    const dispatch = useDispatch();
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    const history = useHistory();
    useEffect(() => {
        // Retrieve login status data from sessionStorage on component mount
        const storedLoginStatus =
            JSON.parse(sessionStorage.getItem("loginStatus")) || [];
        setLoginStatus(storedLoginStatus);
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        dispatch(logout());
        history.push("/login");
    };

    return (
        <div className="bg-white rounded-md border border-[#F4F4F4] p-4 sm:p-6 lg:p-8">
            <div className="font-semibold text-[16px] mb-6">
                Login Status Management
            </div>
            <div className="account-overview__login-status-table__wrapper">
                <table className="login-status-table w-full">
                    <thead className="login-status-table__header">
                        <tr>
                            <th scope="col" className="w-1/6">
                                Time
                            </th>
                            <th scope="col" className="w-[25%]">
                                Device
                            </th>
                            <th scope="col" className="w-[25%]">
                                IP
                            </th>
                            <th scope="col" className="w-1/6">
                                Location
                            </th>
                            <th scope="col" className="w-1/6">
                                Operation
                            </th>
                        </tr>
                    </thead>
                    <tbody className="login-status-table__body">
                        {loginStatus.length > 0 ? (
                            loginStatus.slice(0, 5).map((item, index) => (
                                <tr key={index}>
                                    <td className="max-w-1/6">
                                        {new Date(
                                            item.timestamp
                                        ).toLocaleString()}
                                    </td>
                                    <td className="max-w-[25%]">
                                        {item.device}|{item.os}|{item.browser}
                                    </td>
                                    <td className="max-w-[25%]">{item.ip}</td>
                                    <td className="max-w-1/6">
                                        {item.location || "Unknown"}
                                    </td>
                                    <td className="max-w-1/6">
                                        <span
                                            className="text-yellow-400"
                                            onClick={handleLogout}
                                        >
                                            Log Out
                                        </span>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan="5"
                                    className="text-center text-sm p-4"
                                >
                                    No login status available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default LoginStatusManagement;
