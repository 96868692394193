import React, { useState } from 'react';
import USDM from './USDM';
import CoinM from './CoinM';
import HistoryDropdown from '../../SpotHistory/DropDownUI';
import { DatePicker } from 'antd';
import { createGlobalStyle, styled } from "styled-components";

const { RangePicker } = DatePicker;

const TransactionHistory = () => {
    const [activeSubItem, setActiveSubItem] = useState('USDⓈ-M');
    const subNavItems = ['USDⓈ-M', 'COIN-M'];

    const options2 = ["All", "USDT", "FDUSD", "BTC", "BNB", "ETH", "USDC"];
    const options3 = ["All", "BTC", "APT", "SAND", "LTC", "DODGE"];
    const options4 = ["All", "Transfer", "Realized PNL", "Commision", "Funding Fee", "Liquidation Clearance", "Auto Conversion", "Swap"]
    const handleSelect1 = (selected) => {
        console.log("Dropdown 1 selected:", selected);
    };

    const renderSubContent = () => {
        if (activeSubItem === 'COIN-M') {
            return (
                <>
                    <CoinM />
                </>
            );
        }

        switch (activeSubItem) {
            case 'USDⓈ-M':
                return <USDM />;
            default:
                return <USDM />;
        }
    };

    return (
        <div className='px-3'>
            <div className='container mt-5 !p-0'>
                <div className='flex gap-4 flex-wrap items-center'>
                    <nav className='inline-flex flex-wrap border rounded-lg pt-[0.20rem] pb-[0.25rem] px-1 gap-2'>
                        {subNavItems.map((item) => (
                            <button
                                key={item}
                                onClick={() => setActiveSubItem(item)}
                                className={`w-auto py-1.5 px-3 text-sm font-medium ${activeSubItem === item ? 'bg-[#F5F5F5] rounded-md' : 'text-gray-400'
                                    }`}
                            >
                                {item}
                            </button>
                        ))}
                    </nav>


                    <GlobalStyles />
                    <StyledRangePicker className="px-2 w-[200px] h-[40px] bg-white py-[0.55rem] border-l" />

                    <HistoryDropdown
                        label="Asset"
                        options={activeSubItem === 'COIN-M' ? options3 : options2}
                        initialSelected="All"
                        onSelect={handleSelect1}
                        isSearchable={false}
                        width="100px"
                    />
                    <HistoryDropdown
                        label="Type"
                        options={options4}
                        initialSelected="All"
                        onSelect={handleSelect1}
                        isSearchable={false}
                        width="100px"
                    />
                    <button className='bg-[#EFB81C] px-4 py-2 rounded-lg font-medium text-sm'>Search</button>
                    <button className='font-medium text-sm'>Reset</button>
                </div>

                <div className="mt-6">{renderSubContent()}</div>
            </div>
        </div>
    );
};

const GlobalStyles = createGlobalStyle`
  // Override hover state for picker
  .ant-picker:hover {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on hover */
  }

  // Override focus state for picker
  .ant-picker-focused {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on focus */
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important; /* Add shadow with color #EFB81C */
  }

  // Style for the active state bar in RangePicker
  .ant-picker-active-bar {
    background: #EFB81C !important; /* Change the active border bar color to #EFB81C */
  }

  // Style calendar cells with selected date
  .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EFB81C !important; /* Change the background color of selected date */
    color: white !important; /* Keep the text color white for contrast */
  }

  // Style for "Today" cell in the calendar dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    border: 1px solid #EFB81C !important; /* Change border color to #EFB81C for the "Today" cell */
    border-radius: 4px;
    content: "";
  }
    // Change color of calendar header buttons on hover
  .ant-picker-dropdown .ant-picker-header-view > button:hover {
    color: #EFB81C !important; /* Change hover color to #EFB81C */
  }
`;

const StyledRangePicker = styled(RangePicker)`
  // Custom styling for RangePicker input border when focused
  .ant-picker-input > input:focus {
    box-shadow: none !important; /* Remove any focus box-shadow */
    border-color: #EFB81C !important; /* Ensure border stays #EFB81C */
  }
`;


export default TransactionHistory;
