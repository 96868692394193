import React, { useState } from 'react';
import CoinSelectWithdrawModal from './CoinSelectWithdrawModal';
import { Input } from 'antd';
import { MdOutlineBrightnessHigh } from "react-icons/md";
import NetworkSelectWithdrawModal from './NetworkSelectWithdrawModal';
import { Radio } from "antd";
import styled from "styled-components";
import { FiRefreshCcw } from "react-icons/fi";

const dummyCoins = [
  { value: "btc", label: "Bitcoin", icon: "https://cryptoicons.org/api/icon/btc/32" },
  { value: "eth", label: "Ethereum", icon: "https://cryptoicons.org/api/icon/eth/32" },
  { value: "ltc", label: "Litecoin", icon: "https://cryptoicons.org/api/icon/ltc/32" },
  { value: "xrp", label: "Ripple", icon: "https://cryptoicons.org/api/icon/xrp/32" },
];

const dummyNetworks = [
  { value: "erc20", label: "Ethereum (ERC20)" },
  { value: "bep20", label: "Binance Smart Chain (BEP20)" },
  { value: "trc20", label: "Tron (TRC20)" },
  { value: "sol", label: "Solana (SOL)" },
  { value: "polygon", label: "Polygon (MATIC)" },
  { value: "avalanche", label: "Avalanche (AVAX)" },
];


function Address({ onClose }) {
  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [isWalletButtonActive, setIsWalletButtonActive] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleImageClick = (coin) => {
    console.log(`Clicked on ${coin.label}`);
  };

  const toggleWalletButton = () => {
    setIsWalletButtonActive(!isWalletButtonActive);
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row w-full gap-6 justify-between">
        <div className="w-full mt-5">
          <CoinSelectWithdrawModal
            coins={dummyCoins}
            selectedCrypto={selectedCrypto}
            setSelectedCrypto={setSelectedCrypto}
            handleImageClick={handleImageClick}
          />
        </div>
      </div>
      <div className="w-full mt-5">
        <div className='flex justify-between w-full'>
          <label className="block text-xs font-normal text-[#81858c] dark:text-white">
            Wallet Address
          </label>
          <label className="block mb-1 text-xs font-normal text-[#EFB81C] dark:text-white">
            Add
          </label>
        </div>
        <button
          onClick={toggleWalletButton}
          className={`px-2 my-1 mb-1 py-1 rounded-md font-semibold text-xs ${isWalletButtonActive ? "text-[#EFB81C] bg-[#EFB81C] bg-opacity-15" : "text-[#81858c] bg-[#f1f5f7]"
            }`}
        >
          Buycex Wallet
        </button>
        <Input
          type="text"
          variant="borderless"
          suffix={<MdOutlineBrightnessHigh />}
          className="bg-[#f3f5f7] py-2 rounded-md text-[#81858c] text-sm"
          placeholder="Please Enter"
        />
      </div>
      <div className="w-full mt-5">
        <NetworkSelectWithdrawModal
          networks={dummyNetworks}
          selectedNetwork={selectedNetwork}
          setSelectedNetwork={setSelectedNetwork}
        />
      </div>
      <div className="flex mt-5 flex-col w-full">
        <div className='flex w-full justify-between'>
          <div className='flex items-center gap-1 mb-1'><label className='text-[#81858c] text-xs'>Amount</label><p className='text-[#81858c] underline-offset-4 underline decoration-dotted text-xs'>(Raise Amount)</p></div>
          <p className='text-xs text-[#81858c]'>0 BTC</p>
        </div>
        <Input variant="borderless" className="bg-[#f3f5f7] py-2 rounded-md text-[#81858c] text-sm" placeholder='Minimum' suffix={<p className='text-sm font-medium text-[#EFB81C]'>All</p>} />
      </div>
      <div className='flex items-center mt-1 justify-between py-1 w-full'>
      <div className='flex items-center gap-5'>
      <div className='flex items-center'>
        <StyledRadio
          type="radio"
          name="option"
          value="funding"
          checked={selectedOption === 'funding'}
          onChange={handleChange}
        >
        <p className='text-sm'>Funding</p>
        </StyledRadio>
      </div>
      <div className='flex items-center'>
        <StyledRadio
          type="radio"
          name="option"
          value="spot" 
          checked={selectedOption === 'spot'}
          onChange={handleChange}
        >
        <p className='text-sm'>Spot</p>
        </StyledRadio>
      </div>
    </div>
        <div className='flex items-center gap-2'>
          <p className='text-xs text-black'>0</p>
          <FiRefreshCcw className='text-[#efb81c]' />
        </div>
      </div>
      <div className='flex items-center my-5 justify-between w-full'>
        <p className='text-xs text-[#81858c]'>Transaction Fee</p>
        <p className='text-xs text-black'>-- BTC</p>
      </div>
      <div>
        <div className='flex items-center justify-between w-full'>
          <div className='flex flex-col'>
            <p className='text-lg font-semibold text-black'>-- BTC</p>
            <p className='text-[#81858c] text-xs'>Amount Received <span className='text-[#efb81c] text-xs'>Set Up</span></p>
          </div>
          <button onClick={() => onClose()} className='px-4 py-2 font-semibold text-sm text-black bg-[#efb81c] rounded-md'>
            Confirm
          </button>
        </div>
      </div>
    </>
  );
}

const StyledRadio = styled(Radio)`
    .ant-radio-inner {
        border-color: #efb81c;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #efb81c;
        background-color: #efb81c;
    }

    .ant-radio-checked .ant-radio-inner::after {
        background-color: #fff;
    }

    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2);
    }

    .ant-radio-wrapper {
        color: #1f2937;
    }
`;
export default Address;
