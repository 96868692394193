// components/ApplicationHistory.js
import React from 'react';


const TableUncreditedDepositForm = () => {
  return (
    <div className="flex flex-col items-center w-full bg-white p-[28px] mt-3 border rounded-2xl">
      {/* Heading */}
      <div className="flex items-center justify-between w-full mb-4">
        <h6 className="text-yellow-500">Application History</h6>
      </div>

      {/* Main Table */}
      <div className="w-full table-container h-fit">
        <div className="grid grid-cols-[1fr_1fr_1fr_1fr_2fr] mb-1 border-b">
          <div className="text-[#aaaaaa] font-light px-4 py-2 text-left">Time</div>
          <div className="text-[#aaaaaa] font-light px-4 py-2 text-left">Coin</div>
          <div className="text-[#aaaaaa] font-light px-4 py-2 text-center">Amount</div>
          <div className="text-[#aaaaaa] font-light px-4 py-2 text-center">Status</div>
          <div className="text-[#aaaaaa] font-light px-4 py-2 text-right">Operation</div>
        </div>

        <div className="grid grid-cols-[1fr_1fr_1fr_1fr_2fr] py-1">
          <div colSpan="5" className="px-4 py-2 text-center text-sm text-gray-500">No Data</div>
        </div>
      </div>
    </div>
  );
};

export default TableUncreditedDepositForm;
