import React from 'react';
import Crypto1 from 'assets/img/Crypto1.png';
import Crypto2 from 'assets/img/Crypto2.png';
import Crypto3 from 'assets/img/Crypto3.png';

const FiatDepositPromotion = () => {
  return (
    <div>
      <div className="mt-40 text-3xl font-semibold leading-9 text-[#151415] dark:text-[#F4F4F4] max-md:mt-10 max-md:max-w-full">
        Deposit Local Fiat Currencies to Buy Crypto
      </div>
      <div className="mt-7 text-base leading-8 text-neutral-900 dark:text-white max-md:max-w-full">
        Convert cash into crypto. Deposit over 65+ fiat currencies to get started with crypto trading.
      </div>
      <div className="mt-12 max-md:mt-10 max-md:max-w-full dark:bg-[#151415]">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <FeatureCard
            imgSrc={Crypto1}
            title="Transact Seamlessly With Bank Transfers"
            description="Transact effortlessly with banks including SEPA, FPS, Visa, and Mastercard."
          />
          <FeatureCard
            imgSrc={Crypto2}
            title="Swift Transactions With Robust Security"
            description="Experience rapid transactions and rest easy knowing your funds are secure."
          />
          <FeatureCard
            imgSrc={Crypto3}
            title="Zero Fees & Competitive Rates"
            description="Maximize your investment with zero fees and competitive currency rates."
          />
        </div>
      </div>
    </div>
  );
};

const FeatureCard = ({ imgSrc, title, description }) => {
  return (
    <div className="flex flex-col items-center p-8 rounded-3xl border border-solid border-zinc-300 dark:border-zinc-600 text-neutral-900 dark:text-white shadow-md hover:shadow-lg transition-shadow duration-300">
      <img src={imgSrc} alt={title} className="w-20 h-20 rounded-full mb-0 object-cover" />
      <div className="text-center">
        <div className="mt-4 text-xl font-semibold leading-7">{title}</div>
        <div className="mt-3 text-sm leading-5 text-neutral-600 dark:text-neutral-300">{description}</div>
      </div>
    </div>
  );
};

export default FiatDepositPromotion;
