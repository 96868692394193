import React from 'react';
import { CgLoadbar } from "react-icons/cg";

function TopPayment() {
  const paymentMethods = [
    { name: 'Zelle', color: '#E954F6' },
    { name: 'Bank Transfer', color: 'green-600' },
    { name: 'Payeer', color: 'yellow-600' },
    { name: 'AirTm', color: 'blue-600' },
    { name: 'Perfect Money', color: 'red-600' },
    { name: 'Perfect Money', color: 'red-600' },
    { name: 'Perfect Money', color: 'red-600' },
  ];

  return (
    <div className="flex flex-col w-full">
      <h3 className="text-[40px] font-bold capitalize mb-5">
        Top Payment Methods
      </h3>
      <div className="flex flex-wrap gap-4">
        {paymentMethods.map(({ name, color }, index) => (
          <div key={index} className="border border-gray-100 rounded-lg w-1/5 px-4 py-4">
            <div className="flex items-center gap-3">
              <CgLoadbar className={`text-lg text-${color} rotate-90`} />
              <p>{name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TopPayment;
