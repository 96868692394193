import React from 'react'
import NoData from "assets/img/user/No-data.svg";

function StakingTable() {
  return (
    <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
      <div className="flex justify-between border-b pb-3 w-full">
          <p className="flex-1 text-start">Date</p>
          <p className="flex-1 text-start">Stake</p>
          <p className="flex-1 text-start">Stake Amount</p>
          <p className="flex-1 text-start">Distribute</p>
          <p className="flex-1 text-start">Distribute Amount</p>
          <p className="flex-[0.5] text-end">Ratio</p>
          <p className="flex-1 text-center">Status</p>


        </div>
      <div className="w-full flex justify-center items-center h-96">
        <div className='flex flex-col gap-1 h-44 justify-center items-center'>
          <img src={NoData} alt="No Data" />
          <p className='text-[#707a8a] text-sm'>No records</p>
        </div>
      </div>
    </div>
  )
}

export default StakingTable