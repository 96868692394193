import React, { useState } from "react";
import { Modal } from "antd";
import { FaRotate } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";

const TransferModel = ({ showTransferModel }) => {
    const [isModalOpen, setIsModalOpen] = useState(true);

    const handleOk = () => {
        setIsModalOpen(false);
        showTransferModel(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        showTransferModel(false);
    };
    return (
        <div>
            <div className="model">
                <Modal
                    title="Transfer"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    className="convert-model"
                >
                    <div className="content">
                        <div className="mt-4 flex items-center justify-center gap-1">
                            <div className="flex-1">
                                <div className="text-gray-500 text-sm">
                                    From
                                </div>
                                <div className="mt-1 h-12 flex items-center justify-start font-semibold text-sm bg-gray-100 dark:bg-opacity-10 dark:text-[#ffffff] rounded-xl px-2">
                                    Funding
                                </div>
                            </div>
                            <div className="bg-gray-300 rounded-full p-1 text-gray-400 mt-4">
                                <FaArrowRight />
                            </div>
                            <div className="flex-1">
                                <div className="text-gray-500 text-sm">To</div>
                                <div className="mt-1 h-12 flex items-center justify-start font-semibold text-sm bg-gray-100 dark:bg-opacity-10 dark:text-[#ffffff] rounded-xl px-2">
                                    Unified Trading
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-gray-400">Coin</div>
                            <div className="mt-1">
                                <div className=" h-12 flex items-center justify-start font-semibold text-sm bg-gray-100 dark:bg-opacity-10 dark:text-[#ffffff] rounded-xl px-2">
                                    <select
                                        name=""
                                        id=""
                                        className="w-full bg-transparent border-none"
                                    >
                                        <option value="">USDT</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-gray-400">Amount</div>
                            <div className="mt-1">
                                <div className=" h-12 flex items-center gap-2 justify-start font-semibold text-sm bg-gray-100 dark:bg-opacity-10 dark:text-[#ffffff] rounded-xl px-2">
                                    <input
                                        type="text"
                                        placeholder="--"
                                        className="flex-1 bg-transparent border-none text-yellow-500"
                                    />
                                    <span className="text-yellow-500 text-sm">
                                        All
                                    </span>
                                    <span className="text-sm">USDT</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-2 items-center justify-between text-gray-400 text-sm">
                            <div>Transferable Amount</div>
                            <div>0.0000 USDT</div>
                        </div>

                        <div className="mt-6 text-center flex items-center justify-center text-sm font-bold bg-yellow-500 rounded-xl h-12">
                            Confirm
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default TransferModel;
