import toast from "react-hot-toast";
import {
    BASIC_VERIFICATION_SUCCESS,
    BASIC_VERIFICATION_FAILURE,
    BASIC_VERIFICATION_REQUEST,
    FETCH_P2P_DATA,
    FETCH_P2P_DATA_ERROR,
} from "../types";
import { RepositoryFactory } from "repository/RepositoryFactory";
import { fetchP2pDataLoading } from "./p2pActions";
import { setIdBackImage, setIdFrontImage } from "./imageAction";

const idVerificationRepository = RepositoryFactory.get("idVerification");

export const checkKycStatus = (userID, userToken) => async (dispatch) => {
    dispatch({ type: BASIC_VERIFICATION_REQUEST });
    try {
        const response = await idVerificationRepository.checkKycStatus(
            userID,
            userToken
        );
        const { data } = response;
        if (data.status === 1) {
            dispatch({ type: BASIC_VERIFICATION_SUCCESS, payload: data.data });
        } else {
            dispatch({
                type: BASIC_VERIFICATION_FAILURE,
                payload: data.detail,
            });
        }
    } catch (error) {
        dispatch({ type: BASIC_VERIFICATION_FAILURE, payload: error.message });
    }
};

export const submitkyc = (formData, userID, userToken) => async (dispatch) => {
    dispatch({ type: BASIC_VERIFICATION_REQUEST });
    try {
        const response = await idVerificationRepository.submitkyc(formData, userID, userToken);
        const { data } = response;

        console.log("SubmitKYC>>>>>", data); // Log the full response data

        if (data.status === 1) {
            dispatch({ type: FETCH_P2P_DATA, payload: data.data });
            toast.success("KYC submitted successfully!"); // Change message for clarity
            return response; // Return the response if successful
        } else {
            dispatch({ type: FETCH_P2P_DATA_ERROR, payload: data.detail });
            toast.error(data.data); // Adjust this message if necessary
            console.error("Error in KYC submission:", data); // More informative error logging
            return response; // Return the response for error handling
        }
    } catch (error) {
        dispatch({ type: FETCH_P2P_DATA_ERROR, payload: error.message });
        toast.error("Error fetching P2P data");
        console.error("Submission error:", error); // Log the error for debugging
        throw error; // Throw the error to be caught in the calling code
    } finally {
        dispatch(fetchP2pDataLoading(false));
    }
};

export const basicVerification =
    (formData, userID, userToken) => async (dispatch) => {
        dispatch({ type: BASIC_VERIFICATION_REQUEST });
        try {
            const response = await idVerificationRepository.basicVerification(
                formData,
                userID,
                userToken
            );
            console.log("ResponseBASIC>>>", response);
            const { data } = response;
            console.log("P2P>>>>>",data)
            if (data.status === 1) {
                dispatch(setIdFrontImage(null));
                dispatch(setIdBackImage(null));
                dispatch({ type: FETCH_P2P_DATA, payload: data.data });
                toast.success(data.data);
                localStorage.removeItem("idVerificationBasicInfo");
                localStorage.removeItem("userIdDetails");
                localStorage.removeItem("idBackImageMeta");
                localStorage.removeItem("idFrontImageMeta");
                localStorage.removeItem("idFrontImage");
                localStorage.removeItem("idBackImage");
                localStorage.removeItem("idbackImage");
                localStorage.removeItem("idfrontImage");
                console.log("Local storage cleared successfully");
                return { success: true };
            } else {
                dispatch({ type: FETCH_P2P_DATA_ERROR, payload: data.detail });
                toast.error(data.data);
                console.log("error in data.status")
                return { success: false };
            }
        } catch (error) {
            dispatch({ type: FETCH_P2P_DATA_ERROR, payload: error.message });
            toast.error("Error fetching p2p data");
            return { success: false };
        } finally {
            dispatch(fetchP2pDataLoading(false));
        }
    };
    
export const showbasicVerification =
    (userID, userToken, success) => async (dispatch) => {
        dispatch({ type: BASIC_VERIFICATION_REQUEST });
        try {
            const response =
                await idVerificationRepository.showbasicVerification(
                    userID,
                    userToken
                );
            console.log("ResponseBASIC>>>", response.data);
            const data = response.data;
            console.log("P2P>>>>>", data?.data);
            if (data.status === 1) {
                dispatch({
                    type: BASIC_VERIFICATION_SUCCESS,
                    payload: data.data,
                });
                success(data)
                // toast.success("P2p data fetched successfully!");
            } else {
                dispatch({
                    type: BASIC_VERIFICATION_FAILURE,
                    payload: data.detail,
                });
                success(data)
                // toast.error(data.detail);
                console.log("error in data.status");
            }
        } catch (error) {
            // dispatch({ type: FETCH_P2P_DATA_ERROR, payload: error.message });
            // toast.error("Error fetching p2p data");
        } finally {
            // dispatch(fetchP2pDataLoading(false));
        }
    };