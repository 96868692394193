import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

const MarketFooter = () => {
    return (
        <footer className="market__footer bg-[#FFFFFF] border-t">
            <div className="market__footer-wrapper-container max-w-[1900px] m-auto">
                <div className="market__footer__inner-wrapper flex items-center justify-between p-2">
                    <div className="market__footer__links flex items-center gap-0">
                        <Link
                            to="#!"
                            title="Market Overview"
                            target="_blank"
                            rel="noreferrer"
                            className="market__footer__link text-[12px] font-[500] after:content-['|'] after:mx-2 last:after:content-none"
                        >
                            Market Overview
                        </Link>
                        <Link
                            to="#!"
                            title="API"
                            target="_blank"
                            rel="noreferrer"
                            className="market__footer__link text-[12px] font-[500] after:content-['|'] after:mx-2 last:after:content-none"
                        >
                            API
                        </Link>
                        <Link
                            to="#!"
                            title="Help Center"
                            target="_blank"
                            rel="noreferrer"
                            className="market__footer__link text-[12px] font-[500] after:content-['|'] after:mx-2 last:after:content-none"
                            >
                            Help Center
                        </Link>
                        <Link
                            to="#!"
                            title="Support"
                            target="_blank"
                            rel="noreferrer"
                            className="market__footer__link text-[12px] font-[500] after:content-['|'] after:mx-2 last:after:content-none"
                        >
                            Support
                        </Link>
                    </div>

                    <div className="market__footer__copyright">
                        <p className="text-[12px]">
                            © 2022. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default MarketFooter;
