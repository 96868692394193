import React, { useState } from 'react';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6"; // Import both eye icons
import { FaAngleDown } from "react-icons/fa6";
import { MdKeyboardArrowRight, MdSettings } from "react-icons/md";

function IsolatedMargin({ isVisible, toggleVisibility }) {
    return (
        <div className='w-full mt-6 p-6 rounded-2xl border'>
            <div>
                <div className='flex items-center gap-1 w-full'>
                    <div className='flex w-full justify-between'>
                        <div className='flex flex-col'>
                            <div className='flex gap-1 items-center'>
                                <p className='text-[20px] font-semibold'>Total balance</p>
                                <button onClick={toggleVisibility} className='mt-1'>
                                    {isVisible ? <FaRegEyeSlash className='text-xl' /> : <FaRegEye className='text-xl' />}
                                </button>
                            </div>
                            <div className='flex items-baseline gap-2'>
                                <p className='text-[32px] font-semibold'>{isVisible ? '0.00000000' : '****'}</p>
                                <span className='text-sm flex items-center gap-1 font-semibold'>
                                    {isVisible ? 'BTC' : '****'} <FaAngleDown className='text-[12px] opacity-40 mt-1' />
                                </span>
                            </div>
                        </div>
                        <div className='text-end flex gap-1 items-start justify-end text-xl font-medium'>
                            <MdSettings/>
                        </div>
                    </div>
                </div>
                <p className='text-sm'>{isVisible ? '≈ $0.00' : '*****'}</p>
                <div className='flex items-center mt-2 '><p className='text-sm'>Today’s PNL <span className='pl-2 text-sm'>{isVisible ? '$0.00 (0.00%)' : '*****'}</span></p><MdKeyboardArrowRight className='text-[#707a8a]' /></div>

                <div className='flex w-full justify-between'>
                    <div className='flex flex-col gap-1 mt-6 w-full md:w-1/2'>
                        <div className='flex items-center gap-1'>
                            <p className='text-sm'>Total Debt(BTC)</p>
                        </div>
                        <p className='font-extralight'>{isVisible ? '-' : '********'}</p>
                    </div>
                    <div className='flex flex-col gap-1 mt-6 w-full'>
                        <div className='flex items-center gap-1'>
                            <p className='text-sm'>Account Equity(BTC)</p>
                        </div>
                        <p className='font-extralight'>{isVisible ? '-' : '********'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IsolatedMargin;
