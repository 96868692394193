import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NoData from "assets/img/user/No-data.svg";

function RecentTransaction() {
    const history = useHistory();
    const dispatch = useDispatch();
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);

    const [allDeposits, setAllDeposits] = useState([]);
    const [deposits, setDeposits] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDeposits = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `https://api.buycex.com/Crypto/MyDeposits/coin/all`,
                    {
                        headers: {
                            TOKEN: userToken,
                            ID: userID,
                        },
                    }
                );
                const data = await response.json();
                if (Array.isArray(data.data)) {
                    setAllDeposits(data.data);
                    setDeposits(data.data);
                } else {
                    setAllDeposits([]);
                    setDeposits([]);
                }
            } catch (error) {
                console.error('Error fetching deposit data:', error);
                setAllDeposits([]);
                setDeposits([]);
            } finally {
                setLoading(false);
            }
        };

        fetchDeposits();
    }, [userToken, userID, history]);

    return (
        <div className='p-3 sm:p-6 mt-6 border rounded-2xl'>
            <div className='flex justify-between items-center'>
                <div className='flex gap-5'>
                    <p className='font-semibold text-lg sm:text-2xl cursor-pointer pb-0 sm:pb-3'>
                        Recent Transactions
                    </p>
                </div>
                <div>
                    <div className='flex items-center'>
                        <p className='text-sm font-medium'>More</p>
                        <MdKeyboardArrowRight className='text-lg text-[#707a8a]' />
                    </div>
                </div>
            </div>
            <div className='overflow-x-auto'>

            <table className='min-w-full mt-4'>
                <thead>
                    <tr>
                        <th className='py-2 px-0 sm:px-4 border-b text-[#AAAAAA] text-start md:text-center font-normal'>Status</th>
                        <th className='py-2 px-4 border-b text-[#AAAAAA] text-center font-normal'>Address</th>
                        <th className='py-2 px-4 border-b text-[#AAAAAA] text-center font-normal'>Amount</th>
                        <th className='py-2 px-4 border-b text-[#AAAAAA] text-center font-normal'>Time</th>
                        <th className='py-2 px-4 border-b text-[#AAAAAA] text-end md:text-center font-normal'>Hash</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan='5' className='py-4 text-center'>
                                <div className='text-black dark:text-white'>Loading...</div>
                            </td>
                        </tr>
                    ) : deposits.length === 0 ? (
                        <tr>
                            <td colSpan='5' className='py-4 text-center'>
                                <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                                    <img src={NoData} />
                                    <p className='text-[#707a8a] text-sm'>No records</p>
                                </div>
                            </td>
                        </tr>
                    ) : (
                        // Show actual data rows
                        deposits.map((deposit, index) => (
                            <tr key={index}>
                                <td className='py-3 px-4 flex justify-center'>
                                    {deposit.status === '1' ? (
                                        <FaCheck className='text-green-500' />
                                    ) : (
                                        <FaCheck className='text-red-500' />
                                    )}
                                </td>
                                <td className='py-3 px-4 text-center whitespace-nowrap'>
                                    {deposit.username.length > 8
                                        ? deposit.username.slice(0, 4) + '...' + deposit.username.slice(-4)
                                        : deposit.username}
                                </td>
                                <td className='py-3 px-4 text-center whitespace-nowrap'>
                                    {deposit.num} {deposit.coinname}
                                </td>
                                <td className='py-3 px-4 text-center whitespace-nowrap'>
                                    {new Date(parseInt(deposit.addtime) * 1000)
                                        .toISOString()
                                        .slice(0, 19)
                                        .replace('T', ' ')}
                                </td>
                                <td className='py-3 px-4 text-center'>
                                    {deposit.txid.length > 8
                                        ? deposit.txid.slice(0, 4) + '...' + deposit.txid.slice(-4)
                                        : deposit.txid}
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            </div>
        </div>
    );
}

export default RecentTransaction;
