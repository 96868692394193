import React from "react";
import { Drawer } from "antd";
import { useState } from "react";
import "./modelstyle.css";
import { FaArrowLeftLong } from "react-icons/fa6";

const CrossModel = ({ setShowCrossModel }) => {
    const [open, setOpen] = useState(true);

    const onClose = () => {
        setOpen(false);
        setShowCrossModel(false);
    };
    return (
        <div className="cross-drawer">
            <Drawer
                className=""
                title={`Choose Margin Mode`}
                placement="right"
                size={"large"}
                onClose={onClose}
                open={open}
            >
                <div>
                    <div className="flex items-center gap-2  dark:text-[#ffffff]">
                        {" "}
                        <button onClick={onClose} className="text-xl">
                            <FaArrowLeftLong />
                        </button>
                        <h2 className="text-xl font-bold">
                            Choose Margin Mode
                        </h2>
                    </div>
                    <div className="mt-4 h-dvh overflow-y-scroll flex gap-6 pr-2">
                        <div className="bg-gray-100 rounded-xl flex-1 p-4 dark:bg-opacity-10 dark:text-[#ffffff]">
                            <h2 className="mt-4 font-semibold text-xl text-center">
                                Isolated Margin
                            </h2>
                            <p className="text-xs text-center">
                                (Regular User)
                            </p>
                            <div className="mt-8 flex gap-10">
                                <div className="w-20 text-gray-400 font-semibold ">
                                    Supports
                                </div>
                                <div className="text-xs font-semibold">
                                    All Perpetual and Futures Contracts
                                </div>
                            </div>
                            <div className="mt-10 flex gap-10">
                                <div className="w-20 text-gray-400 font-semibold ">
                                    Mode(s)
                                </div>
                                <div className="text-xs font-semibold">
                                    <p>One-way</p>
                                    <p className="mt-2">
                                        Hedge (USDT-Perp Only)
                                    </p>
                                </div>
                            </div>
                            <div className="mt-8 flex gap-10">
                                <div className="w-40 text-gray-400 font-semibold ">
                                    Features
                                </div>
                                <div className="text-xs font-semibold">
                                    <p>
                                        Trade Perpetual and Futures contracts
                                        with corresponding settlement assets
                                    </p>
                                    <p className="mt-2">
                                        Isolated Margin mode will apply to all
                                        Perpetual and Futures contracts
                                    </p>
                                    <p className="mt-2">
                                        Supports both manual margin adjustment
                                        and auto margin replenishment
                                    </p>
                                </div>
                            </div>
                            <div className="mt-40">
                                <button className="flex items-center justify-center h-12 w-full bg-yellow-500 font-bold rounded-xl">
                                    Setting
                                </button>
                                <div className="mt-4">
                                    <p className="text-gray-400 text-xs text-center">
                                        See Requirements
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-100 rounded-xl flex-1 p-4 dark:bg-opacity-10 dark:text-[#ffffff]">
                            <h2 className="mt-4 font-semibold text-xl text-center text-yellow-500">
                                Cross Margin
                            </h2>
                            <p className="text-xs text-center text-yellow-500">
                                (Regular User)
                            </p>
                            <div className="mt-8 flex gap-10">
                                <div className="w-20 text-gray-400 font-semibold ">
                                    Supports
                                </div>
                                <div className="text-xs font-semibold">
                                    All Perpetual and Futures Contracts
                                </div>
                            </div>
                            <div className="mt-10 flex gap-10">
                                <div className="w-20 text-gray-400 font-semibold ">
                                    Mode(s)
                                </div>
                                <div className="text-xs font-semibold">
                                    <p>One-way</p>
                                    <p className="mt-2">
                                        Hedge (USDT-Perp Only)
                                    </p>
                                </div>
                            </div>
                            <div className="mt-8 flex gap-10">
                                <div className="w-40 text-gray-400 font-semibold ">
                                    Features
                                </div>
                                <div className="text-xs font-semibold">
                                    <p>
                                        Enables trading of Derivatives with USD
                                        value of multi-asset collateral
                                    </p>
                                    <p className="mt-2">
                                        Enables offsetting of profit and losses
                                        of positions against each other, and
                                        allows profits to be used to open new
                                        positions
                                    </p>
                                </div>
                            </div>
                            <div className="mt-52">
                                <button className="flex items-center justify-center h-12 w-full text-gray-400 font-bold rounded-xl">
                                    Currently Using
                                </button>
                            </div>
                        </div>
                        <div className="bg-gray-100 rounded-xl flex-1 p-4 dark:bg-opacity-10 dark:text-[#ffffff]">
                            <h2 className="mt-4 font-semibold text-xl text-center">
                                Portfolio Margin
                            </h2>
                            <p className="text-xs text-center">
                                (Professional Derivatives Trader)
                            </p>
                            <div className="mt-8 flex gap-10">
                                <div className="w-20 text-gray-400 font-semibold ">
                                    Supports
                                </div>
                                <div className="text-xs font-semibold">
                                    All Perpetual and Futures Contracts
                                </div>
                            </div>
                            <div className="mt-10 flex gap-10">
                                <div className="w-20 text-gray-400 font-semibold ">
                                    Mode(s)
                                </div>
                                <div className="text-xs font-semibold">
                                    <p>One-way</p>
                                </div>
                            </div>
                            <div className="mt-8 flex gap-10">
                                <div className="w-40 text-gray-400 font-semibold ">
                                    Features
                                </div>
                                <div className="text-xs font-semibold">
                                    <p>
                                        Offset Collateral Risk between Spot and
                                        Derivatives
                                    </p>
                                    <p className="mt-2">
                                        Offset Spot PnL with Unrealized
                                        Perpetual Contract PnL
                                    </p>
                                </div>
                            </div>
                            <div className="mt-6 flex items-center gap-10">
                                <div>
                                    <h2 className="text-sm font-semibold">
                                        Spot Hedging
                                    </h2>
                                    <p className="text-xs font-semibold text-gray-400">
                                        Once enabled, the Spot assets selected
                                        as collateral will be for Hedging.
                                    </p>
                                </div>
                                <div className="w-10"></div>
                            </div>
                            <div className="mt-44">
                                <button className="flex items-center justify-center h-12 w-full bg-yellow-500 font-bold rounded-xl">
                                    Setting
                                </button>
                                <div className="mt-4">
                                    <p className="text-gray-400 text-xs text-center">
                                        See Requirements
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default CrossModel;
