import Header from "components/header/Header";
import SpotTradeFooter from "components/spot-trade/SpotTradeFooter";
import React from "react";

const SpotTradeLayout = (props) => {
    return (
        <>
            {/* Spot Trade Header */}
            <Header />

            {/* Spot Trade Body */}
            <main className="bg-[#F4F4F4] flex flex-col gap-1">
                {props.children}
            </main>

            {/* Spot Trade Footer */}
            <SpotTradeFooter />
        </>
    );
};

export default SpotTradeLayout;
