import React from 'react';
import { Link } from 'react-router-dom';
import { IoMdArrowForward } from "react-icons/io";

const RewardCard = ({ amount, description, linkText }) => {
  let mainAmount = amount;
  let percentage = null;

  if (amount === '1025 + 30%') {
    [mainAmount, percentage] = amount.split(' + ');
  }

  return (
    <div className="flex lg:flex-col justify-between rounded-lg overflow-hidden shadow-lg p-1 bg-gradient-light dark:bg-gradient-dark">
      <div className=" p-[8px] md:p-6">
        <h1 className="text-black dark:text-[#adb1b8] text-xs md:text-xl font-light md:mb-4">
          Get Up to <br /> 
          <span className='font-bold text-2xl md:text-5xl mr-1 bg-clip-text text-transparent bg-gradient-to-r from-yellow-600 to-yellow-300'>
            {mainAmount} 
          </span> 
          USDT
          {percentage && (
            <span className='font-bold text-2xl md:text-5xl ml-1 bg-clip-text text-transparent bg-gradient-to-r from-yellow-600 to-yellow-300'>
              + {percentage}
            </span>
          )}
          <br />
          <span className='text-xs md:text-xl'>
            in Rewards {percentage && (
                <span className='text-xs md:text-xl lg:ml-24'>Commission</span>
            )}
            </span>
        </h1>
        <p className="md:mb-6 text-xs md:text-xl">{description}</p>
        {linkText && (
          <Link to="/" className='items-center text-yellow-500 py-2 gap-2 rounded inline-flex'>
            <span>{linkText}</span>
            <IoMdArrowForward />
          </Link>
        )}
      </div>
      <div className="p-4">
        <button className="w-full bg-yellow-500 text-white rounded-full px-4 py-2 focus:outline-none focus:shadow-outline-green">
          Invite
        </button>
      </div>
    </div>
  );
};

export default RewardCard;
