import React from "react";
import { Link } from "react-router-dom";

import {
    BsGrid,
    BsBarChartFill,
    BsBellFill, // Notification icon
    BsKeyFill,  // Security icon
    BsFillCreditCard2BackFill, // Payment icon
} from "react-icons/bs";

const IdentificationMenu = () => {
    const menuList = [
        {
            id: 1,
            name: "Identification",
            title: "Identification",
            icon: <BsGrid size={14} />, // Grid icon for an overview or dashboard
            path: "/user/account/overview",
        },
        {
            id: 2,
            name: "Security",
            title: "Security",
            icon: <BsKeyFill size={14} />, // Key icon for security
            path: "/user/account/security",
        },
        {
            id: 3,
            name: "Payment",
            title: "Payment",
            icon: <BsFillCreditCard2BackFill size={14} />, // Credit card icon for payment
            path: "#", // Add valid path when available
        },
        {
            id: 4,
            name: "Notification",
            title: "Notification",
            icon: <BsBellFill size={14} />, // Bell icon for notifications
            path: "/user/account/notification-center",
        },
        {
            id: 5,
            name: "API Management",
            title: "API Management",
            icon: <BsBarChartFill size={14} />, // Bar chart icon for API management
            path: "#", // Add valid path when available
        },
    ];

    return (
        <div className="header__user-orders-menu-wrapper rounded-b-md bg-[#17181e] w-[90%] sm:w-[200px] absolute top-[34px] right-[0px] h-max z-[999]">
            <div className="w-full h-max px-3 flex flex-col">
                {/* User Orders Navigation Menu */}
                <div className="user-side-menu__menu-wrapper">
                    <ul className="flex flex-col gap-0 user-side-menu__menu-list">
                        {menuList.map((item) => (
                            <li key={item.id} className="flex items-center gap-3">
                                <Link
                                    to={item.path}
                                    title={item.title}
                                    rel="alternate noopener noreferrer"
                                    className="w-full hover:text-[#EFB81C] flex items-center gap-3 px-3 py-[10px] rounded-b-md hover:bg-[#383a3d] opacity-80 hover:opacity-100 text-sm" // Adjusted padding and text size for better fit
                                >
                                    <span>{item.icon}</span>
                                    <span className="font-semibold text-[14px]">
                                        {item.name}
                                    </span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default IdentificationMenu;
