import React, { useState, useRef, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const TimeLimitDropdown = ({ items, buttonText, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(buttonText); // Initialize with buttonText
  const dropdownRef = useRef(null);

  // Update selectedItem when buttonText changes
  useEffect(() => {
    setSelectedItem(buttonText);
  }, [buttonText]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleItemClick = (itemText) => {
    setSelectedItem(itemText);
    setIsOpen(false);
    onSelect(itemText);
  };

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        type="button"
        className={`flex text-sm items-center justify-between w-28 gap-2 px-2 py-2 rounded-[4px] border border-gray-300 bg-white text-gray-700 ${isOpen ? 'text-[#EFB81C]' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedItem}
        {isOpen ? <IoIosArrowUp className="text-sm" /> : <IoIosArrowDown className="text-sm" />}
      </button>
      {isOpen && (
        <div className="absolute left-0 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg">
          <div className="py-1">
            {items.map(({ text }, index) => (
              <div
                key={index}
                className={`flex items-center gap-2 px-4 py-2 cursor-pointer ${text === selectedItem ? 'text-[#EFB81C]' : 'text-gray-700'}`}
                onClick={() => handleItemClick(text)}
              >
                {text}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeLimitDropdown;