
import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Input, Radio, Space } from 'antd';
import { useDispatch } from "react-redux";
import {
    email2fa,
} from "store/actions/authAction";


const ResetSecuritySettings = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [selectedMethod, setSelectedMethod] = useState(null);
    const [activeEmail, setActiveEmail] = useState((localStorage.getItem("active_email").split("@")[0].slice(0,2)) + '****');
    const [options, setOptions] = useState([`Google Authenticator isn't working. I'd like to reset it.`, `Mobile number authentication via **** isn't working. I'd like to change my mobile number.`, `Email authentication via ${activeEmail}@${localStorage.getItem("active_email").split("@")[1]} isn't working. I'd like to change my email address.`]);


    const confirmHandler = () => {
        if (selectedMethod && selectedMethod === 3) {
            dispatch(
                email2fa(
                    localStorage.getItem("user_id_2fa"),
                    () => {
                        history.goBack();
                        localStorage.setItem("enable_confirm_email", true);
                    }
                )
            );
        } else if (selectedMethod && selectedMethod === 1) {
            history.push("/login");
            localStorage.setItem("google_confirm_email", true);
        }

    }

    return (
        <>

            <div className="min-h-screen flex items-center justify-center bg-gray-100 relative bottom-12" >
                {/* Back button */}
                <div className="text-xl font-medium absolute top-[115px] left-[85px]">
                    <Link to="/login">
                        <a className="text-gray-600 hover:text-gray-900 flex items-center">
                            <ArrowLeftOutlined style={{ fontSize: '25px' }} /> {/* Adjust fontSize here */}
                            <span className="ml-2 text-2xl">back</span>
                        </a>
                    </Link>


                </div>

                <div className="bg-white w-[600px] p-10 h-[500px] rounded-lg shadow-md ">
                    {/* Header Section */}
                    <h1 className="text-2xl font-bold mb-4">Reset Security Settings</h1>

                    {/* Warning section */}
                    <div className="bg-blue-100   p-3 rounded-md mb-6">
                        <p className="text-[1rem] font-medium">
                            <span className=" text-[1rem] font-medium">After resetting security settings</span>, on-chain withdrawals, internal transfers, fiat withdrawals, P2P transactions, and   will be temporarily suspended for 24 hours.
                        </p>
                    </div>
                    <div className="mb-6">
                        <p className="font-semibold text-lg mb-2">Select the security option(s) not working</p>

                        <Radio.Group onChange={(e) => { setSelectedMethod(e.target.value) }}>
                            <Space className="block">
                                {options.map((option, index) => {
                                    return (
                                        <div className="flex items-start mb-4" key={index}>
                                            <Radio
                                                className="mt-1 mr-2"
                                                value={index + 1}
                                                disabled={index === 1}
                                            >
                                                <span className="text-base">{option}</span>
                                            </Radio>
                                        </div>
                                    );
                                })}
                            </Space>
                        </Radio.Group>

                    </div>

                    <button
                        className="bg-yellow-400 text-white w-full py-2 rounded-lg font-semibold cursor-pointer disabled:opacity-75"
                        disabled={!selectedMethod}
                        type="button"
                        onClick={confirmHandler}
                    >
                        Confirm
                    </button>

                </div>
            </div>


        </>
    );
};

export default ResetSecuritySettings;

