import React, { useState, useEffect } from 'react';
import image20Million from '../../assets/img/20-million.png'; 
import { useDispatch, useSelector } from 'react-redux';
import { fetchIssues } from 'store/actions/StartupIssueAction';

const LaunchpadProjects = () => {
  const dispatch = useDispatch();
  const { loading, issues, error } = useSelector((state) => state.issues);
  const [filter, setFilter] = useState('All');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const IMAGE_BASE_URL = "https://api.buycex.com/Upload/issue/";
  const moreOptions = ['Option 1', 'Option 2', 'Option 3'];

  useEffect(() => {
    dispatch(fetchIssues());
  }, [dispatch]);

  console.log("Fetch data", issues.data);

  const toggleFilterDropdown = () => {
    setIsFilterOpen((prev) => !prev);
    setIsMoreOpen(false); // Close More dropdown when Filter is opened
  };

  const toggleMoreDropdown = () => {
    setIsMoreOpen((prev) => !prev);
    setIsFilterOpen(false); // Close Filter dropdown when More is opened
  };

  const selectFilter = (option) => {
    setFilter(option);
    setIsFilterOpen(false); // Close dropdown after selection
  };

  const selectMoreOption = (option) => {
    console.log('Selected:', option); // You can handle selected option as needed
    setIsMoreOpen(false); // Close dropdown after selection
  };

  // Mapping API response to projects
  const projects = issues.data?.list_jinxing.map((item) => ({
    id: item.id,
    title: item.name,
    commission: item.commission, // Placeholder, you can replace with actual data if available
    price: item.price, // Placeholder
    buyFrom: item.buycoin,
    goal: `${item.deal}/ ${item.bili}.`, // Placeholder
    timer:item.time, // Placeholder
    img: `${IMAGE_BASE_URL}${item.image}`, 
    btn:item.zhuangtai,// Replace with actual image path if available
  })) || [];

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold">In Progress ({projects.length})</h2>

        {/* Dropdowns for "All" and "More" */}
        <div className="flex space-x-4">
          {/* "All" Filter Dropdown */}
          <div className="relative inline-block text-left">
            <button
              onClick={toggleFilterDropdown}
              className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700"
              id="filter-menu"
              aria-haspopup="true"
            >
              {filter}
              <svg
                className="-mr-1 ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.292 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {isFilterOpen && (
              <div className="origin-top-right absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="filter-menu">
                  <button onClick={() => selectFilter('All')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">All</button>
                  <button onClick={() => selectFilter('VIP')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">VIP</button>
                  <button onClick={() => selectFilter('GT Holders Exclusive')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">GT Holders Exclusive</button>
                  <button onClick={() => selectFilter('Pre-Order')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Pre-Order</button>
                  <button onClick={() => selectFilter('Prime')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Prime</button>
                </div>
              </div>
            )}
          </div>

          {/* "More" Dropdown */}
          <div className="relative inline-block text-left">
            <button
              onClick={toggleMoreDropdown}
              className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700"
              id="more-menu"
              aria-haspopup="true"
            >
              More
              <svg
                className="-mr-1 ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.292 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {isMoreOpen && (
              <div className="origin-top-right absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="more-menu">
                  {moreOptions.map((option, index) => (
                    <button
                      key={index}
                      onClick={() => selectMoreOption(option)}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Projects List */}
      <div className="space-y-8">
        {projects.length > 0 ? (
          projects.map((project) => (
            <div key={project.id} className="bg-white shadow-lg rounded-lg p-6 flex items-center">
              <img
                src={project.img}
                alt={project.title}
                className="w-20 h-20 rounded-full mr-6"
              />
              <div className="flex-1">
                <h3 className="text-2xl font-semibold mb-2">{project.title}</h3>
                <div className="flex items-center space-x-2">
                  <span className="text-gray-500">commission:</span>
                  <span className="font-semibold">{project.commission}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="text-gray-500">BuyFrom:</span>
                  <span className="font-semibold">{project.buyFrom}</span>
                </div>
                <div className="text-gray-500">Price: {project.price}</div>
                <div className="text-[#EFB81C] font-bold">{project.goal}</div>
              </div>
              <div className="text-center">
                <div className="text-gray-500">Time Left:</div>
                <div className="text-lg font-bold">{project.timer}</div>
                <button className="mt-4 bg-[#EFB81C] text-white px-4 py-2 rounded-md">
                  {project.btn}
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">No projects available.</div>
        )}
      </div>
    </div>
  );
};

export default LaunchpadProjects;
