import React from 'react';
import CopyTradingValues from './CopyTradingValues';

const CopyTradingTable = ({ isVisible }) => {

    const renderContent = () => {
        return (
            <CopyTradingValues
                isVisible={isVisible}
            />
        );
    };

    return (
        <div className='p-6 mt-6 border rounded-2xl'>
            <div>{renderContent()}</div>
        </div>
    );
};

export default CopyTradingTable;
