import React from 'react';
import { IoMdCheckmarkCircle } from "react-icons/io";

const P2pComplete = ({ formattedFiat, setShowFeedback }) => {
    return (
        <div className="p-4 rounded-xl">
            <div className='flex justify-center items-center'>
                <IoMdCheckmarkCircle className='text-[80px] text-[#00e297] bg-[#DFF7F0] p-1 rounded-full' />
            </div>
            <h2 className="text-lg text-center font-semibold mb-4 mt-2">Successfully Sold</h2>
            <p className="text-lg text-center font-semibold mb-6">
                {formattedFiat}
            </p>
            <button
                onClick={() => setShowFeedback(false)} // Close the modal
                className="mt-4 flex bg-[#EFB81C] w-full py-2 px-4 rounded-xl items-center justify-center font-semibold "
            >
                Provide Feedback
            </button>
        </div>
    );
};

export default P2pComplete;
