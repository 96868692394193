import React from 'react'
import HeroSection from './HeroSection'
import BuySell from './Buy&Sell'
import MarketToday from './MarketToday'
import CaptureTrading from './CaptureTrading'
import HowStarted from './HowStarted'

function Home2() {
  return (
    <div>
        <HeroSection/>
        <BuySell />
        <MarketToday /> 
        <CaptureTrading/>
        <HowStarted/>
    </div>
  )
}

export default Home2