import React, { useState } from "react";
import {
    MdAccountCircle,
    MdNavigateNext,
    MdCheckBoxOutlineBlank,
} from "react-icons/md";
import { IoCheckbox } from 'react-icons/io5';
import DisableConfirmation from "../disableAccount/disableConfirmation";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { disableAccount } from "store/actions/userSecuritySettingsAction";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/actions/authAction";
import { useHistory } from 'react-router-dom';

function ConfirmDelete() {
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedAssets, setIsCheckedAssets] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;

    const handleCheckboxClick = () => {
        setIsChecked(!isChecked);
    };

    const handleAssetsCheckboxClick = () => {
        setIsCheckedAssets(!isCheckedAssets);
    };

    const openPopup = () => {
        if (isChecked && isCheckedAssets) {
            setIsPopupOpen(true);
        } else {
            toast.error("Please accept the terms and conditions.");
        }
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const handleConfirm = () => {
        const payload = {
            pass: 'freeze',
            reason: "deleted"
        };
    
        dispatch(disableAccount(payload, userId, token))
            .then(() => {
                setIsPopupOpen(false);
                dispatch(logout());  // Log the user out
                history.replace('/login'); // Redirect to login
            })
            .catch((error) => {
                console.error('Error disabling account:', error);
            });
    };

    return (
        <>
            <div className="flex items-center">
                <div className="flex items-center gap-2">
                    <MdAccountCircle className="text-[24px]" />
                    <span className="text-[24px] font-bold">Account Settings</span>
                </div>
                <div>
                    <MdNavigateNext className="text-[24px] text-[#DADADA]" />
                </div>
                <span className="text-[24px] font-bold">Delete Account</span>
            </div>
            <div className="bg-[#fff] mt-2 shadow rounded-xl p-20">
                <p className="font-semibold text-xl mb-3">Delete My Account</p>
                <span className="text-[#9C9C9C]">
                    Before processing your account deletion request, to confirm your account security, the BUYCEX team will perform the following checks before processing your deletion request.
                </span>
                <div className="bg-[#F3F3F3] rounded-xl p-3 !pl-10 !pr-10 mt-6 mb-6">
                    <span className="font-bold">1. Account Security</span>
                    <p className="mt-2">
                        The account is active and operational, with no history of unauthorized access, fraudulent transactions, or violations of platform terms.
                    </p>
                </div>
                <div className="bg-[#F3F3F3] rounded-xl p-3 !pl-10 !pr-10 mt-6 mb-6">
                    <span className="font-bold">2. Asset Settlement</span>
                    <li className="mt-2">The account currently has no active open orders or positions.</li>
                    <li>All account assets are fully processed and cleared, with no outstanding debts.</li>
                    <li>The total value of assets within the account is estimated at 0 USD or less.</li>
                    <li>The account contains no redeemable vouchers or bonuses, or you agree to relinquish any.</li>
                </div>
                <div className="bg-[#F3F3F3] rounded-xl p-3 !pl-10 !pr-10 mt-6 mb-6">
                    <span className="font-bold">3. Referral Benefits Expired</span>
                    <p className="mt-2">Any referral connections associated with your account will be terminated upon deletion.</p>
                </div>
                <div className="pl-10 pr-10">
                    <div className="flex items-center gap-2">
                        <div onClick={handleCheckboxClick} className="flex items-center cursor-pointer">
                            {isChecked ? (
                                <IoCheckbox className="text-lg text-[#EFB81C]" />
                            ) : (
                                <MdCheckBoxOutlineBlank className="text-lg text-[#EFB81C]" />
                            )}
                            <p className="ml-2">
                                I confirm my understanding of the account deletion process and its consequences, and willingly proceed.
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 mt-4">
                        <div onClick={handleAssetsCheckboxClick} className="flex items-center cursor-pointer">
                            {isCheckedAssets ? (
                                <IoCheckbox className="text-2xl text-[#EFB81C]" />
                            ) : (
                                <MdCheckBoxOutlineBlank className="text-2xl text-[#EFB81C]" />
                            )}
                            <p className="ml-2">
                                The estimated total value of assets in this account is 0 USD. I acknowledge that any assets in this account will be permanently lost once the account is deleted.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <button
                        type="button"
                        onClick={openPopup}
                        className="text-black mt-10 bg-[#E6E6E6] hover:bg-[#E6E6E6] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5"
                    >
                        Confirm Delete
                    </button>
                </div>
            </div>

            <DisableConfirmation
                isOpen={isPopupOpen}
                onClose={closePopup}
                onConfirm={handleConfirm}
                source="deleteAccount" 
            />
        </>
    );
}

export default ConfirmDelete;