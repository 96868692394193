import React, { useState } from "react";
import { BsTrash } from "react-icons/bs";
import OrderListBody from "./OrderListBody";

const navItems = [
    { id: "activeNavOpenOrder", label: "Open Orders (0)" },
    { id: "activeNavOrderHistory", label: "Order History" },
    { id: "activeNavTradeHistory", label: "Trade History" },
    { id: "activeNavAssets", label: "Assets" },
    { id: "activeNavCopyTrades", label: "Copy Trades (0)" },
];

const subNav = {
    activeNavOpenOrder: [
        "Active Orders",
        "Conditional",
        "TP/SL",
        "OCO",
        "Trailing Stop",
    ],
    activeNavOrderHistory: [
        "Limit | Market Orders",
        "TP/SL Orders",
        "Conditional Orders",
        "OCO",
        "Trailing Stop",
    ],
    activeNavTradeHistory: [],
    activeNavAssets: [],
    activeNavCopyTrades: [],
};

const OrderListHeader = () => {
    const [activeNav, setActiveNav] = useState("activeNavOpenOrder");
    const [activeSubNav, setActiveSubNav] = useState("activeSubNavItem1");

    const handleActiveNav = (navItem) => {
        setActiveNav(navItem);
        setActiveSubNav("activeSubNavItem1");
    };

    const handleActiveSubNav = (subNavItem) => {
        setActiveSubNav(subNavItem);
    };

    const currentSubNavItems = subNav[activeNav] || [];

    return (
        <div className="flex flex-col">
            {/* Order List Header */}
            <div className="flex items-center gap-4 border-b order-list-header">
                {navItems.map((item) => (
                    <button
                        key={item.id}
                        type="button"
                        className={`order-list-header-item border-b-[2.5px] px-2.5 py-2 rounded-tl-md rounded-tr-md hover:text-[#151415]  dark:hover:text-[#FFFFFF] ${
                            activeNav === item.id
                                ? "border-[#EFB81C] text-[#151415]  dark:text-[#FFFFFF]"
                                : "border-transparent bg-transparent text-[#15141580]  dark:text-[#71777A]"
                        }`}
                        onClick={() => handleActiveNav(item.id)}
                    >
                        <span className="text-[14px] font-[500]">
                            {item.label}
                        </span>
                    </button>
                ))}
            </div>

            {/* Order List Sub Header */}
            {currentSubNavItems.length > 0 && (
                <div className="flex items-center justify-between gap-4 my-3.5">
                    <div className="flex items-center gap-2 rounded-md order-list-header__sub-nav w-max">
                        {currentSubNavItems.map((subItem, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`px-4 py-[1px] rounded-lg order-list-header-item w-max ${
                                    activeSubNav ===
                                    `activeSubNavItem${index + 1}`
                                        ? "bg-[#F4F4F4] dark:bg-[#25282C]"
                                        : "text-[#15141580] hover:text-[#151415]  dark:text-[#71777A] dark:hover:text-[#FFFFFF]"
                                }`}
                                onClick={() =>
                                    handleActiveSubNav(
                                        `activeSubNavItem${index + 1}`
                                    )
                                }
                            >
                                <span className="text-[12px] font-[500]">
                                    {subItem}
                                </span>
                            </button>
                        ))}
                    </div>

                    {/* Cancel All Orders */}
                    {/* <div className="order-list-header__cancel">
                        <button
                            type="button"
                            className="px-2.5 py-1.5 rounded-md bg-[#F4F4F4] hover:text-[#FF0000] flex items-center gap-1 order-list-header__cancel-btn"
                        >
                            <BsTrash size={12} />
                            <span className="text-[11px] font-[500]">
                                Cancel All Orders
                            </span>
                        </button>
                    </div> */}
                </div>
            )}

            {/* Order List Body */}
            <OrderListBody activeNav={activeNav} />
        </div>
    );
};

export default OrderListHeader;
