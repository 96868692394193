import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from "reactstrap";

import CustomDropdownWithImage from "../../../components/CustomDropdownWIthImage/CustomDropdownWithImage";
import SwapButton from "../../../components/cryptoConvertComponents/swapButton";
import { getConversion, getQuote } from 'store/actions/OTCAction';
import toast from "react-hot-toast";
const OTCForm = ({
  selectedCoinFrom,
  setSelectedCoinFrom,
  selectedCoinTo,
  setSelectedCoinTo,
  valueFrom,
  setValueFrom,
  valueTo,
  setValueTo,
  handleSwap,
  selectedCoinFromOptions,
  selectedCoinToOptions,
  excludeValuesTo,
  excludeValuesFrom,

}) => {
  const { loading, error, tradeData, balances } = useSelector((state) => state.cryptoConvert);
  const dispatch = useDispatch();
  const [inputError, setInputError] = useState(false);
  const [quoteLoading, setQuoteLoading] = useState(false);
  const user = useSelector((state) => state.authUser?.user || {});
  const userId = user?.ID;
  const token = user?.TOKEN;

  const selectedCoinBalance = balances[selectedCoinFrom?.value?.toLowerCase()] || { balance: 0 };

  // Function to handle input changes in the FROM field
  const handleInputChange = async (e) => {
    const inputValue = e.target.value;
    const availableBalance = selectedCoinBalance.balance || 0;

    // Handle error if input exceeds balance
    if (parseFloat(inputValue) > availableBalance) {
      setInputError(true);
      setValueFrom(inputValue);
      return;
    } else {
      setInputError(false);
    }

    setValueFrom(inputValue);

    // Trigger conversion API call
    if (selectedCoinFrom?.value && selectedCoinTo?.value && inputValue) {
      try {
        const response = await dispatch(
          getConversion(
            selectedCoinFrom.value, // FROM coin
            parseFloat(inputValue), // Entered amount
            selectedCoinTo.value, // TO coin
            token, // Auth token
            userId // User ID
          )
        );
        console.log("getConversion", response.data)
        if (response && response.success) {
          setValueTo(response.data);
        }
      } catch (err) {
        console.error('Conversion error:', err);
      }
    }
  };

  const handleAllButtonClick = () => {
    const availableBalance = selectedCoinBalance.balance || 0;
    setValueFrom(availableBalance.toString());
  };




  const handleGetQuote = async (e) => {
    e.preventDefault();

    if (!selectedCoinFrom?.value || !selectedCoinTo?.value || !valueFrom) {
      alert("Please fill out all fields before requesting a quote.");
      return;
    }

    setQuoteLoading(true);

    try {
      const response = await dispatch(
        getQuote(
          selectedCoinFrom.value,
          selectedCoinTo.value,
          parseFloat(valueFrom),
          parseFloat(valueTo || 0),
          "buy",
          token,
          userId
        )
      );
      console.log("response getQuote", response)
      if (response && response.success) {

        console.log("Quote data:", response.data);
        setValueTo(response.data.price || ""); // Set the converted value
      }
    } catch (error) {
      console.error("Error fetching quote:", error);
    } finally {
      setQuoteLoading(false);
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleGetQuote}>
      {/* FROM Section */}
      <div className={`dark:bg-[#404347] mt-4 rounded-2xl ${inputError ? 'incorrect-data-highlight-div' : ''}`}>
        <div className='bg-[#f9f9fb] rounded-2xl p-3'>
          <div className="flex justify-between w-full">
          <p className="block text-sm font-medium text-gray-400 pb-2">From</p>
            <p className="text-xs text-gray-400">
              Available: {selectedCoinBalance.balance || "--"} {selectedCoinFrom?.value}
            </p>
          </div>
          <div className="flex flex-row-reverse justify-between items-center pr-3 pt-3">
            <CustomDropdownWithImage
              options={selectedCoinFromOptions}
              selectedValue={selectedCoinFrom}
              setSelectedValue={setSelectedCoinFrom}
              excludeValues={excludeValuesTo}
            />
            <div className="w-full flex items-center justify-between">
              <input
                type="text"
                value={valueFrom}
                onChange={handleInputChange}
                placeholder="0.01 - 34500"
                className={`bg-inherit text-gray-400 dark:text-white rounded outline-none border-none ${inputError ? 'border-red-500' : ''}`}
                style={{ MozAppearance: "textfield" }}
              />
              <span className="flex gap-2">
                | <button type="button" className="text-yellow-700 cursor-pointer" onClick={handleAllButtonClick}>ALL</button>
              </span>
            </div>
          </div>
          {inputError && <p className="text-red-500 text-xs mt-2">Insufficient funds</p>}
        </div>
      </div>

      <SwapButton handleSwap={handleSwap} />

      {/* TO Section */}
      <div className="!mt-[50px]">
        <div className='bg-[#f9f9fb] dark:bg-[#404347] rounded-2xl mt-4 p-3'>
          <div className="flex justify-between w-full">
          <p className="block text-sm font-medium text-gray-400 pb-1">To</p>
            <p className="text-xs text-gray-400">
              Available: {balances[selectedCoinTo?.value?.toLowerCase()] !== undefined
                ? balances[selectedCoinTo?.value?.toLowerCase()].balance
                : "--"} {selectedCoinTo?.value}
            </p>
          </div>
          <div className="flex items-center w-full justify-between pt-3 flex-row-reverse">
            <CustomDropdownWithImage
              options={selectedCoinToOptions}
              selectedValue={selectedCoinTo}
              setSelectedValue={setSelectedCoinTo}
              excludeValues={excludeValuesFrom}
            />
            <span>{loading ? <Spinner size="sm" /> : valueTo || "--"}</span>
          </div>
        </div>
        <div className="flex items-center my-4 justify-center">
          <span className="flex-1 border-t border-gray-300"></span>
          <span className="px-4 text-center">getConversionRateText</span>
          <span className="flex-1 border-t border-gray-300"></span>
        </div>
        <div className="flex justify-between mb-3 text-sm text-gray-400">
          <span>Transaction Fees</span>
          <span className="text-[#EFB81C]">0 Fee</span>
        </div>
        <div className="flex justify-between mb-3 text-sm text-gray-400">
          <span>Receivables</span>
          <span className="text-neutral-900 dark:text-white"><span>{loading ? <Spinner size="sm" /> : valueTo || "--"}{excludeValuesFrom}</span></span>
        </div>
      </div>

      <button
        type="submit"
        className={`w-full bg-yellow-500 text-black p-2 rounded font-medium ${inputError ? "cursor-not-allowed" : "cursor-pointer"
          }`}
        disabled={inputError || quoteLoading}
      >
        {quoteLoading ? <Spinner size="sm" /> : "Get a Quote"}
      </button>
    </form>
  );
};

export default OTCForm;
