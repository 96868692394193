import React, { useState } from 'react';
import HistoryDropdown from '../../SpotHistory/DropDownUI';
import { DatePicker, Modal, Input } from 'antd';
import { createGlobalStyle, styled } from "styled-components";
import dayjs from 'dayjs';
import NoData from "assets/img/user/No-data.svg";
import { TbArrowsLeftRight } from "react-icons/tb";

const { RangePicker } = DatePicker;

function Transfer() {
  const options1 = ["Fiat and Spot", "USDⓈ-M Futures", "COIN-M Futures", "Pool", "P2P", "Isolated Margin"];
  const option2 = ["Past 7 days", "Past 30 days", "Past 90 days", "Customized"];
  const option3 = ["All", "1000SATS", "1INCH", "ACA", "ACH", "ADA", "ACM", "ADD"];
  const options4 = ["Options", "P2P", "USDⓈ-M Futures", "COIN-M Futures", "Pool", "P2P", "Isolated Margin", "Cross Margin", "Funding", "Trading Bots", "Copy Trading", "Tokocrypto"];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Past 7 days");

  const handleSelect1 = (selected) => {
    console.log("Dropdown 1 selected:", selected);
  };

  const handleSelect2 = (selected) => {
    if (selected === "Customized") {
      setIsModalVisible(true);
    } else {
      setSelectedOption(selected);
    }
  };

  const handleOk = () => {
    if (selectedDates && selectedDates.length === 2) {
      const formattedStartDate = dayjs(selectedDates[0]).format('YYYY-MM-DD');
      const formattedEndDate = dayjs(selectedDates[1]).format('YYYY-MM-DD');
      setSelectedOption(`${formattedStartDate} - ${formattedEndDate}`);
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <div className='flex gap-4 items-center'>
        <HistoryDropdown
          label="From"
          options={options1}
          initialSelected="Fiat and Spot"
          onSelect={handleSelect1}
          isSearchable={false}
          width="80px"
        />
        <TbArrowsLeftRight className='opacity-50'/>
        <HistoryDropdown
          label="To"
          options={options4}
          initialSelected="Cross Margin"
          onSelect={handleSelect1}
          isSearchable={false}
          width="80px"
        />
        <HistoryDropdown
          label="Time"
          options={option2}
          initialSelected={selectedOption}
          onSelect={handleSelect2}
          isSearchable={false}
          width="120px"
        />
        <Modal
          title="Select Date Range"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="flex flex-col gap-4">
            <p className='mt-10'>Select your time range within 12 months.</p>
            <GlobalStyles />
            <StyledRangePicker
              onChange={(dates) => setSelectedDates(dates)}
            />
            <button key="ok" type="primary" onClick={handleOk} className='px-4 py-2 my-7 font-semibold bg-[#EFB81C] rounded-lg'>Continue</button>
          </div>
        </Modal>
        <HistoryDropdown
          label="Coin"
          options={option3}
          initialSelected="All"
          onSelect={handleSelect1}
          isSearchable={true}
          width="80px"
        />
        <button className='text-[#EFB81C] font-semibold ml-2'>Reset</button>
      </div>
      <div className="text-xs mt-12 text-[#707a8a] w-full overflow-x-auto">
        <div className="flex justify-between border-b pb-3 w-full">
          <p className="flex-[2] text-start">Date</p>
          <p className="flex-1 text-start">Coin</p>
          <p className="flex-1 text-start">Amount</p>
          <p className="flex-1 text-center">From</p>
          <p className="flex-1 text-center">To</p>
        </div>
        <div className="w-full flex justify-center items-center h-96">
          <div className='flex flex-col gap-1 h-44 justify-center items-center'>
            <img src={NoData} alt="No Data" />
            <p className='text-[#707a8a] text-sm'>No records</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const GlobalStyles = createGlobalStyle`
  .ant-picker:hover {
    border-color: #EFB81C !important;
  }

  .ant-picker-focused {
    border-color: #EFB81C !important;
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important;
  }

  .ant-picker-active-bar {
    background: #EFB81C !important;
  }

  .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EFB81C !important;
    color: white !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    border: 1px solid #EFB81C !important;
    border-radius: 4px;
    content: "";
  }

  .ant-picker-dropdown .ant-picker-header-view > button:hover {
    color: #EFB81C !important;
  }
`;

const StyledRangePicker = styled(RangePicker)`
  .ant-picker-input > input:focus {
    box-shadow: none !important;
    border-color: #EFB81C !important;
  }
`;

export default Transfer;