import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resendPhoneOtp, sendForgotCode, verifyPhone, verifyPhoneNew, verifyPhonePass } from "store/actions/authAction";
import { Spinner } from "reactstrap";
import toast from "react-hot-toast";

function PhoneVerification({ onSuccess, phoneasprop, handleSwitchVerificationTool }) {
const { loading } = useSelector((state) => state.auth);
const [phone, setPhone] = useState("");
const [password, setPassword] = useState("");
const [otp, setOtp] = useState(Array(6).fill(""));
const [isVerifyBtnVisible, setIsVerifyBtnVisible] = useState(false);
const inputRefs = useRef([]);
const dispatch = useDispatch();
const [resendTimer, setResendTimer] = useState(60);
    const [resendDisabled, setResendDisabled] = useState(true);

useEffect(() => {
    const storedPhone = localStorage.getItem("phone");
    const storedPassword = localStorage.getItem("password");
    if (storedPhone) {
        setPhone(storedPhone);
    } else {
        setPhone(phoneasprop);
    }
    if (storedPassword) setPassword(storedPassword);
}, [phoneasprop]);

useEffect(() => {
    const handleKeyDown = (e) => {
        const activeIndex = inputRefs.current.findIndex((input) => input === document.activeElement);
        handleBackspace(e, activeIndex);
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
        window.removeEventListener("keydown", handleKeyDown);
    };
}, [otp]);

useEffect(() => {
    if (resendTimer > 0) {
        const timer = setInterval(() => {
            setResendTimer(prevTimer => prevTimer - 1);
        }, 1000);

        return () => clearInterval(timer);
    } else if (resendTimer === 0) {
        setResendDisabled(false);
    }
}, [resendTimer]);

const handleChange = (e, index) => {
    const { value } = e.target;
    if (!/^\d*$/.test(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value.slice(-1);
    setOtp(newOtp);

    if (value && index < 5) {
        inputRefs.current[index + 1].focus();
    } else if (!value && index > 0) {
        inputRefs.current[index - 1].focus();
    }

    setIsVerifyBtnVisible(newOtp.every((digit) => digit !== ""));
};

const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
        inputRefs.current[index - 1].focus();
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
    }
};

const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    if (paste.length === 6 && /^\d+$/.test(paste)) {
        const newOtp = paste.split("");
        setOtp(newOtp);
        inputRefs.current.forEach((input, i) => {
            input.value = newOtp[i];
        });
        setIsVerifyBtnVisible(true);
    }
};

const verifyOtp = async (e) => {
    e.preventDefault();
    const otpString = otp.join("");
    const payload = {
        cellphone: phone,
        code: otpString,
        // password,
    };

    try {
        await dispatch(verifyPhonePass(payload, () => {
            // toast.success("Phone verified successfully, Please login");
            onSuccess();
        }));
    } catch (error) {
        toast.error("Failed to verify phone");
        console.error("Error verifying phone:", error);
    }
};

const resendCode = async () => {
    // Retrieve authUser data from local storage
    let payload = {
        phone: phone,
    };
    dispatch(sendForgotCode(payload))
};    

return (
    <div className="bg-white flex flex-col gap-2 items-center w-max h-max text-[#000] py-4">
        <div className="mr-auto mb-2">
            <h1 className="text-xl font-bold">Phone Verification</h1>
            <p className="opacity-30 text-xs">Enter the verification code sent to your phone</p>
        </div>
        <form onSubmit={verifyOtp}>
            <div className="mx-8">
                <div className="flex gap-1" onPaste={handlePaste}>
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            ref={(el) => (inputRefs.current[index] = el)}
                            className={`border border-slate-400 w-[50px] h-[50px] text-center rounded-md bg-transparent focus:bg-transparent active:bg-transparent ${digit ? "bg-slate-100" : ""}`}
                            type="text"
                            maxLength="1"
                            value={digit}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleBackspace(e, index)}
                        />
                    ))}
                </div>
            </div>
            {handleSwitchVerificationTool && (
            <button className=" text-yellow-500 text-[10px] border-none outline-none focus:outline-none bg-transparent w-full text-left" onClick={handleSwitchVerificationTool}>switch verification tool</button>
            )}
            <button className="text-yellow-500 text-sm border-none outline-none focus:outline-none bg-transparent w-full text-center" onClick={resendCode} disabled={loading}>
                Resend Code {loading && <Spinner size="sm" />}
            </button>
            {isVerifyBtnVisible && (
                <button type="submit" className="btn font-semibold text-white text-[14px] py-2 mt-6 w-full bg-[#F1BA19] focus:outline-none">
                    Next
                </button>
            )}
        </form>
    </div>
);
}

export default PhoneVerification;
