import Repository from "./Repository";

const LOGIN = "/Login";

const getHeaders = (token, id) => ({
    token: token,
    id: id,
    "Content-type": "multipart/form-data",
});

const checkCellphone = (payload, token, id) => {
    const formData = new FormData();
    formData.append("cellphone", payload.cellphone);

    const headers = getHeaders(token, id);

    return Repository.post(`${LOGIN}/check_cellphone`, formData, {
        headers: headers,
    });
};
const sendNewCellphone = (newCellphone, token, id) => {
    const formData = new FormData();
    formData.append("new_cellphone", newCellphone);

    const headers = getHeaders(token, id);

    return Repository.post("/User/sendnewCellphone", formData, {
        headers: headers,
    });
};
const updateCellphone = (cellphone) => {
    const { cellphone: phone, verificationCode, userToken, userID } = cellphone;
    const formData = new FormData();
    formData.append("cellphone", phone);
    formData.append("cellphone_verify", verificationCode);

    const headers = getHeaders(userToken, userID);

    return Repository.post("/User/upcellphone", formData, {
        headers: headers,
    });
};

export default {
    checkCellphone,
    sendNewCellphone,
    updateCellphone,
};
