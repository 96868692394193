import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { X } from "lucide-react";
import { confirm2faCodelogin } from "store/actions/authAction";
import { Link } from "react-router-dom";

const Email2faVerification = ({ onClose, onSuccess ,userid}) => {

    const inputRefs = useRef([]);
    const dispatch = useDispatch();
    const [g2aCode, setG2aCode] = useState(Array(6).fill(""));

    useEffect(() => {
        const handleKeyDown = (e) => {
            const activeIndex = inputRefs.current.findIndex(
                (input) => input === document.activeElement
            );
            handleBackspace(e, activeIndex);
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [g2aCode]);

    const handleG2aCodeChange = (e, index) => {
        const { value } = e.target;
        if (!/^\d*$/.test(value)) return;
        const newG2aCode = [...g2aCode];
        newG2aCode[index] = value.slice(-1);
        setG2aCode(newG2aCode);

        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        } else if (!value && index > 0) {
            inputRefs.current[index - 1].focus();
        }

        if (newG2aCode.every((digit) => digit !== "")) {
            verifyG2faCodeHandler(newG2aCode);
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && index > 0 && g2aCode[index] === "") {
            inputRefs.current[index - 1].focus();
            const newG2aCode = [...g2aCode];
            newG2aCode[index - 1] = "";
            setG2aCode(newG2aCode);
        }
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData("text");
        if (paste.length === 6 && /^\d+$/.test(paste)) {
            const newG2aCode = paste.split("");
            setG2aCode(newG2aCode);
            inputRefs.current.forEach((input, i) => {
                input.value = newG2aCode[i];
            });
            verifyG2faCodeHandler(newG2aCode);
        }
    };

    const verifyG2faCodeHandler = (code) => {
        const payload = {
            user_id: userid ||localStorage.getItem("user_id_2fa"),
            code: parseInt(code.join("")),
        };

        dispatch(confirm2faCodelogin(payload, onSuccess));
    };

    return (
        <>

            <div className="fixed inset-0 bg-[#151415] bg-opacity-70 backdrop-blur-sm">
                <div className="bg-white absolute inset-0 flex-col z-[5] flex gap-2 items-center justify-center w-max h-max m-auto text-[#000] border border-black p-8 rounded-md">
                    <button onClick={onClose} className="absolute right-2 top-2">
                        <X className="w-[20px] h-[20px]" />
                    </button>
                    <div className="mr-auto mb-2">
                        <h1 className="text-xl font-bold">Email Verification</h1>
                        <p className="opacity-30 text-xs">
                            Enter Email verification code
                        </p>
                    </div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="flex gap-1" onPaste={handlePaste}>
                            {g2aCode.map((digit, index) => (
                                <input
                                    key={index}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    className={`border border-slate-400 w-[50px] h-[50px] text-center rounded-md bg-transparent focus:bg-transparent active:bg-transparent ${digit ? "bg-slate-100" : ""
                                        }`}
                                    type="text"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleG2aCodeChange(e, index)}
                                    onKeyDown={(e) => handleBackspace(e, index)}
                                />
                            ))}
                        </div>
                    </form>
                    <div className="w-full text-right">
                        <Link to="/reset-security-settings" className="text-green-600 text-md font-bold">
                            Use Other Verify Method?
                        </Link>
                    </div>

                </div>


            </div>
        </>
    );
};

export default Email2faVerification;