import React, { useState } from 'react';
import UserGuideStep1 from "assets/img/user-guide-step-1.png";
import UserGuideStep2 from "assets/img/user-guide-step-2.png";
import UserGuideStep3 from "assets/img/user-gide-step-3.png";
import UserGuideStep4 from "assets/img/user-gide-step-4.png";
import UserGuideStep5 from "assets/img/user-gide-step-5.png";

const steps = [
    {
        imgsrc: UserGuideStep1,
        header: 'Generate Key',
        text: 'You need to archive the 32-digit key generated by BUYXCEX and copy the key at the same time',
    },
    {
        imgsrc: UserGuideStep2,
        header: 'Add Key',
        text: 'Open the authenticator on your phone, add a BUYXCEX account, and enter the 32-digit key you copied.',
    },
    {
        imgsrc: UserGuideStep3,
        header: 'Copy',
        text: 'After adding the key, a 6-digit verification code will be automatically generated, and copy the code.',
    },
    {
        imgsrc: UserGuideStep4,
        header: 'Enter Code',
        text: 'Return to BUYCEX, and fill in the 6-digit verification code copied, from the authenticator app into the corresponding box.',
    },
    {
        imgsrc: UserGuideStep5,
        header: 'Bound Successfully',
        text: 'OTP bound successfully.',
    }
];

const InnerWrapper = ({ imgsrc, header, text, step }) => (
    <div className='flex flex-col gap-2 mb-3'>
        <img src={imgsrc} alt={header} />
        <h2 className=' font-semibold text-lg'>{header}<span className=' text-sm font-normal'> [{step + 1}/5]</span></h2>
        <p className=' text-sm'>{text}</p>
    </div>
);

const UserGuideModal = ({ show, onClose }) => {
    const [currentStep, setCurrentStep] = useState(0);

    if (!show) return null;

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrev = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleDone = () => {
        onClose();
    };

    return (
        <div className='fixed inset-0 modal-outer flex items-center justify-center bg-gray-800 bg-opacity-50 z-50'>
            <div className="relative modal-inner bg-white rounded-xl shadow-lg p-6 w-1/3">
                <button
                    onClick={handleDone}
                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <h1 className=' font-bold text-xl mb-2'>Bind OTP Authenticator</h1>
                <InnerWrapper
                    imgsrc={steps[currentStep].imgsrc}
                    header={steps[currentStep].header}
                    text={steps[currentStep].text}
                    step={currentStep}
                />
                <div className='flex justify-between gap-2'>
                    {currentStep > 0 && <button onClick={handlePrev} className=' py-2 bg-yellow-100 text-yellow-500 w-full text-center rounded-md'>Previous</button>}
                    {currentStep < steps.length - 1 && <button onClick={handleNext} className=' py-2 bg-yellow-500 text-white w-full text-center rounded-md'>Next</button>}
                    {currentStep === steps.length - 1 && <button onClick={handleDone} className=' py-2 bg-yellow-500 text-white w-full text-center rounded-md'>Done</button>}
                </div>
            </div>
        </div>
    );
}

export default UserGuideModal;
