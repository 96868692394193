import React, { useState } from "react";
import PropTypes from "prop-types";

import useOrderBook from "../hooks/useOrderBook";
import useLastTrade from "../hooks/useLastTrade";

import "../scss/OrderBook.scss";

import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { useSelectedCoin } from "../context/SelectedCoinContext";

const OrderBook = () => {
    const { selectedCoin } = useSelectedCoin();

    console.log("Selected Coin:", selectedCoin);

    const { orderBook, error: orderBookError } = useOrderBook(selectedCoin);
    const { marketTrades, error: marketTradesError } =
        useLastTrade(selectedCoin);

    const [activeTab, setActiveTab] = useState("orderBook");

    const calculateMidpointPrice = () => {
        if (orderBook.bids.length > 0 && orderBook.asks.length > 0) {
            const highestBid = parseFloat(orderBook.bids[0][0]);
            const lowestAsk = parseFloat(orderBook.asks[0][0]);
            return ((highestBid + lowestAsk) / 2).toFixed(4);
        }
        return "";
    };

    const calculateMidpointTotal = () => {
        if (orderBook.bids.length > 0 && orderBook.asks.length > 0) {
            const highestBidPrice = parseFloat(orderBook.bids[0][0]);
            const lowestAskPrice = parseFloat(orderBook.asks[0][0]);
            const highestBidAmount = parseFloat(orderBook.bids[0][1]);
            const lowestAskAmount = parseFloat(orderBook.asks[0][1]);

            const midpointPrice = (highestBidPrice + lowestAskPrice) / 2;
            const midpointAmount = (highestBidAmount + lowestAskAmount) / 2;

            return (midpointPrice * midpointAmount).toFixed(4);
        }
        return "";
    };

    const midpointPrice = calculateMidpointPrice();
    const midpointTotal = calculateMidpointTotal();

    const renderOrdersTable = (orders, type) => (
        <div className="order-table-container max-h-[255px] overflow-hidden overflow-y-scroll px-1.5 w-full">
            <table className="w-full table-auto orders-table">
                <tbody>
                    {orders.map((order, index) => {
                        const price = parseFloat(order[0]);
                        const amount = parseFloat(order[1]);
                        const total = price * amount;

                        return (
                            <tr key={index} className={`flex justify-between`}>
                                <td
                                    className={`text-left p-1 text-[12px] ${
                                        type === "bids"
                                            ? "text-green-500"
                                            : "text-red-500"
                                    } font-[500]`}
                                >
                                    {price.toFixed(3)}
                                </td>
                                <td className="text-left p-1 text-[12px]">
                                    {amount.toFixed(4)}
                                </td>
                                <td className="text-left p-1 text-[12px]">
                                    {total.toFixed(3)}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

    const renderMarketTradesTable = () => (
        <div className="order-table-container max-h-[255px] overflow-hidden overflow-y-scroll px-1.5 w-full">
            <table className="w-full table-auto trades-table">
                <tbody>
                    {marketTrades.map((trade, index) => {
                        const [datePart, timePart] = trade.addtime.split(" ");
                        const [day, month] = datePart.split("-").map(Number);
                        const [hours, minutes, seconds] = timePart
                            .split(":")
                            .map(Number);

                        // Create a new date object, using the current year
                        const currentYear = new Date().getFullYear();
                        const tradeDate = new Date(
                            currentYear,
                            month - 1,
                            day,
                            hours,
                            minutes,
                            seconds
                        );

                        return (
                            <tr key={index} className="flex justify-between">
                                <td className="text-left p-1 text-[12px]">
                                    {parseFloat(trade.price).toFixed(3)}
                                </td>
                                <td className="text-left p-1 text-[12px]">
                                    {parseFloat(trade.num).toFixed(4)}
                                </td>
                                <td className="text-left p-1 text-[12px]">
                                    {tradeDate.toLocaleTimeString()}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="w-full bg-white rounded-md dark:bg-[#101014] dark:text-[#FFFFFF]">
            <div className="flex flex-col">
                <div className="flex items-center border-b gap-4">
                    <button
                        type="button"
                        className={`text-[12px] py-[11px] font-[500] ${
                            activeTab === "orderBook"
                                ? "border-b-2 border-[#EFB81C]"
                                : "text-[#15141580]  dark:text-[#71777A] "
                        }`}
                        onClick={() => setActiveTab("orderBook")}
                    >
                        Order Book
                    </button>
                    <button
                        type="button"
                        className={`text-[12px]  py-[11px] font-[500] ${
                            activeTab === "marketTrades"
                                ? "border-b-2 border-[#EFB81C]"
                                : "text-[#15141580]  dark:text-[#71777A] "
                        }`}
                        onClick={() => setActiveTab("marketTrades")}
                    >
                        Recent Trades
                    </button>
                </div>
                <div className="order-book__header__table-header flex items-center text-left justify-between px-1.5 py-2">
                    <span className="text-[12px] text-[#15141580]  dark:text-[#71777A]  font-[500]">
                        Price (USDT)
                    </span>
                    <span className="text-[12px] text-[#15141580]  dark:text-[#71777A]  font-[500]">
                        {activeTab === "orderBook"
                            ? "Amount (BTC)"
                            : "Quantity (BTC)"}
                    </span>
                    <span className="text-[12px] text-[#15141580]  dark:text-[#71777A]  font-[500]">
                        {activeTab === "orderBook" ? "Total (USDT)" : "Time"}
                    </span>
                </div>
            </div>

            <div className="flex w-full h-full">
                {orderBookError || marketTradesError ? (
                    <p className="text-red-300 text-[12px] m-auto w-max max-w-[90%]">
                        Error: {orderBookError || marketTradesError}
                    </p>
                ) : (
                    <>
                        {activeTab === "orderBook" ? (
                            <div className="flex flex-col w-full gap-0">
                                {/* OrderBook Asks Table */}
                                {renderOrdersTable(orderBook.asks, "asks")}

                                {/* Midpoint Info */}
                                <div
                                    className={`midpoint-info flex justify-between gap-3 px-2.5 py-2 my-2 font-bold text-center ${
                                        parseFloat(selectedCoin.change) > 0
                                            ? "text-[#20B26C]"
                                            : "text-[#EF454A]"
                                    }`}
                                >
                                    <div
                                        className="flex items-center gap-1  dark:text-[#FFFFFF]"
                                        style={{ width: "calc(100%/2)" }}
                                    >
                                        <div className="text-[12px] text-left">
                                            {midpointPrice}
                                        </div>
                                        <div className="text-[12px] text-left">
                                            {parseFloat(selectedCoin.change) >
                                            0 ? (
                                                <BsArrowUp />
                                            ) : (
                                                <BsArrowDown />
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="text-[12px] text-left text-[#15141590]  dark:text-[#FFFFFF]"
                                        style={{ width: "calc(100%/2)" }}
                                    >
                                        ${midpointPrice}
                                    </div>
                                </div>

                                {/* OrderBook Bids Table */}
                                {renderOrdersTable(orderBook.bids, "bids")}
                            </div>
                        ) : (
                            renderMarketTradesTable()
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

OrderBook.propTypes = {
    selectedCoin: PropTypes.shape({
        ticker: PropTypes.string.isRequired,
        change: PropTypes.number.isRequired,
    }),
};

export default OrderBook;
