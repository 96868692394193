import Repository from "./Repository";
const AssetsEndPoint = "/Finance/assets";
const FinanceOrders = "/Finance/orders";
const FinanceOpenOrders = "/Finance/openOrders";
const FinanceOrdersHistoryEndPoint = "/Finance/orders_history";
const FinanceOrdersTradeHistoryEndPoint = "/Finance/trade_history";
const FinanceUserSpotEndPoint = "/Finance/userSpot";
const FinanceUserFundingEndPoint = "/Finance/userFunding";
const FinanceUserEarnEndPoint = "/Finance/userEarn";
const FiatBalanceEndPoint = "User/FiatBalance";
export default {
    getAssetsData(userId, token, type) {
        return Repository.get(`${AssetsEndPoint}`, {
            params: {
                user_id: userId,
                type: type,
            },
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    FinanceUserSpot(userID, userToken) {
        return Repository.get(`${FinanceUserSpotEndPoint}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    FinanceUserFunding(userID, userToken) {
        return Repository.get(`${FinanceUserFundingEndPoint}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    FinanceUserEarn(userID, userToken) { // New method for userEarn
        return Repository.get(`${FinanceUserEarnEndPoint}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    FinanceOrders(userID, userToken, payload) {
        return Repository.post(`${FinanceOrders}`,payload, {
            headers: {
                token: userToken,
                id: userID,
                'Content-Type': 'Multipart/form-data',
                'Accept': 'application/json'
            },
        });
    },

    FinanceOpenOrders(userID, userToken) {
        return Repository.get(`${FinanceOpenOrders}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
  
    FinanceOrdersHistory(userID, userToken) {
        return Repository.get(`${FinanceOrdersHistoryEndPoint}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },

    FinanceOrdersTradeHistory(userID, userToken) {
        return Repository.get(`${FinanceOrdersTradeHistoryEndPoint}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
    getFiatBalance(userID, userToken) {
        return Repository.get(`${FiatBalanceEndPoint}`, {
            headers: {
                token: userToken,
                id: userID,
            },
        });
    },
};
