import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaCaretUp, FaChevronRight, FaCaretDown } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa6";
import RoutingNavbar from "components/routingNavbar/RoutingNavbar";
// import TopCard from '../MarketPage/MarketComopenets/TopCard';
import TopGainerIcon from "assets/img/marketpageimgs/top-ganier-icon.png";
import TopLoserIcon from "assets/img/marketpageimgs/top-loser-icon.png";
import BCH from "assets/img/BCH.svg";
import p2p from "assets/img/p2p.svg";
import BuyCoin from "assets/img/BuyCoin.png";
import FAQ from "../global/FAQ";
import faqsData from "../global/faqsData";
import HowToBuy from "../global/HowToBuy";
import { LOADING } from "store/types";
import { useDispatch } from "react-redux";
import { fetchMarketData } from "store/actions/marketActions";

function OneClickBuy() {
    const [loading, setLoading] = useState(true);
    const marketData = useSelector((state) => state.market.data);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchMarketData());
    }, [dispatch]);

    useEffect(() => {
        if (marketData && marketData.market) {
            setLoading(false);
        }
    }, [marketData]);
    const [bankTransfer, setBankTransfer] = useState(false);
    const currencies = ["BTC", "ETH", "LTC", "USDT"]; // Dummy currency list
    const [activeSection, setActiveSection] = useState("Buy");
    const isLoggedIn = useSelector((state) => state.authUser.user !== null);

    const [selectedCurrency1, setSelectedCurrency1] = useState("BTC");
    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const [selectedCurrency2, setSelectedCurrency2] = useState("BTC");
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] =
        useState("Bank Transfer");

    const dropdownRef1 = useRef(null);
    const dropdownRef2 = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef1.current &&
                !dropdownRef1.current.contains(event.target)
            ) {
                setDropdownOpen1(false);
            }
            if (
                dropdownRef2.current &&
                !dropdownRef2.current.contains(event.target)
            ) {
                setDropdownOpen2(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleDropdown1 = () => setDropdownOpen1(!dropdownOpen1);
    const toggleDropdown2 = () => setDropdownOpen2(!dropdownOpen2);
    const [amount, setAmount] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [amount2, setAmount2] = useState("");
    const [errorMessage2, setErrorMessage2] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const selectCurrency1 = (currency) => {
        setSelectedCurrency1(currency);
        setDropdownOpen1(false);
    };

    const selectCurrency2 = (currency) => {
        setSelectedCurrency2(currency);
        setDropdownOpen2(false);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const selectPaymentMethod = (method) => {
        setSelectedPaymentMethod(method);
        setDropdownOpen(false);
    };

    const validateAmount = (value) => {
        const number = parseFloat(value);
        if (isNaN(number) || number < 10 || number > 5000) {
            setErrorMessage("Amount must be a number between 10 and 5000.");
        } else {
            setErrorMessage("");
        }
    };

    const validateAmount2 = (value) => {
        const number = parseFloat(value);
        if (isNaN(number) || number < 10 || number > 5000) {
            setErrorMessage2("Amount must be a number between 10 and 5000.");
        } else {
            setErrorMessage2("");
        }
    };

    const handleChange1 = (e) => {
        setAmount(e.target.value);
        validateAmount(e.target.value);
    };

    const handleChange2 = (e) => {
        setAmount2(e.target.value);
        validateAmount2(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validateAmount(amount, setErrorMessage);
        validateAmount(amount2, setErrorMessage2);

        if (!errorMessage && !errorMessage2 && isLoggedIn) {
            alert("Form submitted successfully", {
                amount,
                amount2,
                selectedCurrency1,
                selectedCurrency2,
            });
            // Additional form submission logic goes here
        } else {
            alert("You must be logged in to submit the form.");
        }
    };

    // Assuming marketData is already available as a prop
    const topListedData = marketData?.market
        ? marketData.market.slice(0, 3)
        : [];

    // Filter top gainers and top losers based on the topListedData
    const PostiveListedData =
        marketData.market &&
        marketData.market.filter((item) => !String(item.change).includes("-"));
    const negitiveListedData =
        marketData.market &&
        marketData.market.filter((item) => String(item.change).includes("-"));
    const topGainers = PostiveListedData ? PostiveListedData.slice(0, 3) : [];
    const topLosers = negitiveListedData ? negitiveListedData.slice(0, 3) : [];

    return (
        <>
            <main className="container py-10 w-full h-full dark:bg-[#000000]">
                <div className="flex flex-col items-center justify-between w-full h-full my-10 gap-28">
                    {/* section1 */}
                    <section className="flex flex-col items-center justify-between w-full gap-6 section1">
                        {/* nav (upper) */}
                        <RoutingNavbar />

                        {/* sub section(lower) */}

                        <div className="flex flex-col items-start justify-between w-full gap-3 mt-10 lower md:flex-row h-fit">
                            {/* left */}
                            <div className="left flex flex-col items-start justify-between md:w-[55%] w-full h-fit gap-4">
                                <h3 className="heading1 md:text-[28px] text-[20px] w-full self-start font-semibold">
                                    One Click Buy
                                </h3>
                                <p className="text-[24px] lg:flex hidden">
                                    65+ fiat currencies and 100+
                                    cryptocurrencies at your fingertips
                                </p>
                                {/* tables */}
                                <div className="flex flex-col items-center justify-start w-full gap-4 boxes-wrapper lg:flex-row">
                                    {/* Top Gainer Component */}

                                    {/* <TopCard
                                        isLoading={loading} // or true based on loading state
                                        data={topGainers}
                                        title={"Trending"}
                                        icon={TopGainerIcon}
                                        pageHref={"/market/top-gainers"}
                                    /> */}
                                    {/* Top Loser Component */}
                                    {/* <TopCard
                                        isLoading={loading} // or true based on loading state
                                        data={topLosers}
                                        title={"Top Losers"}
                                        icon={TopLoserIcon}
                                        pageHref={"/market/top-losers"}
                                    /> */}
                                </div>
                                {/* link box sub section */}
                                <div className="w-full rounded-[20px] flex justify-start items-center border p-2">
                                    <Link
                                        to="/"
                                        className="text-[20px] font-medium ml-2"
                                    >
                                        How Can You Buy Coin Instantly?
                                    </Link>
                                    <img
                                        src={BuyCoin}
                                        alt=""
                                        className="ml-auto bg-transparent"
                                    />
                                </div>
                            </div>
                            {/* right (form) */}
                            <form
                                onSubmit={handleSubmit}
                                className="lg:w-[30%] md:w-[55%] w-full rounded-xl shadow-md flex flex-col justify-between gap-5 items-center p-2 h-full md:p-4"
                            >
                                <div className="flex items-center justify-between w-full p-1 border rounded-md group">
                                    <button
                                        onClick={() => setActiveSection("Buy")}
                                        className={`${
                                            activeSection === "Buy"
                                                ? "bg-[#EFB81C]  text-white dark:text-black"
                                                : "text-black dark:text-white"
                                        } font-semibold py-2 w-full rounded-md text-center transition-colors duration-500 ease-in-out`}
                                    >
                                        Buy
                                    </button>
                                    <button
                                        onClick={() => setActiveSection("Sell")}
                                        className={`${
                                            activeSection === "Sell"
                                                ? "bg-[#EFB81C] text-white dark:text-black"
                                                : "text-black dark:text-white"
                                        } font-semibold py-2 w-full text-center rounded-md transition-colors duration-500 ease-in-out`}
                                    >
                                        Sell
                                    </button>
                                </div>
                                {/* input1 */}
                                <div className="flex flex-col items-start justify-start w-full">
                                    <label className="text-[16px] md:text-[16px] text-black dark:text-white">
                                        Spend
                                    </label>
                                    <div className="w-full flex items-center justify-between group border p-2 rounded-md group-focus-within:border-[#FBBF24] group-focus-within:shadow-focus-outline">
                                        <input
                                            type="text"
                                            name="amount"
                                            value={amount}
                                            onChange={handleChange1}
                                            placeholder="Limit: 10-5000"
                                            className="w-full bg-transparent md:placeholder:text-[18px] placeholder:text-[16px] p-2 placeholder:text-[#DBDBDB] dark:placeholder:text-white border-none outline-none"
                                            required
                                        />
                                        <div className="flex md:w-[45%] w-full justify-end items-center relative">
                                            <div
                                                className="mx-2 justify-between items-center text-md flex gap-2 text-black dark:text-white group-hover:text-[#FBBF24] cursor-pointer"
                                                onClick={toggleDropdown1}
                                            >
                                                {selectedCurrency1}
                                                <FaChevronDown
                                                    color="#DBDBDB"
                                                    size={12}
                                                />
                                            </div>
                                            {dropdownOpen1 && (
                                                <div
                                                    ref={dropdownRef1}
                                                    className="absolute right-0 z-10 w-32 mt-1 bg-white border rounded-md shadow-md top-full dark:bg-black"
                                                >
                                                    {currencies.map(
                                                        (currency) => (
                                                            <div
                                                                key={currency}
                                                                className="px-4 py-2 text-sm text-black dark:text-white hover:bg-[#FBBF24] cursor-pointer"
                                                                onClick={() =>
                                                                    selectCurrency1(
                                                                        currency
                                                                    )
                                                                }
                                                            >
                                                                {currency}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {errorMessage && (
                                        <p className="mt-1 text-sm text-red-500">
                                            {errorMessage}
                                        </p>
                                    )}
                                </div>
                                {/* input2 */}
                                <div className="flex flex-col items-start justify-start w-full">
                                    <label className="text-[16px] md:text-[16px] text-black dark:text-white">
                                        Receive
                                    </label>
                                    <div className="w-full flex items-center justify-between group text-[16px] border md:px-[12px] px-2 py-[11px] rounded-md">
                                        <input
                                            type="text"
                                            name="amount"
                                            value={amount2}
                                            onChange={handleChange2}
                                            placeholder={`Limit: 10-5000`}
                                            className="w-full bg-transparent md:placeholder:text-[18px] placeholder:text-[16px] p-2 dark:placeholder:text-white placeholder:text-[#DBDBDB] border-none outline-none"
                                            required
                                        />
                                        <div className="flex md:w-[45%] w-full justify-end items-center relative">
                                            <div
                                                className="mx-2 justify-between items-center text-md flex gap-2 text-black dark:text-white group-hover:text-[#FBBF24] cursor-pointer"
                                                onClick={toggleDropdown2}
                                            >
                                                {selectedCurrency2}
                                                <FaChevronDown
                                                    color="#DBDBDB"
                                                    size={12}
                                                />
                                            </div>
                                            {dropdownOpen2 && (
                                                <div
                                                    ref={dropdownRef2}
                                                    className="absolute right-0 z-10 w-32 mt-1 bg-white border rounded-md shadow-md top-full dark:bg-black"
                                                >
                                                    {currencies.map(
                                                        (currency) => (
                                                            <div
                                                                key={currency}
                                                                className="px-4 py-2 text-sm text-black dark:text-white hover:bg-[#FBBF24] cursor-pointer"
                                                                onClick={() =>
                                                                    selectCurrency2(
                                                                        currency
                                                                    )
                                                                }
                                                            >
                                                                {currency}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {errorMessage2 && (
                                        <p className="mt-1 text-sm text-red-500">
                                            {errorMessage2}
                                        </p>
                                    )}
                                    <div className="text-[#DBDBDB] dark:text-white md:text-[18px] text-[16px]">
                                        1 USDT ≈ 292.04123 BTC
                                    </div>
                                </div>
                                {/* 3 */}
                                <div className="flex flex-col items-start justify-between w-full">
                                    <label className="text-[16px] md:text-[16px] text-black dark:text-white">
                                        Payment Method
                                    </label>
                                    <div className="w-full flex items-center justify-between group text-[16px] border px-[12px] py-[11px] rounded-md">
                                        <div className="mx-2 w-full justify-start items-center text-md flex gap-2 text-black dark:text-white group-hover:text-[#FBBF24]">
                                            <img
                                                alt=""
                                                className="w-[25px] h-[25px] md:w-[25px] md:h-[25px]"
                                                src={
                                                    selectedPaymentMethod ===
                                                    "Bank Transfer"
                                                        ? BCH
                                                        : p2p
                                                }
                                            />
                                            {selectedPaymentMethod}
                                        </div>
                                        <div className="flex items-center">
                                            <FaChevronDown
                                                color="#DBDBDB"
                                                onClick={toggleDropdown}
                                                size={12}
                                            />
                                        </div>
                                        {dropdownOpen && (
                                            <div
                                                ref={dropdownRef}
                                                className="absolute lg:top-[90%] top-full md:top-[105%] right-[10%] dark:bg-black bg-white border rounded-md shadow-md z-10"
                                            >
                                                <div
                                                    className="px-4 py-2 text-sm text-black dark:text-white hover:bg-[#FBBF24] cursor-pointer flex items-center gap-2"
                                                    onClick={() =>
                                                        selectPaymentMethod(
                                                            "Bank Transfer"
                                                        )
                                                    }
                                                >
                                                    <img
                                                        alt=""
                                                        className="w-[25px] h-[25px]"
                                                        src={BCH}
                                                    />
                                                    Bank Transfer
                                                </div>
                                                <div
                                                    className="px-4 py-2 text-sm text-black dark:text-white hover:bg-[#FBBF24] cursor-pointer flex items-center gap-2"
                                                    onClick={() =>
                                                        selectPaymentMethod(
                                                            "P2P"
                                                        )
                                                    }
                                                >
                                                    <img
                                                        alt=""
                                                        className="w-[25px] h-[25px]"
                                                        src={p2p}
                                                    />
                                                    P2P
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="bg-[#EFB81C] hover:bg-[#FBBF24] dark:text-black text-white font-semibold py-2 w-full mt-4 rounded-md text-center"
                                >
                                    Buy USDT
                                </button>
                            </form>
                        </div>
                    </section>
                    {/* section2 */}
                    <HowToBuy />
                    {/* faq */}
                    <FAQ faqsData={faqsData} />
                </div>
            </main>
        </>
    );
}

export default OneClickBuy;
