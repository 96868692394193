import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { createGlobalStyle, styled } from "styled-components";
import AssetDropdown from './DropDownUI';
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import NoData from "assets/img/user/No-data.svg";
import { useDispatch, useSelector } from 'react-redux';
import { FinanceOrdersHistory } from 'store/actions/AssetsUserAction';

const { RangePicker } = DatePicker;

function OrderHistory() {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  const [orderListData, setOrderListData] = useState([]);
  const [marketListData, setMarketListData] = useState([]);
  const [filteredOrderListData, setFilteredOrderListData] = useState([]);

  useEffect(() => {
    const payload = {
      user_id: userID,
    };

    dispatch(FinanceOrdersHistory(userID, userToken, payload))
      .then((response) => {
        if (response) {
          const orderList = response.data?.data?.list || [];
          const marketList = response.data?.data?.market_list || [];
          setOrderListData(orderList);
          setMarketListData(marketList);
          setFilteredOrderListData(orderList);
        } else {
          console.error(response);
        }
      });
  }, [dispatch, userID, userToken]);

  const options1 = ["All", "1000SATS", "1INCH", "ACA", "AAVE", "ADX", "AED"];
  const options2 = ["All", "Pre-Market", "USDT", "FSUSD", "USDC", "TUSD", "BNB", "BTC", "ETH"];
  const options3 = ["All", "Buy", "Sell"];
  const options4 = ["Order Time", "Update Time"];

  const handleSelect1 = (selected) => {
    console.log("Dropdown 1 selected:", selected);
  };

  const renderCell = (data) => {
    return data !== undefined ? data : '-';
  };

  return (
    <>
      <div className='w-full flex justify-between items-center'>
        <div className='flex items-center w-[83%] gap-3'>
          <GlobalStyles />
          <StyledRangePicker className="px-2 w-[600px] h-[40px] bg-white sm:w-1/3 py-[0.55rem] border-l" />
          <AssetDropdown
            label="Base"
            options={options1}
            initialSelected="All"
            onSelect={handleSelect1}
            isSearchable={true}
          />
          -
          <AssetDropdown
            label="Quote"
            options={options2}
            initialSelected="All"
            onSelect={handleSelect1}
            isSearchable={true}
          />
          <AssetDropdown
            label="Side"
            options={options3}
            initialSelected="All"
            onSelect={handleSelect1}
            isSearchable={false}
            width="!w-[200px]"
          />
          <AssetDropdown
            label="Sort By"
            options={options4}
            initialSelected="Order Time"
            onSelect={handleSelect1}
            isSearchable={false}
          />
        </div>
        <div className='flex gap-2 items-center justify-end mr-3 w-[15%] '>
          <MdCheckBoxOutlineBlank />
          <p className='text-sm'>Hide all Canceled</p>
        </div>
      </div>
      <div className="flex gap-3 items-center mt-4">
        <button className='bg-[#EFB81C] px-3 py-2 rounded-md font-medium'>Search</button>
        <button className='px-2 py-2 rounded-md font-medium'>Reset</button>
      </div>
      <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
        <div className="flex justify-between border-b pb-3 w-[95%]">
          <p style={{ width: '10%' }}>Order Time</p>
          <p style={{ width: '10%' }}>Pair</p>
          <p style={{ width: '10%' }}>Type</p>
          <p style={{ width: '10%' }}>Side</p>
          <p style={{ width: '10%' }}>Average</p>
          <p style={{ width: '10%' }}>Price</p>
          <p style={{ width: '10%' }}>Executed</p>
          <p style={{ width: '10%' }}>Amount</p>
          <p style={{ width: '10%' }}>Amount per Iceberg Order</p>
          <p style={{ width: '10%' }}>Filled</p>
          <p style={{ width: '10%' }}>Total</p>
          <p style={{ width: '10%' }}>Trigger Conditions</p>
          <p style={{ width: '10%' }}>TP/SL</p>
          <p style={{ width: '10%' }}>Status</p>
        </div>
        {filteredOrderListData.length > 0 ? (
          <div className="w-full">
            {filteredOrderListData.map((order, index) => (
              <div key={index} className="flex justify-between border-b py-2 w-[95%]">
                <p style={{ width: '10%' }}>{renderCell(new Date(
                                                parseInt(order.addtime) * 1000
                                            ).toLocaleString())}</p>
                <p style={{ width: '10%' }}>{order.market.toUpperCase()}</p>
                <p style={{ width: '10%' }}>{order.type === 1 ? 'Buy' : 'Sell'}</p>
                <p style={{ width: '10%' }}>-</p>
                <p style={{ width: '10%' }}>{renderCell(parseFloat(order.price).toFixed(2))}</p>
                <p style={{ width: '10%' }}>{renderCell(parseFloat(order.price).toFixed(2))}</p>
                <p style={{ width: '10%' }}>-</p>
                <p style={{ width: '10%' }}>{renderCell(parseFloat(order.num).toFixed(2))}</p>
                <p style={{ width: '10%' }}>-</p>
                <p style={{ width: '10%' }}>-</p>
                <p style={{ width: '10%' }}>{parseFloat(order.num * order.price).toFixed(2)}</p>
                <p style={{ width: '10%' }}>-</p>
                <p style={{ width: '10%' }}>-</p>
                <p style={{ width: '10%' }}>{renderCell(order.status)}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full flex justify-center items-center h-96">
            <div className='flex flex-col gap-1 h-44 justify-center items-center'>
              <img src={NoData} alt="No Data" />
              <p className='text-[#707a8a] text-sm'>No records</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const GlobalStyles = createGlobalStyle`
  // Override hover state for picker
  .ant-picker:hover {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on hover */
  }

  // Override focus state for picker
  .ant-picker-focused {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on focus */
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important; /* Add shadow with color #EFB81C */
  }

  // Style for the active state bar in RangePicker
  .ant-picker-active-bar {
    background: #EFB81C !important; /* Change the active border bar color to #EFB81C */
  }

  // Style calendar cells with selected date
  .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EFB81C !important; /* Change the background color of selected date */
    color: white !important; /* Keep the text color white for contrast */
  }

  // Style for "Today" cell in the calendar dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
    border: 1px solid #EFB81C !important; /* Change border for today's date */
  }
`;

const StyledRangePicker = styled(RangePicker)`
  .ant-picker-input {
    padding-left: 10px; /* Adjust left padding */
  }
`;

export default OrderHistory;