/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";

const UserProfile = "/User/profile";
const UserDashboard = "/Finance/userDashboard";
const Password = "/User/uppassword";
const AuthCheck = "/User/auth_check";
const GetSet2FA = "/User/GetSet2FA";
const saveAntiPishing = "/User/saveAntiPhishing";
const doFreeze = "/User/doFreeze";
const bank = "/User/addbank";
const getbank = "/User/mybank";
const delbank = "/User/delbank";
const set2FA = "/User/set2FA";
const UpdatePass = "/User/updatepass";
const getUserAntiPhishingCode='User/getUserAntiPhishingCode';
const userRepository = {
    getUserInfo(userId, token) {
        return Repository.get(`${UserProfile}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },

    getUserDashboard(userId, token) {
        return Repository.get(`${UserDashboard}`, {
            params: {
                user_id: userId,
            },
            headers: {
                token: token,
                id: userId,
            },
        });
    },

    changePassword(payload) {
        const body = {
            oldpassword: payload.oldpassword,
            newpassword: payload.newpassword,
        };
        return Repository.post(`${Password}`, body, {
            headers: {
                "Content-type": "multipart/form-data",
                id: payload.id,
                token: payload.token,
            },
        });
    },

    updatePass(payload) {
        const body = {
            curpass: payload?.oldPassword,
            newpass: payload?.newPassword,
            repass: payload?.confirmNewPassword,
        };

        return Repository.post(`${UpdatePass}`, body, {
            headers: {
                "Content-type": "multipart/form-data",
                ID: payload.id,
                TOKEN: payload.token,
            },
        });
    },

    authCheck(userId, token) {
        return Repository.get(`${AuthCheck}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },

    bank(userId, token, payload) {
        return Repository.post(`${bank}`, payload, {
            headers: {
                token: token,
                id: userId,
                "Content-Type": "multipart/form-data",
            },
        });
    },

    getbank(userId, token) {
        return Repository.get(`${getbank}`, {
            headers: {
                token: token,
                id: userId,
                "Content-Type": "multipart/form-data",
            },
        });
    },

    delbank(userId, token, payload) {
        return Repository.post(`${delbank}`, payload, {
            headers: {
                token: token,
                id: userId,
                "Content-Type": "multipart/form-data",
            },
        });
    },

    get2FA(userId, token) {
        return Repository.get(`${GetSet2FA}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    set2FA(payload) {
        // const formData = new FormData();
        // formData.append('ga', payload.ga);
        // formData.append('type', payload.type);
        // formData.append('ga_transfer', payload.ga_transfer);
        // formData.append('ga_login', payload.ga_login);
        // formData.append('secret', payload.secret);
        // const body = {ga: payload.ga, type: payload.type, ga_transfer: payload.ga_transfer, ga_login: payload.ga_login};
        // console.log("body",body);
        // return false;
        return Repository.post(`${set2FA}`, payload, {
            headers: {
                token: payload.token,
                id: payload.userId,
                "Content-Type": "multipart/form-data",
            },
        });
    },
    updateGA(payload) {
        // const formData = new FormData();
        // formData.append('ga', payload.ga);
        // formData.append('type', payload.type);
        // formData.append('ga_transfer', payload.ga_transfer);
        // formData.append('ga_login', payload.ga_login);
        const body = {
            ga: payload.ga,
            type: payload.type,
            ga_transfer: payload.ga_transfer,
            ga_login: payload.ga_login,
        };
        return Repository.post(`${UpdateGA}`, body, {
            headers: {
                token: payload.token,
                id: payload.userId,
                "Content-Type": "application/json",
            },
        });
    },
    SaveAntiPishing(payload) {
        const formData = new FormData();
        formData.append("antiphishing", payload.antiphishing);

        return Repository.post(`${saveAntiPishing}`, formData, {
            headers: {
                TOKEN: payload.TOKEN,
                ID: payload.ID,
                "Content-Type": "multipart/form-data",
            },
        });
    },
    disableAccount(payload, userId, token) {
        return Repository.post(`${doFreeze}`, payload, {
            headers: {
                token: token,
                id: userId,
                "Content-Type": "multipart/form-data",
            },
        });
    },
    
    getUserAntiPhishingCode(userId, token) {

        console.log("user id and token ", userId,token)
        return Repository.get(`${getUserAntiPhishingCode}`, {
            headers: {
                token: token,
                id: userId,
                "Content-Type": "application/json",
            },
        });
    },


};

export default userRepository;
