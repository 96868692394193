import TransferRepository from 'repository/transferRepository';
import {
  DO_TRANSFER_REQUEST,
  DO_TRANSFER_SUCCESS,
  DO_TRANSFER_FAILURE,
} from '../types';

// Action to perform the transfer
export const doTransfer = (from, to, coin, amount, token, id) => async (dispatch) => {
  dispatch({ type: DO_TRANSFER_REQUEST });

  try {
    // Call repository function to perform the transfer
    const response = await TransferRepository.doTransfer(from, to, coin, amount, token, id);
    console.log("API Response", response);

    // Check response status
    if (response.data.status === 1) {
      // Assuming the success message is inside `data`
      dispatch({
        type: DO_TRANSFER_SUCCESS,
        payload: response.data.data || 'Transfer completed successfully!', // Adjust as per API response
      });
    } else {
      dispatch({ type: DO_TRANSFER_FAILURE, payload: 'Transfer failed' });
    }
  } catch (error) {
    dispatch({ type: DO_TRANSFER_FAILURE, payload: error.message });
  }
};
