import React, { useState, useEffect } from "react";
import { FaUserCircle } from "react-icons/fa";
import { FaCircle, FaCircleCheck } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../../../../store/actions/userProfileAction";
import { IoMdInformationCircle } from "react-icons/io";
import { Tooltip } from "antd";
import { CgMoreO } from "react-icons/cg";
import { IoClose } from "react-icons/io5";
import { MdVerified } from 'react-icons/md';
import { Link } from "react-router-dom/cjs/react-router-dom";
import OnlineAdsNav from "./OnlineAdsNav";
import OnlineAds from "./OnlineAds";
import Feedback from "./Feedback";

function AdvertiserDetail() {
  const dispatch = useDispatch();
  const { userProfile, error } = useSelector((state) => state.userInfo);
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  const user = useSelector((state) => state.authUser);
  const [activeComponent, setActiveComponent] = useState("Online Ads");
  const [checkedStates, setCheckedStates] = useState({
    email: false,
    sms: false,
    kyc: false,
    address: false,
  });

  const handleToggle = (type) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };
  useEffect(() => {
    dispatch(fetchUserProfile(userID, userToken));
  }, [dispatch, userID, userToken]);

  useEffect(() => {
    console.log("userProfile:", userProfile);
    console.log("userToken:" + userToken);
    console.log("user:" + userID);
  }, [userProfile]);

  const [isBalanceVisible, setIsBalanceVisible] = useState(false);

  const toggleBalanceVisibility = () => {
    setIsBalanceVisible((prevState) => !prevState);
  };

  return (
    <>
      <div className="bg-gradient-to-r from-[#f4fbfc] to-[#f6fcf7]">
        <div className="container">
          <button className="flex justify-end w-full text-sm items-center pt-2 gap-2"><Link to='/trade/p2p' className='flex items-center text-sm gap-1'>Back <IoIosArrowForward className="text-[12px]" /></Link></button>
          <div className="flex">
            <div className="flex items-start md:items-start lg:items-center justify-between flex-col md:flex-col lg:flex-row gap-6 w-full pt-2 pb-5">
              <div className="flex items-start md:items-start lg:items-center justify-between flex-col md:flex-col lg:flex-row w-full md:w-full lg:w-full gap-0 md:gap-0 lg:gap-4">
                <div className="flex items-start gap-3">
                  <FaUserCircle className="text-[54px] md:text-[54px] lg:text-[86px] text-[#DADADA]" />
                  <div className="flex flex-col">
                    <div className="flex items-start md:items-start lg:items-start flex-col md:flex-col lg:flex-row gap-2">
                      <div className="flex flex-row items-center gap-2">
                        <div className="flex">
                          <div>
                            <span className="text-[16px] font-semibold capitalize">{userProfile.username}</span>
                          </div>
                        </div>
                        <div className="flex items-start">
                          <span className="text-sm text-slate-400 cursor-pointer"><MdVerified className='text-[#EFB81C] mx-1' /></span>
                        </div>
                      </div>
                      <div className="flex flex-col-reverse sm:flex-col-reverse md:flex-col lg:flex-col items-center">
                        <div className="flex items-center">
                          <button className='bg-[#EFB81C] px-2 py-1 font-semibold rounded-md text-[12px]' >+ Follow</button>
                          <p className="text-[#EFB81C] text-sm font-semibold pl-2 pr-2 border-r">Block</p>
                          <p className="text-[#EFB81C] text-sm font-semibold ml-2">Report</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row lg:flex-row py-2 gap-2 sm:gap-2 md:gap-0 lg:gap-0 items-start md:items-center lg:items-center">
                      <p className="text-sm relative flex flex-col md:flex-row lg:flex-row md:items-center md:pb-2 md:pt-2 ">
                        Online
                        <span className="hidden sm:hidden md:block lg:block w-[1px] h-4 bg-gray-300 sm:ml-2 md:ml-2 md:h-4 lg:h-6"></span>
                      </p>
                      <p className="text-sm relative flex flex-col md:flex-row lg:flex-row md:items-center md:pb-2 md:pt-2 md:pr-2 md:pl-2 lg:pr-2 ">
                        Joined on 2021-04-23
                        <span className="hidden sm:hidden md:block lg:block w-[1px] h-4 bg-gray-300 sm:ml-2 md:ml-2 md:h-4 lg:h-6"></span>
                      </p>
                      <p className="text-sm relative flex flex-row gap-1 md:flex-row lg:flex-row md:items-center md:pb-2 md:pt-2 lg:pr-2 ">
                        Deposit 2,000.00 USDT <IoMdInformationCircle />
                        <span className="block w-[1px] h-6 bg-transparent"></span> {/* Optional empty space for alignment */}
                      </p>
                    </div>
                    <div>
                      <div className="flex flex-wrap gap-2">
                        <div
                          className="flex items-center gap-1 px-1 py-1"
                          onClick={() => handleToggle("email")}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="text-[14px]">Email</span>
                          {checkedStates.email ? (
                            <FaCircle className="text-white" />
                          ) : (
                            <FaCircleCheck className="text-green-400" />
                          )}

                        </div>
                        <div
                          className="flex items-center gap-1  px-1 py-1"
                          onClick={() => handleToggle("sms")}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="text-[14px]">Sms</span>

                          {checkedStates.sms ? (
                            <FaCircle className="text-white" />
                          ) : (
                            <FaCircleCheck className="text-green-400" />
                          )}
                        </div>
                        <div
                          className="flex items-center gap-1  px-1 py-1"
                          onClick={() => handleToggle("kyc")}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="text-[14px]">ID Verification</span>

                          {checkedStates.kyc ? (
                            <FaCircle className="text-white" />
                          ) : (
                            <FaCircleCheck className="text-green-400" />
                          )}
                        </div>
                        <div
                          className="flex items-center gap-1  px-1 py-1"
                          onClick={() => handleToggle("address")}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="text-[14px]">Address</span>

                          {checkedStates.address ? (
                            <FaCircle className="text-white" />
                          ) : (
                            <FaCircleCheck className="text-green-400" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" bg-white w-full md:w-[53%] lg:w-fit p-4 ml-0 lg:ml-16 md:ml-16 mt-3 md:mt-3 lg:mt-0 rounded-2xl">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <span className="font-[300] text-[#1E2329] text-[14px]">
                        Positive Feedback
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-baseline">
                        <div>
                          {/* Display balance or masked value */}
                          <span className="font-bold text-[20px]">
                            98.84%
                          </span>
                        </div>
                        <div className="px-2 justify-center h-[20px]">
                          <div className="flex items-center">
                            <div className="h-[20px]"><span className="text-md font-semibold">(346)</span></div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex gap-1 text-[#707A8A]">
                          <div className="mt-1 flex items-center gap-0.5">
                            <p className="text-[12px]">Positive</p><div className="px-2 rounded-md text-white font-semibold bg-[#39D993]">342</div>
                          </div>
                          <div className="flex gap-1 text-[#707A8A]">
                            <div className="mt-1 flex items-center gap-0.5">
                              <p className="text-[12px]">Negative</p><div className="px-2 rounded-md font-semibold text-white bg-[#FF707E]">4</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Boxes />
        </div>
      </div>
      <div className="container">
        <div className="mt-10">
          <OnlineAdsNav
            setActiveComponent={setActiveComponent}
            activeComponent={activeComponent}
          />
        </div>
        {activeComponent === "Online Ads" && <OnlineAds />}
        {activeComponent === 'Feedback (3)' && <Feedback />}
      </div>
    </>
  );
}
// Array to hold content data for each box
const boxesData = [
  {
    title: "All Trades",
    number: "1135",
    value: "Times(s)",
    info: {
      positive: "Buy 256",
      negative: "/ Sell 879",
    },
  },
  {
    title: "30d Trades",
    number: "597",
    value: "Times(s)",
  },
  {
    title: "30d Completion Rate",
    number: "99.50",
    value: "%",
  },
  {
    title: "Avg. Release Time",
    number: "7.40",
    value: "Minute(s)",
  },
  {
    title: "Avg. Pay Time",
    number: "1.65",
    value: "Minute(s)",
  },
];
const tooltipText = [
  { text: "Total number of completed P2P orders (buy and sell included) in the past 30 days." },
  { text: "Completion Rate - Trades/Orders(30d)*100%." },
  { text: "The average time taken (in the last 30 days) to release crypto assets as the seller in orders." },
  { text: "The average time taken (in the last 30 days) to complete payment as the buyer in orders." },
  { text: "The percentage of good reviews recieved." },
];

const InfoBox = ({ title, number, value, info, tooltipIndex }) => {
  const tooltipContent = tooltipIndex >= 0 && tooltipIndex < tooltipText.length
    ? tooltipText[tooltipIndex].text
    : "No tooltip available";

  return (
    <div className="rounded-xl flex justify-between md:block lg:block h-32 py-0 md:py-5 lg:py-5 text-center flex-1 sm:border-0 md:border"> {/* Remove border on small screens */}
      <div className="flex items-center justify-center gap-1">
        <span className="text-[12px] md:text-sm lg:text-sm text-[#474D57]">{title}</span>
        <Tooltip placement="top" title={tooltipContent}>
          <IoMdInformationCircle className="text-slate-400" />
        </Tooltip>
      </div>
      <div className="pt-0 md:pt-0 lg:pt-3 flex flex-row items-center justify-center gap-0 md:gap-2 lg:gap-2"> {/* Side-by-side on small screens */}
        <div className="flex flex-col items-end lg:items-center">
          <div className="flex items-center">
            <p className="text-[12px] font-semibold sm:text-[20px] md:text-[20px] pr-1">{number}</p>
            <div className="flex flex-col items-end md:items-center gap-[0.2rem] lg:items-center">
              <div><p className="text-[12px] sm:text-[16px] md:text-[16px] font-medium ">{value}</p></div>
            </div>
          </div>
          {info && (
            <div className="flex flex-col mt-1">
              <span style={{ lineHeight: "0.5" }}>
                <span className="text-[#01BC8D] text-[11px]">{info.positive}</span>
                <span className="text-[#F65454] text-[11px]">{info.negative}</span>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const tradeInfoData = [
  { label: "All Trades", value: "1135 Times(s)", subLabel1: "Buy 256", subLabel2: "Sell 879" },
  { label: "30d Trades", value: "597 Times(s)" },
  { label: "30d Completion Rate", value: "99.50 %" },
  { label: "Avg. Release Time", value: "7.40 Minute(s)" },
  { label: "Avg. Pay Time", value: "1.65 Minute(s)" },
  { label: "First Trade", value: "275 Day(s) Ago" },
];


const Popup = ({ onClose }) => (
  <div className="fixed inset-0 !m-0 flex items-end sm:items-center md:items-center lg:items-center justify-center bg-black bg-opacity-50 z-10">
    <div className="bg-white rounded-md shadow-lg relative w-full sm:w-[70%] md:w-[60%] lg:w-[50%]">
      <div className="flex h-[45px] px-4 mt-2 mb-4 justify-end">
        <div className="flex w-[60%] justify-between">
          <h2 className="text-xl font-semibold text-center">Trade Info</h2>
          <button onClick={onClose}>
            <IoClose className="font-bold text-[28px] m-2 text-gray-400" />
          </button>
        </div>
      </div>
      <div className="px-8 py-4">
        {tradeInfoData.map((item, index) => (
          <div className={`flex justify-between w-full ${index !== 0 ? 'mt-4 mb-4' : ''}`} key={index}>
            <p className="text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px]">{item.label}</p>
            <div className="flex flex-col">
              <p className="font-semibold text-end text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px]">{item.value}</p>
              {item.subLabel1 && (
                <div className="flex gap-1">
                  <p className="text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px] text-[#03A66D]">{item.subLabel1}</p>
                  <p className="text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px]">/</p>
                  <p className="text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px] text-[#CF304A]">{item.subLabel2}</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Boxes = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div className="flex flex-wrap flex-col md:flex-row lg:flex-row md:grid md:grid-cols-3 lg:flex lg:flex-wrap gap-[1.2rem] md:gap-[0.2rem] lg:gap-4 pt-5 pb-5">
      {boxesData.slice(0, 5).map((box, index) => (
        <InfoBox
          key={index}
          title={box.title}
          value={box.value}
          number={box.number}
          info={box.info}
          tooltipIndex={index}
          className="w-full md:w-full lg:w-[18%]" // Full width on small/medium, 18% width on large
        />
      ))}

      {/* 6th box with fixed width on large screens */}
      <div
        className="border-0 pt-2 md:pt-0 lg:pt-0 md:border lg:border rounded-lg lg:rounded-lg md:rounded-xl flex justify-end lg:justify-center md:justify-center items-center w-full md:w-full lg:w-[4%] cursor-pointer"
        onClick={togglePopup}
      >
        <CgMoreO className="text-[#EFB81C]" /><span className="pl-2 block md:hidden sm:hidden lg:hidden text-[12px]">View more {`>`}</span>
      </div>

      {isPopupVisible && <Popup onClose={togglePopup} />}
    </div>


  );
};

export default AdvertiserDetail;
