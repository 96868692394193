import Repository from "./Repository";

const PROFILE = "/User/profile";
const URL = "/User/";
const CURRENCY = "/User/updateFiat";

export default {
    getFiatList(userId, token) {
        return Repository.get(`${PROFILE}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    getCurrencyFiatList(userId, token) {
        return Repository.get(`${URL}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },

    // Update selected currency
    setCurrencySelected(userId, token, selectedCurrency) {
        return Repository.post(
            `${CURRENCY}`,
            { currency: selectedCurrency },
            {
                headers: {
                    token: token,
                    id: userId,
                },
            }
        );
    },
};
