import React, { useState } from "react";
import Image from "../../../assets/img/earn/usdt.png";

import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SingleRowItem = ({ item }) => {
    const [monthly, setMonthly] = useState(10); // Default value
    const [duration, setDuration] = useState(1);

    const handleMonthlyChange = (e) => {
        setMonthly(Number(e.target.value));
    };

    const handleDurationChange = (e) => {
        setDuration(Number(e.target.value));
    };

    const total = monthly * duration * 12;
    return (
        <div className="">
            <div
                className={`p-3 px-4 min-h-[56px] py-4 border-b-[1px] border-solid border-[#f3f5f7] `}
            >
                <div className="h-[56px] flex items-center justify-between gap-4 cursor-pointer">
                    <div className="flex-1 flex items-center gap-[10px]">
                        <img
                            src={Image}
                            alt="BTC"
                            className="w-[28px] h-[28px]"
                        />
                        <p className="text-[16px] text-[#121214] font-medium">
                            USDT
                        </p>
                    </div>
                    <div className="flex-1 flex items-center text-[16px] text-[#20b26c] font-medium">
                        <select
                            name="amount"
                            id=""
                            className="w-16 cursor-pointer"
                            value={monthly}
                            onChange={handleMonthlyChange}
                        >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                        </select>
                    </div>
                    <div className="flex-1 flex items-center text-[16px] text-[#121214] font-medium">
                        <select
                            name="duration "
                            id=""
                            className="w-24 cursor-pointer"
                            value={duration}
                            onChange={handleDurationChange}
                        >
                            <option value="1">1 Year</option>
                            <option value="2">2 Years</option>
                            <option value="5">5 Years</option>
                            <option value="10">10 Years</option>
                        </select>
                    </div>
                    <div className="flex-1 flex items-center text-[16px] text-[#121214] font-medium">
                        {total} USDT
                    </div>
                    <div className="flex-1 items-center flex text-center justify-end">
                        <button className="bg-[#ffb11a] flex items-center justify-center rounded-[8px] text-[12px] w-[100px] h-[32px] texxt-center text-[#000] font-semibold ">
                            Subscribe
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleRowItem;
