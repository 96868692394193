import React, { useState } from 'react';
import { MdKeyboardArrowRight, MdSearch } from "react-icons/md";
import WalletView from './WalletView';
import CoinView from './CoinView';

const MyAssets = ({ isVisible,data }) => {
    const [activeItem, setActiveItem] = useState('Coin View');
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [hideLowValueAssets, setHideLowValueAssets] = useState(false);
    const [showGreaterThanOne, setShowGreaterThanOne] = useState(false);

    // Function to render the content based on active item
    const renderContent = () => {
        switch (activeItem) {
            case 'Coin View':
                return (
                    <CoinView
                        isVisible={true}
                        searchQuery={searchQuery}  // Pass search query here
                        showGreaterThanOne={showGreaterThanOne}
                        hideLowValueAssets={hideLowValueAssets} // Pass this state too
                    />
                );
            case 'Wallet View':
                return <WalletView
                    isVisible={true}
                    searchQuery={searchQuery}  // Pass search query here
                    showGreaterThanOne={showGreaterThanOne}
                    hideLowValueAssets={hideLowValueAssets}
                />;
            default:
                return <CoinView isVisible={isVisible} searchQuery={searchQuery} hideLowValueAssets={hideLowValueAssets} />;
        }
    };

    // Toggle the state for hiding low-value assets
    const handleCheckboxClick = () => {
        setHideLowValueAssets(prev => !prev);
    };

    return (
        <div className='p-3 md:p-6 mt-6 border rounded-2xl'>
            <div className='flex flex-col md:flex-row justify-between'>
                <div className='flex gap-5'>
                    <p className={`font-semibold text-2xl cursor-pointer pb-3`}>Spot Assets</p>
                </div>
                <div className='flex justify-between md:justify-normal gap-5'>
                <div className='flex gap-1 items-center'>
                    {/* Search Icon/Input */}
                    <div
                        className='relative flex items-center'
                        onMouseEnter={() => setShowSearchInput(true)}
                        onMouseLeave={() => setShowSearchInput(false)}
                    >
                        {showSearchInput ? (
                            <input
                                type="text"
                                className="transition-all duration-300 ease-in-out w-0 px-3 py-1 rounded-md border border-gray-300 focus:outline-none"
                                style={{
                                    width: showSearchInput ? '150px' : '0',
                                    opacity: showSearchInput ? '1' : '0',
                                    paddingLeft: showSearchInput ? '8px' : '0',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search assets..."
                                autoFocus
                            />
                        ) : (
                            <MdSearch className="text-gray-600 text-lg cursor-pointer transition-all duration-300 hover:text-black" />
                        )}
                    </div>
                    {/* Checkbox for hiding low-value assets */}
                    <div className='flex items-center cursor-pointer' onClick={handleCheckboxClick}>
                        <div className='flex items-center'>
                            <input
                                type="checkbox"
                                checked={showGreaterThanOne}
                                onChange={() => setShowGreaterThanOne(!showGreaterThanOne)}
                                className="mr-2"
                            />
                            <label className='text-sm font-medium'>Hide assets &lt; 1 USD</label>
                        </div>
                    </div>
                </div>
                <div className='flex items-center'>
                    <p className='text-sm font-medium text-[#EFB81C]'>View All</p>
                    <MdKeyboardArrowRight className='text-[#EFB81C] text-lg' />
                </div>
                </div>
            </div>

            <nav className='flex gap-10 w-full justify-between border-gray-200 pt-2'>
                <div className='flex gap-5'>
                    {/* <button
                        onClick={() => setActiveItem('Coin View')}
                        className={`relative py-2 text-md font-semibold ${activeItem === 'Coin View' ? '' : 'text-gray-400'}`}
                    >
                        Coin View
                        {activeItem === 'Coin View' && (
                            <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                        )}
                    </button> */}
                    {/* <button
                        onClick={() => setActiveItem('Wallet View')}
                        className={`relative py-2 text-md font-semibold ${activeItem === 'Wallet View' ? '' : 'text-gray-400'}`}
                    >
                        Wallet View
                        {activeItem === 'Wallet View' && (
                            <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                        )}
                    </button> */}
                </div>
                
            </nav>

            <div className="mt-6">{renderContent()}</div>
        </div>
    );
};

export default MyAssets;
