import { FETCH_MARKET_DATA, FETCH_MARKET_DATA_ERROR, FETCH_MARKET_DATA_LOADING } from '../types';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const marketReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MARKET_DATA:
            return { ...state, data: action.payload, loading: false, error: null };
        case FETCH_MARKET_DATA_ERROR:
            return { ...state, data: [], loading: false, error: action.payload };
        case FETCH_MARKET_DATA_LOADING:
            return { ...state, loading: action.payload };
        default:
            return state;
    }
};

export default marketReducer;
