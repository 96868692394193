import React, { useState } from 'react';
import { FaArrowRight } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { FaSortUp } from "react-icons/fa";

function BuySell() {
    const coins = [
        { name: 'Bitcoin', image: 'https://api.buycex.com/Upload/coin/BTC.png' },
        { name: 'Ethereum', image: 'https://api.buycex.com/Upload/coin/ETH.png' },
        { name: 'Ripple', image: 'https://api.buycex.com/Upload/coin/XRP.png' },
        { name: 'Tron', image: 'https://api.buycex.com/Upload/coin/TRX.png' },
        { name: 'BNB', image: 'https://api.buycex.com/Upload/coin/BNB.png' }
    ];

    const fiats = [
        { name: 'USD', image: 'https://api.buycex.com/Upload/coin/65fc704078da2.png' },
        { name: 'INR', image: 'https://api.buycex.com/Upload/coin/660c8eb792b10.png' },
        { name: 'BDT', image: 'https://api.buycex.com/Upload/coin/660c8fc8a7273.png' },
        { name: 'AED', image: 'https://api.buycex.com/Upload/coin/660c89d0b0433.jpg' },
        { name: 'EUR', image: 'https://api.buycex.com/Upload/coin/660c892ab200d.png' }
    ];

    const [selectedCoinPay, setSelectedCoinPay] = useState(fiats[0]); // Default to fiat for Pay
    const [selectedCoinReceive, setSelectedCoinReceive] = useState(coins[0]); // Default to coin for Receive

    const [isDropdownOpenPay, setIsDropdownOpenPay] = useState(false);
    const [isDropdownOpenReceive, setIsDropdownOpenReceive] = useState(false);

    const handleSelectCoin = (value, type) => {
        if (type === 'pay') {
            setSelectedCoinPay(value);
            setIsDropdownOpenPay(false);
        } else if (type === 'receive') {
            setSelectedCoinReceive(value);
            setIsDropdownOpenReceive(false);
        }
    };

    return (
        <div className='bg-white rounded-3xl flex items-center h-32 mx-2'>
            <div className='container'>
                <div className='flex w-full gap-4 items-center justify-between'>
                    <div className='w-[34%]'>
                    <h2 className='text-black text-[34px] font-bold'>Market Today</h2>
                    <p className='flex items-center font-medium text-sm'><p className='text-gray-600'>Market Cap: </p><p className='text-[#5169b1] font-medium ml-1'>$3.27T</p><FaSortUp className='text-[#2ECC71] mt-2 ml-1'/><p className='text-[#2ECC71]'>5.09%</p> <p className='text-gray-600 ml-4'>24h Vol:</p><p className='text-[#5169b1] font-medium ml-1'>$241.74B</p><FaSortUp className='text-[#2ECC71] mt-2 ml-1'/><p className='text-[#2ECC71]'>31.75%</p></p>
                    </div>

                    {/* Pay Section */}
                    <div className='w-[33%] bg-white border rounded-full flex px-6 py-3'>
                        <div className='flex items-center w-full justify-between gap-4'>
                            <div>
                                <p className='text-xs text-[#81858c] w-full'>Pay</p>
                                <input className='text-2xl focus:outline-none w-52' placeholder='Enter Amount' />
                            </div>
                            <div className='relative'>
                                <div
                                    className='bg-white flex items-center gap-3 rounded-full px-4 py-1 cursor-pointer'
                                    onClick={() => setIsDropdownOpenPay(!isDropdownOpenPay)}
                                >
                                    <img
                                        src={selectedCoinPay.image}
                                        alt={selectedCoinPay.name}
                                        className='w-6 h-6'
                                    />
                                    {selectedCoinPay.name}
                                    <FaAngleDown
                                        className={`transition-transform duration-300 ${isDropdownOpenPay ? 'rotate-180' : 'rotate-0'}`}
                                    />
                                </div>
                                {isDropdownOpenPay && (
                                    <div className='absolute top-10 left-0 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10'>
                                        {fiats.map((fiat, index) => (
                                            <div
                                                key={index}
                                                className='flex items-center px-4 py-2 hover:bg-gray-200 cursor-pointer'
                                                onClick={() => handleSelectCoin(fiat, 'pay')}
                                            >
                                                <img
                                                    src={fiat.image}
                                                    alt={fiat.name}
                                                    className='w-6 h-6 mr-2'
                                                />
                                                {fiat.name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <FaArrowRight className='text-md' fill='#81858c' />

                    {/* Receive Section */}
                    <div className='w-[33%] bg-white border rounded-full flex px-6 py-3'>
                        <div className='flex items-center w-full justify-between gap-4'>
                            <div>
                                <p className='text-xs text-[#81858c] w-full'>Receive</p>
                                <input className='text-2xl focus:outline-none w-44' placeholder='Enter Amount' />
                            </div>
                            <div className='relative'>
                                <div
                                    className='bg-white flex items-center gap-3 rounded-full px-4 py-1 cursor-pointer'
                                    onClick={() => setIsDropdownOpenReceive(!isDropdownOpenReceive)}
                                >
                                    <img
                                        src={selectedCoinReceive.image}
                                        alt={selectedCoinReceive.name}
                                        className='w-6 h-6'
                                    />
                                    {selectedCoinReceive.name}
                                    <FaAngleDown
                                        className={`transition-transform duration-300 ${isDropdownOpenReceive ? 'rotate-180' : 'rotate-0'}`}
                                    />
                                </div>
                                {isDropdownOpenReceive && (
                                    <div className='absolute top-10 left-0 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10'>
                                        {coins.map((coin, index) => (
                                            <div
                                                key={index}
                                                className='flex items-center px-4 py-2 hover:bg-gray-200 cursor-pointer'
                                                onClick={() => handleSelectCoin(coin, 'receive')}
                                            >
                                                <img
                                                    src={coin.image}
                                                    alt={coin.name}
                                                    className='w-6 h-6 mr-2'
                                                />
                                                {coin.name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BuySell;
