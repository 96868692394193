import React, { useState } from "react";
import { BsArrowLeft, BsSend } from "react-icons/bs";
import ChatBotRobotIcon from "../icons/chat-bot-assistant-robot.jpg";
import ChatBotAnim from "../icons/buycex-chat-bot-anim.mp4";

const ChatDialogContainer = ({ setChatStarted }) => {
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleBackClick = () => {
        setChatStarted(false);
    };

    return (
        <div className="chat-dialog-container flex flex-col justify-between h-full">
            <div
                className="chat-dialog__header flex items-center justify-between w-full p-4"
                style={{
                    boxShadow: "rgba(0, 0, 0, 0.20) 0px 15px 10px -20px",
                }}
            >
                <div className="flex items-center justify-center rounded-md overflow-hidden">
                    <img
                        src={ChatBotRobotIcon}
                        alt="Chat Bot"
                        className="w-[45px]"
                        title="Chat Bot"
                        style={{ objectFit: "cover" }}
                        loading="lazy"
                    />
                </div>

                <div className="flex flex-col gap-1 mx-auto">
                    <div className="text-[14px] font-bold leading-none">
                        BUYCEX Assistant
                    </div>
                </div>

                <button
                    type="button"
                    onClick={handleBackClick}
                    title="Close Chat"
                    className="text-gray-600 hover:text-gray-800"
                    aria-label="Close"
                >
                    <BsArrowLeft size={24} />
                </button>
            </div>

            <div className="chat-dialog__body flex-1 overflow-y-auto">
                <div className="chat-field__body w-full flex flex-col gap-4 items-center justify-center">
                    <video
                        loop
                        muted
                        autoPlay
                        playsInline
                        style={{ width: "60%" }}
                    >
                        <source src={ChatBotAnim} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <p className="text-[14px] text-center max-w-[60%]">
                        Oh! Oh! Not yet. <br />{" "}
                        <span className="text-blue-500 text-[14px] text-[500]">
                            I'm under development.
                        </span>{" "}
                        <br />
                        Come back soon...
                    </p>
                </div>
            </div>

            <div
                className="chat-dialog__footer flex items-center justify-between gap-0 px-4 py-3"
                style={{
                    boxShadow: "rgba(0, 0, 0, 0.30) 0px -15px 10px -20px",
                }}
            >
                {/* User Chat input field */}
                <div className="w-full chat-dialog__user-input">
                    <input
                        type="text"
                        name="user-input"
                        id="user-input"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="Type your question here..."
                        className="w-full py-2.5 px-4 text-[14px] placeholder:text-[14px] placeholder:text-[#15141580] rounded-md outline-none border"
                    />
                </div>

                {/* Submit Button */}
                <div
                    className={`chat-dialog__user-submit-btn flex ${
                        inputValue
                            ? "translate-x-0 w-[45px] opacity-100"
                            : "translate-x-6 w-0 opacity-0"
                    }`}
                    style={{
                        transition: `
                            width 0.5s cubic-bezier(0.96, 0.2, 0.06, 0.94),
                            transform 0.4s cubic-bezier(0.72, 0.36, 0.03, 0.98),
                        `,
                    }}
                >
                    <button
                        type="button"
                        className="cursor-not-allowed m-auto p-2 rounded-md transition-all duration-500"
                        disabled
                    >
                        <BsSend size={20} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChatDialogContainer;
