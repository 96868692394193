import React, { useEffect, useRef, useState } from "react";

import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { fetchFiatOrder } from "store/actions/OrderUserAction";
import FiatOrderTable from '../../tables/FiatOrderTable'
import LaunchPadTable from "../../tables/LaunchPadTable";
const LaunchPad = () => {

  const dispatch = useDispatch();

  const { loading, fiatOrder, error } = useSelector(
    (state) => state.fiatOrder
  );
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  useEffect(() => {
   
    dispatch(fetchFiatOrder(userID, userToken ));

  }, [dispatch, userID, userToken]);
  
  


  const dropDownRef = useRef(null);

  // DropDown States
  const [isTrade, setIsTrade] = useState(false);
  const [isMarket, setIsMarket] = useState(false);
  const [isSide, setIsSide] = useState(false);

  // DropDowns Handler
  const handleTradeDropDown = () => {
    setIsTrade(!isTrade);
    setIsMarket(false);
    setIsSide(false);

  };

  const handleMarketDropDown = () => {
    setIsMarket(!isMarket);
    setIsTrade(false);
    setIsSide(false);
  };
  const handleSideDropDown = () => {
    setIsMarket(false);
    setIsTrade(false);
    setIsSide(!isSide);

  };

  //For Clicking Outside DROPDOWNS
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsTrade(null);
        setIsMarket(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  

  useEffect(() => {
    console.log("fiatOrder ", fiatOrder);
  }, [fiatOrder]);



  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg text-red-500">Error: {error}</div>
      </div>
    );
  }

 


  return (
    <>
      <div useRef={dropDownRef} className="flex flex-col w-full">
        {/* First card */}
       <div className="mt-3 mb-2 text-lg font-bold">Locked:</div>

        {/* Table */}
        <LaunchPadTable />

        <div className="mt-3 mb-2 text-lg font-bold">Notes</div>
        <div className="flex flex-wrap items-center w-full gap-x-3 ">
          <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
          <div className="text-[13px]">Before the subscription ends, you can cancel the slots of subscription in Dock Orders. Both your payment and staked assets will be returned to your Spot Account after the cancellation.</div>
        </div>
        <div className="flex items-center w-full my-1 gap-x-3">
          <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
          <div className="text-[13px]">After the lottery ends, the staked assets will be automatically returned to your Spot Account. Users who won the lottery can get the subscribed tokens. The payment and staked assets will be returned for those who missed the lottery.</div>
        </div>
        <div className="flex items-center w-full gap-x-3 ">
          <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
          <div className="text-[13px]">If you have more than one subscription and only part of them won the lottery, you will only be charged for the winning part while the rest of the payment and staked asset will be automatically returned to your Spot account.</div>
        </div>
      </div>
    </>
  );
};

export default LaunchPad;
