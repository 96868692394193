import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Input } from "antd";
import { HiPlus } from "react-icons/hi";
import { IoClose } from "react-icons/io5"; // Close icon
import TimeLimitDropdown from "./TimeLimitDropdown";
import AddPaymentMethodModal from "./AddPaymentMethodModal";
import AddFundsModal from "./AddFundsModal"; // Import the new modal
import YourPaymentMethodModal from "./YourPaymentMethodModal"; // Import the new modal component
import {
    setTotalAmount,
    setFromAmount,
    setToAmount,
    setTime,
    setSelectedBanks,
    setSelectedPaymentMethod,
} from "../../../store/reducers/stepOneSlice";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";

function StepTwo({ mode }) {
    const dispatch = useDispatch();
    const {
        totalAmount,
        fromAmount,
        toAmount,
        time,
        selectedCrypto,
        selectedFiat,
    } = useSelector((state) => state.stepone);
    const { selectedBanks } = useSelector((state) => state.stepone);
    const selectedPaymentMethod = useSelector(
        (state) => state.stepone.selectedPaymentMethod
    );
    const fiatValue = selectedFiat === "Indian Rupees" ? "INR" : selectedFiat;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isFundsModalVisible, setIsFundsModalVisible] = useState(false);
    const [
        isYourPaymentMethodModalVisible,
        setIsYourPaymentMethodModalVisible,
    ] = useState(false);
    // const [selectedBanks, setSelectedBanks] = useState([]);
    // const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    console.log(selectedPaymentMethod);

    const TimeLimitItems = [
        { text: "15 Mins", value: 15 },
        { text: "30 Mins", value: 30 },
        { text: "1.0 hour", value: 60 }, // 1 hour = 60 mins
        { text: "2.0 hour", value: 120 }, // 2 hours = 120 mins
        { text: "4.0 hour", value: 240 }, // 4 hours = 240 mins
        { text: "6.0 hour", value: 360 }, // 6 hours = 360 mins
        { text: "12.0 hour", value: 720 }, // 12 hours = 720 mins
    ];

    // console.log("this is step 2",selectedCrypto, selectedFiat, resultValue,val,currentVal)
    const storedTimeValue = useSelector((state) => state.stepone.time);
    const [timeLimitText, setTimeLimitText] = useState("15 Mins");

    useEffect(() => {
        // Set the initial timeLimitText based on the stored time value
        const initialItem = TimeLimitItems.find(
            (item) => item.value === storedTimeValue
        );
        setTimeLimitText(initialItem ? initialItem.text : "15 Mins"); // Set the text based on the time value
    }, [storedTimeValue]);

    const handleTimeLimitSelect = (selectedText) => {
        const selectedItem = TimeLimitItems.find(
            (item) => item.text === selectedText
        );
        if (selectedItem) {
            dispatch(setTime(selectedItem.value));
            setTimeLimitText(selectedItem.text);
        }
    };

    const handleAddClick = () => {
        if (mode === "sell") {
            setIsYourPaymentMethodModalVisible(true); // Show New Modal when mode is 'sell'
        } else {
            setIsModalVisible(true); // Show Add Payment Method Modal otherwise
        }
    };

    const handleAddFundsClick = () => {
        if (mode === "sell") {
            setIsFundsModalVisible(true); // Show Add Funds Modal when mode is 'sell'
        }
    };

    const handleModalOk = (banks) => {
        dispatch(setSelectedBanks(banks));
        setIsModalVisible(false);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const handleFundsModalCancel = () => {
        setIsFundsModalVisible(false); // Close Add Funds Modal
    };

    const handleYourPaymentMethodModalCancel = () => {
        setIsYourPaymentMethodModalVisible(false); // Close New Modal
    };

    // const handleRemoveBank = (bankName) => {
    //   setSelectedBanks(prevSelectedBanks =>
    //     prevSelectedBanks.filter(bank => bank.name !== bankName)
    //   );
    // };
    useEffect(() => {
        if (selectedPaymentMethod) {
            dispatch(setSelectedPaymentMethod(selectedPaymentMethod));
        }
    }, [selectedPaymentMethod, dispatch]);

    const handleSelectPaymentMethod = (method) => {
        const alreadySelected = selectedPaymentMethod.find(
            (item) => item.method === method.method
        );

        if (alreadySelected) {
            const updatedMethods = selectedPaymentMethod.filter(
                (item) => item.method !== method.method
            );
            dispatch(setSelectedPaymentMethod(updatedMethods));
        } else {
            dispatch(
                setSelectedPaymentMethod([...selectedPaymentMethod, method])
            );
        }

        console.log("Received payment method:", method);
        setIsYourPaymentMethodModalVisible(false); // Close modal after selection
    };

    const selectedBanksText =
        selectedBanks.length > 0
            ? selectedBanks.map((bank) => (
                  <BankTag key={bank.name} color={bank.color}>
                      <div className="bg-[#fafafa] text-[11px] px-2">
                          {bank.name}
                      </div>
                  </BankTag>
              ))
            : "";

    const handleTotalAmountChange = (e) => {
        const amount = e.target.value; // Get the amount from input
        dispatch(setTotalAmount(amount));
    };

    const handleFromAmountChange = (e) => {
        const amount = e.target.value; // Get the amount from input
        dispatch(setFromAmount(amount)); // Call the handler passed from the parent
    };

    const handleToAmountChange = (e) => {
        const amount = e.target.value; // Get the amount from input
        dispatch(setToAmount(amount)); // Call the handler passed from the parent
    };

    return (
        <div className="px-14 py-10">
            <p className="pb-3 font-light text-sm">Total Amount</p>
            <StyledInput
                suffix={selectedCrypto}
                onChange={handleTotalAmountChange}
                value={totalAmount}
                size="large"
                placeholder="Please enter total amount"
                className="w-1/2"
            />
            <p className="font-light text-sm py-1">≈ 0 PKR</p>
            {mode === "sell" && (
                <p className="flex items-center font-light">
                    <span className="text-[12px] text-[#707A8A]">
                        Available balance:{" "}
                        <span className="text-black text-[12px]">0</span>
                        {selectedCrypto}
                    </span>{" "}
                    <span className="text-[12px] text-[#EFB81C] ml-2">All</span>
                    <button
                        className="text-[#EFB81C] ml-3 font-semibold flex gap-1 items-center"
                        onClick={handleAddFundsClick} // Show Add Funds modal when clicked and mode is 'sell'
                    >
                        <HiPlus className="text-[12px]" />
                        <span className="text-[12px]">Add Funds</span>
                    </button>
                </p>
            )}
            <div className="py-6 border-b">
                <p className="pb-3 text-sm font-light">Order Limit</p>
                <div className="flex gap-4 w-1/2 items-center">
                    <div className="flex flex-col">
                        <StyledInput
                            value={fromAmount}
                            onChange={handleFromAmountChange}
                            suffix={fiatValue}
                            size="large"
                        />
                        <p className="text-sm font-light text-[#707A8A] py-1">
                            ≈ 3.59 USDT
                        </p>
                    </div>
                    <p className="mb-7">~</p>
                    <div className="flex flex-col">
                        <StyledInput
                            value={toAmount}
                            onChange={handleToAmountChange}
                            suffix={fiatValue}
                            size="large"
                        />
                        <p className="text-sm font-light text-[#707A8A] py-1">
                            ≈ 2691.84 USDT
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <p className="pt-8 pb-2 text-sm font-light">Payment Method</p>
                {mode === "buy" && (
                    <>
                        <div className="flex flex-wrap gap-2 mb-4">
                            {selectedBanksText}
                            <button
                                className="bg-[#EFB81C] px-7 py-1 flex gap-1 rounded-[3px] items-center"
                                onClick={handleAddClick} // Show appropriate modal based on mode
                            >
                                <HiPlus className="text-sm font-bold" />
                                <span className="text-sm font-semibold">
                                    Add
                                </span>
                            </button>
                        </div>
                        <p className="text-sm font-light text-[#707A8A] py-1">
                            Select up to 5 methods
                        </p>
                    </>
                )}
                {mode === "sell" && (
                    <div className="w-full">
                        {selectedPaymentMethod.length > 0 && (
                            <div className="border mb-4 p-4 rounded-md mt-3 border-black relative">
                                <IoClose
                                    className="absolute top-2 right-2 cursor-pointer text-black text-xl"
                                    onClick={() =>
                                        dispatch(setSelectedPaymentMethod([]))
                                    } // Close button for clearing all selections
                                />
                                {selectedPaymentMethod.map(
                                    (paymentMethod, index) => (
                                        <div
                                            key={index}
                                            className="flex justify-between items-center mb-2"
                                        >
                                            <div className="w-full">
                                                {paymentMethod.bank ? (
                                                    <>
                                                        <div className="flex justify-between mb-1">
                                                            <p className="text-sm mb-2 w-[20%] text-[#707A8A]">
                                                                Bank:
                                                            </p>
                                                            <p className="text-sm mb-2 w-full text-black">
                                                                {
                                                                    paymentMethod.bank
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-between mb-1">
                                                            <p className="text-sm mb-2 w-[20%] text-[#707A8A]">
                                                                Name:
                                                            </p>
                                                            <p className="text-sm mb-2 w-full text-black">
                                                                {
                                                                    paymentMethod.name
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-between mb-1">
                                                            <p className="text-sm mb-2 w-[20%] text-[#707A8A]">
                                                                Account Number:
                                                            </p>
                                                            <p className="text-sm mb-2 w-full text-black">
                                                                {
                                                                    paymentMethod.bankcard
                                                                }
                                                            </p>
                                                        </div>
                                                    </>
                                                ) : paymentMethod.method ===
                                                  "cdm" ? (
                                                    <>
                                                        <div className="flex justify-between mb-1">
                                                            <p className="text-sm mb-2 w-[20%] text-[#707A8A]">
                                                                Method:
                                                            </p>
                                                            <p className="text-sm mb-2 w-full text-black">
                                                                Cash Deposit
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-between mb-1">
                                                            <p className="text-sm mb-2 w-[20%] text-[#707A8A]">
                                                                Information:
                                                            </p>
                                                            <p className="text-sm mb-2 w-full text-black">
                                                                {
                                                                    paymentMethod.information
                                                                }
                                                            </p>
                                                        </div>
                                                    </>
                                                ) : paymentMethod.method ===
                                                  "upi" ? (
                                                    <>
                                                        <div className="flex justify-between mb-1">
                                                            <p className="text-sm mb-2 w-[20%] text-[#707A8A]">
                                                                Method:
                                                            </p>
                                                            <p className="text-sm mb-2 w-full text-black">
                                                                UPI
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-between mb-1">
                                                            <p className="text-sm mb-2 w-[20%] text-[#707A8A]">
                                                                Information:
                                                            </p>
                                                            <p className="text-sm mb-2 w-full text-black">
                                                                {
                                                                    paymentMethod.information
                                                                }
                                                            </p>
                                                        </div>
                                                    </>
                                                ) : paymentMethod.method ===
                                                  "paypal" ? (
                                                    <>
                                                        <div className="flex justify-between mb-1">
                                                            <p className="text-sm mb-2 w-[20%] text-[#707A8A]">
                                                                Method:
                                                            </p>
                                                            <p className="text-sm mb-2 w-full text-black">
                                                                PayPal
                                                            </p>
                                                        </div>
                                                        <div className="flex justify-between mb-1">
                                                            <p className="text-sm mb-2 w-[20%] text-[#707A8A]">
                                                                Information:
                                                            </p>
                                                            <p className="text-sm mb-2 w-full text-black">
                                                                {
                                                                    paymentMethod.information
                                                                }
                                                            </p>
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>
                                            <DeleteOutlined
                                                className="cursor-pointer text-red-600 text-lg ml-2"
                                                onClick={() => {
                                                    const updatedMethods =
                                                        selectedPaymentMethod.filter(
                                                            (item) =>
                                                                item.method !==
                                                                paymentMethod.method
                                                        );
                                                    dispatch(
                                                        setSelectedPaymentMethod(
                                                            updatedMethods
                                                        )
                                                    );
                                                }}
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                        )}

                        <div className="flex flex-wrap gap-2 mb-4">
                            <button
                                className="bg-[#EFB81C] px-7 py-1 flex gap-1 rounded-[3px] items-center"
                                onClick={handleAddClick} // Show appropriate modal based on mode
                            >
                                <HiPlus className="text-sm font-bold" />
                                <span className="text-sm font-semibold">
                                    Add
                                </span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div>
                <p className="pt-8 pb-2 text-sm font-light">
                    Payment Time Limit
                </p>
                <TimeLimitDropdown
                    items={TimeLimitItems}
                    buttonText={timeLimitText}
                    onSelect={handleTimeLimitSelect}
                />
            </div>

            <AddPaymentMethodModal
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            />

            {/* Add Funds Modal */}
            <AddFundsModal
                visible={isFundsModalVisible}
                onCancel={handleFundsModalCancel}
            />

            {/* New Modal */}
            <YourPaymentMethodModal
                visible={isYourPaymentMethodModalVisible}
                onCancel={handleYourPaymentMethodModalCancel}
                onSelect={handleSelectPaymentMethod} // Pass selected method back to parent
            />

            <GlobalStyle />
        </div>
    );
}

const BankTag = styled.div`
    display: flex;
    align-items: center;
    padding: 0.2rem 0.2rem;
    border: 1px solid rgb(234, 236, 239);
    font-size: 0.875rem;
    font-weight: 500;
    color: ${({ color }) => color};

    .anticon-close {
        font-size: 1rem;
        color: ${({ color }) => color};
    }
`;

const GlobalStyle = createGlobalStyle`
  .ant-input:focus, .ant-input:hover, .ant-input:active {
    border-color: #EFB81C !important;
    box-shadow: none !important;
  }
`;

const StyledInput = styled(Input)`
    &:focus,
    &:hover,
    &:active {
        border-color: #efb81c !important;
        box-shadow: none !important;
    }
    &.ant-input-outlined:focus,
    &.ant-input-outlined:focus-within {
        border-color: #efb81c !important;
        box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.1) !important;
        outline: 0;
        background-color: #ffffff;
    }
    .ant-input-suffix {
        font-size: 14px;
        font-weight: 300;
    }
    &.ant-input-affix-wrapper-lg {
        border-radius: 5px;
    }
`;

export default StepTwo;
