import React, { useState, useEffect } from "react";
import "assets/css/auth/Login.css";
import loginImage from "assets/img/auth/login.png";
import logo from "assets/img/logo/buycex-yellow.png";
import barCode from "assets/img/bar-code.svg";
import { FcGoogle } from "react-icons/fc";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa6";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    email2fa,
    phone2fa,
    googleLoginNew,
    login,
    loginLoading,
} from "store/actions/authAction";

import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import i18nIsoCountries from "i18n-iso-countries";
import CaptchaModal from "./captchaSlider";
import Email2faVerification from "components/auth/validations/email/email2faCode";
import Phone2faVerification from "components/auth/validations/phone/phone2faCode";
import G2faLoginVerification from "components/auth/validations/g2fa/g2faVerification";
import toast from "react-hot-toast";
import { SET_PASSWORD } from "store/types";
import { GlobalOutlined } from "@ant-design/icons";
import sliderCaptchaImgCreate from "../../helpers/sliderCaptchaImgCreate";
import { Button, Flex } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import { fetchAuthStatus } from "store/actions/userSecuritySettingsAction";

i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const Login = () => {
    const { loading } = useSelector((state) => state?.auth);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phone, setPhone] = useState("");
    const [g2aCode, setG2aCode] = useState(Array(6).fill(""));
    const [countryCode, setCountryCode] = useState("");
    const [emailPassword, setEmailPassword] = useState("");
    const [phonePassword, setPhonePassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showEmail2faModal, setShowEmail2faModal] = useState(false);
    const [showPhone2faModal, setShowPhone2faModal] = useState(false);
    const [showCaptchaModal, setShowCaptchaModal] = useState(false);
    const [showPhoneCaptchaModal, setPhoneShowCaptchaModal] = useState(false);
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [twoFaType, setTwoFaType] = useState("e2fa"); // 2fa type
    const [defaultCountry, setDefaultCountry] = useState("");
    const [captchaVal, setCaptchaVal] = useState(-1);
    const [nextLoading, setNextLoading] = useState(false);
    const [captchaImage, setCaptchaImage] = useState({
        bgUrl: "",
        puzzleUrl: "",
    });
    const dispatch = useDispatch();
    const history = useHistory();
    const state = useSelector((state) => state); // Accessing the entire Redux state
    const userId = useSelector((state) => state.authUser?.user?.ID);
    const token = useSelector((state) => state.authUser?.user?.TOKEN);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const getUserLocation = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        });
                    },
                    (error) => {
                        console.error("Failed to get location", error);
                        reject("Unknown location");
                    }
                );
            } else {
                reject("Geolocation not supported");
            }
        });
    };
    const getUserIP = async () => {
        try {
            const response = await fetch("https://api.ipify.org?format=json");
            const data = await response.json();
            return data.ip;
        } catch (error) {
            console.error("Failed to fetch IP address", error);
            return "Unknown IP";
        }
    };

    const getCountryCode = async (ip) => {
        try {
            const response = await fetch(`https://ipapi.co/${ip}/json/`);
            const data = await response.json();
            return data.country_code.toLowerCase(); // Ensure country code is lowercase
        } catch (error) {
            console.error("Failed to fetch country code", error);
            return null;
        }
    };

    useEffect(() => {
        const initialize = async () => {
            const ip = await getUserIP();
            if (ip) {
                const countryCode = await getCountryCode(ip);
                if (countryCode) {
                    setDefaultCountry(countryCode);
                }
            }
        };

        initialize();
    }, []);

    useEffect(() => {
        if (localStorage.getItem("enable_confirm_email")) {
            setShowEmail2faModal(true);
            localStorage.removeItem("enable_confirm_email");
        } else if (localStorage.getItem("google_confirm_email")) {
            setShowModal(true);
            localStorage.removeItem("google_confirm_email");
        }
    }, []);

    useEffect(() => {
        if (userId && token) {
            history.push("/");
        }
    }, []);

    const getDeviceInfo = () => {
        const userAgent = navigator.userAgent;

        // Detect Device Type
        const isMobile = /Mobi|Android/i.test(userAgent);
        const deviceType = isMobile ? "Mobile" : "Desktop";

        // Detect Operating System
        let os = "Unknown OS";
        if (navigator.platform.indexOf("Win") !== -1) os = "Windows";
        else if (navigator.platform.indexOf("Mac") !== -1) os = "macOS";
        else if (navigator.platform.indexOf("Linux") !== -1) os = "Linux";
        else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
            os = "iOS";
        else if (/Android/.test(userAgent)) os = "Android";

        // Detect Browser Name and Version
        let browserName = "Unknown Browser";
        let browserVersion = "Unknown Version";

        if (userAgent.indexOf("Firefox") > -1) {
            browserName = "Firefox";
            browserVersion = userAgent.split("Firefox/")[1];
        } else if (
            userAgent.indexOf("Opera") > -1 ||
            userAgent.indexOf("OPR") > -1
        ) {
            browserName = "Opera";
            browserVersion =
                userAgent.split("OPR/")[1] || userAgent.split("Opera/")[1];
        } else if (userAgent.indexOf("Trident") > -1) {
            browserName = "Internet Explorer";
            browserVersion = userAgent.split("rv:")[1];
        } else if (userAgent.indexOf("Edge") > -1) {
            browserName = "Edge";
            browserVersion = userAgent.split("Edge/")[1];
        } else if (userAgent.indexOf("Chrome") > -1) {
            browserName = "Chrome";
            browserVersion = userAgent.split("Chrome/")[1].split(" ")[0];
        } else if (userAgent.indexOf("Safari") > -1) {
            browserName = "Safari";
            browserVersion = userAgent.split("Version/")[1].split(" ")[0];
        }

        return {
            deviceType,
            os,
            browserName,
            browserVersion,
        };
    };

    const [toggler, setToggler] = useState(1);
    function updateToggle(id) {
        setToggler(id);
    }
    const showCaptchaModel = (e) => {
        e.preventDefault();

        // if (!email || !emailPassword) return;
        setShowCaptchaModal(true);
    };

    const handleAuthCheck = async () => {
        const ss = await dispatch(fetchAuthStatus(userId, token));

        if (ss?.idcardauth === "0") {
            history.push("/identity-verification");
        } else {
            history.push("/user/dashboard");
        }
    };

    const showPhoneCaptchaModel = async (e) => {
        e.preventDefault();
        await setPhoneShowCaptchaModal(false);
        await setNextLoading(true);

        const val = await sliderCaptchaImgCreate();
        await setCaptchaVal(val.left);

        await setCaptchaImage({
            bgUrl: val.bg,
            puzzleUrl: val.puzzle,
        });

        await setNextLoading(false);

        // if (!phone || !phonePassword) return;
        await setPhoneShowCaptchaModal(true);
    };
    const emailAndPassword = useSelector((state) => ({
        // email: state.auth.email,
        password: state.auth.password,
    }));

    const handleEmailSignIn = async () => {
        console.log("Before setting local storage");
        // localStorage.setItem("password", emailPassword);
        // localStorage.setItem("email", email);
        // dispatch(setEmailAndPasswordAction(email, emailPassword));

        console.log("Before creating payload");
        let payload = {
            username: email,
            password: emailPassword,
        };

        console.log("Before dispatching loginLoading");
        dispatch(loginLoading(true));

        const userIP = await getUserIP();
        const deviceInfo = getDeviceInfo();
        let userLocation = "Unknown location";
        try {
            const location = await getUserLocation();
            userLocation = `Lat: ${location.latitude}, Long: ${location.longitude}`;
        } catch (error) {
            console.error("Failed to get location:", error);
        }

        dispatch(
            login(
                payload,
                (response) => {
                    console.log("Login success", response);
                    const loginStatus = {
                        timestamp: new Date().toISOString(),
                        device: deviceInfo.deviceType,
                        os: deviceInfo.os,
                        browser: `${deviceInfo.browserName} ${deviceInfo.browserVersion}`,
                        ip: userIP,
                        location: userLocation,
                        loginMethod: "Email",
                    };

                    sessionStorage.setItem(
                        "loginStatus",
                        JSON.stringify([loginStatus])
                    );

                    if (response.status === 1) {
                        if (response.data.ga === 0) {
                            console.log("Dispatching email2fa");
                            dispatch(
                                email2fa(
                                    localStorage.getItem("user_id_2fa"),
                                    () => setShowEmail2faModal(true)
                                )
                            );
                        } else {
                            console.log("Showing modal");
                            setShowModal(true);
                        }
                    }
                },
                () => {
                    setTwoFaType("g2fa");
                    showCaptchaModel();
                },
                () => {
                    setTwoFaType("e2fa");
                    showCaptchaModel();
                }
            )
        );
    };

    const handlePhoneSignIn = async (e) => {
        // e.preventDefault();
        // if (!phone) {
        //     toast.error("Phone is required.");
        //     return;
        // }
        // if (!phonePassword) {
        //     toast.error("Password is required.");
        //     return;
        // }
        localStorage.setItem("password", phonePassword);
        localStorage.setItem("phone", phone);

        let payload = {
            username: phone,
            password: phonePassword,
        };

        dispatch(loginLoading(true));

        // Get IP address
        const userIP = await getUserIP();
        const deviceInfo = getDeviceInfo();
        // Get location
        let userLocation = "Unknown location";
        try {
            const location = await getUserLocation();
            userLocation = `Lat: ${location.latitude}, Long: ${location.longitude}`;
        } catch (error) {
            console.error("Failed to get location:", error);
        }

        dispatch(
            login(
                payload,
                (response) => {
                    // Define the login status object
                    const loginStatus = {
                        timestamp: new Date().toISOString(),
                        device: deviceInfo.deviceType, // Mobile or Desktop
                        os: deviceInfo.os, // Operating System
                        browser: `${deviceInfo.browserName} ${deviceInfo.browserVersion}`, // Browser Name and Version
                        ip: userIP,
                        location: userLocation,
                        loginMethod: "Email", // Indicates the method used for login
                    };

                    // Store loginStatus in sessionStorage
                    sessionStorage.setItem(
                        "loginStatus",
                        JSON.stringify([loginStatus])
                    );

                    // Redirect to home page
                    //history.push("/");
                    if (response.status === 1) {
                        if (response.data.ga === 0) {
                            console.log("Dispatching email2fa");
                            dispatch(
                                response.data.ga === 0
                                    ? phone2fa(
                                        localStorage.getItem("user_id_2fa"),
                                        () => setShowPhone2faModal(true)
                                    )
                                    : setShowModal(true)
                            );
                        } else {
                            console.log("Showing modal");
                            setShowModal(true);
                        }
                    }
                },
                (username, password) => {
                    setTwoFaType("g2fa");
                    showPhoneCaptchaModel();
                    // setShowModal(true);
                },
                () => {
                    setTwoFaType("e2fa");
                    showPhoneCaptchaModel();
                }
            )
        );
    };

    const handle2faSuccess = async () => {
        setShowModal(false);
        setTimeout(async () => {
            await handleAuthCheck();
        }, 1000);
    };
    const handleEmail2faSuccess = async () => {
        setShowEmail2faModal(false);
        setTimeout(async () => {
            await handleAuthCheck();
        }, 1000);
        // await handleAuthCheck();
    };
    const handlePhone2faSuccess = async () => {
        setShowPhone2faModal(false);
        setTimeout(async () => {
            await handleAuthCheck();
        }, 1000);
        // await handleAuthCheck();
    };
    const handleCaptchaSuccess = () => {
        setShowCaptchaModal(false);
        setCaptchaVerified(true);
        //setShowCaptchaModal(false);
        handleEmailSignIn();
        // dispatch(
        //     twoFaType === "e2fa"
        //         ? email2fa(localStorage.getItem("user_id_2fa"), () =>
        //               setShowEmail2faModal(true)
        //           )
        //         : setShowModal(true)
        // );
    };

    const handlePhoneCaptchaSuccess = () => {
        setPhoneShowCaptchaModal(false);
        setCaptchaVerified(true);
        //setShowCaptchaModal(false);
        handlePhoneSignIn();
        // dispatch(
        //     twoFaType === "e2fa"
        //         ? phone2fa(localStorage.getItem("user_id_2fa"), () =>
        //               setShowPhone2faModal(true)
        //           )
        //         : setShowModal(true)
        // );
    };

    const handlePhoneCaptchaFailure = () => {
        setCaptchaVerified(false);
        // setShowCaptchaModal(false);
    };

    const handleCaptchaFailure = () => {
        setCaptchaVerified(false);
        // setShowCaptchaModal(false);
    };
    // useEffect(() => {
    //     dispatch(googleLogin());
    // }, [dispatch]);
    const loginB = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            console.log("Login successful, codeResponse:", codeResponse);
            const { access_token } = codeResponse;

            try {
                const response = await axios.get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                            Accept: "application/json",
                        },
                    }
                );
                const profileData = response.data;
                const email = profileData.email;

                // Dispatch success action
                // dispatch(googleLoginNew(email, () => history.push("/")));
                dispatch(
                    googleLoginNew(email, async () => {
                        await handleAuthCheck();
                    })
                );
                dispatch({
                    type: SET_PASSWORD,
                    payload: {
                        email: email, // use the username as email
                        password: null, // keep the password from input
                    },
                });
            } catch (error) {
                console.log("Profile fetch error:", error);
                dispatch({
                    type: "FETCH_GOOGLE_LOGIN_FAILURE",
                    payload: error.message,
                });
            }
        },
        onError: (error) => {
            console.log("Login Failed:", error);
            dispatch({
                type: "FETCH_GOOGLE_LOGIN_FAILURE",
                payload: error.message,
            });
        },
    });
    // const logOut = () => {
    //     googleLogout();
    //     setProfile(null);
    // };

    const getCaptchaHandler = async (e) => {
        e.preventDefault();
        await setShowCaptchaModal(false);
        await setNextLoading(true);
        const val = await sliderCaptchaImgCreate();
        await setCaptchaVal(val.left);

        await setCaptchaImage({
            bgUrl: val.bg,
            puzzleUrl: val.puzzle,
        });

        await setShowCaptchaModal(true);
        await setNextLoading(false);
        localStorage.setItem("active_email", email);
    };

    return (
        <>
            <div
                className={` fixed flex flex-col items-center justify-between w-full min-h-[90vh] h-[100vh]`}
            >
                <div
                    className={`${showCaptchaModal ? "block" : "hidden"
                        } absolute h-[100vh] bg-black/60  w-full z-10`}
                ></div>
                <div className="rounded-2xl text-center flex-col items-center justify-center flex border mt-4  sm:mt-[45px]  inset-0 mx-auto dark:bg-[#000000] w-[90%] sm:w-[420px]">
                    <div className="flex lg:items-center w-full px-6 py-0 sm:py-4 rounded-3xl bg-[#FFFFFF] dark:bg-[#1A191B] mb-6">
                        <div className="flex items-center justify-center w-full text-center  ">
                            <div className="w-full">
                                <div className="flex flex-col items-start sm:items-center justify-center w-full gap-4 my-3 text-start sm:text-center">
                                    <img
                                        src={logo}
                                        alt=""
                                        className="bg-white/90 sm:w-[250px] w-[200px]"
                                    />
                                    <p className="text-[14px] font-bold">
                                        Community Owned Crypto Currency
                                        Exchange
                                    </p>
                                    <h1 className="text-2xl font-bold">
                                        Log In
                                    </h1>
                                </div>

                                <div className="form-tabs flex items-center gap-4 border-b pb-1.5 border-[#F4F4F4] w-full mb-4">
                                    <button
                                        type="button"
                                        className={`relative font-bold text-[14px] transition-all ${toggler === 1
                                                ? "active"
                                                : "not-active text-[#bbb]"
                                            }`}
                                        onClick={() => setToggler(1)}
                                        role="tab"
                                        aria-selected={toggler === 1}
                                    >
                                        Email
                                    </button>
                                    <button
                                        type="button"
                                        // disabled
                                        className={`relative font-bold text-[14px] transition-all ${toggler === 2
                                                ? "active"
                                                : "not-active text-[#bbb]"
                                            }`}
                                        onClick={() => setToggler(2)}
                                        role="tab"
                                        aria-selected={toggler === 2}
                                    >
                                        Mobile
                                    </button>
                                    <button
                                        type="button"
                                        // disabled
                                        className={`relative font-bold text-[14px] transition-all ${toggler === 3
                                                ? "active"
                                                : "not-active text-[#bbb]"
                                            }`}
                                        onClick={() => setToggler(3)}
                                        role="tab"
                                        aria-selected={toggler === 3}
                                    >
                                        QR Code
                                    </button>
                                    <style>
                                        {`.${toggler === 1
                                                ? "active"
                                                : "not-active"
                                            }:after {
                                    content: "";
                                    position: absolute;
                                    bottom: -6px;
                                    left: 0;
                                    width: 100%;
                                    height: 2px;
                                    border-radius: 10px;
                                    background-color: ${toggler === 1
                                                ? "#F1BA19"
                                                : "transparent"
                                            };
                                }`}
                                        {`.${toggler === 2
                                                ? "active"
                                                : "not-active"
                                            }:after {
                                    content: "";
                                    position: absolute;
                                    bottom: -6px;
                                    left: 0;
                                    width: 100%;
                                    height: 2px;
                                    border-radius: 10px;
                                    background-color: ${toggler === 2
                                                ? "#F1BA19"
                                                : "transparent"
                                            };
                                }`}
                                        {`.${toggler === 3
                                                ? "active"
                                                : "not-active"
                                            }:after {
                                    content: "";
                                    position: absolute;
                                    bottom: -6px;
                                    left: 0;
                                    width: 100%;
                                    height: 2px;
                                    border-radius: 10px;
                                    background-color: ${toggler === 3
                                                ? "#F1BA19"
                                                : "transparent"
                                            };
                                }`}
                                    </style>
                                </div>

                                <div
                                    className={
                                        toggler === 1
                                            ? "block form-field-wrapper"
                                            : "hidden"
                                    }
                                >
                                    <form
                                        onSubmit={showCaptchaModel}
                                        encType="multipart/form-data"
                                    >
                                        <div className="flex flex-col gap-4">
                                            <div className="flex flex-col w-full">
                                                <input
                                                    type="email"
                                                    required
                                                    className="w-full px-3 py-2 bg-transparent placeholder:text-gray-300 placeholder:text-[14px] focus:ring-0 ring-0 focus:outline-none border border-gray-300 dark:border-gray-600 focus:border-red-400 hover:border-gray-400 rounded-md"
                                                    placeholder="Enter your email"
                                                    id="email"
                                                    name="email"
                                                    autoComplete="email"
                                                    value={email}
                                                    onChange={(e) => {
                                                        const emailValue =
                                                            e.target.value;
                                                        setEmail(emailValue);
                                                    }}
                                                    onBlur={(e) => {
                                                        setEmailError(
                                                            email.includes("@")
                                                                ? ""
                                                                : "Invalid Email"
                                                        );
                                                    }}
                                                    onFocus={() =>
                                                        setEmailError("")
                                                    }
                                                />
                                                {emailError && (
                                                    <div className="text-red-500 text-[12px] flex flex-row-reverse">
                                                        {emailError}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex flex-col w-full h-[50px] relative overflow-hidden">
                                                <input
                                                    type={
                                                        passwordVisible
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    id="password"
                                                    name="password"
                                                    autoComplete="current-password webauthn"
                                                    placeholder="Enter your password"
                                                    className="w-full px-3 py-2 bg-transparent placeholder:text-gray-300 placeholder:text-[14px] focus:ring-0 ring-0 focus:outline-none border border-gray-300 dark:border-gray-600 focus:border-red-400 hover:border-gray-400 rounded-md"
                                                    value={emailPassword}
                                                    onChange={(e) =>
                                                        setEmailPassword(
                                                            e.target.value
                                                        )
                                                    }
                                                />

                                                <button
                                                    type="button"
                                                    className="password-toggle-btn absolute bottom-[13px] right-[5px] p-2 text-[#15141530]"
                                                    onClick={
                                                        togglePasswordVisibility
                                                    }
                                                >
                                                    {passwordVisible ? (
                                                        <FaRegEye
                                                            width={"15px"}
                                                            height={"15px"}
                                                        />
                                                    ) : (
                                                        <FaRegEyeSlash
                                                            width={"15px"}
                                                            height={"15px"}
                                                        />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mt-1 text-end form-field-wrapper__forget-password">
                                            <Link
                                                className="w-max text-[14px] text-end mt-1 text-[#F1BA19] dark:text-[#F1BA19] hover:text-[#000] hover:dark:text-[#FFFFFF] hover:underline"
                                                to="/user/forget-password"
                                            >
                                                Forget Password?
                                            </Link>
                                        </div>
                                        {nextLoading ? (
                                            <Button
                                                className="login-button bg-[#F1BA19] hover:bg-[#f1bb19db] text-[#151415] mt-3 py-[10px] w-full rounded-md font-medium"
                                                type="submit"
                                                icon={<PoweroffOutlined />}
                                                loading
                                                size="large"
                                            >
                                                Loading...
                                            </Button>
                                        ) : (
                                            <button
                                                className="login-button bg-[#F1BA19] hover:bg-[#f1bb19db] text-[#151415] mt-3 py-[10px] w-full rounded-md font-medium"
                                                type="submit"
                                                onClick={getCaptchaHandler}
                                            >
                                                {loading
                                                    ? "Loading..."
                                                    : "Next"}
                                            </button>
                                        )}
                                    </form>
                                </div>

                                <div
                                    className={
                                        toggler === 2
                                            ? "block form-field-wrapper"
                                            : "hidden"
                                    }
                                >
                                    <form
                                        onSubmit={showPhoneCaptchaModel}
                                        encType="multipart/form-data"
                                    >
                                        <div className="flex flex-col gap-4">
                                            <div className="flex flex-col w-full">
                                                <PhoneInput
                                                    country={defaultCountry}
                                                    value={phone}
                                                    onChange={(phone) =>
                                                        setPhone(phone)
                                                    }
                                                    inputClass="w-full px-3 py-2 bg-transparent placeholder:text-gray-300 placeholder:text-[14px] focus:ring-0 ring-0 hover:border-[#F1BA19] focus:outline-none border border-[#151415] dark:border-[#F4F4F4] focus:border-red-400 rounded-md"
                                                    containerClass="w-full"
                                                    inputProps={{
                                                        name: "phone",
                                                        id: "phone",
                                                        autoComplete: "phone",
                                                    }}
                                                    placeholder="Enter your phone"
                                                />
                                            </div>
                                            <div className="flex flex-col w-full h-[50px] relative overflow-hidden">
                                                <input
                                                    type={
                                                        passwordVisible
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    id="phonePassword"
                                                    name="phonePassword"
                                                    autoComplete="current-password webauthn"
                                                    placeholder="Enter your password"
                                                    className="w-full px-3 py-2 bg-transparent placeholder:text-gray-300 placeholder:text-[14px] focus:ring-0 ring-0 hover:border-gray-400 focus:outline-none border border-gray-300 dark:border-gray-600 focus:border-red-400 rounded-md"
                                                    value={phonePassword}
                                                    onChange={(e) =>
                                                        setPhonePassword(
                                                            e.target.value
                                                        )
                                                    }
                                                />

                                                <button
                                                    type="button"
                                                    className=" password-toggle-btn absolute bottom-[13px] right-[5px] p-2  cursor-pointer text-[#c4c4c4]"
                                                    onClick={
                                                        togglePasswordVisibility
                                                    }
                                                >
                                                    {passwordVisible ? (
                                                        <FaRegEye
                                                            width={"15px"}
                                                            height={"15px"}
                                                        />
                                                    ) : (
                                                        <FaRegEyeSlash
                                                            width={"15px"}
                                                            height={"15px"}
                                                        />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mt-1 text-end">
                                            <Link
                                                className="w-max text-[14px] text-end mt-1 text-[#F1BA19] dark:text-[#F1BA19] hover:text-[#000] hover:dark:text-[#FFFFFF] hover:underline"
                                                to="/user/forget-password"
                                            >
                                                Forget Password?
                                            </Link>
                                        </div>
                                        {nextLoading ? (
                                            <Button
                                                className="bg-[#F1BA19] hover:bg-[#f1bb19db] text-[#151415] mt-3 py-[10px] w-full rounded-md font-medium"
                                                type="submit"
                                                icon={<PoweroffOutlined />}
                                                size="large"
                                                loading
                                            >
                                                Loading...
                                            </Button>
                                        ) : (
                                            <button
                                                className="bg-[#F1BA19] hover:bg-[#f1bb19db] text-[#151415] mt-3 py-[10px] w-full rounded-md font-medium"
                                                type="submit"
                                                onClick={showPhoneCaptchaModel}
                                            >
                                                {loading
                                                    ? "Loading..."
                                                    : "Next"}
                                            </button>
                                        )}
                                    </form>
                                </div>

                                <div
                                    className={
                                        toggler === 3
                                            ? "block form-field-wrapper"
                                            : "hidden"
                                    }
                                >
                                    <div className="flex flex-col items-center justify-center ">
                                        <div className="w-52 ">
                                            {/* QR code image */}
                                            <img
                                                src={barCode}
                                                alt="QR Code"
                                                className="object-cover w-full h-full"
                                            />
                                        </div>
                                        <div className="mt-6 text-center">
                                            <p className="text-xl font-medium">
                                                Scan with your{" "}
                                                <span className="text-2xl font-bold text-black">
                                                    BUYCEX
                                                </span>{" "}
                                                App
                                            </p>
                                            <p className="mt-2 text-gray-500">
                                                Don't have the BuyCex app yet?
                                                Scan the QR code above to
                                                download the latest version.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2 mt-4 mb-7">
                                    {/* <div className="flex items-center my-1">
                                        <div className="flex-grow border-t border-gray-300"></div>
                                        <span className="mx-4 text-gray-500 text-[14px]">
                                            log in with
                                        </span>
                                        <div className="flex-grow border-t border-gray-300"></div>
                                    </div> */}
                                    <div className="flex items-center w-full  ">
                                        <a
                                            // href={isLoading ? "" : googleData}
                                            className="w-full hover:text-black"
                                        >
                                            <button
                                                onClick={() => loginB()}
                                                className="footer__google-btn inline-flex items-center text-nowrap text-[14px] border border-gray-300 w-full rounded-md px-6 py-2.5 dark:text-white justify-start hover:dark:bg-[#000000] font-bold "
                                            >
                                                <FcGoogle className="w-[18px] h-[18px] me-2" />
                                                <span className="ml-2 md:ml-4 lg:ml-24">
                                                    Continue With Google
                                                </span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <Link
                                    className="text-[#EFB81C] text-[22px] font-bold hover:underline"
                                    to="/register"
                                >
                                    Signup Now
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {showModal && (
                    <G2faLoginVerification
                        username={email}
                        password={emailPassword}
                        onClose={() => setShowModal(false)}
                        onSuccess={handle2faSuccess}
                    />
                )}
                {showEmail2faModal && (
                    <Email2faVerification
                        username={email}
                        password={emailPassword}
                        onClose={() => setShowEmail2faModal(false)}
                        onSuccess={handleEmail2faSuccess}
                    />
                )}
                {showPhone2faModal && (
                    <Phone2faVerification
                        username={email}
                        password={emailPassword}
                        onClose={() => setShowPhone2faModal(false)}
                        onSuccess={handlePhone2faSuccess}
                    />
                )}
                {showCaptchaModal && (
                    <CaptchaModal
                        isOpen={showCaptchaModal}
                        onClose={() => setShowCaptchaModal(false)}
                        onSuccess={handleCaptchaSuccess}
                        onFailure={handleCaptchaFailure}
                        captchaVal={captchaVal}
                        captchaImage={captchaImage}
                        getCaptchaHandler={getCaptchaHandler}
                    />
                )}
                {showPhoneCaptchaModal && (
                    <CaptchaModal
                        isOpen={showPhoneCaptchaModal}
                        onClose={() => setPhoneShowCaptchaModal(false)}
                        onSuccess={handlePhoneCaptchaSuccess}
                        onFailure={handlePhoneCaptchaFailure}
                        captchaVal={captchaVal}
                        captchaImage={captchaImage}
                        getCaptchaHandler={getCaptchaHandler}
                    />
                )}

                <div className="flex justify-center text-sm font-semibold space-x-6 text-gray-500 mb-2">
                    <a href="#" className="cursor-pointer hover:text-gray-700">
                        <GlobalOutlined /> English
                    </a>
                    <a href="#" className="cursor-pointer hover:text-gray-700">
                        Cookies
                    </a>
                    <a href="#" className="cursor-pointer hover:text-gray-700">
                        Terms
                    </a>
                    <a href="#" className="cursor-pointer hover:text-gray-700">
                        Privacy
                    </a>
                </div>
            </div>
        </>
    );
};

export default Login;
