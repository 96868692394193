import React from 'react'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import legalTerms from "assets/img/LegalTerms.svg";

function RiskDisclosure() {
  return (
    <div className="flex flex-col justify-center self-stretch text-sm leading-6 bg-[#F4F4F4] text-[#151415] dark:bg-[#151415] dark:text-[#F4F4F4]">
    <div className="flex flex-col px-5 pb-20 w-full max-md:max-w-full">
      <div className="flex overflow-hidden relative flex-col px-2 w-full text-5xl text-white leading-[48.3px] min-h-[267px] hidden md:flex">
        <img
          loading="lazy"
          src={legalTerms}
          alt="Legal Terms"
          className="object-cover absolute inset-0 size-full"
        />
      </div>

    <div className="flex flex-col justify-center py-px w-full text-base text-center border-b border-gray-200 border-solid leading-[70px] text-zinc-500 max-md:py-5 max-md:max-w-full">
      <div className="flex justify-center items-center px-16 w-full max-md:px-5 max-md:max-w-full">
        <div className="flex gap-5 justify-between max-w-full w-[571px] max-md:flex-row max-md:w-full max-md:mr-8 max-md:gap-2 max-md:text-sm">
          <Link
            to="/terms-and-conditions"
            className="hover:text-amber-300 focus:border-b-2 focus:border-amber-500 focus:outline-none"
          >
            Terms and Conditions
          </Link>
          <Link
            to="/privacy-policy"
            className="hover:text-amber-300 focus:border-b-2 focus:border-amber-500 focus:outline-none"
          >
            Privacy Policy
          </Link>
          <div className="justify-center border-b-2 border-amber-500 border-solid text-neutral-700 dark:text-[#F4F4F4] cursor-pointer">
            Risk Disclosure Statement
          </div>
        </div>
      </div>
    </div>

      <div className="mt-20 w-full max-md:mt-10 max-md:max-w-full max-md:text-sm">
        This Risk Disclosure Statement (this{" "}
        <span className="font-bold max-md:text-sm">“Statement”</span>)
        applies to the electronic trading platform (including any applicable
        mobile applications and websites used to access the same)
        (collectively the{" "}
        <span className="font-bold max-md:text-sm">“Platform”</span>)
        provided by Bybit Fintech Limited (the{" "}
        <span className="font-bold max-md:text-sm">“Company”</span>,{" "}
        <span className="font-bold max-md:text-sm">“we”, “us”</span> or
        <span className="font-bold max-md:text-sm">“our”</span>), and shall
        be deemed as part of the {<Link
            to="#!"
            className="text-amber-300 hover:text-amber-300 focus:outline-none"
          >
            Service Agreement
          </Link>} between the User and 
        the Company (the “Service Agreement”). All capitalized terms that are 
        used but not otherwise defined herein shall have the meanings ascribed 
        to them in the Service Agreement.
      </div>
      <div className="mt-8 w-full max-md:max-w-full max-md:text-sm">
        In acceding to or using the Platform and the Site, you represent and
        warrant that you are fully aware of the risks associated with the
        transactions involving Digital Assets or the use of Platform. You
        agree and understand that you are solely responsible for determining
        the nature, potential value, suitability, and appropriateness of these
        risks for yourself, and that the Company does not give advice or
        recommendations regarding any Digital Asset, including the suitability
        and appropriateness of, and investment strategies for, any Digital
        Asset. <span className="font-bold max-md:text-sm">You </span>
        <span className="font-bold max-md:text-sm">
          agree and understand that you access and use the Platform and the
          Site at your own risk.
        </span>{" "}
        This brief statement does not disclose all of the risks associated
        with the Digital Assets and using the Platform. You should, therefore,
        carefully consider whether such use is suitable for you in light of your
        circumstances and financial resources. You should
        be aware that you may sustain a total loss of the Digital Assets in
        your Account, and that under certain market conditions, you may find
        it difficult or impossible to liquidate a position.
      </div>
      <div className="justify-center py-px mt-8 w-full text-2xl font-bold border-b border-gray-200 border-solid leading-[49px] max-md:max-w-full max-md:text-sm">
        1.Risks Relating to Digital Assets
      </div>
      <div className="mt-5 w-full max-md:max-w-full max-md:text-sm">
        ·The price and value of any investment in Digital Asset products and
        the income, if any, from them, can fluctuate and may fall against your
        interest. An individual Digital Asset product may experience downward
        price movements and may under some circumstances even
        become valueless. An inherent risk of trading/investing in Digital
        Asset products is that losses may be incurred, rather than profits
        made, as a result of buying and selling such products.
      </div>
      <div className="mt-6 max-md:max-w-full max-md:text-sm">
        ·Digital Assets are not legal tender and are not backed by the
        government, or by commodities such as gold or silver. There is no
        central bank that can take corrective measure to protect the value of
        Bitcoin and/or Ether and/or any other Digital Assets in a crisis or
        issue more Digital Assets. Instead, Bitcoin, Ether and any other Digital Assets
        are autonomous and largely unregulated on worldwide networks. Traders
        put their trust in a digital, decentralized and partially anonymous
        system that relies on peer-to-peer networking and cryptography to
        maintain its integrity.
      </div>
      <div className="mt-6 w-full leading-[157%] max-md:max-w-full max-md:text-sm">
        ·Transactions in Digital Assets may be irreversible, and, accordingly,
        losses due to fraudulent or accidental transactions may not be
        recoverable;
      </div>
      <div className="mt-6 w-full leading-[157%] max-md:max-w-full max-md:text-sm">
        ·Some Digital Asset transactions shall be deemed to be made when
        recorded on a public ledger, which is not necessarily the date or time
        that you initiated the transaction;
      </div>
      <div className="mt-6 w-full max-md:max-w-full max-md:text-sm">
        ·The value of Digital Assets may be derived from the continued
        willingness of market participants to exchange fiat currency or
        Digital Assets for Digital Assets, which may result in the potential
        for permanent and total loss of value of a particular Digital Asset
        should the market for that Digital Asset disappear;
      </div>
      <div className="mt-6 w-full leading-[157%] max-md:max-w-full max-md:text-sm">
        ·The nature of Digital Assets may lead to an increased risk of fraud
        or cyber attack, and may mean that technological difficulties
        experienced by the Platform may prevent the access to or use of your
        Digital Assets;
      </div>
      <div className="mt-6 w-full max-md:max-w-full max-md:text-sm">
        ·Trading of Digital Assets is susceptible to irrational (or rational)
        bubbles or loss of confidence, which could collapse demand relative to
        supply. For example, confidence might collapse in Bitcoin because of
        unexpected changes imposed by the software developers or others, a
        government crackdown, the creation of superior competing alternative
        cryptocurrencies, or a deflationary or inflationary spiral. Confidence
        might also collapse if the anonymity of the system is compromised, if
        money is lost or stolen, or if hackers or governments can prevent
        any transactions from settling.
      </div>
      <div className="mt-6 w-full leading-[157%] max-md:max-w-full max-md:text-sm">
        ·Deposits into your Account is not considered deposits under the
        applicable laws, rules, or regulations in your jurisdiction.
      </div>
      <div className="mt-6 w-full leading-[157%] max-md:max-w-full max-md:text-sm">
        ·Digital Assets in your Account is not subject to applicable deposit
        insurance protection.
      </div>
      <div className="justify-center py-px mt-8 w-full text-2xl font-bold border-b border-gray-200 border-solid leading-[49px] max-md:max-w-full max-md:text-sm">
        2. Risks Relating to Derivatives, Margin and Security/Digital Asset
        Borrowing
      </div>
      <div className="mt-5 w-full max-md:max-w-full max-md:text-sm">
        ·Derivatives products carry a high degree of risk as they often
        involve gearing or leverage, so that a relatively small movement in
        the price of the underlying Digital Asset results in a much larger
        movement, favourable or unfavourable, in the price of the product. If
        the direction
        of the trade is opposite to the fluctuation of the market, it will
        cause a large loss. Depending on the extent of the loss, investors
        need to add margins or reduce positions, otherwise, their positions
        may be forced to liquidate, and investors must bear all the losses
        caused thereby.
      </div>
      <div className="mt-6 w-full max-md:max-w-full max-md:text-sm">
        ·The risk of loss in financing a transaction by deposit of collateral
        may be significant. You may sustain losses in excess of your Digital
        Assets deposited as collateral with the Company. You may be called
        upon at short notice to make additional margin deposits or interest
        payments. If required margin deposit or interest payment is not made
        within the prescribed time, your collateral or positions may be
        liquidated by the Company at a loss without prior notification to you.
        You should therefore carefully consider whether such a financing
        arrangement is suitable in light of your own financial position and
        investment objectives.
      </div>
      <div className="mt-6 w-full max-md:max-w-full max-md:text-sm">
        ·When you borrow securities and/or Digital Assets from the Company,
        you will be required to deposit a required level of collateral. You
        may be called upon at short notice to place additional deposits if the
        level of collateral is inadequate in relation to the market value of
        borrowed assets. If the required deposit is not made within the
        prescribed time, the Company may buy back the borrowed securities
        and/or Digital Assets without prior notification to you. When you lend
        securities and/or Digital Assets to the Company, you temporarily lose
        legal ownership rights to the assets lent but in place, has a right to
        claim equivalent products.
      </div>
      <div className="justify-center py-px mt-14 w-full text-2xl font-bold border-b border-gray-200 border-solid leading-[49px] max-md:mt-10 max-md:max-w-full max-md:text-sm">
        3.Other Risks
      </div>
      <div className="mt-5 w-full leading-[157%] max-md:max-w-full max-md:text-sm">
        ·Legislative and regulatory changes or actions at the state, federal,
        or international level may adversely affect the use, transfer,
        exchange, and value of Digital Assets.
      </div>
      <div className="mt-6 w-full max-md:max-w-full max-md:text-sm">
        ·
        <span className="font-bold max-md:text-sm">
          NEITHER THE COMPANY NOR THE PLATFORM IS REGISTERED AS A BROKER OR
          DEALER, A NATIONAL SECURITIES CHANGE, A CAPITAL MARKET SERVICE
          PROVIDER OR AN ALTERNATIVE TRADING SYSTEM IN ANY JURISDICTION. THE
          TRADING AND SETTLEMENT OF
        </span>
        <span className="font-bold max-md:text-sm">
          THE TRANSACTIONS REGARDING THE DIGITAL ASSETS ARE NOT OVERSIGHT BY
          ANY GOVERNMENTAL AUTHORITY OR SELF-REGULATORY INSTITUTION.
        </span>
      </div>
      <div className="mt-6 w-full leading-[157%] max-md:max-w-full max-md:text-sm">
        ·The Platform or the Site may become unavailable due to changes of
        applicable laws and you may suffer loss or incur liabilities due to
        the termination or suspension of the Platform or any or all of the
        Accounts.
      </div>
      <div className="mt-6 w-full max-md:max-w-full max-md:text-sm">
        ·The Company’s trading facilities are supported by computer-based
        component systems for the order-routing, execution, matching,
        registration or clearing of trades. As with all facilities and
        computer systems, customers will be exposed to risks associated with
        the systems
        including the failure of hardware and software. The result of any
        system failure may be that your order is either not executed according
        to instructions or is not executed at all. You should also be aware
        that the Internet is not a completely reliable transmission medium and
        there may be delays in service provisions.
      </div>
      <div className="mt-6 mb-5 w-full max-md:max-w-full max-md:text-sm">
        ·You should note in particular that the proceeds from the sale of
        Digital Assets and other income may be or may become subject to tax,
        levies, duties or other fees or charges imposed by the authorities in
        that market, including taxation levied by withholding at source. Tax
        law and practice regarding Digital Assets in certain countries may not be
        clearly established. It is therefore possible that the current
        interpretation of the law or understanding of practice may change, or
        that the law may be changed with retrospective effect.
      </div>
    </div>
  </div>
  );
}

export default RiskDisclosure