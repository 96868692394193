import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsExclamationCircle } from "react-icons/bs";
import HistoryDropdown from '../SpotHistory/DropDownUI';
import EditModal from './EditModal';
import AddModal from './AddModal';
import { getUserWallets,deleteWalletWhitelist,addWalletWhitelist ,editWalletWhitelist} from 'store/actions/walletaddreswhitelistAction';
import { Modal } from 'antd';

import { fetchMarketData } from 'store/actions/marketActions';
function WithdrawAddressWhitelist() {
    const options1 = ["All", "Paid", "Receive", "Pre-Authorization"];
    const options2 = ["All", "Paid", "Receive", "Pre-Authorization"];
    
    const dispatch = useDispatch();
    const handleSelect1 = (selected) => {
        console.log("Dropdown 1 selected:", selected);
    };
    const { wallets, loading } = useSelector((state) => state.walletwhitelist);
    const marketData = useSelector((state) => state.market.data?.market || []);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    useEffect(() => {
        if (userID && userToken) {
            dispatch(getUserWallets(userToken, userID));
            dispatch(fetchMarketData());
            
        }
    }, [dispatch,userToken, userID]);


    
    const addressData = wallets.map((wallet) => ({
        
        coinId: wallet.id?.toUpperCase() || "N/A",
        coinName: wallet.coinname?.toUpperCase() || "N/A", // Convert to uppercase if available
        networkName: wallet.name || "Unknown Network",   // Use 'name' or default text
        addtime: wallet.addtime || "No Notes",                // Default note if missing
        walletAddress: wallet.addr,   
        Id: wallet.id?.toUpperCase() || "N/A",                 // Wallet address from API
    }));

    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isAddModalVisible, setisAddModalVisible] = useState(false);

    const showEditModal = () => {
        setIsEditModalVisible(true);
    };

    const handleEditOk = () => {
        setIsEditModalVisible(false);
    };

    const handleEditCancel = () => {
        setIsEditModalVisible(false);
    };

    const showAddModal = () => {
        setisAddModalVisible(true);
    };

    const handleAddOk = () => {
        setisAddModalVisible(false);
    };

    const handleAddCancel = () => {
        setisAddModalVisible(false);
    };
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState(null);

    const handleDeleteClick = (wallet) => {
        setSelectedWallet(wallet); // Store wallet details
        setIsDeleteModalVisible(true); // Show modal
    };

    const handleEditClick = (wallet) => {
        setSelectedWallet(wallet); // Store wallet details
        showEditModal();
    };
    const handleDeleteConfirm = () => {
        if (userToken && userID && selectedWallet?.coinId) {
            dispatch(deleteWalletWhitelist(selectedWallet.coinId, userToken, userID))
                .then(() => {
                    // Re-fetch the wallets after successful deletion
                    dispatch(getUserWallets(userToken, userID));
                })
                .catch((error) => {
                    // Handle error (optional)
                    console.error("Failed to delete wallet:", error);
                });
        }
        setIsDeleteModalVisible(false); // Close modal after deletion
    };
    
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false); // Close modal without deletion
    };

    return (
        <div className='px-3'>
            <div className='container mt-10 !p-0'>
                <div className='flex w-full justify-between border-b pb-5'>
                    <div>
                        <p className='text-2xl font-semibold pb-3'>Wallet Address</p>
                        <div className='flex items-center gap-8'>
                            <div className='flex items-center gap-2'>
                                <BsExclamationCircle className='opacity-50' />
                                <p>Withdraw via Address Book</p>
                            </div>
                            <div className='flex items-center gap-2'>
                                <BsExclamationCircle className='opacity-50' />
                                <p>Withdrawals are unavailable for newly saved addresses for 24 hours</p>
                            </div>
                        </div>
                    </div>
                    <button onClick={showAddModal} className='bg-[#EFB81C] px-4 h-10 font-semibold rounded-md text-black'>+Add</button>
                </div>
                <div className='flex w-full justify-between'>
                    <div className='flex gap-5 mt-5'>
                        <HistoryDropdown
                            label="Type"
                            options={options1}
                            initialSelected="All"
                            onSelect={handleSelect1}
                            isSearchable={false}
                            width="80px"
                        />
                        <HistoryDropdown
                            label="Assets"
                            options={options2}
                            initialSelected="All"
                            onSelect={handleSelect1}
                            isSearchable={false}
                            width="80px"
                        />
                        <input placeholder='Enter the address' className='inline-flex items-center justify-between gap-x-1.5 rounded-lg px-3 py-2.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-200 hover:bg-gray-50' />
                        <button className='ring-1 ring-inset ring-gray-300 px-3 rounded-md font-semibold text-black'>Search</button>
                    </div>

                    <label className="inline-flex items-end cursor-pointer">
                        <input type="checkbox" value="" className="sr-only peer" />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#EFB81C]"></div>
                        <p className='pl-3 pr-6 font-medium'>Withdrawal Address Whitelist</p>
                    </label>
                </div>
                <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
    <div className="flex justify-between border-b pb-3 w-full">
        <p className="w-[15%] text-start">Coin Name</p>
        <p className="w-[15%] text-start">Network Name</p>
        <p className="w-[20%] text-start">Added Time</p>
        <p className="w-[35%] text-center">Wallet Address</p>
        <p className="w-[15%] text-center">Actions</p>
    </div>
    {addressData.map((item, index) => (
        <div key={index} className="flex border-b justify-between pb-2 w-full">
            {/* Display Coin Name */}
            <p className="w-[15%] text-sm text-black py-4 text-start">{item.coinName}</p>

            {/* Display Network Name */}
            <p className="w-[15%] text-sm text-black py-4 text-start">{item.networkName}</p>

            {/* Display Added Time */}
            <p className="w-[20%] text-sm text-black py-4 text-start">{item.addtime}</p>

            {/* Display Wallet Address */}
            <p className="w-[35%] text-sm text-black py-4 text-center">{item.walletAddress}</p>

            {/* Action Buttons */}
            <p className="w-[15%] text-sm text-black py-4 flex gap-3 justify-center text-center">
                <button   onClick={() => handleEditClick(item)}className="text-[#EFB81C] font-medium">
                    Edit
                </button>
         

                                        <button
                        onClick={() => handleDeleteClick(item)}
                        className="text-[#EFB81C] font-medium"
                    >
                        Delete
                    </button>

            </p>
        </div>
    ))}
</div>
<EditModal
getUserWallets={getUserWallets}
marketData={marketData}
    selectedWallet={selectedWallet} // Pass selected wallet details
    editWalletWhitelist={editWalletWhitelist}
    visible={isEditModalVisible}
    onOk={handleEditOk}
    onCancel={handleEditCancel}
/>


                <AddModal  getUserWallets={getUserWallets} addWalletWhitelist={addWalletWhitelist} marketData={marketData} visible={isAddModalVisible} onOk={handleAddOk} onCancel={handleAddCancel} />

                 {/* Delete Confirmation Modal */}
                 <Modal
                title="Confirm Deletion"
                visible={isDeleteModalVisible}
                onOk={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
                okText="Delete"
                okButtonProps={{ danger: true }}
                cancelText="Cancel"
            >
                {selectedWallet && (
                    <>
                     

                        <div className="flex border-b justify-between pb-2 w-full">
            {/* Display Coin Name */}
            <p className="w-[15%] text-sm text-black py-4 text-start">{selectedWallet.coinName}</p>

            {/* Display Network Name */}
            <p className="w-[15%] text-sm text-black py-4 text-start">{selectedWallet.networkName}</p>

            {/* Display Added Time */}
            <p className="w-[20%] text-sm text-black py-4 text-start">{selectedWallet.addtime}</p>

            {/* Display Wallet Address */}
            <p className="w-[35%] text-sm text-black py-4 text-center">{selectedWallet.walletAddress}</p>

       
        </div>
                    </>
                )}
            </Modal>
            </div>
        </div>
    );
}

export default WithdrawAddressWhitelist;
