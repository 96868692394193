import React, { useEffect, useState } from "react";
import "../../components/home-page-sections/hero-section/./HeroSection.scss";
import LaunchpadProjects from '../../components/Launchpad/LaunchpadProjects';
const heroImages = [HeroImageOne, HeroImageTwo, HeroImageThird];
import HeroImageOne from "assets/img/Aboutsection3.png";
import HeroImageTwo from "assets/img/Announcement.svg";
import HeroImageThird from "assets/img/Announcement.svg";
import HowToJoin from "components/Launchpad/Startup-task/HowToJoin ";
import FaQs from "components/Launchpad/Startup-task/FAQItem";
import { FaBell } from 'react-icons/fa';
import Launchnavbar from "components/Launchpad/Launchnavbar";
const StartupTask = () => {

  const flippingWords = ["tasks ", "Event", "Participation"];
  const [currentWord, setCurrentWord] = useState("tasks ");
  const [flipState, setFlipState] = useState("flip-enter");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageTransitionState, setImageTransitionState] = useState("fade-in");

  useEffect(() => {
    const wordInterval = setInterval(() => {
        setFlipState("flip-exit");

        setTimeout(() => {
            setCurrentWord((prev) => {
                const nextIndex =
                    (flippingWords.indexOf(prev) + 1) %
                    flippingWords.length;
                return flippingWords[nextIndex];
            });
            setFlipState("flip-enter");
        }, 600);
    }, 3000);

    return () => clearInterval(wordInterval);
}, []);

useEffect(() => {
    const imageInterval = setInterval(() => {
        setImageTransitionState("fade-out");

        setTimeout(() => {
            setCurrentImageIndex((prevIndex) => {
                return (prevIndex + 1) % heroImages.length;
            });
            setImageTransitionState("fade-in");
        }, 600);
    }, 3000);

    return () => clearInterval(imageInterval);
}, []);
  return (
<>
  <div className="text-black min-h-screen">
    {/* Navbar */}
    <Launchnavbar/>


<section className="hero-section border-b flex relative mb-8 pb-8"> {/* Reduced mb and pb */}
  <div className="hero-section__wrapper container flex items-center justify-between gap-8 w-full max-h-[60vh] m-auto max-md:flex-col">
    {/* Left Section */}
    <div className="hero-title-wrapper flex flex-col gap-4 w-[60%] mb-10"> {/* Reduced bottom margin */}
      <h1 className="hero-title flex flex-col gap-1 text-[48px] font-[600] leading-tight">
        <span className="text-[48px] font-[900] text-[#EFB81C]">Startup Task</span>
      </h1>

      <p className="hero-description text-xl mb-4"> {/* Reduced margin-bottom */}
        <span>Complete</span>{" "}
        <span className={`flip-text ${flipState}`}>{currentWord}</span>{" "}
        <span>to claim airdrops</span>
      </p>

   





      <div className="hero-btn-wrap flex items-center gap-4 mt-4 mb-8"> {/* Reduced margin-top and margin-bottom */}
        <button className="bg-[#EFB81C] text-white py-2 px-6 rounded-md">
          How to Earn
        </button>
        <button className="bg-[#EFB81C10] hover:bg-[#EFB81C] text-black py-2 px-6 rounded-md flex items-center">
      <FaBell className="mr-2" /> {/* Bell icon */}
      Subscription Notification
    </button>
      </div>
    </div>

    {/* Right Section: Hero Image */}
    <div className="hero-image-wrapper relative w-[40%] min-h-[400px] rounded-xl overflow-hidden">
      {heroImages.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`hero-img-${index}`}
          className={`hero-image absolute top-0 left-0 w-full h-full rounded-xl object-contain transition-opacity duration-700 ${imageTransitionState} ${
            index === currentImageIndex ? "active" : "inactive"
          }`}
        />
      ))}
    </div>
  </div>
</section>

<HowToJoin/>
<FaQs/>

    
  </div>
  
</>

  );
};

export default StartupTask;
