import React, { useState, useEffect } from "react";
import { GoDotFill } from "react-icons/go";
import { NavLink } from "react-router-dom";
import { FaCheck, FaArrowRight } from "react-icons/fa";
import { components } from "react-select";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CoinSelect from "./CoinSelect";
import NetworkSelect from "./NetworkSelect";
import DepositAddress from "./DepositAddress";
import trace from "assets/img/image-21@2x.png";
import RoutingNavbar from "components/routingNavbar/RoutingNavbar";
import { ImNotification } from "react-icons/im";


const CustomOption = ({ data, ...props }) => (
    <components.Option {...props}>
        <div className="flex items-center">
            <img
                src={data.icon}
                alt={`${data.name}-coin`}
                className="w-6 h-6 mr-2"
            />
            <div className="flex-grow">{data.label}</div>
        </div>
    </components.Option>
);

function CryptoDeposit() {
    const [coins, setCoins] = useState([]);
    const [selectedCrypto, setSelectedCrypto] = useState(null);
    const [networks, setNetworks] = useState([]); // Initialize as an empty array
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [withdrawalAddress, setWithdrawalAddress] = useState("");
    const [deposits, setDeposits] = useState([]);
    const history = useHistory();
    const [loading, setLoading] = useState(true); // Add loading state

    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};

    console.log("Token", TOKEN, ID);
    useEffect(() => {
        // Check on mount if TOKEN or ID is missing
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
            return;
        }

        // Fetch coin data from the API
        fetch("https://api.buycex.com/Index/marketInfo")
            .then((response) => response.json())
            .then((data) => {
                // Create an array to store coin data
                const coinsData = [];

                // Iterate over the market data to extract coin information
                data.data.market.forEach((coin) => {
                    // Get the coin name and icon URL
                    const name = coin.ticker.split("_")[0].toUpperCase();
                    const icon = coin.icon;

                    // Add the coin to the array if it's not already present
                    if (
                        name !== "LTC" &&
                        name !== "DOGE" &&
                        !coinsData.some((c) => c.value === name)
                    ) {
                        coinsData.push({
                            value: name,
                            label: name,
                            icon: icon,
                            onlySend: `Only send ${name}`,
                        });
                    }
                });

                // Manually add additional coin data
                coinsData.push(
                    {
                        value: "ADA",
                        label: "ADA",
                        icon: "https://api.buycex.com/Upload/coin/65f83ff22d26f.png",
                        onlySend: "Only send ADA",
                    },
                    {
                        value: "Avax",
                        label: "Avax",
                        icon: "https://api.buycex.com/Upload/coin/65f843f1daacc.png",
                        onlySend: "Only send Avax",
                    },
                    {
                        value: "USDT",
                        label: "USDT",
                        icon: "https://api.buycex.com/Upload/coin/USDT.png",
                        onlySend: "Only send USDT",
                    },
                    {
                        value: "TUSDT",
                        label: "TUSDT",
                        icon: "https://api.buycex.com/Upload/coin/65f98a1338aff.png",
                        title: "Tether USDT [TRC20](TUSDT)",
                        onlySend: "Only send USDT",
                    },
                    {
                        value: "BUSDT",
                        label: "BUSDT",
                        icon: "https://api.buycex.com/Upload/coin/65f98a1338aff.png",
                        title: "USDT [Bep20](BUSDT)",
                        onlySend: "Only send USDT",
                    }
                );

                // Set the state with the coin data
                setCoins(coinsData);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [TOKEN, ID, history]);

    useEffect(() => {
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
            return;
        }

        if (selectedCrypto && TOKEN && ID) {
            fetch(
                `https://api.buycex.com/Crypto/coinbalance/coin/${selectedCrypto.value}`,
                {
                    headers: {
                        TOKEN: TOKEN,
                        ID: ID,
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.data) {
                        // Check if data exists and has a 'data' property
                        // Set the networks as an array of objects with value and label properties
                        setNetworks([
                            { value: data.data.symbol, label: data.data.title },
                        ]);
                    } else {
                        console.log("No data available");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [selectedCrypto, TOKEN, ID, history]);

    useEffect(() => {
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
            return;
        }

        if (selectedCrypto) {
            fetch(
                `https://api.buycex.com/Crypto/coinbalance/coin/${selectedCrypto.value}`,
                {
                    headers: {
                        TOKEN: TOKEN,
                        ID: ID,
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.data) {
                        setNetworks([
                            { value: data.data.symbol, label: data.data.title },
                        ]);
                    } else {
                        console.log("No data available");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });

            fetch(
                `https://api.buycex.com/Crypto/MyDeposits/coin/${selectedCrypto.value}`,
                {
                    headers: {
                        TOKEN: TOKEN,
                        ID: ID,
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.data) {
                        setDeposits(data.data);
                    } else {
                        console.log("No deposits data available");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching deposit data:", error);
                });

            const fetchWallet = async () => {
                try {
                    const response = await fetch(
                        `https://api.buycex.com/Crypto/depositaddress/coin/${selectedCrypto.value.toLowerCase()}`,
                        {
                            headers: {
                                TOKEN: TOKEN,
                                ID: ID,
                            },
                        }
                    );
                    const data = await response.json();
                    setWithdrawalAddress(data.wallet);
                } catch (error) {
                    console.error("Error fetching wallet:", error);
                }
            };
            fetchWallet();
        }
    }, [selectedCrypto, TOKEN, ID, history]);
    useEffect(() => {
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
            return;
        }

        const fetchDeposits = async () => {
            try {
                setLoading(true); // Set loading to true before fetching
                const response = await fetch(
                    `https://api.buycex.com/Crypto/MyDeposits/coin/all`,
                    {
                        headers: {
                            TOKEN: TOKEN,
                            ID: ID,
                        },
                    }
                );
                const data = await response.json();
                if (Array.isArray(data.data)) {
                    setDeposits(data.data);
                } else {
                    setDeposits([]);
                }
            } catch (error) {
                console.error("Error fetching deposit data:", error);
                setDeposits([]);
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchDeposits();
    }, [TOKEN, ID, history]);

    useEffect(() => {
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
            return;
        }

        if (selectedCrypto) {
            const cryptoDeposit = async () => {
                try {
                    const response = await fetch(
                        `https://api.buycex.com/Wallet/cryptodeposit?coin=${selectedCrypto.value.toLowerCase()}`,
                        {
                            headers: {
                                TOKEN: TOKEN,
                                ID: ID,
                            },
                        }
                    );
                    const data = await response.json();
                    // console.log("data response",data);
                    // setWithdrawalAddress(data.wallet);
                } catch (error) {
                    console.error("Error fetching wallet:", error);
                }
            };
            cryptoDeposit();
        }
    }, [selectedCrypto, TOKEN, ID, history]);

    const handleImageClick = (coin) => {
        setSelectedNetwork(null);
        setSelectedCrypto(coin);
    };
    const faqs = [
        "How to Make a Deposit",
        "Unsupported Deposit Recovery Procedure Rules",
        "FAQ — Crypto Deposit",
        "How to Recover a Deposit with Wrong or Missing Tag/Memo",
        "Deposits yet to be credited? Self-Service",
        "Deposit/Withdrawal Status of All Coins Find Out",
    ];
    const SkeletonRow = () => (
        <tr>
            <td className="px-4 py-3 text-center">
                <div className="w-4 h-4 m-auto bg-gray-300 rounded-full animate-pulse"></div>
            </td>
            <td className="px-4 py-3 text-center">
                <div className="w-24 h-4 m-auto bg-gray-300 rounded animate-pulse"></div>
            </td>
            <td className="px-4 py-3 text-center">
                <div className="w-16 h-4 m-auto bg-gray-300 rounded animate-pulse"></div>
            </td>
            <td className="px-4 py-3 text-center">
                <div className="w-32 h-4 m-auto bg-gray-300 rounded animate-pulse"></div>
            </td>
            <td className="px-4 py-3 text-center">
                <div className="w-40 h-4 m-auto bg-gray-300 rounded animate-pulse"></div>
            </td>
        </tr>
    );

    return (
        <main className="container py-2 w-full h-full dark:bg-[#000000]">
            <div className="flex flex-col items-center justify-between w-full h-full my-2 gap-8">
                {/* section1 */}
                <section className="flex flex-col items-center justify-between w-full gap-3 section1">
                    {/* nav (upper) */}
                    {/* <RoutingNavbar /> */}

                    <div className=" w-full max-w-100 max-md:max-w-full m-auto min-w-[400px]">
                        <div className="  mb-5 mt-[10 px] md:mt-5">
                            <b className="font-semibold text-[22px] md:text-3xl">
                                Deposit
                            </b>
                        </div>
                        <div className="flex flex-col gap-10 lg:flex-row md:gap-10 lg:w-full md:space-x-0">
                            <section className="w-full lg:w-1/2 lg:min-w-[53%]">
                                <div className="border rounded-[20px] pt-5 pb-5 dark:bg-[#1A191B]">
                                    <div className="flex gap-5">
                                        <div
                                            className="w-1/6"
                                            style={{ width: "8%" }}
                                        >
                                            <div className="flex items-center justify-end">
                                                <div className="flex flex-col items-center">
                                                    {/* First Icon with Diamond Shape */}
                                                    <div className="flex items-center justify-center text-white bg-yellow-500 w-8 h-8 rounded-md transform">
                                                        <FaCheck className="transform" />
                                                    </div>

                                                    {/* Vertical Line */}
                                                    <div className="w-2 h-[11.5rem] border"></div>

                                                    {/* Second Icon with Diamond Shape */}
                                                    <div className="flex items-center justify-center text-white bg-yellow-500 w-8 h-8 rounded-md transform">
                                                        <FaCheck className="transform" />
                                                    </div>

                                                    {/* Vertical Line */}
                                                    <div className="w-2 h-[10.5rem] border"></div>

                                                    {/* Third Icon with Diamond Shape */}
                                                    <div className="flex items-center justify-center text-white bg-yellow-500 w-8 h-8 rounded-md transform">
                                                        <FaCheck className="transform" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="w-5/6">
                                            <form className="px-3 mx-auto">
                                                <CoinSelect
                                                    coins={coins}
                                                    selectedCrypto={
                                                        selectedCrypto
                                                    }
                                                    setSelectedCrypto={
                                                        setSelectedCrypto
                                                    }
                                                    handleImageClick={
                                                        handleImageClick
                                                    }
                                                />
                                                <NetworkSelect
                                                    networks={networks}
                                                    selectedNetwork={
                                                        selectedNetwork
                                                    }
                                                    setSelectedNetwork={
                                                        setSelectedNetwork
                                                    }
                                                />
                                                <label className="block mb-2 text-[16px] font-medium text-gray-900 dark:text-white mt-[3.5rem]">
                                                    Address
                                                </label>
                                                <DepositAddress
                                                    selectedNetwork={
                                                        selectedNetwork
                                                    }
                                                    selectedCrypto={
                                                        selectedCrypto
                                                    }
                                                    withdrawalAddress={
                                                        withdrawalAddress
                                                    }
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="pl-8 w-100 md:-1/2">
                                <div className="font-semibold text-[22px] md:text-3xl sm:mx-4">
                                    FAQ
                                </div>
                                <ul className="pt-2">
                                    {faqs.map((faq, index) => (
                                        <div
                                            key={index}
                                            className="flex items-center"
                                        >
                                            <GoDotFill
                                                style={{ fontSize: "12px" }}
                                            />
                                            <li className="px-2 py-2">
                                                <NavLink
                                                    to={`/${faq
                                                        .toLowerCase()
                                                        .replace(/ /g, "-")}`}
                                                    className="text-black hover:text-[#EFB81C] hover:no-underline dark:text-white dark:hover:text-[#EFB81C]"
                                                >
                                                    {faq}
                                                </NavLink>
                                            </li>
                                        </div>
                                    ))}
                                </ul>

                                <div className="w-[26rem] p-2 mt-4 rounded-lg bg-whitesmoke box-border overflow-hidden flex flex-row items-center justify-between max-w-full gap-[1.25rem] border-[1px] border-solid border-dark-2 mq450:flex-wrap">
                                    <div className="flex flex-row items-center justify-start gap-[0.937rem] w-100">
                                        <img
                                            className="h-[3.438rem] w-[3.188rem] relative object-cover"
                                            alt=""
                                            src={trace}
                                        />
                                        <div className="flex justify-around gap-16 align-center w-100">
                                            <div className="flex flex-col items-start justify-start gap-[0.062rem]">
                                                <div className="relative text-[11px] sm:text-[14px] font-semibold">
                                                    Trace your Deposit Now
                                                </div>
                                                <div className="relative text-[11px]">
                                                    Get your trace Deposit
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className="focus:outline-none text-white bg-[#EFB81C] hover:bg-[#EFB81C] focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-2 py-1.5 me-2 dark:focus:ring-yellow-900"
                                            >
                                                <FaArrowRight />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <section className="py-8 mx-auto ">
                            <div className="mb-8 mt-14">
                                <h1 className="font-semibold text-[22px] md:text-3xl mb-4">
                                    Recent Deposits
                                </h1>
                            </div>
                            <div className="overflow-x-auto">
                                <table className="min-w-full">
                                    <thead>
                                        <tr>
                                            <th className="py-2 px-4 border-b text-[#AAAAAA] text-center font-normal">
                                                Status
                                            </th>
                                            <th className="py-2 px-4 border-b text-[#AAAAAA] text-center font-normal">
                                                Address
                                            </th>
                                            <th className="py-2 px-4 border-b text-[#AAAAAA] text-center font-normal">
                                                Amount
                                            </th>
                                            <th className="py-2 px-4 border-b text-[#AAAAAA] text-center font-normal">
                                                Time
                                            </th>
                                            <th className="py-2 px-4 border-b text-[#AAAAAA] text-center font-normal">
                                                Hash
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            // Show skeleton rows if loading
                                            Array.from({ length: 5 }).map(
                                                (_, index) => (
                                                    <SkeletonRow key={index} />
                                                )
                                            )
                                        ) : deposits.length === 0 ? (
                                            // Show "empty" message if deposits array is empty
                                            <tr>
                                                <td
                                                    colSpan="5"
                                                    className="py-4 text-center"
                                                >
                                                    <div className="flex items-center justify-center gap-2">
                                                        <div>
                                                            <ImNotification className="text-black dark:text-white" />
                                                        </div>
                                                        <div className="text-black dark:text-white">
                                                            No Recent Deposits
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            // Show actual data rows
                                            deposits.map((deposit, index) => (
                                                <tr key={index}>
                                                    <td className="flex justify-center px-4 py-3">
                                                        {deposit.status ===
                                                            "1" ? (
                                                            <FaCheck className="text-green-500" />
                                                        ) : (
                                                            <FaCheck className="text-red-500" />
                                                        )}
                                                    </td>
                                                    <td className="px-4 py-3 text-center">
                                                        {deposit.username
                                                            .length > 8
                                                            ? deposit.username.slice(
                                                                0,
                                                                4
                                                            ) +
                                                            "..." +
                                                            deposit.username.slice(
                                                                -4
                                                            )
                                                            : deposit.username}
                                                    </td>
                                                    <td className="px-4 py-3 text-center">
                                                        {deposit.num}
                                                        {deposit.coinname}
                                                    </td>
                                                    <td className="px-4 py-3 text-center">
                                                        {new Date(
                                                            parseInt(
                                                                deposit.addtime
                                                            ) * 1000
                                                        )
                                                            .toISOString()
                                                            .slice(0, 19)
                                                            .replace("T", " ")}
                                                    </td>
                                                    <td className="px-4 py-3 text-center">
                                                        {deposit.txid.length > 8
                                                            ? deposit.txid.slice(
                                                                0,
                                                                4
                                                            ) +
                                                            "..." +
                                                            deposit.txid.slice(
                                                                -4
                                                            )
                                                            : deposit.txid}
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </div>
                </section>
            </div>
        </main>
    );
}

export default CryptoDeposit;
