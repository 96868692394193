// tradeReducer.js
import { FETCH_DATA_REQUEST, FETCH_DATA_SUCCESS, FETCH_DATA_FAILURE,SET_SELECTED_MAIN_FILTER,  TOGGLE_THEME,  } from '../types';

const initialState = {
  data: null,
  loading: false,
  error: null,
  selectedMainFilter: "Spot",
  isDarkTheme: localStorage.getItem("theme") === "dark", 
};

const tradeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return { ...state, loading: true };
    case FETCH_DATA_SUCCESS:
      console.log('Reducer data:', action.payload); // Debugging line
      return { ...state, loading: false, data: action.payload };
    case FETCH_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
      case SET_SELECTED_MAIN_FILTER: // New case for setting the selected main filter
      return { ...state, selectedMainFilter: action.payload };
      case TOGGLE_THEME:
            const newTheme = state.isDarkTheme ? "light" : "dark";
            localStorage.setItem("theme", newTheme);
            document.documentElement.classList.toggle("dark", newTheme === "dark");
            return { ...state, isDarkTheme: !state.isDarkTheme };
    default:
      return state;
  }
};

export default tradeReducer;
