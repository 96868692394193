import { FETCH_USER_PROFILE_FAILURE, FETCH_USER_PROFILE_REQUEST, FETCH_USER_PROFILE_SUCCESS } from "../types";

const initialStateInfo = {
    loading: false,
    userProfile: [],
    error: null
};

const initialStateDashboard = {
    loading: false,
    userDashboard: [],
    error: null
};

export const userProfileReducer = (state = initialStateInfo, action) => {
    switch (action.type) {
        case FETCH_USER_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                userProfile: action.payload
            };
        case FETCH_USER_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export const userDashboardReducer = (state = initialStateDashboard, action) => {
    switch (action.type) {
        case "FETCH_USER_DASHBOARD_REQUEST":
            return {
                ...state,
                loading: true,
                error: null
            };
        case "FETCH_USER_DASHBOARD_SUCCESS":
            return {
                ...state,
                loading: false,
                userDashboard: action.payload
            };
        case "FETCH_USER_DASHBOARD_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
