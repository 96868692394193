import React, { useState } from "react";
import { Modal } from "antd";
import { IoClose } from "react-icons/io5";
import TransferFunds from "assets/img/transfer-funds.svg";
import ConvertFunds from "assets/img/convert-funds.svg";
import { IoIosArrowForward } from "react-icons/io";
import TransferModal from "./TransferModal";
import ConvertModal from "./ConvertModal"; // Import your ConvertModal

const AddFundsModal = ({ visible, onCancel }) => {
    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
    const [isConvertModalVisible, setIsConvertModalVisible] = useState(false); // State for Convert Modal

    const handleTransferClick = () => {
        setIsTransferModalVisible(true);
    };

    const handleConvertClick = () => {
        setIsConvertModalVisible(true); // Open Convert Modal
    };

    const handleTransferCancel = () => {
        setIsTransferModalVisible(false);
    };

    const handleConvertCancel = () => {
        setIsConvertModalVisible(false); // Close Convert Modal
    };

    return (
        <>
            <Modal
                visible={visible}
                footer={null}
                onCancel={onCancel}
                closeIcon={<IoClose />}
                title="Add Funds"
            >
                <div>
                    <p className="text-sm text-[#707A8A]">
                        Top up your Funding Wallet easily via these methods:
                    </p>

                    {/* Transfer Section */}
                    <div
                        className="flex mt-10 mb-3 bg-gray-50 p-5 rounded-md justify-between items-center cursor-pointer"
                        onClick={handleTransferClick}
                    >
                        <div className="flex gap-3">
                            <img
                                src={TransferFunds}
                                className="w-12 h-12"
                                alt="Transfer Funds"
                            />
                            <div className="flex flex-col">
                                <p className="text-md font-semibold">
                                    Transfer
                                </p>
                                <p className="text-[12px] text-[#707A8A]">
                                    Transfer funds to Funding Wallet
                                </p>
                            </div>
                        </div>
                        <div>
                            <IoIosArrowForward className="text-[#707A8A]" />
                        </div>
                    </div>

                    {/* Convert Section */}
                    <div
                        className="flex bg-gray-50 p-5 rounded-md justify-between items-center cursor-pointer"
                        onClick={handleConvertClick}
                    >
                        <div className="flex gap-3">
                            <img
                                src={ConvertFunds}
                                className="w-12 h-12"
                                alt="Convert Funds"
                            />
                            <div className="flex flex-col">
                                <p className="text-md font-semibold">Convert</p>
                                <p className="text-[12px] text-[#707A8A]">
                                    Convert your funds to different currencies
                                </p>
                            </div>
                        </div>
                        <div>
                            <IoIosArrowForward className="text-[#707A8A]" />
                        </div>
                    </div>
                </div>
            </Modal>

            {/* Transfer Modal */}
            <TransferModal
                visible={isTransferModalVisible}
                onCancel={handleTransferCancel}
            />

            {/* Convert Modal */}
            <ConvertModal
                visible={isConvertModalVisible} // Pass state to ConvertModal
                onCancel={handleConvertCancel} // Handle cancel for ConvertModal
            />
        </>
    );
};

export default AddFundsModal;
