import React from 'react';
import { FcFeedback } from "react-icons/fc";

const P2pFeedBack = ({ onCancel }) => {
    return (
        <div className="p-4 rounded-xl">
            <div className='flex justify-center items-center'>
                <FcFeedback className='text-[100px]' />
            </div>
            <h2 className="text-lg text-center font-semibold mb-4">Provide Your Feedback!</h2>
            <p className="text-sm text-center text-gray-700">
                Do you feel that the design of the order process helped you to complete your transaction?
            </p>
            <button onClick={onCancel} className="mt-4 flex bg-[#EFB81C] w-full py-2 px-4 rounded-xl items-center justify-center font-semibold">
                Provide Feedback
            </button>
            <button
                className="mt-4 flex bg-gray-200 w-full py-2 px-4 rounded-xl items-center justify-center font-semibold"
                onClick={onCancel}
            >
                Cancel
            </button>
        </div>
    );
};

export default P2pFeedBack;
