import React, { useState } from "react";

const dates = [
    { value: "All" },
    { value: "01-11-24" },
    { value: "03-11-24" },
    { value: "08-11-24" },

    { value: "15-11-24" },
    { value: "22-11-24" },
    { value: "29-11-24" },

    { value: "27-12-24" },
    { value: "28-23-25" },
    { value: "23-06-25" },
    { value: "26-09-25" },
];

const OptionTable = () => {
    const [activeDate, setActiveDate] = useState("01-11-24");
    const items = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    return (
        <div>
            {/* top bar date buttons */}
            <div className="p-4 flex items-center gap-4">
                {dates.map((date, i) => (
                    <button
                        onClick={() => setActiveDate(date.value)}
                        className={`px-2 py-2  text-sm font-semibold  rounded-lg ${
                            activeDate === date.value
                                ? "bg-yellow-100 text-yellow-600"
                                : "bg-gray-100 dark:text-[#71777A] dark:bg-[#25282C]  "
                        }`}
                        key={i}
                    >
                        {date.value}
                    </button>
                ))}
                <div className="text-xs text-gray-500 dark:text-[#71777A]">
                    ATM options
                </div>
                <div className="text-xs text-gray-500 dark:text-[#71777A]">
                    Basket Order
                </div>
                <div className="text-xs text-gray-500 dark:text-[#71777A]">
                    Position Builder
                </div>
            </div>
            {/* table section  */}
            <div className="flex flex-col gap-0.5 w-full">
                <div className="w-full bg-gray-100 flex   dark:text-[#71777A]items-center justify-between px-4 py-4 dark:bg-[#25282C]">
                    <div className="flex-1 font-semibold text-xs text-black  dark:text-[#71777A]">
                        24H Vol.
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Open
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Delta
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Bid Size
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Bid
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Mark Price
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Ask
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Ask Size
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Position
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Strike
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Position
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Bid Size
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Bid
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Mark Price
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Ask
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Ask Size
                    </div>
                    <div className="flex-1 font-semibold text-xs text-black dark:text-[#71777A]">
                        Delta
                    </div>
                </div>
                <div className="w-full bg-gray-100 dark:bg-[#25282C]  dark:text-[#FFFFFF] flex items-center justify-center px-4 py-4 text-sm font-bold text-center">
                    1 NOV 2024
                </div>
                <div className="w-full bg-white flex flex-col gap-0.5 dark:bg-[#101014] dark:text-[#FFFFFF]">
                    {items.map((item) => (
                        <div
                            key={item}
                            className="w-full bg-gray-100 dark:bg-[#25282C] flex items-center justify-between px-4 py-4 text-gray-500"
                        >
                            <div className="flex-1 font-semibold text-xs text-gray-500">
                                0.00
                            </div>
                            <div className="flex-1 font-semibold text-xs text-gray-500">
                                0.02
                            </div>
                            <div className="flex-1 font-semibold text-xs text-gray-500">
                                1.00
                            </div>
                            <div className="flex-1 font-semibold text-xs text-black dark:text-[#FFFFFF]">
                                28.02
                            </div>
                            <div className="flex-1 font-semibold text-xs text-gray-500">
                                22.322
                            </div>
                            <div className="flex-1 font-semibold text-xs text-green-500">
                                23565
                            </div>
                            <div className="flex-1 font-semibold text-xs text-black dark:text-[#FFFFFF]">
                                0.55634
                            </div>
                            <div className="flex-1 font-semibold text-xs text-red-500">
                                245122312
                            </div>
                            <div className="flex-1 font-semibold text-xs text-black dark:text-[#FFFFFF]">
                                ---
                            </div>
                            <div className="flex-1 font-semibold text-xs text-gray-500">
                                665
                            </div>
                            <div className="flex-1 font-semibold text-xs text-black dark:text-[#FFFFFF]">
                                ---
                            </div>
                            <div className="flex-1 font-semibold text-xs text-black dark:text-[#FFFFFF]">
                                ---
                            </div>
                            <div className="flex-1 font-semibold text-xs text-black dark:text-[#FFFFFF]">
                                ---
                            </div>
                            <div className="flex-1 font-semibold text-xs text-gray-500">
                                3200
                            </div>
                            <div className="flex-1 font-semibold text-xs text-red-500">
                                5.0
                            </div>
                            <div className="flex-1 font-semibold text-xs text-black dark:text-[#FFFFFF]">
                                0.50
                            </div>
                            <div className="flex-1 font-semibold text-xs text-black dark:text-[#FFFFFF]">
                                -000
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OptionTable;
