import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resendPhoneOtp, verifyPhone } from "store/actions/authAction";
import toast from "react-hot-toast";
import { Spinner } from "reactstrap";
import { FaMobile } from "react-icons/fa";
import { IoMdHelpCircleOutline } from "react-icons/io";
import G2faVerification from "./helpers/G2faVerify";
import YellowTick from "assets/img/yellow-bg-tick.png";
import PhoneInput from 'react-phone-input-2';
import i18nIsoCountries from 'i18n-iso-countries';
import EmailVerification from "./helpers/EmailVerify";

i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const MobileSetOn2FAActiveModal = ({ show, onClose, onSubmit, AlertMessage, closeAlert, setAlertMessage, SuccessMessage, email, username }) => {
    const [step, setStep] = useState(1);
    const [emailCode, setEmailCode] = useState('');
    const [otpCode, setOtpCode] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState();
    const [phoneOTP, setPhoneOTP] = useState('');
    const [resendDisabled, setResendDisabled] = useState(true);
    const [resendTimer, setResendTimer] = useState(60);
    const dispatch = useDispatch();

    useEffect(() => {
        const storedPassword = localStorage.getItem("password");
        if (storedPassword) setPassword(storedPassword);
    }, []);

    useEffect(() => {
        if (step === 4 && resendTimer > 0) {
            const timer = setInterval(() => {
                setResendTimer(prevTimer => prevTimer - 1);
            }, 1000);
    
            return () => clearInterval(timer);
        } else if (resendTimer === 0) {
            setResendDisabled(false);
        }
    }, [step, resendTimer]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const modal = document.querySelector(".modal-inner");
            if (modal && !modal.contains(event.target)) {
                onClose();
            }
            const outermodal = document.querySelector(".modal-outer");
            if (outermodal && !modal.contains(event.target)) {
                onClose();
            }
        };

        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show, onClose]);

    if (!show) return null;

    const handleNextStep = async () => {
        if (step === 3) {
            try {
                const response = await fetch("https://api.buycex.com/Login/sendCellphone", {
                    method: 'POST',
                    body: new URLSearchParams({ cellphone: phone }),
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                });
                const data = await response.json();
                if (data.status === 1) {
                    toast.success(data.data.msg);
                    setStep(step + 1);
                } else {
                    toast.error("Failed to send verification code");
                }
            } catch (error) {
                toast.error("Error sending verification code");
                console.error("Error sending cellphone:", error);
            }
        } else {
            setStep(step + 1);
        }
    };

    const verifyOtp = async (e) => {
        const payload = {
            phone,
            verify: phoneOTP,
            password,
        };

        try {
            await dispatch(verifyPhone(payload, () => {
                toast.success("Phone verified successfully");
                handleNextStep();
            }));
        } catch (error) {
            toast.error("Failed to verify phone");
            console.error("Error verifying phone:", error);
        }
    };

    const resendCode = async () => {
        const payload = { phone };

        try {
            await dispatch(resendPhoneOtp(payload));
            toast.success("OTP sent successfully");
            if (!resendDisabled) {
                // Logic to resend code
                setResendTimer(60);
                setResendDisabled(true);
            }
            console.log("OTP sent successfully to:", phone);
        } catch (error) {
            toast.error("Failed to resend OTP");
            console.error("Error resending phone OTP:", error);
        }
    };

    const handleSubmit = () => {
        onSubmit(phone, phoneOTP);
        // if (SuccessMessage) {
        //   setStep(5); // Move to success step after submission
        // }
        setStep(5);
    };

    const handleCancel = () => {
        setStep(1);
        setEmailCode('');
        setOtpCode('');
        setPhone('');
        setPhoneOTP('');
        onClose();
    };

    const handleResend = () => {
        if (!resendDisabled) {
            // Logic to resend code
            setResendTimer(60);
            setResendDisabled(true);
        }
    };

    const formatPhone = (phone) => {
        const length = phone.length;
        if (length <= 5) return phone;
        const firstPart = phone.slice(0, 3);
        const lastPart = phone.slice(-2);
        return `${firstPart}****${lastPart}`;
    };

    return (
        <div className="fixed inset-0 modal-outer flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="relative modal-inner bg-white rounded-xl shadow-lg p-6 w-1/3">
                <button
                    onClick={handleCancel}
                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                {AlertMessage && (
                    <div className={`px-4 py-2 my-3 rounded ${AlertMessage.type === 'error' ? 'bg-red-200 text-red-800' : 'bg-green-200 text-green-800'}`}>
                        <div className="flex justify-between items-center">
                            <span>{AlertMessage.message}</span>
                            <button className="text-xl leading-none" onClick={closeAlert}>&times;</button>
                        </div>
                    </div>
                )}
                <div className="flex gap-2">
                    <FaMobile className=" text-yellow-500 text-2xl"/>
                    <h1 className="font-semibold text-2xl">Bind New Mobile</h1>
                </div>
                {/* Step Tracer */}
                <div className="flex mt-4 mb-8 items-center gap-2 relative">
                    <div className={`componentBorder rounded-xl text-center px-1 w-28 relative ${step >= 1 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">Email Verification</p>
                        <div className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-2 ${step >= 1 ? 'bg-yellow-500' : 'bg-gray-300'} transition-colors duration-300`}></div>
                    </div>
                    <div className={`componentBorder rounded-xl text-center px-1 w-12 relative ${step >= 2 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">2FA</p>
                        <div className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-2 ${step >= 2 ? 'bg-yellow-500' : 'bg-gray-300'} transition-colors duration-300`}></div>
                    </div>
                    <div className={`componentBorder rounded-xl text-center px-1 w-24 relative ${step >= 3 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">Bind New Mobile</p>
                        <div className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-2 ${step >= 3 ? 'bg-yellow-500' : 'bg-gray-300'} transition-colors duration-300`}></div>
                    </div>
                    <div className={`componentBorder rounded-xl text-center px-1 w-28 relative ${step >= 4 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">Mobile Verification</p>
                        <div className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-2 ${step >= 3 ? 'bg-yellow-500' : 'bg-gray-300'} transition-colors duration-300`}></div>
                    </div>
                    <div className={`componentBorder rounded-xl text-center px-1 w-12 relative ${step >= 5 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">Done</p>
                    </div>
                </div>

                {step === 1 && (
                    <EmailVerification onSuccess={handleNextStep}/>
                )}
                {step === 2 && (
                    <G2faVerification 
                        onSuccess={handleNextStep}
                        emailasprop={email}
                    />
                )}
                {step === 3 && (
                    <div>
                        <div className=" flex flex-col mb-6 gap-4">
                            <h2 className=" font-medium text-lg text-left ">Mobile</h2>
                            <PhoneInput
                                country={''}
                                value={phone}
                                onChange={phone => setPhone(phone)}
                                inputClass='w-full px-3 py-2 bg-gray-300 placeholder:text-gray-300 placeholder:text-[14px] focus:ring-0 ring-0 hover:border-[#F1BA19] focus:outline-none border border-[#151415] dark:border-[#F4F4F4] focus:border-red-400 rounded-md'
                                inputProps={{ name: 'phone', id: 'phone', autoComplete: 'phone', autoFocus: true }}
                                containerClass="w-full"
                                placeholder="Enter your phone"
                            />
                        </div>
                        <div className="flex justify-between">
                            <button className="bg-yellow-500 text-white py-2 px-4 rounded focus:outline-none w-full" onClick={handleNextStep}>Next</button>
                        </div>
                    </div>
                )}
                {step === 4 && (
                    <div>
                    <h2 className=" text-lg font-medium text-left mb-2">
                        SMS Code
                    </h2>
                    <input
                        type="text"
                        className="border bg-gray-100 rounded w-full p-2 mb-1 focus:outline-none"
                        value={phoneOTP}
                        onChange={(e) => setPhoneOTP(e.target.value)}
                        placeholder="Enter Verification Code"
                    />
                    <div className="flex justify-between mb-4">
                        <p className="text-[10px] text-gray-300">
                            {phone ? `Please enter the 6-digit TOTP code sent to ${formatPhone(phone)}` : 'Please enter the 6-digit TOTP code'}
                        </p>
                        <button
                            className={`text-yellow-500 bg-none outline-none focus:outline-none text-[10px] ${resendDisabled ? 'cursor-not-allowed' : ''}`}
                            onClick={resendCode}
                            disabled={resendDisabled}
                        >
                            Resend {resendDisabled ? `(${resendTimer}s)` : ''}
                        </button>
                    </div>
                    <div className="flex justify-between">
                        <button className="bg-yellow-500 text-white py-2 px-4 rounded focus:outline-none w-full" onClick={verifyOtp}>Next</button>
                    </div>
                </div>
                )}
                {step === 5 && (
                    <div>
                        <div className=" flex justify-center mb-2 items-center py-4"><img src={YellowTick} alt="success" className="mr-2 w-8 h-8"/><h2 className="text-lg font-medium">New Phone Binded Successfully</h2></div>
                        <button className="bg-yellow-500 text-white py-2 px-4 rounded focus:outline-none w-full" onClick={handleCancel}>Done</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileSetOn2FAActiveModal;
