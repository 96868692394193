import React, { useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import SliderCaptcha from "rc-slider-captcha";
import "rc-slider/assets/index.css";
import { CloseCircleOutlined, ExclamationCircleOutlined, ReloadOutlined } from "@ant-design/icons";

const CaptchaModal = ({ isOpen, onClose, onSuccess, onFailure, captchaVal, captchaImage, getCaptchaHandler }) => {
    const captchaRef = useRef(null);
    
    const handleCaptchaSuccess = () => {
        onSuccess();
    };

    const getCaptcha = async () => {
        if (isOpen) {
            return {
                bgUrl: captchaImage.bgUrl,
                puzzleUrl: captchaImage.puzzleUrl,
            }
        }
    };

    return (
        <Modal
            className="captcha-modal flex p-2 pb-3 bg-black/60 h-[100vh] w-full justify-center items-center"
            centered
            isOpen={isOpen}
            toggle={onClose}
            // style={{ maxWidth: "350px", width: "max-content" }}
        >
            <div className="bg-gray-50 p-3 rounded-md">
            <ModalBody>
                <SliderCaptcha
                    request={getCaptcha}
                    showRefreshIcon={false}
                    onVerify={async (data) => {
                        const x = interpolate(0, 260, 0, 389, data?.x);
                        if (x && x > captchaVal - 91 / 5 && x < captchaVal + 91 / 5) {
                            handleCaptchaSuccess();
                            return Promise.resolve();
                        }
                        return Promise.reject();
                    }}
                    tipText={{
                        default: "Slide to complete the puzzle",
                        loading: "Loading...",
                        moving: "Slide right to the puzzle",
                        verifying: "Verifying",
                        error: "Failed",
                    }}
                    loadingBoxProps={{
                        text: "loading",
                    }}
                    actionRef={captchaRef}
                />
            </ModalBody>
            <ModalFooter>
                <div className="relative w-full">
                    <hr className="absolute top-0 left-0 right-0 mt-3 border-gray-300" />
                </div>
                <div className="flex items-center justify-between w-full px-2">
                    <div className="flex space-x-2">
                        <Button
                            className="relative z-10 flex items-center justify-center mt-6 border-none rounded-md"
                            type="button"
                            onClick={onClose}
                        >
                            <CloseCircleOutlined className="text-gray-600 font" />
                        </Button>
                        <Button
                            className="relative z-10 flex items-center justify-center mt-6 border-none rounded-md"
                            type="button"
                        >
                            <ExclamationCircleOutlined className="text-gray-600 font" />
                        </Button>
                    <Button
                        className="relative z-10 flex items-center justify-center mt-6 border-none rounded-md"
                        type="button"
                        onClick={(e) => getCaptchaHandler(e)}
                    >
                        <ReloadOutlined className="text-gray-600 font" />
                    </Button>
                    </div>
                </div>
            </ModalFooter>
            </div>
        </Modal>
    );
};

function interpolate(min_in, max_in, min_out, max_out, inputValue) {
    const normalizedInput = (inputValue - min_in) / (max_in - min_in);
    const interpolatedValue = min_out + (max_out - min_out) * normalizedInput;
    return interpolatedValue;
}

export default CaptchaModal;