import React, { useState } from "react";
import {
    BsCaretDownFill,
    BsCaretRightFill,
    BsChevronRight,
    BsNutFill,
} from "react-icons/bs";
import { Link } from "react-router-dom/cjs/react-router-dom";

import "./ChatBotStyle.css";

const QuickQuestions = () => {
    const [openFaq, setOpenFaq] = useState(null);

    const defaultQuestions = [
        {
            id: 1,
            title: "What is the difference between buying and selling?",
            link: "#!",
        },
        {
            id: 2,
            title: "How do I verify my identity on BUYCEX?",
            link: "#!",
        },
        {
            id: 3,
            title: "How can I reset my password?",
            link: "#!",
        },
        {
            id: 4,
            title: "What is a trading fee and how is it calculated?",
            link: "#!",
        },
        {
            id: 5,
            title: "How do I withdraw funds from my account?",
            link: "#!",
        },
    ];

    const otherServices = [
        {
            id: 1,
            title: "Cryptocurrency Staking",
            description:
                "Earn rewards by staking your digital assets securely on our platform.",
            link: "/services/staking",
        },
        {
            id: 2,
            title: "P2P Trading",
            description:
                "Trade crypto directly with other users using our peer-to-peer marketplace.",
            link: "/services/p2p-trading",
        },
        {
            id: 3,
            title: "Margin Trading",
            description:
                "Leverage your trades and maximize potential returns with margin trading options.",
            link: "/services/margin-trading",
        },
        {
            id: 4,
            title: "Futures Contracts",
            description:
                "Speculate on the future price of cryptocurrencies with our futures contracts.",
            link: "/services/futures-contracts",
        },
    ];

    const faqs = [
        {
            id: 1,
            question: "How can I secure my BUYCEX account?",
            answer: "Enable 2FA, use strong passwords, and regularly review your account activity for any unauthorized access.",
        },
        {
            id: 2,
            question: "What is KYC, and why is it required?",
            answer: "KYC (Know Your Customer) is a process to verify the identity of users to comply with regulatory standards and prevent fraud.",
        },
        {
            id: 3,
            question: "How do I transfer funds from BUYCEX to another wallet?",
            answer: "Navigate to the withdrawal section, select the currency, enter the recipient's wallet address, and confirm the transaction.",
        },
        {
            id: 4,
            question: "What are the withdrawal limits on BUYCEX?",
            answer: "Withdrawal limits vary depending on your account level. VIP levels have higher limits.",
        },
        {
            id: 5,
            question: "How can I contact support?",
            answer: "You can contact support via live chat, email, or by submitting a support ticket on our website.",
        },
    ];

    const toggleFaq = (id) => {
        setOpenFaq(openFaq === id ? null : id);
    };

    return (
        <div className="flex flex-col w-full gap-6 quick-questions-wrapper">
            <div className="w-full quick-questions__item">
                <div className="flex items-center justify-between w-full gap-2 p-4 quick-questions__item-header w-ful">
                    <span className="quick-questions__item__header__title font-[500] text-[16px]">
                        You might be looking for
                    </span>
                    <Link
                        to="#!"
                        className="flex items-center justify-between gap-1 hover:underline"
                    >
                        <span className="text-[#EFB81C] text-[16px]">More</span>
                        <BsChevronRight size={12} />
                    </Link>
                </div>

                <div className="w-full px-4 quick-questions__item-body">
                    <div className="w-full quick-questions__item__body-item">
                        <ul className="quick-questions__item__body-item__list w-full flex flex-col gap-2.5">
                            {defaultQuestions.map((question) => (
                                <li
                                    key={question.id}
                                    className="flex items-center justify-between w-full gap-4 transition-none"
                                >
                                    <Link
                                        to={question.link}
                                        className="w-full flex items-center justify-between gap-4 hover:underline hover:text-[#EFB81C] transition-none"
                                    >
                                        <span className="text-[14px]">
                                            {question.title}
                                        </span>
                                        <BsChevronRight
                                            size={12}
                                            className="opacity-80"
                                        />
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="w-full quick-questions__other-item">
                <div className="flex items-center justify-between w-full gap-2 p-4 quick-questions__other-item__header w-ful">
                    <span className="quick-questions__other-item__header-title font-[500] text-[16px]">
                        Other Services
                    </span>
                    <Link
                        to="#!"
                        className="flex items-center justify-between gap-1 hover:underline"
                    >
                        <span className="text-[#EFB81C] text-[16px]">
                            View All
                        </span>
                        <BsChevronRight size={12} />
                    </Link>
                </div>

                <div className="w-full px-4 quick-questions__other-item__body">
                    <div className="w-full quick-questions__other-item__body-item">
                        <ul className="flex flex-wrap justify-between w-full gap-3 quick-questions__other-item__body-item__list">
                            {otherServices.map((service) => (
                                <li
                                    key={service.id}
                                    className="flex items-center justify-between gap-4 transition-none w-[48%] p-2 border rounded-md"
                                >
                                    <Link
                                        to={service.link}
                                        className="flex flex-col w-full gap-1 transition-none hover:underline"
                                    >
                                        <span>
                                            <BsNutFill
                                                size={14}
                                                fill="#EFB81C"
                                            />
                                        </span>
                                        <span className="text-[12px] font-bold">
                                            {service.title}
                                        </span>
                                        <span className="text-[12px] line-clamp-2">
                                            {service.description}
                                        </span>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {/* FAQs */}
            <div className="w-full quick-questions__faqs">
                <div className="flex items-center justify-between w-full gap-2 p-4 quick-questions__faqs-header">
                    <span className="quick-questions__faqs-header__title font-[500] text-[16px]">
                        FAQs
                    </span>
                    <Link
                        to="#!"
                        className="flex items-center justify-between gap-1 hover:underline"
                    >
                        <span className="text-[#EFB81C] text-[16px]">
                            Read More
                        </span>
                        <BsChevronRight size={12} />
                    </Link>
                </div>

                <div className="w-full px-4 pb-8 quick-questions__faqs-body">
                    <ul className="flex flex-col w-full gap-3">
                        {faqs.map((faq) => (
                            <li
                                key={faq.id}
                                className="cursor-pointer"
                                onClick={() => toggleFaq(faq.id)}
                            >
                                <div className="w-full">
                                    <div className="flex items-center gap-2">
                                        {openFaq === faq.id ? (
                                            <BsCaretDownFill
                                                size={10}
                                                className="opacity-80"
                                            />
                                        ) : (
                                            <BsCaretRightFill
                                                size={10}
                                                className="opacity-80"
                                            />
                                        )}
                                        <span className="quick-questions__faqs__item-title font-[500] text-[14px]">
                                            {faq.question}
                                        </span>
                                    </div>
                                    {openFaq === faq.id && (
                                        <p className="text-[12px] mt-2 pl-[18px] break-words">
                                            {faq.answer}
                                        </p>
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default QuickQuestions;
