import { FIAT_WITHDRAWAL_REQUEST, FIAT_WITHDRAWAL_SUCCESS, FIAT_WITHDRAWAL_FAILURE } from "../types";

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const fiatWithdrawalReducer = (state = initialState, action) => {
    switch (action.type) {
        case FIAT_WITHDRAWAL_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FIAT_WITHDRAWAL_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case FIAT_WITHDRAWAL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error || action.payload.data,
            };
        default:
            return state;
    }
};

export default fiatWithdrawalReducer;
