import React from 'react';
import { TbCaretUpDownFilled } from "react-icons/tb";
import { FaBitcoin, FaEthereum } from "react-icons/fa";
import { HiMiniCurrencyDollar } from "react-icons/hi2";

function FusionRankings() {
    const popularCoins = [
        {
            name: 'BTCUSDT',
            icon: <FaBitcoin className='text-2xl text-[#F7931A]' />,
            color: 'red',
            amount: '0.000000',
            price: '0.000000',
            descriptions: {
                coin: 'Bitcoin',
                amount: '$0.000000',
                subprice: '$0.000000',
            }
        },
        {
            name: 'ETHUSDT',
            icon: <FaEthereum className='text-2xl text-[#3C3C3D]' />,
            color: 'green',
            amount: '0.000000',
            price: '0.000000',
            descriptions: {
                coin: 'Ethereum',
                amount: '$0.000000',
                subprice: '$0.000000',
            }
        },
    ];

    return (
        <div className='w-full'>
            {/* Header row */}
            <div className='flex border-b border-t border-slate-100 w-full py-4 px-2'>
                <div className='flex items-center gap-1 flex-[2]'>
                    <p className='text-xs text-[#929AA5]'>Market</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-start gap-1 flex-1'>
                    <p className='text-xs text-[#929AA5]'>ROI</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-start gap-1 flex-1'>
                    <p className='text-xs text-[#929AA5]'>P&L (USD)</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-start gap-1 flex-1'>
                    <p className='text-xs text-[#929AA5]'>Copiers</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-start gap-1 flex-1'>
                    <p className='text-xs text-[#929AA5]'>Running Time</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex justify-end gap-1 flex-1'>
                    <p className='text-xs text-[#929AA5]'>Action</p>
                </div>
            </div>

            {/* Display coins */}
            {popularCoins.map((coin, index) => (
                <div key={index} className='flex w-full border-b py-4 border-slate-100 items-center'>
                    <div className='flex gap-2 flex-[2]'>
                        <div className='flex items-center gap-2'>
                            <div className='text-lg' style={{ color: coin.color }}>
                                {coin.icon}
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <p className='text-sm font-normal'>{coin.name}</p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-1 flex-1'>
                        <p className='text-sm text-start font-normal text-[#2ebd85]'>{coin.amount}%</p>
                    </div>
                    <div className='flex flex-col gap-1 flex-1'>
                        <p className='text-sm text-start font-normal'>{coin.price}</p>
                    </div>
                    <div className='flex flex-col gap-1 flex-1'>
                        <p className='text-start font-normal'>
                            <span className='!text-[14px]'>{coin.price}</span>
                        </p>
                    </div>
                    <div className='flex flex-col gap-1 flex-1'>
                        <p className='text-start font-normal'>
                            <span className='!text-[14px]'>	233D 13h 7m</span>
                        </p>
                    </div>
                    <div className='flex justify-end flex-1'>
                        <div className='flex justify-end gap-2 flex-1'>
                            <button className='text-sm flex items-center px-2 py-1 border rounded-md'>Copy For Free</button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default FusionRankings;
