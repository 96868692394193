import React from "react";

const SpotOrderMarginTable = () => {
  return (
    <>
      <div className="bg-white rounded-2xl w-full min-h-[500px] mt-3">
        {/* Table Container */}
        <div className="flex flex-col items-center px-3  w-full min-h-[500px] pt-3 ">
          <table className="w-full text-left">
            <thead className="text-gray-400 ">
              <tr className="pb-3 border-b border-gray-200 ">
              <th className="px-4 pt-2 pb-3 font-medium">Time</th>
                <th className="px-4 py-2 pb-3 font-medium">Market</th>
                <th className="px-4 py-2 pb-3 font-medium">Type</th>
                <th className="px-4 py-2 pb-3 font-medium">Side</th>
                <th className="px-4 py-2 pb-3 font-medium">Order Price</th>
                <th className="px-4 py-2 pb-3 font-medium">Amount</th>
                <th className="px-4 py-2 pb-3 font-medium">Avg Price</th>
                <th className="px-4 py-2 pb-3 font-medium">Executed</th>
                <th className="px-4 py-2 pb-3 font-medium">Executed Status</th>
                <th className="px-4 py-2 pb-3 font-medium ">Operation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="12" className="py-10 text-center text-gray-400">
                  <div className="text-center">
                    <svg
                      className="mx-auto mb-4"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      width="48"
                      height="48"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 7v10M21 7v10M12 5v14M8 5h8m-4-4v4m0 10v4m4-4h4m-4-4h4m-4-4h4"
                      />
                    </svg>
                    <p>No Data</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SpotOrderMarginTable;
