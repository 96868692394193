import React, { useState } from "react";
import MarketDataNavigation from "./components/MarketOverviewTableNav";

// Market Data Tables
import AllCryptoDataTable from "./tables/AllCryptoDataTable";
import FavoritesDataTable from "./tables/FavoritesDataTable";
import SpotMarketDataTable from "./tables/SpotMarketDataTable";
import NewListedDataTable from "./tables/NewListedDataTable";
import SearchDataTable from "./tables/SearchDataTable";
import Image from "../../../assets/img/market4.jpeg";

import "./MarketOverviewStyle.scss";
import FuturesDataTable from "./tables/FuturesDataTable";
import SpotMarginDataTable from "./components/SpotMarginDataTable";

const MarketDataTable = () => {
    const [navActive, setSelectedNavData] = useState("All Crypto");
    const [searchQuery, setSearchQuery] = useState("");

    return (
        <div className="market-overview__data-wrapper pb-14">
            <div className="  mt-4 container ">
                <div className="bg-[#fff] rounded-lg border h-44 ">
                    <img src={Image} alt="" className="w-full h-full" />
                </div>
                <h2 className="mt-6 mb-6 font-bold text-5xl">Markets</h2>
            </div>
            <MarketDataNavigation
                setNavActive={setSelectedNavData}
                setSearchQuery={setSearchQuery}
            />

            <div className="market-data-wrapper">
                <div className="market-data-table-container container ">
                    {navActive === "All Crypto" ? (
                        <AllCryptoDataTable navActive={navActive} />
                    ) : navActive === "Spot/Margin" ? (
                        <SpotMarginDataTable navActive={navActive} />
                    ) : navActive === "Favorites" ? (
                        <FavoritesDataTable navActive={navActive} />
                    ) : navActive === "Pre-Listed" ? (
                        <SpotMarketDataTable navActive={navActive} />
                    ) : navActive === "Futures" ? (
                        <FuturesDataTable navActive={navActive} />
                    ) : navActive === "New Listed" ? (
                        <NewListedDataTable navActive={navActive} />
                    ) : navActive === "Search" ? (
                        <SearchDataTable
                            navActive={navActive}
                            searchQuery={searchQuery}
                        />
                    ) : (
                        <div className="flex items-center justify-center min-h-[30vh]">
                            <p className="m-auto text-[14px]">
                                No Data or Error to fetch the data
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MarketDataTable;
