import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";

const CustomOption = ({ data, ...props }) => (
    <components.Option {...props}>
        <div className="flex items-center">
            <img src={data.icon} alt={`${data.name}-coin`} className="w-6 h-6 mr-2" />
            <div className="flex-grow">{data.label}</div>
        </div>
    </components.Option>
);

const CoinSelect = ({ coins, selectedCrypto, setSelectedCrypto, handleImageClick }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate API call
        setTimeout(() => {
            setLoading(false);
        }, 800); // simulate 2 seconds loading time
    }, []);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: "10px",
            height: "55px",
            backgroundColor: state.isFocused ? "transparent" : "transparent",
            border: state.isFocused ? "1px solid #EFB81C" : "1px solid grey",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: document.documentElement.classList.contains('dark') ? "#151415" : "white",
            color: document.documentElement.classList.contains('dark') ? "white" : "black",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "red" : state.selectProps.isDarkMode ? "white" : "black",
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: state.selectProps.isDarkMode ? "gray" : "gray",
        }),
    };

    return (
        <>
            <label className="block mb-2 text-[16px] font-medium text-gray-900 dark:text-white">
                Select Coin
            </label>
            <div className="relative">
                {loading ? (
                    <div className="animate-pulse">
                        <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
                    </div>
                ) : (
                    <div className="dark:bg-red rounded">
                        <Select
                            value={selectedCrypto}
                            onChange={(selectedOption) => setSelectedCrypto(selectedOption)}
                            options={coins}
                            isSearchable
                            placeholder="Select Crypto"
                            components={{ Option: CustomOption }}
                            styles={customStyles}
                            isDarkMode={document.documentElement.classList.contains('dark')}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#EFB81C', // Light red for the highlighted option
                                    primary: '#EFB81C', // Darker red for the selected option
                                    neutral0: document.documentElement.classList.contains('dark') ? '#EFB81C' : '#ffffff', // Background color
                                    neutral80: document.documentElement.classList.contains('dark') ? 'white' : 'black', // Text color
                                },
                            })}
                        />
                    </div>
                )}
            </div>
            <div className="flex gap-2 mt-4 flex-wrap">
                {loading
                    ? Array(5)
                          .fill()
                          .map((_, index) => (
                              <div
                                  key={index}
                                  className="flex items-center rounded border-2 border-slate-200 dark:text-white dark:border-1 pl-1 pr-1 gap-1 cursor-pointer animate-pulse"
                              >
                                  <div className="bg-gray-200 p-1 w-7" style={{ width: "27px", height: "27px" }}></div>
                                  <div className="bg-gray-200 h-5 w-10"></div>
                              </div>
                          ))
                    : coins.slice(0, 5).map((coin, index) => (
                          <div
                              key={index}
                              className="flex items-center rounded border-2 border-slate-200 dark:text-white pl-2 pr-2 gap-1 cursor-pointer"
                              onClick={() => handleImageClick(coin)}
                          >
                              <img src={coin.icon} alt={`${coin.value}-coin`} className="p-1 w-7" style={{ width: "27px" }} />
                              {coin.value}
                          </div>
                      ))}
            </div>
        </>
    );
};

export default CoinSelect;
