import React from "react";
import CardImage from "../../assets/img/earn/card22.svg";

const TopCard = ({ newbtn }) => {
    return (
        <div className="flex-1 bg-[#FFF] rounded-[16px] border-[1px] border-solid border-[#f5f7fa] text-[#121214] p-6 shadow-lg cursor-pointer">
            <div className="flex justify-between items-center">
                <div className="flex gap-2">
                    <div className="w-10 h-10 rounded-full ">
                        <img src={CardImage} alt="" className="w-full h-full" />
                    </div>
                    <div>
                        <p className="text-[#121214] text-[16px] font-semibold">
                            KMNO - USDT Buy Low
                        </p>
                        <p className="text-[#81858c] text-[14px]">
                            Dual Asset | Advanced
                        </p>
                    </div>
                </div>
                {newbtn && (
                    <div className="bg-[#f7a600] text-[12px] p-1 rounded-[4px] text-[#121214] px-2">
                        New
                    </div>
                )}
            </div>
            <div className="flex justify-between mt-6">
                <div>
                    <p className="text-[#81858c] text-[12px]">APR</p>
                    <p className="text-[#20b26c] text-[16px] font-semibold">
                        212.04 % ~ 487.89 %
                    </p>
                </div>
                <div>
                    <p className="text-[#81858c] text-[12px]">APR</p>
                    <p className="text-[#121214] text-[16px] font-semibold">
                        1 Day
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TopCard;
