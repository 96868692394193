import React from 'react'

import hand from "assets/img/varification/hand-shake.png";
import RelatedArticles from 'components/varifications/RelatedArticles';
function Verification() {

  return (
    <>
      <section className="bg-black bg-cover bg-center w-full flex justify-center items-center py-[100px]">
        <div className="container">
          <div class="text-center max-w-3xl mx-auto  my-auto">
            <h1 class="md:text-5xl/snug text-white text-3xl font-semibold  ">
              <span class="text-[#EFB81C]  md:text-5xl/snug text-3xl font-semibold">  Bycex  </span> Authenticity Check
            </h1>
            <p class="text-base font-normal text-white mt-5">
              Bybit does not tolerate illegal practices of any nature.
              Kindly check the authenticity of your Bybit sources (e.g. telephone numbers, emails and URLs) in the search bar below.
            </p>
            <div className=' bg-white shadow rounded p-3 md:flex items-center justify-between mt-3 gap-1'>
              <span className='text-[#837e7e]   md:w-[25%] block w-full  text-left '>
                <select placeholder='Select a channel' className='w-full py-2 outline-none border-none'>
                  <option>
                    Phone
                  </option>
                  <option>
                    Telegram
                  </option>
                  <option>
                    Whatsapp
                  </option>
                </select>
              </span>
              <input type="search" className='md:w-[55%]  py-2  border-l ps-2 block w-full' placeholder='Please select a channel to verify' />
              <button className='bg-[#EFB81C] py-2 md:w-[20%] rounded px-3 text-white text-center hover:no-underline text-[16px] block w-full'>Search</button>
            </div>
          </div>
        </div>
      </section>
      <section className='py-[100px]'>
        <div className='container'>
          <h1 class="md:text-2xl/snug  text-center text-xl mb-3 font-semibold  ">
            Related Articles
          </h1>
          <div className='grid md:grid-cols-3 grid-cols-1  gap-4 lg:w-[70%] md:w-full mx-auto'>
            <RelatedArticles />
          </div>
        </div>

      </section>

      <section

        className=" 
      py-[100px]
"
      >
        <div className="container">
          <div class="text-center flex items-center justify-center flex-col max-w-3xl mx-auto  my-auto">
            <img src={hand} alt="" className=' w-[100px] h-[100px] object-contain mt-[-100px]' />

            <h1 class="md:text-2xl/snug  text-3xl font-semibold  ">
              Your contribution is invaluable.
            </h1>
            <p class="text-sm font-normal   mt-2">
              Bybit does not tolerate illegal practices of any nature.
              Kindly check the authenticity of your Bybit sources (e.g. telephone numbers, emails and URLs) in the search bar below.
            </p>
            <button className='bg-[#EFB81C] py-2 rounded px-4 text-white text-center hover:no-underline text-[16px] mt-3'>Search</button>

          </div>
        </div>
      </section>

    </>
  )
}

export default Verification
