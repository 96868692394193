import { useState, useEffect } from "react";
import axios from "axios";

const useOrderBook = (selectedCoin) => {
    const [orderBook, setOrderBook] = useState({ bids: [], asks: [] });
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrderBook = async () => {
            try {
                const selectedCoinPair = selectedCoin.ticker
                    .toLowerCase()
                    .replace("/", "_");

                const response = await axios.get(
                    `https://api.buycex.com/cmc/orderbook?market_pair=${selectedCoinPair}`
                );
                const data = response.data;

                if (!data && !data.data) {
                    setError(
                        "No order book data available for the selected coin."
                    );
                    setOrderBook({ bids: [], asks: [] });
                    return;
                }

                const newBids = data.data.bids || [];
                const newAsks = data.data.asks || [];

                setOrderBook({ bids: newBids, asks: newAsks });
                setError(null);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchOrderBook();
        const intervalId = setInterval(fetchOrderBook, 60000);

        return () => clearInterval(intervalId);
    }, [selectedCoin]);

    return { orderBook, error };
};

export default useOrderBook;
