import React from "react";
import trades from "../../assets/img/derivates-trade.png";
import google from "../../assets/img/derivates-google-play.png";
import appstore from "../../assets/img/derivates-app-store.png";

const TradeAnywhere = () => {
  return (
    <div className="flex items-center justify-center w-full py-10 text-white bg-gray-50">
      <div className="flex flex-col lg:flex-row items-start justify-between w-[100%] max-w-[1200px] mx-auto gap-x-4">
        {/* Left Side Content */}
        <div className="flex md:ml-5 flex-col justify-center gap-1 text-center lg:gap-2 lg:!text-left">
          <h1 className="mb-4 text-4xl font-bold lg:text-5xl text-black">
            Trade Anywhere
          </h1>

          {/* Trading Volume and Users */}
          <div className="flex justify-center mt-4 text-sm gap-y-0 gap-x-3 gap lg:justify-start lg:text-base">
            <div>
              <h6 className="text-lg text-black font-bold">API</h6>
              <p className="text-[10px] text-gray-400">
                Trade using external services with OpenAPI
              </p>
            </div>
            <div>
              <h6 className="text-lg text-black font-bold">Mobile</h6>
              <p className="text-[10px] text-gray-400">
                Download the Bybit app to trade whenever, wherever
              </p>
            </div>
            <div>
              <h6 className="text-lg text-black  font-bold">Web</h6>
              <p className="text-[10px] text-gray-400">
                Get the full Bybit experience on the web
              </p>
            </div>
          </div>

          <div className="flex justify-center mt-3 gap-x-2 lg:justify-start">
            <a
              href="https://apps.apple.com"
              target="_blank"
              rel="noopener noreferrer"
              className="border-[1px] border-white/50 rounded-md"
            >
              <img src={appstore} className="w-[130px]" alt="App Store" />
            </a>
            <a
              href="https://play.google.com"
              target="_blank"
              rel="noopener noreferrer"
              className="border-[1px] border-white/50 rounded-md"
            >
              <img
                src={google}
                className="w-[130px] "
                alt="Google Play Store"
              />
            </a>
          </div>
        </div>
        {/* Right Side Image */}
        <div className="mt-8 !ml-5 md:!ml-0 lg:mt-0">
          <img
            src={trades}
            className="w-[90%] md:w-[710px] lg:h-[300px] object-contain"
            alt="Crypto trading platform"
          />
        </div>
      </div>
    </div>
  );
};

export default TradeAnywhere;
