import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    BsWallet2,
    BsGrid,
    BsGraphUp,
    BsCashStack,
    BsBarChartFill,
    BsSpeedometer2,
    BsCurrencyExchange,
    BsEyeSlashFill,
    BsEyeFill,
} from "react-icons/bs";

const UserOrdersMenu = () => {
    const [isAssetsHidden, setIsAssetsHidden] = useState(true);
    const menuList = [
        {
            id: 1,
            name: "Dashboard",
            title: "Dashboard",
            icon: <BsGrid size={14} />,
            path: "/user/dashboard",
        },
        {
            id: 2,
            name: "Spot",
            title: "Spot",
            icon: <BsGraphUp size={14} />,
            path: "/user/spot-order/pending",
        },
        {
            id: 3,
            name: "Funding",
            title: "Funding",
            icon: <BsWallet2 size={14} />,
            path: "/user/assets/funding-overview",
        },
        {
            id: 4,
            name: "Earn",
            title: "Earn",
            icon: <BsCashStack size={14} />,
            path: "/user/assets/earn-overview",
        },
        {
            id: 5,
            name: "Margin",
            title: "Margin",
            icon: <BsBarChartFill size={14} />,
            path: "/user/assets/margin",
        },
        {
            id: 6,
            name: "Futures",
            title: "Futures",
            icon: <BsSpeedometer2 size={14} />,
            path: "#",
        },
        {
            id: 7,
            name: "Fiat Balance",
            title: "Fiat Balance",
            icon: <BsCurrencyExchange size={14} />,
            path: "#",
        },
    ];
    const redirectAssetsOverview = () => {
        history.push("/user/assets/spot");
    };
    const toggleAssetsVisibility = () => {
        setIsAssetsHidden(!isAssetsHidden);
    };

    return (
        <div className="header__user-orders-menu-wrapper rounded-b-md dropdown-shadow bg-[#17181e] w-[200px] absolute top-[34px] right-[0px] h-max z-[999]">
            <div className="px-3 flex flex-col">
                <div className="flex flex-col">
                    {/* <div className="flex gap-3 items-center pb-2 relative"> 
                        <div className="user-assets-menu__total-assets flex flex-col gap-3 w-full">
                            <div className="total-assets__label flex items-center gap-2">
                                <span className="text-[16px] font-semibold">
                                    Total Assets
                                </span>
                                <button
                                    type="button"
                                    onClick={toggleAssetsVisibility}
                                    className="total-assets__hide-btn w-max h-max text-[#151415] opacity-60 transition-all ease-out duration-300 hover:opacity-100"
                                >
                                    {isAssetsHidden ? (
                                        <BsEyeSlashFill className="w-4 h-4" />
                                    ) : (
                                        <BsEyeFill className="w-4 h-4" />
                                    )}
                                </button>
                            </div>
                            <div className="total-assets__value w-full">
                                <button
                                    type="button"
                                    onClick={redirectAssetsOverview}
                                    className="flex items-center gap-2 w-full"
                                >
                                    {isAssetsHidden ? (
                                        "******"
                                    ) : (
                                        <>
                                            <div className="text-[16px] font-semibold">
                                                <span>0.00</span>
                                            </div>
                                            <div className="text-[14px] opacity-60">
                                                <span>USD</span>
                                            </div>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div> */}
                    {/* Deposit and Withdraw Buttons */}
                </div>
                {/* User Orders Navigation Menu */}
                <div className="user-side-menu__menu-wrapper">
                    <ul className="flex flex-col gap-0 user-side-menu__menu-list">
                        {menuList.map((data) => (
                            <li
                                key={data.id}
                                className="flex items-center gap-2"
                            >
                                <Link
                                    to={data.path}
                                    title={data.title}
                                    rel="alternate noopener noreferrer"
                                    className="w-full flex items-center gap-2 px-2 py-[10px] rounded-md hover:bg-[#383a3d] opacity-80 hover:opacity-100"
                                >
                                    <span>{data.icon}</span>
                                    <span className="font-semibold text-[14px]">
                                        {data.name}
                                    </span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default UserOrdersMenu;
