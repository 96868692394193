export const ADD_FAVORITE = 'ADD_FAVORITE';
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';
export const TOGGlE_FAVORITE='TOGGlE_FAVORITE'

export const addFavorite = (item) => (dispatch, getState) => {
  const { favorites } = getState().favorites;
  const exists = favorites.some(favorite => favorite.id === item.id);

  if (!exists) {
    dispatch({ type: ADD_FAVORITE, payload: item });
  }
};

export const removeFavorite = (itemId) => {
  return { type: REMOVE_FAVORITE, payload: itemId };
};

export const toggleFavorite = (item) => (dispatch, getState) => {
  const { favorites } = getState().favorites;
  const exists = favorites.some(favorite => favorite.id === item.id);

  if (exists) {
    dispatch(removeFavorite(item.id));
  } else {
    dispatch(addFavorite(item));
  }
};
