import React, { useEffect, useState } from 'react';
import HistoryDropdown from '../../SpotHistory/DropDownUI';
import { DatePicker, Modal, Input } from 'antd';
import { createGlobalStyle, styled } from "styled-components";
import dayjs from 'dayjs';
import { IoSearchOutline } from "react-icons/io5";
import NoData from "assets/img/user/No-data.svg";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { transactionHistory } from 'store/actions/FinanceActions';

const { RangePicker } = DatePicker;

function Crypto() {
    const options1 = ["All", "Sell", "Buy"];
    const option2 = ["Past 7 days", "Past 30 days", "Past 90 days", "Customized"];
    const [options3, setOptions3] = useState(["All"]);
    const option4 = ["All", "Completed", "Pending"];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDates, setSelectedDates] = useState([null, null]);
    const [selectedOption, setSelectedOption] = useState("Past 7 days");
    const [txIdFilter, setTxIdFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [typeFilter, setTypeFilter] = useState('All');
    const [marketFilter, setMarketFilter] = useState('All');


    const handleSelect1 = (selected) => {
        setTypeFilter(selected);
    };
    const handleSelectMarket = (selected) => {
        setMarketFilter(selected);
    };
  
    const handleSelect2 = (selected) => {
        if (selected === "Customized") {
            setIsModalVisible(true);
        } else {
            setSelectedOption(selected);
        }
    };

    const handleOk = () => {
        if (selectedDates[0] && selectedDates[1]) {
            const formattedStartDate = dayjs(selectedDates[0]).format('YYYY-MM-DD');
            const formattedEndDate = dayjs(selectedDates[1]).format('YYYY-MM-DD');
            setSelectedOption(`${formattedStartDate} - ${formattedEndDate}`);
        }
        setIsModalVisible(false);
        setSelectedDates([null, null]);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedDates([null, null]);
    };

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const { data } = useSelector((state) => state.financeHistory || {});
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (userID && userToken) {
            dispatch(transactionHistory(userID, userToken));
        }
    }, [dispatch, userID, userToken]);

    useEffect(() => {
      if (data?.list) {
          const markets = [...new Set(data.list.map(item => item.market))];
          const formattedMarkets = markets.map(market => market.replace("_", "/").toUpperCase());
          setOptions3(["All", ...formattedMarkets]);
      }
  }, [data]);
  
    
    const getStartDate = (option) => {
        const now = dayjs();
        switch (option) {
            case "Past 7 days":
                return now.subtract(7, 'day');
            case "Past 30 days":
                return now.subtract(30, 'day');
            case "Past 90 days":
                return now.subtract(90, 'day');
            case "Customized":
                return dayjs(selectedDates[0]);
            default:
                return null;
        }
    };

    const filteredData = data?.list?.filter(item => {
      const itemDate = dayjs(item.addtime * 1000);
      const startDate = getStartDate(selectedOption);
      const endDate = selectedOption === "Customized" ? dayjs(selectedDates[1]) : dayjs();
  
      const matchesTxId = item.id.toString().includes(txIdFilter);
      const matchesStatus = statusFilter === "All" || 
                            (statusFilter === "Completed" && item.status === "1") || 
                            (statusFilter === "Pending" && item.status === "0");
      const matchesType = typeFilter === "All" || 
                          (typeFilter === "Buy" && item.type === "1") || 
                          (typeFilter === "Sell" && item.type === "2");
      const matchesMarket = marketFilter === "All" || item.market.replace("_", "/").toUpperCase() === marketFilter; // Add this line
      const matchesTime = selectedOption === "All" || (itemDate.isAfter(startDate) && itemDate.isBefore(endDate));
  
      return matchesTxId && matchesStatus && matchesType && matchesMarket && matchesTime;
  }) || [];

  const resetFilters = () => {
      setTxIdFilter('');
      setStatusFilter('All');
      setTypeFilter('All');
      setMarketFilter('All');
      setSelectedOption("Past 7 days");
      setSelectedDates([null, null]);
      setIsModalVisible(false);
  };

  

    return (
        <div>
            <div className='flex gap-4 items-center'>
                <HistoryDropdown
                    label="Type"
                    options={options1}
                    initialSelected={typeFilter}
                    onSelect={handleSelect1}
                    isSearchable={false}
                    width="80px"
                />
                <HistoryDropdown
                    label="Time"
                    options={option2}
                    initialSelected={selectedOption}
                    onSelect={handleSelect2}
                    isSearchable={false}
                    width="120px"
                />
                <Modal
                    title="Select Date Range"
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <div className="flex flex-col gap-4">
                        <p className='mt-10'>Select your time range within 12 months.</p>
                        <GlobalStyles />
                        <StyledRangePicker
                            onChange={(dates) => setSelectedDates(dates)}
                            value={selectedDates}
                        />
                        <button key="ok" type="primary" onClick={handleOk} className='px-4 py-2 my-7 font-semibold bg-[#EFB81C] rounded-lg'>Continue</button>
                    </div>
                </Modal>
                <HistoryDropdown
                    label="Market"
                    options={options3}
                    initialSelected={marketFilter}
                    onSelect={handleSelectMarket}
                    isSearchable={true}
                    width="80px"
                />
                <HistoryDropdown
                    label="Status"
                    options={option4}
                    initialSelected={statusFilter}
                    onSelect={(selected) => setStatusFilter(selected)}
                    isSearchable={false}
                    width="80px"
                />
                <StyledInput
                    type="text"
                    placeholder="Enter TxID"
                    className="w-[140px] px-3 ring-1 ring-inset ring-gray-200 py-2 border border-gray-100 rounded-lg"
                    prefix={<IoSearchOutline />}
                    value={txIdFilter}
                    onChange={(e) => setTxIdFilter(e.target.value)}
                />
                <button className='text-[#EFB81C] font-semibold ml-2' onClick={resetFilters}>Reset</button>
            </div>
            <div className='mt-5 underline underline-offset-2 text-sm text-[#EFB81C]'>Deposit hasn't arrived?</div>
            <div className="text-xs mt-12 text-[#707a8a] w-full overflow-x-auto">
                <div className="flex justify-between border-b pb-3 w-full">
                    <p className="flex-1 text-start">Time</p>
                    <p className="flex-1 text-start">Type</p>
                    <p className="flex-[2] text-center">Market</p>
                    <p className="flex-1 text-start">Price</p>
                    <p className="flex-1 text-center">Amount</p>
                    <p className="flex-1 text-center">Total</p>
                    <p className="flex-1 text-center">TxID</p>
                    <p className="flex-1 text-center">Status</p>
                </div>
                {filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                        <div key={index} className="flex justify-between w-full py-3">
                            <p className="flex-1 text-start flex items-center text-[14px] text-black">
                                {new Date(item.addtime * 1000).toLocaleString() || 'N/A'}
                            </p>
                            <p className="flex-1 text-start text-[14px]">
                                {item.type === "1" ? "Buy" : "Sell"}
                            </p>
                            <p className="flex-[2] text-center text-[14px] text-black">
                                {item.market.replace("_", "/").toUpperCase()}
                            </p>
                            <p className="flex-1 text-start text-[14px] text-black">
                                {(item.price ?? 0).toFixed(8)}
                            </p>
                            <p className="flex-1 text-center text-[14px] text-black">
                                {(item.num ?? 0).toFixed(4)}
                            </p>
                            <p className="flex-1 text-center text-[14px] text-black">
                                {(item.total ?? 0).toFixed(8)}
                            </p>
                            <p className="flex-1 text-center text-[14px] text-black">
                                {item.id}
                            </p>
                            <p className="flex-1 text-center text-[14px] text-black">
                                {item.status === "1" ? "Completed" : "Pending"}
                            </p>
                        </div>
                    ))
                ) : (
                    <div className='flex flex-col items-center justify-center'>
                        <img src={NoData} alt="No Data" />
                        <p className='text-center'>No Data Found</p>
                    </div>
                )}
            </div>
        </div>
    );
}

const StyledInput = styled(Input)`
    border-radius: 0.375rem; /* Custom border radius */
`;

const GlobalStyles = createGlobalStyle`
    .ant-picker {
        width: 100%;
    }
`;

const StyledRangePicker = styled(RangePicker)`
    width: 100%;
`;

export default Crypto;
