import React, { useEffect, useState } from "react";
import OrderNavbar from "./OrderNavbar";
import InProgress from "./InProgress";
import AllOrders from "./AllOrders";
import AppealRecords from "./AppealRecords";
import { useDispatch, useSelector } from "react-redux";
import { fetchFiatOrder } from "../../../store/actions/OrderUserAction";

const OrderPage = () => {
  const [activeComponent, setActiveComponent] = useState("AllOrders");
  const dispatch = useDispatch();

  const { loading, fiatOrder, error } = useSelector((state) => state.fiatOrder);
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  useEffect(() => {
    dispatch(fetchFiatOrder(userID, userToken));
  }, [dispatch, userID, userToken]);

  return (
    <div>
      <div className="container">
      <OrderNavbar
        setActiveComponent={setActiveComponent}
        activeComponent={activeComponent}
      />
      </div>
      <div>
        {activeComponent === "Processing" && <InProgress />}
        {activeComponent === "AllOrders" && <AllOrders />}
        {activeComponent === "AppealRecords" && <AppealRecords />}
    </div>
    </div>
  );
};

export default OrderPage;
