import { RepositoryFactory } from "repository/RepositoryFactory";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const otcRepository = RepositoryFactory.get("otc");

export const Otcrecords = (userId, token) => async (dispatch) => {
    dispatch({type: "FETCH_OTC_RECORDS_STATUS_REQUEST"});
    try {
        const response = await otcRepository.records(userId, token);
        const res = response.data.data
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_OTC_RECORDS_STATUS_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: res };
        } else {
            dispatch({
                type: "FETCH_OTC_RECORDS_STATUS_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)            
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_OTC_RECORDS_STATUS_FAILURE", payload: error.message });
        return false;
    }
};


export const fetchOtcCoin = (userId, token) => async (dispatch) => {
    dispatch({ type: "FETCH_OTC_COIN_REQUEST" });
    try {
        const response = await otcRepository.otcCoin(userId, token);
        const res = response.data.data;
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_OTC_COIN_SUCCESS",
                payload: res,
            });
            return { success: true, data: res };
        } else {
            dispatch({
                type: "FETCH_OTC_COIN_FAILURE",
                payload: response.data.message,
            });
            toast.error(response.data.message);
            return false;
        }
    } catch (error) {
        dispatch({
            type: "FETCH_OTC_COIN_FAILURE",
            payload: error.message,
        });
        return false;
    }
};

export const getQuote = (tradeCoin, baseCoin, input1, input2, tradeType, token, userId) => async (dispatch) => {
    dispatch({ type: "FETCH_QUOTE_REQUEST" });
    try {
        const response = await otcRepository.getQuote(tradeCoin, baseCoin, input1, input2, tradeType, token, userId);
        console.log("get quote response", response);

        const res = response.data;

        // Top-level API status check
        if (res.status === 1) {
            const quoteData = res.data;

            // Nested status check inside "data"
            if (quoteData.status === 1) {
                dispatch({
                    type: "FETCH_QUOTE_SUCCESS",
                    payload: quoteData.data, // The actual quote information
                });
                toast.success("Quote fetched successfully!");
                return { success: true, data: quoteData.data };
            } else {
                // Nested status is 0, handle the error message
                dispatch({
                    type: "FETCH_QUOTE_FAILURE",
                    payload: quoteData.msg, // Error message from the API
                });
                toast.error(quoteData.msg); // Display error to the user
                return { success: false, message: quoteData.msg };
            }
        } else {
            // Top-level status is not 1
            dispatch({
                type: "FETCH_QUOTE_FAILURE",
                payload: res.data || "An error occurred",
            });
            toast.error(res.data || "Failed to fetch quote");
            return { success: false, message: res.data || "Failed to fetch quote" };
        }
    } catch (error) {
        // Handle network or other unexpected errors
        dispatch({
            type: "FETCH_QUOTE_FAILURE",
            payload: error.message,
        });
        toast.error("Something went wrong. Please try again.");
        return { success: false, message: error.message };
    }
};




export const getConversion = (fromCoin, amount, toCoin, token, userId) => async (dispatch) => {
    dispatch({ type: "FETCH_CONVERSION_REQUEST" });
    try {
        const response = await otcRepository.getConversion(fromCoin, amount, toCoin, token, userId);
        const res = response.data.data;
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_CONVERSION_SUCCESS",
                payload: res,
            });
            return { success: true, data: res };
        } else {
            dispatch({
                type: "FETCH_CONVERSION_FAILURE",
                payload: response.data.message,
            });
            toast.error(response.data.message);
            return false;
        }
    } catch (error) {
        dispatch({
            type: "FETCH_CONVERSION_FAILURE",
            payload: error.message,
        });
        return false;
    }
};
