import React, { useState } from "react";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import SecurityLevelStatus from "components/global/validations/security/KYCStatusChecker";
import NetworkStatusChecker from "components/global/network/NetworkStatusChecker";
import ChatSupport from "components/global/bot/chat/ChatBot";

const MainLayout = (props) => {
    const [allVerified, setAllVerified] = useState(false);
    const [isOffline, setIsOffline] = useState(false);

    return (
        <>
            <Header />
            <main className="bg-[#FFFFFF]">
                {/* Shows Verification Status */}
                {!allVerified && (
                    <SecurityLevelStatus setAllVerified={setAllVerified} />
                )}

                {/* Shows Network Status Warning */}
                <div className="fixed bottom-1 left-1">
                    <NetworkStatusChecker
                        isOffline={isOffline}
                        setIsOffline={setIsOffline}
                    />
                </div>

                {/* Page Contents Wrapper */}
                <div className="buycex-content-wrapper">{props.children}</div>

                {/* Chat Support Bot */}
                <ChatSupport />
            </main>
            <Footer />
        </>
    );
};

export default MainLayout;
