import React from 'react';
import { useHistory } from "react-router-dom";


const Identity = () => {
    const history = useHistory();

    const handleVerify = () => {
        history.push("/user/account/identity-verification/information");
    }
    
    return (
        <div>
            <div className="bg-gray-100 text-lg">
                {/* Container */}
                <div className="mx-auto container bg-white p-6 sm:p-12 md:p-16 rounded-lg space-y-6">
                    {/* Header Row */}
                    <div className="flex flex-col md:flex-row justify-between items-center mb-6">
                        <h1 className="text-xl sm:text-2xl font-semibold mb-4 md:mb-0">
                            Identity Verification
                        </h1>
                        <button className="bg-[#F1BA19] hover:bg-[#f1bb19db] text-white px-4 py-2 rounded">
                            Entity Verification
                        </button>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {/* Identity Verification Card */}
                        <div
                            className="p-6 space-y-6 rounded-lg border bg-white col-span-1 md:col-span-2"
                            style={{ height: 350 }}
                        >
                            <div className="flex items-start space-x-4">
                                {/* Icon Section */}
                                <div className="flex-shrink-0 bg-blue-100 rounded-full p-3">
                                    {/* Icon */}
                                    <svg
                                        t={1681740431756}
                                        className="icon"
                                        viewBox="0 0 1024 1024"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        p-id={18387}
                                        width={50}
                                        height={50}
                                    >
                                        <path
                                            d="M628.516571 336.749714c0 71.899429-40.228571 134.436571-99.364571 166.253715 18.212571 5.412571 35.766857 12.397714 52.443429 20.845714A255.634286 255.634286 0 0 0 475.428571 731.428571c0 25.417143 3.693714 49.956571 10.605715 73.142858H165.083429c-21.906286 0-39.862857-17.810286-37.156572-39.533715a314.733714 314.733714 0 0 1 222.72-262.034285 188.635429 188.635429 0 1 1 277.942857-166.253715z"
                                            fill="#2354E6"
                                            p-id={18388}
                                        />
                                        <path
                                            d="M731.428571 914.285714a182.857143 182.857143 0 1 0 0-365.714285 182.857143 182.857143 0 0 0 0 365.714285z m121.051429-242.029714l-128.658286 171.081143-109.604571-93.878857 33.28-38.912 68.205714 58.404571 95.817143-127.488 40.96 30.793143z"
                                            fill="#04D590"
                                            p-id={18389}
                                        />
                                    </svg>
                                </div>
                                {/* Content Section */}
                                <div className="w-full">
                                    {/* Title, Requirements, and Entitlements */}
                                    <div>
                                        <h2 className="text-2xl font-bold mb-2">
                                            Identity Verification
                                            <span className="text-white bg-green-700 px-3 py-1 rounded-lg text-xs font-medium ml-2">
                                                Complete verification to claim $50-$100 Reward
                                            </span>
                                        </h2>
                                        <p className="text-sm text-gray-600 mb-3">
                                            Requirements: basic information, identification document, facial
                                            recognition
                                        </p>
                                        <ul className="text-sm text-gray-700 bg-gray-100 rounded ml-2 space-y-3">
                                            <li
                                                style={{
                                                    listStyle: "square",
                                                    color: "rgb(72, 170, 72)",
                                                    fontSize: 15
                                                }}
                                            >
                                                <p style={{ color: "black" }}>
                                                    <strong>Entitlements</strong>: fiat, deposit, withdrawal,
                                                    transaction, coupon center, Startup, event
                                                    <br /> rewards, etc.
                                                </p>
                                            </li>
                                        </ul>
                                        <p className="text-xs text-gray-500 mt-2 space-y-3">
                                            Estimated time to complete review: 2 minutes
                                        </p>
                                    </div>
                                    {/* Button aligned to the bottom right */}
                                    <div className="flex justify-end">
                                        <button className="bg-[#F1BA19] hover:bg-[#f1bb19db] text-white px-6 py-2 mt-8 rounded" onClick={handleVerify}>
                                            Verify Now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FAQ Section */}
                        <div className="border p-6 rounded-lg overflow-hidden space-y-6 h-64 md:h-80 lg:h-96">
                            <h3 className="text-lg font-semibold mb-6">FAQ</h3>
                            <div className="space-y-4">
                                {/* FAQ Item 1 */}
                                <p className="text-sm text-gray-500 cursor-pointer">
                                    How to complete Individual KYC?
                                </p>
                                <p className="text-sm text-gray-500 cursor-pointer">
                                    How to complete Enterprise KYC?
                                </p>
                                <hr className="pb-4" />
                                {/* FAQ Item 2 with expandable details */}
                                <details className="border-b pb-8 space-y-2">
                                    <summary className="font-medium text-gray-800 cursor-pointer flex justify-between items-center">
                                        Why is KYC necessary?
                                        <span className="text-gray-500">▾</span>
                                    </summary>
                                    <p className="mt-2 text-sm text-gray-600">
                                        Explanation about the necessity of KYC.
                                    </p>
                                </details>
                                {/* FAQ Item 3 with expandable details */}
                                <details className=" pb-2 space-y-9">
                                    <summary className="font-medium text-gray-800 cursor-pointer flex justify-between items-center">
                                        Why is an advanced verification necessary?
                                        <span className="text-gray-500">▾</span>
                                    </summary>
                                    <p className="mt-2 text-sm text-gray-600">
                                        Details on why advanced verification might be required.
                                    </p>
                                </details>
                            </div>
                        </div>
                        {/* Address Verification Card */}
                        <div
                            className="border p-4 rounded-lg flex items-start space-x-4 col-span-1 md:col-span-2 mt-4 md:mt-0"
                            style={{ marginTop: "-33px" }}
                        >
                            <div className="flex-shrink-0 bg-blue-100 rounded-full p-2">
                                {/* Icon */}
                                <svg
                                    t={1681740618478}
                                    className="icon"
                                    viewBox="0 0 1024 1024"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    p-id={18523}
                                    width={50}
                                    height={50}
                                >
                                    <path
                                        d="M689.540414 395.511172a282.482759 282.482759 0 0 1-8.898207 70.62069h-111.721931a176.551724 176.551724 0 1 0-91.241931 91.241931v268.923586l-70.62069-42.372413-149.821793 89.900137a17.655172 17.655172 0 0 1-26.729931-15.148137v-242.65269a282.482759 282.482759 0 1 1 459.034483-220.513104z"
                                        fill="#2354E6"
                                        p-id={18524}
                                    />
                                    <path
                                        d="M407.057655 501.442207a105.931034 105.931034 0 1 0 0-211.862069 105.931034 105.931034 0 0 0 0 211.862069z"
                                        fill="#2354E6"
                                        p-id={18525}
                                    />
                                    <path
                                        d="M583.609379 536.752552a35.310345 35.310345 0 0 0-35.310345 35.310345v282.482758a35.310345 35.310345 0 0 0 35.310345 35.310345h282.482759a35.310345 35.310345 0 0 0 35.310345-35.310345v-282.482758a35.310345 35.310345 0 0 0-35.310345-35.310345h-282.482759z m117.68938 270.265379l-102.753104-102.753103 40.748138-40.783449 62.004966 62.004966 103.212138-103.212138 40.783448 40.783448-143.995586 143.995586z"
                                        fill="#04D590"
                                        p-id={18526}
                                    />
                                </svg>
                            </div>
                            <div className="ml-4 w-full">
                                <h2 className="text-lg font-semibold">Address Verification</h2>
                                <p className="text-sm text-gray-600">
                                    Requirements: Proof of address
                                </p>
                                <p className="text-xs text-gray-500 mt-2">
                                    Estimated time to complete review: 10 minutes
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Identity;
