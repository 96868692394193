import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import {
    BsCaretDown,
    BsCaretDownFill,
    BsCaretUp,
    BsCaretUpFill,
} from "react-icons/bs";

const TableSpot = ({ assetsData, loading, error, hideAssets }) => {
    const [isFilter, setIsFilter] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: "ascending",
    });
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        console.log("testing assetsData", assetsData)
        console.log("testing crypo", assetsData.cryptoList)
        console.log("testing fiatlist", assetsData.fiatList)
        if (assetsData && (assetsData.cryptoList || assetsData.fiatList)) {
            if (isFilter && assetsData.cryptoList) {
                const coinList = Object.values(assetsData.cryptoList).map(
                    (coin, index) => ({
                        id: index,
                        icon: coin.img || "bitcoin",
                        coinName: coin.name.toUpperCase(),
                        coinType: coin.title,
                        amount: parseFloat(coin.xnbz),
                        coinPrice: coin.jia,
                    })
                );
                setTableData(coinList);
            }
            else if (assetsData.fiatList) {
                const fiatList = Object.values(assetsData.fiatList).map(
                    (coin, index) => ({
                        id: index,
                        icon: coin.img || "bitcoin",
                        coinName: coin.name.toUpperCase(),
                        coinType: coin.title,
                        amount: parseFloat(coin.xnbz),
                        coinPrice: coin.jia,
                    })
                );
                setTableData(fiatList);
            }
        }
    }, [isFilter, assetsData]);

    const sortData = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }

        const sortedData = [...tableData].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === "ascending" ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === "ascending" ? 1 : -1;
            }
            return 0;
        });

        setTableData(sortedData);
        setSortConfig({ key, direction });
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = tableData.filter(
        (data) =>
            data.coinName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            data.coinType.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="text-lg">Loading...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="text-lg text-red-500">Error: {error}</div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center w-full bg-white p-[28px] mt-3 border rounded-2xl">
            {/* Heading */}
            <div className="flex items-center justify-between w-full mb-4">
                {/* Search Bar */}
                <div className="border">
                    <input
                        type="text"
                        placeholder="Search"
                        className="w-[155px] py-1 text-[#DBDBDB] text-[10px] px-2 bg-transparent outline-none placeholder-inherit"
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                </div>
                <h6 className="text-yellow-500">Transaction History</h6>
            </div>

            {/* Buttons */}
            <div className="flex w-full justify-start mt-4 font-medium gap-x-6 text-[16px]">
                <button
                    onClick={() => setIsFilter(true)}
                    className={`${isFilter ? "border-[#c9c9c9]" : "border-transparent"
                        } pb-1 text-[16px] border-b-4`}
                >
                    Coins List
                </button>
                <button
                    onClick={() => setIsFilter(false)}
                    className={`${isFilter ? "border-transparent" : "border-[#c9c9c9]"
                        } pb-1 text-[16px] border-b-4`}
                >
                    Fiat List
                </button>
            </div>

            {/* Main Table */}
            <div className="w-full table-container h-fit">
                <div className="grid grid-cols-[1fr_1fr_1fr_1fr_2fr] mb-1 border-b">
                    <div
                        className="text-[#aaaaaa] font-light px-4 py-2 text-left flex items-center gap-1 cursor-pointer"
                        onClick={() => sortData("coinName")}
                    >
                        Coin
                        <div className="flex flex-col">
                            <span>
                                {sortConfig.key === "coinName" &&
                                    sortConfig.direction === "ascending" ? (
                                    <BsCaretUpFill size={12} fill="#151415" />
                                ) : (
                                    <BsCaretUp size={12} />
                                )}
                            </span>
                            <span>
                                {sortConfig.key === "coinName" &&
                                    sortConfig.direction === "descending" ? (
                                    <BsCaretDownFill size={12} fill="#151415" />
                                ) : (
                                    <BsCaretDown size={12} />
                                )}
                            </span>
                        </div>
                    </div>
                    <div
                        className="text-[#aaaaaa] font-light px-4 py-2 text-center flex items-center justify-center gap-1 cursor-pointer"
                        onClick={() => sortData("amount")}
                    >
                        Amount
                        <span className="relative flex text-[#aaaaaa] text-sm">
                            <span
                                className={`absolute right-0 top-1 ${sortConfig.key === "amount" &&
                                    sortConfig.direction === "ascending"
                                    ? "text-black"
                                    : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCaretUp} />
                            </span>
                            <span
                                className={`mt-[9px] ${sortConfig.key === "amount" &&
                                    sortConfig.direction === "descending"
                                    ? "text-black"
                                    : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                        </span>
                    </div>
                    <div
                        className="text-[#aaaaaa] font-light px-4 py-2 text-center flex items-center justify-center gap-1 cursor-pointer"
                        onClick={() => sortData("amount")}
                    >
                        Value
                        <span className="relative flex text-[#aaaaaa] text-sm">
                            <span
                                className={`absolute right-0 top-1 ${sortConfig.key === "amount" &&
                                    sortConfig.direction === "ascending"
                                    ? "text-black"
                                    : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCaretUp} />
                            </span>
                            <span
                                className={`mt-[9px] ${sortConfig.key === "amount" &&
                                    sortConfig.direction === "descending"
                                    ? "text-black"
                                    : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                        </span>
                    </div>
                    <div
                        className="text-[#aaaaaa] font-light px-4 py-2 text-center flex items-center justify-center gap-1 cursor-pointer"
                        onClick={() => sortData("coinPrice")}
                    >
                        Price
                        <span className="relative flex text-[#aaaaaa] text-sm">
                            <span
                                className={`absolute right-0 top-1 ${sortConfig.key === "coinPrice" &&
                                    sortConfig.direction === "ascending"
                                    ? "text-black"
                                    : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCaretUp} />
                            </span>
                            <span
                                className={`mt-[9px] ${sortConfig.key === "coinPrice" &&
                                    sortConfig.direction === "descending"
                                    ? "text-black"
                                    : ""
                                    }`}
                            >
                                <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                        </span>
                    </div>
                    <div className="text-[#aaaaaa] font-light px-4 py-2 text-right">
                        Action
                    </div>
                </div>

                {filteredData.map((data) => (
                    <div
                        key={data.id}
                        className="grid grid-cols-[1fr_1fr_1fr_1fr_2fr] py-1"
                    >
                        <div className="flex items-center px-4 py-2 text-left gap-x-4">
                            <img
                                src={`https://api.buycex.com/Upload/coin/${data.icon}`}
                                alt=""
                                className="w-6 h-6 rounded-full"
                            />
                            <div className="flex flex-col">
                                <span>{data.coinName}</span>
                                <span className="text-[12px] text-[#aaaaaa]">
                                    {data.coinType}
                                </span>
                            </div>
                        </div>
                        {/* Amount */}
                        <td className="flex-1 px-4 py-2 text-center">
                            {hideAssets ? "****" : data.amount.toFixed(2)}
                        </td>
                        {/* Value */}
                        <div className="flex items-center justify-center px-4 py-2 text-center">
                            {data.amount} USD
                        </div>
                        {/* coin Price */}
                        <div className="flex items-center justify-center px-4 py-2 text-center">
                            ${data.coinPrice.toFixed(2)}
                        </div>
                        {/* Actions */}
                        <div className="flex items-center justify-end px-4 py-2 text-right text-yellow-500 gap-x-2">
                            <button className="">Deposit</button>
                            <button className="">Withdraw</button>
                            <button className="">Trade</button>
                            <button className="">Convert</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TableSpot;
