const initialState = {
  records: {
    data: [],
    loading: false,
    error: null,
  },
  otcCoin: {
    data: [],
    loading: false,
    error: null,
  },
  quote: {
    data: null,
    loading: false,
    error: null,
  },
  conversion: {
    data: null,
    loading: false,
    error: null,
  },
};

export const otcHistory = (state = initialState, action) => {
  switch (action.type) {
    // Records actions
    case "FETCH_OTC_RECORDS_STATUS_REQUEST":
      return {
        ...state,
        records: {
          ...state.records,
          loading: true,
          error: null,
        },
      };
    case "FETCH_OTC_RECORDS_STATUS_SUCCESS":
      return {
        ...state,
        records: {
          ...state.records,
          loading: false,
          data: action.payload,
        },
      };
    case "FETCH_OTC_RECORDS_STATUS_FAILURE":
      return {
        ...state,
        records: {
          ...state.records,
          loading: false,
          error: action.payload,
        },
      };

    // OTC Coin actions
    case "FETCH_OTC_COIN_REQUEST":
      return {
        ...state,
        otcCoin: {
          ...state.otcCoin,
          loading: true,
          error: null,
        },
      };
    case "FETCH_OTC_COIN_SUCCESS":
      return {
        ...state,
        otcCoin: {
          ...state.otcCoin,
          loading: false,
          data: action.payload,
        },
      };
    case "FETCH_OTC_COIN_FAILURE":
      return {
        ...state,
        otcCoin: {
          ...state.otcCoin,
          loading: false,
          error: action.payload,
        },
      };

    // Quote actions
    case "FETCH_QUOTE_REQUEST":
      return {
        ...state,
        quote: {
          ...state.quote,
          loading: true,
          error: null,
        },
      };
    case "FETCH_QUOTE_SUCCESS":
      return {
        ...state,
        quote: {
          ...state.quote,
          loading: false,
          data: action.payload,
        },
      };
    case "FETCH_QUOTE_FAILURE":
      return {
        ...state,
        quote: {
          ...state.quote,
          loading: false,
          error: action.payload,
        },
      };

    // Conversion actions
    case "FETCH_CONVERSION_REQUEST":
      return {
        ...state,
        conversion: {
          ...state.conversion,
          loading: true,
          error: null,
        },
      };
    case "FETCH_CONVERSION_SUCCESS":
      return {
        ...state,
        conversion: {
          ...state.conversion,
          loading: false,
          data: action.payload,
        },
      };
    case "FETCH_CONVERSION_FAILURE":
      return {
        ...state,
        conversion: {
          ...state.conversion,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default otcHistory;
