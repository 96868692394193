import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import TradeSlider from "./TradeSlider";

const TradeAreaCross2X = ({
    orderAction,
    orderActionType,
    selectedCoin,
    isModalOpen,
    setIsModalOpen,
}) => {
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState("");
    const [total, setTotal] = useState("");
    const [maxSize, setMaxSize] = useState(0);
    const [available, setAvailable] = useState(0);
    const [currentLoan, setCurrentLoan] = useState(0);
    const [value, setValue] = useState(0);
    useEffect(() => {
        setIsModalOpen(true);
    }, []);
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleQuantityChange = (e) => {
        const value = e.target.value;
        setQuantity(value);
        setTotal(value * price);
    };

    const selectedCoinName =
        selectedCoin.ticker.toUpperCase().replace("_", "/").split("/")[0] || "";

    return (
        <div className="trade-area-cross2x min-h-[50vh]">
            <div className="trade-area-cross2x__inner">
                <div className="flex items-center gap-2 cross-margin-selector">
                    <label
                        htmlFor="cross-margin-selector"
                        className="text-[12px] font-[500]"
                    >
                        Margin
                    </label>
                    <select
                        name="cross-margin-selector"
                        id="cross-margin-selector"
                        className="text-[12px] font-[500] min-w-[50px] dark:bg-[#101014]  dark:text-[#FFFFFF]"
                    >
                        <option value="1x" selected>
                            1x
                        </option>
                        <option value="2x">2x</option>
                        <option value="3x">3x</option>
                    </select>
                </div>

                <div className="flex flex-col gap-4 cross-margin__field">
                    {/* Order Price */}
                    <div className="flex flex-col gap-1">
                        <label
                            className="w-max text-[14px] text-[#15141580]   dark:text-[#71777A]"
                            htmlFor="order-price"
                        >
                            Order Price
                        </label>
                        <div className="flex items-center justify-between gap-1 px-2 border rounded-md dark:bg-[#25282C] dark:border-transparent">
                            <input
                                type="number"
                                id="order-price"
                                name="order-price"
                                placeholder="Enter Price"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                className="w-full px-2 py-1.5 text-[12px] placeholder:text-[11px] bg-transparent"
                            />
                            <span className="text-[12px]">USDT</span>
                        </div>
                    </div>

                    {/* Quantity */}
                    <div className="flex flex-col gap-1 ">
                        <label
                            className="w-max text-[14px] text-[#15141580]  dark:text-[#71777A]"
                            htmlFor="quantity"
                        >
                            Quantity
                        </label>
                        <input
                            type="number"
                            id="quantity"
                            name="quantity"
                            placeholder="Enter Quantity"
                            value={quantity}
                            onChange={handleQuantityChange}
                            className="w-full px-2 py-1.5 text-[12px] placeholder:text-[11px] border rounded-md bg-transparent dark:bg-[#25282C] dark:border-transparent"
                        />
                    </div>

                    {/* Range Selector Slider */}
                    <div className="p-2 mb-6 mt-2">
                        <TradeSlider />
                    </div>
                    {/* <div className="flex flex-col">
                        <input
                            id="range-slider"
                            name="range-slider"
                            type="range"
                            value={value}
                            min="0"
                            max="100"
                            step="25"
                            onChange={handleChange}
                            className="w-full dark:bg-[#101014]  dark:text-[#FFFFFF]"
                        />
                        <div className="relative w-full">
                            <span className="text-[11px] text-[#15141580] dark:text-[#71777A]  font-bold absolute left-0 -bottom-4">
                                0%
                            </span>
                            <span className="text-[11px] text-[#15141580] dark:text-[#71777A] font-bold absolute left-1/4 transform -translate-x-1/2 -bottom-4">
                                25%
                            </span>
                            <span className="text-[11px] text-[#15141580] dark:text-[#71777A]font-bold absolute left-1/2 transform -translate-x-1/2 -bottom-4">
                                50%
                            </span>
                            <span className="text-[11px] text-[#15141580] dark:text-[#71777A]font-bold absolute left-3/4 transform -translate-x-1/2 -bottom-4">
                                75%
                            </span>
                            <span className="text-[11px] text-[#15141580] dark:text-[#71777A] font-bold absolute left-full transform -translate-x-full -bottom-4">
                                100%
                            </span>
                        </div>
                    </div> */}

                    {/* Total price section */}
                    <div className="flex flex-col gap-1 mt-2">
                        <label
                            className="w-max text-[14px] text-[#15141580] dark:text-[#71777A]"
                            htmlFor="total"
                        >
                            Total
                        </label>
                        <div className="flex items-center justify-between w-full gap-1 px-2 overflow-hidden border rounded-md dark:bg-[#25282C] dark:border-transparent">
                            <input
                                type="number"
                                id="total"
                                name="total"
                                value={total}
                                readOnly
                                className="py-1.5 text-[12px] placeholder:text-[11px] bg-transparent"
                            />
                            <span className="text-[11px] font-[bold] text-[#15141590] dark:text-[#FFFFFF]">
                                USDT
                            </span>
                        </div>
                    </div>
                    {/* Buttons to submit order based on Order action type (BUY or SELL) */}
                    <div className="py-6">
                        <button
                            type="button"
                            className={`py-2 px-4 w-full rounded-md dark:text-[#fff] ${
                                orderAction === "Buy"
                                    ? "bg-[#20B26C]"
                                    : "bg-[#EF454A]"
                            }`}
                        >
                            <span className="text-[14px] font-bold">
                                {orderAction === "Buy"
                                    ? `Buy/Long ${selectedCoinName} `
                                    : `Sell/Short ${selectedCoinName}`}
                            </span>
                        </button>
                    </div>
                    {/* Maximum, Available and Current Loan status */}
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between w-full gap-2">
                            <span className="text-[12px] text-[#15141580] dark:text-[#71777A] font-[500]">
                                Max Size:
                            </span>
                            <div className="flex items-center gap-1">
                                <span className="text-[12px] text-[#15141580] dark:text-[#71777A]">
                                    {maxSize.toFixed(4)}
                                </span>
                                <span className="text-[12px] text-[#15141580]dark:text-[#FFFFFF]">
                                    USDT
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-between w-full gap-2">
                            <span className="text-[12px] text-[#15141580] font-[500] dark:text-[#71777A]">
                                Available:
                            </span>
                            <div className="flex items-center gap-1">
                                <span className="text-[12px] text-[#15141580]">
                                    {available.toFixed(4)}
                                </span>
                                <span className="text-[12px] text-[#15141580] dark:text-[#71777A]">
                                    USDT
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-between w-full gap-2">
                            <span className="text-[12px] text-[#15141580] dark:text-[#71777A] font-[500]">
                                Current Loan:
                            </span>
                            <div className="flex items-center gap-1">
                                <span className="text-[12px] text-[#15141580]">
                                    {currentLoan.toFixed(4)}
                                </span>
                                <span className="text-[12px] text-[#15141580] dark:text-[#71777A]">
                                    USDT
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="model dark:bg-[#101014] dark:text-[#FFFFFF]">
                <Modal
                    title="Cross Margin Trading Service Agreement"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    className="convert-model dark:bg-[#101014] dark:text-[#FFFFFF]"
                >
                    <div className="">
                        <div className="mt-4 text-gray-500 dark:bg-[#101014] dark:text-[#71777A] text-xs h-[50vh] overflow-y-scroll">
                            Leveraged trading is highly risky, only suitable for
                            professional investment institutions or experienced
                            investors, or those who can adequately understand
                            all risks related to leveraged trading and can
                            assume some or all losses of account assets due to
                            investment blunders. When engaged in a leveraged
                            borrowing and trading on our website, you are
                            advised to manage risks to your ability; evaluate
                            both the investment value and risks of digital
                            assets; and bear the potential financial risks in
                            the event of a full investment loss. You should
                            trade based on your financial conditions and risk
                            tolerance, and be well aware of the investment risks
                            that digital assets possess. When you make a
                            leveraged investment in digital assets, there is a
                            possibility of either profit or loss. For your
                            better understanding of the risks involved, in
                            accordance with relevant laws, regulations,
                            administrative rules and national policies, we
                            hereby state the risks in leveraged trading, so do
                            read them carefully and thoroughly. The risks of
                            your leveraged trading with us include but are not
                            limited to: 1. Risks associated with digital assets
                            The price and value of any investment in digital
                            asset products and the earnings (if any) derived
                            therefrom may fluctuate and may be detrimental to
                            your interests. An individual digital asset may
                            decline in price and may even, under some
                            circumstances, lose its value. An inherent risk of
                            trading or investing in digital assets is that it
                            may incur losses instead of making profits, as a
                            result of buying and selling such products. Digital
                            assets are not legal tender backed by governments or
                            by commodities such as gold or silver. There is no
                            central bank that can take corrective measures to
                            protect the value of Bitcoins and/or Ethereum and/or
                            any other digital assets (each a "Digital Asset") in
                            a crisis, or issue more Digital Assets. Digital
                            Assets are considered autonomous and are largely
                            unregulated worldwide. Traders put their trust in a
                            digital, decentralized and partially anonymous
                            system that relies on peer-to-peer networking and
                            cryptography to maintain its integrity. Digital
                            Assets trading is susceptible to irrational (or
                            rational) bubbles or loss of confidence, which could
                            lead to a breakdown in either demand or supply. For
                            example, confidence in Bitcoin may collapse due to
                            unexpected changes imposed by software developers or
                            others, government crackdown, enhanced creations
                            that replace cryptocurrencies, or a deflationary or
                            inflationary spiral. Confidence may also collapse if
                            the anonymity of the system is compromised; or if
                            any fund has been stolen or lost; or if any hackers
                            or government are able to prevent any trading from
                            settling. Digital Assets deposited into your account
                            do not constitute deposits under the maxiumu
                            permitted extent under applicable laws, rules or
                            regulations of your respective jurisdiction. Digital
                            assets in your account are not covered by applicable
                            deposit insurance. 2. Risks associated with
                            derivatives, margin and borrowing of digital assets
                            Derivatives carry a high degree of risk because they
                            are generally “leveraged” or “geared” against a
                            larger collateral for a smaller trade with higher
                            returns. Therefore, a relatively small price change
                            of underlying Digital Assets will have a larger
                            impact on the value of products, which may work for
                            you as well as against you. You may sustain
                            substantial losses if market conditions move against
                            your positions. Depending on the extent of losses,
                            investors like yourself may need to increase margin
                            or reduce their positions, otherwise their positions
                            (and their collaterals therein) may have to be
                            liquidated, and you will bear all the losses caused
                            therefrom. The risk of loss may be significant if
                            you deposit collaterals as a form of trade
                            financing. You may sustain losses in excess of your
                            digital assets deposited as collateral with the
                            firm. You may be called upon at short notice to make
                            additional margin deposits or interest payments. If
                            the required margin deposits or interest payments
                            are not made within the prescribed time, your
                            collateral or position may be liquidated without
                            prior notice. Therefore, you should carefully
                            consider whether such financing arrangement is
                            suitable in light of your financial condition,
                            position and investment objectives. If you are
                            borrowing digital assets from the firm, you are to
                            deposit collaterals of the required level. You may
                            be called upon at short notice to place additional
                            deposits if the level of collateral is inadequate in
                            relation to the market value of the borrowed assets.
                            If the required additional digital assets are not
                            made within the prescribed time, the firm may
                            buy-back the borrowed digital assets without prior
                            notice. If you lend a digital asset to the firm, you
                            shall temporarily lose legal ownership and title
                            over the lent asset. 3. Other Risks Legislative and
                            regulatory changes or actions at the state, federal,
                            or international level may adversely affect the
                            utilization, transfer, exchange, and value of
                            Digital Assets. Neither the firm nor the platform is
                            registered as a broker or dealer, modification of
                            national securities, service provider for capital
                            markets, or alternative trading system in any
                            jurisdiction. The transaction and settlement of
                            trading related to digital assets are not supervised
                            by any government body or self-regulator. The
                            platform or website may become unavailable due to
                            changes in applicable law, and you may suffer losses
                            or be liable due to the termination or suspension of
                            the platform, or any or all accounts. The firm’s
                            trading facilities are supported by computer-based
                            component systems for the order-routing, execution,
                            matching, registration or settling of trades. As
                            with all facilities and computer systems, client
                            will be exposed to risks associated with the system,
                            including the failure of technology, hardware and
                            software. Any system failure may cause improper
                            executuion of your orders or fail execution
                            entirely. You should also note that the Internet may
                            not be a completely reliable medium of transmission,
                            which may delay our provision of services. This risk
                            disclosure covered in the Agreement may not be
                            comprehensive for your purposes and may not disclose
                            all the risks of leveraged trading in Digital
                            Assets. Every market has its own risks, please
                            exercise caution when investing. Where you still
                            remain undercetain of the risks, you should seek an
                            indepdent and professional opnion to satisfy
                            yourself of the risks involved. Be sure to read the
                            Agreement carefully and thoroughly before utilizing
                            the leveraged trading services provided by the
                            Company. You shall be deemed to have read and agreed
                            to the provisions thereof upon utilizing the
                            service. The Company reserves the rights to amend
                            the contents hereof and all amendments shall be
                            informed to users by e-mail, but we are not obliged
                            to inform otherwise; and we reserve the right of
                            final interpretation of the contents hereof.
                        </div>
                        <div className="mt-4 text-center flex items-center justify-center text-sm font-bold bg-yellow-500 rounded-xl h-12">
                            Acknowledge
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default TradeAreaCross2X;
