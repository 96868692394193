import React, { useState,useEffect } from "react";
import Link from "antd/es/typography/Link";

import SinglePageTable from "./SinglePageTable";
import axios from 'axios';
import { useParams } from 'react-router-dom';

import CountdownTimer from "./CountdownTimer"
import { ImCross } from "react-icons/im";
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

const SingleTradeCard = () => {
         const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
   const { id,name } = useParams();
   const [showPopup,setShowPopup]=useState(false);

 const togglePopup=()=>{
     setShowPopup(!showPopup);
    
 }



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.buycex.com/issue/index');
        const allData = response.data.data.list_jinxing;

        // Filter data based on id and name
        const filteredData = allData.find(item => item.id === id && item.name === name);
        
        setData(filteredData);
        
        
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };
        
    fetchData();
  }, []);
    return (
        <>
                <div className="bg-[#F9FAFB] relative">
             <div style={{backgroundImage:"url(//s1.bycsi.com/bybit/deadpool/image-eef9085dbc5a4431a805551046944c43.png)", backgroundPosition:"50%"}} className="premarketcontainer bg-[#040404] bg-cover !text-white pt-10">
            

                 <div className="container">
                 <div className="flex items-center gap-[6px] text-[14px]">
                        <div className="flex items-center gap-[6px] text-[#81858c] font-medium">
                            <Link to="/trade/pre-market">
                                {" "}
                                <span className="text-[#81858c]">
                                    Pre-Market Spot 
                                </span>{" "}
                            </Link>
                            <span className="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    class="arrowchevron_right_icon_svg__icon"
                                    viewBox="0 0 1024 1024"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M353.835 225.835a42.667 42.667 0 0 1 60.33 0l256 256a42.667 42.667 0 0 1 0 60.33l-256 256a42.667 42.667 0 0 1-60.33-60.33L579.669 512 353.835 286.165a42.667 42.667 0 0 1 0-60.33z"
                                    ></path>
                                </svg>
                            </span>
                        </div>
                        <div className="font-medium">Details</div>
                    </div>
                    <div className="flex justify-between pt-[48px] relative">
                        <div className="flex items-start w-[100%] flex-col">
                            <div className="flex items-start justify-start gap-[16px]">
                                <div className="w-[78px] h-[78px] flex items-center justify-center rounded-full bg-[#1e2023] border-[1px] border-[#404347]">
                                    <img
                                        src={`https://api.buycex.com/Upload/issue/${data.image}`}
                                        alt=""
                                        className="w-[60px] h-[60px] rounded-full object-cover"
                                    />
                                </div>
                                <div>
                                    <h1 className="font-semibold text-[32px] leading-tight">
                                       {data.name}
                                    </h1>
                                    <p className=" text-[12px] text-[#81858c]">
                                        {data.coinname}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-[16px] text-[12px] text-[#adb1b8]">
                                {data.content ? (<>{data.content}</>):(<>A short description about The coin, Its just a dummy text bcz currently this coin have no description.</>)}
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-col w-[540px] px-[24px] pt-[32px] pb-[24px] items-start border-[1px] border-[#25282c] relative -mt-[18px] bg-[#C0D2E71F] rounded-[16px]">
                                <div>
                                    <div className="flex absolute right-0 top-0 px-[10px] py-[5px] gap-[6px] items-center justify-center text-[12px] font-medium leading-[18px]  text-[#20b26c] bg-[#20B26C33] rounded-bl-[16px] rounded-tr-[16px]">
                                        <img
                                            src="//fh-static.bycsi.com/icon_check_circle-8f39aef2a7bbc92995cfbf876533b0dc.svg"
                                            alt=""
                                            className="w-[14px] h-[14px] "
                                        />
                                        {data.zhuangtai}
                                    </div>
                                </div>
                                <div className="flex items-start justify-between w-[100%]">
                                    <h1 className="text-[#81858c] text-[14px]">Total Supply</h1>
                                    <h1 className="text-[14px]">{data.num}</h1>
                                </div>
                                <div className="flex items-start justify-between w-[100%] mt-[12px]">
                                    <h1 className="text-[#81858c] text-[14px]">Total Subscribed </h1>
                                    <h1 className="text-[14px]">{data.deal}</h1>
                                </div>
                                <div className="flex items-start justify-between w-[100%] mt-[12px]">
                                    <h1 className="text-[#81858c] text-[14px]">ICO Price </h1>
                                    <h1 className="text-[14px]">{data.price} </h1>
                                </div>
                                <div className="flex items-start justify-between w-[100%] mt-[12px]">
                                    <h1 className="text-[#81858c] text-[14px]">ICO Limit </h1>
                                    <h1 className="text-[14px]">{data.limit}</h1>
                                </div>
                                <div className="flex items-start justify-between w-[100%] mt-[12px]">
                                    <h1 className="text-[#81858c] text-[14px]">Starting Time</h1>
                                    <h1 className="text-[14px]">{data.beginTime}</h1>
                                </div>
                                <div className="flex items-start justify-between w-[100%] mt-[12px]">
                                    <h1 className="text-[#81858c] text-[14px]"> End Time </h1>
                                     <h1 className="text-[14px]">{data.endTime}</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                 </div>
             </div>
            <div className=" premarketcontainer">
                <div className="container ">
                    
                    {/* create order section  */}
                    <div className="p-[32px]  rounded-[16px] mt-[24px] flex items-center justify-between bg-[#fff] shadow-lg   relative">
                         <div className="flex items-start justify-start gap-[16px]">
                                <div className="w-[78px] h-[78px] flex items-center justify-center rounded-full bg-[#81858c] border-[1px] border-[#81858c]">
                                    <img
                                        src={`https://api.buycex.com/Upload/issue/${data.image}`}
                                        alt=""
                                        className="w-[60px] h-[60px] rounded-full object-cover"
                                    />
                                </div>
                                <div>
                                    <h1 className="font-semibold text-[32px] leading-tight">
                                       {data.name}
                                    </h1>
                                    <p className=" text-[12px] text-[#81858c]">
                                        {data.coinname}
                                    </p>
                                </div>
                            </div>
                        <div>
                            <div className="text-[#81858c] text-[12px] leading-[18px]">Price</div>
                            <div className="text-[18px] font-medium leading-[26px] mt-[4px]">{data.price}</div>
                        </div>
                        <div>
                            <div className="text-[#81858c] text-center text-[12px] leading-[18px]">Countdown</div>
                            <CountdownTimer endtime={data.endtime}/>
                        </div>
                       
                        <div>
                            
                            <button onClick={togglePopup} className="text-sm font-semibold py-[8px] px-[16px] bg-[#f7a600] rounded-lg">Create Order</button>
                            
                            
                            
                            <NavLink to="/user/spot-order/order-history">
                            <button className="ml-[16px] text-[#d6850d] text-sm font-semibold px-[16px] py-[8px] border-[1px] border-[#d6850d] rounded-lg">My Orders</button>
                            </NavLink>
                        </div>
                    </div>


                    {/* main table  */}
                    <SinglePageTable />
                </div>
            </div>
        
            <div className='bg-gray-200  text-black py-3 px-3 lg:px-8 flex items-center justify-between'>
         <div className='flex md:items-center flex-col md:flex-row  gap-1 md:gap-4 lg:gap-10'>
                 <div className='flex  gap-2 lg:gap-4 items-center'>
                   <button className='text-xs  font-semibold pr-1 md:pr-2 lg:pr-4  border-r border-black'>Market Overview</button>
                   <button className='text-xs  font-semibold pr-1 md:pr-2 lg:pr-4  border-r border-black'>Trading Free</button>
                   <button className='text-xs  font-semibold pr-1 md:pr-2 lg:pr-4 border-r border-black'>API</button>
                   <button className='text-xs  font-semibold pr-1 md:pr-2 lg:pr-4 '>Help Center</button>
                 </div>
                 <p className='text-xs  text-[#82868a] font-semibold'>© 2018-2024 Bybit.com. All rights reserved.</p>
         </div>
         <button className='text-[#82868a] font-semibold text-[9px] md:text-xs'>Routing Auto</button>
       </div>
             {/* showing subscribe popup  */}
       {showPopup && (
           <div className="fixed bg-white bg-opacity-30 top-0 left-0  w-screen h-screen flex items-center justify-center ">
                <div className="bg-white rounded-xl shadow-lg p-10 relative">
                <button onClick={togglePopup} className="top-4 right-4 absolute"><ImCross /></button>
                <h2 className="font-semibold text-2xl text-center mb-4">Countdown</h2>
                <CountdownTimer endtime={data.endtime}/>
              
                <div className="px-[10px] bg-[#f3f5f7] shadow-lg border-[1px] border-[#82868a] rounded-sm flex items-center h-[40px] overflow-hidden mt-4 w-80">
                                    <input
                                        type="text"
                                        className="bg-[#f3f5f7] w-full outline-none text-[14px]"
                                        placeholder="Number of ICO"
                                    />
                                </div>
                                 <div className="px-[10px] bg-[#f3f5f7] shadow-lg border-[1px] border-[#82868a] rounded-sm flex items-center h-[40px] overflow-hidden mt-4 w-80">
                                    <input
                                        type="text"
                                        className="bg-[#f3f5f7] w-full outline-none text-[14px]"
                                        placeholder="Fund Password"
                                    />
                                </div>
                                <p className=" text-right mt-2 text-yellow-600">!forget my password?</p>
                                 <div className="px-[10px]  shadow-lg border-[1px] border-yellow-600 rounded-sm flex items-center h-[40px] overflow-hidden mt-4 w-80">
                                    
                                    <p className="font-semibold text-black w-full  text-[14px]">
                                    1 CVM = USDT [Balance:0.00172797 USDT]
                                    </p>
                                </div>
                                <button className="px-[10px] bg-yellow-600 shadow-lg  rounded-sm flex items-center h-[40px] overflow-hidden mt-4 w-80">
                                    
                                    <p className="text-white font-bold text-center w-full  text-[16px]">Subscribe now</p>
                                </button>
                </div>
       </div>
       )}
        </div>

           {loading && (
                   <div className="text-center text-green-600 p-20 text-2xl min-h-screen">Loading...</div>
                 )}
  {error && (
    <div className="text-center text-red-600 p-20 text-2xl min-h-screen">{error}</div>
  )}
        </>
    );
};

export default SingleTradeCard;