import React,{useState} from 'react'

import ResetConfirmation from "./ResetConfirmation";
import SecurityAuthentication from "./SecurityAuthentication";
import IdentityVerification from "./IdentityVerification";
import SubmitSuccessfully from "./SubmitSuccessfully";
import { GoFileSubmodule } from "react-icons/go";
import ProgressBar from "./ProgressBar"

const ResetWithDrawPassword = () => {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <ResetConfirmation nextStep={nextStep} />;
      case 2:
        return <SecurityAuthentication nextStep={nextStep}  />;
      case 3:
        return <IdentityVerification nextStep={nextStep}  />;
        // return <ChangePassword nextStep={nextStep}  />;
      case 4:
        return <SubmitSuccessfully  />;
      default:
        return <ResetConfirmation nextStep={nextStep} />;
    }
  };
  return (
    <div className="">
     <h1 className="mt-10 font-semibold flex items-center justify-center gap-2 text-xl"> <GoFileSubmodule className="text-3xl text-yellow-500" /> Reset Withdrawal Password</h1>
     <ProgressBar step={step} />
     <div>{renderStep()}</div>
    </div>
  )
}

export default ResetWithDrawPassword