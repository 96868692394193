import { UP_TRADE_REQUEST, UP_TRADE_SUCCESS, UP_TRADE_FAILURE } from '../types';

const initialState = {
  upTradeData: null,
  upTradeLoading: false,
  upTradeError: null,
};

const buySellReducer = (state = initialState, action) => {
  switch (action.type) {
    case UP_TRADE_REQUEST:
      return { ...state, upTradeLoading: true, upTradeError: null };
    case UP_TRADE_SUCCESS:
      return { ...state, upTradeLoading: false, upTradeData: action.payload };
    case UP_TRADE_FAILURE:
      return { ...state, upTradeLoading: false, upTradeError: action.payload };
    default:
      return state;
  }
};

export default buySellReducer;
