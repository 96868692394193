import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
const TableFunding = ({ assetsData, loading, error }) => {
  const [isFilter, setIsFilter] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (assetsData && (assetsData.cryptoList || assetsData.fiatList)) {
      if (isFilter && assetsData.cryptoList) {
        const coinList = Object.values(assetsData.cryptoList).map(
          (coin, index) => ({
            id: index,
            icon: coin.img || "bitcoin",
            coinName: coin.name.toUpperCase(),
            coinType: coin.title,
            amount: parseFloat(coin.xnbz),
            coinPrice: coin.jia,
            frozen: coin.xnbz,
            value: coin.xnbz,
          })
        );
        setTableData(coinList);
      }
      else if(assetsData.fiatList) {
        const fiatList = Object.values(assetsData.fiatList).map(
          (coin, index) => ({
            id: index,
            icon: coin.img || "bitcoin",
            coinName: coin.name.toUpperCase(),
            coinType: coin.title,
            amount: parseFloat(coin.xnbz),
            coinPrice: coin.jia,
            frozen: coin.xnbz,
            value: coin.xnbz,
          })
        );
        setTableData(fiatList);
      }
    }
  }, [isFilter, assetsData]);

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedData = [...tableData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setTableData(sortedData);
    setSortConfig({ key, direction });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = tableData.filter(
    (data) =>
      data.coinName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data.coinType.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg text-red-500">Error: {error}</div>
      </div>
    );
  }
  return (
    <div className="flex flex-col items-center w-full p-[28px] mt-5 bg-white border rounded-2xl">
      {/* Heading */}
      <div className="flex items-center justify-between w-full">
        <h6 className="text-[20px] font-medium">Funding</h6>
        <button
          type="button"
          className="px-3 py-1 text-[12px] rounded-xl bg-[#ededed] flex items-center gap-1"
        >
          View All <MdOutlineKeyboardArrowRight />
        </button>
      </div>

      {/* Search Bar */}
      <div className="flex items-center justify-end w-full mt-4">
        <div className="border">
          <input
            type="text"
            placeholder="Search"
            className="w-[155px] py-1 text-[#DBDBDB] text-[10px] px-2 bg-transparent outline-none placeholder-inherit"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
      </div>

      {/* Buttons */}
      <div className="flex w-full  justify-start mt-4 font-medium gap-x-6 text-[16px]">
        <button
          onClick={() => setIsFilter(true)}
          className={`${
            isFilter ? "border-[#c9c9c9]" : "border-transparent"
          } pb-1 text-[16px] border-b-4`}
        >
          Coins List
        </button>
        <button
          onClick={() => setIsFilter(false)}
          className={`${
            isFilter ? "border-transparent" : "border-[#c9c9c9]"
          } pb-1 text-[16px] border-b-4`}
        >
            Fiat List
        </button>
      </div>

      {/* Main Table */}
      <table className="w-full table-container h-fit">
        {/* Table Headings */}
        <thead className="mb-1 border-b">
          <tr className="flex justify-between">
            <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-left">
              <div
                className="flex items-center gap-1 cursor-pointer"
                onClick={() => sortData("coinName")}
              >
                {" "}
                Coin
                <span className="relative flex text-[#aaaaaa] text-sm">
                  <span
                    className={`absolute right-0 top-1 ${
                      sortConfig.key === "coinName" &&
                      sortConfig.direction === "ascending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                  <span
                    className={`mt-[9px] ${
                      sortConfig.key === "coinName" &&
                      sortConfig.direction === "descending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </span>
              </div>
            </th>
            <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
              <div
                className="flex items-center justify-center gap-1 cursor-pointer"
                onClick={() => sortData("amount")}
              >
                {" "}
                Value
                <span className="relative flex text-[#aaaaaa] text-sm">
                  <span
                    className={`absolute right-0 top-1 ${
                      sortConfig.key === "amount" &&
                      sortConfig.direction === "ascending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                  <span
                    className={`mt-[9px] ${
                      sortConfig.key === "amount" &&
                      sortConfig.direction === "descending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </span>
              </div>
            </th>
            <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
              <div
                className="flex items-center justify-center gap-1 cursor-pointer"
                onClick={() => sortData("amount")}
              >
                {" "}
                Amount
                <span className="relative flex text-[#aaaaaa] text-sm">
                  <span
                    className={`absolute right-0 top-1 ${
                      sortConfig.key === "amount" &&
                      sortConfig.direction === "ascending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                  <span
                    className={`mt-[9px] ${
                      sortConfig.key === "amount" &&
                      sortConfig.direction === "descending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </span>
              </div>
            </th>
            <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
              <div
                className="flex items-center justify-center gap-1 cursor-pointer"
                onClick={() => sortData("coinPrice")}
              >
                {" "}
                Price
                <span className="relative flex text-[#aaaaaa] text-sm">
                  <span
                    className={`absolute right-0 top-1 ${
                      sortConfig.key === "coinPrice" &&
                      sortConfig.direction === "ascending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                  <span
                    className={`mt-[9px] ${
                      sortConfig.key === "coinPrice" &&
                      sortConfig.direction === "descending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </span>
              </div>
            </th>
            <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
              <div
                className="flex items-center justify-center gap-1 cursor-pointer"
                onClick={() => sortData("coinPrice")}
              >
                {" "}
                Frozen
                <span className="relative flex text-[#aaaaaa] text-sm">
                  <span
                    className={`absolute right-0 top-1 ${
                      sortConfig.key === "coinPrice" &&
                      sortConfig.direction === "ascending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                  <span
                    className={`mt-[9px] ${
                      sortConfig.key === "coinPrice" &&
                      sortConfig.direction === "descending"
                        ? "text-black"
                        : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                </span>
              </div>
            </th>
            <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-right">
              Action
            </th>
          </tr>
        </thead>

        {/* Table Data */}
        <tbody>
          {filteredData.map((data) => (
            <tr key={data.id} className="flex justify-between py-1">
              {/* For Coin Column */}
              <td className="flex flex-1 px-4 py-2 text-left gap-x-4">
                <img
                  src={`https://api.buycex.com/Upload/coin/${data.icon}`}
                  alt=""
                  className="w-10 h-10 rounded-full"
                />
                <div className="flex flex-col">
                  <span>{data.coinName}</span>
                  <span className="text-[12px] text-[#aaaaaa]">
                    {data.coinType}
                  </span>
                </div>
              </td>
              {/* For Amount Column */}
              <td className="flex-1 px-4 py-2 text-center">{data.amount}</td>
              {/* For Value Column */}
              <td className="flex-1 px-4 py-2 text-center">{data.value}</td>
              {/* For Price Column */}
              <td className="flex-1 px-4 py-2 text-center">
                ${data.coinPrice.toFixed(2)}
              </td>
              {/* For Frozen Column */}
              <td className="flex-1 px-4 py-2 text-center">{data.frozen}</td>

              {/* For Action Column */}
              <td className="px-4 py-2 text-right flex-2">
                <button className="text-green-400">Buy</button>{" "}
                <button className="text-red-500">Sell</button>{" "}
                <button className="text-yellow-500 ">Convert</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableFunding;
