import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { verifyG2faCode } from 'store/actions/authAction'; // Adjust the import path as needed
import G2faVerification from "../.././SecuritSettingsComponents/helpers/G2faVerify";
import { FaCircleQuestion } from "react-icons/fa6";
import { Link } from "react-router-dom";



const TwoFA = ({ nextStep }) => {
  
  const username = useSelector((state) => state.authUser?.user?.username); 
  const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;
    const { authStatus, authStatusError } = useSelector(
        (state) => state.authCheck
    );
    const email = authStatus?.email;
 

 

  return (
    <div className=" bg-white h-max text-[#000] w-full"> 
       <G2faVerification onSuccess={nextStep}
                        emailasprop={email} userId={userId}
                            token={token}
                            type="update" />
        <div className="text-center">
        <Link to="/user/account/security" className="text-yellow-500 mt-3 ">Security Authentication unavailable?</Link>
        </div>
      
    </div>
  );
};

export default TwoFA;
