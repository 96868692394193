import React, { useEffect, useState } from "react";
import TableFunding from "../tables/TableFunding";

import SubHeader from "../SubHeader";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import fetchAssetsData from "store/actions/AssetsUserAction";
import Balance from "../Balance";
import { GrOverview } from "react-icons/gr";
import { RiRefundFill } from "react-icons/ri";
import { IoEye } from "react-icons/io5";
import { FaRegQuestionCircle } from "react-icons/fa";

const Funding = () => {
  const dispatch = useDispatch();
  const [hideAssets, sethideAssets] = useState(false);
  const [activeTab, setActiveTab] = useState('p2p'); // defualt data

  const { loading, assetsData, error } = useSelector(
    (state) => state.assetsData
  );
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  const history = useHistory();
  useEffect(() => {
    dispatch(fetchAssetsData(userID, userToken, activeTab));
  }, [dispatch, userID, userToken, history, activeTab]);

  useEffect(() => {
    console.log("funding data-check ", assetsData);
    console.log("funding data-type-check ", assetsData.type);
  }, [assetsData]);
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg text-red-500">Error: {error}</div>
      </div>
    );
  }
  return (
    <>
      <div className="relative flex items-center gap-x-3 bottom-3">
        <RiRefundFill size={26} />
      
      </div>
      {/* Estimated Balance Container*/}
      <div className="w-full p-4 border-[1px] rounded-2xl bg-white">
        {/* Headings */}
        <div className="flex items-center justify-between w-full">
          <div className="text-[16px] flex items-center gap-x-2 font-semibold">
            <h6>Estimated Balance</h6>
          </div>
        </div>

        {/* Price */}
        <div className="flex items-center my-2 gap-x-3">
          <div className="text-[24px] font-bold">
            {hideAssets ? "****" : parseFloat(assetsData.conv_total).toFixed(2)}
          </div>
          <select
            name=""
            id=""
            className="bg-[#ededed] focus:outline-none rounded-md text-sm cursor-pointer px-1"
          >
            <option value={assetsData.conversion_coin}>
              {assetsData.conversion_coin}
            </option>
          </select>
        </div>

        {/* Total Value & buttons */}
        <div className="flex items-start justify-between">
          <div className="text-[16px] text-neutral-300 font-medium">
            = $
            {hideAssets ? "****" : parseFloat(assetsData?.usd?.zj).toFixed(2)}
            <div className="flex gap-2">
              <p className="text-[16px] text-neutral-300 flex items-center gap-1">
                Today's PnL <FaRegQuestionCircle />
              </p>
              <p className="text-[16px] dark:text-white text-neutral-900 font-semibold">
                {hideAssets
                  ? "****"
                  : `${parseFloat(assetsData?.usd?.ky).toFixed(
                    2
                  )} (${parseFloat(assetsData?.usd?.dj).toFixed(2)}%)`}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-x-3 text-[#EFB81C] ">
            <button className="bg-[#fdf5dd] hover:bg-[#EFB81C] hover:text-white px-3 py-2 rounded-[10px]">
              Deposit
            </button>
            <button className="bg-[#fdf5dd] hover:bg-[#EFB81C] hover:text-white px-3 py-2 rounded-[10px]">
              Withdraw
            </button>
            <button className="bg-[#fdf5dd] hover:bg-[#EFB81C] hover:text-white px-3 py-2 rounded-[10px]">
              Transfer
            </button>
            <button className="bg-[#fdf5dd] hover:bg-[#EFB81C] hover:text-white px-3 py-2 rounded-[10px]">
              Pay
            </button>
          </div>

        </div>
      </div>
      {/* tabs Buttons */}
      <div className="flex w-full justify-start mt-4 font-medium gap-x-6 text-[16px]">
        <button
          onClick={() => setActiveTab("spot")}
          className={`${activeTab === 'spot' ? "border-[#c9c9c9] !important" : "border-transparent"
            } pb-1 text-[16px] border-b-4`}
        >
          Spot
        </button>
        <button
          onClick={() => setActiveTab("p2p")}
          className={`${activeTab === 'p2p' ? " border-[#c9c9c9] !important" : "border-transparent"
            } pb-1 text-[16px] border-b-4`}
        >
          P2P
        </button>
        <button
          onClick={() => setActiveTab("staking")}
          className={`${activeTab === 'staking' ? "border-[#c9c9c9] !important" : "border-transparent"
            } pb-1 text-[16px] border-b-4`}
        >
          Stacking
        </button>
      </div>

      {/* Table */}
      <TableFunding loading={loading} error={error} assetsData={assetsData} />
    </>
  );
};

export default Funding;
