import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "../../../store/actions/tradeAction";
import { BsSun, BsMoon } from "react-icons/bs";

const DarkTheme = () => {
    const dispatch = useDispatch();
    const isDarkTheme = useSelector((state) => state.trade.isDarkTheme);

    const handleToggleTheme = () => {
        dispatch(toggleTheme());
    };
    return (
        <div>
            <button onClick={handleToggleTheme} className="flex items-center ">
                {isDarkTheme ? <BsSun /> : <BsMoon />}
            </button>
        </div>
    );
};

export default DarkTheme;
