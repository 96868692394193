import { 
    FETCH_BALANCE_TYPES_REQUEST, 
    FETCH_BALANCE_TYPES_SUCCESS, 
    FETCH_BALANCE_TYPES_FAILURE 
} from '../types';

const initialState = {
    loading: false,           // To track loading state
    balanceTypes: {},         // To store fetched balance types
    error: null               // To track any errors
};

const convertbalanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BALANCE_TYPES_REQUEST:
            return { 
                ...state, 
                loading: true, 
                error: null    // Reset error when a new request is made
            };
        
        case FETCH_BALANCE_TYPES_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                balanceTypes: action.payload,  // Payload contains the balance types
                error: null                    // Clear any previous errors on success
            };
        
        case FETCH_BALANCE_TYPES_FAILURE:
            return { 
                ...state, 
                loading: false, 
                error: action.payload.error || 'Failed to fetch balance types' // Ensure an error message is set
            };

        default:
            return state; // Return unchanged state for unrelated actions
    }
};

export default convertbalanceReducer;
