import Repository from "./Repository";
const CryptoDeposityHistoryEndPoint = "/Wallet/deposit_history";
const CryptoWithdrawHistoryEndPoint = "/Finance/coinoutLog";
const TransferHistoryEndPoint = "/Transfer/history"; 
const ConvertTradeHistoryEndPoint = "/Convert/tradeHistory";
export default {
    crypto_deposit_history(userId, token) {
        return Repository.get(`${CryptoDeposityHistoryEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },

    crypto_withdraw_history(userId, token) {
        return Repository.get(`${CryptoWithdrawHistoryEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
    transfer_history(userId, token) { // Add the new method
        return Repository.get(`${TransferHistoryEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },

    convert_trade_history(userId, token) { // Add the new method

        console.log("the end point of the coin", userId,token)
        return Repository.get(`${ConvertTradeHistoryEndPoint}`, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
};