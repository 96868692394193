import React, { useState } from "react";
import ShopCard from "./ShopCard";
import SideCategories from "./SideCategories";
import Mobile from "./mobile.avif";

const data = [
    {
        id: 1,
        title: "Universal Mobile",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 300,
        category: "Electronics",
        img: Mobile,
    },
    {
        id: 2,
        title: "OPPO A7",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 200,
        category: "Electronics",
        img: Mobile,
    },
    {
        id: 3,
        title: "SAMSUNG S7",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 800,
        category: "Electronics",
        img: Mobile,
    },
    {
        id: 4,
        title: "HP Core 2",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 900,
        category: "Computers",
        img: Mobile,
    },
    {
        id: 5,
        title: "DELL Core 2",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 400,
        category: "Computers",
        img: Mobile,
    },
    {
        id: 6,
        title: "HP Core 2",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 1200,
        category: "Computers",
        img: Mobile,
    },
    {
        id: 7,
        title: "HP Core 2",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 700,
        category: "Health",
        img: Mobile,
    },
    {
        id: 8,
        title: "HP Core 2",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 550,
        category: "Computers",
        img: Mobile,
    },
    {
        id: 9,
        title: "HP Core 2",
        desc: "Universal Mobile is a versatile solution for all your mobile needs, offering cutting-edge technology, user-friendly features, and seamless connectivity to keep you powered, connected, and productive wherever you go",
        price: 1100,
        category: "Computers",
        img: Mobile,
    },
];

const ShopCenter = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState(null);

    const filteredData = data.filter((item) => {
        const matchesSearch = item.title
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        const matchesCategory = selectedCategory
            ? item.category === selectedCategory
            : true;
        const matchesPrice = selectedPrice
            ? (selectedPrice === "Below $200" && item.price < 200) ||
              (selectedPrice === "Below $500" && item.price < 500) ||
              (selectedPrice === "Below $800" && item.price < 800) ||
              (selectedPrice === "Below $1000" && item.price < 1000) ||
              (selectedPrice === "Above $1000" && item.price > 1000)
            : true;
        return matchesSearch && matchesCategory && matchesPrice;
    });

    return (
        <div className="flex justify-start items-start gap-4 mt-16 mb-16 relative">
            <div className="w-60 sticky top-20 h-[80vh] p-6 sidebar-bg flex flex-col gap-4">
                <SideCategories
                    search
                    categories
                    price
                    onSearch={(value) => setSearchTerm(value)}
                    onCategoryChange={(value) => setSelectedCategory(value)}
                    onPriceChange={(value) => setSelectedPrice(value)}
                />
            </div>
            <div className="flex-1 flex items-center justify-center gap-10 flex-wrap">
                {filteredData.map((item) => (
                    <ShopCard key={item.id} item={item} />
                ))}
            </div>
        </div>
    );
};

export default ShopCenter;
