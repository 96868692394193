import * as types from '../types';
import competitionRepository from 'repository/competitionRepository';

// Fetch Actions
export const fetchCompetitionRequest = () => ({
  type: types.FETCH_COMPETITION_REQUEST,
});

export const fetchCompetitionSuccess = (data) => ({
  type: types.FETCH_COMPETITION_SUCCESS,
  payload: data,
});

export const fetchCompetitionFailure = (error) => ({
  type: types.FETCH_COMPETITION_FAILURE,
  payload: error,
});

// Fetch Competition Data
export const fetchCompetition = () => async (dispatch) => {
  dispatch(fetchCompetitionRequest());
  try {
    const data = await competitionRepository.getCompetitionData();
    console.log("Competition data", data.data);
    dispatch(fetchCompetitionSuccess(data.data));
  } catch (error) {
    dispatch(fetchCompetitionFailure(error.message));
  }
};

// Update Actions
export const updateCompetitionRequest = () => ({
  type: types.UPDATE_COMPETITION_REQUEST,
});

export const updateCompetitionSuccess = (data) => ({
  type: types.UPDATE_COMPETITION_SUCCESS,
  payload: data,
});

export const updateCompetitionFailure = (error) => ({
  type: types.UPDATE_COMPETITION_FAILURE,
  payload: error,
});

// Update Competition Data
export const updateCompetition = (competitionId, updatedData) => async (dispatch) => {
  dispatch(updateCompetitionRequest());
  try {
    const data = await competitionRepository.updateCompetitionData(competitionId, updatedData);
    dispatch(updateCompetitionSuccess(data));
  } catch (error) {
    dispatch(updateCompetitionFailure(error.message));
  }
};
