import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMarketData } from "store/actions/marketActions";
import { BsQuestionCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import "./MarketTopCoins.scss";

const MarketTopCoins = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const marketData = useSelector((state) => state.market.data?.market || []);
    const loading = useSelector((state) => state.market.loading);
    const [validIcons, setValidIcons] = useState({});

    useEffect(() => {
        dispatch(fetchMarketData());
    }, [dispatch]);

    // Function to check if an image URL is valid
    const checkIconValidity = (url) => {
        // Treat specific placeholder URLs as invalid
        const invalidUrls = ["https://api.buycex.com/Upload/coin/"];
        if (invalidUrls.includes(url)) {
            return Promise.resolve(false);
        }

        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    };

    useEffect(() => {
        // Check validity of each icon URL
        const validateIcons = async () => {
            const results = {};
            for (const coin of marketData) {
                if (coin.icon) {
                    results[coin.id] = await checkIconValidity(coin.icon);
                }
            }
            setValidIcons(results);
        };

        if (marketData.length > 0) {
            validateIcons();
        }
    }, [marketData]);

    const extractPair = (name) => {
        // Extract the pair symbol from the name
        const match = name.match(/\(([^)]+)\)/);
        return match ? match[1] : name; // Return the pair or the original name if not found
    };

    const formatPrice = (price) => {
        const numPrice = Number(price);
        return isNaN(numPrice) ? "N/A" : numPrice.toFixed(2);
    };

    // Top Gainers: Coins with the highest positive change
    const topGainers = marketData
        .filter((coin) => coin.change > 0)
        .sort((a, b) => b.change - a.change)
        .slice(0, 5);

    // New Listed: Based on the latest IDs
    const newListed = marketData
        .map((coin) => ({
            ...coin,
            id: Number(coin.id),
        }))
        .sort((a, b) => b.id - a.id) // Sort IDs in descending order
        .slice(0, 5) // Get the top 5 IDs
        .map((coin) => ({
            ...coin,
            id: coin.id.toString(), // Convert ID back to string if needed
        }));

    // Top Losers: Coins with the highest negative change
    const topLosers = marketData
        .filter((coin) => coin.change < 0)
        .sort((a, b) => a.change - b.change) // Sort ascending for losers
        .slice(0, 5); // Limit to top 5 losers

    if (loading) {
        return (
            <div className="min-h-[30vh] w-full text-center m-auto">
                Loading...
            </div>
        );
    }

    const handleMarketOverview = () => {
        history.push("/market/overview");
    };

    return (
        <section className="market-top-coins-section py-8">
            <div className="container pt-4 pb-8">
                <div className="market-top-coins-header flex items-center justify-between mb-6">
                    <h2 className="market-top-coins-header__title text-[34px] font-bold">
                        Market Overview
                    </h2>
                    <div className="w-max">
                        <button
                            onClick={handleMarketOverview}
                            className="px-5 py-2 text-[14px] font-[500] rounded-md hover:bg-[#EFB81C] border"
                        >
                            View All
                        </button>
                    </div>
                </div>
                <div className="market-top-coins-wrapper flex items-stretch justify-between gap-8">
                    {/* Top Gainers */}
                    <div className="market-top-category w-full h-auto">
                        <div className="category-wrapper flex flex-col min-h-[325px] shadow-card rounded-md p-4">
                            <h3 className="text-left text-[16px] font-bold mb-3">
                                Top Gainers
                            </h3>
                            <div className="flex items-center justify-center w-full overflow-hidden">
                                {topGainers.length > 0 ? (
                                    <table className="market-top-coins-table w-full">
                                        <thead>
                                            <tr>
                                                <th className="px-2 py-2 text-left text-[12px] font-medium">
                                                    Pairs
                                                </th>
                                                <th className="px-2 py-2 text-left text-[12px] font-medium">
                                                    Price
                                                </th>
                                                <th className="px-2 py-2 text-[12px] font-medium w-max text-right">
                                                    24H Change
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {topGainers.map((coin) => (
                                                <tr key={coin.id}>
                                                    <td className="px-2 py-1.5 text-left text-[14px] font-[500]">
                                                        <div className="flex items-center gap-2">
                                                            <div className="flex py-1.5">
                                                                {validIcons[
                                                                    coin.id
                                                                ] ? (
                                                                    <img
                                                                        src={
                                                                            coin.icon
                                                                        }
                                                                        alt={
                                                                            coin.ticker
                                                                        }
                                                                        className="coin-icon w-5 h-5 object-contain"
                                                                    />
                                                                ) : (
                                                                    <BsQuestionCircleFill
                                                                        className="w-5 h-5"
                                                                        fill="#15141550"
                                                                    />
                                                                )}
                                                            </div>
                                                            <span className="text-[14px] font-[500]">
                                                                {extractPair(
                                                                    coin.name
                                                                )}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="px-2 py-1.5 text-left text-[14px] font-[500]">
                                                        $
                                                        {formatPrice(
                                                            coin.new_price
                                                        )}
                                                    </td>
                                                    <td
                                                        className={`px-2 py-1.5 text-[14px] font-bold text-right ${
                                                            coin.change >= 0
                                                                ? "text-[#2ECC71]"
                                                                : "text-[#F65454]"
                                                        }`}
                                                    >
                                                        {coin.change.toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p className="m-auto">
                                        No <strong>Top Gainers</strong> data
                                        available
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* New Listed */}
                    <div className="market-top-category w-full h-auto">
                        <div className="category-wrapper flex flex-col min-h-[325px] shadow-card rounded-md p-4">
                            <h3 className="text-left text-[16px] font-bold mb-3">
                                Newly Listed
                            </h3>
                            <div className="flex items-center justify-center w-full overflow-hidden">
                                {newListed.length > 0 ? (
                                    <table className="market-top-coins-table w-full">
                                        <thead>
                                            <tr>
                                                <th className="px-2 py-2 text-left text-[12px] font-medium">
                                                    Pairs
                                                </th>
                                                <th className="px-2 py-2 text-left text-[12px] font-medium">
                                                    Price
                                                </th>
                                                <th className="px-2 py-2 text-[12px] font-medium w-max text-right">
                                                    24H Change
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {newListed.map((coin) => (
                                                <tr key={coin.id}>
                                                    <td className="px-2 py-1.5 text-left text-[14px] font-[500]">
                                                        <div className="flex items-center gap-2">
                                                            <div className="flex py-1.5">
                                                                {validIcons[
                                                                    coin.id
                                                                ] ? (
                                                                    <img
                                                                        src={
                                                                            coin.icon
                                                                        }
                                                                        alt={
                                                                            coin.ticker
                                                                        }
                                                                        className="coin-icon w-5 h-5 object-contain"
                                                                    />
                                                                ) : (
                                                                    <BsQuestionCircleFill
                                                                        className="w-5 h-5"
                                                                        fill="#15141550"
                                                                    />
                                                                )}
                                                            </div>
                                                            <span className="text-[14px] font-[500]">
                                                                {extractPair(
                                                                    coin.name
                                                                )}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="px-2 py-1.5 text-left text-[14px] font-[500]">
                                                        $
                                                        {formatPrice(
                                                            coin.new_price
                                                        )}
                                                    </td>
                                                    <td
                                                        className={`px-2 py-1.5 text-[14px] font-bold text-right ${
                                                            coin.change >= 0
                                                                ? "text-[#2ECC71]"
                                                                : "text-[#F65454]"
                                                        }`}
                                                    >
                                                        {coin.change.toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p className="m-auto">
                                        No <strong>New Listed</strong> coins
                                        data available
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Top Losers */}
                    <div className="market-top-category w-full h-auto">
                        <div className="category-wrapper flex flex-col min-h-[325px] shadow-card rounded-md p-4">
                            <h3 className="text-left text-[16px] font-bold mb-3">
                                Top Losers
                            </h3>
                            <div className="flex items-center justify-center w-full overflow-hidden">
                                {topLosers.length > 0 ? (
                                    <table className="market-top-coins-table w-full">
                                        <thead>
                                            <tr>
                                                <th className="px-2 py-2 text-left text-[12px] font-medium">
                                                    Pairs
                                                </th>
                                                <th className="px-2 py-2 text-left text-[12px] font-medium">
                                                    Price
                                                </th>
                                                <th className="px-2 py-2 text-[12px] font-medium w-max text-right">
                                                    24H Change
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {topLosers.map((coin) => (
                                                <tr key={coin.id}>
                                                    <td className="px-2 py-1.5 text-left text-[14px] font-[500]">
                                                        <div className="flex items-center gap-2">
                                                            <div className="flex py-1.5">
                                                                {validIcons[
                                                                    coin.id
                                                                ] ? (
                                                                    <img
                                                                        src={
                                                                            coin.icon
                                                                        }
                                                                        alt={
                                                                            coin.ticker
                                                                        }
                                                                        className="coin-icon w-5 h-5 object-contain"
                                                                    />
                                                                ) : (
                                                                    <BsQuestionCircleFill
                                                                        className="w-5 h-5"
                                                                        fill="#15141550"
                                                                    />
                                                                )}
                                                            </div>
                                                            <span className="text-[14px] font-[500]">
                                                                {extractPair(
                                                                    coin.name
                                                                )}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="px-2 py-1.5 text-left text-[14px] font-[500]">
                                                        $
                                                        {formatPrice(
                                                            coin.new_price
                                                        )}
                                                    </td>
                                                    <td
                                                        className={`px-2 py-1.5 text-[14px] font-bold text-right ${
                                                            coin.change >= 0
                                                                ? "text-[#2ECC71]"
                                                                : "text-[#F65454]"
                                                        }`}
                                                    >
                                                        {coin.change.toFixed(2)}
                                                        %
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p className="m-auto">
                                        No <strong>Top Losers</strong> data available
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MarketTopCoins;
