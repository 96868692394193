import React from 'react'
import NoData from "assets/img/user/No-data.svg";

function CommissionRebate() {
    return (
        <div>
            <p className='text-[#707a8a] text-sm'>* Commissions refer to the amount earned when your referred friends start trading on Buycex Spot, Futures, and Pool.</p>
            <div className="text-xs mt-12 text-[#707a8a] w-full overflow-x-auto">
                <div className="flex justify-between border-b pb-3 w-full">
                    <p className="flex-1 text-start">Order Type</p>
                    <p className="flex-1 text-start">Friend's User ID</p>
                    <p className="flex-1 text-start">Commission Earned</p>
                    <p className="flex-1 text-center">Friend's Trading Date</p>
                    <p className="flex-1 text-center">Commission Date</p>
                    <p className="flex-1 text-center">Distribution Status</p>
                </div>
                <div className="w-full flex justify-center items-center h-96">
                    <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                        <img src={NoData} alt="No Data" />
                        <p className='text-[#707a8a] text-sm'>No records</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommissionRebate