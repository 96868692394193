import React from 'react';

const ListingNewsFilter = ({ newsData, currentCategory, onFilterSelect, selectedFilter }) => {
  const uniqueListingTypes = Array.from(new Set(newsData
    .filter(news => news.category === 'new-listings')
    .map(news => news.listingType)
    .filter(Boolean)
  ));

  const filterOptions = [
    { text: 'All Listings', value: 'all-listings' },
    ...uniqueListingTypes.map(type => ({
      text: type.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
      value: type
    }))
  ];

  const handleFilterSelect = (filter) => {
    onFilterSelect(filter);
  };

  return (
    <div className="flex gap-2 items-center p-3 mb-4 overflow-x-auto whitespace-nowrap">
      {filterOptions.map((option, index) => (
        <button
          key={index}
          onClick={() => handleFilterSelect(option.value)}
          className={`text-xs px-3 py-1 rounded-md border ${selectedFilter === option.value ? 'border-yellow-500 text-yellow-500' :  ' border-gray-300 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800'}`}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
};

export default ListingNewsFilter;
