import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import { BiSolidCopy } from "react-icons/bi";
import { get2FA } from 'store/actions/userSecuritySettingsAction';
import { toast } from 'react-hot-toast';

const QRCodeDisplay = ({ userId, token }) => {
    const dispatch = useDispatch();
    const get2FAState = useSelector(state => state.get2FAQR);
    const { data: get2FAData, error: get2FAError } = get2FAState;

    // const dispatch = useDispatch();
    // const state = useSelector((state) => state);
    // console.log(state)
    // console.log(state.get2FAQR.data.Asecret)

    useEffect(() => {
        dispatch(get2FA(userId, token));
    }, [dispatch, token, userId]);


    useEffect(() => {
        if (get2FAError) {
            console.error("Error fetching 2FA data:", get2FAError);
        }
    }, [get2FAError]);

    useEffect(() => {
        if (get2FAData && get2FAData.Asecret) {
            sessionStorage.removeItem('secret');
            localStorage.removeItem('secret');

            sessionStorage.setItem('secret', get2FAData.Asecret);
            localStorage.setItem('secret', get2FAData.Asecret);
        }
    }, [get2FAData]);

    return (
        <>
            {get2FAData ? (
                <div className="flex flex-col items-center w-full">
                    <QRCode className='border p-3 rounded-xl' value={get2FAData.qrCodeUrl} size={160} />
                    <div className="flex justify-between pl-2 items-center py-1 gap-2 mt-5 mx-16 my-1 rounded-lg">
                        <p className="text-sm font-medium">{get2FAData.Asecret}</p>
                        <BiSolidCopy
                            className="text-sm mr-2 cursor-pointer"
                            onClick={() => {
                                navigator.clipboard.writeText(get2FAData.Asecret);
                                toast.success("Code copied to clipboard");
                            }}
                        />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default QRCodeDisplay;