import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import icon from "assets/img/icon.svg";
import Mask from "assets/img/MaskID.svg";
import AdvanceCompleteVerification from "./AdvanceCompleteVerification";
import {
    showbasicVerification,
    submitkyc,
} from "store/actions/idVerificationAction";
import { fetchUserProfile } from "store/actions/userProfileAction";
import toast from "react-hot-toast";

const AdvancedVerificationForm = () => {
    const dispatch = useDispatch();
    const [next, setNext] = useState(1);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const approved = useSelector((state) => state.userInfo.userProfile.kyc != 0);

    const [country, setCountry] = useState('');
    const [idcard, setIdcard] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    // const [idType, setIdType] = useState('');
    
    const [frontPhoto, setFrontPhoto] = useState('');
    const [backPhoto, setBackPhoto] = useState('');

    const [addressPhoto, setAddressPhoto] = useState(null);
    const [address, setAddress] = useState("");
    const [selfiePhoto, setSelfiePhoto] = useState(null);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

    const [addressPhotoPreview, setAddressPhotoPreview] = useState(null);
    const [selfiePhotoPreview, setSelfiePhotoPreview] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (userID && userToken) {
                dispatch(showbasicVerification(userID, userToken, async (response) => {
                    if (response) {
                        console.log("Response from showbasicVerification:", response.status);
                        if (response.status === 1) {
                            const { country = '', idcard = '', firstname = '', lastname = '', frontphoto = '', backphoto = '' } = response.data || {};
                            setCountry(country);
                            setIdcard(idcard);
                            setFirstName(firstname);
                            setLastName(lastname); 
                            setFrontPhoto(frontphoto); 
                            setBackPhoto(backphoto);
                        } else {
                            console.error("No response received or an error occurred.");
                        }
                    }
                    dispatch(fetchUserProfile(userID, userToken));
                }));
            } else {
                console.warn("userID or userToken is not available.");
            }
        };
        fetchData();
    }, [userID, userToken, dispatch]);

    useEffect(() => {
        const isFormComplete = addressPhoto && selfiePhoto && address;
        setIsSubmitEnabled(isFormComplete);
    }, [addressPhoto, selfiePhoto,address]);

    const handleNext = async () => {
        const formData = new FormData();
        const fullName = `${firstName} ${lastName}`;

        const frontPhotoFileName = frontPhoto ? frontPhoto.split('/').pop() : "placeholder.jpg";
        const backPhotoFileName = backPhoto ? backPhoto.split('/').pop() : "placeholder.jpg";

        formData.append("frontphoto", frontPhotoFileName);
        formData.append("backphoto", backPhotoFileName);
        formData.append("addressphoto", addressPhoto);
        formData.append("selfiephoto", selfiePhoto);
        formData.append("address", address);
        formData.append("idcard", idcard);
        formData.append("truename", fullName);
    
        dispatch(submitkyc(formData, userID, userToken)).then((response) => {
            if (response && response.data && response.data.status === 1) {
                console.log("KYC Response:", response.data); // Log to confirm structure
                setNext(2); // Move to the next step only if submission is successful
            } else {
                console.error("KYC Submission failed:", response);
                toast.error("Submission failed. Please try again.");
            }
        }).catch(error => {
            console.error("Submission error:", error);
            toast.error("An error occurred during submission.");
        });   
    };    
    
    const handleAddressPhotoChange = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
        const maxSize = 5 * 1024 * 1024; // 5 MB
    
        if (file) {
            if (!allowedTypes.includes(file.type)) {
                toast.error("Image should be in PNG or JPG format!");
                return;
            }
    
            if (file.size > maxSize) {
                toast.error("Image size should not be greater than 5 MB!");
                return;
            }
    
            if (file) {
                setAddressPhoto(file);
                setAddressPhotoPreview(URL.createObjectURL(file));
            }
        } else {
            toast.error("No file selected!");
        }
    };
    
    const handleSelfiePhotoChange = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
        const maxSize = 5 * 1024 * 1024; // 5 MB
    
        if (file) {
            if (!allowedTypes.includes(file.type)) {
                toast.error("Image should be in PNG or JPG format!");
                return;
            }
    
            if (file.size > maxSize) {
                toast.error("Image size should not be greater than 5 MB!");
                return;
            }
            if (file) {
            setSelfiePhoto(file);
            setSelfiePhotoPreview(URL.createObjectURL(file));
            }
        } else {
            toast.error("No file selected!");
        }
    };    

    if (approved) {
        // return <AdvanceCompleteVerification approved={approved} />;
        return <AdvanceCompleteVerification />;
    }

    return (
        <>
            {next === 1 ? (
                <div className="min-h-screen bg-[#f4f4f4] flex flex-col items-center pt-4 pb-6">
                    <div className="inline-flex gap-3 mb-6">
                        <img src={icon} alt="" />
                        <h1 className="font-bold text-[21px]">
                            Advanced Verification
                        </h1>
                    </div>
                    <div className="bg-white rounded-xl py-8 px-8 flex-col gap-4 flex justify-around items-center w-[80%]">
                        <div className="inline-flex gap-5 w-full justify-center items-center">
                            <div className="flex flex-col w-[80%]">
                                <h1>
                                    <span className="text-red-600">*</span>
                                    Country of Residence
                                </h1>
                                <p className="text-[12px] text-[#9c9c9c]">
                                    Has there been a change in your country of
                                    residence in the primary verification?
                                    <span className="text-[#ffbe0b] text-[12px]">
                                        {" "}
                                        Click here to update{" "}
                                    </span>
                                </p>
                            </div>
                            <div className="p-2 bg-[#f3f3f3] w-full font-semibold rounded-[8px] h-fit">
                                {country}
                            </div>
                        </div>
                        <div className="inline-flex gap-5 w-full justify-center items-center">
                            <div className="flex flex-col w-[80%]">
                                <h1>
                                    <span className="text-red-600">*</span>Name
                                </h1>
                            </div>
                            <div className="p-2 bg-[#f3f3f3] w-full font-semibold rounded-[8px] h-fit">{`${firstName} ${lastName}`}</div>
                        </div>
                        <div className="inline-flex gap-5 w-full justify-center items-center">
                            <div className="flex flex-col w-[80%]">
                                <h1>
                                    <span className="text-red-600">*</span>
                                    Residential address
                                </h1>
                            </div>
                            <input
                                className="p-2 border-[1px] border-[#f3f3f3] w-full rounded-[8px] h-fit text-[13px] placeholder:text-[9px] outline-none"
                                placeholder="Please enter an address that matches the address proof you will upload"
                                value={address}
                                onChange={(e) =>
                                    setAddress(e.target.value)
                                }
                            />
                        </div>
                        <div className="inline-flex gap-5 w-full justify-center items-center mt-4">
                            <div className="flex flex-col w-full gap-3">
                                <h1>
                                    <span className="text-red-600">*</span>Proof
                                    of address
                                </h1>
                                <ul className="list-disc list-inside text-[#9c9c9c]">
                                    <li className="text-[14px]">
                                        Please upload a document that matches
                                        the exact address provided, e.g. utility
                                        bills, credit card statement, tax forms,
                                        etc.
                                    </li>
                                    <li className="text-[14px]">
                                        The document must include: your full
                                        name, your address, the date of issue,
                                        and the issuing authority
                                    </li>
                                    <li className="text-[14px]">
                                        The document must be issued within the
                                        last 3 months
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="inline-flex gap-3 w-full mt-3">
                            {/* Address Photo */}
                            <div className="border-[2px] rounded-xl border-dashed flex flex-col justify-start items-start w-full py-3 pl-4">
                                <h2 className="font-medium">Proof of Address</h2>
                                <div className="inline-flex justify-start items-center gap-3">
                                    <label className="bg-[#CCCCCC] py-1 px-3 font-medium rounded-full inline-flex cursor-pointer">
                                        <img src={Mask} className="mr-2" />
                                        <p className="text-[10px]">Upload</p>
                                        <input
                                            type="file"
                                            className="hidden"
                                            accept=".jpg,.jpeg,.png"
                                            onChange={handleAddressPhotoChange}
                                        />
                                    </label>
                                    {addressPhotoPreview && (
                                        <img
                                            src={addressPhotoPreview}
                                            alt="Address Preview"
                                            className="w-20 h-20 object-cover"
                                        />
                                    )}
                                    <p className="text-[8px] text-gray-500">
                                        Max 5 MB in .jpg/.jpeg/.png format
                                    </p>
                                </div>
                            </div>
                            {/* Selfie Photo */}
                            <div className="border-[2px] rounded-xl border-dashed flex flex-col justify-start items-start w-full py-3 pl-4">
                                <h2 className="font-medium">Selfie</h2>
                                <div className="inline-flex justify-start items-center gap-3">
                                    <label className="bg-[#CCCCCC] py-1 px-3 font-medium rounded-full inline-flex cursor-pointer">
                                        <img src={Mask} className="mr-2" />
                                        <p className="text-[10px]">Upload</p>
                                        <input
                                            type="file"
                                            className="hidden"
                                            accept=".jpg,.jpeg,.png"
                                            onChange={handleSelfiePhotoChange}
                                        />
                                    </label>
                                    {selfiePhotoPreview && (
                                        <img
                                            src={selfiePhotoPreview}
                                            alt="Selfie Preview"
                                            className="w-20 h-20 object-cover"
                                        />
                                    )}
                                    <p className="text-[8px] text-gray-500">
                                        Max 5 MB in .jpg/.jpeg/.png format
                                    </p>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={handleNext}
                            disabled={!isSubmitEnabled}
                            className={`mt-5 py-2 w-full rounded-[8px] ${
                                isSubmitEnabled
                                    ? "bg-[#0D6EFD]"
                                    : "bg-[#9c9c9c]"
                            } text-white font-semibold`}
                        >
                            Next
                        </button>
                    </div>
                </div>
            ) : (
                <AdvanceCompleteVerification />
            )}
        </>
    );
};

export default AdvancedVerificationForm;