import { BASIC_VERIFICATION_SUCCESS,BASIC_VERIFICATION_FAILURE,BASIC_VERIFICATION_REQUEST } from "../types";
const initialState = {
    loading: false,
    basicData: [],
    error: null
  };
  const idverificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case BASIC_VERIFICATION_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case BASIC_VERIFICATION_SUCCESS:
        return {
          ...state,
          loading: false,
          basicData: action.payload,
         
        };
       
      case BASIC_VERIFICATION_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default idverificationReducer;
  