import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GoogleIcon from "assets/img/GoogleOTPSVG.png";
import YellowTick from "assets/img/yellow-bg-tick.png";
import EmailVerification from "./helpers/EmailVerify";
import { SetEmail, setEmail } from "store/actions/authAction";
import { fetchAuthStatus } from "store/actions/userSecuritySettingsAction";
import SetEmailVerification from "./helpers/SetEmailVerify";
import { Modal } from "antd";
import AddEmailVerification from "./AddEmailVerification";
import ModalVerification from "components/auth/validations/email/emailVerificationHelp";

const EmailSetModal = ({ show, onClose, email }) => {
    const [emailC, setEmailC] = useState('');
    const [step, setStep] = useState(1);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const handleClickOutside = (event) => {
            const modal = document.querySelector(".modal-inner");
            if (modal && !modal.contains(event.target)) {
                onClose();
            }
            const outermodal = document.querySelector(".modal-outer");
            if (outermodal && !modal.contains(event.target)) {
                onClose();
            }
        };

        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show, onClose]);

    const handleNextStep = async () => {
        if (step === 1) {
            const payload = {
                email: emailC,
                user_id: userID
            };

            const success = await dispatch(SetEmail(payload));

            if (success) {
                setStep(step + 1);
            }
        } else {
            setStep(step + 1);
        }
    };

    if (!show) return null;

    const handleCancel = () => {
        setStep(1);
        onClose();
        if (userID && userToken) {
            dispatch(fetchAuthStatus(userID, userToken));
        }
    };

    return (
        <Modal
            visible={show}
            onCancel={handleCancel}
            footer={null}
            title={
                <p className="mb-6 text-lg">Set an Email</p>
            }
            centered
        >

            {step === 1 && (
                <div>
                    <div className="flex flex-col my-8 w-full items-center">
                        <input
                            type="email"
                            className="w-full rounded-md py-2 px-4 text-black bg-gray-100 font-medium focus:outline-none border-none"                            
                            placeholder="Enter your email"
                            onChange={(e) => setEmailC(e.target.value)}
                        />
                    </div>

                    <div className="flex justify-between">
                        <button
                            className="bg-yellow-500 text-white py-2 px-4 rounded focus:outline-none w-full"
                            onClick={handleNextStep}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            )}
            {step === 2 && <AddEmailVerification onSuccess={handleNextStep} />}
            {step === 3 && (
                <div>
                    <div className="flex justify-center items-center my-6">
                        <img
                            src={YellowTick}
                            alt="success"
                            className="mr-2"
                        />
                        <h2 className="text-lg font-medium">
                            Email Setup Completed Successfully
                        </h2>
                    </div>
                    <button
                        className="bg-yellow-500 text-white py-2 px-4 rounded focus:outline-none w-full"
                        onClick={handleCancel}
                    >
                        Done
                    </button>
                </div>
            )}
        </Modal>
    );
};

export default EmailSetModal;
