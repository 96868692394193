import React, { useState } from "react";
import icon from "assets/img/icon.svg";
import Mask from "assets/img/Mask group.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const AdvanceCompleteVerification = ({ approved }) => {
    return (
        <div className="min-h-screen flex flex-col justify-start items-center pt-4 pb-6 w-full gap-8 bg-[#f4f4f4]">
            <div className=" inline-flex gap-3">
                <img src={icon} alt="" />
                <h1 className=" font-bold text-[21px]">
                    Advanced Verification
                </h1>
            </div>
            {/* Part 2 */}
            <div className=" bg-white p-3 rounded-xl flex flex-col w-[70%] gap-3 justify-center items-center">
                <div className=" inline-flex gap-3 justify-center items-center">
                    <img src={Mask} alt="" />
                    <div className=" flex flex-col justify-start items-start ">
                        {approved ? (
                            <>
                                <h1>Already Completed</h1>
                                <p className="text-gray-500">
                                    Your verification is already completed.
                                </p>
                            </>
                        ) : (
                            <>
                                <h1>Verification Process is Completed</h1>
                                <p className="text-gray-500">
                                    You will receive an email once the review is
                                    complete.
                                </p>
                            </>
                        )}
                    </div>
                </div>
                <div className=" w-full flex flex-row justify-center items-center gap-3 mt-8 mb-4">
                    <Link to="/user/account/overview">
                        {" "}
                        <button className=" bg-[#efb81c] text-black  rounded-[10px] px-6 py-[5px] font-medium">
                            Back to Security
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AdvanceCompleteVerification;