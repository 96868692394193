import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const CryptoWithdrawalRecord  = ({ selectedCoin, TOKEN, ID }) => {
  const [withdrawalRecords, setWithdrawalRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!TOKEN || !ID) {
      history.push("/login");
      return;
    }

    if (selectedCoin && TOKEN && ID) {
      const fetchWithdrawals = async () => {
        setLoading(true);
        try {
          const response = await fetch(
            `https://api.buycex.com/Crypto/MyWithdrawals?coin=${selectedCoin.value}`,
            {
              headers: {
                TOKEN,
                ID,
              },
            }
          );
          console.log("withdrawal",response)
          const data = await response.json();
        
          if (data?.status === 1 && data.data) {

            setWithdrawalRecords(data.data);
          } else {
            console.log("No withdrawal records found");
          }
        } catch (error) {
          console.error("Error fetching withdrawal records:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchWithdrawals();
    }
  }, [selectedCoin, TOKEN, ID, history]);
console.log("withdrawalRecords",withdrawalRecords)
  return (
    <div className="mt-32 container mb-4">
      <span className="text-[24px] font-semibold">Recent Withdrawal Records</span>
      <span className="ml-2 text-[24px] font-semibold text-[#BDBDBD]">
        {selectedCoin ? selectedCoin.label : ""} Withdrawal History
      </span>

      <section className="container overflow-x-auto px-3 mt-4">
        <table className="text-center table-auto w-full">
          <thead>
            <tr>
              <th className="border-b-[1px] text-start min-w-24 py-2 font-normal text-[#BDBDBD]">
                Coin
              </th>
              <th className="border-b-[1px] min-w-24 py-2 font-normal text-[#BDBDBD]">
                Network
              </th>
              <th className="border-b-[1px] min-w-24 py-2 font-normal text-[#BDBDBD]">
                Quantity
              </th>
              <th className="border-b-[1px] min-w-24 py-2 font-normal text-[#BDBDBD]">
                Transaction Fee
              </th>
              <th className="border-b-[1px] min-w-24 py-2 font-normal text-[#BDBDBD]">
                Address
              </th>
              <th className="border-b-[1px] min-w-24 py-2 font-normal text-[#BDBDBD]">
                Transaction ID
              </th>
              <th className="border-b-[1px] min-w-24 py-2 font-normal text-[#BDBDBD]">
                Status
              </th>
              <th className="border-b-[1px] min-w-24 py-2 font-normal text-[#BDBDBD]">
                Date & Time
              </th>
              <th className="border-b-[1px] min-w-24 py-2 font-normal text-[#BDBDBD]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              [...Array(5)].map((_, index) => (
                <tr key={index}>
                  <td className="px-4 py-2">
                    <div className="h-6 bg-gray-300 rounded animate-pulse"></div>
                  </td>
                  <td className="px-4 py-2">
                    <div className="h-6 bg-gray-300 rounded animate-pulse"></div>
                  </td>
                  {/* Repeat skeleton for other cells */}
                </tr>
              ))
            ) : withdrawalRecords.length > 0 ? (
              withdrawalRecords.map((record, index) => (
                <tr key={index}>
                  <td className="px-4 py-2">{record.coinname}</td>
                  <td className="px-4 py-2">{record.coinname}</td>
                  <td className="px-4 py-2">{record.num}</td>
                  <td className="px-4 py-2">{record.fee}</td>
                  <td className="px-4 py-2">{record.username}</td>
                  <td className="px-4 py-2">{record.txid}</td>
                  <td className="px-4 py-2">{record.status}</td>
                  <td className="px-4 py-2">{record.addtime}</td>
                  <td className="px-4 py-2">
                    <button className="btn-action">View</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="py-4 text-gray-500">
                  No withdrawal records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default CryptoWithdrawalRecord ;
