import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    verifyCodeFromEmail,
    SetEmail,
    sendCodeToEmail,
    verifySaveEmail,
    verifyG2faCode,
} from "store/actions/authAction";
import { fetchAuthStatus } from "store/actions/userSecuritySettingsAction";
import { TbBrandGoogle } from "react-icons/tb";
import { IoMailOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import ModalVerification from "components/auth/validations/email/emailVerificationHelp";

function AddEmailVerification({ onSuccess = {}, showText, step }) {
    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [timer, setTimer] = useState(60);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const state = useSelector((state) => state.Newemail.email);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const stateS = useSelector((state) => state.userInfo.userProfile.email);
    const email = useSelector((state) => state.authCheck.authStatus.email);
    // const email = authStatus?.email;

    // console.log(stateE);

    const dispatch = useDispatch();

    // useEffect(() => {
    //     const storedEmail = localStorage.getItem("email");
    //     const storedPassword = localStorage.getItem("password");
    //     if (storedEmail) setEmail(storedEmail);
    //     if (storedPassword) setPassword(storedPassword);
    // }, []);

    // useEffect(() => {
    //     if (userID && userToken) {
    //         dispatch(fetchAuthStatus(userID, userToken));
    //     }
    // }, [step, userID, userToken, dispatch]);

    useEffect(() => {
        let interval = null;
        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        setIsButtonDisabled(false);
                        return 60;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isButtonDisabled]);

    const verifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = {
            user_id: userID,
            code: otp,
        };
        const payloadS = {
            email: state,
            user_id: userID,
            code: otp,
        };
        if (step === 1) {
            dispatch(
                verifyCodeFromEmail(payload, true, () => {
                    setLoading(false);
                    onSuccess();
                })
            );
        } else {
            dispatch(
                verifySaveEmail(payloadS, true, () => {
                    setLoading(false);
                    onSuccess();
                })
            );
        }
    };

    const handleSendCode = () => {
        if (!isButtonDisabled) {
            const payload = {
                email: state,
                user_id: userID,
            };
            const payloadE = {
                email: email,
                user_id: userID,
            };
            if (step === 1) {
                dispatch(sendCodeToEmail(payloadE)); // Dispatch for step 1
            } else {
                dispatch(SetEmail(payload)); // Dispatch for other steps
            }
            setIsButtonDisabled(true);
        }
    };


    return (
        <div className="flex flex-col gap-4 my-auto">
            <form onSubmit={verifyOtp}>
                <div className="flex flex-col gap-2 items-start justify-center">
                    {showText && (
                        <div className="flex text-[#81858c] items-center gap-1">
                            <IoMailOutline />
                            <h6 className="text-sm text-[#81858c]">
                                A code has been sent to the{" "}
                                <span className="text-md font-semibold text-black">
                                    {email}
                                </span>{" "}
                                .
                            </h6>
                        </div>
                    )}
                    <div className="flex mt-8 justify-between bg-gray-100 rounded-md py-2 px-4 w-full">
                        <input
                            type="text"
                            placeholder="Enter Verification code"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            className="bg-transparent text-black font-medium focus:outline-none border-none"
                        />
                        <div>
                            <span
                                className={`text-[#F1BA19] font-medium text-sm ${isButtonDisabled
                                        ? "cursor-not-allowed opacity-50"
                                        : "cursor-pointer"
                                    }`}
                                onClick={handleSendCode}
                            >
                                Send Verification Code{" "}
                                {isButtonDisabled ? `(${timer}s)` : ""}
                            </span>
                        </div>
                    </div>
                    
                    <button
                        type="submit"
                        className="btn bg-yellow-500 py-2 mt-4 rounded-md text-black font-semibold text-center w-full focus:no-underline focus:outline-none"
                    >
                        {/* {loading ? <Spinner size="sm" /> : "Next"} */}
                        Next Step
                    </button>
                </div>
            </form>
            <div className="w-full">
                <button
                    onClick={() => setShowModal(true)}
                    className="block text-[#F1BA19] hover:no-underline text-start font-semibold w-full"
                    style={{ cursor: "pointer" }}
        
                >
                    Having problems with verification?
                </button>
            </div>
            <ModalVerification
                visible={showModal}
                onClose={() => setShowModal(false)}
                email={email}
            />
            <style>
                {`
                .no-arrows::-webkit-inner-spin-button,
                .no-arrows::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .no-arrows {
                    -moz-appearance: textfield; /* For Firefox */
                }
                `}
            </style>
        </div>
    );
}

export default AddEmailVerification;
