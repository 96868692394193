import React from 'react';
import { TbCaretUpDownFilled } from "react-icons/tb";
import { FaBitcoin, FaEthereum } from "react-icons/fa"; // Import specific coin icons
import { HiTrendingUp } from "react-icons/hi";

function Earn() {
    const popularCoins = [
        { 
            name: 'BTC', 
            icon: <FaBitcoin className='text-2xl'/>, 
            color: '#F7931A', 
            apr: '4.91% - 4.91%', 
            duration: 'flexible', 
            flexibleApr: '4.91%', 
            descriptions: {
                aprd: '*******',
            }
        },
        { 
            name: 'ETH', 
            icon: <FaEthereum className='text-2xl'/>, 
            color: '#3C3C3D', 
            apr: '0.58% - 161.59%', 
            duration: 'flexible / fixed', 
            flexibleApr: '0.58%', 
            descriptions: {
                aprd: '*******',
            }
        },
    ];

    return (
        <div className='w-full'>
            {/* Header row */}
            <div className='flex w-full mb-8'>
                <div className='flex items-center gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Coins</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Est.APR</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Duration</p>
                    <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                </div>
                <div className='flex items-center justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Flexible APR</p>
                </div>
                <div className='flex justify-end gap-1 w-1/5'>
                    <p className='text-xs text-[#929AA5]'>Action</p>
                </div>
            </div>

            {/* Map through popular coins array */}
            {popularCoins.map((coin, index) => (
                <div key={index} className='flex w-full items-center mb-4'>
                    <div className='flex gap-2 w-1/5'>
                        <div className='flex items-center gap-2'>
                            <div className='text-lg' style={{ color: coin.color }}>
                                {coin.icon} {/* Coin icon with color */}
                            </div>
                        </div>
                        {/* Description for each coin */}
                        <div className='flex flex-col'>
                        <p className='text-md font-normal'>{coin.name}</p>
                        <p className='text-sm text-[#929AA5]'>{coin.descriptions.coin}</p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-1 w-1/5'>
                        <p className='text-md text-end font-normal text-[#2EBD85]'>{coin.apr}</p>
                    </div>
                    <div className='flex flex-col gap-1 w-1/5'>
                        <p className='text-md text-end font-normal'>{coin.duration}</p>
                        {/* Description for duration */}
                    </div>
                    <div className='w-1/5'>
                        <div className='flex gap-2 justify-end items-center'><p className='text-md'>{coin.flexibleApr}</p><button className='text-sm text-[#EFB81C] bg-[#FEF6D8] px-1 py-0.5 rounded-md'>Low Risk</button></div>
                    </div>
                    <div className='flex justify-end w-1/5'>
                        <button className='text-sm text-[#EFB81C] underline'>Subscribe</button>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Earn;
