/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";

const FIAT_DEPOSIT = "/Crypto/fiatDeposit";
const FIAT_DEPOSIT_HISTORY = "/Crypto/fiatDepositHistory";
const EndPOint = "/Finance/myczUp";

export default {
    fiatDeposit(userDetail) {
        return Repository.get(`${FIAT_DEPOSIT}`, {
            headers: {
                token: "",
                id: "",
            },
        });
    },
    getPaymentMethods(userId, token, coinname) {
        return Repository.get(
            `${FIAT_DEPOSIT}?coinname=${coinname.toLowerCase()}`,
            {
                headers: {
                    token: token,
                    id: userId,
                },
            }
        );
    },
    fiatDepositHistory(userId, token, coinname) {
        return Repository.get(
            `${FIAT_DEPOSIT_HISTORY}?coinname=${coinname.toLowerCase()}`,
            {
                headers: {
                    token: token,
                    id: userId,
                },
            }
        );
    },
    FinanceDeposit(userId, token,payload) {
        return Repository.post(`${EndPOint}`,payload, {
            headers: {
                token: token,
                id: userId,
                "Content-Type": "multipart/form-data"
            },
        });
    },
};
