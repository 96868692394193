import { Modal } from 'antd';
import { IoIosGlobe } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { MdPhoneAndroid } from "react-icons/md";
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkCellphone, sendNewCellphone, updateCellphone } from 'store/actions/authphoneActions';
import { toast, ToastContainer } from 'react-toastify';
import { AiOutlineCheckCircle } from "react-icons/ai";
const countries = [
    { code: 'US', name: 'United States', flag: '🇺🇸', dialCode: '+1' },
    { code: 'IN', name: 'India', flag: '🇮🇳', dialCode: '+91' },
    { code: 'GB', name: 'United Kingdom', flag: '🇬🇧', dialCode: '+44' },
    { code: 'CA', name: 'Canada', flag: '🇨🇦', dialCode: '+1' },
    { code: 'AU', name: 'Australia', flag: '🇦🇺', dialCode: '+61' },
    { code: 'DE', name: 'Germany', flag: '🇩🇪', dialCode: '+49' },
    { code: 'FR', name: 'France', flag: '🇫🇷', dialCode: '+33' },
    { code: 'BR', name: 'Brazil', flag: '🇧🇷', dialCode: '+55' },
    { code: 'ZA', name: 'South Africa', flag: '🇿🇦', dialCode: '+27' },
    { code: 'CN', name: 'China', flag: '🇨🇳', dialCode: '+86' },
    { code: 'JP', name: 'Japan', flag: '🇯🇵', dialCode: '+81' },
    { code: 'IT', name: 'Italy', flag: '🇮🇹', dialCode: '+39' },
    { code: 'ES', name: 'Spain', flag: '🇪🇸', dialCode: '+34' },
    { code: 'RU', name: 'Russia', flag: '🇷🇺', dialCode: '+7' },
    { code: 'MX', name: 'Mexico', flag: '🇲🇽', dialCode: '+52' },
    { code: 'KR', name: 'South Korea', flag: '🇰🇷', dialCode: '+82' },
    { code: 'AE', name: 'United Arab Emirates', flag: '🇦🇪', dialCode: '+971' },
    { code: 'NG', name: 'Nigeria', flag: '🇳🇬', dialCode: '+234' },
    { code: 'EG', name: 'Egypt', flag: '🇪🇬', dialCode: '+20' },
    { code: 'AR', name: 'Argentina', flag: '🇦🇷', dialCode: '+54' },
    { code: 'TR', name: 'Turkey', flag: '🇹🇷', dialCode: '+90' },
    { code: 'PK', name: 'Pakistan', flag: '🇵🇰', dialCode: '+92' },
    { code: 'ID', name: 'Indonesia', flag: '🇮🇩', dialCode: '+62' },
    { code: 'MY', name: 'Malaysia', flag: '🇲🇾', dialCode: '+60' },
    { code: 'PH', name: 'Philippines', flag: '🇵🇭', dialCode: '+63' },
    { code: 'TH', name: 'Thailand', flag: '🇹🇭', dialCode: '+66' },
    { code: 'SA', name: 'Saudi Arabia', flag: '🇸🇦', dialCode: '+966' },
    { code: 'VN', name: 'Vietnam', flag: '🇻🇳', dialCode: '+84' },
    { code: 'BD', name: 'Bangladesh', flag: '🇧🇩', dialCode: '+880' },
    { code: 'SG', name: 'Singapore', flag: '🇸🇬', dialCode: '+65' },
    { code: 'HK', name: 'Hong Kong', flag: '🇭🇰', dialCode: '+852' },
];



const MobileNoChangeModal = ({ show, onClose }) => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(countries[0]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [success, setSuccess] = useState(false); // Track success state
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const dispatch = useDispatch();
    const { loading, error, available } = useSelector((state) => state.checkcellphone || {});
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setDropdownOpen(false);
    };

    const handleMobileChange = (e) => {
        const input = e.target.value.replace(/[^0-9]/g, '');
        setMobileNumber(input);
    };

    const handleSendCode  = async () => {
        if (mobileNumber.trim() !== '') {
            const fullNumber = selectedCountry.dialCode + mobileNumber;

            // Remove the first `+` sign
            const formattedNumber = fullNumber.startsWith('+') ? fullNumber.substring(1) : fullNumber;
            
            const response = await dispatch(checkCellphone({ cellphone: formattedNumber, userToken, userID }));
            if (response?.status === 1) {
                
                toast.success("Phone number is available");
                console.log("response checkCellphone",response)
                dispatch(sendNewCellphone(fullNumber, userToken, userID));
                setIsCodeSent(true);
            } else {
                toast.error("Enter valid no ");
            }
     
        } else {
            toast.error("Please enter a valid mobile number.");
        }
    };

    const handleVerifyCode = async () => {
        if (verificationCode.trim() !== '') {
            const fullNumber = selectedCountry.dialCode + mobileNumber;
            const formattedNumber = fullNumber.startsWith('+') ? fullNumber.substring(1) : fullNumber;
            try {
                const response = await dispatch(updateCellphone({
                    cellphone: formattedNumber,
                    verificationCode,
                    userToken,
                    userID
                }));

                if (response?.status === 1) {
                    setSuccess(true); // Set success state
                    toast.success(response?.data || "Phone number updated successfully!");
                } else {
                    toast.error(response?.data || "Unexpected response from the server.");
                }
            } catch (error) {
                toast.error("Failed to update phone number.");
            }
        } else {
            toast.error("Verification code cannot be empty.");
        }
    };

    const handleSuccessClose = () => {
        setSuccess(false);
        onClose(); // Close modal
    };

    return (
        <Modal
            title={success ? "Success" : "Set Mobile Phone Number"}
            visible={show}
            onCancel={onClose}
            footer={null}
            destroyOnClose
        >
            {success ? (
               <div className="text-center p-6 ">
               <AiOutlineCheckCircle className="text-green-500 text-6xl mb-4 mx-auto" /> {/* Success Icon */}
               <p className="text-green-500 font-semibold text-2xl mb-4">
                 Successfully added your phone to the account!
               </p>
               <button
                 className="bg-[#EFB81C] mt-5 py-2 text-center rounded-md w-full font-semibold text-white"
                 onClick={handleSuccessClose}
               >
                 OK
               </button>
             </div>
            ) : (
                <div>
                    {/* Country dropdown */}
                    <div className="relative" ref={dropdownRef}>
                        <button
                            className="bg-gray-100 w-full py-2 px-3 rounded-md flex items-center justify-between"
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                        >
                            <span>
                                {selectedCountry.flag} {selectedCountry.name} ({selectedCountry.dialCode})
                            </span>
                            <RiArrowDropDownLine
                                className={`transition-transform duration-300 ${dropdownOpen ? 'rotate-180' : ''}`}
                                size={20}
                            />
                        </button>
                        {dropdownOpen && (
                            <ul className="absolute bg-white border rounded-md shadow-lg w-full mt-1 max-h-60 overflow-y-auto">
                                {countries.map((country) => (
                                    <li
                                        key={country.code}
                                        className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                        onClick={() => handleCountryChange(country)}
                                    >
                                        {country.flag} {country.name} ({country.dialCode})
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    {/* Mobile number input */}
                    <div className="mt-4">
                        <div className="flex items-center gap-1 mb-2">
                            <MdPhoneAndroid />
                            <p>Enter a new mobile phone number</p>
                        </div>
                        <div className="flex">
                            <span className="bg-gray-200 px-3 py-2 rounded-l-md">{selectedCountry.dialCode}</span>
                            <input
                                type="text"
                                className="bg-gray-100 rounded-r-md focus:outline-none px-3 w-full py-2"
                                placeholder="New mobile number"
                                value={mobileNumber}
                                onChange={handleMobileChange}
                            />
                        </div>
                    </div>

                    {/* Verification code input */}
                    {isCodeSent && (
                        <div className="mt-4">
                            <div className="flex items-center gap-1 mb-2">
                                <MdPhoneAndroid />
                                <p>Enter Verification Code</p>
                            </div>
                            <input
                                type="text"
                                className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2"
                                placeholder="Verification code"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                            />
                        </div>
                    )}
 {/* Feedback messages */}
 <div className="mt-4">
                    {error && <p className="text-red-500">{error}</p>}
                    {available === 1 && <p className="text-green-500">This number is available!</p>}
                    {available === 0 && (
                        <p className="text-red-500">This number is not available. Try a different one.</p>
                    )}
                </div>
                    {/* Action button */}
                    <div className="mt-6 flex justify-end">
                        <button
                            className={`bg-[#EFB81C] mt-5 py-2 text-center rounded-md w-full font-semibold`}
                            onClick={isCodeSent ? handleVerifyCode : handleSendCode}
                        >
                            {isCodeSent ? "Verify Code" : "Send Code"}
                        </button>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default MobileNoChangeModal;
