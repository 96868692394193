import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import AssetsSideBar from "components/user/assets/AssetsSideBar";
import "assets/css/user/user-components.scss";

const Assets = (props) => {
	return (
		<>
			<Header />
			<div className="bg-[#F0F0F0]">
			<div className='container flex gap-4 py-12'>
				<aside>
					<AssetsSideBar />
				</aside>
				<main className="w-full">
					{props.children}
				</main>
			</div>
			</div>
			<Footer />
		</>
	);
};

export default Assets;
