import { RepositoryFactory } from "repository/RepositoryFactory";
import { toast } from "react-hot-toast";
import {
    LOGIN,
    SET_EMAIL,
    SET_PASSWORD,
    LOGOUT,
    LOADING,
    SIGNUP,
    PHONE_SIGNUP,
    EMAIL_2FA,
    PHONE_2FA,
    EMAIL_2FA_FAIL,
    PHONE_2FA_FAIL,
    VERIFY_CODE,
    SET_FUND_PASSWORD,
    CHECK_FUND_PASSWORD,
    CHANGE_FUND_PASSWORD,
} from "../types";

const auth = RepositoryFactory.get("auth");

const validatePayload = (payload, requiredFields) => {
    const errorToastId = "general-error-toast";

    for (let field of requiredFields) {
        if (!payload[field]) {
            const fieldName = field.charAt(0).toUpperCase() + field.slice(1);
            toast.dismiss(errorToastId);
            toast.error(`${fieldName} is required`, { id: errorToastId });
            return false;
        }
    }

    const password = payload.password;
    if (password) {
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/;
        if (!passwordRegex.test(password)) {
            let errorMessage = "Password must contain 8-30 characters";
            if (password.length < 8 || password.length > 30) {
                errorMessage = "Password must contain 8-30 characters";
            } else if (!/[A-Z]/.test(password)) {
                errorMessage =
                    "Password must contain at least one capital letter";
            } else if (!/[a-z]/.test(password)) {
                errorMessage =
                    "Password must contain at least one small letter";
            } else if (!/\d/.test(password)) {
                errorMessage = "Password must contain at least one number";
            } else if (!/[@$!%*?&]/.test(password)) {
                errorMessage =
                    "Password must contain at least one special character";
            }

            toast.dismiss(errorToastId);
            toast.error(errorMessage, { id: errorToastId });
            return false;
        }
    }
    return true;
};

export default validatePayload;

export const login =
    (
        payload,
        onSuccess,
        on2FARequired,
        onEmail2FARequired,
        onPhone2FARequired
    ) =>
    async (dispatch) => {
        const requiredFields = ["username", "password"];
        if (!validatePayload(payload, requiredFields)) return;
        dispatch(loginLoading(true));

        try {
            const { data } = await auth.login(payload);
            console.log("After API call", data);

            if (data.status === 1) {
                if (data.data.msg.includes("User found Verify 2fa to login")) {
                    console.log("Verify 2fa to login required", data);
                    localStorage.setItem("user_id_2fa", data.data.id);
                    dispatch({
                        type: SET_PASSWORD,
                        payload: {
                            email: payload.username, // use the username as email
                            password: payload.password, // keep the password from input
                        },
                    });
                    // onEmail2FARequired(data);  // Pass response data
                    onSuccess(data);
                } else if (
                    data.data.msg.includes("User found Verify 2fa to login!")
                ) {
                    console.log("Verify 2fa to login required", data);
                    localStorage.setItem("user_id_2fa", data.data.id);
                    dispatch({
                        type: SET_PASSWORD,
                        payload: {
                            email: payload.username, // use the username as email
                            password: payload.password, // keep the password from input
                        },
                    });
                    // onPhone2FARequired();
                    onSuccess(data);
                } else if (data.data.msg.includes("!This Account Needs 2Fa")) {
                    localStorage.setItem("user_id_2fa", data.data.ID);
                    // on2FARequired();
                    dispatch({
                        type: SET_PASSWORD,
                        payload: {
                            email: payload.username, // use the username as email
                            password: payload.password, // keep the password from input
                        },
                    });
                    console.log("Verify g2fa to login required", data);
                    onSuccess(data);
                } else {
                    toast.dismiss("success-toast");
                    toast.success("Login Successful", { id: "success-toast" });
                    dispatch({ type: LOGIN, payload: data.data });
                    dispatch({
                        type: SET_PASSWORD,
                        payload: {
                            email: payload.username, // use the username as email
                            password: payload.password, // keep the password from input
                        },
                    });
                    onSuccess(data); // Pass response data
                }
            } else {
                toast.dismiss("error-toast");
                toast.error(data.data || "Login failed. Please try again.", {
                    id: "error-toast",
                });
            }
        } catch (error) {
            toast.dismiss("error-toast");
            toast.error("Login failed. Please try again.", {
                id: "error-toast",
            });
        } finally {
            dispatch(loginLoading(false));
        }
    };

export const reactiveEmailPasswordAuth =
    (payload, onSuccess) => async (dispatch) => {
        const requiredFields = ["username", "password"];
        if (!validatePayload(payload, requiredFields)) return;

        dispatch(loginLoading(true));

        try {
            const { data } = await auth.reactiveEmailPasswordAuth(payload);
            console.log("API Response:", data); // Log the full API response

            // Check for success status
            if (data.status === 1) {
                // Adjusting the check for success based on your response
                if (data.data === "Successfully") {
                    dispatch({
                        type: SET_PASSWORD,
                        payload: {
                            email: payload.username,
                            password: payload.password,
                        },
                    });
                    onSuccess(data); // Pass the data to handleLoginResponse
                } else if (
                    data.data.includes("User found Verify 2fa to login!")
                ) {
                    dispatch({
                        type: SET_PASSWORD,
                        payload: {
                            email: payload.username,
                            password: payload.password,
                        },
                    });
                    onSuccess(data);
                } else {
                    toast.dismiss("success-toast");
                    toast.success("Login Successful", { id: "success-toast" });
                    dispatch({ type: LOGIN, payload: data.data });
                    dispatch({
                        type: SET_PASSWORD,
                        payload: {
                            email: payload.username,
                            password: payload.password,
                        },
                    });
                    onSuccess(data); // Pass response data
                }
            } else {
                // If status is not 1, show error message
                toast.dismiss("error-toast");
                toast.error(data.data || "Login failed. Please try again.", {
                    id: "error-toast",
                });
            }
        } catch (error) {
            console.error("Error during login:", error); // Log the error for debugging
            toast.dismiss("error-toast");
            toast.error("Login failed. Please try again.", {
                id: "error-toast",
            });
        } finally {
            dispatch(loginLoading(false));
        }
    };

export const email2fa = (userId, onSuccess) => async (dispatch) => {
    try {
        console.log("user email to 2fa request", userId);
        const response = await auth.email2fa(userId);
        if (response.data.status === 1) {
            dispatch({ type: EMAIL_2FA, payload: response.data });
            // toast.dismiss("success-toast");
            toast.success(response.data.data || "Please check your email", {
                id: "success-toast",
            });
            onSuccess();
        }
    } catch (error) {
        dispatch({ type: EMAIL_2FA_FAIL, error: error.message });
    }
};

export const phone2fa = (userId, onSuccess) => async (dispatch) => {
    try {
        console.log("user phone to 2fa request", userId);
        const response = await auth.phone2fa(userId);
        if (response.data.status === 1) {
            dispatch({ type: PHONE_2FA, payload: response.data });
            // toast.dismiss("success-toast");
            toast.success(response.data.data.msg || "Please check your phone", {
                id: "success-toast",
            });
            // toast.success(response.data.data.code)
            alert(`Your verification code is: ${response.data.data.code}`);
            onSuccess();
            // console.log("code",response.data.data.code);
        }
    } catch (error) {
        dispatch({ type: PHONE_2FA_FAIL, error: error.message });
    }
};

export const confirm2faCodelogin = (payload, onSuccess) => async (dispatch) => {
    const requiredFields = ["code", "user_id"];
    if (!validatePayload(payload, requiredFields)) return;
    dispatch(loginLoading(true));

    try {
        const { data } = await auth.confirm2faCodelogin(payload);

        if (data.status === 1) {
            // toast.dismiss("Please Check your email");
            toast.success("Verification Successful", { id: "success-toast" });
            localStorage.removeItem("user_id_2fa");
            dispatch({ type: LOGIN, payload: data.data });
            if (onSuccess) {
                onSuccess();
            }
        } else {
            toast.dismiss("error-toast");
            toast.error("Invalid OTP!");
        }
    } catch (error) {
        toast.dismiss("error-toast");
        toast.error("2FA verification failed. Please try again.", {
            id: "error-toast",
        });
    } finally {
        dispatch(loginLoading(false));
    }
};

export const doActiveFreezeAccount =
    (payload, onSuccess) => async (dispatch) => {
        const requiredFields = ["code", "user_id"];
        if (!validatePayload(payload, requiredFields)) return;
        dispatch(loginLoading(true));

        try {
            const { data } = await auth.doActiveFreezeAccount(payload);

            if (data.status === 1) {
                // toast.dismiss("Please Check your email");
                toast.success("Verification Successful", {
                    id: "success-toast",
                });
                // dispatch({ type: LOGIN, payload: data.data });
                if (onSuccess) {
                    onSuccess(data);
                }
            } else {
                toast.dismiss("error-toast");
                toast.error("Invalid OTP!");
            }
        } catch (error) {
            toast.dismiss("error-toast");
            toast.error("2FA verification failed. Please try again.", {
                id: "error-toast",
            });
        } finally {
            dispatch(loginLoading(false));
        }
    };

export const verifyG2faCode = (payload, onSuccess) => async (dispatch) => {
    const requiredFields = ["ga"];
    if (!validatePayload(payload, requiredFields)) return;
    dispatch(loginLoading(true));
    try {
        const { data } = await auth.verifyG2faCode(payload);
        if (data.status === 1) {
            toast.dismiss("success-toast");
            toast.success("Verification Successful", { id: "success-toast" });
            dispatch({ type: LOGIN, payload: data.data });
            if (onSuccess) {
                onSuccess();
            }
        } else {
            toast.dismiss("error-toast");
            toast.error(
                data.data || "2FA verification failed. Please try again.",
                { id: "error-toast" }
            );
        }
    } catch (error) {
        toast.dismiss("error-toast");
        toast.error("2FA verification failed. Please try again.", {
            id: "error-toast",
        });
    } finally {
        dispatch(loginLoading(false));
    }
};

export const view2faGoogle = (payload, onSuccess) => async (dispatch) => {
    const requiredFields = ["ga", "ga_login", "userid", "type"];
    if (!validatePayload(payload, requiredFields)) return;
    //dispatch(loginLoading(true));
    try {
        const { data } = await auth.view2faGoogle(payload);
        if (data.status === 1) {
            toast.dismiss("success-toast");
            toast.success("Verification Successful", { id: "success-toast" });
            //dispatch({ type: LOGIN, payload: data.data });
            if (onSuccess) {
                onSuccess();
            }
        } else {
            toast.dismiss("error-toast");
            toast.error(
                data.data || "2FA verification failed. Please try again.",
                { id: "error-toast" }
            );
        }
    } catch (error) {
        toast.dismiss("error-toast");
        toast.error("2FA verification failed. Please try again.", {
            id: "error-toast",
        });
    } finally {
        //dispatch(loginLoading(false));
    }
};

export const setFundPassword = (payload, onSuccess) => async (dispatch) => {
    dispatch(loginLoading(true));

    try {
        const { data } = await auth.setFundPassword(payload);

        if (data.status === 1) {
            toast.success("Password Configuered Successful");
            dispatch({ type: SET_FUND_PASSWORD, payload: data?.data });

            return true;
        } else {
            toast.error(data?.data);
        }
    } catch (error) {
        console.error("Error in forgotCode:", error); // Log the error for debugging
        toast.error("Failed to send verification code. Please try again.");
        return false;
    } finally {
        dispatch(loginLoading(false));
    }
};

export const checkFundPassword = (payload) => async (dispatch) => {
    dispatch(loginLoading(true));

    try {
        const { data } = await auth.paypassword(payload);
        if (data.status === 1) {
            toast.success(data?.data);
            dispatch({ type: CHECK_FUND_PASSWORD, payload: data?.data });
            return true;
        } else {
            // toast.error(data?.data);
        }
    } catch (error) {
        console.error("Error in forgotCode:", error); // Log the error for debugging
        toast.error("Failed to send verification code. Please try again.");
        return false;
    } finally {
        dispatch(loginLoading(false));
    }
};

export const changeFundPassword = (payload) => async (dispatch) => {
    dispatch(loginLoading(true));

    try {
        const { data } = await auth.changeFundPassword(payload);

        console.log("\n\n\n\n this is the data: ", data, "\n\n");

        toast.success("Fund Password Changed Successfully");
        dispatch({ type: CHECK_FUND_PASSWORD, payload: data?.data });
        return true;
    } catch (error) {
        console.error("Error in forgotCode:", error); // Log the error for debugging
        toast.error("Failed to change fund password. Please try again.");
        return false;
    } finally {
        dispatch(loginLoading(false));
    }
};

export const SetEmail = (payload) => async (dispatch) => {
    const requiredFields = ["user_id", "email"];
    if (!validatePayload(payload, requiredFields)) return false;

    dispatch(loginLoading(true));
    try {
        const { data } = await auth.setEmail(payload);

        if (data.status === 1) {
            if (
                data.data.msg.includes(
                    "Please check your email for confirmation"
                )
            ) {
                toast.dismiss("error-toast");
                toast.success(data.data.msg);
                dispatch({ type: SET_EMAIL, payload: data.data.email });
                // alert(`Your verification code is: ${data.data.code}`);
                return true;
            }
        } else if (data.status === "Email Does not exist!") {
            toast.error(data.data.msg || "Unable to send verification code!");
            return false;
        } else {
            toast.error(data.data || "Unable to send verification code!");
            return false;
        }
    } catch (error) {
        console.error("Error in forgotCode:", error); // Log the error for debugging
        toast.error("Failed to send verification code. Please try again.");
        return false;
    } finally {
        dispatch(loginLoading(false));
    }
};
export const sendCodeToEmail = (payload) => async (dispatch) => {
    const requiredFields = ["user_id", "email"];
    if (!validatePayload(payload, requiredFields)) return false;

    dispatch(loginLoading(true));
    try {
        const { data } = await auth.sendCodeToEmail(payload);

        if (data.status === 1) {
            if (
                data.data.msg.includes(
                    "Please check your email for confirmation"
                )
            ) {
                toast.dismiss("error-toast");
                toast.success(data.data.msg);
                dispatch({ type: SET_EMAIL, payload: data.data.email });
                // alert(`Your verification code is: ${data.data.code}`);
                return true;
            }
        } else if (data.status === "Email Does not exist!") {
            toast.error(data.data.msg || "Unable to send verification code!");
            return false;
        } else {
            toast.error(data.data || "Unable to send verification code!");
            return false;
        }
    } catch (error) {
        console.error("Error in forgotCode:", error); // Log the error for debugging
        toast.error("Failed to send verification code. Please try again.");
        return false;
    } finally {
        dispatch(loginLoading(false));
    }
};

export const forgotCode = (payload) => async (dispatch) => {
    const requiredFields = ["email"];
    if (!validatePayload(payload, requiredFields)) return false;

    dispatch(loginLoading(true));
    try {
        const { data } = await auth.forgotCode(payload);

        if (data.status === 1) {
            if (data.data.msg.includes("Verification code has been sent")) {
                toast.dismiss("error-toast");
                toast.success(data.data.msg);
                // alert(`Your verification code is: ${data.data.code}`);
                return true;
            }
        } else if (data.status === "Email Does not exist!") {
            toast.error(data.data.msg || "Unable to send verification code!");
            return false;
        } else {
            toast.error(data.data || "Unable to send verification code!");
            return false;
        }
    } catch (error) {
        console.error("Error in forgotCode:", error); // Log the error for debugging
        toast.error("Failed to send verification code. Please try again.");
        return false;
    } finally {
        dispatch(loginLoading(false));
    }
};

export const sendForgotCode = (payload) => async (dispatch) => {
    const requiredFields = ["phone"];
    if (!validatePayload(payload, requiredFields)) return false;

    dispatch(loginLoading(true));
    try {
        const { data } = await auth.sendForgotCode(payload);

        if (data.status === 1) {
            if (data.data.msg.includes("Verification code has been sent")) {
                toast.dismiss("error-toast");
                toast.success(data.data.msg);
                alert(`Your verification code is: ${data.data.code}`);
                return true;
            }
        } else if (data.data.msg === "Email Does not exist!") {
            toast.error(data.data.msg || "Unable to send verification code!");
            return false;
        } else {
            toast.error(data.data || "Unable to send verification code!");
            return false;
        }
    } catch (error) {
        console.error("Error in sendForgotCode:", error); // Log the error for debugging
        toast.error("Failed to send verification code. Please try again.");
        return false;
    } finally {
        dispatch(loginLoading(false));
    }
};

export const confirmCode =
    (payload, forEmailVer = false, onSuccess) =>
    async (dispatch) => {
        const requiredFields = ["email", "verify_code"];

        if (!validatePayload(payload, requiredFields)) {
            return false;
        }

        dispatch(loginLoading(true));

        try {
          
            const { data } = await auth.confirmCode(payload);

            if (data.status === 1) {
                toast.dismiss("error-toast");
                toast.success("Password Changed Successfully, Please Login", {
                    id: "error-toast",
                });
                if (onSuccess) {
                    onSuccess();
                }
                return true;
            } else if (data.data.includes("No changes were made!")) {
                toast.dismiss("error-toast");

                if (!forEmailVer) {
                    toast.error("Password Cannot be the same as old password", {
                        id: "error-toast",
                    });
                    return false;
                } else {
                    toast.success("Email Verified", { id: "error-toast" });
                    if (onSuccess) {
                        onSuccess();
                    }
                    return false;
                }
            } else {
                toast.error("Invalid OTP!");
                return false;
            }
        } catch (error) {
            toast.error("Failed to confirm code. Please try again.");
            return false;
        } finally {
            dispatch(loginLoading(false));
        }
    };

    export const verifyOTP =
    (payload, forEmailVer = false, onSuccess) =>
    async (dispatch) => {
        const requiredFields = ["email", "verify_code"];

        if (!validatePayload(payload, requiredFields)) {
            return false;
        }

        dispatch(loginLoading(true));

        try {
            const { data } = await auth.verifyOTP(payload);

            // Case 1: OTP Verification success
            if (data.status === 1 && !forEmailVer) {
                toast.dismiss("error-toast");
                toast.success("OTP Verified", { id: "error-toast" });

                if (onSuccess) {
                    onSuccess(); // Callback after OTP verification
                }
                return true;
            }

            // Case 2: Password Change success
            if (data.status === 1 && forEmailVer) {
                toast.dismiss("error-toast");
                toast.success("Password Changed Successfully, Please Login", { id: "error-toast" });

                if (onSuccess) {
                    onSuccess(); // Callback after password change
                }
                return true;
            }

            // Case 3: No changes made / Password is the same as old one
            if (data.data.includes("No changes were made!")) {
                toast.dismiss("error-toast");

                if (!forEmailVer) {
                    toast.error("Password Cannot be the same as old password", { id: "error-toast" });
                    return false;
                } else {
                    toast.success("Email Verified", { id: "error-toast" });
                    if (onSuccess) {
                        onSuccess();
                    }
                    return false;
                }
            }

            // Case 4: Invalid OTP
            toast.error("Invalid OTP!");
            return false;
        } catch (error) {
            toast.error("Failed to confirm code. Please try again.");
            return false;
        } finally {
            dispatch(loginLoading(false));
        }
    };


export const verifySaveEmail =
    (payload, forEmailVer = false, onSuccess) =>
    async (dispatch) => {
        const requiredFields = ["email", "code", "user_id"];

        if (!validatePayload(payload, requiredFields)) {
            return false;
        }

        dispatch(loginLoading(true));

        try {
            const { data } = await auth.verifySaveEmail(payload);

            if (data.status === 1) {
                // toast.dismiss("error-toast");
                toast.success(data.data);
                dispatch({ type: SET_EMAIL, payload: data.data.email });
                if (onSuccess) {
                    onSuccess();
                }
                return true;
            } else if (data.data.includes("Email already exists!")) {
                toast.error(data.data);

                //     if (!forEmailVer) {
                //         toast.error("Password Cannot be the same as old password", {
                //             id: "error-toast",
                //         });
                //         return false;
                //     } else {
                //         toast.success("Email Verified", { id: "error-toast" });
                //         if (onSuccess) {
                //             onSuccess();
                //         }
                //         return false;
                //     }
                // } else {
                //     toast.error("Invalid OTP!");
                //     return false;
            }
        } catch (error) {
            toast.error("Failed to confirm code. Please try again.");
            return false;
        } finally {
            dispatch(loginLoading(false));
        }
    };

export const verifyCodeFromEmail =
    (payload, forEmailVer = false, onSuccess) =>
    async (dispatch) => {
        const requiredFields = ["code", "user_id"];

        if (!validatePayload(payload, requiredFields)) {
            return false;
        }

        dispatch(loginLoading(true));

        try {
            const { data } = await auth.verifyCodeFromEmail(payload);

            if (data.status === 1) {
                toast.dismiss("error-toast");
                dispatch({ type: SET_EMAIL, payload: data.data.email });
                toast.success(data.data.msg);
                if (onSuccess) {
                    onSuccess();
                }
                return true;
            } else if (data.data.msg.includes("No changes were made!")) {
                toast.dismiss("error-toast");

                if (!forEmailVer) {
                    toast.error("Password Cannot be the same as old password", {
                        id: "error-toast",
                    });
                    return false;
                } else {
                    toast.success("Email Verified", { id: "error-toast" });
                    if (onSuccess) {
                        onSuccess();
                    }
                    return false;
                }
            } else {
                toast.error("Invalid OTP!");
                return false;
            }
        } catch (error) {
            toast.error("Failed to confirm code. Please try again.");
            return false;
        } finally {
            dispatch(loginLoading(false));
        }
    };

export const phoneResetPassword =
    (payload, forPhoneVer = false, onSuccess) =>
    async (dispatch) => {
        const requiredFields = ["phone", "verify_code", "password"];

        if (!validatePayload(payload, requiredFields)) {
            return false;
        }

        dispatch(loginLoading(true));

        try {
            const { data } = await auth.phoneResetPassword(payload);

            if (data.status === 1) {
                toast.dismiss("error-toast");
                toast.success("Password Changed Successfully, Please Login", {
                    id: "error-toast",
                });

                if (onSuccess) {
                    onSuccess(); // Trigger callback if provided
                }
                return true;
            }

            if (data.data.includes("No changes were made!")) {
                toast.dismiss("error-toast");

                if (!forPhoneVer) {
                    toast.error(
                        "Password cannot be the same as the old password",
                        {
                            id: "error-toast",
                        }
                    );
                    return false;
                } else {
                    toast.success("Email Verified", { id: "error-toast" });
                    if (onSuccess) {
                        onSuccess();
                    }
                    return true;
                }
            }

            toast.error(data.data || "Failed to reset the password.");
            return false;
        } catch (error) {
            console.error("Error in phoneResetPassword:", error);
            toast.error("Failed to reset the password. Please try again.");
            return false;
        } finally {
            dispatch(loginLoading(false));
        }
    };

export const emailSignup = (payload, onSuccess) => async (dispatch) => {
    const requiredFields = ["email", "password", "username"];
    if (!validatePayload(payload, requiredFields)) return;
    dispatch(loginLoading(true));
    try {
        const { data } = await auth.signup(payload);
        if (data && data.status === 1) {
            dispatch({ type: SIGNUP, payload: data.data.sid });
            // toast.success(data.msg);
            onSuccess();
        } else if (data.data.includes("Email already exists!")) {
            toast.dismiss("error-toast");
            toast.error("Email already exists! Please login", {
                id: "error-toast",
            });
        } else if (data.data.includes("Too many attempts")) {
            toast.dismiss("error-toast");
            toast.error("Too many attempts to register, Contact Support!", {
                id: "error-toast",
            });
        } else {
            toast.dismiss("error-toast");
            toast.error(data.msg || "Signup failed", { id: "error-toast" });
        }
    } catch (error) {
        toast.dismiss("error-toast");
        toast.error("Error during signup. Please try again.", {
            id: "error-toast",
        });
    } finally {
        dispatch(loginLoading(false));
    }
};

export const emailRegister = (payload, onSuccess) => async (dispatch) => {
    const requiredFields = ["email", "password", "username"];
    if (!validatePayload(payload, requiredFields)) return;
    dispatch(loginLoading(true));
    try {
        const { data } = await auth.emailRegister(payload);
        if (data && data.status === 1) {
            dispatch({ type: SIGNUP, payload: data.data.sid });
            // toast.success(data.msg);
            onSuccess();
        } else if (data.data.includes("Email already exists!")) {
            toast.dismiss("error-toast");
            toast.error("Email already exists! Please login", {
                id: "error-toast",
            });
        } else if (data.data.includes("Too many attempts")) {
            toast.dismiss("error-toast");
            toast.error("Too many attempts to register, Contact Support!", {
                id: "error-toast",
            });
        } else {
            toast.dismiss("error-toast");
            toast.error(data.msg || "Signup failed", { id: "error-toast" });
        }
    } catch (error) {
        toast.dismiss("error-toast");
        toast.error("Error during signup. Please try again.", {
            id: "error-toast",
        });
    } finally {
        dispatch(loginLoading(false));
    }
};

export const verifyEmail = (payload, onSuccess) => async (dispatch) => {
    const requiredFields = ["code"];
    if (!validatePayload(payload, requiredFields)) return;
    dispatch(loginLoading(true));
    try {
        const { data } = await auth.verifyEmail(payload);
        if (data.status === 1) {
            // toast.success("Email Verified Successfully!");
            dispatch({ type: VERIFY_CODE, payload: data.data.sid });
            toast.success("Email verified successfully, Please login");
            onSuccess();
        } else {
            // console.log("Email verification failed. Error data: ", data);
            // toast.dismiss("error-toast");
            toast.error(data.data);
        }
    } catch (error) {
        console.log("error: ", error);
        toast.dismiss("error-toast");
        toast.error("Email verification failed. Please try again.", {
            id: "error-toast",
        });
    } finally {
        dispatch(loginLoading(false));
    }
};

export const phoneSignup = (payload, onSuccess) => async (dispatch) => {
    const requiredFields = ["phone", "password", "username"];
    if (!validatePayload(payload, requiredFields)) return;
    dispatch(loginLoading(true));
    try {
        const { data } = await auth.phoneSignup(payload);
        if (data.status === 1) {
            dispatch({ type: PHONE_SIGNUP, payload: data.data.sid });
            toast.success(data.data.msg);
            // toast.success(data.data.code);
            alert(`Your verification code is: ${data.data.code}`);
            onSuccess();
        } else {
            toast.dismiss("error-toast");
            toast.error(data.data || "Signup failed", { id: "error-toast" });
        }
    } catch (error) {
        toast.dismiss("error-toast");
        toast.error("Error during signup. Please try again.", {
            id: "error-toast",
        });
    } finally {
        dispatch(loginLoading(false));
    }
};

export const verifyPhone = (payload, onSuccess) => async (dispatch) => {
    const requiredFields = ["code"];
    if (!validatePayload(payload, requiredFields)) return;
    dispatch(loginLoading(true));
    try {
        const { data } = await auth.verifyPhone(payload);
        if (data.status === 1) {
            dispatch({ type: VERIFY_CODE, payload: data.data.sid });
            toast.success("Phone verified successfully, Please login");
            onSuccess();
        } else if (data.status === 0) {
            toast.error("Invalid OTP!");
        } else {
            toast.error("Verification failed. Please check your input.");
        }
    } catch (error) {
        toast.dismiss("error-toast");
        toast.error("Phone verification failed. Please try again.", {
            id: "error-toast",
        });
    } finally {
        dispatch(loginLoading(false));
    }
};

export const verifyPhonePass = (payload, onSuccess) => async (dispatch) => {
    const requiredFields = ["code", "cellphone"];
    if (!validatePayload(payload, requiredFields)) return;
    dispatch(loginLoading(true));
    try {
        const { data } = await auth.verifyPhonePass(payload);
        if (data.status === 1) {
            dispatch({ type: VERIFY_CODE });
            toast.success("Phone verified successfully, Please login");
            onSuccess();
        } else if (data.status === 0) {
            toast.error("Invalid OTP!");
        } else {
            toast.error("Verification failed. Please check your input.");
        }
    } catch (error) {
        toast.dismiss("error-toast");
        toast.error("Phone verification failed. Please try again.", {
            id: "error-toast",
        });
    } finally {
        dispatch(loginLoading(false));
    }
};

export const verifyPhoneNew = (payload, onSuccess) => async (dispatch) => {
    const requiredFields = ["code", "cellphone"];
    if (!validatePayload(payload, requiredFields)) return;

    const authUser = JSON.parse(localStorage.getItem("authUser"));

    let user_id = null;
    if (authUser && authUser.user && authUser.user.ID) {
        user_id = authUser.user.ID; // Extract user ID
    } else {
        console.error("authUser is not in the expected format:", authUser);
    }

    if (user_id) {
        payload.user_id = user_id; // Add userId to payload
    } else {
        toast.error("User ID not found in local storage.");
        return; // Early return if userId is not available
    }

    dispatch(loginLoading(true));
    try {
        const { data } = await auth.verifyPhoneNew(payload);
        if (data.status === 1) {
            dispatch({ type: VERIFY_CODE, payload: data.data.sid });
            toast.success(data.data.msg);
            onSuccess();
        } else if (data.status === 0) {
            toast.error("Invalid OTP!");
        } else {
            toast.error(data.data.msg);
        }
    } catch (error) {
        toast.dismiss("error-toast");
        toast.error("Phone verification failed. Please try again.", {
            id: "error-toast",
        });
    } finally {
        dispatch(loginLoading(false));
    }
};

export const resendEmailOtp = (payload, onSuccess) => async (dispatch) => {
    const requiredFields = ["sid"];
    if (!validatePayload(payload, requiredFields)) return;
    dispatch(loginLoading(true));
    try {
        const { data } = await auth.resendEmailOtp(payload);
        // console.log("Response from resendEmailOtp API:", data);
        if (data.status === 1) {
            toast.success(data.data);
            onSuccess();
        } else {
            toast.dismiss("error-toast");
            toast.error(data.data, { id: "error-toast" });
        }
    } catch (error) {
        toast.dismiss("error-toast");
        // toast.error("Failed to resend email OTP. Please try again.", {
        //     id: "error-toast",
        // });
        // console.error("Error resending email OTP:", error);
    } finally {
        dispatch(loginLoading(false));
    }
};

export const resendPhoneOtp = (payload, onSuccess) => async (dispatch) => {
    const requiredFields = ["sid"];
    if (!validatePayload(payload, requiredFields)) return;
    dispatch(loginLoading(true));
    try {
        const { data } = await auth.resendPhoneOtp(payload);
        if (data.status === 1) {
            toast.success(data.data.msg);
            // toast.success(data.data.code)
            alert(`Your verification code is: ${data.data.code}`);
            onSuccess();
        } else {
            toast.dismiss("error-toast");
            toast.error(data.data, { id: "error-toast" });
        }
    } catch (error) {
        toast.dismiss("error-toast");
        // toast.error("Failed to resend phone OTP. Please try again.", {
        //     id: "error-toast",
        // });
    } finally {
        dispatch(loginLoading(false));
    }
};

export const logout = () => async (dispatch) => {
    try {
        sessionStorage.clear();
        console.log("Clearing local storage...");
        localStorage.clear();
        dispatch({ type: LOGOUT });
        localStorage.clear();
        console.log("Local storage after clearing:", localStorage);

        toast.success("Logged out successfully");
    } catch (error) {
        toast.error("Logout failed. Please try again.");
    }
};
export const loginLoading = (val) => async (dispatch) => {
    dispatch({ type: LOADING, payload: val });
};

export const googleLoginNew = (email, onSuccess) => async (dispatch) => {
    dispatch({ type: "FETCH_GOOGLE_LOGIN_REQUEST" });
    try {
        const response = await auth.googleLogin({ email });
        console.log(response);

        if (response.data.status === 1) {
            dispatch({
                type: LOGIN,
                payload: response.data.data,
            });
            dispatch({
                type: SET_PASSWORD,
                payload: {
                    email: email, // use the username as email
                    password: null, // keep the password from input
                },
            });
            toast.success(response.data.data.msg);
            onSuccess(response);
            return true;
        } else {
            dispatch({
                type: "FETCH_GOOGLE_LOGIN_FAILURE",
                payload: response.data,
            });
            toast.error(response.data.data);
        }
    } catch (error) {
        console.log("Google Error: ", error.message);
        dispatch({
            type: "FETCH_GOOGLE_LOGIN_FAILURE",
            payload: error.message,
        });
    }
};

export const googleRegisterNew = (email, onSuccess) => async (dispatch) => {
    dispatch({ type: "FETCH_GOOGLE_LOGIN_REQUEST" });
    try {
        // Extract username from email
        const username = email.split("@")[0];

        const response = await auth.googleRegister({ email, username });

        if (response.data.status === 1) {
            dispatch({
                type: LOGIN,
                payload: response.data.data,
            });
            toast.success(response.data.data.msg);
            onSuccess();
            return true;
        } else {
            dispatch({
                type: "FETCH_GOOGLE_REGISTER_FAILURE",
                payload: response.data,
            });
            console.log("Register Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("Register Error: ", error.message);
        dispatch({
            type: "FETCH_GOOGLE_REGISTER_FAILURE",
            payload: error.message,
        });
    }
};
export const resetPasswordEmail = (payload) => async (dispatch) => {
    const requiredFields = ["email", "email_verify", "password", "repassword"];
    console.log("Payload data: ", payload);

    if (!validatePayload(payload, requiredFields)) return false;

    dispatch({ type: LOADING, payload: true });

    try {
        
        const response = await auth.resetPasswordEmail(payload);
    

        if (response?.data?.status === 1) {
            toast.success(response?.data?.message || "Password reset successfully!");
            return true; 
        } else {
            toast.error(response?.data || "Failed to reset password.");
            return false; 
        }

    } catch (error) {
        const errorMessage =
            error.response?.data?.message ||
            "An unexpected error occurred while resetting the password.";

        if (
            errorMessage.includes("unusual attempts") ||
            errorMessage.includes("contact support")
        ) {
            toast.error("We detected unusual activity from your IP. Please contact support.");
        } else {
            toast.error(errorMessage);
        }

        console.error("Error resetting password:", error);
        return false; // Return false in case of error
    } finally {
        dispatch({ type: LOADING, payload: false });
    }
};
