import React, { useState } from 'react';
import USDM from './USDM';
import CoinM from './CoinM';
import Basic from './Basic';
import Conditional from './Conditional';
import HistoryDropdown from '../../SpotHistory/DropDownUI';

const OpenOrder = () => {
    const [activeSubItem, setActiveSubItem] = useState('USDⓈ-M');
    const subNavItems = ['USDⓈ-M', 'COIN-M'];

    const coinMSubNavItems = ['Basic', 'Conditional'];
    const [activeCoinMSubItem, setActiveCoinMSubItem] = useState('Basic');

    const options1 = ["All", "Limit Order", "Stop-Limit Order", "Stop-Market Order", "Take-Profit-Limit Order", "Take-Profit-Market Order"];
    const options2 = ["All", "BTCUSDT", "ETHUSDT", "XRPUSDT", "LTCUSDT", "ADAUSDT"];
    const options3 = ["All", "BTC", "ETH", "XRP", "LTC", "ADA"];
    const options4 = ["All", "Buy", "Sell"]
    const handleSelect1 = (selected) => {
        console.log("Dropdown 1 selected:", selected);
    };

    const renderSubContent = () => {
        if (activeSubItem === 'COIN-M') {
            return (
                <>
                    {/* <CoinM /> */}
                    <div className="mt-4">
                        {activeCoinMSubItem === 'Basic' ? <Basic /> : <Conditional />}
                    </div>
                </>
            );
        }

        switch (activeSubItem) {
            case 'USDⓈ-M':
                return <USDM />;
            default:
                return <USDM />;
        }
    };

    return (
        <div className='px-3'>
            <div className='container mt-5 !p-0'>
                <div className='flex gap-4 items-center'>
                    <nav className='inline-flex flex-wrap border rounded-lg pt-[0.20rem] pb-[0.25rem] px-1 gap-2'>
                        {subNavItems.map((item) => (
                            <button
                                key={item}
                                onClick={() => setActiveSubItem(item)}
                                className={`w-auto py-1.5 px-3 text-sm font-medium ${activeSubItem === item ? 'bg-[#F5F5F5] rounded-md' : 'text-gray-400'
                                    }`}
                            >
                                {item}
                            </button>
                        ))}
                    </nav>

                    {activeSubItem === 'COIN-M' && (
                        <nav className='inline-flex flex-wrap border rounded-lg pt-[0.20rem] pb-[0.25rem] px-1 gap-2'>
                            {coinMSubNavItems.map((item) => (
                                <button
                                    key={item}
                                    onClick={() => setActiveCoinMSubItem(item)}
                                    className={`w-auto py-1.5 px-3 text-sm font-medium ${activeCoinMSubItem === item ? 'bg-[#F5F5F5] rounded-md' : 'text-gray-400'
                                        }`}
                                >
                                    {item}
                                </button>
                            ))}
                        </nav>
                    )}
                    <HistoryDropdown
                        label="Order Type"
                        options={options1}
                        initialSelected="All"
                        onSelect={handleSelect1}
                        isSearchable={false}
                        width="200px"
                    />
                    <HistoryDropdown
                        label="Symbol"
                        options={activeSubItem === 'COIN-M' ? options3 : options2}
                        initialSelected="All"
                        onSelect={handleSelect1}
                        isSearchable={true}
                        width="100px"
                    />
                    <HistoryDropdown
                        label="Side"
                        options={options4}
                        initialSelected="All"
                        onSelect={handleSelect1}
                        isSearchable={false}
                        width="100px"
                    />
                    <button className='text-[#EFB81C] font-medium'>Reset</button>
                </div>

                <div className="mt-6">{renderSubContent()}</div>
            </div>
        </div>
    );
};

export default OpenOrder;
