import React, { useState } from "react";
import Support from "assets/img/support.png";
function SubmitCase() {
    // const [value , setValue] = useState('')
    const [selected, setSelected] = useState("");
    const handleChange = (e) => {
        console.log(e.target.value);
        setSelected(e.target.value);
    };
    return (
        <div>
            <section className="my-5 min-h-screen">
                <div className="container">
                    <h2 className="text-3xl my-3 font-semibold ">
                        Submit Case
                    </h2>
                    <p className="text-[16px]   ">
                        Kindly navigate the options below ( 5 mins to complete)
                        to submit a request ticket. Our dedicated 24/7 customer
                        support will get back to you shortly.
                    </p>

                    <div className="bg-[#FFF4DD] p-3 mt-3 rounded text-[14px] flex   gap-1 ">
                        <img
                            className="w-[20px] h-[20px]  "
                            src={Support}
                            alt="img"
                        />
                        <span className=" text-[14px] text-black">
                            Alert! We are currently experiencing a large volume
                            of inquiries. To facilitate a faster response time,
                            please do not resubmit if you have already submitted
                            your inquiry through email or webform previously.
                            Instead, we would like to invite you to find out
                            more about the commonly asked questions at the Bybit
                            Help Center here Thank you for your support!
                        </span>
                    </div>
                    <form class=" md:max-w-sm max-w-full mt-4 ">
                        <div className="mt-3">
                            <label
                                for="countries"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Select an option
                            </label>
                            <select
                                id="countries"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block   p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full
  overflow-hidden"
                                onChange={(e) => handleChange(e)}
                                value={selected}
                            >
                                <option>
                                    {" "}
                                    Bybit account login, KYC and other requests
                                </option>
                                <option>
                                    {" "}
                                    Deposits, withdrawals and assets exchange
                                </option>
                                <option> P2P Trading</option>
                                <option>
                                    {" "}
                                    Account hacked / Abnormal activities
                                </option>
                                <option> Spoofing / Scam / Fraud </option>
                                <option>
                                    Bybit Campaigns, Rewards and Launchpad{" "}
                                </option>
                                <option>
                                    {" "}
                                    Bybit Earn (Bybit Savings, Dual Asset,
                                    Liquidity Mining, ETH 2.0, Smart Leverage,
                                    Double Win, Wealth Management){" "}
                                </option>
                                <option>
                                    {" "}
                                    Derivatives Trading (Perpetual and Futures,
                                    Copy Trading, Trading Bot){" "}
                                </option>
                                <option> Unified Account </option>
                                <option>
                                    {" "}
                                    Crypto Trading (Spot & Margin Trading,
                                    Pre-Market Trading, Convert, Bybit Leveraged
                                    Token){" "}
                                </option>
                                <option>
                                    {" "}
                                    Finance (Bybit Card, Crypto Loan){" "}
                                </option>
                                <option> NFT </option>
                                <option>
                                    {" "}
                                    API Trading / Report a Security
                                    Vulnerability{" "}
                                </option>
                                <option> Law Enforcement Request </option>
                                <option> Affiliate related inquiries</option>
                            </select>
                        </div>
                        <div className="mt-3">
                            <label
                                for="countries"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Deposit, withdrawal and asset exchange
                            </label>
                            <select
                                id="countries"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => handleChange(e)}
                                value={selected}
                            >
                                <option>
                                    {" "}
                                    I encountered an issue with my Bybit
                                    deposits
                                </option>
                                <option>
                                    {" "}
                                    I encountered an issue with my Bybit
                                    withdrawals
                                </option>
                            </select>
                        </div>

                        <div className="mt-3">
                            <label
                                for="countries"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Select a method of deposit
                            </label>
                            <select
                                id="countries"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => handleChange(e)}
                                value={selected}
                            >
                                <option> Buy Crypto Express</option>
                                <option> Crypto Deposit</option>
                                <option> Fiat Deposit</option>
                            </select>
                        </div>
                        <div className="mt-3">
                            <label
                                for="countries"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Fiat deposit
                            </label>
                            <select
                                id="countries"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => handleChange(e)}
                                value={selected}
                            >
                                <option> Why is my payment rejected?</option>
                                <option>
                                    My bank is charged but I do not see the
                                    deposit in my Bybit Funding Account
                                </option>
                                <option>
                                    {" "}
                                    I want to remove/change the Tax ID
                                    (CPF/CUIT/Turkish Identity No./ CURP) linked
                                    to Bybit
                                </option>
                                <option> I am getting an error code</option>
                                <option> Suggestions and Feedback</option>
                                <option>My inquiry is not listed above</option>
                            </select>
                        </div>
                    </form>

                    <div className="bg-[#FFF4DD]  mt-3 rounded text-[14px]     border border-[#f9c828] ">
                        <h5 className=" p-3">Useful Tips</h5>

                        <div className=" border-b border-b-[#ccc] ">
                            <span className=" border-b-2 border-b-[#171212]  text-base font-bold px-3">
                                Note
                            </span>
                        </div>
                        <div className=" p-3 block">
                            <span className=" text-[14px] text-black ">
                                The processing time can be different depending
                                on the fiat currency and payment method
                                selected. You can find the estimated processing
                                time for each fiat by going to the Fiat deposit
                                page and selecting the fiat currency of your
                                choice. Please also read through the tips next
                                to the order window for more details. If the
                                order is charged and has exceeded the processing
                                time, kindly submit this form. Thank you
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SubmitCase;
