import React, { useState } from 'react';
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const ChangePassword = ({ nextStep }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (newPassword !== confirmPassword) {
      toast.error("New Password and Confirm Password do not match");
      return;
    }
  
    const formData = new FormData();
    formData.append('oldpaypassword', oldPassword);
    formData.append('newpaypassword', newPassword);
    formData.append('repaypassword', confirmPassword);
  
    try {
      const response = await fetch('https://api.buycex.com/User/changeFundPassword', {
        method: 'POST',
        headers: {
          'ID': userID,
          'TOKEN': userToken,
        },
        body: formData,
      });
  
      const result = await response.json(); // Parse the JSON response
  
      if (result.status === 1) {
        // console.log('Success:', result);
        toast.success(result.data || 'Password changed successfully'); // Show success message from data
        nextStep();
      } else if (result.status === 0) {
        // console.error('Error data:', result);
        toast.error(result.data || 'Error changing password'); // Show error message from data
      }
    } catch (error) {
      // console.error('Error:', error);
      toast.error(`Error changing password: ${error.message}`);
    }
  };  

  return (
    <div className="p-4">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <h1 className="font-semibold mt-4">Old Password</h1>
          <div className="rounded-md w-full bg-gray-100 bg-opacity-70 mt-1 py-2 px-3">
            <input
              type="password"
              className="outline-none bg-transparent w-full"
              placeholder="Enter old password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <h1 className="font-semibold mt-4">New Password</h1>
          <div className="rounded-md w-full bg-gray-100 bg-opacity-70 mt-1 py-2 px-3">
            <input
              type="password"
              className="outline-none bg-transparent w-full"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <h1 className="font-semibold mt-2">Confirm Password</h1>
          <div className="rounded-md w-full bg-gray-100 bg-opacity-70 mt-1 py-2 px-3">
            <input
              type="password"
              className="outline-none bg-transparent w-full"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mt-4 text-center">
          <button
            type="submit"
            className="w-full bg-yellow-500 font-semibold text-white py-2 rounded-md"
          >
            Confirm
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;