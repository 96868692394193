import React, { useEffect, useRef, useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";

const faqData = [
  {
    question: "What is Buycex Trading Bot?",
    answer: "Buycex Trading Bot is a one-stop platform for automated crypto trading. It offers various types of pre-configured and code-free trading robots. Buycex Trading Bot provides users with effective trading strategies that save time and improve their investment returns at no additional cost.",
  },
  {
    question: "What is Spot Grid Bot?",
    answer: "Spot Grid is a trading strategy based on price volatility, commonly used in the Spot trading market. The strategy establishes buy and sell grids by setting a series of buy and sell price points with a certain price interval, and buys or sells when the market price touches the grid to achieve profit.",
  },
  {
    question: "What is DCA Bot?",
    answer: "DCA (Dollar-Cost Averaging) is a long-term investment strategy commonly used in assets such as digital currencies. The strategy invests a certain amount of funds in the target asset regularly to obtain the average cost price and holds the target asset for a long time to achieve long-term investment returns.",
  },
  {
    question: "What is Futures Grid Bot?",
    answer: "Futures Grid is a trading strategy based on price volatility, commonly used in the Futures trading market. The strategy is similar to Spot Grid, establishing buy and sell grids by setting a series of buy and sell price points with a certain price interval, and buying or selling when the market price touches the grid to achieve profit.",
  },
  {
    question: "How do I choose the best trading bot for me?",
    answer: "Buycex offers a range of Trading Bots, each designed to capitalize on specific market conditions and investment objectives. Choose the most suitable trading bot for your goals: 1. Grid Bot: Designed to profit from sideways markets by buying low and selling high. 2. DCA Bot: Accumulate your desired cryptocurrency at regular intervals in both bull and bear markets. 3. Martingale Bot: Double down and bounce back quickly from losses.",
  },
  {
    question: "Are there any fees associated with Buycex Trading Bot?",
    answer: "Buycex Trading Bot incurs no additional fees. Trading fees are based on your VIP status and are calculated when your orders are filled successfully.",
  },
  {
    question: "Who can use the Buycex Trading Bot?",
    answer: "Everyone! Beginners can use our Auto-Fill function to easily create a trading bot with pre-configured parameters, or refer to our Homepage for high-APR bot parameters! Advanced users can join our community to get the latest market analysis and other recommended trading strategies.",
  },
  {
    question: "Understanding the Martingale Strategy",
    answer: "Martingale is a popular strategy that initially emerged from gambling but has found applications in various financial markets, including cryptocurrency trading. The fundamental concept involves doubling the investment after each loss with the goal of recovering prior losses and achieving a profit equal to the initial investment. This strategy operates on the assumption that a winning trade will eventually occur after a series of losing trades, effectively covering the accumulated losses and generating a profit.",
  },
];

const Faq = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const faqRef = useRef(null);

    const handleToggle = (index) => {
        setOpenIndex(prevIndex => (prevIndex === index ? null : index));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (faqRef.current && !faqRef.current.contains(event.target)) {
                setOpenIndex(null);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <section className="relative container mt-20 flex flex-col items-center justify-center w-full bg-transparent rounded-t-3xl bottom-10 gap-y-2">
            <h6 className="w-full ml-2 text-2xl text-black dark:text-white font-semibold">FAQ</h6>
            <div
                className="flex flex-col gap-y-5 md:gap-y-0 md:flex-row md:gap-x-5 items-center justify-center md:items-start md:justify-evenly xl:justify-center xl:gap-x-10 w-[100%] xl:w-[100%] mt-3"
                ref={faqRef}
            >
                {/* Left FAQs */}
                <div className="flex flex-col items-center justify-center w-[100%] md:w-[350px] lg:w-[650px] xl:w-[800px] gap-y-5">
                    {faqData.slice(0, 3).map((faq, index) => (
                        <div
                            key={index}
                            className={`${
                                openIndex === index ? "max-h-[500px]" : "max-h-[97px]"
                            } shadow-sm border flex flex-col bg-transparent dark:bg-[#1A191B] w-[100%] rounded-xl transition-all duration-500 p-4 overflow-hidden`}
                            style={{ transition: 'max-height 0.5s ease' }} // Smooth transition
                        >
                            <div className="flex items-center justify-between mt-1">
                                <div className="text-15px lg:text-[17px] flex items-center gap-x-4 w-full">
                                    <h6 className={`py-[5px] max-w-[88%] text-black dark:text-white font-semibold`}>
                                        {faq.question}
                                    </h6>
                                </div>
                                <div className="cursor-pointer bg-transparent" onClick={(e) => {
                                    e.stopPropagation();
                                    handleToggle(index);
                                }}>
                                    {openIndex === index ? (
                                        <FiMinus
                                            size={37}
                                            className="transition-all duration-700 p-[6px] bg-[#efb81c] rounded-md text-[#ffffff]"
                                        />
                                    ) : (
                                        <FiPlus
                                            size={37}
                                            className="transition-all duration-700 p-[6px] bg-[#f7f7ff]  dark:bg-[#1A191B]  rounded-md text-[#6f6c8f] dark:text-white"
                                        />
                                    )}
                                </div>
                            </div>
                            {openIndex === index && (
                                <div className="flex flex-col text-sm mt-[35px] text-black dark:text-white gap-y-5 lg:text-base">
                                    <p>{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* Right FAQs */}
                <div className="flex flex-col items-center justify-center w-[100%] md:w-[350px] lg:w-[650px] xl:w-[800px] gap-y-5">
                    {faqData.slice(3, 6).map((faq, index) => (
                        <div
                            key={index + 3} // Adjust index for the second column
                            className={`${
                                openIndex === index + 3 ? "max-h-[500px]" : "max-h-[97px]"
                            } shadow-sm border bg-transparent dark:bg-[#1A191B] flex flex-col w-[100%] rounded-xl transition-all duration-500 p-4 overflow-hidden`}
                            style={{ transition: 'max-height 0.5s ease' }} // Smooth transition
                        >
                            <div className="flex items-center justify-between mt-1">
                                <div className="text-15px lg:text-[17px] flex items-center gap-x-4 w-full">
                                    <h6 className={`py-[5px] max-w-[88%] text-black dark:text-white font-semibold`}>
                                        {faq.question}
                                    </h6>
                                </div>
                                <div className="cursor-pointer bg-transparent" onClick={(e) => {
                                    e.stopPropagation();
                                    handleToggle(index + 3);
                                }}>
                                    {openIndex === index + 3 ? (
                                        <FiMinus
                                            size={37}
                                            className="transition-all duration-700 p-[6px] bg-[#efb81c] rounded-md text-[#ffffff]"
                                        />
                                    ) : (
                                        <FiPlus
                                            size={37}
                                            className="transition-all duration-700 p-[6px] bg-[#f7f7ff]  dark:bg-[#1A191B]  rounded-md text-[#6f6c8f] dark:text-white"
                                        />
                                    )} 
                                </div>
                            </div>
                            {openIndex === index + 3 && (
                                <div className="flex flex-col text-black dark:text-white text-sm mt-[35px] gap-y-5 lg:text-base">
                                    <p>{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Faq;
