import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkKycStatus } from "store/actions/idVerificationAction";
import { BsXCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/system";

const UpdateSecurityLevel = ({ setAllVerified }) => {
    const dispatch = useDispatch();
    const [showWarning, setShowWarning] = useState(false);
    const [removeWarning, setRemoveWarning] = useState(false);

    const isMobile = useMediaQuery("(max-width: 911px)");

    const { userID, userToken } = useSelector((state) => ({
        userID: state.authUser?.user?.ID,
        userToken: state.authUser?.user?.TOKEN,
    }));

    const kycStatus = useSelector(
        (state) => state.idVerification?.basicData?.verified ?? null
    );

    useEffect(() => {
        if (userID && userToken) {
            dispatch(checkKycStatus(userID, userToken));
        }
    }, [dispatch, userID, userToken]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowWarning(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    // Set allVerified state based on kycStatus
    useEffect(() => {
        setAllVerified(kycStatus === 1);
    }, [kycStatus, setAllVerified]);

    const handleClose = () => {
        setShowWarning(false);
        setTimeout(() => {
            setRemoveWarning(true);
        }, 1000);
    };

    const allVerified = kycStatus === 1;

    if (!userID || !userToken) {
        return null;
    }

    if (kycStatus === null) {
        return null;
    }

    return (
        <>
            {!allVerified && !removeWarning && (
                <div
                    className={`sticky top-[48px] z-[999] bg-[#EFB81C] text-[#151415] p-4 transform duration-1000  ${
                        showWarning
                            ? "translate-y-0 opacity-100"
                            : "-translate-y-20 opacity-0"
                    }`}
                    style={{
                        transitionTimingFunction:
                            "cubic-bezier(0.16, 1, 0.3, 1)",
                    }}
                >
                    <div className="flex justify-between items-center w-full gap-2.5">
                        <div
                            className={`flex items-center gap-1 w-full ${
                                isMobile && "justify-between"
                            }`}
                        >
                            <p
                                className={`font-[500] ${
                                    isMobile
                                        ? "text-[12px] max-w-[75%]"
                                        : "text-[14px]"
                                }`}
                            >
                                Please Complete Verification Lv. 1 to continue
                                using BUYCEX services.
                            </p>
                            <Link
                                to="/user/account/identity-verification/information"
                                className="underline text-[14px] font-[600]"
                                title="Verify Now"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span
                                    className={`text-[14px] ${
                                        isMobile && "text-[12px]"
                                    }`}
                                >
                                    Verify Now
                                </span>
                            </Link>
                        </div>
                        <button
                            type="button"
                            onClick={handleClose}
                            className="w-max text-[#151415] focus:outline-none"
                            title="Close"
                        >
                            <BsXCircleFill size={18} />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default UpdateSecurityLevel;
