import React from 'react';

const OnlineAdsNav = ({ setActiveComponent = () => {}, activeComponent }) => {
  if (typeof setActiveComponent !== 'function') {
    console.error('setActiveComponent is not a function');
    return null;
  }

  const navItems = [
    { name: 'Online Ads', label: 'Online Ads' },
    { name: 'Feedback (3)', label: 'Feedback (346)' },
  ];

  return (
    <ul className="flex gap-5 overflow-x-auto hide-scrollbar mb-10" >
      {navItems.map((item) => (
        <li key={item.name}>
          <button
            className={`px-6 py-3 font-semibold text-sm ${
              activeComponent === item.name ? 'bg-[#EFB81C33] text-[#EFB81C] rounded-lg' : 'text-gray-500'
            }`}
            onClick={() => setActiveComponent(item.name)}
            style={{minWidth:'max-content'}}
          >
            {item.label}
          </button>
        </li>
      ))}
          <style>
    {`
    /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
    .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for Firefox */
    .hide-scrollbar {
        scrollbar-width: none;
    }
    `}
  </style>
  </ul>
  );
};

export default OnlineAdsNav;
