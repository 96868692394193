// P2pPaymentMethod.js
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { AiOutlineFileSearch } from "react-icons/ai";
import { CgLoadbar } from "react-icons/cg";
import { IoIosArrowForward } from "react-icons/io";
import SelectMethod from "./P2pPaymentMethodComponents/SelectMethod";
import { Link } from "react-router-dom/cjs/react-router-dom";

function P2pPaymentMethod() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <div className="mt-5 mb-8">
                <span className="text-[20px] font-bold">Payment Methods</span>
                <div className="flex flex-col md:flex-col lg:flex-row">
                    <div className="w-full lg:w-[75%] md:w-full">
                        <p className="text-[14px] w-full md:w-full lg:w-[75%] text-[#707A8A] pb-10 pt-3">
                            P2P payment methods: When you sell cryptocurrencies, the payment method added will be displayed to buyer
                            as options to accept payment, please ensure that the account owner’s name is consistent with your verified
                            name on Buycex. You can add up to 20 payment methods.
                        </p>
                    </div>
                    <div className="relative w-full lg:w-1/4">
                        <button
                            className="w-full flex items-center gap-2 justify-center bg-[#F1F1F1] rounded-md text-sm h-[45px] px-3 py-3 font-semibold"
                            onClick={toggleDropdown}
                        >
                            <FaPlus /> Add a payment method
                        </button>
                        {dropdownOpen && (
                            <div className="absolute w-full mt-1 bg-white shadow-lg rounded-lg z-10">
                                <ul className="py-2">
                                    <Link to='/trade/p2p/payment/bank'>
                                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center">
                                        <CgLoadbar className='rotate-90 text-[#EFB81C]' />
                                        <h3 className='text-sm mb-0'>Bank Transfer</h3>
                                    </li>
                                    </Link>
                                    <li
                                        className="px-4 py-2 hover:text-[#EFB81C] hover:bg-gray-100 cursor-pointer text-sm mb-0 flex items-center justify-between"
                                        onClick={showModal}
                                    >
                                        More <IoIosArrowForward />
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className="h-52 flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                        <AiOutlineFileSearch className="text-[60px] text-[#707A8A]" />
                        <p className="mt-3 text-[#474D57]">You have not added any payment methods</p>
                    </div>
                </div>
            </div>

            {/* Use the new PaymentMethodModal component */}
            <SelectMethod
                isVisible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            />
        </>
    );
}

export default P2pPaymentMethod;
