import React from 'react';

const Launchnavbar = () => {
  return (
    <nav className="flex space-x-8 py-4 ml-8 pl-8">
      <a 
        href="/launchpad" 
        className="font-semibold border-b-4 border-transparent hover:bg-[#EFB81C] transition duration-300"
      >
        Startup
      </a>
      <a 
        href="/startup-mining" 
        className="text-gray-500 hover:text-black border-b-4 border-transparent hover:bg-[#EFB81C] transition duration-300"
      >
        Startup Mining
      </a>
      <a 
        href="/startup-task" 
        className="text-gray-500 hover:text-black border-b-4 border-transparent hover:bg-[#EFB81C] transition duration-300"
      >
        Startup Task                                     
      </a>
    </nav>
  );
};

export default Launchnavbar;
