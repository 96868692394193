import { RepositoryFactory } from "repository/RepositoryFactory";
import {
    GET_PAYMENT_METHODS_FAILURE,
    GET_PAYMENT_METHODS_REQUEST,
    GET_PAYMENT_METHODS_SUCCESS,
    GET_FIAT_DEPOSIT_HISTORY_FAILURE,
    GET_FIAT_DEPOSIT_HISTORY_REQUEST,
    GET_FIAT_DEPOSIT_HISTORY_SUCCESS,
} from "../types";
import toast from "react-hot-toast";

const FiatDepositRepository = RepositoryFactory.get("fiatDeposit");

export const FinanceDeposit = (userid, token, payload) => async (dispatch) => {
    dispatch({ type: "FETCH_STAKING_DATA_REQUEST" });
    try {
        const response = await FiatDepositRepository.FinanceDeposit(userid, token,payload);

        console.log(response.data.data);
        
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_STAKING_DATA_SUCCESS",
                payload: response.data.data,
            });
            toast.success(response.data.data);
            return response
        } else {
            dispatch({
                type: "FETCH_STAKING_DATA_FAILURE",
                payload: response.data.message,
            });
            toast.error(response.data.data);
        }
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_STAKING_DATA_FAILURE", payload: error.message });
    }
};

export const getPaymentMethods =
    (userId, token, coinname) => async (dispatch) => {
        dispatch({ type: GET_PAYMENT_METHODS_REQUEST });
        try {
            const response = await FiatDepositRepository.getPaymentMethods(
                userId,
                token,
                coinname
            );
            if (response.data && response.data.status === 1) {
                dispatch({
                    type: GET_PAYMENT_METHODS_SUCCESS,
                    payload: response.data,
                });
                return response
            } else {
                dispatch({
                    type: GET_PAYMENT_METHODS_FAILURE,
                    payload: response.data,
                });
                return response
            }
        } catch (error) {
            dispatch({ type: GET_PAYMENT_METHODS_FAILURE, error });
        }
    };

export const fiatDepositHistory =
    (userId, token, coinname) => async (dispatch) => {
        dispatch({ type: GET_FIAT_DEPOSIT_HISTORY_REQUEST });
        try {
            const response = await FiatDepositRepository.fiatDepositHistory(
                userId,
                token,
                coinname
            );
            if (response.data && response.data.status === 1) {
                dispatch({
                    type: GET_FIAT_DEPOSIT_HISTORY_SUCCESS,
                    payload: response.data,
                });
            } else {
                dispatch({
                    type: GET_FIAT_DEPOSIT_HISTORY_FAILURE,
                    payload: response.data,
                });
            }
        } catch (error) {
            dispatch({ type: GET_FIAT_DEPOSIT_HISTORY_FAILURE, error });
        }
    };
