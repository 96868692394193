import axios from "axios";

const baseDomain = "https://api.buycex.com/";
const baseURL = `${baseDomain}`;

const axiosObj = axios.create({
    baseURL,
    headers: {},
});

export default axiosObj;
