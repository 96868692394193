import UserProfileRepository from "repository/UserRepository";
import {
    FETCH_USER_PROFILE_FAILURE,
    FETCH_USER_PROFILE_REQUEST,
    FETCH_USER_PROFILE_SUCCESS,
} from "../types";

// actions/userProfileAction.js
export const fetchUserProfile = (userId, token) => async (dispatch) => {
    dispatch({ type: FETCH_USER_PROFILE_REQUEST });
    try {
        const response = await UserProfileRepository.getUserInfo(userId, token);

        const userProfile = response.data.data[0];
        if (response.data.status === 1) {
            dispatch({
                type: FETCH_USER_PROFILE_SUCCESS,
                payload: userProfile,
            });
        } else {
            dispatch({
                type: FETCH_USER_PROFILE_FAILURE,
                payload: "Failed to fetch user profile",
            });
        }
    } catch (error) {
        dispatch({ type: FETCH_USER_PROFILE_FAILURE, payload: error.message });
    }
};

export const fetchUserDashboard = (userId, token) => async (dispatch) => {
    dispatch({ type: "FETCH_USER_DASHBOARD_REQUEST" });
    try {
        const response = await UserProfileRepository.getUserDashboard(
            userId,
            token
        );
      
        const userDashboard = response.data.data;
        console.log("respone",userDashboard);
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_USER_DASHBOARD_SUCCESS",
                payload: userDashboard,
            });
        } else {
            dispatch({
                type: "FETCH_USER_DASHBOARD_FAILURE",
                payload: "Failed to fetch user dashboard",
            });
        }
    } catch (error) {
        dispatch({
            type: "FETCH_USER_DASHBOARD_FAILURE",
            payload: error.message,
        });
    }
};
