import React from "react";
import { NavLink } from "react-router-dom";
import { GoDotFill } from "react-icons/go";

const FAQSection = () => {
    const faqs = [
        "Crypto Withdrawal FAQs",
        "How to Withdraw Through Internal Transfer",
        "View the Deposit/Withdrawal Status of All Coins",
        "How to Change Your Withdrawal Limit",
        "How to Manage Your Withdrawal Address Book",
    ];

  return (
    <section className="pl-8 w-100 md:w-1/2">
      <div className="font-semibold text-[22px] md:text-3xl sm:mx-4">FAQ</div>
      <ul className="pt-2">
        {faqs.map((faq, index) => (
          <div key={index} className="flex items-center">
            <GoDotFill style={{ fontSize: "12px" }} />
            <li className="px-2 py-2">
              <NavLink
                to={`/${faq.toLowerCase().replace(/ /g, "-")}`}
                className="text-black hover:text-[#EFB81C] hover:no-underline dark:text-white dark:hover:text-[#EFB81C]"
              >
                {faq}
              </NavLink>
            </li>
          </div>
        ))}
      </ul>
    </section>
  );
};

export default FAQSection;
