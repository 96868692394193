import React from 'react';


const ProgressBar = ({ step }) => {
  return (
    <div className="flex  justify-between items-center w-[80%] mx-auto my-10 relative">
      <div className="absolute top-1/2 w-full h-[1px] bg-gray-300 transform -translate-y-1/2"></div>
      <div className="absolute top-1/2 h-[2px]  bg-yellow-500 transform -translate-y-1/2 transition-all" style={{ width: `${(step-1 ) * 33.33}%` }}></div>
      {['Reset Confirmation', 'Security Authentication', 'Identity Verification', 'Submit Successfully'].map((title, idx) => (
        <div key={idx} className={` bg-white text-black text-sm px-2 py-1  rounded-2xl flex justify-center items-center relative ${step > idx ? '  border-yellow-500 border-2' : 'border '}`}>
          {title}
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
