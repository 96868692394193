import React, { useState, useEffect } from "react";
import { Carousel, Select, Input } from "antd";
import { IoArrowForward } from "react-icons/io5";
import styled from "styled-components";
import slide1 from "assets/img/bank-logo.svg";
import slide2 from "assets/img/express-Slide-2.png";
import slide3 from "assets/img/express-Slide-3.png";

import leadingBackground from "assets/img/leading-crypto-bg.png";
import million20 from "assets/img/20-million.png";
import p2pSymbol from "assets/img/p2p-symbol.png";
import graphMillion from "assets/img/million-graph.png";
import FaQs from "components/FAQs";
import buySellTab from "assets/img/buy-sell-tab.png";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
import Popup from "./ExpressComponents/BuyPaymentMethod";

import P2pWork from "../P2P/P2pComponents/P2pWork";
import LeadingCrypto from "./ExpressComponents/LeadingCrypto";
import TopCryptoTable from "./ExpressComponents/TopCryptoTable";
import { useSelector } from "react-redux";
import { RepositoryFactory } from "repository/RepositoryFactory";
import BuyComponent from "./ExpressComponents/BuyComponent";
import SellComponent from "./ExpressComponents/SellComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const rates = {
    BTC: 26800,
    ETH: 1700,
    BNB: 215,
    CVM: 0.5,
    USDT: 1, // base value for USDT
};

const fiatCurrencies = [
    { name: "usd", title: "USD", img: "65fc704078da2.png" },
    { name: "inr", title: "Indian Rupees", img: "660c8eb792b10.png" },
    { name: "bdt", title: "BDT", img: "660c8fc8a7273.png" },
    { name: "aed", title: "AED", img: "660c89d0b0433.jpg" },
    { name: "eur", title: "EURO", img: "660c892ab200d.png" },
    { name: "gbp", title: "GBP", img: "660c88a063280.png" },
    { name: "idr", title: "IDR", img: "660c902d2bfc5.png" },
    { name: "afn", title: "AFN", img: "660c93625c2ac.jpg" },
    { name: "amd", title: "AMD", img: "660c97ec93a1c.jpg" },
    { name: "aoa", title: "AOA", img: "660c9814ba8e4.png" },
    { name: "ars", title: "ARS", img: "660c98447c66c.png" },
    { name: "aud", title: "AUD", img: "660c985fa133e.png" },
    { name: "azn", title: "AZN", img: "660c987bccf0d.png" },
    { name: "bgn", title: "BGN", img: "660c994159403.png" },
    { name: "bhd", title: "BHD", img: "660c9966a6216.png" },
    { name: "bnd", title: "BND", img: "660c9986ddd29.png" },
    { name: "bob", title: "BOB", img: "660c99a06c5e7.png" },
    { name: "brl", title: "BRL", img: "660c99bb5c0ac.png" },
    { name: "byn", title: "BYN", img: "660c99db5990b.png" },
    { name: "cad", title: "CAD", img: "660c9f39bbfe9.png" },
];

const allowed_cryptos = [
    {
        name: "BTC",
        title: "BTC",
        img: "BTC.png",
    },
    {
        name: "ETH",
        title: "ETH",
        img: "ETH.png",
    },
    {
        name: "BNB",
        title: "BNB",
        img: "BNB.png",
    },
    {
        title: "CVM",
        name: "CVM",
        img: "65f80eb6e11c9.png",
    },
    {
        name: "USDT",
        title: "USDT",
        img: "USDT.png",
    },
];

function Express() {
    const [isBuyMode, setIsBuyMode] = useState(true);
    const [selectedCoin, setSelectedCoin] = useState("BTC");
    const [selectedReceiveCoin, setSelectedReceiveCoin] = useState("ETH");
    const [amount, setAmount] = useState("");
    const [convertedAmount, setConvertedAmount] = useState("");
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const [selectedMethod, setSelectedMethod] = useState("");

    const [p2pfiatCurrencies, setP2pFiatCurrencies] = useState(fiatCurrencies);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const history = useHistory();

    const [buyCoins, setBuyCoins] = useState({
        buySpendCoing: {
            value: p2pfiatCurrencies[0]?.name,
            label: (
                <div className="flex items-center">
                    <img
                        src={`https://api.buycex.com/Upload/coin/${p2pfiatCurrencies[0]?.img}`}
                        alt="BTC"
                        className="w-6 h-6 mr-2"
                    />
                    {p2pfiatCurrencies[0]?.title}
                </div>
            ),
        },
        buyReceiveCopin: {
            value: "ETH",
            label: (
                <div className="flex items-center">
                    <img
                        src={`https://api.buycex.com/Upload/coin/ETH.png`}
                        alt="BTC"
                        className="w-6 h-6 mr-2"
                    />
                    ETH
                </div>
            ),
        },
    });
    const [sellCoins, setSellCoins] = useState({
        sellSpendCoing: {
            value: "ETH",
            label: (
                <div className="flex items-center">
                    <img
                        src={`https://api.buycex.com/Upload/coin/ETH.png`}
                        alt="BTC"
                        className="w-6 h-6 mr-2"
                    />
                    ETH
                </div>
            ),
        },
        sellReceiveCopin: {
            value: p2pfiatCurrencies[0]?.name,
            label: (
                <div className="flex items-center">
                    <img
                        src={`https://api.buycex.com/Upload/coin/${p2pfiatCurrencies[0]?.img}`}
                        alt="BTC"
                        className="w-6 h-6 mr-2"
                    />
                    {p2pfiatCurrencies[0]?.title}
                </div>
            ),
        },
    });

    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};

    useEffect(() => {
        const p2pRepository = RepositoryFactory.get("p2p");

        const fetchFiatList = async () => {
            const response = await p2pRepository.quick(ID, TOKEN);
            const res = response.data.data;

            if (res?.FiatList?.length) {
                setP2pFiatCurrencies(res?.FiatList?.map((item) => item));
            }
        };

        fetchFiatList();
    }, [TOKEN]);

    useEffect(() => {
        const p2pRepository = RepositoryFactory.get("p2p");

        const fetchPaymentMethods = async () => {
            const response = await p2pRepository.getP2pPaymentMethodsNew(
                ID,
                TOKEN
            );
            const res = response.data.data;

            if (Object.values(res)?.length) {
                setPaymentMethods(Object.values(res));
            }
        };

        fetchPaymentMethods();
    }, [TOKEN]);

    const handleToggle = async () => {
        await setIsBuyMode(!isBuyMode);
        setConvertedAmount(""); // Reset conversion when switching modes
        setAmount(""); // Reset amount on mode toggle
    };

    const handleAmountChange = (e) => {
        const inputAmount = e.target.value;
        setAmount(inputAmount);

        const conversionRate =
            rates[selectedCoin] && rates[selectedReceiveCoin]
                ? rates[selectedCoin] / rates[selectedReceiveCoin]
                : 1;

        const converted = (inputAmount * conversionRate).toFixed(4);
        setConvertedAmount(converted);
    };

    const handleCoinChange = async (value) => {
        let foundedCurrency;

        if (isBuyMode) {
            foundedCurrency = p2pfiatCurrencies?.find(
                ({ name }) => value === name
            );

            await setBuyCoins({
                ...buyCoins,
                buySpendCoing: {
                    value: foundedCurrency?.name,
                    label: (
                        <div className="flex items-center">
                            <img
                                src={`https://api.buycex.com/Upload/coin/${foundedCurrency?.img}`}
                                alt="BTC"
                                className="w-6 h-6 mr-2"
                            />
                            {foundedCurrency?.title}
                        </div>
                    ),
                },
            });

            await setSelectedCoin(value);

            return;
        } else {
            foundedCurrency = allowed_cryptos?.find(
                ({ name }) => value === name
            );

            await setSellCoins({
                ...sellCoins,
                sellSpendCoing: {
                    value: foundedCurrency?.name,
                    label: (
                        <div className="flex items-center">
                            <img
                                src={`https://api.buycex.com/Upload/coin/${foundedCurrency?.img}`}
                                alt="BTC"
                                className="w-6 h-6 mr-2"
                            />
                            {foundedCurrency?.title}
                        </div>
                    ),
                },
            });

            await setSelectedCoin(value);

            return;
        }
    };

    const handleReceiveCoinChange = async (value) => {
        let foundedCurrency;

        if (isBuyMode) {
            foundedCurrency = allowed_cryptos?.find(
                ({ name }) => value === name
            );

            await setBuyCoins({
                ...buyCoins,
                buyReceiveCopin: {
                    value: foundedCurrency?.name,
                    label: (
                        <div className="flex items-center">
                            <img
                                src={`https://api.buycex.com/Upload/coin/${foundedCurrency?.img}`}
                                alt="BTC"
                                className="w-6 h-6 mr-2"
                            />
                            {foundedCurrency?.title}
                        </div>
                    ),
                },
            });

            await setSelectedReceiveCoin(value);

            return;
        } else {
            foundedCurrency = p2pfiatCurrencies?.find(
                ({ name }) => value === name
            );

            await setSellCoins({
                ...sellCoins,
                sellReceiveCopin: {
                    value: foundedCurrency?.name,
                    label: (
                        <div className="flex items-center">
                            <img
                                src={`https://api.buycex.com/Upload/coin/${foundedCurrency?.img}`}
                                alt="BTC"
                                className="w-6 h-6 mr-2"
                            />
                            {foundedCurrency?.title}
                        </div>
                    ),
                },
            });

            await setSelectedReceiveCoin(value);

            return;
        }
    };

    const handleTextClick = () => setIsPopupVisible(true);
    const closePopup = () => setIsPopupVisible(false);

    const handleSelectMethod = (method) => {
        setSelectedMethod(method);
        closePopup();
    };

    const coinsData = [
        {
            name: "BTC",
            url: "https://api.buycex.com/Upload/coin/BTC.png",
            price: "$58,195.04",
            change: "-1.63%",
        },
        {
            name: "CVM",
            url: "https://api.buycex.com/Upload/coin/65f80eb6e11c9.png",
            price: "$0.10",
            change: "2.56%",
        },
        {
            name: "BNB",
            url: "https://api.buycex.com/Upload/coin/BNB.png",
            price: "$522.40",
            change: "0.04%",
        },
        {
            name: "ETH",
            url: "https://api.buycex.com/Upload/coin/ETH.png",
            price: "$2448",
            change: "-3.01%",
        },
    ];

    const getColorClass = (change) => {
        const numericChange = parseFloat(change.replace("%", ""));
        return numericChange < 0 ? "text-red-500" : "text-green-500";
    };

    // Array of slides for the carousel
    const slidesData = [
        {
            title: "How Can You Buy Coin Instantly?",
            img: slide1,
            description: null,
        },
        {
            title: "Wednesday Airdrop: New User Perk",
            img: slide3,
            description:
                "Deposit for a 100% chance to win the hottest coin! First come, first served.",
        },
        {
            title: "Unlock 30,000 ARS Today",
            img: slide2,
            description:
                "Sign up, deposit 100 USDT, and earn 30,000 ARS with Buycex Card!",
        },
    ];

    const tableData = [
        {
            crypto: {
                name: "Bitcoin",
                image: "https://api.buycex.com/Upload/coin/BTC.png",
            },
            EUR: "30,000",
            BRL: "29,000",
            PLN: "26,000",
            USD: "32,000",
            JPY: "4,000,000",
            INR: "2,400,000",
        },
        {
            crypto: {
                name: "Ethereum",
                image: "https://api.buycex.com/Upload/coin/ETH.png",
            },
            EUR: "1,800",
            BRL: "1,700",
            PLN: "1,500",
            USD: "1,900",
            JPY: "240,000",
            INR: "144,000",
        },
        {
            crypto: {
                name: "BNB",
                image: "https://api.buycex.com/Upload/coin/BNB.png",
            },
            EUR: "1,100",
            BRL: "1,900",
            PLN: "500",
            USD: "1,300",
            JPY: "240,000",
            INR: "144,000",
        },
    ];

    return (
        <div>
            <div className="bg-white pb-20">
                <div className="container">
                    <div className="flex flex-col md:flex-col justify-center md:justify-center md:items-center lg:flex-row gap-8 md:gap-8 lg:gap-20 lg:justify-between w-full pt-[36px]">
                        <div className="w-full text-center md:w-full lg:w-1/2 md:text-center lg:text-left">
                            <span className="text-[32px] lg:text-[48px] md:text-[32px] font-[600]">
                                Express Trading
                            </span>
                            <p className="text-[14px] lg:text-[24px] md:text-[14px] font-medium mt-2">
                                One Click Buy and Sell Crypto Currency With Most
                                Popular Local Payment Method.
                            </p>

                            <div className="justify-between hidden w-full mt-12 md:hidden lg:flex">
                                {/* Top Picks and Newly Listed sections */}
                                {["P2P Buy Rate", "P2P Sale Rate"].map(
                                    (title, index) => (
                                        <div
                                            key={index}
                                            className={`w-1/2 ${
                                                index === 0 ? "border-r" : ""
                                            }`}
                                        >
                                            <div className="flex flex-col gap-4">
                                                <p
                                                    className={`text-[#81858C] text-sm font-semibold ${
                                                        index === 0
                                                            ? "pr-3"
                                                            : "pl-6 pr-3"
                                                    }`}
                                                >
                                                    {title}
                                                </p>
                                                {coinsData.map((coin, idx) => (
                                                    <div
                                                        key={idx}
                                                        className={`flex w-full justify-between ${
                                                            index === 0
                                                                ? "pr-3"
                                                                : "pl-6 pr-3"
                                                        }`}
                                                    >
                                                        <div className="flex items-center gap-1">
                                                            <img
                                                                src={coin.url}
                                                                alt="Coin"
                                                                style={{
                                                                    width: "16px",
                                                                    height: "16px",
                                                                }}
                                                            />
                                                            <p className="text-sm font-semibold">
                                                                {coin.name}
                                                            </p>
                                                        </div>
                                                        <p className="text-sm font-semibold">
                                                            {coin.price}
                                                        </p>
                                                        <p
                                                            className={`font-semibold text-sm ${getColorClass(
                                                                coin.change
                                                            )}`}
                                                        >
                                                            {coin.change}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>

                            {/* Carousel section */}
                            <div className="hidden md:hidden lg:block">
                                <StyledCarousel
                                    className="mt-10"
                                    arrows
                                    infinite={false}
                                >
                                    {slidesData.map((slide, idx) => (
                                        <div key={idx}>
                                            <h3
                                                className="justify-between px-2 py-4"
                                                style={contentStyle}
                                            >
                                                <div
                                                    className={`flex ${
                                                        slide.description
                                                            ? "flex-col w-[70%]"
                                                            : ""
                                                    }`}
                                                >
                                                    <span className="font-semibold text-[18px]">
                                                        {slide.title}
                                                    </span>
                                                    {slide.description && (
                                                        <div className="flex">
                                                            <span className="text-[14px] text-[#81858C]">
                                                                {
                                                                    slide.description
                                                                }
                                                                <button className="bg-[#EFB81C] ml-3 px-2 rounded-lg">
                                                                    <IoArrowForward className="font-thin" />
                                                                </button>
                                                            </span>
                                                        </div>
                                                    )}
                                                    {!slide.description && (
                                                        <button className="bg-[#EFB81C] ml-3 px-2 rounded-lg">
                                                            <IoArrowForward className="font-thin" />
                                                        </button>
                                                    )}
                                                </div>
                                                <img
                                                    src={slide.img}
                                                    className="w-20 h-20"
                                                    alt={`Slide ${idx + 1}`}
                                                />
                                            </h3>
                                        </div>
                                    ))}
                                </StyledCarousel>
                            </div>
                        </div>

                        {/* Right side placeholder */}
                        <div className="w-full md:w-[70%] lg:w-[38%]">
                            <div className="bg-[#e9edf2] shadow-lg rounded-3xl">
                                <div className="flex h-[60px] rounded-3xl w-full">
                                    <div
                                        className="w-full"
                                        style={{
                                            backgroundImage: `url(${buySellTab})`, // Replace with your actual image source
                                            backgroundSize: "cover",
                                            backgroundPosition: "center bottom",
                                            backgroundRepeat: "no-repeat",
                                            borderTopLeftRadius: "22px",
                                            height: "60px",
                                            borderTopRightRadius: "22px",
                                            transform: isBuyMode
                                                ? "none"
                                                : "rotateY(180deg)", // Rotate image for "Sell"
                                        }}
                                    >
                                        {isBuyMode ? (
                                            <div className="flex w-full gap-[30px]">
                                                <p className="text-[20px] font-semibold flex h-[60px] justify-center items-center text-center w-[50%] cursor-pointer">
                                                    Buy
                                                </p>
                                                <p
                                                    onClick={handleToggle}
                                                    className="text-[20px] text-[#adb1b8] font-semibold flex h-[60px] justify-center items-center text-center w-[50%] cursor-pointer"
                                                >
                                                    Sell
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="flex w-full gap-[30px]">
                                                <p
                                                    style={{
                                                        transform:
                                                            "rotateY(180deg)",
                                                    }}
                                                    className="text-[20px] font-semibold flex h-[60px] justify-center items-center text-center w-[50%] cursor-pointer"
                                                >
                                                    Sell
                                                </p>
                                                <p
                                                    style={{
                                                        transform:
                                                            "rotateY(180deg)",
                                                    }}
                                                    onClick={handleToggle}
                                                    className="text-[20px] text-[#adb1b8] font-semibold flex h-[60px] justify-center items-center text-center w-[50%] cursor-pointer"
                                                >
                                                    Buy
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className={`p-8 bg-white rounded-b-3xl ${
                                        isBuyMode
                                            ? "rounded-tr-3xl"
                                            : "rounded-tl-3xl"
                                    }`}
                                >
                                    {isBuyMode ? (
                                        <>
                                            <BuyComponent
                                                p2pfiatCurrencies={
                                                    p2pfiatCurrencies
                                                }
                                                amount={amount}
                                                handleAmountChange={
                                                    handleAmountChange
                                                }
                                                handleCoinChange={
                                                    handleCoinChange
                                                }
                                                buyCoins={
                                                    buyCoins.buySpendCoing
                                                }
                                                setBuyCoins={setBuyCoins}
                                            />

                                            <SellComponent
                                                convertedAmount={
                                                    convertedAmount
                                                }
                                                handleReceiveCoinChange={
                                                    handleReceiveCoinChange
                                                }
                                                p2pfiatCurrencies={
                                                    allowed_cryptos
                                                }
                                                sellCoins={
                                                    buyCoins.buyReceiveCopin
                                                }
                                                setSellCoins={setSellCoins}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <BuyComponent
                                                p2pfiatCurrencies={
                                                    allowed_cryptos
                                                }
                                                amount={amount}
                                                handleAmountChange={
                                                    handleAmountChange
                                                }
                                                handleCoinChange={
                                                    handleCoinChange
                                                }
                                                buyCoins={
                                                    sellCoins.sellSpendCoing
                                                }
                                            />

                                            <SellComponent
                                                convertedAmount={
                                                    convertedAmount
                                                }
                                                handleReceiveCoinChange={
                                                    handleReceiveCoinChange
                                                }
                                                sellCoins={
                                                    sellCoins.sellReceiveCopin
                                                }
                                                p2pfiatCurrencies={
                                                    p2pfiatCurrencies
                                                }
                                            />
                                        </>
                                    )}
                                    {/* Static Conversion Rate Example */}
                                    <div className="flex items-center gap-2 mt-2">
                                        <p className="text-sm text-[#adb1b8]">
                                            {" "}
                                            1 {selectedCoin} ≈{" "}
                                            {rates[selectedCoin]} USDT{" "}
                                        </p>
                                        <IoIosInformationCircleOutline className="text-[#EFB81C]" />
                                    </div>

                                    {/* Payment Methods Section */}
                                    <p className="pt-20 font-medium">
                                        Payment Methods
                                    </p>
                                    <div>
                                        <div
                                            className="px-4 py-4 mt-2 border rounded-lg cursor-pointer"
                                            onClick={handleTextClick}
                                        >
                                            <div className="flex items-center gap-4">
                                                <img
                                                    src={p2pSymbol}
                                                    className="w-[32px] h-[32px]"
                                                    alt="p2p symbol"
                                                />
                                                <div className="flex items-center justify-between w-full">
                                                    <div>
                                                        <p className="font-semibold text-md">
                                                            {selectedMethod ||
                                                                paymentMethods[0]
                                                                    ?.name ||
                                                                "bank Transfer"}
                                                        </p>
                                                    </div>
                                                    <IoMdArrowDropdown className="text-lg" />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Popup Component */}
                                        <Popup
                                            show={isPopupVisible}
                                            onClose={closePopup}
                                            onSelectMethod={handleSelectMethod}
                                            paymentMethods={paymentMethods}
                                        />
                                    </div>
                                    {TOKEN ? (
                                        <button
                                            className="bg-[#EFB81C] px-2 py-3 font-bold w-full rounded-lg mt-8"
                                            onClick={() =>
                                                history.push("/trade/p2p")
                                            }
                                        >
                                            Submit
                                        </button>
                                    ) : (
                                        <button className="bg-[#EFB81C] px-2 py-3 font-bold w-full rounded-lg mt-8">
                                            Log In
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-10">
                <P2pWork />
                {/* <LeadingCrypto
                    leadingBackground={leadingBackground}
                    million20={million20}
                    graphMillion={graphMillion}
                /> */}
                {/* <TopCryptoTable tableData={tableData} /> */}
                <FaQs />
            </div>
        </div>
    );
}

const contentStyle = {
    margin: 0,
    height: "90px",
    display: "flex",
    alignItems: "center",
    padding: "40px",
    background: "white",
    borderRadius: "10px",
};

// Styled components for Carousel with custom arrows
const StyledCarousel = styled(Carousel)`
    .slick-prev,
    .slick-next {
        color: white !important;
        background-color: #efb81c !important;
        border-radius: 20px;
        padding: 13px;
    }

    .slick-prev:hover,
    .slick-next:hover {
        color: white !important;
        background-color: #efb81c !important;
    }

    :where(.css-dev-only-do-not-override-1c0na6j).ant-carousel
        .slick-dots
        li
        button {
        position: relative;
        display: block !important;
        width: 100%;
        height: 3px;
        padding: 0;
        color: transparent;
        font-size: 0;
        background: #ffffff;
        border: 0;
        border-radius: 3px;
        outline: none;
        cursor: pointer;
        opacity: 0.2;
        transition: all 0.3s;
    }

    :where(.css-dev-only-do-not-override-1c0na6j).ant-carousel
        .slick-dots
        li.slick-active
        button {
        background: #fb1d1d;
        opacity: 1;
    }

    .slick-dots li.slick-active button {
        background: #efb81c; /* Change background color to yellow */
        opacity: 1; /* Ensure full opacity */
    }
    .slick-dots li button {
        position: relative;
        display: block !important;
        width: 100%;
        height: 3px;
        padding: 0;
        color: transparent;
        font-size: 0;
        background: #efb81c; /* Change background color to yellow */
        border: 0;
        border-radius: 3px;
        outline: none;
        cursor: pointer;
        opacity: 0.2; /* Default opacity for inactive dots */
        transition: all 0.3s;
    }

    .slick-prev::after,
    .slick-next::after {
        box-sizing: border-box;
        position: absolute;
        top: 6px;
        left: 5px;
        display: inline-block;
        width: 11.31370849898476px;
        height: 11.31370849898476px;
        border: 0 solid currentcolor;
        border-inline-width: 2px 0;
        border-block-width: 2px 0;
        border-radius: 1px;
        content: "";
    }
    :where(.css-dev-only-do-not-override-1c0na6j).ant-carousel .slick-slider {
        position: relative;
        display: block !important;
        box-sizing: border-box;
        touch-action: pan-y;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: transparent;
    }
`;

export default Express;
