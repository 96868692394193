import {
    GET_PAYMENT_METHODS_REQUEST,
    GET_PAYMENT_METHODS_SUCCESS,
    GET_PAYMENT_METHODS_FAILURE,
    GET_FIAT_DEPOSIT_HISTORY_REQUEST,
    GET_FIAT_DEPOSIT_HISTORY_SUCCESS,
    GET_FIAT_DEPOSIT_HISTORY_FAILURE
} from "../types";

const initialState = {
    loading: false,
    gateways: [],
    depositHistory: [],
    error: null,
};

const fiatDepositReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAYMENT_METHODS_REQUEST:
        case GET_FIAT_DEPOSIT_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_PAYMENT_METHODS_SUCCESS:
            return {
                ...state,
                loading: false,
                gateways: action.payload.data.gateways,
                error: null,
            };
        case GET_FIAT_DEPOSIT_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                depositHistory: action.payload.data,
                error: null,
            };
        case GET_PAYMENT_METHODS_FAILURE:
        case GET_FIAT_DEPOSIT_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.data || action.error,
            };
        default:
            return state;
    }
};

export default fiatDepositReducer;
