import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CurrencyDropdown from "components/currencyDropdown";
import {
    FinanceDeposit,
    getPaymentMethods,
} from "store/actions/fiatDepositAction";
import coin from "assets/img/coin.png";
import coin1 from "assets/img/coin1.png";
import { FaArrowRight } from "react-icons/fa6";
import FaQs from "components/FAQs";
import FiatDepositPromotion from "components/FiatDepositPromotion";
import toast from "react-hot-toast";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SkeletonRow = () => (
    <div className="flex flex-col justify-between w-full gap-5 px-4 py-3 border border-solid rounded-xl border-zinc-300 dark:border-gray-700 animate-pulse">
        <div className="flex items-center">
            <div className="h-[30px] w-[30px] rounded-full"></div>
            <div className="w-1/2 h-5 ml-4rounded"></div>
        </div>
    </div>
);

const FiatDeposit = () => {
    const [selectedGateway, setSelectedGateway] = useState(true);
    const [selectedCurrency, setSelectedCurrency] = useState("USD");
    const user = useSelector((state) => state.authUser?.user || {});
    const [paymentMethodName, setPaymentMethodName] = useState("");
    const [amount, setAmount] = useState("");
    const userId = user?.ID;
    const token = user?.TOKEN;
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, gateways, error } = useSelector(
        (state) => state.fiatDeposit
    );

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    useEffect(() => {
        if (!userId || !token) {
            history.push("/fiat-deposit");
        } else {
            dispatch(getPaymentMethods(userId, token, selectedCurrency)).then(
                (response) => {
                    const paymentMethods = response.data.data.gateways;
                    paymentMethods.forEach((method) => {
                        setPaymentMethodName(method.name);
                    });
                }
            );
        }
    }, [dispatch, userId, token, history, selectedCurrency]);

    const handleSubmit = () => {
        if (amount < 10 || amount > 5000) {
            toast.error("Amount must be between 10 and 5000");
            return;
        }        

        const payload = {
            user_id: userId,
            type: paymentMethodName,
            num: amount,
            coinname: selectedCurrency.toLocaleLowerCase(),
        };
        dispatch(FinanceDeposit(userId, token, payload)).then((response) => {
            if (response && response.data && response.data.status === 1) {
                setAmount("");
                setSelectedCurrency("USD");
                setPaymentMethodName('')
            }
        });
    };

    const handleGatewaySelect = (gatewayId) => {
        setSelectedGateway(gatewayId);
    };

    return (
        <main className="container w-full h-full py-1">
            <div className="flex flex-col items-center justify-between w-full h-full my-1 gap-28">
                {/* section1 */}
                <section className="flex flex-col items-center justify-between w-full gap-6 section1">
                    {/* nav (upper) */}
                    {/* <RoutingNavbar /> */}

                    <div className="flex flex-col min-w-[330px] items-center pb-10 text-[#151415] dark:bg-[#151415] dark:text-[#F4F4F4]">
                        {/* Main content section */}
                        <main className="flex flex-col w-full mt-5 max-md:max-w-full">
                            <section className="max-md:max-w-full">
                                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                    {/* Left side content */}
                                    <div className="flex flex-col w-[57%] max-md:ml-0 max-md:w-full">
                                        <article className="flex flex-col mt-2 max-md:mt-10 max-md:max-w-full">
                                            <h1 className="text-3xl font-semibold leading-9 text-neutral-900 dark:text-white max-md:max-w-full">
                                                Fiat Deposit
                                            </h1>
                                            <p className="hidden mt-6 text-2xl leading-8 text-neutral-900 dark:text-white max-md:max-w-full md:flex">
                                                Purchase crypto instantly with
                                                deposits from your bank account.
                                            </p>
                                            <div className="hidden mt-12 max-md:mt-10 max-md:max-w-full md:flex">
                                                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                                                    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                                                        <img
                                                            alt="Coin"
                                                            src={coin1}
                                                            className="grow w-full aspect-[1.49] max-md:mt-5"
                                                        />
                                                    </div>
                                                    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                                                        <img
                                                            alt="Coin"
                                                            src={coin}
                                                            className="grow w-full aspect-[1.49] max-md:mt-5"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>

                                    {/* Right side content */}
                                    <div className="flex flex-col w-[43%] max-md:ml-0 max-md:w-full">
                                        <Link to="/fiat/withdraw" className="flex justify-end"><button className="self-end rounded-md font-semibold mb-8 bg-[#EFB81C] text-white py-2 flex items-center gap-1 px-4">Fiat Withdraw<FaArrowRight/></button></Link>
                                        <section className="flex flex-col w-full px-8 border border-gray-200 border-solid shadow-sm grow py-9 rounded-3xl max-md:px-5 max-md:mt-10 max-md:max-w-full max-md:py-12">
                                            <label
                                                htmlFor="amount"
                                                className="text-base max-md:text-sm max-md:max-w-full"
                                            >
                                                Amount
                                            </label>
                                            <div className="flex gap-5 justify-between px-4 py-3 mt-3.5 w-full text-lg rounded-xl shadow-[0px_0px_16px_2px_rgba(0,_0,_0,_0.07)] max-md:flex-wrap max-md:max-w-full max-md:text-sm border">
                                                <input
                                                    id="amount"
                                                    type="number"
                                                    value={amount} // Bind to state
                                                    onChange={
                                                        handleAmountChange
                                                    } // Handle changes
                                                    className="flex justify-between w-full gap-5 px-4 py-2 text-lg border-solid rounded-xl border-bottom border-amber-400 max-md:flex-wrap max-md:max-w-full dark:bg-gray-800 dark:text-white"
                                                    placeholder="Limit: 10 - 5000"
                                                />
                                                <CurrencyDropdown
                                                    selectedCurrency={
                                                        selectedCurrency
                                                    }
                                                    setSelectedCurrency={
                                                        setSelectedCurrency
                                                    }
                                                />
                                            </div>
                                            <label
                                                htmlFor="payment-method"
                                                className="text-base mt-11 text-neutral-900 dark:text-white max-md:mt-10 max-md:max-w-full max-md:text-sm"
                                            >
                                                Payment Method
                                            </label>
                                            <div
                                                id="payment-method"
                                                className="flex flex-col gap-5 justify-between mt-3 w-full rounded-xl shadow-[0px_0px_16px_2px_rgba(0,_0,_0,_0.07)] text-neutral-900 dark:text-white max-md:flex-wrap max-md:max-w-full"
                                            >
                                                {loading && (
                                                    <>
                                                        <SkeletonRow />
                                                    </>
                                                )}
                                                {!loading && !error && (
                                                    <div className="flex flex-col gap-5">
                                                        {gateways &&
                                                        gateways.length > 0 ? (
                                                            gateways.map(
                                                                (gateway) => (
                                                                    <div
                                                                        key={
                                                                            gateway.id
                                                                        }
                                                                        className={`flex flex-col gap-5 justify-between px-4 py-3 w-full rounded-xl border border-solid cursor-pointer ${
                                                                            selectedGateway ===
                                                                            gateway.id
                                                                                ? "activeMethod"
                                                                                : "border-zinc-300 dark:border-gray-700"
                                                                        }`}
                                                                        onClick={() =>
                                                                            handleGatewaySelect(
                                                                                gateway.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <div className="flex items-center">
                                                                            <img
                                                                                src={
                                                                                    gateway.img
                                                                                }
                                                                                alt={
                                                                                    gateway.title
                                                                                }
                                                                                className="h-[30px] w-[30px] rounded-full"
                                                                            />
                                                                            &nbsp;
                                                                            <div className="my-auto text-lg leading-9 text-center dark:text-white max-md:text-sm">
                                                                                {
                                                                                    gateway.title
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )
                                                        ) : (
                                                            // Render a skeleton component if no gateways data is available
                                                            <div className="flex flex-col gap-5 justify-between px-4 py-3 w-full rounded-xl border border-solid border-zinc-300">
                                                                <div className="flex items-center">
                                                                    <div className="h-[30px] w-[30px] rounded-full bg-gray-300 animate-pulse"></div>
                                                                    &nbsp;
                                                                    <div className=" text-lg text-center ml-2 rounded">No Payment Method Avaliable</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <button
                                                onClick={handleSubmit}
                                                className="justify-center items-center px-5 py-3 mt-10 text-xl font-medium text-center text-white bg-amber-400 rounded-xl shadow-[0px_0px_16px_2px_rgba(0,_0,_0,_0.07)] max-md:px-5 max-md:mt-10 max-md:max-w-full cursor-pointer max-md:text-sm"
                                            >
                                                Buy {selectedCurrency}
                                            </button>
                                        </section>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <FiatDepositPromotion />
                            </section>
                            <section>
                                <FaQs />
                            </section>
                        </main>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default FiatDeposit;
