import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GoogleIcon from "assets/img/GoogleOTPSVG.png";
import YellowTick from "assets/img/yellow-bg-tick.png";
import EmailVerification from "./helpers/EmailVerify";
import { SetEmail, setEmail } from "store/actions/authAction";
import { fetchAuthStatus } from "store/actions/userSecuritySettingsAction";
import SetEmailVerification from "./helpers/SetEmailVerify";
import { Modal } from "antd";
import { GoDotFill } from "react-icons/go";

const EmailChangeModal = ({ show, onClose, email }) => {
    const [emailC, setEmailC] = useState('');
    const [step, setStep] = useState(1);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    // const stateS = useSelector((state) => state.userInfo.userProfile.email);
    // console.log(stateS);


    const dispatch = useDispatch();

    useEffect(() => {
        const handleClickOutside = (event) => {
            const modal = document.querySelector(".modal-inner");
            if (modal && !modal.contains(event.target)) {
                onClose();
            }
            const outermodal = document.querySelector(".modal-outer");
            if (outermodal && !modal.contains(event.target)) {
                onClose();
            }
        };

        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show, onClose]);

    useEffect(() => {
        if (step === 1 && userID && userToken) {
            dispatch(fetchAuthStatus(userID, userToken));
        }
    }, [step, userID, userToken, dispatch]);

    const handleNextStep = async () => {
        if (step === 2) {
            const payload = {
                email: emailC,
                user_id: userID
            };
            
            const success = await dispatch(SetEmail(payload));
    
            if (success) {
                setStep(step + 1);
            }
        } else {
            setStep(step + 1);
        }
    };    

    if (!show) return null;

    const handleCancel = () => {
        setStep(1);
        onClose();
        if (userID && userToken) {
            dispatch(fetchAuthStatus(userID, userToken));
        }
    };

    return (
        <Modal
            title={
                <p className="mb-6">Security Verification</p>
            }
            visible={show}
            onCancel={handleCancel}
            footer={null}
        >
                {/* <div className="flex mt-4 mb-8 items-center gap-4 relative">
                    <div
                        className={`componentBorder rounded-xl text-center px-1 w-32 relative ${
                            step >= 1 ? "componentBorderSelected" : ""
                        }`}
                    >
                        <p className="text-[8px]">Verify Email</p>
                        <div
                            className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-4 ${
                                step >= 1 ? "bg-yellow-500" : "bg-gray-300"
                            } transition-colors duration-300`}
                        ></div>
                    </div>
                    <div
                        className={`componentBorder rounded-xl text-center px-1 w-32 relative ${
                            step >= 2 ? "componentBorderSelected" : ""
                        }`}
                    >
                        <p className="text-[8px]">Change Email</p>
                        <div
                            className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-4 ${
                                step >= 2 ? "bg-yellow-500" : "bg-gray-300"
                            } transition-colors duration-300`}
                        ></div>
                    </div>
                    <div
                        className={`componentBorder rounded-xl text-center px-1 w-24 relative ${
                            step >= 3 ? "componentBorderSelected" : ""
                        }`}
                    >
                        <p className="text-[8px]">Email Verification</p>
                        <div
                            className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-4 ${
                                step >= 3 ? "bg-yellow-500" : "bg-gray-300"
                            } transition-colors duration-300`}
                        ></div>
                    </div>
                    <div
                        className={`componentBorder rounded-xl text-center px-1 w-24 relative ${
                            step >= 4 ? "componentBorderSelected" : ""
                        }`}
                    >
                        <p className="text-[8px]">Changed successfully</p>
                    </div>
                </div> */}

                {step === 1 && (
                    <SetEmailVerification onSuccess={handleNextStep} step={step} showText={step === 1}/>
                )}
                {step === 2 && (
                    <div>
                        <div className="bg-gray-100 p-3 rounded-lg">
                    <p className="font-semibold text-[16px]">Steps to Unlink your Email</p>
                    <div className="flex gap-2 mt-3">
                    <GoDotFill size={20} className="opacity-50"/><p className="text-[14px]">
                        After updating your email address, on-chain withdrawals,
                        internal transfers, fiat withdrawals, P2P trades, and
                        BuyCex Card transactions will be temporarily
                        suspended for 24 hours.
                    </p>
                    </div>
                </div>
                        <h4 className="my-2 font-semibold">Type your new Email</h4>
                        <div className="flex flex-col w-full items-center">
                            <input
                                type="email"
                                className="w-full p-2 mb-3 text-sm text-gray-700 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                                placeholder="Enter your email"
                                onChange={(e) => setEmailC(e.target.value)}
                            />
                        </div>

                        <div className="flex gap-5">
                        <button
                            className="bg-yellow-500 text-black font-medium py-2 px-4 rounded focus:outline-none w-1/2"
                            onClick={handleNextStep}
                        >
                            Next Step
                        </button>
                        <button
                            className="border-2 text-black font-medium py-2 px-4 rounded focus:outline-none w-1/2"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                        </div>
                    </div>
                )}
                {step === 3 && <SetEmailVerification onSuccess={handleNextStep} />}
                {step === 4 && (
                    <div>
                        <div className="flex justify-center items-center mb-4">
                            <img
                                src={YellowTick}
                                alt="success"
                                className="mr-2"
                            />
                            <h2 className="text-lg font-medium">
                                Email Setup Completed Successfully
                            </h2>
                        </div>
                        <button
                            className="bg-yellow-500 text-white py-2 px-4 rounded focus:outline-none w-full"
                            onClick={handleCancel}
                        >
                            Done
                        </button>
                    </div>
                )}
            </Modal>
    );
};

export default EmailChangeModal;
