import React, { useEffect, useState } from 'react';
import HistoryDropdown from '../SpotHistory/DropDownUI';
import { DatePicker, Modal } from 'antd';
import { createGlobalStyle, styled } from "styled-components";
import NoData from "assets/img/user/No-data.svg";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Otcrecords } from "store/actions/OTCAction";
//import moment from 'moment'; // For formatting time

const { RangePicker } = DatePicker;

function OTCHistory() {
    const options1 = ["All", "Buy", "Sell"];
    const [filterType, setFilterType] = useState("All");

    const handleSelect1 = (selected) => {
        setFilterType(selected);
    };

    const dispatch = useDispatch();
    const { loading, data, error } = useSelector(
        (state) => state.otcHistory
    );
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const history = useHistory();

    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        dispatch(Otcrecords(userID, userToken));
    }, [dispatch, userID, userToken, history]);

    useEffect(() => {
        if (data && data.list) {
            setFilteredData(data.list);
        }
    }, [data]);

    const formatDate = (timestamp) => {
        const unixTime = parseInt(timestamp, 10);
        return moment.unix(unixTime).isValid() 
            ? moment.unix(unixTime).format("YYYY-MM-DD HH:mm:ss") 
            : "N/A";
    };

    const handleSearch = () => {
        let filtered = data.list;

        // Ensure selectedDateRange is an array and not empty before checking
        if (Array.isArray(selectedDateRange) && selectedDateRange.length === 2) {
            const [startDate, endDate] = selectedDateRange;
            filtered = filtered.filter((item) => {
                const itemDate = moment.unix(item.addtime);
                return itemDate.isBetween(startDate, endDate, 'day', '[]');
            });
        }

        // Filter by type
        if (filterType !== "All") {
            filtered = filtered.filter(item => item.type.toLowerCase() === filterType.toLowerCase());
        }

        setFilteredData(filtered);
    };

    const handleReset = () => {
        setSelectedDateRange([]);
        setFilterType("All");
        setFilteredData(data.list); // Reset to all data
    };

    return (
        <div className='mt-5 container !p-0'>
            <div className='px-3 flex items-center gap-6 pt-2'>
                <HistoryDropdown
                    label="Type"
                    options={options1}
                    initialSelected="All"
                    onSelect={handleSelect1}
                    isSearchable={false}
                    width="80px"
                />
                <GlobalStyles />
                <StyledRangePicker
                    className="px-2 w-[600px] h-[40px] bg-white sm:w-1/4 py-[0.55rem] border-l"
                    value={selectedDateRange}
                    onChange={setSelectedDateRange}
                />
                <button
                    className='bg-[#EAECEF] px-4 py-2 rounded-lg font-medium'
                    onClick={handleReset}
                >
                    Reset
                </button>
                <button
                    className='bg-[#EFB81C] px-4 py-2 rounded-lg font-medium'
                    onClick={handleSearch}
                >
                    Search
                </button>
            </div>
            <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
                <div className="flex justify-between border-b pb-3 w-full">
                    <p className="flex-[2] text-start pl-5">Type</p>
                    <p className="flex-1 text-start">Traded</p>
                    <p className="flex-[2] text-start">Price</p>
                    <p className="flex-1 text-start">Total</p>
                    <p className="flex-1 text-start">Fee</p>
                    <p className="flex-1 text-center">Time</p>
                </div>

                {filteredData && filteredData.length > 0 ? filteredData.map((item, index) => (
                    <div key={index} className="flex justify-between border-b w-full">
                       <p className="flex-[2] text-[14px] text-black py-6 text-start pl-5">
  {item.type.toLowerCase() === "buy" ? "Buy" : "Sell"}
</p>
<p className="flex-1 text-[14px] text-black py-6 text-start">
  {item.qty ? parseFloat(item.qty).toFixed(8) : "N/A"} {item.trade_coin} 
</p>
<p className="flex-[2] text-[14px] text-black py-6 text-start">
  {item.final_price ? parseFloat(item.final_price).toFixed(8) : "N/A"} {item.base_coin} 
</p>
<p className="flex-1 text-[14px] text-black py-6 text-start">
  {item.final_total ? parseFloat(item.final_total).toFixed(8) : "N/A"} {item.base_coin} 
</p>
<p className="flex-1 text-[14px] text-black py-6 text-start">
  {item.fees_paid ? parseFloat(item.fees_paid).toFixed(8) : "N/A"} {item.base_coin} 
</p>

                        <p className="flex-1 text-[14px] text-black py-6 text-center">
                            {formatDate(item.addtime)}
                        </p>
                    </div>
                )) : (
                    <div className="w-full flex justify-center items-center h-96">
                        <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                            <img src={NoData} alt="No Data" />
                            <p className='text-[#707a8a] text-sm'>No records</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const GlobalStyles = createGlobalStyle`
  .ant-picker:hover {
    border-color: #EFB81C !important;
  }
  .ant-picker-focused {
    border-color: #EFB81C !important;
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important;
  }
  .ant-picker-active-bar {
    background: #EFB81C !important;
  }
  .ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #EFB81C !important;
    color: white !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #EFB81C !important;
  }
  .ant-picker-dropdown .ant-picker-header-view > button:hover {
    color: #EFB81C !important;
  }
`;

const StyledRangePicker = styled(RangePicker)`
  .ant-picker-input > input:focus {
    box-shadow: none !important;
    border-color: #EFB81C !important;
  }
`;

export default OTCHistory;
