import React from "react";
import { MdAccountCircle, MdNavigateNext } from "react-icons/md";
import { BsExclamationDiamondFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


function DeleteAccount() {
    // const userEmail = useSelector((state) => state.authUser?.user?.userData?.email);
    const userEmail = useSelector((state) => state.authCheck.authStatus.email);
    const userPhone = useSelector((state) => state.authUser?.user?.userData?.cellphone);
    

    return (
        <>
            <div className="flex items-center">
                <div className="flex items-center gap-2">
                    <MdAccountCircle className="text-[24px]" />
                    <span className="text-[24px] font-bold">
                        Account Settings
                    </span>
                </div>
                <div>
                    <MdNavigateNext className="text-[24px] text-[#DADADA]" />
                </div>
                <span className="text-[24px] font-bold">Delete Account</span>
            </div>
            <div className="bg-[#fff] mt-2 shadow rounded-xl p-20 pl-[20%] pr-[20%] text-justify">
                <div className="flex justify-center items-center gap-4 mb-5">
                    <BsExclamationDiamondFill className="text-6xl text-[#EFB81C]" />
                    <span className="text-xl text-[#EFB81C]">
                        Important Notice
                    </span>
                </div>
                <span className="text-[16px] text-[#9C9C9C]">
                    <span className="pl-10">Please be aware that</span> once you
                    request account deletion, all associated information,
                    including your identification details, transaction history,
                    and other related data, will be permanently removed from our
                    system and cannot be recovered. After account deletion,
                    BUYCEX will no longer provide any services.
                </span>
                <div className="bg-[#F4F4F4] rounded-md pt-2 pb-2 mt-5 mb-5">
                    <p className="text-center">
                        <span className="font-semibold">Delete Account:</span>{" "}
                        <span className="text-[#F65454]">{userEmail ? userEmail : userPhone}</span>
                    </p>
                </div>
                <div className="bg-[#F3F3F3] rounded-xl p-3 !pl-10 !pr-10 mt-6 mb-6">
                    <span className="font-bold">Note:</span>
                    <p className="text-sm mt-2 text-[#F65454]">
                        <li className="list-decimal	">
                            Once submitted, your request to delete your BUYCEX
                            account cannot be undone.
                        </li>
                        <li className="list-decimal mt-1">
                            Please do not request account deletion if you have
                            any pending deposits or withdrawals. Uncredited
                            funds may be lost if you delete your account.
                        </li>
                    </p>
                </div>
                <div className="flex justify-center items-center gap-3">
                <Link to="/user/account/delete/confirm">
                    <button
                        type="button"
                        className="text-black mt-5 bg-[#EFB81C] hover:bg-[#EFB81C] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5"
                    >
                        Initiate Account Deletion
                    </button>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default DeleteAccount;