import Repository from "./Repository";

const LOGIN = '/Login';


const getHeaders = (token, id) => ({
    'token': token,
    'id': id,
    'Content-type': 'multipart/form-data', 
});

const getUserWallets = (token, id) => {
    const headers = getHeaders(token, id);

    return Repository.get('/User/userWallets', {
        headers: headers,
    });
};

const addWalletWhitelist = (coin_id, name, addr, paypassword, token, id) => {
    const formData = new FormData();
    formData.append('coin_id', coin_id);
    formData.append('name', name);
    formData.append('addr', addr);
    formData.append('paypassword', paypassword);

    const headers = getHeaders(token, id);

    return Repository.post('User/addWallet', formData, {
        headers: headers,
    });
};

const deleteUserWalletwhitelist = (coinId, token,userId) => {
    const formData = new FormData();
    formData.append('id', coinId);  

    const headers = getHeaders(token, userId);

    return Repository.post('/User/delelteUserWallet', formData, {
        headers: headers,
    });
};
const editUserWallet = (coin_id, name, addr, paypassword, id, token, userId) => {
    const formData = new FormData();
    formData.append('coin_id', coin_id);
    formData.append('name', name);
    formData.append('addr', addr);
    formData.append('paypassword', paypassword);
    formData.append('id', id);

    const headers = getHeaders(token, userId);

    return Repository.post('/User/editUserWallet', formData, {
        headers: headers,
    });
};
export default {
    getUserWallets,
    addWalletWhitelist,
    deleteUserWalletwhitelist,
    editUserWallet
};
