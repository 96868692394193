import {
  FETCH_P2P_DATA,
  FETCH_P2P_DATA_ERROR,
  FETCH_P2P_DATA_LOADING,
} from "../types";

const initialState = {
  dataa: [],
  loading: false,
  error: null,
};
const initialStateMyAds = {
  loading: false,
  error: null,
  myAdsData: [],
};
const initialStateP2pOrderHistory = {
  loading: false,
  error: null,
  P2pOrderHistory: [],
};
const p2pReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_P2P_DATA:
      return { ...state, dataa: action.payload, loading: false, error: null };
    case FETCH_P2P_DATA_ERROR:
      return { ...state, dataa: [], loading: false, error: action.payload };
    case FETCH_P2P_DATA_LOADING:
      return { ...state, loading: action.payload };
   
    default:
      return state;
  }
};

export const myAddsReducer = (state = initialStateMyAds, action) => {
  switch (action.type) {
    case "FETCH_MY_ADS_REQUEST":
        return {
          ...state,
          loading: true,
          error: null,
        };
      case "FETCH_MY_ADS_SUCCESS":
        return {
          ...state,
          loading: false,
          myAdsData: action.payload,
        };
      case "FETCH_MY_ADS_FAILURE":
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  

    default:
      return state;
  }
};

export const P2pOrderHistory = (state = initialStateP2pOrderHistory, action) => {
  switch (action.type) {
    case "FETCH_TRADE_STATUS_REQUEST":
        return {
          ...state,
          loading: true,
          error: null,
        };
      case "FETCH_TRADE_STATUS_SUCCESS":
        return {
          ...state,
          loading: false,
          P2pOrderHistory: action.payload,
        };
      case "FETCH_TRADE_STATUS_FAILURE":
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  

    default:
      return state;
  }
};

export default p2pReducer;
