import React, { useEffect, useState } from "react";
import { BsReception4, BsWifiOff } from "react-icons/bs";

const MAX_RETRIES = 5; // Maximum retry attempts
const RETRY_INTERVAL = 5000; // Retry interval in milliseconds (e.g., 5 seconds)

const NetworkStatusChecker = ({ isOffline, setIsOffline }) => {
    const [retryCount, setRetryCount] = useState(0);
    const [retrying, setRetrying] = useState(false);
    const [countdown, setCountdown] = useState(RETRY_INTERVAL / 1000);
    const [stableMessageVisible, setStableMessageVisible] = useState(false);

    useEffect(() => {
        setIsOffline(!navigator.onLine); // Set initial offline status based on network

        const handleOffline = () => {
            console.log("Network went offline");
            setIsOffline(true);
            setRetrying(true);
            setCountdown(RETRY_INTERVAL / 1000); // Start countdown when offline
            setStableMessageVisible(false); // Hide stable message when offline
        };

        const handleOnline = () => {
            console.log("Network back online");
            setIsOffline(false);
            setRetrying(false);
            setRetryCount(0);
            setCountdown(RETRY_INTERVAL / 1000); // Reset countdown when back online
            performRetry();

            // Show stable message and hide after 2 seconds
            setStableMessageVisible(true);
            setTimeout(() => {
                setStableMessageVisible(false);
            }, 3000);
        };

        window.addEventListener("offline", handleOffline);
        window.addEventListener("online", handleOnline);

        return () => {
            window.removeEventListener("offline", handleOffline);
            window.removeEventListener("online", handleOnline);
        };
    }, [setIsOffline]);

    useEffect(() => {
        if (isOffline && retrying && retryCount < MAX_RETRIES) {
            // Update countdown every second
            const countdownTimer = setInterval(() => {
                setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
            }, 1000);

            return () => clearInterval(countdownTimer);
        }
    }, [isOffline, retrying]);

    // Retry logic when offline
    useEffect(() => {
        if (isOffline && retrying && retryCount < MAX_RETRIES) {
            const retryTimer = setInterval(() => {
                console.log("Retrying connection...");
                performRetry();
                setRetryCount((prevCount) => prevCount + 1);
                setCountdown(RETRY_INTERVAL / 1000); // Reset countdown after each retry
            }, RETRY_INTERVAL);

            return () => clearInterval(retryTimer);
        } else if (retryCount >= MAX_RETRIES) {
            setRetrying(false); // Stop retrying after maximum retries
            console.log("Maximum retries reached. Stopping retries.");
        }
    }, [isOffline, retrying, retryCount]);

    const performRetry = () => {
        console.log("Attempting to reconnect or retry an operation...");
        if (!navigator.onLine) {
            console.log("Still offline, retry will continue...");
        } else {
            console.log("Network restored. Retry succeeded.");
            setRetrying(false);
        }
    };

    const handleManualRetry = () => {
        console.log("Manual retry triggered.");
        setRetryCount(0);
        setRetrying(true);
        setCountdown(RETRY_INTERVAL / 1000);
        performRetry();
    };

    return (
        <>
            {isOffline ? (
                <div className="flex gap-1 items-center relative bottom-1 left-1 w-max rounded-sm bg-red-500 text-white px-2 py-1 z-[1000]">
                    <BsWifiOff size={10} fill="#fff" />
                    {retryCount < MAX_RETRIES ? (
                        <p className="text-[10px]">
                            Network Error. Please check your internet
                            connection. Retrying in {countdown} seconds...
                        </p>
                    ) : (
                        <div className="flex items-center gap-1">
                            <p className="text-[10px]">
                                Unable to reconnect after {MAX_RETRIES}{" "}
                                attempts. Please check your internet settings.
                            </p>
                            <button
                                onClick={handleManualRetry}
                                className="bg-white text-red-500 text-[10px] mt-1 px-2 py-1 rounded"
                            >
                                Retry Now
                            </button>
                        </div>
                    )}
                </div>
            ) : stableMessageVisible ? (
                <div className="flex gap-1 items-center relative bottom-1 left-1 w-max rounded-sm bg-green-500 text-white px-2 py-1 z-[1000]">
                    <BsReception4 size={10} fill="#fff" />
                    <p className="text-[10px]">Network Connection Stable.</p>
                </div>
            ) : null}
        </>
    );
};

export default NetworkStatusChecker;
