// src/repository/TransferRepository.js
import Repository from './Repository';

// Endpoints
const TRANSFER = '/Transfer/doTransfer';

// Helper function to generate headers
const getHeaders = (token, id) => ({
  'TOKEN': token,
  'ID': id,
  'Content-type': 'application/x-www-form-urlencoded',
});

const TransferRepository = {
  // Function to perform the transfer
  async doTransfer(from, to, coin, amount, token, id) {
    const data = new URLSearchParams({
      from,
      to,
      coin,
      amount,
    });

    try {
      const response = await Repository.post(TRANSFER, data.toString(), {
        headers: getHeaders(token, id),
      });
      console.log("Transfer API Response", response);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default TransferRepository;
