import React, { useState } from "react";
import { Modal } from "antd";
import { BsExclamationCircleFill } from "react-icons/bs";
import buycexOfficialLogo from "assets/img/header/buycex-official-logo.png";
import { IoIosQrScanner } from "react-icons/io";
import { TbBrandGoogle } from "react-icons/tb";

const AntiPhishingModal = ({ visible, onClose, onSave }) => {
    const [code, setCode] = useState({
        googleCode: "",
        antiFishingCode: "",
    });

    const handleSave = () => {
        onSave(code);
        onClose();
    };

    return (
        <Modal
            title={<p>Set up Anti-phishing Code</p>}
            visible={visible}
            onCancel={onClose}
            footer={null}
            okText="Save"
            cancelText="Cancel"
        >
            <div className="flex gap-2 bg-yellow-50 rounded-md p-2 items-start">
                <BsExclamationCircleFill
                    fill="#EFB81C"
                    className="mt-[5px]"
                    size={16}
                />
                <span className="font-medium w-96">
                    Please do not disclose your anti-phishing code to anyone,
                    including BuyCex employees.
                </span>
            </div>
            <div className="bg-gray-100 mt-4 rounded-md py-4 px-3">
                <p className="text-[13px] text-black font-medium">
                    After you've successfully set up the anti-phishing code, it
                    will appear in all official emails form BuyCex to prevent
                    phishing attempts.
                </p>
                <div className="bg-black flex items-center justify-center h-20 mx-5 rounded-md mt-3">
                    <img
                        src={buycexOfficialLogo}
                        alt="BUYCEX Official Logo"
                        title="BUYCEX"
                        className="w-52 h-auto"
                        loading="eager"
                    />
                </div>
            </div>
            <div className="mt-4">
                <div className="flex items-center gap-1 mb-2">
                    <IoIosQrScanner className="text-[#81858c]" />{" "}
                    <p className="text-[#81858c]">Anti-phishing Code</p>
                </div>
                <input
                    className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2 no-arrows"
                    style={{ appearance: "textfield" }}
                    placeholder="Letters only a-z"
                    onChange={(e) => {
                        setCode({
                            ...code,
                            antiFishingCode: e.target.value,
                        });
                    }}
                />
                <p className="text-[13px] mt-2 text-[#81858c]">
                    Please enter 4 to 20 characters that contain letters,
                    numbers and underscores only. Please do not set your
                    frequently used password as the anti-phishing code.
                </p>
                <div className="mt-4">
                    <div className="flex items-center gap-1 mb-2">
                        <TbBrandGoogle /> <p>Google verification code</p>
                    </div>
                    <input
                        type="number"
                        className="bg-gray-100 rounded-md focus:outline-none px-3 w-full py-2 no-arrows"
                        placeholder="Please enter your Google Authenticator code"
                        style={{ appearance: "textfield" }}
                        onChange={(e) => {
                            setCode({
                                ...code,
                                googleCode: e.target.value,
                            });
                        }}
                    />
                    <p className="text-end my-2 text-sm font-medium">
                        Having problems with verification?
                    </p>
                </div>
                <div className="flex gap-5 mt-6 w-full">
                    <button
                        onClick={handleSave}
                        className="bg-[#EFB81C] py-3 font-semibold rounded-lg w-1/2"
                    >
                        Confirm
                    </button>
                    <button
                        onClick={onClose}
                        className="border-2 py-3 rounded-lg font-semibold w-1/2"
                    >
                        Cancel
                    </button>
                </div>
                <style>
                    {`
                .no-arrows::-webkit-inner-spin-button,
                .no-arrows::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .no-arrows {
                    -moz-appearance: textfield; /* For Firefox */
                }
                `}
                </style>
            </div>
        </Modal>
    );
};

export default AntiPhishingModal;
