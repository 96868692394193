import React from 'react';
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuthStatus } from 'store/actions/userSecuritySettingsAction';

const SetSuccessfully = ({ onClose }) => {
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  const dispatch = useDispatch();
  
  const handleSubmit = async () => {
    if (userID && userToken) {
      dispatch(fetchAuthStatus(userID, userToken));
    }
  };

  return (
    <div>
      <div>
        <h1 className="font-semibold mt-4">
          <FaCheckCircle className="text-3xl text-yellow-500" /> Withdrawal password changed Successfully
        </h1>
      </div>
      
      <div className="mt-4 text-center">
        <button
          className="w-full bg-yellow-500 font-semibold text-white py-2 rounded-md"
          onClick={() => {
            handleSubmit();
            onClose();
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default SetSuccessfully;