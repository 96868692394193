import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import menuItems from "./MenuItems";

const HistorySidebar = () => {
    const location = useLocation();
    const path = location.pathname;

    // Filter history-related items
    const historyItems = menuItems.find(item => item.name === "History")?.subItems || [];

    // Group other history items
    const groupedHistory = {
        "Trading History": historyItems
            .filter(item => ["Spot History", "Transfer History", "P2P History", "Margin History", "Future History", "Pre-List", "OTC History"].includes(item.name))
            .map(item => ({ ...item, name: item.name.replace("History", "").trim() })),
        "Crypto History": historyItems
            .filter(item => ["Crypto Withdraw", "Crypto Deposit", "Crypto Convert", "Crypto Transfer", "Payment"].includes(item.name))
            .map(item => ({ ...item, name: item.name.replace("Crypto", "").trim() })),
        "Earning History": historyItems
            .filter(item => ["Earn History", "Referral History", "DPS History"].includes(item.name))
            .map(item => ({ ...item, name: item.name.replace("History", "").trim() })),
    };

    // Filter dashboard item separately
    const dashboardItem = historyItems.find(item => item.name === "Dashboard");

    return (
        <div className="flex flex-col gap-y-5 mt-7">
            <nav className="w-[248px] h-full">
                {/* Render Dashboard item directly */}
                {dashboardItem && (
                    <ul className="flex flex-col bg-white rounded-md gap-1">
                        <li key={dashboardItem.name}>
                            <NavLink
                                to={dashboardItem.to}
                                className={`${path.includes(dashboardItem.to) ? "font-semibold bg-[#F4F4F4]" : "font-medium"} flex items-center pl-6 px-3 py-3 gap-3 text-gray dark:text-white hover:text-[#151415] hover:bg-[#F4F4F4] rounded-lg`}
                            >
                                {dashboardItem.icon}<span className="text-[16px]">{dashboardItem.name}</span>
                            </NavLink>
                        </li>
                    </ul>
                )}

                {/* Render grouped history items */}
                {Object.entries(groupedHistory).map(([category, items]) => (
                    items.length > 0 && (
                        <div key={category} className="">
                            <h3 className="pl-6 text-xs py-3 font-semibold text-gray-500">{category}</h3>
                            <ul className="flex flex-col bg-white rounded-md gap-1">
                                {items.map((subItem) => (
                                    <li key={subItem.name}>
                                        <NavLink
                                            to={subItem.to}
                                            className={`${path.includes(subItem.to) ? "font-semibold bg-[#F4F4F4]" : "font-medium"} flex items-center pl-6 px-3 py-3 gap-3 text-gray dark:text-white hover:text-[#151415] hover:bg-[#F4F4F4] rounded-lg`}
                                        >
                                            {subItem.icon}<span className="text-[16px]">{subItem.name}</span>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )
                ))}
            </nav>
        </div>
    );
};

export default HistorySidebar;
