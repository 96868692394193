import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { X } from "lucide-react";
import { verifyG2faCode } from "store/actions/authAction";
import { Link } from "react-router-dom";


const G2faLoginVerification = ({ onClose, onSuccess,userid, username, password }) => {
    const inputRefs = useRef([]);
    const dispatch = useDispatch();
    const [g2aCode, setG2aCode] = useState(Array(6).fill(""));

    const state = useSelector((state) => state.authCheck.authStatus.id);
    console.log(state);

    useEffect(() => {
        const handleKeyDown = (e) => {
            const activeIndex = inputRefs.current.findIndex(
                (input) => input === document.activeElement
            );
            handleBackspace(e, activeIndex);
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [g2aCode]);

    const handleG2aCodeChange = (e, index) => {
        const { value } = e.target;
        if (!/^\d*$/.test(value)) return;
        const newG2aCode = [...g2aCode];
        newG2aCode[index] = value.slice(-1);
        setG2aCode(newG2aCode);

        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        } else if (!value && index > 0) {
            inputRefs.current[index - 1].focus();
        }

        if (newG2aCode.every((digit) => digit !== "")) {
            verifyG2faCodeHandler(newG2aCode);
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && index > 0 && g2aCode[index] === "") {
            inputRefs.current[index - 1].focus();
            const newG2aCode = [...g2aCode];
            newG2aCode[index - 1] = "";
            setG2aCode(newG2aCode);
        }
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData("text");
        if (paste.length === 6 && /^\d+$/.test(paste)) {
            const newG2aCode = paste.split("");
            setG2aCode(newG2aCode);
            inputRefs.current.forEach((input, i) => {
                input.value = newG2aCode[i];
            });
            verifyG2faCodeHandler(newG2aCode);
        }
    };

    const verifyG2faCodeHandler = (code) => {
        const payload = {
            user_id:  userid ||localStorage.getItem("user_id_2fa"),
            ga: parseInt(code.join("")),
        };
        console.log("user payload",payload)
        dispatch(verifyG2faCode(payload, onSuccess));
    };

    return (
        <div className="fixed top-0 inset-0 bg-[#151415] bg-opacity-70 backdrop-blur-sm">
            <div className="bg-white absolute inset-0 flex-col z-[5] flex gap-2 items-center justify-center w-max h-max m-auto text-[#000] border border-black p-8 rounded-md">
                <button onClick={onClose} className="absolute right-2 top-2">
                    <X className="w-[20px] h-[20px]" />
                </button>
                <div className="mb-2 mr-auto">
                    <h1 className="text-xl font-bold">Security Verification</h1>
                    <p className="text-xs opacity-30">
                        Enter Google Authenticator code
                    </p>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="flex gap-1" onPaste={handlePaste}>
                        {g2aCode.map((digit, index) => (
                            <input
                                key={index}
                                ref={(el) => (inputRefs.current[index] = el)}
                                className={`border border-slate-400 w-[50px] h-[50px] text-center rounded-md bg-transparent focus:bg-transparent active:bg-transparent ${digit ? "bg-slate-100" : ""
                                    }`}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleG2aCodeChange(e, index)}
                                onKeyDown={(e) => handleBackspace(e, index)}
                            />
                        ))}
                    </div>
                </form>
                <div className="w-full text-right">
                    <Link to="/reset-security-settings" className="text-green-600 text-md font-bold">
                        Use Other Verify Method?
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default G2faLoginVerification;