import React, { useState } from 'react';
import { FaCheckCircle } from "react-icons/fa";
import Dropdown from 'views/trade/MyAds/Dropdown';
import { TbCaretUpDownFilled } from "react-icons/tb";
import Locked from './Locked';
import FlexibleTrialFund from './FlexibleTrialFund';
import Collateral from './Collateral';
import NoData from "assets/img/user/No-data.svg";

function SimpleEarn({ data }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [activeButton, setActiveButton] = useState('Flexible Term');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const handleToggleDropdown = (isOpen) => {
        setIsDropdownOpen(isOpen);
    };

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };

    const coinOptions = [
        'Bitcoin (BTC)',
        'Ethereum (ETH)',
        'Ripple (XRP)',
        'Litecoin (LTC)',
        'Cardano (ADA)'
    ];

    const getButtonClass = (buttonName) => (
        `px-2 py-2 rounded-md font-medium text-sm text-ellipsis whitespace-nowrap ${activeButton === buttonName ? 'bg-[#f5f5f5]' : ''}`
    );

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentPageData = data.slice(startIndex, startIndex + itemsPerPage);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className='border sm:p-6 p-3 rounded-xl'>
            {/* Navbar displayed only when the "Flexible" button is active */}

            <div className='w-full flex flex-col gap-2 justify-between mb-4'>
                <div className='flex gap-3'>
                    <button
                        onClick={() => handleButtonClick('Flexible Term')}
                        className={getButtonClass('Flexible Term')}
                    >
                        Flexible Term
                    </button>
                    {/* <button
                        onClick={() => handleButtonClick('Locked')}
                        className={getButtonClass('Locked')}
                    >
                        Locked
                    </button>
                    <button
                        onClick={() => handleButtonClick('FlexibleTrialFund')}
                        className={getButtonClass('FlexibleTrialFund')}
                    >
                        Flexible Trial Fund
                    </button>
                    <button
                        onClick={() => handleButtonClick('Collateral')}
                        className={getButtonClass('Collateral')}
                    >
                        Collateral
                    </button> */}
                    <div
                        className='relative'
                        onMouseEnter={() => handleToggleDropdown(true)}
                        onMouseLeave={() => handleToggleDropdown(false)}
                    >
                        <Dropdown
                            label="AllCoin"
                            options={coinOptions}
                            initialSelected="All Coins"
                            onSelect={(option) => console.log(option)}
                            isOpen={isDropdownOpen}
                            onToggle={handleToggleDropdown}
                            isSearchable={true}
                            width="150px" // Adjust width as needed
                        />
                    </div>
                </div>
                <div className='flex gap-2 items-center'>

                </div>
            </div>

            {/* Header rows displayed based on the active button */}
            {activeButton === 'Flexible Term' && (
                <div className='w-full my-6'>
                    {/* Header row */}
                    <div className="w-full overflow-x-auto">
                        <div className='flex min-w-[800px]'>
                            <div className='flex items-center gap-1'>
                                <p className='text-xs text-[#929AA5]'>Assets</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex items-center justify-end gap-1 w-1/5'>
                                <p className='text-xs text-[#929AA5] text-ellipsis whitespace-nowrap'>Auto-Subscribe</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex items-center justify-end gap-1 w-1/5'>
                                <p className='text-xs text-[#929AA5]'>Total Amount</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex items-center justify-end gap-1 w-1/5'>
                                <p className='text-xs text-[#929AA5]'>APR</p>
                                <TbCaretUpDownFilled className='text-xs text-[#929AA5]' />
                            </div>
                            <div className='flex justify-end gap-1 w-1/4 sm:w-1/5'>
                                <p className='text-xs text-[#929AA5] text-ellipsis whitespace-nowrap'>Comulative Total Reward</p>
                            </div>
                            <div className='flex justify-end gap-1 w-1/5'>
                                <p className='text-xs text-[#929AA5]'>Actions</p>
                            </div>
                        </div>

                        {/* Check if there are any filtered coins and display the appropriate message */}
                        {currentPageData.length === 0 ? (
                            <div className='text-center text-md'>
                                <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                                    <img src={NoData} alt="No Data" />
                                    <p className='text-[#707a8a] text-sm'>No records</p>
                                </div>
                            </div>
                        ) : (
                            currentPageData.map((data) => (
                                <div key={data.id} className='flex min-w-[800px] mt-3 items-center mb-4'>
                                    <div className='flex gap-2'>
                                        <div className='flex items-center gap-2'>
                                            <img
                                                src={`https://api.buycex.com/Upload/coin/${data.img}`}
                                                alt=""
                                                className="w-6 h-6 rounded-full"
                                            />
                                            <div className="flex flex-col">
                                                <p className='text-md'>{data.coinName}</p>
                                                {/* <span className="text-[12px] text-[#aaaaaa]">
                            {data.coinType}
                        </span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-1 w-1/5'>
                                        <p className='text-md text-end font-normal text-ellipsis whitespace-nowrap'>
                                            <label class="inline-flex items-center cursor-pointer">
                                                <input type="checkbox" value="" class="sr-only peer" />
                                                <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#EFB81C]"></div>
                                            </label>
                                        </p>
                                    </div>
                                    <div className='flex flex-col gap-1 w-1/5'>
                                        <p className='text-md text-end font-normal'>{data.amount} {data.coinName}</p>
                                        <p className='text-sm text-end font-normal text-[#929AA5]'>{data.amount}</p>
                                    </div>
                                    <div className='flex flex-col gap-1 w-1/5'>
                                        <p className='text-md text-end font-medium text-[#2ebd85]'>5.79%</p>
                                    </div>
                                    <div className='flex flex-col gap-1 w-1/4 sm:w-1/5'>
                                        <p className='text-md text-end font-medium text-[#2ebd85]'>$48.16768</p>
                                        <p className='text-sm text-end font-normal text-[#929AA5]'>$48.18</p>
                                    </div>
                                    <div className='flex justify-end gap-2 w-1/5'>
                                        <button className='text-sm flex items-center px-2 py-1 border rounded-md bg-[#EFB81C]'>Unstack</button>
                                    </div>
                                </div>
                            ))
                        )}

                    </div>
                </div>
            )}

            {/* {activeButton === 'Locked' && (
                <Locked />
            )}

            {activeButton === 'FlexibleTrialFund' && (
                <FlexibleTrialFund />
            )}

            {activeButton === 'Collateral' && (
                <Collateral />
            )} */}
            {/* Pagination controls */}
            {totalPages > 1 && (
                <div className="flex justify-center gap-4 mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-3 py-1 mx-1 text-xs font-semibold border rounded ${currentPage === index + 1 ? 'bg-yellow-500 text-black' : 'bg-gray-100 text-gray-700'}`}
                    >
                        {index + 1}
                    </button>
                ))}
                </div>
            )}
        </div>
    );
}

export default SimpleEarn;
