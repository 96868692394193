import React, { useState } from 'react'
import MyAdsNav from './MyAdsNav'
import NormalAds from './NormalAds';
import CashAds from './CashAds';
import FiatAds from './FiatAds';
import BlockAds from './BlockAds';
import DropdownFilters from './DropdownFilters';

function MyAds() {
  const [activeComponent, setActiveComponent] = useState("Normal Ads");
  const [filters, setFilters] = useState({
    coin: "All",
    type: "All status",
    fiat: "All types",
    startTime: "",
    endTime: ""
});

const handleFiltersChange = (newFilters) => {
    setFilters((prevFilters) => ({
        ...prevFilters,
        ...newFilters
    }));
};
  return (
    <div className='container'>
      <p className="text-[22px] font-bold pl-0 py-6 container">My Ads</p>
      <MyAdsNav
        setActiveComponent={setActiveComponent}
        activeComponent={activeComponent}
      />
      {/* <DropdownFilters  filters={filters} onFiltersChange={handleFiltersChange}/> */}

      <div>
        {activeComponent === "Normal Ads" && <NormalAds filters={filters}/>}
        {activeComponent === "Cash Ads" && <CashAds />}
        {activeComponent === "Block Ads" && <BlockAds />}
        {activeComponent === "Fiat Ads" && <FiatAds />}
      </div>
    </div>
  )
}

export default MyAds