import React, { useEffect, useState } from 'react';
import HistoryDropdown from '../SpotHistory/DropDownUI';
import { DatePicker, Modal, Input } from 'antd';
import { createGlobalStyle, styled } from "styled-components";
import dayjs from 'dayjs';
import { IoSearchOutline } from "react-icons/io5";
import NoData from "assets/img/user/No-data.svg";
import { useDispatch, useSelector } from 'react-redux';
import { cryptoWithdrawHistory } from 'store/actions/commonActions';

const { RangePicker } = DatePicker;

function CryptoWithdrawHistory() {
    const option2 = ["Past 7 days", "Past 30 days", "Past 90 days", "Customized"];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDates, setSelectedDates] = useState([null, null]);
    const [selectedOption, setSelectedOption] = useState("Past 7 days");
    const [txIdFilter, setTxIdFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');
    const [coinFilter, setCoinFilter] = useState('All'); 

    const handleSelect1 = (selected) => {
        setStatusFilter(selected);
    };

    const handleSelect2 = (selected) => {
        if (selected === "Customized") {
            setIsModalVisible(true);
        } else {
            setSelectedOption(selected);
        }
    };

    const handleOk = () => {
        if (selectedDates[0] && selectedDates[1]) {
            const formattedStartDate = dayjs(selectedDates[0]).format('YYYY-MM-DD');
            const formattedEndDate = dayjs(selectedDates[1]).format('YYYY-MM-DD');
            setSelectedOption(`${formattedStartDate} - ${formattedEndDate}`);
        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedDates([null, null]);
    };

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const { data } = useSelector((state) => state.cryptoWithdrawHistory || []);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userID && userToken) {
            dispatch(cryptoWithdrawHistory(userID, userToken));
        }
    }, [dispatch, userID, userToken]);

    const resetFilters = () => {
        setTxIdFilter('');
        setStatusFilter('All');
        setCoinFilter('All');
        setSelectedOption("Past 7 days");
        setSelectedDates([null, null]);
        setIsModalVisible(false);
    };

    const currentTime = dayjs().unix();
    let startTime, endTime;

    if (selectedOption === "Past 7 days") {
        startTime = currentTime - (7 * 24 * 60 * 60);
        endTime = currentTime;
    } else if (selectedOption === "Past 30 days") {
        startTime = currentTime - (30 * 24 * 60 * 60);
        endTime = currentTime;
    } else if (selectedOption === "Past 90 days") {
        startTime = currentTime - (90 * 24 * 60 * 60);
        endTime = currentTime;
    } else if (selectedDates[0] && selectedDates[1]) {
        startTime = dayjs(selectedDates[0]).unix();
        endTime = dayjs(selectedDates[1]).unix();
    } else {
        startTime = currentTime - (7 * 24 * 60 * 60);
        endTime = currentTime;
    }

    const filteredData = data?.list?.filter(item => {
        const itemTime = item.addtime; // Assuming addtime is in Unix timestamp format
        const itemTxId = item.txid?.toLowerCase() || ''; // Ensure to lowercase for case-insensitive matching
        const itemCoin = item.coinname?.toLowerCase() || ''; // Get the coin name and ensure it’s lowercase for matching
        const txIdMatch = txIdFilter ? itemTxId.includes(txIdFilter.toLowerCase()) : true; // Filter by transaction ID
        const coinMatch = coinFilter === 'All' || itemCoin === coinFilter.toLowerCase(); // Filter by coin
    
        return itemTime >= startTime && itemTime <= endTime && txIdMatch && coinMatch; // Check both time, tx ID, and coin
    });
    

    const handleCoinSelect = (selected) => {
        setCoinFilter(selected);
    };
    
    const options1 = ["All", ...Object.values(data?.coin_list || {}).map(coin => coin.name)];
    

    return (
        <div className='mt-10'>
            <div className='flex gap-4 items-center'>
                <HistoryDropdown
                    label="Coins"
                    options={options1}
                    initialSelected={coinFilter}
                    onSelect={handleCoinSelect}
                    isSearchable={false}
                    width="80px"
                />
                <HistoryDropdown
                    label="Time"
                    options={option2}
                    initialSelected={selectedOption}
                    onSelect={handleSelect2}
                    isSearchable={false}
                    width="120px"
                />
                <Modal
                    title="Select Date Range"
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <div className="flex flex-col gap-4">
                        <p className='mt-10'>Select your time range within 12 months.</p>
                        <GlobalStyles />
                        <StyledRangePicker
                            onChange={(dates) => setSelectedDates(dates)}
                            value={selectedDates}
                        />
                        <button key="ok" type="primary" onClick={handleOk} className='px-4 py-2 my-7 font-semibold bg-[#EFB81C] rounded-lg'>Continue</button>
                    </div>
                </Modal>
                <StyledInput
                    type="text"
                    placeholder="Search txid"
                    className="w-[140px] px-3 ring-1 ring-inset ring-gray-200 py-2 border border-gray-100 rounded-lg"
                    prefix={<IoSearchOutline />}
                    value={txIdFilter}
                    onChange={(e) => setTxIdFilter(e.target.value)}
                />
                <button className='text-[#EFB81C] font-semibold ml-2' onClick={resetFilters}>Reset</button>
            </div>
            <div className="text-xs mt-12 text-[#707a8a] w-full overflow-x-auto">
                <div className="flex justify-between border-b pb-3 w-full">
                    <p className="flex-[2] text-start">Time</p>
                    <p className="flex-[2] text-start">Txid</p>
                    <p className="flex-1 text-start">Address</p>
                    <p className="flex-1 text-start">Coin</p>
                    <p className="flex-1 text-start">Sent</p>
                    <p className="flex-1 text-start">Withdraw fees</p>
                </div>
                {filteredData && filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                        <div key={index} className="flex justify-between w-full py-3">
                            <p className="flex-[2] text-start flex items-start text-[14px] text-black">
                                {new Date(item.addtime * 1000).toLocaleString() || 'N/A'}
                            </p>
                            <p className="flex-1 text-center text-[14px] text-black">
                                {item.txid ? `${item.txid.substring(0, 4)}...${item.txid.substring(item.txid.length - 4)}` : '--'}
                            </p>
                            <p className="flex-1 text-center text-[14px]">
                                {item.username ? `${item.username.substring(0, 4)}...${item.username.substring(item.username.length - 4)}` : '--'}
                            </p>
                            
                            <p className="flex-1 text-center text-[14px] text-black">
                                {item.num ? parseFloat(item.num).toFixed(2) : 'N/a'} {item?.coinname}
                            </p>
                            <p className="flex-1 text-center text-[14px] text-black">
                                {item.mum ? parseFloat(item.mum).toFixed(2) : 'N/a'}
                            </p>
                            <p className="flex-1 text-center text-[14px] text-black">
                                {item.fee} {item.zc_coin ? item.zc_coin : item.coinname} 
                            </p>
                        </div>
                    ))
                ) : (
                    <div className="flex justify-center items-center mt-20">
                        <img src={NoData} alt="No Data" />
                        <p className="text-black mt-2">No data available</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CryptoWithdrawHistory;

const GlobalStyles = createGlobalStyle`
    .ant-picker {
        border-radius: 0.375rem;
    }
`;

const StyledRangePicker = styled(RangePicker)`
    .ant-picker {
        width: 100%;
        border-radius: 0.375rem;
    }
`;

const StyledInput = styled(Input)`
    border-radius: 0.375rem;
`;
