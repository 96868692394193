import React from "react";
import { FaCopy } from "react-icons/fa";
import { BsPatchCheck, BsPatchCheckFill } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { toast } from "react-hot-toast";

import DefaultUserIcon from "assets/img/user/buycex-default-user-profile-img.png";

const UserProfileDetails = ({
    userID,
    username,
    notVerified,
    redirectAccountOverview,
}) => {
    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.dismiss("success-toast");
            toast.success("UID Copied to Clipboard", { id: "success-toast" });
        } catch (err) {
            toast.dismiss("error-toast");
            toast.error("Failed to copy UID: ", err, { id: "error-toast" });
        }
    };

    return (
        <div className="flex flex-col">
            {/* User Profile and Details */}
            <div className="flex gap-2 items-center relative">
                <button
                    type="button"
                    onClick={redirectAccountOverview}
                    className="w-max overflow-hidden rounded-full max-w-16 max-h-16 border-[2px] border-[#15141550]"
                >
                    <img
                        src={DefaultUserIcon}
                        alt="User icon"
                        className="w-full h-auto rounded-full"
                        loading="lazy"
                    />
                </button>
                {/* User details => username and UID */}
                <div className="user-side-menu__user-status-wrapper flex flex-col gap-1 w-full">
                    <button
                        type="button"
                        onClick={redirectAccountOverview}
                        className="text-[18px] font-semibold w-max flex items-center gap-2"
                    >
                        <span className="user-status-wrapper__username text-[22px] capitalize">
                            {username || "Guest User"}
                        </span>
                        <span
                            className={`user-status-wrapper__verification-checker ${
                                notVerified ? "opacity-40" : "opacity-100"
                            } `}
                            data-tooltip-id="account-overview-verification-status"
                            data-tooltip-place="right"
                            data-tooltip-content={
                                notVerified
                                    ? "Not verified! Please complete KYC to get verified user"
                                    : "You are now a verified user"
                            }
                        >
                            {notVerified ? (
                                <BsPatchCheck size={14} />
                            ) : (
                                <BsPatchCheckFill size={14} fill="#EFB81C" />
                            )}
                        </span>
                        <Tooltip id="account-overview-verification-status" />
                    </button>
                    <div className="flex items-center gap-2 w-max">
                        <p className="bg-[#15141510] mt-1 px-2.5 py-1 rounded-full user-status-wrapper__uid flex gap-1.5 items-center w-max">
                            <span className="text-[12px]">UID: {userID}</span>
                            <button
                                type="button"
                                onClick={() => handleCopy(userID)}
                            >
                                <FaCopy className=" cursor-pointer text-[12px]" />
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfileDetails;
