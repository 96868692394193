import React, { useState, useRef, useCallback, useEffect } from "react";
import Dropdown from "./Dropdown";
import { IoSearchOutline } from "react-icons/io5";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchP2pOrderStatus,
} from "../../../store/actions/OrderUserAction";
import styled from "styled-components";

function InProgress() {
    const [openDropdown, setOpenDropdown] = useState(null);
    const [selectedCoin, setSelectedCoin] = useState("all");
    const [selectedType, setSelectedType] = useState("Buy / Sell");
    const [selectedFiat, setSelectedFiat] = useState("all");

    const dropdownRefs = useRef({});

    const dispatch = useDispatch();

    const { loading, orderStatus, error } = useSelector(
        (state) => state.orderStatus
    );
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    useEffect(() => {
        dispatch(fetchP2pOrderStatus(userID, userToken));
    }, [dispatch, userID, userToken]);

    // Handles the toggle of dropdowns
    const handleDropdownToggle = useCallback(
        (dropdown) => {
            setOpenDropdown(openDropdown === dropdown ? null : dropdown);
        },
        [openDropdown]
    );

    // Closes all dropdowns
    const closeAllDropdowns = () => {
        setOpenDropdown(null);
    };

    const [activeTab, setActiveTab] = useState("All");

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    // Handles clicks outside the dropdown
    const handleClickOutside = (event) => {
        const dropdownElement = dropdownRefs.current[openDropdown];
        if (dropdownElement && !dropdownElement.contains(event.target)) {
            closeAllDropdowns();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [openDropdown]);

    // Configures dropdowns with options, their state, and width
    const dropdowns = [
        {
            key: "coin",
            label: "Coin",
            options: ["All", "USDT"],
            selected: selectedCoin,
            setSelected: setSelectedCoin,
            width: "100%", // Custom width for this dropdown
        },
        {
            key: "fiat",
            label: "Currency",
            options: ["All Fiat", "UYU", "OMR", "MYR"],
            selected: selectedFiat,
            setSelected: setSelectedFiat,
            width: "100%", // Custom width for this dropdown
            isSearchable: true, // Enable search for this dropdown
        },
        {
            key: "type",
            label: "Order Type",
            options: ["All trade Types", "Buy", "Sell"],
            selected: selectedType,
            setSelected: setSelectedType,
            width: "100%", // Custom width for this dropdown
        },
    ];
    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="text-lg">Loading...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="text-lg text-red-500">Error: {error}</div>
            </div>
        );
    }
    return (
        <>
            <div className="pb-1 shadow-md">
                <div className="container">
                    {/* Tabs */}
                    <div className="container flex flex-wrap gap-4 pb-4 mt-4 rounded-md">
                        <button
                            className={`px-4 py-2 text-sm rounded-md ${
                                activeTab === "All"
                                    ? "bg-[#EFB81C33] text-[#EFB81C]"
                                    : "text-gray-500 bg-[#F5F5F5]"
                            }`}
                            onClick={() => handleTabClick("All")}
                        >
                            <div className="flex items-center gap-1">
                                <p className="text-[14px]">All</p>
                            </div>
                        </button>
                        <button
                            className={`px-4 py-2 text-sm rounded-md ${
                                activeTab === "Unpaid"
                                    ? "bg-[#EFB81C33] text-[#EFB81C]"
                                    : "text-gray-500 bg-[#F5F5F5]"
                            }`} // Renamed to 'Completed'
                            onClick={() => handleTabClick("Unpaid")}
                        >
                            <div className="flex items-center gap-1">
                                <p className="text-[14px]">Unpaid</p>
                            </div>
                        </button>
                        <button
                            className={`px-4 py-2 text-sm rounded-md ${
                                activeTab === "Paid"
                                    ? "bg-[#EFB81C33] text-[#EFB81C]"
                                    : "text-gray-500 bg-[#F5F5F5]"
                            }`} // Renamed to 'Completed'
                            onClick={() => handleTabClick("Paid")}
                        >
                            <div className="flex items-center gap-1">
                                <p className="text-[14px]">Paid</p>
                            </div>
                        </button>
                        <button
                            className={`px-4 py-2 text-sm rounded-md ${
                                activeTab === "Apeal"
                                    ? "bg-[#EFB81C33] text-[#EFB81C]"
                                    : "text-gray-500 bg-[#F5F5F5]"
                            }`} // Renamed to 'Completed'
                            onClick={() => handleTabClick("Apeal")}
                        >
                            <div className="flex items-center gap-1">
                                <p className="text-[14px]">Apeal in Progress</p>
                            </div>
                        </button>
                    </div>
                    <div className="container">
                        <div className="flex w-full flex-wrap sm:w-full lg:w-[76%] md:w-full items-center gap-2 mb-4">
                            {dropdowns.map(
                                ({
                                    key,
                                    label,
                                    options,
                                    selected,
                                    setSelected,
                                    width,
                                    isSearchable,
                                }) => (
                                    <div
                                        key={key}
                                        className="flex-1 w-full"
                                        ref={(el) =>
                                            (dropdownRefs.current[key] = el)
                                        } // Assign ref to each dropdown container
                                    >
                                        <Dropdown
                                            label={label}
                                            options={options}
                                            initialSelected={selected} // Pass initialSelected as a prop
                                            onSelect={(value) => {
                                                console.log(
                                                    `Selected ${
                                                        key
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        key.slice(1)
                                                    }:`,
                                                    value
                                                );
                                                setSelected(value);
                                                closeAllDropdowns(); // Close dropdown after selection
                                            }}
                                            isOpen={openDropdown === key}
                                            onToggle={(isOpen) =>
                                                isOpen
                                                    ? handleDropdownToggle(key)
                                                    : closeAllDropdowns()
                                            }
                                            width={width} // Pass the width to the Dropdown component
                                            isSearchable={[
                                                "fiat",
                                                "coin",
                                                "type",
                                            ].includes(key)} // Set searchable based on key
                                        />
                                    </div>
                                )
                            )}
                            <div className="flex items-center">
                                <div className="flex-1 min-w-[80px]">
                                    <StyledInput
                                        placeholder="Search order no."
                                        prefix={<IoSearchOutline />}
                                    />
                                </div>
                                <div className="ml-3 text-[#EFB81C] text-sm">
                                    Reset
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <table className="container w-full text-sm font-light border-none table-hover">
                    <thead className="border-b text-neutral-400">
                        <tr className="flex w-full">
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px] cursor-pointer flex-1"
                            >
                                Order ID/Time Created
                            </th>
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px] cursor-pointer flex-1"
                            >
                                Price
                            </th>
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px] cursor-pointer flex-1"
                            >
                                Total Value
                            </th>
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px] cursor-pointer flex-1"
                            >
                                Amount
                            </th>
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px] cursor-pointer flex-1"
                            >
                                Merchant Name
                            </th>
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px] cursor-pointer flex-1"
                            >
                                Status
                            </th>
                            <th
                                scope="col"
                                className="py-2 font-light text-[12px] cursor-pointer flex-1"
                            >
                                Operation
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className="flex items-center justify-center h-96">
                    <div className="flex flex-col items-center justify-center">
                        {orderStatus}
                    </div>
                </div>
            </div>
        </>
    );
}
const StyledInput = styled(Input)`
    padding: 0.55rem;
    font-weight: bold;
    color: #707a8a;
    border: 2px solid #dbdbdb;
    transition: border-color 0.3s ease; /* Smooth transition for border color */

    &:hover {
        border-color: #efb81c; /* Border color on hover */
    }

    &:focus,
    &:focus-within {
        border-color: #efb81c; /* Border color on focus */
        box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2); /* Shadow color on focus */
        outline: none; /* Removes default outline */
    }
`;
export default InProgress;
