import React, { useState, useEffect } from "react";
import { FaUserCircle } from "react-icons/fa";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { FaCircle, FaCircleCheck } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import ProfileNav from "./ProfileNav";
import P2pPaymentMethod from "./P2pPaymentMethod";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../../store/actions/userProfileAction";
import { HiPencilSquare } from "react-icons/hi2";
import { IoMdInformationCircle } from "react-icons/io";
import { Tooltip } from "antd";
import Feedback from "./Feedback";
import BlockedUsers from "./BlockedUsers";
import Follows from "./Follows";
import NotificationSettings from "./NotificationSettings";
import RestrictionRemoval from "./RestrictionRemoval";
import { CgMoreO } from "react-icons/cg";
import { IoClose, IoIosCheckmarkCircle, IoIosCheckbox } from "react-icons/io5";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { Link } from "react-router-dom";

function MyProfile() {
  const dispatch = useDispatch();
  const { userProfile, error } = useSelector((state) => state.userInfo);
  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
  const user = useSelector((state) => state.authUser);
  const [activeComponent, setActiveComponent] = useState("P2P Payment Methods");
  const [checkedStates, setCheckedStates] = useState({
    email: false,
    sms: false,
    kyc: false,
  });

  const handleToggle = (type) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };
  useEffect(() => {
    dispatch(fetchUserProfile(userID, userToken));
  }, [dispatch, userID, userToken]);

  useEffect(() => {
    console.log("userProfile:", userProfile);
    console.log("userToken:" + userToken);
    console.log("user:" + userID);
  }, [userProfile]);

  const [isBalanceVisible, setIsBalanceVisible] = useState(false);

  const toggleBalanceVisibility = () => {
    setIsBalanceVisible((prevState) => !prevState);
  };

  return (
    <>
      <div className="bg-gradient-to-r from-[#f4fbfc] to-[#f6fcf7]">
        <div className="container">
          <div className="flex">
            <div className="flex items-start md:items-start lg:items-center justify-between flex-col md:flex-col lg:flex-row gap-6 w-full pt-5 pb-5">
              <div className="flex items-start md:items-start lg:items-center justify-between flex-col md:flex-col lg:flex-row w-full md:w-full lg:w-full gap-0 md:gap-0 lg:gap-4">
                <div className="flex items-center gap-3">
                  <FaUserCircle className="text-[54px] md:text-[54px] lg:text-[86px] text-[#DADADA]" />
                  <div className="flex flex-col">
                    <div className="flex gap-2">
                      <div className="flex flex-row items-center gap-2 mb-1">
                        <div>
                          <span className="text-[16px] font-semibold capitalize">{userProfile.username}</span>
                        </div>
                      </div>
                      <div>
                        <span className="text-sm text-slate-400 cursor-pointer"><HiPencilSquare /></span>
                      </div>
                    </div>

                    <div>
                      <div className="flex gap-2">
                        <div
                          className="flex items-center gap-1 px-1 py-1"
                          onClick={() => handleToggle("email")}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="text-[14px]">Email</span>
                          {checkedStates.email ? (
                            <FaCircle className="text-white" />
                          ) : (
                            <FaCircleCheck className="text-green-400" />
                          )}

                        </div>
                        <div
                          className="flex items-center gap-1  px-1 py-1"
                          onClick={() => handleToggle("sms")}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="text-[14px]">Sms</span>

                          {checkedStates.sms ? (
                            <FaCircle className="text-white" />
                          ) : (
                            <FaCircleCheck className="text-green-400" />
                          )}
                        </div>
                        <div
                          className="flex items-center gap-1  px-1 py-1"
                          onClick={() => handleToggle("kyc")}
                          style={{ cursor: "pointer" }}
                        >
                          <span className="text-[14px]">KYC</span>

                          {checkedStates.kyc ? (
                            <FaCircle className="text-white" />
                          ) : (
                            <FaCircleCheck className="text-green-400" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="flex ml-[4.5rem] md:ml-[4.5rem] lg:ml-0 flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <span className="font-[300] text-[#1E2329] text-[14px]">
                        P2P Estimated Value (BTC)
                      </span>
                      {/* Toggle between eye and eye-slash icons */}
                      <div className="hover:bg-[#EFB81C] p-0.5 rounded bg-slate-200 ">
                        {isBalanceVisible ? (
                          <IoIosEyeOff className="cursor-pointer" onClick={toggleBalanceVisibility} />
                        ) : (
                          <IoIosEye className="cursor-pointer" onClick={toggleBalanceVisibility} />
                        )}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="h-[20px]">
                        {/* Display balance or masked value */}
                        <span className="font-semibold text-[20px]">
                          {isBalanceVisible ? '0.00000000' : '*****'}
                        </span>
                      </div>
                      <div className="px-2 justify-center h-[20px]">
                        <div className="flex items-center">
                          <div className="h-[20px]"><span className="font-bold text-[20px]">{isBalanceVisible ? 'BTC' : '***'}</span></div>
                        </div>
                      </div>
                      <div>
                        <div className="flex gap-1 text-[#707A8A]">
                          <div className="!h-[20px] mt-1">
                            <span>{isBalanceVisible ? '≈' : '*'}</span>
                          </div>
                          <div className="flex items-center gap-3">
                            <div className="h-[20px]"><span className="text-[14px]">{isBalanceVisible ? '$0.00' : '*****'}</span></div>
                            <IoIosArrowForward className="text-[12px]" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end items-end ml-[4.5rem] md:ml-[0.5rem] lg:ml-0 md:w-[30%] lg:w-[30%]">
                <button className="bg-[#EFB81C] font-medium px-3 py-2 h-10 rounded-md">
                  Become merchant
                </button>
              </div>
            </div>
          </div>
          <Boxes />
        </div>
      </div>
      <div className="container">
        <div className="mt-20">
          <ProfileNav
            setActiveComponent={setActiveComponent}
            activeComponent={activeComponent}
          />
        </div>
        {activeComponent === "P2P Payment Methods" && <P2pPaymentMethod />}
        {activeComponent === 'Feedback (3)' && <Feedback />}
        {activeComponent === 'Blocked Users' && <BlockedUsers />}
        {activeComponent === 'Follows' && <Follows />}
        {activeComponent === 'Notification Settings' && <NotificationSettings />}
        {activeComponent === 'Restrictions Removal Center' && <RestrictionRemoval />}
      </div>
    </>
  );
}
// Array to hold content data for each box
const boxesData = [
  {
    title: "30d Trades",
    number: "0",
    value: "Times(s)",
  },
  {
    title: "30d Completion Rate",
    number: "0",
    value: "%",
  },
  {
    title: "Avg. Release Time",
    number: "0",
    value: "Minute(s)",
  },
  {
    title: "Avg. pay Time",
    number: "0",
    value: "Minute(s)",
  },
  {
    title: "30d Trades",
    value: "100% (3)",
    info: {
      positive: "Positive 3",
      negative: "/ Negative 0",
    },
  },
];
const tooltipText = [
  { text: "Total number of completed P2P orders (buy and sell included) in the past 30 days." },
  { text: "Completion Rate - Trades/Orders(30d)*100%." },
  { text: "The average time taken (in the last 30 days) to release crypto assets as the seller in orders." },
  { text: "The average time taken (in the last 30 days) to complete payment as the buyer in orders." },
  { text: "The percentage of good reviews recieved." },
];

const InfoBox = ({ title, number, value, info, tooltipIndex }) => {
  const tooltipContent = tooltipIndex >= 0 && tooltipIndex < tooltipText.length
    ? tooltipText[tooltipIndex].text
    : "No tooltip available";

  return (
    <div className="rounded-xl flex justify-between md:block lg:block h-32 py-0 md:py-5 lg:py-5 text-center flex-1 sm:border-0 md:border"> {/* Remove border on small screens */}
      <div className="flex items-center justify-center gap-1">
        <span className="text-[12px] md:text-sm lg:text-sm text-[#474D57]">{title}</span>
        <Tooltip placement="top" title={tooltipContent}>
          <IoMdInformationCircle className="text-slate-400" />
        </Tooltip>
      </div>
      <div className="pt-0 md:pt-0 lg:pt-3 flex flex-row items-center justify-center gap-0 md:gap-2 lg:gap-2"> {/* Side-by-side on small screens */}
        <span className="text-[12px] font-semibold sm:text-[20px] md:text-[20px] pr-1">{number}</span>
        <div className="flex flex-col items-end md:items-center gap-[0.2rem] lg:items-center">
          <div><span className="text-[12px] sm:text-[16px] md:text-[16px] font-medium ">{value}</span></div>
          {info && (
            <div className="flex flex-col mt-1">
              <span style={{ lineHeight: "0.5" }}>
                <span className="text-[#01BC8D] text-[11px]">{info.positive}</span>
                <span className="text-[#F65454] text-[11px]">{info.negative}</span>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const tradeInfoData = [
  { label: "All Trades", value: "0 Times(s)", subLabel1: "Buy 0", subLabel2: "Sell 0" },
  { label: "30d Trades", value: "0 Times(s)" },
  { label: "30d Completion Rate", value: "0 %" },
  { label: "Avg. Release Time", value: "0 Minute(s)" },
  { label: "Avg. Pay Time", value: "0 Minute(s)" },
  { label: "Approx. 30d Volume", value: "0.00000000 BTC" },
  { label: "Approx. Total Volume", value: "0 Times(s)", subLabel1: "Buy 0.00000000", subLabel2: "Sell 0.00000000" },
  { label: "Positive Feedback", value: "0% (0)", subLabel1: "Positive 0", subLabel2: "Negative 0" },
  { label: "Registered", value: "1204 Day(s) Ago" },
  { label: "First Trade", value: "1204 Day(s) Ago" },
];


const Popup = ({ onClose }) => (
  <div className="fixed inset-0 !m-0 flex items-end sm:items-center md:items-center lg:items-center justify-center bg-black bg-opacity-50 z-10">
    <div className="bg-white rounded-md shadow-lg relative w-full sm:w-[70%] md:w-[60%] lg:w-[50%]">
      <div className="flex h-[45px] px-4 mt-2 mb-4 justify-end">
        <div className="flex w-[60%] justify-between">
          <h2 className="text-xl font-semibold text-center">Trade Info</h2>
          <button onClick={onClose}>
            <IoClose className="font-bold text-[28px] m-2 text-gray-400" />
          </button>
        </div>
      </div>
      <div className="px-8 py-4">
        {tradeInfoData.map((item, index) => (
          <div className={`flex justify-between w-full ${index !== 0 ? 'mt-4 mb-4' : ''}`} key={index}>
            <p className="text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px]">{item.label}</p>
            <div className="flex flex-col">
              <p className="font-semibold text-end text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px]">{item.value}</p>
              {item.subLabel1 && (
                <div className="flex gap-1">
                  <p className="text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px] text-[#03A66D]">{item.subLabel1}</p>
                  <p className="text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px]">/</p>
                  <p className="text-[12px] sm:text-[14px] md:text-[14px] lg:text-[14px] text-[#CF304A]">{item.subLabel2}</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Boxes = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div className="flex flex-wrap flex-col md:flex-row lg:flex-row md:grid md:grid-cols-3 lg:flex lg:flex-wrap gap-[0.2rem] md:gap-[0.2rem] lg:gap-4 pt-5 pb-5">
      {boxesData.slice(0, 5).map((box, index) => (
        <InfoBox
          key={index}
          title={box.title}
          value={box.value}
          number={box.number}
          info={box.info}
          tooltipIndex={index}
          className="w-full md:w-full lg:w-[18%]" // Full width on small/medium, 18% width on large
        />
      ))}

      {/* 6th box with fixed width on large screens */}
      <div
        className="border-0 pt-2 md:pt-0 lg:pt-0 md:border lg:border rounded-lg lg:rounded-lg md:rounded-xl flex justify-end lg:justify-center md:justify-center items-center w-full md:w-full lg:w-[4%] cursor-pointer"
        onClick={togglePopup}
      >
        <CgMoreO className="text-[#EFB81C]" /><span className="pl-2 block md:hidden sm:hidden lg:hidden text-[12px]">View more {`>`}</span>
      </div>

      {isPopupVisible && <Popup onClose={togglePopup} />}
    </div>


  );
};

export default MyProfile;
