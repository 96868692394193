export const SET_ID_FRONT_IMAGE = "SET_ID_FRONT_IMAGE";
export const SET_ID_BACK_IMAGE = "SET_ID_BACK_IMAGE";
export const SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE";
export const SET_ID_CARD = "SET_ID_CARD";
export const SET_USER_DETAILS = "SET_USER_DETAILS";

export const setIdFrontImage = (file) => ({
    type: SET_ID_FRONT_IMAGE,
    payload: file,
});

export const setIdBackImage = (file) => ({
    type: SET_ID_BACK_IMAGE,
    payload: file,
});

export const setProfileImage = (file) => ({
    type: SET_PROFILE_IMAGE,
    payload: file,
});

export const setIdCard = (number) => ({
    type: SET_ID_CARD,
    payload: number,
});

export const setUserDetails = (details) => ({
    type: SET_USER_DETAILS,
    payload: details,
});
