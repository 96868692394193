// AssetsUserAction
import { RepositoryFactory } from "repository/RepositoryFactory";
const assetsUser = RepositoryFactory.get("dashboardAssetsData");

export const fetchNewDashboard = (userid, token,type) => async (dispatch) => {
    dispatch({ type: "FETCH_NEW_DASHBOARD_DATA_REQUEST" });
    try {
        const response = await assetsUser.getNewDashboardData(userid, token,type);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_NEW_DASHBOARD_DATA_SUCCESS",
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: "FETCH_NEW_DASHBOARD_DATA_FAILURE",
                payload: response.data.message,
            });
            console.log("Assets Data Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("Assets Data Error: ", error.message);
        dispatch({ type: "FETCH_NEW_DASHBOARD_DATA_FAILURE", payload: error.message });
    }
};

export default fetchNewDashboard;
