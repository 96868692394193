import { RepositoryFactory } from "repository/RepositoryFactory";
const orderUser = RepositoryFactory.get("orderData");

export const fetchP2pOrderStatus =
    (userId, token, orderId, viewerType) => async (dispatch) => {
        dispatch({ type: "FETCH_ORDER_STATUS_REQUEST" });
        try {
            const response = await orderUser.postP2pOrderStatus(
                userId,
                token,
                orderId,
                viewerType
            );

            // if (response.status === 1) {
            if (response.data.status == 0) {
                dispatch({
                    type: "FETCH_ORDER_STATUS_SUCCESS",
                    payload: response.data.data,
                });
            } else {
                dispatch({
                    type: "FETCH_ORDER_STATUS_FAILURE",
                    payload: response.data.message,
                });
                console.log("Order Status Failure: ", response.data.status);
            }
        } catch (error) {
            console.log("Order Status Error: ", error.message);
            dispatch({
                type: "FETCH_ORDER_STATUS_FAILURE",
                payload: error.message,
            });
        }
    };

export const fetchP2pReceiveData =
    (userId, token, payloads) => async (dispatch) => {
        dispatch({ type: "FETCH_RECEIVE_DATA_REQUEST" });
        try {
            const response = await orderUser.postP2pReceiveData(
                userId,
                token,
                payloads
            );
            if (response.data.status == 1) {
                dispatch({
                    type: "FETCH_RECEIVE_DATA_SUCCESS",
                    payload: response.data.data,
                });
            } else {
                dispatch({
                    type: "FETCH_RECEIVE_DATA_FAILURE",
                    payload: response.data.message,
                });
            }
        } catch (error) {
            console.log("Receive Data Error: ", error.message);
            dispatch({
                type: "FETCH_RECEIVE_DATA_FAILURE",
                payload: error.message,
            });
        }
    };

export const fetchOrderHistory =
    (userId, token, payload) => async (dispatch) => {
        dispatch({ type: "FETCH_ORDER_HISTORY_REQUEST" });
        try {
            const response = await orderUser.postOrderHistory(
                userId,
                token,
                payload
            );

            if (response.data.status === 1) {
                dispatch({
                    type: "FETCH_ORDER_HISTORY_SUCCESS",
                    payload: response.data.data,
                });
            } else {
                dispatch({
                    type: "FETCH_ORDER_HISTORY_FAILURE",
                    payload: response.data.message,
                });
                console.log("Order History Failure: ", response.data.status);
            }
        } catch (error) {
            console.log("Order History Error: ", error.message);
            dispatch({
                type: "FETCH_ORDER_HISTORY_FAILURE",
                payload: error.message,
            });
        }
    };
export const fetchMyOpenOrder = (userId, token) => async (dispatch) => {
    dispatch({ type: "FETCH_OPEN_ORDER_REQUEST" });
    try {
        const response = await orderUser.postMyOpenOrder(userId, token);

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_OPEN_ORDER_SUCCESS",
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: "FETCH_OPEN_ORDER_FAILURE",
                payload: response.data.message,
            });
            console.log("Open Order  Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("Open Order Error: ", error.message);
        dispatch({ type: "FETCH_OPEN_ORDER_FAILURE", payload: error.message });
    }
};

export const fetchFiatOrder = (userId, token) => async (dispatch) => {
    dispatch({ type: "FETCH_FIAT_ORDER_REQUEST" });
    try {
        const response = await orderUser.getFiatOrders(userId, token);
        if (response.data.status === 0) {
            // if (response.data.status === 1) {

            dispatch({
                type: "FETCH_FIAT_ORDER_SUCCESS",
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: "FETCH_FIAT_ORDER_FAILURE",
                payload: response.data.message,
            });
            console.log("Fiat Order  Failure: ", response.data.status);
        }
    } catch (error) {
        console.log("Fiat Order Error: ", error.message);
        dispatch({ type: "FETCH_FIAT_ORDER_FAILURE", payload: error.message });
    }
};
export const fetchOrderView = (userId, token,payload) => async (dispatch) => {
    dispatch({ type: "FETCH_ORDER_VIEW_REQUEST" });
    try {
        const response = await orderUser.getOrdersView(userId, token,payload);
        const res = response.data.data
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_ORDER_VIEW_SUCCESS",
                payload: response.data.data,
            });
            // toast.success(res)
            return { success: true, data: res };
        } else {
            dispatch({
                type: "FETCH_ORDER_VIEW_FAILURE",
                payload: response.data.message,
            });
            console.log("Order view  Failure: ", response.data.status);
            return false;
        }
    } catch (error) {
        console.log("Order view Error: ", error.message);
        dispatch({ type: "FETCH_ORDER_VIEW_FAILURE", payload: error.message });
    }
};

export default {
    fetchOrderView,
    fetchP2pOrderStatus,
    fetchP2pReceiveData,
    fetchMyOpenOrder,
    fetchOrderHistory,
};
