import React from "react";
import OpenOrdersTable from "./tables/OpenOrdersTable";
import OrderHistoryTable from "./tables/OrderHistoryTable";
import TradeHistoryTable from "./tables/TradeHistoryTable";
import AssetsTable from "./tables/AssetsTable";
import CopyTradesTable from "./tables/CopyTradesTable";

const OrderListBody = ({ activeNav }) => {
    return (
        <div className="w-full order-list-body-wrapper">
            <div className="w-full order-list-body">
                {activeNav === "activeNavOpenOrder" ? (
                    <OpenOrdersTable />
                ) : activeNav === "activeNavOrderHistory" ? (
                    <OrderHistoryTable />
                ) : activeNav === "activeNavTradeHistory" ? (
                    <TradeHistoryTable />
                ) : activeNav === "activeNavAssets" ? (
                    <AssetsTable />
                ) : activeNav === "activeNavCopyTrades" ? (
                    <CopyTradesTable />
                ) : null}
            </div>
        </div>
    );
};

export default OrderListBody;
