import React from 'react';
import NoData from "assets/img/user/No-data.svg";

function ConvertHistoryTable({ data }) {
  // Check if data exists and is not empty
  const hasData = data && data.length > 0;

  return (
    <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
      <div className="flex justify-between border-b pb-3 w-full">
        <p className="flex-1 text-start">Trade Date</p>
        <p className="flex-1 text-start">Wallet</p>
        <p className="flex-1 text-start">Pair</p>
        <p className="flex-1 text-start">Type</p>
        <p className="flex-1 text-start">From</p>
        <p className="flex-1 text-start">To</p>
        <p className="flex-1 text-start">Price</p>
        <p className="flex-1 text-start">Settlement Date</p>
        <p className="flex-1 text-center">Status</p>
      </div>
      {hasData ? (
        <div className="w-full">
          {data.map((item) => (
            <div
              key={item.id}
              className="flex justify-between border-b py-2 w-full"
            >
              <p className="flex-1 text-start">{new Date(item.addtime * 1000).toLocaleDateString()}</p>
              <p className="flex-1 text-start">{item.market.split('_')[0].toUpperCase()}</p>
              <p className="flex-1 text-start">{item.market.toUpperCase()}</p>
              <p className="flex-1 text-start">{item.type === "2" ? "Sell" : "Buy"}</p>
              <p className="flex-1 text-start">{item.num}</p>
              <p className="flex-1 text-start">{item.mum}</p>
              <p className="flex-1 text-start">{item.price}</p>
              <p className="flex-1 text-start">
                {item.endtime !== "0"
                  ? new Date(item.endtime * 1000).toLocaleDateString()
                  : "Pending"}
              </p>
              <p className="flex-1 text-center">
                {item.status === "1" ? "Completed" : "Pending"}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div className="w-full flex justify-center items-center h-96">
          <div className="flex flex-col gap-1 h-44 justify-center items-center">
            <img src={NoData} alt="No Data" />
            <p className="text-[#707a8a] text-sm">No records</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConvertHistoryTable;
