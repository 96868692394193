import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchUserProfile } from "store/actions/userProfileAction";

import {
    BsGem,
    BsXCircleFill,
    BsPatchCheckFill,
    BsShieldLockFill,
    BsCheckCircleFill,
    BsPatchExclamationFill,
    BsPatchQuestionFill,
} from "react-icons/bs";
import { FaFileCircleCheck } from "react-icons/fa6";
import verifiedTick from "assets/img/Verified tick.svg";
import yellowTick from "assets/img/yellow-bg-tick.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { fetchKycStatus } from "store/actions/AccountuserActions";
import { fetchAuthStatus } from "store/actions/userSecuritySettingsAction";

import LoginHistory from "./LoginHistory";
import UserProfileDetails from "./UserProfileDetails";
import LoginStatusManagement from "./LoginStatusManagement";
import { Tooltip } from "react-tooltip";
import PersonalInformation from "./PersonalInformation";
import AccountActivity from "./AccountActivity";
const AccountOverviewNew = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, userProfile, error } = useSelector(
        (state) => state.userInfo
    );
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const kycStatus = useSelector((state) => state?.userReducer);
    const { authStatus } = useSelector((state) => state.authCheck);
    const verified = useSelector(
        (state) => state.authCheck.authStatus.idcardauth
    );
    const usertype = useSelector(
        (state) => state.authCheck.authStatus.usertype
    );
    const idcard = useSelector(
        (state) => state.authCheck.authStatus.idcardimg1
    );
    const addtime = useSelector((state) => state.authCheck.authStatus.addtime);
    const dob = useSelector((state) => state.authCheck.authStatus.dob);
    const address = useSelector((state) => state.authCheck.authStatus.address);
    const IdCard = useSelector((state) => state.authCheck.authStatus.idcard);
    const cellphone = useSelector(
        (state) => state.authCheck.authStatus.cellphone
    );

    const statusMap = {
        0: {
            icon: <BsXCircleFill size={12} fill="red" />,
            text: "Not Verified",
            textColor: "text-[red]",
        },
        1: {
            icon: <BsCheckCircleFill size={12} fill="#2bab73" />,
            text: "Completed",
            textColor: "text-[green]",
        },
        2: {
            icon: <BsPatchQuestionFill size={12} fill="orange" />,
            text: "In Review",
            textColor: "text-[orange]",
        },
        3: {
            icon: <BsXCircleFill size={12} fill="red" />,
            text: "Rejected",
            textColor: "text-[red]",
        },
    };

    const defaultStatus = {
        icon: <BsPatchQuestionFill size={10} fill="grey" />,
        text: "In Review",
        textColor: "text-[orange]",
    };

    const { icon, text, textColor } = statusMap[verified] || defaultStatus;

    useEffect(() => {
        if (ID && TOKEN) {
            dispatch(fetchUserProfile(ID, TOKEN));
        }
    }, [dispatch, ID, TOKEN]);
    useEffect(() => {
        if (userID && userToken) {
            dispatch(fetchKycStatus(userID, userToken));
            dispatch(fetchAuthStatus(userID, userToken));
        }
    }, [dispatch, userID, userToken]);


    useEffect(() => {
        if(verified === '0') {
            history.push('/identity-verification');
        }
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    const username = userProfile?.username;
    const email = userProfile?.email;
    const truename = userProfile?.truename;
    const kycProfile = (kyc) => {
        if (kyc === "1") {
            return "Verified";
        } else if (kyc === "2") {
            return "In Review";
        } else if (kyc === "0") {
            return "Not Verified";
        }else if (kyc === "3") {
            return "Rejected";
        }else {
            return "Unknown Status";
        }
    };

    const kyc = userProfile?.kyc;
    const statusMessage = kycProfile(kyc);

    console.log(statusMessage);

    // const kycNotVerified = userProfile?.kyc == 0;
    const kycNotVerified = !userProfile?.kyc_comment;

    const emailNotVerified = !authStatus?.email;
    const phoneNotVerified = !authStatus?.cellphone;
    const g2faNotVerified = !authStatus?.ga;

    const checkSecurityLevel = (
        emailNotVerified,
        phoneNotVerified,
        g2faNotVerified
    ) => {
        const notVerifiedItems = [
            { name: "Email", notVerified: emailNotVerified },
            { name: "Phone", notVerified: phoneNotVerified },
            { name: "G2FA", notVerified: g2faNotVerified },
        ];

        return notVerifiedItems.map((item, index) => (
            <div
                key={index}
                className={`flex items-center gap-1 ${
                    item.notVerified
                        ? "cursor-not-allowed user-select-none border-[0.5px] px-[6px] py-[2px] rounded-md opacity-50 bg-transparent"
                        : "cursor-default user-select-none px-[6px] py-[2px] rounded-md border border-[#F4F4F4] opacity-100 bg-[white]"
                }`}
            >
                <span className="text-[10px]">{item.name}</span>
                {!item.notVerified && (
                    <BsCheckCircleFill size={8} fill="green" />
                )}
            </div>
        ));
    };

    const getStatus = (emailNotVerified, phoneNotVerified, g2faNotVerified) => {
        const notVerifiedCount = [
            emailNotVerified,
            phoneNotVerified,
            g2faNotVerified,
        ].filter(Boolean).length;

        let statusText = "";
        let statusColor = "";

        if (notVerifiedCount === 3) {
            statusText = "Low";
            statusColor = "red";
        } else if (notVerifiedCount === 2) {
            statusText = "Low";
            statusColor = "orange";
        } else if (notVerifiedCount === 1) {
            statusText = "Medium";
            statusColor = "#EFB81C";
        } else {
            statusText = "High";
            statusColor = "green";
        }

        return (
            <div className="flex items-center gap-1">
                <span>
                    <BsPatchCheckFill size={10} fill={statusColor} />
                </span>
                <span className={`text-[12px] text-[${statusColor}]`}>
                    {statusText}
                </span>
            </div>
        );
    };

    const handleidVerificationRedirect = () => {
        if (idcard) {
            history.push(
                "/user/account/identity-verification/advance-verification"
            );
        } else {
            history.push("/user/account/identity-verification/information");
        }
    };

    const handleSecuritySettingsRedirect = () => {
        history.push("/user/account/security");
    };

    const handleAdvancementsRedirect = () => {
        history.push("/user/account/advancements");
    };

    return (
        <div className="w-full px-3 mt-10 flex flex-col gap-3">
            <UserProfileDetails
                notVerified={kycNotVerified}
                statusMessage={statusMessage}
                username={username}
                email={email}
                userID={ID}
                userToken={TOKEN}
                addtime={addtime}
            />
            {/* top section  */}
            <div className="font-bold text-[18px] my-3 pt-2">
                <span className="text-[24px] font-semibold">Account Info</span>
            </div>
            <div>
                {kycNotVerified ? (
                    <div
                        className={`py-[20px] px-4 border rounded-xl flex items-center justify-between`}
                    >
                        <div className="flex items-center gap-14">
                            <div className="flex items-center gap-4">
                                <div className="bg-gray-100 p-2 rounded-full">
                                    <BsPatchCheckFill size={20} />
                                </div>
                                <div>
                                    <div className="font-semibold text-[14px]">
                                        Identifications
                                    </div>
                                    <p className="capitalize text-xs">
                                        Complete All Verification Process. Photo
                                        ID, Face Verification, Proof of Address.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center gap-10">
                            <div className="flex items-center gap-1">
                                <span>{icon}</span>
                                <span className={`text-[12px] ${textColor}`}>
                                    {text}
                                </span>
                            </div>
                            {/* {kycNotVerified ? (
                                <div className="flex items-center gap-1">
                                    <span>
                                        <BsPatchExclamationFill
                                            size={10}
                                            fill="red"
                                        />
                                    </span>
                                    <span className="text-[12px] text-[red]">
                                        Not Submitted
                                    </span>
                                </div>
                            ) : (
                                <div className="flex items-center gap-1">
                                    <span>
                                        <BsPatchCheckFill
                                            size={10}
                                            fill="green"
                                        />
                                    </span>
                                    <span className="text-[12px] text-[green]">
                                        Verified
                                    </span>
                                </div>
                            )} */}
                            {statusMessage !== "Verified" && (
                                <button
                                    type="button"
                                    onClick={handleidVerificationRedirect}
                                    className={`font-semibold px-[15px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px]`}
                                >
                                    {statusMessage === "In Review"
                                        ? "Verify"
                                        : "Verify"}
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div
                        className={`bg-gradient-to-r py-4 from-[#f5d577] via-white  to-[#f5d577] rounded-t-xl  h-[100px] flex flex-col justify-center items-center ${
                            kycNotVerified ? "opacity-0" : "opacity-100"
                        } `}
                    >
                        <div className="inline-flex gap-2">
                            <img src={verifiedTick} alt="" />
                            <p className="text-[#01BC8D]">ID Verification</p>
                        </div>
                        <div className=" inline-flex gap-2">
                            <img src={yellowTick} alt="" />
                            <p className="text-[14px]">
                                Personalized Verification Approved
                            </p>
                        </div>
                        <button className="font-medium bg-[#E6E6E6] hover:bg-yellow-500 hover:text-white px-4 rounded-lg ">
                            View
                        </button>
                    </div>
                )}

                {/* <div className="py-[20px] px-4 border-t flex items-center justify-between">
                    <div className="flex items-center gap-32">
                        <div className="flex items-center gap-4">
                            <div>
                                <BsGem size={20} />
                            </div>
                            <div className="font-semibold text-[14px]">
                                VIP Level
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-10">
                        <div className="flex gap-2 items-center text-[#EFB81C]">
                            <span>
                                <BsGem size={10} />
                            </span>
                            <span className="text-[12px]">Regular User</span>
                        </div>
                        <div>
                            <button
                                type="button"
                                onClick={() => {}}
                                className={`font-semibold px-[15px] py-2 bg-[#F4F4F4] text-[#151415] min-w-[85px] rounded-md text-[12px] ${
                                    kycNotVerified
                                        ? "cursor-not-allowed text-[#15141520]"
                                        : ""
                                }`}
                                {...(kycNotVerified ? "disabled" : "")}
                                data-tooltip-id="disabled-vip-level-tooltip"
                                data-tooltip-place="left"
                                data-tooltip-content={`${
                                    kycNotVerified
                                        ? "Please Complete the KYC to get VIP level"
                                        : ""
                                }`}
                            >
                                View
                                <span>
                                    <Tooltip id="disabled-vip-level-tooltip" />
                                </span>
                            </button>
                        </div>
                    </div>
                </div> */}

                {/* third line  */}

                <div className="py-[20px] px-4 border mt-3 rounded-xl flex items-center justify-between">
                    <div className="flex items-center gap-14">
                        <div className="flex gap-4 items-center">
                        <div className="bg-gray-100 p-2 rounded-full">
                                <BsShieldLockFill size={20} />
                            </div>
                            <div className="flex flex-col gap-1">
                                <div className="flex items-center gap-3">
                                    <div className="font-semibold text-[14px]">
                                        Security
                                    </div>
                                    <div
                                        className={`flex items-center gap-3 ${
                                            loading
                                                ? "opacity-0"
                                                : "opacity-100"
                                        }`}
                                    >
                                        {checkSecurityLevel(
                                            emailNotVerified,
                                            phoneNotVerified,
                                            g2faNotVerified
                                        )}
                                    </div>
                                </div>
                                <p className="capitalize text-xs">
                                    Complete All Verification Process. Photo ID,
                                    Face Verification, Proof of Address.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center gap-10">
                        <div
                            className={`flex items-center gap-1 ${
                                loading ? "opacity-0" : "opacity-100"
                            }`}
                        >
                            {getStatus(
                                emailNotVerified,
                                phoneNotVerified,
                                g2faNotVerified
                            )}
                        </div>
                        <button
                            type="button"
                            onClick={handleSecuritySettingsRedirect}
                            className="font-semibold px-[15px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px]"
                        >
                            Settings
                        </button>
                    </div>
                </div>

                <div className="py-[20px] px-4 mt-3 border rounded-xl flex items-center justify-between">
                    <div className="flex items-center gap-32">
                        <div className="flex items-center gap-4">
                        <div className="bg-gray-100 p-2 rounded-full">
                                <BsGem size={20} />
                            </div>
                            <div className="flex flex-col">
                                <div className="font-semibold text-[14px]">
                                    Settings
                                </div>
                                <p className="capitalize text-xs">
                                    Set username, change language, currency and
                                    upgrade your VIP Level.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-10">
                        <div>
                            <Link to="/user/settings">
                            <button
                                type="button"
                                onClick={() => {}}
                                className={`font-semibold px-[15px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px]`}
                            >
                                Settings
                                <span>
                                    <Tooltip id="disabled-vip-level-tooltip" />
                                </span>
                            </button>
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Fourth line  */}
                    <div className="py-[20px] mt-3 px-4 border rounded-xl flex items-center justify-between">
                        <div className="flex items-center gap-32">
                            <div className="flex items-center gap-4">
                            <div className="bg-gray-100 p-2 rounded-full"><FaFileCircleCheck className="text-2xl" /></div>
                                <div className="flex flex-col">
                                    <h1 className="font-semibold text-[14px]">
                                        API Management
                                    </h1>
                                    <p className="capitalize text-xs">
                                        API to check market data, process
                                        automated trading orders and much more.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Link to="/#">
                                <button
                                    type="button"
                                    className="font-semibold px-[15px] py-2 border-2 hover:bg-[#15141520] text-[#151415] min-w-[85px] rounded-md text-[12px]"
                                >
                                    Create
                                </button>
                            </Link>
                        </div>
                    </div>
            </div>
            <PersonalInformation
                truename={truename}
                dob={dob}
                address={address}
                IdCard={IdCard}
                email={email}
                cellphone={cellphone}
            />

            <AccountActivity />
        </div>
    );
};

export default AccountOverviewNew;
