import React, { useState } from 'react';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6"; // Import both eye icons
import { FaAngleDown } from "react-icons/fa6";
import { RiExchangeDollarLine } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa6";
import { IoInformationCircle } from "react-icons/io5";
import ConvertModal from 'views/trade/PostAds/ConvertModal';
import TransferModal from 'views/trade/PostAds/TransferModal';
import WithdrawModal from 'views/crypto/WithdrawPopup/WithdrawModal';
import CryptoDepositModal from 'components/cryptoDeposit/CryptoDepositPopup/CryptoDepositModal';
import { useHistory } from 'react-router-dom';

function EstimatedAssets({ isVisible, toggleVisibility, data }) {
  const [activeButton, setActiveButton] = useState('deposit');
  const history = useHistory()
  const estimateBalance = data?.estimate_balance || {};
  const conversionCoin = data?.estimate_balance?.conversion_coin || 'N/A';
  const totalBalance = isVisible ? parseFloat(estimateBalance.balance || 0).toFixed(2) : '****';
  const pnl = isVisible ? parseFloat(estimateBalance.pnl || 0).toFixed(2) : '****';
  const pnlPercentage = isVisible ? parseFloat(estimateBalance.pnl_percentage || 0).toFixed(2) : '*****';
  const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
  const handleTransferClick = () => {
      setIsTransferModalVisible(true);
  };

  const handleTransferCancel = () => {
      setIsTransferModalVisible(false);
  };

  const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);
  const showWithdrawModal = () => {
      setIsWithdrawModalVisible(true);
  };

  const closeWithdrawModal = () => {
      setIsWithdrawModalVisible(false);
  };

  const [cryptoDepositModalVisible, setCryptoDepositModalVisible] = useState(false);
  const showCryptoDepositModal = () => {
      setCryptoDepositModalVisible(true);
  };

  const handleCloseCryptoDepositModal = () => {
      setCryptoDepositModalVisible(false);
  };

  const [isConvertModalVisible, setIsConvertModalVisible] = useState(false);
  const handleConvertClick = () => {
      setIsConvertModalVisible(true); // Open Convert Modal
  };

  const handleConvertCancel = () => {
      setIsConvertModalVisible(false); // Close Convert Modal
  };


  const handleButtonClick = (buttonType) => {
    if (buttonType === 'deposit') {
      showCryptoDepositModal();
    } else if (buttonType === 'withdraw') {
      showWithdrawModal();
    } else if (buttonType === 'transfer') {
      handleTransferClick();
    } else if (buttonType === 'convert') {
      handleConvertClick();
    }
    setActiveButton(buttonType);
  };

  const getButtonClass = (buttonType) => {
    return buttonType === activeButton
      ? 'bg-[#EFB81C] md:px-3 px-1 py-2 rounded-md text-sm font-semibold'
      : 'border border md:px-3 px-1 py-2 rounded-md text-sm font-semibold';
  };
  return (
    <div className='flex flex-col md:flex-row w-full justify-between mt-0 lg:mt-6 p-3 sm:p-6 rounded-2xl border'>
      <div>
        <div className='flex items-center gap-1'>
          <p className='text-[20px] font-semibold'>Estimated Balance</p>
          <button onClick={toggleVisibility} className='mt-1'>
            {isVisible ? <FaRegEyeSlash className='text-xl' /> : <FaRegEye className='text-xl' />}
          </button>
        </div>
        <div className='flex items-baseline gap-2'>
          <p className='text-[32px] font-semibold'>{isVisible ? parseFloat(totalBalance).toFixed(2) : '*****'}</p>
          <span className='text-sm flex items-center gap-1 font-semibold'>{conversionCoin}<FaAngleDown className='text-[12px] opacity-40 mt-1' /></span>
        </div>
        <p>{isVisible ? `≈ ${parseFloat(totalBalance).toFixed(2)}` : '****'}</p>
        <p className='flex items-center'>Today‘s PnL <IoInformationCircle className='mx-3 opacity-50' /> {isVisible ? `${parseFloat(
        pnl
        ).toFixed(2)} (${parseFloat(
         pnlPercentage
        ).toFixed(2)}%)` : '*****'}</p>
      </div>
      <div className='flex flex-col items-start md:items-end justify-between'>
      <div className='flex justify-between my-4 md:my-0 w-full'>
          <button
            onClick={() => handleButtonClick('deposit')}
            className={`${getButtonClass('deposit')} flex-1`}
          >
            Deposit
          </button>
          <CryptoDepositModal visible={cryptoDepositModalVisible} onClose={handleCloseCryptoDepositModal} />
          <button
            onClick={() => handleButtonClick('withdraw')}
            className={`ml-2 sm:ml-4 flex-1 ${getButtonClass('withdraw')}`}
          >
            Withdraw
          </button>
          <WithdrawModal isVisible={isWithdrawModalVisible} onClose={closeWithdrawModal} />
          <button
            onClick={() => handleButtonClick('transfer')}
            className={`ml-2 sm:ml-4 flex-1 ${getButtonClass('transfer')}`}
          >
            Transfer
          </button>
          <TransferModal visible={isTransferModalVisible} onCancel={handleTransferCancel} />
          <ConvertModal visible={isConvertModalVisible}  onCancel={handleConvertCancel} />
          <button
            onClick={() => handleButtonClick('convert')}
            className={`ml-2 sm:ml-4 flex-1 ${getButtonClass('convert')}`}
          >
            Convert
          </button>
        </div>
        <div className='flex gap-3 items-center'>
          <RiExchangeDollarLine className='text-2xl bg-black rounded-full text-white' />
          <div>
            <p className='text-xs'>Buy Bcx & Be a Proud</p>
            <div className='flex items-center gap-1'><p className='text-xs'>Owner of BuyCex</p> <button className='text-[#EFB81C] text-xs font-semibold'>Details</button><FaArrowRight className='text-[#EFB81C] text-xs' /></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EstimatedAssets;
