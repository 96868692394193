import React, { useEffect, useState } from "react";
import { MdFileCopy, MdInfo } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import { CgLoadbar } from "react-icons/cg";
import { Tooltip, Modal, Button } from "antd"; // Import Modal from antd
import "antd/dist/reset.css"; // Import Ant Design styles
import toast from "react-hot-toast"; // Import react-hot-toast
import P2pChatBox from "./P2pChatBox/P2pChatBox";
import P2pCancel from "./P2pChatPopups/P2pCancel";
import { IoPersonCircle } from "react-icons/io5";
import { FaComments, FaTimes } from "react-icons/fa"; // Importing the close icon
import { IoIosArrowForward } from "react-icons/io";
import { MdChat } from "react-icons/md";
import { useLocation } from "react-router-dom";
import CancelOrder from "./CancelOrder";
import P2pFeedBack from "./P2pChatPopups/P2pFeedBack";
import P2pAuthenticator from "./P2pChatPopups/P2pAuthenticator";
import P2pAppeal from "./P2pChatPopups/P2pAppeal";
import CompleteOrder from "./CompleteOrder";
import { IoMdCheckmarkCircle } from "react-icons/io";
import P2pComplete from "./P2pChatPopups/P2pComplete";
import { fetchOrderView } from "store/actions/OrderUserAction";
import { useDispatch, useSelector } from "react-redux";
import { confirmCancel, markAsPaid } from "store/actions/p2pActions";

// Copy to clipboard function
const copyToClipboard = (text) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            toast.success("Copied to clipboard!");
        })
        .catch((err) => {
            console.error("Failed to copy: ", err);
            toast.error("Failed to copy!");
        });
};

const DetailRow = ({ label, value, icon, info }) => (
    <div className="flex py-2 w-full justify-between">
        <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-between w-full md:items-center item-start lg:items-center">
            <div className="flex">
                <p className="text-[#aaaaaa] text-sm md:text-[16px]">{label}</p>
                <p>
                    {info && (
                        <Tooltip title={info}>
                            <MdInfo className="ml-2 mt-1 cursor-pointer text-gray-400 text-[14px]" />
                        </Tooltip>
                    )}
                </p>
            </div>
            <p className="text-sm md:text-[16px]">{value}</p>
        </div>
        <div className="flex gap-1 ml-2 items-center">
            <p>{icon && icon}</p>
        </div>
    </div>
);

const P2pChat = React.memo(() => {
    const [isOrderCancelled, setIsOrderCancelled] = useState(false);
    const [showFeedback, setShowFeedback] = useState(true);
    const [showComplete, setShowComplete] = useState(true);
    const [isChatVisible, setIsChatVisible] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [authVisible, setAuthVisible] = useState(false);
    const location = useLocation(); // Use the useLocation hook to access location

    const [isAppealModalVisible, setIsAppealModalVisible] = useState(false);
    const [isOrderCompleted, setIsOrderCompleted] = useState(false); // State for order completion
    const { bankDetails, orderid } = location.state || {};
    const dispatch = useDispatch();

    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;

    const MarkPAid = () => {
        const payload = {
            order_id: Id,
        };
        dispatch(markAsPaid(userId, token, payload));
    };

    const openAuthModal = () => {
        setAuthVisible(true);
        MarkPAid();
    };

    const closeAuthModal = () => {
        setAuthVisible(false);
    };

    const openAppealModal = () => {
        setIsAppealModalVisible(true);
    };

    const closeAppealModal = () => {
        setIsAppealModalVisible(false);
    };

    const handleCompleteOrder = () => {
        setIsOrderCompleted(true); // Set order as completed
        toast.success("Order completed successfully!"); // Show success message
    };
    const [orderId, setOrderId] = useState(null);
    const [Id, setId] = useState(null);
    const [AdId, setAdId] = useState(null);
    const [Time, setTime] = useState(null);
    const [timeLeft, setTimeLeft] = useState(null); // State for the time left
    const [timerRunning, setTimerRunning] = useState(false);
    const [Name, setname] = useState("Bydefault Name");
    const [coin, setcoin] = useState(null);
    const [fiat, setfiat] = useState(null);
    const [UserBankName, setUserBankName] = useState(null);
    const [UserMethod, setUserMethod] = useState(null);
    const [UserInformation, setUserInformation] = useState(null);
    const [UserBankCard, setUserBankCard] = useState(null);
    const [UserBank, setUserBank] = useState(null);
    const [UserBankAddress, setUserBankAddress] = useState(null);
    const [TotalPrice, setTotalPrice] = useState(null);
    const [TotalCoin, setTotalCoin] = useState(null);
    const [TotalFiat, setTotalFiat] = useState(null);
    const [Adtype, setAdtype] = useState(null);
    const formattedPrice = `${parseFloat(TotalPrice).toFixed(2)} USDT`;
    const formattedCoin = `${parseFloat(TotalCoin).toFixed(2)} USDT`;
    const formattedFiat = TotalFiat
        ? `${parseFloat(TotalFiat).toFixed(2)} ${
              coin ? coin.toUpperCase() : fiat.toUpperCase()
          }`
        : "09.00 USDT";

    useEffect(() => {
        const payload = {
            order: orderid,
        };
        dispatch(fetchOrderView(userId, token, payload)).then((result) => {
            if (result) {
                const responseData = result.data;
                const orderId = responseData.order.orderid;
                const Id = responseData.order.id;
                const AdId = responseData.order.ad_id;
                const Time = responseData.order.addtime;
                const Timer = responseData.order.time_limit;
                const name = responseData.order.name;
                const coin = responseData.order.coin;
                const fiat = responseData.order.fiat;
                const TotalPrice = responseData.order.fixed_price;
                const TotalCoin = responseData.order.coin_qty;
                const TotalFiat = responseData.order.fiat_qty;
                const Adtype = responseData.order.ad_type;
                const UserBankName = responseData.payinfo[0].name;
                const UserMethod = responseData.payinfo[0].method;
                const UserBankCard = responseData.payinfo[0].bankcard;
                const UserInformation = responseData.payinfo[0].information;
                const UserBank = responseData.payinfo[0].bank;
                const UserBankAddress = responseData.payinfo[0].bankaddr;
                setOrderId(orderId);
                setId(Id);
                setAdId(AdId);
                setTime(Time);
                setTimeLeft(Timer); // Set the time to state
                setTimerRunning(true);
                setname(name);
                setcoin(coin);
                setfiat(fiat);
                setUserBankName(UserBankName);
                setUserMethod(UserMethod);
                setUserBankCard(UserBankCard);
                setUserBank(UserBank);
                setUserInformation(UserInformation);
                setUserBankAddress(UserBankAddress);
                setTotalPrice(TotalPrice);
                setTotalCoin(TotalCoin);
                setTotalFiat(TotalFiat);
                setAdtype(Adtype);
                if (!timeLeft || timeLeft === 0) {
                    startTimer(Timer); // Initialize the timer only if not already running
                }
            }
        });
    }, [dispatch, userId, token]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes < 10 ? "0" : ""}${minutes}:${
            remainingSeconds < 10 ? "0" : ""
        }${remainingSeconds}`;
    };

    const Cancel = () => {
        const payload = {
            order_id: Id,
        };
        dispatch(confirmCancel(userId, token, payload));
    };

    const onSuccess = () => {
        setAuthVisible(false);
        setIsOrderCompleted(true); // Set order as completed
        // toast.success("Order completed successfully!");
    };

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(
                () => setTimeLeft((prev) => prev - 1),
                1000
            );
            return () => clearTimeout(timer);
        }

        if (timeLeft == 0) {
            Cancel();
            navigate("/trade");
        }
    }, [timeLeft]);

    const startTimer = (initialTime) => {
        setTimeLeft(initialTime * 60); // Initialize timer in seconds
    };
    
    const orderDetails = [
        {
            label: "Fiat amount",
            value: (
                <div className="flex items-center gap-1">
                    <p className="text-[#2EBD85] font-semibold text-sm md:text-[16px]">
                        {formattedFiat}
                    </p>
                </div>
            ),
        },
        { label: "Price", value: formattedCoin },
        {
            label: "Total Quantity",
            value: (
                <div className="flex items-center gap-1">
                    <p className="text-sm md:text-[16px]">{formattedPrice}</p>
                    <IoIosArrowDown />
                </div>
            ),
        },
        { label: "Time created", value: "2024-09-08 12:35:17" },
        ...(Adtype === "2"
            ? [
                  {
                      label: "Payment method",
                      value: (
                          <div className="flex items-center gap-1">
                              <CgLoadbar className="rotate-90 text-[#EFB81C]" />
                              <h3 className="text-sm md:text-[16px] mb-0 font-semibold">
                                  {UserMethod === "cdm"
                                      ? "Cash Deposit"
                                      : UserMethod === "upi"
                                      ? "UPI"
                                      : UserMethod === "paypal"
                                      ? "Paypal"
                                      : "Unknown Method"}
                              </h3>
                          </div>
                      ),
                  },
              ]
            : []),
    ];

    const Step = ({
        number,
        title,
        description,
        details,
        isLast,
        handleCancelOrder,
    }) => {
        const [isPopupOpen, setIsPopupOpen] = useState(false);

        useEffect(() => {
            console.log("isPopupOpensssss:", isPopupOpen); // Debugging the state changes
        }, [isPopupOpen]);

        return (
            <div className="flex">
                <div className="flex flex-col items-center">
                    <div className="relative w-5 h-5 flex justify-center items-center">
                        <div
                            className="absolute top-0 left-0 w-full h-full bg-black"
                            style={{
                                transform: "rotate(45deg)",
                                zIndex: -1,
                            }}
                        />
                        <span className="text-white relative">{number}</span>
                    </div>
                    {!isLast && (
                        <div className="flex-grow border-l-2 border-black"></div>
                    )}
                </div>
                <div className="ml-4 w-full">
                    <h2 className="text-sm md:text-[16px] font-semibold">
                        {title}
                    </h2>
                    <p className="text-sm md:text-[16px] py-2">{description}</p>
                    <div
                        className={`rounded-xl my-3 ${
                            !(
                                title === "Notify Seller" ||
                                title === "Confirm payments is received."
                            )
                                ? "border p-3"
                                : "p-0"
                        }`}
                    >
                        {title ===
                            "Open Bank Transfer to transfer Rs. 51,000.00" && (
                            <div className="pb-2 flex items-center gap-2">
                                <CgLoadbar className="rotate-90 text-[#EFB81C]" />
                                <h3 className="text-sm mb-0 md:text-[16px] font-semibold">
                                    {UserMethod === "cdm"
                                        ? "Cash Deposit"
                                        : UserMethod === "upi"
                                        ? "UPI"
                                        : UserMethod === "paypal"
                                        ? "Paypal"
                                        : "Unknown Method"}
                                </h3>
                            </div>
                        )}
                        {Adtype == "2" &&
                        title ===
                            `Confirm that the payments from ${
                                Name ? Name : "Bydefault Name"
                            }` ? (
                            <div className="py-4 flex items-center gap-2">
                                <CgLoadbar className="rotate-90 text-[#EFB81C]" />
                                <h3 className="text-sm mb-0 md:text-[16px] font-semibold">
                                    {UserMethod === "cdm"
                                        ? "Cash Deposit"
                                        : UserMethod === "upi"
                                        ? "UPI"
                                        : UserMethod === "paypal"
                                        ? "Paypal"
                                        : "Unknown Method"}
                                </h3>
                            </div>
                        ) : (
                            <div></div>
                        )}
                        {details.map((detail, index) =>
                            detail.label ? (
                                <DetailRow
                                    key={index}
                                    label={detail.label}
                                    value={detail.value}
                                    icon={detail.icon}
                                    info={detail.info}
                                />
                            ) : null
                        )}
                        {Adtype == "2" &&
                        title ===
                            `Confirm that the payments from ${
                                Name ? Name : "Bydefault Name"
                            }` ? (
                            <div className="py-2 my-2 mb-3 px-4 border rounded-md w-full justify-between flex items-center gap-2">
                                <h3 className="mb-0 text-[#aaaaaa]">
                                    Buyer's name
                                </h3>
                                <h3 className="text-sm mb-0 md:text-[16px] font-semibold">
                                    {Name ? Name : "Bydefault Name"}
                                </h3>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>

                {/* P2pCancel Popup */}
                {isPopupOpen && (
                    <div>
                        {/* P2pCancel component */}
                        <P2pCancel
                            Id={Id}
                            onClose={() => setIsPopupOpen(false)}
                            onConfirmCancel={handleCancelOrder}
                        />
                    </div>
                )}
            </div>
        );
    };
    // Steps data without buttons
    const steps = [
        {
            number: <FaCheck />,
            title: "Order Created",
            details: [
                {
                    label: "Fiat amount",
                    value: (
                        <span className="font-semibold text-sm md:text-[16px] text-[#2ECC71]">
                            {formattedFiat}
                        </span>
                    ),
                    info: "This is the total amount you need to pay.",
                    icon: (
                        <MdFileCopy
                            className="cursor-pointer text-gray-400 text-[14px]"
                            onClick={() => copyToClipboard(formattedFiat)}
                        />
                    ),
                },
                { label: "Price", value: formattedCoin },
                Adtype == "1"
                    ? {
                          label: "Receive Quantity",
                          value: formattedPrice,
                          icon: <IoIosArrowDown className="cursor-pointer" />,
                      }
                    : {
                          label: "Total Quantity",
                          value: formattedPrice,
                          icon: <IoIosArrowDown className="cursor-pointer" />,
                      },
            ],
        },
        {
            number: 2,
            title:
                Adtype == "1"
                    ? `Open Bank Transfer to transfer ${formattedFiat}`
                    : `Confirm that the payments from ${
                          Name ? Name : "Bydefault Name"
                      }`,
            description:
                Adtype == "1" ? (
                    <div className="flex items-center gap-2">
                        <p className="text-sm md:text-[16px] py-1">
                            Transfer the funds to the seller's account provided
                            below.
                        </p>
                        <Tooltip title="This is the seller’s account info.">
                            <MdInfo className="cursor-pointer text-gray-400 text-[14px]" />
                        </Tooltip>
                    </div>
                ) : (
                    <div>
                        <div className="flex items-center gap-2">
                            <p className="text-sm md:text-[16px] py-1">
                                Log into your payment account below.
                            </p>
                        </div>
                        <p className="text-[#FF0000]">
                            *Scammers can send fake banking app notification,
                            Beware from this frauds.
                        </p>
                    </div>
                ),
            details: UserMethod
                ? [
                      {
                          label: "Payment Method",
                          value:
                              UserMethod === "cdm"
                                  ? "Cash Deposit"
                                  : UserMethod === "upi"
                                  ? "UPI"
                                  : UserMethod === "paypal"
                                  ? "Paypal"
                                  : "Unknown Method",
                          icon: (
                              <MdFileCopy
                                  className="cursor-pointer text-gray-400 text-[14px]"
                                  onClick={() => copyToClipboard(UserMethod)}
                              />
                          ),
                      },
                      {
                          label: "Information",
                          value: UserInformation,
                          icon: (
                              <MdFileCopy
                                  className="cursor-pointer text-gray-400 text-[14px]"
                                  onClick={() =>
                                      copyToClipboard(UserInformation)
                                  }
                              />
                          ),
                      },
                  ]
                : [
                      {
                          label: "Name",
                          value: UserBankName,
                          icon: (
                              <MdFileCopy
                                  className="cursor-pointer text-gray-400 text-[14px]"
                                  onClick={() => copyToClipboard(UserBankName)}
                              />
                          ),
                      },
                      {
                          label: "Bank Card/Account Number",
                          value: UserBankCard,
                          icon: (
                              <MdFileCopy
                                  className="cursor-pointer text-gray-400 text-[14px]"
                                  onClick={() => copyToClipboard(UserBankCard)}
                              />
                          ),
                      },
                      {
                          label: "Bank Name",
                          value: UserBank,
                          icon: (
                              <MdFileCopy
                                  className="cursor-pointer text-gray-400 text-[14px]"
                                  onClick={() => copyToClipboard(UserBank)}
                              />
                          ),
                      },
                      {
                          label: "Account Opening Branch",
                          value: UserBankAddress,
                          icon: (
                              <MdFileCopy
                                  className="cursor-pointer text-gray-400 text-[14px]"
                                  onClick={() =>
                                      copyToClipboard(UserBankAddress)
                                  }
                              />
                          ),
                      },
                  ],
        },
        {
            number: 3,
            title:
                Adtype == "1"
                    ? "Notify Seller"
                    : "Confirm payments is received.",
            description:
                Adtype == "1"
                    ? "After payment, remember to click the 'Transfered, Notify Seller' button to facilitate the crypto release by the seller."
                    : "After confirming the payment, be sure to click the 'Payment received' button.",
            details: [], // No details for this step
        },
    ];

    const handleCancelOrder = () => {
        setIsOrderCancelled(true);
        setIsPopupOpen(false);
    };

    useEffect(() => {
        console.log("isPopupOpen:", isPopupOpen); // Debugging the state changes
    }, [isPopupOpen]);

    const toggleChatBox = () => {
        setIsChatVisible(!isChatVisible);
    };

    return (
        <div className="container pb-20">
            <div className="flex justify-between w-full">
                {/* Left Section */}
                <div className="w-full md:w-full lg:w-1/2">
                    {isOrderCancelled ? (
                        <div className="py-4">
                            <div className="flex gap-1 mb-3 items-center sm:hidden md:hidden lg:hidden justify-end text-sm">
                                <div className="flex items-center gap-0.5">
                                    <MdChat />
                                    <button onClick={toggleChatBox}>
                                        Chat
                                    </button>
                                </div>{" "}
                                <IoIosArrowForward />
                            </div>

                            {/* Chatbox that covers the full screen on mobile */}
                            {isChatVisible && (
                                <div className="fixed inset-0 bg-white z-[9997] sm:hidden flex flex-col">
                                    <div className="flex-grow overflow-y-auto">
                                        <P2pChatBox
                                            toggleChatBox={toggleChatBox}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="flex w-full justify-between items-center">
                                <div>
                                    <h1 className="text-md md:text-lg lg:text-xl mb-0 font-semibold">
                                        You Have Canceled the Order
                                    </h1>
                                    <div className="flex gap-1 py-1.5">
                                        <span className="text-[#aaaaaa] text-[12px] md:text-sm lg:text-sm">
                                            Order Number
                                        </span>
                                        <span className="text-[12px] md:text-sm lg:text-sm">
                                            {orderId}
                                        </span>
                                        <Tooltip title="Copy Order Number">
                                            <MdFileCopy
                                                className="cursor-pointer text-gray-400 text-[12px] md:text-sm lg:text-sm mt-1"
                                                onClick={() =>
                                                    navigator.clipboard.writeText(
                                                        orderId
                                                    )
                                                }
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                                <IoPersonCircle className="text-gray-200 w-14 h-14" />
                            </div>

                            <div className="mt-10">
                                <CancelOrder
                                    Id={Id}
                                    AdId={AdId}
                                    orderDetails={orderDetails}
                                />
                            </div>

                            {/* Feedback modal */}
                            {showFeedback && (
                                <Modal
                                    title=""
                                    visible={showFeedback}
                                    onCancel={() => setShowFeedback(false)}
                                    footer={null}
                                >
                                    <P2pFeedBack
                                        onCancel={() => setShowFeedback(false)}
                                    />
                                </Modal>
                            )}
                        </div>
                    ) : isOrderCompleted ? ( // Check if the order is completed
                        <div className="py-4">
                            <div className="flex gap-1 mb-3 items-center sm:hidden md:hidden lg:hidden justify-end text-sm">
                                <div className="flex items-center gap-0.5">
                                    <MdChat />
                                    <button onClick={toggleChatBox}>
                                        Chat
                                    </button>
                                </div>{" "}
                                <IoIosArrowForward />
                            </div>

                            {/* Chatbox that covers the full screen on mobile */}
                            {isChatVisible && (
                                <div className="fixed inset-0 bg-white z-[9997] sm:hidden flex flex-col">
                                    <div className="flex-grow overflow-y-auto">
                                        <P2pChatBox
                                            toggleChatBox={toggleChatBox}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="flex w-full mb-1 justify-between items-center">
                                <div>
                                    <h1 className="text-md md:text-lg lg:text-xl mb-0 font-semibold">
                                        You Have Completed the Order
                                    </h1>
                                    <div className="flex gap-1 py-1.5">
                                        <span className="text-[#aaaaaa] text-[12px] md:text-sm lg:text-sm">
                                            Order Number
                                        </span>
                                        <span className="text-[12px] md:text-sm lg:text-sm">
                                            {orderid}
                                        </span>
                                        <Tooltip title="Copy Order Number">
                                            <MdFileCopy
                                                className="cursor-pointer text-gray-400 text-[12px] md:text-sm lg:text-sm mt-1"
                                                onClick={() =>
                                                    navigator.clipboard.writeText(
                                                        orderid
                                                    )
                                                }
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                                <IoMdCheckmarkCircle className="text-[#00e297] bg-[#DFF7F0] p-1 rounded-full w-14 h-14" />
                            </div>
                            <div>
                                <CompleteOrder
                                    Id={Id}
                                    AdId={AdId}
                                    Time={Time}
                                    UserMethod={UserMethod}
                                    formattedPrice={formattedPrice}
                                    formattedCoin={formattedCoin}
                                    formattedFiat={formattedFiat}
                                    orderDetails={orderDetails}
                                    Adtype={Adtype}
                                />
                            </div>
                            {/* Feedback modal */}
                            {showComplete && (
                                <Modal
                                    title=""
                                    visible={showFeedback}
                                    onCancel={() => setShowFeedback(false)}
                                    footer={null}
                                >
                                    <P2pComplete
                                        formattedFiat={formattedFiat}
                                        setShowFeedback={setShowFeedback}
                                    />
                                </Modal>
                            )}
                        </div>
                    ) : (
                        <>
                            <div className="py-4 border-b">
                                <div className="flex w-full justify-between">
                                    {Adtype == "1" ? (
                                        <h1 className="text-md md:text-lg lg:text-xl font-semibold">
                                            Order Created, Pay the Seller within{" "}
                                            <span className="text-[#EFB81C] text-md md:text-lg lg:text-xl">
                                                {timeLeft
                                                    ? formatTime(timeLeft)
                                                    : "00:00"}{" "}
                                            </span>
                                        </h1>
                                    ) : (
                                        <div>
                                            <h1 className="text-md md:text-lg lg:text-xl font-semibold">
                                                Verify Payment
                                            </h1>
                                        </div>
                                    )}
                                    {/* Chat button on mobile view */}
                                    <div className="flex gap-1 mb-3 items-center sm:hidden md:hidden lg:hidden justify-end text-sm">
                                        <div className="flex items-center gap-0.5">
                                            <MdChat />
                                            <button onClick={toggleChatBox}>
                                                Chat
                                            </button>
                                        </div>{" "}
                                        <IoIosArrowForward />
                                    </div>
                                </div>
                                <div className="flex gap-1 py-1.5">
                                    <span className="text-[#aaaaaa] text-[12px] md:text-sm lg:text-sm">
                                        Order Number
                                    </span>
                                    <span className="text-[12px] md:text-sm lg:text-sm">
                                        {orderId}
                                    </span>
                                    <Tooltip title="Copy Order Number">
                                        <MdFileCopy
                                            className="cursor-pointer text-gray-400 text-[12px] md:text-sm lg:text-sm mt-1"
                                            onClick={() =>
                                                navigator.clipboard.writeText({
                                                    orderId,
                                                })
                                            }
                                        />
                                    </Tooltip>
                                </div>

                                {/* Chatbox that covers the full screen on mobile */}
                                {isChatVisible && (
                                    <div className="fixed inset-0 bg-white z-[9997] sm:hidden flex flex-col">
                                        <div className="flex-grow overflow-y-auto">
                                            <P2pChatBox
                                                toggleChatBox={toggleChatBox}
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className="relative hidden sm:block md:block lg:hidden">
                                    {/* Chatbox for tablet view */}
                                    <div
                                        className={`fixed top-20 bottom-0 right-0 w-80 rounded-2xl transform transition-transform duration-300 ${
                                            isChatVisible
                                                ? "translate-x-0 mr-2"
                                                : "translate-x-full mr-0"
                                        }`}
                                    >
                                        <P2pChatBox />
                                    </div>

                                    {/* Chat Icon */}
                                    <button
                                        onClick={toggleChatBox}
                                        className={`fixed top-20 bg-[#EFB81C] p-4 rounded-full shadow-lg text-white text-xl transform transition-transform duration-400 focus:outline-none z-50 ${
                                            isChatVisible
                                                ? "translate-x-0 right-80 mr-2"
                                                : "translate-x-full right-[3.2rem] mr-0"
                                        }`}
                                    >
                                        <FaComments />
                                    </button>
                                </div>
                            </div>

                            {/* Steps */}
                            <div className="flex flex-col mt-10">
                                {steps.map((step, index) => (
                                    <Step
                                        key={index}
                                        number={step.number}
                                        title={step.title}
                                        description={step.description}
                                        details={step.details}
                                        isLast={index === steps.length - 1}
                                        handleCancelOrder={handleCancelOrder}
                                    />
                                ))}
                                {Adtype == "1" ? (
                                    <div className="ml-0 lg:ml-8 md:ml-0 flex flex-col md:flex-row lg:flex-row gap-2">
                                        <button
                                            onClick={openAuthModal}
                                            className="bg-[#EFB81C] font-semibold px-3 py-2 h-10 rounded-md text-sm md:text-[16px]"
                                        >
                                            Transfered, Notify Seller
                                        </button>
                                        <button
                                            className="text-[#EFB81C] font-semibold px-3 py-2 h-10 rounded-md text-sm md:text-[16px]"
                                            onClick={() => setIsPopupOpen(true)}
                                        >
                                            Cancel Order
                                        </button>
                                    </div>
                                ) : (
                                    <div className="ml-0 lg:ml-8 md:ml-0 flex flex-col md:flex-row lg:flex-row gap-2">
                                        <button
                                            className="bg-[#EFB81C] font-semibold px-3 py-2 h-10 rounded-md text-sm md:text-[16px]"
                                            onClick={openAuthModal}
                                        >
                                            Payment Received
                                        </button>
                                        <button
                                            className="text-[#EFB81C] font-semibold px-3 py-2 h-10 rounded-md text-sm md:text-[16px]"
                                            onClick={openAppealModal}
                                        >
                                            Appeal
                                        </button>
                                    </div>
                                )}

                                {/* P2pCancel Popup */}
                                {isPopupOpen && (
                                    <div>
                                        <P2pCancel
                                            Id={Id}
                                            onClose={() =>
                                                setIsPopupOpen(false)
                                            }
                                            onConfirmCancel={handleCancelOrder}
                                        />
                                    </div>
                                )}

                                {/* Authenticator Modal */}
                                {authVisible && (
                                    <P2pAuthenticator
                                        authVisible={authVisible}
                                        onClose={closeAuthModal}
                                        orderId={Id}
                                        handleCompleteOrder={
                                            handleCompleteOrder
                                        }
                                        onSuccess={onSuccess}
                                    />
                                )}

                                {/* Appeal Modal rendering */}
                                {isAppealModalVisible && (
                                    <Modal
                                        visible={isAppealModalVisible}
                                        onCancel={closeAppealModal}
                                        footer={null}
                                        title="Available Solutions"
                                    >
                                        <P2pAppeal onClose={closeAppealModal} />
                                    </Modal>
                                )}
                            </div>
                        </>
                    )}
                </div>

                {/* Right Section - Chatbox visibility depends on isChatVisible */}
                <div className="hidden md:hidden lg:block w-1/3">
                    <P2pChatBox />
                </div>
            </div>
        </div>
    );
});

export default P2pChat;
