import {
    GET_USER_WALLETS_REQUEST,
    GET_USER_WALLETS_SUCCESS,
    GET_USER_WALLETS_FAILURE,
    ADD_WALLET_WHITELIST_REQUEST,
    ADD_WALLET_WHITELIST_SUCCESS,
    ADD_WALLET_WHITELIST_FAILURE,
    DELETE_WALLET_WHITELIST_REQUEST,
    DELETE_WALLET_WHITELIST_SUCCESS,
    DELETE_WALLET_WHITELIST_FAILURE,
    EDIT_WALLET_WHITELIST_REQUEST,
    EDIT_WALLET_WHITELIST_SUCCESS,
    EDIT_WALLET_WHITELIST_FAILURE,
} from "../types";

const initialState = {
    loading: false,
    wallets: [],
    error: null,
    success: false,
};

const walletwhitelistReducer = (state = initialState, action) => {
    switch (action.type) {
        // Fetch user wallets
        case GET_USER_WALLETS_REQUEST:
            return { ...state, loading: true, error: null };
        case GET_USER_WALLETS_SUCCESS:
            return { ...state, loading: false, wallets: action.payload, error: null };
        case GET_USER_WALLETS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        // Add wallet to whitelist
        case ADD_WALLET_WHITELIST_REQUEST:
            return { ...state, loading: true, error: null };
        case ADD_WALLET_WHITELIST_SUCCESS:
            return { ...state, loading: false, wallets: [...state.wallets, action.payload], error: null };
        case ADD_WALLET_WHITELIST_FAILURE:
            return { ...state, loading: false, error: action.payload };

        // Delete wallet from whitelist
        case DELETE_WALLET_WHITELIST_REQUEST:
            return { ...state, loading: true, error: null };
        case DELETE_WALLET_WHITELIST_SUCCESS:
            return {
                ...state,
                loading: false,
                wallets: state.wallets.filter((wallet) => wallet.id !== action.payload),
                error: null,
            };
        case DELETE_WALLET_WHITELIST_FAILURE:
            return { ...state, loading: false, error: action.payload };

        // Edit wallet in whitelist
        case EDIT_WALLET_WHITELIST_REQUEST:
            return { ...state, loading: true, error: null };
        case EDIT_WALLET_WHITELIST_SUCCESS:
            return {
                ...state,
                loading: false,
                wallets: state.wallets.map((wallet) =>
                    wallet.id === action.payload.id ? { ...wallet, ...action.payload } : wallet
                ),
                error: null,
            };
        case EDIT_WALLET_WHITELIST_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

export default walletwhitelistReducer;
