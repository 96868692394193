import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ContinueBtn from "components/cryptoWithdraw/ContinueBtn";
import withdrawKey from "assets/img/Withdraw-key.png";
import { AiOutlineClose } from "react-icons/ai";
// import RequestOTP from "./requestOTP ";
import toast from "react-hot-toast";
import { Modal } from "antd";
import { IoMdCheckmarkCircle } from "react-icons/io";

const InternalTransfer = ({ selectedCoin }) => {
    const history = useHistory();
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    const [otp, setOtp] = useState("");
    const [coin, setCoin] = useState(selectedCoin?.value.toLowerCase() || "");
    const [num, setNum] = useState("");
    const [address, setAddress] = useState("");
    const [payPassword, setPayPassword] = useState("");
    const [payMethod, setPayMethod] = useState("email");
    const [responseMessage, setResponseMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [errors, setErrors] = useState({});
    const [uidInputVisible, setUidInputVisible] = useState(false);
    const [receiver_id, setReceiverId] = useState("");
    const [showErrors, setShowErrors] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState({});

    useEffect(() => {
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
        }
    }, [TOKEN, ID, history]);

    useEffect(() => {
        if (selectedCoin) {
            setCoin(selectedCoin.value.toLowerCase());
        }
    }, [selectedCoin]);

    useEffect(() => {
        setPayMethod("email");
    }, []);

    useEffect(() => {
        validateForm();
    }, [coin, num, address, payPassword]);

    const validateForm = () => {
        let errors = {};

        if (!num && showErrors) {
            toast.error("Amount is required.");
        }
        if (num && parseFloat(num) < 0.01 && showErrors) {
            toast.error("Amount must be at least 0.01.");
        }
        if (!address && !receiver_id && showErrors) {
            toast.error("Either email address or receiver ID is required.");
        }

        setErrors(errors);
        setIsFormValid(
            Object.keys(errors).length === 0 &&
            !!num &&
            (address || receiver_id)
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        validateForm();

        if (!address && !receiver_id && !num) {
            toast.error(
                "Please enter an amount and either an email address or receiver ID."
            );
            return;
        }

        if ((address || receiver_id) && !num) {
            toast.error(
                "Amount is required when an email address or receiver ID is provided."
            );
            return;
        }

        if (isFormValid && !isSubmitting) {
            setIsSubmitting(true);

            if (parseFloat(num) < 0.01) {
                toast.error("Amount must be at least 0.01.");
                setIsSubmitting(false);
                return;
            }

            setIsLoading(true);

            try {
                const formData = new FormData();
                formData.append("user_id", ID);
                formData.append("otp", otp);
                formData.append("coin", coin);
                formData.append("num", num);
                formData.append("paypassword", payPassword);
                formData.append("paymethod", payMethod);

                if (payMethod === "email") {
                    formData.append("address", address);
                } else {
                    formData.append("receiver_id", receiver_id);
                }

                const response = await axios.post(
                    "https://api.buycex.com/Pay/doPaymentSend",
                    formData,
                    { headers: { TOKEN, ID } }
                );

                if (response.data.status === 1) {
                    toast.success("Transaction successful!");
                    const transactionTime = new Date().toLocaleString();
                    setShowModal(false);
                    setCoin(selectedCoin.value.toLowerCase())
                    setNum('')
                    setAddress('')
                    setPayPassword('')
                    setPayMethod('')
                    setReceiverId('')
                    setTransactionDetails({
                        amount: num,
                        receiver: payMethod === "email" ? address : receiver_id,
                        currency: coin,
                        transactionTime: transactionTime,
                        method: payMethod
                    });
                    console.log(address,receiver_id,payMethod)

                    setShowSuccessModal(true);
                } else if (response.data.status === 0) {
                    toast.error(response.data.data || "Transaction failed.");
                    setResponseMessage(response.data.data);
                    setShowModal(true);
                } else {
                    // toast.error("Transaction failed.");
                    // setResponseMessage("Transaction failed.");
                    setShowModal(true);
                }
            } catch (error) {
                // toast.error(
                //     "Error occurred. Please check console for details."
                // );
                // console.error("Submission error:", error); // Error log for more details
            } finally {
                setIsLoading(false);
                setIsSubmitting(false);
            }
        } else {
            // console.log("Form is either invalid or currently submitting."); // Debugging check
        }
    };

    const handlePayMethodChange = (method) => {
        setPayMethod(method);
        if (method === "payid") {
            setUidInputVisible(true);
        } else {
            setUidInputVisible(false);
        }
    };
    const closeModal = () => {
        setShowModal(false);
        setShowSuccessMessage(false);
    };

    return (
        <div className="w-full">
            <div
                className="inline-flex w-[100%] my-3 rounded-md"
                role="group"
            >
                <button
                    type="button"
                    className={`px-4 py-2 text-sm font-semibold rounded-md dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-[#EFB81C] ${payMethod === "email"
                        ? "text-black bg-[#F5F5F5]"
                        : "hover:text-black text-[#707a8a]"
                        }`}
                    onClick={() => handlePayMethodChange("email")}
                >
                    Email
                </button>

                <button
                    type="button"
                    className={`px-4 py-2 text-sm font-semibold rounded-md dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-[#EFB81C] ${payMethod === "payid"
                        ? "text-black bg-[#F5F5F5]"
                        : "hover:text-black text-[#707a8a]"
                        }`}
                    onClick={() => handlePayMethodChange("payid")}
                >
                    User ID
                </button>
            </div>
            <form onSubmit={handleSubmit}>
                {!uidInputVisible && (
                    <>
                        <p className="text-[#81858c] mt-3 text-sm mb-1">Email Address</p>
                        <input
                            type="email"
                            placeholder="Recipient's email"
                            className="bg-[#f3f5f7] h-14 rounded-[10px] text-gray-900 text-sm focus:ring-1 focus:ring-[#EFB81C] focus:border-[#EFB81C] dark:border-[#EFB81C] focus:outline-none block w-full p-2.5 dark:bg-transparent dark:placeholder-gray-400 dark:text-white dark:focus:!border-[#EFB81C] dark:focus:outline-none"
                            onChange={(e) => setAddress(e.target.value)}
                            onFocus={() => setShowErrors(false)}
                        />
                    </>
                )}
                {uidInputVisible && (
                    <>
                        <p className="text-[#81858c] mt-3 text-sm mb-1">
                            Receiver ID
                        </p>
                        <input
                            type="number"
                            placeholder="Enter Receiver ID"
                            className="bg-[#f3f5f7] h-14 rounded-[10px] text-gray-900 text-sm focus:ring-1 focus:ring-[#EFB81C] focus:border-[#EFB81C] dark:border-[#EFB81C] focus:outline-none block w-full p-2.5 dark:bg-transparent dark:placeholder-gray-400 dark:text-white dark:focus:!border-[#EFB81C] dark:focus:outline-none"
                            onChange={(e) => setReceiverId(e.target.value)}
                            onFocus={() => setShowErrors(false)}
                        />
                    </>
                )}
                <p className="text-[#81858c] text-sm mt-6 mb-1">Amount</p>
                <input
                    type="number"
                    step="0.01"
                    placeholder="Minimal 0.01"
                    className="bg-[#f3f5f7] mb-4 h-14 rounded-[10px] text-gray-900 text-sm focus:ring-1 focus:ring-[#EFB81C] focus:border-[#EFB81C] dark:border-[#EFB81C] focus:outline-none block w-full p-2.5 dark:bg-transparent dark:placeholder-gray-400 dark:text-white dark:focus:!border-[#EFB81C] dark:focus:outline-none"
                    onChange={(e) => setNum(e.target.value)}
                    onFocus={() => setShowErrors(false)}
                />
                <div className="flex justify-end w-full">
                <ContinueBtn
                    onClick={(e) => {
                        e.preventDefault();
                        setShowErrors(true);
                        // validateForm();
                        if (isFormValid) {
                            setShowModal(true);
                        }
                    }}
                    disabled={!isFormValid}
                >
                    Continue
                </ContinueBtn>
                </div>

                {showModal && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50 ">

                        <div
                            className="bg-white p-6 rounded-lg shadow-lg dark:!bg-zinc-900"
                            style={{
                                width: "30%",
                                minWidth: "350px",
                            }}
                        >
                            <div className="flex flex-col items-center justify-center">

                                <div className="flex self-end cursor-pointer">
                                    <AiOutlineClose
                                        onClick={() => setShowModal(false)}
                                    />
                                </div>
                                <img
                                    src={withdrawKey}
                                    alt="withdrawKey"
                                    className="w-40"
                                />

                                <div className="w-100">
                                    <label className="font-[600] dark:text-white">
                                        Fund Password
                                    </label>
                                    <input
                                        type="password"
                                        className="bg-gray-50 h-14 rounded-[10px] border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Enter your fund password"
                                        value={payPassword}
                                        onChange={(e) =>
                                            setPayPassword(e.target.value)
                                        }
                                        onFocus={() => setShowErrors(false)}
                                        required
                                    />
                                </div>
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className="mt-4 pl-4 w-100 pr-4 px-4 py-2 rounded-lg bg-[#EFB81C] text-white"
                                >
                                    {isLoading ? "Loading..." : "Submit"}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <Modal
                    title="Transaction Success"
                    visible={showSuccessModal}
                    onCancel={() => setShowSuccessModal(false)}
                    footer={null}
                >

                    <div className="p-4 rounded-xl">
                        <div className='flex justify-center items-center'><IoMdCheckmarkCircle className='text-[80px] text-[#00e297] bg-[#DFF7F0] p-1 rounded-full' /></div>
                        <h2 className="text-lg text-center font-bold mt-2">Recipient Amount</h2>
                        <p className="text-2xl text-center font-bold uppercase">{transactionDetails.currency}</p>
                        <p className="text-center mb-4 mt-1">The Recipient can check his balance in the funding wallet.</p>
                        <div className="bg-[#EFB81C] bg-opacity-20 rounded-md p-3">
                            <p className="font-semibold">Visit the Buycex Pay homepage for more payment services</p>
                            <p className="text-[#EFB81C] font-semibold text-sm">View Now</p>

                        </div>
                        <div className="my-6 flex flex-col gap-2">
                            <div className="flex w-full justify-between text-sm font-medium text-[#707a8a]">
                                <p>Total Paid</p>
                                <p className="uppercase">{transactionDetails.amount} {transactionDetails.currency}</p>
                            </div>
                            <div className="flex w-full justify-between text-sm font-medium text-[#707a8a]">
                                <p>Receiver</p>
                                <p>{transactionDetails.receiver}</p>
                            </div>
                            <div className="flex w-full justify-between text-sm font-medium text-[#707a8a]">
                                <p>Send Mode</p>
                                {/* Correctly display "Email" when payMethod is "email" */}
                                <p>{transactionDetails.method === 'email' ? 'Email Address' : transactionDetails.method === 'payid' ? 'User ID' : ''}</p>
                            </div>
                            <div className="flex w-full justify-between text-sm font-medium text-[#707a8a]">
                                <p>Time Created</p>
                                <p>{transactionDetails.transactionTime}</p>
                            </div>
                        </div>

                        <button onClick={() => setShowSuccessModal(false)} className="mt-4 flex bg-[#EFB81C] w-full py-2 px-4 rounded-xl items-center justify-center font-semibold ">
                            Ok
                        </button>
                    </div>
                </Modal>
            </form>
        </div>
    );
};

export default InternalTransfer;
