import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { FaAngleDown } from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

const AddModal = ({ getUserWallets,addWalletWhitelist, marketData, visible, onCancel }) => {
    const [isCoinDropdownOpen, setIsCoinDropdownOpen] = useState(false);
    const [selectedCoin, setSelectedCoin] = useState({ name: 'Select a coin', id: null });
    const [isNetworkDropdownOpen, setIsNetworkDropdownOpen] = useState(false);
    const [selectedNetwork, setSelectedNetwork] = useState('Select a network');
    const [walletAddress, setWalletAddress] = useState(null); // Default to null
    const [payPassword, setPayPassword] = useState(null); // Default to null
    
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const dispatch = useDispatch();

    // Extract unique coins and their corresponding IDs
    const coins = [...new Map(marketData.map(item => {
        const coinName = item.coinData?.name
            ? item.coinData?.name?.charAt(0).toUpperCase() + item.coinData?.name.slice(1) // Capitalize first letter
            : 'Unknown'; // Fallback if name is missing
        return [coinName,  item.coinData?.id]; // Use coin name and ID as key-value pair
    })).entries()]
    .map(([name, id]) => ({ name, id })); // Convert Map entries back to array
    
    

    // Filter the networks based on the selected coin
    const filteredNetworks = selectedCoin.id
    ? [...new Set(
        marketData
            .filter(item => item.coinData?.name?.toLowerCase() === selectedCoin.name.toLowerCase()) // Match coin names
            .map(item => item.name.split('(')[0].trim()) // Extract part before parentheses and trim spaces
    )]
    : [];


    // Toggle dropdown visibility for coin
    const toggleCoinDropdown = () => {
        setIsCoinDropdownOpen(!isCoinDropdownOpen);
    };

    // Toggle dropdown visibility for network
    const toggleNetworkDropdown = () => {
        setIsNetworkDropdownOpen(!isNetworkDropdownOpen);
    };

    // Select coin from the dropdown
    const selectCoin = (coin) => {
        setSelectedCoin(coin);
        setSelectedNetwork('Select a network'); // Reset network selection when coin changes
        setIsCoinDropdownOpen(false);
    };

    // Select network from the dropdown
    const selectNetwork = (network) => {
        setSelectedNetwork(network);
        setIsNetworkDropdownOpen(false);
    };

    // Handle wallet address input
    const handleAddressChange = (e) => {
        setWalletAddress(e.target.value);
    };

    // Handle pay password input
    const handlePayPasswordChange = (e) => {
        setPayPassword(e.target.value);
    };
    const isValidAddress = (address, network) => {
        if (!address) return false;
    
        switch (network.toLowerCase()) {
            case 'ethereum':
            case 'bsc': 
            case 'polygon':
                // Ethereum-like networks
                return /^0x[a-fA-F0-9]{40}$/.test(address);
    
            case 'bitcoin':
                // Bitcoin addresses: Legacy (P2PKH), SegWit (P2WPKH), or Bech32
                return /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(address) || 
                       /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/.test(address);
    
            case 'solana':
                // Solana addresses (Base58)
                return /^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(address);
    
            case 'tron':
                // Tron addresses start with "T" followed by Base58 characters
                return /^T[a-zA-Z0-9]{33}$/.test(address);
    
            case 'dogecoin':
                // Dogecoin addresses
                return /^[D9][a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(address);
    
            case 'cardano':
                // Cardano addresses (can vary, simple pattern for mainnet here)
                return /^addr[a-z0-9]{58,}$/i.test(address);
    
            default:
                // Default case: Unknown network
                return false;
        }
    };
    
    const handleSubmit = async () => {
        if (!isValidAddress(walletAddress, selectedNetwork)) {
            toast.error("Invalid wallet address for the selected network.");
            return;
        }
        if (
            selectedCoin.id &&
            selectedNetwork !== 'Select a network' &&
            walletAddress.trim() &&
            payPassword.trim()
        ) {
            const walletData = {
                coin_id: selectedCoin.id,
                name: selectedNetwork,
                addr: walletAddress,
                paypassword: payPassword,
            };
    
            if (userID && userToken) {
                try {

                    console.log(walletData,"walletData")
                    const response = await dispatch(addWalletWhitelist(walletData, userToken, userID)); // Adjust action to return the API response
                    console.log(response?.data?.status,"response?.status")
                    if (response?.data?.status === 1) {
                        dispatch(getUserWallets(userToken, userID));
                        toast.success(response?.data?.data || "Wallet added successfully!");
                     
                        onCancel();  // Close the modal on success
               
                    } else {
                        toast.error(response?.data?.data || "An error occurred.");
                    }
                } catch (error) {
                    toast.error("Failed to add wallet. Please try again.");
                }
            } else {
                toast.error("User not authenticated.");
            }
        } else {
            toast.error("Please fill out all fields.");
        }
    };
    

    useEffect(() => {
        // Reset selected network if the selected coin changes
        setSelectedNetwork('Select a network');
    }, [selectedCoin]);

    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} />
            <Modal
                title="Add Address"
                visible={visible}
                onCancel={onCancel}
                footer={null}
            >
                {/* Coin Dropdown */}
                <label className="block mt-6 mb-1 text-xs font-normal text-[#81858c] dark:text-white">
                    Coin
                </label>
                <div className="relative mb-4">
                    <div
                        className="bg-gray-100 rounded-md py-2 px-3 flex justify-between items-center cursor-pointer"
                        onClick={toggleCoinDropdown}
                    >
                        <p>{selectedCoin.name}</p>
                        <FaAngleDown
                            className={`transition-transform transform ${isCoinDropdownOpen ? 'rotate-180' : ''}`}
                        />
                    </div>
                    {isCoinDropdownOpen && (
                        <ul className="absolute left-0 right-0 bg-white border rounded-md shadow-lg mt-1 z-10">
                            {coins.map((coin, index) => (
                                <li
                                    key={index}
                                    className="py-2 px-3 hover:bg-gray-200 cursor-pointer"
                                    onClick={() => selectCoin(coin)}
                                >
                                    {coin.name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                {/* Network Dropdown */}
                <label className="block mb-1 text-xs font-normal text-[#81858c] dark:text-white">
                    Network
                </label>
                <div className="relative mb-4">
                    <div
                        className="bg-gray-100 rounded-md py-2 px-3 flex justify-between items-center cursor-pointer"
                        onClick={toggleNetworkDropdown}
                    >
                        <p>{selectedNetwork}</p>
                        <FaAngleDown
                            className={`transition-transform transform ${isNetworkDropdownOpen ? 'rotate-180' : ''}`}
                        />
                    </div>
                    {isNetworkDropdownOpen && filteredNetworks.length > 0 && (
                        <ul className="absolute left-0 right-0 bg-white border rounded-md shadow-lg mt-1 z-10">
                            {filteredNetworks.map((network, index) => (
                                <li
                                    key={index}
                                    className="py-2 px-3 hover:bg-gray-200 cursor-pointer"
                                    onClick={() => selectNetwork(network)}
                                >
                                    {network}
                                </li>
                            ))}
                        </ul>
                    )}
                    {filteredNetworks.length === 0 && isNetworkDropdownOpen && (
                        <p className="text-xs text-center py-2">No networks available for this coin</p>
                    )}
                </div>

                {/* Wallet Address Input */}
                <label className="block mb-1 text-xs font-normal text-[#81858c] dark:text-white">
                    Wallet Address
                </label>
                <input
                    className="bg-gray-100 w-full rounded-md py-2 px-3"
                    placeholder="Please Enter"
                    value={walletAddress}
                    onChange={handleAddressChange}
                />

                {/* Pay Password Input */}
                <label className="block mb-1 mt-4 text-xs font-normal text-[#81858c] dark:text-white">
                    Pay Password
                </label>
                <input
                   type="password"
                    className="bg-gray-100 w-full rounded-md py-2 px-3"
                    placeholder="Please Enter"
                    value={payPassword}
                    onChange={handlePayPasswordChange}
                />

                <div className="flex mt-5 gap-5">
                    <button
                        onClick={handleSubmit}
                        className="w-1/2 py-2 font-medium rounded-md bg-[#EFB81C]"
                    >
                        Submit
                    </button>
                    <button
                        onClick={onCancel}
                        className="w-1/2 py-2 font-medium rounded-md ring-1 ring-slate-700"
                    >
                        Cancel
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default AddModal;
