import React, { useState } from 'react';
import { Modal } from 'antd';
import Address from './Address';
import InternalTransfer from './InternalTransfer';
import  { createGlobalStyle } from 'styled-components';

function WithdrawModal({ isVisible, onClose }) {
  const [showOnChainWithdrawal, setShowOnChainWithdrawal] = useState(true);
  const [showInternalTransfer, setShowInternalTransfer] = useState(false);

  const handleInternalTransferClick = () => {
    setShowOnChainWithdrawal(false);
    setShowInternalTransfer(true);
  };

  const handleOnChainWithdrawalClick = () => {
    setShowOnChainWithdrawal(true);
    setShowInternalTransfer(false);
  };
  
  return (
    <><GlobalModalStyle />
    <Modal
      title={<div className='flex w-full justify-between items-center'><p className="text-lg font-semibold">Withdraw</p>
        <button className='mr-6 bg-[#f3f5f7] px-4 py-1 rounded-md font-medium text-sm'>Fiat Withdraw</button>
      </div>}
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      className="max-w-[90%] sm:max-w-[70%] lg:max-w-[50%]"
    >

      <div className='flex gap-5 w-full'>
        <div className='w-[60%]'>
          <div className="flex flex-col lg:flex-row w-full gap-6 justify-between">
            <div
              className="flex gap-5 w-full text-base font-medium border-b-[1px] border-gray-300"
              style={{ height: "2rem" }}
            >
              <div
                className={`flex items-center ${showOnChainWithdrawal
                  ? "font-bold dark:text-white border-b-2 border-yellow-500"
                  : "text-gray-500"
                  }`}
              >
                <button
                  onClick={
                    handleOnChainWithdrawalClick
                  }
                >
                  <div className="text-xs">
                    Address
                  </div>
                </button>
              </div>
              <div
                className={`flex justify-center items-center ${showInternalTransfer
                  ? "font-bold dark:text-white border-b-2 border-yellow-500"
                  : "text-gray-500"
                  }`}
              >
                <button
                  type="button"
                  className="text-xs"
                  onClick={
                    handleInternalTransferClick
                  }
                >
                  Internal Transfer
                </button>
              </div>
            </div>
          </div>
          {showOnChainWithdrawal && (
            <>
              <Address onClose={onClose} />
            </>
          )}
          {showInternalTransfer && (
            <>
              <InternalTransfer onClose={onClose} />
            </>
          )}
        </div>
        <div className='w-[40%]'>
          <div className='border-b pb-5 '>
            <div className='bg-[#f3f5f7] mt-3 rounded-md p-2'>
              <p className="text-[#81858c] text-xs">Daily Remaining Limit</p>
              <p className="text-black text-xs font-medium mb-1">1,000,000/1,000,000 USDT</p>
              <p className='text-[#efb81c] text-xs pt-1'>Manage Limit</p>
            </div>
            {showInternalTransfer && (
              <>
                <p className='text-[#81858c] text-xs pt-3'>• Make sure the account info entered is accurate. After five (5) failed attempts, your access to the Internal Transfer will be suspended for 24 hours.</p>
              </>
            )}
          </div>
          <p className='text-[#efb81c] text-xs pt-1'>Please click here to know more</p>
        </div>
      </div>

    </Modal>
    </>
  );
}

const GlobalModalStyle = createGlobalStyle`
    :where(.css-dev-only-do-not-override-5wsri9).ant-modal-root .ant-modal-mask {
    background: rgba(0, 0, 0, 0.2) !important;
  }
`;

export default WithdrawModal;
