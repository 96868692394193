import React, { useState, useRef, useEffect } from "react";
import { Modal } from "antd";
import { FaClipboardList } from "react-icons/fa6";
import { IoMdArrowDropdown } from "react-icons/io";

const ProfitModel = ({ selectedCoin }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [mainTab, setMainTab] = useState("Profit");
    const [activeTab, setActiveTab] = useState("USDT");
    const [buy, setBuy] = useState("Long");
    const h2Ref = useRef(null);
    const detailsRef = useRef(null);
    // New ref for the details element

    const handleOk = () => {
        setIsModalOpen(false);
        setShowDetails(false); // Hide details when modal closes
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setShowDetails(false); // Hide details when modal cancels
    };

    const toggleDetails = () => {
        setShowDetails((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        // Check if the click is outside both the h2 and details elements
        if (
            h2Ref.current &&
            detailsRef.current &&
            !h2Ref.current.contains(event.target) &&
            !detailsRef.current.contains(event.target)
        ) {
            setShowDetails(false); // Hide details on outside click
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div>
            <button onClick={() => setIsModalOpen(true)} className="text-xl">
                <FaClipboardList />
            </button>
            <div className="model">
                <Modal
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    className="profit-model"
                >
                    <div>
                        <h2
                            ref={h2Ref}
                            onClick={toggleDetails}
                            className="text-lg font-bold  py-2 relative cursor-pointer dark:text-[#ffffff]"
                        >
                            <div className="flex items-center gap-2">
                                <img
                                    src={selectedCoin.icon}
                                    alt=""
                                    className="w-6 h-6"
                                />
                                <h2>{selectedCoin.name}</h2>
                                <IoMdArrowDropdown />
                            </div>
                        </h2>
                        {showDetails && (
                            <div
                                ref={detailsRef} // Assign ref to the details div
                                className="absolute bg-white dark:bg-[#101014] dark:text-[#ffffff]  w-96 h-96 top-16 left-6 border rounded-xl shadow-xl p-4"
                            >
                                <div className="bg-gray-100 dark:bg-opacity-10 rounded-lg p-2 ">
                                    <input
                                        type="text"
                                        className="border-none bg-transparent text-gray-400 placeholder:text-gray-400 texlxl
                                    "
                                        placeholder="Search"
                                    />
                                </div>
                                <div className="mt-4 border-b-[1px] border-gray-400  flex items-center gap-2">
                                    <button
                                        onClick={() => setActiveTab("USDT")}
                                        className={`text-xs font-bold py-2 border-b-[3px]  ${
                                            activeTab === "USDT"
                                                ? "border-yellow-500 text-black  dark:text-[#ffffff]"
                                                : "border-transparent text-gray-400"
                                        }`}
                                    >
                                        USDT Perpetual
                                    </button>
                                    <button
                                        onClick={() => setActiveTab("Inverse ")}
                                        className={`text-xs font-bold py-2 border-b-[3px]  ${
                                            activeTab === "Inverse "
                                                ? "border-yellow-500 text-black  dark:text-[#ffffff]"
                                                : "border-transparent text-gray-400"
                                        }`}
                                    >
                                        Inverse Perpetual
                                    </button>
                                    <button
                                        onClick={() => setActiveTab("Futures")}
                                        className={`text-xs font-bold py-2 border-b-[3px]  ${
                                            activeTab === "Futures"
                                                ? "border-yellow-500 text-black  dark:text-[#ffffff]"
                                                : "border-transparent text-gray-400"
                                        }`}
                                    >
                                        Inverse Futures
                                    </button>
                                </div>
                                <div className="mt-2 hover:bg-gray-100 dark:hover:bg-opacity-10 flex items-center py-2 rounded-lg px-2 gap-2 text-xs font-semibold">
                                    <img
                                        src={selectedCoin.icon}
                                        alt=""
                                        className="w-6 h-6"
                                    />
                                    <h2>{selectedCoin.name}</h2>
                                </div>
                                <div className="mt-2 hover:bg-gray-100 dark:hover:bg-opacity-10  flex items-center py-2 rounded-lg px-2 gap-2 text-xs font-semibold">
                                    <img
                                        src={selectedCoin.icon}
                                        alt=""
                                        className="w-6 h-6"
                                    />
                                    <h2>{selectedCoin.name}</h2>
                                </div>
                                <div className="mt-2 hover:bg-gray-100 dark:hover:bg-opacity-10  flex items-center py-2 rounded-lg px-2 gap-2 text-xs font-semibold">
                                    <img
                                        src={selectedCoin.icon}
                                        alt=""
                                        className="w-6 h-6"
                                    />
                                    <h2>{selectedCoin.name}</h2>
                                </div>
                            </div>
                        )}
                        <div className="mt-4">
                            <div className="mt-4 border-b-[1px] border-gray-400   flex items-center gap-4">
                                <button
                                    onClick={() => setMainTab("Profit")}
                                    className={`text-sm font-bold py-2 border-b-[3px]  ${
                                        mainTab === "Profit"
                                            ? "border-yellow-500 text-black  dark:text-[#ffffff]"
                                            : "border-transparent text-gray-400"
                                    }`}
                                >
                                    Profit/Loss
                                </button>
                                <button
                                    onClick={() => setMainTab("Target")}
                                    className={`text-sm font-bold py-2 border-b-[3px]  ${
                                        mainTab === "Target"
                                            ? "border-yellow-500 text-black  dark:text-[#ffffff]"
                                            : "border-transparent text-gray-400"
                                    }`}
                                >
                                    Target Price
                                </button>
                                <button
                                    onClick={() => setMainTab("Entry")}
                                    className={`text-sm font-bold py-2 border-b-[3px]  ${
                                        mainTab === "Entry"
                                            ? "border-yellow-500 text-black dark:text-[#ffffff]"
                                            : "border-transparent text-gray-400"
                                    }`}
                                >
                                    Entry Price
                                </button>
                            </div>
                            <div className="mt-4 flex gap-4 ">
                                <div className="flex-1">
                                    <div className="flex">
                                        <div
                                            onClick={() => setBuy("Long")}
                                            className={`flex-1 cursor-pointer flex items-center justify-center font-semibold text-xs  p-2 rounded-lg ${
                                                buy === "Long"
                                                    ? "bg-green-500 text-white"
                                                    : "bg-gray-100 text-black "
                                            }`}
                                        >
                                            Long
                                        </div>
                                        <div
                                            onClick={() => setBuy("Short")}
                                            className={`flex-1 cursor-pointer flex items-center justify-center font-semibold text-xs  p-2 rounded-lg ${
                                                buy === "Short"
                                                    ? "bg-red-500 text-white"
                                                    : "bg-gray-100 text-black"
                                            }`}
                                        >
                                            Short
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <h2 className="text-xs text-gray-400">
                                            Leverage
                                        </h2>
                                        <div className="bg-gray-100 dark:bg-opacity-10  p-2 rounded-lg mt-1">
                                            <input
                                                type="text"
                                                placeholder="10"
                                                className="bg-transparent border-none text-gray-400 placeholder:text-gray-400 text-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <h2 className="text-xs text-gray-400">
                                            Entry Price
                                        </h2>
                                        <div className="bg-gray-100 dark:bg-opacity-10 p-2 rounded-lg mt-1">
                                            <input
                                                type="text"
                                                placeholder="Enter Price"
                                                className="bg-transparent border-none text-gray-400 placeholder:text-gray-400 text-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <h2 className="text-xs text-gray-400">
                                            Close Price
                                        </h2>
                                        <div className="bg-gray-100 dark:bg-opacity-10 p-2 rounded-lg mt-1">
                                            <input
                                                type="text"
                                                placeholder="Enter Price"
                                                className="bg-transparent border-none text-gray-400 placeholder:text-gray-400 text-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <h2 className="text-xs text-gray-400">
                                            Qty
                                        </h2>
                                        <div className="bg-gray-100 dark:bg-opacity-10 p-2 rounded-lg mt-1">
                                            <input
                                                type="text"
                                                placeholder="Enter Quantity"
                                                className="bg-transparent border-none text-gray-400 placeholder:text-gray-400 text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-44 bg-gray-100 dark:bg-opacity-10  dark:text-[#ffffff] p-4 rounded-lg">
                                    <h2 className="text-xs font-bold">
                                        Results
                                    </h2>
                                    <div className="mt-4 flex flex-col gap-2 text-xs font-semibold text-gray-400">
                                        <div className="flex items-center justify-between">
                                            <div>Initial Margin</div>
                                            <div className="text-yellow-500">
                                                0.00 USDT
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>Profit/Loss</div>
                                            <div className="text-red-500">
                                                0.00 USDT
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>Profit/Loss%</div>
                                            <div className="text-red-500">
                                                0.00 %
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>ROI</div>
                                            <div className="text-red-500">
                                                0.00 %
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default ProfitModel;
