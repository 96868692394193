import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { FaAngleDown } from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';
const EditModal = ({getUserWallets, marketData,selectedWallet, editWalletWhitelist, visible, onOk, onCancel }) => {
    const [isCoinDropdownOpen, setIsCoinDropdownOpen] = useState(false);
    const [isNetworkDropdownOpen, setIsNetworkDropdownOpen] = useState(false);

    const [selectedCoin, setSelectedCoin] = useState('');
    const [selectedNetwork, setSelectedNetwork] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [payPassword, setPayPassword] = useState('');
    const dispatch = useDispatch();
    const coins = ['BTC', 'ETH', 'USDT', 'BNB', 'XRP'];
    const networks = ['BSC (BEP20)', 'ERC20', 'TRC20', 'Solana', 'Polygon'];

    // Populate form with selectedWallet data when it changes
    useEffect(() => {
        
        if (selectedWallet) {
            setSelectedCoin(selectedWallet.coinName || 'Select a coin');
            setSelectedNetwork(selectedWallet.networkName || 'Select a network');
            setWalletAddress(selectedWallet.walletAddress || '');
        }
    }, [selectedWallet]);
console.log("selectedWallet",selectedWallet?.coinId)
    const toggleCoinDropdown = () => {
        setIsCoinDropdownOpen(!isCoinDropdownOpen);
    };

    const toggleNetworkDropdown = () => {
        setIsNetworkDropdownOpen(!isNetworkDropdownOpen);
    };

    const selectCoin = (coin) => {
        setSelectedCoin(coin);
        setIsCoinDropdownOpen(false);
    };

    const selectNetwork = (network) => {
        setSelectedNetwork(network);
        setIsNetworkDropdownOpen(false);
    };
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
   
  
    const handleSubmit = async () =>{
        if (!selectedCoin || !selectedNetwork || !walletAddress || !payPassword) {
            alert('Please fill all fields.');
            return;
        }
    
        const payload = {
            coin_id: selectedWallet.coinId, // Map to the API's expected coin_id
            name: selectedCoin, // Corresponds to 'coinName' in the Redux action
            addr: walletAddress, // Wallet address to update
            paypassword: payPassword, // Pay password field
            id: selectedWallet.coinId, // Wallet ID (if required by the API)
        };
    
       
        if (userID && userToken) {
            try {
                // Dispatch edit wallet action and wait for the response
                const response = await  dispatch(editWalletWhitelist(payload, userToken, userID));
    
            
                if (response?.data?.status=== 1) {
                    dispatch(getUserWallets(userToken, userID));
                    toast.success(response?.data?.data || "Wallet updated successfully!");
                    onCancel(); // Close the modal on success
                } else {
                    toast.error(response?.data?.data || "An error occurred while editing the wallet.");
                }
            } catch (error) {
                toast.error("Failed to edit wallet. Please try again.");
            }
        } else {
            toast.error("User not authenticated.");
        }
        onOk(); // Close modal
    };
  
    
    return (
        <Modal title="Edit Address" visible={visible} footer={null} onCancel={onCancel}>
            <label className="block mt-6 mb-1 text-xs font-normal text-[#81858c] dark:text-white">
                Coin
            </label>
            <div className="relative mb-4">
                <div
                    className="bg-gray-100 rounded-md py-2 px-3 flex justify-between items-center cursor-pointer"
                    onClick={toggleCoinDropdown}
                >
                    <p>{selectedCoin}</p>
                    <FaAngleDown
                        className={`transition-transform transform ${isCoinDropdownOpen ? 'rotate-180' : ''}`}
                    />
                </div>
                {isCoinDropdownOpen && (
                    <ul className="absolute left-0 right-0 bg-white border rounded-md shadow-lg mt-1 z-10">
                        {coins.map((coin, index) => (
                            <li
                                key={index}
                                className="py-2 px-3 hover:bg-gray-200 cursor-pointer"
                                onClick={() => selectCoin(coin)}
                            >
                                {coin}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            <label className="block mb-1 text-xs font-normal text-[#81858c] dark:text-white">
                Network
            </label>
            <div className="relative mb-4">
                <div
                    className="bg-gray-100 rounded-md py-2 px-3 flex justify-between items-center cursor-pointer"
                    onClick={toggleNetworkDropdown}
                >
                    <p>{selectedNetwork}</p>
                    <FaAngleDown
                        className={`transition-transform transform ${isNetworkDropdownOpen ? 'rotate-180' : ''}`}
                    />
                </div>
                {isNetworkDropdownOpen && (
                    <ul className="absolute left-0 right-0 bg-white border rounded-md shadow-lg mt-1 z-10">
                        {networks.map((network, index) => (
                            <li
                                key={index}
                                className="py-2 px-3 hover:bg-gray-200 cursor-pointer"
                                onClick={() => selectNetwork(network)}
                            >
                                {network}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            <label className="block mb-1 text-xs font-normal text-[#81858c] dark:text-white">
                Wallet Address
            </label>
            <input
                className="bg-gray-100 w-full rounded-md py-2 px-3"
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
                placeholder="Please Enter"
            />

            <label className="block mb-1 mt-4 text-xs font-normal text-[#81858c] dark:text-white">
                Pay password
            </label>
            <input
                className="bg-gray-100 w-full rounded-md py-2 px-3"
                value={payPassword}
                onChange={(e) => setPayPassword(e.target.value)}
                placeholder="Please Enter"
                type="password"
            />
            <div className="flex mt-5 gap-5">
                <button onClick={handleSubmit} className="w-1/2 py-2 font-medium rounded-md bg-[#EFB81C]">
                    Submit
                </button>
                <button onClick={onCancel} className="w-1/2 py-2 font-medium rounded-md ring-1 ring-slate-700">
                    Cancel
                </button>
            </div>
        </Modal>
    );
};

export default EditModal;
