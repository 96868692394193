import React from "react";

const ContinueBtn = ({ onClick, children }) => {
    return (
        <button
            type="button"
            className="pl-10 pr-10 px-6 py-2.5 w-100 text-md rounded-[10px] font-medium text-white bg-[#EFB81C] hover:bg-[#EFB81C] focus:ring-4 focus:outline-none focus:bg-[#EFB81C] text-center dark:bg-[#EFB81C] dark:hover:bg-[#EFB81C] dark:focus:ring-blue-800"
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default ContinueBtn;
