import React from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
const ShopCard = ({ item }) => {
    return (
        <div>
            <div class="card">
                <div class="bg">
                    <div className="w-full h-40  rounded-[10px] overflow-hidden relative">
                        <img src={item.img} alt="" />
                        <button className="w-20 h-8 rounded-[10px] absolute top-1 right-1 font-semibold bg-red-500 text-[#ffffff]">
                            ${item.price}
                        </button>
                    </div>
                    <div className="p-2 flex flex-col gap-2">
                        <h2 className=" text-[18px] font-semibold">
                            {item.title}
                        </h2>
                        <p className="text-[#81858c] text-[14px]  h-16 overflow-hidden">
                            {item.desc}
                        </p>
                        <NavLink
                            to={`/shop/${item.id}`}
                            className="w-full h-10 bg-[#ffb11a] rounded-[10px] font-semibold text-sm text-black text-center pt-2"
                        >
                            See More
                        </NavLink>
                        <button className="w-full h-10 bg-[#ffb11a] rounded-[10px] font-semibold text-sm text-black">
                            Buy Now
                        </button>
                    </div>
                </div>
                <div class="blob"></div>
            </div>
        </div>
    );
};

export default ShopCard;
