import React,{useState} from 'react'
import P2pNeedHelp from './P2pChatPopups/P2pNeedHelp';
import P2pStepCancel from './P2pChatPopups/P2pStepCancel';
import P2pChatBox from './P2pChatBox/P2pChatBox';
import { FaComments } from 'react-icons/fa'; // Importing the close icon
import { FaAngleRight } from "react-icons/fa";
import { TradingExp } from 'store/actions/p2pActions';
import { useDispatch, useSelector } from 'react-redux';
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CancelOrder = ({orderDetails,Id,AdId}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isHelpVisible, setIsHelpVisible] = useState(false);
    const [isChatVisible, setIsChatVisible] = useState(false);
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.authUser?.user?.ID);
    const token = useSelector((state) => state.authUser?.user?.TOKEN);

    const stepsData = [
        { stepNumber: 1, title: 'Step 1', description: 'Description for step 1', isLast: false },
        { stepNumber: 2, title: 'Step 2', description: 'Description for step 2', isLast: false },
        { stepNumber: 3, title: 'Step 3', description: 'Description for step 3', isLast: false },
        { stepNumber: 4, title: 'Step 4', description: 'Description for step 4', isLast: true }
    ];

    // Function to show the steps modal
    const showModal = () => {
        setIsModalVisible(true);
    };

    // Function to close the steps modal
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // Function to show the help modal
    const showHelpModal = () => {
        setIsHelpVisible(true);
    };

    // Function to close the help modal
    const handleHelpCancel = () => {
        setIsHelpVisible(false);
    };

    const toggleChatBox = () => {
        setIsChatVisible(!isChatVisible);
    };

    const handleFeedback = (experienceValue) => {
        const payload = {
            order_id: Id,
            ad_id: AdId,
            experience: experienceValue,
        };
        dispatch(TradingExp(userId, token, payload));
    };

    const history = useHistory();

    const handleReorderClick = () => {
        history.push("/trade/p2p");
    };

    return (
        <div>
            <div className="p-4 bg-white rounded-xl border">
                <div className='border-b'>
                    <p className='font-semibold text-[14px] md:text-[18px]'><span className='text-[#2EBD85]'>Buy</span> <span>USDT</span></p>
                    {orderDetails.slice(0, 3).map((detail, index) => (
                        <div key={index} className='flex items-center justify-between'>
                            <p className='text-[#707A8A] py-2 text-sm md:text-[16px]'>{detail.label}</p>
                            {typeof detail.value === 'string' ? <p className=' text-sm md:text-[16px] font-semibold]'>{detail.value}</p> : detail.value}
                        </div>
                    ))}
                </div>
                {/* Chatbox that covers the full screen on mobile */}
                {isChatVisible && (
                    <div className="fixed inset-0 bg-white z-[9997] sm:hidden flex flex-col">

                        <div className="flex-grow overflow-y-auto">
                            <P2pChatBox toggleChatBox={toggleChatBox} />
                        </div>
                    </div>
                )}
                <div className="relative hidden sm:block md:block lg:hidden">
                    {/* Chatbox for tablet view */}
                    <div
                        className={`fixed top-20 bottom-0 right-0 w-80 rounded-2xl transform transition-transform duration-300 ${isChatVisible ? 'translate-x-0 mr-2' : 'translate-x-full mr-0'}`}
                    >
                        <P2pChatBox toggleChatBox={toggleChatBox} />
                    </div>

                    {/* Chat Icon */}
                    <button
                        onClick={toggleChatBox}
                        className={`fixed top-20 bg-[#EFB81C] p-4 rounded-full shadow-lg text-white text-xl transform transition-transform duration-400 focus:outline-none z-50 ${isChatVisible ? 'translate-x-0 right-80 mr-2' : 'translate-x-full right-[3.2rem] mr-0'}`}
                    >
                        <FaComments />
                    </button>
                </div>
                <div className='pt-4'>
                    {orderDetails.slice(3).map((detail, index) => (
                        <div key={index} className='flex items-center justify-between'>
                            <p className='text-[#707A8A] py-2 text-sm md:text-[16px]'>{detail.label}</p>
                            {typeof detail.value === 'string' ? <p className='text-sm md:text-[16px]'>{detail.value}</p> : detail.value}
                        </div>
                    ))}
                </div>
            </div>
            <div className='mt-5 flex gap-2'>
                <button onClick={handleReorderClick} className="bg-[#EFB81C] font-medium px-3 py-2 h-10 rounded-md">Reorder</button>
                <button
                    className="text-[#EFB81C] font-medium px-3 py-2 h-10 rounded-md"
                    onClick={showHelpModal} // Show help modal on click
                >
                    Need Help
                </button>
            </div>
            <div>
                <h3 className="font-semibold py-7">
                    How was your trading experience?
                </h3>
                <div className="flex w-full gap-2">
                    <button
                        className="flex items-center border rounded-md w-1/2 justify-center py-2 gap-3 font-medium"
                        onClick={() => handleFeedback(1)}
                    >
                        <BiSolidLike />
                        Positive
                    </button>
                    <button
                        className="flex items-center border rounded-md w-1/2 justify-center py-2 gap-3 font-medium"
                        onClick={() => handleFeedback(0)}
                    >
                        <BiSolidDislike />
                        Negative
                    </button>
                </div>
            </div>
            <div>
                {/* Clickable div to trigger the steps modal */}
                <div
                    className='p-4 border rounded-xl mt-8 flex w-full items-center justify-between cursor-pointer'
                    onClick={showModal}
                >
                    <p className='mb-0'>I paid but the order has been canceled</p>
                    <FaAngleRight />
                </div>

                {/* Modal with Steps */}
                <P2pStepCancel
                    visible={isModalVisible}
                    onClose={handleCancel}
                />

                {/* Help Modal */}
                <P2pNeedHelp
                    visible={isHelpVisible}
                    onClose={handleHelpCancel}
                />
            </div>
        </div>
    );
};

export default CancelOrder