import React, { useState } from "react";
import PropTypes from "prop-types";
import { BsArrowLeftRight, BsArrowRepeat, BsUpload, BsX } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AddFundModal = ({
    closeAddFundModal,
    setShowDepositModel,
    setShowTransferModel,
    setShowConvertModel,
}) => {
    const history = useHistory();

    const handleDeposit = () => {
        setShowDepositModel(true);
        closeAddFundModal();
    };

    const handleTransfer = () => {
        // history.push("crypto/transfer");
        setShowTransferModel(true);
        closeAddFundModal();
    };

    const handleConvert = () => {
        setShowConvertModel(true);
        closeAddFundModal();
    };

    return (
        <>
            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full backdrop-blur-[1.6px] z-[99999] bg-black/30">
                <div className="modal-content bg-[#FFFFFF] dark:bg-[#101014] dark:text-[#ffffff]  w-[300px] md:w-[400px] lg:w-[500px] rounded-md h-auto p-4 flex flex-col gap-2">
                    <div className="flex items-center justify-between w-full gap-1">
                        <h3 className="text-[14px] w-full font-bold">
                            Boost up your Balance
                        </h3>
                        <div className="flex w-max">
                            <button
                                type="button"
                                className="p-1 w-max"
                                onClick={closeAddFundModal}
                            >
                                <BsX size={20} />
                            </button>
                        </div>
                    </div>
                    <div className="title-tagline w-max text-wrap">
                        <p className="text-[14px] font-[500] text-[#15141590] dark:bg-opacity-10 dark:text-[#ffffff]">
                            Want to add funds to your account? <br />
                            Please boost up your balance by choosing the
                            following options you want.
                        </p>
                    </div>
                    <div className="flex flex-col gap-2 p-2">
                        <div className="flex bg-[#F4F4F4] dark:bg-opacity-10 hover:bg-[#ddd] rounded-md">
                            <button
                                type="button"
                                className="w-full px-6 py-4 font-bold text-[12px] flex items-center gap-3"
                                onClick={handleDeposit}
                            >
                                <BsUpload size={22} fill="#151415" />
                                <div className="text-[12px] flex flex-col gap-0 items-start">
                                    <span className="text-[14px] font-bold w-max">
                                        Deposit
                                    </span>
                                    <p className="text-[11px] text-[#15141580]  dark:text-[#ffffff] w-max">
                                        Deposit Crypto
                                    </p>
                                </div>
                            </button>
                        </div>
                        <div className="flex bg-[#F4F4F4] dark:bg-opacity-10 hover:bg-[#ddd] rounded-md">
                            <button
                                type="button"
                                className="w-full px-6 py-4 font-bold text-[12px] flex items-center gap-3"
                                onClick={handleTransfer}
                            >
                                <BsArrowLeftRight size={22} fill="#151415" />
                                <div className="text-[12px] flex flex-col gap-0 items-start">
                                    <span className="text-[14px] font-bold w-max">
                                        Transfer
                                    </span>
                                    <p className="text-[11px] text-[#15141580] w-max dark:text-[#ffffff]">
                                        Within Account
                                    </p>
                                </div>
                            </button>
                        </div>
                        <div className="flex bg-[#F4F4F4] dark:bg-opacity-10 hover:bg-[#ddd] rounded-md">
                            <button
                                type="button"
                                className="w-full px-6 py-4 font-bold text-[12px] flex items-center gap-3"
                                onClick={handleConvert}
                            >
                                <BsArrowRepeat size={22} fill="#151415" />
                                <div className="text-[12px] flex flex-col gap-0 items-start">
                                    <span className="text-[14px] font-bold w-max">
                                        Convert
                                    </span>
                                    <p className="text-[11px] text-[#15141580] w-max dark:text-[#ffffff]">
                                        Redemption Period
                                    </p>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

AddFundModal.propTypes = {
    closeAddFundModal: PropTypes.func.isRequired,
};

export default AddFundModal;
