import { RepositoryFactory } from "repository/RepositoryFactory";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

const financeRepository = RepositoryFactory.get("finance");

export const transactionHistory = (userId, token) => async (dispatch) => {
    dispatch({type: "FETCH_FINANCE_HISTORY_REQUEST"});
    try {
        const response = await financeRepository.mycj(userId, token);
        const res = response.data.data
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_FINANCE_HISTORY_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: res };
        } else {
            dispatch({
                type: "FETCH_FINANCE_HISTORY_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)            
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_FINANCE_HISTORY_FAILURE", payload: error.message });
        return false;
    }
};