import React, { useState } from 'react'
import { FaFileContract } from "react-icons/fa6";

const Guidesection = () => {
    const[currentService,setCurrentService]=useState("buyasmaker");

    const data=[
        {
        id:1,
        Icon:<FaFileContract size={30}/>,
        para:"First, click on [Create Order] and select the [Buy] tab. Next, enter the quantity and price to place a buy order. Lastly, confirm your order and provide the payment amount.",
        type:"buyasmaker"
},
{
    id:2,
    Icon:<FaFileContract size={30}/>,
    para:"Wait for a seller to be matched with your order. Once a seller has accepted your order, simply wait for the delivery phase to receive your tokens.",
    type:"buyasmaker"
},
{
    id:3,
    Icon:<FaFileContract size={30}/>,
    para:"You will receive the tokens from the seller during the delivery phase. Otherwise, you will be compensated on top of recovering your payment amount.",
    type:"buyasmaker"
},
{
  id:4,
  Icon:<FaFileContract size={30}/>,
  para:"Find an existing order that meets your requirements",
  type:"buyastaker"
},
{
  id:5,
  Icon:<FaFileContract size={30}/>,
  para:"Accept the sell order, thoroughly check the quantity and price. Ensure that you have the full amount of assets to make the purchase and click Confirm. You will receive the tokens during the delivery phase.",
  type:"buyastaker"
},
{
  id:6,
  Icon:<FaFileContract size={30}/>,
  para:"You will receive the tokens from the seller during the delivery phase. Otherwise, you will be compensated on top of recovering your payment amount.",
  type:"buyastaker"
},
{
  id:7,
  Icon:<FaFileContract size={30}/>,
  para:"First, click on [Create Order] and select the [Sell] tab. Next, enter the quantity and price to place a sell order. Lastly, confirm your order and provide the required collateral.",
  type:"sellingasmaker"
},
{
  id:8,
  Icon:<FaFileContract size={30}/>,
  para:"Wait for a buyer to be matched with your order. Once a buyer has accepted your order, you will have to get ready to deliver the tokens during the delivery phase.",
  type:"sellingasmaker"
},
{
  id:9,
  Icon:<FaFileContract size={30}/>,
  para:"Note: Failure to deliver the tokens in full and on time will result in the loss of all your collateral for the order.",
  type:"sellingasmaker"
},
{
  id:10,
  Icon:<FaFileContract size={30}/>,
  para:"Find an existing order that meets your requirements",
  type:"sellingastaker"
},
{
  id:11,
  Icon:<FaFileContract size={30}/>,
  para:"Accept the buy order, thoroughly check the quantity and price. Ensure that you will have the full amount of assets to make the sale and click Confirm. Then, be ready to deliver the tokens during the delivery phase.",
  type:"sellingastaker"
},
{
  id:12,
  Icon:<FaFileContract size={30}/>,
  para:"Note: Failure to deliver the tokens in full and on time will result in the loss of all your collateral for the order.",
  type:"sellingastaker"
},
]

const GuideData= data.filter(data=>data.type===currentService);

  return (
    <div className=' mt-10'>
        <div className='border-l-[4px] leading-normal px-2  border-yellow-500 font-semibold text-[20px]'>Guide to Pre-Market Trading</div>
        <div className='mt-6 flex items-center flex-wrap gap-2 lg:gap-4 text-white '>
            <button onClick={()=>setCurrentService("buyasmaker")} className={`py-2 lg:text-lg text-sm lg:px-6 px-2 font-semibold rounded-lg  ${currentService=="buyasmaker" ? " text-[#d6850d] bg-[#FFE5B2]" : "bg-[#1e2023]"}`}>Buying as a Maker</button>
            <button onClick={()=>setCurrentService("buyastaker")} className={`py-2 lg:text-lg  text-sm px-2 lg:px-6  font-semibold rounded-lg  ${currentService=="buyastaker" ? " text-[#d6850d] bg-[#FFE5B2]" : "bg-[#1e2023]"}`}>Buying as a Taker</button>
            <button onClick={()=>setCurrentService("sellingasmaker")} className={`py-2 lg:text-lg  text-sm px-2 lg:px-6   font-semibold rounded-lg  ${currentService=="sellingasmaker" ? " text-[#d6850d] bg-[#FFE5B2]" : "bg-[#1e2023]"}`}>Selling as a Maker</button>
            <button onClick={()=>setCurrentService("sellingastaker")} className={`py-2 lg:text-lg  text-sm px-2 lg:px-6  font-semibold rounded-lg  ${currentService=="sellingastaker" ? " text-[#d6850d] bg-[#FFE5B2]" : "bg-[#1e2023]"}`}>Selling as a Taker</button>
        </div>
        <div className='mt-10 flex-wrap md:flex-row flex-col  flex items-center justify-between gap-4 lg:gap-14'>
            {GuideData.map((data)=>(
              <div className="md:w-[30%] w-full">
                    <button className='p-3 rounded-xl border border-[#81858c] text-[#81858c]'>{data.Icon}</button>
                <p className='md:mt-6 mt-2 text-[14px] text-[#81858c] border-b-[1px] md:border-b-[0px] md:border-r-[1px] border-[#25282c] border-dashed pr-6 lg:pr-14 min-h-28 flex items-center'>{data.para}</p>
              </div>  
            ))}
            
            
        </div>
    </div>
  )
}

export default Guidesection