import React from "react";
import BG from "assets/img/bg.jfif";
import SecurityCard from "components/userProtection/SecurityCard";
import UserLevelSceurity from "components/userProtection/UserLevelSceurity";
import ComplianceInitiatives from "components/userProtection/ComplianceInitiatives";
import Learn from "components/userProtection/Learn";
import News from "components/userProtection/News";
import Blogs from "components/userProtection/Blogs";

function UserProtection() {
    const myStyle = {
        backgroundImage: `url(${BG})`,
    };
    return (
        <>
            <section
                style={myStyle}
                className=" 
 bg-cover 
 bg-center   min-h-screen w-full
flex justify-center items-center
 "
            >
                <div className="container">
                    <div class="text-center max-w-3xl mx-auto  my-auto">
                        <h6 className="text-base text-white font-bold mb-2">
                            Secure. Compliant. Trustworthy
                        </h6>
                        <h1 class="md:text-5xl/snug text-white text-3xl font-semibold  ">
                            Our Commitment to  <span class="text-[#EFB81C]  md:text-5xl/snug text-3xl font-semibold">  Compliance & Your Protection
                            </span>
                        </h1>
                        <p class="text-base font-normal text-white mt-5">
                            Experience the confidence of trading on the world’s
                            first public-owned cryptocurrency exchange, where
                            your security and compliance come first. we place
                            the highest priority on protecting our users and
                            ensuring strict compliance with regulatory
                            standards. As the world's first public-owned
                            cryptocurrency exchange, we are committed to
                            providing a secure, transparent, and trustworthy
                            trading environment. Our comprehensive approach to
                            user protection and compliance sets us apart in the
                            digital asset industry.
                        </p>
                    </div>
                </div>
            </section>

            <section className=" my-5">
                <div className="container">
                    <h2 className="md:text-3xl/snug text-black dark:text-white text-3xl font-semibold mb-3 ">
                        Secure anytime, anywhere
                    </h2>

                    <p>
                        We protect user funds by implementing strict protocols
                        and state-of-the-art technical measures. Our platform
                        features real-time monitoring, a comprehensive risk
                        management system, advanced data privacy tools, and
                        ongoing security education for users. At BUYCEX, we are
                        dedicated to continuously innovating to ensure the
                        utmost protection for our users.
                    </p>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                        <div className="">
                            <h2 className="text-2xl">Platform Security</h2>
                            <SecurityCard />
                        </div>
                        <div className="">
                            <h2 className="text-2xl">User-Level Security</h2>
                            <UserLevelSceurity />
                        </div>
                    </div>
                </div>
            </section>

            <ComplianceInitiatives />

            <section class=" my-5">
                <div class="container">
                    <h2 class="md:text-3xl/snug my-3 text-black   font-semibold ">
                        Our Approach to User Care and Education
                    </h2>
                    <p>
                        To serve our users, we actively combat cybercrime,
                        locate missing assets, and recover funds for everyday
                        users, even if incidents happen outside the BUYCEX
                        ecosystem or are due to preventable human error.
                        Additionally, we invest in user education through
                        initiatives like BUYCEX Learn, a free platform designed
                        to keep users informed and guide them safely through
                        their crypto journey.
                    </p>
                </div>
            </section>

            <Learn />
            <News />
            <Blogs />

            <section class=" my-5">
                <div class="container">
                    <p className="text-[18px]">
                        At BUYCEX, our commitment to user protection and
                        compliance is unwavering. We strive to provide a secure,
                        transparent, and compliant trading environment for all
                        our users. By combining advanced security measures,
                        stringent compliance practices, and continuous
                        innovation, we aim to set new standards in the
                        cryptocurrency exchange industry. Join us at BUYCEX and
                        experience the confidence of trading on the world’s
                        first public-owned cryptocurrency exchange
                    </p>
                </div>
            </section>
        </>
    );
}

export default UserProtection;
