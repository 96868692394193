import React from "react";

import HeroSection from "components/home-page-sections/hero-section/HeroSection";
import MarketTopCoins from "components/home-page-sections/market-top-coins/MarketTopCoins";
import AppSection from "components/home-page-sections/download-apps/DownloadApps";
import ServiceSection from "components/home-page-sections/service/ServiceSection";
import CustomerSupport from "components/home-page-sections/customer-support/CustomerSupport";
import WhyChooseUs from "components/home-page-sections/why-choose-us/WhyChooseUs";
import UserRedirection from "components/home-page-sections/start-trade/StartTrade";

const HomePageSections = () => {
    return (
        <>
            {/* HERO SECTION */}
            <HeroSection />

            {/* Top Coins: Market Overview */}
            <MarketTopCoins />

            {/* Apps Section */}
            <AppSection />

            {/* Product and Services */}
            {/* <ServiceSection /> */}

            {/* Customer Support */}
            <CustomerSupport />

            {/* Why Choose Us */}
            {/* <WhyChooseUs /> */}

            {/* User Redirection */}
            <UserRedirection />
        </>
    );
};

export default HomePageSections;
