import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useMemo,
    useCallback,
} from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fetchMarketData } from "store/actions/marketActions";

const SelectedCoinContext = createContext();

export const SelectedCoinProvider = ({ children }) => {
    const dispatch = useDispatch();
    const marketDataFromStore = useSelector(
        (state) => state.market?.data?.market || []
    );

    // Load selected coin from localStorage if available, otherwise initialize with the first coin or BTC
    const [selectedCoin, setSelectedCoin] = useState(() => {
        const savedCoin = localStorage.getItem("selectedCoin");
        if (savedCoin) {
            return JSON.parse(savedCoin);
        }

        const defaultCoin = marketDataFromStore.find(
            (coin) => coin.id === 18 || coin.ticker === "btc_usdt"
        );

        return defaultCoin
            ? {
                  id: defaultCoin.id,
                  ticker: defaultCoin.ticker,
                  icon: defaultCoin.icon,
                  name: defaultCoin.name,
                  sell_price: parseFloat(defaultCoin.sell_price) || 0,
                  buy_price: parseFloat(defaultCoin.buy_price) || 0,
                  change: parseFloat(defaultCoin.change) || 0,
                  max_price: parseFloat(defaultCoin.max_price) || 0,
                  min_price: parseFloat(defaultCoin.min_price) || 0,
                  volume: defaultCoin.volume || 0,
              }
            : {};
    });

    useEffect(() => {
        // Fetch market data on component mount
        const fetchData = async () => {
            await dispatch(fetchMarketData());
        };
        fetchData();
    }, [dispatch]);

    // Function to fetch coin data and update selected coin in the state
    const fetchCoinData = useCallback(
        (ticker) => {
            const coinSelected = marketDataFromStore.find(
                (coin) => coin.ticker === ticker
            );
            if (coinSelected) {
                setSelectedCoin({
                    id: coinSelected.id,
                    ticker: coinSelected.ticker,
                    icon: coinSelected.icon,
                    name: coinSelected.name,
                    sell_price: parseFloat(coinSelected.sell_price) || 0,
                    buy_price: parseFloat(coinSelected.buy_price) || 0,
                    change: parseFloat(coinSelected.change) || 0,
                    max_price: parseFloat(coinSelected.max_price) || 0,
                    min_price: parseFloat(coinSelected.min_price) || 0,
                    volume: coinSelected.volume || 0,
                });
            } else {
                console.error("Coin not found in market data:", ticker);
            }
        },
        [marketDataFromStore]
    );

    // Save selectedCoin to localStorage when it changes
    useEffect(() => {
        if (selectedCoin && selectedCoin.ticker) {
            localStorage.setItem("selectedCoin", JSON.stringify(selectedCoin));
        }
    }, [selectedCoin]);

    // Memoize the context value to avoid unnecessary re-renders
    const contextValue = useMemo(
        () => ({ selectedCoin, setSelectedCoin, fetchCoinData }),
        [selectedCoin, fetchCoinData]
    );

    return (
        <SelectedCoinContext.Provider value={contextValue}>
            {children}
        </SelectedCoinContext.Provider>
    );
};

SelectedCoinProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

// Custom hook to use the SelectedCoinContext
export const useSelectedCoin = () => {
    const context = useContext(SelectedCoinContext);
    if (!context) {
        throw new Error(
            "useSelectedCoin must be used within a SelectedCoinProvider"
        );
    }
    return context;
};
