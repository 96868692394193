import React from 'react'
import { FaUserEdit } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";
import { FaCoins } from "react-icons/fa";

function HowStarted() {
    return (
        <div className='mb-20'>
            <div className="market-top-coins-header flex items-center justify-between my-6 pt-6">
                <h2 className="market-top-coins-header__title container text-[34px] font-bold">
                    How to Get Started
                </h2>
            </div>
            <div className='container flex gap-6 justify-between'>
                <div className='border flex py-6 px-8 flex-col gap-3 rounded-md flex-1'>
                    <FaUserEdit className='ml-4' size={50} />
                    <p className='text-2xl font-medium'>01. Create Account</p>
                    <p className='text-sm text-[#81858c] w-80'>Sign up and complete beginner tasks, win-up to $6666.</p>
                    <button className='bg-[#EFB81C] px-4 rounded-full py-1 flex items-start w-fit text-white font-semibold'>Sign Up</button>
                </div>
                <div className='border flex py-6 px-8 flex-col gap-3 rounded-md flex-1'>
                    <FaWallet className='ml-4' size={50} />
                    <p className='text-2xl font-medium'>02. Fund Your Account</p>
                    <p className='text-sm text-[#81858c] w-96'>Add funds to your account to start your crypto investment journey.</p>
                    <div className='flex gap-2'>
                        <button className='bg-[#EFB81C] px-4 rounded-full py-1 flex items-start w-fit text-white font-semibold'>Deposit Crypto</button>
                        <button className='bg-[#EFB81C] px-4 rounded-full py-1 flex items-start w-fit text-white font-semibold'>Buy Crypto</button>
                    </div>
                </div>
                <div className='border flex py-6 px-8 flex-col gap-3 rounded-md flex-1'>
                    <FaCoins className='ml-4' size={50} />
                    <p className='text-2xl font-medium'>03. Start Trading</p>
                    <p className='text-sm text-[#81858c] w-80 h-10'>Sell, buy or copy trade crypto and explore more.</p>
                    <button className='bg-[#EFB81C] px-4 rounded-full py-1 flex items-start w-fit text-white font-semibold'>Trade</button>
                </div>
            </div>
            <div className='flex w-[99%] mx-2 gap-4 mt-10'>
                <div className='bg-black p-3 rounded-md w-[25%] flex'>
                    <div className='flex flex-col justify-between'>
                        <p>
                            <p className='text-white font-medium text-xs'>8x CHAMPIONS</p>
                            <p className='uppercase font-bold text-lg text-white'>Trade smart & earn big</p>
                        </p>
                        <p className='text-white text-xs mt-10'>Over <span className='text-[#2ECC71]'>1000 USDT</span> Up for Grabs!</p>
                    </div>
                </div>
                <div className='bg-black p-3 rounded-md w-[25%]'>
                    <p className='text-white font-medium text-xs'>Deposit Frenzy</p>
                    <p className='font-bold text-lg text-white'>Deposit 10 USDT</p>
                    <p className='font-bold text-lg text-white'>Earn 10 USDT Bonus!</p>
                    <p className='text-[#2ECC71] text-xs mt-10'>Grab up to 1000 USDT</p>
                </div>
                <div className='bg-black p-3 rounded-md flex flex-col justify-center items-start w-[25%]'>
                    <p className='font-bold text-lg text-[#2ECC71]'>35 USDT <span className='text-white text-lg'>airdrop</span></p>
                    <p className='font-bold text-lg text-white'>is waiting for you!</p>
                </div>
                <div className='bg-black p-3 rounded-md w-[25%] flex'>
                    <div className='flex flex-col justify-between'>
                        <p>
                            <p className='text-white font-medium text-xs'>8x CHAMPIONS</p>
                            <p className='uppercase font-bold text-lg text-white'>Trade smart & earn big</p>
                        </p>
                        <p className='text-white text-xs mt-10'>Over <span className='text-[#2ECC71]'>1000 USDT</span> Up for Grabs!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowStarted