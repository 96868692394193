import React, { useEffect, useState } from "react";
import { BsCaretRightFill, BsQuestionCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchMarketData } from "store/actions/marketActions";

const MarketChangersCards = () => {
    const dispatch = useDispatch();
    const marketData = useSelector((state) => state.market.data?.market || []);
    const { loading, error } = useSelector((state) => state.market.loading);
    const [validIcons, setValidIcons] = useState({});

    useEffect(() => {
        dispatch(fetchMarketData());
    }, [dispatch]);

    // Uncomment this if needed a refresh to the market data

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         dispatch(fetchMarketData());
    //     }, 60000);

    //     return () => clearInterval(intervalId);
    // }, [dispatch]);

    // Function to check if an image URL is valid
    const checkIconValidity = (url) => {
        const invalidUrls = ["https://api.buycex.com/Upload/coin/"];
        if (invalidUrls.includes(url)) {
            return Promise.resolve(false);
        }

        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    };

    useEffect(() => {
        // Check validity of each icon URL
        const validateIcons = async () => {
            const results = {};
            for (const coin of marketData) {
                if (coin.icon) {
                    results[coin.id] = await checkIconValidity(coin.icon);
                }
            }
            setValidIcons(results);
        };

        if (marketData.length > 0) {
            validateIcons();
        }
    }, [marketData]);

    const extractCoinName = (name) => {
        // Extract the text inside parentheses and then extract the part before the slash
        const match = name.match(/\(([^/]+)\//);
        return match ? match[1] : name;
    };

    const formatPrice = (price) => {
        const numPrice = Number(price);
        if (isNaN(numPrice)) return "N/A";
        if (numPrice >= 1e9) return `$${(numPrice / 1e9).toFixed(2)}B`;
        if (numPrice >= 1e6) return `$${(numPrice / 1e6).toFixed(2)}M`;
        if (numPrice >= 1e3) return `$${(numPrice / 1e3).toFixed(2)}K`;
        return `$${numPrice.toFixed(2)}`;
    };

    // Trending Coins
    const trendingCoins = marketData
        .filter((coin) => coin.change > 0)
        .sort((a, b) => b.change - a.change)
        .slice(0, 3);

    // Top Gainers
    const topGainers = marketData
        .filter((coin) => coin.change > 0)
        .sort((a, b) => b.change - a.change)
        .slice(0, 3);

    // New Listed
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    // const newListed = marketData
    //     .filter((coin) => {
    //         const addtime = new Date(coin?.coinData?.addtime); // Parse the addtime from coinData
    //         return addtime > oneMonthAgo; // Filter only coins added within the last month
    //     })
    //     .map((coin) => ({
    //         ...coin,
    //         id: Number(coin.id),
    //     }))
    //     .sort((a, b) => b.id - a.id)
    //     .slice(0, 3)
    //     .map((coin) => ({
    //         ...coin,
    //         id: coin.id.toString(),
    //     }));

    const newListed = marketData
        .map((coin) => ({
            ...coin,
            id: Number(coin.id),
        }))
        .sort((a, b) => b.id - a.id)
        .slice(0, 3)
        .map((coin) => ({
            ...coin,
            id: coin.id.toString(),
        }));

    // Top Volume
    const topVolume = marketData
        .sort((a, b) => b.volume - a.volume)
        .slice(0, 3);

    const renderSkeletonRow = () => (
        <div className="flex items-center justify-between w-full p-2 animate-pulse">
            <div className="flex items-center gap-1 min-w-[32%]">
                <div className="w-3 h-3 bg-gray-300 rounded"></div>
                <div className="h-2 bg-gray-300 rounded w-16"></div>
            </div>
            <div className="h-2 bg-gray-300 rounded w-16 min-w-[25%] mr-auto"></div>
            <div className="h-2 bg-gray-300 rounded w-12 min-w-[32%]"></div>
        </div>
    );

    if (error) {
        return (
            <div className="min-h-[30vh] w-full flex m-auto">
                <p className="m-auto text-[14px] font-[500]">
                    Error to fetch market data.
                </p>
            </div>
        );
    }

    return (
        <div className="market-changer-card wrapper">
            <div className="market-changer-card-container container ">
                <div className="market-changers__title pt-2 mb-4"></div>
                <h2 className=" font-bold text-3xl">Market Summary</h2>
                <div className="inner-wrapper flex justify-between my-2 bg-solid py-4  rounded-md max-lg:hidden p-2">
                    {/* Trending Coins Card */}

                    <div className="bg-gray-50 rounded-2xl market-changer__card card-trending-coins min-w-[24%] p-2 relative after:content-[''] after:absolute after:top-0 after:right-0 after:w-[1px] after:h-full after:bg-gray-300 after:hidden last:after:hidden">
                        <div className="card-header flex items-center justify-between p-2">
                            <h3 className="card-header__title text-[16px] font-bold">
                                Trending Coins
                            </h3>
                            <Link
                                to="#!"
                                title="More details about top trending coins"
                                className="card-header__link flex items-center text-[14px] text-[#EFB81C]"
                            >
                                More
                                <BsCaretRightFill className="w-2 h-2" />
                            </Link>
                        </div>
                        <div className="card-body flex flex-col">
                            {loading ? (
                                <>
                                    {renderSkeletonRow()}
                                    {renderSkeletonRow()}
                                    {renderSkeletonRow()}
                                </>
                            ) : (
                                <>
                                    {trendingCoins.map((coin) => (
                                        <div
                                            key={coin.id}
                                            className="card-body__row coin-details flex items-center gap-2 justify-between w-full p-2"
                                        >
                                            <div className="coin-details__data-cell coin-name__coin-icon flex items-center gap-2 text-left min-w-[32%]">
                                                {validIcons[coin.id] ? (
                                                    <img
                                                        src={coin.icon}
                                                        alt={coin.name}
                                                        className="w-5 h-5 object-contain rounded"
                                                        title={coin.name}
                                                        loading="lazy"
                                                        decoding="async"
                                                    />
                                                ) : (
                                                    <BsQuestionCircleFill className="w-5 h-5" />
                                                )}
                                                <span className="text-[13px] font-bold">
                                                    {extractCoinName(coin.name)}
                                                </span>
                                            </div>
                                            <div className="coin-details__data-cell coin-value text-left min-w-[32%]">
                                                <span className="text-[13px] font-bold text-left">
                                                    {formatPrice(
                                                        coin.new_price
                                                    )}
                                                </span>
                                            </div>
                                            <div
                                                className={`coin-details__data-cell coin-change min-w-[32%] text-right text-[13px] font-bold ${
                                                    coin.change < 0
                                                        ? "text-red-500"
                                                        : "text-green-500"
                                                }`}
                                            >
                                                {coin.change > 0
                                                    ? `+${coin.change.toFixed(
                                                          2
                                                      )}%`
                                                    : `${coin.change.toFixed(
                                                          2
                                                      )}%`}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>

                    {/* Top Gainers Card */}
                    <div className="bg-gray-50 rounded-2xl  market-changer__card card-top-gainers-coins min-w-[24%] p-2 relative after:content-[''] after:absolute after:top-0 after:right-0 after:w-[1px] after:h-full after:bg-gray-300 after:hidden last:after:hidden">
                        <div className="card-header flex items-center justify-between p-2">
                            <h3 className="card-header__title text-[16px] font-bold">
                                Top Gainers
                            </h3>
                            <Link
                                to="#!"
                                title="More details about top gaining coins"
                                className="card-header__link flex items-center text-[14px] text-[#EFB81C]"
                            >
                                More
                                <BsCaretRightFill className="w-2 h-2" />
                            </Link>
                        </div>
                        <div className="card-body flex flex-col">
                            {loading ? (
                                <>
                                    {renderSkeletonRow()}
                                    {renderSkeletonRow()}
                                    {renderSkeletonRow()}
                                </>
                            ) : (
                                <>
                                    {topGainers.map((coin) => (
                                        <div
                                            key={coin.id}
                                            className="card-body__row coin-details flex items-center gap-2 justify-between w-full p-2"
                                        >
                                            <div className="coin-details__data-cell coin-name__coin-icon flex items-center gap-2 text-left min-w-[32%]">
                                                {validIcons[coin.id] ? (
                                                    <img
                                                        src={coin.icon}
                                                        alt={coin.name}
                                                        className="w-5 h-5 object-contain rounded"
                                                        title={coin.name}
                                                        loading="lazy"
                                                        decoding="async"
                                                    />
                                                ) : (
                                                    <BsQuestionCircleFill className="w-5 h-5" />
                                                )}
                                                <span className="text-[13px] font-bold">
                                                    {extractCoinName(coin.name)}
                                                </span>
                                            </div>
                                            <div className="coin-details__data-cell coin-value text-left min-w-[32%]">
                                                <span className="text-[13px] font-bold text-left">
                                                    {formatPrice(
                                                        coin.new_price
                                                    )}
                                                </span>
                                            </div>
                                            <div
                                                className={`coin-details__data-cell coin-change min-w-[32%] text-right text-[13px] font-bold ${
                                                    coin.change < 0
                                                        ? "text-red-500"
                                                        : "text-green-500"
                                                }`}
                                            >
                                                {coin.change > 0
                                                    ? `+${coin.change.toFixed(
                                                          2
                                                      )}%`
                                                    : `${coin.change.toFixed(
                                                          2
                                                      )}%`}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>

                    {/* New Listings Card */}
                    <div className="bg-gray-50 rounded-2xl  market-changer__card card-new-listing-coins min-w-[24%] p-2 relative after:content-[''] after:absolute after:top-0 after:right-0 after:w-[1px] after:h-full after:bg-gray-300 after:hidden last:after:hidden">
                        <div className="card-header flex items-center justify-between p-2">
                            <h3 className="card-header__title text-[16px] font-bold">
                                New Listings
                            </h3>
                            <Link
                                to="#!"
                                title="More details about newly listed coins"
                                className="card-header__link flex items-center text-[14px] text-[#EFB81C]"
                            >
                                More
                                <BsCaretRightFill className="w-2 h-2" />
                            </Link>
                        </div>
                        <div className="card-body flex flex-col">
                            {loading ? (
                                <>
                                    {renderSkeletonRow()}
                                    {renderSkeletonRow()}
                                    {renderSkeletonRow()}
                                </>
                            ) : (
                                <>
                                    {newListed.map((coin) => (
                                        <div
                                            key={coin.id}
                                            className="card-body__row coin-details flex items-center gap-2 justify-between w-full p-2"
                                        >
                                            <div className="coin-details__data-cell coin-name__coin-icon flex items-center gap-2 text-left min-w-[32%]">
                                                {validIcons[coin.id] ? (
                                                    <img
                                                        src={coin.icon}
                                                        alt={coin.name}
                                                        className="w-5 h-5 object-contain rounded"
                                                        title={coin.name}
                                                        loading="lazy"
                                                        decoding="async"
                                                    />
                                                ) : (
                                                    <BsQuestionCircleFill className="w-5 h-5" />
                                                )}
                                                <span className="text-[13px] font-bold">
                                                    {extractCoinName(coin.name)}
                                                </span>
                                            </div>
                                            <div className="coin-details__data-cell coin-value text-left min-w-[32%]">
                                                <span className="text-[13px] font-bold text-left">
                                                    {formatPrice(
                                                        coin.new_price
                                                    )}
                                                </span>
                                            </div>
                                            <div
                                                className={`coin-details__data-cell coin-change min-w-[32%] text-right text-[13px] font-bold ${
                                                    coin.change < 0
                                                        ? "text-red-500"
                                                        : "text-green-500"
                                                }`}
                                            >
                                                {coin.change > 0
                                                    ? `+${coin.change.toFixed(
                                                          2
                                                      )}%`
                                                    : `${coin.change.toFixed(
                                                          2
                                                      )}%`}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>

                    {/* Top Volume Card */}
                    <div className="bg-gray-50 rounded-2xl  market-changer__card card-top-volume-coins min-w-[24%] p-2 relative after:content-[''] after:absolute after:top-0 after:right-0 after:w-[1px] after:h-full after:bg-gray-300 last:after:hidden">
                        <div className="card-header flex items-center justify-between p-2">
                            <h3 className="card-header__title text-[16px] font-bold">
                                Top Volume Coins
                            </h3>
                            <Link
                                to="#!"
                                title="More details about top volume coins"
                                className="card-header__link flex items-center text-[14px] text-[#EFB81C]"
                            >
                                More
                                <BsCaretRightFill className="w-2 h-2" />
                            </Link>
                        </div>
                        <div className="card-body flex flex-col">
                            {loading ? (
                                <>
                                    {renderSkeletonRow()}
                                    {renderSkeletonRow()}
                                    {renderSkeletonRow()}
                                </>
                            ) : (
                                <>
                                    {topVolume.map((coin) => (
                                        <div
                                            key={coin.id}
                                            className="card-body__row coin-details flex items-center gap-2 justify-between w-full p-2"
                                        >
                                            <div className="coin-details__data-cell coin-name__coin-icon flex items-center gap-2 text-left min-w-[32%]">
                                                {validIcons[coin.id] ? (
                                                    <img
                                                        src={coin.icon}
                                                        alt={coin.name}
                                                        className="w-5 h-5 object-contain rounded"
                                                        title={coin.name}
                                                        loading="lazy"
                                                        decoding="async"
                                                    />
                                                ) : (
                                                    <BsQuestionCircleFill className="w-5 h-5" />
                                                )}
                                                <span className="text-[13px] font-bold">
                                                    {extractCoinName(coin.name)}
                                                </span>
                                            </div>
                                            <div className="coin-details__data-cell coin-value text-left min-w-[32%]">
                                                <span className="text-[13px] font-bold text-left">
                                                    {formatPrice(
                                                        coin.new_price
                                                    )}
                                                </span>
                                            </div>
                                            <div
                                                className={`coin-details__data-cell coin-change min-w-[32%] text-right text-[13px] font-bold ${
                                                    coin.change < 0
                                                        ? "text-red-500"
                                                        : "text-green-500"
                                                }`}
                                            >
                                                {coin.change > 0
                                                    ? `+${coin.change.toFixed(
                                                          2
                                                      )}%`
                                                    : `${coin.change.toFixed(
                                                          2
                                                      )}%`}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketChangersCards;
