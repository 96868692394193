import React, { useState, useEffect, useRef } from "react";

const CoinSelect = ({ coins, selectedCrypto, setSelectedCrypto, handleImageClick }) => {
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCoins, setFilteredCoins] = useState(coins);
    const dropdownRef = useRef(null);

    useEffect(() => {
        
        setTimeout(() => {
            setLoading(false);
        }, 800); 
    }, []);

    useEffect(() => {
        const filtered = coins.filter((coin) =>
            coin.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCoins(filtered);
    }, [searchTerm, coins]);

    const handleSelect = (coin) => {
        setSelectedCrypto(coin);
        setIsOpen(false);
        setSearchTerm("");
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <label className="block mb-2 text-[16px] font-medium text-gray-900 dark:text-white">
                Select Coin
            </label>
            <div className="relative" ref={dropdownRef}>
                {loading ? (
                    <div className="animate-pulse">
                        <div className="h-10 bg-gray-200 rounded-[10px] w-full mb-4"></div>
                    </div>
                ) : (
                    <div className="relative dark:bg-red rounded-[10px]">
                        <button
                            className="w-full h-12 bg-[#f3f5f7] dark:bg-gray-800 border-gray-300 dark:border-gray-700 rounded-[10px] flex items-center px-4 justify-between"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            {selectedCrypto ? (
                                <div className="flex items-center">
                                    <img
                                        src={selectedCrypto.icon}
                                        alt={`${selectedCrypto.name}-coin`}
                                        className="w-6 h-6 mr-2"
                                    />
                                    <span className="text-[17px] font-semibold">{selectedCrypto.label}</span>
                                </div>
                            ) : (
                                <span className="text-black text-[17px] font-semibold">Select Crypto</span>
                            )}
                            <svg
                                className={`w-3 h-3 transition-transform ${
                                    isOpen ? "rotate-180" : ""
                                }`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </button>
                        {isOpen && (
                            <div className="absolute w-full bg-white dark:bg-gray-800 dark:border-gray-700 mt-1 rounded-[10px] shadow-lg z-10">
                                <input
                                    type="text"
                                    className="py-2 ml-3 mr-3 w-[96%] my-2 rounded-[10px] px-2 bg-[#F3F5F7] dark:border-gray-700 dark:bg-gray-800 dark:text-white"
                                    placeholder="Search Coin"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <div className="max-h-60 overflow-y-auto">
                                    {filteredCoins.length > 0 ? (
                                        filteredCoins.map((coin) => (
                                            <div
                                                key={coin.value}
                                                className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
                                                onClick={() => handleSelect(coin)}
                                            >
                                                <img
                                                    src={coin.icon}
                                                    alt={`${coin.name}-coin`}
                                                    className="w-6 h-6 mr-2"
                                                />
                                                <span className="text-[14px] font-">{coin.label}</span>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="px-4 py-2 text-gray-500 text-center dark:text-gray-400">
                                            No results found
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default CoinSelect;
