import React, { useState } from 'react';
import { Modal } from 'antd';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import { Input } from 'antd';
import { FcCheckmark } from "react-icons/fc";
import { RxCross2 } from "react-icons/rx";
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';

const { TextArea } = Input;

function P2pAppeal() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedIssue, setSelectedIssue] = useState('Please select');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileList, setFileList] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const issues = [
        'I did not receive payment from the buyer.',
        'I received payment from the buyer but the amount is incorrect.',
        'I received payment but the payment info does not match the buyer\'s name.',
        'I suspect the buyer is scamming.',
        'Funds received, although I am unable to release order due to an internal system error or OTP issue. I want to release crypto to the buyer now.',
        'I want to cancel this order as the order as the price set is wrong.'
    ];

    const steps = [
        { title: 'Appeal Submitted' },
        { title: 'Negotiate with Counterparty' },
        { title: 'Cs Review ' },
        { title: 'Appeal Complete' }
    ];

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectIssue = (issue) => {
        setSelectedIssue(issue);
        setIsOpen(false);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="relative w-full h-96">
            <p className='text-sm pt-4'>Select an issue you are facing</p>
            <div className="border border-gray-300 rounded-lg p-2 mt-2 cursor-pointer w-full" onClick={handleToggleDropdown}>
                <div className='flex w-full justify-between font-semibold items-center'>
                    <span className="truncate overflow-hidden whitespace-nowrap">{selectedIssue}</span>
                    {isOpen ? <MdOutlineArrowDropUp className='text-2xl text-gray-500' /> : <MdOutlineArrowDropDown className='text-2xl text-gray-500' />}
                </div>
            </div>
            {isOpen && (
                <ul className="absolute rounded-lg mt-1 w-full overflow-y-auto h-52 shadow-md bg-white z-10">
                    {issues.map((issue, index) => (
                        <li
                            key={index}
                            className="p-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleSelectIssue(issue)}
                        >
                            {issue}
                        </li>
                    ))}
                </ul>
            )}

            <Modal
                title="Submit Appeal"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Submit"
                cancelText="Cancel"
                footer={null}
            >
                {/* Step Indicators */}
                <div className="container mx-auto">
                    <div className="flex justify-between my-5 w-full items-center">
                        {steps.map((_, index) => (
                            <div key={index} className="w-full">
                                <div className="flex items-center">
                                    <div
                                        className="h-7 w-7 relative left-10 rounded-full flex items-center justify-center font-normal text-black bg-gray-300"
                                    >
                                        {index + 1}
                                    </div>
                                    {index < 3 && (
                                        <div className="flex-1 h-1 relative left-10 bg-gray-300"></div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* Step Titles */}
                <div className="container flex w-full">
                    {steps.map((step, index) => (
                        <div key={index} className="flex-1 text-center">
                            <p className='text-xs'>{step.title}</p>
                        </div>
                    ))}
                </div>
                <div className="bg-yellow-100 mt-4 p-3 rounded-sm">
                    <ol className="list-decimal pl-5 text-sm space-y-3">
                        <li className='text-xs'>Submitted contents are visible to both parties and CS. Avoid including any private or sensitive info.</li>
                        <li className='text-xs'>Baseless appeals can result in account ban.</li>
                        <li className='text-xs'>CS review takes a few hours. More proof from both parties might be requested.</li>
                    </ol>
                </div>
                <p className='mt-3 text-sm mb-1.5'>Reason for Appeal (Mandatory)</p>
                <div className="border border-gray-300 rounded-lg p-2 cursor-pointer w-full">
                    <div className='flex w-full justify-between font-semibold items-center'>
                        <span className="truncate overflow-hidden whitespace-nowrap text-sm">{selectedIssue}</span>
                        <MdOutlineArrowDropDown className='text-2xl text-gray-500' />
                    </div>
                </div>
                <p className='text-sm mt-3 pb-1.5'>Description</p>
                <TextArea showCount maxLength={500} placeholder="Please describe the complaint as detailed as possible" variant="borderless" className='bg-gray-100' style={{ height: '150px' }} />
                <p className='mt-14 text-sm mb-1.5'>Upload proof (Mandatory)</p>
                <p className='text-xs pb-4'>Screenshots or video/audio recordings of payment and communication data should not exceed a total of 5 files with total size of 200 MB. Supported file formats include pdf, jpg, jpeg, png, mp3, mp4, avi, rm, rmvb, mov, wmv.</p>
                <div className='flex items-center mb-2'><FcCheckmark className='w-10 text-[#01BC8D]' /> <p className='text-xs ml-1'>Ensure that the date, time, account number, and name of the sender and recipient are displayed in the payment transaction details.</p></div>
                <div className='flex items-center mb-2'><FcCheckmark className='w-10 text-[#01BC8D]' /> <p className='text-xs ml-1'>Video should show the entire payment account log in process (please hide the password), and the payment transaction details</p></div>
                <div className='flex items-center mb-3'><FcCheckmark className='w-6 text-[#01BC8D]' /> <p className='text-xs ml-1.5'>Video must be recorded using another phone/camera and unedited.</p></div>
                <div className='flex items-center mb-2'><RxCross2 className='w-6 text-[#FF0000]' /> <p className='text-xs ml-1.5'>Account will be restricted if fake payment proof is provided.</p></div>
                <ImgCrop rotationSlider>
                    <Upload
                        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                        className="my-8"
                    >
                        {fileList.length < 5 && '+ Upload'}
                    </Upload>
                </ImgCrop>
                <div className='flex gap-2'>
                    <button onClick={handleCancel} className="bg-slate-200 w-1/2 font-semibold py-2 px-3 text-sm rounded-lg">
                        Cancel
                    </button>
                    <button onClick={handleOk} className="bg-[#EFB81C] w-1/2 font-semibold py-2 px-3 text-sm rounded-lg">
                        Submit Appeal
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default P2pAppeal;