import {
    WITHDRAW_REQUEST,
    WITHDRAW_SUCCESS,
    WITHDRAW_FAILURE,
} from "../types";
import toast from "react-hot-toast";

import cryptowithdrawRepository from "repository/cryptowithdrawRepository";

export const doWithdraw = (token, userId, withdrawalInputs) => async (dispatch) => {
    dispatch({ type: WITHDRAW_REQUEST }); 

    try {
        const response = await cryptowithdrawRepository.doWithdraw(
            withdrawalInputs,
            token,
            userId
        );

        if (response.data.status === 1) {
            toast.success(response.data.data); 
            dispatch({
                type: WITHDRAW_SUCCESS,
                payload: response.data, 
            });
            return response; 
        } else {
            toast.error(response.data.data); 
            dispatch({
                type: WITHDRAW_FAILURE,
                payload: response.data, 
            });
            return response; 
        }
    } catch (error) {
        console.error("Withdrawal API failed:", error);
        toast.error("Withdrawal failed. Please try again later."); 
        dispatch({
            type: WITHDRAW_FAILURE,
            error,
        });
        throw error;
    }
};
