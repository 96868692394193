import React, { useEffect, useRef, useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { faq } from "store/actions/p2pActions";

const Faq = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const [faqsData, setFaqsData] = useState(null);
    const faqRef = useRef(null);
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;
    const dispatch = useDispatch();

    const handleToggle = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (faqRef.current && !faqRef.current.contains(event.target)) {
                setOpenIndex(null);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        dispatch(faq(userId, token)).then((result) => {
            if (result) {
                setFaqsData(result.data);
                console.log(result);
            }
        });
    }, [dispatch, userId, token]);

    if (!faqsData) {
        return <div>Loading FAQs...</div>;
    }

    return (
        <section className="relative flex flex-col items-center justify-center w-full bg-transparent rounded-t-3xl bottom-10 gap-y-2">
            <h6 className="w-full ml-2 text-2xl text-black dark:text-white font-semibold uppercase">{faqsData.main_title}</h6>
            <div
                className="flex flex-col gap-y-5 md:gap-y-0 md:flex-row md:gap-x-5 items-center justify-center md:items-start md:justify-evenly xl:justify-center xl:gap-x-10 w-[100%] xl:w-[100%] mt-3"
                ref={faqRef}
            >
                <div className="flex flex-col items-center justify-center w-[100%] gap-y-5">
                    {faqsData.list.slice(0, 3).map((category) => (
                        <div key={category.id} className="w-full">
                            <h6 className="text-xl font-semibold mb-3 text-black dark:text-white">{category.category}</h6>
                            {category.data.map((faq) => (
                                <div
                                    key={faq.id}
                                    className={`${
                                        openIndex === faq.id ? "min-h-[120px]" : "max-h-[97px]"
                                    } shadow-sm border flex flex-col bg-transparent dark:bg-[#1A191B] gap-y-5 w-[100%] rounded-xl transition-all duration-500 p-4 overflow-hidden mb-5`}
                                >
                                    <div className="flex items-center justify-between mt-1">
                                        <div className="text-15px lg:text-[17px] flex items-center gap-x-4 w-full">
                                            <h6 className="py-[5px] max-w-[88%] text-black dark:text-white font-semibold">
                                                {faq.title}
                                            </h6>
                                        </div>
                                        <div
                                            className="cursor-pointer bg-transparent"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleToggle(faq.id);
                                            }}
                                        >
                                            {openIndex === faq.id ? (
                                                <FiMinus
                                                    size={37}
                                                    className="transition-all duration-700 p-[6px] bg-[#efb81c] rounded-md text-[#ffffff]"
                                                />
                                            ) : (
                                                <FiPlus
                                                    size={37}
                                                    className="transition-all duration-700 p-[6px] bg-[#f7f7ff]  dark:bg-[#1A191B]  rounded-md text-[#6f6c8f] dark:text-white"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {openIndex === faq.id && (
                                        <div className="flex flex-col text-sm mt-[35px] text-black dark:text-white gap-y-5 lg:text-base">
                                            <p>{faq.content}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>

                <div className="flex flex-col items-center justify-center w-[100%] md:w-[350px] lg:w-[650px] xl:w-[800px] gap-y-5">
                    {faqsData.list.slice(3, 6).map((category) => (
                        <div key={category.id} className="w-full">
                            <h6 className="text-xl font-semibold mb-3 gap-y-5 text-black dark:text-white">{category.category}</h6>
                            {category.data.map((faq) => (
                                <div
                                    key={faq.id}
                                    className={`${
                                        openIndex === faq.id ? "min-h-[120px]" : "max-h-[97px]"
                                    } shadow-sm border bg-transparent dark:bg-[#1A191B] flex flex-col w-[100%] rounded-xl transition-all duration-500 p-4 overflow-hidden mb-5`}
                                >
                                    <div className="flex items-center justify-between mt-1">
                                        <div className="text-15px lg:text-[17px] flex items-center gap-x-4 w-full">
                                            <h6 className="py-[5px] max-w-[88%] text-black dark:text-white font-semibold">
                                                {faq.title}
                                            </h6>
                                        </div>
                                        <div
                                            className="cursor-pointer bg-transparent"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleToggle(faq.id);
                                            }}
                                        >
                                            {openIndex === faq.id ? (
                                                <FiMinus
                                                    size={37}
                                                    className="transition-all duration-700 p-[6px] bg-[#efb81c] rounded-md text-[#ffffff]"
                                                />
                                            ) : (
                                                <FiPlus
                                                    size={37}
                                                    className="transition-all duration-700 p-[6px] bg-[#f7f7ff]  dark:bg-[#1A191B]  rounded-md text-[#6f6c8f] dark:text-white"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {openIndex === faq.id && (
                                        <div className="flex flex-col text-black dark:text-white text-sm mt-[35px] gap-y-5 lg:text-base">
                                            <p>{faq.content}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Faq;
