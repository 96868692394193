import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    BsDownload,
    BsSun,
    BsGlobe,
    BsCurrencyDollar,
    BsMoon,
} from "react-icons/bs";

const Dropdownmore = () => {
    const menuList = [
        { id: 1, name: "Download", icon: <BsDownload size={14} />, path: "#" },
        {
            id: 2,
            name: "Theme",
            icon: <BsMoon size={14} />,
            path: "#",
        },
        { id: 3, name: "Language", icon: <BsGlobe size={14} />, path: "#" },
        {
            id: 4,
            name: "Currency",
            icon: <BsCurrencyDollar size={14} />,
            path: "#",
        },
    ];

    return (
        <div className="header__user-orders-menu-wrapper rounded-b-md bg-[#17181e] absolute top-[42px] right-[0px] h-max z-[999]">
            <div className="h-max px-3 flex flex-col">
                {/* User Orders Navigation Menu */}
                <div className="user-side-menu__menu-wrapper">
                    <ul className="flex flex-col gap-0 user-side-menu__menu-list">
                        {menuList.map((data) => (
                            <li key={data.id} className="flex items-center w-full gap-3">
                                <Link
                                    to={data.path}
                                    title={data.title}
                                    rel="alternate noopener noreferrer"
                                    className="hover:text-[#EFB81C] w-full flex items-center gap-3 px-3 py-[13px] rounded-md hover:bg-[#383a3d] opacity-80 hover:opacity-100"
                                >
                                    <span>{data.icon}</span>
                                    <span className="font-semibold text-[14px]">
                                        {data.name}
                                    </span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Dropdownmore;
