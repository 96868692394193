import React, { useState } from 'react';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6"; // Import both eye icons
import { FaAngleDown } from "react-icons/fa6";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaCircleNotch } from "react-icons/fa6";

function TotalValue({ data }) {
    const [isVisible, setIsVisible] = useState(false); // State to track visibility
    const [activeButton, setActiveButton] = useState('Earning Item'); // State to track the active button
    const estimateBalance = data?.estimate_balance || {};
    const conversionCoin = estimateBalance?.conversion_coin || 'N/A';
    const totalBalance = isVisible ? parseFloat(estimateBalance.balance || 0).toFixed(2) : '****';
    const pnl = isVisible ? parseFloat(estimateBalance.pnl || 0).toFixed(2) : '****';
    const pnlPercentage = isVisible ? parseFloat(estimateBalance.pnl_percentage || 0).toFixed(2) : '*****';
    // Function to handle button click
    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
    };

    const getButtonClass = (buttonType) => {
        return buttonType === activeButton
            ? 'bg-[#EFB81C] px-3 py-2 rounded-md text-sm font-semibold'
            : 'border border px-3 py-2 rounded-md text-sm font-semibold';
    };

    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(prev => !prev);
    };

    return (
        <div className='mt-0 lg:mt-6 p-3 sm:p-6 rounded-2xl border'>
            <div>
                <div className='flex  flex-col md:flex-row justify-normal md:justify-between w-full'>
                    <div>
                        <div className='flex items-center gap-1 w-full'>
                            <div className='flex w-full justify-between'>
                                <div className='flex items-center gap-1'>
                                    <p className='text-[20px] font-semibold'>Total Value</p>
                                    <button onClick={toggleVisibility} className='mt-1'>
                                        {isVisible ? <FaRegEyeSlash className='text-xl' /> : <FaRegEye className='text-xl' />}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-baseline gap-2'>
                            <p className='text-[32px] font-semibold'>{isVisible ? parseFloat(totalBalance).toFixed(2) : '****'}</p>
                            <span className='text-sm flex items-center gap-1 font-semibold'>{conversionCoin}<FaAngleDown className='text-[12px] opacity-40 mt-1' /></span>
                            <p className='border px-3 py-1 rounded-md font-semibold text-sm flex gap-2 items-center'><FaCircleNotch />All Assets</p>
                        </div>
                        <p>{isVisible ? `≈ ${parseFloat(totalBalance).toFixed(2)}` : '*****'}</p>
                    </div>
                    <div>
                        <button
                            onClick={() => handleButtonClick('Earning Item')}
                            className={getButtonClass('Earning Item')}
                        >
                            Earning Item
                        </button>
                        <button
                            onClick={() => handleButtonClick('History')}
                            className={`ml-4 ${getButtonClass('History')}`}
                        >
                            History
                        </button>
                    </div>

                </div>
                <div className='flex w-1/2 justify-between flex-wrap'>
                    <div className='flex flex-col gap-1 mt-6'>
                        <div className='flex items-center gap-1 text-ellipsis whitespace-nowrap'><p className='text-sm'>Last Day Profits ({conversionCoin})</p> <MdKeyboardArrowRight className='text-[#707a8a]' /></div>
                        <p className='font-semibold text-lg'>{isVisible ? '0' : '****'}</p>
                        <p className='text-[#707a8a]'>{isVisible ? '≈ $0.00' : '*****'}</p>
                    </div>
                    <div className='flex flex-col gap-1 mt-6'>
                        <div className='flex items-center gap-1 text-ellipsis whitespace-nowrap'><p className='text-sm'>30 Day Profits ({conversionCoin})</p> <MdKeyboardArrowRight className='text-[#707a8a]' /></div>
                        <p className='font-semibold text-lg'>{isVisible ? '0' : '****'}</p>
                        <p className='text-[#707a8a]'>{isVisible ? '≈ $0.00' : '*****'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TotalValue;
