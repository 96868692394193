import React, { useState } from "react";
// Import custom CSS for slider styling

const TradeSlider = () => {
    // Slider positions corresponding to the value markers (0, 25, 50, 75, 100)
    const marks = [0, 25, 50, 75, 100];
    const [value, setValue] = useState(0); // Selected value

    // Handle clicking on a dot to set the slider's value
    const handleDotClick = (val) => {
        setValue(val);
    };

    return (
        <div className="slider-container">
            <div className="slider-track">
                {marks.map((mark, index) => (
                    <div
                        key={index}
                        className={`slider-dot ${
                            value === mark ? "active" : ""
                        }`}
                        onClick={() => handleDotClick(mark)}
                        style={{ left: `${(mark / 100) * 100}%` }} // Position each dot
                    >
                        <span className="slider-value">{mark}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TradeSlider;
