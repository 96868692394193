import React from 'react';

const OrderNavbar = ({ activeComponent, setActiveComponent }) => {

  if (typeof setActiveComponent !== 'function') {
    console.error('setActiveComponent is not a function');
    return null;
  }

  return (
    <>
      <nav className='container p-0'>
        <ul className="flex gap-5 mt-5 overflow-x-auto border-b-2 hide-scrollbar">
          <li>
            <button
              className={`text-[#707A8A] font-medium text-[16px] pb-5 ${activeComponent === 'Processing' ? 'border-b-[3px] border-[#EFB81C] text-[black]' : ''}`}
              onClick={() => setActiveComponent('Processing')}
            >
              Processing
            </button>
          </li>
          <li>
            <button
              className={`text-[#707A8A] font-medium text-[16px] min-w-[80px] pb-5 ${activeComponent === 'AllOrders' ? 'border-b-[3px] border-[#EFB81C] text-[black]' : ''}`}
              onClick={() => setActiveComponent('AllOrders')}
            >
              All Orders
            </button>
          </li>
          <li>
            <button
              className={`text-[#707A8A] font-medium text-[16px] min-w-[180px] pb-5 ${activeComponent === 'AppealRecords' ? 'border-b-[3px] border-[#EFB81C] text-[black]' : ''}`}
              onClick={() => setActiveComponent('AppealRecords')}
            >
              Profit & Loss Statement
            </button>
          </li>
        </ul>
      </nav>
      <style>
        {`
        /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
        .hide-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for Firefox */
        .hide-scrollbar {
            scrollbar-width: none;
        }
        `}
      </style>
    </>
  );
};

export default OrderNavbar;
