import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Modal, Input } from 'antd';
import styled from 'styled-components';
import { IoSearchOutline } from 'react-icons/io5';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { newad } from 'store/actions/p2pActions';
import { setSelectedPaymentMethodIds } from '../../../store/reducers/stepOneSlice';

const AddPaymentMethodModal = ({ visible, onOk, onCancel }) => {
    const [search, setSearch] = useState("");
    const [selectedMethods, setSelectedMethods] = useState([]);
    const [BuyMethods, setBuyMethods] = useState([]);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;
    const selectedPaymentMethodIds = useSelector((state) => state.stepone.selectedPaymentMethodIds || []); // Get selected payment method IDs from Redux state

    useEffect(() => {
        const fetchDatas = async () => {
            const result = await dispatch(newad(userId, token));
            if (result) {
                setBuyMethods(result.data.BuyMethods || []);
            }
        };
        fetchDatas();
    }, [dispatch, userId, token]);

    useEffect(() => {
        // Update selectedMethods when selectedPaymentMethodIds changes
        const methods = BuyMethods.filter(method => selectedPaymentMethodIds.includes(method.id));
        setSelectedMethods(methods);
    }, [BuyMethods, selectedPaymentMethodIds]);

    const filteredMethods = useMemo(() =>
        BuyMethods.filter(method =>
            method.name.toLowerCase().includes(search.toLowerCase())
        ), [search, BuyMethods]);

    const handleMethodSelect = useCallback((method) => {
        setSelectedMethods(prevSelectedMethods => {
            const isSelected = prevSelectedMethods.some(selected => selected.id === method.id);
            let newSelectedMethods;
            if (isSelected) {
                newSelectedMethods = prevSelectedMethods.filter(selected => selected.id !== method.id);
            } else {
                newSelectedMethods = prevSelectedMethods.length < 5 ? [...prevSelectedMethods, method] : prevSelectedMethods;
            }

            // Update the selected ids in the Redux store
            const selectedIds = newSelectedMethods.map((item) => item.id);
            dispatch(setSelectedPaymentMethodIds(selectedIds));

            return newSelectedMethods;
        });
    }, [dispatch]);

    const handleOk = useCallback(() => {
        onOk(selectedMethods);
    }, [selectedMethods, onOk]);

    return (
        <Modal
            title="Select payment method"
            visible={visible}
            onOk={handleOk}
            onCancel={onCancel}
            footer={[
                <button className='bg-[#EFB81C] px-10 py-1.5 text-sm font-semibold rounded-sm' key="submit" type="primary" onClick={handleOk}>Confirm</button>,
            ]}
        >
            <SearchContainer>
                <StyledInput
                    type="text"
                    placeholder="Search payment methods"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className='my-3'
                    prefix={<IoSearchOutline />}
                />
                <div>
                    <p className='py-2 text-sm font-light'>
                        Select Payment Method
                        <span className='text-[#f6465d] text-sm font-light ml-1'>(Up to 5 methods)</span>
                    </p>
                    {filteredMethods.map(method => (
                        <div key={method.id}>
                            <button
                                onClick={() => handleMethodSelect(method)}
                                className={`w-full flex justify-between rounded-md items-center ${selectedMethods.some(selected => selected.id === method.id) ? 'border-primary' : 'border-gray-300'}`}
                                disabled={selectedMethods.length >= 5 && !selectedMethods.some(selected => selected.id === method.id)}
                            >
                                <div className='flex justify-between w-full border p-4 mb-2 rounded-md items-center'>
                                    <span className='text-sm font-semibold'>{method.name}</span>
                                    <div>
                                        {selectedMethods.some(selected => selected.id === method.id) ? <MdCheckBox className='text-[#EFB81C] text-xl' /> : <MdCheckBoxOutlineBlank className='text-xl text-gray-400' />}
                                    </div>
                                </div>
                            </button>
                        </div>
                    ))}
                    {(!search && BuyMethods.length === 0) && (
                        <p>No payment methods available</p>
                    )}
                </div>
            </SearchContainer>
        </Modal>
    );
};

const SearchContainer = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  padding: 0.55rem;
  font-weight: bold;
  color: #707a8a;
  border: 2px solid #dbdbdb;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #efb81c;
  }

  &:focus, 
  &:focus-within {
    border-color: #efb81c;
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2);
    outline: none;
  }
`;

export default AddPaymentMethodModal;