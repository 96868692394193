// CustomDropdownWithImage.js
import React, { useState, useEffect, useRef } from 'react';
import { MdArrowDropDown } from "react-icons/md";

const CustomDropdownWithImage = ({ options, selectedValue, setSelectedValue, excludeValues }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    const handleSelect = (value, ticker, icon) => {
        setSelectedValue({ value, ticker, icon });
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !excludeValues.includes(option.value) &&
        option.value !== selectedValue?.value // Exclude currently selected value
    );

    return (
        <div className="relative" ref={dropdownRef}>
            <div
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center bg-inherit py-2 px-3 cursor-pointer justify-between w-fit rounded focus:outline-none dark:bg-[#404347]"
            >
                {selectedValue?.icon && (
                    <img src={selectedValue?.icon} alt={selectedValue?.value} className="w-7 h-7 rounded-full mr-2" />
                )}
                <span className="text-neutral-900 font-medium text-sm dark:text-[#f4f4f4] flex items-center">
                    {selectedValue?.value}
                    <MdArrowDropDown size={18}/>
                </span>
            </div>
            {isOpen && (
                <div className="absolute mt-1 w-max max-h-60 overflow-y-auto bg-[#f4f4f4] dark:bg-[#404347] border dark:border-[#404347] rounded shadow-lg z-10">
                    <input
                        type="text"
                        placeholder="Search currencies"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full py-2 px-3 text-sm border-b border-gray-300 dark:bg-[#404347] dark:text-[#f4f4f4] dark:border-[#404347] focus:outline-none focus:border-primary-500"
                    />
                    {filteredOptions.map(option => (
                        <div
                            key={option.value}
                            onClick={() => handleSelect(option.value, option.ticker, option.icon)}
                            className="flex items-center py-2 px-3 cursor-pointer hover:bg-gray-300 dark:hover:bg-[#151415]"
                        >
                            {option.icon && <img src={option.icon} alt={option.value} className="w-6 h-6 rounded-full mr-2" />}
                            <span className="text-sm text-neutral-900 dark:text-[#f4f4f4]">{option.label}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomDropdownWithImage;
