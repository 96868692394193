import Repository from "./Repository";
const NewDashboardUrl = 'Finance/newDashboard';

export default {

    getNewDashboardData(userId, token,type) {
        return Repository.get(`${NewDashboardUrl}`, {
            headers: {
                token: token,
                id: userId,
            },
            params:{
                type: type
            }
        });
    },
};
