import React from 'react'
import NoData from "assets/img/user/No-data.svg";
import { TiArrowUnsorted } from "react-icons/ti";

function OpenOrders() {
  return (
    <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
      <div className="flex justify-between border-b border-t py-3 w-full">
          <p className="flex-1 flex items-center text-start">Date<TiArrowUnsorted/></p>
          <p className="flex-1 text-start">Wallet</p>
          <p className="flex-1 text-start">Pair</p>
          <p className="flex-1 text-start">Sell</p>
          <p className="flex-1 text-start">Buy</p>
          <p className="flex-1 text-start">Price</p>
          <p className="flex-1 text-start">Expires in</p>
          <p className="flex-1 text-start">Expiration</p>
          <p className="flex-1 text-center">Action</p>


        </div>
      <div className="w-full flex justify-center items-center h-96">
        <div className='flex flex-col gap-1 h-44 justify-center items-center'>
          <img src={NoData} alt="No Data" />
          <p className='text-[#707a8a] text-sm'>No records</p>
        </div>
      </div>
    </div>
  )
}

export default OpenOrders