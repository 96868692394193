import React, { useState, useRef, useCallback, useEffect } from "react";
import NoData from "assets/img/user/No-data.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchP2pOrderStatus } from "../../../store/actions/OrderUserAction";
import AppealDropdown from "./AppealDropdown";
import { RiDownload2Line, RiFileHistoryFill } from "react-icons/ri";
import { DatePicker, Space } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import { Tooltip } from "react-tooltip";
import { IoMdInformationCircle } from "react-icons/io";
const { RangePicker } = DatePicker;

function AppealRecords() {
    const [openDropdown, setOpenDropdown] = useState(null);
    const [selectedCoin, setSelectedCoin] = useState("All");
    const [selectedType, setSelectedType] = useState("All");
    const [selectedStatus, setSelectedStatus] = useState("All");
    const [selectedFiat, setSelectedFiat] = useState("All");
    const [selectedDate, setSelectedDate] = useState("Start Date");
    const dispatch = useDispatch();

    const { loading, orderStatus, error } = useSelector(
        (state) => state.orderStatus
    );
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    useEffect(() => {
        dispatch(fetchP2pOrderStatus(userID, userToken));
    }, [dispatch, userID, userToken]);
    const dropdownRefs = useRef({});

    const handleDropdownToggle = useCallback(
        (dropdown) => {
            setOpenDropdown(openDropdown === dropdown ? null : dropdown);
        },
        [openDropdown]
    );

    const closeAllDropdowns = () => {
        setOpenDropdown(null);
    };

    const handleClickOutside = (event) => {
        const dropdownElement = dropdownRefs.current[openDropdown];
        if (dropdownElement && !dropdownElement.contains(event.target)) {
            closeAllDropdowns();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [openDropdown]);

    const dropdowns = [
        {
            key: "coin",
            label: "Coins",
            options: ["All", "USDT"],
            selected: selectedCoin,
            setSelected: setSelectedCoin,
            width: "100%",
        },
        {
            key: "type",
            label: "Type",
            options: ["All trade Types", "Buy", "Sell"],
            selected: selectedType,
            setSelected: setSelectedType,
            width: "100%",
        },
    ];
    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="text-lg">Loading...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="text-lg text-red-500">Error: {error}</div>
            </div>
        );
    }
    return (
        <div className="container">
            <div className="flex container justify-between">
                <div className="flex flex-1 flex-col md:flex-col lg:flex-row w-full items-baseline gap-3 mb-4">
                    <div className="flex gap-3 flex-wrap items-center w-full">
                        {dropdowns.map(
                            ({
                                key,
                                label,
                                options,
                                selected,
                                setSelected,
                                width,
                                isSearchable,
                            }) => (
                                <div
                                    key={key}
                                    className="flex-1 w-full"
                                    ref={(el) =>
                                        (dropdownRefs.current[key] = el)
                                    }
                                >
                                    <AppealDropdown
                                        label={label}
                                        options={options}
                                        selected={selected}
                                        onSelect={(value) => {
                                            console.log(
                                                `Selected ${
                                                    key
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                    key.slice(1)
                                                }:`,
                                                value
                                            );
                                            setSelected(value);
                                            closeAllDropdowns();
                                        }}
                                        isOpen={openDropdown === key}
                                        onToggle={(isOpen) =>
                                            isOpen
                                                ? handleDropdownToggle(key)
                                                : closeAllDropdowns()
                                        }
                                        width={width}
                                        isSearchable={key === "fiat"}
                                    />
                                </div>
                            )
                        )}

                        <div className="mt-[8px] !md:mt-[8px] lg:mt-3">
                            <label className="flex gap-1 !items-start text-[12px] mb-1">
                                Date
                                <label
                                    htmlFor="coin"
                                    className="flex items-start text-sm font-medium"
                                >
                                    <IoMdInformationCircle
                                        data-tooltip-id="coin-tooltip"
                                        data-tooltip-content="Export order list"
                                        className="inline cursor-pointer text-gray-400"
                                    />
                                    <Tooltip
                                        id="coin-tooltip"
                                        place="top"
                                        effect="solid"
                                    />
                                </label>
                            </label>
                            <div className="">
                                <GlobalStyles />
                                <StyledRangePicker
                                    className="px-2 py-[0.55rem] border-[2px] border-[#DBDBDB]"
                                    style={{ borderRadius: "0.5rem" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between mt-[-25px] md:mt-[-25px] lg:mt-0 w-full">
                        <div className="flex mt-8 justify-between ml-0 md:ml-0 lg:ml-4 gap-2">
                            <button className="bg-[#EFB81C] font-medium text-[14px] px-8 py-2.5 rounded-lg">
                                Search
                            </button>
                            <button className="bg-gray-200 font-medium text-[14px] px-8 py-2.5 rounded-lg">
                                Reset
                            </button>
                        </div>
                        <div className="flex mt-8 items-center justify-between gap-2 md:gap-6 lg:gap-6">
                            <label
                                htmlFor="coin"
                                className="block text-sm font-medium bg-gray-200 rounded-md px-3 py-3"
                            >
                                <RiDownload2Line
                                    data-tooltip-id="coin-tooltip"
                                    data-tooltip-content="Export order list"
                                    className="inline cursor-pointer"
                                />
                                <Tooltip
                                    id="coin-tooltip"
                                    place="top"
                                    effect="solid"
                                />
                            </label>
                            <label
                                htmlFor="coin"
                                className="block text-sm font-medium bg-gray-200 rounded-md px-3 py-3"
                            >
                                <RiFileHistoryFill
                                    data-tooltip-id="coin-tooltip"
                                    data-tooltip-content="View the export task process"
                                    className="inline cursor-pointer"
                                />
                                <Tooltip
                                    id="coin-tooltip"
                                    place="top"
                                    effect="solid"
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <table className="w-full text-sm font-light border-none table-hover">
                <thead className="border-b text-neutral-400">
                    <tr className="flex w-full">
                        <th
                            scope="col"
                            className="py-2 font-light text-[12px] cursor-pointer flex-1"
                        >
                            Order ID/Time Created
                        </th>
                        <th
                            scope="col"
                            className="py-2 font-light text-[12px] cursor-pointer flex-1"
                        >
                            Price
                        </th>
                        <th
                            scope="col"
                            className="py-2 font-light text-[12px] cursor-pointer flex-1"
                        >
                            Total Value
                        </th>
                        <th
                            scope="col"
                            className="py-2 font-light text-[12px] cursor-pointer flex-1"
                        >
                            Amount
                        </th>
                        <th
                            scope="col"
                            className="py-2 font-light text-[12px] cursor-pointer flex-1"
                        >
                            Merchant Name
                        </th>
                        <th
                            scope="col"
                            className="py-2 font-light text-[12px] cursor-pointer flex-1"
                        >
                            Status
                        </th>
                        <th
                            scope="col"
                            className="py-2 font-light text-[12px] cursor-pointer flex-1"
                        >
                            Operation
                        </th>
                    </tr>
                </thead>
            </table>
            <div className="flex items-center justify-center h-96">
                <div className="flex flex-col items-center justify-center">
                    {orderStatus}
                </div>
            </div>
        </div>
    );
}
const GlobalStyles = createGlobalStyle`
  // Override hover state for picker
  .ant-picker:hover {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on hover */
  }

  // Override focus state for picker
  .ant-picker-focused {
    border-color: #EFB81C !important; /* Change border color to #EFB81C on focus */
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important; /* Add shadow with color #EFB81C */
  }

  // Style for the active state bar in RangePicker
  .ant-picker-active-bar {
    background: #EFB81C !important; /* Change the active border bar color to #EFB81C */
  }

  // Style calendar cells with selected date
  .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EFB81C !important; /* Change the background color of selected date */
    color: white !important; /* Keep the text color white for contrast */
  }

  // Style for "Today" cell in the calendar dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1;
    border: 1px solid #EFB81C !important; /* Change border color to #EFB81C for the "Today" cell */
    border-radius: 4px;
    content: "";
  }
    // Change color of calendar header buttons on hover
  .ant-picker-dropdown .ant-picker-header-view > button:hover {
    color: #EFB81C !important; /* Change hover color to #EFB81C */
  }
`;

const StyledRangePicker = styled(RangePicker)`
    // Custom styling for RangePicker input border when focused
    .ant-picker-input > input:focus {
        box-shadow: none !important; /* Remove any focus box-shadow */
        border-color: #efb81c !important; /* Ensure border stays #EFB81C */
    }
`;

export default AppealRecords;
