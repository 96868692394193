import { RepositoryFactory } from "repository/RepositoryFactory";
import {
    FIAT_WITHDRAWAL_REQUEST,
    FIAT_WITHDRAWAL_FAILURE,
    FIAT_WITHDRAWAL_SUCCESS,
} from "../types";
import toast from "react-hot-toast";

const FiatWithdrawalRepository = RepositoryFactory.get("fiatWithdrawal");

export const doFiatWithdrawal =
    (userId, token, withdrawalInputs) => async (dispatch) => {
        dispatch({ type: FIAT_WITHDRAWAL_REQUEST });
        try {
            const response = await FiatWithdrawalRepository.dofiatWithdrawal(
                userId,
                token,
                withdrawalInputs
            );
            if (response.data.status === 1) {
                toast.success(response.data.data);
                dispatch({
                    type: FIAT_WITHDRAWAL_SUCCESS,
                    payload: response.data,
                });
                return response
            } else {
                dispatch({
                    type: FIAT_WITHDRAWAL_FAILURE,
                    payload: response.data,
                });
                toast.error(response.data.data);
                return response
            }
        } catch (error) {
            console.log("We failed.", error);
            dispatch({ type: FIAT_WITHDRAWAL_FAILURE, error });
        }
    };
