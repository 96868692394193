import Repository from "./Repository";

const DO_FIAT_WITHDRAWL = "/Crypto/doFiatWithdrawal";

export default {
    dofiatWithdrawal(userId, token, withdrawalInputs) {
        const formattedWithdrawalInputs = {
            num: Number(withdrawalInputs.num),
            paypassword: withdrawalInputs.paypassword,
            type: Number(withdrawalInputs.type),
            coinname: withdrawalInputs.coinname.toLowerCase(),
        };
        const url = `${DO_FIAT_WITHDRAWL}?num=${formattedWithdrawalInputs.num}&paypassword=${formattedWithdrawalInputs.paypassword}&type=${formattedWithdrawalInputs.type}&coinname=${formattedWithdrawalInputs.coinname}`;
        return Repository.get(url, {
            headers: {
                token: token,
                id: userId,
            },
        });
    },
};
