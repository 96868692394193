/* eslint-disable */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { InactivityProvider } from "context/InactivityContext";
import { Toaster } from "react-hot-toast";
import { SelectedCoinProvider } from "./components/spot-trade/context/SelectedCoinContext"; // Import the SelectedCoinProvider

import ErrorBoundary from "error/ErrorBoundary";

import Auth from "layouts/Auth";
import MainLayout from "layouts/Master";
import Market from "layouts/MarketLayout";
import User from "layouts/User";
import Assets from "layouts/Assets";
import Orders from "layouts/Orders";
import P2pLayout from "layouts/P2pLayout";

import store from "./store";
import routes from "./routes";
import SpotTradeLayout from "layouts/SpotTradeLayout";
// import AuthenticatedRoute from "./AuthenticatedRoute"
import Derivate from "layouts/Derivate";

// import { GoogleLogin } from "@react-oauth/google";

function App() {
    const layoutMap = {
        main: MainLayout,
        derivate: Derivate,
        auth: Auth,
        user: User,
        assets: Assets,
        orders: Orders,
        p2p: P2pLayout,
        market: Market,
        spotTrade: SpotTradeLayout,
    };

    return (
        <Provider store={store}>
            <BrowserRouter>
                <InactivityProvider>
                    <Toaster />
                    {/* <GoogleLogin
                        onSuccess={responseMessage}
                        onError={errorMessage}
                    /> */}
                    <ErrorBoundary>
                        <SelectedCoinProvider>
                            <Switch>
                                {routes.map((route) => {
                                    const LayoutComponent =
                                        layoutMap[route.layout];

                                    // if (route.layout === "auth") {
                                    //     return (
                                    //         <Route
                                    //             key={route.path}
                                    //             exact={route.exact}
                                    //             path={route.path}
                                    //             render={(props) => (
                                    //                 <LayoutComponent>
                                    //                     <route.component
                                    //                         {...props}
                                    //                     />
                                    //                 </LayoutComponent>
                                    //             )}
                                    //         />
                                    //     );
                                    // }
                                    return (
                                        // <AuthenticatedRoute
                                        <Route
                                            key={route.path}
                                            exact={route.exact}
                                            path={route.path}
                                            render={(props) => (
                                                <LayoutComponent>
                                                    <route.component {...props} />
                                                </LayoutComponent>
                                            )}
                                            // layout={LayoutComponent}
                                            // component={route.component}
                                        />
                                    );
                                })}
                                <Redirect to="/" />
                                {/* <Redirect to="/login" /> */}
                            </Switch>
                        </SelectedCoinProvider>
                    </ErrorBoundary>
                </InactivityProvider>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
