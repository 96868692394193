import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { MdKeyboardArrowLeft,MdKeyboardArrowRight } from "react-icons/md";

const Card = ({ title, value }) => (
  <div className="p-4 w-[300px] bg-gray-100 rounded-xl drop-shadow w m">
    <div className="mb-2 text-sm text-gray-700">{title}</div>
    <div className="text-2xl font-semibold">{value}</div>
  </div>
);

const TableEarn = ({ assetsData, loading, error }) => {
  const [isFilter, setIsFilter] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    if (assetsData && (assetsData.cryptoList || assetsData.fiatList)) {
      if (isFilter && assetsData.cryptoList) {
        const coinList = Object.values(assetsData.cryptoList).map((coin, index) => ({
          id: index,
          icon: coin.img || "bitcoin",
          coinName: coin.name.toUpperCase(),
          coinType: coin.title,
          amount: parseFloat(coin.xnbz),
          coinPrice: coin.jia,
        }));
        setTableData(coinList);
      }
      else if (assetsData.fiatList) {
        const fiatList = Object.values(assetsData.fiatList).map((coin, index) => ({
          id: index,
          icon: coin.img || "bitcoin",
          coinName: coin.name.toUpperCase(),
          coinType: coin.title,
          amount: parseFloat(coin.xnbz),
          coinPrice: coin.jia,
        }));
        setTableData(fiatList);
      }
    }
  }, [isFilter,assetsData]);

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedData = [...tableData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setTableData(sortedData);
    setSortConfig({ key, direction });
  };

  const handleSearch = (e) => setSearchQuery(e.target.value);

  const filteredData = tableData.filter(
    (data) =>
      data.coinName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data.coinType.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * rowsPerPage;
  const paginatedData = filteredData.slice(startIndex, startIndex + rowsPerPage);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg text-red-500">Error: {error}</div>
      </div>
    );
  }

  return (
    <>
      <div className="relative flex items-center w-full mt-4 left-[2px] top-3">
        <h6 className="text-[20px] font-medium bg-white pb-4 cursor-pointer rounded-t-xl px-3 pt-1.5">
          Savings
        </h6>
        <h6 className="text-[20px] font-medium pb-4 rounded-t-xl px-3 cursor-pointer pt-1.5">
          Stacking
        </h6>
      </div>
      <div className="flex flex-col items-center w-full p-[20px] bg-white border rounded-2xl">
        {/* Cards */}
        <div className="flex justify-around w-full mb-4">
          <Card title="Cml. Incomes (USD)" value="23.4" />
          <Card title="Ytd's Income (USD)" value="23.4" />
          <Card title="Ag. APY" value="23.4" />
        </div>

        {/* Search Bar */}
        <div className="flex items-center justify-start w-full mt-4 mb-4 ml-4">
          <div className="border bg-gray-100 rounded-lg text-gray-700 flex items-center  px-2 py-1">
          <FaMagnifyingGlass />
            <input
              type="text"
              placeholder="Search"
              className="w-[155px] py-1 text-gray-700 text-sm px-2 bg-transparent outline-none placeholder-inherit"
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>
        </div>
        {/* Buttons */}
        <div className="flex w-full  justify-start mt-4 font-medium gap-x-6 text-[16px]">
            <button
            onClick={() => setIsFilter(true)}
            className={`${
                isFilter ? "border-[#c9c9c9]" : "border-transparent"
            } pb-1 text-[16px] border-b-4`}
            >
            Coins List
            </button>
            <button
            onClick={() => setIsFilter(false)}
            className={`${
                isFilter ? "border-transparent" : "border-[#c9c9c9]"
            } pb-1 text-[16px] border-b-4`}
            >
                Fiat List
            </button>
        </div>
        {/* Main Table */}
        <table className="w-full table-container h-fit">
          {/* Table Headings */}
          <thead className="mb-1 border-b">
            <tr className="flex justify-between">
              <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-left">
                <div className="flex text-sm items-center gap-1 cursor-pointer" onClick={() => sortData("coinName")}>
                  Coin
                  <span className="relative flex text-[#aaaaaa] text-sm">
                    <span
                      className={`absolute right-0 top-1 ${sortConfig.key === "coinName" && sortConfig.direction === "ascending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretUp} />
                    </span>
                    <span
                      className={`mt-[9px] ${sortConfig.key === "coinName" && sortConfig.direction === "descending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                  </span>
                </div>
              </th>
              <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
                <div className="flex text-sm items-center justify-center gap-1 cursor-pointer" onClick={() => sortData("amount")}>
                  Amount
                  <span className="relative flex text-[#aaaaaa] text-sm">
                    <span
                      className={`absolute right-0 top-1 ${sortConfig.key === "amount" && sortConfig.direction === "ascending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretUp} />
                    </span>
                    <span
                      className={`mt-[9px] ${sortConfig.key === "amount" && sortConfig.direction === "descending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                  </span>
                </div>
              </th>
              <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
                <div className="flex text-sm items-center justify-center gap-1 cursor-pointer" onClick={() => sortData("coinPrice")}>
                  Cml. Incomes
                  <span className="relative flex text-[#aaaaaa] text-sm">
                    <span
                      className={`absolute right-0 top-1 ${sortConfig.key === "coinPrice" && sortConfig.direction === "ascending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretUp} />
                    </span>
                    <span
                      className={`mt-[9px] ${sortConfig.key === "coinPrice" && sortConfig.direction === "descending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                  </span>
                </div>
              </th>
              <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
                <div className="flex  items-center text-sm justify-center gap-1 cursor-pointer" onClick={() => sortData("coinPrice")}>
                  Ytd's Income
                  <span className="relative flex text-[#aaaaaa] text-sm">
                    <span
                      className={`absolute right-0 top-1 ${sortConfig.key === "coinPrice" && sortConfig.direction === "ascending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretUp} />
                    </span>
                    <span
                      className={`mt-[9px] ${sortConfig.key === "coinPrice" && sortConfig.direction === "descending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                  </span>
                </div>
              </th>
              <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
                <div className="flex text-sm items-center justify-center gap-1 cursor-pointer" onClick={() => sortData("coinPrice")}>
                  1-DAY APY
                  <span className="relative flex text-[#aaaaaa] text-sm">
                    <span
                      className={`absolute right-0 top-1 ${sortConfig.key === "coinPrice" && sortConfig.direction === "ascending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretUp} />
                    </span>
                    <span
                      className={`mt-[9px] ${sortConfig.key === "coinPrice" && sortConfig.direction === "descending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                  </span>
                </div>
              </th>
              <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
                <div className="flex text-sm items-center justify-center gap-1 cursor-pointer" onClick={() => sortData("coinPrice")}>
                  7-DAY APY
                  <span className="relative flex text-[#aaaaaa] text-sm">
                    <span
                      className={`absolute right-0 top-1 ${sortConfig.key === "coinPrice" && sortConfig.direction === "ascending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretUp} />
                    </span>
                    <span
                      className={`mt-[9px] ${sortConfig.key === "coinPrice" && sortConfig.direction === "descending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                  </span>
                </div>
              </th>
              <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-center">
                <div className="flex text-sm items-center justify-center gap-1 cursor-pointer" onClick={() => sortData("coinPrice")}>
                  AGR. APY
                  <span className="relative flex text-[#aaaaaa] text-sm">
                    <span
                      className={`absolute right-0 top-1 ${sortConfig.key === "coinPrice" && sortConfig.direction === "ascending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretUp} />
                    </span>
                    <span
                      className={`mt-[9px] ${sortConfig.key === "coinPrice" && sortConfig.direction === "descending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                  </span>
                </div>
              </th>
              <th className="text-[#aaaaaa] font-light px-4 py-2 flex-1 text-right">
                <div className="flex text-sm items-center justify-end gap-1 cursor-pointer" onClick={() => sortData("coinPrice")}>
                  Actions
                  <span className="relative flex text-[#aaaaaa] text-sm">
                    <span
                      className={`absolute right-0 top-1 ${sortConfig.key === "coinPrice" && sortConfig.direction === "ascending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretUp} />
                    </span>
                    <span
                      className={`mt-[9px] ${sortConfig.key === "coinPrice" && sortConfig.direction === "descending" ? "text-black" : ""}`}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                  </span>
                </div>
              </th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {paginatedData.map((data) => (
              <tr key={data.id} className="flex justify-between py-2 hover:shadow-lg">
                <td className="flex flex-1 px-4 py-2 text-left gap-x-2">
                  <img src={`https://api.buycex.com/Upload/coin/${data.icon}`} alt="" className="rounded-full w-7 h-7" />
                  <div className="font-semibold"><span>{data.coinName}</span></div>
                </td>
                <td className="flex-1 px-4 py-2 text-center">{data.amount}</td>
                <td className="flex-1 px-4 py-2 text-center">${data.coinPrice.toFixed(2)}</td>
                <td className="flex-1 px-4 py-2 text-center">0</td> {/* Ytd's Income column placeholder */}
                <td className="flex-1 px-4 py-2 text-center">0</td> {/* 1-Day APY column placeholder */}
                <td className="flex-1 px-4 py-2 text-center">0</td> {/* 7-Day APY column placeholder */}
                <td className="flex-1 px-4 py-2 text-center">0</td> {/* Agr. APY column placeholder */}
                <td className="flex-1 px-4 py-2 text-right">
                  <button className="text-yellow-500">Transfer</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center items-center gap-4 w-full mt-4">
          <button
            className="px-2 py-2 text-sm text-yellow-500 bg-gray-200 rounded-md hover:bg-gray-300"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            <MdKeyboardArrowLeft className="text-xl" />
          </button>
          <div className="flex gap-2">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                className={`px-4 py-2 text-sm ${i + 1 === currentPage ? "bg-black text-white" : "bg-gray-200 text-gray-600"} rounded-md`}
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>
          <button
            className="px-2 py-2 text-sm text-yellow-500 bg-gray-200 rounded-md hover:bg-gray-300"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            <MdKeyboardArrowRight className="text-xl" />
          </button>
        </div>
    </>
  );
};

export default TableEarn;
