import React from "react";
import Image from "./sheet2.avif";
const Partners = () => {
    return (
        <div>
            <table className="w-[100%] mt-10 mb-10">
                <tbody>
                    <tr>
                        <td className="border-4 border-solid border-[#f5f5f5] p-4">
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <div className="w-32 h-32  overflow-hidden">
                                    <img src={Image} alt="" className="" />
                                </div>
                                <h2 className="text-xl font-semibold">
                                    Google Sheets
                                </h2>
                                <p className="font-semibold text-[#a0a0a0]">
                                    Export to Spreadsheet in one click
                                </p>
                            </div>
                        </td>
                        <td className="border-4 border-solid border-[#f5f5f5] p-4">
                            <div className="flex flex-col gap-1 items-center justify-end">
                                <div className="w-32 h-32  overflow-hidden">
                                    <img src={Image} alt="" className="" />
                                </div>
                                <h2 className="text-xl font-semibold">
                                    Plotly
                                </h2>
                                <p className="font-semibold text-[#a0a0a0]">
                                    Export to Spreadsheet in one click
                                </p>
                            </div>
                        </td>
                        <td className="border-4 border-solid border-[#f5f5f5] p-4">
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <div className="w-32 h-32  overflow-hidden">
                                    <img src={Image} alt="" className="" />
                                </div>
                                <h2 className="text-xl font-semibold">
                                    Excel Spreadsheets
                                </h2>
                                <p className="font-semibold text-[#a0a0a0]">
                                    Export to Spreadsheet in one click
                                </p>
                            </div>
                        </td>
                        <td className="border-4 border-solid border-[#f5f5f5] p-4">
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <div className="w-32 h-32  overflow-hidden">
                                    <img src={Image} alt="" className="" />
                                </div>
                                <h2 className="text-xl font-semibold">Silk</h2>
                                <p className="font-semibold text-[#a0a0a0]">
                                    Export to Spreadsheet in one click
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="border-4 border-solid border-[#f5f5f5] p-4">
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <div className="w-32 h-32  overflow-hidden">
                                    <img src={Image} alt="" className="" />
                                </div>
                                <h2 className="text-xl font-semibold">
                                    Tablau
                                </h2>
                                <p className="font-semibold text-[#a0a0a0]">
                                    Export to Spreadsheet in one click
                                </p>
                            </div>
                        </td>
                        <td className="border-4 border-solid border-[#f5f5f5] p-4">
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <div className="w-32 h-32  overflow-hidden">
                                    <img src={Image} alt="" className="" />
                                </div>
                                <h2 className="text-xl font-semibold">
                                    Infogram
                                </h2>
                                <p className="font-semibold text-[#a0a0a0]">
                                    Export to Spreadsheet in one click
                                </p>
                            </div>
                        </td>
                        <td className="border-4 border-solid border-[#f5f5f5] p-4">
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <div className="w-32 h-32  overflow-hidden">
                                    <img src={Image} alt="" className="" />
                                </div>
                                <h2 className="text-xl font-semibold">
                                    AppGyver
                                </h2>
                                <p className="font-semibold text-[#a0a0a0]">
                                    Export to Spreadsheet in one click
                                </p>
                            </div>
                        </td>
                        <td className="border-4 border-solid border-[#f5f5f5] p-4">
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <div className="w-32 h-32  overflow-hidden">
                                    <img src={Image} alt="" className="" />
                                </div>
                                <h2 className="text-xl font-semibold">
                                    CartoDB
                                </h2>
                                <p className="font-semibold text-[#a0a0a0]">
                                    Export to Spreadsheet in one click
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="border-4 border-solid border-[#f5f5f5] p-4">
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <div className="w-32 h-32  overflow-hidden">
                                    <img src={Image} alt="" className="" />
                                </div>
                                <h2 className="text-xl font-semibold">
                                    Blockspring
                                </h2>
                                <p className="font-semibold text-[#a0a0a0]">
                                    Export to Spreadsheet in one click
                                </p>
                            </div>
                        </td>
                        <td className="border-4 border-solid border-[#f5f5f5] p-4">
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <div className="w-32 h-32  overflow-hidden">
                                    <img src={Image} alt="" className="" />
                                </div>
                                <h2 className="text-xl font-semibold">
                                    Big ML
                                </h2>
                                <p className="font-semibold text-[#a0a0a0]">
                                    Export to Spreadsheet in one click
                                </p>
                            </div>
                        </td>
                        <td className="border-4 border-solid border-[#f5f5f5] p-4">
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <div className="w-32 h-32  overflow-hidden">
                                    <img src={Image} alt="" className="" />
                                </div>
                                <h2 className="text-xl font-semibold">
                                    Interworks
                                </h2>
                                <p className="font-semibold text-[#a0a0a0]">
                                    Export to Spreadsheet in one click
                                </p>
                            </div>
                        </td>
                        <td className="border-4 border-solid border-[#f5f5f5] p-4">
                            <div className="flex flex-col gap-1 items-center justify-center">
                                <div className="w-32 h-32  overflow-hidden">
                                    <img src={Image} alt="" className="" />
                                </div>
                                <h2 className="text-xl font-semibold">
                                    Your business
                                </h2>
                                <p className="font-semibold text-[#a0a0a0]">
                                    Export to Spreadsheet in one click
                                </p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Partners;
