import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchUserProfile } from "store/actions/userProfileAction";

import {
    BsGem,
    BsSliders,
    BsPatchCheckFill,
    BsShieldLockFill,
    BsCheckCircleFill,
    BsPatchExclamationFill,
    BsPatchQuestionFill,
} from "react-icons/bs";
import { FaFileCircleCheck } from "react-icons/fa6";
import verifiedTick from "assets/img/Verified tick.svg";
import yellowTick from "assets/img/yellow-bg-tick.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { fetchKycStatus } from "store/actions/AccountuserActions";
import { fetchAuthStatus } from "store/actions/userSecuritySettingsAction";

import LoginHistory from "./LoginHistory";
import UserProfileDetails from "./UserProfileDetails";
import LoginStatusManagement from "./LoginStatusManagement";
import { Tooltip } from "react-tooltip";
const AccountOverview = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, userProfile, error } = useSelector(
        (state) => state.userInfo
    );
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const kycStatus = useSelector((state) => state?.userReducer);
    const { authStatus } = useSelector((state) => state.authCheck);
    const verified = useSelector(
        (state) => state.authCheck.authStatus.idcardauth
    );
    const usertype = useSelector(
        (state) => state.authCheck.authStatus.usertype
    );
    const idcard = useSelector(
        (state) => state.authCheck.authStatus.idcardimg1
    );

    const statusMap = {
        0: {
            icon: <BsPatchExclamationFill size={10} fill="red" />,
            text: "Not Submitted",
            textColor: "text-[red]",
        },
        1: {
            icon: <BsPatchCheckFill size={10} fill="green" />,
            text: "Approved",
            textColor: "text-[green]",
        },
        2: {
            icon: <BsPatchQuestionFill size={10} fill="orange" />,
            text: "In Review",
            textColor: "text-[orange]",
        },
        3: {
            icon: <BsPatchExclamationFill size={10} fill="red" />,
            text: "Rejected",
            textColor: "text-[red]",
        },
    };

    const defaultStatus = {
        icon: <BsPatchQuestionFill size={10} fill="grey" />,
        text: "In Review",
        textColor: "text-[orange]",
    };

    const { icon, text, textColor } = statusMap[verified] || defaultStatus;

    useEffect(() => {
        if (ID && TOKEN) {
            dispatch(fetchUserProfile(ID, TOKEN));
        }
    }, [dispatch, ID, TOKEN]);
    useEffect(() => {
        if (userID && userToken) {
            dispatch(fetchKycStatus(userID, userToken));
            dispatch(fetchAuthStatus(userID, userToken));
        }
    }, [dispatch, userID, userToken]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    const username = userProfile?.username;

    // const kycNotVerified = userProfile?.kyc == 0;
    const kycNotVerified = !userProfile?.kyc_comment;

    const emailNotVerified = !authStatus?.email;
    const phoneNotVerified = !authStatus?.cellphone;
    const g2faNotVerified = !authStatus?.ga;

    const checkSecurityLevel = (
        emailNotVerified,
        phoneNotVerified,
        g2faNotVerified
    ) => {
        const notVerifiedItems = [
            { name: "Email", notVerified: emailNotVerified },
            { name: "Phone", notVerified: phoneNotVerified },
            { name: "G2FA", notVerified: g2faNotVerified },
        ];

        return notVerifiedItems.map((item, index) => (
            <div
                key={index}
                className={`flex items-center gap-1 ${
                    item.notVerified
                        ? "cursor-not-allowed user-select-none border-[0.5px] px-[6px] py-[2px] rounded-md opacity-50 bg-transparent"
                        : "cursor-default user-select-none px-[6px] py-[2px] rounded-md border border-[#F4F4F4] opacity-100 bg-[white]"
                }`}
            >
                <span className="text-[10px]">{item.name}</span>
                {!item.notVerified && (
                    <BsCheckCircleFill size={8} fill="green" />
                )}
            </div>
        ));
    };

    const getStatus = (emailNotVerified, phoneNotVerified, g2faNotVerified) => {
        const notVerifiedCount = [
            emailNotVerified,
            phoneNotVerified,
            g2faNotVerified,
        ].filter(Boolean).length;

        let statusText = "";
        let statusColor = "";

        if (notVerifiedCount === 3) {
            statusText = "Low";
            statusColor = "red";
        } else if (notVerifiedCount === 2) {
            statusText = "Low";
            statusColor = "orange";
        } else if (notVerifiedCount === 1) {
            statusText = "Medium";
            statusColor = "#EFB81C";
        } else {
            statusText = "High";
            statusColor = "green";
        }

        return (
            <div className="flex items-center gap-1">
                <span>
                    <BsPatchCheckFill size={10} fill={statusColor} />
                </span>
                <span className={`text-[12px] text-[${statusColor}]`}>
                    {statusText}
                </span>
            </div>
        );
    };

    const handleidVerificationRedirect = () => {
        if (idcard) {
            history.push(
                "/user/account/identity-verification/advance-verification"
            );
        } else {
            history.push("/user/account/identity-verification/information");
        }
    };

    const handleSecuritySettingsRedirect = () => {
        history.push("/user/account/security");
    };

    const handleAdvancementsRedirect = () => {
        history.push("/user/account/advancements");
    };

    return (
        <div className="w-full mt-10 flex flex-col gap-3">
            <UserProfileDetails
                notVerified={kycNotVerified}
                username={username}
                userID={ID}
            />
            {/* top section  */}
            <div className="bg-[#FFFFFF] rounded-md border border-[#F4F4F4]">
                {kycNotVerified ? (
                    <div
                        className={`py-[20px] px-4  flex items-center justify-between`}
                    >
                        <div className="flex items-center gap-14">
                            <div className="flex items-center gap-4">
                                <div>
                                    <BsPatchCheckFill size={20} />
                                </div>
                                <div className="font-semibold text-[14px]">
                                    ID Verification
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center gap-10">
                            <div className="flex items-center gap-1">
                                <span>{icon}</span>
                                <span className={`text-[12px] ${textColor}`}>
                                    {text}
                                </span>
                            </div>
                            {/* {kycNotVerified ? (
                                <div className="flex items-center gap-1">
                                    <span>
                                        <BsPatchExclamationFill
                                            size={10}
                                            fill="red"
                                        />
                                    </span>
                                    <span className="text-[12px] text-[red]">
                                        Not Submitted
                                    </span>
                                </div>
                            ) : (
                                <div className="flex items-center gap-1">
                                    <span>
                                        <BsPatchCheckFill
                                            size={10}
                                            fill="green"
                                        />
                                    </span>
                                    <span className="text-[12px] text-[green]">
                                        Verified
                                    </span>
                                </div>
                            )} */}
                            {verified == 0 ? (
                                <button
                                    type="button"
                                    onClick={handleidVerificationRedirect}
                                    className="font-semibold px-[15px] py-2 bg-[#F4F4F4] text-[#151415] min-w-[85px] rounded-md text-[12px]"
                                >
                                    <span className="text-[12px]">Set</span>
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    onClick={handleidVerificationRedirect}
                                    className="font-semibold px-[15px] py-2 bg-[#F4F4F4] text-[#151415] min-w-[85px] rounded-md text-[12px]"
                                >
                                    <span className="text-[12px]">View</span>
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div
                        className={`bg-gradient-to-r py-4 from-[#f5d577] via-white  to-[#f5d577] rounded-t-xl  h-[100px] flex flex-col justify-center items-center ${
                            kycNotVerified ? "opacity-0" : "opacity-100"
                        } `}
                    >
                        <div className="inline-flex gap-2">
                            <img src={verifiedTick} alt="" />
                            <p className="text-[#01BC8D]">ID Verification</p>
                        </div>
                        <div className=" inline-flex gap-2">
                            <img src={yellowTick} alt="" />
                            <p className="text-[14px]">
                                Personalized Verification Approved
                            </p>
                        </div>
                        <button className="font-medium bg-[#E6E6E6] hover:bg-yellow-500 hover:text-white px-4 rounded-lg ">
                            View
                        </button>
                    </div>
                )}

                <div className="py-[20px] px-4 border-t flex items-center justify-between">
                    <div className="flex items-center gap-32">
                        <div className="flex items-center gap-4">
                            <div>
                                <BsGem size={20} />
                            </div>
                            <div className="font-semibold text-[14px]">
                                VIP Level
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-10">
                        <div className="flex gap-2 items-center text-[#EFB81C]">
                            <span>
                                <BsGem size={10} />
                            </span>
                            <span className="text-[12px]">
                                {usertype == 0
                                    ? "Regular User"
                                    : usertype == 1
                                    ? "VIP User"
                                    : "Unknown User"}
                            </span>
                        </div>
                        <div>
                            <button
                                type="button"
                                onClick={() => {}}
                                className={`font-semibold px-[15px] py-2 bg-[#F4F4F4] text-[#151415] min-w-[85px] rounded-md text-[12px]`}
                            >
                                View
                                <span>
                                    <Tooltip id="disabled-vip-level-tooltip" />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                {/* <div className="py-[20px] px-4 border-t flex items-center justify-between">
                    <div className="flex items-center gap-32">
                        <div className="flex items-center gap-4">
                            <div>
                                <BsGem size={20} />
                            </div>
                            <div className="font-semibold text-[14px]">
                                VIP Level
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-10">
                        <div className="flex gap-2 items-center text-[#EFB81C]">
                            <span>
                                <BsGem size={10} />
                            </span>
                            <span className="text-[12px]">Regular User</span>
                        </div>
                        <div>
                            <button
                                type="button"
                                onClick={() => {}}
                                className={`font-semibold px-[15px] py-2 bg-[#F4F4F4] text-[#151415] min-w-[85px] rounded-md text-[12px] ${
                                    kycNotVerified
                                        ? "cursor-not-allowed text-[#15141520]"
                                        : ""
                                }`}
                                {...(kycNotVerified ? "disabled" : "")}
                                data-tooltip-id="disabled-vip-level-tooltip"
                                data-tooltip-place="left"
                                data-tooltip-content={`${
                                    kycNotVerified
                                        ? "Please Complete the KYC to get VIP level"
                                        : ""
                                }`}
                            >
                                View
                                <span>
                                    <Tooltip id="disabled-vip-level-tooltip" />
                                </span>
                            </button>
                        </div>
                    </div>
                </div> */}

                {/* third line  */}

                <div className="py-[20px] px-4 border-t flex items-center justify-between">
                    <div className="flex items-center gap-14">
                        <div className="flex items-center gap-4">
                            <div>
                                <BsShieldLockFill size={20} />
                            </div>
                            <div className="font-semibold text-[14px]">
                                Security Level
                            </div>
                        </div>
                        <div
                            className={`flex items-center gap-3 ${
                                loading ? "opacity-0" : "opacity-100"
                            }`}
                        >
                            {checkSecurityLevel(
                                emailNotVerified,
                                phoneNotVerified,
                                g2faNotVerified
                            )}
                        </div>
                    </div>
                    <div className="flex items-center gap-10">
                        <div
                            className={`flex items-center gap-1 ${
                                loading ? "opacity-0" : "opacity-100"
                            }`}
                        >
                            {getStatus(
                                emailNotVerified,
                                phoneNotVerified,
                                g2faNotVerified
                            )}
                        </div>
                        <button
                            type="button"
                            onClick={handleSecuritySettingsRedirect}
                            className="font-semibold px-[15px] py-2 bg-[#F4F4F4] text-[#151415] min-w-[85px] rounded-md text-[12px]"
                        >
                            View
                        </button>
                    </div>
                </div>
                {/* Fourth line  */}
                {kycStatus?.kycStatus?.data?.verified === 1 ? (
                    <div className="py-3 px-4 border-t flex items-center justify-between">
                        <div className="flex items-center gap-32">
                            <div className="flex items-center gap-4">
                                <FaFileCircleCheck className="text-2xl" />
                                <h1 className="font-semibold text-[14px]">
                                    P2P Proflie{" "}
                                </h1>
                            </div>
                        </div>
                        <div>
                            <Link to="/trade/p2p">
                                <button
                                    type="button"
                                    className="font-semibold px-[15px] py-2 bg-[#F4F4F4] text-[#151415] min-w-[85px] rounded-md text-[12px]"
                                >
                                    View
                                </button>
                            </Link>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {/* Fifth line  */}
                <div className="py-[20px] px-4 border-t flex items-center justify-between">
                    <div className="flex items-center gap-32">
                        <div className="flex items-center gap-4">
                            <div>
                                <BsSliders size={20} />
                            </div>
                            <div className="font-semibold text-[14px]">
                                Advancements
                            </div>
                        </div>
                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={handleAdvancementsRedirect}
                            className="font-semibold px-[15px] py-2 bg-[#F4F4F4] text-[#151415] min-w-[85px] rounded-md text-[12px]"
                        >
                            Set
                        </button>
                    </div>
                </div>
            </div>

            {/* Login status management */}
            <LoginStatusManagement />

            {/* Login history management */}
            <LoginHistory />
        </div>
    );
};

export default AccountOverview;
