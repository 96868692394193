import { RepositoryFactory } from "repository/RepositoryFactory";
import { toast } from "react-hot-toast";
import {
    FETCH_P2P_DATA,
    FETCH_P2P_DATA_ERROR,
    FETCH_P2P_DATA_LOADING,
    FETCH_TRADE_STATUS_REQUEST,
    FETCH_TRADE_STATUS_SUCCESS,
    FETCH_TRADE_STATUS_FAILURE,
} from "../types";
import { useSelector } from "react-redux";

const p2pRepository = RepositoryFactory.get("p2p");
export const fetchp2pData =
    (type, coin, fiatValue, userid, usertoken,fiat_qty,pm) => async (dispatch) => {
        dispatch(fetchP2pDataLoading(true));
        try {
            const response = await p2pRepository.getP2PInfo(
                type,
                coin,
                fiatValue, userid, usertoken,fiat_qty,pm
            );
            const { data } = response;
            console.log("P2P>>>>>",data)
            if (data.status === 1) {
                dispatch({ type: FETCH_P2P_DATA, payload: data.data });
                // toast.success("P2p data fetched successfully!");
            } else {
                dispatch({ type: FETCH_P2P_DATA_ERROR, payload: data.detail });
                toast.error(data.detail);
                // console.log("error in data.status");
            }
        } catch (error) {
            dispatch({ type: FETCH_P2P_DATA_ERROR, payload: error.message });
            // toast.error("Error fetching p2p data");
        } finally {
            dispatch(fetchP2pDataLoading(false));
        }
    };

export const fetchP2pDataLoading = (val) => {
    return { type: FETCH_P2P_DATA_LOADING, payload: val };
};

export const fetchMyAds = (userID, userToken, payload) => async (dispatch) => {
    dispatch({ type: "FETCH_MY_ADS_REQUEST" });

    try {
        console.log("Fetching ads with payload:", payload);
        const response = await p2pRepository.getMyAds(userID, userToken, payload);

        console.log("API Response:", response);
        if (response.data.status === 1) {
            console.log("Success! Dispatching FETCH_MY_ADS_SUCCESS with data:", response.data.data);
            dispatch({
                type: "FETCH_MY_ADS_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: response.data.data };
        } else {
            console.log("My Ads Failure: ", response.data.message);
            dispatch({
                type: "FETCH_MY_ADS_FAILURE",
                payload: response.data.message,
            });
        }
    } catch (error) {
        console.log("My Ads Error: ", error.message);
        dispatch({ type: "FETCH_MY_ADS_FAILURE", payload: error.message });
    }
};

export const SellTrade = (userId, token,payload) => async (dispatch) => {
    dispatch({type: FETCH_TRADE_STATUS_REQUEST});
    try {
        const response = await p2pRepository.SellTrade(userId, token , payload);
        const res = response.data.data
        const rese = response.data.data.msg
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_TRADE_STATUS_SUCCESS",
                payload: response.data.data,
            });
            toast.success(rese)
            return { success: true, orderid: res.order.orderid };
        } else {
            dispatch({
                type: "FETCH_TRADE_STATUS_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)
            console.log(res);
            
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_TRADE_STATUS_FAILURE", payload: error.message });
        return false;
    }
};

export const DelOrder = (userID, userToken,payload) => async (dispatch) => {
    dispatch({type: FETCH_TRADE_STATUS_REQUEST});
    try {
        const response = await p2pRepository.DelOrder(userID, userToken , payload);
        const res = response.data.data
        const rese = response.data.data
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_TRADE_STATUS_SUCCESS",
                payload: response.data.data,
            });
            toast.success(rese)
            return { success: true, orderid: res.order.orderid };
        } else {
            dispatch({
                type: "FETCH_TRADE_STATUS_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)
            console.log(res);
            
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_TRADE_STATUS_FAILURE", payload: error.message });
        return false;
    }
};
export const ActiveAd = (userID, userToken,payload) => async (dispatch) => {
    dispatch({type: FETCH_TRADE_STATUS_REQUEST});
    try {
        const response = await p2pRepository.ActiveAd(userID, userToken , payload);
        const res = response.data.data
        const rese = response.data.data
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_TRADE_STATUS_SUCCESS",
                payload: response.data.data,
            });
            toast.success(rese)
            return { success: true, orderid: res.order.orderid };
        } else {
            dispatch({
                type: "FETCH_TRADE_STATUS_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)
            console.log(res);
            
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_TRADE_STATUS_FAILURE", payload: error.message });
        return false;
    }
};

export const grabPrice = (userID, userToken,payload) => async (dispatch) => {
    dispatch({type: FETCH_TRADE_STATUS_REQUEST});
    try {
        const response = await p2pRepository.grabPrice(userID, userToken , payload);
        const res = response.data.data
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_TRADE_STATUS_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: res };
        } else {
            dispatch({
                type: "FETCH_TRADE_STATUS_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)
            console.log(res);
            
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_TRADE_STATUS_FAILURE", payload: error.message });
        return false;
    }
};

export const AddPaymentMethod = (userId, token,payload) => async (dispatch) => {
    dispatch({type: FETCH_TRADE_STATUS_REQUEST});
    try {
        const response = await p2pRepository.AddPaymentMethod(userId, token , payload);
        const res = response.data.data
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_TRADE_STATUS_SUCCESS",
                payload: response.data.data,
            });
            toast.success(res)
            return true;
        } else {
            dispatch({
                type: "FETCH_TRADE_STATUS_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)
            console.log(res);
            
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_TRADE_STATUS_FAILURE", payload: error.message });
        return false;
    }
};


export const doNewAd = (userId, token,payload) => async (dispatch) => {
    dispatch({type: FETCH_TRADE_STATUS_REQUEST});
    try {
        const response = await p2pRepository.doNewAd(userId, token , payload);
        const res = response.data.data
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_TRADE_STATUS_SUCCESS",
                payload: response.data.data,
            });
            toast.success(res)
            return true;
        } else {
            dispatch({
                type: "FETCH_TRADE_STATUS_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_TRADE_STATUS_FAILURE", payload: error.message });
        return false;
    }
};

export const TradingExp = (userId, token,payload) => async (dispatch) => {
    dispatch({type: FETCH_TRADE_STATUS_REQUEST});
    try {
        const response = await p2pRepository.TradingExp(userId, token , payload);
        const res = response.data.data
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_TRADE_STATUS_SUCCESS",
                payload: response.data.data,
            });
            toast.success(res)
            return true;
        } else {
            dispatch({
                type: "FETCH_TRADE_STATUS_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_TRADE_STATUS_FAILURE", payload: error.message });
        return false;
    }
};

export const delpm = (userId, token,payload,onSuccess) => async (dispatch) => {
    dispatch({ type: FETCH_TRADE_STATUS_REQUEST });

    try {
        const api = await p2pRepository.delpm(userId, token,payload);
        const response = api.data.data;

        if (api.data.status === 1) {
            dispatch({ type: FETCH_TRADE_STATUS_REQUEST, payload: response });
            toast.success(response)
            onSuccess(api.data);
            return true;
        } else {
            dispatch({ type: FETCH_TRADE_STATUS_REQUEST, payload: response });
            toast.error(response)
        }
    } catch (error) {
        dispatch({ type: FETCH_TRADE_STATUS_REQUEST, payload: error.message });
    }
}

export const markAsPaid = (userId, token,payload) => async (dispatch) => {
    dispatch({ type: FETCH_TRADE_STATUS_REQUEST });

    try {
        const api = await p2pRepository.markAsPaid(userId, token,payload);
        const response = api.data.data;

        if (api.data.status === 1) {
            dispatch({ type: FETCH_TRADE_STATUS_REQUEST, payload: response });
            return true;
        } else {
            dispatch({ type: FETCH_TRADE_STATUS_REQUEST, payload: response });
        }
    } catch (error) {
        dispatch({ type: FETCH_TRADE_STATUS_REQUEST, payload: error.message });
    }
}

export const confirmCancel = (userId, token,payload) => async (dispatch) => {
    dispatch({ type: FETCH_TRADE_STATUS_REQUEST });

    try {
        const api = await p2pRepository.confirmCancel(userId, token,payload);
        const response = api.data.data;

        if (api.data.status === 1) {
            dispatch({ type: FETCH_TRADE_STATUS_REQUEST, payload: response });
            return true;
        } else {
            dispatch({ type: FETCH_TRADE_STATUS_REQUEST, payload: response });
        }
    } catch (error) {
        dispatch({ type: FETCH_TRADE_STATUS_REQUEST, payload: error.message });
    }
}

export const trade = (userId, token) => async (dispatch) => {
    dispatch({type: FETCH_TRADE_STATUS_REQUEST});
    try {
        const response = await p2pRepository.trade(userId, token);
        const res = response.data.data

        console.log('this is fuckong response: ', res)

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_TRADE_STATUS_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: res };
        } else {
            dispatch({
                type: "FETCH_TRADE_STATUS_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)            
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_TRADE_STATUS_FAILURE", payload: error.message });
        return false;
    }
};

export const newad = (userId, token) => async (dispatch) => {
    dispatch({type: FETCH_TRADE_STATUS_REQUEST});
    try {
        const response = await p2pRepository.newad(userId, token);
        const res = response.data.data
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_TRADE_STATUS_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: res };
        } else {
            dispatch({
                type: "FETCH_TRADE_STATUS_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)            
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_TRADE_STATUS_FAILURE", payload: error.message });
        return false;
    }
};
export const faq = (userId, token) => async (dispatch) => {
    dispatch({type: FETCH_TRADE_STATUS_REQUEST});
    try {
        const response = await p2pRepository.faq(userId, token);
        const res = response.data.data
        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_TRADE_STATUS_SUCCESS",
                payload: response.data.data,
            });
            return { success: true, data: res };
        } else {
            dispatch({
                type: "FETCH_TRADE_STATUS_FAILURE",
                payload: response.data.message,
            });
            toast.error(res)            
            return false;
        }
    } catch (error) {
        dispatch({ type: "FETCH_TRADE_STATUS_FAILURE", payload: error.message });
        return false;
    }
};

export const MerchantApply = (userId, token) => async (dispatch) => {
    dispatch({ type: "SET_BIT_REQUEST" }); // Start loading
    try {
        const response = await p2pRepository.MerchantApply(userId, token);
        const res = response.data.data;

        if (response.data.status === 1) {
            dispatch({
                type: "FETCH_TRADE_STATUS_SUCCESS",
                payload: res,
            });
            dispatch({ type: "SET_BIT_SUCCESS" }); // Set bit to 1
            return response;
        } else {
            dispatch({
                type: "FETCH_TRADE_STATUS_FAILURE",
                payload: response.data.message,
            });
            dispatch({ type: "SET_BIT_FAILURE", payload: response.data.message }); // Set bit to 0 with error
            toast.error(res);
            return response;
        }
    } catch (error) {
        dispatch({
            type: "FETCH_TRADE_STATUS_FAILURE",
            payload: error.message,
        });
        dispatch({ type: "SET_BIT_FAILURE", payload: error.message }); // Set bit to 0 with error
        return false;
    }
};
