import React, { useEffect } from "react";
import { Modal, Input, Form } from "antd";
import { CgLoadbar } from "react-icons/cg";
import { IoMdInformationCircle } from "react-icons/io";
import styled, { createGlobalStyle } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { bank, getbank } from "store/actions/userSecuritySettingsAction";
import { AddPaymentMethod } from "store/actions/p2pActions";

function BankTransfer({
    isModalVisible,
    BankTransferhandleCancel,
    BankTransferhandleOk,
    selectedMethods
}) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;

    const onFinish = async (values) => {
        const isBasicFieldsOnly = ["Cash Deposit", "Paypal", "UPI"].includes(selectedMethods);
        let methodType;

        if (isBasicFieldsOnly) {
            if (selectedMethods === "Cash Deposit") {
                methodType = "cdm";
            } else if (selectedMethods === "Paypal") {
                methodType = "paypal";
            } else if (selectedMethods === "UPI") {
                methodType = "upi";
            }

            const payload = {
                method: methodType,
                information: values.bankcard,
            };
            const success = await dispatch(AddPaymentMethod(userId, token, payload));
            if (success) {
                form.resetFields();
                BankTransferhandleOk(); // Call OK handler
                setTimeout(() => {
                    getbank(userId, token);
                }, 2000);
            }
        } else {
            const payload = {
                name: values.name,
                bankcard: values.bankcard,
                bank: values.bank,
                bankaddr: values.bankaddr,
            };
            const success = await dispatch(bank(userId, token, payload));
            if (success) {
                form.resetFields();
                BankTransferhandleOk(); // Call OK handler
                setTimeout(() => {
                    getbank(userId, token);
                }, 2000);
            }
        }
    };

    return (
        <StyledModal
            title={`Set my payment method`}
            visible={isModalVisible}
            onCancel={BankTransferhandleCancel}
            footer={null} // No default footer
            maskClosable={false}
        >
            <div
                className="flex items-baseline p-4 mb-4 text-sm text-[#EFB81C] rounded-lg bg-[#D89F0014] dark:bg-[#EFB81C] dark:text-[#EFB81C]"
                role="alert"
            >
                <IoMdInformationCircle className="flex-shrink-0 w-3 h-3 me-3 text-[#D89F00]" />
                <span className="text-[12px] leading-normal">
                    Tips: The added payment method will be shown to the buyer during the transaction to accept fiat transfers. Please ensure that the information is correct, real, and matches your KYC information on Buycex.
                </span>
            </div>
            <div className="py-1 mb-2 cursor-pointer flex items-center">
                <CgLoadbar className="rotate-90 text-[#D89F00] text-xl" />
                <h3 className="text-md font-semibold ml-2">
                    {["Cash Deposit", "Paypal", "UPI"].includes(selectedMethods) ? selectedMethods : "Bank Transfer"}
                </h3>
            </div>
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                    label="Bank Card/Account Number"
                    name="bankcard"
                    rules={[{ required: true, message: "Bank Card/Account Number is required" }]}
                >
                    <StyledInput placeholder="Please enter your bank account/card number" />
                </Form.Item>

                {!["Cash Deposit", "Paypal", "UPI"].includes(selectedMethods) && (
                    <>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: "Name is required" }]}
                        >
                            <StyledInput placeholder="Enter Your Name" />
                        </Form.Item>

                        <Form.Item
                            label="Bank Name"
                            name="bank"
                            rules={[{ required: true, message: "Bank Name is required" }]}
                        >
                            <StyledInput placeholder="Enter the name of your bank" />
                        </Form.Item>

                        <Form.Item
                            label="Account opening branch (Optional)"
                            name="bankaddr"
                        >
                            <StyledInput placeholder="Enter bank branch information" />
                        </Form.Item>
                    </>
                )}
                <div className="flex w-full gap-2">
                    <button
                        className="w-1/2 text-center bg-gray-200 hover:bg-gray-100 hover:text-black py-3 rounded-md font-medium"
                        onClick={BankTransferhandleCancel}
                        type="button"
                    >
                        Cancel
                    </button>
                    <button
                        className="w-1/2 text-center bg-[#EFB81C] hover:text-black py-3 rounded-md font-medium"
                        type="submit"
                    >
                        Confirm
                    </button>
                </div>
            </Form>
            <GlobalStyle />
        </StyledModal>
    );
}

const StyledInput = styled(Input)`
    height: 48px;
    padding: 0 12px;
    font-size: 14px;
`;

const GlobalStyle = createGlobalStyle`
  .ant-form-item-required::before {
    content: none !important;
  }
  .ant-form-item-explain-error {
    font-size: 12px !important;
    color: #CF304A !important;
    padding-bottom: 20px;
  }
  .ant-form-item-label {
    padding: 0 0 4px !important;
  }
  .ant-input:focus {
    border-color: #EFB81C !important;
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important;
  }
  .ant-input:hover {
    border-color: #EFB81C !important;
  }
`;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        width: 600px !important;
        max-width: 600px !important;
    }
    .ant-modal {
        width: 600px !important;
        max-width: 600px !important;
        display: flex;
        justify-content: center;
        pointer-events: auto !important;
        z-index: 1050 !important;
    }
    .ant-modal-wrap {
        pointer-events: none !important;
    }
    div[tabindex="-1"] {
        display: flex;
        outline: none;
        justify-content: center;
        align-items: center;
    }
`;

export default BankTransfer;