// orderBookReducer.js
import {
    FETCH_ORDER_BOOK_REQUEST,
    FETCH_ORDER_BOOK_SUCCESS,
    FETCH_ORDER_BOOK_FAILURE,
    FETCH_MARKET_TRADES_REQUEST,
    FETCH_MARKET_TRADES_SUCCESS,
    FETCH_MARKET_TRADES_FAILURE
} from '../types';

const initialState = {
    orderBook: { bids: [], asks: [] },
    marketTrades: [],
    loading: false,
    error: null
};

const orderBookReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDER_BOOK_REQUEST:
            return { ...state, loading: true };
        case FETCH_ORDER_BOOK_SUCCESS:
            return { ...state, orderBook: action.payload, loading: false, error: null };
        case FETCH_ORDER_BOOK_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case FETCH_MARKET_TRADES_REQUEST:
            return { ...state, loading: true };
        case FETCH_MARKET_TRADES_SUCCESS:
            return { ...state, marketTrades: action.payload, loading: false, error: null };
        case FETCH_MARKET_TRADES_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default orderBookReducer;
