import React, { useState,useEffect } from 'react';
import { Modal, Button, Input } from 'antd';
import CoinSelectDeposit from './CoinSelectDeposit';
import NetworkSelectDepositModal from './NetworkSelectDepositModal';
import { FaAngleDown } from "react-icons/fa6";
import { CiCircleInfo } from "react-icons/ci";
import { GoDotFill } from "react-icons/go";
import QRCode from 'qrcode.react';
import { FaCaretDown } from "react-icons/fa";
import { MdOutlineContentCopy } from "react-icons/md";
import toast from "react-hot-toast";

const dummyCoins = [
    { value: "btc", label: "Bitcoin", icon: "https://cryptoicons.org/api/icon/btc/32" },
    { value: "eth", label: "Ethereum", icon: "https://cryptoicons.org/api/icon/eth/32" },
    { value: "ltc", label: "Litecoin", icon: "https://cryptoicons.org/api/icon/ltc/32" },
    { value: "xrp", label: "Ripple", icon: "https://cryptoicons.org/api/icon/xrp/32" },
];

const dummyNetworks = [
    { value: "erc20", label: "Ethereum (ERC20)" },
    { value: "bep20", label: "Binance Smart Chain (BEP20)" },
    { value: "trc20", label: "Tron (TRC20)" },
    { value: "sol", label: "Solana (SOL)" },
    { value: "polygon", label: "Polygon (MATIC)" },
    { value: "avalanche", label: "Avalanche (AVAX)" },
];

const CryptoDepositModal = ({ visible, onClose }) => {
    const [selectedCrypto, setSelectedCrypto] = useState(null);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [showQRCode, setShowQRCode] = useState(false);
    const qrCodeValue = "1234567890";

    const handleImageClick = (coin) => {
        console.log(`Clicked on ${coin.label}`);
    };

    const handleAcknowledgeClick = () => {
        setShowQRCode(true); 
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.success('Copied Address!');  
        }).catch(() => {
            toast.error('Failed to copy!');  
        });
    };

    const [modalWidth, setModalWidth] = useState('30%');

    useEffect(() => {
        const updateWidth = () => {
            if (window.innerWidth >= 1024) {
                setModalWidth(selectedNetwork ? '50%' : '30%');
            }else if (window.innerWidth >= 768) {
                setModalWidth(selectedNetwork ? '50%' : '50%');
            }
            
            else {
                setModalWidth('100%');
            }
        };

        window.addEventListener('resize', updateWidth);
        updateWidth(); 

        return () => window.removeEventListener('resize', updateWidth);
    }, [selectedNetwork]);

    return (
        <Modal
            title={
                <div className='flex w-full justify-between items-center'>
                    <p className="text-lg font-semibold">Deposit Crypto</p>
                    <button className='mr-6 bg-[#f3f5f7] px-4 py-1 rounded-md font-medium text-sm'>Deposit Fiat</button>
                </div>
            }
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>
                    Close
                </Button>,
            ]}
            width={modalWidth}
        >
            <div className='flex gap-5 w-full'>
                <div className="flex flex-col w-full gap-6 justify-between self-start">
                    <div className="w-full mt-5">
                        <CoinSelectDeposit
                            coins={dummyCoins}
                            selectedCrypto={selectedCrypto}
                            setSelectedCrypto={setSelectedCrypto}
                            handleImageClick={handleImageClick}
                        />
                    </div>
                    <div className="w-full mt-1">
                        <NetworkSelectDepositModal
                            networks={dummyNetworks}
                            selectedNetwork={selectedNetwork}
                            setSelectedNetwork={setSelectedNetwork}
                        />
                    </div>
                    {selectedNetwork && !showQRCode && ( 
                        <div className='bg-[#f3f5f7] h-32'>
                            <div className='flex gap-2 mt-5'>
                                <CiCircleInfo className='relative self-start mt-[-5px] top-0' size={30} />
                                <p className="block mb-1 text-xs font-normal text-[#121214] dark:text-white">Please confirm that you are depositing BTC to this address on the BTC network. Mismatched address information may result in the permanent loss of your assets.</p>
                            </div>
                            <div className='flex justify-center'>
                                <button className='px-4 py-2 bg-[#efb81c] rounded-md font-semibold' onClick={handleAcknowledgeClick}>
                                    Acknowledge
                                </button>
                            </div>
                        </div>
                    )}

                    {showQRCode && (
                        <>
                            <div className='flex items-start w-full gap-4 bg-[#f3f5f7] py-6 px-4'>
                                <QRCode value={qrCodeValue} size={80} />
                                <div className='w-full'>
                                    <label className="block mb-1 text-xs font-normal text-[#81858c] dark:text-white">
                                        BTC Address
                                    </label>
                                    <div className='flex items-center bg-white p-2 rounded-md'>
                                        <div className='flex justify-between items-center w-full'>
                                            <p className='font-medium text-xs'>{qrCodeValue}</p><FaCaretDown />
                                        </div>
                                        <MdOutlineContentCopy onClick={() => copyToClipboard(qrCodeValue)}  className='text-[#efb81c] ml-2 cursor-pointer' />
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-between w-full'>
                                <p className='text-[#81858c] text-xs'>Minimum Deposit Amount</p>
                                <p className='text-xs font-medium'>0.000006 BTC</p>
                            </div>
                        </>
                    )}
                </div>

                {selectedNetwork && (
                    <div className='w-[60%]'>
                        <div className="mt-6 p-2 bg-[#f3f5f7] rounded-md">
                            <div className='mb-4'>
                                <p className="block mb-1 text-xs font-normal text-[#81858c] dark:text-white">Deposits auto channeled to</p>
                                <p className="mb-1 gap-1 text-xs font-medium text-black flex items-center dark:text-white">Funding<FaAngleDown className='opacity-40' /></p>
                            </div>
                            <div className='mb-4'>
                                <p className="block mb-1 text-xs font-normal text-[#81858c] dark:text-white">Deposit Arrival</p>
                                <p className="mb-1 gap-1 text-xs font-medium text-black flex items-center dark:text-white">1 confirmations</p>
                            </div>
                            <div className='mb-4'>
                                <p className="block mb-1 text-xs font-normal text-[#81858c] dark:text-white">Withdrawal Unlocked</p>
                                <p className="mb-1 gap-1 text-xs font-medium text-black flex items-center dark:text-white">2 confirmations</p>
                            </div>
                        </div>

                        <div className='h-52 overflow-y-auto border-b mb-2'>
                            <div className='flex gap-2 mt-5'>
                                <CiCircleInfo className='relative self-start mt-[-16px] top-0' size={50} />
                                <p className="block mb-1 text-xs font-normal text-[#121214] dark:text-white">In upholding the integrity and safety of our platform's trading environment, Bybit is dedicated to combating financial crime and ensuring adherence to anti-money laundering measures.</p>
                            </div>
                            <div className='flex gap-2 mt-5'>
                                <GoDotFill className='relative opacity-50 self-start mt-[-16px] top-0' size={50} />
                                <p className="block mb-1 text-xs font-normal text-[#121214] dark:text-white">The minimal deposit amount is 0.000006 BTC. If your deposited amount is less than the minimal requirement, the funds will not be added to your available balance — nor will it be refunded.</p>
                            </div>
                            <div className='flex gap-2 mt-5'>
                                <GoDotFill className='relative opacity-50 self-start mt-[-16px] top-0' size={50} />
                                <p className="block mb-1 text-xs font-normal text-[#121214] dark:text-white">Please make sure that only BTC deposit is made via this address. Otherwise, your deposited funds will not be added to your available balance — nor will it be refunded.</p>
                            </div>
                            <div className='flex gap-2 mt-5'>
                                <GoDotFill className='relative opacity-50 self-start mt-[-16px] top-0' size={50} />
                                <p className="block mb-1 text-xs font-normal text-[#121214] dark:text-white">Please make sure that your Bybit deposit address is correct. Otherwise, your deposited funds will not be added to your available balance — nor will it be refunded.</p>
                            </div>
                            <div className='flex gap-2 mt-5'>
                                <GoDotFill className='relative opacity-50 self-start mt-[-14px] top-0' size={45} />
                                <p className="block mb-1 text-xs font-normal text-[#121214] dark:text-white">If the current address is used, 1 block confirmations are required before your deposited funds can be added to your available balance.</p>
                            </div>
                            <div className='flex gap-2 mt-5'>
                                <GoDotFill className='relative opacity-50 self-start  top-0' size={15} />
                                <p className="block mb-1 text-xs font-normal text-[#121214] dark:text-white">BEP2 and BEP20 (BSC) deposits not supported.</p>
                            </div>
                            <div className='flex gap-2 mt-5'>
                                <GoDotFill className='relative opacity-50 self-start mt-[-8px] top-0' size={30} />
                                <p className="block mb-1 text-xs font-normal text-[#121214] dark:text-white">The deposit will be credited only after it has been confirmed on the blockchain.</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default CryptoDepositModal;
