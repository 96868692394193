import { getTradeData } from "repository/tradeRepository";
import {
    FETCH_DATA_REQUEST,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_FAILURE,
    SET_SELECTED_MAIN_FILTER,
    TOGGLE_THEME,
} from "../types";

export const setSelectedMainFilter = (filter) => ({
    type: SET_SELECTED_MAIN_FILTER,
    payload: filter,
});
const fetchDataRequest = () => ({
    type: FETCH_DATA_REQUEST,
});

const fetchDataSuccess = (data) => ({
    type: FETCH_DATA_SUCCESS,
    payload: data,
});

const fetchDataFailure = (error) => ({
    type: FETCH_DATA_FAILURE,
    payload: error,
});

export const fetchData = (marketId, token, userId) => {
    return async (dispatch) => {
        dispatch(fetchDataRequest());
        try {
            const response = await getTradeData(marketId, token, userId);
            console.log("API response:", response); // Debugging line
            const data = response.data; // Adjust if needed based on actual response
            dispatch(fetchDataSuccess(data));
        } catch (error) {
            console.error("Fetch data error:", error.message); // Debugging line
            dispatch(fetchDataFailure(error.message));
        }
    };
};
export const toggleTheme = () => ({
    type: TOGGLE_THEME,
});
