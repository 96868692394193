import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotCode, resendPhoneOtp, verifyPhone, verifyPhoneNew } from "store/actions/authAction";
import { Alert, Spinner } from "reactstrap";
import { FaMobile } from "react-icons/fa";
import { IoMdHelpCircleOutline } from "react-icons/io";
import toast from "react-hot-toast";
import YellowTick from "assets/img/yellow-bg-tick.png";
import PhoneInput from 'react-phone-input-2';
import i18nIsoCountries from 'i18n-iso-countries';
import PhoneVerification from "./helpers/phoneVerify";
import G2faVerification from "./helpers/G2faVerify";
import EmailVerification from "./helpers/EmailVerify";
import { getUserIP, getCountryCode } from '../../../../components/UserLocation/geolocationUtils'
import { fetchAuthStatus } from "store/actions/userSecuritySettingsAction";

i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const MobileChangeModal = ({ show, onClose, onSubmit, AlertMessage, closeAlert, setAlertMessage, SuccessMessage, email, username, cellphone="", userId, token  }) => {
    const [step, setStep] = useState(1);
    const [emailCode, setEmailCode] = useState('');
    const [otpCode, setOtpCode] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState();
    const [phoneOTP, setPhoneOTP] = useState('');
    const [resendDisabled, setResendDisabled] = useState(true);
    const [resendTimer, setResendTimer] = useState(60);
    const [defaultCountry, setDefaultCountry] = useState("");
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const dispatch = useDispatch();

    useEffect(() => {
        const initialize = async () => {
            const ip = await getUserIP();
            if (ip) {
                const countryCode = await getCountryCode(ip);
                if (countryCode) {
                    setDefaultCountry(countryCode);
                }
            }
        };

        initialize();
    }, []);

    useEffect(() => {
        if (show && step === 1) {
            let payload = {
                email: email,
            };
            dispatch(forgotCode(payload));
        }
    }, [dispatch, email, step, show]);

    useEffect(() => {
        const storedPassword = localStorage.getItem("password");
        if (storedPassword) setPassword(storedPassword);
    }, [])

    useEffect(() => {
        if (step === 4 && resendTimer > 0) {
            const timer = setInterval(() => {
                setResendTimer(prevTimer => prevTimer - 1);
            }, 1000);
    
            return () => clearInterval(timer);
        } else if (resendTimer === 0) {
            setResendDisabled(false);
        }
    }, [step, resendTimer]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const modal = document.querySelector(".modal-inner");
            if (modal && !modal.contains(event.target)) {
                onClose();
            }
            const outermodal = document.querySelector(".modal-outer");
            if (outermodal && !modal.contains(event.target)) {
                onClose();
            }
        };

        if (show) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show, onClose]);

    if (!show) return null;

    const handleNextStep = async () => {
        // Retrieve authUser data from local storage
        const authUser = JSON.parse(localStorage.getItem('authUser'));
    
        let user_id = null;
        if (authUser && authUser.user && authUser.user.ID) {
            user_id = authUser.user.ID; // Extract user ID
        } else {
            console.error("authUser is not in the expected format:", authUser);
        }
    
        // If step is 3, perform API call with both cellphone and user_id
        if (step === 3) {
            if (user_id) {
                try {
                    const response = await fetch("https://api.buycex.com/Login/sendCellphone", {
                        method: 'POST',
                        body: new URLSearchParams({
                            cellphone: phone,   // Sending the phone number
                            user_id: user_id    // Sending the user ID
                        }),
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    });
    
                    const data = await response.json();
    
                    if (data.status === 1) {
                        alert(data.data.code);
                        toast.success(data.data.msg);
                        setStep(step + 1); // Move to the next step
                    } else {
                        toast.error(data.data);
                    }
                } catch (error) {
                    toast.error("Error sending verification code");
                    console.error("Error sending cellphone:", error);
                }
            } else {
                toast.error("User ID not found in local storage.");
            }
        } else {
            setStep(step + 1); // For other steps, just move to the next step
        }
    };    

    const verifyOtp = async (e) => {
        e.preventDefault();
        const payload = {
            cellphone: phone,
            code: phoneOTP,
            // password,
        };

        try {
            await dispatch(verifyPhoneNew(payload, () => {
                // toast.success("Phone verified successfully");
                handleNextStep();
            }));
        } catch (error) {
            toast.error("Failed to verify phone");
            console.error("Error verifying phone:", error);
        }
    };

    const resendCode = async () => {
        // Retrieve authUser data from local storage
        const authUser = JSON.parse(localStorage.getItem('authUser'));
    
        let user_id = null;
        if (authUser && authUser.user && authUser.user.ID) {
            user_id = authUser.user.ID; // Extract user ID
        } else {
            console.error("authUser is not in the expected format:", authUser);
        }
    
        // If user_id is available, proceed with the API call
        if (user_id) {
            try {
                // Make API call to resend OTP with both cellphone and user_id
                const response = await fetch("https://api.buycex.com/Login/sendCellphone", {
                    method: 'POST',
                    body: new URLSearchParams({
                        cellphone: phone,     // Sending the phone number
                        user_id: user_id      // Sending the user ID
                    }),
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                });
    
                const data = await response.json();
    
                if (data.status === 1) {
                    alert(data.data.code);
                    toast.success("OTP sent successfully");
    
                    if (!resendDisabled) {
                        setResendTimer(60); // Start resend timer
                        setResendDisabled(true); // Disable resend button for 60 seconds
                    }
    
                    console.log("OTP sent successfully to:", phone);
                } else {
                    toast.error("Failed to resend OTP");
                }
            } catch (error) {
                toast.error("Error resending OTP");
                console.error("Error resending phone OTP:", error);
            }
        } else {
            toast.error("User ID not found in local storage.");
        }
    };    

    const handleCancel = () => {
        setStep(1);
        setEmailCode('');
        setOtpCode('');
        setPhone('');
        setPhoneOTP('');
        onClose();
            if (userID && userToken) {
                dispatch(fetchAuthStatus(userID, userToken));
            }
    };

    const formatPhone = (phone) => {
        const length = phone.length;
        if (length <= 5) return phone;
        const firstPart = phone.slice(0, 3);
        const lastPart = phone.slice(-2);
        return `${firstPart}****${lastPart}`;
    };

    return (
        <div className="fixed inset-0 modal-outer flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="relative modal-inner bg-white rounded-xl shadow-lg p-6 w-1/3">
                <button
                    onClick={handleCancel}
                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                {AlertMessage && (
                    <div className={`px-4 py-2 my-3 rounded ${AlertMessage.type === 'error' ? 'bg-red-200 text-red-800' : 'bg-green-200 text-green-800'}`}>
                        <div className="flex justify-between items-center">
                            <span>{AlertMessage.message}</span>
                            <button className="text-xl leading-none" onClick={closeAlert}>&times;</button>
                        </div>
                    </div>
                )}
                <div className="flex gap-2">
                    <FaMobile className=" text-yellow-500 text-2xl"/>
                    <h1 className="font-semibold text-2xl">Bind New Mobile</h1>
                </div>
                {/* Step Tracer */}
                <div className="flex mt-4 mb-8 items-center gap-2 relative">
                    <div className={`componentBorder rounded-xl text-center px-1 w-28 relative ${step >= 1 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">Email Verification</p>
                        <div className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-2 ${step >= 1 ? 'bg-yellow-500' : 'bg-gray-300'} transition-colors duration-300`}></div>
                    </div>
                    <div className={`componentBorder rounded-xl text-center px-1 w-12 relative ${step >= 2 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">2FA</p>
                        <div className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-2 ${step >= 2 ? 'bg-yellow-500' : 'bg-gray-300'} transition-colors duration-300`}></div>
                    </div>
                    <div className={`componentBorder rounded-xl text-center px-1 w-24 relative ${step >= 3 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">Bind New Mobile</p>
                        <div className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-2 ${step >= 3 ? 'bg-yellow-500' : 'bg-gray-300'} transition-colors duration-300`}></div>
                    </div>
                    <div className={`componentBorder rounded-xl text-center px-1 w-28 relative ${step >= 4 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">Mobile Verification</p>
                        <div className={`absolute top-1/2 left-full transform -translate-y-1/2 h-0.5 w-2 ${step >= 3 ? 'bg-yellow-500' : 'bg-gray-300'} transition-colors duration-300`}></div>
                    </div>
                    <div className={`componentBorder rounded-xl text-center px-1 w-12 relative ${step >= 5 ? 'componentBorderSelected' : ''}`}>
                        <p className="text-[8px]">Done</p>
                    </div>
                </div>

                {step === 1 && (
                    <EmailVerification onSuccess={handleNextStep}/>
                )}
                {step === 2 && (
                    <G2faVerification 
                        onSuccess={handleNextStep}
                        emailasprop={email}
                        type="update"
                        userId={userId}
                        token={token}
                    />
                )}
                {step === 3 && (
                    <div>
                        <div className=" flex flex-col mb-6 gap-4">
                            <h2 className=" font-medium text-lg text-left ">Mobile</h2>
                            <PhoneInput
                                country={defaultCountry}
                                value={phone}
                                onChange={phone => setPhone(phone)}
                                inputClass='w-full px-3 py-2 bg-gray-300 placeholder:text-gray-300 placeholder:text-[14px] focus:ring-0 ring-0 hover:border-[#F1BA19] focus:outline-none border border-[#151415] dark:border-[#F4F4F4] focus:border-red-400 rounded-md'
                                inputProps={{ name: 'phone', id: 'phone', autoComplete: 'phone', autoFocus: true }}
                                containerClass="w-full"
                                placeholder="Enter your phone"
                            />
                        </div>
                        <div className="flex justify-between">
                            <button className="bg-yellow-500 text-white py-2 px-4 rounded focus:outline-none w-full" onClick={handleNextStep}>Next</button>
                        </div>
                    </div>
                )}
                {step === 4 && (
                    <div>
                        <h2 className=" text-lg font-medium text-left mb-2">
                            SMS Code
                        </h2>
                        <input
                            type="text"
                            className="border bg-gray-100 rounded w-full p-2 mb-1 focus:outline-none"
                            value={phoneOTP}
                            onChange={(e) => setPhoneOTP(e.target.value)}
                            placeholder="Enter Verification Code"
                        />
                        <div className="flex justify-between mb-4">
                            <p className="text-[10px] text-gray-300">
                                {phone ? `Please enter the 6-digit TOTP code sent to ${formatPhone(phone)}` : 'Please enter the 6-digit TOTP code'}
                            </p>
                            <button
                                className={`text-yellow-500 bg-none outline-none focus:outline-none text-[10px] ${resendDisabled ? 'cursor-not-allowed' : ''}`}
                                onClick={resendCode}
                                disabled={resendDisabled}
                            >
                                Resend {resendDisabled ? `(${resendTimer}s)` : ''}
                            </button>
                        </div>
                        <div className="flex justify-between">
                            <button className="bg-yellow-500 text-white py-2 px-4 rounded focus:outline-none w-full" onClick={verifyOtp}>Next</button>
                        </div>
                </div>
                )}
                {step === 5 && (
                    <div>
                        <div className=" flex justify-center mb-2 items-center py-4"><img src={YellowTick} alt="success" className="mr-2 w-8 h-8"/><h2 className="text-lg font-medium">Mobile Number Changed Successfully</h2></div>
                        <button className="bg-yellow-500 text-white py-2 px-4 rounded focus:outline-none w-full" onClick={handleCancel}>Done</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MobileChangeModal;
