import React, { useState, useEffect } from "react";
import { FaUnlockAlt } from "react-icons/fa";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
import ReactivateAccountSecondPopup from "./reactivateAccountSecondPopup";
import { Link } from "react-router-dom";
import {
    confirm2faCodelogin,
    doActiveFreezeAccount,
    email2fa,
    login,
    reactiveEmailPasswordAuth,
    resendEmailOtp,
} from "store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

function ReactivateAccount({ onSuccess = null }) {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const [emailPassword, setEmailPassword] = useState("");
    const [g2aCode, setG2aCode] = useState(Array(6).fill(""));
    const [timer, setTimer] = useState({ minutes: 0, seconds: 59 });
    const [isCountdownComplete, setIsCountdownComplete] = useState(false);
    const userID = useSelector((state) => state.authUser?.user?.ID);
    console.log(userID);

    const dispatch = useDispatch();
    const [showWelcomePopup, setShowWelcomePopup] = useState(true);
    const [showPopup, setShowPopup] = useState(true);

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleEmailSignIn = () => {
        const payload = {
            username: email || "",
            password: emailPassword || "",
        };

        dispatch(
            reactiveEmailPasswordAuth(payload, (response) => {
                console.log("Response received in handleEmailSignIn:", response);
                handleLoginResponse(response);
            })
        );
    };

    const handleLoginResponse = (response) => {
        if (response && response.status == 1) {
            setStep(2);
            const payload = userID;

            dispatch(email2fa(payload));
            // toast.success("Please check your email for confirmation")
        } else {
            console.error("Login failed", response.error || "Unknown error");
        }
    };

    useEffect(() => {
        const countdown = setInterval(() => {
            if (timer.seconds === 0) {
                if (timer.minutes === 0) {
                    setIsCountdownComplete(true);
                    clearInterval(countdown);
                } else {
                    setTimer({ minutes: timer.minutes - 1, seconds: 59 });
                }
            } else {
                setTimer((prev) => ({ ...prev, seconds: prev.seconds - 1 }));
            }
        }, 1000);

        return () => clearInterval(countdown);
    }, [timer]);

    const handleResendCode = () => {
        if (isCountdownComplete) {
            resendCode();
        } else {
            toast.error(
                "Please wait until the timer finishes before resending the code."
            );
        }
    };

    const verifyG2faCodeHandler = () => {
        const payload = {
            user_id: userID,
            code: parseInt(g2aCode.join("")),
        };
    
        dispatch(
            doActiveFreezeAccount(payload, (response) => {
                console.log(response);
                
                if (response && response.status === 1) {
                    if (onSuccess) {
                        onSuccess();
                    }
                    setStep(3); // Move to next step upon success
                } else {
                    toast.error("2FA confirmation failed. Please try again.");
                }
            })
        );
    };    

    const resendCode = async () => {
        const payload = userID;

        try {
            await dispatch(email2fa(payload));
            setTimer({ minutes: 0, seconds: 59 });
            setIsCountdownComplete(false);
        } catch (error) {
            toast.error("Failed to resend OTP");
            console.error("Error resending email OTP:", error);
        }
    };

    const handleNextClick = () => {
        if (step === 1) {
            handleEmailSignIn(); // Step 1: Sign in with email/password
        } else if (step === 2) {
            verifyG2faCodeHandler(); // Step 2: Verify 2FA code
        }
    };

    useEffect(() => {
        setShowWelcomePopup(true);
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    return (
        <>
            <div className="container mt-16">
                <div className="flex gap-3 items-center justify-center">
                    <FaUnlockAlt className="text-[26px] text-[#EFB81C]" />
                    <p className="text-xl font-semibold">Reactivate Account</p>
                </div>
                <div className="flex items-center justify-center mt-5 mb-5 pl-[20%] pr-[20%]">
                    <div className="relative flex items-center justify-center rounded-full border-2 border-[#EFB81C]">
                        <span className="text-[12px] pl-2 pr-2">
                            Email Confirm
                        </span>
                    </div>
                    <div className="flex-1 h-[2px] bg-[#EFB81C]"></div>
                    <div
                        className={`relative flex items-center justify-center rounded-full border-2 ${
                            step >= 2 ? "border-[#EFB81C]" : "border-gray-300"
                        }`}
                    >
                        <span className="text-[12px] pl-2 pr-2">
                            Anti-Phishing Code Settings
                        </span>
                    </div>
                    <div
                        className={`flex-1 h-[2px] ${
                            step >= 3 ? "bg-[#EFB81C]" : "bg-gray-300"
                        }`}
                    ></div>
                    <div
                        className={`relative flex items-center justify-center rounded-full border-2 ${
                            step >= 3 ? "border-[#EFB81C]" : "border-gray-300"
                        }`}
                    >
                        <span className="text-[12px] pl-2 pr-2">
                            Set successfully
                        </span>
                    </div>
                </div>
                <div className="bg-white p-8 rounded-xl shadow-lg relative pt-10 pl-[20%] pr-[20%]">
                    {step === 1 && (
                        <div>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <span className="text-md font-semibold">
                                    Account
                                </span>
                                <Input
                                    placeholder="Enter email address"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    className="mt-2 h-12 mb-4"
                                />
                                <span className="text-md font-semibold">
                                    Password
                                </span>
                                <Input.Password
                                    placeholder="Enter password"
                                    onChange={(e) =>
                                        setEmailPassword(e.target.value)
                                    }
                                    value={emailPassword}
                                    className="mt-2 h-12"
                                    iconRender={(visible) =>
                                        visible ? (
                                            <EyeTwoTone />
                                        ) : (
                                            <EyeInvisibleOutlined />
                                        )
                                    }
                                />
                            </form>
                        </div>
                    )}
                    {step === 2 && (
                        <div>
                            <span className="text-md font-semibold">
                                Enter 2FA Code
                            </span>
                            <Input
                                className="mt-2"
                                placeholder="Enter Verification Code"
                                onChange={(e) =>
                                    setG2aCode(
                                        e.target.value.split("").slice(0, 6)
                                    )
                                }
                                value={g2aCode.join("")}
                                maxLength={6}
                                suffix={
                                    <div>
                                        <span
                                            className="text-sm cursor-pointer text-[#EFB81C]"
                                            onClick={handleResendCode}
                                        >
                                            Resend code
                                        </span>
                                        <span className="text-[#918f8f]">
                                            {isCountdownComplete
                                                ? ""
                                                : ` ${timer.minutes
                                                      .toString()
                                                      .padStart(
                                                          2,
                                                          "0"
                                                      )}:${timer.seconds
                                                      .toString()
                                                      .padStart(2, "0")}`}
                                        </span>
                                    </div>
                                }
                                style={{ height: "50px" }}
                            />
                        </div>
                    )}

                    {step === 3 && (
                        <div className="flex mt-2">
                            <div className="flex gap-3">
                                <RiCheckboxCircleFill className="text-[56px] text-[#EFB81C]" />
                                <div className="flex flex-col">
                                    <p className="text-xl font-semibold">
                                        Your account reactivation request has
                                        been successfully submitted.
                                    </p>
                                    <p className="mt-2 text-sm text-[#9C9C9C]">
                                        Please allow up to 7 business days for
                                        your review to be completed. Upon
                                        finalization, you will receive a
                                        notification via email.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex justify-center mt-20">
                        {step === 3 ? (
                            <Link to="/user/account/security">
                                <button
                                    type="button"
                                    className="focus:outline-none w-60 mt-3 text-black bg-[#EFB81C] hover:bg-[#EFB81C] font-medium rounded-md text-md px-5 py-2 mb-2"
                                >
                                    Back to Settings
                                </button>
                            </Link>
                        ) : (
                            <button
                                type="button"
                                onClick={handleNextClick}
                                className="focus:outline-none w-60 mt-3 text-black bg-[#EFB81C] hover:bg-[#EFB81C] font-medium rounded-md text-md px-5 py-2 mb-2"
                            >
                                Next
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {showPopup && (
                <ReactivateAccountSecondPopup onClose={handleClosePopup} />
            )}
        </>
    );
}

export default ReactivateAccount;