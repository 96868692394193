import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchAuthStatus,
    set2FA,
} from "store/actions/userSecuritySettingsAction";
import { IoMdHelpCircleOutline } from "react-icons/io";
import toast from "react-hot-toast";

const G2faVerification = ({
    onSuccess,
    emailasprop,
    handleSwitchVerificationTool,
    type = "",
    userId,
    token,
}) => {
    const [g2aCode, setG2aCode] = useState("");
    const [ga, setga] = useState("");
    // const state = useSelector(state)
    const Secret = useSelector((state) => state?.get2FAQR?.data?.Asecret);
    const state = useSelector((state) => state);
    console.log(state);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAuthStatus(userId, token)).then((response) => {
            console.log("\n\n this is the response: ", response);
            const gaData = response?.ga?.split("|")[0];
            setga(gaData);
        });
    }, [dispatch, token, userId]);
    const verifyG2faCodeHandler = (e) => {
        e.preventDefault();
        if (!g2aCode) {
            toast.error("Please enter the verification code.");
            return;
        }
        const secretValue = type === "add" ? Secret : ga;
        let payload = {
            ga: parseInt(g2aCode),
            type: type,
            ga_transfer: 0,
            ga_login: 1, //according to api doc: 1=2fa Required at login, 0 =2fa Not required at login
            userId: userId,
            token: token,
            secret: secretValue,
        };
        console.log(payload);
        dispatch(
            set2FA(payload, () => {
                onSuccess();
            })
        );
    };

    return (
        <div className="bg-white flex flex-col gap-2 h-max text-[#000] py-4">
            <div className="mb-2 mr-auto">
                <h1 className="inline-flex items-center gap-1 text-xl font-bold">
                    <p className="text-2xl font-semibold">
                        Verify Authenticator
                    </p>
                    <div className="tooltip-container">
                        <IoMdHelpCircleOutline className="fill-gray-600" />
                        <span className="tooltip-text">
                            OTP is a one-time six-digit verification code.
                            Commonly used OTP authenticators: Google
                            Authenticator
                        </span>
                    </div>
                </h1>
            </div>
            <p className="text-sm text-[#81858c] text-start">
                Enter the 6-digit code generated by the Authenticator App.
            </p>
            <form onSubmit={verifyG2faCodeHandler} className="w-full">
                <div className="flex justify-center gap-4 my-4">
                    {Array(6)
                        .fill("")
                        .map((_, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                value={g2aCode[index] || ""}
                                onChange={(e) => {
                                    const newCode = g2aCode.split("");
                                    newCode[index] = e.target.value;
                                    setG2aCode(newCode.join(""));

                                    // Move focus to the next input if a digit is entered
                                    if (e.target.value && index < 5) {
                                        document
                                            .getElementById(
                                                `input-${index + 1}`
                                            )
                                            .focus();
                                    }
                                    // Move focus to the previous input if backspace is pressed
                                    else if (!e.target.value && index > 0) {
                                        document
                                            .getElementById(
                                                `input-${index - 1}`
                                            )
                                            .focus();
                                    }
                                }}
                                className={`border border-slate-400 w-[50px] h-[50px] text-center rounded-md bg-gray-200 focus:bg-transparent focus:outline-none ${
                                    g2aCode[index] ? "bg-slate-100" : ""
                                }`}
                                id={`input-${index}`} // Assigning unique IDs to each input for focus management
                            />
                        ))}
                </div>
                <div className="flex justify-between">
                    {handleSwitchVerificationTool && (
                        <button
                            className="text-yellow-500 text-[10px] border-none outline-none focus:outline-none bg-transparent"
                            onClick={handleSwitchVerificationTool}
                        >
                            switch verification tool
                        </button>
                    )}
                </div>
                <button
                    type="submit"
                    className="btn font-semibold text-black text-[14px] py-2 mt-6 w-full bg-[#F1BA19] focus:outline-none"
                >
                    Submit
                </button>
            </form>

            <style jsx>{`
                .tooltip-container {
                    position: relative;
                    display: inline-block;
                }

                .tooltip-text {
                    visibility: hidden;
                    width: 300px;
                    background-color: #555;
                    color: #fff;
                    text-align: center;
                    border-radius: 5px;
                    padding: 5px;
                    position: absolute;
                    z-index: 1;
                    bottom: 125%;
                    left: 50%;
                    margin-left: -20px;
                    opacity: 0;
                    transition: opacity 0.3s;
                }

                .tooltip-container:hover .tooltip-text {
                    visibility: visible;
                    opacity: 1;
                }
            `}</style>
        </div>
    );
};

export default G2faVerification;
