import {
    CHECK_CELLPHONE_REQUEST,
    CHECK_CELLPHONE_SUCCESS,
    CHECK_CELLPHONE_FAILURE,
    SEND_NEW_CELLPHONE_REQUEST,
    SEND_NEW_CELLPHONE_SUCCESS,
    SEND_NEW_CELLPHONE_FAILURE
} from "../types";  // Import action types from the types.js file


import authPhoneNoRepository from "repository/authPhoneNoRepository";

// Action to check cellphone
export const checkCellphone = (payload, token, id) => async (dispatch) => {
    dispatch({ type: CHECK_CELLPHONE_REQUEST });

    try {

        console.log("ayload ",payload)
        const response = await authPhoneNoRepository.checkCellphone(payload, token, id); 
     
        
        const { status, data } = response.data;
        console.log("dtaa",status, data)
        if (status === 1 && data) {
            dispatch({
                type: CHECK_CELLPHONE_SUCCESS,
                payload: data.available, // Assuming `available` is the data needed
            });
            console.log("response.data 1",response.data)
            return response.data;
        } else {
            dispatch({
                type: CHECK_CELLPHONE_FAILURE,
                payload: data || "Invalid response format",
            });
            console.log("response.data 2",response.data)
            return response.data;
        }
    } catch (error) {
        dispatch({
            type: CHECK_CELLPHONE_FAILURE,
            payload: error.message || "An unexpected error occurred",
        });
    }
};

// Action to send new cellphone number
export const sendNewCellphone = (newCellphone, token, id) => async (dispatch) => {
    dispatch({ type: SEND_NEW_CELLPHONE_REQUEST });

    try {
        const response = await authPhoneNoRepository.sendNewCellphone(newCellphone, token, id);
 

        const { status, data } = response.data;

        if (status === 1 && data && typeof data === "object") {
            dispatch({
                type: SEND_NEW_CELLPHONE_SUCCESS,
                payload: data, // Assuming `data` is the response data
            });
        } else {
            dispatch({
                type: SEND_NEW_CELLPHONE_FAILURE,
                payload: data || "Invalid response format",
            });
        }
    } catch (error) {
        dispatch({
            type: SEND_NEW_CELLPHONE_FAILURE,
            payload: error.message || "An unexpected error occurred",
        });
    }
};



export const updateCellphone = (cellphone, cellphoneVerify, token, id) => async (dispatch) => {
    dispatch({ type: SEND_NEW_CELLPHONE_REQUEST });

    try {
        // Make the API call
        const response = await authPhoneNoRepository.updateCellphone(cellphone, cellphoneVerify, token, id);

        const { status, data } = response.data;
        console.log("status and data", status, data); // Log to check the API response

        // Check status and dispatch accordingly
        if (status === 1) {
            dispatch({
                type: SEND_NEW_CELLPHONE_SUCCESS,
                payload: response.data, // Send the data to the reducer
            });
          
            // Return the data for the component
            return response.data; 
        } else {
            dispatch({
                type: SEND_NEW_CELLPHONE_FAILURE,
                payload: data || "Invalid response format",
            });

            // Throw error with the data message
            throw new Error(data || "Unexpected error occurred");
        }
    } catch (error) {
        dispatch({
            type: SEND_NEW_CELLPHONE_FAILURE,
            payload: error.message || "An unexpected error occurred",
        });

        // Throw error to reject the promise
        throw new Error(error.message || "Failed to update phone number");
    }
};




