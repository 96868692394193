import { useState, useEffect } from "react";
import axios from "axios";

import { useSelectedCoin } from "../context/SelectedCoinContext";

const useLastTrade = () => {
    const { selectedCoin } = useSelectedCoin();
    const [marketTrades, setMarketTrades] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMarketTrades = async () => {
            if (!selectedCoin?.ticker.toLowerCase().replace("/", "_")) return;

            try {
                const marketPair = selectedCoin?.ticker
                    .toLowerCase()
                    .replace("/", "_");
                const response = await axios.get(
                    `https://api.buycex.com/Exchange/lasttrades/market/${marketPair}`
                );
                const data = response.data;

                if (!data?.data) {
                    setError(
                        "No market trades data available for the selected coin."
                    );
                    setMarketTrades([]);
                    return;
                }

                setMarketTrades(data.data.tradelog || []);
                setError(null);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchMarketTrades();
        const intervalId = setInterval(fetchMarketTrades, 60000);

        return () => clearInterval(intervalId);
    }, [selectedCoin]);

    return { marketTrades, error };
};

export default useLastTrade;
