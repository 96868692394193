import React from 'react'

import  BG from 'assets/img/bg.jfif'
import  coin from 'assets/img/coin.png'
import  coin1 from 'assets/img/coin1.png'
function News() {
    const securityData = [
        {
            id: 1 ,
            title : "Lorem, ipsum dolor", 
            img :  BG,
            desc : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam deserunt fugiat placeat itaque. Eveniet unde totam necessitatibus sequi nisi laboriosam architecto veritatis id placeat praesentium. Magni eos magnam repellat tenetur!" ,

            
        },
        {
            id: 2 ,
            title : "Lorem, ipsum dolor", 
            img :  coin,
            desc : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam deserunt fugiat placeat itaque. Eveniet unde totam necessitatibus sequi nisi laboriosam architecto veritatis id placeat praesentium. Magni eos magnam repellat tenetur!", 

        },
        {
            id: 3 ,
            title : "Lorem, ipsum dolor", 
            img :  coin1,
            desc : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam deserunt fugiat placeat itaque. Eveniet unde totam necessitatibus sequi nisi lequi nisi lequi nisi lequi nisi lequi nisi laboriosam architecto veritatis id placeat praesentium. Magni eos magnam repellat tenetur!" ,

        },
         
    ]

  return (
   <>
        <section className="my-10">
            <div className="container">
                <h2 className="md:text-4xl/snug text-black dark:text-white text-3xl font-semibold  mb-3 ">
                    News
                </h2>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">


                {
  securityData.map((data) => (    

<div className="  border w-full h-full shadow dark:shadow-lg dark:shadow-slate-200 relative rounded-3xl   border-transparent  transition   ">
<img
    src= {data.img}
  alt="img"
    className="h-60 w-full rounded-t-3xl object-cover border border-b  "
/>
<div className=" py-3 px-3">
    <h6 className="font-extrabold text-2xl mb-3 text-[#EFB81C] ">
    {data.title}
    </h6>
    
    <p className="line-clamp-4 ">
    {data.desc}
    </p>
</div>
</div>








  ))
 
} 



                  
                   
                </div>
            </div>
        </section>
   </>
  )
}

export default News
