import React from 'react'
import { AiOutlineFileSearch } from "react-icons/ai";

function BlockedUsers() {
    return (
        <div>
            <div className='border-b pb-6'>
                <p className='text-[20px] font-medium'>Blocked Users</p>
            </div>
            <div className="h-52 flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                    <AiOutlineFileSearch className="text-[60px] text-[#707A8A]"/>
                    <p className="mt-3 text-[#474D57]">No Comments</p>
                    </div>
                </div>
        </div>
    )
}

export default BlockedUsers