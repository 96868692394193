import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import downloads from "assets/img/downloads.png";
import scanCode from "assets/img/scan-qr-code.jpg";
import { FaApple } from "react-icons/fa";
import { FaGooglePlay } from "react-icons/fa";
import { MdPhoneAndroid } from "react-icons/md";
import { TfiGallery } from "react-icons/tfi";
import { HiMiniBuildingStorefront } from "react-icons/hi2";
import { LiaTrashRestoreAltSolid } from "react-icons/lia";
import { VscQuestion } from "react-icons/vsc";
import { GrInstallOption } from "react-icons/gr";
import { BsQrCodeScan } from "react-icons/bs";

const Downloads = () => {
    return (
        <>
            <main className="h-full w-full py-20 bg-[#F4F4F4] dark:bg-[#000000] ">
                <div className="h-full w-full flex flex-col justify-between gap-16 items-start">
                    <div className="flex flex-col justify-between gap-3 items-start">
                        <h1 className="text-[40px] text-black dark:text-white font-semibold">
                            Start With Rewards
                        </h1>
                        <div className="flex flex-col-reverse justify-between md:flex-row items-start gap-4 w-full h-full">
                            {/* left */}
                            <div className="flex flex-col  justify-between md:w-[60%] gap-8 items-start w-full h-full">
                                <p className="text-lg font-semibold text-black dark:text-white">
                                    Download the Buycex app and get upto $5,020
                                    in wecome gifts!
                                </p>
                                <div className="flex flex-col justify-between md:flex-row items-start md:gap-4 gap-8 w-full h-full">
                                    {/* scan */}
                                    <div className="flex flex-col justify-center items-start gap-8 w-full h-full">
                                        <p className="text-base text-black dark:text-white">
                                            Scan to Download the Buycex app for
                                            IOS & Andriod(Phones & Tablet
                                            version available).
                                        </p>
                                        <BsQrCodeScan size={160} className='' />                                    
                                    </div>
                                    {/* ios */}
                                    <div className="flex gap-4 flex-col justify-between  items-start w-full h-full">
                                    <span className="text-base text-black font-bold dark:text-white">
                                    iOS
                                    </span>
                                        <Link
                                            to=" "
                                            class="hover:bg-[#EFB81C] w-[70%] bg-[#EFB81C] group flex gap-3 lg:min-w-[150px] min-w-[130px] justify-center rounded-xl items-center  py-2  "
                                        >
                                            <FaApple size={29} className="group-hover:text-white  text-[9px] text-black"/>
                                            <div class="flex flex-col  justify-between items-start">
                                                <span class=" group-hover:text-white   text-[9px] text-black ">
                                                    Download on the
                                                </span>
                                                <span class="text-black  text-sm group-hover:text-white  text-[9px]">
                                                    App store
                                                </span>
                                            </div>
                                        </Link>
                                        <Link
                                            to=" "
                                            class=" dark:bg-[#1A191B] border-2 w-[70%] lg:min-w-[150px] min-w-[130px] border-black no-underline group flex gap-2 justify-center rounded-xl items-center  py-3"
                                        >
                                            <MdPhoneAndroid 
                                            size={20} className="group-hover:text-[#81858c]"/>
                          
                                                <span class=" group-hover:text-[#81858c] group group-hover:no-underline no-underline  text-sm text-black dark:text-white">
                                                    Buycex Beta
                                                </span>
            
                                            
                                        </Link>
                                        <div className="flex w-[70%] flex-col p-3 lg:min-w-[150px] min-w-[130px] md:max-w-[150px] dark:bg-[#1A191B] border-2  border-black justify-center items-start gap-2 rounded-xl">
                                        <VscQuestion size={20}/>
                                        <p className="text-sm text-black dark:text-white">
                                           Can't find the Buycex App in the App Store?
                                        </p>
                                        <Link to='' className="text-[#EFB81C]  hover:text-[#cfa83e]">click here</Link>
                                        </div>
                                    </div>
                                    {/* andriod */}
                                    <div className="flex flex-col gap-4 justify-between items-start w-full h-full">
                                    <span className="text-base text-black font-bold dark:text-white">
                                    Andriod
                                    </span>
                                        <Link
                                            to=" "
                                            class="hover:bg-[#EFB81C] w-[70%] lg:min-w-[150px] min-w-[130px] bg-[#EFB81C] group flex gap-2 justify-center rounded-xl items-center  py-2  "
                                        >
                                            <FaGooglePlay size={25} className="group-hover:text-white text-black"/>
                                            <div class="flex flex-col  justify-between items-start">
                                                <span class=" group-hover:text-white  text-[9px] text-black ">
                                                GET IT ON
                                                </span>
                                                <span class="text-black  text-sm group-hover:text-white ">
                                                Google Play
                                                </span>
                                            </div>
                                        </Link>
                                        <Link
                                            to=" "
                                            class=" dark:bg-[#1A191B] w-[70%] border-2 lg:min-w-[150px] min-w-[130px] border-black no-underline group flex gap-2 justify-center rounded-xl items-center py-3"
                                        >
                                            <TfiGallery  
                                            size={20} className="group-hover:text-[#81858c]"/>
                          
                                                <span class=" group-hover:text-[#81858c] group group-hover:no-underline no-underline  text-sm text-black dark:text-white">
                                                    App Gallery
                                                </span>
            
                                            
                                        </Link>
                                        <Link
                                            to=" "
                                            class=" dark:bg-[#1A191B] w-[70%] border-2 lg:min-w-[150px] min-w-[130px] border-black no-underline group flex gap-2 justify-center rounded-xl items-center py-3"
                                        >
                                            <HiMiniBuildingStorefront
 
                                            size={20} className="group-hover:text-[#81858c]"/>
                          
                                                <span class=" group-hover:text-[#81858c] group group-hover:no-underline no-underline  text-sm text-black dark:text-white">
                                                    Mi Store
                                                </span>
            
                                            
                                        </Link>
                                        <Link
                                            to=" "
                                            class=" dark:bg-[#1A191B] w-[70%] border-2 lg:min-w-[150px] min-w-[130px] border-black no-underline group flex gap-1 justify-center rounded-xl items-center py-3"
                                        >
                                            <LiaTrashRestoreAltSolid  
                                            size={20} className="group-hover:text-[#81858c]"/>
                          
                                                <span class=" group-hover:text-[#81858c] group group-hover:no-underline no-underline  text-sm text-black dark:text-white">
                                                    Samsung Store
                                                </span>
            
                                            
                                        </Link>
                                        <Link
                                            to=" "
                                            class=" dark:bg-[#1A191B] w-[70%] border-2 lg:min-w-[150px] min-w-[130px] border-black no-underline group flex gap-2 justify-center rounded-xl items-center py-3"
                                        >
                                            <GrInstallOption  
                                            size={20} className="group-hover:text-[#81858c]"/>
                          
                                                <span class=" group-hover:text-[#81858c] group group-hover:no-underline no-underline  text-sm text-black dark:text-white">
                                                    Install APK
                                                </span>
            
                                            
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* right */}
                            <div className="flex justify-between  md:w-[40%] md:flex-row items-center w-full h-full">
                                <img
                                    src={downloads}
                                    alt="Downloads"
                                    className=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Downloads;
