import React from 'react'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import route from "assets/img/route.svg";
import omni from "assets/img/omni.svg";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import FaQs from 'components/FAQs';

function PublicVoting() {

  const FeatureCard = ({ title, children, description }) => {
    return (
      <div className="flex flex-col items-center p-8 rounded-3xl border border-solid border-zinc-300 dark:border-zinc-600 text-neutral-900 dark:text-white shadow-md hover:shadow-lg transition-shadow duration-300">
        <div className="text-center">
          <div className="mt-0 text-xl font-semibold leading-7">{title}</div>
          <div className="justify-center px-2.5 py-2 mt-3 text-xs leading-5 text-amber-400 rounded-md bg-amber-400 bg-opacity-20">{children}</div>
          <div className="mt-3 text-sm leading-5 text-neutral-600 dark:text-neutral-300">{description}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col bg-[#F4F4F4] text-[#151415] dark:bg-[#151415] dark:text-[#F4F4F4]">
        <div className="flex flex-col text-white justify-center items-start px-16 py-20 w-full font-semibold bg-gradient-to-r from-amber-500 to-amber-400 border border-gray-200 border-solid shadow-lg max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col mt-1.5 mb-3 max-w-full w-[563px]">
            <div className="text-4xl leading-9 max-md:max-w-full animate-pulse max-md:text-sm">
              ByVotes | Spot
            </div>
            <div className="mt-5 text-xl font-medium leading-7 max-md:max-w-full max-md:text-sm">
              Hold USDT, USDC, USDD, DAI, or CUSD to be able to vote for your
              favorite tokens and stand to win airdrops.
            </div>
            <div className="flex gap-5 mt-8 text-base text-center max-md:flex-wrap">
              <Link to="#!" className="grow justify-center hover:bg-amber-500 transition duration-300 ease-in-out transform hover:scale-105 px-8 py-4 rounded-xl border border-white border-solid w-fit max-md:px-5 cursor-pointer max-md:text-sm">
                How to Participate in ByVotes
              </Link>
              <Link to="#!" className="justify-center hover:bg-amber-500 transition duration-300 ease-in-out transform hover:scale-105 px-8 py-4 rounded-xl border border-white border-solid max-md:px-5 cursor-pointer max-md:text-sm">
                Use Center
              </Link>
            </div>
          </div>
        </div>

        {/* Route (Total Prize Pool) Section */}
        <div className="flex flex-col self-center px-5 mt-16 w-full max-w-[1200px] max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col px-12 py-12 border border-gray-200 border-solid shadow-sm rounded-[40px] max-md:border-none max-md:px-5 max-md:max-w-full">
            <div className="text-3xl font-semibold max-md:max-w-full max-md:text-sm">
              The 21th ByVotes event has concluded.
            </div>
            <div className="mt-7 text-xl font-medium leading-7 text-neutral-400 max-md:max-w-full max-md:text-sm">
              The token with most votes has been listed on Buycex Spot Trading
              Platform.
            </div>
            <div className="flex flex-col px-8 py-9 mt-14 rounded-3xl bg-amber-400 bg-opacity-10 max-md:px-5 max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-5 justify-between w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                <div className="flex gap-4 max-md:gap-2 text-2xl font-semibold">
                  <img
                    loading="lazy"
                    src={route}
                    alt="Routes"
                    className="shrink-0 aspect-square border-[5px] w-[60px] rounded-full transition duration-300 ease-in-out transform hover:scale-105"
                  />
                  <div className="my-auto max-md:text-sm">ROUTE</div>
                </div>
                <div className="flex gap-5 my-auto text-base font-medium text-center max-md:flex-col">
                  <Link to="#!" className="justify-center px-8 py-4 text-amber-400 rounded-xl border border-amber-400 border-solid max-md:px-5 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-amber-400 hover:text-white max-md:text-sm">
                    Details
                  </Link>
                  <Link to="#!" className="justify-center px-8 py-4 text-white bg-amber-400 rounded-xl max-md:px-5 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-amber-500 max-md:text-sm">
                    Trade
                  </Link>
                </div>
              </div>
              <div className="flex gap-5 justify-between mt-10 w-full max-md:flex-wrap max-md:max-w-full">
                <div className="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full">
                  <div className="flex flex-col text-base font-medium">
                    <div className="leading-[162.5%] text-neutral-400 max-md:text-sm">
                      Total Prize Pool (ROUTE)
                    </div>
                    <div className="mt-6 leading-7">
                      <span className="text-2xl font-bold text-amber-400 max-md:text-sm">
                        20,000
                      </span>{" "}
                      ≈ 100,000 USDT
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-base font-medium leading-7 text-neutral-400 max-md:text-sm">
                      Total Votes
                    </div>
                    <div className="mt-7 text-2xl font-bold leading-6 max-md:text-sm">
                      64,420,913
                    </div>
                  </div>
                </div>
                <div className="flex gap-5 justify-between self-start text-base font-medium leading-7 text-neutral-400">
                  <div className="flex flex-col max-md:text-sm">
                    <div>Your Cast Votes</div>
                    <div className="mt-7 max-md:text-sm">Your ROUTE Allocation</div>
                  </div>
                  <div className="flex flex-col text-right whitespace-nowrap max-md:text-sm">
                    <div className="max-md:text-sm">0</div>
                    <div className="mt-12 max-md:text-sm">0</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Unsuccessful Listing Section */}
            <div className="mt-14 text-2xl font-semibold max-md:mt-10 max-md:max-w-full max-md:text-lg">
              Unsuccessful Listings
            </div>
            <div className="mt-4 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow px-8 py-7 rounded-3xl border border-solid border-zinc-300 max-md:px-5 max-md:mt-5 max-md:max-w-full">
                    <div className="px-px max-md:max-w-full">
                      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[69%] max-md:ml-0 max-md:w-full">
                          <div className="flex flex-col grow text-base font-medium max-md:mt-5">
                            <div className="flex gap-4 max-md:gap-2 text-2xl font-semibold whitespace-nowrap">
                              <img
                                loading="lazy"
                                src={route}
                                alt="Routes"
                                className="shrink-0 aspect-square border-[5px] w-[60px] rounded-full transition duration-300 ease-in-out transform hover:scale-105"
                              />
                              <div className="flex-auto my-auto max-md:text-sm">ROUTE</div>
                            </div>
                            <div className="mt-10 leading-[162.5%] text-neutral-400 max-md:text-sm">
                              Total Prize Pool (ROUTE)
                            </div>
                            <div className="mt-6 leading-7">
                              <span className="text-2xl font-bold text-amber-400 max-md:text-sm">
                                20,000
                              </span>{" "}
                              ≈ 100,000 USDT
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-[31%] max-md:ml-0 max-md:w-full">
                          <div className="flex flex-col grow mt-24 max-md:mt-10">
                            <div className="self-start text-base font-medium leading-7 text-neutral-400 max-md:text-sm">
                              Total Votes
                            </div>
                            <div className="mt-7 text-lg font-bold leading-6 max-md:text-sm">
                              64,420,913
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="shrink-0 mt-7 h-px border border-solid bg-zinc-300 border-zinc-300 max-md:max-w-full" />
                    <div className="flex gap-5 justify-between mt-8 text-base font-medium max-md:flex-wrap max-md:max-w-full">
                      <div className="flex flex-col leading-[162.5%] text-neutral-400 max-md:text-sm">
                        <div>Your Cast Votes <span className="ml-2 lg:hidden">0</span></div>
                        <div className="mt-1.5 text-right hidden md:flex">0</div>
                      </div>
                      <Link to="#!" className="justify-center px-8 py-4 text-amber-400 rounded-xl border border-amber-400 border-solid max-md:px-5 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-amber-400 hover:text-white max-md:text-sm">
                        Token info
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow px-8 py-7 rounded-3xl border border-solid border-zinc-300 max-md:px-5 max-md:mt-5 max-md:max-w-full">
                    <div className="px-px max-md:max-w-full">
                      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                        <div className="flex flex-col w-[69%] max-md:ml-0 max-md:w-full">
                          <div className="flex flex-col grow text-base font-medium max-md:mt-5">
                            <div className="flex gap-4 max-md:gap-2 text-2xl font-semibold whitespace-nowrap">
                              <img
                                loading="lazy"
                                src={route}
                                alt="Routes"
                                className="shrink-0 aspect-square border-[5px] w-[60px] rounded-full transition duration-300 ease-in-out transform hover:scale-105"
                              />
                              <div className="flex-auto my-auto max-md:text-sm">ROUTE</div>
                            </div>
                            <div className="mt-10 leading-[162.5%] text-neutral-400 max-md:text-sm">
                              Total Prize Pool (ROUTE)
                            </div>
                            <div className="mt-6 leading-7">
                              <span className="text-2xl font-bold text-amber-400 max-md:text-sm">
                                20,000
                              </span>{" "}
                              ≈ 100,000 USDT
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-[31%] max-md:ml-0 max-md:w-full">
                          <div className="flex flex-col grow mt-24 max-md:mt-10">
                            <div className="self-start text-base font-medium leading-7 text-neutral-400 max-md:text-sm">
                              Total Votes
                            </div>
                            <div className="mt-7 text-lg font-bold leading-6 max-md:text-sm">
                              64,420,913
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="shrink-0 mt-7 h-px border border-solid bg-zinc-300 border-zinc-300 max-md:max-w-full" />
                    <div className="flex gap-5 justify-between mt-8 text-base font-medium max-md:flex-wrap max-md:max-w-full">
                      <div className="flex flex-col leading-[162.5%] text-neutral-400 max-md:text-sm">
                        <div>Your Cast Votes <span className="ml-2 lg:hidden">0</span></div>
                        <div className="mt-1.5 text-right hidden md:flex">0</div>
                      </div>
                      <Link to="#!" className="justify-center px-8 py-4 text-amber-400 rounded-xl border border-amber-400 border-solid max-md:px-5 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-amber-400 hover:text-white max-md:text-sm">
                        Token info
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Participate inBtVotes Section */}
          <div className="mt-28 text-2xl font-semibold max-md:mt-10 max-md:max-w-full max-md:text-lg">
            How to Participate in ByVotes
          </div>
          <div className="mt-12 max-md:mt-10 max-md:max-w-full dark:bg-[#151415]">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <FeatureCard
                title="Snapshot Phase"
                children="2024-02-07 ~ 02-07 23:59 UTC"
                description="Get votes by depositing cryptocurrency before the snapshot phase ends. The higher the value of the tokens you hold, the more votes you will be awarded."
              />
              <FeatureCard
                title="Voting Phase"
                children="2024-02-07 ~ 02-07 23:59 UTC"
                description="Vote for your favorite tokens during this period and help to get them listed on Bybit! You will get the chance to share a prize pool if the token you voted for gets listed."
              />
              <FeatureCard
                title="Allocation Phase"
                children="2024-02-07 ~ 02-07 23:59 UTC"
                description="Tokens will be allocated to each user who voted for the winning project, based on how much they voted."
              />
            </div>
          </div>

          {/* Past Winning Section */}
          <div className="mt-32 text-3xl font-semibold max-md:mt-10 max-md:max-w-full max-md:text-lg">
            Past Winning Listings
          </div>
          <div className="flex gap-5 justify-between items-center mt-8 w-full max-md:flex-wrap max-md:max-w-full">
            <div className="flex gap-2.5 self-stretch text-base font-semibold whitespace-nowrap">
              <img
                loading="lazy"
                src={omni}
                alt="OMNI"
                className="shrink-0 aspect-square border-[5px] w-[60px] rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              />
              <div className="my-auto">OMNI</div>
            </div>
            <div className="flex gap-5 justify-between self-stretch my-auto max-md:flex-wrap max-md:max-w-full">
              <div className="flex flex-col">
                <div className="text-xs font-medium leading-7 text-neutral-400">
                  Total Prize Pool (OMNI)
                </div>
                <div className="text-base font-bold leading-7 text-amber-400">
                  3000
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-xs font-medium leading-7 text-neutral-400">
                  Total votes
                </div>
                <div className="text-base font-bold leading-7">
                  50
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-xs font-medium leading-7 text-neutral-400">
                  Listing Time (UTC)
                </div>
                <div className="flex gap-1.5 text-base font-bold leading-7">
                  <div>10:00 </div>
                  <div className="shrink-0 my-auto rounded-lg bg-zinc-300 h-[18px] w-[3px]" />
                  <div className="text-right">01 - 26 - 2024</div>
                </div>
              </div>
            </div>
            <div className="flex gap-3.5 self-stretch my-auto text-base font-medium text-center whitespace-nowrap">
              <Link to="#!" className="justify-center px-8 py-4 text-amber-400 rounded-xl border border-amber-400 border-solid max-md:px-5 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-amber-400 hover:text-white">
                Details
              </Link>
              <Link to="#!" className="justify-center px-8 py-4 text-white bg-amber-400 rounded-xl max-md:px-5 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-amber-500">
                Trade
              </Link>
            </div>
          </div>

          <div className="flex gap-5 justify-between items-center mt-12 w-full max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-2.5 self-stretch text-base font-semibold whitespace-nowrap">
              <img
                loading="lazy"
                src={omni}
                alt="OMNI"
                className="shrink-0 aspect-square border-[5px] w-[60px] rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              />
              <div className="my-auto">OMNI</div>
            </div>
            <div className="flex gap-5 justify-between self-stretch my-auto max-md:flex-wrap max-md:max-w-full">
              <div className="flex flex-col">
                <div className="text-xs font-medium leading-7 text-neutral-400">
                  Total Prize Pool (OMNI)
                </div>
                <div className="text-base font-bold leading-7 text-amber-400">
                  10,300
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-xs font-medium leading-7 text-neutral-400">
                  Total votes
                </div>
                <div className="text-base font-bold leading-7">
                  510
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-xs font-medium leading-7 text-neutral-400">
                  Listing Time (UTC)
                </div>
                <div className="flex gap-1.5 text-base font-bold leading-7">
                  <div>10:00 </div>
                  <div className="shrink-0 my-auto rounded-lg bg-zinc-300 h-[18px] w-[3px]" />
                  <div className="text-right">01 - 09 - 2024</div>
                </div>
              </div>
            </div>
            <div className="flex gap-3.5 self-stretch my-auto text-base font-medium text-center whitespace-nowrap">
              <Link to="#!" className="justify-center px-8 py-4 text-amber-400 rounded-xl border border-amber-400 border-solid max-md:px-5 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-amber-400 hover:text-white">
                Details
              </Link>
              <Link to="#!" className="justify-center px-8 py-4 text-white bg-amber-400 rounded-xl max-md:px-5 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-amber-500">
                Trade
              </Link>
            </div>
          </div>

          <div className="flex gap-5 justify-between items-center mt-12 w-full max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-2.5 self-stretch text-base font-semibold whitespace-nowrap">
              <img
                loading="lazy"
                src={omni}
                alt="OMNI"
                className="shrink-0 aspect-square border-[5px] w-[60px] rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              />
              <div className="my-auto">OMNI</div>
            </div>
            <div className="flex gap-5 justify-between self-stretch my-auto max-md:flex-wrap max-md:max-w-full">
              <div className="flex flex-col">
                <div className="text-xs font-medium leading-7 text-neutral-400">
                  Total Prize Pool (OMNI)
                </div>
                <div className="text-base font-bold leading-7 text-amber-400">
                  1000
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-xs font-medium leading-7 text-neutral-400">
                  Total votes
                </div>
                <div className="text-base font-bold leading-7">
                  150
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-xs font-medium leading-7 text-neutral-400">
                  Listing Time (UTC)
                </div>
                <div className="flex gap-1.5 text-base font-bold leading-7">
                  <div>10:00 </div>
                  <div className="shrink-0 my-auto rounded-lg bg-zinc-300 h-[18px] w-[3px]" />
                  <div className="text-right">01 - 30 - 2024</div>
                </div>
              </div>
            </div>
            <div className="flex gap-3.5 self-stretch my-auto text-base font-medium text-center whitespace-nowrap">
              <Link to="#!" className="justify-center px-8 py-4 text-amber-400 rounded-xl border border-amber-400 border-solid max-md:px-5 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-amber-400 hover:text-white">
                Details
              </Link>
              <Link to="#!" className="justify-center px-8 py-4 text-white bg-amber-400 rounded-xl max-md:px-5 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-amber-500">
                Trade
              </Link>
            </div>
          </div>

          <div className="flex gap-5 justify-between items-center mt-12 w-full max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-2.5 self-stretch text-base font-semibold whitespace-nowrap">
              <img
                loading="lazy"
                src={omni}
                alt="OMNI"
                className="shrink-0 aspect-square border-[5px] w-[60px] rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              />
              <div className="my-auto">OMNI</div>
            </div>
            <div className="flex gap-5 justify-between self-stretch my-auto max-md:flex-wrap max-md:max-w-full">
              <div className="flex flex-col">
                <div className="text-xs font-medium leading-7 text-neutral-400">
                  Total Prize Pool (OMNI)
                </div>
                <div className="text-base font-bold leading-7 text-amber-400">
                  15,000
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-xs font-medium leading-7 text-neutral-400">
                  Total votes
                </div>
                <div className="text-base font-bold leading-7">
                  5190
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-xs font-medium leading-7 text-neutral-400">
                  Listing Time (UTC)
                </div>
                <div className="flex gap-1.5 text-base font-bold leading-7">
                  <div>10:00 </div>
                  <div className="shrink-0 my-auto rounded-lg bg-zinc-300 h-[18px] w-[3px]" />
                  <div className="text-right">01 - 03 - 2024</div>
                </div>
              </div>
            </div>
            <div className="flex gap-3.5 self-stretch my-auto text-base font-medium text-center whitespace-nowrap">
              <Link to="#!" className="justify-center px-8 py-4 text-amber-400 rounded-xl border border-amber-400 border-solid max-md:px-5 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-amber-400 hover:text-white">
                Details
              </Link>
              <Link to="#!" className="justify-center px-8 py-4 text-white bg-amber-400 rounded-xl max-md:px-5 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-amber-500">
                Trade
              </Link>
            </div>
          </div>

          {/* Next Pages Section */}
          <div className="flex gap-5 justify-center items-center self-center mt-12 text-base font-medium leading-6 text-right whitespace-nowrap max-md:flex-wrap max-md:mt-10">

            <Link to="#!">
              <FaChevronLeft className="shrink-0 self-stretch aspect-square w-[30px] hover:text-[#EFB81C] cursor-pointer" />
            </Link>

            <div className="justify-center items-center self-stretch px-2.5 rounded-lg bg-amber-400 h-[25px] w-[25px] cursor-pointer">
              1
            </div>
            <Link to="#!" className="self-stretch my-auto cursor-pointer">2</Link>
            <Link to="#!" className="self-stretch my-auto cursor-pointer">3</Link>
            <Link to="#!" className="self-stretch my-auto cursor-pointer">4</Link>
            <Link to="#!" className="self-stretch my-auto cursor-pointer">5</Link>
            <Link to="#!" className="self-stretch my-auto cursor-pointer">...</Link>
            <Link to="#!" className="self-stretch my-auto cursor-pointer">6</Link>
            <Link to="#!">
              <FaChevronRight className="shrink-0 self-stretch aspect-square w-[30px] text-[#EFB81C] cursor-pointer cursor-pointer" />
            </Link>
          </div>
          <section>
            <FaQs />
          </section>
        </div>
      </div>
    </>
  )
};

export default PublicVoting