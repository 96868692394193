import * as types from '../types';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const competitionReducer = (state = initialState, action) => {
  switch (action.type) {
    // Fetch Actions
    case types.FETCH_COMPETITION_REQUEST:
      return { ...state, loading: true, error: null };
    case types.FETCH_COMPETITION_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case types.FETCH_COMPETITION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Update Actions
    case types.UPDATE_COMPETITION_REQUEST:
      return { ...state, loading: true, error: null };
    case types.UPDATE_COMPETITION_SUCCESS:
      // Assuming you want to replace the entire data with the updated data
      // You can also merge or modify specific fields if needed
      return { ...state, loading: false, data: action.payload };
    case types.UPDATE_COMPETITION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default competitionReducer;
