import React, { useRef } from "react";

const ModalVerification = ({ visible, onClose, email }) => {
    const modalContentRef = useRef(null);

    const handleOnClose = (e) => {
        if (e.target.id === "ModalClose" || !modalContentRef.current.contains(e.target)) {
            onClose();
        }
    };

    return (
        <div>
            {visible && (
                <div id="ModalClose" onClick={handleOnClose} className="fixed inset-0 z-50">
                    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
                    <div className="fixed inset-0 flex items-center justify-center">
                        <div ref={modalContentRef} className="bg-white p-3 dark:bg-[#151415] sm:w-[438px] w-full rounded-xl">
                            <p className="md:text-[21px] text-[16px] dark:text-white text-[#151415] font-bold mb-1">
                                Didn't receive the verification email?
                            </p>
                            <p className="md:text-[16px] text-[14px] font-normal mb-2 dark:text-white text-[#151415] ">
                                Try these steps:
                            </p>
                            <div className="bg-[#F3F3F3] dark:[#201E20] px-7 py-3 rounded-xl">
                                <ul className="list-disc md:text-[13px] text-[10px] dark:text-white text-[#151415] ">
                                    <li className="mt-2">
                                        Ensure if the email is in the spam folder.
                                    </li>
                                    <li className="mt-2">
                                        Ensure if your Address is{" "}
                                        <span className="font-semibold">
                                            {
                                                email ? email : "ABCDE@email.com"
                                            }
                                        </span>
                                    </li>
                                    <li className="mt-2">
                                        The mail may have been delayed. Please
                                        retry in ten (10) minutes.
                                    </li>
                                    <li className="mt-2">
                                        If you've already tried all the above
                                        options and still haven't received it,
                                        please contact our customer service.
                                    </li>
                                </ul>
                            </div>

                            <button
                                className="bg-[#F1BA19] hover:bg-[#f1bb19db] mt-2 p-[8px] w-full text-[12px] md:text-[14px] text-white rounded-[10px] font-medium"
                                type="submit" onClick={onClose}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModalVerification;