import React, { useState, useRef, useEffect } from "react";
import person from "assets/img/user/person-authentication-icon.png";

import Webcam from "react-webcam";
import { useDispatch, useSelector } from "react-redux";
import { basicVerification } from "store/actions/idVerificationAction";

import { BsPersonVcardFill } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import imgPath from "../../../../assets/img/user/buycex-default-user-profile-img.png"

const FaceRecognition = () => {
    const history = useHistory();
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const dispatch = useDispatch();
    const [showWebcam, setShowWebcam] = useState(false);
    const [capturedPhoto, setCapturedPhoto] = useState(null);
    const webcamRef = useRef(null);
    const [localData, setLocalData] = useState([]);
    const idFrontImage = useSelector((state) => state.images.idFrontImage);
    const idBackImage = useSelector((state) => state.images.idBackImage);
    const idCard = useSelector((state) => state.images.idCard);
    const firstname = useSelector(
        (state) => state.images.userDetails.firstName
    );
    const lastName = useSelector((state) => state.images.userDetails.lastName);
    const nationality = useSelector(
        (state) => state.images.userDetails.nationality
    );
    const gender = useSelector((state) => state.images.userDetails.gender);
    const residence = useSelector(
        (state) => state.images.userDetails.residence
    );
    const dob = useSelector((state) => state.images.userDetails.dob);
    const idType = useSelector((state) => state.images.userDetails.idType);
    // const idcard = useSelector((state) => state.images.idcard);
    const state = useSelector((state) => state);
    console.log(state);

    useEffect(() => {
        const storedUserInfo = localStorage.getItem("idVerificationBasicInfo");
        if (storedUserInfo) {
            const idVerificationBasicInfo = JSON.parse(storedUserInfo);
            setLocalData(idVerificationBasicInfo);
        }
    }, []);
    const dobDate = new Date(dob);
    const year = dobDate.getUTCFullYear();
    const month = String(dobDate.getUTCMonth() + 1).padStart(2, "0"); // Add 1 to month, since it’s zero-based
    const day = String(dobDate.getUTCDate()).padStart(2, "0");

    const handleLocalStorage = async () => {
        const defaultImgFile = await fetch(imgPath)
    .then((res) => res.blob())
    .then((blob) => new File([blob], "default-profile.png", { type: blob.type }));

        const formData = new FormData();
        formData.append("nationality", nationality || "");
        formData.append("country", residence || "");
        formData.append("firstname", firstname || "");
        formData.append("lastname", lastName || "");
        formData.append("gender", gender || "");
        formData.append("dob", `${year}-${month}-${day}`);
        formData.append("idcard", idCard || "");
        formData.append("frontphoto", idFrontImage || "");
        formData.append("backidcard", idBackImage || "");
        formData.append("profilephoto", defaultImgFile || "");
        formData.append("backphoto", capturedPhoto);
        formData.append("idType", idType);

        const result = await dispatch(
            basicVerification(formData, userID, userToken)
        );
        if (result.success) {
            history.push("/user/account/overview");
        }
    };

    const handleStart = () => {
        setShowWebcam(true);
        setCapturedPhoto(null); // Reset captured photo if restarting
    };

    const handleCloseWebcam = () => {
        setShowWebcam(false);
    };

    const capturePhoto = () => {
        const imageSrc = webcamRef.current.getScreenshot({
            format: "image/png",
        });
        if (imageSrc) {
            convertToPng(imageSrc, (pngFile) => {
                setCapturedPhoto(pngFile);
            });
        }
        setShowWebcam(false);
    };

    const convertToPng = (imageSrc, callback) => {
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((blob) => {
                const file = new File([blob], "backphoto.png", {
                    type: "image/png",
                });
                callback(file);
            }, "image/png");
        };
    };

    const handleBack = () => {
        history.push("/user/account/identity-verification/id-upload");
    };

    const handleReset = () => {
        setCapturedPhoto(null);
        setShowWebcam(false);
    };

    return (
        <div className="flex flex-col justify-start w-full">
            <div className="flex items-center gap-2 mb-[35px]">
                <div>
                    <BsPersonVcardFill size={20} />
                </div>
                <div>
                    <span className="text-[22px] font-semibold">
                        Identity Verification
                    </span>
                </div>
            </div>

            <section className="flex flex-col gap-3">
                <div className="flex flex-col gap-5 p-4 bg-white rounded-md border border-[#F4F4F4]">
                    <div>
                        <span className="text-[14px] font-semibold">
                            Basic Info
                        </span>
                    </div>
                    <div className="relative flex items-center gap-2 justify-between">
                        {["Basic Info", "Upload ID", "Face Recognition"].map(
                            (step, index) => (
                                <div
                                    key={index}
                                    className={`user-select-none min-w-[30%] inline-flex gap-3 items-center px-4 py-3 border-[0.5px] rounded-md ${
                                        index === 0 ||
                                        index === 1 ||
                                        index === 2
                                            ? "bg-[#EFB81C50]"
                                            : ""
                                    }`}
                                >
                                    <div className="flex w-[22px] h-[22px] border-[0.5px] bg-[#EFB81C] rounded-full">
                                        <span className="text-[12px] leading-none m-auto">
                                            {index + 1}
                                        </span>
                                    </div>
                                    <span className="text-[14px] font-semibold leading-none">
                                        {step}
                                    </span>
                                </div>
                            )
                        )}
                    </div>
                </div>

                <div className="bg-white p-4 rounded-md flex flex-col gap-6 border border-[#F4F4F4] w-full">
                    <div className="w-max">
                        <span className="text-[14px] font-semibold">
                            Face Verification
                        </span>
                    </div>
                    <div className="flex gap-8 items-center justify-start">
                        <div className="w-[150px]">
                            <img
                                src={person}
                                alt="Person Authentication"
                                width={"100%"}
                                height={"auto"}
                                title="Person Authentication"
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="w-max">
                                <span className="text-[14px] font-semibold">
                                    Before you start
                                </span>
                            </div>
                            <ul className="text-[12px] list-decimal ml-4 flex flex-col gap-2">
                                <li className="text-[12px]">
                                    Take off your glasses, hat. or any items
                                    that cover your face
                                </li>
                                <li className="text-[12px]">
                                    Make sure your face is well-lit
                                </li>
                                <li className="text-[12px]">
                                    Follow the on-screen instructions that
                                    appear on the screen during the process for
                                    a successful liveness check.
                                </li>
                                <li className="text-[12px]">
                                    Make sure you have a webcam available on
                                    your computer.
                                </li>
                                <li className="text-[12px]">
                                    Grant the appropriate permissions to get an
                                    access on the webcam to complete the
                                    process.
                                </li>
                            </ul>
                        </div>
                    </div>
                    {showWebcam && (
                        <div className="w-full flex flex-col justify-center items-center mt-4">
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/png"
                                className="rounded-md"
                            />
                            <div className="inline-flex gap-3 mt-4">
                                <button
                                    className="bg-[#efb81c] rounded-[10px] px-10 py-[5px] font-medium"
                                    onClick={handleCloseWebcam}
                                >
                                    Close Webcam
                                </button>
                                <button
                                    className="bg-[#efb81c] rounded-[10px] px-10 py-[5px] font-medium"
                                    onClick={capturePhoto}
                                >
                                    Capture Photo
                                </button>
                            </div>
                        </div>
                    )}
                    {capturedPhoto && (
                        <div className="w-full flex flex-col justify-center items-center mt-4">
                            <h2>Captured Photo:</h2>
                            <img
                                src={URL.createObjectURL(capturedPhoto)}
                                alt="Captured"
                                className="rounded-lg mt-4"
                            />
                        </div>
                    )}
                    <div className="w-full flex flex-row justify-center items-center gap-3 mt-4">
                        {!showWebcam && !capturedPhoto && (
                            <>
                                <button
                                    type="button"
                                    onClick={handleBack}
                                    className="inline-flex bg-[#EFB81C] rounded-md px-10 py-2 transition-all ease-out duration-500"
                                >
                                    <span className="text-[12px] font-semibold m-auto">
                                        Back
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    onClick={handleStart}
                                    className="inline-flex bg-[#EFB81C] rounded-md px-10 py-2 transition-all ease-out duration-500"
                                >
                                    <span className="text-[12px] font-semibold m-auto">
                                        Start
                                    </span>
                                </button>
                            </>
                        )}
                        {capturedPhoto && (
                            <>
                                <button
                                    type="button"
                                    className={`inline-flex rounded-md px-10 py-2 bg-[#F4F4F4] hover:bg-[red] transition-all ease-out duration-500`}
                                    onClick={handleReset}
                                >
                                    <span className="text-[12px] font-semibold m-auto">
                                        Reset
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className={`inline-flex rounded-md px-10 py-2 bg-[#EFB81C] transition-all ease-out duration-500`}
                                    onClick={handleLocalStorage}
                                >
                                    <span className="text-[12px] font-semibold m-auto">
                                        Next
                                    </span>
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default FaceRecognition;