import React from 'react';

const InviteStep = ({ icon, title, description }) => (
    <div className="text-center flex flex-col gap-3 md:h-[240px] md:w-1/4 md:text-left">
      <div className="flex items-center justify-center">{icon}</div>
      <h1 className="text-xl font-bold">{title}</h1>
      <p>{description}</p>
    </div>
  );

export default InviteStep;