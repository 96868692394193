import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";
import store from "./store";

// Import CSS here
import "tailwindcss/tailwind.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "assets/css/global/custom-style.scss";
import "assets/css/style.css";

// Render the App component
ReactDOM.render(
    <GoogleOAuthProvider clientId="933893635578-51sampn35re16ihkm6h4qlqq7nfrcaop.apps.googleusercontent.com">
        <Provider store={store}>
            <App />
        </Provider>
    </GoogleOAuthProvider>,
    document.getElementById("root")
);
