import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { showbasicVerification } from "store/actions/idVerificationAction";
import { BsPersonVcardFill } from "react-icons/bs";
import toast from "react-hot-toast";
import { fetchAuthStatus } from "store/actions/userSecuritySettingsAction";
import { fetchKycStatus } from "store/actions/AccountuserActions";

const IdVerification = () => {
    const dispatch = useDispatch();

    const [isChecked, setIsChecked] = useState(false);
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };
    const history = useHistory();

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const token = useSelector((state) => state.authUser?.user?.TOKEN);
    const basicData = useSelector((state) => state?.idVerification);

    const { authStatus } = useSelector((state) => state.authCheck);

    const isSecurityEnabled = !authStatus?.ga && !authStatus?.email;

    useEffect(() => {
        if (userID && token) {
            dispatch(showbasicVerification(userID, token));
        }
    }, [userID, token]);

    useEffect(() => {
        if (userID && token) {
            dispatch(fetchKycStatus(userID, token));
            dispatch(fetchAuthStatus(userID, token));
        }
    }, [dispatch, userID, token]);

    useEffect(() => {
        if (basicData?.basicData?.idcard != null) {
            const isNotEmpty = (value) => value !== null;
            const kycVerified =
                isNotEmpty(basicData?.basicData?.country) &&
                isNotEmpty(basicData?.basicData?.dob) &&
                isNotEmpty(basicData?.basicData?.firstname) &&
                isNotEmpty(basicData?.basicData?.lastname) &&
                isNotEmpty(basicData?.basicData?.idcard) &&
                isNotEmpty(basicData?.basicData?.id) &&
                isNotEmpty(basicData?.basicData?.idcardimg1) &&
                isNotEmpty(basicData?.basicData?.idcardimg2);

            if (kycVerified) {
                history.push(
                    "/user/account/identity-verification/kyc-verification"
                );
            }
        }
    }, [basicData, history]);

    const startVerification = () => {
        if (!isSecurityEnabled) {
            history.push(
                "/user/account/identity-verification/basic-verification"
            );
        } else {
            toast.error(
                "Please enable minimum two security functions. Redirecting to security settings",
                {
                    id: "error",
                }
            );
            setTimeout(() => {
                toast.remove("error");
                history.push("/user/account/security");
            }, 1500);
        }
    };

    return (
        <div className="w-full mt-10">
            <div class="flex items-center gap-2 mb-[35px]">
                <div>
                    <BsPersonVcardFill size={20} />
                </div>
                <div>
                    <span class="text-[22px] font-semibold">
                        Identity Verification
                    </span>
                </div>
            </div>
            <section className="flex flex-col gap-3">
                <div className="flex flex-col gap-5 p-4 bg-white rounded-md border border-[#F4F4F4]">
                    <div>
                        <span className="text-[14px] font-semibold">
                            How to verify?
                        </span>
                    </div>
                    <div className="flex items-center gap-2 justify-between">
                        {["Basic Info", "Upload ID", "Face Recognition"].map(
                            (step, index) => (
                                <div
                                    key={index}
                                    className="user-select-none min-w-[30%] inline-flex gap-3 items-center px-4 py-3 border-[0.5px] rounded-md"
                                >
                                    <div className="flex w-[22px] h-[22px] border-[0.5px] bg-[#EFB81C] rounded-full">
                                        <span className="text-[12px] leading-none m-auto">
                                            {index + 1}
                                        </span>
                                    </div>
                                    <span className="text-[14px] font-semibold leading-none">
                                        {step}
                                    </span>
                                </div>
                            )
                        )}
                    </div>
                </div>

                {/* Id Verification Info */}
                <div className="bg-white rounded-md border border-[#F4F4F4] p-4">
                    <div className="mb-4">
                        <div className="mb-1">
                            <span className="text-[14px] font-semibold">
                                Get your identity verified to buy and trade on
                                Buycex.
                            </span>
                        </div>
                        <p className="text-[12px] leading-5">
                            Complete your KYC verification to unlock the full
                            potential of Your Buycex profile! KYC (Know Your
                            Customer) is a quick and secure process that helps
                            us comply with regulations and keep our platform
                            safe. By verifying your identity, you'll gain access
                            to higher transaction limits, advanced features, and
                            the peace of mind that comes with a trusted
                            exchange.
                        </p>
                    </div>

                    <div className="mb-4">
                        <div className="mb-1">
                            <span className="text-[14px] font-semibold">
                                Information Required
                            </span>
                        </div>
                        <ul className="list-decimal ml-8">
                            <li className="text-[12px] font-medium py-1">
                                Personal Information
                            </li>
                            <li className="text-[12px] font-medium py-1">
                                Government-issued ID
                            </li>
                            <li className="text-[12px] font-medium py-1">
                                Facial recognition
                            </li>
                        </ul>
                    </div>

                    <div className="w-full flex flex-col justify-center items-center">
                        <div
                            className="flex items-center gap-2 mb-4 cursor-pointer"
                            onClick={handleToggle}
                        >
                            <input
                                type="checkbox"
                                id="verification-checkbox"
                                className="w-4 h-4 accent-[#EFB81C]"
                                checked={isChecked}
                                onChange={handleToggle}
                            />
                            <label
                                htmlFor="verification-checkbox"
                                className="text-[12px] font-medium m-0"
                            >
                                I guarantee that none of the information shared
                                by me is plagiarized and is completely genuine.
                            </label>
                        </div>
                        <button
                            type="button"
                            onClick={isChecked ? startVerification : null}
                            disabled={!isChecked}
                            className={`w-max py-2 px-3 rounded-md text-[12px] font-semibold ${
                                isChecked
                                    ? "bg-[#EFB81C]"
                                    : "bg-[#F4F4F4] cursor-not-allowed opacity-50"
                            } transition-all ease-out duration-500`}
                        >
                            Start Verification
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default IdVerification;