import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import Mask from "assets/img/MaskWebcam.svg";
import MaskFile from "assets/img/Mask File.svg";

const WebCamModal = ({ onConfirm, onClose, onCapture }) => {
    const [isWebcamOpen, setIsWebcamOpen] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const webcamRef = useRef(null);

    const handleOptionClick = (option) => {
        if (selectedOption !== option) {
            setSelectedOption(option);
            if (option === "webcam") {
                setIsWebcamOpen(true);
            } else if (option === "file") {
                setIsWebcamOpen(false);
                document.getElementById("fileInput").click();
            }
        }
    };

    const captureImage = () => {
        const imageSrc = webcamRef?.current?.getScreenshot();
        if (imageSrc) {
            fetch(imageSrc)
                .then((res) => res.blob())
                .then((blob) => {
                    const file = new File([blob], "webcam_image.jpg", {
                        type: "image/jpeg",
                    });
                    setCapturedImage(file);
                    setIsWebcamOpen(false); // Close the webcam after capturing the image
                });
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setCapturedImage(file);
        setSelectedOption("file"); // Set option to 'file' to handle subsequent actions
    };

    const handleContinue = () => {
        if (capturedImage) {
            onCapture(capturedImage);
            setCapturedImage(null); // Clear the captured image after sending
        }
        onConfirm();
        onClose();
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 outline-none focus:outline-none">
            <div className="bg-white rounded-xl p-6 w-[450px] shadow-lg relative">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                    &times;
                </button>
                <h2 className="text-lg font-bold mb-4 w-full text-center">
                    Select an Option
                </h2>
                <div className="space-y-4">
                    <button
                        onClick={() => handleOptionClick("webcam")}
                        className={`w-full p-[10px] rounded-[9px] flex items-center justify-start hover:bg-gray-200 ${
                            selectedOption === "webcam"
                                ? "bg-gray-300 font-bold"
                                : "bg-gray-100"
                        }`}
                        disabled={isWebcamOpen} // Disable if webcam is open
                    >
                        <img src={Mask} width={25} height={25} alt="Webcam" />{" "}
                        Open Webcam
                    </button>
                    <input
                        type="file"
                        accept="image/*"
                        className="hidden"
                        id="fileInput"
                        onChange={handleFileChange}
                    />
                    <button
                        onClick={() => handleOptionClick("file")}
                        className={`w-full p-[10px] rounded-[9px] flex items-center justify-start hover:bg-gray-200 ${
                            selectedOption === "file"
                                ? "bg-gray-300 font-bold"
                                : "bg-gray-100"
                        }`}
                        disabled={isWebcamOpen} // Disable if webcam is open
                    >
                        <img src={MaskFile} alt="File" /> Upload from Files
                    </button>
                </div>
                <button
                    onClick={handleContinue}
                    className={`mt-6 w-full text-white p-2 rounded-[9px] hover:bg-yellow-600 ${
                        capturedImage
                            ? "bg-[#efb81c]"
                            : "bg-[#efb81c] cursor-not-allowed"
                    }`}
                    disabled={!capturedImage}
                >
                    Continue
                </button>
                {isWebcamOpen && (
                    <div className="mt-4">
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            className="w-full h-auto rounded-lg"
                        />
                        <button
                            onClick={captureImage}
                            className="mt-2 w-full bg-yellow-500 text-white p-3 rounded-lg hover:bg-yellow-600"
                        >
                            Capture Image
                        </button>
                        <button
                            onClick={() => setIsWebcamOpen(false)} // Close the webcam without capturing
                            className="mt-2 w-full bg-yellow-500 text-white p-3 rounded-lg hover:bg-yellow-600"
                        >
                            Close Webcam
                        </button>
                    </div>
                )}
                {capturedImage && (
                    <div className="mt-4">
                        <img
                            src={URL.createObjectURL(capturedImage)}
                            alt="Captured"
                            className="w-full h-auto rounded-lg"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default WebCamModal;
