import React, { useEffect, useRef, memo } from "react";
import PropTypes from "prop-types";
import { useSelectedCoin } from "../context/SelectedCoinContext";

function SpotTradeChartCanvas() {
    const { selectedCoin } = useSelectedCoin();
    const container = useRef(null);

    useEffect(() => {
        const updateChart = () => {
            const containerElement = container.current;
            containerElement.innerHTML = "";

            const marketPair = selectedCoin.ticker
                .toUpperCase()
                .replace("_", "");

            if (marketPair) {
                const script = document.createElement("script");
                script.src =
                    "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
                script.type = "text/javascript";
                script.async = true;

                // Set theme based on `dark` class presence
                const theme = document.documentElement.classList.contains(
                    "dark"
                )
                    ? "dark"
                    : "light";

                script.innerHTML = JSON.stringify({
                    autosize: true,
                    symbol: `BINANCE:${marketPair}`,
                    interval: "D",
                    timezone: "Etc/UTC",
                    theme: theme,
                    style: "1",
                    locale: "en",
                    withdateranges: false,
                    details: false,
                    hide_volume: true,
                    hide_side_toolbar: false,
                    allow_symbol_change: true,
                    calendar: false,
                    left_toolbar: true,
                    enable_publishing: true,
                    support_host: "https://www.tradingview.com",
                    container_id: "spot-trade-chart",
                });

                containerElement.appendChild(script);
            }
        };

        updateChart(); // Initial chart load

        // Observe theme class changes
        const observer = new MutationObserver(updateChart);
        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ["class"],
        });

        return () => observer.disconnect(); // Clean up observer on unmount
    }, [selectedCoin]);

    return (
        <div
            className="tradingview-widget-container !rounded !h-[70lvh]"
            ref={container}
            id="spot-trade-chart"
        >
            <div className="tradingview-widget-container__widget"></div>
        </div>
    );
}

SpotTradeChartCanvas.propTypes = {
    selectedCoin: PropTypes.shape({
        ticker: PropTypes.string,
    }),
};

export default memo(SpotTradeChartCanvas);
