import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd';
import { createGlobalStyle, styled } from "styled-components";
import AssetDropdown from './DropDownUI';
import { useDispatch, useSelector } from 'react-redux';
import { FinanceOrdersTradeHistory } from 'store/actions/AssetsUserAction';
import NoData from "assets/img/user/No-data.svg";

const { RangePicker } = DatePicker;

function TradeHistory() {
    const options1 = ["All", "1000SATS", "1INCH", "ACA", "AAVE", "ADX", "AED"];
    const options2 = ["All", "Pre-Market", "USDT", "FSUSD", "USDC", "TUSD", "BNB", "BTC", "ETH"];
    const options3 = ["All", "Buy", "Sell"];
    const options4 = ["Order Time", "Update Time"]

    const dispatch = useDispatch();
    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const [orderListData, setOrderListData] = useState([]);

    useEffect(() => {
        const payload = { user_id: userID };

        dispatch(FinanceOrdersTradeHistory(userID, userToken))
            .then((response) => {
                if (response) {
                    const orderList = response.data?.data?.list || [];
                    setOrderListData(orderList);
                } else {
                    console.error(response);
                }
            });
    }, [dispatch, userID, userToken]);

    return (
        <>
            <div className='w-full flex justify-between items-center'>
                <div className='flex items-center w-[83%] gap-3'>
                    <GlobalStyles />
                    <StyledRangePicker className="px-2 w-[600px] h-[40px] bg-white sm:w-1/3 py-[0.55rem] border-l" />
                    <AssetDropdown label="Base" options={options1} initialSelected="All" isSearchable={true} />
                    <AssetDropdown label="Quote" options={options2} initialSelected="All" isSearchable={true} />
                    <AssetDropdown label="Side" options={options3} initialSelected="All" isSearchable={false} width="!w-[200px]" />
                    <div className="flex gap-3 items-center">
                        <button className='bg-[#EFB81C] px-3 py-2 rounded-md font-medium'>Search</button>
                        <button className='px-2 py-2 rounded-md font-medium'>Reset</button>
                    </div>
                </div>
            </div>

            <div className="text-xs mt-8 text-[#707a8a] w-full overflow-x-auto">
                <div className="flex justify-between border-b pb-3 w-[95%] trade-table">
                    <p style={{ width: '10%' }}>Date</p>
                    <p style={{ width: '10%' }}>Pair</p>
                    <p style={{ width: '10%' }}>Side</p>
                    <p style={{ width: '10%' }}>Price</p>
                    <p style={{ width: '10%' }}>Filled</p>
                    <p style={{ width: '10%' }}>Buy/Sell Fee</p>
                    <p style={{ width: '10%' }}>Role</p>
                    <p style={{ width: '10%' }}>Total</p>
                    <p style={{ width: '10%' }}>Total in USDT</p>
                </div>
                
                {orderListData.length > 0 ? (
                    orderListData.map((order, index) => (
                        <div key={index} className="flex justify-between py-2 border-b w-[95%] trade-table">
                            <p style={{ width: '10%' }}>{new Date(parseInt(order.addtime) * 1000).toLocaleString() || '-'}</p>
                            <p style={{ width: '10%' }}>{order.market.toUpperCase() || '-'}</p>
                            <p style={{ width: '10%' }}>{order.side || '-'}</p>
                            <p style={{ width: '10%' }}>{parseFloat(order.price).toFixed(2) || '-'}</p>
                            <p style={{ width: '10%' }}>{order.fill || '-'}</p>
                            <p style={{ width: '10%' }}>{parseFloat(order.fee_buy).toFixed(2) || '-'} / {parseFloat(order.fee_sell).toFixed(2) || '-'}</p>
                            <p style={{ width: '10%' }}>{order.role || '-'}</p>
                            <p style={{ width: '10%' }}>{parseFloat(order.price * order.num).toFixed(2) || '-'}</p>
                            <p style={{ width: '10%' }}>{parseFloat(order.price * order.num).toFixed(2) || '-'}</p>
                        </div>
                    ))
                ) : (
                    <div className="w-full flex justify-center items-center h-96">
                        <div className='flex flex-col gap-1 h-44 justify-center items-center'>
                            <img src={NoData} alt="No Data" />
                            <p className='text-[#707a8a] text-sm'>No records</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

const GlobalStyles = createGlobalStyle`
  .ant-picker:hover { border-color: #EFB81C !important; }
  .ant-picker-focused { border-color: #EFB81C !important; box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important; }
  .ant-picker-active-bar { background: #EFB81C !important; }
  .ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #EFB81C !important;
    color: white !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #EFB81C !important;
    border-radius: 4px;
    content: "";
  }
  .ant-picker-dropdown .ant-picker-header-view > button:hover { color: #EFB81C !important; }
`;

const StyledRangePicker = styled(RangePicker)`
  .ant-picker-input > input:focus { box-shadow: none !important; border-color: #EFB81C !important; }
`;

// Styles for equal column widths in the table
const TradeTable = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 1rem;
  text-align: center;
`;

export default TradeHistory;
