import React, { useState, useEffect } from "react";
import Select from "react-select";

const NetworkSelect = ({ networks, selectedNetwork, setSelectedNetwork }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate API call
        setTimeout(() => {
            setLoading(false);
        }, 800); // simulate 2 seconds loading time
    }, []);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: "10px",
            height: "55px",
            backgroundColor: state.isFocused ? "transparent" : "transparent",
            border: state.isFocused ? "2px solid #EFB81C" : "1px solid grey",
            boxShadow: "none",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: document.documentElement.classList.contains('dark') ? "#151415" : "white",
            color: document.documentElement.classList.contains('dark') ? "white" : "black",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: document.documentElement.classList.contains('dark') ? "white" : "black",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: document.documentElement.classList.contains('dark') ? "gray" : "gray",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#EFB81C" : state.isFocused ? (document.documentElement.classList.contains('dark') ? "gray" : "#EFB81C") : "transparent",
            color: state.isSelected ? "white" : "black",
        }),
    };

    return (
        <>
            <label className="block mb-2 text-[16px] font-medium text-gray-900 dark:text-white mt-9">
                Select Network
            </label>
            {loading ? (
                <div className="animate-pulse">
                    <div className="h-10 bg-gray-200 rounded w-full mb-4"></div>
                </div>
            ) : (
                <Select
                    value={selectedNetwork}
                    onChange={setSelectedNetwork}
                    options={networks}
                    isSearchable
                    placeholder="Select Network"
                    styles={customStyles}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#EFB81C', // Light red for the highlighted option
                            primary: '#EFB81C', // Darker red for the selected option
                            neutral0: document.documentElement.classList.contains('dark') ? '#1a202c' : '#ffffff', // Background color
                            neutral80: document.documentElement.classList.contains('dark') ? 'white' : 'black', // Text color
                        },
                    })}
                />
            )}
            <p className="text-[12px] mt-2">
                Make sure the network you choose for the deposit matches the withdrawal network or your assets may be lost.
            </p>
        </>
    );
};

export default NetworkSelect;
