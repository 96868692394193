import React, { useState } from 'react';
import { IoMdAlert } from "react-icons/io";
import { Modal } from 'antd'; 
import { useDispatch, useSelector } from 'react-redux';
import { confirmCancel } from "store/actions/p2pActions";

// Checkbox option component
const CheckboxOption = ({ id, label, onChange }) => (
    <div className="flex items-center mb-4">
        <input
            id={id}
            type="checkbox"
            value={id}
            name="additional-reason"
            onChange={onChange}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600"
        />
        <label htmlFor={id} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            {label}
        </label>
    </div>
);

// Radio option component
const RadioOption = ({ id, label, onChange }) => (
    <div className="flex items-center mb-4">
        <input
            id={id}
            type="radio"
            value={id}
            name="cancel-reason"
            onChange={onChange}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:bg-gray-700 dark:border-gray-600"
        />
        <label htmlFor={id} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            {label}
        </label>
    </div>
);

// Main P2pCancel component
const P2pCancel = ({ onConfirmCancel,onClose,Id }) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [isAdditionalChecked, setIsAdditionalChecked] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);  // State to track if cancellation is confirmed
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(true); // Control the first modal visibility
    const dispatch = useDispatch();

    
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;

    const Cancel = () => {
        const payload = {
            order_id: Id,
        };
        dispatch(confirmCancel(userId, token, payload));
    };

    // Handle feedback opening after cancel action
    const handleCancelAction = () => {
        Cancel()
        setIsCancelModalVisible(false);
        onConfirmCancel();
    };

    // Buyer options data
    const buyerOptions = [
        { id: 'cancel-1', label: 'I do not want to trade anymore' },
        { id: 'cancel-2', label: "I do not meet the requirements of the advertiser's trading terms and conditions" },
        { id: 'cancel-3', label: 'There is a technical or network error with the payment platform' },
        { id: 'cancel-4', label: "I have not paid but clicked 'Transferred'" },
        { id: 'cancel-5', label: "Other reasons" }
    ];

    // Seller options data
    const sellerOptions = [
        { id: 'cancel-6', label: 'Seller is asking for extra fee' },
        { id: 'cancel-7', label: "Problem with seller's payment method results in unsuccessful payments" }
    ];

    // Handler for changes to radio inputs
    const handleRadioChange = (e) => {
        setSelectedOption(e.target.value);
    };

    // Handler for changes to checkbox input
    const handleCheckboxChange = (e) => {
        setIsAdditionalChecked(e.target.checked);
    };

    // Determine if any option is selected
    const isButtonActive = selectedOption || isAdditionalChecked;

    return (
        <div>
            {/* Cancel modal */}
            <Modal
                title="Cancellation Reason"  // Updated to have title "Cancellation Reason"
                visible={isCancelModalVisible}
                onCancel={onClose}
                footer={null}
            >
                <div>
                    <div className='bg-gray-100 p-2 rounded-xl border-b'>
                        <div className='flex gap-2'>
                            <div className='mt-1'>
                                <IoMdAlert />
                            </div>
                            <p className='text-sm'>
                                If you voluntarily cancel your order or exceed the time limit 3 times in a day, your order function will be disabled for the remainder of the day, and your personal completion rate will be affected.
                                However, if the order is canceled due to the seller's reasons, the system will re-evaluate the situation.
                                <span className='text-[#EFB81C] text-sm'>View more</span>
                            </p>
                        </div>
                    </div>

                    <div className='border-b'>
                        <p className='text-md font-semibold py-3'>Due to buyer</p>
                        {buyerOptions.map(option => (
                            <RadioOption key={option.id} id={option.id} label={option.label} onChange={handleRadioChange} />
                        ))}
                    </div>

                    <div className='border-b'>
                        <p className='text-md font-semibold py-3'>Due to seller</p>
                        {sellerOptions.map(option => (
                            <RadioOption key={option.id} id={option.id} label={option.label} onChange={handleRadioChange} />
                        ))}
                    </div>

                    <div className='pt-3'>
                        <CheckboxOption id="additional-1" label="I have not paid the seller / have received seller's refund" onChange={handleCheckboxChange} />
                        <button 
                            onClick={handleCancelAction}
                            className={`mt-4 flex w-full py-2 px-4 rounded-xl items-center justify-center font-semibold ${isButtonActive ? 'bg-yellow-500 cursor-pointer text-white' : 'bg-gray-200 cursor-not-allowed text-gray-400'}`}
                            disabled={!isButtonActive}
                        >
                            Confirm Cancellation
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default P2pCancel;
