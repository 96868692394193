import React, { useState } from 'react';
import OpenOrder from './OpenOrder';
import OrderHistory from './OrderHistory';
import TradeHistory from './TradeHistory';

const SpotHistory = () => {
    const [activeItem, setActiveItem] = useState('Open Order'); // Default active item

    const discoverNavItems = ['Open Order', 'Order History', 'Trade History'];

    // Function to render the content based on active item
    const renderContent = () => {
        switch (activeItem) {
            case 'Open Order':
                return <OpenOrder />;
            case 'Order History':
                return < OrderHistory />;
            case 'Trade History':
                return < TradeHistory />;
            default:
                return < OpenOrder />;
        }
    };

    return (
        <div className='px-3'>
            <div className='container mt-5 !p-0'>
                {/* Render only discoverNavItems */}
                <nav className='flex gap-3 w-[60%] border-gray-200 pt-2'>
                    {discoverNavItems.map((item) => (
                        <button
                            key={item}
                            onClick={() => setActiveItem(item)}
                            className={`relative py-2 px-2 text-md font-semibold ${activeItem === item ? '' : 'text-gray-400'
                                }`}
                        >
                            {item}
                            {activeItem === item && (
                                <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                            )}
                        </button>
                    ))}
                </nav>

                <div className="mt-6">{renderContent()}</div> {/* Render the content of the active tab */}
            </div>
        </div>
    );
};

export default SpotHistory;
