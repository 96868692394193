import React, { useState } from "react";
import { Modal } from "antd";
import { FaArrowRight } from "react-icons/fa";

const TenxModel = ({ setShowTenxModel }) => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [leverage, setLeverage] = useState(0);

    const handleLeverageChange = (event) => {
        const newLeverageValue = parseFloat(event.target.value);
        setLeverage(newLeverageValue);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setShowTenxModel(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setShowTenxModel(false);
    };
    return (
        <div>
            <div className="model">
                <Modal
                    title="Adjust Leverage"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    className="convert-model dark:bg-[#101014] dark:text-[#ffffff]"
                >
                    <div className="content">
                        <div className="mt-10">
                            <div className="text-gray-400  ">Leverage</div>
                            <div className="mt-1">
                                <div className=" h-12 text-clip flex items-center justify-center font-semibold text-sm bg-gray-100 dark:bg-opacity-10 dark:text-[#ffffff] rounded-xl px-2">
                                    10
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-col ">
                                <input
                                    id="range-slider"
                                    name="range-slider"
                                    type="range"
                                    min="0"
                                    max="100"
                                    step="0.5"
                                    value={leverage}
                                    onChange={handleLeverageChange}
                                    className="w-full appearance-none cursor-pointer dark:bg-[#101014]"
                                    data-tooltip-id="leverage-tooltip"
                                    data-tooltip-content={`${leverage}%`}
                                    data-tooltip-place="top"
                                />
                                <div className="relative w-full flex justify-between mt-1">
                                    <span className="text-[11px] text-[#15141580 dark:text-[#ffffff] font-bold">
                                        1
                                    </span>
                                    <span className="text-[11px] text-[#15141580] dark:text-[#ffffff] font-bold">
                                        3
                                    </span>
                                    <span className="text-[11px] text-[#15141580] dark:text-[#ffffff] font-bold">
                                        5
                                    </span>
                                    <span className="text-[11px] text-[#15141580] dark:text-[#ffffff] font-bold">
                                        10
                                    </span>
                                    <span className="text-[11px] text-[#15141580] dark:text-[#ffffff] font-bold">
                                        25
                                    </span>
                                    <span className="text-[11px] text-[#15141580] dark:text-[#ffffff] font-bold">
                                        50
                                    </span>
                                    <span className="text-[11px] text-[#15141580] dark:text-[#ffffff] font-bold">
                                        100
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="flex mt-4 items-center justify-between text-gray-400 text-xs">
                            <div>Max Position at Current Leverage</div>
                            <div>100,000,000 USDT</div>
                        </div>
                        <div className="mt-2 text-xs text-yellow-500">
                            Learn More
                        </div>

                        <div className="flex items-center justify-between gap-4 mt-4">
                            <div className="flex-1 text-center flex items-center justify-center text-sm font-bold bg-yellow-500 rounded-xl h-12">
                                Confirm
                            </div>
                            <div className="flex-1 text-center flex items-center justify-center text-sm font-bold border border-gray-500 rounded-xl h-12">
                                Cancel
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default TenxModel;
