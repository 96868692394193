import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { CgLoadbar } from "react-icons/cg";
import { FaSearch } from "react-icons/fa";
import styled, { createGlobalStyle } from 'styled-components';

function SelectMethod({ isVisible, onOk, onCancel }) {
    const banks = [
        { name: 'Bank of America', color: '#FF0000' }, // Red color
        { name: 'Chase Bank', color: '#00FF00' },      // Green color
        { name: 'Wells Fargo', color: '#0000FF' },     // Blue color
        { name: 'Citibank', color: '#FF00FF' },        // Magenta color
        { name: 'HSBC', color: '#00FFFF' },            // Cyan color
        { name: 'TD Bank', color: '#FFA500' },         // Orange color
    ];

    // Generate alphabet array and add "All" at the start
    const alphabet = ['All', ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i))];

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedLetter, setSelectedLetter] = useState('All');

    // Filter banks based on both search term and selected letter
    const filteredBanks = banks.filter(bank => {
        const matchesSearch = bank.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesLetter = selectedLetter === 'All' ? true : bank.name[0].toUpperCase() === selectedLetter;
        return matchesSearch && matchesLetter;
    });

    return (
        <>
            {/* Apply the global style overrides */}
            <GlobalStyle />
            <Modal
                title="Select a Payment Method"
                visible={isVisible}
                onOk={onOk}
                onCancel={onCancel}
                footer={null}
            >
                <div className='py-3 mb-3 border-b'>
                    <p className='py-2 text-sm font-semibold'>Recommended</p>
                    <p className="flex items-center py-2 cursor-pointer">
                        <CgLoadbar className='rotate-90 text-[#EFB81C]' />
                        <h3 className='ml-1 text-md mb-0 hover:text-[#EFB81C]'>Bank Transfer</h3>
                    </p>
                </div>

                <div className='flex items-center mb-3'>
                    <p className='w-1/2 py-2 text-sm font-semibold'>All Payment Methods</p>
                    <StyledInput
                        size="large"
                        className='w-1/2 font-semibold'
                        placeholder="Search"
                        prefix={<FaSearch className='text-slate-400' />}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                {/* Alphabet Row */}
                <div className="flex flex-wrap mb-4">
                    {alphabet.map((letter, index) => (
                        <button
                            key={index}
                            className={`px-1 py-2 text-[11px] ${selectedLetter === letter ? ' text-[#EFB81C] border-transparent' : 'bg-transparent text-black'}  hover:text-[#EFB81C] transition-colors`}
                            onClick={() => setSelectedLetter(letter)} // Select the letter, "All" will show all banks
                        >
                            {letter}
                        </button>
                    ))}
                </div>

                {/* Filtered Banks List */}
                <div className="flex flex-wrap mt-4">
                    {filteredBanks.map((bank, index) => (
                        <div key={index} className="flex items-center w-1/2 py-2">
                            <CgLoadbar className={`rotate-90`} style={{ color: bank.color }} />
                            <h3 className="ml-1 text-md mb-0 hover:text-[#EFB81C] cursor-pointer">{bank.name}</h3>
                        </div>
                    ))}
                    {/* If no banks match the search */}
                    {filteredBanks.length === 0 && (
                        <p className="w-full py-4 text-center text-slate-500">
                            No banks found.
                        </p>
                    )}
                </div>
            </Modal>
        </>
    );
}

const GlobalStyle = createGlobalStyle`
  .css-dev-only-do-not-override-1c0na6j .ant-input-outlined:focus-within {
    border-color: #EFB81C !important; /* Yellow border on focus */
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2); /* Add a soft yellow shadow */
  }
`;

const StyledInput = styled(Input)`
  &:hover {
    border-color: #EFB81C; /* hover border color - yellow */
  }
  &:focus {
    border-color: #EFB81C !important; /* active border color - yellow */
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2); /* focus outline */
  }
`;

export default SelectMethod;
