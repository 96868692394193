// src/slices/stepOneSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedCrypto: '',
  selectedFiat: '',
  resultValue: '',
  symbolValue: '',
  currentValue: 0,
  isFixed: true,
  userFloat: 0,
  val: 1,
  totalAmount: '',
  fromAmount: '',
  toAmount: '',
  selectedTimeLimit: '15',
  time: '15',
  description: '',        
  autoReply: '',          
  regTimeInput: '',       
  balanceInput: '',       
  checkedItems: {         
    kyc_required: false,
    reg_time_required: false,
    balance_required: false,
  },
  selectedStatus: '',
  selectedPaymentMethodIds: [],
  selectedBanks: [],
  selectedPaymentMethod: [],
  country: '',
  tags: [],
};

const stepOneSlice = createSlice({
  name: 'stepOne',
  initialState,
  reducers: {
    resetState: (state) => {
      state.selectedCrypto = '';
      state.selectedFiat = '';
      state.resultValue = '';
      state.symbolValue = '';
      state.currentValue = 0;
      state.isFixed = true;
      state.userFloat = 100;
      state.val = 1;
      state.totalAmount = '';
      state.fromAmount = '';
      state.toAmount = '';
      state.selectedTimeLimit = '15 Mins';
      state.time = '15 Mins';
      state.description = '';
      state.autoReply = '';
      state.regTimeInput = '';
      state.balanceInput = '';
      state.tags = [];
      state.checkedItems = {
        kyc_required: false,
        reg_time_required: false,
        balance_required: false,
      };
      state.selectedStatus = '';
      state.selectedPaymentMethodIds = [];
      state.selectedBanks = [];
      state.selectedPaymentMethod = [];
    },
    
    setSelectedCrypto: (state, action) => {
      state.selectedCrypto = action.payload;
    },
    setSelectedFiat: (state, action) => {
      state.selectedFiat = action.payload;
    },
    setResultValue: (state, action) => {
      state.resultValue = action.payload;
    },
    setSymbolValue: (state, action) => {
      state.symbolValue = action.payload;
    },
    setCurrentValue: (state, action) => {
      state.currentValue = action.payload;
    },
    setIsFixed: (state, action) => {
      state.isFixed = action.payload;
    },
    setUserFloat: (state, action) => {
      state.userFloat = action.payload;
    },
    setVal: (state, action) => {
      state.val = action.payload;
    },
    setTotalAmount: (state, action) => {
      state.totalAmount = action.payload;
    },
    setFromAmount: (state, action) => {
      state.fromAmount = action.payload;
    },
    setToAmount: (state, action) => {
      state.toAmount = action.payload;
    },
    setTime: (state, action) => {
      state.time = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;  
    },
    setAutoReply: (state, action) => {
      state.autoReply = action.payload;    
    },
    setRegTimeInput: (state, action) => {
      state.regTimeInput = action.payload; 
    },
    setBalanceInput: (state, action) => {
      state.balanceInput = action.payload; 
    },
    setCheckedItems: (state, action) => {
      state.checkedItems = action.payload; 
    },
    setSelectedStatus(state, action) {
        state.selectedStatus = action.payload;
    },
    setSelectedPaymentMethodIds: (state, action) => {
      state.selectedPaymentMethodIds = action.payload;
    },
    setSelectedBanks: (state, action) => {
      state.selectedBanks = action.payload;
    },
    setSelectedPaymentMethod: (state, action) => {
      state.selectedPaymentMethod = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    },
  },
});

export const {
  resetState,
  setSelectedCrypto,
  setSelectedFiat,
  setResultValue,
  setSymbolValue,
  setCurrentValue,
  setIsFixed,
  setUserFloat,
  setVal,
  setTotalAmount,
  setFromAmount,
  setToAmount,
  setTime,
  setDescription,  
  setAutoReply,     
  setRegTimeInput,   
  setBalanceInput,     
  setCheckedItems,     
  setSelectedStatus,
  setSelectedPaymentMethodIds,
  setSelectedBanks,
  setSelectedPaymentMethod,
  setCountry,
  setTags,
} = stepOneSlice.actions;

export default stepOneSlice.reducer;