import React, { useState, useEffect } from "react";
import { FaFileCircleCheck } from "react-icons/fa6";
import UncreditedDepositForm from './UncreditedDepositForm';  // Adjust the path as necessary
import { FaCheck, FaArrowRight } from "react-icons/fa";
import { ImNotification } from "react-icons/im";
import { useHistory } from "react-router-dom";

const Deposit = () => {
  const [showForm, setShowForm] = useState(false);
  const [allDeposits, setAllDeposits] = useState([]); // Store all deposits
  const [deposits, setDeposits] = useState([]); // Store filtered deposits
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [selectedRange, setSelectedRange] = useState("all");
  const history = useHistory();

  const TOKEN = "45d67184844d0b710fdf837207f42b44";
  const ID = 449;

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange((prev) => ({ ...prev, [name]: value }));
    setSelectedRange("");
  };

  const handleRangeFilter = (range) => {
    setSelectedRange(range);
    setDateRange({ startDate: "", endDate: "" });
  };

  useEffect(() => {
    let filtered = allDeposits;

    if (selectedRange === "last7Days") {
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);
      filtered = allDeposits.filter(deposit => {
        const depositDate = new Date(parseInt(deposit.addtime) * 1000);
        return depositDate >= sevenDaysAgo && depositDate <= today;
      });
    } else if (selectedRange === "last30Days") {
      const today = new Date();
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);
      filtered = allDeposits.filter(deposit => {
        const depositDate = new Date(parseInt(deposit.addtime) * 1000);
        return depositDate >= thirtyDaysAgo && depositDate <= today;
      });
    }

    // Filter based on custom date range
    if (dateRange.startDate && dateRange.endDate) {
      filtered = allDeposits.filter(deposit => {
        const depositDate = new Date(parseInt(deposit.addtime) * 1000);
        const startDate = new Date(dateRange.startDate);
        const endDate = new Date(dateRange.endDate);
        return depositDate >= startDate && depositDate <= endDate;
      });
    }

    setDeposits(filtered);
  }, [dateRange, allDeposits, selectedRange]);

  const SkeletonRow = () => (
    <tr>
        <td className="py-3 px-4 text-center">
            <div className="animate-pulse bg-gray-300 h-4 w-4 m-auto rounded-full"></div>
        </td>
        <td className="py-3 px-4 text-center">
            <div className="animate-pulse bg-gray-300 h-4 w-24 m-auto rounded"></div>
        </td>
        <td className="py-3 px-4 text-center">
            <div className="animate-pulse bg-gray-300 h-4 w-16 m-auto rounded"></div>
        </td>
        <td className="py-3 px-4 text-center">
            <div className="animate-pulse bg-gray-300 h-4 w-32 m-auto rounded"></div>
        </td>
        <td className="py-3 px-4 text-center">
            <div className="animate-pulse bg-gray-300 h-4 w-40 m-auto rounded"></div>
        </td>
    </tr>
);

  useEffect(() => {
    if (!TOKEN || !ID) {
        console.log("Redirecting to sign-in");
        history.push("/login");
        return;
    }

    const fetchDeposits = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                `https://api.buycex.com/Crypto/MyDeposits/coin/all`,
                {
                    headers: {
                        TOKEN: TOKEN,
                        ID: ID,
                    },
                }
            );
            const data = await response.json();
            if (Array.isArray(data.data)) {
              setAllDeposits(data.data);
              setDeposits(data.data);
            } else {
              setAllDeposits([]);
              setDeposits([]);
            }
        } catch (error) {
            console.error("Error fetching deposit data:", error);
            setAllDeposits([]);
            setDeposits([]);
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };

    fetchDeposits();
}, [TOKEN, ID, history]);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  return (
    <>
     <div className="relative flex items-center gap-x-3 bottom-3">
        <FaFileCircleCheck size={26} />
        <div className="text-black text-[24px] font-semibold">
          Spot <span className="text-gray-600"> &gt; </span>Deposit Records
          {showForm && <span className="text-gray-600"> &gt; Apply to Retrieve Uncredited Deposit</span>}
        </div>
        {!showForm && (
          <button onClick={handleButtonClick} className="p-2 bg-yellow-200 rounded-md">
            Retrieving Uncredited Deposit
          </button>
        )}
      </div>

      {!showForm ? (
        <div className="flex flex-col w-full">
          {/* First card */}
          <div className="flex-col justify-center w-full p-4 space-x-4 bg-white rounded-3xl">
            <div className="flex items-center w-full gap-x-5">
              <div className="flex items-center space-x-2">
                <label htmlFor="asset" className="text-gray-700">
                  Asset
                </label>
                <select id="asset" className="p-2 border rounded-md">
                  <option>All</option>
                </select>
              </div>
              <div className="flex items-center space-x-2">
                <label htmlFor="operation" className="text-gray-700">
                  Operation
                </label>
                <select id="operation" className="p-2 border rounded-md">
                  <option>All</option>
                </select>
              </div>
            </div>
            <div className="flex items-center w-full mt-3 gap-x-3">
              <div className="flex items-center gap-x-2">
                <label className="text-gray-700">Time</label>
                <button 
                  className={`p-2 ${selectedRange === 'last7Days' ? 'bg-yellow-300' : 'bg-gray-200'} rounded-md`}
                  onClick={() => handleRangeFilter("last7Days")}
                >
                  Last 7 days
                </button>
                <button 
                  className={`p-2 ${selectedRange === 'last30Days' ? 'bg-yellow-300' : 'bg-gray-200'} rounded-md`}
                  onClick={() => handleRangeFilter("last30Days")}
                >
                  Last 30 days
                </button>
                <button 
                  className={`p-2 ${selectedRange === 'all' ? 'bg-yellow-300' : 'bg-gray-200'} rounded-md`}
                  onClick={() => handleRangeFilter("all")}
                >
                  All
                </button>
              </div>
              <div className="flex items-center space-x-2">
              <input
                  type="date"
                  name="startDate"
                  value={dateRange.startDate}
                  onChange={handleDateChange}
                  className="p-2 border rounded-md"
                />
                <span className="text-gray-700">-</span>
                <input
                  type="date"
                  name="endDate"
                  value={dateRange.endDate}
                  onChange={handleDateChange}
                  className="p-2 border rounded-md"
                />
              </div>
              <button className="p-2 bg-yellow-200 rounded-md">Export</button>
            </div>
          </div>

          {/* Second Card */}
          <div className="bg-white rounded-2xl w-full min-h-[500px] mt-3">
          <section className="  mx-auto px-4 py-8">
                    <div className="mb-8 mt-14">
                        <h1 className="font-semibold text-[22px] md:text-3xl sm:mx-4 mb-4">
                            Recent Deposits
                        </h1>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th className="py-2 px-4 border-b text-[#AAAAAA] text-center font-normal">
                                        Status
                                    </th>
                                    <th className="py-2 px-4 border-b text-[#AAAAAA] text-center font-normal">
                                        Address
                                    </th>
                                    <th className="py-2 px-4 border-b text-[#AAAAAA] text-center font-normal">
                                        Amount
                                    </th>
                                    <th className="py-2 px-4 border-b text-[#AAAAAA] text-center font-normal">
                                        Time
                                    </th>
                                    <th className="py-2 px-4 border-b text-[#AAAAAA] text-center font-normal">
                                        Hash
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    // Show skeleton rows if loading
                                    Array.from({ length: 5 }).map(
                                        (_, index) => (
                                            <SkeletonRow key={index} />
                                        )
                                    )
                                ) : deposits.length === 0 ? (
                                    // Show "empty" message if deposits array is empty
                                    <tr>
                                        <td
                                            colSpan="5"
                                            className="py-4 text-center"
                                        >
                                            <div className="flex justify-center gap-2 items-center">
                                                <div>
                                                    <ImNotification className="text-black dark:text-white" />
                                                </div>
                                                <div className="text-black dark:text-white">
                                                    No Recent Deposits
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    // Show actual data rows
                                    deposits.map((deposit, index) => (
                                        <tr key={index}>
                                            <td className="py-3 px-4 flex justify-center">
                                                {deposit.status === "1" ? (
                                                    <FaCheck className="text-green-500" />
                                                ) : (
                                                    <FaCheck className="text-red-500" />
                                                )}
                                            </td>
                                            <td className="py-3 px-4 text-center">
                                                {deposit.username.length > 8
                                                    ? deposit.username.slice(
                                                        0,
                                                        4
                                                    ) +
                                                    "..." +
                                                    deposit.username.slice(-4)
                                                    : deposit.username}
                                            </td>
                                            <td className="py-3 px-4 text-center">
                                                {deposit.num}
                                                {deposit.coinname}
                                            </td>
                                            <td className="py-3 px-4 text-center">
                                                {new Date(
                                                    parseInt(deposit.addtime) *
                                                    1000
                                                )
                                                    .toISOString()
                                                    .slice(0, 19)
                                                    .replace("T", " ")}
                                            </td>
                                            <td className="py-3 px-4 text-center">
                                                {deposit.txid.length > 8
                                                    ? deposit.txid.slice(0, 4) +
                                                    "..." +
                                                    deposit.txid.slice(-4)
                                                    : deposit.txid}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </section>
          </div>
        </div>
      ) : (
        <UncreditedDepositForm />
      )}
    </>
  );
};

export default Deposit;
