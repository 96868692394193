import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsPersonVcardFill } from "react-icons/bs";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import i18nIsoCountries from "i18n-iso-countries";
import { addYears } from "date-fns";

import "i18n-iso-countries/langs/en.json";
import { setUserDetails } from "store/actions/imageAction";
import { useDispatch, useSelector } from "react-redux";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const Information = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    console.log(state)

    const [nationality, setNationality] = useState("");
    const [residence, setResidence] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState(null);

    const [datePicker, setDatePicker] = useState(false);
    const maxDate = addYears(new Date(), -10);
    const datePickerRef = React.useRef(null);

    const handleLocalStorage = () => {
        const data = {
            nationality,
            residence,
            firstName,
            lastName,
            gender,
            dob,
        };
        localStorage.setItem("idVerificationBasicInfo", JSON.stringify(data));
    };

    const isFormValid = () =>
        nationality && residence && firstName && lastName && gender && dob;

    const isAnyFieldValid = () =>
        nationality || residence || firstName || lastName || gender || dob;

    const handleBack = () => {
        setNationality("");
        setResidence("");
        setFirstName("");
        setLastName("");
        setGender("");
        setDob(null);
        localStorage.removeItem("idVerificationBasicInfo");
        history.push("/user/account/identity-verification/information");
    };

    const handleReset = () => {
        setNationality("");
        setResidence("");
        setFirstName("");
        setLastName("");
        setGender("");
        setDob(null);
        localStorage.removeItem("idVerificationBasicInfo");
    };

    const handleNext = () => {
        if (isFormValid()) {
            handleLocalStorage();
            const userDetails = {
                nationality,
                residence,
                firstName,
                lastName,
                gender,
                dob,
            };
            dispatch(setUserDetails(userDetails));
            history.push("/user/account/identity-verification/id-upload");
        }
    };

    const countryNames = i18nIsoCountries.getNames("en", {
        select: "official",
    });

    const handleDateSelector = () => {
        setDatePicker(!datePicker);
    };

    const handleDateChange = (ranges) => {
        const { startDate } = ranges.selection;
        setDob(startDate);
        setDatePicker(false);
    };

    useEffect(() => {
        const storedData = localStorage.getItem("idVerificationBasicInfo");
        if (storedData) {
            const data = JSON.parse(storedData);
            setNationality(data.nationality);
            setResidence(data.residence);
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setGender(data.gender);
            setDob(new Date(data.dob));
        }
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                datePickerRef.current &&
                !datePickerRef.current.contains(event.target)
            ) {
                setDatePicker(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="flex flex-col justify-start w-full">
            <div className="flex items-center gap-2 mb-[35px]">
                <div>
                    <BsPersonVcardFill size={20} />
                </div>
                <div>
                    <span className="text-[22px] font-semibold">
                        Identity Verification
                    </span>
                </div>
            </div>
            <section className="flex flex-col gap-3">
                <div className="flex flex-col gap-5 p-4 bg-white rounded-md border border-[#F4F4F4]">
                    <div>
                        <span className="text-[14px] font-semibold">
                            Basic Info
                        </span>
                    </div>
                    <div className="relative flex items-center gap-2 justify-between">
                        {["Basic Info", "Upload ID", "Face Recognition"].map(
                            (step, index) => (
                                <div
                                    key={index}
                                    className={`user-select-none min-w-[30%] inline-flex gap-3 items-center px-4 py-3 border-[0.5px] rounded-md ${
                                        index === 0 ? "bg-[#EFB81C50]" : ""
                                    }`}
                                >
                                    <div className="flex w-[22px] h-[22px] border-[0.5px] bg-[#EFB81C] rounded-full">
                                        <span className="text-[12px] leading-none m-auto">
                                            {index + 1}
                                        </span>
                                    </div>
                                    <span className="text-[14px] font-semibold leading-none">
                                        {step}
                                    </span>
                                </div>
                            )
                        )}
                    </div>
                </div>

                <div className="user-basic-info__form-wrapper bg-[#FFFFFF] rounded-md border border-[#F4F4F4] p-4">
                    <form
                        className="w-full"
                        onSubmit={handleNext}
                        autoComplete="on"
                    >
                        <div className="form-fields-container flex flex-col gap-4">
                            {/* Nationality and Residence fields */}
                            <div className="form-field-wrapper w-full flex items-center justify-between">
                                <div className="form-field flex flex-col gap-1 w-[45%]">
                                    <label
                                        htmlFor="nationality"
                                        className="text-[14px] font-semibold"
                                    >
                                        Nationality
                                    </label>
                                    <select
                                        id="nationality"
                                        className="py-3 px-4 ps-16 block w-full border rounded-md text-[12px] font-medium focus:z-10 focus:outline-none focus:border-[#EFB81C] focus:ring-[#EFB81C] disabled:opacity-50 disabled:pointer-events-none"
                                        value={nationality}
                                        onChange={(e) =>
                                            setNationality(e.target.value)
                                        }
                                    >
                                        <option value="">Choose</option>
                                        {Object.entries(countryNames).map(
                                            ([code, name]) => (
                                                <option key={code} value={name}>
                                                    {name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                                <div className="form-field flex flex-col gap-1 w-[45%]">
                                    <label
                                        htmlFor="residence"
                                        className="text-[14px] font-semibold"
                                    >
                                        Country of Residence
                                    </label>
                                    <select
                                        id="residence"
                                        className="py-3 px-4 ps-16 block w-full border rounded-md text-[12px] font-medium focus:z-10 focus:outline-none focus:border-[#EFB81C] focus:ring-[#EFB81C] disabled:opacity-50 disabled:pointer-events-none"
                                        value={residence}
                                        onChange={(e) =>
                                            setResidence(e.target.value)
                                        }
                                    >
                                        <option value="">Choose</option>
                                        {Object.entries(countryNames).map(
                                            ([code, name]) => (
                                                <option key={code} value={name}>
                                                    {name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                            </div>

                            {/* First Name and Last Name Inputs */}
                            <div className="form-field-wrapper w-full flex items-center justify-between">
                                <div className="form-field flex flex-col gap-1 w-[45%]">
                                    <label
                                        htmlFor="firstName"
                                        className="text-[14px] font-semibold"
                                    >
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        placeholder="Please Enter your First Name"
                                        className="py-3 px-4 ps-16 block w-full border rounded-md text-[12px] font-medium focus:z-10 focus:outline-none focus:border-[#EFB81C] focus:ring-[#EFB81C] disabled:opacity-50 disabled:pointer-events-none"
                                        value={firstName}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^[A-Za-z]*$/.test(value)) {
                                                setFirstName(value);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="form-field flex flex-col gap-1 w-[45%]">
                                    <label
                                        htmlFor="lastName"
                                        className="text-[14px] font-semibold"
                                    >
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        placeholder="Please Enter your Last Name"
                                        className="py-3 px-4 ps-16 block w-full border rounded-md text-[12px] font-medium focus:z-10 focus:outline-none focus:border-[#EFB81C] focus:ring-[#EFB81C] disabled:opacity-50 disabled:pointer-events-none"
                                        value={lastName}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^[A-Za-z]*$/.test(value)) {
                                                setLastName(value);
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="flex items-center justify-between w-full">
                                {/* Gender Toggle Selector */}
                                <div className="form-field-wrapper w-[45%]">
                                    <div className="gender-selector">
                                        <div className="flex flex-col w-full">
                                            <div className="my-2">
                                                <span className="text-[14px] font-semibold">
                                                    Gender
                                                </span>
                                            </div>
                                            <div className="inline-flex gap-3">
                                                {["Male", "Female"].map(
                                                    (genderOption) => (
                                                        <div
                                                            key={genderOption}
                                                            className="inline-flex gap-2 py-2 px-4 rounded-md border"
                                                        >
                                                            <input
                                                                type="radio"
                                                                name="gender"
                                                                id="gender"
                                                                value={
                                                                    genderOption
                                                                }
                                                                checked={
                                                                    gender ===
                                                                    genderOption
                                                                }
                                                                onChange={(e) =>
                                                                    setGender(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                className="accent-[#EFB81C] border-none focus:shadow-none focus:z-10 focus:outline-none focus:border-[#EFB81C] focus:ring-[#EFB81C]"
                                                            />
                                                            <label
                                                                htmlFor="gender"
                                                                className="text-[12px] font-medium m-0"
                                                            >
                                                                {genderOption}
                                                            </label>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Date of Birth Selector */}
                                <div className="form-field-wrapper w-[45%]">
                                    <div
                                        className="dob-selector"
                                        ref={datePickerRef}
                                    >
                                        <div className="flex flex-col w-full relative">
                                            <label
                                                htmlFor="dateSelector"
                                                className="text-[14px] font-semibold mt-2"
                                            >
                                                Date of Birth
                                            </label>
                                            <button
                                                type="button"
                                                id="dateSelector"
                                                onClick={handleDateSelector}
                                                className="w-max py-2 px-4 border-[0.5px] bg-white rounded-md flex items-center justify-between"
                                            >
                                                <span className="text-[12px] font-medium">
                                                    {dob
                                                        ? dob.toDateString()
                                                        : "Select Date"}
                                                </span>
                                            </button>
                                            {datePicker ? (
                                                <DateRangePicker
                                                    ranges={[
                                                        {
                                                            startDate: dob,
                                                            endDate: dob,
                                                            key: "selection",
                                                        },
                                                    ]}
                                                    onChange={handleDateChange}
                                                    maxDate={maxDate}
                                                    moveRangeOnFirstSelection={
                                                        false
                                                    }
                                                    retainEndDateOnFirstSelection={
                                                        false
                                                    }
                                                    rangeColors={["#EFB81C"]}
                                                    className="z-[999] top-[35px] right-[10px]"
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex flex-row justify-center items-center gap-3 mt-10">
                            <button
                                type="button"
                                onClick={handleBack}
                                className="inline-flex bg-[#EFB81C] rounded-md px-10 py-2 transition-all ease-out duration-500"
                            >
                                <span className="text-[12px] font-semibold m-auto">
                                    Back
                                </span>
                            </button>
                            <button
                                type="reset"
                                className={`inline-flex bg-[#F4F4F4] rounded-md px-10 py-2 ${
                                    isAnyFieldValid()
                                        ? "hover:bg-[#D1001F]"
                                        : "opacity-50 cursor-not-allowed bg-[#F4F4F4]"
                                } transition-all ease-out duration-500`}
                                disabled={!isAnyFieldValid()}
                                onClick={handleReset}
                            >
                                <span className="text-[12px] font-semibold m-auto">
                                    Reset
                                </span>
                            </button>
                            <button
                                type="button"
                                className={`inline-flex rounded-md px-10 py-2 ${
                                    !isFormValid()
                                        ? "cursor-not-allowed opacity-50 bg-[#F4F4F4]"
                                        : "hover:bg-[#EFB81C] bg-[#EFB81C]"
                                } transition-all ease-out duration-500`}
                                disabled={!isFormValid()}
                                onClick={handleNext}
                            >
                                <span className="text-[12px] font-semibold m-auto">
                                    Next
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default Information;