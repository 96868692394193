import React, { useState } from "react";
import { FaCopy } from "react-icons/fa";
import QRCode from "qrcode.react";
import Modal from "react-modal";
import { BsQrCodeScan } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";

// Set the app element for accessibility
Modal.setAppElement("#root");

const DepositAddress = ({
    selectedNetwork,
    selectedCrypto,
    withdrawalAddress,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(withdrawalAddress);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };
    const [darkMode, setDarkMode] = useState(false);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    return (
        <>
            {selectedNetwork && selectedCrypto && (
                <div>
                    <div className="flex items-center space-x-2 ">
                        <div className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 dark:bg-transparent dark:text-white dark:border-gray-600">
                            <div className="flex justify-between items-center">
                                <span className="truncate text-[11px] sm:text-sm">
                                    {withdrawalAddress}
                                </span>
                                <div className="flex gap-3">
                                    {!isCopied ? (
                                        <FaCopy
                                            className="text-gray-500 hover:text-gray-700 cursor-pointer"
                                            onClick={copyToClipboard}
                                        />
                                    ) : (
                                        <TiTick className="text-green-500" />
                                    )}
                                    <button
                                        type="button"
                                        className=" text-slate-600 bg-none rounded-lg"
                                        onClick={openModal} // Open the modal when the button is clicked
                                    >
                                        <BsQrCodeScan />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal Component */}
                    <Modal
                        isOpen={showModal}
                        onRequestClose={closeModal}
                        contentLabel="QR Code Modal"
                        className="modal-content bg-white rounded-lg shadow-lg p-6 text-center max-w-md mx-auto relative"
                        overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
                    >
                        <button
                            onClick={closeModal}
                            className="absolute top-2 right-2 p-2 bg-gray-200 hover:bg-gray-300 rounded-full text-gray-700"
                        >
                            <i className="fa fa-times w-50 h-50"><RxCross2 /></i>
                        </button>
                        <div className="icon text-4xl text-green-500 mb-4">
                            <i className="fa fa-check-circle"></i>
                        </div>
                        <div className="title text-lg font-semibold mb-2 dark:text-black">
                            Scan QR code for deposit address
                        </div>
                        <div className="desc flex justify-center mb-4">
                            <QRCode value={withdrawalAddress} size={150} />
                        </div>
                        <button
                            onClick={closeModal}
                            className="mt-4 p-2 bg-gray-200 hover:bg-gray-300 rounded text-gray-700"
                        >
                            Close
                        </button>
                    </Modal>

                    <div className="mb-4 p-2 bg-transparent dark:bg-black ">
                        <div className="flex justify-between text-xs sm:text-sm mt-3">
                            <div><p className="text-[11px] sm:text-sm">Expected Arrival:</p></div>
                            <div>
                                <p className="text-[11px] sm:text-sm">{selectedNetwork ? selectedNetwork.value : ""}{" "}
                                    network confirmation/s</p>
                            </div>
                        </div>
                        <div className="flex justify-between text-xs sm:text-sm mt-3">
                            <div><p className="text-[11px] sm:text-sm">Coin:</p></div>
                            <div>
                                <p className="text-[11px] sm:text-sm">Only send{" "}
                                    {selectedCrypto ? selectedCrypto.label : ""}</p>
                            </div>
                        </div>
                        <div className="flex justify-between text-xs sm:text-sm mt-3">
                            <div><p className="text-[11px] sm:text-sm">Network:</p></div>
                            <div>
                                <p className="text-[11px] sm:text-sm">Make sure the network is{" "}
                                    {selectedNetwork ? selectedNetwork.label : ""}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DepositAddress;