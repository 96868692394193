import React, { useEffect, useState } from 'react';
import MyAssets from './MyAssets';
import RecentTransaction from './RecentTransaction';
import EstimatedAssets from './EstimatedAssets';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import fetchAssetsData from 'store/actions/AssetsUserAction';
import { FinanceUserSpot } from 'store/actions/AssetsUserAction';
function SpotAssets() {
  const [isVisible, setIsVisible] = useState(false); // State to track visibility

  // Function to toggle visibility
  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };
  
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, assetsData, error } = useSelector(
      (state) => state.assetsData
  );
  const { data  } = useSelector((state) => state.financeUserSpotReducer);


  const userID = useSelector((state) => state.authUser?.user?.ID);
  const userToken = useSelector((state) => state.authUser?.user?.TOKEN);

  useEffect(() => {
    dispatch(FinanceUserSpot(userID, userToken));
}, [dispatch, userID, userToken, history]);
  useEffect(() => {
      console.log("spot data-check", assetsData);
      console.log("spot data-type-check", assetsData.type);

  }, [assetsData]);
  console.log("data", data);
  return (
    <div className='px-0 lg:px-3'>
      <div className='max-w-[1400px] mx-auto !p-0'>
        <EstimatedAssets isVisible={isVisible} toggleVisibility={toggleVisibility} data={data}/>
        <MyAssets isVisible={isVisible} data={data}/>
        <RecentTransaction />
      </div>
    </div>
  );
}

export default SpotAssets;
