import Repository from "./Repository";
const ChartEndPoint = "/Chart/depth";

export default {
    // Function for getting Assets data
    getChartData(marketId) {
        return Repository.get(`${ChartEndPoint}`, {
            params: {
                marketid: marketId,
            },
        });
    },
};
