import React, { useState, useEffect } from "react";
import { ImCross } from "react-icons/im";
import ProgressBar from "./setWithdrawPaswordSteps/ProgressBar";
import EmailVerification from "./setWithdrawPaswordSteps/EmailVerification";
import TwoFA from "./setWithdrawPaswordSteps/TwoFA";
import SetPassword from "./setWithdrawPaswordSteps/SetPassword";
import SetSuccessfully from "./setWithdrawPaswordSteps/SetSuccessfully";
import ChangePassword from "./setWithdrawPaswordSteps/ChangePassword";
import { GoFileSubmodule } from "react-icons/go";

const SetWithDrawalPassword = ({ handleHideMenus }) => {
    const [step, setStep] = useState(1);
    const [existPassword, setExistPassword] = useState(false);

    const nextStep = () => {
        setStep((prevStep) => prevStep + 1);
    };

    useEffect(() => {
        const existingPassword = localStorage.getItem("existPassword");
        if (existingPassword === "true") {
            setExistPassword(true);
        }
    }, []);

    const handleExistingPassword = () => {
        setExistPassword(true);
        localStorage.setItem("existPassword", "true");
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return <EmailVerification nextStep={nextStep} />;
            case 2:
                return <TwoFA nextStep={nextStep} />;
            case 3:
                return existPassword ? (
                    <ChangePassword nextStep={nextStep} />
                ) : (
                    <SetPassword
                        nextStep={nextStep}
                        handleExistingPassword={handleExistingPassword}
                    />
                );
            case 4:
                return <SetSuccessfully onClose={handleHideMenus} />; // Ensure onClose is handled correctly
            default:
                return <EmailVerification nextStep={nextStep} />;
        }
    };

    return (
        <div className="bg-white rounded-xl w-[50%] py-3 px-6 relative">
            <div>
                <button onClick={handleHideMenus} className="float-right">
                    <ImCross className="text-xl text-[#D7D7D7]" />
                </button>
            </div>
            <div className="mt-2">
                <div className="flex items-center gap-4">
                    <GoFileSubmodule className="text-3xl text-yellow-500" />
                    <h1 className="font-semibold text-xl">Set withdrawal password</h1>
                </div>
                <ProgressBar step={step} existPassword={existPassword} />
                <div>{renderStep()}</div>
            </div>
        </div>
    );
};

export default SetWithDrawalPassword;