import React, { useEffect, useRef } from "react";
import { Radio } from "antd";
import styled from "styled-components";
import FiatDropdown from "./PostAdsDropdown2";
import { FaPlus, FaMinus } from "react-icons/fa";
import {
    setSelectedCrypto,
    setSelectedFiat,
    setResultValue,
    setSymbolValue,
    setCurrentValue,
    setIsFixed,
    setUserFloat,
    setVal,
} from "../../../store/reducers/stepOneSlice";
import { useDispatch, useSelector } from "react-redux";

const StyledRadio = styled(Radio)`
    .ant-radio-inner {
        border-color: #efb81c;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #efb81c;
        background-color: #efb81c;
    }

    .ant-radio-checked .ant-radio-inner::after {
        background-color: #fff;
    }

    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2);
    }

    .ant-radio-wrapper {
        color: #1f2937;
    }
`;

const StepOneContent = () => {
    const dispatch = useDispatch();
    const stepOneState = useSelector((state) => state.stepone) || {};
    const { selectedCrypto, selectedFiat, resultValue, symbolValue, currentValue, isFixed, userFloat,val } = stepOneState;

    useEffect(() => {
        if (val === 2) { 
            const newFloat = resultValue ? (currentValue * 100) / resultValue : 100; 
            dispatch(setUserFloat(newFloat));
        }
    }, [currentValue, resultValue, dispatch, val]);
    

    console.log("this is float", userFloat, currentValue, resultValue);

    useEffect(() => {
        if (resultValue == currentValue) {
            const parsedResultValue = parseFloat(resultValue);
            dispatch(setCurrentValue(!isNaN(parsedResultValue) ? parsedResultValue : 0));
        }
    }, [resultValue, dispatch]);

    const increment = () => {
        const parsedValue = parseFloat(currentValue) || 0;
    
        let newValue;
        if (val === 1) {
            newValue = parsedValue + 0.01;
            dispatch(setUserFloat(100));
        } else {
            const newUserFloat = userFloat + 0.1; 
            newValue = (resultValue * newUserFloat) / 100;
        }
    
        dispatch(setCurrentValue(newValue)); 
    };    
    
    const decrement = () => {
        const parsedValue = parseFloat(currentValue) || 0; 
    
        let newValue;
        if (val === 1) {
            newValue = parsedValue - 0.01;
            newValue = newValue > 0 ? newValue : 0; 
            dispatch(setUserFloat(100));
        } else {
            const newUserFloat = userFloat - 0.1; 
            newValue = (resultValue * newUserFloat) / 100; 
        }
    
        dispatch(setCurrentValue(newValue));
    };    

    const handleInputChange = (e) => {
        const value = parseFloat(e.target.value);
        if (!isNaN(value)) {
            dispatch(setCurrentValue(value.toFixed(2)));
        }
    };

    const formatValue = () => parseFloat(currentValue).toFixed(2);

    const handleCryptoChange = (crypto) => {
        dispatch(setSelectedCrypto(crypto));
    };

    const handleFiatChange = (fiat) => {
        dispatch(setSelectedFiat(fiat));
    };

    const previousResultValue = useRef(resultValue);
    const handleResultValueChange = (value) => {
        if (previousResultValue.current !== value) {
            dispatch(setResultValue(value));
            dispatch(setCurrentValue(value));
            previousResultValue.current = value;
        }
    };

    const handleResultSymbolChange = (value) => {
        dispatch(setSymbolValue(value));
    };

    const decodeHTMLEntities = (str) => {
        const txt = document.createElement("textarea");
        txt.innerHTML = str;
        return txt.value;
    };

    const handleRadioChange = (type) => {
        dispatch(setIsFixed(type === "fixed")); 
        const newVal = type === "fixed" ? 1 : 2;
        dispatch(setVal(newVal));
    };

    console.log(selectedCrypto, selectedFiat, resultValue, symbolValue);

    return (
        <div className="px-14 py-10">
            <div>
                <div className="h-32 border-b">
                    <FiatDropdown
                        onCryptoSelect={handleCryptoChange}
                        onFiatSelect={handleFiatChange}
                        onResultValueChange={handleResultValueChange}
                        onResultSymbolChange={handleResultSymbolChange}
                    />
                </div>
                <div>
                    <div className="my-8">
                        <p className="text-sm mb-2 font-light">Price Type</p>
                        <div className="flex gap-20">
                            <label>
                                <StyledRadio
                                    type="radio"
                                    checked={isFixed}
                                    onChange={() => handleRadioChange("fixed")}
                                />
                                <span className="ml-2 text-sm font-light">Fixed</span>
                            </label>
                            <label>
                                <StyledRadio
                                    type="radio"
                                    checked={!isFixed}
                                    onChange={() => handleRadioChange("float")}
                                />
                                <span className="ml-2 text-sm font-light">Floating</span>
                            </label>
                        </div>
                    </div>

                    <p className="text-sm mb-2 font-light">
                        {isFixed ? "Fixed Price" : "Floating Price Margin"}
                    </p>

                    <div className="relative border !w-[12rem] rounded px-2">
                        <input
                            type="text"
                            className="w-full h-10 text-center rounded focus:outline-none"
                            value={formatValue()}
                            onChange={handleInputChange}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center">
                            <button
                                onClick={increment}
                                className="px-2 py-1 mr-1 w-8 h-8 text-gray-700 bg-gray-200 hover:bg-gray-300 rounded"
                            >
                                <FaPlus className="text-gray-500" />
                            </button>
                        </div>
                        <div className="absolute inset-y-0 left-0 flex items-center">
                            <button
                                onClick={decrement}
                                className="px-2 py-1 ml-1 w-8 h-8 text-gray-700 bg-gray-200 hover:bg-gray-300 rounded"
                            >
                                <FaMinus className="text-gray-500" />
                            </button>
                        </div>
                    </div>
                </div>
                <p className="text-sm pb-3 my-3 font-light">
                    Pricing formula 278.04 * 100.00% ≈{" "}
                    <span className="font-medium text-sm">278.04</span> PKR
                </p>
                <div className="flex gap-8">
                    <div className="flex flex-col">
                        <p className="text-sm font-light">Your Price</p>
                        <p className="text-[24px] font-medium py-2">
                            {resultValue && !isNaN(resultValue)
                                ? `${
                                    isNaN(symbolValue)
                                        ? decodeHTMLEntities(symbolValue)
                                        : symbolValue
                                  } ${formatValue()}`
                                : "USD 0.00"}
                        </p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-sm font-light">Highest Order Price</p>
                        <p className="text-[24px] font-medium py-2">
                            {resultValue && !isNaN(resultValue)
                                ? `${
                                    isNaN(symbolValue)
                                        ? decodeHTMLEntities(symbolValue)
                                        : symbolValue
                                  } ${parseFloat(resultValue).toFixed(2)}`
                                : "USD 0.00"}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepOneContent;
