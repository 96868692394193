import { useState } from "react";

export const useSort = (initialData) => {
    const [data, setData] = useState(initialData);
    const [sortOrder, setSortOrder] = useState({}); // Use an object to track sort orders for each key

    const sortData = (key) => {
        let sortedData;
        const currentOrder = sortOrder[key] || "none"; // Default to "none" if no sort order exists for the key

        if (currentOrder === "none") {
            // Sort ascending
            sortedData = [...initialData].sort((a, b) => a[key] - b[key]);
            setSortOrder({ ...sortOrder, [key]: "asc" });
        } else if (currentOrder === "asc") {
            // Sort descending
            sortedData = [...initialData].sort((a, b) => b[key] - a[key]);
            setSortOrder({ ...sortOrder, [key]: "desc" });
        } else if (currentOrder === "desc") {
            // Reset to initial state
            sortedData = initialData;
            setSortOrder({ ...sortOrder, [key]: "none" });
        }

        setData(sortedData);
    };

    return { data, sortOrder, sortData };
};
