import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import EarnFaqs from "./EarnFaqs";
import MainTable from "./MainTable";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const EarnSavings = () => {
    const location = useLocation();
    const {coin,id,value} = location.state || {};
    return (
        <div className="">
            <div className="earn-savings">
                <div className="bg-[#f5f7fa]">
                    <div className="container py-[28px] !px-10">
                        <div className="flex gap-2 items-center">
                            <Link
                                to="/earn/home"
                                className="text-[#71757a] text-[14px] font-semibold"
                            >
                                BuyCex Earn
                            </Link>
                            <button>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="6"
                                    height="10"
                                    fill="none"
                                    viewBox="0 0 6 10"
                                    class="breadcrumbs_arrow__vbTn7"
                                    data-rtl="true"
                                >
                                    <path
                                        fill="#ADB1B8"
                                        d="M.528.529c.26-.26.683-.26.943 0l4 4c.26.26.26.682 0 .942l-4 4a.667.667 0 1 1-.943-.942l3.529-3.53L.528 1.472a.667.667 0 0 1 0-.942Z"
                                    ></path>
                                </svg>
                            </button>
                            <button className="text-[14px] font-semibold">
                                BuyCex Savings
                            </button>
                        </div>
                    </div>
                </div>
                <div className="earn-overview-header h-80">
                    <div className="container !px-10 h-full flex items-center justify-between">
                        <div>
                            <h1 className="text-[32px] font-bold">
                                BuyCex Savings
                            </h1>
                            <p className="text-[#adb1b8] text-[24px] font-medium max-w-[680px]">
                                Your one-stop investment center with a suite of
                                products. Enjoy greater flexibility and higher
                                returns.
                            </p>
                            <div className="mt-10 flex gap-4">
                                <button className="px-6 py-2 border-[1px] border-solid border-[#d5dae0] rounded-[8px] text-[16px] font-semibold flex items-center gap-1">
                                    <span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            fill="none"
                                            viewBox="0 0 18 18"
                                        >
                                            <g clip-path="url(#clip0_2150_83682)">
                                                <path
                                                    fill="#121214"
                                                    fill-rule="evenodd"
                                                    d="M2.25 9a6.75 6.75 0 1 1 13.5 0 6.75 6.75 0 0 1-13.5 0ZM9 .75a8.25 8.25 0 1 0 0 16.5A8.25 8.25 0 0 0 9 .75ZM7.916 5.376A.75.75 0 0 0 6.75 6v6a.75.75 0 0 0 1.166.624l4.5-3a.75.75 0 0 0 0-1.248l-4.5-3ZM10.648 9 8.25 10.599V7.4L10.648 9Z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_2150_83682">
                                                    <path
                                                        fill="#fff"
                                                        d="M0 0h18v18H0z"
                                                    ></path>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    Video
                                </button>
                                <button className="px-6 py-2 border-[1px] border-solid border-[#d5dae0] rounded-[8px] text-[16px] font-semibold flex items-center gap-1">
                                    <span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            fill="none"
                                            viewBox="0 0 18 18"
                                        >
                                            <g id="left_icon">
                                                <path
                                                    id="Union"
                                                    fill="#121214"
                                                    fill-rule="evenodd"
                                                    d="M3 4.125a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v7.688H4.031c-.367 0-.716.078-1.031.218V4.125ZM1.508 14.549a2.531 2.531 0 0 0 2.523 2.326H13.5a.75.75 0 0 0 0-1.5H4.031a1.031 1.031 0 1 1 0-2.063H15.75a.75.75 0 0 0 .75-.75V4.126a3 3 0 0 0-3-3h-9a3 3 0 0 0-3 3v10.312c0 .037.003.074.008.11Zm7.207-9.763c-.51.037-.825.308-.899.53a.675.675 0 1 1-1.28-.427c.3-.904 1.223-1.388 2.082-1.45.902-.065 1.913.307 2.512 1.305.29.485.406.979.361 1.459a2.427 2.427 0 0 1-.48 1.21c-.473.644-1.19 1.082-1.694 1.299a.675.675 0 1 1-.532-1.241c.37-.159.854-.47 1.137-.857a1.07 1.07 0 0 0 .225-.537c.015-.164-.016-.374-.175-.638a1.312 1.312 0 0 0-1.257-.653Zm1.036 5.152a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </g>
                                        </svg>
                                    </span>
                                    Guide
                                </button>
                            </div>
                        </div>
                        <div
                            style={{ background: "rgba(245, 247, 250, .8" }}
                            className="border-[1px] border-solid border-[#f5f7fa] w-[500px] rounded-[14px] shadow-lg p-6 flex items-center justify-between gap-8"
                        >
                            <div className="flex-1 flex flex-col gap-4 ">
                                <p className="text-[#adb1b8] text-[14px]">
                                    Total Staked
                                </p>
                                <div className="flex items-center gap-1">
                                    <p className="text-[16px] font-semibold">
                                        $000{" "}
                                        <span className="text-[16px] font-normal">
                                            USDT
                                        </span>
                                    </p>
                                    <button></button>
                                </div>
                                <p className=" ">
                                    <p className="text-[14px] font-semibold  flex items-center gap-1 ">
                                        Auto Savings
                                        <span className="text-[14px] font-semibold">
                                            <svg
                                                viewBox="64 64 896 896"
                                                focusable="false"
                                                data-icon="question-circle"
                                                width="1em"
                                                height="1em"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                                                <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path>
                                            </svg>
                                        </span>
                                    </p>
                                </p>
                                <button className="w-full bg-[#fff] border-[1px] border-solid border-[#d5dae0] text-[14px] h-[40px] rounded-[8px] font-semibold flex items-center justify-center gap-1">
                                    <span>
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g id="icon_earphone">
                                                <path
                                                    id="Union"
                                                    d="M12.8334 2.33337C12.8334 2.05723 12.6096 1.83337 12.3334 1.83337C12.0573 1.83337 11.8334 2.05723 11.8334 2.33337V3.50004H10.6667C10.3906 3.50004 10.1667 3.7239 10.1667 4.00004C10.1667 4.27618 10.3906 4.50004 10.6667 4.50004H11.8334V5.66671C11.8334 5.94285 12.0573 6.16671 12.3334 6.16671C12.6096 6.16671 12.8334 5.94285 12.8334 5.66671V4.50004H14.0001C14.2762 4.50004 14.5001 4.27618 14.5001 4.00004C14.5001 3.7239 14.2762 3.50004 14.0001 3.50004L12.8334 3.50004V2.33337Z"
                                                    fill="#121214"
                                                ></path>
                                                <g id="Union_2">
                                                    <path
                                                        d="M2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C8.34233 2.5 8.67692 2.53121 9.00122 2.59082C9.27281 2.64074 9.53345 2.46104 9.58337 2.18945C9.63329 1.91786 9.45359 1.65722 9.182 1.6073C8.79835 1.53678 8.40325 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 7.66478 14.4746 7.33518 14.4255 7.01308C14.3839 6.74009 14.1288 6.55252 13.8559 6.59413C13.5829 6.63574 13.3953 6.89077 13.4369 7.16376C13.4784 7.43616 13.5 7.7154 13.5 8C13.5 11.0376 11.0376 13.5 8 13.5C4.96243 13.5 2.5 11.0376 2.5 8Z"
                                                        fill="#121214"
                                                    ></path>
                                                </g>
                                                <path
                                                    id="å½¢ç¶ç»åå¤ä»½ 9"
                                                    d="M10.4013 5.33337V6.53329H8.57357V7.31776C10.0536 7.38489 11.1667 7.67469 11.1667 8.0219C11.1667 8.36906 10.0536 8.65876 8.57357 8.72598V11.2425H7.27133L7.27133 8.72657C5.78555 8.66035 4.66675 8.37013 4.66675 8.0219C4.66675 7.67368 5.78556 7.38345 7.27133 7.31724V6.5333H5.44355V5.33337L10.4013 5.33337ZM8.57357 8.42551C9.74188 8.37218 10.607 8.18007 10.607 7.95105C10.607 7.72205 9.74178 7.52985 8.57245 7.47665V8.20168C8.36521 8.2127 8.14404 8.21856 7.91561 8.21856C7.69076 8.21856 7.47396 8.21279 7.27016 8.20226V7.47449C6.09617 7.52759 5.22534 7.71979 5.22534 7.94996C5.22534 8.18007 6.09603 8.37285 7.27016 8.42537L7.3475 8.42869H7.34852C7.4262 8.43166 7.50505 8.43411 7.5851 8.43588H7.59252L7.66007 8.43728H7.67971L7.73625 8.43804H8.09802L8.15515 8.43728H8.17561L8.248 8.43579H8.25135C8.30532 8.43459 8.35861 8.43306 8.41127 8.43127H8.41893L8.48973 8.42869H8.49807L8.57357 8.42551Z"
                                                    fill="#F7A600"
                                                ></path>
                                            </g>
                                        </svg>
                                    </span>
                                    My Holdings
                                </button>
                            </div>
                            <div className="flex-1 flex flex-col  items-end gap-4">
                                <p className="text-[#adb1b8] text-[14px]">
                                    Yesterday's Yields
                                </p>
                                <div className="flex items-center gap-1">
                                    <p className="text-[16px] font-semibold text-[#20b26c]">
                                        $000{" "}
                                        <span className="text-[16px] font-normal">
                                            USDT
                                        </span>
                                    </p>
                                    <button></button>
                                </div>
                                <p className=" ">
                                    <p className="text-[14px] font-semibold  flex items-center gap-1 ">
                                        Enable Now
                                        <span className="text-[14px] font-semibold">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                fill="none"
                                                viewBox="0 0 20 20"
                                            >
                                                <g id="right_icon">
                                                    <path
                                                        id="Union"
                                                        fill="#121214"
                                                        fill-rule="evenodd"
                                                        d="m13.823 10.835-4.41 4.41a.833.833 0 1 0 1.178 1.18l5.828-5.828a.842.842 0 0 0 0-1.19L10.59 3.578a.833.833 0 0 0-1.178 1.179l4.41 4.41H4.116c-.431 0-.78.373-.78.834 0 .46.349.833.78.833h9.707Z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </p>
                                </p>
                                <button className="w-full bg-[#fff] border-[1px] border-solid border-[#d5dae0] text-[14px] h-[40px] rounded-[8px] font-semibold flex items-center justify-center gap-1">
                                    <span>
                                        <svg
                                            width="17"
                                            height="16"
                                            viewBox="0 0 17 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g id="icon_earphone">
                                                <path
                                                    id="Union"
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M13.2407 4.61727C13.3369 4.74549 13.3888 4.90144 13.3888 5.06172L13.3888 10.8148C13.3888 11.2239 13.0572 11.5555 12.6481 11.5555L10.9444 11.5555H10.0555L7.01847 11.5555C6.60937 11.5555 6.27773 11.2239 6.27773 10.8148L6.27773 3.18517C6.27773 2.77607 6.60937 2.44443 7.01847 2.44443L11.2407 2.44443C11.4738 2.44443 11.6934 2.5542 11.8333 2.74073L13.2407 4.61727ZM10.0555 12.4444L7.01847 12.4444C6.11845 12.4444 5.38884 11.7148 5.38884 10.8148L5.38884 4.44443H4.3518C3.9427 4.44443 3.61106 4.77607 3.61106 5.18517L3.61106 12.8148C3.61106 13.2239 3.9427 13.5555 4.3518 13.5555L9.31476 13.5555C9.72386 13.5555 10.0555 13.2239 10.0555 12.8148V12.4444ZM10.9444 12.4444V12.8148C10.9444 13.7148 10.2148 14.4444 9.31476 14.4444L4.3518 14.4444C3.45178 14.4444 2.72217 13.7148 2.72217 12.8148L2.72217 5.18517C2.72217 4.28515 3.45178 3.55554 4.3518 3.55554H5.38884V3.18517C5.38884 2.28515 6.11845 1.55554 7.01847 1.55554L11.2407 1.55554C11.7536 1.55554 12.2366 1.79704 12.5444 2.20739L13.9518 4.08394C14.1634 4.36602 14.2777 4.70911 14.2777 5.06172L14.2777 10.8148C14.2777 11.7148 13.5481 12.4444 12.6481 12.4444H10.9444Z"
                                                    fill="#121214"
                                                ></path>
                                                <path
                                                    id="Union_2"
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M10.4999 6.5C10.7761 6.5 10.9999 6.27614 10.9999 6C10.9999 5.72386 10.7761 5.5 10.4999 5.5L7.83325 5.5C7.55711 5.5 7.33325 5.72386 7.33325 6C7.33325 6.27614 7.55711 6.5 7.83325 6.5H10.4999ZM11.8333 8.5C12.1094 8.5 12.3333 8.27614 12.3333 8C12.3333 7.72386 12.1094 7.5 11.8333 7.5L7.83325 7.5C7.55711 7.5 7.33325 7.72386 7.33325 8C7.33325 8.27614 7.55711 8.5 7.83325 8.5H11.8333Z"
                                                    fill="#F7A600"
                                                ></path>
                                            </g>
                                        </svg>
                                    </span>
                                    Orders
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* show the card data  */}
                <div className="container !px-10 mt-10 mb-10">
                    <MainTable />
                </div>
                <div className=" container !px-10">
                    <EarnFaqs />
                </div>
            </div>
        </div>
    );
};

export default EarnSavings;
