import React from "react";
import { IoStarSharp } from "react-icons/io5";

import image1 from '../../assets/img/diversity-image1.png'
import image2 from '../../assets/img/diversity-image2.png'
import { FaArrowRight } from "react-icons/fa6";

export default function Diversity() {
  const cardsData = [
    {
        img: image1,

      id: 1,
      author: "Perpetual",
      title: 'Trade Over 457 Cryptos',

      description: `With our USDT, USDC, and coin-settled perpetual contracts at up to 125x leverage.`,
    },
    {
        img: image1,

      id: 2,
      author: "Futures",
      title: 'Hedge and Speculate on Future Prices',

      description: `With USDC and coin-settled Weekly, Monthly and Quarterly Futures contracts.`,
    },
    {
        img: image2,
      id: 3,
      author: "Options",
      title: 'Diversify Trading Strategies & Control Risks',
      description: `With Daily, Monthly and Quarterly Options for BTC, ETH and SOL, settled in USDC.`,
    },

  ];

  return (
    <section
      id="testimonials"
      className="flex flex-col items-center justify-center w-full py-5 "
    >
      <h6 className="text-[26px] text-[#EFB81C] capitalize sm:text-[32px] md:text-[40px] font-bold  pb-5">
        Diversity Your Trades
      </h6>

    

      <div className="flex flex-col flex-wrap items-center justify-center sm:flex-row gap-x-5 gap-y-5">
        {cardsData.map((data) => (
          <div
            key={data.id}
            className={`p-3 flex flex-col items-center justify-center text-center gap-y-3 max-w-[350px] font-[400]   ${data.id == '3' ?  'border-black md:border-r-[1px]': data.id =='2' ? 'md:border-r-[1px] border-white/20 md:!pr-4' : 'md:border-r-[1px] border-white/20'}`}
          >
            <img src={data.img} alt="" className="w-full" />


            <h6 className="font-semibold text-[22px] text-[#EFB81C]">{data.author}</h6>
            <h6 className="font-medium mb-3 text-black text-[16px]">{data.title}</h6>
            <div className="mb-2 text-gray-600">{data.description}</div>
            <button className="border-[1px] border-white/40 p-3 rounded-full"><FaArrowRight className="text-[#EFB81C]"/></button>
          </div>
        ))}
      </div>
    </section>
  );
}
