import React from 'react';
import image20Million from '../../assets/img/Aboutsection4.png'; 

const LaunchProjectsSection = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between p-6 bg-gray-50 mx-4 md:mx-10">
      <div className="max-w-xl mb-6 md:mb-0">
        <h2 className="text-2xl font-bold mb-4">Launch Your Projects on Startup</h2>
        <p className="text-lg mb-6">
          BUYCEX Startup is one of the world's largest project launch platforms! 
          Join our platform now and give your project more exposure and development opportunities.
        </p>
        <button className="bg-[#EFB81C] text-white px-6 py-2 rounded-md hover:bg-[#EFB81C]">
          Apply Now
        </button>
      </div>
      <div className="max-w-md">
        <img 
          src={image20Million} 
          alt="Launch Illustration" 
          className="w-full h-auto"
        />
      </div>
    </div>
  );
};

export default LaunchProjectsSection;
