import React, { useState } from "react";
import { Modal, Input, Form } from "antd";
import { CgLoadbar } from "react-icons/cg";
import styled from "styled-components";
import { IoMdInformationCircle } from "react-icons/io";
import Confirm from "assets/img/Delete-bankinfo.svg";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { createGlobalStyle } from "styled-components";

function BankTransfer() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const showCancelModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        console.log("Cancel confirmed");
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        console.log("Cancel aborted");
    };

    const onFinish = (values) => {
        console.log("Form values:", values);
        // Proceed with form submission
    };

    return (
        <div className="bg-[#FAFAFA]">
            <div className="shadow-md bg-white">
                <p className="text-[22px] font-bold py-6 container">
                    Add payment method
                </p>
            </div>

            <div className="bg-white mb-6 relative bottom-3 rounded-md m-6 h-full shadow-md flex justify-center">
                <div className="w-96 p-6 md:p-0 lg:p-0 md:min-w-96 lg:w-1/3 my-5 pb-5">
                    <div
                        className="flex items-baseline p-4 mb-4 text-sm text-[#EFB81C] rounded-lg bg-[#D89F0014] dark:bg-[#EFB81C] dark:text-[#EFB81C]"
                        role="alert"
                    >
                        <IoMdInformationCircle className="flex-shrink-0 w-3 h-3 me-3 text-[#D89F00]" />
                        <span className="text-[12px] leading-normal">
                            Tips: The added payment method will be shown to the
                            buyer during the transaction to accept fiat
                            transfers. Please ensure that the information is
                            correct, real, and matches your KYC information on
                            Buycex.
                        </span>
                    </div>

                    <div className="py-2 mb-5 cursor-pointer flex items-center">
                        <CgLoadbar className="rotate-90 text-[#D89F00] text-xl" />
                        <h3 className="text-md font-semibold ml-2">
                            Bank Transfer
                        </h3>
                    </div>

                    <Form form={form} onFinish={onFinish} layout="vertical">
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                { required: true, message: "Name is required" },
                            ]}
                        >
                            <StyledInput placeholder="Enter Your Name" />
                        </Form.Item>

                        <Form.Item
                            label="Bank Card/Account Number"
                            name="accountNumber"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Bank Card/Account Number is required",
                                },
                            ]}
                        >
                            <StyledInput placeholder="Please enter your bank account/card number" />
                        </Form.Item>

                        <Form.Item
                            label="Bank Name"
                            name="bankName"
                            rules={[
                                {
                                    required: true,
                                    message: "Bank Name is required",
                                },
                            ]}
                        >
                            <StyledInput placeholder="Enter the name of your bank" />
                        </Form.Item>

                        <Form.Item
                            label="Account opening branch (Optional)"
                            name="branch"
                        >
                            <StyledInput placeholder="Enter bank branch information" />
                        </Form.Item>

                        <Form.Item label="IBAN (Optional)" name="iban">
                            <StyledInput placeholder="IBAN" />
                        </Form.Item>

                        <p className="text-sm font-light pb-8 pt-2 text-[#707A8A]">
                            <span className="text-sm font-[600]">Warning:</span>{" "}
                            Please make sure you add your bank card number for
                            instant payments. Do not include details of other
                            banks or payment methods. You must add the payment
                            details of the selected bank.
                        </p>

                        <Form.Item>
                            <div className="flex w-full gap-2">
                                <button
                                    className="bg-gray-200 py-3 rounded-md font-medium w-1/2"
                                    type="button"
                                    onClick={showCancelModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bg-[#EFB81C] py-3 rounded-md font-medium w-1/2"
                                    type="submit"
                                >
                                    Confirm
                                </button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>

            {/* Ant Design Modal for Cancel Confirmation */}
            <StyledModal
                title=""
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                okText="Yes"
                cancelText="No"
            >
                <div className="flex items-center flex-col gap-3">
                    <img className="w-[80px] h-[80px]" src={Confirm} />
                    <p className="font-medium text-[20px]">Confirm Exit?</p>
                    <p className="mb-6 text-sm">
                        Your changes will not be saved
                    </p>
                </div>
                <div className="flex w-full gap-2">
                    <Link
                        className="w-1/2 text-center bg-gray-200 hover:bg-gray-100 hover:text-black py-3 rounded-md font-medium"
                        to="#"
                    >
                        <button onClick={handleCancel}>Not Now</button>
                    </Link>
                    <Link
                        className="w-1/2 text-center bg-[#EFB81C] hover:text-black py-3 rounded-md font-medium"
                        to="/trade/p2p/profile"
                    >
                        <button>Exit</button>
                    </Link>
                </div>
            </StyledModal>
            <GlobalStyle />
        </div>
    );
}
const StyledInput = styled(Input)`
    height: 48px; /* Adjust this value as needed */
    padding: 0 12px; /* Adjust padding if needed */
    font-size: 14px; /* Adjust font size if needed */
`;
// Styled-component targeting the outer modal div
const GlobalStyle = createGlobalStyle`
  .ant-form-item-required::before {
    content: none !important;
  }
  .ant-form-item-explain-error {
    font-size: 12px !important;
    color:#CF304A !important;
    padding-bottom:20px;
  }
  .ant-form-item-label{
  padding: 0 0 4px !important;
  }
    .ant-input:focus {
    border-color: #EFB81C !important;
    box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.3) !important;
}
    .ant-input:hover {
    border-color: #EFB81C !important;
  }
`;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        width: 400px !important;
    }

    .ant-modal {
        width: 400px !important;
        max-width: 400px !important;
        display: flex;
        justify-content: center;
    }
    div[tabindex="-1"] {
        display: flex;
        outline: none;
        justify-content: center;
        align-items: center;
    }
    /* Hide the asterisk from required fields */
    .ant-form-item-required::before {
        content: none;
    }
`;

export default BankTransfer;
