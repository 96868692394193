import Header from "components/header/Header";
import React, { useEffect, useState, useRef } from "react";
import {
    IoIosArrowDown,
    IoIosArrowUp,
    IoIosArrowForward,
} from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import Footer from "components/footer/Footer";
import { FaFileCircleQuestion, FaFileLines } from "react-icons/fa6";
import { ImUsers } from "react-icons/im";
import { GiSellCard } from "react-icons/gi";
import { BiSolidCard } from "react-icons/bi";
import { RiVideoFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { CgMoreO } from "react-icons/cg";

function P2pLayout(props) {
    const [selectedGuide, setSelectedGuide] = useState("P2P Help Center");
    const [selectedAds, setSelectedAds] = useState("Post Ads");
    // const [openDropdown, setOpenDropdown] = useState(""); // State to track the open dropdown
    const location = useLocation();
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredAds, setIsHoveredAds] = useState(false);
    const [isHoveredOrders, setIsHoveredOrders] = useState(false);
    const buttonRef = useRef(null);
    const isP2pPage = location.pathname.startsWith('/trade/p2p');
    const approved = useSelector(
        (state) => state.authCheck.authStatus.idcardauth
    );
    const email = useSelector((state) => state.userInfo.userProfile.email);
    const mobile = useSelector((state) => state.userInfo.userProfile.mobile);
    console.log(email, mobile, approved)
    const verified = useSelector((state) => state.merchantBit.bit)
    console.log(verified)

    const targetPath = email && mobile && approved == 1
    ? "/trade/p2p/post-ads"
    : "/trade/p2p/apply-merchant";

    const targetedPath =  email && mobile && approved == 1
    ? "/trade/p2p/my-ads"
    : "/trade/p2p/apply-merchant";

    useEffect(() => {
        if (TOKEN && ID) {
            setIsAuthenticated(true);
            console.log("Here we have:", TOKEN, ID);
        } else {
            setIsAuthenticated(false);
        }
    }, [TOKEN, ID]);

    const handleGuideSelect = (guideText) => {
        setSelectedGuide(guideText);
        setOpenDropdown(""); // Close dropdown after selection
    };

    const handleAdsSelect = (adsText) => {
        setSelectedAds(adsText);
        setOpenDropdown(""); // Close dropdown after selection
    };

  

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setOpenDropdown(""); // Close dropdown if clicked outside
            setIsMenuOpen("");
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const [openDropdown, setOpenDropdown] = useState("");
    const dropdownRef = useRef(null);

  
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setOpenDropdown("");
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, [openDropdown]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleIconClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [isUserGuideOpen, setIsUserGuideOpen] = useState(false);
    const [isAdsOpen, setIsAdsOpen] = useState(false);

    const toggleUserGuide = () => setIsUserGuideOpen(!isUserGuideOpen);
    const toggleAds = () => setIsAdsOpen(!isAdsOpen);
    return (
        <>
            <Header />
            {/* {isAuthenticated && (
        <div className="p-4 font-bold text-center text-red-500">
          Register Please
        </div>
      )} */}
            <div className="flex items-end mt-6 border-b">
                <div
                    className="container !pl-[1rem] !pr-[1rem] flex justify-between gap-5"
                    style={{ alignItems: "self-start" }}
                >
                    <div className="flex h-10 gap-5">
                        <Link
                            to="/trade/express"
                            className={`relative cursor-pointer pb-2 text-[14px] hover:text-[#EFB81C] ${
                                location.pathname === "/trade/express"
                                    ? "font-semibold"
                                    : "text-[#707A8A]"
                            }`}
                        >
                            Express
                            {location.pathname === "/trade/express" && (
                                <span className="absolute left-1/2 bottom-0 w-[10px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2"></span>
                            )}
                        </Link>

                        <Link
                            to="/trade/p2p"
                            className={`relative cursor-pointer pb-2 text-[14px] hover:text-[#EFB81C] ${
                                location.pathname.startsWith("/trade/p2p")
                                    ? "font-semibold"
                                    : "text-[#707A8A]"
                            }`}
                        >
                            P2P
                            {location.pathname.startsWith("/trade/p2p") && (
                                <span className="absolute left-1/2 bottom-0 w-[10px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2"></span>
                            )}
                        </Link>
                        {isAuthenticated && (
                            <Link
                                to="/fiat/deposit"
                                className={`relative min-w-fit cursor-pointer pb-2 text-[14px] hover:text-[#EFB81C] ${
                                    location.pathname === "/fiat/deposit"
                                        ? "font-semibold"
                                        : "text-[#707A8A]"
                                }`}
                            >
                                Fiat Topup
                                {location.pathname === "/fiat/deposit" && (
                                    <span className="absolute left-1/2 bottom-0 w-[10px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2"></span>
                                )}
                            </Link>
                        )}
                        {isAuthenticated && (
                            <Link
                                to="/trade/third-party"
                                className={`relative cursor-pointer pb-2 text-[14px] hover:text-[#EFB81C] ${
                                    location.pathname === "/trade/third-party"
                                        ? "font-semibold"
                                        : "text-[#707A8A]"
                                }`}
                            >
                                Third Party
                                {location.pathname === "/trade/third-party" && (
                                    <span className="absolute left-1/2 bottom-0 w-[10px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2"></span>
                                )}
                            </Link>
                        )}
                        {isAuthenticated && (
                            <Link
                                to="/crypto/deposit"
                                className={`relative cursor-pointer pb-2 text-[14px] hover:text-[#EFB81C] ${
                                    location.pathname === "/crypto/deposit"
                                        ? "font-semibold"
                                        : "text-[#707A8A]"
                                }`}
                            >
                                Deposit
                                {location.pathname === "/crypto/deposit" && (
                                    <span className="absolute left-1/2 bottom-0 w-[10px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2"></span>
                                )}
                            </Link>
                        )}
                    </div>
                    <div>


                    {isP2pPage && (
                        <div className="items-center hidden gap-5 pb-4 md:hidden lg:flex">
                            {/* User Guide Dropdown */}
                            <div
                                className="relative inline-block text-left text-[14px]"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                {isAuthenticated && (
                                    <button
                                        ref={buttonRef}
                                        type="button"
                                        className={`flex items-center gap-1 ${
                                            isHovered || openDropdown
                                                ? "text-[#EFB81C]"
                                                : ""
                                        } hover:text-[#EFB81C]`}
                                    >
                                        <FaFileCircleQuestion />
                                        <span className="text-[14px] font-semibold mr-1">
                                            {selectedGuide}
                                        </span>
                                        {isHovered || openDropdown ? (
                                            <IoIosArrowUp className="text-[0.7rem]" />
                                        ) : (
                                            <IoIosArrowDown className="text-[0.7rem]" />
                                        )}
                                    </button>
                                )}

                                {(isHovered || openDropdown) && (
                                    <div
                                        className="absolute left-0 z-50 w-full"
                                        style={{ top: "100%" }}
                                    >
                                        <div
                                            className="mt-4 bg-white rounded-md shadow-lg w-52 ring-1 ring-black ring-opacity-5"
                                            ref={dropdownRef}
                                            onMouseEnter={() =>
                                                setIsHovered(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsHovered(false)
                                            }
                                        >
                                            <div className="py-1" role="none">
                                                <h6
                                                    className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl"
                                                    onClick={() =>
                                                        handleGuideSelect(
                                                            "How to Buy/Sell"
                                                        )
                                                    }
                                                >
                                                    <div className="flex items-center gap-1 px-2 text-[14px]">
                                                        <GiSellCard /> How to
                                                        Buy/Sell
                                                    </div>
                                                </h6>
                                                <h6
                                                    className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl"
                                                    onClick={() =>
                                                        handleGuideSelect(
                                                            "Placing an Order"
                                                        )
                                                    }
                                                >
                                                    <div className="flex items-center gap-1 px-2 text-[14px]">
                                                        <BiSolidCard /> Placing
                                                        an Order
                                                    </div>
                                                </h6>
                                                <h6
                                                    className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl"
                                                    onClick={() =>
                                                        handleGuideSelect(
                                                            "Video Tutorial"
                                                        )
                                                    }
                                                >
                                                    <div className="flex items-center gap-1 px-2 text-[14px]">
                                                        <RiVideoFill className="rounded-full" />
                                                        Video Tutorial
                                                    </div>
                                                </h6>
                                                <h6
                                                    className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl"
                                                    onClick={() =>
                                                        handleGuideSelect(
                                                            "P2P Trading FAQ"
                                                        )
                                                    }
                                                >
                                                    <div className="flex items-center gap-1 px-2 text-[14px]">
                                                        <RiVideoFill className="rounded-full" />
                                                        P2P Trading FAQ
                                                    </div>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* Orders Dropdown */}
                            <div
                                className="relative inline-block text-left text-[14px]"
                                onMouseEnter={() => setIsHoveredOrders(true)}
                                onMouseLeave={() => setIsHoveredOrders(false)}
                                ref={dropdownRef}
                            >
                                <Link
                                    to="/trade/p2p/orders"
                                    className={`cursor-pointer text-[14px] font-semibold hover:text-[#EFB81C] ${
                                        location.pathname ===
                                        "/trade/p2p/orders"
                                            ? "border-b-[3px] border-[#EFB81C] font-bold"
                                            : ""
                                    }`}
                                >
                                    {isAuthenticated && (
                                        <button
                                            type="button"
                                            className="flex items-center gap-1 cursor-pointer text-[14px] font-semibold hover:text-[#EFB81C]"
                                        >
                                            <FaFileLines /> Orders
                                        </button>
                                    )}
                                </Link>

                                {isHoveredOrders && (
                                    <div
                                        className="absolute left-0 z-50 w-full"
                                        style={{ top: "100%" }}
                                    >
                                        <div
                                            className="mt-4 bg-white rounded-md shadow-lg w-80 h-96 ring-1 ring-black ring-opacity-5"
                                            onMouseEnter={() =>
                                                setIsHoveredOrders(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsHoveredOrders(false)
                                            }
                                        >
                                            <div className="py-1" role="none">
                                                <div className="flex justify-between pt-2 pb-4 pl-4 pr-4 font-semibold border-b">
                                                    <div>
                                                        <span className="text-[14px]">
                                                            Processing
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <div className="flex items-center text-[12px] text-[#EFB81C]">
                                                            View All{" "}
                                                            <IoIosArrowForward />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Link
                                to="/trade/p2p/profile"
                                className={`cursor-pointer text-[14px] font-semibold hover:text-[#EFB81C] ${
                                    location.pathname === "/trade/p2p/profile"
                                        ? "font-bold"
                                        : ""
                                }`}
                            >
                                <div className="flex gap-1 items-center text-[14px]">
                                    <ImUsers />
                                    P2P User Center
                                </div>
                            </Link>

                            <div
                                className="relative inline-block text-left text-[14px]"
                                onMouseEnter={() => setIsHoveredAds(true)}
                                onMouseLeave={() => setIsHoveredAds(false)}
                            >
                                {isAuthenticated && (
                                    <button
                                        ref={buttonRef}
                                        type="button"
                                        className={`flex items-center gap-1 ${
                                            isHoveredAds || openDropdown
                                                ? "text-[#EFB81C]"
                                                : ""
                                        } hover:text-[#EFB81C]`}
                                    >
                                        <FaFileCircleQuestion />
                                        <span className="text-[14px] font-semibold mr-1">
                                            {selectedAds}
                                        </span>
                                        {isHoveredAds || openDropdown ? (
                                            <IoIosArrowUp className="text-[0.7rem]" />
                                        ) : (
                                            <IoIosArrowDown className="text-[0.7rem]" />
                                        )}
                                    </button>
                                )}

                                {(isHoveredAds || openDropdown) && (
                                    <div
                                        className="absolute left-0 w-full !z-50"
                                        style={{ top: "100%" }}
                                    >
                                        <div
                                            className="mt-4 bg-white -ml-24 rounded-md shadow-lg w-52 ring-1 ring-black ring-opacity-5"
                                            ref={dropdownRef}
                                            onMouseEnter={() =>
                                                setIsHoveredAds(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsHoveredAds(false)
                                            }
                                        >
                                            <div className="py-1" role="none">
                                                <Link
                                                    to="/trade/p2p/apply-merchant"
                                                    className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl"
                                                    onClick={() =>
                                                        handleAdsSelect(
                                                            "Apply Merchant"
                                                        )
                                                    }
                                                >
                                                    <div className="flex items-center gap-1 px-2 text-[14px]">
                                                        <BiSolidCard /> Apply Merchant
                                                    </div>
                                                </Link>
                                                <Link
                                                    to={targetPath}
                                                    className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl"
                                                    onClick={() =>
                                                        handleAdsSelect(
                                                            "Post Ads"
                                                        )
                                                    }
                                                >
                                                    <div className="flex items-center gap-1 px-2 text-[14px]">
                                                        <GiSellCard /> Post Ads
                                                    </div>
                                                </Link>
                                                <Link
                                                    to={targetedPath}
                                                    className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl"
                                                    onClick={() =>
                                                        handleAdsSelect(
                                                            "My Ads"
                                                        )
                                                    }
                                                >
                                                    <div className="flex items-center gap-1 px-2 text-[14px]">
                                                        <BiSolidCard /> My Ads
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        )}

                        {isMenuOpen && (
                            <div
                                ref={dropdownRef}
                                className="md:flex absolute right-0 top-[8rem] w-60 bg-white shadow-lg rounded-sm z-50"
                            >
                                <div className="flex flex-col gap-4 px-6 py-3">
                                    {/* User Guide Dropdown */}
                                    <div className="relative text-left text-[14px]">
                                        <button
                                            onClick={toggleUserGuide}
                                            className="flex items-center gap-3 hover:text-[#EFB81C]"
                                        >
                                            <FaFileCircleQuestion className="text-gray-700" />
                                            <span className="text-[14px] font-semibold">
                                                User Guide
                                            </span>
                                            <IoIosArrowDown
                                                className={`text-[0.7rem] transition-transform ${
                                                    isUserGuideOpen
                                                        ? "rotate-180"
                                                        : ""
                                                }`}
                                            />
                                        </button>
                                        {isUserGuideOpen && (
                                            <div className="w-full mt-2 bg-white rounded-md">
                                                <div className="py-1">
                                                    <h6 className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl">
                                                        How to Buy/Sell
                                                    </h6>
                                                    <h6 className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl">
                                                        Placing an Order
                                                    </h6>
                                                    <h6 className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl">
                                                        Video Tutorial
                                                    </h6>
                                                    <h6 className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl">
                                                        P2P Trading FAQ
                                                    </h6>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Orders Link */}
                                    <Link
                                        to="/trade/p2p/orders"
                                        className="flex items-center gap-3 text-[14px] font-semibold hover:text-[#EFB81C]"
                                    >
                                        <FaFileLines className="text-gray-700" />
                                        Orders
                                    </Link>

                                    {/* P2P User Center Link */}
                                    <Link
                                        to="/trade/p2p/profile"
                                        className="flex items-center gap-3 text-[14px] font-semibold hover:text-[#EFB81C]"
                                    >
                                        <ImUsers className="text-gray-700" />
                                        P2P User Center
                                    </Link>

                                    {/* Ads Dropdown */}
                                    <div className="relative text-left text-[14px]">
                                        <button
                                            onClick={toggleAds}
                                            className="flex items-center gap-3 hover:text-[#EFB81C]"
                                        >
                                            <FaFileCircleQuestion className="text-gray-700" />
                                            <span className="text-[14px] font-semibold">
                                                Ads
                                            </span>
                                            <IoIosArrowDown
                                                className={`text-[0.7rem] transition-transform ${
                                                    isAdsOpen
                                                        ? "rotate-180"
                                                        : ""
                                                }`}
                                            />
                                        </button>
                                        {isAdsOpen && (
                                            <div className="w-full mt-2 bg-white rounded-md">
                                                <div className="py-1">
                                                <Link
                                                    to="/trade/p2p/apply-merchant"
                                                    className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl"
                                                >
                                                        Apply Merchant
                                                </Link>
                                                    <Link
                                                        to={targetPath}
                                                        className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl"
                                                    >
                                                        Post Ads
                                                    </Link>
                                                    <Link
                                                        to={targetedPath}
                                                        className="block px-4 py-2 text-[14px] text-gray-700 cursor-pointer hover:bg-[#fff1cc] rounded-xl"
                                                    >
                                                        My Ads
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Icon for tablet and mobile screens */}
                    <div className="flex justify-center md:flex lg:hidden">
                        <button
                            onClick={handleIconClick}
                            className="text-2xl text-[#EFB81C]"
                        >
                            <div className="flex items-center justify-center gap-1">
                                <CgMoreO />
                                <span className="hidden text-sm font-semibold md:block lg:block">
                                    More
                                </span>
                                <IoIosArrowDown className="text-[12px]" />
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <main>{props.children}</main>
            <Footer />
        </>
    );
}

export default P2pLayout;
