import React, { useState } from "react";
import { BsFillExclamationDiamondFill } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import SetWithDrawalPassword from "./SetWithDrawalPassword";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotCode } from "store/actions/authAction";
import { toast } from "react-toastify"; // Ensure you have toast imported

const WithdrawPassword = ({ onClose }) => {
    const [showAccountVerified, setShowAccountVerified] = useState(true);
    const [showChangeSecurityPassword, setShowChangeSecurityPassword] = useState(false);
    const [showWithdrawPassword, setShowWithdrawPassword] = useState(false);
    const dispatch = useDispatch();

    const email = useSelector((state) => state.authCheck.authStatus.email);
    console.log(email);

    const handleShowChangeSecurityPassword = () => {
        setShowChangeSecurityPassword(true);
        setShowAccountVerified(false);
    };

    const handleShowWithdrawPassword = async () => {
        // Check if the email exists before dispatching
        if (!email) {
            toast.error("Email is required to send the verification code."); // Show an error message
            return; // Exit the function if email is not provided
        }

        // Dispatch the forgotCode action with email
        const result = await dispatch(forgotCode({ email }));

        // Proceed to show withdrawal password modal only if the action was successful
        if (result) {
            setShowWithdrawPassword(true);
            setShowChangeSecurityPassword(false);
            setShowAccountVerified(false);
        }
    };

    // Close all menus
    const handleHideMenus = () => {
        setShowWithdrawPassword(false);
        setShowChangeSecurityPassword(false);
        setShowAccountVerified(false);
    };

    // Only show the modal and background overlay if any menu is open
    if (!showAccountVerified && !showChangeSecurityPassword && !showWithdrawPassword) {
        return null;
    }

    return (
        <div className="fixed inset-0 modal-outer flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            {showAccountVerified && (
                <div className="bg-white rounded-xl w-96 py-2 px-6">
                    <div>
                        <button onClick={onClose} className="float-right">
                            <RxCross2 className="text-xl text-[#D7D7D7]" />
                        </button>
                    </div>
                    <div className="text-center mt-4">
                        <BsFillExclamationDiamondFill className="text-8xl text-yellow-500 mx-auto" />
                    </div>
                    <h1 className="font-semibold text-center text-xl mt-2">Unverified</h1>
                    <p className="text-xs mt-1">
                        For your account security, please complete ID verification before setting withdrawal password.
                    </p>
                    <div className="mt-4 flex items-center justify-between gap-4">
                        <button className="flex-1 rounded-xl py-2 text-sm font-medium bg-yellow-100 text-yellow-500" onClick={onClose}>
                            Cancel
                        </button>
                        <button onClick={handleShowChangeSecurityPassword} className="flex-1 rounded-xl py-2 text-sm font-medium bg-yellow-500 text-white">
                            Verify Now
                        </button>
                    </div>
                </div>
            )}

            {showChangeSecurityPassword && (
                <div className="bg-white rounded-xl w-96 py-2 px-6">
                    <div>
                        <button onClick={handleHideMenus} className="float-right">
                            <RxCross2 className="text-xl text-[#D7D7D7]" />
                        </button>
                    </div>
                    <div className="text-center mt-4">
                        <BsFillExclamationDiamondFill className="text-8xl text-yellow-500 mx-auto" />
                    </div>
                    <h1 className="font-semibold text-center text-xl mt-2">Change Security Password</h1>
                    <p className="text-xs mt-1">
                        For your account security, the withdrawal service will not be available for 24 hours after changing withdrawal password. Are you sure to continue?
                    </p>
                    <div className="my-4 flex flex-col items-center justify-between gap-3">
                        <button onClick={handleShowWithdrawPassword} className="w-full rounded-xl py-2 text-sm font-medium bg-yellow-500 text-white">
                            Continue
                        </button>
                        <Link to="/user/account/security/reset-withdraw-password" className="rounded-xl text-sm font-medium text-yellow-500">
                            Forget withdrawal password?
                        </Link>
                    </div>
                </div>
            )}

            {showWithdrawPassword && (
                <SetWithDrawalPassword handleHideMenus={onClose} />
            )}
        </div>
    );
};

export default WithdrawPassword;