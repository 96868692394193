import { FETCH_TRACE_REQUEST, FETCH_TRACE_SUCCESS, FETCH_TRACE_FAILURE, DO_TRACE_REQUEST, DO_TRACE_SUCCESS, DO_TRACE_FAILURE } from "../types";

const initialState = {
    loading: false,
    traceData: [],
    doTraceData: null,
    error: null
};

const traceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TRACE_REQUEST:
        case DO_TRACE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_TRACE_SUCCESS:
            return {
                ...state,
                loading: false,
                traceData: action.payload
            };
        case DO_TRACE_SUCCESS:
            return {
                ...state,
                loading: false,
                doTraceData: action.payload
            };
        case FETCH_TRACE_FAILURE:
        case DO_TRACE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default traceReducer;
