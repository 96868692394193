import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchMarketData } from "store/actions/marketActions";

export const useMarketData = (navActive) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const marketData = useSelector((state) => state.market.data?.market || []);
    const fetchError = useSelector((state) => state.market.error);
    const fetchLoading = useSelector((state) => state.market.loading);

    const [validIcons, setValidIcons] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [favoritePairs, setFavoritePairs] = useState([]);
    const [filteredData, setFilteredData] = useState(marketData);

    useEffect(() => {
        const savedFavorites =
            JSON.parse(localStorage.getItem("favoritePairs")) || [];
        setFavoritePairs(savedFavorites);

        const loadMarketData = async () => {
            setLoading(true);
            try {
                await dispatch(fetchMarketData());
            } catch (err) {
                setError(err.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        };

        loadMarketData();
        const intervalId = setInterval(loadMarketData, 60000);

        return () => clearInterval(intervalId);
    }, [dispatch, navActive]);

    // Function for toggle favorite pair
    const toggleFavoritePair = (id) => {
        const updatedFavorites = favoritePairs.includes(id)
            ? favoritePairs.filter((favId) => favId !== id)
            : [...favoritePairs, id];

        setFavoritePairs(updatedFavorites);
        localStorage.setItem("favoritePairs", JSON.stringify(updatedFavorites));
    };

    const isFavoritePair = (id) => {
        return favoritePairs.includes(id);
    };

    useEffect(() => {
        const validateIcons = async () => {
            const results = {};
            for (const coin of marketData) {
                if (coin.icon) {
                    results[coin.id] = await checkIconValidity(coin.icon);
                }
            }
            setValidIcons(results);
        };

        if (marketData.length > 0) {
            validateIcons();
        }
    }, [marketData]);

    // Check if an image URL is valid or not
    const checkIconValidity = (url) => {
        const invalidUrls = ["https://api.buycex.com/Upload/coin/"];
        if (invalidUrls.includes(url)) {
            return Promise.resolve(false);
        }

        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    };

    // Extracting coin name
    const extractCoinName = (name) => {
        const match = name.match(/\(([^)]+)\)/);
        return match ? match[1] : name;
    };

    // Format price for display
    const formatPrice = (price) => {
        const numPrice = Number(price);
        if (isNaN(numPrice)) return "N/A";
        if (numPrice >= 1e9) return `$${(numPrice / 1e9).toFixed(2)}B`;
        if (numPrice >= 1e6) return `$${(numPrice / 1e6).toFixed(2)}M`;
        if (numPrice >= 1e3) return `$${(numPrice / 1e3).toFixed(2)}K`;
        return `$${numPrice.toFixed(2)}`;
    };

    // New Listed
    // const newListedCoins = marketData
    //     .map((coin) => ({
    //         ...coin,
    //         id: Number(coin.id),
    //     }))
    //     .sort((a, b) => b.id - a.id) // Sort by id in descending order (latest entries on top)
    //     .map((coin) => ({
    //         ...coin,
    //         id: coin.id.toString(),
    //     }));
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
    
    const newListedCoins = marketData
    .filter((coin) => {
        const addtime = new Date(coin?.coinData?.addtime); // Parse the addtime from coinData
        return addtime > oneMonthAgo; // Filter only coins added within the last month
    })
    .map((coin) => ({
        ...coin,
        id: Number(coin.id),
    }))
    .sort((a, b) => b.id - a.id)
    .slice(0, 3)
    .map((coin) => ({
        ...coin,
        id: coin.id.toString(),
    }));

    // Filter market data based on search term
    const filterData = (searchQuery) => {
        const filtered = marketData.filter((coin) =>
            coin.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered); // Updates state synchronously
        return filtered; // Optional: Return the filtered data
    };

    const handleDetailsRedirect = (id) => {
        const coin = marketData.find((coin) => coin.id === id);
        if (coin) {
            history.push(`/coin/${coin.id}`);
        }
    };

    const handleTradeRedirect = (id) => {
        const coin = marketData.find((coin) => coin.id === id);
        if (coin) {
            history.push(`/trade/spot/${coin.id}`);
        }
    };

    const getColorGradient = (change) => {
        if (change > 0) {
            return {
                gradientId: `gradient-${change}`,
                startColor: "green",
                endColor: "lightgreen",
            };
        } else if (change < 0) {
            return {
                gradientId: `gradient-${change}`,
                startColor: "red",
                endColor: "lightcoral",
            };
        } else {
            return {
                gradientId: `gradient-${change}`,
                startColor: "gray",
                endColor: "lightgray",
            };
        }
    };

    const generatePositivePath = (width, height, change) => {
        let path = `M0 ${height}`;
        const segments = Math.max(5, Math.ceil(width / 10));

        for (let i = 1; i <= segments; i++) {
            const x = (i / segments) * width;
            const y = height - (Math.random() * 10 + 10) + change / 10;
            path += `L${x} ${y} `;
        }

        path += `L${width} ${height} Z`;
        return path;
    };

    const generateNegativePath = (width, height, change) => {
        let path = `M0 ${height}`;
        const segments = Math.max(5, Math.ceil(width / 10));

        for (let i = 1; i <= segments; i++) {
            const x = (i / segments) * width;
            const y = Math.random() * 10 + 10 - change / 10;
            path += `L${x} ${y} `;
        }

        path += `L${width} ${height} Z`;
        return path;
    };

    const createSvgData = (coin) => {
        const { gradientId, startColor, endColor } = getColorGradient(
            coin.change
        );
        const width = 50;
        const height = 20;

        // Choose the path based on whether the change is positive or negative
        const isPositive = coin.change > 0;
        const path = isPositive
            ? generatePositivePath(width, height, coin.change)
            : generateNegativePath(width, height, coin.change);

        return `
            <svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                <defs>
                    <linearGradient id="${gradientId}" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" style="stop-color: ${startColor}; stop-opacity: 0.5;"></stop>
                        <stop offset="90%" style="stop-color: ${endColor}; stop-opacity: 0;"></stop>
                    </linearGradient>
                </defs>
                <path d="${path}" fill="url(#${gradientId})" stroke="rgba(24, 22, 55, .11)" stroke-width="1"></path>
            </svg>
        `;
    };

    const svgDataList = useMemo(
        () => marketData.map(createSvgData),
        [marketData]
    );

    return {
        marketData,
        newListedCoins,
        validIcons,
        loading: fetchLoading || loading,
        error: fetchError || error,
        formatPrice,
        extractCoinName,
        handleDetailsRedirect,
        handleTradeRedirect,
        isFavoritePair,
        toggleFavoritePair,
        filterData,
        svgDataList,
    };
};
