import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import WithdrawalRoutingNavbar from "components/withdrawalRoutingNavbar";
import FaQs from "components/FAQs";
import { NavLink } from "react-router-dom";
import withdrawKey from "assets/img/Withdraw-key.png";
import { AiOutlineClose } from "react-icons/ai";
import { ImNotification } from "react-icons/im";
import InternalTransfer from "components/cryptoWithdraw/InternalTransfer";
import ContinueBtn from "components/cryptoWithdraw/ContinueBtn";
import CoinSelect from "components/cryptoWithdraw/CoinSelectWithdraw";
import NetworkSelectDropdown from "components/cryptoWithdraw/NetworkSelectWithdraw";
import { FaCheck, FaArrowRight } from "react-icons/fa";
import toast from "react-hot-toast";

function CryptoInternalTransfer() {
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};
    const [coins, setCoins] = useState([]);
    const [selectedCoin, setSelectedCoin] = useState(null);
    const [networks, setNetworks] = useState([]);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [withdrawalRecords, setWithdrawalRecords] = useState([]);
    const [withdrawAddress, setWithdrawAddress] = useState("");
    const [withdrawAmount, setWithdrawAmount] = useState("");
    const [fundPassword, setFundPassword] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [balance, setBalance] = useState("");
    const [limit, setLimit] = useState("");
    const [feesPercent, setFeesPercent] = useState("");
    const [feesFlat, setFeesFlat] = useState("");
    const history = useHistory();
    const [showInternalTransfer, setShowInternalTransfer] = useState(true);
    const [activeButton, setActiveButton] = useState('Address');

    const WithdrawRecordBtn = (button) => {
        setActiveButton(button);
    };

    const handleInternalTransferClick = () => {
        setShowInternalTransfer(true);
    };

    useEffect(() => {
        // Check on mount if TOKEN or ID is missing
        if (!TOKEN || !ID) {
            console.log("Redirecting to sign-in");
            history.push("/login");
            return;
        }

        // Fetch coin data from the API
        fetch("https://api.buycex.com/Index/marketInfo")
            .then((response) => response.json())
            .then((data) => {
                // Create an array to store coin data
                const coinsData = [];

                // Iterate over the market data to extract coin information
                data.data.market.forEach((coin) => {
                    // Get the coin name and icon URL
                    const name = coin.ticker.split("_")[0].toUpperCase();
                    const icon = coin.icon;

                    // Add the coin to the array if it's not already present
                    if (
                        name !== "LTC" &&
                        name !== "DOGE" &&
                        !coinsData.some((c) => c.value === name)
                    ) {
                        coinsData.push({
                            value: name,
                            label: name,
                            icon: icon,
                            onlySend: `Only send ${name}`,
                        });
                    }
                });

                // Manually add additional coin data
                coinsData.push(
                    {
                        value: "ADA",
                        label: "ADA",
                        icon: "https://api.buycex.com/Upload/coin/65f83ff22d26f.png",
                        onlySend: "Only send ADA",
                    },
                    {
                        value: "Avax",
                        label: "Avax",
                        icon: "https://api.buycex.com/Upload/coin/65f843f1daacc.png",
                        onlySend: "Only send Avax",
                    },
                    {
                        value: "USDT",
                        label: "USDT",
                        icon: "https://api.buycex.com/Upload/coin/USDT.png",
                        onlySend: "Only send USDT",
                    },
                    {
                        value: "TUSDT",
                        label: "TUSDT",
                        icon: "https://api.buycex.com/Upload/coin/65f98a1338aff.png",
                        title: "Tether USDT [TRC20](TUSDT)",
                        onlySend: "Only send USDT",
                    },
                    {
                        value: "BUSDT",
                        label: "BUSDT",
                        icon: "https://api.buycex.com/Upload/coin/65f98a1338aff.png",
                        title: "USDT [Bep20](BUSDT)",
                        onlySend: "Only send USDT",
                    }
                );

                // Set the state with the coin data
                setCoins(coinsData);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [TOKEN, ID, history]);

    useEffect(() => {
        if (!TOKEN || !ID) {
            history.push("/login");
            return;
        }

        if (selectedCoin && TOKEN && ID) {
            fetch(
                `https://api.buycex.com/Crypto/coinbalance/coin/${selectedCoin.value}`,
                {
                    headers: {
                        TOKEN: TOKEN,
                        ID: ID,
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data && data.data) {
                        setNetworks([
                            { value: data.data.symbol, label: data.data.title },
                        ]);
                        setBalance(data.data.total);
                        setLimit(data.data.zhehe);
                        setFeesPercent(data.data.fees_percent);
                        setFeesFlat(data.data.fees_flat);
                    } else {
                        console.log("No data available");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [selectedCoin, TOKEN, ID, history]);

    useEffect(() => {
        if (!TOKEN || !ID) {
            history.push("/login");
            return;
        }

        if (selectedCoin && TOKEN && ID) {
            const fetchWithdrawals = async () => {
                try {
                    const response = await fetch(
                        `https://api.buycex.com/Crypto/MyWithdrawals?coin=${selectedCoin.value}`,
                        {
                            headers: {
                                TOKEN: TOKEN,
                                ID: ID,
                            },
                        }
                    );
                    const data = await response.json();
                    if (data && data.status === 1 && data.data) {
                        setWithdrawalRecords(data.data);
                    } else {
                        console.log("No withdrawal records found");
                    }
                } catch (error) {
                    console.error("Error fetching withdrawal records:", error);
                }
            };

            fetchWithdrawals();
        }
    }, [selectedCoin, TOKEN, ID, history]);

    const faqs = [
        "Crypto Withdrawal FAQs",
        "How to Withdraw Through Internal Transfer",
        "View the Deposit/Withdrawal Status of All Coins",
        "How to Change Your Withdrawal Limit",
        "How to Manage Your Withdrawal Address Book",
    ];

    const handleImageClick = (coin) => {
        setSelectedNetwork(null);
        setSelectedCoin(coin);
    };

    const handleCoinChange = (selectedOption) => {
        setSelectedCoin(selectedOption);
    };

    const handleNetworkChange = (selectedOption) => {
        setSelectedNetwork(selectedOption);
    };

    // Check if both coin network and withdrawal address are selected before continuing
    const handleButtonClick = () => {
        if (!selectedCoin) {
            toast.error("Please select a Coin");
            return;
        }
        if (!selectedNetwork) {
            toast.error("Please select a network.");
            return;
        }

        if (!withdrawAddress) {
            toast.error("Please provide a withdrawal address.");
            return;
        }
        if (!withdrawAmount) {
            toast.error("Please provide a withdrawal amount.");
            return;
        }

        setIsVisible(!isVisible);
    };

    const handleWithdraw = async () => {
        // Check if withdrawal address is empty or null
        if (!withdrawAddress) {
            toast.error("Please provide a withdrawal address.");
            return;
        }

        // Check if the withdrawal address format matches the selected coin
        if (selectedCoin.value === "LTC" && !withdrawAddress.startsWith("L")) {
            toast.error("Invalid withdrawal address format for LTC.");
            return;
        }

        if (selectedCoin.value === "TRX") {
            if (!withdrawAddress.startsWith("TP")) {
                toast.error("Invalid withdrawal address format for TRX.");
                return;
            }

            if (withdrawAddress.length !== 34) {
                toast.error(
                    "Withdrawal address for TRX must be 34 characters long."
                );
                return;
            }
        }

        if (selectedCoin.value === "XRP") {
            if (!withdrawAddress.startsWith("r")) {
                toast.error("Invalid withdrawal address format for XRP.");
                return;
            }

            if (withdrawAddress.length !== 34) {
                toast.error(
                    "Withdrawal address for XRP must be 34 characters long."
                );
                return;
            }
        }

        if (selectedCoin.value === "TUSDT") {
            if (!withdrawAddress.startsWith("TP")) {
                toast.error("Invalid withdrawal address format for TUSDT.");
                return;
            }

            if (withdrawAddress.length !== 34) {
                toast.error(
                    "Withdrawal address for TUSDT must be 34 characters long."
                );
                return;
            }
        }

        // Check for other coins and their withdrawal address format
        if (!["LTC", "TRX", "XRP", "TUSDT"].includes(selectedCoin.value)) {
            // if (!withdrawAddress.startsWith("0x")) {
            //     toast.error(
            //         "Invalid withdrawal address format for the selected coin."
            //     );
            //     return;
            // }

            // if (withdrawAddress.length !== 42) {
            //     toast.error("Withdrawal address must be 42 characters long.");
            //     return;
            // }
        }

        // Proceed with the withdrawal
        const url = "https://api.buycex.com/Crypto/doWithdraw";

        const data = {
            coin: selectedCoin.value.toLowerCase(),
            num: withdrawAmount,
            addr: withdrawAddress,
            paypassword: fundPassword,
        };

        const options = {
            method: "POST",
            headers: {
                TOKEN: TOKEN,
                ID: ID,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        try {
            const response = await fetch(url, options);
            console.log("Done the response",response)
            const responseData = await response.json();
            if (responseData.status === 1) {
                toast.success("Successfully transferred the balance.");
                setIsVisible(false);
                setFundPassword('')
                setWithdrawAddress('')
                setWithdrawAmount('')
            } else {
                toast.error(`Error: ${responseData.data}`);
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred while processing the withdrawal.");
        }
    };
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate API call
        setTimeout(() => {
            setLoading(false);
        }, 800); // simulate 2 seconds loading time
    }, []);

    return (
        <>
            <section className="dark:bg-black">
                <div className="mt-6">
                    <WithdrawalRoutingNavbar />
                </div>
                <div className="gap-20 container w-100">
                    <div className="flex mt-4 justify-between w-full">
                        <div className="  mb-5 mt-[10 px] md:mt-5">
                            <b className="font-semibold text-[22px] md:text-3xl">
                                Withdraw
                            </b>
                        </div>
                    </div>

                    <section className="flex flex-col w-full gap-10 lg:flex-row">
                        <section className="w-full flex lg:w-1/2 lg:min-w-[53%]">
                            {/* {message && (
                                <div className="mt-4 text-lg text-center text-red-500">
                                    {message}
                                </div>
                            )} */}

                            <div className="shadow-[0px_0px_16px_2px_rgba(0,_0,_0,_0.07)] flex gap-5 rounded-[20px] p-6 w-full pb-4 dark:bg-[#1A191B]">
                                <div
                                    className="w-1/6"
                                    style={{ width: "8%" }}
                                >
                                    <div className="flex items-center justify-end">
                                        <div className="flex flex-col items-center">
                                            {/* First Icon with Diamond Shape */}
                                            <div className="flex items-center justify-center text-white bg-black w-6 h-6 rounded-md transform rotate-45">
                                                <FaCheck className="transform text-sm -rotate-45" />
                                            </div>

                                            {/* Vertical Line */}
                                            <div className="w-0.5 h-[5.5rem] bg-black"></div>

                                            {/* Second Icon with Diamond Shape */}
                                            <div className="flex items-center justify-center w-6 h-6">
                                                <div className="flex items-center justify-center w-full h-full bg-black text-white rounded-md transform rotate-45">
                                                    <span className="transform -rotate-45 text-md font-semibold">2</span>
                                                </div>
                                            </div>


                                            {/* Vertical Line */}
                                            <div className="w-0.5 h-[8.5rem] bg-black"></div>

                                            {/* Third Icon with Diamond Shape */}
                                            <div className="flex items-center justify-center w-6 h-6">
                                                <div className="flex items-center justify-center w-full h-full bg-black text-white rounded-md transform rotate-45">
                                                    <span className="transform -rotate-45 text-white font-semibold">3</span>
                                                </div>
                                            </div>
                                            {/* Vertical Line */}
                                            <div className="w-0.5 h-[4.5rem] bg-black"></div>

                                            {/* Third Icon with Diamond Shape */}
                                            <div className="flex items-center justify-center w-6 h-6">
                                                <div className="flex items-center justify-center w-full h-full bg-black text-white rounded-md transform rotate-45">
                                                    <span className="transform -rotate-45 text-white font-semibold">4</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="w-full">
                                    <div className="flex flex-col lg:flex-row w-full gap-6 justify-between">
                                        <div className="w-full">
                                            <CoinSelect
                                                coins={coins}
                                                selectedCrypto={selectedCoin}
                                                setSelectedCrypto={setSelectedCoin}
                                                handleImageClick={handleImageClick}
                                            />
                                        </div>
                                    </div>
                                    <form>

                                        <div className="flex flex-col lg:flex-row w-full mt-5 gap-6 justify-between">
                                            <div
                                                className="flex gap-5 w-full text-base font-medium border-b-[1px] border-gray-300"
                                                style={{ height: "2.75rem" }}
                                            >
                                                
                                                <div
                                                    className={`flex justify-center items-center ${showInternalTransfer
                                                        ? "font-bold dark:text-white border-b-2 border-yellow-500"
                                                        : "text-gray-500"
                                                        }`}
                                                >
                                                    <button
                                                        type="button"
                                                        className="text-sm"
                                                        onClick={
                                                            handleInternalTransferClick
                                                        }
                                                    >
                                                        Internal Transfer
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    {showInternalTransfer && (
                                        <InternalTransfer
                                            selectedCoin={selectedCoin}
                                            setSelectedCoin={setSelectedCoin}
                                        />
                                    )}
                                </div>
                            </div>
                        </section>
                        <section className="pl-8 w-100 md:-1/2">
                            <div className="font-semibold text-[22px] md:text-3xl sm:mx-4">
                                FAQ
                            </div>
                            <ul className="pt-2">
                                {faqs.map((faq, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center"
                                    >
                                        <GoDotFill
                                            style={{ fontSize: "12px" }}
                                        />
                                        <li className="px-2 py-2">
                                            <NavLink
                                                to={`/${faq
                                                    .toLowerCase()
                                                    .replace(/ /g, "-")}`}
                                                className="text-black hover:text-[#EFB81C] hover:no-underline dark:text-white dark:hover:text-[#EFB81C]"
                                            >
                                                {faq}
                                            </NavLink>
                                        </li>
                                    </div>
                                ))}
                            </ul>
                        </section>
                    </section>
                </div>
                <div className="mt-32 container mb-4">
                    <span className="text-[24px] font-semibold">
                        Withdrawal Records
                    </span>
                    <div className="flex mt-4 space-x-4">
                        <button
                            onClick={() => WithdrawRecordBtn('Address')}
                            className={`px-4 py-2 rounded-md font-semibold ${activeButton === "Address" ? "text-black bg-[#F5F5F5]" : "hover:text-black text-[#707a8a]"
                                }`}
                        >
                            Address
                        </button>
                        <button
                            onClick={() => WithdrawRecordBtn('BuyCex User')}
                            className={`px-4 py-2 rounded-md font-semibold ${activeButton === ('BuyCex User') ? "text-black bg-[#F5F5F5]" : "hover:text-black text-[#707a8a]"
                                }`}
                        >
                            BuyCex User
                        </button>
                    </div>
                </div>

                <section className="container overflow-x-auto px-3">
                    <table className="text-center table-auto w-full">
                        <thead>
                            <tr>
                                <th className="border-b-[1px] text-start min-w-24 py-2 font-normal text-[#BDBDBD]">
                                    Coin
                                </th>
                                <th className="border-b-[1px] min-w-24  py-2 font-normal text-[#BDBDBD]">
                                    Quantity
                                </th>
                                <th className="border-b-[1px] min-w-24 whitespace-nowrap text-ellipsis py-2 font-normal text-[#BDBDBD]">
                                    Transaction Fee
                                </th>
                                <th className="border-b-[1px] min-w-24  py-2 font-normal text-[#BDBDBD]">
                                    Address
                                </th>
                                <th className="border-b-[1px] min-w-24 whitespace-nowrap text-ellipsis py-2 font-normal text-[#BDBDBD]">
                                    Transaction ID
                                </th>
                                <th className="border-b-[1px] min-w-24  py-2 font-normal text-[#BDBDBD]">
                                    Status
                                </th>
                                <th className="border-b-[1px] min-w-24  py-2 font-normal text-[#BDBDBD]">
                                    Date & Time
                                </th>
                                <th className="border-b-[1px] min-w-24  py-2 font-normal text-[#BDBDBD]">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                // Skeleton loader when data is loading
                                [...Array(5)].map((_, index) => (
                                    <tr key={index}>
                                        <td className="px-4 py-2">
                                            <div className="h-6 bg-gray-300 rounded animate-pulse"></div>
                                        </td>
                                        <td className="px-4 py-2">
                                            <div className="h-6 bg-gray-300 rounded animate-pulse"></div>
                                        </td>
                                        <td className="px-4 py-2">
                                            <div className="h-6 bg-gray-300 rounded animate-pulse"></div>
                                        </td>
                                        <td className="px-4 py-2">
                                            <div className="h-6 bg-gray-300 rounded animate-pulse"></div>
                                        </td>
                                        <td className="px-4 py-2">
                                            <div className="h-6 bg-gray-300 rounded animate-pulse"></div>
                                        </td>
                                        <td className="px-4 py-2">
                                            <div className="h-6 bg-gray-300 rounded animate-pulse"></div>
                                        </td>
                                        <td className="px-4 py-2">
                                            <div className="h-6 bg-gray-300 rounded animate-pulse"></div>
                                        </td>
                                        <td className="px-4 py-2">
                                            <div className="h-6 bg-gray-300 rounded animate-pulse"></div>
                                        </td>
                                        <td className="px-4 py-2">
                                            <div className="h-6 bg-gray-300 rounded animate-pulse"></div>
                                        </td>
                                    </tr>
                                ))
                            ) : withdrawalRecords.length > 0 ? (
                                // Actual data rows when data is loaded
                                withdrawalRecords.map((record, index) => (
                                    <tr key={index}>
                                        <td className="text-start capitalize py-2">
                                            {record.coinname}
                                        </td>
                                        <td className="px-4 py-2">
                                            {record.num}
                                        </td>
                                        <td className="px-4 py-2">
                                            {record.fee}
                                        </td>
                                        <td className="px-4 py-2">
                                            {record.username &&
                                                `${record.username.substring(
                                                    0,
                                                    3
                                                )}***${record.username.substring(
                                                    record.username.length - 3
                                                )}`}
                                        </td>
                                        <td className="px-4 py-2">
                                            {record.txid &&
                                                `${record.txid.substring(
                                                    0,
                                                    3
                                                )}***${record.txid.substring(
                                                    record.txid.length - 3
                                                )}`}
                                        </td>
                                        <td className="px-4 py-2">
                                            {record.status}
                                        </td>
                                        <td className="px-4 py-2">
                                            {new Date(
                                                parseInt(record.addtime) * 1000
                                            )
                                                .toISOString()
                                                .slice(0, 19)
                                                .replace("T", " ")}
                                        </td>
                                        <td className="px-4 py-2 text-[#EFB81C]">
                                            Details
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                // Empty state message
                                <tr>
                                    <td
                                        className="px-4 py-2 text-center sm:text-left"
                                        colSpan="9"
                                    >
                                        <div className="flex items-center justify-center gap-2 sm:items-left">
                                            <div>
                                                <ImNotification className="text-black dark:text-white" />
                                            </div>
                                            <div className="text-black dark:text-white">
                                                No Withdrawal Records
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </section>
                <div className="container">
                    <FaQs />
                </div>
            </section>
        </>
    );
}

export default CryptoInternalTransfer;
