import React from "react";
import globe from "assets/img/online-banking.svg";
import convert from "assets/img/convert-coins.svg";
import spendToEarn from "assets/img/spend-to-earn.svg";
import AdvIcon from "assets/img/p2p-landing-page-advantage.svg";

function AdvantageP2p() {
    const advantages = [
        {
            img: globe,
            title: "Global and Local Marketplace",
            description:
                "Whereas many other P2P platforms target specific markets, Buycex P2P provides a truly global trading experience with support for more than 70 local currencies.",
        },
        {
            img: convert,
            title: "Flexible Payment Methods",
            description:
                "Trusted by millions of users worldwide, Buycex P2P provides a safe platform to conduct crypto trades in 800+ payment methods and 100+ fiat currencies. Users can easily buy, sell and trade crypto directly with other users, while setting their preferred prices and payment methods in an open crypto marketplace.",
        },
        {
            img: spendToEarn,
            title: "Trade at Your Preferred Prices",
            description:
                "Trade crypto with the freedom to buy and sell at your preferred prices. Buy or sell from the existing offers, or create trade advertisements to set your own prices.",
        },
    ];

    return (
        <div>
            <h3 className="text-[40px] font-bold capitalize mb-5">
                Advantages of P2P Exchange
            </h3>
            <div className="flex flex-row-reverse items-center justify-between gap-10 mb-6">
                <div className="">
                    <img
                        src={AdvIcon}
                        alt="Advantage Icon"
                        className="w-[70%]"
                    />
                </div>
                <div className="flex-1">
                    {advantages.map(({ img, title, description }, index) => (
                        <div
                            key={index}
                            className="flex flex-col items-start mb-9"
                        >
                            <img src={img} alt={title} className="mb-3" />
                            <p className="font-medium text-[14px] tracking-tighter mb-2">
                                {title}
                            </p>
                            <p className="text-[14px] tracking-tighter font-[300] text-[#474D57]">
                                {description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AdvantageP2p;
