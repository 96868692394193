import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFiatDropdownList } from "../store/actions/SettingActions";

function useFiatDropdown(userId, token, history) {
    const dispatch = useDispatch();
    const dropdownOptions = useSelector(
        (state) => state.setting.dropdownValue || []
    );

    useEffect(() => {
        if (!userId || !token) {
            history.push("/login");
            return;
        }

        if (dropdownOptions.length === 0) {
            dispatch(fetchFiatDropdownList(userId, token));
        }
    }, [userId, token, dropdownOptions, dispatch, history]);

    return dropdownOptions;
}

export default useFiatDropdown;
