import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListingModel from './ListingModel'; // Assuming you have a Modal component for confirmation
import Toast from 'react-hot-toast';
import { upvoteItem, downvoteItem } from 'store/actions/voteActions';

const ListingProject = ({ logo, name, coinId, completePercent, remainingPercent, votecoin, assumnum }) => {
  const dispatch = useDispatch();
  const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {}; // Retrieve ID and Token from Redux
  const isComplete = completePercent === 100 && remainingPercent === 0;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [voteType, setVoteType] = useState(null);

  // Function to handle vote button click
  const handleVoteClick = (type) => {
    if (!ID || !TOKEN) {
      Toast.error('Please log in first!');
      return;
    }
    setVoteType(type);
    setIsModalOpen(true);
  };

  // Function to confirm the vote
  const handleConfirmVote = () => {
    const type = voteType === 'upvote' ? 1 : 2; // Set type to 1 for upvote and 2 for downvote
    if (voteType === 'upvote') {
      dispatch(upvoteItem(type, coinId, ID, TOKEN));
    } else {
      dispatch(downvoteItem(type, coinId, ID, TOKEN));
    }
    setIsModalOpen(false);
  };

  // Function to cancel the vote
  const handleCancelVote = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex items-center justify-between bg-white text-black py-4 px-6 rounded-lg mb-4 shadow-lg">
      <div className="flex items-center">
    <img src={logo} alt={`${name} logo`} className="h-16 w-16 rounded-full border-2 border-yellow-400 mr-6" />
    <h2 className="text-2xl font-semibold">{name}</h2>
  </div>
  <div className="flex flex-col items-center text-lg font-semibold">
    <span>Complete</span>
    <span className="text-yellow-400 text-xl">{completePercent}%</span>
  </div>
  <div className="flex flex-col items-center text-lg font-semibold">
    <span>Remaining</span>
    <span className="text-yellow-400 text-xl">{remainingPercent}%</span>
  </div>
      <div className="flex space-x-2">
        <button
          className="bg-yellow-500 hover:bg-yellow-600  text-white font-bold py-2 px-4 rounded"
          onClick={() => handleVoteClick('downvote')}
        >
          - DownVote
        </button>
        <button
          className="bg-[#EAECEF] hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded"
          onClick={() => handleVoteClick('upvote')}
        >
          + UpVote
        </button>
      </div>

      {isModalOpen && (
        <ListingModel onConfirm={handleConfirmVote} onCancel={handleCancelVote}>
          Voting will cost {votecoin} {assumnum}. Are you sure you want to {voteType} for {name}?
        </ListingModel>
      )}
    </div>
  );
};

export default ListingProject;
