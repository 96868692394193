import React, { useState, useEffect } from 'react';
import HistoryDropdown from '../../SpotHistory/DropDownUI';
import Cross from './Cross';
import Isolated from './Isolated';

const OpenOrder = () => {
    const [activeItem, setActiveItem] = useState('Open Orders');
    const [activeSubItem, setActiveSubItem] = useState('Cross');

    const processingSubItems = ['Cross', 'Isolated'];

    useEffect(() => {
        if (activeItem === 'Open Orders') {
            setActiveSubItem('Cross');
        } else if (activeItem === 'Cross') {
            setActiveSubItem('Cross');
        }
    }, [activeItem]);

    const renderContent = () => {
        switch (activeSubItem) {
            case 'Isolated':
                return <Isolated subItem={activeSubItem} />;
            case 'All':
            default:
                return <Cross subItem={activeSubItem} />;
        }
    };

    const renderSubItems = () => {
        const subItems = activeItem === 'Open Orders' ? processingSubItems : [];

        const options1 = ["All", "Limit Order", "Stop Limit", "Limit-Maker", "Trailing Stop", "Stop Market"];
        const options2 = ["All", "1000SATS/FDUSD", "1000SATS/USDC", "1000SATS/USDT", "1INCH/BTC", "1INCH/USDT"];
        const options3 = ["All", "Buy", "Sell"]

        const handleSelect1 = (selected) => {
            console.log("Dropdown 1 selected:", selected);
        };

        return (
            <>
                <div className='flex gap-4 items-center'>
                    <div className='inline-flex flex-wrap border rounded-lg pt-[0.20rem] pb-[0.25rem] px-1 gap-2'>
                        {subItems.map((subItem) => (
                            <button
                                key={subItem}
                                onClick={() => setActiveSubItem(subItem)}
                                className={`w-auto py-1.5 px-6 text-sm font-medium ${activeSubItem === subItem ? 'bg-[#F5F5F5] rounded-md' : 'text-gray-400'}`}
                            >
                                {subItem}
                            </button>
                        ))}
                    </div>

                    <div className='flex gap-2'>
                        <HistoryDropdown
                            label="Filter"
                            options={options1}
                            initialSelected="All"
                            onSelect={handleSelect1}
                            isSearchable={false}
                            width="200px"
                        />
                        <HistoryDropdown
                            label="Pair"
                            options={options2}
                            initialSelected="All"
                            onSelect={handleSelect1}
                            isSearchable={true}
                            width="200px"
                        />
                        <HistoryDropdown
                            label="Side"
                            options={options3}
                            initialSelected="All"
                            onSelect={handleSelect1}
                            isSearchable={false}
                            width="200px"
                        />
                    </div>
                </div>
                <div className='mt-4'>
                <button className='bg-[#EFB81C] px-4 py-2 rounded-lg font-medium text-sm'>Search</button>
                <button className='px-4 py-2 rounded-lg font-medium text-sm'>Reset</button>
                </div>
            </>
        );
    };

    return (
        <div className='px-3'>
            <div className='container mt-5 !p-0'>

                {renderSubItems()}

                <div className="mt-6">{renderContent()}</div>
            </div>
        </div>
    );
};

export default OpenOrder;
