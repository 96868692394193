import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../../store/actions/authAction";
import { useHistory } from "react-router-dom";

const SwitchToggler = ({ isChecked, onChange }) => (
    <button onClick={onChange} className="flex min-w-[85px]">
        <input
            type="checkbox"
            className="m-auto buycex__toggle-check"
            checked={isChecked}
            readOnly
        />
    </button>
);

const TimerSwitch = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [secureLoggingDuration, setSecureLoggingDuration] = useState(() => {
        const saved = localStorage.getItem("secureLoggingDuration");
        return saved !== null ? JSON.parse(saved) : false;
    });

    const [timer, setTimer] = useState(null);

    const toggleSwitch = () => {
        setSecureLoggingDuration((prevState) => {
            const newState = !prevState;
            localStorage.setItem("secureLoggingDuration", JSON.stringify(newState));
            return newState;
        });
    };

    const handleLogout = () => {
        dispatch(logout());
        history.push("/login");
    };

    useEffect(() => {
        if (secureLoggingDuration) {
            setTimer(setTimeout(() => {
                handleLogout();
            }, 86400000)); // 24 hours
        } else {
            if (timer) {
                clearTimeout(timer);
                setTimer(null);
            }
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [secureLoggingDuration]);

    return (
        <SwitchToggler
            isChecked={secureLoggingDuration}
            onChange={toggleSwitch}
        />
    );
};

export default TimerSwitch;