import React, { useState, useRef, useEffect } from "react";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import styled from "styled-components";
import { IoSearchOutline } from "react-icons/io5";
import { Input } from "antd";
import ToTransferFunds from "assets/img/To-transferFunds.svg";
import FromTransferFunds from "assets/img/From-transferFunds.svg";

const TransferFundDropdown = () => {
    const optionsWithImages = [
        { label: "USDT", image: ToTransferFunds, description: "TetherUS" },
        {
            label: "PKR",
            image: FromTransferFunds,
            description: "Pakistani Rupee",
        },
    ];

    const handleSelect = (selected) => {
        console.log("Selected fund:", selected);
    };

    const handleToggle = (isOpen) => {
        console.log("Dropdown is", isOpen ? "Open" : "Closed");
    };

    return (
        <Dropdown
            label="Select a fund"
            options={optionsWithImages}
            initialSelected={optionsWithImages[0].label}
            onSelect={handleSelect}
            isOpen={false}
            onToggle={handleToggle}
            width="100%"
            isSearchable={true}
        />
    );
};

const Dropdown = ({
    label,
    options,
    initialSelected,
    onSelect,
    isOpen,
    onToggle,
    width = "auto",
    isSearchable,
}) => {
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const [search, setSearch] = useState("");
    const [selected, setSelected] = useState(initialSelected);
    const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen);
    const filteredOptions = isSearchable
        ? options.filter((option) =>
              option.label.toLowerCase().includes(search.toLowerCase())
          )
        : options;

    useEffect(() => {
        setSelected(initialSelected);
    }, [initialSelected]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleOptionClick = (option) => {
        setSelected(option.label);
        onSelect(option.label);
        setIsDropdownOpen(false);
        setSearch("");
    };

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setSearch("");
        onToggle(!isDropdownOpen);
    };

    // Find the selected option's image and description
    const selectedOption = options.find((option) => option.label === selected);

    return (
        <div
            className="relative min-w-[190px] inline-block w-1/2 text-left"
            style={{ width }}
            onMouseLeave={() => setIsDropdownOpen(false)} // Close dropdown on mouse leave
        >
            <div
                ref={buttonRef}
                onMouseEnter={() => setIsDropdownOpen(true)} // Open dropdown on hover
            >
                <button
                    onClick={handleToggleDropdown}
                    type="button"
                    className="inline-flex bg-gray-100 items-center w-full justify-between gap-x-1.5 rounded-lg px-3 py-3 text-sm font-semibold text-gray-900 hover:bg-gray-50"
                    aria-expanded={isDropdownOpen}
                    aria-haspopup="true"
                >
                    <div className="flex justify-between w-full">
                        <div className="flex items-center w-full justify-between gap-2">
                            <div className="flex items-center">
                                {selectedOption && selectedOption.image && (
                                    <img
                                        src={selectedOption.image}
                                        alt={selectedOption.label}
                                        className="w-5 h-5 mr-2"
                                    />
                                )}
                                <p className="flex gap-2 items-end font-medium">
                                    <span className="text-sm">
                                        {selectedOption.label}
                                    </span>
                                    <span className="text-xs text-gray-500 block">
                                        {selectedOption.description}
                                    </span>
                                </p>
                            </div>
                            {isDropdownOpen ? (
                                <MdOutlineArrowDropUp className="text-[18px] text-[#707A8A]" />
                            ) : (
                                <MdOutlineArrowDropDown className="text-[18px] text-[#707A8A]" />
                            )}
                        </div>
                    </div>
                </button>
            </div>

            {isDropdownOpen && (
                <div
                    className="absolute left-0 right-0 h-2 cursor-default"
                    style={{ top: "100%" }}
                    onMouseEnter={() => setIsDropdownOpen(true)} // Keep dropdown open on hover
                    onMouseLeave={() => setIsDropdownOpen(false)} // Close dropdown on mouse leave
                ></div>
            )}

            {isDropdownOpen && (
                <div
                    ref={dropdownRef}
                    onMouseEnter={() => setIsDropdownOpen(true)} // Keep dropdown open on hover
                    onMouseLeave={() => setIsDropdownOpen(false)} // Close dropdown on mouse leave
                    className="absolute z-10 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    style={{ top: "calc(100% + 8px)" }} // Simulate the gap
                    aria-orientation="vertical"
                    tabIndex="-1"
                >
                    <div className="py-1">
                        <div className="m-2">
                            {isSearchable && (
                                <div>
                                    <StyledInput
                                        type="text"
                                        placeholder="Search funds"
                                        value={search}
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                                        prefix={<IoSearchOutline />}
                                    />
                                </div>
                            )}
                        </div>
                        <ul className="max-h-60 overflow-auto">
                            {filteredOptions.length > 0 ? (
                                filteredOptions.map((option) => (
                                    <li
                                        key={option.label}
                                        onClick={() =>
                                            handleOptionClick(option)
                                        }
                                        className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                                    >
                                        <div className="flex justify-between w-full">
                                            <div className="flex items-center">
                                                {option.image && (
                                                    <img
                                                        src={option.image}
                                                        alt={option.label}
                                                        className="w-5 h-5 mr-2"
                                                    />
                                                )}
                                                <div className="flex gap-2 items-end">
                                                    <p className="text-sm font-medium">
                                                        {option.label}
                                                    </p>
                                                    <p className="text-xs text-gray-500 font-medium">
                                                        {option.description}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-end text-xs">
                                                <p className="text-sm">0</p>
                                                <p className="text-xs text-gray-500">
                                                    $0.00
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                ))
                            ) : (
                                <li className="px-4 py-2 text-[14px] text-center text-gray-500">
                                    No results found
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

const StyledInput = styled(Input)`
    padding: 0.55rem;
    font-weight: bold;
    color: #707a8a;
    border: 2px solid #dbdbdb;
    transition: border-color 0.3s ease;

    &:hover {
        border-color: #efb81c;
    }

    &:focus,
    &:focus-within {
        border-color: #efb81c;
        box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.2);
        outline: none;
    }
`;

export default TransferFundDropdown;
