import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const TradeAreaIsolated = ({ setOrderTab, selectedCoin }) => {
    const { TOKEN, ID } = useSelector((state) => state.authUser.user) || {};

    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState("");
    const [total, setTotal] = useState("");
    const [maxSize, setMaxSize] = useState(0);
    const [available, setAvailable] = useState(0);
    const [currentLoan, setCurrentLoan] = useState(0);
    const [coin, setCoin] = useState("");
    const [token, setToken] = useState("");
    const [assets, setAssets] = useState([]);
    const [value, setValue] = useState(0);

    useEffect(() => {
        // Fetch the asset data from the API
        fetch("https://api.buycex.com/cmc/assets")
            .then((response) => response.json())
            .then((data) => {
                if (data.code === "200" && data.data) {
                    setAssets(data.data);
                }
            })
            .catch((error) => console.error("Error fetching assets:", error));
    }, []);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleQuantityChange = (e) => {
        const value = e.target.value;
        setQuantity(value);
        setTotal(value * price);
    };

    return (
        <div className="trade-area-isolated">
            <div className="trade-area-isolated__inner">
                <div className="flex items-center gap-2 margin-selector">
                    <label
                        htmlFor="margin-selector"
                        className="text-[12px] font-[500]"
                    >
                        Margin
                    </label>
                    <select
                        name="margin-selector"
                        id="margin-selector"
                        className="text-[12px] font-[500] min-w-[50px]"
                    >
                        <option value="1x" selected>
                            1x
                        </option>
                        <option value="2x">2x</option>
                        <option value="3x">3x</option>
                        <option value="4x">4x</option>
                        <option value="5x">5x</option>
                        <option value="6x">6x</option>
                        <option value="7x">7x</option>
                        <option value="8x">8x</option>
                        <option value="9x">9x</option>
                        <option value="10x">10x</option>
                    </select>
                </div>

                <div className="flex flex-col gap-4 isolated__field">
                    {/* Order Price */}
                    <div className="flex flex-col gap-1">
                        <label
                            className="w-max text-[14px] text-[#15141580]"
                            htmlFor="order-price"
                        >
                            Order Price
                        </label>
                        <input
                            type="number"
                            id="order-price"
                            name="order-price"
                            placeholder="Enter Price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            className="w-full px-2 py-1.5 text-[12px] placeholder:text-[11px] border rounded-md"
                        />
                    </div>

                    {/* Quantity */}
                    <div className="flex flex-col gap-1">
                        <label
                            className="w-max text-[14px] text-[#15141580]"
                            htmlFor="quantity"
                        >
                            Quantity
                        </label>
                        <input
                            type="number"
                            id="quantity"
                            name="quantity"
                            placeholder="Enter Quantity"
                            value={quantity}
                            onChange={handleQuantityChange}
                            className="w-full px-2 py-1.5 text-[12px] placeholder:text-[11px] border rounded-md"
                        />
                    </div>

                    {/* Range Selector Slider */}
                    <div className="flex flex-col">
                        <input
                            id="range-slider"
                            name="range-slider"
                            type="range"
                            value={value}
                            min="0"
                            max="100"
                            step="25"
                            onChange={handleChange}
                            className="w-full"
                        />
                        <div className="relative w-full">
                            <span className="text-[11px] text-[#15141580] font-bold absolute left-0 -bottom-4">
                                0%
                            </span>
                            <span className="text-[11px] text-[#15141580] font-bold absolute left-1/4 transform -translate-x-1/2 -bottom-4">
                                25%
                            </span>
                            <span className="text-[11px] text-[#15141580] font-bold absolute left-1/2 transform -translate-x-1/2 -bottom-4">
                                50%
                            </span>
                            <span className="text-[11px] text-[#15141580] font-bold absolute left-3/4 transform -translate-x-1/2 -bottom-4">
                                75%
                            </span>
                            <span className="text-[11px] text-[#15141580] font-bold absolute left-full transform -translate-x-full -bottom-4">
                                100%
                            </span>
                        </div>
                    </div>

                    {/* Total price section */}
                    <div className="flex flex-col gap-1">
                        <label
                            className="w-max text-[14px] text-[#15141580]"
                            htmlFor="total"
                        >
                            Total
                        </label>
                        <div className="flex items-center justify-between w-full gap-1 px-2 overflow-hidden border rounded-md">
                            <input
                                type="number"
                                id="total"
                                name="total"
                                value={total}
                                readOnly
                                className="py-1.5 text-[12px] placeholder:text-[11px]"
                            />
                            <span className="text-[11px] font-[bold] text-[#15141590]">
                                USDT
                            </span>
                        </div>
                    </div>

                    {/* Maximum, Available and Current Loan status */}
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between w-full gap-2">
                            <span className="text-[12px] text-[#15141580] font-[500]">
                                Max Size:
                            </span>
                            <div className="flex items-center gap-1">
                                <span className="text-[12px] text-[#15141580]">
                                    {maxSize.toFixed(4)}
                                </span>
                                <span className="text-[12px] text-[#15141580]">
                                    USDT
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-between w-full gap-2">
                            <span className="text-[12px] text-[#15141580] font-[500]">
                                Available:
                            </span>
                            <div className="flex items-center gap-1">
                                <span className="text-[12px] text-[#15141580]">
                                    {available.toFixed(4)}
                                </span>
                                <span className="text-[12px] text-[#15141580]">
                                    USDT
                                </span>
                            </div>
                        </div>
                        <div className="flex justify-between w-full gap-2">
                            <span className="text-[12px] text-[#15141580] font-[500]">
                                Current Loan:
                            </span>
                            <div className="flex items-center gap-1">
                                <span className="text-[12px] text-[#15141580]">
                                    {currentLoan.toFixed(4)}
                                </span>
                                <span className="text-[12px] text-[#15141580]">
                                    USDT
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TradeAreaIsolated;
