import Repository from "./Repository";
const myAdsEndPoint= "/P2p/myads";
const SellTrade = "P2p/doTrade";
const trade = "/P2p/orders";
const faq = "/P2p/faq";
const newad = "/P2p/newad";
const doNewAd = "/P2p/doNewAd";
const MerchantApply = "/P2p/doApply";
const grabPrice = "/P2p/grabPrice";
const DelOrder = "/P2p/closelisting";
const ActiveAd = "/P2p/myadsActiveOffline";
const delpm = "/P2p/del_user_method";
const markAsPaid = "/P2p/markAsPaid";
const confirmCancel = "/P2p/confirmCancel";
const AddPaymentMethod = "/P2p/user_add_method";
const p2pInfoEndpoint = "P2p";
const TradingExp = "P2p/tradingExperience";
const quick = "/P2p/quick"
const p2pPaymentMethodsNew = '/P2p/getP2pPaymentMethodsNew';


export default {
    
    getP2PInfo(type,coin,fiatValue,userid,usertoken,fiat_qty,pm) {
        return Repository.get(`${p2pInfoEndpoint}/index?type=${type}&fiat=${fiatValue}&coin=${coin}&fiat_qty=${fiat_qty}&pm=${pm}`, {
            headers: {
                'ID': userid,
                'TOKEN': usertoken,
            }
        });
    },

    delpm(userId, token,payload) {
        return Repository.post(`${delpm}`,payload, {
            headers: {
                'token': token,
                'id': userId,
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    markAsPaid(userId, token,payload) {
        return Repository.post(`${markAsPaid}`,payload, {
            headers: {
                'token': token,
                'id': userId,
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    confirmCancel(userId, token,payload) {
        return Repository.post(`${confirmCancel}`,payload, {
            headers: {
                'token': token,
                'id': userId,
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    trade(userid,usertoken) {
        return Repository.get(`${trade}`, {
            headers: {
                'ID': userid,
                'TOKEN': usertoken,
            }
        });
    },

    faq(userid,usertoken) {
        return Repository.get(`${faq}`, {
            headers: {
                'ID': userid,
                'TOKEN': usertoken,
            }
        });
    },

    newad(userid,usertoken) {
        return Repository.get(`${newad}`, {
            headers: {
                'ID': userid,
                'TOKEN': usertoken,
            }
        });
    },

    getMyAds(userID,userToken,payload) {
        return Repository.post(`${myAdsEndPoint}`,payload, {
            headers: {
                'ID': userID,
                'TOKEN': userToken,
            }
        });
    },
    doNewAd(userId,token,payload) {
        return Repository.post(`${doNewAd}`,payload, {
            headers: {
                'ID': userId,
                'TOKEN': token,
                'Content-Type': "multipart/form-data",
            }
        });
    },
    SellTrade(userId,token,payload) {
        return Repository.post(`${SellTrade}`,payload, {
            headers: {
                'ID': userId,
                'TOKEN': token,
                'Content-Type': "multipart/form-data",
            }
        });
    },
    DelOrder(userID,userToken,payload) {
        return Repository.post(`${DelOrder}`,payload, {
            headers: {
                'ID': userID,
                'TOKEN': userToken,
                'Content-Type': "multipart/form-data",
            }
        });
    },
    ActiveAd(userID,userToken,payload) {
        return Repository.post(`${ActiveAd}`,payload, {
            headers: {
                'ID': userID,
                'TOKEN': userToken,
                'Content-Type': "multipart/form-data",
            }
        });
    },

    grabPrice(userID,userToken,payload) {
        return Repository.post(`${grabPrice}`,payload, {
            headers: {
                'ID': userID,
                'TOKEN': userToken,
                'Content-Type': "multipart/form-data",
            }
        });
    },

    AddPaymentMethod(userId,token,payload) {
        return Repository.post(`${AddPaymentMethod}`,payload, {
            headers: {
                'ID': userId,
                'TOKEN': token,
                'Content-Type': "multipart/form-data",
            }
        });
    },

    TradingExp(userId,token,payload) {
        return Repository.post(`${TradingExp}`,payload, {
            headers: {
                'ID': userId,
                'TOKEN': token,
                'Content-Type': "multipart/form-data",
            }
        });
    },

    quick(userId, token) {
        return Repository.get(`${quick}`, {
            headers: {
                'ID': userId,
                'TOKEN': token,
            }
        });
    },

    MerchantApply(userId, token) {
        return Repository.get(`${MerchantApply}`, {
            headers: {
                'ID': userId,
                'TOKEN': token,
            }
        });
    },

    getP2pPaymentMethodsNew(userId, token) {
        return Repository.get(`${p2pPaymentMethodsNew}`, {
            headers: {
                'ID': userId,
                'TOKEN': token,
            }
        });
    }
    
}