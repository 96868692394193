import React, { useState } from 'react';
import { FaFileCircleCheck } from "react-icons/fa6";
import Notes from './Notes'; // Ensure the path is correct
import TableUncreditedDepositForm from '../tables/TableUncreditedDepositForm'; // Adjust the path as necessary
import { Tooltip } from 'react-tooltip';
import { FaQuestionCircle } from "react-icons/fa";

const UncreditedDepositForm = () => {
  const [coin, setCoin] = useState('');
  const [recipientAddress, setRecipientAddress] = useState('');
  const [depositAmount, setDepositAmount] = useState('');
  const [txid, setTxid] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
  };

  return (
    <div>
      <div className="flex gap-6">
        <form onSubmit={handleSubmit} className="flex-[2] p-4 border rounded shadow-sm bg-white">
          <div className="mb-4">
            <label htmlFor="coin" className="block text-sm font-medium text-gray-700">
              Coin
              <FaQuestionCircle
                data-tooltip-id="coin-tooltip"
                data-tooltip-content="If you cannot find the coin/token of your deposit in the list, please enter the coin/token name directly."
                className="inline ml-1 text-gray-500 cursor-pointer"
              />
              <Tooltip id="coin-tooltip" place="top" effect="solid" />
            </label>
            <input
              type="text"
              id="coin"
              value={coin}
              onChange={(e) => setCoin(e.target.value)}
              placeholder="Please select your deposit coin"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="recipientAddress" className="block text-sm font-medium text-gray-700">
              Recipient Address For Deposit
              <FaQuestionCircle
                data-tooltip-id="recipientAddress-tooltip"
                data-tooltip-content="The recipient address of this transaction."
                className="inline ml-1 text-gray-500 cursor-pointer"
              />
              <Tooltip id="recipientAddress-tooltip" place="top" effect="solid" />
            </label>
            <input
              type="text"
              id="recipientAddress"
              value={recipientAddress}
              onChange={(e) => setRecipientAddress(e.target.value)}
              placeholder="Please enter your recipient address for deposit"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="depositAmount" className="block text-sm font-medium text-gray-700">
              Deposit Amount
              <FaQuestionCircle
                data-tooltip-id="depositAmount-tooltip"
                data-tooltip-content="Please fill in the deposit amount of this transaction as accurately as possible. We support up to 8 decimals of precision."
                className="inline ml-1 text-gray-500 cursor-pointer"
              />
              <Tooltip id="depositAmount-tooltip" place="top" effect="solid" />
            </label>
            <input
              type="number"
              id="depositAmount"
              value={depositAmount}
              onChange={(e) => setDepositAmount(e.target.value)}
              placeholder="Please enter your deposit amount"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="txid" className="block text-sm font-medium text-gray-700">
              TXID
              <FaQuestionCircle
                data-tooltip-id="txid-tooltip"
                data-tooltip-content="Transaction ID (TXID) is the ONLY representation of each blockchain transaction. You can find your transaction ID (TXID) in your withdrawal record. If you cannot find it, please contact your withdrawal platform for TXID."
                className="inline ml-1 text-gray-500 cursor-pointer"
              />
              <Tooltip id="txid-tooltip" place="top" effect="solid" />
            </label>
            <input
              type="text"
              id="txid"
              value={txid}
              onChange={(e) => setTxid(e.target.value)}
              placeholder="Please enter the TXID"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <button type="submit" className="w-full py-2 px-4 bg-yellow-600 text-white font-semibold rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
            Save
          </button>
        </form>
        <Notes />
      </div>
      <TableUncreditedDepositForm />
    </div>
  );
};

export default UncreditedDepositForm;
