import React, { useEffect, useState } from 'react';
import { FaAngleRight } from "react-icons/fa6";
import Holding from './Holding';
import Hot from './Hot';
import NewListing from './NewListing';
import TopGainers from './TopGainers';
import HVolume from './24hVolume';
import CopyTrading from './CopyTrading';
import Earn from './Earn';
import AllCoins from './AllCoins';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import fetchNewDashboard from 'store/actions/newDashboard';
import { fetchUserDashboard, fetchUserProfile } from 'store/actions/userProfileAction';

const MainTable = () => {
    const [activeItem, setActiveItem] = useState('AllCoins'); // Default active item
    const [isDiscoverActive, setIsDiscoverActive] = useState(false); // Discover state

    const navItems = ['Holding', 'AllCoins', 'New Listing', 'Top Gainers', '24h Volume'];
    const discoverNavItems = ['Copy Trading', 'Earn'];

    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;

    useEffect(() => {
        dispatch(fetchNewDashboard(userId, token, activeItem));
    }, [dispatch, userId, token, history, activeItem]);

    const { newDashboardData } = useSelector(
        (state) => state.newDashboardData
    );

    useEffect(() => {
        dispatch(fetchUserProfile(userId, token));
        dispatch(fetchUserDashboard(userId, token));
    }, [dispatch, userId, token, history]);
    const { userDashboard } = useSelector((state) => state.userDashboard);
    const allCoinsList = userDashboard?.coinList?.allCoinsList || {};
    const holdingsList = userDashboard?.coinList?.holdingsList || {};
    const newCoinList = userDashboard?.coinList?.newCoinList || {};
    const topGainersList = userDashboard?.coinList?.topGainersList || {};
    const twentyFourHVolumn = userDashboard?.coinList?.["24HVolumn"] || {};

    console.log("allCoinsList", allCoinsList);

    // Function to render the content based on active item
    const renderContent = () => {
        if (isDiscoverActive) {
            switch (activeItem) {
                case 'Copy Trading':
                    return <CopyTrading />;
                case 'Earn':
                    return <Earn />;
                default:
                    return <CopyTrading />;
            }
        } else {
            switch (activeItem) {
                case 'Holding':
                    return <Holding data={holdingsList} />;
                case 'New Listing':
                    return <NewListing data={newCoinList} />;
                case 'AllCoins':
                    return <AllCoins data={allCoinsList} />;
                case 'Top Gainers':
                    return <TopGainers data={topGainersList} />;
                case '24h Volume':
                    return <HVolume data={twentyFourHVolumn} />;
                default:
                    return <Holding data={holdingsList} />;
            }
        }
    };

    return (
        <div className='p-3 sm:p-6 mt-6 border rounded-2xl'>
            <div className='flex justify-between'>
                <div className='flex gap-5'>
                    <p
                        className={`font-semibold text-2xl cursor-pointer ${!isDiscoverActive ? '' : 'text-gray-400'}`}
                        onClick={() => {
                            setIsDiscoverActive(false);
                            setActiveItem('Holding'); // Reset to default tab when going back to Market
                        }}
                    >
                        Wallets
                    </p>
                    <p
                        className={`font-semibold text-2xl cursor-pointer ${isDiscoverActive ? '' : 'text-gray-400'}`}
                        onClick={() => {
                            setIsDiscoverActive(true);
                            setActiveItem('Copy Trading'); // Set default active tab for Discover
                        }}
                    >
                        Markets
                    </p>
                </div>
                <div>
                    <div className='flex items-center'>
                        <p className='text-sm'>More</p>
                        <FaAngleRight className='mt-0.5' size={14}/>
                    </div>
                </div>
            </div>

            {/* Conditionally render nav items based on whether Discover is active */}
            <nav className='flex gap-10 whitespace-nowrap w-full overflow-x-auto  border-gray-200 pt-2'
                style={{
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                    overflowX: "auto",
                }}>
                {isDiscoverActive
                    ? discoverNavItems.map((item) => (
                        <button
                            key={item}
                            onClick={() => setActiveItem(item)}
                            className={`relative py-2 text-md font-semibold ${activeItem === item ? '' : 'text-gray-400'
                                }`}
                        >
                            {item}
                            {activeItem === item && (
                                <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                            )}
                        </button>
                    ))
                    : navItems.map((item) => (
                        <button
                            key={item}
                            onClick={() => setActiveItem(item)}
                            className={`relative py-2 text-md font-semibold ${activeItem === item ? '' : 'text-gray-400'
                                }`}
                        >
                            {item}
                            {activeItem === item && (
                                <span className="absolute left-1/2 bottom-0 w-[20px] h-[3px] bg-[#EFB81C] transform -translate-x-1/2 origin-center transition-all duration-500 ease-out hover:w-[20px]"></span>
                            )}
                        </button>
                    ))}
            </nav>

            <div className="mt-6">{renderContent()}</div> {/* Render the content of the active tab */}
        </div>
    );
};

export default MainTable;
