import React, { useState, useRef } from "react";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";

const AppealDropdown = ({ label, options, selected, onSelect, isOpen, onToggle, width, isSearchable }) => {
    const dropdownRef = useRef(null);
    const [search, setSearch] = useState('');
    const filteredOptions = isSearchable
        ? options.filter(option => option.toLowerCase().includes(search.toLowerCase()))
        : options;

    return (
        <div
            ref={dropdownRef}
            className="relative inline-block text-left mt-8 mb-5"
            style={{ width }} // Apply the width prop here
        >
            {label && (
                <label className="block text-[12px] text-gray-700 mb-1">
                    {label}
                </label>
            )}
            <div>
                <button
                    onClick={() => onToggle(!isOpen)}
                    type="button"
                    style={{ border: '0' }}
                    className="inline-flex items-center w-full justify-between gap-x-1.5 bg-gray-100 rounded-md px-3 py-3 text-sm text-gray-900 hover:bg-gray-200"
                    aria-expanded={isOpen}
                    aria-haspopup="true"
                >
                    <span className="text-[14px]">{selected}</span>
                    {isOpen ? <MdOutlineArrowDropUp /> : <MdOutlineArrowDropDown />}
                </button>
            </div>
            {isOpen && (
                <div className="absolute top-full left-0 w-full mt-1 bg-white rounded-xl shadow-lg z-10">
                    {isSearchable && (
                        <input
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={`Search ${label}...`}
                            className="w-[92%] py-2 px-2 bg-[#EDEDED] rounded-md shadow-sm m-2 text-sm"
                        />
                    )}
                    <ul className="max-h-60 overflow-auto">
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map(option => (
                                <li
                                    key={option}
                                    onClick={() => onSelect(option)}
                                    className="px-4 py-2 text-[14px] cursor-pointer hover:bg-gray-100"
                                >
                                    {option}
                                </li>
                            ))
                        ) : (
                            <li className="px-4 py-2 text-[14px] text-center text-gray-500">No results found</li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default AppealDropdown;
