import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { grabPrice, newad } from "store/actions/p2pActions";
import Dropdown from "./DropdownUI"; // Import the updated Dropdown

const FiatDropdown = ({ onCryptoSelect, onFiatSelect, onResultValueChange, onResultSymbolChange }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authUser?.user || {});
    const userId = user?.ID;
    const token = user?.TOKEN;

    // Access selectedCrypto and selectedFiat from the Redux state
    const selectedCryptoFromState = useSelector((state) => state.stepone?.selectedCrypto || "");
    const selectedFiatFromState = useSelector((state) => state.stepone?.selectedFiat || "");
    console.log("selectedCrypto: " + selectedCryptoFromState)

    const [cryptoOptions, setCryptoOptions] = React.useState([]);
    const [fiatOptions, setFiatOptions] = React.useState([]);

    useEffect(() => {
        const fetchDatas = async () => {
            const result = await dispatch(newad(userId, token));
            if (result) {
                setCryptoOptions(result.data.allow_cryptos || []);
                const fiatTitles = result.data.FiatList.map((fiat) => fiat.title) || [];
                setFiatOptions(fiatTitles);
            }
        };
        fetchDatas();
    }, [dispatch, userId, token]);

    // Dispatch selected options whenever they change or the component mounts
    useEffect(() => {
        const fetchData = async () => {
            const fiatValue = selectedFiatFromState === 'Indian Rupees' ? 'INR' : selectedFiatFromState;
            const payload = {
                crypto: selectedCryptoFromState,
                fiat: fiatValue,
            };
            const result = await dispatch(grabPrice(userId, token, payload));
            if (result && result.data) {
                if (result.data.status === 1) {
                    onResultValueChange(result.data.value);
                    onResultSymbolChange(result.data.symbol);
                } else {
                    console.error("Error fetching data:", result.data);
                }
            } else {
                console.error("No result received");
            }
        };

        // Call fetchData whenever selectedCrypto or selectedFiat changes
        if (selectedCryptoFromState && selectedFiatFromState) {
            fetchData();
        }
    }, [dispatch, userId, token, selectedCryptoFromState, selectedFiatFromState, onResultValueChange, onResultSymbolChange]);

    const handleCryptoSelect = (option) => {
        console.log("Crypto selected:", option);
        onCryptoSelect(option);
        // Dispatch action to update the Redux state
        dispatch({ type: 'SET_SELECTED_CRYPTO', payload: option }); // Ensure this action exists
    };

    const handleFiatSelect = (option) => {
        console.log("Fiat selected:", option);
        onFiatSelect(option);
        // Dispatch action to update the Redux state
        dispatch({ type: 'SET_SELECTED_FIAT', payload: option }); // Ensure this action exists
    };

    // Dispatch the selected options whenever the component mounts
    useEffect(() => {
        if (selectedCryptoFromState) {
            dispatch({ type: 'SET_SELECTED_CRYPTO', payload: selectedCryptoFromState });
        }
        if (selectedFiatFromState) {
            dispatch({ type: 'SET_SELECTED_FIAT', payload: selectedFiatFromState });
        }
    }, [dispatch, selectedCryptoFromState, selectedFiatFromState]);

    return (
        <div className="flex w-full gap-4">
            {/* Crypto Dropdown */}
            <div className="flex flex-col w-full">
                <p className="font-light text-sm py-2">Asset</p>
                <Dropdown
                    label="Crypto"
                    options={cryptoOptions}
                    initialSelected={selectedCryptoFromState} // Ensure this is the Redux state
                    onSelect={handleCryptoSelect}
                    width="100%" 
                />
            </div>

            {/* Fiat Dropdown */}
            <div className="flex flex-col w-full">
                <p className="font-light text-sm py-2">With Fiat</p>
                <Dropdown
                    label="Fiat"
                    options={fiatOptions}
                    initialSelected={selectedFiatFromState} // Ensure this is the Redux state
                    onSelect={handleFiatSelect}
                    width="100%" 
                />
            </div>
        </div>
    );
};

export default FiatDropdown;