import React from 'react';
import { HiOutlineChevronRight } from "react-icons/hi2";
import { LiaQuestionCircle } from "react-icons/lia";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa6";

function HeroSection() {
    const coins = [
        { name: 'BTC/USDT', change: '+2.5%' },
        { name: 'ETH/USDT', change: '-1.3%' },
        { name: 'DOGE/USDT', change: '+0.8%' },
        { name: 'XRP/USDT', change: '+1.0%' },
        { name: 'TONCOIN/USDT', change: '-0.5%' },
        { name: 'PEPE/USDT', change: '-0.5%' },
        { name: 'BONK/USDT', change: '-0.5%' }
    ];

    return (
        <>
            <div className='h-[400px] flex items-center bg-black'>
                <div className='flex container flex-col'>
                    <span className="text-[48px] font-semibold text-[#EFB81C]">World's First</span>
                    <span className="text-[48px] font-semibold text-white">Community Owned</span>
                    <div className='flex gap-4'>
                        <span className="text-[48px] font-semibold text-white">Crypto Exchange.</span>
                        <div className='rounded-full h-fit p-1 bg-[#1a1b1f]'>
                            <HiOutlineChevronRight className='text-2xl text-[#81858c]' />
                        </div>
                    </div>
                    <div className='flex mt-5 gap-3'>
                    <div className="flex w-80 relative">
                        <input className="bg-white rounded-full text-sm px-4 w-72 h-12 pr-24" placeholder="Email or Phone" />
                        <button className="w-24 h-12 font-medium text-white absolute right-0 top-0 rounded-full bg-[#EFB81C]">
                            Sign Up
                        </button>
                    </div>
                    <div className='p-3 bg-white rounded-full cursor-pointer'><FaApple size={22}/></div>
                    <div className='p-3 bg-white rounded-full cursor-pointer'><FcGoogle size={22}/></div>
                    </div>
                    <div>
                        <div className='flex items-center mt-5 gap-3'>
                            <div className='flex gap-2'><p className='text-[#81858c]'>Maker</p> <p className='text-white font-semibold'>0%</p></div>
                            <span className="border border-l border-slate-400 opacity-55 h-3"></span>
                            <div className='flex gap-2'><p className='text-[#81858c]'>Taker</p> <p className='text-white font-semibold'>0.01%-0.02%</p><LiaQuestionCircle className='text-xl mb-1' fill='#81858c' /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='h-8 bg-[#EFB81C] flex items-center gap-10 px-4'>
                {coins.map((coin, index) => (
                    <div key={index} className='text-white text-md font-semibold flex gap-1'>
                        <span>{coin.name}</span>
                        <span className="text-white font-medium">
                            ({coin.change})
                        </span>
                    </div>
                ))}
            </div>
        </>
    );
}

export default HeroSection;
