import React from 'react';
import { IoArrowForwardOutline } from "react-icons/io5";
import { BsCashCoin } from "react-icons/bs";
import AuraAIZone from './AuraAIZone';
import Tables from './Tables';
import Tutorial from './Tutorial';
import Faq from './FAQs';

function TradingBot() {
    const boxes = [
        { icon: <BsCashCoin className='text-2xl' />, heading: 'Futures Grid', description: 'Multiply your earnings by 100x | ideal for any market conditions.', HighestAPR: '150%' },
        { icon: <BsCashCoin className='text-2xl' />, heading: 'Futures Martingale', description: 'Double down and bounce back quickly from losses!', HighestAPR: '200%' },
        { icon: <BsCashCoin className='text-2xl' />, heading: 'Futures Combo', description: 'Create and maintain an auto-rebalancing portfolio of long/short positions', HighestAPR: '250%' },
        { icon: <BsCashCoin className='text-2xl' />, heading: 'Spot Grid', description: 'Buy low & sell high automatically | ideal for a sideways markets', HighestAPR: '180%' },
        { icon: <BsCashCoin className='text-2xl' />, heading: 'DCA', description: 'Invest on autopilot regardless of market conditions | suitable in bull and bear markets', HighestAPR: '300%' },
    ];

    return (
        <div className='mt-5'> 
            <div className='container px-3'>
                <div className='!p-0 flex items-center w-full justify-between'>
                    <p className='text-3xl font-semibold'>Create My Bot</p>
                    <button className='bg-[#EFB81C] px-3 py-2 rounded-md text-sm font-semibold'>Daily Picks</button>
                </div>

                <div className='grid grid-cols-5 gap-4 my-8'>
                    {boxes.map((box, index) => (
                        <div key={index} className='border rounded-xl w-full p-6 flex flex-col justify-between'>
                            <div>
                                <div className='flex items-center justify-between w-full'><p className='text-lg font-bold'>{box.icon}</p><IoArrowForwardOutline className='text-xl' /></div>
                                <p className='text-md font-bold py-3'>{box.heading}</p>
                                <h3 className='text-xs text-[#81858c]'>{box.description}</h3>
                            </div>
                            <div className='flex items-center gap-2'>
                                <p className='text-xs text-[#81858c]'>Highest APR</p><p className='text-md font-bold py-3 text-[#20b26c]'>{box.HighestAPR}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <AuraAIZone />
            <Tables />
            <Tutorial />
            <Faq />
        </div>
    );
}

export default TradingBot;
