import React, { useEffect, useState } from 'react';
import HistoryDropdown from '../SpotHistory/DropDownUI';
import { DatePicker, Modal, Input } from 'antd';
import { createGlobalStyle, styled } from "styled-components";
import dayjs from 'dayjs';
import { IoSearchOutline } from "react-icons/io5";
import NoData from "assets/img/user/No-data.svg";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { transferHistory } from 'store/actions/commonActions';

const { RangePicker } = DatePicker;

function CryptoTransferHistory() {
    const options1 = ["All", "Completed", "Cancelled"];
    const option2 = ["Past 7 days", "Past 30 days", "Past 90 days", "Customized"];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDates, setSelectedDates] = useState([null, null]);
    const [selectedOption, setSelectedOption] = useState("Past 7 days");
    const [txIdFilter, setTxIdFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('All');

    const handleSelect1 = (selected) => {
        setStatusFilter(selected);
    };

    const handleSelect2 = (selected) => {
        if (selected === "Customized") {
            setIsModalVisible(true);
        } else {
            setSelectedOption(selected);
        }
    };

    const handleOk = () => {
        if (selectedDates[0] && selectedDates[1]) {
            const formattedStartDate = dayjs(selectedDates[0]).format('YYYY-MM-DD');
            const formattedEndDate = dayjs(selectedDates[1]).format('YYYY-MM-DD');
            setSelectedOption(`${formattedStartDate} - ${formattedEndDate}`);
        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedDates([null, null]);
    };

    const userID = useSelector((state) => state.authUser?.user?.ID);
    const userToken = useSelector((state) => state.authUser?.user?.TOKEN);
    const { data, loading, error } = useSelector((state) => state.transferHistory);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userID && userToken) {
            dispatch(transferHistory(userID, userToken));
        }
    }, [dispatch, userID, userToken]);

    console.log("data api",data);

    const resetFilters = () => {
        setTxIdFilter('');
        setStatusFilter('All');
        setSelectedOption("Past 7 days");
        setSelectedDates([null, null]);
        setIsModalVisible(false);
    };

    const currentTime = dayjs().unix();
    let startTime, endTime;

    if (selectedOption === "Past 7 days") {
        startTime = currentTime - (7 * 24 * 60 * 60);
        endTime = currentTime;
    } else if (selectedOption === "Past 30 days") {
        startTime = currentTime - (30 * 24 * 60 * 60);
        endTime = currentTime;
    } else if (selectedOption === "Past 90 days") {
        startTime = currentTime - (90 * 24 * 60 * 60);
        endTime = currentTime;
    } else if (selectedDates[0] && selectedDates[1]) {
        startTime = dayjs(selectedDates[0]).unix();
        endTime = dayjs(selectedDates[1]).unix();
    } else {
        startTime = currentTime - (7 * 24 * 60 * 60);
        endTime = currentTime;
    }

    const filteredData = data?.filter((item) => {
        const matchesStatus =
            statusFilter === "All" ||
            (statusFilter === "Completed" && item.status === "1") ||
            (statusFilter === "Cancelled" && item.status === "0");

        const matchesTxId = txIdFilter === '' || item.id?.includes(txIdFilter);

        const itemTime = parseInt(item.created_at);
        const matchesDate = itemTime >= startTime && itemTime <= endTime;

        return matchesStatus && matchesTxId && matchesDate;
    }) || [];

    return (
        <div className='mt-10'>
            <div className='flex gap-4 items-center'>
                <HistoryDropdown
                    label="Status"
                    options={options1}
                    initialSelected={statusFilter}
                    onSelect={handleSelect1}
                    isSearchable={false}
                    width="80px"
                />
                <HistoryDropdown
                    label="Time"
                    options={option2}
                    initialSelected={selectedOption}
                    onSelect={handleSelect2}
                    isSearchable={false}
                    width="120px"
                />
                <Modal
                    title="Select Date Range"
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <div className="flex flex-col gap-4">
                        <p className='mt-10'>Select your time range within 12 months.</p>
                        <GlobalStyles />
                        <StyledRangePicker
                            onChange={(dates) => setSelectedDates(dates)}
                            value={selectedDates}
                        />
                        <button key="ok" type="primary" onClick={handleOk} className='px-4 py-2 my-7 font-semibold bg-[#EFB81C] rounded-lg'>Continue</button>
                    </div>
                </Modal>
                <StyledInput
                    type="text"
                    placeholder="Enter ID"
                    className="w-[140px] px-3 ring-1 ring-inset ring-gray-200 py-2 border border-gray-100 rounded-lg"
                    prefix={<IoSearchOutline />}
                    value={txIdFilter}
                    onChange={(e) => setTxIdFilter(e.target.value)}
                />
                <button className='text-[#EFB81C] font-semibold ml-2' onClick={resetFilters}>Reset</button>
            </div>
            <div className="text-xs mt-12 text-[#707a8a] w-full overflow-x-auto">
                <div className="flex justify-between border-b pb-3 w-full">
                    <p className="flex-1 text-start">ID</p>
                    <p className="flex-1 text-start">From Account</p>
                    <p className="flex-1 text-start">To Account</p>
                    <p className="flex-1 text-center">Amount</p>
                    <p className="flex-1 text-center">Time</p>
                    <p className="flex-1 text-center">Status</p>
                </div>
                {filteredData && filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                        <div key={index} className="flex justify-between w-full py-3">
                            <p className="flex-1 text-start">{item.id}</p>
                            <p className="flex-1 text-start">{item.from_account}</p>
                            <p className="flex-1 text-start">{item.to_account}</p>
                            <p className="flex-1 text-center">{parseFloat(item.amount).toFixed(2)}</p>
                            <p className="flex-1 text-center">{new Date(item.created_at * 1000).toLocaleString()}</p>
                            <p className="flex-1 text-center">{item.status === "1" ? "Completed" : "Cancelled"}</p>
                        </div>
                    ))
                ) : (
                    <div className="flex justify-center items-center mt-20">
                        <img src={NoData} alt="No Data" />
                        <p className="text-black mt-2">No data available</p>
                    </div>
                )}
            </div>
        </div>
    );
}


export default CryptoTransferHistory;

const GlobalStyles = createGlobalStyle`
    .ant-picker {
        border-radius: 0.375rem;
    }
`;

const StyledRangePicker = styled(RangePicker)`
    .ant-picker {
        border-radius: 0.375rem;
    }
`;

const StyledInput = styled(Input)`
    width: 140px;
`;
