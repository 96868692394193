import React from "react";
import Faq from "react-faq-component";
// import { FaArrowRight } from "react-icons/fa";

const EarnFaqs = () => {
    const data = {
        title: "FAQ",
        rows: [
            {
                title: "What is Bybit Savings?",
                content:
                    "Bybit Savings is a new investment product on Bybit Earn that offers both flexible and fixed product terms. Bybit Savings supports BTC, ETH, USDT and other popular coins, while providing competitive and guaranteed APRs.",
            },
            {
                title: "How is my daily yield calculated?",
                content: `For flexible-term products: <br/><br/>

Your daily yield depends on the type and quantity of the staked assets. Once staked, the daily yield is automatically calculated, and the APR changes based on the token type and market conditions. For detailed information, please check the latest 24H Real-Time APR on the product page. <br/><br/>

The yield for your staked assets begins accumulating at the start of the next hour and updates every hour thereafter. The yield accrued is distributed to your Funding Account daily at 00:30AM UTC. You won't earn yield for the hour in which you unstake your assets.  <br/><br/>

Example: Alice stakes 2,000 USDT on June 1, 2023, at 7:30AM UTC. From 8AM UTC on June 1, 2023, her yield, rounded to the nearest integer, begins to accumulate hourly. At 00:30AM UTC on June 2, 2023, the accrued yield from 8AM on June 1, 2023, to 12AM (midnight) on June 2, 2023, is credited to her Funding Account. <br/><br/>

If Alice unstakes 100 USDT on June 1, 2023, at 11:30PM UTC. In this case, she will receive: <br/>
1. The yield accrued up to 11:30PM UTC and the 100 USDT principal.  <br/>
2. Additional yield generated for the remaining 1,900 USDT during the final hour of June 1, 2023. <br/><br/>

If Alice stakes an additional 10,000 USDT on June 2, 2023, at 7:30AM UTC, yield accrual for the newly-staked 10,000 USDT starts at 8AM UTC, and the additional yield is distributed to her Funding Account at 00:30AM UTC on June 3, 2023. <br/><br/>

For fixed-term products: <br/><br/>

The staking period and APR are fixed when you purchase a product plan. Both the principal and the yield will be auto-distributed to your Funding Account when the staking period ends. <br/><br/>

Total Yield = Number of Tokens Staked × (APR for Staked Token / 365) × Staking Period <br/><br/>

Calculations for your yield will start on the next day (T+1) after you’ve staked the tokens, and will be deposited in total to your Funding Account when the staking period ends. <br/><br/>

Let’s say that Alice stakes 20,000 USDT on June 1, 2022, for a fixed term of seven days, and the estimated APR for USDT is 9%. Starting at 12AM (midnight) UTC on June 2, 2022, calculations for Alice’s daily yield will begin automatically, rounded to the nearest integer. When the fixed-term product ends at 12AM (midnight) UTC on June 9, 2022, 34.52 USDT will be auto-deposited to her Funding Account, along with the principal of 20,000 USDT.
<br/><br/>

Please be aware that due to the time difference between the distribution of savings interest and the collection of loan interest, there may occasionally be a delay of up to 12 hours in interest distribution for flexible or fixed savings.`,
            },
            {
                title: "When can I unstake tokens I've staked?",
                content: `For flexible-term products: <br/> <br/>

Your staked assets can be unstaked anytime and will be automatically credited to your Funding Account immediately upon unstaking. To unstake, head to Bybit Savings in your Earn Account under Assets. Please note that in rare instances where the Asset Pool is fully utilized, the redeemable amount may be slightly lower than your staked amount.
<br/><br/>
For fixed-term products:<br/><br/>

Once your staking period begins, your tokens are locked in until maturity. Your principal and guaranteed yield will be deposited directly into your Funding Account when the staking period ends.`,
            },
            {
                title: "Can I compound my yield?",
                content:
                    "Currently, yield compounding is not available. Yield from Flexible or Fixed Savings will not be reinvested automatically to generate additional yield. However, our Auto-Savings function can maximize your earnings. Upon reaching the minimum staking amount, the Auto-Savings function will automatically invest your accrued yield in Flexible Savings, essentially letting you earn interest on your interest.",
            },
            {
                title: "What is the effective staking amount?",
                content:
                    "The Effective Staking Amount refers to the assets currently contributing to yield calculation. Newly staked assets will become effective at the start of the next hour. For example, if you stake 20,000 USDT at 10AM UTC on June 1, 2023, your Effective Staking Amount will be updated at 11AM UTC to ensure your yield calculations are always accurate.",
            },
            {
                title: "How does the APR booster work?",
                content:
                    "You can select an APR Booster when purchasing Flexible Savings. The APR Booster will take effect when your first daily yield starts to be calculated. For example, if you've purchased Flexible Savings with a 100% APR and the APR Booster you've selected offers a 10% APR, your product plan will have a cumulative APR of 110%. Your yield from the APR Booster is capped at a certain amount. The cumulative APR will be valid until the selected APR Booster expires or the cap has been reached. If you make a second purchase for a Flexible Savings plan with a previous APR Booster still in effect, the cumulative APR will be applied by default.",
            },
            {
                title: "How is yield generated for Bybit Savings products?",
                content:
                    "Yields for Bybit Savings products are generated from Bybit loan activities.",
            },
        ],
    };

    return (
        <div className="mt-20 pb-20">
            <Faq
                data={data}
                styles={{
                    titleTextColor: "black",

                    rowTitleColor: "black",
                    rowContentColor: "#81858c",
                    bgColor: "white",
                    arrowColor: "black",
                }}
            />
        </div>
    );
};

export default EarnFaqs;
