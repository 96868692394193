import React from 'react'
import Diversity from './Diversity'
import Hero from './Hero'
import TradeAnywhere from './TradeAnywhere'
import MarketOpportunities from './Market'

const Derivates = () => {
  return (
    <div className='flex flex-col w-full py-5 text-white gap-y-12'>
        <Hero/>
        <MarketOpportunities/>
        <Diversity/>
        <TradeAnywhere/>
    </div>
  )
}

export default Derivates