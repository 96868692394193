import React, { useState } from "react";
import { Modal, Input } from "antd";
import { IoClose } from "react-icons/io5";
import { FaArrowDownLong } from "react-icons/fa6";
import { PiArrowsDownUpFill } from "react-icons/pi";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import TransferDropdown from "./TransferFundDropdown";
import FromTransferFunds from "assets/img/From-transferFunds.svg";
import ToTransferFunds from "assets/img/To-transferFunds.svg";
import styled from "styled-components";

const TransferModal = ({ visible, onCancel }) => {
    const coinOptions = ["Bitcoin", "Ethereum", "Litecoin", "USDT"];
    const dropdownOptions = [
        {
            label: "Options",
            icon: <GoDotFill className="text-xs" />,
            status: "Inactive",
            additionalText: "Inactive",
        },
        {
            label: "USDⓈ-M Futures",
            icon: <GoDotFill className="text-xs" />,
            status: "Inactive",
            additionalText: "Inactive",
        },
        {
            label: "Coin-M Futures",
            icon: <GoDotFill className="text-xs" />,
            status: "Inactive",
            additionalText: "Inactive",
        },
        {
            label: "Fiat and Spot",
            icon: <GoDotFill className="text-xs" />,
            status: "Active",
            additionalText: "",
        },
    ];
    const TodropdownOptions = [
        {
            label: "Options",
            icon: <GoDotFill className="text-xs" />,
            status: "Inactive",
            additionalText: "Inactive",
        },
        {
            label: "Cross Margin",
            icon: <GoDotFill className="text-xs" />,
            status: "Active",
            additionalText: "",
        },
        {
            label: "USDⓈ-M Futures",
            icon: <GoDotFill className="text-xs" />,
            status: "Inactive",
            additionalText: "Inactive",
        },
        {
            label: "Coin-M Futures",
            icon: <GoDotFill className="text-xs" />,
            status: "Inactive",
            additionalText: "Inactive",
        },
        {
            label: "Funding",
            icon: <GoDotFill className="text-[#EFB81C]" />,
            status: "Active",
            additionalText: "",
        },
        {
            label: "Isolated Margin",
            icon: <GoDotFill className="text-[#EFB81C]" />,
            status: "Active",
            additionalText: "",
        },
    ];
    const isolatedMarginOptions = [
        {
            label: "Option 1",
            icon: <GoDotFill className="text-xs" />,
            status: "Active",
            additionalText: "",
        },
        {
            label: "Option 2",
            icon: <GoDotFill className="text-xs" />,
            status: "Active",
            additionalText: "",
        },
    ];

    const [selectedCoin, setSelectedCoin] = useState(coinOptions[0]);
    const [selectedFrom, setSelectedFrom] = useState(
        dropdownOptions.find((option) => option.status === "Active")
    );
    const [selectedTo, setSelectedTo] = useState(
        TodropdownOptions.find((option) => option.label === "Funding")
    );
    const [showIsolatedMarginDropdown, setShowIsolatedMarginDropdown] =
        useState(false);
    const [showIsolatedMarginInputs, setShowIsolatedMarginInputs] =
        useState(false);
    const [selectedIsolatedMarginOption, setSelectedIsolatedMarginOption] =
        useState(isolatedMarginOptions[0]);
    const [isolatedMarginValue1, setIsolatedMarginValue1] = useState("");
    const [isolatedMarginValue2, setIsolatedMarginValue2] = useState("");

    const handleCoinSelect = (option) => {
        setSelectedCoin(option);
    };

    const handleFromSelect = (option) => {
        setSelectedFrom(option);
    };

    const handleToSelect = (option) => {
        setSelectedTo(option);
        setShowIsolatedMarginDropdown(option.label === "Isolated Margin");
        setShowIsolatedMarginInputs(option.label === "Isolated Margin");
    };

    const handleIsolatedMarginOptionSelect = (option) => {
        setSelectedIsolatedMarginOption(option);
    };

    return (
        <Modal
            visible={visible}
            footer={null}
            onCancel={onCancel}
            closeIcon={<IoClose />}
            title="Transfer"
        >
            <div>
                <p className="text-sm mt-8 text-[#707A8A]">
                    Internal transfers are free on Buycex.
                </p>
                <div className="mt-10">
                    <div className="pb-10 border-b">
                        <div className="bg-gray-50 py-4 rounded-md">
                            {/* From Dropdown */}
                            <div className="flex mb-3 px-5 rounded-md justify-between items-center cursor-pointer">
                                <div className="flex w-full gap-5">
                                    <div className="flex gap-2">
                                        <img
                                            src={FromTransferFunds}
                                            className="w-6 h-6"
                                            alt="Transfer Funds"
                                        />
                                        <p className="text-sm w-12">From</p>
                                    </div>
                                    <Dropdown
                                        options={dropdownOptions}
                                        selected={selectedFrom}
                                        onSelect={handleFromSelect}
                                        className="w-full"
                                    />
                                </div>
                            </div>

                            {/* Arrow Separator */}
                            <div className="flex justify-between px-5 w-full">
                                <div>
                                    <FaArrowDownLong className="ml-1" />
                                </div>
                                <div>
                                    <PiArrowsDownUpFill className="text-[#EFB81C]" />
                                </div>
                            </div>

                            {/* To Dropdown */}
                            <div className="flex pt-3 px-5 rounded-md items-center cursor-pointer">
                                <div className="flex w-full gap-5">
                                    <div className="flex gap-2">
                                        <img
                                            src={ToTransferFunds}
                                            className="w-6 h-6"
                                            alt="Transfer Funds"
                                        />
                                        <p className="text-sm w-12">To</p>
                                    </div>
                                    <Dropdown
                                        options={TodropdownOptions}
                                        selected={selectedTo}
                                        onSelect={handleToSelect}
                                        className={`transition-all ${
                                            showIsolatedMarginDropdown
                                                ? "w-1/2"
                                                : "w-full"
                                        }`}
                                    />
                                    {showIsolatedMarginDropdown && (
                                        <Dropdown
                                            options={isolatedMarginOptions}
                                            selected={
                                                selectedIsolatedMarginOption
                                            }
                                            onSelect={
                                                handleIsolatedMarginOptionSelect
                                            }
                                            className="w-1/2"
                                            isSearchable={true} // Enable search
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8">
                        <p className="text-sm">Coin</p>
                        <div>
                            {/* Conditionally Render Isolated Margin Inputs */}
                            {showIsolatedMarginInputs && (
                                <div className="flex gap-2">
                                    <div>
                                        <input
                                            type="text"
                                            value={isolatedMarginValue1}
                                            onChange={(e) =>
                                                setIsolatedMarginValue1(
                                                    e.target.value
                                                )
                                            }
                                            className="bg-gray-100 rounded-md py-2 px-3 w-full"
                                        />
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            value={isolatedMarginValue2}
                                            onChange={(e) =>
                                                setIsolatedMarginValue2(
                                                    e.target.value
                                                )
                                            }
                                            className="bg-gray-100 rounded-md py-2 px-3 w-full"
                                        />
                                    </div>
                                </div>
                            )}
                            {!showIsolatedMarginInputs && (
                                <TransferDropdown
                                    label="Coin"
                                    options={coinOptions}
                                    initialSelected={selectedCoin}
                                    onSelect={handleCoinSelect}
                                    width="100%"
                                />
                            )}
                        </div>
                        <div className="flex mt-3 justify-between w-full">
                            <p className="text-sm">Amount</p>
                            <p className="text-sm">
                                0.00000000{" "}
                                <span className="text-[#707A8A] text-sm">
                                    available
                                </span>{" "}
                                / 0.00000000{" "}
                                <span className="text-sm text-[#707A8A]">
                                    in order
                                </span>
                            </p>
                        </div>
                        <div>
                            <StyledInput
                                suffix="MAX"
                                className="bg-gray-100 rounded-md py-2"
                                size="large"
                            />
                        </div>
                    </div>
                </div>
                <button onClick={onCancel} className="bg-[#efb81c] py-2 text-center font-semibold w-full rounded-md mt-4">Confirm</button>
            </div>
        </Modal>
    );
};

const Dropdown = ({
    options,
    selected,
    onSelect,
    className,
    isSearchable = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(""); // State to track the search term
    const [filteredOptions, setFilteredOptions] = useState(options); // Filtered options based on search

    const handleToggle = () => setIsOpen(!isOpen);

    const handleSelect = (option) => {
        if (option.status === "Active") {
            onSelect(option);
            setIsOpen(false);
        }
    };

    // Function to handle search input change
    const handleSearchChange = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
        setFilteredOptions(
            options.filter((option) =>
                option.label.toLowerCase().includes(value)
            )
        );
    };

    return (
        <div className={`relative ${className}`}>
            <div
                onClick={handleToggle}
                className="cursor-pointer flex justify-between items-center rounded-md"
                aria-haspopup="listbox"
                aria-expanded={isOpen}
            >
                <label className="text-sm flex w-full justify-between items-center">
                    <p className="ml-2 text-sm">{selected.label}</p>
                </label>
                {isOpen ? (
                    <MdOutlineArrowDropUp className="text-[#707A8A]" />
                ) : (
                    <MdOutlineArrowDropDown className="text-[#707A8A]" />
                )}
            </div>
            {isOpen && (
                <div className="absolute z-10 bg-white rounded-md w-full shadow-lg mt-2">
                    {isSearchable && (
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="Search options..."
                            className="w-full px-3 py-2 border-b border-gray-200"
                        />
                    )}
                    {filteredOptions.map((option) => (
                        <div
                            key={option.label}
                            onClick={() => handleSelect(option)}
                            className={`px-3 py-2 cursor-pointer flex justify-between w-full items-center gap-2 
                                ${
                                    selected.label === option.label
                                        ? "text-[#EFB81C]"
                                        : "hover:bg-gray-100"
                                } 
                                ${
                                    option.status === "Inactive"
                                        ? "cursor-not-allowed"
                                        : ""
                                }`}
                        >
                            <p
                                className={`text-sm ${
                                    option.status === "Inactive"
                                        ? "text-gray-300"
                                        : ""
                                }`}
                            >
                                <span className="text-sm">{option.label}</span>
                            </p>
                            <div className="flex items-center">
                                <p
                                    className={`text-[#F6465D] ${
                                        option.status === "Inactive"
                                            ? "block"
                                            : "hidden"
                                    }`}
                                >
                                    {option.icon}
                                </p>
                                <p>
                                    {option.additionalText && (
                                        <span className="text-gray-500 text-xs">
                                            {option.additionalText}
                                        </span>
                                    )}
                                </p>
                            </div>
                        </div>
                    ))}
                    {filteredOptions.length === 0 && (
                        <div className="text-gray-500 px-3 py-2 text-sm">
                            No options found
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const StyledInput = styled(Input)`
    border-color: #ffffff; /* Set border color to white */
    &:focus,
    &:hover,
    &:active {
        border-color: #efb81c !important;
        box-shadow: none !important;
    }
    &.ant-input-outlined:focus,
    &.ant-input-outlined:focus-within {
        border-color: #efb81c !important;
        box-shadow: 0 0 0 2px rgba(239, 184, 28, 0.1) !important;
        outline: 0;
        background-color: #ffffff;
    }
    .ant-input-suffix {
        font-size: 14px;
        font-weight: 300;
    }
    &.ant-input-affix-wrapper-lg {
        border-radius: 5px;
    }
`;

export default TransferModal;
