// action types
import { 
    GET_2FA_QR_FAILURE, GET_2FA_QR_REQUEST, GET_2FA_QR_SUCCESS,
    SET_2FA_FAILURE, SET_2FA_REQUEST, SET_2FA_SUCCESS,
    CHANGE_PASSWORD_FAILURE, CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS,
    FETCH_AUTH_STATUS_FAILURE, FETCH_AUTH_STATUS_REQUEST, FETCH_AUTH_STATUS_SUCCESS,
    SAVE_ANTI_PISHING_FAILURE, SAVE_ANTI_PISHING_SUCCESS, SAVE_ANTI_PISHING_REQUEST,
    TOGGLE_SECURE_LOGGING,
    DISABLE_USER_ACCOUNT_REQUEST, DISABLE_USER_ACCOUNT_FAILURE, DISABLE_USER_ACCOUNT_SUCCESS,
    FETCH_BANK_STATUS_REQUEST,
    FETCH_BANK_STATUS_SUCCESS,
    FETCH_BANK_STATUS_FAILURE,
    FETCH_ANTI_PHISHING_CODE_REQUEST,
    FETCH_ANTI_PHISHING_CODE_SUCCESS,
    FETCH_ANTI_PHISHING_CODE_FAILURE,
} from "../types";

const initialChangePasswordState = {
    changePasswordLoading: false,
    changePasswordMessage: '',
    changePasswordError: null
};

export const changePasswordReducer = (state = initialChangePasswordState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                changePasswordLoading: true,
                changePasswordMessage: '',
                changePasswordError: null,
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordLoading: false,
                changePasswordMessage: action.payload,
                changePasswordError: null,
            };
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePasswordLoading: false,
                changePasswordMessage: '',
                changePasswordError: action.payload,
            };
        default:
            return state;
    }
};

const initialAuthCheckState = {
    authStatusLoading: false,
    authStatus: {},
    authStatusError: null
};

export const authCheckReducer = (state = initialAuthCheckState, action) => {
    switch (action.type) {
        case FETCH_AUTH_STATUS_REQUEST:
            return {
                ...state,
                authStatusLoading: true,
                authStatus: {},
                authStatusError: null
            };
        case FETCH_AUTH_STATUS_SUCCESS:
            return {
                ...state,
                authStatusLoading: false,
                authStatus: action.payload,
                authStatusError: null
            };
        case FETCH_AUTH_STATUS_FAILURE:
            return {
                ...state,
                authStatusLoading: false,
                authStatus: {},
                authStatusError: action.payload.error
            };
        default:
            return state;
    }
};

const bank = {
    authStatusLoading: false,
    bank: {},
    authStatusError: null
};

export const banks = (state = bank, action) => {
    switch (action.type) {
        case FETCH_BANK_STATUS_REQUEST:
            return {
                ...state,
                authStatusLoading: true,
                bank: {},
                authStatusError: null
            };
        case FETCH_BANK_STATUS_SUCCESS:
            return {
                ...state,
                authStatusLoading: false,
                bank: action.payload,
                authStatusError: null
            };
        case FETCH_BANK_STATUS_FAILURE:
            return {
                ...state,
                authStatusLoading: false,
                bank: {},
                authStatusError: action.payload
            };
        default:
            return state;
    }
};

const initial2FAQRState = {
    loading: false,
    data: null,
    error: null
};

export const get2FAQRReducer = (state = initial2FAQRState, action) => {
    switch (action.type) {
        case GET_2FA_QR_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case GET_2FA_QR_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        case GET_2FA_QR_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

const initialSet2FAState = {
    loading: false,
    data: null,
    error: null
};

export const set2FAReducer = (state = initialSet2FAState, action) => {
    switch (action.type) {
        case SET_2FA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case SET_2FA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        case SET_2FA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

const initialSaveAntiPishingState = {
    loading: false,
    data: null,
    error: null
}

export const saveAntiPishingReducer = (state = initialSaveAntiPishingState, action) => {
    switch (action.type) {
        case SAVE_ANTI_PISHING_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case SAVE_ANTI_PISHING_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        case SAVE_ANTI_PISHING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

const initialSecureLoggingState = {
    secureLogging: true, // default is active
};

export const secureLoggingReducer = (state = initialSecureLoggingState, action) => {
    switch (action.type) {
        case TOGGLE_SECURE_LOGGING:
            return {
                ...state,
                secureLogging: !state.secureLogging,
            };
        default:
            return state;
    }
};

const initialDisableAccountState = {
    loading: false,
    data: null,
    error: null   
}

export const disableAccountReducer = (state = initialDisableAccountState, action) => {
    switch (action.type) {
        case DISABLE_USER_ACCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case DISABLE_USER_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        case DISABLE_USER_ACCOUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};
const initialAntiPhishingCodeState = {
    loading: false,
    data: null,
    error: null
};



// Reducer
export const fetchAntiPhishingCode = (state = initialAntiPhishingCodeState, action) => {
   
    switch (action.type) {
   

        case FETCH_ANTI_PHISHING_CODE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null, // Clear previous errors
            };

        case FETCH_ANTI_PHISHING_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
         
                antiPhishingCode: action.payload, // Update the fetched anti-phishing code
                error: null,
            };
         
        case FETCH_ANTI_PHISHING_CODE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload, // Capture the error message or object
            };

        default:
            return state;
    }
};
