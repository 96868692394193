import React from 'react'
import Scams from "assets/img/varification/scams.svg";
import Secure from "assets/img/varification/secure-account.svg";
import Spot from "assets/img/varification/spot-spoofing.svg";
 
function RelatedArticles() { 

    const securityData = [
      {
          id: 1 ,
          title : "  How to Secure Your Account", 
          img :  Scams,          
      },
      {
          id: 2 ,
          title : "Unveiling Cryptocurrency Scams",         
          img :  Secure,
      },
      {
          id: 3 ,
          title : "Spotting Spoofing and Avoiding Scams",          
          img :  Spot,
      },
      
  ]

  return (
    <>


{
  securityData.map((data) => (  

<div className='p-3 bg-white shadow border rounded-2xl mt-5 flex justify-center items-center flex-col h-[200px]'>
<img src= {data.img} alt="" className=' w-[100px] h-[100px] object-contain lg:mt-[-100px]  md:mt-[-80px]' />
<h3 className='mt-3 text-base font-bold text-center lg:text-lg'>{data.title}</h3>
<a href="/" className='text-base bg-yellow-300 mt-3 p-1 px-2 rounded text-[#fff]'>More</a>

</div>

  ))
}   


   
    </>
  )
}

export default RelatedArticles
