const initialState = {
    loading: false,
    earnStaking: [],
    error: null
  };
  
  const earnStakingRedu = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_STAKING_DATA_REQUEST":
        return {
          ...state,
          loading: true,
          error: null
        };
      case "FETCH_STAKING_DATA_SUCCESS":
        return {
          ...state,
          loading: false,
          earnStaking: action.payload
        };
       
      case "FETCH_STAKING_DATA_FAILURE":
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default earnStakingRedu;
  