import React from "react";
import HomePageSections from "components/home-page-sections";

const Home = () => {
    return (
        <div className="home-page-wrapper">
            <HomePageSections />
        </div>
    );
};

export default Home;
