import React from 'react';

const ListingModel = ({ onConfirm, onCancel, children }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50" onClick={onCancel} />
      <div className="bg-white rounded-lg shadow-lg p-6 z-10">
        <div className="mb-4">{children}</div>
        <div className="flex justify-end space-x-4">
          <button className="bg-gray-300 px-4 py-2 rounded" onClick={onCancel}>
            Cancel
          </button>
          <button className="bg-yellow-500 text-white px-4 py-2 rounded" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListingModel;
